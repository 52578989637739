import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { Box, Button, DialogActions, DialogContent, Divider, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SearchSelect } from '@hoot/components/SearchSelect';
import { useAlert } from '@hoot/contexts/AlertContext';
import useGetSchoolsForDistrict from '@hoot/hooks/api/hfs/useGetSchools';
import {
  SchoolState,
  useDistrictRepPermissionsWizardContext,
} from '@hoot/pages/users/district-reps/district-rep-permissions-wizard/DistrictRepPermissionsContextProvider';

export interface SchoolSelectionForm {
  schoolId: string;
}

const SchoolSelectionStep = () => {
  const {
    billableEntityState,
    permissionsState,
    schoolState,
    actions: { attachSchoolData, goToNextStep, goToPreviousStep, dismissPermissionsWizard },
  } = useDistrictRepPermissionsWizardContext();

  const { error } = useAlert();

  const [selectedSchool, setSelectedSchool] = useState<SchoolState | undefined>(schoolState);

  const { data } = useGetSchoolsForDistrict(billableEntityState?.id ?? '', {
    enabled: !!billableEntityState,
    onError: (err) => {
      console.error(err);
      error('There was an error getting the enrolments for the billable entity!');
    },
  });

  const selectData =
    data?.schools?.map((school) => ({
      label: school.name,
      value: school.id,
    })) ?? [];

  const { handleSubmit, control, watch } = useForm<SchoolSelectionForm>({
    defaultValues: {
      schoolId: schoolState?.id,
    },
  });

  const submitSchoolSelectionData = () => {
    if (selectedSchool) {
      attachSchoolData(selectedSchool.id, selectedSchool.name);
    }
    goToNextStep();
  };

  const handleSelectEnrolment = (form: SchoolSelectionForm) => {
    const newSelectedSchool = data?.schools.find((entity) => entity.id === form.schoolId);
    if (!newSelectedSchool) {
      return;
    }
    setSelectedSchool({ id: newSelectedSchool.id, name: newSelectedSchool.name });
  };

  const schoolId = watch('schoolId');
  const isDistrict = billableEntityState?.type === LocationType.District;
  const isSchoolSelectionalRequired = isDistrict && !!permissionsState?.schoolEdit;
  const disableNextButton = (isSchoolSelectionalRequired && !selectedSchool) || (!!schoolId && !selectedSchool) || selectedSchool?.id !== schoolId;

  return (
    <form>
      <DialogContent sx={{ pl: 0, pt: 0 }}>
        <Typography variant="titleMedium" sx={{ color: '#000' }}>
          Step 3 - Select School
        </Typography>
        <Stack sx={{ pt: 2 }} direction="row">
          <Box sx={{ width: '50%' }}>
            <SearchSelect
              data={selectData}
              control={control}
              name="schoolId"
              label={`School ${isSchoolSelectionalRequired ? '' : '(Optional)'}`}
              required={isSchoolSelectionalRequired}
              onChangeCallback={handleSubmit(handleSelectEnrolment)}
            />
          </Box>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 0, mt: 3 }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack direction="row">
            <Button onClick={() => goToPreviousStep()} color="inherit" variant="outlined" size="large">
              Back
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button onClick={dismissPermissionsWizard} color="inherit" variant="outlined" size="large">
              Cancel
            </Button>
            <Button onClick={submitSchoolSelectionData} variant="contained" size="large" type="submit" disabled={disableNextButton}>
              Next
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </form>
  );
};

export default SchoolSelectionStep;
