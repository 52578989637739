import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { groupQualityAssuranceMetricByValue } from '../../../../hooks/api/tasks/enums';
import FilterDropDown from '../../FilterDropDown';

export function QualityAssuranceMetricsFilterDropDown(props: {
  value: string[];
  onChange: (val: string[]) => void;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { value, onChange, popOverButtonSx } = props;

  return (
    <FilterDropDown<string>
      title="Metric"
      dismissPopoverOnSelection={true}
      value={value}
      options={groupQualityAssuranceMetricByValue().map((value) => ({
        label: value,
        value: value,
      }))}
      onChange={(val) => onChange(val as string[])}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveQualityAssuranceMetricsFilterDropDown(props: { value?: string[]; onChange: (val: string[]) => void }) {
  const { value = [], onChange } = props;

  const handleDelete = (val: string) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip key={`chip-metric-${c}`} label={`Metric: ${c}`} variant="outlined" onDelete={handleDelete(c)} deleteIcon={<Close />} />
      ))}
    </>
  );
}
