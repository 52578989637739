import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LessonDetailResponse } from '@hoot/hooks/api/lessons/useGetLesson';
import { RescheduleWizardState, RescheduleWizardType, useRescheduleWizard } from './RescheduleWizardContextProvider';
import { RescheduleWizardDialogBody } from './RescheduleWizardModal';

interface Form {
  wizardType: RescheduleWizardType;
}

export function RescheduleWizardTypeStep(props: { lessonDetails: LessonDetailResponse }) {
  const { control, handleSubmit } = useForm<Form>({
    defaultValues: {
      wizardType: RescheduleWizardType.NewTeacher,
    },
  });

  const { updateWizardState, updateRescheduledLesson } = useRescheduleWizard();

  const onSubmit = (form: Form) => {
    updateRescheduledLesson({
      wizardType: form.wizardType,
      teacher: null,
    });
    updateWizardState(RescheduleWizardState.LessonDetails);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RescheduleWizardDialogBody>
          <Stack>
            <Controller
              name="wizardType"
              control={control}
              render={({ field }) => (
                <RadioGroup onChange={field.onChange} value={field.value} name="reschedule-wizard-type">
                  <FormControlLabel value={RescheduleWizardType.NewTeacher} control={<Radio />} label="Reschedule with any teacher" />
                  {props.lessonDetails.teacherAccountId ? (
                    <FormControlLabel
                      value={RescheduleWizardType.SameTeacher}
                      control={<Radio />}
                      label={`Reschedule with previous teacher (${props.lessonDetails.teacherNumber})`}
                    />
                  ) : null}
                </RadioGroup>
              )}
            />
          </Stack>
        </RescheduleWizardDialogBody>
      </form>
    </>
  );
}
