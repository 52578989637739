import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { BoxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { LocationTypeFilterOptions } from '@hoot/hooks/api/hfs/useSearchDistrictSchoolDetails';
import { useStableSearchParams } from '@hoot/hooks/useStableSearchParams';
import { IFilter, IFilterSelection } from '../../components/form/searchFilters/FilterSelects';
import SearchFilters from '../../components/form/searchFilters/SearchFilters';
import { DistrictSchoolPageQueryParams } from './models';

const districtAndSchoolFilters: IFilter[] = [
  {
    label: 'Type',
    queryName: DistrictSchoolPageQueryParams.Type,
    options: [
      { label: 'District', queryValue: LocationTypeFilterOptions.District },
      { label: 'School', queryValue: LocationTypeFilterOptions.School },
      { label: 'Standalone School', queryValue: LocationTypeFilterOptions.StandaloneSchool },
    ],
  },
  {
    label: 'Product Status',
    queryName: DistrictSchoolPageQueryParams.ProductStatus,
    options: [
      { label: 'Active', queryValue: ProductStatus.Active },
      { label: 'Requested', queryValue: ProductStatus.Requested },
      { label: 'Expired', queryValue: ProductStatus.Expired },
    ],
  },
  {
    label: 'Enrolment Status',
    queryName: DistrictSchoolPageQueryParams.EnrolmentStatus,
    options: [
      { label: 'Open', queryValue: EnrolmentStatus.Open },
      { label: 'In Progress', queryValue: EnrolmentStatus.InProgress },
      { label: 'Scheduled', queryValue: EnrolmentStatus.Scheduled },
      { label: 'Closed', queryValue: EnrolmentStatus.Closed },
    ],
  },
  {
    label: 'Country',
    queryName: DistrictSchoolPageQueryParams.Country,
    options: [
      { label: 'Canada', queryValue: HootSupportedCountry.Canada },
      { label: 'United States', queryValue: HootSupportedCountry.UnitedStates },
    ],
  },
];

const getInitialFilterSelections = (searchParams: URLSearchParams) => {
  return districtAndSchoolFilters.map((filter: IFilter) => ({
    ...filter,
    values: filter.options.map((option) => ({
      ...option,
      isSelected: searchParams.has(filter.queryName!) && searchParams.getAll(filter.queryName!).includes(option.queryValue!),
    })),
  }));
};

interface DistrictsAndSchoolsSearchFiltersProps {
  sx?: BoxProps['sx'];
}

const DistrictsAndSchoolsFilters = (props: DistrictsAndSchoolsSearchFiltersProps) => {
  const { sx } = props;

  const [searchParams, setSearchParams] = useStableSearchParams();

  const [filterSelections, setFilterSelections] = useState<IFilterSelection[]>(getInitialFilterSelections(searchParams));

  // When filters have been updated, then update the query args in the URL.
  useEffect(() => {
    // Transform the selected filters into an object that react-router-dom can recognize as query strings.
    const selectedFilters = filterSelections
      .map((filterSelection) => {
        const selectedOptionValues = filterSelection.values
          .filter((filterOption) => filterOption.isSelected)
          .map((filterOption) => filterOption.queryValue);
        return {
          filterName: filterSelection.queryName!,
          selectedOptionValues,
        };
      })
      .filter((filter) => filter.selectedOptionValues.length > 0)
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr.filterName]: curr.selectedOptionValues,
        };
      }, {});

    setSearchParams(
      (prevSearchParamsState) => {
        const newSearchParams = createSearchParams(selectedFilters);

        const retainPrevSearchArgIfExists = (key: string) => {
          if (prevSearchParamsState.has(key)) {
            newSearchParams.set(key, prevSearchParamsState.get(key)!);
          }
        };
        // Retain all other "non-filter" query properties.
        retainPrevSearchArgIfExists(DistrictSchoolPageQueryParams.Search);
        retainPrevSearchArgIfExists(DistrictSchoolPageQueryParams.PageSize);
        retainPrevSearchArgIfExists(DistrictSchoolPageQueryParams.OrderBy);
        retainPrevSearchArgIfExists(DistrictSchoolPageQueryParams.SortDirection);

        // Reset to the initial page when filter criteria changes, else result set may become out of sync.
        newSearchParams.set(DistrictSchoolPageQueryParams.Page, '1');

        return newSearchParams;
      },
      { replace: true },
    );
  }, [filterSelections, setSearchParams]);

  return <SearchFilters sx={sx} filterSelections={filterSelections} setFilterSelections={setFilterSelections} />;
};

export default DistrictsAndSchoolsFilters;
