import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface PrioritizedSkillResponse {
  id: string;
  name: string;
}

async function getPrioritizedSkill(): Promise<PrioritizedSkillResponse[]> {
  const { data } = await axios.get<PrioritizedSkillResponse[]>(`${config.apiUrl}/admin/library/filter-level-prioritized-skill`);
  return data;
}

export default function useGetFilterLevelPrioritizedSkill(options?: Omit<UseQueryOptions<PrioritizedSkillResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetFilterLevelPrioritizedSkill], () => getPrioritizedSkill(), options);
}
