import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { TimePickerStyle, useUserPreferences } from '../../contexts/UserPreferencesContext';

interface Form {
  timePickerStyle: TimePickerStyle;
}

export function EditPreferencesDialog(props: { onClose: () => void }) {
  const { timePickerStyle, setTimePickerStyle } = useUserPreferences();

  const { control, handleSubmit } = useForm<Form>({
    defaultValues: {
      timePickerStyle: timePickerStyle,
    },
  });

  const handleOnClose = () => {
    props.onClose();
  };

  const onSubmit = (form: Form) => {
    setTimePickerStyle(form.timePickerStyle);
    props.onClose();
  };

  return (
    <Dialog open={true} onClose={handleOnClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="headlineSmall">Edit Preferences</Typography>
          <IconButton onClick={handleOnClose} sx={{ color: '#1C1B1F' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack mt="16px">
            <FormControl>
              <FormLabel id="time-picker-style">
                <Typography sx={{ color: '#1C1B1F' }} variant="bodySmall">
                  Clock
                </Typography>
              </FormLabel>

              <Controller
                name="timePickerStyle"
                control={control}
                render={({ field }) => (
                  <RadioGroup value={field.value} onChange={field.onChange} aria-labelledby="time-picker-style">
                    <FormControlLabel value={TimePickerStyle.Analog} control={<Radio />} label="Use analog clock to select time" />
                    <FormControlLabel value={TimePickerStyle.Digital} control={<Radio />} label="Use digital clock to select time" />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
          <Button size="large" variant="outlined" color="primary" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button size="large" variant="contained" type="submit">
            Apply
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
