import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import QualityAssuranceTasks from '@hoot/pages/tasks/QualityAssuranceTasks';

const TeacherTasksTab = () => {
  const { teacherAccountId } = useParams() as { teacherAccountId: string | undefined };

  if (!teacherAccountId) {
    return null;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <QualityAssuranceTasks teacherAccountId={teacherAccountId} />
    </Box>
  );
};

export default TeacherTasksTab;
