import { Autocomplete, TextField } from '@mui/material';
import useGetAccounts, { AccountResponse } from '../../../hooks/api/common/useGetAccounts';

export function AccountAutoComplete(props: { onChange: (value: string) => void; value: string }) {
  const { onChange, value } = props;
  const { data } = useGetAccounts(false);
  const accounts: AccountResponse[] = data ? [{ id: '', name: '', active: false, additionalBackgroundCheck: false }, ...data] : [];

  const selectedAccount = accounts.find((s) => s.id === value);

  return (
    <Autocomplete
      getOptionLabel={(option) => option.name}
      options={accounts}
      fullWidth
      size="small"
      onChange={(event, val) => {
        if (val) {
          onChange(val?.id);
        }
      }}
      renderInput={(params) => <TextField {...params} label="Accounts" variant="outlined" />}
      value={selectedAccount || null}
    />
  );
}
