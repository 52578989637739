import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';

export interface ImpersonationResponse {
  impersonationUrl: string;
}

async function impersonateUser(userId: string): Promise<ImpersonationResponse> {
  const { data } = await axios.post(`${config.apiUrl}/auth/impersonate/${userId}`);

  return data;
}

export function useImpersonateUser(options?: Omit<UseMutationOptions<ImpersonationResponse, AxiosError, string>, 'mutationKey' | 'mutationFn'>) {
  return useMutation<ImpersonationResponse, AxiosError, string>((userId) => impersonateUser(userId), options);
}
