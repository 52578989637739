import { SearchStudentsQuerySortKeyEnum } from '@hoot-reading/hoot-core/dist/enums/user/search-student-query-sort-key.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  TableContainer,
  TablePagination,
  TablePaginationProps,
} from '@mui/material';
import { useEffect, useState } from 'react';
import SearchTextField, { SearchTextFieldProps } from '@hoot/components/form/SearchTextField';
import { IFilterSelection } from '@hoot/components/form/searchFilters/FilterSelects';
import SearchFilters from '@hoot/components/form/searchFilters/SearchFilters';
import SelectStudentTable, { SelectStudentTableProps } from '@hoot/components/modals/select-student-modal/SelectStudentTable';
import { useAlert } from '@hoot/contexts/AlertContext';
import useSearchStudentsV2, { SearchStudentResponse, SearchStudentsQuery } from '@hoot/hooks/api/end-users/useSearchStudentsV2';
import { SortOrder } from '@hoot/interfaces/order-by';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import { studentProfileStatusType } from '@hoot/interfaces/profile';

export interface SelectStudentModalProps extends Pick<DialogProps, 'open'> {
  onDismiss: () => void;
  onApply: (selectedStudent: SearchStudentResponse) => void;
  queryDefaults?: Partial<SearchStudentsQuery>;
  hideColumns?: SearchStudentsQuerySortKeyEnum[];
}

const initialFilters: IFilterSelection[] = [
  {
    label: 'Profile Status',
    queryName: 'profile-status',
    values: [
      { label: studentProfileStatusType.ACTIVE, queryValue: StudentStatus.Active, isSelected: true },
      { label: studentProfileStatusType.LEAD, queryValue: StudentStatus.Lead, isSelected: false },
      { label: studentProfileStatusType.PAUSED, queryValue: StudentStatus.Paused, isSelected: false },
      { label: studentProfileStatusType.INACTIVE, queryValue: StudentStatus.Inactive, isSelected: false },
      { label: studentProfileStatusType.COLD, queryValue: StudentStatus.Cold, isSelected: false },
    ],
  },
  {
    label: 'Has Enrolments',
    queryName: 'has-enrolments',
    values: [
      { label: 'Yes', queryValue: 'true', isSelected: false },
      { label: 'No', queryValue: 'false', isSelected: false },
    ],
  },
];

const SelectStudentModal = (props: SelectStudentModalProps) => {
  const { open, onApply, onDismiss, queryDefaults, hideColumns } = props;

  const [searchInput, setSearchInput] = useState('');
  const [filterSelections, setFilterSelections] = useState<IFilterSelection[]>(initialFilters);
  const [selectedStudent, setSelectedStudent] = useState<SearchStudentResponse>();

  const [searchStudentsQuery, setSearchStudentsQuery] = useState<SearchStudentsQuery>({
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: SearchStudentsQuerySortKeyEnum.Name,
    sortDirection: SortOrder.ASC,
    ...queryDefaults,
  });

  const [searchStudentsResponse, setSearchStudentsResponse] = useState<GenericPaginatedResponse<SearchStudentResponse>>({
    data: [],
    count: 0,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { error } = useAlert();

  const { refetch, isFetching } = useSearchStudentsV2(searchStudentsQuery, {
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setSearchStudentsResponse(data);
    },
    onError: (err) => {
      console.error(err);
      error('An error occurred while searching students.');
    },
  });

  // When the query changes, then re-fetch results.
  useEffect(() => {
    refetch();
  }, [searchStudentsQuery, refetch]);

  // Update the search query when the filters are changed.
  useEffect(() => {
    // Transform the selected filters into an object that react-router-dom can recognize as query strings.
    const selectedFilters: Record<string, string[]> = filterSelections
      .map((filterSelection) => {
        const selectedOptionValues = filterSelection.values
          .filter((filterOption) => filterOption.isSelected)
          .map((filterOption) => filterOption.queryValue);
        return {
          queryName: filterSelection.queryName!,
          selectedOptionValues,
        };
      })
      .filter((filter) => filter.selectedOptionValues.length > 0)
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr.queryName]: curr.selectedOptionValues,
        };
      }, {});

    setSearchStudentsQuery((prevState) => ({
      ...prevState,
      profileStatus: selectedFilters['profile-status'] as StudentStatus[],
      hasEnrolments: selectedFilters['has-enrolments']?.map((x) => x === 'true'),
    }));
  }, [filterSelections]);

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchInput(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (debouncedText) => {
    setSearchStudentsQuery((prevState) => ({
      ...prevState,
      page: 1,
      search: debouncedText,
    }));
  };

  const onOrderByChanged: SelectStudentTableProps['onOrderByChanged'] = (orderByColumn, sortOrder) => {
    setSearchStudentsQuery((prevState) => ({
      ...prevState,
      orderBy: orderByColumn,
      sortDirection: sortOrder,
    }));
  };

  const onPageChange: TablePaginationProps['onPageChange'] = (_, newPage) => {
    setSearchStudentsQuery((prevState) => ({
      ...prevState,
      page: newPage + 1,
    }));
  };

  const onRowsPerPageChange: TablePaginationProps['onRowsPerPageChange'] = (event) => {
    setSearchStudentsQuery((prevState) => ({
      ...prevState,
      pageSize: Number(event.target.value),
    }));
  };

  const onStudentClicked: SelectStudentTableProps['onStudentClicked'] = (student) => {
    setSelectedStudent(student);
  };

  const _onApply = () => {
    if (selectedStudent) {
      onApply(selectedStudent);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onDismiss}
      fullWidth
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          maxHeight: '800px',
          height: '80vh',
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        Select Student
        <IconButton onClick={onDismiss}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <SearchTextField
          sx={{
            marginTop: '6px', // So the floating label doesn't get clipped.
          }}
          searchInput={searchInput}
          onSearchInputChanged={onSearchInputChanged}
          onSearchInputDebounced={onSearchInputDebounced}
          autoFocus
        />
        <SearchFilters filterSelections={filterSelections} setFilterSelections={setFilterSelections} sx={{ mt: 3 }} />
        <Box sx={{ width: '100%', mt: 6.5, height: '4px' }}>{isFetching && <LinearProgress />}</Box>
        <TableContainer>
          <SelectStudentTable
            query={searchStudentsQuery}
            results={searchStudentsResponse}
            onOrderByChanged={onOrderByChanged}
            selectedStudent={selectedStudent}
            onStudentClicked={onStudentClicked}
            hideColumns={hideColumns}
          />
          <TablePagination
            component="div"
            count={searchStudentsResponse.count ?? 0}
            page={searchStudentsResponse.page - 1}
            rowsPerPage={searchStudentsResponse.pageSize}
            rowsPerPageOptions={[10]}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Divider sx={{ position: 'absolute', top: 0, left: 0, right: 0, marginLeft: 3, marginRight: 3 }} />
        <Button onClick={onDismiss} variant="outlined" size="large">
          Cancel
        </Button>
        <Button onClick={_onApply} autoFocus variant="contained" size="large" disabled={!selectedStudent}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectStudentModal;
