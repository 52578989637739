import { Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';
import { FieldValues, Path, UseControllerProps, useController } from 'react-hook-form';

export type SelectProps<T extends FieldValues, TObj extends Path<T>> = MuiSelectProps & UseControllerProps<T, TObj>;

export function Select<T extends FieldValues, TObj extends Path<T>>(props: SelectProps<T, TObj>) {
  const { name, control, defaultValue, rules } = props;

  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  return (
    <MuiSelect {...field} {...props} error={!!fieldState.error}>
      {props.children}
    </MuiSelect>
  );
}
