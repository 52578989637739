import {
  CancellationReason,
  ScheduledLessonStatus,
  cancellationReasonLookup,
  lessonStatusesLookup,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultRowsPerPage } from '@hoot/common/constants';
import { HeaderData, Table } from '@hoot/components/ui/Table';
import {
  LessonNotesLegacyOrderColumn,
  LessonNotesLegacyQuery,
  useGetLessonNotesLegacy,
} from '@hoot/hooks/api/user/student/lesson-reviews/useGetLessonNoteLegacy';
import { SortOrder, toggleSortOrder } from '@hoot/interfaces/order-by';
import { routes } from '@hoot/routes/routes';
import { OrderBy } from '../../../lessons/enums';

export interface LessonNotesLegacyRow {
  id: string;
  lessonDate: string;
  lessonStatus: string;
  createdBy: string;
  bookLevels: string;
  cancellationReason: string;
}

const headers: HeaderData<LessonNotesLegacyRow>[] = [
  { name: 'id', property: 'id', isHidden: true },
  { name: 'Lesson Date', property: 'lessonDate', isSortable: true },
  { name: 'Lesson Status', property: 'lessonStatus', isSortable: true },
  { name: 'Created By', property: 'createdBy', isSortable: true },
  { name: 'Book Level(s)', property: 'bookLevels', isSortable: false },
  { name: 'Cancellation Reason', property: 'cancellationReason', isSortable: true },
];

const LessonNotesLegacyCard = (props: { studentProfileId: string }) => {
  const [query, setQuery] = useState<LessonNotesLegacyQuery>({
    page: 1,
    pageSize: defaultRowsPerPage,
    studentProfileId: props.studentProfileId,
    orderColumn: LessonNotesLegacyOrderColumn.LessonDate,
    orderBy: SortOrder.ASC,
  });
  const navigate = useNavigate();
  const lessonNotesLegacy = useGetLessonNotesLegacy(query);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize }));
  };

  const handleRowSelect = (lessonNoteId: string) => {
    navigate(routes.users.students.lessonNotes.urlWithId(props.studentProfileId, lessonNoteId));
  };

  const handleSortBy = (selectedColumn: keyof LessonNotesLegacyRow) => {
    function sortKey(): LessonNotesLegacyOrderColumn {
      switch (selectedColumn) {
        case 'cancellationReason':
          return LessonNotesLegacyOrderColumn.CancellationReason;
        case 'createdBy':
          return LessonNotesLegacyOrderColumn.CreatedBy;
        case 'lessonDate':
          return LessonNotesLegacyOrderColumn.LessonDate;
        case 'lessonStatus':
          return LessonNotesLegacyOrderColumn.LessonStatus;
        default:
          return LessonNotesLegacyOrderColumn.LessonDate;
      }
    }

    const orderColumn = sortKey();
    setQuery((q) => ({ ...q, orderColumn: orderColumn, orderBy: toggleSortOrder(q.orderBy), page: 1 }));
  };

  if (!lessonNotesLegacy.data) {
    return null;
  }

  const data = lessonNotesLegacy.data.data.map<LessonNotesLegacyRow>((lr) => ({
    cancellationReason: !!lr.cancellationReason ? cancellationReasonLookup[lr.cancellationReason as CancellationReason] : '',
    bookLevels: lr.bookLevels,
    createdBy: lr.createdBy,
    id: lr.id,
    lessonDate: DateTime.fromSQL(lr.lessonDate).toFormat('ccc LLLL dd, yyyy'),
    lessonStatus: lessonStatusesLookup[lr.lessonStatus as ScheduledLessonStatus],
  }));

  function sortColumn(): keyof LessonNotesLegacyRow {
    switch (query.orderColumn) {
      case LessonNotesLegacyOrderColumn.CancellationReason:
        return 'cancellationReason';
      case LessonNotesLegacyOrderColumn.CreatedBy:
        return 'createdBy';
      case LessonNotesLegacyOrderColumn.LessonDate:
        return 'lessonDate';
      case LessonNotesLegacyOrderColumn.LessonStatus:
        return 'lessonStatus';
    }
  }

  return (
    <Card
      sx={{
        width: 1,
      }}
    >
      <CardContent>
        <Stack>
          <Typography variant="titleLarge">Lesson Notes (Legacy)</Typography>
          <Box sx={{ marginTop: '24px' }}>
            <Table
              isSortable
              headers={headers}
              data={data}
              isPaginated
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onSortBy={handleSortBy}
              page={query.page}
              count={lessonNotesLegacy.data?.count || 0}
              rowsPerPage={query.pageSize}
              sortBy={sortColumn()}
              sortOrder={query.orderBy === SortOrder.ASC ? OrderBy.Asc : OrderBy.Desc}
              onRowClick={handleRowSelect}
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default LessonNotesLegacyCard;
