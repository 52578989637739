import { InstructionalFocusStatus } from '@hoot-reading/hoot-core/dist/enums/hoot-assessment/assessment-reporting';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { useGetHootLevelReport } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentHootLevelReport';
import StudentAssessmentState from '@hoot/pages/users/students/assessments/StudentAssessmentState';
import StudentUnitsInProgress from '@hoot/pages/users/students/assessments/StudentUnitsInProgress';

const StudentHootLevelCard = (props: { studentProfileId: string }) => {
  const { studentProfileId } = props;
  const { data } = useGetHootLevelReport(studentProfileId);

  const hasOnlyNotStartedModules = data?.every((module) => module.status === InstructionalFocusStatus.NotAssessed);
  if (!data || hasOnlyNotStartedModules) {
    return null;
  }

  return (
    <Card sx={{ p: 3 }}>
      <CardHeader sx={{ p: 0, pb: 3 }} title={<Typography variant="titleLarge">Hoot Level Summary</Typography>} />
      <CardContent sx={{ p: 0 }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <StudentUnitsInProgress instructionalFocusModule={data} />
          </Grid>
          <Grid item xs={6}>
            <StudentAssessmentState instructionalFocusModule={data} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StudentHootLevelCard;
