import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface EnrolmentQuery {
  page: number;
  pageSize: number;
  searchString?: string;
  status?: EnrolmentStatus[];
  districtRepresentativeAccountId?: string;
  studentProfileId?: string;
  locationId?: string;
  teacherAccountId?: string;
}

export interface EnrolmentResponse {
  enrolmentId: string;
  friendlyId: string;
  enrolmentStatus: EnrolmentStatus;
  locationId: string;
  locationName: string;
}

export interface EnrolmentQueryResponse {
  count: number;
  page: number;
  pageSize: number;
  enrolments: EnrolmentResponse[];
}

const getEnrolments = async (searchParams: EnrolmentQuery): Promise<EnrolmentQueryResponse> => {
  const { data } = await axios.post<EnrolmentQueryResponse>(`${config.apiUrl}/admin/hfs/enrolments/search`, searchParams);
  return data;
};

export default function useGetEnrolments(
  searchParams: EnrolmentQuery,
  options?: Omit<UseQueryOptions<EnrolmentQueryResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.EnrolmentSearch, searchParams], () => getEnrolments(searchParams), options);
}
