import React from 'react';

const SortIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1259_12484" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1259_12484)">
      <path
        d="M8 13.9997L5 10.9997L5.96667 10.0331L8 12.0664L10.0333 10.0331L11 10.9997L8 13.9997ZM5.96667 6.03307L5 5.06641L8 2.06641L11 5.06641L10.0333 6.03307L8 3.99974L5.96667 6.03307Z"
        fill="#333333"
      />
    </g>
  </svg>
);

export default SortIcon;
