import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../../config';
import { QueryKey } from '../../../queryKeys';

export type useGetComprehensionFormativeAssessmentDetailsResponse = {
  lessonDate: string;
  lessonNumber: string;
  lessonId: string;
  teacherName: string;
  teacherAccountId: string;
  bookTitle: string;
  score: string;
  textSpecificKnowledge: string;
  textStructure: string;
  sentenceAnalysis: string;
  verbalReasoning: string;
  vocabulary: string;
};

const getComprehensionFormativeAssessmentDetails = async (lessonReviewId: string, bookId: string) => {
  const { data } = await axios.get<useGetComprehensionFormativeAssessmentDetailsResponse>(
    `${config.apiUrl}/admin/lesson-reviews/${lessonReviewId}/formative-assessment/comprehension/${bookId}`,
  );
  return data;
};

export default function useGetFluencyFormativeAssessmentDetails(
  lessonReviewId: string,
  bookId: string,
  options?: Omit<UseQueryOptions<useGetComprehensionFormativeAssessmentDetailsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetComprehensionFormativeAssessmentDetails, lessonReviewId, bookId],
    () => getComprehensionFormativeAssessmentDetails(lessonReviewId, bookId),
    options,
  );
}
