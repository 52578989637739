import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routes/routes';
import HootReadingLogo from './HootReadingLogo';

const HootreadingLogo = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Typography variant="h5" noWrap onClick={() => navigate(routes.home.url)}>
      <div className={classes.title}>
        <HootReadingLogo width={97} height={52} />
        <div className={classes.reading} data-testid="admin-text">
          Admin
        </div>
      </div>
    </Typography>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  reading: {
    marginLeft: '12px',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Poppins',
  },
}));

export default HootreadingLogo;
