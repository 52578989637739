import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import {
  CancellationReason,
  ScheduledLessonLanguage,
  ScheduledLessonProgram,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonTheme,
  ScheduledLessonType,
  SubRequired,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { LessonQueryRequest } from '@hoot/hooks/api/lessons/lesson-query-request';
import { GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import { ScheduledLessonSetStatus } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';

export interface LessonWithTimeInfoResponse {
  lessonId: string;
  lessonNumber: number;
  lessonPrefixedNumber: string;
  status: ScheduledLessonStatus;
  cancellationReason: CancellationReason;
  language: ScheduledLessonLanguage;
  duration: number;
  parentEmail: string | null;
  teacherEmail: string | null;
  startTime: number;
  endTime: number;
  durationInMinutes: number;
  startDate: number;
  type: ScheduledLessonType;
  theme: ScheduledLessonTheme;
  program: ScheduledLessonProgram | null;
  subject: ScheduledLessonSubject;
  teacherPrefixedNumber: string | null;
  teacherId: string | null;
  teacherPhone: string | null;
  teacherJoinedAt?: number;
  teacherLeftAt?: number;
  studentPrefixedNumber: string;
  studentId: string;
  studentJoinedAt?: number;
  studentLeftAt?: number;
  parentPrefixedNumber: string | null;
  parentId: string | null;
  subRequired: SubRequired;
  siteName: string;
  accountName: string;
  partnerName: string;
  parentPhone: string | null;
  enrolment: EnrolmentSummary | null;
  lessonSet: LessonSetSummary | null;
}

interface LessonSetSummary {
  id: string;
  lessonSetNumber: number;
  lessonSetPrefixedNumber: string;
  status: ScheduledLessonSetStatus;
}

interface EnrolmentSummary {
  id: string;
  friendlyId: string;
  type: EnrolmentType;
  locationId: string;
}

async function getLessonsWithTimeInfo(query: LessonQueryRequest) {
  const { data } = await axios.post<GenericPaginatedResponse<LessonWithTimeInfoResponse>>(
    `${config.apiUrl}/admin/scheduler/lessons/search/with-time-info`,
    query,
  );
  return data;
}

export default function useLessonsWithTimeInfoQuery(
  query: LessonQueryRequest,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<LessonWithTimeInfoResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.LessonsWithTimeInfo, query], () => getLessonsWithTimeInfo(query), options);
}
