import styled from '@emotion/styled';
import { Refresh } from '@mui/icons-material';
import { Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import CountUp from 'react-countup';
import { useAlert } from '@hoot/contexts/AlertContext';
import useGetLessonSummaryDashboard from '../../../hooks/api/reporting/useGetLessonSummaryDashboard';
import { LessonSummary } from '../interfaces/lesson-summary';

const LessonSummaryDash = () => {
  const { error } = useAlert();

  const [dashboard, setDashboard] = useState<LessonSummary>();
  const { refetch } = useGetLessonSummaryDashboard({
    onSuccess: (response) => {
      setDashboard(response);
    },
    onError: () => {
      error('Failed to fetch dashboard data');
    },
  });

  async function refreshData() {
    setDashboard(undefined); // so that it counts up and visually appears to load new data
    await refetch();
  }

  return (
    <Grid container alignItems="center" maxWidth="xl" spacing={2}>
      <Grid container item xs={12}>
        <Grid item container xs={4} />
        <Grid item container xs={4}>
          <Typography variant="h5" align={'center'}>
            Welcome to the Hoot Admin Portal!
          </Typography>
        </Grid>
        <Grid item container justifyContent="right" xs={4}>
          <Tooltip title={'Refresh data'}>
            <Refresh
              onClick={refreshData}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Today's Snapshot
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <NumberBadge elevation={3} sx={{ backgroundColor: '#1976d2' }}>
          <Stack direction={'column'}>
            <Typography variant="h2" align={'center'} sx={{ fontWeight: 'bold' }}>
              <CountUp separator="," end={dashboard?.openInProgress || 0} duration={0.5} />
            </Typography>
            <Typography variant="body1" align={'center'}>
              Lessons Open or In Progress
            </Typography>
          </Stack>
        </NumberBadge>
      </Grid>
      <Grid item xs={3}>
        <NumberBadge elevation={3} sx={{ backgroundColor: '#6386a5' }}>
          <Stack direction={'column'}>
            <Typography variant="h2" align={'center'} sx={{ fontWeight: 'bold' }}>
              <CountUp separator="," end={dashboard?.upcoming || 0} duration={0.5} />
            </Typography>
            <Typography variant="body1" align={'center'}>
              Upcoming Scheduled Lessons
            </Typography>
          </Stack>
        </NumberBadge>
      </Grid>
      <Grid item xs={3}>
        <NumberBadge elevation={3} sx={{ backgroundColor: '#5caf02' }}>
          <Stack direction={'column'}>
            <Typography variant="h2" align={'center'} sx={{ fontWeight: 'bold' }}>
              <CountUp separator="," end={dashboard?.completedSuccessfully || 0} duration={0.5} />
            </Typography>
            <Typography variant="body1" align={'center'}>
              Completed Successfully
            </Typography>
          </Stack>
        </NumberBadge>
      </Grid>
      <Grid item xs={3}>
        <NumberBadge elevation={3} sx={{ backgroundColor: '#bd0000' }}>
          <Stack direction={'column'}>
            <Typography variant="h2" align={'center'} sx={{ fontWeight: 'bold' }}>
              <CountUp separator="," end={dashboard?.completedUnsuccessfully || 0} duration={0.5} />
            </Typography>
            <Typography variant="body1" align={'center'}>
              Completed Unsuccessfully
            </Typography>
          </Stack>
        </NumberBadge>
      </Grid>
      <Grid item xs={3}>
        <NumberBadge elevation={3} sx={{ backgroundColor: '#673ab7' }}>
          <Stack direction={'column'}>
            <Typography variant="h2" align={'center'} sx={{ fontWeight: 'bold' }}>
              <CountUp separator="," end={(dashboard?.totalMinutesRead || 0) / 60} duration={0.5} />
            </Typography>
            <Typography variant="body1" align={'center'}>
              📚 Hours of Reading! 🚀
            </Typography>
          </Stack>
        </NumberBadge>
      </Grid>
    </Grid>
  );
};

const NumberBadge = styled(Paper)({
  minHeight: '165px',
  color: 'white',
  opacity: '0.75',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export default LessonSummaryDash;
