import { Button, CircularProgress, Divider, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { VALID_HOOT_EMAIL_REGEX } from '@hoot/common/constants';
import SwitchField from '@hoot/components/form/SwitchField';
import { TextField } from '@hoot/components/form/TextField';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import useCreateHootEmployee from '@hoot/hooks/api/hoot-employees/useCreateHootEmployee';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import useHootEmployeeForm from '../hooks/useHootEmployeeForm';

interface Props {
  setHootEmployeeUserId: Dispatch<SetStateAction<string | undefined>>;
  setFormIsDirty: Dispatch<SetStateAction<boolean>>;
  setShowModal: (showAddHootEmployeeModal: boolean) => void;
  setShowDrawer: (showAddHootEmployeeDrawer: boolean) => void;
}

const HootEmployeeCreate = (props: Props) => {
  const { setHootEmployeeUserId, setFormIsDirty, setShowModal, setShowDrawer } = props;
  const { hootEmployee } = useAuth();
  const isUserSuperEmployee = hootEmployee!.canGrantPermissions;
  const { success, error } = useAlert();

  ///// Query items /////

  const queryClient = useQueryClient();

  const createHootEmployee = useCreateHootEmployee();

  const isLoading = createHootEmployee.isLoading;

  ///// Form items /////

  const { control, handleSubmit, formState, resetForm, setValue, watch } = useHootEmployeeForm();

  const isCurrHootEmployeeSuperUser = watch('isSuperUser');

  ///// Side effects /////

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState, setFormIsDirty]);

  /*
   * If the new employee is set to a super user then they
   * automatically get all permissions.
   */
  useEffect(() => {
    if (isCurrHootEmployeeSuperUser) {
      setValue('canUpdateBooks', true);
      setValue('canViewLessonVideos', true);
      setValue('canImpersonateUsers', true);
      setValue('canAccessReportingPage', true);
      setValue('canSoftDeleteUsers', true);
      setValue('canAccessPartnerManagement', true);
      setValue('canAdvancedEditLessonTeacher', true);
      setValue('canAdvancedEditLessonDetails', true);
      setValue('canAccessInvoicing', true);
      setValue('canManageLessonReviews', true);
      setValue('canImportTeacherReliabilityScore', true);
      setValue('canManageAdvancedStudentData', true);
      setValue('canViewDistrictDetails', true);
      setValue('canManageStudentDetails', true);
      setValue('canManageTeacherDetails', true);
      setValue('canManageDistrictReps', true);
      setValue('canAccessBillingInformation', true);
      setValue('canDeleteAssessments', true);
      setValue('canManageInstructionalLibraries', true);
    } else {
      setValue('canUpdateBooks', false);
      setValue('canViewLessonVideos', false);
      setValue('canImpersonateUsers', false);
      setValue('canAccessReportingPage', false);
      setValue('canSoftDeleteUsers', false);
      setValue('canAccessPartnerManagement', false);
      setValue('canAdvancedEditLessonTeacher', false);
      setValue('canAdvancedEditLessonDetails', false);
      setValue('canAccessInvoicing', false);
      setValue('canManageLessonReviews', false);
      setValue('canImportTeacherReliabilityScore', false);
      setValue('canManageAdvancedStudentData', false);
      setValue('canViewDistrictDetails', false);
      setValue('canManageStudentDetails', false);
      setValue('canManageTeacherDetails', false);
      setValue('canManageDistrictReps', false);
      setValue('canAccessBillingInformation', false);
      setValue('canDeleteAssessments', false);
      setValue('canManageInstructionalLibraries', false);
    }
  }, [isCurrHootEmployeeSuperUser, setValue]);

  ///// Event handlers /////

  const onSubmit = async (data: any) => {
    createHootEmployee.mutate(data, {
      onSuccess: (successData) => {
        success(`Employee has been created.`);
        queryClient.invalidateQueries(QueryKey.HootEmployeeSearch);
        setHootEmployeeUserId(successData.id);
        setShowModal(false);
        setShowDrawer(true);
      },
      onError: (errorData: any) => {
        console.error(errorData);
        const message = `${errorData.response.data.message}` || `There was an error, the employee has not been created.`;
        error(message);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="hoot-employee-form">
      <Stack direction="column" spacing={[4, 2]} sx={{ marginTop: '8px' }}>
        <Box sx={{ fontSize: 24, fontWeight: 600, alignSelf: 'center' }}>Create New Employee</Box>
        <Stack direction="column" alignItems="center" alignSelf="center" sx={{ padding: '10px' }} spacing={2}>
          <TextField
            name="firstName"
            label={'First Name *'}
            rules={{
              required: 'First Name is required',
              maxLength: {
                value: 48,
                message: 'First Name is too long',
              },
            }}
            control={control}
            variant="outlined"
            size="small"
            fullWidth
            helperText={''}
            helperTextFn={() => formState?.errors['firstName']?.message || ''}
          />
          <TextField
            name="lastName"
            label={'Last Name *'}
            rules={{
              required: 'Last Name is required',
              maxLength: {
                value: 48,
                message: 'Last Name is too long',
              },
            }}
            control={control}
            variant="outlined"
            size="small"
            fullWidth
            helperText={''}
            helperTextFn={() => formState?.errors['lastName']?.message || ''}
          />

          <TextField
            name="email"
            label={'Email *'}
            rules={{
              required: 'Email is required',
              maxLength: {
                value: 48,
                message: 'Email is too long',
              },
              pattern: {
                value: VALID_HOOT_EMAIL_REGEX,
                message: 'A valid @hootreading.com email is required.',
              },
            }}
            control={control}
            variant="outlined"
            size="small"
            fullWidth
            helperText={''}
            helperTextFn={() => formState?.errors['email']?.message || ''}
          />
          {isUserSuperEmployee ? (
            <Stack direction="column" alignItems="center" alignSelf="center" sx={{ padding: '10px' }} spacing={1}>
              <Divider>Permissions</Divider>
              <SwitchField label="Is Super User" control={control} name="isSuperUser" />
              <SwitchField label="Can Access Lesson Recordings" control={control} name="canViewLessonVideos" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField
                label="Can Access Partner Management"
                control={control}
                name="canAccessPartnerManagement"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField label="Can Access Invoicing" control={control} name="canAccessInvoicing" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField label="Can Access Reporting" control={control} name="canAccessReportingPage" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField label="Can Manage Library" control={control} name="canUpdateBooks" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField label="Can Impersonate Users" control={control} name="canImpersonateUsers" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField label="Soft-delete Users" control={control} name="canSoftDeleteUsers" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField
                label="Can Edit Assigned Teacher After Lesson Start Time"
                control={control}
                name="canAdvancedEditLessonTeacher"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField
                label="Can Update Lesson Details After Lesson Start Time"
                control={control}
                name="canAdvancedEditLessonDetails"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField label="Can Manage Lesson Reviews" control={control} name="canManageLessonReviews" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField label="Can Import TRS" control={control} name="canImportTeacherReliabilityScore" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField label="Can Manage TRS" control={control} name="canImportTeacherReliabilityScore" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField
                label="Can Manage Advanced Student Data"
                control={control}
                name="canManageAdvancedStudentData"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField label="Can View District Details" control={control} name="canViewDistrictDetails" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField
                label="Can Manage Student Details"
                control={control}
                name="canManageStudentDetails"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField
                label="Can Manage Teacher Details"
                control={control}
                name="canManageTeacherDetails"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField label="Can Manage District Reps" control={control} name="canManageDistrictReps" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField
                label="Can Access Billing Information"
                control={control}
                name="canAccessBillingInformation"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField label="Can Delete Assessments" control={control} name="canDeleteAssessments" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField
                label="Can Manage Instructional Libraries"
                control={control}
                name="canManageInstructionalLibraries"
                disabled={isCurrHootEmployeeSuperUser}
              />
            </Stack>
          ) : null}

          <Button
            form="hoot-employee-form"
            variant="contained"
            type="submit"
            disabled={!formState.isValid || isLoading}
            fullWidth
            style={{ height: 42, marginTop: 30 }}
          >
            {isLoading ? <CircularProgress size={22} /> : 'APPLY'}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default HootEmployeeCreate;
