import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import { ScheduledLessonType, scheduledLessonTypeLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { MailOutline, PhoneOutlined, WarningOutlined } from '@mui/icons-material';
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { DateTime, Duration, Interval } from 'luxon';
import React, { ChangeEvent, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { defaultRowsPerPage, rowsPerPageOptions } from '@hoot/common/constants';
import { useAlert } from '@hoot/contexts/AlertContext';
import { LiveLessonQueryRequest, LiveLessonResponse } from '@hoot/hooks/api/lessons/useLiveLessonsQuery';
import { LessonSetStatusIcon } from '@hoot/pages/lesson-sets/LessonSets';
import { routes } from '../../routes/routes';

function displayDuration(duration: Duration) {
  if (duration.weeks > 0) {
    return duration.weeks + ' week(s)';
  } else if (duration.days > 0) {
    return duration.days + ' day(s)';
  } else if (duration.hours > 0) {
    return `${duration.hours} hrs ${duration.minutes.toFixed(0)} min`;
  } else {
    return `${duration.minutes.toFixed(0)} min`;
  }
}

enum AlertColour {
  White = '#FFFFFF',
  Red = '#F9DDDD',
  Yellow = '#FFF1D4',
}

const OutsideShiftRow = (props: { lesson: LiveLessonResponse }) => {
  const { lesson } = props;
  const { success } = useAlert();

  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    success('Copied to clipboard');
  };

  const lessonDate = DateTime.fromMillis(lesson.startTime);
  const now = DateTime.now();
  const timeUntil = lessonDate.diffNow(['weeks', 'days', 'hours', 'minutes']);
  const difference = Interval.fromDateTimes(now, lessonDate);

  let alertColor = AlertColour.White;
  // If the diff is within the next 2 days
  if (difference.length('days') <= 2) {
    alertColor = AlertColour.Red;
  }
  // If the diff is greater than 2 days, but under 1 week
  else if (difference.length('days') > 2 && difference.length('week') <= 1) {
    alertColor = AlertColour.Yellow;
  }

  return (
    <TableRow
      key={lesson.lessonId}
      hover
      sx={{
        backgroundColor: alertColor,
      }}
    >
      <TableCell>
        <Grid container alignItems="center">
          {alertColor !== AlertColour.White ? (
            <WarningOutlined color={alertColor === AlertColour.Red ? 'error' : 'warning'} sx={{ marginLeft: '8px', marginRight: '8px' }} />
          ) : null}
          <Grid item>
            <Link target="_blank" to={routes.lessons.details.url(lesson.lessonId)}>
              {lesson.lessonPrefixedNumber}
            </Link>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>{displayDuration(timeUntil)}</TableCell>
      <TableCell>
        <Grid container flexDirection="column" alignItems="left" justifyContent="space-between">
          <Grid item sx={{ fontWeight: 'bold' }}>
            {scheduledLessonTypeLookup[lesson.type as ScheduledLessonType]}
            <Box>
              {lesson.lessonSet && (
                <Link to={routes.lessonSets.details.url(lesson.lessonSet.id)} target="_blank">
                  <span>{lesson.lessonSet.lessonSetPrefixedNumber}&nbsp;</span>
                  <span>
                    <LessonSetStatusIcon status={lesson.lessonSet.status} sx={{ width: '8px', height: '8px' }} />
                  </span>
                </Link>
              )}
            </Box>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Link target="_blank" to={routes.users.students.details.url(lesson.studentId)}>
          {lesson.studentPrefixedNumber}
        </Link>
      </TableCell>
      <TableCell>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {lesson.enrolment && lesson.enrolment?.type === EnrolmentType.InSchool ? (
              <Chip
                key={lesson.enrolment.friendlyId}
                label={lesson.enrolment.friendlyId}
                variant="outlined"
                onClick={() =>
                  lesson.enrolment
                    ? window.open(routes.districtsSchools.enrolments.details.url(lesson.enrolment.locationId, lesson.enrolment.id), '_blank')
                    : undefined
                }
              />
            ) : (
              <Link target="_blank" to={lesson.parentId ? routes.users.parents.details.url(lesson.parentId!) : '#'}>
                {lesson.parentPrefixedNumber ?? 'None'}
              </Link>
            )}
          </Grid>
          <Grid item>
            {!lesson.enrolment || lesson.enrolment?.type !== EnrolmentType.InSchool ? (
              <>
                {!!lesson.parentEmail ? (
                  <Tooltip title={lesson.parentEmail}>
                    <IconButton onClick={handleCopyToClipboard(lesson.parentEmail)}>
                      <MailOutline sx={{ marginLeft: '4px', marginRight: '4px', cursor: 'pointer' }} />{' '}
                    </IconButton>
                  </Tooltip>
                ) : null}

                {!!lesson.parentPhone ? (
                  <Tooltip title={lesson.parentPhone}>
                    <IconButton onClick={handleCopyToClipboard(lesson.parentPhone)}>
                      <PhoneOutlined sx={{ marginLeft: '4px', marginRight: '4px', cursor: 'pointer' }} />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </>
            ) : null}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const OutsideShiftTab = (props: {
  lessons: LiveLessonResponse[];
  lessonCount: number;
  lessonQuery: LiveLessonQueryRequest;
  setLessonQuery: React.Dispatch<React.SetStateAction<LiveLessonQueryRequest>>;
  isLoading: boolean;
  renderPaginationTotalCount: (props: { from: number; to: number; count: number }) => string;
}) => {
  const { lessons, lessonCount, lessonQuery, setLessonQuery, isLoading, renderPaginationTotalCount } = props;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(routes.liveLessons.outsideShift.url, { replace: true });
  }, [navigate]);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setLessonQuery((q) => ({ ...q, page: page + 1 }));
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setLessonQuery((q) => ({ ...q, pageSize }));
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress style={{ color: '#1976d2' }} />
      </div>
    );
  }

  if (lessonCount === 0) {
    return (
      <Typography align="center" variant="subtitle1">
        No lessons were found.
      </Typography>
    );
  }

  return (
    <Grid container sx={{ marginTop: '24px' }}>
      <Table
        sx={{
          border: 'solid 1px rgba(224, 224, 224, 1)',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell width="10%">Lesson Number</TableCell>
            <TableCell width="10%">Time Until</TableCell>
            <TableCell width="25%">Lesson Type</TableCell>
            <TableCell width="25%">Student</TableCell>
            <TableCell width="30%">Parent / Enrolment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lessons.map((lesson) => (
            <OutsideShiftRow key={lesson.lessonId} lesson={lesson} />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              count={lessonCount}
              rowsPerPage={lessonQuery.pageSize}
              labelDisplayedRows={renderPaginationTotalCount}
              page={lessonQuery.page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Grid>
  );
};

export default OutsideShiftTab;
