import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const PartialCircleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66699 4C8.66699 6.20914 6.87613 8 4.66699 8C2.45785 8 0.666992 6.20914 0.666992 4C0.666992 1.79086 2.45785 0 4.66699 0C6.87613 0 8.66699 1.79086 8.66699 4ZM2.66699 4H6.66699C6.66699 2.89543 5.77156 2 4.66699 2C3.56242 2 2.66699 2.89543 2.66699 4Z"
        fill={props.fill ?? '#1976D2'}
      />
    </SvgIcon>
  );
};

export default PartialCircleIcon;
