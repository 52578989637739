import { ScheduledLessonType, lessonLanguagesLookup, lessonSubjectLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { ArrowDropDown, ArrowDropUp, ReportProblemOutlined } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Grid, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TimePicker2 } from '../../../../components/form/DatePicker';
import { NonFormDisplayField } from '../../../../components/form/NonFormDisplayField';
import { LessonTypeSelect } from '../../../../components/form/selectFields/LessonTypeSelect';
import { enrolmentTypeMapping } from '../../../../components/hfs-utils';
import useGetEnrolment from '../../../../hooks/api/hfs/useGetEnrolment';
import useGetLocation from '../../../../hooks/api/hfs/useGetLocation';
import useGetLessonConflictsV2 from '../../../../hooks/api/lessons/useGetConflictsV2';
import { LessonDetailResponse } from '../../../../hooks/api/lessons/useGetLesson';
import { routes } from '../../../../routes/routes';
import { combineDateWithTime } from '../../../../utils/dateTime';
import { RescheduleWizardState, useRescheduleWizard } from '../RescheduleWizardContextProvider';
import { RescheduleWizardDialogBody } from '../RescheduleWizardModal';

interface Form {
  lessonDate: DateTime;
  lessonTime: DateTime;
  lessonType: ScheduledLessonType;
  isCustomerBilledLesson: boolean;
}

export function RescheduleWizardLessonDetailsStep(props: { lessonDetails: LessonDetailResponse }) {
  const { updateRescheduledLesson, updateWizardState, rescheduledLesson } = useRescheduleWizard();

  const [showMoreDetails, setShowMoreDetails] = useState<boolean>(false);

  const { control, getValues, handleSubmit, watch } = useForm<Form>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      isCustomerBilledLesson: rescheduledLesson.isCustomerBillable ?? props.lessonDetails.isCustomerBillable ?? false,
      lessonDate: DateTime.fromMillis(rescheduledLesson.lessonAt ?? props.lessonDetails.startsAt),
      lessonTime: DateTime.fromMillis(rescheduledLesson.lessonAt ?? props.lessonDetails.startsAt),
      lessonType: rescheduledLesson.lessonType ?? props.lessonDetails.lessonType,
    },
  });

  const { lessonDate, lessonTime } = watch();

  const newLessonDateTime = combineDateWithTime(lessonDate, lessonTime);
  const isConflictCheckedEnabled = props.lessonDetails.startsAt !== newLessonDateTime.toMillis();

  const conflictCheckerRequest = useGetLessonConflictsV2(
    {
      studentProfileId: props.lessonDetails.studentProfileId,
      studentLessonTimeFrames: [
        {
          startsAt: newLessonDateTime.toMillis(),
          endsAt: newLessonDateTime.plus({ minutes: props.lessonDetails.duration }).toMillis(),
          lessonId: props.lessonDetails.lessonId,
        },
      ],
    },
    { enabled: isConflictCheckedEnabled },
  );

  const hasConflict = (conflictCheckerRequest.data?.length ?? 0) > 0;

  const handleShowMoreDetailsClick = () => {
    setShowMoreDetails((currentState) => !currentState);
  };

  const isLessonSet = !!props.lessonDetails.lessonSet;
  const now = DateTime.now();

  function getMinStartDate() {
    if (props.lessonDetails.enrolment) {
      const { startDate } = props.lessonDetails.enrolment;
      if (startDate >= now.toMillis()) {
        return DateTime.fromMillis(props.lessonDetails.enrolment.startDate).startOf('day');
      }
    }
    return DateTime.now().startOf('day');
  }

  function getMaxStartDate() {
    if (props.lessonDetails.enrolment) {
      if (props.lessonDetails.enrolment?.startDate) {
        return DateTime.fromMillis(props.lessonDetails.enrolment.endDate).endOf('day');
      }
    }
    return DateTime.now().plus({ year: 1 }).endOf('day');
  }

  const onSubmit = (form: Form) => {
    updateRescheduledLesson({
      lessonAt: combineDateWithTime(form.lessonDate, form.lessonTime).toMillis(),
      lessonType: form.lessonType,
      isCustomerBillable: form.isCustomerBilledLesson,
    });

    updateWizardState(RescheduleWizardState.TeacherDetails);
  };

  const minLessonAt = getMinStartDate();
  const maxLessonAt = getMaxStartDate();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RescheduleWizardDialogBody
        errorContainer={<Errors hasError={hasConflict} message="This date and time are not available for this student" />}
        nextStepDisabled={hasConflict}
      >
        <Typography sx={{ lineHeight: '24px', fontSize: '16px', fontWeight: 500 }}>Lesson Details</Typography>
        <Grid marginTop="24px" container flex={1}>
          <Stack direction="row" flex={1} spacing="16px">
            <Grid item md={6}>
              <Controller
                name="lessonDate"
                control={control}
                rules={{
                  required: true,
                  validate: (val: DateTime) => {
                    if (isLessonSet && (val < minLessonAt || val > maxLessonAt)) {
                      return 'Lesson date(s) outside of Enrolment';
                    } else if (hasConflict) {
                      return '';
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState }) => (
                  <DesktopDatePicker
                    label="Lesson Date"
                    disablePast
                    minDate={minLessonAt}
                    maxDate={maxLessonAt}
                    onChange={field.onChange}
                    value={field.value}
                    format="LL/dd/yyyy"
                    slotProps={{
                      textField: {
                        helperText: fieldState.error?.message,
                        error: !!fieldState.error,
                        fullWidth: true,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name="lessonTime"
                control={control}
                rules={{
                  required: true,
                  validate: (val) => {
                    if (!val) {
                      return 'Selected Time is a required value';
                    }

                    const { lessonDate, lessonTime } = getValues();

                    if (!lessonDate) {
                      return 'Date cannot be empty!';
                    }

                    try {
                      const startsAtDateTime = lessonDate.set({
                        hour: lessonTime.hour,
                        minute: lessonTime.minute,
                        second: 0,
                        millisecond: 0,
                      });

                      const now = DateTime.now();
                      if (startsAtDateTime < now) {
                        return `Selected Time must be greater than ${now.toFormat('h:mm a')}`;
                      }
                    } catch (exc) {
                      console.error('Not a valid time.', exc);
                      return 'Not a valid time.';
                    }

                    return true;
                  },
                }}
                render={({ field, fieldState }) => (
                  <TimePicker2
                    onChange={field.onChange}
                    value={field.value}
                    label="Lesson Time"
                    slotProps={{
                      textField: {
                        error: !!fieldState.error,
                        helperText: fieldState.error?.message,
                        fullWidth: true,
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Stack>
          <Grid item md={12} marginTop="24px">
            <LessonTypeSelect control={control} name="lessonType" required />
          </Grid>
          <Grid item md={12} marginTop="24px">
            <Controller
              name="isCustomerBilledLesson"
              control={control}
              render={({ field }) => <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label="Customer-Billed Lesson" />}
            />
          </Grid>

          <Stack>
            <Stack marginTop="24px" alignItems="center" direction="row">
              <Typography sx={{ lineHeight: '24px', fontSize: '16px', fontWeight: 500 }}>More Details</Typography>
              <IconButton onClick={handleShowMoreDetailsClick} sx={{ marginLeft: '24px' }}>
                {showMoreDetails ? <ArrowDropUp /> : <ArrowDropDown />}
              </IconButton>
            </Stack>
            {showMoreDetails ? <MoreDetailsPanel lessonDetails={props.lessonDetails} /> : null}
          </Stack>
        </Grid>
      </RescheduleWizardDialogBody>
    </form>
  );
}

function MoreDetailsPanel(props: { lessonDetails: LessonDetailResponse }) {
  const enrolemenRequest = useGetEnrolment(props.lessonDetails.enrolment?.id ?? '', {
    enabled: !!props.lessonDetails.enrolment,
  });

  const locationRequest = useGetLocation(props.lessonDetails.enrolment?.locationId ?? '', {
    enabled: !!props.lessonDetails.enrolment && !!props.lessonDetails.enrolment.locationId,
  });

  return (
    <Grid container marginTop="8px" spacing="16px">
      <Grid item md={6}>
        <NonFormDisplayField label="Account" value={props.lessonDetails.account ?? 'N/A'} />
      </Grid>
      <Grid item md={6}>
        <NonFormDisplayField label="Duration" value={`${props.lessonDetails.duration} Minutes`} />
      </Grid>
      <Grid item md={6}>
        <NonFormDisplayField
          label="Enrolment"
          value={props.lessonDetails.enrolment?.friendlyId ?? 'N/A'}
          linkTo={
            props.lessonDetails.enrolment
              ? routes.districtsSchools.enrolments.details.url(props.lessonDetails.enrolment.locationId, props.lessonDetails.enrolment.id)
              : undefined
          }
        />
      </Grid>
      <Grid item md={6}>
        <NonFormDisplayField label="Enrolment Type" value={enrolemenRequest.data ? enrolmentTypeMapping[enrolemenRequest.data.type] : 'N/A'} />
      </Grid>
      <Grid item md={6}>
        <NonFormDisplayField label="Language" value={lessonLanguagesLookup[props.lessonDetails.language]} />
      </Grid>
      <Grid item md={6}>
        <NonFormDisplayField label="Lesson Set ID" value={props.lessonDetails.lessonSet?.lessonSetNumber.toString() ?? 'N/A'} />
      </Grid>
      {props.lessonDetails.parentName ? (
        <Grid item md={6}>
          <NonFormDisplayField
            label="Parent Name"
            value={props.lessonDetails.parentName}
            linkTo={props.lessonDetails.parentProfileId ? routes.users.parents.details.url(props.lessonDetails.parentProfileId) : undefined}
          />
        </Grid>
      ) : null}
      {props.lessonDetails.parentEmail ? (
        <Grid item md={6}>
          <NonFormDisplayField label="Parent Email" value={props.lessonDetails.parentEmail} />
        </Grid>
      ) : null}
      <Grid item md={6}>
        <NonFormDisplayField
          label="School"
          value={locationRequest.data?.name ?? 'N/A'}
          linkTo={locationRequest.data ? routes.districtsSchools.details.url(locationRequest.data.id) : undefined}
        />
      </Grid>
      <Grid item md={6}>
        <NonFormDisplayField
          label="District"
          value={locationRequest.data?.district?.name ?? 'N/A'}
          linkTo={
            locationRequest.data && locationRequest.data.district ? routes.districtsSchools.details.url(locationRequest.data.district.id) : undefined
          }
        />
      </Grid>
      <Grid item md={6}>
        <NonFormDisplayField label="Subject" value={lessonSubjectLookup[props.lessonDetails.subject]} />
      </Grid>
      <Grid item md={6}>
        <NonFormDisplayField label="Associated Rescheduled Lesson" value={props.lessonDetails.rescheduledLessonNumber?.toString() ?? 'N/A'} />
      </Grid>
    </Grid>
  );
}

function Errors(props: { hasError?: boolean; message: string }) {
  if (!props.hasError) {
    return null;
  }

  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{
        border: 'solid 2px #FF0000',
        backgroundColor: '#fdede9',
        borderRadius: '4px',
        marginBottom: '24px',
        padding: '24px',
      }}
    >
      <ReportProblemOutlined htmlColor="#F00" />
      <Typography sx={{ marginLeft: '16px', fontSize: '16', fontWeight: 500, lineHeight: '24px' }}>{props.message}</Typography>
    </Stack>
  );
}
