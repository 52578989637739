export const DEFAULT_PAGE_SIZE = 10;

// For when a page url uses query params.
export enum PaginationQueryParams {
  Page = 'page',
  PageSize = 'page-size',
}

export interface PaginatableQuery {
  page: number;
  pageSize: number;
}

export interface GenericPaginatedResponse<T, U = undefined> {
  page: number;
  pageSize: number;
  count: number;
  data: T[];
  extraData?: U;
}
