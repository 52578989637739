import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { BookType, DecodableFormat, Language, SpellingType, Subject } from './enums';

export interface UpdateBookRequest {
  title: string;
  isbn: string;
  isActive: boolean;
  publisher: string;
  description: string;
  minAge?: number;
  maxAge?: number;
  subject: Subject;
  language: Language;
  resourceType?: BookType;
  decodableFormatId?: DecodableFormat;
  instructionalFocus: string[];
  instructionalUnit: string[];
  prioritizedSkill: string[];
  genre?: string;
  subGenre?: string;
  topic: string[];
  subTopic: string[];
  theme: string[];
  subTheme: string[];
  format?: string;
  series?: string;
  nonConforming: boolean;
  hiLo: boolean;
  level: string;
  collections: string[];
  contentTags: string[];
  wordCount?: number;
  spellingType: SpellingType | null;
  seq?: number;
  isDecodableLibrary: boolean;
  isDoublePageEnabled: boolean;
  isStandardDoublePage: boolean;
}

async function updateBook(bookId?: string, updatedBook?: UpdateBookRequest) {
  await axios.put(`${config.apiUrl}/admin/library/${bookId}`, updatedBook);
}

export default function useEditBook(
  bookId?: string,
  options?: Omit<UseMutationOptions<void, AxiosError, UpdateBookRequest>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((updatedBook) => updateBook(bookId, updatedBook), options);
}
