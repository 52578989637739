import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { ConflictType } from '@hoot/hooks/api/lessons/useGetConflicts';
import { QueryKey } from '../queryKeys';

export interface ConflictsRequest {
  studentProfileId: string;
  teacherAccountId?: string;
  studentLessonTimeFrames: ConflictsRequestV2LessonTimeFrame[];
  teacherLessonTimeFrames?: ConflictsRequestV2LessonTimeFrame[];
}

export interface ConflictsRequestV2LessonTimeFrame {
  lessonId?: string;
  startsAt: number;
  endsAt: number;
}

export interface ConflictsV2Response {
  lessonId?: string;
  lessonStart: number;
  lessonEnd: number;
  message: string;
  conflictType: ConflictType;
  userType: ConflictUserType;
}

export enum ConflictUserType {
  Student = 'Student',
  Teacher = 'Teacher',
}

export default function useGetLessonConflictsV2(
  request: ConflictsRequest,
  options?: Omit<UseQueryOptions<ConflictsV2Response[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.LessonConflictsV2, request],
    async () => {
      return (await axios.post<ConflictsV2Response[]>(`${config.apiUrl}/admin/scheduler/conflicts/v2`, request)).data;
    },
    options,
  );
}
