import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { DistrictRepresentativeResponse } from '@hoot/hooks/api/user/district-representative/useGetDistrictRepresentative';
import DistrictRepPermissionsWizardContextProvider, { useDistrictRepPermissionsWizardContext } from './DistrictRepPermissionsContextProvider';

type DistrictRepPermissionsDialogProps = InternalDialogProps & InternalContextProps;

interface InternalDialogProps {
  show: boolean;
}

interface InternalContextProps {
  onDismiss: () => void;
  onCompleted?: () => void;
  districtRep: DistrictRepresentativeResponse;
}

const DistrictRepPermissionsWizardDialog = (props: InternalDialogProps) => {
  const { show } = props;
  const {
    currentStep,
    permissionsState,
    billableEntityState,
    actions: { dismissPermissionsWizard, reset },
  } = useDistrictRepPermissionsWizardContext();

  const steps: string[] = useMemo(() => {
    if (billableEntityState?.type === LocationType.District) {
      if (permissionsState?.enrolmentView && !permissionsState?.schoolEdit) {
        return ['Billable Entity', 'Permissions', 'School', 'Enrolment', 'Summary'];
      } else if (permissionsState?.schoolEdit && !permissionsState.districtEdit) {
        return ['Billable Entity', 'Permissions', 'School', 'Summary'];
      } else {
        return ['Billable Entity', 'Permissions', 'Summary'];
      }
    } else {
      if (permissionsState?.enrolmentView && !permissionsState?.schoolEdit) {
        return ['Billable Entity', 'Permissions', 'Enrolment', 'Summary'];
      } else {
        return ['Billable Entity', 'Permissions', 'Summary'];
      }
    }
  }, [billableEntityState, permissionsState]);

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [reset, show]);

  return (
    <Dialog open={show} onClose={dismissPermissionsWizard} fullWidth maxWidth="md">
      <Stack direction="column" sx={{ backgroundColor: 'white', p: 3 }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="headlineSmall">Permissions Wizard</Typography>
            <Close sx={{ '&:hover': { cursor: 'pointer' }, mr: '22px' }} onClick={dismissPermissionsWizard} />
          </Stack>

          <Stepper sx={{ marginTop: 6 }} activeStep={currentStep.id} alternativeLabel nonLinear>
            {steps.map((label, idx) => {
              return (
                <Step key={label}>
                  <StepLabel StepIconProps={{ sx: { transform: 'scale(2)', zIndex: 1, color: '#757575' } }}>
                    <span style={{ fontWeight: currentStep.id === idx ? 'bold' : 'normal' }}>{label}</span>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        {currentStep.render()}
      </Stack>
    </Dialog>
  );
};

const DistrictRepPermissionsDialog = (props: DistrictRepPermissionsDialogProps) => {
  const { show, districtRep, onDismiss, onCompleted } = props;
  return (
    <DistrictRepPermissionsWizardContextProvider dismissPermissionsWizard={onDismiss} onCompleted={onCompleted} districtRep={districtRep}>
      <DistrictRepPermissionsWizardDialog show={show} />
    </DistrictRepPermissionsWizardContextProvider>
  );
};

export default DistrictRepPermissionsDialog;
