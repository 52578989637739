import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import DistrictsAndSchoolsPage from '@hoot/pages/district-schools/DistrictsAndSchoolsPage';
import LocationPage, { LocationTabs } from '@hoot/pages/district-schools/LocationPage';
import EnrolmentPage, { EnrolmentTabs } from '@hoot/pages/district-schools/details/enrolments/EnrolmentPage';
import ProductsPage, { ProductTabs } from '@hoot/pages/district-schools/products/ProductPage';
import { DashboardView } from '@hoot/pages/home';
import PermissionDenied from '@hoot/pages/home/PermissionDenied';
import LessonSetDetailsPage from '@hoot/pages/lesson-sets/details/LessonSetDetailsPage';
import LessonSetDetails from '@hoot/pages/lesson-sets/details/details-tab/LessonSetDetails';
import LessonSetTeacherAssignment from '@hoot/pages/lesson-sets/details/teacher-assignment-tab/LessonSetTeacherAssignment';
import Lessons from '@hoot/pages/lessons';
import ImportExportAvailability from '@hoot/pages/lessons/Availability/ImportExportAvailability';
import LessonsLayout from '@hoot/pages/lessons/LessonsLayout';
import CreateLessonPage from '@hoot/pages/lessons/create-v2/CreateLessonPage';
import EditLessonPage, { LessonDetailTabs } from '@hoot/pages/lessons/edit/EditLessonPage';
import LiveLessons from '@hoot/pages/live-lessons';
import PartnerManagement from '@hoot/pages/partner-management';
import ReportingView from '@hoot/pages/reporting/ReportingView';
import Shifts from '@hoot/pages/shifts/Shifts';
import QualityAssuranceTaskPage, { QATasksTabs } from '@hoot/pages/tasks/QualityAssuranceTaskPage';
import TeacherInvoiceBillableTasksPage from '@hoot/pages/teacher-invoices/TeacherInvoiceBillableTasksPage';
import TeacherInvoicePeriodsPage from '@hoot/pages/teacher-invoices/TeacherInvoicePeriodsPage';
import DistrictRepresentativePage from '@hoot/pages/users/district-reps/DistrictRepresentativePage';
import { DistrictRepTabs } from '@hoot/pages/users/district-reps/components/DistrictRepsTable/DistrictRepsTable';
import DistrictRepsView from '@hoot/pages/users/district-reps/components/DistrictRepsView';
import HootEmployeesView from '@hoot/pages/users/employees/HootEmployeesView';
import ParentsPage from '@hoot/pages/users/parents/ParentsPage';
import { ParentTabs } from '@hoot/pages/users/parents/components/ParentsTable/ParentsTable';
import ParentsView from '@hoot/pages/users/parents/components/ParentsView';
import StudentPage from '@hoot/pages/users/students/StudentPage';
import StudentsView from '@hoot/pages/users/students/StudentsView';
import { StudentTabs } from '@hoot/pages/users/students/components/StudentsTable/StudentsTable';
import LessonReviewDetails from '@hoot/pages/users/students/lesson-reviews/details/LessonReviewDetails';
import TeachersPage from '@hoot/pages/users/teachers/TeachersPage';
import TeachersView from '@hoot/pages/users/teachers/components/TeachersView';
import CreateShiftPage from '@hoot/pages/users/teachers/create-shift-wizard/CreateShiftPage';
import { TeacherTabs } from '@hoot/pages/users/teachers/enums/teacher-enums';
import TeacherShiftDetailsPage from '@hoot/pages/users/teachers/shifts/TeacherShiftDetailsPage';
import { FeatureFlags } from '../common/featureFlags';
import { useFeatureFlags } from '../contexts/FeatureFlagContext';
import useSentry from '../hooks/useSentry';
import Blocks from '../pages/blocks/Blocks';
import AdminView from '../pages/home/AdminView';
import { LessonSets } from '../pages/lesson-sets/LessonSets';
import LessonSetQualityMonitoring from '../pages/lesson-sets/details/quality-monitoring-tab/LessonSetQualityMonitoring';
import { BookDetails } from '../pages/library/BookDetails';
import { Library } from '../pages/library/Library';
import StudentLevelsReport from '../pages/reporting/student-levels/StudentLevelsReport';
import QualityAssuranceTasks from '../pages/tasks/QualityAssuranceTasks';
import { LessonNoteDetails } from '../pages/users/students/lesson-notes/details';
import TeacherBlockDetailsPage from '../pages/users/teachers/blocks/TeacherBlocksDetailsPage';
import { TeacherBlockHistoryPage } from '../pages/users/teachers/blocks/TeacherBlocksHistoryPage';
import CreateBlockPage from '../pages/users/teachers/create-block-wizard/CreateBlockPage';
import { TeacherShiftHistoryPage } from '../pages/users/teachers/shifts/TeacherShiftHistoryPage';
import { routes } from './routes';

function PermissionLayout(props: { permission: HootEmployeeScope }) {
  const { scopes } = useAuth();
  const accessGranted = !!scopes.find((s) => s === props.permission);

  return accessGranted ? <Outlet /> : <PermissionDenied />;
}

function ParentsRoutes() {
  return (
    <Routes>
      <>
        <Route index element={<ParentsView />} />
        <Route path={routes.users.parents.create.path} element={<ParentsPage tab={ParentTabs.Details} />} />
        <Route path=":userId">
          <Route path={routes.users.parents.details.path} element={<ParentsPage tab={ParentTabs.Details} />} />
          <Route path={routes.users.parents.history.path} element={<ParentsPage tab={ParentTabs.History} />} />
        </Route>
      </>
    </Routes>
  );
}

function StudentRoutes() {
  const { scopes } = useAuth();
  const specialIndicatorTabAccessGranted = !!scopes.find((s) => s === HootEmployeeScope.AdvancedStudentData);

  return (
    <Routes>
      <Route index element={<StudentsView />} />
      <Route path={routes.users.students.create.path} element={<StudentPage tab={StudentTabs.Details} />} />
      <Route path={':studentProfileId'}>
        <Route index element={<Navigate to={routes.users.students.details.path} replace />} />
        <Route path={routes.users.students.details.path} element={<StudentPage tab={StudentTabs.Details} />} />
        <Route path={routes.users.students.timeline.path} element={<StudentPage tab={StudentTabs.Timeline} />} />
        <Route path={routes.users.students.schedule.path} element={<StudentPage tab={StudentTabs.Schedule} />} />
        <Route path={routes.users.students.instructionalPlan.path} element={<StudentPage tab={StudentTabs.InstructionalPlan} />} />
        <Route path={routes.users.students.lessonReviews.path}>
          <Route index element={<StudentPage tab={StudentTabs.LessonReviews} />} />
          <Route path=":lessonReviewId" element={<LessonReviewDetails />} />
        </Route>
        <Route path={routes.users.students.assessments.path} element={<StudentPage tab={StudentTabs.Assessments} />} />
        <Route path={routes.users.students.lessonNotes.path}>
          <Route index element={<Navigate to={routes.home.path} replace />} />
          <Route path=":lessonNoteId" element={<LessonNoteDetails />} />
        </Route>
        <Route path={routes.users.students.tasks.path} element={<StudentPage tab={StudentTabs.Tasks} />} />
        <Route path={routes.users.students.history.path} element={<StudentPage tab={StudentTabs.History} />} />
        <Route
          path={routes.users.students.specialIndicators.path}
          element={specialIndicatorTabAccessGranted ? <StudentPage tab={StudentTabs.SpecialIndicators} /> : <Navigate to={routes.home.url} />}
        />
      </Route>
    </Routes>
  );
}

function TeacherRoutes() {
  const { isFeatureEnabled } = useFeatureFlags();
  const isBlocksAndOpportunitiesEnabled = isFeatureEnabled(FeatureFlags.ff13371_blocks_and_opportunities);

  return (
    <Routes>
      <Route index element={<TeachersView />} />
      <Route path={routes.users.teachers.create.path} element={<TeachersPage tab={TeacherTabs.Details} />} />
      <Route path={routes.users.teachers.shifts.path}>
        <Route path={routes.users.teachers.shifts.create.path} element={<CreateShiftPage />} />
      </Route>
      <Route path={':teacherAccountId'}>
        <Route index element={<Navigate to={routes.users.teachers.details.path} replace />} />
        <Route path={routes.users.teachers.details.path} element={<TeachersPage tab={TeacherTabs.Details} />} />
        <Route path={routes.users.teachers.availability.path} element={<TeachersPage tab={TeacherTabs.Availability} />} />
        <Route path={routes.users.teachers.schedule.path} element={<TeachersPage tab={TeacherTabs.Schedule} />} />
        <Route path={routes.users.teachers.availabilityExceptions.path} element={<TeachersPage tab={TeacherTabs.AvailabilityExceptions} />} />
        <Route path={routes.users.teachers.reliability.path} element={<TeachersPage tab={TeacherTabs.Reliability} />} />
        <Route path={routes.users.teachers.tasks.path} element={<TeachersPage tab={TeacherTabs.Tasks} />} />
        <Route path={routes.users.teachers.history.path} element={<TeachersPage tab={TeacherTabs.History} />} />
        {isBlocksAndOpportunitiesEnabled ? null : (
          <Route path={routes.users.teachers.shifts.path}>
            <Route path={routes.users.teachers.shifts.create.path} element={<CreateShiftPage />} />
            <Route path=":shiftId/details" element={<TeacherShiftDetailsPage />} />
            <Route path=":shiftId/history" element={<TeacherShiftHistoryPage />} />
          </Route>
        )}
        {isBlocksAndOpportunitiesEnabled ? (
          <Route path={routes.users.teachers.blocks.path}>
            <Route path={routes.users.teachers.blocks.create.path} element={<CreateBlockPage />} />
            <Route path=":blockId/details" element={<TeacherBlockDetailsPage />} />
            <Route path=":blockId/history" element={<TeacherBlockHistoryPage />} />
          </Route>
        ) : null}
      </Route>
    </Routes>
  );
}

function DistrictRepRoutes() {
  return (
    <Routes>
      <Route index element={<DistrictRepsView />} />
      <Route path={routes.users.districtReps.create.path} element={<DistrictRepresentativePage tab={DistrictRepTabs.Details} />} />
      <Route path={':districtRepresentativeId'}>
        <Route path={routes.users.districtReps.details.path} element={<DistrictRepresentativePage tab={DistrictRepTabs.Details} />} />
        <Route path={routes.users.districtReps.history.path} element={<DistrictRepresentativePage tab={DistrictRepTabs.History} />} />
      </Route>
    </Routes>
  );
}

function UserRoutes() {
  return (
    <Routes>
      <Route path={routes.users.parents.path} element={<ParentsRoutes />} />
      <Route path={routes.users.students.path} element={<StudentRoutes />} />
      <Route path={routes.users.districtReps.path} element={<DistrictRepRoutes />} />
      <Route path={routes.users.teachers.path} element={<TeacherRoutes />} />
      <Route path={routes.users.employees.path} element={<HootEmployeesView />} />
    </Routes>
  );
}

function LessonRoutes() {
  return (
    <Routes>
      <Route index element={<Lessons />} />
      <Route path={routes.lessons.create.path} element={<CreateLessonPage />} />
      <Route path=":lessonId">
        <Route path={routes.lessons.details.path} element={<EditLessonPage tab={LessonDetailTabs.Details} />} />
        <Route path={routes.lessons.history.path} element={<EditLessonPage tab={LessonDetailTabs.History} />} />
        <Route path={routes.lessons.comments.path} element={<EditLessonPage tab={LessonDetailTabs.Comments} />} />
        <Route path={routes.lessons.dailyRecordings.path} element={<EditLessonPage tab={LessonDetailTabs.DailyRecording} />} />
        <Route path={routes.lessons.visualizer.path} element={<EditLessonPage tab={LessonDetailTabs.Visualizer} />} />
      </Route>
    </Routes>
  );
}

function ShiftRoutes() {
  return (
    <Routes>
      <Route index element={<Shifts />} />
    </Routes>
  );
}

function BlocksRoutes() {
  return (
    <Routes>
      <Route index element={<Blocks />} />
    </Routes>
  );
}

function DistrictsSchoolsRoutes() {
  return (
    <Routes>
      <Route index element={<DistrictsAndSchoolsPage />} />
      <Route path={routes.districtsSchools.create.path} element={<LocationPage tab={LocationTabs.Details} />} />
      <Route path=":locationId">
        <Route path={routes.districtsSchools.summary.path} element={<LocationPage tab={LocationTabs.Summary} />} />
        <Route path={routes.districtsSchools.details.path} element={<LocationPage tab={LocationTabs.Details} />} />
        <Route path={routes.districtsSchools.prodEnrol.path} element={<LocationPage tab={LocationTabs.ProductsAndEnrolments} />} />
        <Route path={routes.districtsSchools.students.path} element={<LocationPage tab={LocationTabs.Students} />} />
        <Route path={routes.districtsSchools.products.path}>
          <Route path=":productId">
            <Route path={routes.districtsSchools.products.details.path} element={<ProductsPage tab={ProductTabs.Details} />} />
            <Route path={routes.districtsSchools.products.capacity.path} element={<ProductsPage tab={ProductTabs.Capacity} />} />
            <Route path={routes.districtsSchools.products.qualityMonitoring.path} element={<ProductsPage tab={ProductTabs.QualityMonitoring} />} />
          </Route>
        </Route>
        <Route path={routes.districtsSchools.enrolments.path}>
          <Route path={routes.districtsSchools.enrolments.create.path} element={<EnrolmentPage tab={EnrolmentTabs.Details} />} />
          <Route path=":enrolmentId">
            <Route path={routes.districtsSchools.enrolments.details.path} element={<EnrolmentPage tab={EnrolmentTabs.Details} />} />
            <Route path={routes.districtsSchools.enrolments.capacity.path} element={<EnrolmentPage tab={EnrolmentTabs.Capacity} />} />
            <Route
              path={routes.districtsSchools.enrolments.qualityMonitoring.path}
              element={<EnrolmentPage tab={EnrolmentTabs.QualityMonitoring} />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

function LessonSetRoutes() {
  return (
    <Routes>
      <Route index element={<LessonSets />} />
      <Route path=":lessonSetId" element={<LessonSetDetailsPage />}>
        <Route path={routes.lessonSets.details.path} element={<LessonSetDetails />} />
        <Route path={routes.lessonSets.qualityMonitoring.path} element={<LessonSetQualityMonitoring />} />
        <Route path={routes.lessonSets.teacherAssignment.path} element={<LessonSetTeacherAssignment />} />
      </Route>
    </Routes>
  );
}

function TeacherInvoicesRoutes() {
  return (
    <Routes>
      <Route element={<PermissionLayout permission={HootEmployeeScope.AccessInvoicing} />}>
        <Route index element={<TeacherInvoicePeriodsPage />} />
        <Route path={routes.invoicing.tasks.path} element={<TeacherInvoiceBillableTasksPage />} />
      </Route>
    </Routes>
  );
}

const SecureRoutes = () => {
  useSentry();
  return (
    <>
      <Routes>
        <Route element={<AdminView />}>
          <Route path={routes.users.path} element={<UserRoutes />} />
          <Route path={routes.reporting.path}>
            <Route path={routes.reporting.metabase.path}>
              <Route path=":type?" element={<ReportingView />} />
            </Route>
            <Route path={routes.reporting.studentLevels.path} element={<StudentLevelsReport />} />
          </Route>

          <Route path={routes.home.path} element={<DashboardView />} />
          <Route path={routes.library.path}>
            <Route index element={<Library />} />
            <Route path={routes.library.book.path} element={<BookDetails />} />
          </Route>
          <Route path={routes.availabilityImportExport.path} element={<ImportExportAvailability />} />
          <Route path={routes.partnerManagement.path} element={<PartnerManagement />} />
          <Route element={<LessonsLayout />}>
            <Route path={routes.liveLessons.path}>
              <Route path=":type" element={<LiveLessons />} />
            </Route>
            <Route path={routes.lessons.path} element={<LessonRoutes />} />
          </Route>
          <Route path={routes.lessonSets.path} element={<LessonSetRoutes />} />
          <Route path={routes.districtsSchools.path} element={<DistrictsSchoolsRoutes />} />
          <Route path={routes.invoicing.path} element={<TeacherInvoicesRoutes />} />
          <Route path={routes.tasks.path}>
            <Route index element={<QualityAssuranceTasks />} />
            <Route path={':qualityAssuranceTaskId'}>
              <Route index element={<Navigate to={routes.tasks.details.path} replace />} />
              <Route path={routes.tasks.details.path} element={<QualityAssuranceTaskPage tab={QATasksTabs.Details} />} />
              <Route path={routes.tasks.history.path} element={<QualityAssuranceTaskPage tab={QATasksTabs.History} />} />
            </Route>
          </Route>
          <Route path={routes.shifts.path}>
            <Route index element={<ShiftRoutes />} />
          </Route>
          <Route path={routes.blocks.path}>
            <Route index element={<BlocksRoutes />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default SecureRoutes;
