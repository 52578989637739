import { Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { Control } from 'react-hook-form';
import { TextField } from '@hoot/components/form/TextField';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import { LessonNoteForm } from './LessonNoteDetailsPage';

interface LessonNoteNotesCardProps {
  isLoading: boolean;
  lessonNotes: string;
  control: Control<LessonNoteForm>;
}

const LessonNoteNotesCard = (props: LessonNoteNotesCardProps) => {
  const { isLoading, control, lessonNotes } = props;

  const { scopes } = useAuth();
  const canManageLessonReviews = !!scopes.find((s) => s === HootEmployeeScope.ManageLessonReviews);

  const LessonNoteTextDisplay = () => {
    if (!canManageLessonReviews) {
      return <Typography variant="bodyMedium">{lessonNotes}</Typography>;
    }
    return (
      <TextField
        name="lessonNotes"
        rules={{ required: 'Lesson notes are required' }}
        control={control}
        label="Lesson Notes"
        multiline
        fullWidth
        minRows={3}
      />
    );
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="titleLarge" sx={{ display: 'block', mb: 3 }}>
            Lesson Notes*
          </Typography>
          {isLoading ? <LoadingContent /> : <LessonNoteTextDisplay />}
        </CardContent>
      </Card>
    </>
  );
};

const LoadingContent = () => (
  <Grid container spacing={1}>
    {[...Array(3)].map((_, index) => (
      <React.Fragment key={`lesson-note-notes-card-placeholder-${index}`}>
        <Grid item xs={12}>
          <Skeleton variant="text" />
        </Grid>
      </React.Fragment>
    ))}
  </Grid>
);

export default LessonNoteNotesCard;
