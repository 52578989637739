import { TablePagination, TablePaginationProps } from '@mui/material';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { GenericPaginatedResponse, PaginationQueryParams } from '../interfaces/pagination';

interface QueryableTablePaginationProps<T, U> {
  paginatedResponse: GenericPaginatedResponse<T, U>;
}

const QueryableTablePagination = <T, U>(props: QueryableTablePaginationProps<T, U>) => {
  const { paginatedResponse } = props;
  const { count, page, pageSize } = paginatedResponse;

  const [, setSearchParams] = useSearchParams();

  const onPageChange: TablePaginationProps['onPageChange'] = (_, newPage) => {
    setSearchParams(
      (prev) => {
        const newSearchParams = createSearchParams(prev);
        newSearchParams.set(PaginationQueryParams.Page, `${newPage + 1}`);
        return newSearchParams;
      },
      { replace: true },
    );
  };

  const onRowsPerPageChange: TablePaginationProps['onRowsPerPageChange'] = (event) => {
    setSearchParams(
      (prev) => {
        const newSearchParams = createSearchParams(prev);
        newSearchParams.set(PaginationQueryParams.PageSize, event.target.value);
        return newSearchParams;
      },
      { replace: true },
    );
  };

  return (
    <>
      <TablePagination
        component="div"
        count={count ?? 0}
        page={page - 1}
        rowsPerPage={pageSize}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
};

export default QueryableTablePagination;
