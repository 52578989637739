import { BoxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { Months } from '@hoot/common/constants';
import { IFilter, IFilterSelection } from '@hoot/components/form/searchFilters/FilterSelects';
import SearchFilters from '@hoot/components/form/searchFilters/SearchFilters';
import { useStableSearchParams } from '@hoot/hooks/useStableSearchParams';
import { range } from '@hoot/pages/lessons/utils/date';
import { TeacherInvoicePeriodsPageQueryParams } from './interfaces/TeacherInvoicePeriodsPageQueryFilterParams';

const yearRange = range(2023, 2028);
const yearRangeOptions = yearRange.map((year) => ({
  label: year.toString(),
  queryValue: year.toString(),
}));

const teacherInvoicePeriodsFilters: IFilter[] = [
  {
    label: 'Month',
    queryName: TeacherInvoicePeriodsPageQueryParams.Month,
    options: [
      { label: Months.January, queryValue: '1' },
      { label: Months.February, queryValue: '2' },
      { label: Months.March, queryValue: '3' },
      { label: Months.April, queryValue: '5' },
      { label: Months.May, queryValue: '4' },
      { label: Months.June, queryValue: '6' },
      { label: Months.July, queryValue: '7' },
      { label: Months.August, queryValue: '8' },
      { label: Months.September, queryValue: '9' },
      { label: Months.October, queryValue: '10' },
      { label: Months.November, queryValue: '11' },
      { label: Months.December, queryValue: '12' },
    ],
  },
  {
    label: 'Year',
    queryName: TeacherInvoicePeriodsPageQueryParams.Year,
    options: yearRangeOptions,
  },
];

const getInitialFilterSelections = (searchParams: URLSearchParams) => {
  return teacherInvoicePeriodsFilters.map((filter: IFilter) => ({
    ...filter,
    values: filter.options.map((option) => ({
      ...option,
      isSelected: searchParams.has(filter.queryName!) && searchParams.getAll(filter.queryName!).includes(option.queryValue!),
    })),
  }));
};

interface TeacherInvoicePeriodsSearchFiltersProps {
  sx?: BoxProps['sx'];
}

const TeacherInvoicePeriodsFilters = (props: TeacherInvoicePeriodsSearchFiltersProps) => {
  const { sx } = props;

  const [searchParams, setSearchParams] = useStableSearchParams();

  const [filterSelections, setFilterSelections] = useState<IFilterSelection[]>(getInitialFilterSelections(searchParams));

  // When filters have been updated, then update the query args in the URL.
  useEffect(() => {
    // Transform the selected filters into an object that react-router-dom can recognize as query strings.
    const selectedFilters = filterSelections
      .map((filterSelection) => {
        const selectedOptionValues = filterSelection.values
          .filter((filterOption) => filterOption.isSelected)
          .map((filterOption) => filterOption.queryValue);
        return {
          filterName: filterSelection.queryName!,
          selectedOptionValues,
        };
      })
      .filter((filter) => filter.selectedOptionValues.length > 0)
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr.filterName]: curr.selectedOptionValues,
        };
      }, {});

    setSearchParams(
      (prevSearchParamsState) => {
        const newSearchParams = createSearchParams(selectedFilters);

        const retainPrevSearchArgIfExists = (key: string) => {
          if (prevSearchParamsState.has(key)) {
            newSearchParams.set(key, prevSearchParamsState.get(key)!);
          }
        };
        // Retain all other "non-filter" query properties.
        retainPrevSearchArgIfExists(TeacherInvoicePeriodsPageQueryParams.PageSize);
        retainPrevSearchArgIfExists(TeacherInvoicePeriodsPageQueryParams.OrderBy);
        retainPrevSearchArgIfExists(TeacherInvoicePeriodsPageQueryParams.SortDirection);

        // Reset to the initial page when filter criteria changes, else result set may become out of sync.
        newSearchParams.set(TeacherInvoicePeriodsPageQueryParams.Page, '1');

        return newSearchParams;
      },
      { replace: true },
    );
  }, [filterSelections, setSearchParams]);

  return <SearchFilters sx={sx} filterSelections={filterSelections} setFilterSelections={setFilterSelections} />;
};

export default TeacherInvoicePeriodsFilters;
