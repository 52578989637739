import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

export function CountryDropDown(props: {
  onChange: (countryCode: string) => void;
  value?: string;
  allCountries?: boolean;
  includeEmpty?: boolean;
  errorMessage?: string;
}) {
  const { errorMessage, onChange, includeEmpty, value } = props;
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <FormControl error={!!errorMessage} fullWidth>
      <InputLabel id="country-label">Country</InputLabel>
      <Select onChange={handleChange} labelId="counlabeltry" label="Country" value={value}>
        {includeEmpty ? (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        ) : null}
        <MenuItem value="CA">Canada</MenuItem>
        <MenuItem value="US">United States</MenuItem>
      </Select>
      {errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
}

export default CountryDropDown;
