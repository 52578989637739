import { LanguageQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/language-qualification.enum';
import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { TeacherSearchFieldOptions } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-search-field-options.enum';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import { FilterAltOutlined, MailOutline, PhoneOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Badge,
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { defaultRowsPerPage } from '@hoot/common/constants';
import SearchTextField, { SearchTextFieldProps } from '@hoot/components/form/SearchTextField';
import DataImportDialog from '@hoot/components/modals/data-import-modal/DataImportDialog';
import { HeaderData, Table } from '@hoot/components/ui/Table';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import useGetUsers, { TeacherSearchParams, UserQuery } from '@hoot/hooks/api/end-users/useGetUsers';
import { usePlaces } from '@hoot/hooks/usePlaces';
import { FormOption } from '@hoot/interfaces/form';
import { languageQualificationTypes, spokenLanguageTypes } from '@hoot/interfaces/teacher';
import { UserAccount } from '@hoot/interfaces/user';
import ActionsButton from '@hoot/pages/users/teachers/components/TeachersTable/TeachersTableActionsButton';
import { routes } from '@hoot/routes/routes';
import { removePrefix } from '@hoot/utils/stringUtils';
import TeacherFiltersDrawer from './TeacherFiltersDrawer';

const IGNORE_FILTERS = [
  'searchText',
  'page',
  'pageSize',
  'account',
  'accountType',
  'teacherQuery',
  'customerQuery',
  'districtRepQuery',
  'studentQuery',
];

const IGNORE_TEACHER_FILTERS = ['searchFieldSelection'];

type QueryKeys = keyof UserQuery;

type TeacherKeys = keyof TeacherSearchParams;

type AllKeys = QueryKeys | TeacherKeys;

const searchFieldOptions: FormOption<TeacherSearchFieldOptions>[] = [
  { value: TeacherSearchFieldOptions.TeacherName, label: 'Teacher Name' },
  { value: TeacherSearchFieldOptions.TeacherNumber, label: 'Teacher Number' },
  { value: TeacherSearchFieldOptions.TeacherEmail, label: 'Teacher Email' },
  { value: TeacherSearchFieldOptions.TeacherPhoneNumber, label: 'Teacher Phone' },
  { value: TeacherSearchFieldOptions.All, label: 'All Fields' },
];

interface TeacherTableRow {
  teacherName: React.ReactNode;
  teacherNumber: React.ReactNode;
  status: React.ReactNode;
  hootQualifications: React.ReactNode;
  languageQualifications: React.ReactNode;
}

const headers: HeaderData<TeacherTableRow>[] = [
  { property: 'teacherName', name: 'Name' },
  { property: 'teacherNumber', name: 'Teacher #' },
  { property: 'status', name: 'Status' },
  { property: 'hootQualifications', name: 'Hoot Qualifications' },
  { property: 'languageQualifications', name: 'Language Qualifications' },
];

function getChipStatusColor(value: TeacherStatus | undefined): string {
  if (!value) return '';
  switch (value) {
    case TeacherStatus.Active:
      return '#338F59';
    case TeacherStatus.Candidate:
      return '#0091D5';
    case TeacherStatus.Inactive:
      return '#D32D41';
  }
}

const FilterChips = (props: {
  badgesWithMultiSelectors: any[];
  userQuery: UserQuery;
  setUserQuery: React.Dispatch<React.SetStateAction<UserQuery>>;
}) => {
  const { badgesWithMultiSelectors, userQuery, setUserQuery } = props;
  const { countryOptionsCanadaFirst } = usePlaces('CA');

  const countryTypes = [...countryOptionsCanadaFirst].reduce(
    (a, b) => ({
      ...a,
      [b.value]: b.label,
    }),
    {},
  ) as { [key: string]: string };

  return (
    <Grid container item sx={{ marginLeft: '24px' }}>
      {badgesWithMultiSelectors?.map((badge) => {
        const [filterType, filterValue]: [AllKeys, any] = badge;
        const key = filterType;
        let value = filterValue;
        if (filterType === 'isEnabled') {
          value = filterValue ? 'Yes' : 'No';
        } else if (filterType === 'fromDate' || filterType === 'toDate') {
          value = DateTime.fromISO(filterValue).toFormat('LLL dd, yyyy');
        } else if (filterType === 'languageQualifications') {
          value = filterValue === 'IS_EMPTY' ? capitalCase(filterValue) : languageQualificationTypes[filterValue as LanguageQualification];
        } else if (filterType === 'spokenLanguages') {
          value = filterValue === 'IS_EMPTY' ? capitalCase(filterValue) : spokenLanguageTypes[filterValue as SpokenLanguage];
        } else if (filterType === 'country') {
          value = countryTypes[filterValue as SpokenLanguage];
        } else {
          if (typeof filterValue === 'string') {
            value = capitalCase(filterValue);
          }
        }
        return (
          <Chip
            key={`${key}-${value}`}
            sx={{
              margin: '4px',
            }}
            label={`${capitalCase(key)}: ${value}`}
            onDelete={() => {
              if (Object.keys(userQuery.teacherQuery).includes(filterType)) {
                setUserQuery((q: UserQuery) => ({
                  ...q,
                  teacherQuery: {
                    ...q.teacherQuery,
                    [filterType]: Array.isArray(q.teacherQuery[filterType as TeacherKeys])
                      ? (q.teacherQuery[filterType as TeacherKeys] as any)?.filter((val: string) => val !== filterValue)
                      : undefined,
                  },
                }));
              } else {
                setUserQuery((q: UserQuery) => ({
                  ...q,
                  [filterType]: Array.isArray(q[filterType as QueryKeys])
                    ? (q[filterType as QueryKeys] as any)?.filter((val: string) => val !== filterValue)
                    : undefined,
                }));
              }
            }}
          />
        );
      })}
    </Grid>
  );
};

export function TeachersTable() {
  return (
    <Stack spacing="16px">
      <HeaderCard />
      <SearchCard />
    </Stack>
  );
}

function HeaderCard() {
  const [showTRSImport, setShowTRSImport] = useState<boolean>(false);
  const { scopes } = useAuth();
  const canImportTRS = !!scopes.find((s) => s === HootEmployeeScope.ImportTeacherReliabilityScoreImport);

  return (
    <Card sx={{ padding: '24px' }}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Typography variant="headlineLarge">Teachers</Typography>
        <Stack direction="row" gap={2}>
          {showTRSImport ? <DataImportDialog isOpen={showTRSImport} close={() => setShowTRSImport(false)} /> : null}
          {canImportTRS ? <ActionsButton setShowTRSImport={setShowTRSImport} /> : null}
          <AddTeacherAction />
        </Stack>
      </Grid>
    </Card>
  );
}

const SearchCard = () => {
  const [searchText, setSearchText] = useState('');
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [userQuery, setUserQuery] = useState<UserQuery>({
    pageSize: 10,
    page: 1,
    searchText,
    isEnabled: true,
    customerQuery: {},
    teacherQuery: { searchFieldSelection: TeacherSearchFieldOptions.All, teacherStatus: [TeacherStatus.Active] },
    districtRepQuery: {},
    accountType: UserAccount.Teacher,
  });

  // Search for users based on toolbar searchText, resulting data goes to table body as rows of users.
  const { isFetching: isLoadingTeacherSearch, data: teacherResponseData } = useGetUsers({
    ...userQuery,
    searchText: removePrefix(userQuery.searchText),
  });

  const { success } = useAlert();

  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    success('Copied to clipboard');
  };

  const data =
    teacherResponseData && teacherResponseData.users
      ? teacherResponseData.users.map<TeacherTableRow>((user) => ({
          teacherName: (
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Link to={routes.users.teachers.details.url(user.teacherAccount!.id)}>
                  {user.firstName} {user.lastName}
                </Link>
              </Grid>

              <Grid item>
                <Tooltip title={user?.email ?? ''}>
                  <IconButton onClick={handleCopyToClipboard(user.email)}>
                    <MailOutline sx={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title={user?.phoneNumber ?? ''}>
                  <IconButton onClick={handleCopyToClipboard(user.phoneNumber)}>
                    <PhoneOutlined sx={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ),
          teacherNumber: <Typography variant="bodyMedium">{user?.teacherAccount?.prefixedNumber}</Typography>,
          status: (
            <Chip
              label={`${capitalCase(user.teacherAccount?.status ?? '')}`}
              sx={{
                margin: '4px',
                fontSize: 10,
                color: '#fff',
                backgroundColor: getChipStatusColor(user.teacherAccount?.status),
              }}
            />
          ),
          hootQualifications: (
            <Grid>
              {user.teacherAccount?.hootQualifications.map((q) => <Chip key={q} label={`${capitalCase(q)}`} sx={{ margin: '4px', fontSize: 10 }} />)}
            </Grid>
          ),
          languageQualifications: (
            <Tooltip title={user?.siteName ?? ''}>
              <Grid sx={{ maxWidth: 175 }}>
                {user.teacherAccount?.languageQualifications.map((q) => (
                  <Chip
                    key={q}
                    label={`${languageQualificationTypes[q]}`}
                    sx={{
                      margin: '4px',
                      fontSize: 10,
                      color: '#fff',
                      backgroundColor: '#9C27B0',
                    }}
                  />
                ))}
              </Grid>
            </Tooltip>
          ),
        }))
      : [];

  const badges = () => {
    const mainBadges = Object.entries(userQuery).filter(
      ([k, v]) => !IGNORE_FILTERS.includes(k) && v !== undefined && (Array.isArray(v) ? v.length !== 0 : true),
    ) as [QueryKeys, any][];
    const teacherBadges = Object.entries(userQuery.teacherQuery).filter(
      ([k, v]) => !IGNORE_TEACHER_FILTERS.includes(k) && v !== undefined && v !== '' && (Array.isArray(v) ? v.length !== 0 : true),
    ) as [QueryKeys, any][];
    mainBadges.push(...teacherBadges);
    return mainBadges;
  };

  const badgesWithMultiSelectors = badges().reduce((acc: any[], badge) => {
    if (Array.isArray(badge[1])) return [...acc, ...badge[1].map((val: any) => [badge[0], val])];
    return [...acc, badge];
  }, []);

  const handlePageChange = (_event: unknown, page: number) => {
    setUserQuery((current) => ({ ...current, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setUserQuery((current) => ({ ...current, pageSize: pageSize }));
  };

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchText(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (searchText) => {
    setUserQuery((current) => ({ ...current, searchText, page: 1 }));
  };

  const searchFieldOnChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUserQuery((current) => ({
      ...current,
      teacherQuery: { ...current.teacherQuery, searchFieldSelection: value as TeacherSearchFieldOptions },
    }));
  };

  return (
    <Card sx={{ padding: '24px' }}>
      <Stack sx={{ mb: 3 }}>
        <Typography variant="titleLarge">Teachers</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <Box width={'250px'}>
          <FormControl fullWidth variant={'outlined'} size={'medium'}>
            <InputLabel id={'fieldSearchField'}>Search in</InputLabel>
            <Select
              labelId={'fieldSearchField'}
              label={'Search in'}
              value={userQuery.teacherQuery.searchFieldSelection as any}
              onChange={searchFieldOnChange}
            >
              {searchFieldOptions.map((x) => (
                <MenuItem key={`fieldSearchField-${x.value}`} value={x.value}>
                  {x.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <SearchTextField
          searchInput={searchText}
          onSearchInputChanged={onSearchInputChanged}
          onSearchInputDebounced={onSearchInputDebounced}
          autoFocus
        />

        <Tooltip title={'Filter Results'}>
          <IconButton size="large" onClick={() => setShowFilters(true)} color="primary">
            <Badge color="primary">
              <FilterAltOutlined sx={{ cursor: 'pointer' }} />
            </Badge>
          </IconButton>
        </Tooltip>
      </Stack>

      <Box sx={{ marginY: '20px' }}>
        <FilterChips badgesWithMultiSelectors={badgesWithMultiSelectors} userQuery={userQuery} setUserQuery={setUserQuery} />
      </Box>

      <Stack sx={{ marginTop: '32px' }}>
        <Table
          isPaginated
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          headers={headers}
          data={data}
          count={teacherResponseData?.count || 0}
          page={teacherResponseData?.page || 1}
          rowsPerPage={userQuery.pageSize}
          isLoading={isLoadingTeacherSearch}
        />
      </Stack>

      {showFilters ? (
        <TeacherFiltersDrawer showDrawer={showFilters} setShowDrawer={setShowFilters} userQuery={userQuery} setUserQuery={setUserQuery} />
      ) : null}
    </Card>
  );
};

const AddTeacherAction = () => {
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate(routes.users.teachers.create.url)} style={{ height: 36 }} variant="contained" color="primary">
      <AddIcon fontSize="small" style={{ marginRight: 5 }} /> Add Teacher
    </Button>
  );
};
export default TeachersTable;
