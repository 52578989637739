import { Button, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Typography, styled } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Link, createSearchParams, useSearchParams } from 'react-router-dom';
import StatusChip, { StatusChipIcon, StatusChipIconColor } from '@hoot/components/ui/StatusChip';
import {
  InvoicePeriodDetailsResponse,
  SearchTeacherInvoiceBillableTasksQuery,
  TeacherInvoiceBillableTasksQuerySortKeyEnum,
  TeacherInvoiceBillableTasksResponse,
} from '@hoot/hooks/api/teacher-invoice/useSearchTeacherInvoiceBillableTasks';
import { InvoiceLineItemStatus } from '@hoot/pages/teacher-invoices/enums/invoice-line-item-status.enum';
import { TeacherInvoiceBillableTasksPageQueryParams } from '@hoot/pages/teacher-invoices/interfaces/TeacherInvoiceBillableTasksPageQueryFilterParams';
import TeacherInvoiceTaskDetailsModal from '@hoot/pages/teacher-invoices/modals/TeacherInvoiceTaskDetailsModal';
import { capitalizeFirstLetter } from '@hoot/utils/stringUtils';
import { SortOrder, TableHeaderCell, muiTableSortDirectionLookup } from '../../interfaces/order-by';
import { GenericPaginatedResponse } from '../../interfaces/pagination';
import { routes } from '../../routes/routes';

const TeacherPageLink = styled(Link)({
  textDecoration: 'none',
  color: '#1976D2',
});

interface TeacherInvoiceBillableTasksTableProps {
  query: SearchTeacherInvoiceBillableTasksQuery;
  teacherInvoiceBillableTasksPaginatedResponse: GenericPaginatedResponse<TeacherInvoiceBillableTasksResponse, InvoicePeriodDetailsResponse>;
}

const tableHeaderCells: TableHeaderCell<TeacherInvoiceBillableTasksQuerySortKeyEnum>[] = [
  { label: 'Teacher', sortKey: TeacherInvoiceBillableTasksQuerySortKeyEnum.Teacher },
  { label: 'Project', sortKey: TeacherInvoiceBillableTasksQuerySortKeyEnum.Project },
  { label: 'QTY/Duration', sortKey: TeacherInvoiceBillableTasksQuerySortKeyEnum.QuantityDuration },
  { label: 'Date', sortKey: TeacherInvoiceBillableTasksQuerySortKeyEnum.Date },
  { label: 'Amount', sortKey: TeacherInvoiceBillableTasksQuerySortKeyEnum.Amount },
  { label: 'Status', sortKey: TeacherInvoiceBillableTasksQuerySortKeyEnum.Status },
  { label: 'Action', sortKey: TeacherInvoiceBillableTasksQuerySortKeyEnum.Action },
];

const billableTaskStatusColorLookUp: Record<InvoiceLineItemStatus, StatusChipIconColor> = {
  [InvoiceLineItemStatus.Pending]: StatusChipIconColor.Blue,
  [InvoiceLineItemStatus.Rejected]: StatusChipIconColor.Red,
  [InvoiceLineItemStatus.Approved]: StatusChipIconColor.Green,
};

const TeacherInvoiceBillableTasksTable = (props: TeacherInvoiceBillableTasksTableProps) => {
  const {
    query,
    teacherInvoiceBillableTasksPaginatedResponse: { data, extraData },
  } = props;
  const { orderBy, sortDirection } = query;
  const [, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [rowData, setRowData] = useState<TeacherInvoiceBillableTasksResponse>();

  // If we've toggling the same order-by key, then just flip the sort direction.
  // Else, we're sorting by a different column, and we can default the sort to 'asc'.
  const toggleOrderBy = (newOrderBy: string) => () => {
    const isAsc = orderBy === newOrderBy && sortDirection === SortOrder.ASC;
    setSearchParams(
      (prev) => {
        const newSearchParams = createSearchParams(prev);
        newSearchParams.set(TeacherInvoiceBillableTasksPageQueryParams.OrderBy, newOrderBy);
        newSearchParams.set(TeacherInvoiceBillableTasksPageQueryParams.SortDirection, isAsc ? SortOrder.DESC : SortOrder.ASC);
        return newSearchParams;
      },
      { replace: true },
    );
  };

  const openModal = (row: TeacherInvoiceBillableTasksResponse) => {
    setRowData(row);
    setShowModal(true);
  };

  const closeModal = () => {
    setRowData(undefined);
    setShowModal(false);
  };

  return (
    <>
      <Table sx={{ minWidth: 650 }} aria-label="billable tasks table">
        <TableHead>
          <TableRow>
            {tableHeaderCells.map((cell) => (
              <TableCell
                variant="head"
                sx={{ width: cell.cellWidth }}
                key={`headerCell-${cell.label}`}
                sortDirection={
                  cell.sortKey ? (orderBy === cell.sortKey ? muiTableSortDirectionLookup[sortDirection ?? SortOrder.ASC] : false) : undefined
                }
              >
                {cell.sortKey ? (
                  <TableSortLabel
                    active={orderBy === cell.sortKey}
                    direction={orderBy === cell.sortKey ? muiTableSortDirectionLookup[sortDirection ?? SortOrder.ASC] : undefined}
                    onClick={toggleOrderBy(cell.sortKey)}
                  >
                    {cell.label}
                  </TableSortLabel>
                ) : (
                  cell.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '& > td': { verticalAlign: 'top' },
              }}
            >
              <TableCell>
                <TeacherPageLink to={routes.users.teachers.details.url(row.teacherId ?? '')}>{row.teacherName}</TeacherPageLink>
                <Typography sx={{ fontSize: '14px' }}>{row.teacherEmail}</Typography>
              </TableCell>
              <TableCell>{row.project}</TableCell>
              <TableCell>{row.quantityDuration}</TableCell>
              <TableCell>{DateTime.fromISO(row.date).toFormat('MM/dd/yyyy')}</TableCell>
              <TableCell>
                {row.total.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </TableCell>
              <TableCell>
                <StatusChip
                  key={`billable-task-status-${row.status}`}
                  size="small"
                  statusIcon={StatusChipIcon.FullCircle}
                  iconColor={billableTaskStatusColorLookUp[row.status as InvoiceLineItemStatus]}
                  label={capitalizeFirstLetter(row.status)}
                />
              </TableCell>
              <TableCell>
                <Button type="button" color="primary" variant="contained" size="large" onClick={() => openModal(row)}>
                  {row.action ? 'View' : 'Review'}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {rowData && extraData ? (
        <TeacherInvoiceTaskDetailsModal open={showModal} onCancel={closeModal} task={rowData} invoicePeriod={extraData} />
      ) : null}
    </>
  );
};

export default TeacherInvoiceBillableTasksTable;
