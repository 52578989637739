import { Close as CloseIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useAlert } from '@hoot/contexts/AlertContext';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { ResourceProgress, resourceProgressOptions } from '@hoot/hooks/api/user/student/instructional-plan/enums';
import { StudentResourceFavouritesResponse } from '@hoot/hooks/api/user/student/instructional-plan/useGetStudentResourcesLessonPlan';
import useUpdateLessonPlanBooks from '@hoot/hooks/api/user/student/useUpdateLessonPlanBooks';

interface FormBook {
  bookId: string;
  bookTitle: string;
  resourceProgress: ResourceProgress;
}

interface Form {
  books: FormBook[];
}

export function EditBooksDialog(props: { studentId: string; onClose: () => void; books: StudentResourceFavouritesResponse[]; selected: string[] }) {
  const { onClose, books, selected } = props;
  const selectedBooks: FormBook[] = books
    .filter((book) => selected.includes(book.bookId))
    .map((book) => ({
      bookId: book.bookId,
      bookTitle: book.bookTitle,
      resourceProgress: book.resourceProgress,
    }));

  const { handleSubmit, control, formState, setValue } = useForm<Form>({
    defaultValues: {
      books: selectedBooks,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'books',
  });

  const updateLessonPlanBooksMutation = useUpdateLessonPlanBooks(props.studentId);
  const queryClient = useQueryClient();
  const alerts = useAlert();

  const handleOnClose = () => {
    onClose();
  };

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value !== ResourceProgress.NotStarted) {
      setValue(event.target.name as any, event.target.value, { shouldDirty: true });
    }
  };

  const handleOnApply = (data: Form) => {
    updateLessonPlanBooksMutation.mutate(
      {
        books: data.books.map((book) => ({
          bookId: book.bookId,
          resourceProgress: book.resourceProgress,
        })),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKey.GetStudentInstructionalLessonPlan]);
          alerts.success(`Successfully updated ${selected.length} resource(s) from student's lesson plan`);
          onClose();
        },
        onError: (err) => {
          const errorMessage = (err.response as any)?.data.message ?? `An unknown error occurred while updating resource(s) from lesson plan.`;
          alerts.error(errorMessage);
        },
      },
    );
  };

  return (
    <form>
      <Dialog open={true} onClose={handleOnClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="headlineSmall">Edit Resource Progress?</Typography>
          <IconButton onClick={handleOnClose} sx={{ color: '#1C1B1F' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {fields?.map((book, index) => (
              <Grid item container justifyContent="space-between" key={book.bookId} spacing={2} alignItems="center">
                <Grid item xs={7}>
                  <Typography variant="titleMedium">{book.bookTitle}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Controller
                    name={`books.${index}.resourceProgress`}
                    control={control}
                    defaultValue={book.resourceProgress}
                    render={({ field }) => (
                      <Select {...field} onChange={handleChange} fullWidth>
                        {resourceProgressOptions
                          .filter((option) =>
                            book.resourceProgress !== ResourceProgress.NotStarted ? option.value !== ResourceProgress.NotStarted : true,
                          )
                          .map((option, idx) => (
                            <MenuItem key={idx} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
          <Button size="large" variant="outlined" color="primary" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(handleOnApply)} size="large" variant="contained" type="button" disabled={!formState.isDirty}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
