import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { ProductProgram } from '@hoot-reading/hoot-core/dist/enums/hfs/product-program.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { BackgroundCheck } from '@hoot/interfaces/teacher';
import { QueryKey } from '../../../queryKeys';

export interface ProductLocation {
  id: string;
  type: LocationType;
  isBillableEntity: boolean;
  name: string;
  friendlyId: string;
}

export interface ProductDetailsResponse {
  name: string;
  id: string;
  friendlyId: string;
  status: ProductStatus;
  qualificationRequirements: HootQualification[];
  program: ProductProgram;
  startDate: number;
  endDate: number;
  numberOfEnrolments: number;
  numberOfStudents: number;
  openEnrolments: number;
  inProgressEnrolments: number;
  scheduledEnrolments: number;
  closedEnrolments: number;
  lastUpdatedAt: number;
  location: ProductLocation;
  countryRequirements: HootSupportedCountry | undefined;
  numberOfLessons: number;
  numberOfLessonsPerSet: number;
  numberOfAllowedLessonsSets: number;
  numberOfRegisteredLessonsSets: number;
  numberOfScheduledLessonsSets: number;
  numberOfScheduledLessons: number;
  numberOfOccurredLessons: number;
  backgroundCheckRequirements: BackgroundCheck[];
}

const getProduct = async (productId: string): Promise<ProductDetailsResponse> => {
  const { data } = await axios.get<ProductDetailsResponse>(`${config.apiUrl}/admin/hfs/product/${productId}`);
  return data;
};

export default function useGetProduct(
  productId: string,
  options?: Omit<UseQueryOptions<ProductDetailsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetProduct, productId], () => getProduct(productId), options);
}
