import { ProductProgram } from '@hoot-reading/hoot-core/dist/enums/hfs/product-program.enum';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface CreateProductRequest {
  name: string;
  program: ProductProgram;
  startDate: number;
  endDate: number;
  locationId: string;
  cycle: number;
  numberOfStudents: number;
}

const createProduct = async (request: CreateProductRequest): Promise<string> => {
  const { data } = await axios.post<string>(`${config.apiUrl}/admin/hfs/product`, request);
  return data;
};

const useCreateProduct = (options?: Omit<UseMutationOptions<string, AxiosError, CreateProductRequest>, 'mutationFn' | 'mutationKey'>) => {
  return useMutation<string, AxiosError, CreateProductRequest>((request) => createProduct(request), options);
};

export default useCreateProduct;
