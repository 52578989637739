import { Autocomplete, TextField } from '@mui/material';
import { FormOption } from '@hoot/interfaces/form';

interface Props<T extends string | number> {
  onChange: (val: T[]) => void;
  allData: FormOption<T>[];
  displayData: FormOption<T>[];
  label?: string;
  disabled?: boolean;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  size?: 'medium' | 'small';
  required?: string;
  onInputChange: (event: any, val: any) => void;
}

const SimpleMultiSelect = <T extends string | number>(props: Props<T>) => {
  const { onChange, displayData, label, disabled, variant, size, required, onInputChange, allData } = props;

  const idToName = allData.reduce((acc: Record<string | number, string>, val) => {
    acc[val.value] = val.label;
    return acc;
  }, {});

  return (
    <Autocomplete
      onChange={(_event, selectedItems) => {
        // Newly selected items in 'selectedItems' from Autocomplete will always be string values
        // which can cause issues if the original options data are enums with numeric values.
        // We also need to consider/handle the case where the control is populated based on previous selections.
        // In this scenario, selected items can be a mix of strings (new selections) and numbers (old selections
        // loaded on render).
        // Do a string based comparison of the selectedItems and source data values, but return the selected values
        // in its original type
        const selectedStrVals = selectedItems.map((si) => si.toString());
        const selectedData = allData
          .filter((x) => selectedStrVals.includes(x.value.toString()))
          .map((x) => x.value)
          .sort();
        onChange(selectedData);
      }}
      disabled={disabled}
      multiple
      size={size}
      options={displayData.map((record) => record.value.toString())}
      getOptionLabel={(optionId: string) => {
        if (optionId) return idToName[optionId];
        else return '';
      }}
      onInputChange={(event, val) => onInputChange(event, val)}
      isOptionEqualToValue={(optionId, valueId) => optionId === undefined || valueId === '' || optionId === valueId}
      renderInput={(params) => (
        <TextField {...params} variant={variant} label={required && label ? `${label}*` : `${label ? `${label}` : ''}`} fullWidth />
      )}
      fullWidth
    />
  );
};

export default SimpleMultiSelect;
