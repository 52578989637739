import { PeriodBasedValues, TimelineFilterType, TimelineViewOption } from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@hoot/components/form/DatePicker';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { StudentTimelineRequest } from '@hoot/hooks/api/user/student/timeline/getStudentTimelineVis';
import { FormOption } from '@hoot/interfaces/form';

const filterTypeOptions: FormOption<TimelineFilterType>[] = [
  { label: 'Date Based', value: TimelineFilterType.DateBased },
  { label: 'Period Based', value: TimelineFilterType.PeriodBased },
];

const periodBasedOptions: FormOption<PeriodBasedValues>[] = [
  { label: 'Entire Current Enrolment', value: PeriodBasedValues.EntireEnrolment },
  { label: 'All Time Lesson History', value: PeriodBasedValues.AllTimeLessonHistory },
  { label: 'Most Recent HRA until next HRA', value: PeriodBasedValues.MostRecentUntilNextHRA },
  { label: 'Most Recent HRA until next HRA or PMA', value: PeriodBasedValues.MostRecentUntilNextHRAOrPMA },
];

export interface TimelineFilterFormValues {
  filterType: string;
  viewOption: string | null;
  startDate: number | null;
  endDate: number | null;
}

const defaultValues: TimelineFilterFormValues = {
  filterType: TimelineFilterType.DateBased,
  viewOption: null,
  startDate: null,
  endDate: null,
};

interface TimelineFilterDialogProps {
  open: boolean;
  query: StudentTimelineRequest;
  setQuery: React.Dispatch<React.SetStateAction<StudentTimelineRequest>>;
  setTlFilterOptions: React.Dispatch<React.SetStateAction<TimelineFilterFormValues>>;
  onDismiss: () => void;
}

const TimelineFilterDialog = (props: TimelineFilterDialogProps) => {
  const { open, query, setQuery, onDismiss, setTlFilterOptions } = props;

  const { handleSubmit, control, watch, clearErrors, reset } = useForm<TimelineFilterFormValues>({
    defaultValues: defaultValues,
  });

  const handleClose = () => {
    onDismiss();
    reset();
    clearErrors();
  };

  const handleApply = (data: TimelineFilterFormValues) => {
    setTlFilterOptions(data);
    setQuery({
      ...query,
      filterType: data.filterType as TimelineFilterType,
      viewOption: (data.viewOption as TimelineViewOption) ?? TimelineViewOption.None,
      fromDate: data.startDate ?? undefined,
      toDate: data.endDate ?? undefined,
    });
    handleClose();
  };

  const isPeriodBasedSelected = watch('filterType') === TimelineFilterType.PeriodBased;

  const minStartDate = DateTime.fromObject({ year: 2023, month: 1, day: 1 }); // January 1, 2023.
  const maxEndDate = DateTime.now().plus({ years: 1 }); // 1 Year from now.

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'xs'}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Timeline Filter
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack paddingTop={1} gap={2}>
          <Typography variant="titleMedium">Filter Type</Typography>
          <Dropdown
            name="filterType"
            label="Filter Type"
            options={filterTypeOptions}
            control={control}
            rules={{ required: true }}
            variant="outlined"
          />
          <Typography variant="titleMedium">View Option</Typography>
          {isPeriodBasedSelected ? (
            <>
              <Dropdown
                name="viewOption"
                label="View Option"
                options={periodBasedOptions}
                control={control}
                rules={{ required: true }}
                variant="outlined"
              />
            </>
          ) : (
            <>
              <Stack direction="row" gap={1}>
                <DatePicker
                  label="From Date"
                  name="startDate"
                  control={control}
                  rules={{
                    required: 'From Date is required',
                  }}
                  minDate={minStartDate}
                  treatDateAsNumber
                />
                <DatePicker
                  label="To Date"
                  name="endDate"
                  control={control}
                  rules={{
                    required: 'To Date is required',
                  }}
                  maxDate={maxEndDate}
                  treatDateAsNumber
                />
              </Stack>
            </>
          )}
        </Stack>
      </DialogContent>
      <Divider sx={{ marginLeft: 3, marginRight: 3 }} />
      <DialogActions>
        <Stack direction="row" gap={1}>
          <Button onClick={handleClose} variant="outlined" size="large">
            Cancel
          </Button>
          <Button onClick={handleSubmit(handleApply)} variant="contained" size="large">
            Apply
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default TimelineFilterDialog;
