import { TeacherShiftCancellationReason, TeacherShiftStatus } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import { Close } from '@mui/icons-material';
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import SelectTeacher from '../../../../../components/modals/SelectTeacher';
import { useAlert } from '../../../../../contexts/AlertContext';
import { QueryKey } from '../../../../../hooks/api/queryKeys';
import useRescheduleShifts from '../../../../../hooks/api/shifts/useRescheduleShifts';
import useValidateRescheduleShifts from '../../../../../hooks/api/shifts/useValidateRescheduleShifts';
import useGetTeacherAccount, { TeacherAccount } from '../../../../../hooks/api/user/teacher/useGetTeacherAccount';
import { ShiftConflictSeverity } from '../../../../../hooks/api/user/teacher/useGetTeacherShiftConflicts';
import { ShiftConflictMessage } from '../../../../lessons/create-v2/common/ConflictMessage';

interface UpdatedShifts {
  shifts: UpdatedShift[];
}

interface UpdatedShift {
  id: string;
  startsAt: DateTime;
  endsAt: DateTime;
  skip: boolean;
}

export interface TentativeShiftForm {
  id: string;
  startsAt: DateTime;
  endsAt: DateTime;
  skip: boolean;
  conflict?: {
    severity: ShiftConflictSeverity;
    message: string;
  };
}

interface Form {
  tentativeShifts: TentativeShiftForm[];
}

enum RescheduleShiftStep {
  Conflicts = 0,
  Summary = 1,
}

interface RescheduleWizardRequestedChange {
  status: TeacherShiftStatus;
  cancellationReason?: TeacherShiftCancellationReason;
  originalStartsAt: DateTime;
  originalEndsAt: DateTime;
  updatedStartsAt: DateTime;
  updatedEndAt: DateTime;
}

/** RescheduleShiftsDialog */
export function RescheduleShiftsDialog(props: {
  shiftId: string;
  teacherAccountId: string;
  open: boolean;
  rescheduledChange: RescheduleWizardRequestedChange;
  onDismiss: () => void;
  onApply: () => void;
  showWarning?: boolean;
}) {
  const [step, setStep] = useState(RescheduleShiftStep.Conflicts);
  const [data, setData] = useState<UpdatedShifts>({
    shifts: [],
  });

  const handleStepChange = (updatedStep: RescheduleShiftStep) => {
    setStep(updatedStep);
  };

  const handleUpdate = (update: Partial<UpdatedShifts>) => {
    setData((currentState) => ({ ...currentState, ...update }));
  };

  return (
    <Dialog maxWidth="md" fullWidth open={props.open} onClose={props.onDismiss}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="headlineSmall">Reschedule Shift Wizard</Typography>
          <IconButton onClick={props.onDismiss}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Content
        shiftId={props.shiftId}
        teacherAccountId={props.teacherAccountId}
        rescheduledChange={props.rescheduledChange}
        onStepChange={handleStepChange}
        onDismiss={props.onDismiss}
        step={step}
        onUpdate={handleUpdate}
        value={data}
        showWarning={props.showWarning}
      />
    </Dialog>
  );
}

/** WizardStep */
function WizardStep(props: { activeStep: number }) {
  return (
    <Stepper sx={{ marginTop: 6 }} activeStep={props.activeStep} alternativeLabel>
      <Step key={RescheduleShiftStep.Conflicts}>
        <StepLabel>Conflicts</StepLabel>
      </Step>

      <Step key={RescheduleShiftStep.Conflicts}>
        <StepLabel>Summary</StepLabel>
      </Step>
    </Stepper>
  );
}

/** Content */
function Content(props: {
  shiftId: string;
  teacherAccountId: string;
  rescheduledChange: RescheduleWizardRequestedChange;
  step: RescheduleShiftStep;
  onStepChange: (updatedStep: RescheduleShiftStep) => void;
  onDismiss: () => void;
  onUpdate: (update: Partial<UpdatedShifts>) => void;
  value: UpdatedShifts;
  showWarning?: boolean;
}) {
  const teacherAccountRequest = useGetTeacherAccount(props.teacherAccountId);

  if (!teacherAccountRequest.isFetched || !teacherAccountRequest.data) {
    return <CircularProgress />;
  }

  switch (props.step) {
    case RescheduleShiftStep.Conflicts:
      return (
        <ConflictStep
          shiftId={props.shiftId}
          step={props.step}
          onStepChange={props.onStepChange}
          onDismiss={props.onDismiss}
          teacherAccount={teacherAccountRequest.data}
          rescheduledChange={props.rescheduledChange}
          onUpdate={props.onUpdate}
          value={props.value}
          showWarning={props.showWarning}
        />
      );
    case RescheduleShiftStep.Summary:
      return (
        <SummaryStep
          shiftId={props.shiftId}
          onDismiss={props.onDismiss}
          onStepChange={props.onStepChange}
          step={props.step}
          teacherAccount={teacherAccountRequest.data}
          rescheduledChange={props.rescheduledChange}
          value={props.value}
          showWarning={props.showWarning}
        />
      );
  }
}

/** ConflictStep */
function ConflictStep(props: {
  step: RescheduleShiftStep;
  shiftId: string;
  teacherAccount: TeacherAccount;
  rescheduledChange: RescheduleWizardRequestedChange;
  onStepChange: (updatedStep: RescheduleShiftStep) => void;
  onDismiss: () => void;
  onUpdate: (update: Partial<UpdatedShifts>) => void;
  value: UpdatedShifts;
  showWarning?: boolean;
}) {
  const isLoaded = useRef(false);

  const alert = useAlert();

  const { control, reset, handleSubmit } = useForm<Form>({
    defaultValues: {
      tentativeShifts: [],
    },
  });

  const rescheduleFutureShiftsRequest = useValidateRescheduleShifts(props.shiftId, {
    onSuccess: (data) => {
      const tentativeShifts = data.proposedShifts.map<TentativeShiftForm>((l) => {
        const updatedShift = props.value.shifts.find((s) => s.id === l.shiftId);

        return {
          id: l.shiftId,
          startsAt: DateTime.fromMillis(l.proposedStartsAt),
          endsAt: DateTime.fromMillis(l.proposedEndsAt),
          conflict:
            l.conflictMessage && l.conflictSeverity
              ? {
                  message: l.conflictMessage,
                  severity: l.conflictSeverity,
                }
              : undefined,
          skip: updatedShift?.skip ?? l.conflictSeverity === ShiftConflictSeverity.Blocker,
        };
      });
      reset({
        tentativeShifts: tentativeShifts,
      });
    },
    onError: (error) => {
      alert.error(error.response?.data?.message ?? 'Reschedule request did not pass validation.');
    },
  });

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      rescheduleFutureShiftsRequest.mutate({
        updatedShiftStartsAt: props.rescheduledChange.updatedStartsAt.toMillis(),
        updatedShiftEndsAt: props.rescheduledChange.updatedEndAt.toMillis(),
      });
    }
  }, [rescheduleFutureShiftsRequest, props.rescheduledChange]);

  const onSubmit: SubmitHandler<Form> = (data) => {
    const dataToUpdate: UpdatedShifts = {
      shifts: data.tentativeShifts.map((s) => ({
        id: s.id,
        startsAt: s.startsAt,
        endsAt: s.endsAt,
        skip: s.skip,
      })),
    };

    props.onUpdate(dataToUpdate);
    props.onStepChange(RescheduleShiftStep.Summary);
  };

  return (
    <DialogForm onSubmit={handleSubmit(onSubmit)} onDismiss={props.onDismiss} step={props.step}>
      {props.showWarning ? <Warning rescheduledChange={props.rescheduledChange} /> : null}

      <Typography variant="titleMedium">Step 1 - Teacher Conflicts</Typography>
      <SelectTeacher
        disabled
        value={{
          id: props.teacherAccount.id,
          name: props.teacherAccount.displayName,
          number: props.teacherAccount.number.toString(),
        }}
        periodIsLesson={false}
        showHasConflictFilter={false}
      />

      <Typography marginTop="16px">Select from these available dates</Typography>
      <Controller
        control={control}
        name="tentativeShifts"
        render={({ field }) => (
          <TentativeShifts
            isCancelling={props.rescheduledChange.status === TeacherShiftStatus.Cancelled}
            isLoading={false}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
    </DialogForm>
  );
}

/** TentativeShifts */
function TentativeShifts(props: {
  isLoading?: boolean;
  value: TentativeShiftForm[];
  onChange: (value: TentativeShiftForm[]) => void;
  isCancelling: boolean;
}) {
  if (props.isLoading) {
    return <CircularProgress />;
  }

  const handleClick = (shift: TentativeShiftForm) => {
    const updatedState = props.value.map<TentativeShiftForm>((v) =>
      shift.startsAt === v.startsAt
        ? {
            ...v,
            skip: !shift.skip,
          }
        : v,
    );
    props.onChange(updatedState);
  };

  return (
    <Stack marginTop="16px">
      <Stack sx={{ marginTop: '8px' }} direction="row">
        <Typography sx={{ width: '49px' }} variant="bodySmall">
          {props.isCancelling ? 'Cancel Shift' : 'Update Shift'}
        </Typography>
        <Typography sx={{ marginLeft: '40px', width: '43px' }} variant="bodySmall">
          Skip Shift
        </Typography>
      </Stack>
      <FormGroup sx={{ marginTop: '8px' }}>
        {props.value.map((l) => (
          <TentativeShiftField key={l.startsAt.toMillis()} value={l} onClick={handleClick} />
        ))}
      </FormGroup>
    </Stack>
  );
}

/** TentativeShiftField */
function TentativeShiftField(props: { value: TentativeShiftForm; onClick: (value: TentativeShiftForm) => void }) {
  const canAssignTeacher = props.value.conflict?.severity !== 'BLOCKER';
  const handleClick = () => {
    props.onClick(props.value);
  };

  return (
    <Stack direction="row">
      <FormControlLabel
        disabled={!canAssignTeacher}
        control={
          <Stack direction="row">
            <Checkbox sx={{ marginLeft: '6px' }} disabled={!canAssignTeacher} onClick={handleClick} checked={!props.value.skip} />
            <Checkbox sx={{ marginLeft: '45px' }} disabled={!canAssignTeacher} onClick={handleClick} checked={props.value.skip} />
          </Stack>
        }
        label={
          <Typography variant="bodyLarge" sx={{ marginLeft: '42px' }}>
            {props.value.startsAt.toFormat('ccc, LLL d, yyyy @ h:mma')} - {props.value.endsAt.toFormat('h:mma')}
          </Typography>
        }
      />
      {props.value.conflict ? <ShiftConflictMessage severity={props.value.conflict.severity} message={props.value.conflict.message} /> : null}
    </Stack>
  );
}

/** Summary Step */
function SummaryStep(props: {
  step: RescheduleShiftStep;
  teacherAccount: TeacherAccount;
  shiftId: string;
  onStepChange: (updatedStep: RescheduleShiftStep) => void;
  onDismiss: () => void;
  rescheduledChange: RescheduleWizardRequestedChange;
  value: UpdatedShifts;
  showWarning?: boolean;
}) {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const rescheduleShiftsMutation = useRescheduleShifts({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.GetShiftDetails);
      queryClient.invalidateQueries(QueryKey.GetTeacherShift);
      queryClient.invalidateQueries(QueryKey.GetTeacherShifts);
      queryClient.invalidateQueries(QueryKey.GetTeacherShiftLessons);
      alert.success('Shifts have been successfully updated');
      props.onDismiss();
    },
    onError: (error) => {
      alert.error(error.response?.data?.message ?? 'There was an issue updating shifts');
    },
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    rescheduleShiftsMutation.mutate({
      status: props.rescheduledChange.status,
      cancellationReason: props.rescheduledChange.cancellationReason,
      shifts: props.value.shifts
        .filter((s) => !s.skip)
        .map((s) => ({
          shiftId: s.id,
          startsAt: s.startsAt.toMillis(),
          endsAt: s.endsAt.toMillis(),
        })),
    });
  };

  const handlePreviousStep = () => {
    props.onStepChange(RescheduleShiftStep.Conflicts);
  };

  return (
    <DialogForm onSubmit={handleSubmit} onDismiss={props.onDismiss} onPreviousStep={handlePreviousStep} step={props.step}>
      {props.showWarning ? <Warning rescheduledChange={props.rescheduledChange} /> : null}

      <Typography variant="titleMedium">Step 2 - Summary</Typography>

      <SelectTeacher
        disabled
        value={{
          id: props.teacherAccount.id,
          name: props.teacherAccount.displayName,
          number: props.teacherAccount.number.toString(),
        }}
        periodIsLesson={false}
        showHasConflictFilter={false}
      />
      <Stack marginTop="16px">
        <Typography marginTop="16px" variant="titleMedium">
          Shifts that will be {props.rescheduledChange.status === TeacherShiftStatus.Cancelled ? 'Cancelled' : 'Updated'}
        </Typography>

        <Stack marginTop="16px">
          {props.value.shifts
            .filter((s) => !s.skip)
            .map((s, idx) => (
              <FormControlLabel
                key={`${s.id}-${idx}`}
                disabled
                control={
                  <Stack direction="row">
                    <Checkbox sx={{ marginLeft: '24px' }} disabled checked />
                  </Stack>
                }
                label={
                  <Typography variant="bodyLarge" sx={{ marginLeft: '42px' }}>
                    {s.startsAt.toFormat('ccc, LLL d, yyyy @ h:mma')} - {s.endsAt.toFormat('h:mma')}
                  </Typography>
                }
              />
            ))}
        </Stack>
      </Stack>
    </DialogForm>
  );
}

function Warning(props: { rescheduledChange: RescheduleWizardRequestedChange }) {
  if (
    props.rescheduledChange.originalStartsAt === props.rescheduledChange.updatedStartsAt &&
    props.rescheduledChange.originalEndsAt === props.rescheduledChange.updatedEndAt
  ) {
    return (
      <Stack marginY="16px">
        <Alert severity="warning">Only the statuses of the shifts will be updated</Alert>
      </Stack>
    );
  } else if (props.rescheduledChange.status === TeacherShiftStatus.Cancelled)
    return (
      <Stack marginY="16px">
        <Alert severity="warning">
          Note, only the status change will take effect. The data/time will not be updated. To update the date/time, do so separately.
        </Alert>
      </Stack>
    );
  else {
    return null;
  }
}

/** DialogForm */
function DialogForm(props: {
  step: RescheduleShiftStep;
  onDismiss: () => void;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  onPreviousStep?: () => void;
  children: React.ReactNode;
}) {
  function submitButtonText() {
    if (props.step === RescheduleShiftStep.Conflicts) {
      return 'Next Step';
    } else if (props.step === RescheduleShiftStep.Summary) {
      return 'Complete';
    }
  }

  return (
    <form onSubmit={props.onSubmit}>
      <DialogContent>
        <WizardStep activeStep={props.step} />
        {props.children}
      </DialogContent>
      <DialogActions sx={{ p: 3, position: 'relative' }}>
        <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
        <Stack flex={1} direction="row" justifyContent="space-between">
          {props.onPreviousStep ? (
            <Button size="large" variant="outlined" color="primary" onClick={props.onPreviousStep}>
              Previous Step
            </Button>
          ) : null}

          <Stack flex={1} justifyContent="flex-end" direction="row" spacing="16px">
            <Button size="large" variant="outlined" color="primary" onClick={props.onDismiss}>
              Cancel
            </Button>

            <Button size="large" variant="contained" type="submit">
              {submitButtonText()}
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </form>
  );
}
