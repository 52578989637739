import React, { useState } from 'react';
import { TeacherAccount } from '../../hooks/api/user/teacher/useSearchTeacherAccounts';

interface Props {
  children: React.ReactNode;
}

interface Values {
  previousTeacherAccountFilter?: TeacherAccount;
  setPreviousTeacherAccountFilter: React.Dispatch<React.SetStateAction<TeacherAccount | undefined>>;
}

const LessonsContext = React.createContext<Values>(undefined!);

const LessonsProvider = (props: Props) => {
  const { children } = props;

  const [previousTeacherAccountFilter, setPreviousTeacherAccountFilter] = useState<TeacherAccount>();

  return (
    <LessonsContext.Provider
      value={{
        previousTeacherAccountFilter,
        setPreviousTeacherAccountFilter,
      }}
    >
      {children}
    </LessonsContext.Provider>
  );
};

const useLessons = () => {
  const context = React.useContext(LessonsContext);

  if (context === undefined) {
    throw new Error('useLessons must be used within a CreateLessonProvider');
  }

  return context;
};

export { LessonsProvider, useLessons };
