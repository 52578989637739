import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { DetailedBook } from '@hoot/hooks/api/library/useGetBook';
import { QueryKey } from '../queryKeys';

export type BookOrderBy = keyof DetailedBook;
export type SortOrder = 'ASC' | 'DESC';

export enum Subject {
  Reading = 'READING',
  Math = 'MATH',
}

export enum Language {
  English = 'EN',
  Spanish = 'ES',
  French = 'FR',
}

export interface QueryLibrary {
  profileId?: string;
  readingLevel?: string;
  subject?: Subject;
  language?: Language;
  publisher?: string;
  category?: string;
  favouritesOnly?: boolean;
  searchText?: string;
  isActive?: boolean;
  orderBy?: BookOrderBy;
  orderSort: SortOrder;
  page: number;
  pageSize: number;
}

export interface LibraryResponse {
  count: number;
  page: number;
  pageSize: number;
  books: DetailedBook[];
}

async function queryLibrary(query: QueryLibrary): Promise<LibraryResponse> {
  const { data } = await axios.post<LibraryResponse>(`${config.apiUrl}/admin/library/search`, query);
  return data;
}

export default function useQueryLibrary(query: QueryLibrary, options?: Omit<UseQueryOptions<LibraryResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.LibraryQuery, query], () => queryLibrary(query), options);
}
