import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { InvoicingImportResponse } from '@hoot/hooks/api/teacher-invoice/useInvoiceImportCsv';
import { InvoicingImportType } from '@hoot/pages/teacher-invoices/enums/invoicing-import-type.enum';

const getImport = async (importId: string, importType: InvoicingImportType): Promise<InvoicingImportResponse> => {
  const { data } = await axios.get<InvoicingImportResponse>(`${config.apiUrl}/admin/invoicing-import/${importId}?&type=${importType}`);
  return data;
};

export default function useGetInvoicingImport(
  importId: string,
  importType: InvoicingImportType,
  options?: Omit<UseQueryOptions<InvoicingImportResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetInvoiceImport, importId], () => getImport(importId, importType), options);
}
