import { TeacherShiftStatus } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface TeacherAccountShiftDetailsResponse {
  id: string;
  number: number;
  startsAt: number;
  endsAt: number;
  status: TeacherShiftStatus;
}

async function getShiftDetails(teacherAccountId: string, shiftId: string): Promise<TeacherAccountShiftDetailsResponse> {
  const { data } = await axios.get<TeacherAccountShiftDetailsResponse>(`${config.apiUrl}/admin/teacher-shift/${teacherAccountId}/shift/${shiftId}`);
  return data;
}

export function useGetShiftDetails(
  teacherAccountId: string,
  shiftId: string,
  options?: Omit<UseQueryOptions<TeacherAccountShiftDetailsResponse>, 'mutationKey' | 'mutationFn'>,
) {
  return useQuery([QueryKey.GetShiftDetails, teacherAccountId, shiftId], () => getShiftDetails(teacherAccountId, shiftId), options);
}
