import { Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { Control } from 'react-hook-form';
import { TextField } from '@hoot/components/form/TextField';
import { SightWordlistSelect } from '@hoot/components/form/selectFields/SightWordListSelect';
import { AdminLessonNoteDetails } from '@hoot/hooks/api/lesson-notes/useGetLessonNoteDetailsById';
import { LessonNoteForm } from './LessonNoteDetailsPage';

interface LessonNoteSightWordListCardProps {
  isLoading: boolean;
  lessonLanguage: AdminLessonNoteDetails['lessonLanguage'];
  control: Control<LessonNoteForm>;
}

const LessonNoteSightWordListCard = (props: LessonNoteSightWordListCardProps) => {
  const { isLoading, lessonLanguage, control } = props;
  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="titleLarge" sx={{ display: 'block', mb: 2 }}>
            Sight Word List
          </Typography>
          {isLoading ? (
            <LoadingContent />
          ) : (
            <>
              <SightWordlistSelect language={lessonLanguage} name="sightWordList" control={control} includeEmpty />
              <TextField sx={{ mt: 3 }} label="Missed Words" multiline minRows={3} fullWidth name="sightWordsMissed" control={control} />
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const LoadingContent = () => (
  <>
    <Skeleton variant="rectangular" height="56px" sx={{ mb: 2 }} />
    <Grid container spacing={1}>
      {[...Array(3)].map((_, index) => (
        <React.Fragment key={`lesson-note-sight-words-card-placeholder-${index}`}>
          <Grid item xs={12}>
            <Skeleton variant="text" />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  </>
);

export default LessonNoteSightWordListCard;
