import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface SubGenreResponse {
  id: string;
  name: string;
}

async function getSubGenres(): Promise<SubGenreResponse[]> {
  const { data } = await axios.get<SubGenreResponse[]>(`${config.apiUrl}/admin/library/sub-genres`);
  return data;
}

export default function useGetSubGenres(options?: Omit<UseQueryOptions<SubGenreResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetSubGenres], () => getSubGenres(), options);
}
