import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';
import { config } from '../../../config';

export interface AddAccountRequest {
  name: string;
}

export interface AddAccountResponse {
  id: string;
  name: string;
  active: string;
}

async function createAccount(payload: AddAccountRequest) {
  const { data } = await axios.post(`${config.apiUrl}/admin/partner/account`, payload);
  return data;
}

export default function useCreateAccount() {
  return useMutation<AddAccountResponse, AxiosError<ErrorResponseDto>, AddAccountRequest>((data: AddAccountRequest) => createAccount(data));
}
