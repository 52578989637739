import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import useSearchSchools, { SchoolSummary, SearchSchoolsQuery } from '@hoot/hooks/api/hfs/useSearchSchools';

const DEFAULT_PAGE_SIZE = 5;
const MAX_PAGE_SIZE = DEFAULT_PAGE_SIZE * 10;

export function SchoolAutoComplete(props: {
  onChange: (value: string | null) => void;
  value: string | null;
  size?: 'medium' | 'small';
  districtId?: string;
  setSelectedSchoolFilter?: React.Dispatch<React.SetStateAction<SchoolSummary | undefined>>;
}) {
  const { onChange, value, size, districtId, setSelectedSchoolFilter } = props;
  const [schools, setSchools] = useState<SchoolSummary[]>([]);
  const [query, setQuery] = useState<SearchSchoolsQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    districtId: districtId,
    searchString: '',
  });

  useEffect(() => {
    setQuery((current) => ({ ...current, districtId: districtId }));
  }, [districtId]);

  const { isLoading } = useSearchSchools(query, {
    keepPreviousData: true,
    onSuccess: (data) => {
      setSchools(data.schools);
    },
  });

  const selectedSchool = schools.find((s) => s.id === value);

  return (
    <Autocomplete
      onChange={(event, val) => {
        if (typeof val === 'object') {
          if (onChange) {
            onChange(val ? (val as SchoolSummary).id : null);
          }
          if (setSelectedSchoolFilter) {
            setSelectedSchoolFilter(val ? (val as SchoolSummary) : undefined);
          }
        }
      }}
      size={size}
      fullWidth
      loading={isLoading}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={schools}
      getOptionLabel={(option) => option.name}
      value={selectedSchool || null}
      filterOptions={(x) => x}
      onInputChange={(event, val) => {
        setQuery((current) => ({ ...current, searchString: val, pageSize: !!val ? MAX_PAGE_SIZE : DEFAULT_PAGE_SIZE }));
      }}
      renderInput={(params) => <TextField {...params} label="School" variant="outlined" />}
    />
  );
}
