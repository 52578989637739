import { PermIdentity } from '@mui/icons-material';
import { InputAdornment, Stack, TextField } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { LessonDetailResponse } from '@hoot/hooks/api/lessons/useGetLesson';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { routes } from '@hoot/routes/routes';
import useRescheduleLesson from '../../../../hooks/api/lessons/useRescheduleLesson';
import { RescheduleWizardState, useRescheduleWizard } from '../RescheduleWizardContextProvider';
import { RescheduleWizardDialogBody } from '../RescheduleWizardModal';

export function RescheduleWizardConfirmStep(props: { lessonDetails: LessonDetailResponse; onClose: () => void }) {
  const { updateWizardState, rescheduledLesson } = useRescheduleWizard();

  const navigate = useNavigate();
  const { error, success } = useAlert();
  const queryClient = useQueryClient();

  const rescheduleLessonMutation = useRescheduleLesson(props.lessonDetails.lessonId, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QueryKey.ScheduledLessons);
      queryClient.invalidateQueries(QueryKey.MonthlyScheduledLessons);
      queryClient.invalidateQueries(QueryKey.Lessons);
      queryClient.invalidateQueries(QueryKey.LiveLessons);
      queryClient.invalidateQueries(QueryKey.UserScheduleLessons);
      success('Your rescheduled lesson has been successfully created.');
      navigate(routes.lessons.details.url(response?.rescheduledLessonId ?? props.lessonDetails.lessonId));
    },
    onError: () => {
      error('There was an issue creating your rescheduled lesson.');
    },
  });

  const handlePrevButtonClick = () => {
    updateWizardState(RescheduleWizardState.TeacherDetails);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    rescheduleLessonMutation.mutate({
      durationInMinutes: rescheduledLesson.duration ?? props.lessonDetails.duration,
      language: props.lessonDetails.language,
      lessonType: rescheduledLesson.lessonType ?? props.lessonDetails.lessonType,
      theme: props.lessonDetails.theme,
      program: props.lessonDetails.program ?? undefined,
      startsAt: rescheduledLesson.lessonAt ?? props.lessonDetails.startsAt,
      subject: props.lessonDetails.subject,
      teacherAccountId: rescheduledLesson.teacher?.id ?? null,
      accountId: props.lessonDetails.account,
      partnerId: props.lessonDetails.partner,
      siteId: props.lessonDetails.site,
      enrolmentId: props.lessonDetails.enrolment?.id,
      isCustomerBillable: rescheduledLesson.isCustomerBillable ?? props.lessonDetails.isCustomerBillable ?? undefined,
    });
  };

  const studentLabel = `${props.lessonDetails.studentName} (${props.lessonDetails.studentNumber})`;
  const teacherLabel = rescheduledLesson.teacher ? `${rescheduledLesson.teacher.name} (${rescheduledLesson.teacher.number})` : 'None';

  return (
    <form onSubmit={onSubmit}>
      <RescheduleWizardDialogBody onPreviousStep={handlePrevButtonClick}>
        <Stack spacing="24px">
          <TextField
            required
            fullWidth
            label="Student"
            value={studentLabel}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentity />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            fullWidth
            label="Teacher"
            value={teacherLabel}
            helperText={rescheduledLesson.teacher ? undefined : 'These lessons will go up to the opportunities page for substitutes.'}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentity />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </RescheduleWizardDialogBody>
    </form>
  );
}
