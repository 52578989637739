import { CustomerStatus } from '@hoot-reading/hoot-core/dist/enums/user/customer-status.enum';
import { PasswordSetFilter } from '@hoot-reading/hoot-core/dist/enums/user/password-reset-filter.enum';
import { StudentDateType } from '@hoot-reading/hoot-core/dist/enums/user/student/student-date-type.enum';
import { StudentSearchFieldOptions } from '@hoot-reading/hoot-core/dist/enums/user/student/student-search-field-options.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { Close } from '@mui/icons-material';
import { Button, Divider, Drawer, FormControl, FormHelperText, Grid, IconButton, List, ListItem, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Control, FormState, SubmitHandler, UseFormWatch, useForm } from 'react-hook-form';
import { SearchSelect } from '@hoot/components/SearchSelect';
import { DatePicker } from '@hoot/components/form/DatePicker';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { MultiSelect } from '@hoot/components/form/MultiSelect';
import useGetAccounts from '@hoot/hooks/api/common/useGetAccounts';
import { UserQuery } from '@hoot/hooks/api/end-users/useGetUsers';
import useGetAllBillableLocations from '@hoot/hooks/api/hfs/useGetAllBillableLocations';
import useGetAllEnrolments from '@hoot/hooks/api/hfs/useGetAllEnrolments';
import useGetAllSchools from '@hoot/hooks/api/hfs/useGetAllSchools';
import { StudentQuery } from '@hoot/hooks/api/user/student/useSearchStudents';
import { usePlaces } from '@hoot/hooks/usePlaces';
import { UserAccount } from '@hoot/interfaces/user';
import {
  lineOfBusinessFilterOptions,
  passwordSetFilterOptions,
  studentDateTypeOptions,
  studentStatusFilterOptions,
} from '@hoot/pages/users/interfaces/user-filters-form';
import { timeZoneData } from '@hoot/utils/timeZones';

interface Form {
  accountType: UserAccount | '';
  timeZone: string[];
  province: string[];
  userIsEnabled: string | '';
  country: string | '';
  isPasswordSet: string | '';
  student: {
    searchFieldSelection?: StudentSearchFieldOptions;
    studentStatus: (StudentStatus | 'IS_EMPTY')[];
    studentLocationIds?: (string | 'IS_EMPTY')[] | '';
    studentSchoolIds?: (string | 'IS_EMPTY')[] | '';
    enrolmentIds?: (string | 'IS_EMPTY')[] | '';
    lineOfBusiness?: string | '';
    dateType?: StudentDateType | '';
    fromDate?: string;
    toDate?: string;
    passwordSet?: PasswordSetFilter | '';
  };
  customer: {
    customerStatus?: (CustomerStatus | 'IS_EMPTY')[];
    account?: string[] | '';
  };
}

interface Props {
  showDrawer: boolean;
  setShowDrawer: (showAddHootEmployeeModal: boolean) => void;
  userQuery: UserQuery;
  setUserQuery: React.Dispatch<React.SetStateAction<UserQuery>>;
  studentQuery: StudentQuery;
  setStudentQuery: React.Dispatch<React.SetStateAction<StudentQuery>>;
}

const StudentFilters = (props: { control: Control<Form>; watch: UseFormWatch<Form>; formState: FormState<Form> }) => {
  const { control, watch, formState } = props;
  const accounts = useGetAccounts(false);
  const districts = useGetAllBillableLocations();
  const schools = useGetAllSchools();
  const enrolments = useGetAllEnrolments();
  const { student } = watch();

  const accountsOptions =
    accounts?.data?.map((option) => {
      return { value: option.id, label: option.name };
    }) ?? [];

  const districtOptions =
    districts?.data?.billableLocations.map((option) => {
      return { value: option.id, label: option.name };
    }) ?? [];

  const schoolsOptions =
    schools?.data?.schools.map((option) => {
      return { value: option.id, label: option.name };
    }) ?? [];

  const enrolmentsOptions =
    enrolments?.data?.enrolments.map((option) => {
      return { value: option.id, label: option.friendlyId };
    }) ?? [];

  return (
    <>
      <ListItem>
        <MultiSelect size="small" control={control} label="Status" name="student.studentStatus" data={studentStatusFilterOptions} />
      </ListItem>

      <ListItem>
        <MultiSelect size="small" control={control} label="Account" name="customer.account" data={accountsOptions} />
      </ListItem>

      <ListItem>
        <MultiSelect size="small" control={control} label="District" name="student.studentLocationIds" data={districtOptions} />
      </ListItem>

      <ListItem>
        <MultiSelect size="small" control={control} label="School" name="student.studentSchoolIds" data={schoolsOptions} />
      </ListItem>

      <ListItem>
        <MultiSelect size="small" control={control} label="Enrolment" name="student.enrolmentIds" data={enrolmentsOptions} />
      </ListItem>

      <ListItem>
        <FormControl fullWidth size="small">
          <Dropdown
            variant="outlined"
            size="small"
            control={control}
            includeEmpty
            label="Line of Business"
            name="student.lineOfBusiness"
            options={lineOfBusinessFilterOptions}
          />
        </FormControl>
      </ListItem>

      <ListItem>
        <FormControl fullWidth size="small">
          <Dropdown
            variant="outlined"
            size="small"
            name="student.dateType"
            control={control}
            includeEmpty
            label="Date Type"
            options={studentDateTypeOptions}
          />
        </FormControl>
      </ListItem>

      <ListItem>
        <FormControl variant="outlined" fullWidth>
          <DatePicker
            size="small"
            name="student.fromDate"
            control={control}
            label="From Date"
            disabled={!student.dateType}
            rules={{ required: !!student.dateType ? 'Field is required while Date Type is selected.' : false }}
          />
          {formState.errors.student?.fromDate ? (
            <FormHelperText style={{ marginTop: '10px' }} error={true}>
              {formState.errors.student?.fromDate?.message}
            </FormHelperText>
          ) : null}
        </FormControl>
      </ListItem>

      <ListItem>
        <FormControl variant="outlined" fullWidth>
          <DatePicker
            size="small"
            name="student.toDate"
            control={control}
            label="To Date"
            disabled={!student.dateType}
            rules={{ required: !!student.dateType ? 'Field is required while Date Type is selected.' : false }}
          />
          {formState.errors.student?.toDate ? (
            <FormHelperText style={{ marginTop: '10px' }} error={true}>
              {formState.errors.student?.toDate?.message}
            </FormHelperText>
          ) : null}
        </FormControl>
      </ListItem>

      <ListItem>
        <FormControl fullWidth size="small">
          <Dropdown
            variant="outlined"
            size="small"
            control={control}
            includeEmpty
            label="Password Set"
            name="student.passwordSet"
            options={passwordSetFilterOptions}
          />
        </FormControl>
      </ListItem>
    </>
  );
};

const StudentFiltersDrawer = (props: Props) => {
  const { showDrawer, setShowDrawer, userQuery, studentQuery, setStudentQuery } = props;

  const { watch, control, reset, handleSubmit, setValue, formState } = useForm<Form>({
    mode: 'onSubmit',
    defaultValues: {
      accountType: userQuery.accountType || '',
      country: userQuery.country || studentQuery.country || '',
      timeZone: userQuery.timeZones || studentQuery.timeZones || [],
      province: userQuery.provincesStates || studentQuery.provincesStates || [],
      userIsEnabled: userQuery.isEnabled?.toString() || '',
      isPasswordSet: userQuery.isPasswordSet?.toString() || studentQuery.isPasswordSet?.toString() || '',
      customer: {
        account: userQuery.customerQuery.account || studentQuery.customerQuery.account || [],
        customerStatus: userQuery.customerQuery.customerStatus || [],
      },
      student: {
        searchFieldSelection: studentQuery.studentQuery.searchFieldSelection || StudentSearchFieldOptions.All,
        studentLocationIds: studentQuery.studentQuery.studentLocationIds || [],
        studentSchoolIds: studentQuery.studentQuery.studentSchoolIds || [],
        enrolmentIds: studentQuery.studentQuery.enrolmentIds || [],
        dateType: studentQuery.studentQuery.dateType || '',
        fromDate: studentQuery.studentQuery.fromDate || '',
        toDate: studentQuery.studentQuery.toDate || '',
        studentStatus: studentQuery.studentQuery.studentStatus || [],
        lineOfBusiness: studentQuery.studentQuery.lineOfBusiness || '',
        passwordSet: studentQuery.studentQuery.passwordSet || '',
      },
    },
  });

  const { country } = watch();
  const { provinceStateOptions } = usePlaces(country);

  const countryOptionsCanadaFirst = [
    { label: 'Canada', value: 'CA' },
    { label: 'United States', value: 'US' },
  ];

  const handleClose = () => {
    setShowDrawer(false);
  };

  const handleClearFilters = () => {
    reset();
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    setStudentQuery((current) => ({
      ...current,
      page: 1,
      country: data.country !== '' ? data.country : undefined,
      isEnabled: data.userIsEnabled !== '' ? data.userIsEnabled === 'true' : undefined,
      timeZones: data.timeZone?.length === 0 ? undefined : data.timeZone,
      provincesStates: data.province?.length === 0 ? undefined : data.province,
      isPasswordSet: data.isPasswordSet !== '' ? data.isPasswordSet === 'true' : undefined,
      customerQuery: {
        account: data.customer.account !== '' ? data.customer.account : undefined,
        customerStatus: data.customer.customerStatus?.length === 0 ? undefined : data.customer.customerStatus,
      },
      studentQuery: {
        searchFieldSelection: data.student.searchFieldSelection || StudentSearchFieldOptions.All,
        studentLocationIds: data.student.studentLocationIds !== '' ? data.student.studentLocationIds : undefined,
        studentSchoolIds: data.student.studentSchoolIds !== '' ? data.student.studentSchoolIds : undefined,
        enrolmentIds: data.student.enrolmentIds !== '' ? data.student.enrolmentIds : undefined,
        dateType: data.student.dateType !== '' ? data.student.dateType : undefined,
        fromDate: data.student.fromDate !== '' ? data.student.fromDate : undefined,
        toDate: data.student.toDate !== '' ? data.student.toDate : undefined,
        studentStatus: data.student.studentStatus?.length === 0 ? undefined : data.student.studentStatus,
        lineOfBusiness: data.student.lineOfBusiness !== '' ? data.student.lineOfBusiness : undefined,
        passwordSet: data.student.passwordSet !== '' ? data.student.passwordSet : undefined,
      },
    }));
    handleClose();
  };

  return (
    <Drawer anchor="right" open={showDrawer} onClose={handleClose}>
      <Box sx={{ minWidth: '320px' }}>
        <Box sx={{ position: 'relative' }}>
          <Typography
            style={{
              marginLeft: '24px',
              marginTop: '105px',
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '48px',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            Student Filters
          </Typography>

          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '4px',
              cursor: 'pointer',
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ marginTop: '16px' }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <List disablePadding sx={{ marginTop: '16px' }}>
            <StudentFilters control={control} watch={watch} formState={formState} />
            <>
              <ListItem>
                <MultiSelect size="small" control={control} label="Time Zone" name="timeZone" data={timeZoneData} />
              </ListItem>

              <ListItem>
                <SearchSelect
                  size="small"
                  onChangeCallback={() => setValue('province', [])}
                  control={control}
                  label="Country"
                  name="country"
                  data={countryOptionsCanadaFirst}
                />
              </ListItem>

              <ListItem>
                <MultiSelect
                  size="small"
                  control={control}
                  label="Province / State"
                  name="province"
                  data={provinceStateOptions}
                  disabled={country === 'all'}
                />
              </ListItem>
            </>
          </List>
          <Grid container p={2} sx={{ position: 'sticky', bottom: 0, zIndex: 1, background: '#fff' }}>
            <Grid item container>
              <Button type="submit" size="small" variant="contained" fullWidth>
                Apply
              </Button>
            </Grid>
            <Grid item container sx={{ marginTop: '16px' }}>
              <Button color="inherit" type="button" onClick={handleClearFilters} size="small" variant="contained" fullWidth>
                Reset
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Drawer>
  );
};

export default StudentFiltersDrawer;
