import { Button, DialogActions, DialogContent, Divider, Stack, Typography } from '@mui/material';
import { useDistrictRepPermissionsWizardContext } from '@hoot/pages/users/district-reps/district-rep-permissions-wizard/DistrictRepPermissionsContextProvider';

const SummaryStep = () => {
  const {
    districtRep,
    currentStep,
    billableEntityState,
    permissionsState,
    schoolState,
    enrolmentState,
    actions: { goToPreviousStep, dismissPermissionsWizard, submitPermissions },
  } = useDistrictRepPermissionsWizardContext();

  const onApply = () => {
    submitPermissions();
    dismissPermissionsWizard();
  };

  const permissions = [
    permissionsState?.districtEdit && 'District Edit',
    permissionsState?.schoolEdit && 'School Edit',
    permissionsState?.enrolmentEdit && 'Enrolment Edit',
    permissionsState?.enrolmentView && 'Enrolment View',
  ]
    .filter((permission) => !!permission)
    .join(', ');

  const enrolments = enrolmentState?.map((enrolment) => enrolment.friendlyId).join(', ');

  return (
    <form>
      <DialogContent sx={{ px: 0, pt: 0 }}>
        <Typography variant="titleMedium" sx={{ color: '#000' }}>
          Step {currentStep.id + 1} - Summary
        </Typography>
        <Stack sx={{ mt: 3, p: 2, border: '1px solid #1976D2', borderRadius: '8px', backgroundColor: '#E9F4FF' }} gap={1}>
          <Stack direction="row" gap={1}>
            <Typography variant="titleMedium">District Rep:</Typography>
            <Typography variant="bodyLarge">
              {districtRep.firstName} {districtRep.lastName}
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography variant="titleMedium">Billable Entity:</Typography>
            <Typography variant="bodyLarge">{billableEntityState?.name}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography variant="titleMedium">Permissions: </Typography>
            <Typography variant="bodyLarge">{permissions}</Typography>
          </Stack>
          {schoolState ? (
            <Stack direction="row" gap={1}>
              <Typography variant="titleMedium">School: </Typography>
              <Typography variant="bodyLarge">{schoolState.name}</Typography>
            </Stack>
          ) : null}
          {enrolments ? (
            <Stack direction="row" gap={1}>
              <Typography variant="titleMedium">Enrolment: </Typography>
              <Typography variant="bodyLarge">{enrolments}</Typography>
            </Stack>
          ) : null}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 0, mt: 3 }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack direction="row">
            <Button onClick={goToPreviousStep} color="inherit" variant="outlined" size="large">
              Back
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button onClick={dismissPermissionsWizard} color="inherit" variant="outlined" size="large">
              Cancel
            </Button>
            <Button onClick={onApply} variant="contained" size="large">
              Apply
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </form>
  );
};

export default SummaryStep;
