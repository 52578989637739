// For when a page url uses query params.
export enum SortByQueryParams {
  OrderBy = 'order-by',
  SortDirection = 'sort-direction',
}

export interface SortableQuery<T> {
  orderBy?: T;
  sortDirection?: SortOrder;
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const toggleSortOrder = (sortOrder?: SortOrder): SortOrder => {
  if (!sortOrder) {
    return SortOrder.ASC;
  }
  return sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
};

// workaround. MUI table uses lowercase, but our api uses uppercase.
export const muiTableSortDirectionLookup: Record<SortOrder, 'asc' | 'desc'> = {
  [SortOrder.ASC]: 'asc',
  [SortOrder.DESC]: 'desc',
};

export interface TableHeaderCell<SortKey> {
  label: string;
  sortKey?: SortKey;
  cellWidth?: string;
}
