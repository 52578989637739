import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface Enrolment {
  id: string;
  friendlyId: string;
}

export interface EnrolmentsResponse {
  enrolments: Enrolment[];
}

async function getAllEnrolments() {
  const { data } = await axios.get<EnrolmentsResponse>(`${config.apiUrl}/admin/hfs/enrolments`);
  return data;
}

export default function useGetAllEnrolments() {
  return useQuery(QueryKey.GetAllEnrolments, () => getAllEnrolments());
}
