import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import {
  CancellationReason,
  ScheduledLessonLanguage,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonType,
  cancellationReasonLookup,
  lessonLanguagesLookup,
  lessonStatusesLookup,
  lessonSubjectLookup,
  scheduledLessonTypeLookup,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { FilterAltOutlined, MailOutline, PhoneOutlined, Refresh } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Badge,
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ONE_MINUTE_IN_MS, defaultRowsPerPage, rowsPerPageOptions } from '@hoot/common/constants';
import SearchTextField, { SearchTextFieldProps } from '@hoot/components/form/SearchTextField';
import { HeaderData, SelectState, Table } from '@hoot/components/ui/Table';
import useGetAllEnrolments from '@hoot/hooks/api/hfs/useGetAllEnrolments';
import { SchoolSummary } from '@hoot/hooks/api/hfs/useSearchSchools';
import { LessonQueryRequest, LessonSearchFieldOptions } from '@hoot/hooks/api/lessons/lesson-query-request';
import { FormOption } from '@hoot/interfaces/form';
import { LessonSetStatusIcon } from '@hoot/pages/lesson-sets/LessonSets';
import { DayOfWeek, daysOfWeekType } from '@hoot/utils/dateTime';
import { removePrefix } from '@hoot/utils/stringUtils';
import { useAlert } from '../../contexts/AlertContext';
import useGetAccounts from '../../hooks/api/common/useGetAccounts';
import useLessonsWithTimeInfoQuery from '../../hooks/api/lessons/useLessonsWithTimeInfoQuery';
import useLessonsWithTimeInfoTotalResultCountQuery from '../../hooks/api/lessons/useLessonsWithTimeInfoTotalResultCountQuery';
import { useMappedSearchParams } from '../../hooks/useMappedSearchParams';
import { usePageTitle } from '../../hooks/usePageTitle';
import { studentProfileStatusType } from '../../interfaces/profile';
import { routes } from '../../routes/routes';
import { useLessons } from './LessonContextProvider';
import LessonFilters from './LessonFilters';
import LessonMassEditDrawer from './LessonMassEditDrawer';
import { OrderBy, OrderColumn, shortHandCancellationReasons } from './enums';

const DISABLED_CHECKBOX_STATUSES: ScheduledLessonStatus[] = [
  ScheduledLessonStatus.InProgress,
  ScheduledLessonStatus.CompletedSuccessfully,
  ScheduledLessonStatus.CompletedUnsuccessfully,
];

const SubRequiredNotification = <div style={{ color: '#f00', fontSize: '11px' }}>SUB REQUIRED</div>;

const QUERY_FILTER = ['orderColumn', 'orderBy', 'page', 'pageSize', 'query'];

type QueryKeys = keyof LessonQueryRequest;

interface LessonTableRow {
  id: string;
  selected: boolean;
  status: ScheduledLessonStatus;
  number: React.ReactNode;
  statusRow: React.ReactNode;
  schedule: React.ReactNode;
  lessonType: React.ReactNode;
  teacher: React.ReactNode;
  student: React.ReactNode;
  parentOrEnrolment: React.ReactNode;
}

const headers: HeaderData<LessonTableRow>[] = [
  { property: 'number', name: 'Lesson #' },
  { property: 'statusRow', name: 'Status' },
  { property: 'schedule', name: 'Schedule', isSortable: true, sortKey: OrderColumn.StartsAt },
  { property: 'lessonType', name: 'Lesson Type' },
  { property: 'teacher', name: 'Teacher' },
  { property: 'student', name: 'Student', isSortable: true, sortKey: OrderColumn.StudentName },
  { property: 'parentOrEnrolment', name: 'Parent/Enrolment', isSortable: true, sortKey: OrderColumn.ParentName },
];

const getStatusColor = (lessonStatus: ScheduledLessonStatus) => {
  switch (lessonStatus) {
    case ScheduledLessonStatus.Cancelled:
      return '#D32D41';
    case ScheduledLessonStatus.Open:
      return '#0091D5';
    case ScheduledLessonStatus.OpenWaitingForStudent:
      return '#0091D5';
    case ScheduledLessonStatus.OpenWaitingForTeacher:
      return '#0091D5';
    case ScheduledLessonStatus.InProgress:
      return '#0091D5';
    case ScheduledLessonStatus.CompletedSuccessfully:
      return '#338F59';
    case ScheduledLessonStatus.CompletedUnsuccessfully:
      return '#EA6A47';
    default:
      return '#000000';
  }
};

const calculateLessonMinutesForDates = (lessonEventTime: number, lessonTime: number) => {
  const difference = Math.floor((lessonEventTime - lessonTime) / ONE_MINUTE_IN_MS);
  return difference > 0 ? `+${difference}` : `${difference}`;
};

const searchFieldOptions: FormOption<LessonSearchFieldOptions>[] = [
  { value: LessonSearchFieldOptions.LessonNumber, label: 'Lesson Number' },
  { value: LessonSearchFieldOptions.LessonSetNumber, label: 'Lesson Set Number' },
  { value: LessonSearchFieldOptions.StudentNumber, label: 'Student Number' },
  { value: LessonSearchFieldOptions.ParentNumber, label: 'Parent Number' },
  { value: LessonSearchFieldOptions.ParentEmail, label: 'Parent Email' },
  { value: LessonSearchFieldOptions.TeacherNumber, label: 'Teacher Number' },
  { value: LessonSearchFieldOptions.TeacherEmail, label: 'Teacher Email' },
  { value: LessonSearchFieldOptions.All, label: 'All Fields' },
];

const FilterChips = (props: { badgesWithMultiSelectors: any[]; setLessonQuery: React.Dispatch<React.SetStateAction<LessonQueryRequest>> }) => {
  const { badgesWithMultiSelectors, setLessonQuery } = props;
  const accounts = useGetAccounts(false);
  const { data: enrolmentsResponse } = useGetAllEnrolments();
  const { setPreviousTeacherAccountFilter } = useLessons();

  const handleChipDeleteClick = (field: QueryKeys, valueToRemove: string) => () => {
    if (field === 'previousTeacherAccountEmails') {
      setPreviousTeacherAccountFilter(undefined);
      setLessonQuery((q: LessonQueryRequest) => ({ ...q, previousTeacherAccountEmails: undefined }));
    } else {
      setLessonQuery((q: LessonQueryRequest) => ({
        ...q,
        [field]: Array.isArray(q[field]) ? (q[field] as any)!.filter((val: string) => val !== valueToRemove) : undefined,
      }));
    }
  };

  return (
    <Grid container item>
      {badgesWithMultiSelectors.map((badge) => {
        let value;
        const [filterType, filterValue]: [QueryKeys, any] = badge;
        if (filterType === 'fromDate' || filterType === 'toDate') {
          value =
            filterType === 'fromDate' || filterType === 'toDate' ? DateTime.fromMillis(filterValue).toFormat('LLL dd, yyyy hh:mm a') : filterValue;
        } else if (filterType === 'accountId') {
          value = accounts.data?.find((a) => a.id === filterValue)?.name;
        } else if (filterType === 'cancelledReason') {
          value = cancellationReasonLookup[filterValue as CancellationReason];
        } else if (filterType === 'subject') {
          value = lessonSubjectLookup[filterValue as ScheduledLessonSubject];
        } else if (filterType === 'language') {
          value = lessonLanguagesLookup[filterValue as ScheduledLessonLanguage];
        } else if (filterType === 'status') {
          value = lessonStatusesLookup[filterValue as ScheduledLessonStatus];
        } else if (filterType === 'type') {
          value = scheduledLessonTypeLookup[filterValue as ScheduledLessonType];
        } else if (filterType === 'daysOfWeek') {
          value = daysOfWeekType[filterValue as DayOfWeek];
        } else if (filterType === 'studentProfileStatus') {
          value = studentProfileStatusType[filterValue as StudentStatus];
        } else if (filterType === 'isRecurring') {
          value = filterValue ? 'Yes' : 'No';
        } else if (filterType === 'isCustomerBillable') {
          value = filterValue ? 'Yes' : 'No';
        } else if (filterType === 'enrolmentId') {
          value = enrolmentsResponse?.enrolments?.find((a) => a.id === filterValue)?.friendlyId ?? '';
        } else if (filterType === 'lessonSearchFieldSelection') {
          //Note: not displaying the field to search in selection filter
          return null;
        } else {
          value = filterValue;
        }
        return (
          <Chip
            key={`${filterType}-${value}`}
            sx={{ margin: '4px', marginLeft: '18px' }}
            label={`${capitalCase(filterType)}: ${value}`}
            onDelete={handleChipDeleteClick(filterType, filterValue)}
          />
        );
      })}
    </Grid>
  );
};

function Lessons() {
  return (
    <Stack spacing="16px">
      <HeaderCard />
      <SearchCard />
    </Stack>
  );
}

function HeaderCard() {
  return (
    <Card sx={{ padding: '24px' }}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Typography variant="headlineLarge">Lessons</Typography>
        <AddLessonAction />
      </Grid>
    </Card>
  );
}

const SearchCard = () => {
  usePageTitle('Lessons | Admin Portal');
  const [lessonQuery, setLessonQuery] = useLessonSearchQueryParams();
  const [searchText, setSearchText] = useState(lessonQuery.query ?? '');
  const [selectedLessons, setSelectedLessons] = useState<string[]>([]);
  const [lessonResultCount, setLessonResultCount] = useState<number>(0);
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [showMassEditDialog, setShowMassEditDialog] = useState<boolean>(false);
  //Note: must store school filter selection here to handle edge case on filter changes within LessonFilters.tsx and schoolAutoComplete.tsx
  const [selectedSchoolFilter, setSelectedSchoolFilter] = useState<SchoolSummary | undefined>(undefined);

  const getLessonsRequest = useLessonsWithTimeInfoQuery(
    { ...lessonQuery, query: removePrefix(lessonQuery.query) },
    {
      onSuccess: (result) => {
        // Once we have the results, we can fetch the result count.
        if (result.count > 0) {
          getLessonsRequestCount.refetch();
        }
      },
    },
  );

  const getLessonsRequestCount = useLessonsWithTimeInfoTotalResultCountQuery(
    { ...lessonQuery, query: removePrefix(lessonQuery.query) },
    {
      enabled: false, // Need to manually call this endpoint.
      onSuccess: (result) => {
        setLessonResultCount(result);
      },
    },
  );

  const { success } = useAlert();
  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    success('Copied to clipboard');
  };

  const lessons = getLessonsRequest.data?.data ?? [];

  const data: LessonTableRow[] = getLessonsRequest.data?.data
    ? getLessonsRequest.data.data.map<LessonTableRow>((lesson) => ({
        checkboxDisabled: DISABLED_CHECKBOX_STATUSES.includes(lesson.status),
        id: lesson.lessonId,
        status: lesson.status,
        selected: selectedLessons.includes(lesson.lessonId),
        number: <Link to={routes.lessons.details.url(lesson.lessonId)}>{lesson.lessonPrefixedNumber}</Link>,
        statusRow: (
          <>
            <p
              style={{
                color: getStatusColor(lesson.status),
                marginBottom: 0,
              }}
            >
              {lessonStatusesLookup[lesson.status as ScheduledLessonStatus]}
            </p>
            <p
              style={{
                color: 'grey',
                fontStyle: 'italic',
                fontSize: '11px',
                marginTop: 0,
              }}
            >
              {shortHandCancellationReasons[lesson.cancellationReason as CancellationReason]}
            </p>
          </>
        ),
        schedule: (
          <>
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{DateTime.fromMillis(lesson.startDate).toFormat('LLL. dd, yyyy')}</span>
              <span>
                {DateTime.fromMillis(lesson.startDate).toFormat('ccc')} | {DateTime.fromMillis(lesson.startDate).toFormat('hh:mm a')}
              </span>
            </span>
          </>
        ),
        lessonType: (
          <>
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{scheduledLessonTypeLookup[lesson.type as ScheduledLessonType]}</span>
              {/* If this lesson is part of a set, then show the lesson set # (hyper linked to lesson set details page) and status indicator. Kinda looks like ass. */}
              {lesson.lessonSet ? (
                <Link to={routes.lessonSets.details.url(lesson.lessonSet.id)}>
                  <span>#{lesson.lessonSet.lessonSetPrefixedNumber}&nbsp;</span>
                  <span>
                    <LessonSetStatusIcon status={lesson.lessonSet.status} sx={{ width: '8px', height: '8px' }} />
                  </span>
                </Link>
              ) : null}
              <span>
                {/* Note: currently we only ever have Math lessons in English, that's why this is set up the way it is */}
                {lesson.subject !== 'READING' ? (
                  <>{lessonSubjectLookup[lesson.subject as ScheduledLessonSubject]}</>
                ) : lesson.language !== ScheduledLessonLanguage.English ? (
                  <>{lessonLanguagesLookup[lesson.language as ScheduledLessonLanguage]}</>
                ) : null}
              </span>
            </span>
          </>
        ),
        teacher: (
          <>
            <Grid container alignItems="center" justifyContent="space-between">
              <Box>
                {lesson.teacherPrefixedNumber && lesson.teacherId ? (
                  <>
                    <Link color="#000000" to={routes.users.teachers.details.url(lesson.teacherId)}>
                      {lesson.teacherPrefixedNumber}
                    </Link>
                    <Grid>
                      <Grid item style={{ display: 'flex' }}>
                        {lesson.teacherJoinedAt ? (
                          <Box>
                            <Tooltip title="Join Time KPI">
                              <span
                                style={{
                                  color: +calculateLessonMinutesForDates(lesson.teacherJoinedAt, lesson.startTime) > 0 ? '#D32D41' : 'black',
                                  fontWeight: +calculateLessonMinutesForDates(lesson.teacherJoinedAt, lesson.startTime) > 0 ? 'bold' : 'normal',
                                }}
                              >
                                {calculateLessonMinutesForDates(lesson.teacherJoinedAt, lesson.startTime)}
                              </span>
                            </Tooltip>
                          </Box>
                        ) : null}

                        {lesson.teacherLeftAt ? <span>&nbsp;|&nbsp;</span> : null}

                        {lesson.teacherLeftAt ? (
                          <Box>
                            <Tooltip title="Leave Time KPI">
                              <span
                                style={{
                                  color: +calculateLessonMinutesForDates(lesson.teacherLeftAt, lesson.endTime) < 0 ? '#D32D41' : 'black',
                                  fontWeight: +calculateLessonMinutesForDates(lesson.teacherLeftAt, lesson.endTime) < 0 ? 'bold' : 'normal',
                                }}
                              >
                                {calculateLessonMinutesForDates(lesson.teacherLeftAt, lesson.endTime)}
                              </span>
                            </Tooltip>
                          </Box>
                        ) : null}
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  'No teacher yet'
                )}
                {lesson.subRequired === 'YES' ? SubRequiredNotification : null}
              </Box>
              <Box>
                {lesson.teacherEmail ? (
                  <Tooltip title={lesson.teacherEmail}>
                    <IconButton onClick={handleCopyToClipboard(lesson.teacherEmail)}>
                      <MailOutline
                        sx={{
                          marginLeft: '4px',
                          marginRight: '4px',
                          cursor: 'pointer',
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {lesson.teacherPhone ? (
                  <Tooltip title={lesson.teacherPhone}>
                    <IconButton onClick={handleCopyToClipboard(lesson.teacherPhone)}>
                      <PhoneOutlined sx={{ marginLeft: '4px', marginRight: '4px', cursor: 'pointer' }} />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Box>
            </Grid>
          </>
        ),
        student: (
          <>
            <Link color="#000000" to={routes.users.students.details.url(lesson.studentId)}>
              {lesson.studentPrefixedNumber}
            </Link>
            <Grid>
              <Grid item style={{ display: 'flex' }}>
                {lesson.studentJoinedAt ? (
                  <Box>
                    <Tooltip title="Join Time KPI">
                      <span
                        style={{
                          color: +calculateLessonMinutesForDates(lesson.studentJoinedAt, lesson.startTime) > 0 ? '#D32D41' : 'black',
                          fontWeight: +calculateLessonMinutesForDates(lesson.studentJoinedAt, lesson.startTime) > 0 ? 'bold' : 'normal',
                        }}
                      >
                        {calculateLessonMinutesForDates(lesson.studentJoinedAt, lesson.startTime)}
                      </span>
                    </Tooltip>
                  </Box>
                ) : null}

                {lesson.studentLeftAt ? <span>&nbsp;|&nbsp;</span> : null}

                {lesson.studentLeftAt ? (
                  <Box>
                    <Tooltip title="Leave Time KPI">
                      <span
                        style={{
                          color: +calculateLessonMinutesForDates(lesson.studentLeftAt, lesson.endTime) < 0 ? '#D32D41' : 'black',
                          fontWeight: +calculateLessonMinutesForDates(lesson.studentLeftAt, lesson.endTime) < 0 ? 'bold' : 'normal',
                        }}
                      >
                        {calculateLessonMinutesForDates(lesson.studentLeftAt, lesson.endTime)}
                      </span>
                    </Tooltip>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </>
        ),
        parentOrEnrolment: (
          <>
            <Grid container alignItems="center" justifyContent="space-between">
              {lesson.enrolment && lesson.enrolment?.type === EnrolmentType.InSchool ? (
                <Grid>
                  <Link to={routes.districtsSchools.enrolments.details.url(lesson.enrolment.locationId, lesson.enrolment.id)}>
                    {lesson.enrolment.friendlyId}
                  </Link>
                </Grid>
              ) : (
                <>
                  <Box>
                    <Link to={lesson.parentId ? routes.users.parents.details.url(lesson.parentId!) : '#'}>
                      {lesson.parentPrefixedNumber ?? 'None'}
                    </Link>
                  </Box>
                  <Box>
                    {lesson.parentEmail ? (
                      <Tooltip title={lesson.parentEmail}>
                        <IconButton onClick={lesson.parentEmail ? handleCopyToClipboard(lesson.parentEmail) : () => null}>
                          <MailOutline
                            sx={{
                              marginLeft: '4px',
                              marginRight: '4px',
                              cursor: 'pointer',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    {lesson.parentPhone ? (
                      <Tooltip title={lesson.parentPhone}>
                        <IconButton onClick={lesson.parentPhone ? handleCopyToClipboard(lesson.parentPhone) : () => null}>
                          <PhoneOutlined sx={{ marginLeft: '4px', marginRight: '4px', cursor: 'pointer' }} />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </Box>
                </>
              )}
            </Grid>
          </>
        ),
      }))
    : [];

  const searchFieldOnChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLessonQuery((q) => ({ ...q, lessonSearchFieldSelection: value as LessonSearchFieldOptions }));
  };

  const handleFilterClick = () => {
    setShowFilters(true);
  };

  const handleCloseDrawer = () => {
    setShowFilters(false);
  };

  const handleEditSelectedLessonsClick = () => {
    setShowMassEditDialog(true);
  };

  const badges = Object.entries(lessonQuery).filter(([k, v]) => !QUERY_FILTER.includes(k) && v !== undefined) as [QueryKeys, any][];

  const badgesWithMultiSelectors = badges.reduce((acc: any[], badge) => {
    if (Array.isArray(badge[1])) return [...acc, ...badge[1].map((val: any) => [badge[0], val])];
    return [...acc, badge];
  }, []);

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchText(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (searchText) => {
    setLessonQuery((q) => ({ ...q, page: 1, query: searchText }));
  };

  const handlePageChange = (_event: unknown, page: number) => {
    setLessonQuery((current) => ({ ...current, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setLessonQuery((current) => ({ ...current, pageSize }));
  };

  const handleSelected = (row: LessonTableRow, selected: boolean) => {
    if (selected) {
      setSelectedLessons((current) => (DISABLED_CHECKBOX_STATUSES.includes(row.status) ? current : [...current, row.id]));
    } else {
      setSelectedLessons((current) => current.filter((lessonId) => lessonId !== row.id));
    }
  };

  const handleSelectedAll = (state: SelectState) => {
    if (state === 'none') {
      setSelectedLessons(data.filter((lesson) => !DISABLED_CHECKBOX_STATUSES.includes(lesson.status)).map((lesson) => lesson.id));
    } else {
      setSelectedLessons([]);
    }
  };

  const handleSortBy = (column: keyof LessonTableRow) => {
    function orderColumn() {
      switch (column) {
        case 'schedule':
          return OrderColumn.StartsAt;
        case 'student':
          return OrderColumn.StudentName;
        case 'parentOrEnrolment':
          return OrderColumn.ParentName;
        default:
          return OrderColumn.StartsAt;
      }
    }

    setLessonQuery((prev) => ({
      ...prev,
      orderColumn: orderColumn(),
      orderBy: prev.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
    }));
  };

  function sortBy(): keyof LessonTableRow {
    switch (lessonQuery.orderColumn) {
      case OrderColumn.StartsAt:
        return 'schedule';
      case OrderColumn.StudentName:
        return 'student';
      case OrderColumn.ParentName:
        return 'parentOrEnrolment';
      default:
        return 'schedule';
    }
  }

  return (
    <Card sx={{ padding: '24px' }}>
      <Stack sx={{ mb: 3 }} justifyContent="space-between" direction="row" alignItems="center" gap={2}>
        <Typography variant="titleLarge">Lessons</Typography>
        {selectedLessons.length > 0 ? (
          <Box alignContent={'flex-end'}>
            <Button onClick={handleEditSelectedLessonsClick} style={{ height: 36 }} variant="contained" color="primary">
              Bulk Edit
            </Button>
          </Box>
        ) : null}
      </Stack>

      <Stack direction="row" alignItems="center" gap={2}>
        <Box width={'250px'}>
          <FormControl fullWidth variant={'outlined'} size={'medium'}>
            <InputLabel id={'lessonSearchFieldSelection'}>Search in</InputLabel>
            <Select
              labelId={'lessonSearchFieldSelection'}
              label={'Search in'}
              value={lessonQuery.lessonSearchFieldSelection as any}
              onChange={searchFieldOnChange}
            >
              {searchFieldOptions.map((x) => (
                <MenuItem key={`lessonSearchFieldSelection-${x.value}`} value={x.value}>
                  {x.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <SearchTextField
          searchInput={searchText}
          onSearchInputChanged={onSearchInputChanged}
          onSearchInputDebounced={onSearchInputDebounced}
          autoFocus
        />

        <Stack direction="row" alignItems="center" gap={2}>
          <Tooltip title={'Filter Results'}>
            <IconButton color={'primary'} size="large" onClick={handleFilterClick}>
              <Badge color={'primary'} badgeContent={badgesWithMultiSelectors.length}>
                <FilterAltOutlined sx={{ cursor: 'pointer' }} />
              </Badge>
            </IconButton>
          </Tooltip>

          <Tooltip title={'Refresh'}>
            <IconButton color={'primary'} size="large" onClick={() => getLessonsRequest.refetch()}>
              <Refresh sx={{ cursor: 'pointer' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <Box sx={{ marginY: '20px' }}>
        <FilterChips badgesWithMultiSelectors={badgesWithMultiSelectors} setLessonQuery={setLessonQuery} />
      </Box>

      <Stack sx={{ marginTop: '32px' }}>
        <Table
          isSelectable
          isPaginated
          isSortable
          onSelect={handleSelected}
          onSelectAll={handleSelectedAll}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          headers={headers}
          data={data}
          count={getLessonsRequestCount.isFetching ? 0 : lessonResultCount}
          page={lessonQuery.page || 1}
          rowsPerPage={lessonQuery.pageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          isLoading={getLessonsRequest.isFetching}
          sortOrder={lessonQuery.orderBy === OrderBy.Asc ? OrderBy.Asc : OrderBy.Desc}
          sortBy={sortBy()}
          onSortBy={handleSortBy}
        />
      </Stack>

      {showFilters ? (
        <LessonFilters
          handleClose={handleCloseDrawer}
          open={showFilters}
          query={lessonQuery}
          setQuery={setLessonQuery}
          setSelectedSchoolFilter={setSelectedSchoolFilter}
          selectedSchoolFilter={selectedSchoolFilter}
        />
      ) : null}

      {showMassEditDialog ? (
        <LessonMassEditDrawer
          open={showMassEditDialog}
          handleClose={() => setShowMassEditDialog(false)}
          lessons={lessons.filter((l) => selectedLessons.some((sl) => sl === l.lessonId))}
        />
      ) : null}
    </Card>
  );
};

function useLessonSearchQueryParams() {
  const [, setSearchParams] = useSearchParams();

  const params = useMappedSearchParams<LessonQueryRequest>([
    { name: 'orderColumn', default: OrderColumn.StartsAt },
    { name: 'orderBy', default: OrderBy.Asc },
    { name: 'page', type: 'number', default: 1 },
    { name: 'pageSize', type: 'number', default: defaultRowsPerPage },
    { name: 'fromDate', type: 'number', default: DateTime.now().startOf('day').toMillis() },
    { name: 'toDate', type: 'number', default: DateTime.now().endOf('day').toMillis() },
    { name: 'cancelledReason', isArray: true },
    { name: 'daysOfWeek', type: 'number', isArray: true },
    { name: 'isCustomerBillable', type: 'boolean' },
    { name: 'isRecurring', type: 'boolean' },
    { name: 'status', isArray: true },
    { name: 'studentProfileIds', isArray: true },
    { name: 'teacherAccountIds', isArray: true },
    { name: 'previousTeacherAccountEmails', isArray: true },
    { name: 'enrolmentId', isArray: true },
  ]);

  const lessonQueryState = useState<LessonQueryRequest>({
    ...params,
    lessonSearchFieldSelection: LessonSearchFieldOptions.All,
  });

  const [lessonQuery] = lessonQueryState;

  useEffect(() => {
    const params = Object.entries(lessonQuery)
      .filter(([, b]) => !!b)
      .reduce((a, [key, val]) => ({ ...a, [key]: val }), {}) as Record<string, string>;
    setSearchParams(params);
  }, [lessonQuery, setSearchParams]);

  return lessonQueryState;
}

const AddLessonAction = () => {
  const navigate = useNavigate();
  return (
    <>
      <Button onClick={() => navigate(routes.lessons.create.url)} style={{ height: 36 }} variant="contained" color="primary">
        <AddIcon fontSize="small" style={{ marginRight: 5 }} />
        Lesson
      </Button>
    </>
  );
};

export default Lessons;
