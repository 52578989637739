import { ScheduledLessonLanguage } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { useMemo } from 'react';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { englishSightWordListList, frenchSightWordListList } from '@hoot/pages/lessons/enums';
import { FormSelect, FormSelectOption, SelectProps } from './FormSelect';

interface SightWordListSelectProps {
  language: ScheduledLessonLanguage;
}

export function SightWordlistSelect<T extends FieldValues, TObj extends Path<T>>(
  props: SelectProps & UseControllerProps<T, TObj> & SightWordListSelectProps,
) {
  const { language, ...rest } = props;

  const options = useMemo<FormSelectOption[]>(() => {
    return language === ScheduledLessonLanguage.French ? frenchSightWordListList : englishSightWordListList;
  }, [language]);

  return <FormSelect label="Sight Word List" labelId="sight-word-list-label" options={options} {...rest} />;
}
