import { ProductProgram } from '@hoot-reading/hoot-core/dist/enums/hfs/product-program.enum';
import { FormControl, FormHelperText, InputLabel, MenuItem } from '@mui/material';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { SelectProps } from '@hoot/components/form/selectFields/FormSelect';
import { Select } from '../../SelectField';

export const productProgramList: {
  value: ProductProgram;
  label: string;
}[] = [
  { value: ProductProgram.HootForAll, label: 'Hoot For All' },
  { value: ProductProgram.HootForSchools, label: 'Hoot For Schools' },
  { value: ProductProgram.HootForCompanies, label: 'Hoot For Companies' },
  { value: ProductProgram.HootReading, label: 'HootReading' },
];

export const productPrograms = productProgramList.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as Record<ProductProgram, string>;

export function ProductProgramSelect<T extends FieldValues, TObj extends Path<T>>(
  props: SelectProps &
    UseControllerProps<T, TObj> & {
      filter?: ProductProgram[];
    },
) {
  const { filter, size, errorMessage, includeEmpty, includeAll, variant, ...rest } = props;
  return (
    <FormControl variant={variant || 'outlined'} fullWidth size={size} error={!!errorMessage}>
      <InputLabel id="program-type-label">Program Type</InputLabel>
      <Select {...rest} labelId="program-type-label" label="Program Type">
        {includeEmpty ? (
          <MenuItem value="">
            <span>None</span>
          </MenuItem>
        ) : null}

        {includeAll ? (
          <MenuItem value="all">
            <span>All</span>
          </MenuItem>
        ) : null}

        {productProgramList
          .filter((v) => (filter && filter.length > 0 ? filter.some((f) => f === v.value) : v))
          .map((s) => (
            <MenuItem key={s.value} value={s.value}>
              {s.label}
            </MenuItem>
          ))}
      </Select>
      {errorMessage ? <FormHelperText error={true}>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
}
