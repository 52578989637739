import { Button, styled } from '@mui/material';
import React, { ErrorInfo } from 'react';
import LogoBlack from '@hoot/svg/Logo-Black';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

const Root = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  width: '100vw',
  backgroundColor: '#fafafa',
});

const Container = styled('div')({
  alignItems: 'center',
  backgroundColor: '#fafafa',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  padding: 16,
  maxWidth: '540px',
  '@media (max-width: 1024px)': {
    maxWidth: '980px',
  },
  '@media (max-width: 800px)': {
    flexDirection: 'column',
  },
});

const LogoContainer = styled('div')({
  '& > svg': {
    height: 35,
  },
});

const Header = styled('div')({
  marginBottom: 16,
  fontSize: '26px',
  lineHeight: '28px',
  fontWeight: 600,
});

const Help = styled('div')({
  marginBottom: 24,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 600,
});

const ErrorMessageContainer = styled('div')({
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 0,
  marginBottom: 32,
});

const ErrorMessage = (props: { error?: Error; errorInfo?: ErrorInfo }) => {
  const { error } = props;

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Root>
      <Container>
        <LogoContainer>
          <LogoBlack width={120} height={50} />
        </LogoContainer>
        <Header>Uh Oh There Was A Problem!</Header>
        <Help>If you keep experiencing issues, please create a bug request.</Help>
        <ErrorMessageContainer>
          <div>Error Name: {error?.name}</div>
          <div>Error Message: {error?.message}</div>
        </ErrorMessageContainer>
        <div>
          <Button
            onClick={handleRefresh}
            color="primary"
            variant="contained"
            fullWidth
            sx={{
              maxWidth: 122,
            }}
          >
            Refresh
          </Button>
        </div>
      </Container>
    </Root>
  );
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState((prevState) => ({ ...prevState, error, errorInfo }));
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage error={this.state.error} errorInfo={this.state.errorInfo} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
