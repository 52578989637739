import { ScheduledLessonStatus, ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { TeacherBlockCancellationReason, TeacherBlockStatus, TeacherBlockType } from '@hoot-reading/hoot-core/dist/enums/teacher-block';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface TeacherBlockUtilizationQuery {
  date: string;
  teacherStatuses?: TeacherStatus[];
  blockToday?: boolean;
  blockType?: TeacherBlockType[];
  showCancelled?: boolean;
  fromStartTime?: string;
  toStartTime?: string;
}

export interface BlockUtilizationLesson {
  id: string;
  prefixedNumber: string;
  status: ScheduledLessonStatus;
  type: ScheduledLessonType;
  startsAt: number;
  durationInMinutes: number;
  studentId: string;
  studentPrefixedNumber: string;
}

export interface BlockUtilizationBlock {
  id: string;
  prefixedNumber: string;
  teacherAccountId: string;
  type: TeacherBlockType;
  status: TeacherBlockStatus;
  startsAt: number;
  endsAt: number;
  cancelledAt: number | null;
  cancellationReason: TeacherBlockCancellationReason | null;
}

export interface BlockUtilizationTeacher {
  id: string;
  name: string;
  prefixedNumber: string;
  blocks: BlockUtilizationBlock[];
  lessons: BlockUtilizationLesson[];
}

export interface TeacherBlockUtilizationResponse {
  teachers: BlockUtilizationTeacher[];
  subRequiredLessons: BlockUtilizationLesson[];

  // Below fields are in the "summary bar" and not yet calculated
  // from requirements of SC-11291: "Note: the summary bar under the applied filters in the designs will be created in an iteration ticket."
  utilizedSlots: number;
  hoursOnBlock: number;
  hoursInLesson: number;
}

async function getTeacherBlockUtilization(query: TeacherBlockUtilizationQuery) {
  const { data } = await axios.post<TeacherBlockUtilizationResponse>(`${config.apiUrl}/admin/teacher-block/utilization`, query);
  return data;
}

export default function useTeacherGetBlockUtilization(
  query: TeacherBlockUtilizationQuery,
  options?: Omit<UseQueryOptions<TeacherBlockUtilizationResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetTeacherBlockUtilization, query], () => getTeacherBlockUtilization(query), options);
}
