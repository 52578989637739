import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { productStatusList } from '../../../../hooks/api/user/district-representative/useGetDistrictRepresentative';
import { productStatusLabels } from '../../../hfs-utils';
import FilterDropDown from '../../FilterDropDown';

export function ProductStatusFilterDropDown(props: {
  value: ProductStatus[];
  onChange: (val: ProductStatus[]) => void;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { value, onChange, popOverButtonSx } = props;

  return (
    <FilterDropDown<string>
      title="Status"
      dismissPopoverOnSelection={true}
      value={value}
      options={productStatusList.map((s) => ({
        label: s.label,
        value: s.value,
      }))}
      onChange={(val) => onChange(val as ProductStatus[])}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveProductStatusFilterDropDown(props: { value?: ProductStatus[]; onChange: (val: ProductStatus[]) => void }) {
  const { value = [], onChange } = props;

  const handleDelete = (val: ProductStatus) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`Status: ${productStatusLabels[c]}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}
