import { StudentGoal } from '@hoot-reading/hoot-core/dist/enums/user/student/student-goal.enum';
import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';
import { config } from '../../../config';
import { MathStrengthsGoals, SightWordList } from '../../../pages/lessons/enums';
import { AdminLessonNoteDetails } from './useGetLessonNoteDetailsById';

export interface UpdateLessonNoteRequest {
  strengths: StudentGoal[] | MathStrengthsGoals[];
  goals: StudentGoal[] | MathStrengthsGoals[];
  sightWordList: SightWordList | null;
  sightWordsMissed: string | null;
  lessonNotes: string | null;
  lessonNoteBookIds: string[];
}

export default function useUpdateLessonNoteDetails(lessonNoteId: string) {
  return useMutation<AdminLessonNoteDetails, AxiosError<ErrorResponseDto>, UpdateLessonNoteRequest>(
    async (request) => (await axios.put(`${config.apiUrl}/admin/lesson-notes/${lessonNoteId}`, request)).data,
  );
}
