import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { SortableQuery } from '@hoot/interfaces/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/interfaces/pagination';
import { QueryKey } from '../queryKeys';

export enum SearchDistrictSchoolsQuerySortKeyEnum {
  ID = 'id',
  Name = 'name',
  City = 'city',
  Country = 'country',
  Type = 'type',
}
export interface DistrictSchoolQuery extends PaginatableQuery, SortableQuery<SearchDistrictSchoolsQuerySortKeyEnum> {
  page: number;
  pageSize: number;
  name?: string;
  type?: LocationType[];
  country?: HootSupportedCountry[];
  isLocation?: boolean;
}

export interface DistrictSchoolResponse {
  id: string;
  friendlyId: string;
  name: string;
  city: string;
  country: HootSupportedCountry;
  type: LocationType;
}

const getDistrictSchools = async (query: DistrictSchoolQuery): Promise<GenericPaginatedResponse<DistrictSchoolResponse>> => {
  const { data } = await axios.post<GenericPaginatedResponse<DistrictSchoolResponse>>(`${config.apiUrl}/admin/hfs/district-schools/search`, query);
  return data;
};

export default function useGetDistrictSchools(
  query: DistrictSchoolQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<DistrictSchoolResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.SearchDistrictsSchools, query], () => getDistrictSchools(query), options);
}
