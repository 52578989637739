import {
  Box,
  Checkbox,
  LinearProgress,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TablePagination as MuiTablePagination,
  TableRow as MuiTableRow,
  TableSortLabel as MuiTableSortLabel,
  Paper,
  Stack,
  TableContainer,
  styled,
} from '@mui/material';
import { ReactNode } from 'react';
import { StripedTableRow } from '@hoot/styles/Table';
import { OrderBy } from '../../pages/lessons/enums';
import ViewStateIllustration, { IllustrationEnum } from './ViewStateIllustration';

export interface HeaderData<T> {
  name: string;
  property: keyof T;
  isSortable?: boolean;
  width?: string;
  isHidden?: boolean;
  sortKey?: string;
}

export type SelectState = 'none' | 'all' | 'indeterminate';

export type SelectableRow = {
  id: string;
  selected: boolean;
  checkboxDisabled?: boolean;
};

type Paginated =
  | {
      isPaginated: true;
      allowRowsPerPage?: boolean;
      onPageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
      onRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
      count: number;
      page: number;
      rowsPerPage?: number;
      rowsPerPageOptions?: Array<number | { value: number; label: string }>;
    }
  | {
      isPaginated?: false;
    };

type Sortable<T> =
  | {
      isSortable?: false;
    }
  | {
      isSortable: true;
      sortBy: keyof T;
      sortOrder: OrderBy;
      onSortBy: (property: keyof T) => void;
    };

export type SelectableData<T> = T extends SelectableRow ? SelectableRow : undefined;

export type FieldsForSelectable<T> = { id: string; selected: boolean } & Record<keyof T, any>;

type Selectable<T> =
  | {
      isSelectable?: false;
      data: T[];
    }
  | {
      isSelectable: true;
      data: FieldsForSelectable<T>[];
      onSelectAll?: (selectState: SelectState) => void;
      onSelect?: (val: T, selected: boolean) => void;
    };

export type SelectAllState = 'none' | 'all' | 'indeterminate';

type TableConditionalProps =
  | {
      isPaginated: true;
      onPageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
      onRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
    }
  | {
      isPaginated?: false;
      onPageChange?: never;
      onRowsPerPageChange?: never;
    };

export type TableProps<T> = {
  headers: HeaderData<T>[];
  isLoading?: boolean;
  stickyHeader?: boolean;
  stripedRows?: boolean;
} & TableConditionalProps &
  Sortable<T> &
  Paginated &
  Selectable<T> & { onRowClick?: (rowId: string) => void };

const TableCellStyled = styled(MuiTableCell)({
  borderStyle: 'solid',
  borderRightWidth: '0px',
  borderLeftWidth: '0px',
  borderTopWidth: '0px',
  borderBottomWidth: '1px',
  margin: 0,
  padding: '16px 24px',
});

export function Table<T>(props: TableProps<T>) {
  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    if (props.onPageChange) {
      props.onPageChange(event, page);
    }
  };

  function selectAllState(): SelectAllState {
    if (props.isSelectable) {
      if (props.data.length > 0 && props.data.every((d) => d?.selected)) {
        return 'all';
      } else if (props.data.some((d) => d?.selected)) {
        return 'indeterminate';
      }
    }
    return 'none';
  }

  const selectedState = selectAllState();

  const colspan = props.isSelectable ? props.headers.length + 1 : props.headers.length;

  function RenderBody() {
    if (props.isLoading) {
      return <LoadingIndicator colspan={colspan} />;
    } else if (props.data.length === 0) {
      return <NoResults colspan={colspan} />;
    }
    return (
      <TableBody
        onSelect={props.isSelectable ? props.onSelect : undefined}
        onRowClick={props.onRowClick}
        properties={props.headers.filter((h) => !h.isHidden).map((h) => h.property)}
        data={props.data}
        isSelectable={props.isSelectable}
        stripedRows={props.stripedRows}
      />
    );
  }

  return (
    <Paper>
      <TableContainer sx={{ maxHeight: props.stickyHeader ? '440px' : undefined }}>
        <MuiTable
          stickyHeader={props.stickyHeader}
          sx={{
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
          }}
        >
          <TableHeader
            selectedState={selectedState}
            isSelectable={props.isSelectable}
            headers={props.headers}
            sortBy={props.isSortable ? props.sortBy : undefined}
            sortOrder={props.isSortable ? props.sortOrder : undefined}
            onSortBy={props.isSortable ? props.onSortBy : undefined}
            onSelectAll={props.isSelectable ? props.onSelectAll : undefined}
          />
          <RenderBody />
        </MuiTable>
      </TableContainer>

      {props.isPaginated ? (
        <MuiTablePagination
          component="div"
          colSpan={props.isSelectable ? props.headers.length + 1 : props.headers.length}
          rowsPerPageOptions={props.rowsPerPageOptions ?? [10, 25]}
          count={props.count ?? 0}
          rowsPerPage={props.rowsPerPage ?? 10}
          SelectProps={{
            disabled: props.allowRowsPerPage,
          }}
          page={(props?.page ?? 1) - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={props.onRowsPerPageChange}
          sx={{
            borderBottom: 'none',
          }}
        />
      ) : null}
    </Paper>
  );
}

function TableHeader<T>(props: {
  headers: HeaderData<T>[];
  isSelectable?: boolean;
  selectedState?: SelectState;
  sortBy?: keyof T;
  sortOrder?: OrderBy;
  onSortBy?: (property: keyof T) => void;
  onSelectAll?: (selectState: SelectState) => void;
}) {
  const handleSelectAll = () => {
    if (props.onSelectAll && props.selectedState) {
      props.onSelectAll(props.selectedState);
    }
  };

  return (
    <MuiTableHead>
      <MuiTableRow>
        {props.isSelectable ? (
          <MuiTableCell
            sx={{
              margin: 0,
              padding: '16px 24px',
            }}
            padding="checkbox"
          >
            <Checkbox
              onClick={handleSelectAll}
              indeterminate={props.selectedState === 'indeterminate'}
              checked={props.selectedState === 'all'}
              sx={{ margin: 0, padding: 0 }}
              color="primary"
            />
          </MuiTableCell>
        ) : null}
        {props.headers
          .filter((h) => !h.isHidden)
          .map((h) => (
            <MuiTableCell
              key={h.property.toString()}
              sx={{
                color: '#000000',
                cursor: h.isSortable ? 'pointer' : 'default',
                margin: 0,
                padding: '16px 24px',
              }}
              width={h.width}
            >
              {h.isSortable ? (
                <MuiTableSortLabel
                  onClick={() => {
                    if (h.isSortable && props.onSortBy) {
                      props.onSortBy(h.property);
                    }
                  }}
                  direction={props.sortOrder === OrderBy.Asc ? 'asc' : 'desc'}
                  active={h.sortKey === props.sortBy || h.property === props.sortBy}
                >
                  {h.name}
                </MuiTableSortLabel>
              ) : (
                h.name
              )}
            </MuiTableCell>
          ))}
      </MuiTableRow>
    </MuiTableHead>
  );
}

function TableBody<T>(props: {
  properties: (keyof T)[];
  data: T[];
  isSelectable?: boolean;
  stripedRows?: boolean;
  onSelect?: (val: T, selected: boolean) => void;
  onRowClick?: (rowId: string) => void;
}) {
  const handleSelectClick = (val: T) => (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (props.onSelect) {
      props.onSelect(val, checked);
    }
  };

  const handleRowClick = (rowId: string | undefined) => {
    if (rowId && props.onRowClick) {
      props.onRowClick(rowId);
    }
  };

  return (
    <MuiTableBody>
      {props.stripedRows
        ? props.data.map((d, idx) => (
            <StripedTableRow
              key={`${(d as SelectableRow).id}-${idx}-col-select}`}
              hover={!!props.onRowClick}
              sx={{ cursor: props.onRowClick ? 'pointer' : undefined }}
              onClick={() => handleRowClick((d as SelectableRow).id)}
            >
              {props.isSelectable ? (
                <TableCellStyled key={`${(d as SelectableRow).id}-select`} padding="checkbox">
                  <Checkbox
                    sx={{ margin: 0, padding: 0 }}
                    checked={(d as SelectableRow).selected}
                    color="primary"
                    onChange={handleSelectClick(d)}
                    disabled={(d as SelectableRow).checkboxDisabled}
                  />
                </TableCellStyled>
              ) : null}

              {props.properties.map((p, idx) => (
                <TableCellStyled key={`${(d as SelectableRow).id}-col-${idx}`}>{d[p] as ReactNode}</TableCellStyled>
              ))}
            </StripedTableRow>
          ))
        : props.data.map((d, idx) => (
            <MuiTableRow
              key={`${(d as SelectableRow).id}-${idx}-col-select}`}
              hover={!!props.onRowClick}
              sx={{ cursor: props.onRowClick ? 'pointer' : undefined }}
              onClick={() => handleRowClick((d as SelectableRow).id)}
            >
              {props.isSelectable ? (
                <TableCellStyled key={`${(d as SelectableRow).id}-select`} padding="checkbox">
                  <Checkbox
                    sx={{ margin: 0, padding: 0 }}
                    checked={(d as SelectableRow).selected}
                    color="primary"
                    onChange={handleSelectClick(d)}
                    disabled={(d as SelectableRow).checkboxDisabled}
                  />
                </TableCellStyled>
              ) : null}

              {props.properties.map((p, idx) => (
                <TableCellStyled key={`${(d as SelectableRow).id}-col-${idx}`}>{d[p] as ReactNode}</TableCellStyled>
              ))}
            </MuiTableRow>
          ))}
    </MuiTableBody>
  );
}

function LoadingIndicator(props: { colspan: number }) {
  return (
    <MuiTableBody>
      <MuiTableRow>
        <MuiTableCell colSpan={props.colspan}>
          <Box sx={{ width: '100%', height: '4px' }}>
            <LinearProgress />
          </Box>
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableBody>
  );
}

function NoResults(props: { colspan: number }) {
  return (
    <MuiTableBody>
      <MuiTableRow>
        <MuiTableCell colSpan={props.colspan}>
          <Stack direction="row" justifyContent="center">
            <ViewStateIllustration illustration={IllustrationEnum.NoResults} />
          </Stack>
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableBody>
  );
}
