import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { ProductProgram } from '@hoot-reading/hoot-core/dist/enums/hfs/product-program.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '../../../config';
import { SortOrder } from '../../../interfaces/order-by';
import { QueryKey } from '../queryKeys';

export interface ProductQuery {
  page?: number;
  pageSize?: number;
  locationId?: string;
  productFriendlyId?: string;
  status?: ProductStatus[];
  program?: ProductProgram[];
  startsAt?: number;
  endsAt?: number;
  hasEnrolment?: boolean;
  orderBy?: Omit<keyof ProductQuery, 'orderBy' | 'sortDirection' | 'locationId' | 'page' | 'pageSize'>;
  sortDirection?: SortOrder;
}

interface EnrolmentResponse {
  enrolmentId: string;
  enrolmentStatus: EnrolmentStatus;
  schoolId: string;
  schoolName: string;
}

export interface ProductResponse {
  id: string;
  friendlyId: string;
  startDate: number;
  endDate: number;
  status: ProductStatus;
  enrolments: EnrolmentResponse[];
  program: ProductProgram;
  requirements: HootQualification[];
}

export interface ProductQueryResponse {
  count: number;
  page: number;
  pageSize: number;
  products: ProductResponse[];
}

const searchProducts = async (searchParams: ProductQuery): Promise<ProductQueryResponse> => {
  const { data } = await axios.post<ProductQueryResponse>(`${config.apiUrl}/admin/hfs/products/search`, searchParams);
  return data;
};

export default function useSearchProducts(searchParams: ProductQuery) {
  return useQuery([QueryKey.ProductSearch, searchParams], () => searchProducts(searchParams));
}
