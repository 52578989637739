import { Close, ContentCopy } from '@mui/icons-material';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { useAlert } from '@hoot/contexts/AlertContext';
import { ShiftUtilizationLesson } from '@hoot/hooks/api/shifts/useGetShiftUtilization';
import { lessonColour } from '@hoot/pages/users/components/user-schedule/weekly-schedule/utils';
import { routes } from '@hoot/routes/routes';
import { enumToTitleCase } from '@hoot/utils/stringUtils';

interface ILessonDetailsProps {
  open: boolean;
  onClose: () => void;
  lessons: ShiftUtilizationLesson[];
}

const LessonDetailsDialog = (props: ILessonDetailsProps) => {
  const { open, onClose, lessons } = props;
  const { info } = useAlert();
  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    info(`Copied "${val}" to clipboard.`);
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { width: '20%' } }}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Lesson Details</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          gap={4}
          justifyContent="space-evenly"
          sx={{ height: '500px', '& > :nth-child(n+2)': { borderTop: '1px dashed #CAC4D0', paddingTop: 4 } }}
        >
          {lessons.map((lesson) => (
            <Stack key={lesson.id} gap={2} justifyContent="space-evenly">
              <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                  <Typography variant="bodyMedium">Lesson ID</Typography>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Link href={routes.lessons.details.url(lesson.id)}>
                      <Typography variant="titleMedium">{lesson.prefixedNumber}</Typography>
                    </Link>
                    <Tooltip title="Copy to clipboard">
                      <IconButton onClick={handleCopyToClipboard(lesson.prefixedNumber)} sx={{ height: '12px', width: '12px' }}>
                        <ContentCopy sx={{ height: '16px', width: '16px' }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Card>
              <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                  <Typography variant="bodyMedium">Lesson Status</Typography>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Box sx={{ width: '8px', height: '8px', backgroundColor: lessonColour(lesson.status), borderRadius: '50px' }}></Box>
                    <Typography variant="titleMedium">{enumToTitleCase(lesson.status)}</Typography>
                  </Stack>
                </Stack>
              </Card>
              <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                  <Typography variant="bodyMedium">Type</Typography>
                  <Typography variant="titleMedium">{enumToTitleCase(lesson.type)}</Typography>
                </Stack>
              </Card>
              <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                  <Typography variant="bodyMedium">Date</Typography>
                  <Typography variant="titleMedium">{DateTime.fromMillis(lesson.startsAt).toFormat('MMMM dd, yyyy')}</Typography>
                </Stack>
              </Card>
              <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                  <Typography variant="bodyMedium">Start Time</Typography>
                  <Typography variant="titleMedium">{DateTime.fromMillis(lesson.startsAt).toFormat('h:mm a')}</Typography>
                </Stack>
              </Card>
              <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                  <Typography variant="bodyMedium">Duration</Typography>
                  <Typography variant="titleMedium">{lesson.durationInMinutes} Minutes</Typography>
                </Stack>
              </Card>
              <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                  <Typography variant="bodyMedium">Student</Typography>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Link href={routes.users.students.details.url(lesson.studentId)}>
                      <Typography variant="titleMedium">{lesson.studentPrefixedNumber}</Typography>
                    </Link>
                    <Tooltip title="Copy to clipboard">
                      <IconButton onClick={handleCopyToClipboard(lesson.studentPrefixedNumber)} sx={{ width: '12px', height: '12px' }}>
                        <ContentCopy sx={{ width: '16px', height: '16px' }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Card>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LessonDetailsDialog;
