import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { InvoiceRateTier } from '@hoot/pages/users/teachers/enums/teacher-enums';
import { QueryKey } from '../../queryKeys';

export interface LessonPayRateTiersResponse {
  tiers: LessonPayRateTierResponse[];
}

export interface LessonPayRateTierResponse {
  id: string;
  tier: InvoiceRateTier;
  hourlyRate: number;
  effectiveAt: number;
}

const searchLessonPayRateTiers = async (): Promise<LessonPayRateTiersResponse> => {
  const { data } = await axios.get(`${config.apiUrl}/admin/teacher-invoicing/lesson-pay-rate-tiers`);
  return data;
};

export default function useSearchLessonPayRateTiers(options?: Omit<UseQueryOptions<LessonPayRateTiersResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetLessonPayRateTiers], () => searchLessonPayRateTiers(), options);
}
