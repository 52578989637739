import { ScheduledLessonLanguage, ScheduledLessonSubject } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Close } from '@mui/icons-material';
import { Box, Button, Divider, Drawer, FormControl, Grid, IconButton, InputLabel, List, ListItem, MenuItem, Typography } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { Select } from '@hoot/components/form/SelectField';
import { LiveLessonQueryRequest } from '@hoot/hooks/api/lessons/useLiveLessonsQuery';
import { LIVE_LESSON_TABS } from '.';
import { DateTimePicker } from '../../components/form/DatePicker';
import { AccountAutoComplete } from '../../components/form/selectFields/AccountAutoComplete';
import { LanguageSelect } from '../../components/form/selectFields/LanguageSelect';
import { SubjectSelect } from '../../components/form/selectFields/SubjectSelect';
import { lineOfBusinessFilterOptions } from '../users/interfaces/user-filters-form';

interface Props {
  tab: LIVE_LESSON_TABS;
  open: boolean;
  handleClose: () => void;
  query: LiveLessonQueryRequest;
  setQuery: React.Dispatch<React.SetStateAction<LiveLessonQueryRequest>>;
}

interface Form {
  fromDate: number | null;
  toDate: number | null;
  account: string | '';
  subject: ScheduledLessonSubject | '';
  language: ScheduledLessonLanguage | '';
  isRecurring: 'yes' | 'no' | '';
  lineOfBusiness?: string | '';
}

const header = (tab: LIVE_LESSON_TABS) => {
  if (tab === 'ATTENDANCE') {
    return 'Attendance Filters';
  } else if (tab === 'SUB_REQUIRED') {
    return 'Sub Required Filters';
  }
};

const LiveLessonFilters = (props: Props) => {
  const { tab, handleClose, open, query, setQuery } = props;

  const { handleSubmit, control, reset } = useForm<Form>({
    defaultValues: {
      fromDate: query.fromDate,
      toDate: query.toDate,
      account: query.accountId || '',
      subject: query.subject || '',
      language: query.language || '',
      isRecurring: query.isRecurring === undefined ? '' : query.isRecurring ? 'yes' : 'no',
      lineOfBusiness: query.lineOfBusiness || '',
    },
  });

  const handleCancelClick = () => {
    handleClose();
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    setQuery((q) => ({
      ...q,
      page: 1,
      fromDate: !!data.fromDate ? data.fromDate : undefined,
      toDate: !!data.toDate ? data.toDate : undefined,
      accountId: !!data.account ? data.account : undefined,
      subject: !!data.subject ? data.subject : undefined,
      language: !!data.language ? data.language : undefined,
      isRecurring: data.isRecurring === '' ? undefined : data.isRecurring === 'yes' ? true : false,
      lineOfBusiness: !!data.lineOfBusiness ? data.lineOfBusiness : undefined,
    }));

    handleClose();
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ flex: '1 1 auto', overflowY: 'auto', position: 'relative' }}>
          <Box sx={{ minWidth: '320px', position: 'relative', paddingBottom: '64px' }}>
            <Box sx={{ position: 'relative' }}>
              <Typography
                sx={{
                  marginLeft: '24px',
                  marginTop: '105px',
                  fontWeight: 600,
                  fontSize: '20px',
                  lineHeight: '48px',
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
              >
                {header(tab)}
              </Typography>

              <IconButton
                onClick={handleCancelClick}
                sx={{
                  position: 'absolute',
                  right: '8px',
                  top: '4px',
                  cursor: 'pointer',
                }}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider sx={{ marginTop: '16px' }} />

            <form id="live-lesson-filters-form" onSubmit={handleSubmit(onSubmit)}>
              <List disablePadding sx={{ marginTop: '16px' }}>
                {tab === 'SUB_REQUIRED' && (
                  <>
                    <ListItem>
                      <FormControl variant="outlined" fullWidth>
                        <DateTimePicker size="small" name="fromDate" control={control} label="Lesson From" />
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <FormControl variant="outlined" fullWidth>
                        <DateTimePicker size="small" name="toDate" control={control} label="Lesson To" />
                      </FormControl>
                    </ListItem>
                  </>
                )}
                <ListItem>
                  <Controller name="account" control={control} render={({ field }) => <AccountAutoComplete {...field} />} />
                </ListItem>
                <ListItem>
                  <SubjectSelect size="small" control={control} name="subject" includeEmpty />
                </ListItem>
                <ListItem>
                  <LanguageSelect size="small" control={control} name="language" includeEmpty />
                </ListItem>
                <ListItem>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="is-recurring-label">Is Recurring Lesson?</InputLabel>
                    <Select control={control} name="isRecurring" labelId="is-recurring-label" label="Is Recurring" fullWidth>
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormControl>
                </ListItem>
                <ListItem>
                  <FormControl fullWidth size="small">
                    <Dropdown
                      variant="outlined"
                      size="small"
                      control={control}
                      includeEmpty
                      label="Line of Business"
                      name="lineOfBusiness"
                      options={lineOfBusinessFilterOptions}
                    />
                  </FormControl>
                </ListItem>
              </List>
            </form>
          </Box>
        </Box>
        <Box sx={{ flex: '0 0 auto', borderTop: '1px solid rgba(0, 0, 0, 0.12)', position: 'sticky', bottom: 0, background: '#fff' }}>
          <Grid container p={2}>
            <Grid item container>
              <Button type="submit" form="live-lesson-filters-form" size="small" variant="contained" fullWidth>
                Apply
              </Button>
            </Grid>
            <Grid item container sx={{ marginTop: '16px' }}>
              <Button color="inherit" type="button" onClick={() => reset()} size="small" variant="contained" fullWidth>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

export default LiveLessonFilters;
