import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

async function softDeleteTeacherAccount(teacherAccountId: string): Promise<void> {
  await axios.delete(`${config.apiUrl}/admin/teacher-account/${teacherAccountId}`);
}

export default function useSoftDeleteTeacherAccount(options?: Omit<UseMutationOptions<void, AxiosError, string>, 'mutationKey' | 'mutationFn'>) {
  return useMutation<void, AxiosError, string>((teacherAccountId) => softDeleteTeacherAccount(teacherAccountId), options);
}
