import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface DistrictsResponse {
  districts: DistrictSummary[];
}

export interface DistrictSummary {
  id: string;
  friendlyId: string;
  name: string;
}

const getDistricts = async (): Promise<DistrictsResponse> => {
  const { data } = await axios.get<DistrictsResponse>(`${config.apiUrl}/admin/hfs/districts`);
  return data;
};

export default function useGetDistricts(options?: Omit<UseQueryOptions<DistrictsResponse, AxiosError>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetDistricts], () => getDistricts(), options);
}
