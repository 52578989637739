import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { SortableQuery } from '@hoot/interfaces/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/interfaces/pagination';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';
import { LocationSummaryQuery } from './useGetLocationSummaryDashboard';

interface Enrolment {
  id: string;
  friendlyId: string;
}

export enum GetLocationSummaryProductsQuerySortKeys {
  ProductId = 'productId',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Status = 'status',
}

export interface LocationSummaryProductsQuery
  extends LocationSummaryQuery,
    PaginatableQuery,
    SortableQuery<GetLocationSummaryProductsQuerySortKeys> {}

export interface GetLocationSummaryProductsResponse {
  id: string;
  friendlyId: string;
  startDate: number;
  endDate: number;
  enrolments: Enrolment[];
  status: ProductStatus;
}

export default function useGetLocationSummaryProducts(
  locationId: string,
  searchParams: LocationSummaryProductsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<GetLocationSummaryProductsResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetLocationSummaryProducts, locationId, searchParams],
    async () => {
      return (
        await axios.get(`${config.apiUrl}/admin/hfs/location/${locationId}/summary/products`, {
          params: searchParams,
        })
      ).data;
    },
    options,
  );
}
