import { EnrolmentLoginType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-login-type.enum';
import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export const EnrolmentTypeList: { value: EnrolmentType; label: string }[] = [
  { value: EnrolmentType.AtHome, label: 'At Home' },
  { value: EnrolmentType.InSchool, label: 'In School' },
];

export const EnrolmentLoginTypeList: { value: EnrolmentLoginType; label: string }[] = [
  { value: EnrolmentLoginType.Canvas, label: 'Canvas' },
  { value: EnrolmentLoginType.Classlink, label: 'Classlink' },
  { value: EnrolmentLoginType.Clever, label: 'Clever' },
  { value: EnrolmentLoginType.GoogleClassroom, label: 'Google Classroom' },
  { value: EnrolmentLoginType.Password, label: 'Password' },
  { value: EnrolmentLoginType.Other, label: 'Other' },
];
