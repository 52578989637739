import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import {
  CancellationReason,
  ScheduledLessonLanguage,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonTheme,
  ScheduledLessonType,
  SubRequired,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { LessonQueryRequest } from '@hoot/hooks/api/lessons/lesson-query-request';
import { ScheduledLessonSetStatus } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';

export interface LessonResponse {
  lessonId: string;
  lessonNumber: number;
  lessonPrefixedNumber: string;
  status: ScheduledLessonStatus;
  cancellationReason: CancellationReason;
  language: ScheduledLessonLanguage;
  duration: number;
  parentEmail: string;
  teacherEmail: string | null;
  startTime: number;
  endTime: number;
  durationInMinutes: number;
  startDate: number;
  type: ScheduledLessonType;
  theme: ScheduledLessonTheme;
  subject: ScheduledLessonSubject;
  teacherName: string | null;
  teacherPrefixedNumber: string | null;
  teacherId: string | null;
  teacherPhone: string | null;
  studentName: string;
  studentPrefixedNumber: string;
  studentId: string;
  parentName: string | null;
  parentPrefixedNumber: string | null;
  parentId: string;
  subRequired: SubRequired;
  accountName: string;
  parentPhone: string;
  studentProfileStatus: StudentStatus;
  enrolment: EnrolmentSummary | null;
  country: string | null;
  lessonSet: LessonSetSummary | null;
}

interface LessonSetSummary {
  id: string;
  lessonSetNumber: number;
  lessonSetPrefixedNumber: string;
  status: ScheduledLessonSetStatus;
}

interface EnrolmentSummary {
  id: string;
  friendlyId: string;
  type: EnrolmentType;
  locationId: string;
}

export interface LessonsResponse {
  count: number;
  page: number;
  pageSize: number;
  lessons: LessonResponse[];
}

async function getLessons(query: LessonQueryRequest) {
  const { data } = await axios.post<LessonsResponse>(`${config.apiUrl}/admin/scheduler/lessons/search`, query);
  return data;
}

export default function useLessonQuery(query: LessonQueryRequest, options?: Omit<UseQueryOptions<LessonsResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.Lessons, query], () => getLessons(query), options);
}
