import { AddCircle, Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import CreateShiftPage from '@hoot/pages/users/teachers/create-shift-wizard/CreateShiftPage';
import { ZIndexLayer } from './enums';
import { TimeSlot } from './styled-components';

interface FreeShiftSlotProps {
  shiftStartTime: DateTime;
  shiftEndTime: DateTime;
}

function FreeShiftSlot(props: FreeShiftSlotProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const setOpenModal = () => {
    setShowModal(true);
  };

  const setCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <TimeSlot
        sx={{ pointerEvents: 'all' }}
        alignItems="flex-start"
        justifyContent="center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Stack>
          {isHovered ? (
            <IconButton sx={{ pointerEvents: 'all', zIndex: ZIndexLayer.Top + 1 }} onClick={setOpenModal}>
              <AddCircle sx={{ color: 'green', fontSize: 32 }} />
            </IconButton>
          ) : null}
        </Stack>
      </TimeSlot>
      {showModal ? (
        <Dialog open={showModal} fullWidth={showModal} maxWidth={'lg'} onClose={setCloseModal}>
          <DialogTitle sx={{ padding: 0 }}>
            <Stack direction="row" justifyContent="flex-end">
              <IconButton onClick={setCloseModal} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
                <Close />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            <CreateShiftPage shiftStartTime={props.shiftStartTime} shiftEndTime={props.shiftEndTime} closeModal={setCloseModal} />
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
}

export default FreeShiftSlot;
