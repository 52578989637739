import { HootHttpHeader } from '@hoot-reading/hoot-core/dist/enums/hoot-http-header';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ONE_MINUTE_IN_MS } from '@hoot/common/constants';
import { useRefreshToken } from '@hoot/hooks/api/auth/useRefreshToken';
import useGetHootEmployee from '@hoot/hooks/api/hoot-employees/useGetHootEmployee';
import { useInterval } from '@hoot/hooks/useInterval';
import { useLocalStorage } from '@hoot/hooks/useLocalStorage';
import { SENTRY_HOOT_SESSION_TAG } from '@hoot/hooks/useSentry';
import { useSessionStorage } from '@hoot/hooks/useSessionStorage';
import { Tokens } from '@hoot/interfaces/auth';
import { HootEmployee } from '@hoot/interfaces/hoot-employee';
import useLogin from '../../hooks/api/auth/useLogin';
import useLogout from '../../hooks/api/auth/useLogout';
import { routes } from '../../routes/routes';
import { useCurrentTime } from '../CurrentTimeContext';
import { HootEmployeeScope } from './enums/hoot-employee.scope';

interface Values {
  tokens?: Tokens;
  hootEmployee?: HootEmployee;
  isAuthenticated?: boolean;
  login: (idToken: string) => Promise<void>;
  logout: (redirectToLoginPage?: boolean, notifyAPI?: boolean) => void;
  scopes: HootEmployeeScope[];
}

const AuthStateContext = React.createContext<Values>(undefined!);

interface Props {
  children: React.ReactNode;
}

export const AuthProvider = (props: Props) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [tokens, setTokens] = useLocalStorage<Tokens | undefined>('USER_TOKEN');
  const [hootEmployee, setHootEmployee] = useLocalStorage<HootEmployee | undefined>('HOOT_EMPLOYEE');
  const [refreshInProgress, setRefreshInProgress] = useState(false);
  const { getCurrentTime } = useCurrentTime();
  const refreshTokenMutation = useRefreshToken();
  const [scopes, setScopes] = useState<HootEmployeeScope[]>([]);
  const loginMutation = useLogin();
  const logoutMutation = useLogout();
  const navigate = useNavigate();
  const [hootBrowserSessionId] = useSessionStorage<string>('hootSessionId', uuidv4());
  const loggingInterceptorHandle = useRef<number | undefined>(undefined);

  useEffect(() => {
    const accessToken = tokens?.accessToken ?? '';
    if (accessToken) {
      const decodedAccessToken = jwtDecode<{ scope: HootEmployeeScope[] }>(accessToken);
      const scopes = decodedAccessToken?.scope;
      setScopes(scopes);
    } else {
      setScopes([]);
    }
  }, [tokens]);

  useEffect(() => {
    if (hootEmployee) {
      const hootEmployeeData: HootEmployee = {
        ...hootEmployee,
        canGrantPermissions: scopes.includes(HootEmployeeScope.GrantPermissions),
        canUpdateBooks: scopes.includes(HootEmployeeScope.UpdateBook),
        canViewLessonVideos: scopes.includes(HootEmployeeScope.ViewLessonVideos),
        canAccessReportingPage: scopes.includes(HootEmployeeScope.AccessReporting),
        canAdvancedEditLessonTeacher: scopes.includes(HootEmployeeScope.AdvancedEditLessonTeacher),
        canAdvancedEditLessonDetails: scopes.includes(HootEmployeeScope.AdvancedEditLessonDetails),
        canAccessInvoicing: scopes.includes(HootEmployeeScope.AccessInvoicing),
        canManageLessonReviews: scopes.includes(HootEmployeeScope.ManageLessonReviews),
        canImportTeacherReliabilityScore: scopes.includes(HootEmployeeScope.ImportTeacherReliabilityScoreImport),
        canManageAdvancedStudentData: scopes.includes(HootEmployeeScope.AdvancedStudentData),
        canViewDistrictDetails: scopes.includes(HootEmployeeScope.ViewDistrictDetails),
        canManageStudentDetails: scopes.includes(HootEmployeeScope.ManageStudentDetails),
        canManageTeacherDetails: scopes.includes(HootEmployeeScope.ManageTeacherDetails),
        canManageDistrictReps: scopes.includes(HootEmployeeScope.ManageDistrictReps),
        canAccessBillingInformation: scopes.includes(HootEmployeeScope.AccessBillingInformation),
        canDeleteAssessments: scopes.includes(HootEmployeeScope.DeleteAssessments),
      };
      setHootEmployee(hootEmployeeData);
    }
  }, [scopes]); // eslint-disable-line react-hooks/exhaustive-deps

  const isAccessTokenExpired = useCallback(() => {
    if (tokens) {
      const decodedAccessToken = jwtDecode<JwtPayload>(tokens.accessToken);
      const timeRemainingInMs = decodedAccessToken.exp! * 1000 - getCurrentTime();
      return timeRemainingInMs <= 0;
    }
    return true;
  }, [tokens, getCurrentTime]);

  const isRefreshTokenExpired = useCallback(() => {
    if (tokens && tokens.refreshToken) {
      const decodedRefreshToken = jwtDecode<JwtPayload>(tokens.refreshToken);
      const timeRemainingInMs = decodedRefreshToken.exp! * 1000 - getCurrentTime();
      return timeRemainingInMs <= 0;
    }
    return true;
  }, [tokens, getCurrentTime]);

  const refreshTokens = () => {
    if (tokens) {
      const accessTokenExpired = isAccessTokenExpired();
      const refreshTokenExists = !!tokens.refreshToken;
      const refreshTokenExpired = isRefreshTokenExpired();
      const shouldAttemptRefresh = accessTokenExpired && refreshTokenExists && !refreshTokenExpired;

      if (shouldAttemptRefresh && !refreshInProgress) {
        setRefreshInProgress(true);
        refreshTokenMutation.mutate(tokens?.refreshToken, {
          onSuccess: (data) => {
            setTokens(data);
          },
          onError: (err) => {
            if (err.response?.status === 401) {
              // refresh token itself was invalid
              logout(true, false);
            }
          },
          onSettled: () => {
            setRefreshInProgress(false);
          },
        });
      } else if (accessTokenExpired && refreshTokenExpired) {
        logout(true, false);
      }
    }
  };

  useInterval(() => {
    refreshTokens();
  }, 5000);

  useEffect(() => {
    if (tokens) {
      const isExpired = isAccessTokenExpired();
      if (!isExpired) {
        setIsAuthenticated(true);
        setAuthHeader(tokens);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [isAccessTokenExpired, tokens]);

  useEffect(() => {
    axios.defaults.headers.common['hoot-session-id'] = hootBrowserSessionId;
    if (Sentry.isInitialized()) {
      Sentry.setTag(SENTRY_HOOT_SESSION_TAG, hootBrowserSessionId);
    }
  }, [hootBrowserSessionId]);

  useEffect(() => {
    if (loggingInterceptorHandle.current !== undefined) {
      axios.interceptors.request.eject(loggingInterceptorHandle.current);
    }

    loggingInterceptorHandle.current = axios.interceptors.request.use(
      (config) => {
        config.headers[HootHttpHeader.RequestId] = uuidv4();
        config.headers[HootHttpHeader.UserId] = hootEmployee?.id;
        // Employees aren't really a 'Profile Type' and don't have a distinct profile id,
        // but to keep a consistent set of logging headers, we'll set the values to EMPLOYEE and the userId
        config.headers[HootHttpHeader.ProfileType] = hootEmployee ? 'EMPLOYEE' : undefined;
        config.headers[HootHttpHeader.ProfileId] = hootEmployee?.id;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }, [hootEmployee]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  useGetHootEmployee(hootEmployee?.id!, {
    enabled: !!tokens?.accessToken && isAuthHeaderSet(),
    refetchInterval: ONE_MINUTE_IN_MS,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    cacheTime: 0,
    onSuccess: (hootEmployee) => {
      forceLogoutIfDisabled(hootEmployee);
    },
    onError: () => console.error('hoot employee fetch for disabled check failed'),
  });

  const forceLogoutIfDisabled = (hootEmployee: HootEmployee) => {
    if (!hootEmployee.isEnabled) {
      logout();
    }
  };

  function setAuthHeader(tokens: Tokens) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`;
  }

  function isAuthHeaderSet(): boolean {
    return !!axios.defaults.headers.common['Authorization'];
  }

  const login = async (idToken: string) => {
    await loginMutation.mutateAsync(
      {
        googleAccessToken: idToken,
      },
      {
        onSuccess: (data) => {
          setHootEmployee(data.hootEmployee);
          setTokens(data.tokens);
          Sentry.setUser({
            id: data.hootEmployee.id,
            email: data.hootEmployee.email,
          });
        },
      },
    );
  };

  const logout = (redirectToLoginPage = true, notifyAPI = true) => {
    if (notifyAPI) {
      logoutMutation.mutate(undefined, {
        onSettled: () => {
          clearTokens();
          if (redirectToLoginPage) {
            navigate(routes.home.url);
          }
        },
      });
    } else {
      clearTokens();
      if (redirectToLoginPage) {
        navigate(routes.home.url);
      }
    }
  };

  const clearTokens = () => {
    setTokens(undefined);
    setHootEmployee(undefined);
    setIsAuthenticated(false);
    setScopes([]);
    window.localStorage.clear();
  };

  refreshTokens(); // Initial call so we don't wait 5 seconds on initial load if tokens are expired

  return (
    <AuthStateContext.Provider
      value={{
        isAuthenticated,
        tokens,
        hootEmployee,
        login,
        logout,
        scopes,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
