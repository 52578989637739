import { TeacherCertificationArea } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-certification-area.enum';
import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { teacherCertificationAreasOptions, teacherCertificationAreasTypes } from '../../../../interfaces/teacher';
import FilterDropDown from '../../FilterDropDown';

export function CertificationAreasFilterDropDown(props: {
  value?: TeacherCertificationArea[];
  onChange: (val: TeacherCertificationArea[]) => void;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { value, onChange, popOverButtonSx } = props;

  return (
    <FilterDropDown<string>
      title="Certification Areas"
      dismissPopoverOnSelection={true}
      value={value}
      options={teacherCertificationAreasOptions}
      onChange={(val) => onChange(val as TeacherCertificationArea[])}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveCertificationAreasFilterDropDown(props: {
  value?: TeacherCertificationArea[];
  onChange: (val: TeacherCertificationArea[]) => void;
}) {
  const { value = [], onChange } = props;

  const handleDelete = (val: TeacherCertificationArea) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`Certification Area: ${teacherCertificationAreasTypes[c]}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}
