import { Check } from '@mui/icons-material';
import { Checkbox, Chip, FormControlLabel, FormGroup, FormHelperText, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import React, { Fragment } from 'react';
import { ScheduleCombinationSlot } from '@hoot/pages/district-schools/details/enrolments/capacity/EnrolmentCapacity';
import { daysOfWeekLabels } from '@hoot/pages/lessons/utils/date';
import { DayOfWeek } from '@hoot/utils/dateTime';
import { TimePicker2 } from '../../../../../components/form/DatePicker';

const daysOfWeek: ScheduleCombinationSlot[] = [
  { dayOfWeek: DayOfWeek.Sunday, time: '' },
  { dayOfWeek: DayOfWeek.Monday, time: '' },
  { dayOfWeek: DayOfWeek.Tuesday, time: '' },
  { dayOfWeek: DayOfWeek.Wednesday, time: '' },
  { dayOfWeek: DayOfWeek.Thursday, time: '' },
  { dayOfWeek: DayOfWeek.Friday, time: '' },
  { dayOfWeek: DayOfWeek.Saturday, time: '' },
];

function DaysOfWeekCombination(props: {
  selectedDaySlots: ScheduleCombinationSlot[];
  setSelectedDaySlots: React.Dispatch<React.SetStateAction<ScheduleCombinationSlot[]>>;
  sameTimeEveryDay: string;
  setSameTimeEveryDay: React.Dispatch<React.SetStateAction<string>>;
  defaultTime: DateTime;
  errorMessage: string;
}) {
  const { selectedDaySlots, setSelectedDaySlots, sameTimeEveryDay, setSameTimeEveryDay, defaultTime, errorMessage } = props;

  const handleClick = (dayOfWeek: DayOfWeek) => () => {
    if (selectedDaySlots.find((dow) => dow.dayOfWeek === dayOfWeek)) {
      const updatedValue = selectedDaySlots.filter((v) => v.dayOfWeek !== dayOfWeek);
      setSelectedDaySlots(updatedValue);
      return;
    } else {
      const updatedValue = [...selectedDaySlots, { dayOfWeek, time: defaultTime.toFormat('HH:mm') }];
      setSelectedDaySlots(updatedValue);
      return;
    }
  };

  const handleTimeChange = (time: string, dayOfWeek: DayOfWeek) => {
    const updatedValue = selectedDaySlots?.map((v) =>
      v.dayOfWeek === dayOfWeek
        ? {
            ...v,
            time: time,
          }
        : v,
    );
    setSelectedDaySlots(updatedValue);
  };

  const handleSameTimeChange = (time: string) => {
    setSameTimeEveryDay(time);
  };

  const handleSameTimeEverydayChecked = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSameTimeEveryDay(sameTimeEveryDay ? sameTimeEveryDay : defaultTime.toFormat('HH:mm'));
    } else {
      setSameTimeEveryDay('');
    }
  };

  return (
    <Stack>
      <Stack direction="row" spacing="8px">
        {daysOfWeek.map((dow) => (
          <Chip
            key={dow.dayOfWeek}
            icon={selectedDaySlots.find((d) => dow.dayOfWeek === d.dayOfWeek) ? <Check /> : undefined}
            sx={{
              backgroundColor: selectedDaySlots.find((d) => dow.dayOfWeek === d.dayOfWeek) ? '#D8E3F8' : '#FFFFFF',
            }}
            variant="outlined"
            label={daysOfWeekLabels.get(dow.dayOfWeek)}
            onClick={handleClick(dow.dayOfWeek)}
          />
        ))}
      </Stack>
      {!!errorMessage ? <FormHelperText error>{errorMessage}</FormHelperText> : null}
      <FormGroup sx={{ marginTop: '12px', width: '250px;' }}>
        <FormControlLabel control={<Checkbox onChange={handleSameTimeEverydayChecked} checked={!!sameTimeEveryDay} />} label="Same time every day" />
      </FormGroup>
      <Stack alignItems="center" sx={{ marginTop: '24px' }} direction="row" spacing="24px">
        {!!sameTimeEveryDay ? (
          <TimePicker2
            label="Lesson Time*"
            value={DateTime.fromFormat(sameTimeEveryDay, 'HH:mm') ?? ''}
            onChange={(val) => handleSameTimeChange(val?.toFormat('HH:mm') ?? '')}
          />
        ) : (
          daysOfWeek
            .filter((dow) => selectedDaySlots.find((d) => dow.dayOfWeek === d.dayOfWeek))
            .map((dow) => {
              return (
                <Fragment key={`weekday-${dow.dayOfWeek}`}>
                  <TimePicker2
                    label={daysOfWeekLabels.get(dow.dayOfWeek)}
                    value={DateTime.fromFormat(selectedDaySlots.find((d) => d.dayOfWeek === dow.dayOfWeek)?.time ?? '0:00', 'HH:mm')}
                    onChange={(val) => {
                      handleTimeChange(val?.toFormat('HH:mm') ?? '', dow.dayOfWeek);
                    }}
                  />
                </Fragment>
              );
            })
        )}
      </Stack>
    </Stack>
  );
}

export default DaysOfWeekCombination;
