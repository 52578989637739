import { Close, OpenInNewRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Select } from '@hoot/components/form/SelectField';
import { useAlert } from '@hoot/contexts/AlertContext';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import {
  InvoicePeriodDetailsResponse,
  TeacherInvoiceBillableTasksResponse,
} from '@hoot/hooks/api/teacher-invoice/useSearchTeacherInvoiceBillableTasks';
import useUpdateTeacherInvoiceBillableTask, {
  UpdateInvoiceBillableTaskDto,
} from '@hoot/hooks/api/teacher-invoice/useUpdateTeacherInvoiceBillableTask';
import { ClosedTeacherInvoiceStatuses, InvoicePeriodStatus, InvoiceProjectId } from '@hoot/pages/teacher-invoices/enums/enums';
import { InvoiceLineItemStatus } from '@hoot/pages/teacher-invoices/enums/invoice-line-item-status.enum';
import { routes } from '@hoot/routes/routes';
import { capitalizeFirstLetter } from '@hoot/utils/stringUtils';

interface Form {
  status: InvoiceLineItemStatus;
  reviewerComments: string | null;
  total?: string;
}

interface IConfirmDeleteDialogProps {
  open: boolean;
  onCancel: () => void;
  task: TeacherInvoiceBillableTasksResponse;
  invoicePeriod: InvoicePeriodDetailsResponse;
}

const CardLine = (props: { title: string; bodyText: string; path?: string }) => {
  return (
    <Stack direction="row" gap={3} justifyContent="space-between" alignItems="center" sx={{ height: 50, paddingRight: 2 }}>
      <Stack>
        <Typography variant="titleSmall" sx={{ color: '#000' }}>
          {props.title}
        </Typography>
        <Typography variant="bodyMedium" sx={{ color: '#000' }}>
          {props.bodyText}
        </Typography>
      </Stack>
      {props.path ? (
        <a href={props.path} target="_blank" rel="noopener noreferrer">
          <IconButton>
            <OpenInNewRounded />
          </IconButton>
        </a>
      ) : null}
    </Stack>
  );
};

const TeacherInvoiceTaskDetailsModal = (props: IConfirmDeleteDialogProps) => {
  const { open, onCancel, task, invoicePeriod } = props;
  const { success, error } = useAlert();
  const isPeriodFinalized = task.invoicePeriodStatus === InvoicePeriodStatus.Submitted;
  const isInvoiceSubmitted = ClosedTeacherInvoiceStatuses.includes(task.invoiceStatus);
  // A period will remain IN_PROGRESS until finalized by ops. We don't want to allow review of tasks
  // until after the period end date is past (isPeriodPast)
  const isBillablePeriodOpen = invoicePeriod.status === InvoicePeriodStatus.InProgress && !invoicePeriod.isPeriodPast;
  const isEditDisabled = isBillablePeriodOpen || isPeriodFinalized || !isInvoiceSubmitted;
  const isApprovedAdminTime = task.projectId === InvoiceProjectId.ApprovedAdminTime;

  const {
    formState: { isDirty },
    handleSubmit,
    watch,
    control,
  } = useForm<Form>({
    defaultValues: {
      status: task.status,
      reviewerComments: task.reviewerComments,
      total: !isNaN(task.total) ? task.total?.toFixed(2) : undefined,
    },
  });

  const { status } = watch();
  const updateBillableTask = useUpdateTeacherInvoiceBillableTask(task.id);
  const queryClient = useQueryClient();

  const onSubmit: SubmitHandler<Form> = (data: Form) => {
    const request: UpdateInvoiceBillableTaskDto = {
      status: data.status,
      reviewerComments: data.reviewerComments ?? null,
      total: Number(data.total),
    };
    updateBillableTask.mutate(request, {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.SearchTeacherInvoiceBillableTasks);
        success('Billable Task Successfully Updated');
        onCancel();
      },
      onError: () => {
        error('There was an error while updating billable task');
      },
    });
  };

  return (
    <form>
      <Dialog open={open} onClose={onCancel} scroll="paper">
        <Stack sx={{ padding: '24px', position: 'relative', minWidth: 500 }} gap={3}>
          <DialogTitle sx={{ padding: 0 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5">Task Details</Typography>
              <IconButton onClick={onCancel} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
                <Close />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <Stack gap={2}>
              <CardLine title="Teacher" bodyText={task.teacherName || ''} path={routes.users.teachers.details.url(task.teacherId)} />
              <CardLine title="Project" bodyText={task.project || ''} />
              {!isApprovedAdminTime ? (
                <CardLine
                  title="Amount"
                  bodyText={
                    task.total.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }) || ''
                  }
                />
              ) : null}
              <CardLine title="Date" bodyText={task.date || ''} />
              {task.lesson ? <CardLine title="Lesson" bodyText={task.lesson?.toString() || 'None'} /> : null}
              {task.duration ? <CardLine title="Duration" bodyText={task.duration.toString() || 'None'} /> : null}
              <CardLine title="Notes" bodyText={task.teacherComments || 'None'} />
              <FormControl size="medium" variant="outlined" fullWidth disabled={isEditDisabled}>
                <InputLabel id="status">Status*</InputLabel>
                <Select control={control} name="status" required label="Status" labelId="status">
                  {Object.values(InvoiceLineItemStatus).map((status) => (
                    <MenuItem key={status} value={status}>
                      {capitalizeFirstLetter(status)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {status === InvoiceLineItemStatus.Rejected ? (
                <Controller
                  name="reviewerComments"
                  control={control}
                  rules={{
                    required: 'Reason is required!',
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      value={field?.value ?? ''}
                      fullWidth
                      rows={5}
                      label="Reason"
                      multiline
                      variant="outlined"
                      size="medium"
                      helperText={fieldState.error?.message}
                      error={!!fieldState.error?.message}
                      disabled={isEditDisabled}
                    />
                  )}
                />
              ) : null}
              {isApprovedAdminTime ? (
                <Controller
                  name="total"
                  control={control}
                  rules={{
                    required: status === InvoiceLineItemStatus.Approved ? 'Pay for Service is required!' : false,
                    min: { value: 0, message: 'Minimum value is 0.' },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      value={field?.value ?? ''}
                      type="number"
                      fullWidth
                      label="Pay for Service"
                      variant="outlined"
                      size="medium"
                      helperText={fieldState.error?.message}
                      error={!!fieldState.error?.message}
                      disabled={isEditDisabled}
                    />
                  )}
                />
              ) : null}
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ padding: 0 }}>
            <Stack direction="row" justifyContent="flex-end" gap={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
              <Button fullWidth size="large" variant="contained" disabled={!isDirty || !isInvoiceSubmitted} onClick={handleSubmit(onSubmit)}>
                Apply
              </Button>
            </Stack>
          </DialogActions>
        </Stack>
      </Dialog>
    </form>
  );
};

export default TeacherInvoiceTaskDetailsModal;
