import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { capitalCase } from 'change-case';
import { usePlaces } from '@hoot/hooks/usePlaces';
import FilterDropDown from '../../FilterDropDown';

function ProvinceStateFilter(props: { value?: string[]; onChange: (val: string[]) => void; popOverButtonSx?: ButtonProps['sx'] }) {
  const { onChange, value, popOverButtonSx } = props;
  const { provinceStateOptions } = usePlaces();

  return (
    <FilterDropDown<string>
      title="Province/State"
      dismissPopoverOnSelection={true}
      value={value}
      options={provinceStateOptions}
      onChange={(val) => onChange(val as string[])}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveProvinceStateFilter(props: { value?: string[]; onChange: (val: string[] | undefined) => void }) {
  const { onChange, value = [] } = props;

  const handleDelete = (val: string) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`Province/State: ${capitalCase(c)}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}

export default ProvinceStateFilter;
