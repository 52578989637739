import { TeacherShiftType } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import { DateTime } from 'luxon';
import { FilterSelectionType, IFilterSelection } from '@hoot/components/form/searchFilters/FilterSelects';

export const THIRTY_MINUTES_IN_MILLISECONDS = 1800000;
export const startOfDay = DateTime.now().startOf('day');
export const timeStartDefault = startOfDay.set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
export const timeEndDefault = timeStartDefault.set({ hour: 21, minute: 0, second: 0, millisecond: 0 });

export enum HoverColor {
  Scheduled = '#10519A',
  Completed = '#29702D',
  Cancelled = '#A1221B',
}

export enum ShiftColor {
  Regular = '#E7EDF9',
  Cancelled = '#F9B6A7',
  Interview = '#FAE6FA',
  InterviewDark = '#EDA6ED',
}

export const defaultShiftsFilters: IFilterSelection[] = [
  {
    label: 'Teacher Status',
    queryName: 'teacherStatus',
    values: [
      { label: 'Candidate', queryValue: TeacherStatus.Candidate, isSelected: false },
      { label: 'Active', queryValue: TeacherStatus.Active, isSelected: true },
      { label: 'Inactive', queryValue: TeacherStatus.Inactive, isSelected: false },
    ],
    type: FilterSelectionType.Checkbox,
  },
  {
    label: 'Shifts Today',
    queryName: 'shiftsToday',
    values: [
      { label: 'True', queryValue: 'true', isSelected: true },
      { label: 'False', queryValue: 'false', isSelected: false },
    ],
    type: FilterSelectionType.Boolean,
  },
  {
    label: 'Shift Type',
    queryName: 'shiftType',
    values: [
      { label: 'Regular', queryValue: TeacherShiftType.Regular, isSelected: false },
      { label: 'On Call', queryValue: TeacherShiftType.OnCall, isSelected: false },
      { label: 'Interview', queryValue: TeacherShiftType.Interview, isSelected: false },
    ],
    type: FilterSelectionType.Checkbox,
  },
  {
    label: 'Time Start',
    queryName: 'timeStart',
    values: [{ label: `${timeStartDefault.toFormat('h:mm a')}`, queryValue: `${timeStartDefault.toFormat('h:mm a')}`, isSelected: true }],
    type: FilterSelectionType.Timepicker,
  },
  {
    label: 'Time End',
    queryName: 'timeEnd',
    values: [{ label: `${timeEndDefault.toFormat('h:mm a')}`, queryValue: `${timeEndDefault.toFormat('h:mm a')}`, isSelected: true }],
    type: FilterSelectionType.Timepicker,
  },
  {
    label: 'Show Cancelled',
    queryName: 'showCancelled',
    values: [
      { label: 'True', queryValue: 'true', isSelected: false },
      { label: 'False', queryValue: 'false', isSelected: true },
    ],
    type: FilterSelectionType.Boolean,
  },
];
