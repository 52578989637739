import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import { Close } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { enrolmentTypeMapping } from '../../../hfs-utils';
import FilterDropDown from '../../FilterDropDown';

export function EnrolmentTypeFilterDropDown(props: { value: EnrolmentType[]; onChange: (val: EnrolmentType[]) => void }) {
  const { value, onChange } = props;

  return (
    <FilterDropDown<string>
      title="Type"
      dismissPopoverOnSelection={true}
      value={value}
      options={Object.entries(enrolmentTypeMapping).map(([value, label]) => ({
        label: label,
        value: value,
      }))}
      onChange={(val) => onChange(val as EnrolmentType[])}
    />
  );
}

export function ActiveEnrolmentTypeFilterDropDown(props: { value?: EnrolmentType[]; onChange: (val: EnrolmentType[]) => void }) {
  const { value = [], onChange } = props;

  const handleDelete = (val: EnrolmentType) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-type-${c}`}
          label={`Type: ${enrolmentTypeMapping[c]}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}
