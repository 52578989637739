import { ScheduledLessonStatus, ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';
import { SortOrder } from '@hoot/interfaces/order-by';

export enum BlockLessonOrderBy {
  Number = 'number',
  Type = 'type',
  Time = 'time',
  Duration = 'duration',
  Status = 'status',
}

export interface TeacherBlockLessonsResponse {
  lessons: TeacherBlockLesson[];
}

export interface TeacherBlockLesson {
  id: string;
  number: number;
  preFixedNumber: string;
  type: ScheduledLessonType;
  startsAt: number;
  duration: number;
  status: ScheduledLessonStatus;
}

export interface TeacherBlockLessonsQuery {
  blockId: string;
  sortColumn: BlockLessonOrderBy;
  sortDirection: SortOrder;
}

export const teacherBlockLessonsQueryKey = (query: TeacherBlockLessonsQuery) => {
  return `${query.blockId}-${query.sortColumn}-${query.sortDirection}`;
};

async function getTeacherBlockLessons(query: TeacherBlockLessonsQuery): Promise<TeacherBlockLessonsResponse> {
  const { data } = await axios.get(
    `${config.apiUrl}/admin/teacher-block/${query.blockId}/lessons?sortColumn=${query.sortColumn}&sortDirection=${query.sortDirection}`,
  );
  return data;
}

export default function useGetTeacherBlockLessons(
  query: TeacherBlockLessonsQuery,
  options?: Omit<UseQueryOptions<TeacherBlockLessonsResponse, AxiosError<ErrorResponseDto>>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TeacherBlockLessonsResponse, AxiosError<ErrorResponseDto>> {
  return useQuery([QueryKey.GetTeacherBlockLessons, teacherBlockLessonsQueryKey(query)], () => getTeacherBlockLessons(query), options);
}
