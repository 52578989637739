import { Box, Button, DialogActions, DialogContent, Divider, Stack, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SearchSelect } from '@hoot/components/SearchSelect';
import WarningModal from '@hoot/components/modals/WarningModal';
import useGetDistrictSchools, { DistrictSchoolQuery, SearchDistrictSchoolsQuerySortKeyEnum } from '@hoot/hooks/api/hfs/useGetDistrictSchools';
import { useDistrictRepPermissionsWizardContext } from '@hoot/pages/users/district-reps/district-rep-permissions-wizard/DistrictRepPermissionsContextProvider';

export interface PermissionsWizardForm {
  billableEntityId: string;
}

const BillableEntitySelectionStep = () => {
  const {
    districtRep,
    billableEntityState,
    actions: { attachBillableEntityData, goToNextStep, dismissPermissionsWizard },
  } = useDistrictRepPermissionsWizardContext();

  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

  const districtSchoolQuery: DistrictSchoolQuery = {
    pageSize: 100,
    page: 1,
    isLocation: true,
    orderBy: SearchDistrictSchoolsQuerySortKeyEnum.Name,
  };

  const { data: districtSchoolResponseData } = useGetDistrictSchools(districtSchoolQuery);

  const selectData =
    districtSchoolResponseData?.data.map((location) => ({
      label: location.name,
      value: location.id,
    })) ?? [];

  const { handleSubmit, control, watch } = useForm<PermissionsWizardForm>({
    defaultValues: {
      billableEntityId: billableEntityState?.id,
    },
  });

  const submitBillableEntitySelectionData = (form: PermissionsWizardForm) => {
    const selectedBillableEntity = districtSchoolResponseData?.data.find((entity) => entity.id === form.billableEntityId);
    if (!selectedBillableEntity) {
      return;
    }
    setOpenConfirmationModal(false);
    attachBillableEntityData(selectedBillableEntity.id, selectedBillableEntity.name, selectedBillableEntity.type);
    goToNextStep();
  };

  const handleCancel = () => {
    setOpenConfirmationModal(false);
  };

  const selectedBillableEntityId = watch('billableEntityId');
  const selectedBillableEntity = districtSchoolResponseData?.data.find((entity) => entity.id === selectedBillableEntityId);

  return (
    <form>
      <DialogContent sx={{ pl: 0, pt: 0 }}>
        <Typography variant="titleMedium" sx={{ color: '#000' }}>
          Step 1 - Select Billable Entity
        </Typography>
        <Stack sx={{ pt: 2 }} direction="row">
          <Box sx={{ width: '50%' }}>
            <SearchSelect
              data={selectData}
              control={control}
              name="billableEntityId"
              label={'Billable Entity'}
              required
              disabled={!!billableEntityState}
            />
          </Box>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 0, mt: 3 }}>
        <Stack direction="row" width="100%" justifyContent="flex-end">
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button onClick={dismissPermissionsWizard} color="inherit" variant="outlined" size="large">
              Cancel
            </Button>
            <Button onClick={() => setOpenConfirmationModal(true)} variant="contained" size="large" disabled={!selectedBillableEntityId}>
              Apply and Continue
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
      <WarningModal
        title="Potential Data Privacy Risk"
        subTitle={
          <Fragment>
            <Typography variant="bodyLarge">You have selected to associate</Typography>
            <Typography variant="titleMedium">
              {selectedBillableEntity?.name} <span style={{ fontWeight: 400 }}>to</span> {districtRep?.firstName} {districtRep?.lastName}
            </Typography>
          </Fragment>
        }
        warningText={
          <Fragment>
            <Typography variant="bodyMedium">An incorrect association may share student data with unauthorized parties.</Typography>
            <Typography variant="bodyMedium">Please double-check before pressing continue.</Typography>
          </Fragment>
        }
        open={openConfirmationModal}
        onCancel={() => setOpenConfirmationModal(false)}
        primaryAction={{
          label: 'Continue',
          onClick: handleSubmit(submitBillableEntitySelectionData),
        }}
        secondaryAction={{
          label: 'Go back',
          onClick: handleCancel,
        }}
      />
    </form>
  );
};

export default BillableEntitySelectionStep;
