import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';
import { PartialStudentRequest } from '@hoot/pages/users/students/details/student';

async function createStudentProfile(payload: PartialStudentRequest) {
  const { data } = await axios.post(`${config.apiUrl}/admin/student-profile`, payload);

  return data;
}

export default function useCreateStudentProfile() {
  return useMutation<string, AxiosError, PartialStudentRequest>((data: PartialStudentRequest) => {
    return createStudentProfile(data);
  });
}
