import { CancelOutlined, Close, Search } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import FilterSelects, { IFilter, IFilterSelection } from '@hoot/components/form/searchFilters/FilterSelects';
import { ParentProfileResponse } from '@hoot/hooks/api/hfs/useGetParents';
import { useDebounce } from '@hoot/hooks/useDebounce';
import SelectedFilterChips from '../../../../../components/SelectedFilterChips';
import ParentProfilesCheckBoxTable from '../components/ParentProfilesCheckBoxTable';

const FILTERS_DATA: IFilter[] = [
  {
    label: 'Country',
    options: [{ label: 'Canada' }, { label: 'United States' }],
  },
];

export const FILTERS_DATA_WITH_SELECTION: IFilterSelection[] = FILTERS_DATA.map((fl: IFilter) => ({
  ...fl,
  values: fl.options.map((o) => ({ ...o, isSelected: false })),
}));

const transformFiltersData = (filtersData: IFilterSelection[]): string[] => {
  return filtersData
    .flatMap((fl) => fl.values.filter((v) => v.isSelected).map((v) => ({ name: fl.label, value: v.label })))
    .map((v) => `${v.name}: ${v.value}`);
};

const AddParentModal = (props: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  onApply: (selectedRow: ParentProfileResponse) => void;
  onClose?: () => void;
}) => {
  const { setOpen, open, onApply } = props;
  const [filtersData, setFiltersData] = useState<IFilterSelection[]>(FILTERS_DATA_WITH_SELECTION);
  const selectedValues = transformFiltersData(filtersData);
  const [activeFilters, setActiveFilters] = useState<string[]>(selectedValues);
  const [searchText, setSearchText] = useDebounce();
  const inputRef = useRef<HTMLInputElement>();
  const [selectedRow, setSelectedRow] = useState<ParentProfileResponse>({} as ParentProfileResponse);

  const handleClose = () => {
    handleExitModal();
  };

  const handleClear = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
      setSearchText('');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleExitModal = () => {
    closeAllModals();
    handleClear();
    setFiltersData((current) =>
      current.map((fl) => ({
        ...fl,
        values: fl.values.map((v) => ({ ...v, isSelected: false })),
      })),
    );
    setActiveFilters([]);
    setSelectedRow({} as ParentProfileResponse);
  };

  useEffect(() => {
    const selectedValues = transformFiltersData(filtersData);
    setActiveFilters(selectedValues);
  }, [filtersData]);

  const closeAllModals = () => {
    setOpen(false);
  };

  const handleApply = () => {
    const selectedId = selectedRow.id;
    if (!selectedId) {
      return;
    }
    onApply(selectedRow);
  };

  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth={'lg'} onClose={handleClose}>
        <Box
          sx={{
            backgroundColor: 'white',
            display: 'flex',
            gap: '24px',
            flexDirection: 'column',
            p: 3,
            minWidth: '800px',
            height: '800px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center',
              }}
            >
              <Box sx={{ textAlign: 'left', fontSize: '24px' }}>Add Parent</Box>
              <Close sx={{ '&:hover': { cursor: 'pointer' }, mr: '22px' }} onClick={handleClose} />
            </Box>
            <Box>
              <TextField
                inputRef={inputRef}
                defaultValue={searchText}
                onChange={handleInputChange}
                variant={'outlined'}
                label={`Search for parent`}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search style={{ fill: '#49454F' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClear} disabled={!searchText}>
                        <CancelOutlined
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                          style={{ fill: '#49454F' }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                textTransform: 'none',
                display: 'flex',
              }}
            >
              <FilterSelects filterSelections={filtersData} setFilterSelections={setFiltersData} />
            </Box>

            <Box
              sx={{
                alignSelf: 'center',
                display: 'flex',
                color: '#49454F',
                width: '100%',
              }}
            >
              <SelectedFilterChips
                activeFilters={activeFilters}
                selectedValues={selectedValues}
                filtersData={filtersData}
                setActiveFilters={setActiveFilters}
                setFiltersData={setFiltersData}
              />
            </Box>

            <Box>
              <ParentProfilesCheckBoxTable
                searchText={searchText}
                activeFilters={activeFilters}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
              />
            </Box>
          </Box>
          <Box
            sx={{
              textAlign: 'right',
              pb: 3,
              gap: 2,
              display: 'flex',
              justifyContent: 'flex-end',
              pt: 3,
              borderTop: '1px solid #757575',
              position: 'sticky',
              bottom: '0',
              backgroundColor: 'rgb(255,255,255, 0.8)',
              backdropFilter: 'blur(2px)',
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                padding: '0px',
                gap: '8px',
                p: 1,
                px: 3,
                textTransform: 'none',
                backgroundColor: 'white',
                border: '1px solid #79747E',
                borderRadius: '20px',
                fontWeight: '600',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#f2f2f2',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!selectedRow?.id}
              onClick={handleApply}
              sx={{
                padding: '0px',
                gap: '8px',
                p: 1,
                px: 3,
                color: 'white',
                textTransform: 'none',
                background: '#1976D2',
                ':disabled': {
                  backgroundColor: 'gray',
                  color: 'white',
                },
                borderRadius: '100px',
                fontWeight: '600',
                '&:hover': {
                  backgroundColor: '#1976D2',
                },
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default AddParentModal;
