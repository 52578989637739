import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { ParentDetailsForm } from '@hoot/components/Secure/Users/parent/form';
import { config } from '@hoot/config';

async function updateParentProfile(userId: string, payload: ParentDetailsForm) {
  const { data } = await axios.post(`${config.apiUrl}/admin/parent/${userId}/update`, payload);
  return data;
}

export default function useUpdateParentProfile(userId: string) {
  return useMutation<string, AxiosError, ParentDetailsForm>((data: ParentDetailsForm) => {
    return updateParentProfile(userId, data);
  });
}
