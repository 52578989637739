import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';
import { TeacherReliabilityScoreImportStatus } from './useTRSImportCsv';

export interface TeacherReliabilityScoreImportResponse {
  id: string;
  status: TeacherReliabilityScoreImportStatus;
  savedRows?: number;
  erroredRows?: number;
}

const getImport = async (importId: string): Promise<TeacherReliabilityScoreImportResponse> => {
  const { data } = await axios.get<TeacherReliabilityScoreImportResponse>(`${config.apiUrl}/admin/teacher-reliability-score-import/${importId}`);
  return data;
};

export default function useGetTRSImport(
  importId: string,
  options?: Omit<UseQueryOptions<TeacherReliabilityScoreImportResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetTRSImport, importId], () => getImport(importId), options);
}
