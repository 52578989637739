import { TimelineOutcome } from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FloatingDialog } from '@hoot/components/ui/FloatingDialog';
import { timelineEventIconDictionary, timelineLegendLabel, timelineLessonReviewIconDictionary, timelineOutcomeIconDictionary } from './models';

interface TimelineLegendDialogProps {
  open: boolean;
  onDismiss: () => void;
}

const timelineEvents = Object.entries(timelineEventIconDictionary);
const assessmentOutcomes = Object.entries(timelineOutcomeIconDictionary).filter((o) => o[0] !== TimelineOutcome.Blank);
const lessonReviewFocuses = Object.entries(timelineLessonReviewIconDictionary);

const TimelineLegendDialog = (props: TimelineLegendDialogProps) => {
  return (
    <>
      {props.open && (
        <FloatingDialog title="Legend" width="340px" onClose={props.onDismiss}>
          <Stack gap={2} mt={2}>
            <div>
              <Typography mb={2} fontWeight="bold">
                Events
              </Typography>
              <Stack gap={2}>
                {timelineEvents.map((x) => (
                  <Stack key={`${x[0]}`} flexDirection="row" gap={2}>
                    {x[1]}
                    <Typography>{timelineLegendLabel[x[0]]}</Typography>
                  </Stack>
                ))}
              </Stack>
            </div>

            <div>
              <Typography mb={2} fontWeight="bold">
                Assessment Outcomes
              </Typography>
              <Stack gap={2}>
                {assessmentOutcomes.map((x) => (
                  <Stack key={`${x[0]}`} flexDirection="row" gap={2}>
                    {x[1]}
                    <Typography>{timelineLegendLabel[x[0]]}</Typography>
                  </Stack>
                ))}
              </Stack>
            </div>

            <div>
              <Typography mb={2} fontWeight="bold">
                Lesson Review Focuses
              </Typography>
              <Stack gap={2}>
                {lessonReviewFocuses.map((x) => (
                  <Stack key={`${x[0]}`} flexDirection="row" gap={2}>
                    {x[1]}
                    <Typography>{timelineLegendLabel[x[0]]}</Typography>
                  </Stack>
                ))}
              </Stack>
            </div>
          </Stack>
        </FloatingDialog>
      )}
    </>
  );
};

export default TimelineLegendDialog;
