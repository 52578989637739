import { TeacherShiftCancellationReason, TeacherShiftType } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface UpdateTeacherShiftRequest {
  startsAt: number;
  endsAt: number;
  type: TeacherShiftType;
  cancellationReason?: TeacherShiftCancellationReason;
}

export interface UpdateTeacherShiftResponse {
  isSuccess: boolean;
}

async function updateTeacherShift(shiftId: string, request: UpdateTeacherShiftRequest) {
  const { data } = await axios.put<UpdateTeacherShiftResponse>(`${config.apiUrl}/admin/teacher-shift/${shiftId}`, request);

  return data;
}

export default function useUpdateTeacherShift(
  shiftId: string,
  options?: Omit<UseMutationOptions<UpdateTeacherShiftResponse, AxiosError, UpdateTeacherShiftRequest>, 'queryKey' | 'queryFn'>,
) {
  return useMutation((request) => updateTeacherShift(shiftId, request), options);
}
