import { TeacherBlockCancellationReason, TeacherBlockStatus, TeacherBlockType } from '@hoot-reading/hoot-core/dist/enums/teacher-block';
import { Button, CircularProgress, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DropdownButton from '../../../../components/form/DropdownButton';
import HeaderCard, { HeaderCardProps, HeaderTab } from '../../../../components/ui/HeaderCard';
import { routes } from '../../../../routes/routes';

export interface TeacherBlockForm {
  number: string;
  teacherId: string;
  teacherNumber: string;
  status: TeacherBlockStatus;
  cancellationReason: TeacherBlockCancellationReason;
  type: TeacherBlockType;
  date: number;
  startTime: number;
  endTime: number;
}

export enum MenuItemId {
  DeleteBlock = 'delete-block',
}

const menuItems = (form: TeacherBlockForm) => {
  return [
    {
      id: MenuItemId.DeleteBlock,
      label: <ListItemText>Delete Block</ListItemText>,
      MenuItemProps: { disabled: form.status !== TeacherBlockStatus.Cancelled },
    },
  ];
};

interface BlockHeaderCardProps {
  form?: TeacherBlockForm;
  isLoading?: boolean;
  isSavingChanges?: boolean;
  canSave?: boolean;
  handleSave?: () => void;
  blockNumber: string;
  blockId: string;
  teacherAccountId: string;
  onMenuItemClicked?: (menuItemId: string) => void;
}

export const TeacherBlockHeaderCard = (props: BlockHeaderCardProps) => {
  const { form, isLoading, isSavingChanges, canSave, handleSave, blockNumber, onMenuItemClicked } = props;

  const navigate = useNavigate();

  const tabs: HeaderTab[] = [
    { id: '1', label: 'Details', route: routes.users.teachers.blocks.details.url(props.teacherAccountId, props.blockId) },
    { id: '2', label: 'History', route: routes.users.teachers.blocks.history.url(props.teacherAccountId, props.blockId) },
  ];

  const onTabSelected: HeaderCardProps['onTabSelected'] = (selectedTab) => {
    navigate(selectedTab.route);
    return true;
  };

  return (
    <HeaderCard
      isLoading={isLoading}
      showNavigateBackButton
      canCopySubtitle
      subtitle={`ID: ${blockNumber}`}
      copySubtitleOverride={blockNumber}
      tabs={tabs}
      onTabSelected={onTabSelected}
      actionButtons={
        form && onMenuItemClicked ? (
          <>
            <DropdownButton label="Actions" menuItems={menuItems(form)} onMenuItemClicked={onMenuItemClicked} />
            <Button
              type="button"
              sx={{ marginLeft: '8px' }}
              color="primary"
              variant="contained"
              size="large"
              disabled={!canSave}
              onClick={handleSave}
            >
              {isSavingChanges ? <CircularProgress size={25} color="inherit" /> : 'Save'}
            </Button>
          </>
        ) : null
      }
      title="Block Details"
    />
  );
};
