import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';

export interface UpdateTeacherShiftRequest {
  comment: string;
}

async function createTeacherShiftComment(shiftId: string, request: UpdateTeacherShiftRequest) {
  await axios.put<void>(`${config.apiUrl}/admin/teacher-shift/${shiftId}/comment`, request);
}

export default function useCreateTeacherShiftComment(
  shiftId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, UpdateTeacherShiftRequest>, 'queryKey' | 'queryFn'>,
) {
  return useMutation((request) => createTeacherShiftComment(shiftId, request), options);
}
