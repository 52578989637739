import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { DayOfWeek } from '@hoot/utils/dateTime';

export interface ScheduleCombinationSlotRequest {
  id?: string;
  dayOfWeek: DayOfWeek;
  time: string;
}

export interface ScheduleCombinationRequest {
  id?: string;
  allowed: number;
  combinationSlots: ScheduleCombinationSlotRequest[];
}

export interface UpdateEnrolmentCapacityRequest {
  numberOfAllowedLessonsSets: number;
  scheduleLessonsDuration: number;
  scheduleCombinations: ScheduleCombinationRequest[];
}

const updateEnrolmentCapacity = async (enrolmentId: string, request: UpdateEnrolmentCapacityRequest): Promise<void> => {
  const { data } = await axios.post<void>(`${config.apiUrl}/admin/hfs/enrolments/${enrolmentId}/capacity`, request);
  return data;
};

const useUpdateEnrolmentCapacity = (
  enrolmentId: string,
  options?: Omit<UseMutationOptions<any, AxiosError, UpdateEnrolmentCapacityRequest>, 'mutationFn' | 'mutationKey'>,
) => {
  return useMutation<void, AxiosError, UpdateEnrolmentCapacityRequest>((request) => updateEnrolmentCapacity(enrolmentId, request), options);
};

export default useUpdateEnrolmentCapacity;
