import { CircularProgress, Grid, TableBody, TableCell, TableRow } from '@mui/material';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { StudentHootAssessment } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentHootAssessments';
import { enumToTitleCase } from '@hoot/utils/stringUtils';

interface Props {
  assessments: StudentHootAssessment[] | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAssessment: React.Dispatch<React.SetStateAction<StudentHootAssessment | undefined>>;
  page: number;
  rowsPerPage: number;
}

const displayDate = (assessmentDate: number | undefined): string => {
  if (!assessmentDate) {
    return '';
  }
  const dateTime = DateTime.fromMillis(assessmentDate);
  return dateTime.toFormat('LLLL d, yyyy');
};

const StudentAssessmentsTableBody = (props: Props) => {
  const { assessments, setOpen, setSelectedAssessment, page, rowsPerPage } = props;

  const { state: navigationState } = useLocation();

  const handleSetSelectedAssessment = (assmt?: StudentHootAssessment) => {
    setOpen(true);
    setSelectedAssessment(assmt);
  };

  // If an Assessment Id was provided via navigation options.state,
  // then we automatically open the appropriate Assessment details dialog
  useEffect(() => {
    if (!!navigationState?.hraId && !!assessments?.length) {
      handleSetSelectedAssessment(assessments.find((assmt) => assmt.id === navigationState.hraId));
      // Important to keep - need to clear the navigation state upon 1st render to prevent it from opening the dialog on page reload.
      window.history.replaceState(null, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableBody>
      {!assessments && (
        <TableRow
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <TableCell style={{ borderBottom: 'none' }} component="th" id={'fetchingUsers'} scope="row" padding="normal" width="25%">
            <CircularProgress />
          </TableCell>
        </TableRow>
      )}
      {assessments?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((assessment, i) => {
        return (
          <TableRow
            sx={{
              padding: '18px',
              cursor: 'pointer',
            }}
            hover
            component={motion.tr}
            key={assessment.id + i}
            onClick={() => handleSetSelectedAssessment(assessment)}
          >
            <TableCell sx={{ width: '20%' }}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item> {enumToTitleCase(assessment.status)} </Grid>
              </Grid>
            </TableCell>
            <TableCell sx={{ width: '20%' }}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item> {displayDate(assessment?.createdAt)} </Grid>
              </Grid>
            </TableCell>
            <TableCell sx={{ width: '20%' }}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item> {displayDate(assessment?.updatedAt)} </Grid>
              </Grid>
            </TableCell>
            <TableCell sx={{ width: '20%' }}>
              <>
                {assessment.assessedByTeachers.map((t) => (
                  <Grid key={t.id} container alignItems="center" justifyContent="space-between">
                    {t.prefixedNumber}
                  </Grid>
                ))}
              </>
            </TableCell>
            <TableCell sx={{ width: '20%' }}>
              <Grid container alignItems="center" justifyContent="space-between">
                {assessment.exitPointUnit?.unitName}
              </Grid>
              <Grid container alignItems="center" justifyContent="space-between">
                {assessment.exitPointUnit?.instructionIndicator ? enumToTitleCase(assessment.exitPointUnit.instructionIndicator) : ''}
              </Grid>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default StudentAssessmentsTableBody;
