import { Button, CircularProgress, Grid, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import useCreateLessonsV2, { CreateLessonRequestV2 } from '@hoot/hooks/api/lessons/useCreateLessonV2';
import CreateLessonFooter from '@hoot/pages/lessons/create-v2/CreateLessonFooter';
import { useCreateLessonContext } from '@hoot/pages/lessons/create-v2/context/CreateLessonContextProvider';
import { useStudentAndLessonContext } from '@hoot/pages/lessons/create-v2/context/StudentAndLessonContextProvider';
import { TentativeLessonStatus, useStudentLessonDetailsContext } from '@hoot/pages/lessons/create-v2/context/StudentLessonDetailsProvider';
import ReviewStudentTeacherLessonsCard from '@hoot/pages/lessons/create-v2/review-step/ReviewStudentTeacherLessonsCard';
import { routes } from '@hoot/routes/routes';

const CreateLessonReviewStep = () => {
  const { goToPrevStep, hideStudentName } = useCreateLessonContext();

  const { studentAndLessonData } = useStudentAndLessonContext();
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const student = studentAndLessonData?.studentProfile!;

  const { lessonDetails } = useStudentLessonDetailsContext();
  const lessons = useMemo(() => {
    return {
      withTeacher: lessonDetails.tentativeLessons?.filter((x) => x.status === TentativeLessonStatus.TeacherAssigned) ?? [],
      withoutTeacher: lessonDetails.tentativeLessons?.filter((x) => x.status === TentativeLessonStatus.NoTeacher) ?? [],
    };
  }, [lessonDetails]);

  const navigate = useNavigate();
  const { error, success } = useAlert();

  const createLessonRequest = useCreateLessonsV2({
    onSuccess: (resp) => {
      success('Successfully created lessons.');
      navigate(resp.firstLessonId ? routes.lessons.details.url(resp.firstLessonId) : routes.lessons.url);
    },
    onError: (err) => {
      console.error(err);

      // Show error message from API if exists.
      const errorMessage = err.response?.data.message ?? `An unknown error occurred while creating lesson(s).`;
      error(errorMessage);
    },
  });

  const onSubmitLessons = () => {
    const { studentProfile, subject, lessonType, lessonSetType, isCustomerBilledLesson, language, selectedEnrolment, theme } = studentAndLessonData!;

    const { duration, tentativeLessons, lessonAt, teacher, endAfterDate, recurring } = lessonDetails;

    const lessonDatesWithTeacher =
      tentativeLessons?.filter((x) => x.status === TentativeLessonStatus.TeacherAssigned).map((x) => x.lessonAt.toMillis()) ?? [];
    const lessonDatesWithoutTeacher =
      tentativeLessons?.filter((x) => x.status === TentativeLessonStatus.NoTeacher).map((x) => x.lessonAt.toMillis()) ?? [];

    const request: CreateLessonRequestV2 = {
      studentProfileId: studentProfile.id,
      enrolmentId: selectedEnrolment?.id,
      // The API is smart enough to know whether  we should be adding a new lesson set or using an existing one.
      isLessonSet: lessonSetType === 'NEW_LESSON_SET' || lessonSetType === 'WITHIN_EXISTING_LESSON_SET',
      lessonType: lessonType,
      subject: subject,
      language: language,
      theme: theme,
      isCustomerBillable: isCustomerBilledLesson,
      teacherAccountId: teacher?.id,
      startsAt: lessonAt!.toMillis(),
      endsAt: endAfterDate?.toMillis(),
      durationInMinutes: duration!,
      isRecurring: !!recurring,
      lessonDatesWithTeacher,
      lessonDatesWithoutTeacher,
    };
    createLessonRequest.mutate(request);
  };

  return (
    <>
      <Stack>
        <Grid container spacing={3}>
          {lessons.withTeacher.length ? (
            <Grid item sm={12} md={6}>
              <ReviewStudentTeacherLessonsCard
                student={student}
                teacher={lessonDetails.teacher}
                lessons={lessons.withTeacher}
                hideStudentName={hideStudentName}
              />
            </Grid>
          ) : null}
          {lessons.withoutTeacher.length ? (
            <Grid item sm={12} md={6}>
              <ReviewStudentTeacherLessonsCard
                student={student}
                teacher={undefined}
                lessons={lessons.withoutTeacher}
                hideStudentName={hideStudentName}
              />
            </Grid>
          ) : null}
        </Grid>
        <CreateLessonFooter>
          <Button variant="contained" size="large" onClick={goToPrevStep}>
            Previous Step
          </Button>
          <Button variant="contained" size="large" onClick={onSubmitLessons} disabled={createLessonRequest.isLoading}>
            {createLessonRequest.isLoading ? <CircularProgress color="inherit" size={24} /> : 'Complete'}
          </Button>
        </CreateLessonFooter>
      </Stack>
    </>
  );
};

export default CreateLessonReviewStep;
