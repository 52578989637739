import { ChevronLeftOutlined } from '@mui/icons-material';
import { Box, Breadcrumbs, Button, Card, CardContent, Fade, LinearProgress, Stack, SxProps, Tab, Tabs, Theme, Typography } from '@mui/material';
import React, { FunctionComponent, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb, LinkRouter } from '../Breadcrumb';
import Clipboard from './Clipboard';

export interface HeaderCardProps {
  title: string;
  subtitle?: string;
  subtitleTwo?: string;
  showNavigateBackButton?: boolean;
  /**
   * Triggered when navigate back button has been clicked.
   */
  interceptNavigateBackButtonClicked?: () => boolean;
  /**
   * If the nav back button is clicked, then the user will be redirected to this route. If no value supplied, then
   * natural browser back navigation is executed.
   */
  navigateBackRoute?: string;
  actionButtons?: ReactNode;
  tabs?: HeaderTab[];
  onTabSelected?: (selectedTab: HeaderTab) => boolean;
  canCopySubtitle?: boolean;
  canCopySubtitleTwo?: boolean;
  copySubtitleOverride?: string;
  copySubtitleTwoOverride?: string;
  renderCustomSubHeader?: () => ReactElement;
  breadcrumbs?: Breadcrumb[];
  isLoading?: boolean;
  sx?: SxProps<Theme>;
}

export interface HeaderTab {
  id: string;
  label: ReactNode;
  route: string;
  isHidden?: boolean;
}

const HeaderCard: FunctionComponent<HeaderCardProps> = (props: HeaderCardProps) => {
  const {
    canCopySubtitle,
    canCopySubtitleTwo,
    copySubtitleOverride,
    copySubtitleTwoOverride,
    title,
    subtitle,
    subtitleTwo,
    renderCustomSubHeader,
    showNavigateBackButton,
    interceptNavigateBackButtonClicked,
    navigateBackRoute,
    actionButtons,
    tabs,
    onTabSelected,
    isLoading = false,
    sx,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const defaultSelectedTabId = useMemo<string | undefined>(() => {
    return tabs?.find((x) => x.route === location.pathname)?.id;
  }, [location.pathname, tabs]);

  const [selectedTabId, setSelectedTabId] = useState<string | undefined>(defaultSelectedTabId);

  useEffect(() => {
    setSelectedTabId(defaultSelectedTabId);
  }, [defaultSelectedTabId]);

  const navigateBack = () => {
    if (navigateBackRoute) {
      navigate(navigateBackRoute);
    } else {
      // Note: using -1 might be an issue in the case where a user is created and app page redirected from the create form to the edit/details page
      navigate(-1);
    }
  };

  const handleBackClick = () => {
    if (!interceptNavigateBackButtonClicked || interceptNavigateBackButtonClicked()) {
      navigateBack();
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, selectedTabId: string) => {
    const selectedTab = tabs?.find((x) => x.id === selectedTabId);
    let allowed: boolean = true;

    if (selectedTab) {
      allowed = onTabSelected?.(selectedTab) ?? true;
    }
    if (allowed) {
      setSelectedTabId(selectedTabId);
    }
  };

  return (
    <Card
      sx={{
        width: 1,
        position: 'relative',
        ...(sx ?? {}),
      }}
    >
      <Box
        height="4px"
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        <Fade in={isLoading}>
          <LinearProgress />
        </Fade>
      </Box>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3 }}>
            {showNavigateBackButton && (
              <Button type="button" color="inherit" variant="contained" startIcon={<ChevronLeftOutlined />} onClick={handleBackClick} size="large">
                Back
              </Button>
            )}
            <Stack direction="column" alignItems="flex-start">
              {props.breadcrumbs && props.breadcrumbs.length > 0 ? (
                <Breadcrumbs>
                  {props.breadcrumbs.map((bc) => (
                    <LinkRouter key={bc.label} underline="hover" color="inherit" to={bc.route}>
                      <Typography variant="bodySmall" sx={{ color: '#000000' }}>
                        {bc.label}
                      </Typography>
                    </LinkRouter>
                  ))}
                  <Typography>&nbsp;</Typography>
                </Breadcrumbs>
              ) : null}
              <Typography component="h2" variant="headlineLarge">
                {title}
              </Typography>
              {renderCustomSubHeader ? renderCustomSubHeader() : null}
              {subtitle && (
                <Typography component="h6" variant="bodySmall">
                  {subtitle}
                  {canCopySubtitle ? <Clipboard toCopy={copySubtitleOverride ?? subtitle} iconProps={{ fontSize: 'small' }} /> : null}
                </Typography>
              )}
              {subtitleTwo && (
                <Typography component="h6" variant="bodySmall">
                  {subtitleTwo}
                  {canCopySubtitleTwo ? <Clipboard toCopy={copySubtitleTwoOverride ?? subtitleTwo} iconProps={{ fontSize: 'small' }} /> : null}
                </Typography>
              )}
            </Stack>
          </Box>
          {actionButtons && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>{actionButtons}</Box>}
        </Box>
      </CardContent>
      {tabs && (
        <Tabs value={selectedTabId} onChange={handleTabChange} variant="fullWidth">
          {tabs
            .filter((t) => !t.isHidden)
            .map((tab) => (
              <Tab key={tab.id} label={tab.label} value={tab.id} />
            ))}
        </Tabs>
      )}
    </Card>
  );
};

export default HeaderCard;
