import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogProps, DialogTitle, IconButton, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import { SelectedTeacher } from '@hoot/components/modals/SelectTeacher';
import { LessonSetResponse } from '@hoot/hooks/api/lesson-sets/useGetLessonSet';
import { LessonSetLesson } from '@hoot/hooks/api/lesson-sets/useGetLessonSetLessons';
import useGetTeacherAccount from '@hoot/hooks/api/user/teacher/useGetTeacherAccount';
import { DayAndTimeLessonGrouping } from '@hoot/pages/lesson-sets/details/teacher-assignment-tab/LessonSetTeacherAssignment';
import LessonSetLessonGroupingEditTimeStep from '@hoot/pages/lesson-sets/details/teacher-assignment-tab/lesson-set-wizard-modal/LessonSetLessonGroupingEditTimeStep';
import LessonSetWizardReviewStep from '@hoot/pages/lesson-sets/details/teacher-assignment-tab/lesson-set-wizard-modal/LessonSetWizardReviewStep';
import LessonSetWizardTeacherCheckStep from '@hoot/pages/lesson-sets/details/teacher-assignment-tab/lesson-set-wizard-modal/LessonSetWizardTeacherCheckStep';

interface LessonSetAssignTeacherModalProps {
  show: boolean;
  lessonGroupWizardType?: LessonSetTeacherAssignmentWizardStepId;
  onApply: (updatedLessons: LessonSetLesson[]) => void;
  // e.g. "13:00:00.000-05:00" -> 1:00pm CST
  onApplyTimeChange: (isoTimeOfDay: string) => void;
  onDismiss: () => void;
  student: LessonSetResponse['student'];
  lessonSet: LessonSetResponse;
  selectedLessonGroups: DayAndTimeLessonGrouping[];
  areAllSelectedGroupsUnassigned: boolean;
}

export enum LessonSetTeacherAssignmentWizardStepId {
  TimeSelection = 0,
  TeacherSelectionCheck = 1,
  Review = 2,
}

interface LessonSetWizardStep {
  id: LessonSetTeacherAssignmentWizardStepId;
  stepperLabel: string;
  contentLabel?: string;
  render: (
    state: LessonSetTeacherAssignmentWizardState,
    onCancel: () => void,
    onGoToPreviousStep: () => void,
    onStepCompleted: (newState: LessonSetTeacherAssignmentWizardState) => void,
  ) => ReactNode;
}

export interface LessonSetTeacherAssignmentWizardState {
  lessonSet: LessonSetResponse;
  selectedLessonGroups: DayAndTimeLessonGrouping[];
  selectedTeacher: SelectedTeacher | null;
  selectedTime: DateTime;
  student: LessonSetResponse['student'];
  lessonIdsAssignedToTeacher: Set<string> | undefined;
  isTimeSelection: boolean;
}

const timeSelectionStep: LessonSetWizardStep = {
  id: LessonSetTeacherAssignmentWizardStepId.TimeSelection,
  stepperLabel: 'Set Time',
  render: (state, onCancel, _, onStepCompleted) => (
    <LessonSetLessonGroupingEditTimeStep
      state={state}
      onCancel={onCancel}
      onTimeSelected={(selectedTime) => {
        onStepCompleted({
          ...state,
          selectedTime,
        });
      }}
    />
  ),
};

const teacherSelectionCheckStep: LessonSetWizardStep = {
  id: LessonSetTeacherAssignmentWizardStepId.TeacherSelectionCheck,
  stepperLabel: 'Teacher',
  render: (state, onCancel, onGoToPreviousStep, onStepCompleted) => (
    <LessonSetWizardTeacherCheckStep
      state={state}
      onCancel={onCancel}
      onPreviousButtonClicked={onGoToPreviousStep}
      onTeacherAndLessonsSelected={(selectedTeacher, lessonIdsAssignedToTeacher) => {
        onStepCompleted({
          ...state,
          selectedTeacher,
          lessonIdsAssignedToTeacher,
        });
      }}
    />
  ),
};

const reviewStep: LessonSetWizardStep = {
  id: LessonSetTeacherAssignmentWizardStepId.Review,
  stepperLabel: 'Review',
  contentLabel: 'Review & Confirm',
  render: (state, onCancel, onGoToPreviousStep, onStepCompleted) => (
    <LessonSetWizardReviewStep
      state={state}
      // State doesn't change when this step is completed.
      onApplyChanges={() => onStepCompleted(state)}
      onPreviousButtonClicked={onGoToPreviousStep}
      onCancel={onCancel}
    />
  ),
};

const getLessonSetWizardSteps = (isTimeSelection: boolean, areAllSelectedGroupsUnassigned: boolean): LessonSetWizardStep[] => [
  ...(isTimeSelection ? [timeSelectionStep] : []),
  ...((isTimeSelection && !areAllSelectedGroupsUnassigned) || !isTimeSelection ? [teacherSelectionCheckStep] : []),
  ...((isTimeSelection && !areAllSelectedGroupsUnassigned) || !isTimeSelection ? [reviewStep] : []),
];

const LessonSetWizardModal = (props: LessonSetAssignTeacherModalProps) => {
  const {
    show,
    onDismiss,
    onApply,
    onApplyTimeChange,
    student,
    lessonSet,
    selectedLessonGroups: _selectedLessonGroups,
    lessonGroupWizardType,
    areAllSelectedGroupsUnassigned,
  } = props;

  const [currentStepId, setCurrentStepId] = useState<LessonSetTeacherAssignmentWizardStepId>(
    lessonGroupWizardType ?? LessonSetTeacherAssignmentWizardStepId.TeacherSelectionCheck,
  );
  const [selectedTeacher, setSelectedTeacher] = useState<SelectedTeacher | null>(null);
  const [selectedLessonGroups, setSelectedLessonGroups] = useState<DayAndTimeLessonGrouping[]>(_selectedLessonGroups);
  const [selectedTime, setSelectedTime] = useState<DateTime>(
    selectedLessonGroups[0]?.isoTimeOfDay ? DateTime.fromISO(selectedLessonGroups[0]?.isoTimeOfDay) : DateTime.now(),
  );
  const [lessonIdsAssignedToTeacher, setLessonIdsAssignedToTeacher] = useState<Set<string>>();
  const isTimeSelection = lessonGroupWizardType === LessonSetTeacherAssignmentWizardStepId.TimeSelection;

  const teacherId = selectedLessonGroups.flatMap((x) => x.lessons).find((x) => x.teacher)?.teacher?.id;
  useGetTeacherAccount(teacherId!, {
    enabled: !!(teacherId && isTimeSelection),
    onSuccess: (teacher) => {
      if (teacher) {
        setSelectedTeacher({
          id: teacher.id,
          name: teacher.user.firstName + ' ' + teacher.user.lastName,
          number: teacher.prefixedNumber,
        });
      }
    },
  });

  const lessonSetWizardSteps = useMemo(
    () => getLessonSetWizardSteps(isTimeSelection, areAllSelectedGroupsUnassigned),
    [areAllSelectedGroupsUnassigned, isTimeSelection],
  );

  const currentStep = useMemo(() => {
    return lessonSetWizardSteps.find((x) => x.id === currentStepId) ?? lessonSetWizardSteps[0];
  }, [currentStepId, lessonSetWizardSteps]);

  const currentStepNumber = useMemo(() => {
    return lessonSetWizardSteps.findIndex((x) => x.id === currentStepId) ?? lessonSetWizardSteps[0];
  }, [currentStepId, lessonSetWizardSteps]);

  const updateLessonsTime = (updatedTime: DateTime) => {
    if (isTimeSelection && updatedTime) {
      const lessonGroupsWithTimeChange = selectedLessonGroups.map((lessonGroup) => ({
        ...lessonGroup,
        lessons: lessonGroup.lessons
          .filter((lesson) => lesson.status === ScheduledLessonStatus.Scheduled)
          .map((lesson) => {
            // Take the previous lesson _date_, and set the new _time_.
            const newStartDateTime = DateTime.fromMillis(lesson.startsAt).set({
              hour: updatedTime.hour,
              minute: updatedTime.minute,
            });
            return {
              ...lesson,
              startsAt: newStartDateTime.toMillis(),
              endsAt: newStartDateTime.plus({ minute: lesson.durationInMinutes }).toMillis(),
            };
          }),
      }));
      setSelectedLessonGroups(lessonGroupsWithTimeChange);
    }
  };

  const _onDismiss: DialogProps['onClose'] = (event, reason) => {
    // Don't dismiss on backdrop click.
    if (reason && reason === 'backdropClick') return;
    onDismiss();
  };

  const onPreviousButtonClicked = () => {
    if (currentStepId <= 0) {
      return;
    }
    setCurrentStepId(currentStepId - 1);
  };

  const onStepCompleted = (newState: LessonSetTeacherAssignmentWizardState) => {
    if (currentStepId === LessonSetTeacherAssignmentWizardStepId.TimeSelection) {
      if (areAllSelectedGroupsUnassigned) {
        onApplyTimeChange(newState.selectedTime.toISOTime()!);
        onDismiss();
        return;
      }
      setSelectedTime(newState.selectedTime);
      updateLessonsTime(newState.selectedTime);
      setCurrentStepId(currentStepId + 1);
    } else if (currentStepId === LessonSetTeacherAssignmentWizardStepId.TeacherSelectionCheck) {
      setSelectedTeacher(newState.selectedTeacher);
      setLessonIdsAssignedToTeacher(newState.lessonIdsAssignedToTeacher);

      setCurrentStepId(currentStepId + 1);
    } else if (currentStepId === LessonSetTeacherAssignmentWizardStepId.Review) {
      // We're done. Batch up these changes and dismiss the wizard.
      if (isTimeSelection) {
        onApplyTimeChange(selectedTime.toISOTime()!);
        onDismiss();
      }

      // Get all _upcoming_ lessons within the selected lesson groups (we don't want to update lessons that already happened).
      const upcomingLessons = selectedLessonGroups.flatMap((x) => x.lessons).filter((x) => x.status === ScheduledLessonStatus.Scheduled);

      // Assemble upcoming lessons assigned to teacher.
      const lessonsAssignedToTeacher = upcomingLessons
        // lesson IDs should be defined by this point since this is the last step in the wizard.
        .filter((x) => lessonIdsAssignedToTeacher!.has(x.lessonId))
        .map<LessonSetLesson>((x) => {
          return {
            ...x,
            teacher: {
              id: selectedTeacher!.id,
              name: selectedTeacher!.name,
            },
          };
        });

      // Assemble upcoming lessons without a teacher.
      const lessonsWithoutTeacher = upcomingLessons
        .filter((x) => !lessonIdsAssignedToTeacher!.has(x.lessonId))
        .map<LessonSetLesson>((x) => {
          return {
            ...x,
            teacher: undefined,
          };
        });

      onApply([...lessonsAssignedToTeacher, ...lessonsWithoutTeacher]);
      return;
    }
  };

  const wizardState: LessonSetTeacherAssignmentWizardState = {
    lessonSet,
    selectedLessonGroups,
    selectedTeacher,
    selectedTime,
    student,
    lessonIdsAssignedToTeacher,
    isTimeSelection,
  };

  return (
    <Dialog open={show} onClose={_onDismiss} maxWidth="md" fullWidth>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box flex={1}>{isTimeSelection ? 'Edit Time' : 'Teacher Assignment'}</Box>
        <IconButton onClick={onDismiss}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {lessonSetWizardSteps.length > 1 ? (
          <Fragment>
            <Stepper activeStep={currentStep.id} alternativeLabel sx={{ paddingTop: 6, paddingBottom: 6 }}>
              {lessonSetWizardSteps.map((step) => (
                <Step key={step.id}>
                  <StepLabel>{step.stepperLabel}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Typography variant="titleLarge">
              Step {currentStepNumber + 1} - {currentStep.contentLabel ?? currentStep.stepperLabel}
            </Typography>
          </Fragment>
        ) : null}
        <Box mt={2.5}>{currentStep.render(wizardState, onDismiss, onPreviousButtonClicked, onStepCompleted)}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default LessonSetWizardModal;
