import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

enum CardType {
  Parent = 'Parent',
  District = 'District',
  School = 'School',
}

const ActionCard = (props: { type: CardType; setOpen: () => void }) => {
  const { type, setOpen } = props;

  return (
    <Stack
      direction="row"
      onClick={setOpen}
      sx={{ height: 70, background: '#ECF5FF', padding: 3, border: '1px solid #1976D2', borderRadius: '4px', cursor: 'pointer' }}
      justifyContent="space-between"
    >
      <Typography variant="bodyMedium" sx={{ color: '#000' }}>
        {`I would like to link a ${type.toLowerCase()} to this student.`}
      </Typography>
      <Typography variant="labelLarge" sx={{ color: '#1976D2' }}>
        Link {type}
      </Typography>
    </Stack>
  );
};

export function MissingAssociationModal(props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setShowParentsModal: Dispatch<SetStateAction<boolean>>;
  setShowDistrictsModal: Dispatch<SetStateAction<boolean>>;
  setShowSchoolsModal: Dispatch<SetStateAction<boolean>>;
}) {
  const { open, setOpen, setShowParentsModal, setShowDistrictsModal, setShowSchoolsModal } = props;

  function handleClose() {
    setOpen(false);
  }

  function handleOpenParentModal() {
    setShowParentsModal(true);
    setOpen(false);
  }

  function handleOpenDistrictModal() {
    setShowDistrictsModal(true);
    setOpen(false);
  }

  function handleOpenSchoolModal() {
    setShowSchoolsModal(true);
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Stack sx={{ padding: '24px', position: 'relative' }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Oops! Link student to a parent or school</Typography>
            <IconButton onClick={handleClose} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Stack gap={3}>
            <DialogContentText textAlign="left" sx={{ color: '#1C1B1F' }}>
              It looks like you're trying to create a student without linking them to a parent or school. Please link a parent or location to
              continue.
            </DialogContentText>
            <ActionCard type={CardType.Parent} setOpen={handleOpenParentModal} />
            <ActionCard type={CardType.District} setOpen={handleOpenDistrictModal} />
            <ActionCard type={CardType.School} setOpen={handleOpenSchoolModal} />
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button
              sx={{
                fontSize: 14,
              }}
              fullWidth
              size="medium"
              color="inherit"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}

export default MissingAssociationModal;
