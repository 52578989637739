import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { spokenLanguageOptions, spokenLanguageTypes } from '../../../../interfaces/teacher';
import FilterDropDown from '../../FilterDropDown';

export function SpokenLanguagesFilterDropDown(props: {
  value?: SpokenLanguage[];
  onChange: (val: SpokenLanguage[]) => void;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { value, onChange, popOverButtonSx } = props;

  return (
    <FilterDropDown<string>
      title="Spoken Languages"
      dismissPopoverOnSelection={true}
      value={value}
      options={spokenLanguageOptions}
      onChange={(val) => onChange(val as SpokenLanguage[])}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveSpokenLanguagesFilterDropDown(props: { value?: SpokenLanguage[]; onChange: (val: SpokenLanguage[]) => void }) {
  const { value = [], onChange } = props;

  const handleDelete = (val: SpokenLanguage) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`Spoken Language: ${spokenLanguageTypes[c]}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}
