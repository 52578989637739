import { Button, Card, CardContent, Stack, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { defaultRowsPerPage } from '@hoot/common/constants';
import { BookSortableQuery, BookTableSortableFields, handleSortBy, sortBy } from '@hoot/components/ui/SortableLibrary';
import { HeaderData, SelectState, Table } from '@hoot/components/ui/Table';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import { StudentProfileResponse } from '@hoot/hooks/api/end-users/useGetStudentProfile';
import { LibraryOrderByEnum } from '@hoot/hooks/api/library/useQueryLibraryV2';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { useGetStudentInstructionalLibraryUnits } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentInstructionalLibraryUnits';
import { resourceProgressLookup, resourceStateLookup } from '@hoot/hooks/api/user/student/instructional-plan/enums';
import { SortOrder } from '@hoot/interfaces/order-by';
import { OrderBy } from '@hoot/pages/lessons/enums';
import { EditBooksDialog } from '@hoot/pages/users/students/instructional-plan/EditBooksDialog';
import { routes } from '@hoot/routes/routes';
import ConfirmDeleteDialog from '../../../../components/modals/ConfirmDeleteDialog';
import useAddResourcesToFavourites from '../../../../hooks/api/user/student/instructional-plan/useAddResoucesToFavourites';
import useAddResourcesToLessonPlan from '../../../../hooks/api/user/student/instructional-plan/useAddResourcesToLessonPlan';
import useDeleteResourcesFromFavourites from '../../../../hooks/api/user/student/instructional-plan/useDeleteResourcesFromFavourites';
import useDeleteResourcesFromLessonPlan from '../../../../hooks/api/user/student/instructional-plan/useDeleteResourcesFromLessonPlan';
import useGetStudentInstructionalLibrary from '../../../../hooks/api/user/student/instructional-plan/useGetStudentInstrunctionalLibrary';
import useGetStudentInstructionalPlanFavourites from '../../../../hooks/api/user/student/instructional-plan/useGetStudentInstrunctionalPlanFavourites';
import useGetStudentResourcesLessonPlan from '../../../../hooks/api/user/student/instructional-plan/useGetStudentResourcesLessonPlan';
import StudentInstructionalLibraryUnits from '../assessments/StudentInstructionalLibraryUnits';
import { AddBookDialog } from './AddBookDialog';

/** INTERFACES */

interface Props {
  studentProfile?: StudentProfileResponse;
}

interface StudentFavouritesColumns extends BookTableSortableFields {
  id: string;
  selected: boolean;
  bookId: string;
  resourceProgress: string;
  resourceState: string;
  instructionalUnit: string;
}

const studentFavoritesHeaders: HeaderData<StudentFavouritesColumns>[] = [
  { name: 'Book ID', property: 'bookId', isHidden: true },
  { name: 'Book Title', property: 'title', isSortable: true, sortKey: LibraryOrderByEnum.Title },
  { name: 'Book Level', property: 'bookLevel', isSortable: true, sortKey: LibraryOrderByEnum.BookLevel },
  { name: 'Resource Progress', property: 'resourceProgress' },
  { name: 'Resource State', property: 'resourceState' },
  { name: 'Instructional Unit', property: 'instructionalUnit' },
  { property: 'bookNumber', name: 'Number', isSortable: true, sortKey: LibraryOrderByEnum.BookNumber },
];

export interface LessonPlanColumns extends BookTableSortableFields {
  id: string;
  selected: boolean;
  bookId: string;
  resourceProgress: string;
  resourceState: string;
  instructionalUnit: string;
  added: string;
  addedBy: React.ReactNode;
}

const studentLessonPlanHeaders: HeaderData<LessonPlanColumns>[] = [
  { name: 'Book ID', property: 'bookId', isHidden: true },
  { name: 'Book Title', property: 'title', isSortable: true, sortKey: LibraryOrderByEnum.Title },
  { name: 'Book Level', property: 'bookLevel', isSortable: true, sortKey: LibraryOrderByEnum.BookLevel },
  { name: 'Resource Progress', property: 'resourceProgress' },
  { name: 'Resource State', property: 'resourceState' },
  { name: 'Instructional Unit', property: 'instructionalUnit' },
  { name: 'Added', property: 'added' },
  { name: 'Added By', property: 'addedBy' },
  { property: 'bookNumber', name: 'Number', isSortable: true, sortKey: LibraryOrderByEnum.BookNumber },
];

interface InstructionalLibraryColumns extends BookTableSortableFields {
  bookId: string;
  resourceProgress: string;
  resourceState: string;
  instructionalUnit: string;
}

const studentInstructionalLibraryHeaders: HeaderData<InstructionalLibraryColumns>[] = [
  { name: 'Book ID', property: 'bookId', isHidden: true },
  { name: 'Book Title', property: 'title', isSortable: true, sortKey: LibraryOrderByEnum.Title },
  { name: 'Book Level', property: 'bookLevel', isSortable: true, sortKey: LibraryOrderByEnum.BookLevel },
  { name: 'Resource Progress', property: 'resourceProgress' },
  { name: 'Resource State', property: 'resourceState' },
  { name: 'Instructional Unit', property: 'instructionalUnit' },
  { property: 'bookNumber', name: 'Number', isSortable: true, sortKey: LibraryOrderByEnum.BookNumber },
];

/** COMPONENTS */

const StudentInstructionalPlanTab = (props: Props) => {
  const { studentProfile } = props;
  const { scopes } = useAuth();
  const canManageLessonReviews = !!scopes.find((s) => s === HootEmployeeScope.ManageLessonReviews);
  const canManageInstructionaLibrary = !!scopes.find((s) => s === HootEmployeeScope.ManageInstructionalLibraries);

  if (!studentProfile) {
    return null;
  }

  return (
    <Stack spacing="16px">
      <LessonPlanCard studentProfile={studentProfile} canManageLessonReviews={canManageLessonReviews} />
      <InstructionalLibraryCard studentProfile={studentProfile} canManageInstructionalLibrary={canManageInstructionaLibrary} />
      <StudentFavouritesCard studentProfile={studentProfile} canManageLessonReviews={canManageLessonReviews} />
    </Stack>
  );
};

const StudentFavouritesCard = (props: { studentProfile: StudentProfileResponse; canManageLessonReviews: boolean }) => {
  const [query, setQuery] = useState<BookSortableQuery>({
    page: 1,
    pageSize: defaultRowsPerPage,
  });

  const [selected, setSelected] = useState<string[]>([]);
  const [showAddBook, setShowAddBook] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { data, isFetching } = useGetStudentInstructionalPlanFavourites(props.studentProfile.id, query);
  const addResourcesToFavouritesMutation = useAddResourcesToFavourites(props.studentProfile.id);
  const deleteResourcesFromFavouritesMutation = useDeleteResourcesFromFavourites(props.studentProfile.id);
  const queryClient = useQueryClient();
  const alerts = useAlert();

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setSelected([]);
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize: pageSize }));
  };

  const handleAddBookClick = () => {
    setShowAddBook(true);
  };

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const handleSelectAll = (selectState: SelectState) => {
    if (selectState === 'all') {
      setSelected([]);
    } else {
      setSelected(data?.data.map((d) => d.bookId) ?? []);
    }
  };

  const handleSelect = (val: StudentFavouritesColumns, selected: boolean) => {
    if (selected) {
      setSelected((currentState) => [...currentState, val.id]);
    } else {
      setSelected((currentState) => currentState.filter((s) => s !== val.id));
    }
  };

  /** Add Book Dialog */

  const handleAddBookDialogApply = (bookIds: string[]) => {
    addResourcesToFavouritesMutation.mutate(
      {
        bookIds: bookIds,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKey.GetStudentInstructionalPlanFavourites]);
          setShowAddBook(false);
          alerts.success(`Successfully added ${bookIds.length} resource(s) to student's favourites`);
        },
        onError: () => {
          alerts.error("There was an issue adding books to student's favourites.");
        },
      },
    );
  };

  const handleAddBookDialogClose = () => {
    setShowAddBook(false);
  };

  /** Delete Confirmation Dialog */

  const handleOnContinueDeleteDialog = () => {
    deleteResourcesFromFavouritesMutation.mutate(
      {
        bookIds: selected,
      },
      {
        onSuccess: () => {
          setSelected([]);
          queryClient.invalidateQueries([QueryKey.GetStudentInstructionalPlanFavourites]);
          setShowDeleteConfirmation(false);
          alerts.success(`Successfully deleted ${selected.length} resource(s) from student's favourites`);
        },
        onError: () => {
          alerts.error("There was an issue deleting books from student's favourites.");
        },
      },
    );
  };

  const handleOnCancelDeleteDialog = () => {
    setShowDeleteConfirmation(false);
  };

  const tableData =
    data?.data.map<StudentFavouritesColumns>((d) => ({
      id: d.bookId,
      selected: selected.findIndex((s) => s === d.bookId) >= 0,
      bookId: d.bookId,
      title: <Link to={routes.library.book.url(d.bookId)}>{d.bookTitle}</Link>,
      bookLevel: d.bookLevel,
      instructionalUnit: d.instructionalUnit,
      resourceProgress: resourceProgressLookup[d.resourceProgress],
      resourceState: resourceStateLookup[d.resourceState],
      bookNumber: d.number,
    })) ?? [];

  const count = data?.count ?? 0;
  const page = data?.page ?? 0;
  const rowsPerPage = data?.pageSize ?? 0;

  return (
    <>
      <Card>
        <CardContent>
          <Stack spacing="16px">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="titleLarge" sx={{ display: 'block', mb: 3 }}>
                Student Favourites
              </Typography>
              <Stack direction="row" spacing="16px">
                {selected.length > 0 ? (
                  <Button onClick={handleDelete} size="large" variant="contained" color="error">
                    Delete {selected.length}
                  </Button>
                ) : null}

                {props.canManageLessonReviews ? (
                  <Button onClick={handleAddBookClick} size="large" variant="contained" color="success">
                    Add Book
                  </Button>
                ) : null}
              </Stack>
            </Stack>
            <Table
              isPaginated
              isSelectable={props.canManageLessonReviews}
              isSortable
              isLoading={isFetching}
              data={tableData}
              headers={studentFavoritesHeaders}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onSelect={props.canManageLessonReviews ? handleSelect : undefined}
              onSelectAll={props.canManageLessonReviews ? handleSelectAll : undefined}
              sortOrder={query.orderBy === SortOrder.ASC ? OrderBy.Asc : OrderBy.Desc}
              sortBy={sortBy()}
              onSortBy={(column) => handleSortBy(column as keyof BookTableSortableFields, setQuery)}
            />
          </Stack>
        </CardContent>
        {showAddBook ? <AddBookDialog onApply={handleAddBookDialogApply} onClose={handleAddBookDialogClose} /> : null}
        {showDeleteConfirmation ? (
          <ConfirmDeleteDialog
            open
            title="Delete Student Favourites?"
            bodyText={`Delete ${selected.length} books from student favourites?`}
            onContinue={handleOnContinueDeleteDialog}
            onCancel={handleOnCancelDeleteDialog}
          />
        ) : null}
      </Card>
    </>
  );
};

const LessonPlanCard = (props: { studentProfile: StudentProfileResponse; canManageLessonReviews: boolean }) => {
  const [query, setQuery] = useState<BookSortableQuery>({
    page: 1,
    pageSize: defaultRowsPerPage,
  });

  const [selected, setSelected] = useState<string[]>([]);
  const [showAddBook, setShowAddBook] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);

  const { data, isFetching } = useGetStudentResourcesLessonPlan(props.studentProfile.id, query);
  const addResourcesToLessonPlanMutation = useAddResourcesToLessonPlan(props.studentProfile.id);
  const deleteResourcesFromLessonPlanMutation = useDeleteResourcesFromLessonPlan(props.studentProfile.id);
  const queryClient = useQueryClient();
  const alerts = useAlert();
  const hasMoreThanTenBooks = selected.length > 10;

  const handleAddBookClick = () => {
    setShowAddBook(true);
  };

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const handleEdit = () => {
    setShowEditDialog(true);
  };

  /** Add Book Dialog */

  const handleAddBookDialogApply = (bookIds: string[]) => {
    addResourcesToLessonPlanMutation.mutate(
      {
        bookIds: bookIds,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKey.GetStudentInstructionalLessonPlan]);
          setShowAddBook(false);
          alerts.success(`Successfully added ${bookIds.length} resource(s) to student's lesson plan`);
        },
        onError: (err) => {
          const errorMessage = (err.response as any)?.data.message ?? `An unknown error occurred while adding resource(s) to lesson plan.`;
          alerts.error(errorMessage);
        },
      },
    );
  };

  const handleAddBookDialogClose = () => {
    setShowAddBook(false);
  };

  /** Delete Confirmation Dialog */

  const handleOnContinueDeleteDialog = () => {
    deleteResourcesFromLessonPlanMutation.mutate(
      {
        bookIds: selected,
      },
      {
        onSuccess: () => {
          setSelected([]);
          queryClient.invalidateQueries([QueryKey.GetStudentInstructionalLessonPlan]);
          setShowDeleteConfirmation(false);
          alerts.success(`Successfully deleted ${selected.length} resource(s) from student's lesson plan`);
        },
        onError: (err) => {
          const errorMessage = (err.response as any)?.data.message ?? `An unknown error occurred while deleting resource(s) from lesson plan.`;
          alerts.error(errorMessage);
        },
      },
    );
  };

  const handleOnCancelDeleteDialog = () => {
    setShowDeleteConfirmation(false);
  };

  const handleOnCancelEditDialog = () => {
    setSelected([]);
    setShowEditDialog(false);
  };

  const tableData =
    data?.data.map<LessonPlanColumns>((d) => ({
      id: d.bookId,
      selected: selected.findIndex((s) => s === d.bookId) >= 0,
      bookId: d.bookId,
      title: <Link to={routes.library.book.url(d.bookId)}>{d.bookTitle}</Link>,
      bookLevel: d.bookLevel,
      instructionalUnit: d.instructionalUnit,
      resourceProgress: resourceProgressLookup[d.resourceProgress],
      resourceState: resourceStateLookup[d.resourceState],
      added: DateTime.fromMillis(d.added).toFormat('dd/LL/yyyy'),
      addedBy: <Link to={routes.users.teachers.details.url(d.addedById)}>{d.addedBy}</Link>,
      bookNumber: d.number,
    })) ?? [];

  /** Table Handlers */

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize: pageSize }));
  };

  const handleSelectAll = (selectState: SelectState) => {
    if (selectState === 'all') {
      setSelected([]);
    } else {
      setSelected(data?.data.map((d) => d.bookId) ?? []);
    }
  };

  const handleSelect = (val: StudentFavouritesColumns, selected: boolean) => {
    if (selected) {
      setSelected((currentState) => [...currentState, val.id]);
    } else {
      setSelected((currentState) => currentState.filter((s) => s !== val.id));
    }
  };

  const count = data?.count ?? 0;
  const page = data?.page ?? 0;
  const rowsPerPage = data?.pageSize ?? 0;

  return (
    <>
      <Card>
        <CardContent>
          <Stack spacing="16px">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="titleLarge" sx={{ display: 'block', mb: 3 }}>
                Lesson Plan
              </Typography>
              <Stack direction="row" spacing="16px">
                {selected.length > 0 ? (
                  <>
                    <Tooltip title={hasMoreThanTenBooks ? 'You can only edit 10 books at a time' : ''}>
                      <span>
                        <Button onClick={handleEdit} size="large" variant="contained" disabled={hasMoreThanTenBooks}>
                          Edit {selected.length}
                        </Button>
                      </span>
                    </Tooltip>
                    <Button onClick={handleDelete} size="large" variant="contained" color="error">
                      Delete {selected.length}
                    </Button>
                  </>
                ) : null}

                {props.canManageLessonReviews ? (
                  <Button onClick={handleAddBookClick} size="large" variant="contained" color="success">
                    Add Book
                  </Button>
                ) : null}
              </Stack>
            </Stack>
            <Table
              isPaginated
              isSelectable={props.canManageLessonReviews}
              isSortable
              data={tableData}
              headers={studentLessonPlanHeaders}
              isLoading={isFetching}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onSelect={props.canManageLessonReviews ? handleSelect : undefined}
              onSelectAll={props.canManageLessonReviews ? handleSelectAll : undefined}
              sortOrder={query.orderBy === SortOrder.ASC ? OrderBy.Asc : OrderBy.Desc}
              sortBy={sortBy()}
              onSortBy={(column) => handleSortBy(column as keyof BookTableSortableFields, setQuery)}
            />
          </Stack>
        </CardContent>
        {showAddBook ? <AddBookDialog onApply={handleAddBookDialogApply} onClose={handleAddBookDialogClose} isLessonPlanBook /> : null}
        {showDeleteConfirmation ? (
          <ConfirmDeleteDialog
            open
            title="Delete From Student Lesson Plan?"
            bodyText={`Delete ${selected.length} books from student favourites?`}
            onContinue={handleOnContinueDeleteDialog}
            onCancel={handleOnCancelDeleteDialog}
          />
        ) : null}
        {showEditDialog ? (
          <EditBooksDialog studentId={props.studentProfile.id} books={data?.data ?? []} selected={selected} onClose={handleOnCancelEditDialog} />
        ) : null}
      </Card>
    </>
  );
};

const InstructionalLibraryCard = (props: { studentProfile: StudentProfileResponse; canManageInstructionalLibrary: boolean }) => {
  const [query, setQuery] = useState<BookSortableQuery>({
    page: 1,
    pageSize: defaultRowsPerPage,
  });

  const { data, isFetching } = useGetStudentInstructionalLibrary(props.studentProfile.id, query);
  const { data: studentModuleData, refetch } = useGetStudentInstructionalLibraryUnits(props.studentProfile.id, {
    onError: (err) => {
      console.error(err);
      error(`Could not fetch student (id: ${props.studentProfile.id}) module data!`);
    },
  });

  const { error } = useAlert();
  const tableData =
    data?.data.map<InstructionalLibraryColumns>((d) => ({
      bookId: d.bookId,
      title: <Link to={routes.library.book.url(d.bookId)}>{d.bookTitle}</Link>,
      bookLevel: d.bookLevel,
      instructionalUnit: d.instructionalUnit,
      resourceProgress: resourceProgressLookup[d.resourceProgress],
      resourceState: resourceStateLookup[d.resourceState],
      bookNumber: d.number,
    })) ?? [];

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize: pageSize }));
  };

  const count = data?.count ?? 0;
  const page = data?.page ?? 0;
  const rowsPerPage = data?.pageSize ?? 0;

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="titleLarge" sx={{ display: 'block', mb: 3 }}>
            Instructional Library
          </Typography>

          {props.canManageInstructionalLibrary && studentModuleData ? (
            <StudentInstructionalLibraryUnits
              instructionalFocusModule={studentModuleData}
              studentId={props.studentProfile.id}
              refetchStudentLibraryInstructionalUnitsFn={refetch}
            />
          ) : null}

          <Table
            isSortable
            isPaginated
            data={tableData}
            headers={studentInstructionalLibraryHeaders}
            isLoading={isFetching}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sortOrder={query.orderBy === SortOrder.ASC ? OrderBy.Asc : OrderBy.Desc}
            sortBy={sortBy()}
            onSortBy={(column) => handleSortBy(column as keyof BookTableSortableFields, setQuery)}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default StudentInstructionalPlanTab;
