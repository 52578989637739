import { Stack, styled } from '@mui/material';
import { CELL_HEIGHT } from '../constants';
import { ZIndexLayer } from '../enums';

export const Label = styled(Stack)({
  boxSizing: 'border-box',
  height: `${CELL_HEIGHT}px`,
  justifyContent: 'center',
  alignItems: 'flex-start',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
});

export const TimeSlot = styled(Stack)({
  boxSizing: 'border-box',
  height: `${CELL_HEIGHT}px`,
  boxShadow: '-1px -1px #CCC, inset -1px -1px 0 0 #CCC',
  zIndex: ZIndexLayer.Bottom,
  backgroundColor: 'transparent',
  pointerEvents: 'none',
});

export const RescheduleTimeSlot = styled(Stack)({
  boxSizing: 'border-box',
  height: `${CELL_HEIGHT}px`,
  boxShadow: '-1px -1px #CCC, inset -1px -1px 0 0 #CCC',
  zIndex: ZIndexLayer.Top,
  backgroundColor: 'transparent',
  cursor: 'pointer',
});

export const BlockedTimeSlot = styled(Stack)({
  boxSizing: 'border-box',
  height: `${CELL_HEIGHT}px`,
  boxShadow: '-1px -1px #757575, inset -1px -1px 0 0 #757575',
  zIndex: ZIndexLayer.Top,
  backgroundColor: 'transparent',
  cursor: 'not-allowed',
});

export const Column = styled(Stack)({
  position: 'relative',
  flex: 1,
  justifyContent: 'space-evenly',
});
