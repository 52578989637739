import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import { MapOutlined } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { hootSupportedCountryMapping } from '@hoot/components/hfs-utils';
import { NoneListItem } from '../details/Shared';

export const CountryRequirementSection = (props: { onChange: (val: HootSupportedCountry) => void; value: HootSupportedCountry }) => {
  const { onChange, value } = props;

  const [showModal, setShowModal] = useState<boolean>(false);

  const onEditClick = () => {
    setShowModal(true);
  };

  return (
    <Grid mt="24px" container item direction="column" xs={12}>
      <Grid container item xs={12}>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Typography component="h3" variant="titleLarge">
              Country Requirements
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={onEditClick} variant="contained" color="primary">
              Edit
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ marginTop: '24px' }} container item xs={12}>
        <List sx={{ width: '100%' }}>
          {props.value ? (
            <ListItem>
              <ListItemIcon>
                <MapOutlined />
              </ListItemIcon>

              <ListItemText primary={hootSupportedCountryMapping[props.value]} />
            </ListItem>
          ) : (
            <NoneListItem />
          )}
        </List>
      </Grid>
      {showModal ? <CountryRequirementsModal onChange={onChange} value={value} open={showModal} onClose={() => setShowModal(false)} /> : null}
    </Grid>
  );
};

const CountryRequirementsModal = (props: {
  value: HootSupportedCountry;
  onChange: (val: HootSupportedCountry) => void;
  open: boolean;
  onClose: () => void;
}) => {
  const { open, onChange, onClose, value } = props;

  const [selectedValue, setSelectedValue] = useState<HootSupportedCountry>(value);

  const handleCheckboxChange = (val: HootSupportedCountry) => (_event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(val);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleApply = () => {
    onChange(selectedValue);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Edit Country Requirements</DialogTitle>
      <DialogContent>
        <FormGroup>
          {Object.entries(hootSupportedCountryMapping).map((p, idx) => (
            <FormControlLabel
              key={`${p[0]}-${idx}`}
              control={
                <Checkbox onChange={handleCheckboxChange(p[0] as HootSupportedCountry)} checked={selectedValue === (p[0] as HootSupportedCountry)} />
              }
              label={p[1]}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button autoFocus onClick={handleCancel} variant="contained" color="inherit">
          Cancel
        </Button>
        <Button onClick={handleApply} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
