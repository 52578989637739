import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { SessionStorageKey } from '@hoot/common/constants';

interface FloatingDialogProps {
  title: string;
  children: React.ReactNode;
  onClose: () => void;
  width: string;
  height?: string;
  dragFromAnywhere?: boolean;
}

interface Coordinates {
  x: number;
  y: number;
}

const initialCoordinates: Coordinates = {
  x: 24,
  y: 24,
};

export function FloatingDialog(props: FloatingDialogProps) {
  // If Session has a key, use those coordinates. Else, just use the initial defaults.
  const coordinatesFromStorage = JSON.parse(
    window.sessionStorage.getItem(SessionStorageKey.FLOATING_DIALOG_POSITION) || JSON.stringify(initialCoordinates),
  );

  const handleOnStop = (_event: DraggableEvent, element: DraggableData) => {
    const coordinates: Coordinates = {
      x: element.x,
      y: element.y,
    };

    // Record the last position to Session Storage.
    window.sessionStorage.setItem(SessionStorageKey.FLOATING_DIALOG_POSITION, JSON.stringify(coordinates));
  };

  return (
    <Draggable handle="#header" defaultPosition={coordinatesFromStorage} onStop={handleOnStop}>
      <Stack
        id={props.dragFromAnywhere ? 'header' : undefined}
        sx={{
          backgroundColor: '#FFF',
          borderRadius: '8px',
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '16px',
          boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
          width: props.width,
          height: props.height,
          zIndex: 1300,
          cursor: props.dragFromAnywhere ? 'move' : 'auto',
        }}
      >
        <Stack
          id={!props.dragFromAnywhere ? 'header' : undefined}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          sx={{ cursor: 'move' }}
        >
          <Typography variant="titleLarge" fontWeight="bold">
            {props.title}
          </Typography>
          {/* Note: onTouchStart needed as react-draggable overlays the button functionality */}
          <IconButton onClick={props.onClose} onTouchStart={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>

        {props.children}
      </Stack>
    </Draggable>
  );
}
