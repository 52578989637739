import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { SortOrder } from '@hoot/interfaces/order-by';
import { GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import { QueryKey } from '../../../queryKeys';

export enum LessonReviewsOrderColumn {
  LessonDate = 'lesson_date',
  LessonNumber = 'lesson_number',
  CreatedBy = 'created_by',
  Subject = 'subject',
  Focus = 'focus',
}

export interface LessonReviewsQuery {
  page: number;
  pageSize: number;
  studentProfileId: string;
  orderColumn: LessonReviewsOrderColumn;
  orderBy: SortOrder;
}

export type LessonReviewsResponse = GenericPaginatedResponse<{
  id: string;
  lessonDate: string;
  lessonNumber: number;
  lessonPrefixedNumber: string;
  lessonId: string;
  teacherAccountId: string;
  subject: string;
  focus: string;
  createdByPrefixedNumber: string;
}>;

async function getLessonReviews(query: LessonReviewsQuery) {
  const { data } = await axios.get<LessonReviewsResponse>(`${config.apiUrl}/admin/lesson-reviews`, { params: query });
  return data;
}

export function useGetLessonReviews(query: LessonReviewsQuery, options?: Omit<UseQueryOptions<LessonReviewsResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.LessonSet, query], () => getLessonReviews(query), options);
}
