import { Card, CircularProgress, Divider, Grid, Typography, TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { Stack } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import React from 'react';
import { Chart } from 'react-google-charts';
import { useParams } from 'react-router-dom';
import { useGetAssessmentMetrics } from '../../../../hooks/api/lesson-sets/metrics/useGetAssessmentMetrics';
import { useGetInstructionsMetrics } from '../../../../hooks/api/lesson-sets/metrics/useGetInstructionsMetrics';
import { useGetScheduleMetrics } from '../../../../hooks/api/lesson-sets/metrics/useGetScheduleMetrics';
import { HootLevelUnitStatus, hootLevelModuleStatusLookup, hootLevelUnitStatusLookup } from '../../../../hooks/api/reporting/hoot-levels/enum';
import { UnitIdentifier, unitLookup } from '../../../../interfaces/assessments-enums';

const LessonSetQualityMonitoring = () => {
  return (
    <Stack>
      <ScheduleMetricsContainer />
      <AssessmentMetricsContainer />
      <InstructionsMetricsContainer />
    </Stack>
  );
};

const ScheduleMetricsContainer = () => {
  const { lessonSetId } = useParams() as { lessonSetId: string };
  const { data, isFetching } = useGetScheduleMetrics(lessonSetId);

  if (isFetching || !data) {
    return <CircularProgress />;
  }

  return (
    <Stack>
      <Typography variant="headlineSmall">Schedule Metric</Typography>
      <Grid marginTop="24px" spacing="24px" container>
        <Grid item xs={4}>
          <Card sx={{ padding: '24px' }}>
            <Typography variant="titleLarge">Set Progress</Typography>

            <Stack marginTop="24px" direction="row" justifyContent="flex-start">
              <Stack flex={1}>
                <Info label="Scheduled Lessons" value={`${data.setProgress.scheduledLessons}`} />
                <Info marginTop="16px" label="Occurred Lessons" value={`${data.setProgress.occurredLessons}`} />
                <Info marginTop="16px" label="Set Progress" value={`${data.setProgress.setProgressPercentage?.toFixed(0) ?? 0}%`} />
              </Stack>
              <Stack alignItems="center" justifyContent="center" flex={1}>
                <Chart
                  chartType="PieChart"
                  options={{
                    legend: 'none',
                    pieHole: 0.4,
                    is3D: false,
                    height: 250,
                    width: 250,
                  }}
                  data={[
                    ['Lessons', 'Occurred'],
                    ['occurred', data.setProgress.occurredLessons],
                    ['remaining', data.setProgress.scheduledLessons - data.setProgress.occurredLessons],
                  ]}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ padding: '24px' }}>
            <Typography variant="titleLarge">Teacher Assignment</Typography>
            <Stack marginTop="24px">
              <Info label="Assigned Teachers" value={data.teacherAssignment.assignedTeachers?.toFixed(0) ?? 0} />

              <Info
                marginTop="16px"
                label="Regularly Scheduled Teachers"
                value={`${data.teacherAssignment.regularScheduledTeachers} (${
                  data.teacherAssignment.regularScheduledTeachersPercentage?.toFixed(0) ?? 0
                }%)`}
              />
              <Info
                marginTop="16px"
                label="Subbed in Teachers"
                value={`${data.teacherAssignment.subbedInTeachers} (${data.teacherAssignment.subbedInTeachersPercentage?.toFixed(0) ?? 0}%)`}
              />
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ padding: '24px' }}>
            <Typography variant="titleLarge">Lesson Success</Typography>
            <Stack>
              <Info
                marginTop="16px"
                label="Student Attendance"
                value={`${data.lessonSuccess.studentAttendance} (${data.lessonSuccess.studentAttendancePercentage?.toFixed(0) ?? 0}%)`}
              />
              <Info
                marginTop="16px"
                label="Completed Lessons"
                value={`${data.lessonSuccess.completedLessons} (${data.lessonSuccess.completedLessonsPercentage?.toFixed(0) ?? 0}%)`}
              />
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};

const AssessmentMetricsContainer = () => {
  const { lessonSetId } = useParams() as { lessonSetId: string };
  const { data, isFetching } = useGetAssessmentMetrics(lessonSetId);

  if (isFetching || !data) {
    return <CircularProgress />;
  }

  return (
    <Stack marginTop="24px">
      <Typography variant="headlineSmall">Assessment Metric</Typography>
      <Stack marginTop="24px" spacing="24px" direction="row">
        <Stack spacing="24px" flex={1}>
          <Card sx={{ padding: '24px' }}>
            <Typography variant="titleLarge">Assessment Completion</Typography>

            <Stack>
              <Info marginTop="16px" label="Assessment Lessons" value={data.assessmentCompletion.assessmentLessons?.toFixed(0) ?? 0} />
              <Info label="Started Assessments" value={data.assessmentCompletion.startedAssessments?.toFixed(0) ?? 0} />
              <Info label="Completed Assessments" value={data.assessmentCompletion.completedAssessments?.toFixed(0) ?? 0} />
            </Stack>
          </Card>

          <Card sx={{ padding: '24px' }}>
            <Typography variant="titleLarge">Assessment State</Typography>
            <Stack>
              <Info marginTop="16px" label="Pre-Word Reading" value={hootLevelModuleStatusLookup[data.assessmentState.preWordReading]} />
              <Info marginTop="16px" label="Early-Word Reading" value={hootLevelModuleStatusLookup[data.assessmentState.earlyWordReading]} />
              <Info marginTop="16px" label="Complex-Word Reading" value={hootLevelModuleStatusLookup[data.assessmentState.complexWordReading]} />
              <Info marginTop="16px" label="Text Reading" value={hootLevelModuleStatusLookup[data.assessmentState.textReading]} />
            </Stack>
          </Card>
        </Stack>
        <Stack flex={1}>
          <Card sx={{ padding: '24px' }}>
            <Typography variant="titleLarge">In Progress Units</Typography>
            <Stack marginTop="24px">
              {data.inProgressUnits.map((m) => (
                <React.Fragment key={`${m.moduleName}`}>
                  <Typography variant="titleMedium">{m.moduleName} (In Progress)</Typography>
                  {m.units.map((u) => (
                    <InProgressUnitRow
                      key={`${unitLookup[u.name as UnitIdentifier]}`}
                      unitLabel={unitLookup[u.name as UnitIdentifier]}
                      status={hootLevelUnitStatusLookup[u.status as HootLevelUnitStatus]}
                    />
                  ))}
                </React.Fragment>
              ))}
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Stack>
  );
};

const InstructionsMetricsContainer = () => {
  const { lessonSetId } = useParams() as { lessonSetId: string };
  const { data, isFetching } = useGetInstructionsMetrics(lessonSetId);

  if (isFetching || !data) {
    return <CircularProgress />;
  }

  const lessonPlanTotal = Object.values(data.lessonPlanDistribution).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const resourceUseTotal = Object.values(data.resourceUseDistribution).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  function percentageDisplay(element: number, lessonPlanDisplay: boolean): string {
    if (lessonPlanDisplay) {
      if (lessonPlanTotal === 0) {
        return '0%';
      }
      return `${Math.round((element / lessonPlanTotal) * 100 * 10) / 10}%`;
    } else {
      if (resourceUseTotal === 0) {
        return '0%';
      }
      return `${Math.round((element / resourceUseTotal) * 100 * 10) / 10}%`;
    }
  }

  return (
    <Stack marginTop="24px">
      <Typography variant="headlineSmall">Instructions Metrics</Typography>
      <Stack marginTop="24px" spacing="24px" direction="row">
        <Stack spacing="24px" flex={1}>
          <Card sx={{ padding: '24px' }}>
            <Typography variant="titleLarge">Instructional Resources</Typography>
            <Divider sx={{ marginTop: '24px' }} />

            <Typography marginTop="24px" variant="titleMedium">
              Rapport Focus
            </Typography>
            <Info
              labelVariant="labelMedium"
              label="Primary Focus"
              valueVariant="bodyMedium"
              value={data.instructionResources.rapportPrimaryFocus.toFixed(0)}
            />
            <Info
              labelVariant="labelMedium"
              label="Secondary Focus"
              valueVariant="bodyMedium"
              value={data.instructionResources.rapportSecondaryFocus.toFixed(0)}
            />

            <Divider sx={{ marginTop: '8px' }} />
            <Typography marginTop="24px" variant="titleMedium">
              Pre-Word Reading
            </Typography>
            <Stack>
              <Info
                labelVariant="labelMedium"
                label="Primary Focus"
                valueVariant="bodyMedium"
                value={data.instructionResources.preWordReadingPlan?.toFixed(0) ?? 0}
              />
              <Info
                labelVariant="labelMedium"
                label="Secondary Focus"
                valueVariant="bodyMedium"
                value={data.instructionResources.preWordReadingSecondaryFocus?.toFixed(0) ?? 0}
              />
              <Info label="Lesson Plan" value={data.instructionResources.preWordReadingPlan?.toFixed(0) ?? 0} />
            </Stack>

            <Divider sx={{ marginTop: '8px' }} />
            <Typography marginTop="24px" variant="titleMedium">
              Early Word Reading
            </Typography>
            <Stack>
              <Info
                labelVariant="labelMedium"
                label="Primary Focus"
                valueVariant="bodyMedium"
                value={data.instructionResources.earlyWordReadingPrimaryFocus?.toFixed(0) ?? 0}
              />
              <Info
                labelVariant="labelMedium"
                label="Secondary Focus"
                valueVariant="bodyMedium"
                value={data.instructionResources.earlyWordReadingSecondaryFocus?.toFixed(0) ?? 0}
              />
              <Info label="Lesson Plan" value={data.instructionResources.earlyWordReadingPlan?.toFixed(0) ?? 0} />
            </Stack>

            <Divider sx={{ marginTop: '8px' }} />
            <Typography marginTop="24px" variant="titleMedium">
              Complex Word Reading
            </Typography>
            <Stack>
              <Info
                labelVariant="labelMedium"
                label="Primary Focus"
                valueVariant="bodyMedium"
                value={data.instructionResources.complexWordReadingPrimaryFocus?.toFixed(0) ?? 0}
              />
              <Info
                labelVariant="labelMedium"
                label="Secondary Focus"
                valueVariant="bodyMedium"
                value={data.instructionResources.complexWordReadingSecondaryFocus?.toFixed(0) ?? 0}
              />
              <Info label="Lesson Plan" value={data.instructionResources.complexWordReadingPlan?.toFixed(0) ?? 0} />
            </Stack>

            <Divider sx={{ marginTop: '8px' }} />
            <Typography marginTop="24px" variant="titleMedium">
              Text Reading, Read Aloud
            </Typography>

            <Stack>
              <Info
                labelVariant="labelMedium"
                label="Primary Focus"
                valueVariant="bodyMedium"
                value={data.instructionResources.textReadingPrimaryFocusReadAloud?.toFixed(0) ?? 0}
              />
              <Info
                labelVariant="labelMedium"
                label="Secondary Focus"
                valueVariant="bodyMedium"
                value={data.instructionResources.textReadingSecondaryFocusReadAloud?.toFixed(0) ?? 0}
              />
            </Stack>

            <Divider sx={{ marginTop: '8px' }} />
            <Typography marginTop="24px" variant="titleMedium">
              Text Reading, Reading
            </Typography>
            <Stack>
              <Info
                labelVariant="labelMedium"
                label="Primary Focus"
                valueVariant="bodyMedium"
                value={data.instructionResources.textReadingPrimaryFocusReading?.toFixed(0) ?? 0}
              />
              <Info
                labelVariant="labelMedium"
                label="Secondary Focus"
                valueVariant="bodyMedium"
                value={data.instructionResources.textReadingSecondaryFocusReading?.toFixed(0) ?? 0}
              />
            </Stack>

            <Divider sx={{ marginTop: '8px' }} />
            <Typography marginTop="24px" variant="titleMedium">
              Text Reading
            </Typography>

            <Stack>
              <Info
                labelVariant="labelMedium"
                label="Lesson Plan"
                valueVariant="bodyMedium"
                value={data.instructionResources.textReadingPlan?.toFixed(0) ?? 0}
              />
            </Stack>
          </Card>
        </Stack>
        <Stack spacing="24px" flex={1}>
          <Card sx={{ padding: '24px' }}>
            <Stack direction="row">
              <Stack flex={1}>
                <Typography variant="titleLarge">Resources Use Distribution</Typography>
                <Info label="Rapport Focus" value={percentageDisplay(data.resourceUseDistribution.rapportFocus, false)} />
                <Info label="Pre-Word Reading" value={percentageDisplay(data.resourceUseDistribution.preWordReading, false)} />
                <Info label="Early Word Reading" value={percentageDisplay(data.resourceUseDistribution.earlyWordReading, false)} />
                <Info label="Complex Word Reading" value={percentageDisplay(data.resourceUseDistribution.complexWordReading, false)} />
                <Info label="Text Reading, Read aloud" value={percentageDisplay(data.resourceUseDistribution.textReadingReadAloud, false)} />
                <Info label="Text Reading, Reading" value={percentageDisplay(data.resourceUseDistribution.textReadingReading, false)} />
              </Stack>
              <Stack flex={1}>
                <Chart
                  chartType="PieChart"
                  options={{
                    pieHole: 0.4,
                    is3D: false,
                    height: 400,
                    width: 400,
                  }}
                  data={[
                    ['LessonPlanDistribution', 'Distribution'],
                    ['Rapport Focus', data.resourceUseDistribution.rapportFocus],
                    ['Pre-Word reading', data.resourceUseDistribution.preWordReading],
                    ['Early Word Reading', data.resourceUseDistribution.earlyWordReading],
                    ['Complex Word Reading', data.resourceUseDistribution.complexWordReading],
                    ['Text Reading, Read aloud', data.resourceUseDistribution.textReadingReadAloud],
                    ['Text Reading, Reading', data.resourceUseDistribution.textReadingReading],
                  ]}
                />
              </Stack>
            </Stack>
          </Card>

          <Card sx={{ padding: '24px' }}>
            <Typography variant="titleLarge">Lesson Plan Distribution</Typography>
            <Stack direction="row">
              <Stack flex={1}>
                <Info label="Pre-Word Reading" value={percentageDisplay(data.lessonPlanDistribution.preWordReading, true)} />
                <Info label="Early Word Reading" value={percentageDisplay(data.lessonPlanDistribution.earlyWordReading, true)} />
                <Info label="Complex Word Reading" value={percentageDisplay(data.lessonPlanDistribution.complexWordReading, true)} />
                <Info label="Text Reading" value={percentageDisplay(data.lessonPlanDistribution.textReading, true)} />
              </Stack>
              <Stack flex={1}>
                <Chart
                  chartType="PieChart"
                  options={{
                    pieHole: 0.4,
                    is3D: false,
                    height: 400,
                    width: 400,
                  }}
                  data={[
                    ['LessonPlanDistribution', 'Distribution'],
                    ['Pre-Word reading', data.lessonPlanDistribution.preWordReading],
                    ['Early Word Reading', data.lessonPlanDistribution.earlyWordReading],
                    ['Complex Word Reading', data.lessonPlanDistribution.complexWordReading],
                    ['Text Reading', data.lessonPlanDistribution.textReading],
                  ]}
                />
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Stack>
  );
};

/** Help Components */

function Info(props: {
  label: string;
  labelVariant?: OverridableStringUnion<'inherit' | Variant, TypographyPropsVariantOverrides>;
  value: string;
  valueVariant?: OverridableStringUnion<'inherit' | Variant, TypographyPropsVariantOverrides>;
  marginTop?: string | number;
}) {
  return (
    <Stack marginTop={props.marginTop}>
      <Typography variant={props.labelVariant ?? 'titleMedium'}>{props.label}</Typography>
      <Typography variant={props.valueVariant ?? 'bodyMedium'}>{props.value}</Typography>
    </Stack>
  );
}

function InProgressUnitRow(props: { unitLabel: string; status: string }) {
  return (
    <Stack sx={{ paddingY: '8px', borderBottom: 'solid 1px #000' }} direction="row" justifyContent="space-between">
      <Typography variant="bodyMedium">{props.unitLabel}</Typography>
      <Typography variant="tableHeading">{props.status} </Typography>
    </Stack>
  );
}

export default LessonSetQualityMonitoring;
