import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { TIME_FORMAT, WINNIPEG_TIMEZONE } from '@hoot/common/constants';
import { ONE_MINUTE_PX_HEIGHT } from './constants';
import { ZIndexLayer } from './enums';
import { convertTimeToOffset } from './utils';

interface AvailabilityProps {
  startsAt: string;
  endsAt: string;
  dayOfWeek: DateTime;
  zone: string;
  yAxisLabelsSliced: string[];
}

// Renders a blue overlay indicating the Availability range.
function Availability(props: AvailabilityProps) {
  const startTime = DateTime.fromFormat(`${props.dayOfWeek.toISODate()} ${props.startsAt} ${props.zone}`, 'yyyy-LL-dd h:mm a z').setZone(
    WINNIPEG_TIMEZONE,
  );

  const endsAt = DateTime.fromFormat(`${props.dayOfWeek.toISODate()} ${props.endsAt} ${props.zone}`, 'yyyy-LL-dd h:mm a z').setZone(
    WINNIPEG_TIMEZONE,
  );

  const top = Math.max(convertTimeToOffset(startTime.toFormat(TIME_FORMAT), props.yAxisLabelsSliced), 0);
  const height = convertTimeToOffset(endsAt.toFormat(TIME_FORMAT), props.yAxisLabelsSliced) - top;

  return (
    <Stack
      sx={{
        top: `${top * ONE_MINUTE_PX_HEIGHT}px`,
        height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
        width: '100%',
        paddingX: '4px',
        position: 'absolute',
        zIndex: ZIndexLayer.Bottom,
      }}
    >
      <Stack
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: '#E7EDF9',
          borderRadius: '8px',
        }}
      />
    </Stack>
  );
}

export default Availability;
