export enum HootEmployeeScope {
  UpdateBook = 'update:book',
  GrantPermissions = 'grant:permissions',
  ViewLessonVideos = 'access:lesson-videos',
  AccessReporting = 'access:reporting',
  AccessAPI = 'access:api',
  RefreshToken = 'refresh:token',
  AccessPartnerManagement = 'access:partner-management',
  AdvancedEditLessonTeacher = 'advanced-edit:lesson-teacher',
  AdvancedEditLessonDetails = 'advanced-edit:lesson-details',
  AccessInvoicing = 'access:invoicing',
  ManageLessonReviews = 'manage:lesson-reviews',
  ImportTeacherReliabilityScoreImport = 'import:teacher-reliability-score',
  AdvancedStudentData = 'manage:advanced-student-data',
  ViewDistrictDetails = 'access:district-details',
  ManageStudentDetails = 'manage:student-details',
  ManageTeacherDetails = 'manage:teacher-details',
  ManageDistrictReps = 'manage:district-reps',
  AccessBillingInformation = 'access:billing-information',
  DeleteAssessments = 'delete:assessments',
  ManageInstructionalLibraries = 'manage:instructional-libraries',
}
