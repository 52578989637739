import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface AddResourcesToFavouritesRequest {
  bookIds: string[];
}

async function addResourcesToFavourites(studentProfileId: string, request: AddResourcesToFavouritesRequest): Promise<void> {
  await axios.post(`${config.apiUrl}/admin/student-profile/${studentProfileId}/resources/favourites`, request);
}

export default function useAddResourcesToFavourites(
  studentProfileId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, AddResourcesToFavouritesRequest>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((request: AddResourcesToFavouritesRequest) => addResourcesToFavourites(studentProfileId, request), options);
}
