import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import {
  ScheduledLessonLanguage,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonType,
  SubRequired,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { ScheduledLessonSetStatus } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';

export enum LiveLessonSearchFieldOptions {
  LessonNumber = 'LESSON_NUMBER',
  EnrolmentId = 'ENROLMENT_FRIENDLY_ID',
  StudentNumber = 'STUDENT_NUMBER',
  ParentNumber = 'PARENT_NUMBER',
  ParentEmail = 'PARENT_EMAIL',
  TeacherNumber = 'TEACHER_NUMBER',
  TeacherEmail = 'TEACHER_EMAIL',
  All = 'ALL',
}

export interface LiveLessonQueryRequest {
  query?: string;
  querySearchFieldSelection?: LiveLessonSearchFieldOptions;
  fromDate?: number;
  toDate?: number;
  accountId?: string;
  subject?: ScheduledLessonSubject;
  language?: ScheduledLessonLanguage;
  isRecurring?: boolean;
  lineOfBusiness?: string;
  status?: ScheduledLessonStatus[];
  ignoreTypes?: ScheduledLessonType[];
  subRequired?: SubRequired;
  outsideShift?: boolean;
  page: number;
  pageSize: number;
}

export interface LiveLessonResponse {
  lessonId: string;
  lessonPrefixedNumber: string;
  status: ScheduledLessonStatus;
  type: ScheduledLessonType;
  startTime: number;
  endTime: number;
  teacherId: string | null;
  teacherPrefixedNumber: string | null;
  teacherEmail: string | null;
  teacherPhone: string | null;
  studentId: string;
  studentPrefixedNumber: string;
  studentProfileStatus: StudentStatus;
  parentId: string;
  parentPrefixedNumber: string | null;
  parentEmail: string | null;
  parentPhone: string | null;

  enrolment: LiveLessonEnrolmentSummary | null;
  lessonSet: LiveLessonLessonSetSummary | null;
}

interface LiveLessonLessonSetSummary {
  id: string;
  lessonSetPrefixedNumber: string;
  status: ScheduledLessonSetStatus;
}

interface LiveLessonEnrolmentSummary {
  id: string;
  friendlyId: string;
  type: EnrolmentType;
  locationId: string;
}

export interface LiveLessonsResponse {
  count: number;
  page: number;
  pageSize: number;
  lessons: LiveLessonResponse[];
}

async function getLiveLessons(query: LiveLessonQueryRequest) {
  const { data } = await axios.post<LiveLessonsResponse>(`${config.apiUrl}/admin/live-lessons`, query);
  return data;
}

export default function useLiveLessonsQuery(
  query: LiveLessonQueryRequest,
  options?: Omit<UseQueryOptions<LiveLessonsResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.LiveLessons, query], () => getLiveLessons(query), options);
}
