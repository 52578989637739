import { EnrolmentLoginType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-login-type.enum';
import { EnrolmentRosterStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-roster-status.enum';
import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface CreateEnrolmentRequest {
  type: EnrolmentType;
  status: EnrolmentStatus;
  startDate: number;
  endDate: number;
  numberOfLessons: number;
  locationId: string;
  productId: string;
  rosterStatus: EnrolmentRosterStatus;
  loginType: EnrolmentLoginType;
}

const createEnrolments = async (request: CreateEnrolmentRequest): Promise<string> => {
  const { data } = await axios.post<string>(`${config.apiUrl}/admin/hfs/enrolments`, request);
  return data;
};

const useCreateEnrolments = (options?: Omit<UseMutationOptions<any, AxiosError, CreateEnrolmentRequest>, 'mutationFn' | 'mutationKey'>) => {
  return useMutation<string, AxiosError, CreateEnrolmentRequest>((request) => createEnrolments(request), options);
};

export default useCreateEnrolments;
