import { EnrolmentLoginType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-login-type.enum';
import { EnrolmentRosterStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-roster-status.enum';
import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import { ProductProgram } from '@hoot-reading/hoot-core/dist/enums/hfs/product-program.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface ProductEnrolmentsResponse {
  status: EnrolmentStatus;
  count: number;
}

export interface EnrolmentProductResponse {
  id: string;
  friendlyId: string;
  program: ProductProgram;
  startDate: number;
  endDate: number;
  openEnrolments: ProductEnrolmentsResponse;
  scheduledEnrolments: ProductEnrolmentsResponse;
  inProgressEnrolments: ProductEnrolmentsResponse;
  closedEnrolments: ProductEnrolmentsResponse;
  requirements: HootQualification[];
}

export interface EnrolmentDistrictRepresentativeResponse {
  id: string;
  prefixedNumber: string;
  name: string;
}

export interface EnrolmentEdlinkDataResponse {
  edlinkClassId: string;
  edlinkClassName: string;
  integrationId: string;
  integrationName: string;
}

export interface EnrolmentResponse {
  friendlyId: string;
  isDistrict: boolean;
  locationName: string;
  locationId: string;
  locationFriendlyId: string;
  status: EnrolmentStatus;
  numberOfLessons: number;
  type: EnrolmentType;
  startDate: number;
  endDate: number;
  updatedStatusAt: number;
  product: EnrolmentProductResponse;
  districtRepresentatives: EnrolmentDistrictRepresentativeResponse[];
  rosterStatus: EnrolmentRosterStatus;
  edlinkData: EnrolmentEdlinkDataResponse | null;
  loginType: EnrolmentLoginType;
}

const getEnrolment = async (enrolmentId: string): Promise<EnrolmentResponse> => {
  const { data } = await axios.get<EnrolmentResponse>(`${config.apiUrl}/admin/hfs/enrolments/${enrolmentId}`);
  return data;
};

export default function useGetEnrolment(enrolmentId: string, options?: Omit<UseQueryOptions<EnrolmentResponse, AxiosError>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetEnrolment, enrolmentId], () => getEnrolment(enrolmentId), options);
}
