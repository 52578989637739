import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface SearchSchoolsQuery {
  page: number;
  pageSize: number;
  searchString?: string;
  districtId?: string;
}

export interface SchoolSummary {
  id: string;
  friendlyId: string;
  name: string;
  isStandAloneSchool: boolean;
  districtId: string | null;
}

export interface SearchSchoolsResponse {
  schools: SchoolSummary[];
}

const searchSchools = async (searchParams: SearchSchoolsQuery): Promise<SearchSchoolsResponse> => {
  const { data } = await axios.post<SearchSchoolsResponse>(`${config.apiUrl}/admin/hfs/schools/search`, searchParams);
  return data;
};

export default function useSearchSchools(
  searchParams: SearchSchoolsQuery,
  options?: Omit<UseQueryOptions<SearchSchoolsResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.SearchSchools, searchParams], () => searchSchools(searchParams), options);
}
