import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, styled } from '@mui/material';
import React from 'react';
import { Link, createSearchParams, useSearchParams } from 'react-router-dom';
import { MONTHS_WITH_NONE } from '@hoot/common/constants';
import {
  SearchTeacherInvoicePeriodsQuery,
  TeacherInvoicePeriodsQuerySortKeyEnum,
  TeacherInvoicePeriodsResponse,
} from '@hoot/hooks/api/teacher-invoice/useSearchTeacherInvoicePeriods';
import { TeacherInvoicePeriodsPageQueryParams } from '@hoot/pages/teacher-invoices/interfaces/TeacherInvoicePeriodsPageQueryFilterParams';
import { SortOrder, TableHeaderCell, muiTableSortDirectionLookup } from '../../interfaces/order-by';
import { GenericPaginatedResponse } from '../../interfaces/pagination';
import { routes } from '../../routes/routes';

const TeacherInvoicePeriodLink = styled(Link)({
  textDecoration: 'none',
  color: '#1976D2',
});

interface TeacherInvoicePeriodsTableProps {
  query: SearchTeacherInvoicePeriodsQuery;
  teacherInvoicePeriodsPaginatedResponse: GenericPaginatedResponse<TeacherInvoicePeriodsResponse>;
}

const tableHeaderCells: TableHeaderCell<TeacherInvoicePeriodsQuerySortKeyEnum>[] = [
  { label: 'Invoice Identifier', sortKey: TeacherInvoicePeriodsQuerySortKeyEnum.Identifier },
  { label: 'Month', sortKey: TeacherInvoicePeriodsQuerySortKeyEnum.Month },
  { label: 'Year', sortKey: TeacherInvoicePeriodsQuerySortKeyEnum.Year },
];

const TeacherInvoicePeriodsTable = (props: TeacherInvoicePeriodsTableProps) => {
  const { query, teacherInvoicePeriodsPaginatedResponse } = props;
  const { orderBy, sortDirection } = query;

  const [, setSearchParams] = useSearchParams();

  // If we've toggling the same order-by key, then just flip the sort direction.
  // Else, we're sorting by a different column, and we can default the sort to 'asc'.
  const toggleOrderBy = (newOrderBy: string) => () => {
    const isAsc = orderBy === newOrderBy && sortDirection === SortOrder.ASC;

    setSearchParams(
      (prev) => {
        const newSearchParams = createSearchParams(prev);
        newSearchParams.set(TeacherInvoicePeriodsPageQueryParams.OrderBy, newOrderBy);
        newSearchParams.set(TeacherInvoicePeriodsPageQueryParams.SortDirection, isAsc ? SortOrder.DESC : SortOrder.ASC);
        return newSearchParams;
      },
      { replace: true },
    );
  };

  return (
    <Table sx={{ minWidth: 650 }} aria-label="invoice periods table">
      <TableHead>
        <TableRow>
          {tableHeaderCells.map((cell) => (
            <TableCell
              variant="head"
              sx={{ width: cell.cellWidth }}
              key={`headerCell-${cell.label}`}
              sortDirection={
                cell.sortKey ? (orderBy === cell.sortKey ? muiTableSortDirectionLookup[sortDirection ?? SortOrder.ASC] : false) : undefined
              }
            >
              {cell.sortKey ? (
                <TableSortLabel
                  active={orderBy === cell.sortKey}
                  direction={orderBy === cell.sortKey ? muiTableSortDirectionLookup[sortDirection ?? SortOrder.ASC] : undefined}
                  onClick={toggleOrderBy(cell.sortKey)}
                >
                  {cell.label}
                </TableSortLabel>
              ) : (
                cell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {teacherInvoicePeriodsPaginatedResponse.data.map((row) => (
          <TableRow
            key={row.id}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              '& > td': { verticalAlign: 'top' },
            }}
          >
            <TableCell>
              <TeacherInvoicePeriodLink to={routes.invoicing.tasks.url(row.id)}>{row.identifier}</TeacherInvoicePeriodLink>
            </TableCell>
            <TableCell>{MONTHS_WITH_NONE[+row.month]}</TableCell>
            <TableCell>{row.year}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TeacherInvoicePeriodsTable;
