import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { DateTime } from 'luxon';
import { WINNIPEG_TIMEZONE } from '@hoot/common/constants';
import { StatusColours } from './enums';
import { LessonTimeSlot, ShiftStatus } from './interfaces';

// this function calculates the offset from top of the timeslots container
// i.e: 8:00 AM ->    0
//      8:01 AM ->    1
//      8:30 AM ->   30
//      9:00 AM ->   60
//      12:00 AM -> 240
export function convertTimeToOffset(time: string, timeArray: string[]): number {
  // since we don't start at midnight, this will offset the time based on the first value in calendarYAxisLabels
  // Note: if we don't set the zone in here, the Settings default runs after this is set, so it will be in the user's timezone instead of Winnipeg
  const timeOffset = DateTime.fromFormat(`1970-01-01 ${timeArray[0]}`, 'yyyy-LL-dd h:mm a', { zone: WINNIPEG_TIMEZONE }).toMillis();
  const topBoundary = DateTime.fromFormat(`1970-01-01 ${timeArray.slice(-1)[0]}`, 'yyyy-LL-dd h:mm a', { zone: WINNIPEG_TIMEZONE }).plus({
    minutes: 30,
  }); // need to go to the end of the slot, so adding extra 30 minutes

  const dt = DateTime.fromFormat(`1970-01-01 ${time}`, 'yyyy-LL-dd h:mm a');

  if (dt > topBoundary) {
    return (topBoundary.toMillis() - timeOffset) / 60000;
  }

  return (dt.toMillis() - timeOffset) / 60000;
}

// This function will find all lessons that overlap with each other and group them together
// i.e: 10:00 AM -> 10:20 AM
// i.e: 10:10 AM -> 10:40 AM
// i.e: 10:30 AM -> 10:50 AM
// Would be considered 1 group since 10:10 -> 10:40 overlaps 10:00 and 10:20 AM
export function findOverlappingTimeslots(timeslots: LessonTimeSlot[]) {
  const buckets: LessonTimeSlot[][] = [];
  for (const timeslot of timeslots.sort((a, b) => (a.startAt < b.startAt ? -1 : 1))) {
    const bucket = buckets.find((bucket) =>
      bucket.some(
        (ts) =>
          isBetween(ts.startAt.toMillis(), timeslot.startAt.toMillis(), timeslot.endsAt.toMillis()) ||
          isBetween(timeslot.startAt.toMillis(), ts.startAt.toMillis(), ts.endsAt.toMillis()),
      ),
    );

    if (bucket) {
      bucket.push(timeslot);
    } else {
      buckets.push([timeslot]);
    }
  }

  return buckets;
}

export function lessonColour(status: ScheduledLessonStatus): StatusColours {
  if (status === ScheduledLessonStatus.Cancelled || status === ScheduledLessonStatus.Rescheduled) {
    return StatusColours.Cancelled;
  } else if (status === ScheduledLessonStatus.CompletedSuccessfully || status === ScheduledLessonStatus.CompletedUnsuccessfully) {
    return StatusColours.Completed;
  } else {
    return StatusColours.Scheduled;
  }
}

export function shiftColour(status: ShiftStatus): StatusColours {
  switch (status) {
    case 'CANCELLED':
      return StatusColours.Cancelled;
    case 'COMPLETED':
      return StatusColours.Completed;
    case 'SCHEDULED':
      return StatusColours.Scheduled;
    case 'PUBLISHED':
      return StatusColours.Published;
  }
}

function isBetween(val: number, lower: number, upper: number) {
  return val >= lower && val <= upper;
}
