import axios, { AxiosError } from 'axios';
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';
import { HistoryItemsResponse } from '@hoot/interfaces/history';
import { QueryKey } from '../../queryKeys';

async function getDistrictRepHistory(districtRepId: string): Promise<HistoryItemsResponse> {
  const { data } = await axios.get(`${config.apiUrl}/admin/district-representative/${districtRepId}/history`);
  return data;
}

export default function useGetDistrictRepHistory(
  districtRepId: string,
  options?: Omit<UseQueryOptions<HistoryItemsResponse, AxiosError<ErrorResponseDto>>, 'queryKey' | 'queryFn'>,
): UseQueryResult<HistoryItemsResponse, AxiosError> {
  return useQuery([QueryKey.GetDistrictRepHistory, districtRepId], () => getDistrictRepHistory(districtRepId), options);
}
