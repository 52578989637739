import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { TIME_FORMAT } from '@hoot/common/constants';
import { ONE_MINUTE_PX_HEIGHT } from './constants';
import { ZIndexLayer } from './enums';
import { convertTimeToOffset } from './utils';

interface LessonPreferenceProps {
  time: string;
  yAxisLabelsSliced: string[];
}

function LessonPreference(props: LessonPreferenceProps) {
  const top = Math.max(convertTimeToOffset(props.time, props.yAxisLabelsSliced), 0);
  const endTime = DateTime.fromFormat(props.time, TIME_FORMAT).plus({ minute: 30 }).toFormat(TIME_FORMAT);
  const height = convertTimeToOffset(endTime, props.yAxisLabelsSliced) - top;

  return (
    <Stack
      sx={{
        top: `${top * ONE_MINUTE_PX_HEIGHT}px`,
        height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
        width: '100%',
        paddingX: '4px',
        position: 'absolute',
        zIndex: ZIndexLayer.Bottom,
      }}
    >
      <Stack
        sx={{
          borderRadius: '4px',
          height: '100%',
          width: '100%',
          backgroundColor: '#E7EDF9',
        }}
      />
    </Stack>
  );
}

export default LessonPreference;
