import { Stack, Typography } from '@mui/material';
import { ONE_MINUTE_PX_HEIGHT } from './constants';
import { ZIndexLayer } from './enums';
import { convertTimeToOffset } from './utils';

interface SelectedTimeSlotProps {
  startsAt: string;
  endsAt: string;
  yAxisLabelsSliced: string[];
}

function SelectedTimeSlot(props: SelectedTimeSlotProps) {
  const top = Math.max(convertTimeToOffset(props.startsAt, props.yAxisLabelsSliced), 0);
  const height = convertTimeToOffset(props.endsAt, props.yAxisLabelsSliced) - top;

  return (
    <Stack
      sx={{
        position: 'absolute',
        top: `${top * ONE_MINUTE_PX_HEIGHT + 2}px`,
        left: '3px',
        zIndex: ZIndexLayer.Middle,
      }}
    >
      <Stack
        sx={{
          backgroundColor: '#569436',
          height: `${height * ONE_MINUTE_PX_HEIGHT + 9}px`,
          width: '86px',
          borderRadius: '8px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="bodyLarge" color="#FFFFFF">
          Selected
        </Typography>
      </Stack>
    </Stack>
  );
}

export default SelectedTimeSlot;
