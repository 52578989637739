import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import { ScheduledLessonSetStatus } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';
import {
  QATaskSearchFieldOptions,
  QualityAssuranceCategory,
  QualityAssuranceOwningTeam,
  QualityAssuranceStatus,
  QualityAssuranceType,
  ThresholdType,
} from './enums';

export interface QueryQualityAssuranceTasks {
  owningTeams: QualityAssuranceOwningTeam[];
  query: string;
  categories: QualityAssuranceCategory[];
  types: QualityAssuranceType[];
  statuses: QualityAssuranceStatus[];
  enrolmentIds: string[];
  searchFieldSelection: QATaskSearchFieldOptions;
  page: number;
  pageSize: number;
  teacherAccountId: string | undefined;
  studentProfileId: string | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  metrics: string[];
}

export interface QualityAssuranceTasksResponse {
  id: string;
  taskNumber: number;
  taskPrefixedNumber: string;
  taskType: QualityAssuranceType;
  taskCreatedAt: number;
  taskCategory: QualityAssuranceCategory;
  status: QualityAssuranceStatus;
  actioned: boolean;
  studentId: string;
  studentName: string;
  studentNumber: number;
  studentPrefixedNumber: string;
  teacherId: string;
  teacherPrefixedNumber: string;
  enrolmentId?: string;
  enrolmentFriendlyId?: string;
  enrolmentLocationId?: string;
  lessonSetStatus: ScheduledLessonSetStatus;
  lessonId: string;
  lessonNumber: number;
  lessonPrefixedNumber: string;
  lessonStatus: ScheduledLessonStatus;
  failedThreshold: ThresholdType;
  owningTeam: QualityAssuranceOwningTeam;
}

async function queryQualityAssuranceTasks(query: QueryQualityAssuranceTasks): Promise<GenericPaginatedResponse<QualityAssuranceTasksResponse>> {
  const { data } = await axios.post<GenericPaginatedResponse<QualityAssuranceTasksResponse>>(
    `${config.apiUrl}/admin/quality-assurance-tasks/search`,
    query,
  );
  return data;
}

export default function useQueryQualityTasks(
  query: QueryQualityAssuranceTasks,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<QualityAssuranceTasksResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.QualityAssuranceTasksQuery, query], () => queryQualityAssuranceTasks(query), options);
}
