import { useContext, useEffect, useRef, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export function useBlocker(when = true) {
  const { navigator } = useContext(NavigationContext);

  const [blocked, setBlocked] = useState<boolean>(false);
  const proceed = useRef<() => void>();

  const keepEditing = () => {
    setBlocked(false);
  };
  useEffect(() => {
    if (!when) {
      return;
    }

    //This handles cases where we have navigate(-1)
    const go = navigator.go;
    navigator.go = (...args: Parameters<typeof go>) => {
      setBlocked(true);
      proceed.current = () => go(...args);
    };

    //This handles cases where we have navigate(some slug string)
    const push = navigator.push;
    navigator.push = (...args: Parameters<typeof push>) => {
      setBlocked(true);
      proceed.current = () => push(...args);
    };

    return () => {
      navigator.go = go;
      navigator.push = push;
      setBlocked(false);
    };
  }, [navigator, when]);

  return [blocked, proceed.current, keepEditing] as [boolean, () => void, () => void];
}
