import { TypographyOptions } from '@mui/material/styles/createTypography';
import React from 'react';

export type HootTypographyVariant =
  | 'displayLarge'
  | 'displayMedium'
  | 'displaySmall'
  | 'headlineLarge'
  | 'headlineMedium'
  | 'headlineSmall'
  | 'titleLarge'
  | 'titleMedium'
  | 'titleSmall'
  | 'labelLarge'
  | 'labelMedium'
  | 'labelSmall'
  | 'bodyLarge'
  | 'bodyMedium'
  | 'bodySmall'
  | 'tableHeading';

export interface HootTypographyPropsVariantOverrides {
  displayLarge: true;
  displayMedium: true;
  displaySmall: true;
  headlineLarge: true;
  headlineMedium: true;
  headlineSmall: true;
  titleLarge: true;
  titleMedium: true;
  titleSmall: true;
  labelLarge: true;
  labelMedium: true;
  labelSmall: true;
  bodyLarge: true;
  bodyMedium: true;
  bodySmall: true;
  tableHeading: true;
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends HootTypographyPropsVariantOverrides {}
}

interface ExtendedTypographyOptions extends TypographyOptions {
  displayLarge: React.CSSProperties;
  displayMedium: React.CSSProperties;
  displaySmall: React.CSSProperties;
  headlineLarge: React.CSSProperties;
  headlineMedium: React.CSSProperties;
  headlineSmall: React.CSSProperties;
  titleLarge: React.CSSProperties;
  titleMedium: React.CSSProperties;
  titleSmall: React.CSSProperties;
  labelLarge: React.CSSProperties;
  labelMedium: React.CSSProperties;
  labelSmall: React.CSSProperties;
  bodyLarge: React.CSSProperties;
  bodyMedium: React.CSSProperties;
  bodySmall: React.CSSProperties;
  tableHeading: React.CSSProperties;
}

const typography: ExtendedTypographyOptions = {
  displayLarge: {
    fontWeight: 400,
    fontSize: '57px',
    lineHeight: '64px',
  },
  displayMedium: {
    fontWeight: 400,
    fontSize: '45px',
    lineHeight: '52px',
  },
  displaySmall: {
    fontWeight: 400,
    fontSize: '36px',
    lineHeight: '44px',
  },
  headlineLarge: {
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '40px',
  },
  headlineMedium: {
    fontWeight: 400,
    fontSize: '28px',
    lineHeight: '36px',
  },
  headlineSmall: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32px',
  },
  titleLarge: {
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '28px',
  },
  titleMedium: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  titleSmall: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  labelLarge: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  labelMedium: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
  labelSmall: {
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px',
  },
  bodyLarge: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  bodyMedium: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  bodySmall: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
  tableHeading: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  button: {
    fontSize: '14px',
    fontWeight: 500,
  },
};

export default typography;
