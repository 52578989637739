import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import { EnrolmentDistrictRepresentativeResponse } from '@hoot/hooks/api/hfs/useGetEnrolment';
import { routes } from '@hoot/routes/routes';

const DistrictRepresentatives = (props: { districtRepresentatives: EnrolmentDistrictRepresentativeResponse[] }) => {
  const { districtRepresentatives } = props;
  const { scopes } = useAuth();

  const hasViewDistrictDetailsPermission = !!scopes.find((s) => s === HootEmployeeScope.ViewDistrictDetails);

  const handleOpenInNew = (url: string) => () => {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  };

  return (
    <Grid container direction="column" justifyContent="space-between">
      {districtRepresentatives.map((dr) => (
        <Stack
          key={dr.id}
          direction="row"
          sx={{ padding: 3, border: '1px solid rgba(0,0,0,0.1)', borderRadius: '4px' }}
          justifyContent="space-between"
        >
          <Stack direction="row" justifyContent="flex-start" gap={2} sx={{ marginTop: '8px' }}>
            <AccountCircleIcon />
            <Typography variant="bodyLarge" sx={{ color: '#000' }}>
              {dr.prefixedNumber}
            </Typography>
            {hasViewDistrictDetailsPermission ? (
              <Typography variant="bodyLarge" sx={{ color: '#000' }}>
                {dr.name}
              </Typography>
            ) : null}
          </Stack>
          <Stack direction="row" justifyContent="flex-start" gap={1}>
            <IconButton onClick={handleOpenInNew(routes.users.districtReps.details.url(dr.id))} title="Open in new tab">
              <OpenInNewIcon />
            </IconButton>
          </Stack>
        </Stack>
      ))}
    </Grid>
  );
};

export default DistrictRepresentatives;
