import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { ParentProfileResponse } from '@hoot/hooks/api/end-users/useGetParentProfile';
import { ParentDetailsForm } from '../../../components/Secure/Users/parent/form';

const defaults: ParentDetailsForm = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  streetAddress: null,
  country: '',
  city: '',
  provinceState: '',
  postalZipCode: null,
  timeZone: '',
  accountId: '',
  userAccountStatus: true,
  parentProfileStatus: true,
  referralCode: '',
  students: [],
};

export default function useParentDetailsForm(parentDetails?: ParentProfileResponse) {
  const getFormValues: (parentDetails: ParentProfileResponse) => ParentDetailsForm = (parentDetails) => {
    const formValues: ParentDetailsForm = {
      firstName: parentDetails.personalInformation.firstName,
      lastName: parentDetails.personalInformation.lastName,
      emailAddress: parentDetails.personalInformation.emailAddress,
      phoneNumber: parentDetails.personalInformation.phoneNumber,
      streetAddress: parentDetails.personalInformation.streetAddress,
      country: parentDetails.personalInformation.country,
      city: parentDetails.personalInformation.city,
      provinceState: parentDetails.personalInformation.provinceState,
      postalZipCode: parentDetails.personalInformation.postalZipCode,
      timeZone: parentDetails.personalInformation.timeZone,
      accountId: parentDetails.statusesAndPermissions.accountId ?? '',
      userAccountStatus: parentDetails.statusesAndPermissions.userAccountStatus,
      parentProfileStatus: parentDetails.statusesAndPermissions.parentProfileStatus,
      referralCode: parentDetails.referralCode ?? '',
      students: parentDetails.students,
    };
    return formValues;
  };

  const formItems = useForm<ParentDetailsForm>({
    mode: 'onChange',
    defaultValues: parentDetails ? getFormValues(parentDetails) : defaults,
  });

  const resetForm = useCallback(() => {
    if (parentDetails) {
      formItems.reset(getFormValues(parentDetails));
    }
  }, [formItems, parentDetails]);

  return {
    ...formItems,
    resetForm,
  };
}
