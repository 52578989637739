import { BoxProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IFilter, IFilterSelection } from '@hoot/components/form/searchFilters/FilterSelects';
import SearchFilters from '@hoot/components/form/searchFilters/SearchFilters';
import { UserSelectableProject } from '@hoot/hooks/api/teacher-invoice/useGetUserSelectableProjects';
import { SearchTeacherInvoiceUploadedTasksQuery } from '@hoot/hooks/api/teacher-invoice/useSearchTeacherInvoiceUploadedBillableTasks';
import { TeacherInvoiceBillableTasksPageQueryParams } from './interfaces/TeacherInvoiceBillableTasksPageQueryFilterParams';

const getInitialFilterSelections = (searchParams: SearchTeacherInvoiceUploadedTasksQuery, projects: UserSelectableProject[]) => {
  const projectOptions = projects?.map((project) => ({
    label: project.projectCode,
    queryValue: project.id,
  }));

  const teacherInvoiceUploadedTasksFilters: IFilter[] = [
    {
      label: 'Project',
      queryName: TeacherInvoiceBillableTasksPageQueryParams.Project,
      options: projectOptions,
    },
  ];

  return teacherInvoiceUploadedTasksFilters.map((filter: IFilter) => ({
    ...filter,
    values: filter.options.map((option) => ({
      ...option,
      isSelected: Object.keys(searchParams).includes(option.label),
    })),
  }));
};

interface TeacherInvoiceBillableTasksSearchFiltersProps {
  sx?: BoxProps['sx'];
  projects: UserSelectableProject[];
  query: SearchTeacherInvoiceUploadedTasksQuery;
  setQuery: React.Dispatch<React.SetStateAction<SearchTeacherInvoiceUploadedTasksQuery>>;
}

const TeacherInvoiceUploadedTasksFilters = (props: TeacherInvoiceBillableTasksSearchFiltersProps) => {
  const { sx, projects, query, setQuery } = props;
  const [filterSelections, setFilterSelections] = useState<IFilterSelection[]>(getInitialFilterSelections(query, projects));

  useEffect(() => {
    const selectedFilters = filterSelections
      .map((filterSelection) => {
        const selectedOptionValues = filterSelection.values
          .filter((filterOption) => filterOption.isSelected)
          .map((filterOption) => filterOption.queryValue);
        return {
          filterName: filterSelection.queryName!,
          selectedOptionValues,
        };
      })
      .filter((filter) => filter.selectedOptionValues.length > 0)
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr.filterName]: curr.selectedOptionValues,
        };
      }, {});

    setQuery((prev) => ({
      ...selectedFilters,
      page: 1,
      pageSize: prev.pageSize,
      orderBy: prev.orderBy,
      sortDirection: prev.sortDirection,
    }));
  }, [filterSelections, setQuery]);

  return <SearchFilters sx={sx} filterSelections={filterSelections} setFilterSelections={setFilterSelections} />;
};

export default TeacherInvoiceUploadedTasksFilters;
