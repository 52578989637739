import { ContentCopy } from '@mui/icons-material';
import { IconButton, SvgIconTypeMap, SxProps, Theme } from '@mui/material';
import { useAlert } from '../../contexts/AlertContext';

type IconProps = SvgIconTypeMap<{}, 'svg'>['props'];

export function Clipboard(props: { toCopy: string; sx?: SxProps<Theme>; iconProps?: IconProps }) {
  const { sx, toCopy, iconProps } = props;

  const { success, error } = useAlert();

  const handleCopyClick = () => {
    try {
      navigator.clipboard.writeText(toCopy);
      success('Successfully copied to clipboard');
    } catch (exc) {
      console.log(exc);
      error('There was an issue copying to clipboard');
    }
  };

  return (
    <IconButton sx={sx} onClick={handleCopyClick}>
      <ContentCopy {...iconProps} />
    </IconButton>
  );
}

export default Clipboard;
