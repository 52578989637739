import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

const ErrorText = () => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      sx={{
        height: '400px',
      }}
    >
      <Box sx={{ fontSize: 24, color: 'lightgrey', alignSelf: 'center' }}>Error</Box>
    </Stack>
  );
};

export default ErrorText;
