import { CustomerStatus } from '@hoot-reading/hoot-core/dist/enums/user/customer-status.enum';
import { DistrictRepresentativeStatus } from '@hoot-reading/hoot-core/dist/enums/user/district-representative/district-representative-status.enum';
import { PasswordSetFilter } from '@hoot-reading/hoot-core/dist/enums/user/password-reset-filter.enum';
import { LineOfBusiness } from '@hoot-reading/hoot-core/dist/enums/user/student/line-of-business.enum';
import { StudentDateType } from '@hoot-reading/hoot-core/dist/enums/user/student/student-date-type.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { AgreementEndReason } from '@hoot-reading/hoot-core/dist/enums/user/teacher/agreement-end-reason.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { LanguageQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/language-qualification.enum';
import { OnLeaveReason } from '@hoot-reading/hoot-core/dist/enums/user/teacher/on-leave-reason.enum';
import { TeacherAvailability } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-availability.enum';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import { OperatorFilter } from '@hoot/common/constants';
import { customerStatus } from '@hoot/interfaces/customer';
import { districtRepStatus } from '@hoot/interfaces/districtRep';
import { FormOption } from '@hoot/interfaces/form';
import { isEmpty } from '@hoot/interfaces/form';
import { studentProfileStatus } from '@hoot/interfaces/profile';
import { studentDateTypes } from '@hoot/interfaces/student';
import {
  agreementEndReasonOptions,
  hootQualificationOptions,
  languageQualificationOptions,
  onLeaveReasonOptions,
  teacherAvailabilityOptions,
  teacherStatusOptions,
} from '@hoot/interfaces/teacher';

type IS_EMPTY = 'IS_EMPTY';
type LineOfBusinessFilter = LineOfBusiness;
type TeacherStatusFilter = TeacherStatus | IS_EMPTY;
type TeacherAvailabilityFilter = TeacherAvailability | IS_EMPTY;
type CustomerStatusFilter = CustomerStatus | IS_EMPTY;
type OnLeaveReasonFilter = OnLeaveReason | IS_EMPTY;
type AgreementEndReasonFilter = AgreementEndReason | IS_EMPTY;
type StudentStatusFilter = StudentStatus | IS_EMPTY;
type StudentDateTypeFilter = StudentDateType;
type HootQualificationFilter = HootQualification | IS_EMPTY;
type LanguageQualificationFilter = LanguageQualification | IS_EMPTY;

export const teacherStatusFilterOptions: FormOption<TeacherAvailabilityFilter>[] = [...teacherStatusOptions, isEmpty];

export const teacherAvailabilityFilterOptions: FormOption<TeacherStatusFilter>[] = [...teacherAvailabilityOptions, isEmpty];

export const onLeaveReasonFilterOptions: FormOption<OnLeaveReasonFilter>[] = [...onLeaveReasonOptions, isEmpty];

export const agreementEndReasonFilterOptions: FormOption<AgreementEndReasonFilter>[] = [...agreementEndReasonOptions, isEmpty];

export const customerStatusFilterOptions: FormOption<CustomerStatusFilter>[] = [...customerStatus, isEmpty];

export const studentDateTypeOptions: FormOption<StudentDateTypeFilter>[] = [...studentDateTypes];

export const studentStatusFilterOptions: FormOption<StudentStatusFilter>[] = [...studentProfileStatus, isEmpty];

export const districtRepStatusFilterOptions: FormOption<DistrictRepresentativeStatus>[] = [...districtRepStatus];

export const hootQualificationFilterOptions: FormOption<HootQualificationFilter>[] = [...hootQualificationOptions, isEmpty];

export const languageQualificationFilterOptions: FormOption<LanguageQualificationFilter>[] = [...languageQualificationOptions, isEmpty];

export const lineOfBusinessFilterOptions: FormOption<LineOfBusinessFilter>[] = [
  { label: 'B2B', value: LineOfBusiness.B2B },
  { label: 'B2C', value: LineOfBusiness.B2C },
  { label: 'Both', value: LineOfBusiness.Both },
];

export const passwordSetFilterOptions: FormOption<PasswordSetFilter>[] = [
  { label: 'Parent Email - Password Set', value: PasswordSetFilter.ParentEmailPasswordSet },
  { label: 'Parent Email - Password Not Set', value: PasswordSetFilter.ParentEmailPasswordNotSet },
  { label: 'Student Number - Password Set', value: PasswordSetFilter.HFSPasswordSet },
  { label: 'Student Number - Password Not Set', value: PasswordSetFilter.HFSPasswordNotSet },
];

export const operatorFilterOptions: FormOption<OperatorFilter>[] = [
  { label: 'Is', value: OperatorFilter.Is },
  { label: 'Is Not', value: OperatorFilter.IsNot },
];
