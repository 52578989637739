import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';

const EnrolmentStatusIcon = (status: EnrolmentStatus) => {
  switch (status) {
    case EnrolmentStatus.Open:
      return <img src={'/images/open-icon.svg'} alt="Open Icon" />;
    case EnrolmentStatus.Scheduled:
    case EnrolmentStatus.InProgress:
      return <img src={'/images/in-progress-icon.svg'} alt="In-progress/Scheduled Icon" />;
    case EnrolmentStatus.Closed:
      return <img src={'/images/closed-icon.svg'} alt="Closed Icon" />;
    default:
      return <img src={'/images/in-progress-icon.svg'} alt="In-progress/Scheduled Icon" />;
  }
};

export default EnrolmentStatusIcon;
