export interface FormOption<T extends string | number | boolean> {
  value: T;
  label: string;
}

export type BooleanFilter = 'true' | 'false';

export const isEmpty: FormOption<any> = { value: 'IS_EMPTY', label: 'Is Empty' };

export const accountStatusOptions: FormOption<any>[] = [
  { value: true, label: 'Enabled' },
  { value: false, label: 'Disabled' },
];

export const booleanFilterOptions: FormOption<BooleanFilter>[] = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
];
