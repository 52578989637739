import { TextField } from '@mui/material';
import {
  DatePickerProps,
  DateTimePickerProps,
  DateTimePicker as MUIDateTimePicker,
  DesktopDatePicker as MUIDesktopDatePicker,
  TimePicker as MUITimePicker,
  TimePickerProps,
  renderTimeViewClock,
} from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { FieldValues, Path, UseControllerProps, useController } from 'react-hook-form';
import { TimePickerStyle, useUserPreferences } from '../../contexts/UserPreferencesContext';

type Props<T extends FieldValues, TObj extends Path<T>> = {
  label: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  disabled?: boolean;
  treatDateAsNumber?: boolean;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'filled' | 'outlined';
} & UseControllerProps<T, TObj> &
  Omit<DatePickerProps<DateTime>, 'value' | 'onChange' | 'renderInput'>;

export function DatePicker<T extends FieldValues, TObj extends Path<T>>(props: Props<T, TObj>) {
  const { name, control, defaultValue, rules, shouldUnregister, ...rest } = props;

  const { field, fieldState } = useController({
    name: name,
    control: control,
    defaultValue: defaultValue,
    rules: rules,
    shouldUnregister: shouldUnregister,
  });

  //converting back to number to pass through to api
  const onChange = (date: DateTime | null) => {
    if (props.treatDateAsNumber) {
      field.onChange(date ? date.toMillis() : null);
    } else {
      field.onChange(date ? date.toISODate() : null);
    }
  };

  return (
    <MUIDesktopDatePicker
      {...rest}
      label={props.label}
      disableFuture={props.disableFuture}
      disablePast={props.disablePast}
      ref={field.ref}
      onChange={(newValue) => onChange(newValue as DateTime)}
      value={field.value ? (props.treatDateAsNumber ? DateTime.fromMillis(field.value as number) : DateTime.fromISO(field.value as string)) : null}
      format="LL/dd/yyyy"
      slotProps={{
        textField: {
          helperText: fieldState.error?.message,
          error: !!fieldState.error,
        },
      }}
      disabled={props.disabled}
    />
  );
}

export function YearPicker<T extends FieldValues, TObj extends Path<T>>(props: Props<T, TObj>) {
  const { field } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });

  const { value, ...rest } = field;

  //converting back to number to pass through to api
  const onChange = (date: DateTime | null) => {
    field.onChange(date ? date.year : null);
  };

  return (
    <MUIDesktopDatePicker
      {...rest}
      onChange={onChange}
      value={value ? DateTime.fromFormat(`01/01/${value}`, 'dd/LL/yyyy') : null}
      label={props.label}
      format="yyyy"
      views={['year']}
      disabled={props.disabled}
    />
  );
}

export function TimePicker<T extends FieldValues, TObj extends Path<T>>(
  props: UseControllerProps<T, TObj> & {
    label: string;
    disabled?: boolean;
  },
) {
  const { field, fieldState } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });

  //converting back to number to pass through to api
  const onChange = (value: string) => {
    const valueToMillis = DateTime.fromFormat(value, 'HH:mm').toMillis();
    field.onChange(valueToMillis);
  };

  function getValue() {
    if (!field.value) return null;
    return DateTime.fromMillis(field.value).toFormat('HH:mm');
  }

  return (
    <TextField
      type="time"
      label={props.label}
      helperText={fieldState.error?.message}
      disabled={props.disabled}
      value={getValue()}
      onChange={(e) => onChange(e.target.value)}
      InputLabelProps={{ shrink: true }}
      sx={{ '& .MuiInputBase-input': { padding: '8.5px 14px' } }}
    />
  );
}

export function DateTimePicker<T extends FieldValues, TObj extends Path<T>>(
  props: UseControllerProps<T, TObj> &
    Omit<DateTimePickerProps<DateTime>, 'onChange' | 'value' | 'renderInput'> & { size?: 'small' | 'medium'; fullWidth?: boolean },
) {
  const { field } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });

  //converting back to number to pass through to api
  const onChange = (date: DateTime | null) => {
    field.onChange(date ? date.toMillis() : null);
  };

  return <MUIDateTimePicker label={props.label} value={field.value ? DateTime.fromMillis(field.value as number) : null} onChange={onChange} />;
}

// TODO: After updating to MUI V6 we can use that combo picker instead.
export function DateTimePicker2(props: { label: string; value: number | null; onChange: (val: number | null) => void }) {
  const { timePickerStyle } = useUserPreferences();

  const value = props.value ? DateTime.fromMillis(props.value) : undefined;
  const handleDateChange = (val: DateTime | null) => {
    if (val) {
      props.onChange(val.toMillis());
    } else {
      props.onChange(null);
    }
  };

  return (
    <MUIDateTimePicker
      onChange={handleDateChange}
      value={value}
      viewRenderers={
        timePickerStyle === TimePickerStyle.Analog
          ? {
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }
          : undefined
      }
      label={props.label}
    />
  );
}

export function TimePicker2(props: TimePickerProps<DateTime>) {
  const { timePickerStyle } = useUserPreferences();

  return (
    <MUITimePicker
      {...props}
      viewRenderers={
        timePickerStyle === TimePickerStyle.Analog
          ? {
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }
          : undefined
      }
    />
  );
}

type TimePicker3Props<T extends FieldValues, TObj extends Path<T>> = {
  label: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'filled' | 'outlined';
} & UseControllerProps<T, TObj> &
  Omit<TimePickerProps<DateTime>, 'value' | 'onChange' | 'renderInput'>;

export function TimePicker3<T extends FieldValues, TObj extends Path<T>>(props: TimePicker3Props<T, TObj>) {
  const { name, control, defaultValue, rules, shouldUnregister, ...rest } = props;
  const { timePickerStyle } = useUserPreferences();
  const { field, fieldState } = useController({
    name: name,
    control: control,
    defaultValue: defaultValue,
    rules: rules,
    shouldUnregister: shouldUnregister,
  });

  return (
    <MUITimePicker
      {...rest}
      slotProps={{
        textField: {
          size: 'medium',
          helperText: fieldState.error?.message,
          error: !!fieldState.error,
        },
      }}
      value={field.value ? DateTime.fromMillis(field.value as number) : null}
      onChange={(val) => field.onChange(val ? val.toMillis() : null)}
      viewRenderers={
        timePickerStyle === TimePickerStyle.Analog
          ? {
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }
          : undefined
      }
    />
  );
}
