import { Close, ContentCopy } from '@mui/icons-material';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { useAlert } from '@hoot/contexts/AlertContext';
import { ShiftUtilizationShift } from '@hoot/hooks/api/shifts/useGetShiftUtilization';
import { shiftColour } from '@hoot/pages/users/components/user-schedule/weekly-schedule/utils';
import { routes } from '@hoot/routes/routes';
import { enumToTitleCase } from '@hoot/utils/stringUtils';

interface IShiftDetailsProps {
  open: boolean;
  onClose: () => void;
  shift: ShiftUtilizationShift | undefined;
}

const ShiftDetailsDialog = (props: IShiftDetailsProps) => {
  const { open, onClose, shift } = props;

  const { info } = useAlert();
  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    info(`Copied "${val}" to clipboard.`);
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { width: '20%' } }}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Shift Details</Typography>
          <IconButton onClick={onClose} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {shift ? (
          <Stack gap={2} justifyContent="space-evenly">
            <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
              <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                <Typography variant="bodyMedium">Shift ID</Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Link href={routes.users.teachers.shifts.details.url(shift.teacherAccountId, shift.id)}>
                    <Typography variant="titleMedium">{shift.prefixedNumber}</Typography>
                  </Link>
                  <Tooltip title="Copy to clipboard">
                    <IconButton onClick={handleCopyToClipboard(shift.prefixedNumber)} sx={{ height: '12px', width: '12px' }}>
                      <ContentCopy sx={{ height: '16px', width: '16px' }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Card>
            <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
              <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                <Typography variant="bodyMedium">Shift Status</Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Box sx={{ width: '8px', height: '8px', backgroundColor: shiftColour(shift.status), borderRadius: '50px' }}></Box>
                  <Typography variant="titleMedium">{enumToTitleCase(shift.status)}</Typography>
                </Stack>
              </Stack>
            </Card>
            <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
              <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                <Typography variant="bodyMedium">Date</Typography>
                <Typography variant="titleMedium">{DateTime.fromMillis(shift.startsAt).toFormat('MMMM dd, yyyy')}</Typography>
              </Stack>
            </Card>
            <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
              <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                <Typography variant="bodyMedium">Start Time</Typography>
                <Typography variant="titleMedium">{DateTime.fromMillis(shift.startsAt).toFormat('h:mm a')}</Typography>
              </Stack>
            </Card>
            <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
              <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                <Typography variant="bodyMedium">End Time</Typography>
                <Typography variant="titleMedium">{DateTime.fromMillis(shift.endsAt).toFormat('h:mm a')}</Typography>
              </Stack>
            </Card>
          </Stack>
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShiftDetailsDialog;
