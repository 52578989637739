import { ProductProgram } from '@hoot-reading/hoot-core/dist/enums/hfs/product-program.enum';
import { Check } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
import { Control, Controller, UseFormSetValue, useForm } from 'react-hook-form';
import { DurationSelect } from '@hoot/components/form/selectFields/DurationSelect';
import { GenerateLesson, LessonTimeByDayOfWeek, generateLessonTimes } from '@hoot/pages/lessons/create-v2/utils/generateLessonTimes';
import { daysOfWeekLabels } from '@hoot/pages/lessons/utils/date';
import { DayOfWeek } from '@hoot/utils/dateTime';
import { TIME_FORMAT } from '../../../../common/constants';
import { TimePicker2 } from '../../../../components/form/DatePicker';
import CreateLessonFooter from '../CreateLessonFooter';
import { useCreateLessonContext } from '../context/CreateLessonContextProvider';
import { useStudentAndLessonContext } from '../context/StudentAndLessonContextProvider';
import { DayOfWeekForLesson, TentativeLesson, TentativeLessonStatus, useStudentLessonDetailsContext } from '../context/StudentLessonDetailsProvider';

const MAX_LESSONS = 365;

export enum RecurrenceEndType {
  EndAfterDate = 'end_after_date',
  EndOnLessonCount = 'end_on_lesson_count',
}

interface Form {
  lessonDate: DateTime;
  lessonTime: DateTime;
  lessonDuration: number;
  daysOfWeek: DayOfWeekForLesson[];
  recurrenceEnd: RecurrenceEndType;
  occurrences: number;
  endAfterDate?: DateTime;
  sameTimeEveryDay: boolean;
}

const CreateLessonDateTimeStep = () => {
  const now = DateTime.now().plus({ minutes: 1 });

  const { goToNextStep, goToPrevStep } = useCreateLessonContext();
  const { studentAndLessonData } = useStudentAndLessonContext();
  const { lessonDetails } = useStudentLessonDetailsContext();

  const isLessonSet = studentAndLessonData?.lessonSetType === 'NEW_LESSON_SET';
  const isWithinExistingLessonSet = studentAndLessonData?.lessonSetType === 'WITHIN_EXISTING_LESSON_SET';
  const isHootReadingProduct = studentAndLessonData?.selectedEnrolment?.productProgram === ProductProgram.HootReading;
  const defaultLessonDuration = isHootReadingProduct ? 25 : 20;

  function getMinStartDate() {
    if (studentAndLessonData && studentAndLessonData.selectedEnrolment && (isLessonSet || isWithinExistingLessonSet)) {
      const { startDate } = studentAndLessonData.selectedEnrolment;
      if (startDate >= now.toMillis()) {
        return DateTime.fromMillis(studentAndLessonData.selectedEnrolment.startDate).startOf('day');
      }
    }
    return DateTime.now().startOf('day');
  }

  function getMaxStartDate() {
    if (studentAndLessonData && (isLessonSet || isWithinExistingLessonSet)) {
      if (studentAndLessonData.selectedEnrolment?.startDate) {
        return DateTime.fromMillis(studentAndLessonData.selectedEnrolment.endDate).endOf('day');
      }
    }
    return DateTime.now().plus({ year: 1 }).endOf('day');
  }

  const minLessonRecurrenceDate = DateTime.now().plus({ days: 7 }).endOf('day');
  const maxLessonDate = getMaxStartDate();

  const { control, getValues, handleSubmit, watch, formState, setValue } = useForm<Form>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      lessonDate: lessonDetails.lessonAt ?? getMinStartDate(),
      lessonTime: lessonDetails.lessonAt ?? now,
      lessonDuration: lessonDetails.duration ?? defaultLessonDuration,
      daysOfWeek: lessonDetails.daysOfWeek ?? [
        { isSelected: false, dayOfWeek: DayOfWeek.Sunday },
        { isSelected: false, dayOfWeek: DayOfWeek.Monday },
        { isSelected: false, dayOfWeek: DayOfWeek.Tuesday },
        { isSelected: false, dayOfWeek: DayOfWeek.Wednesday },
        { isSelected: false, dayOfWeek: DayOfWeek.Thursday },
        { isSelected: false, dayOfWeek: DayOfWeek.Friday },
        { isSelected: false, dayOfWeek: DayOfWeek.Saturday },
      ],
      recurrenceEnd: lessonDetails.recurrenceEnd ?? (isLessonSet ? RecurrenceEndType.EndAfterDate : RecurrenceEndType.EndOnLessonCount),
      occurrences: lessonDetails.occurrences ?? 0,
      endAfterDate: lessonDetails.endAfterDate ?? minLessonRecurrenceDate,
      sameTimeEveryDay: lessonDetails.sameTimeEveryDay ?? true,
    },
  });

  const { recurrenceEnd, sameTimeEveryDay } = watch();

  const recurring = lessonDetails.recurring ?? isLessonSet ?? false;

  const startDateLabel = isLessonSet ? 'Lesson Set Start Date' : 'Start Date';
  const endDateLabel = isLessonSet ? 'Lesson Set End Date' : 'Recurrence End';

  function validateEndDate(value: DateTime | undefined) {
    const { lessonDate } = getValues();
    if (value) {
      if (value <= lessonDate) {
        return 'End Date cannot be before Start Date';
      }
    }
    return true;
  }

  const handlePreviousClick = () => {
    goToPrevStep();
  };

  const onSubmit = (data: Form) => {
    lessonDetails.duration = data.lessonDuration;
    lessonDetails.lessonAt = DateTime.fromObject({
      year: data.lessonDate.year,
      month: data.lessonDate.month,
      day: data.lessonDate.day,
      hour: data.lessonTime.hour,
      minute: data.lessonTime.minute,
      second: 0,
      millisecond: 0,
    });

    lessonDetails.daysOfWeek = data.daysOfWeek;
    lessonDetails.occurrences = data.occurrences;
    lessonDetails.recurrenceEnd = data.recurrenceEnd;
    lessonDetails.endAfterDate = data.endAfterDate;
    lessonDetails.sameTimeEveryDay = data.sameTimeEveryDay;

    if (recurring) {
      const daysOfWeek = lessonDetails.daysOfWeek
        .filter((d) => d.isSelected)
        .map<LessonTimeByDayOfWeek>((d) => ({
          dayOfWeek: d.dayOfWeek,
          time: !data.sameTimeEveryDay && d.time ? d.time : (lessonDetails.lessonAt?.toFormat('H:mm') ?? DateTime.now().toFormat('H:mm')),
        }));

      const request: GenerateLesson =
        lessonDetails.recurrenceEnd === RecurrenceEndType.EndAfterDate && lessonDetails.endAfterDate
          ? {
              // FYI: `startsAt` just represents the start day range from which we can start creating lessons from.
              // It doesn't necessarily mean that the initial lesson will land on this day (`lessonTimeByDayOfWeek`
              // will determine first lesson day starting from the `startsAt`).
              startsAt: lessonDetails.lessonAt.startOf('day'),
              lessonTimeByDayOfWeek: daysOfWeek,
              endsAt: lessonDetails.endAfterDate,
            }
          : {
              startsAt: lessonDetails.lessonAt.startOf('day'),
              lessonTimeByDayOfWeek: daysOfWeek,
              occurrences: lessonDetails.occurrences,
            };

      const tentativeLessons = generateLessonTimes(request);
      lessonDetails.tentativeLessons = tentativeLessons
        .map<TentativeLesson>((l) => ({
          lessonAt: l,
          status: TentativeLessonStatus.NotSet,
        }))
        .sort((a, b) => a.lessonAt.toMillis() - b.lessonAt.toMillis());
    } else {
      const tentativeLessons: TentativeLesson[] = [
        {
          lessonAt: lessonDetails.lessonAt,
          status: TentativeLessonStatus.NotSet,
        },
      ];
      lessonDetails.tentativeLessons = tentativeLessons;
    }
    goToNextStep();
  };

  const minStartDate = getMinStartDate();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Typography variant="titleMedium">Scheduling</Typography>

        <Stack sx={{ marginTop: '24px' }} direction="row" spacing="12px">
          <Grid container item xs={3}>
            <Controller
              name="lessonDate"
              control={control}
              rules={{
                required: true,
                validate: (val: DateTime) => {
                  if ((isLessonSet || isWithinExistingLessonSet) && (val < minStartDate || val > maxLessonDate)) {
                    return 'Lesson date(s) outside of Enrolment';
                  }
                  return true;
                },
              }}
              render={({ field, fieldState }) => (
                <DesktopDatePicker
                  label={startDateLabel}
                  disablePast
                  minDate={minStartDate}
                  maxDate={maxLessonDate}
                  onChange={field.onChange}
                  value={field.value}
                  format="LL/dd/yyyy"
                  slotProps={{
                    textField: {
                      helperText: fieldState.error?.message,
                      error: !!fieldState.error,
                    },
                  }}
                />
              )}
            />
          </Grid>

          {isLessonSet ? (
            <Grid container item xs={3}>
              <Controller
                name="endAfterDate"
                control={control}
                rules={{
                  required: isLessonSet,
                  validate: (val) => {
                    if (val && (val < minStartDate || val > maxLessonDate)) {
                      return 'Lesson date(s) outside of Enrolment';
                    }
                    return validateEndDate(val);
                  },
                }}
                render={({ field, fieldState }) => (
                  <DesktopDatePicker
                    label={endDateLabel}
                    minDate={minLessonRecurrenceDate}
                    maxDate={maxLessonDate}
                    onChange={field.onChange}
                    value={field.value}
                    format="LL/dd/yyyy"
                    slotProps={{
                      textField: {
                        helperText: fieldState.error?.message,
                        error: !!fieldState.error,
                      },
                    }}
                  />
                )}
              />
            </Grid>
          ) : undefined}

          {!recurring ? (
            <Grid container item xs={3}>
              <FormControl fullWidth>
                <Controller
                  name="lessonTime"
                  control={control}
                  rules={{
                    required: true,
                    validate: (val) => {
                      if (!val) {
                        return 'Selected Time is a required value';
                      }

                      const { lessonDate, lessonTime } = getValues();

                      if (!lessonDate) {
                        return 'Date cannot be empty!';
                      }

                      try {
                        const startsAtDateTime = lessonDate.set({
                          hour: lessonTime.hour,
                          minute: lessonTime.minute,
                          second: 0,
                          millisecond: 0,
                        });

                        const now = DateTime.now();
                        if (startsAtDateTime < now) {
                          return `Selected Time must be greater than ${now.toFormat(TIME_FORMAT)}`;
                        }
                      } catch (exc) {
                        console.error('Not a valid time.', exc);
                        return 'Not a valid time.';
                      }

                      return true;
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TimePicker2
                      onChange={field.onChange}
                      value={field.value}
                      label="Lesson Time"
                      slotProps={{ textField: { error: !!fieldState.error, helperText: fieldState.error?.message } }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          ) : null}

          <Grid container item xs={3}>
            <DurationSelect rules={{ required: true }} name="lessonDuration" control={control} />
          </Grid>
        </Stack>

        {recurring && !isWithinExistingLessonSet ? (
          <>
            <Typography sx={{ marginTop: '32px' }} variant="titleMedium">
              Recurrence Details
            </Typography>

            <Box sx={{ marginTop: '16px' }}>
              <Controller
                control={control}
                name="daysOfWeek"
                rules={{
                  validate: (data) => {
                    if (data.every((v) => v.isSelected === false)) {
                      return 'You must select 1 day of the week.';
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState }) => (
                  <DaysOfWeek
                    defaultTime={now}
                    onChange={field.onChange}
                    value={field.value}
                    errorMessage={fieldState.error?.message}
                    parentControl={control}
                    sameTimeEveryDay={sameTimeEveryDay}
                    onSameTimeEveryDayChanged={(checked) => {
                      setValue('sameTimeEveryDay', checked);
                    }}
                    setValue={setValue}
                  />
                )}
              />
            </Box>

            {!isLessonSet ? (
              <>
                <Typography sx={{ marginTop: '24px' }} variant="titleMedium">
                  Recurrence End
                </Typography>

                <Controller
                  name="recurrenceEnd"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      onChange={field.onChange}
                      value={field.value}
                      sx={{
                        marginTop: '16px',
                        width: '250px;',
                      }}
                    >
                      <FormControlLabel value={RecurrenceEndType.EndAfterDate} control={<Radio />} label="End after date" />
                      <FormControlLabel value={RecurrenceEndType.EndOnLessonCount} control={<Radio />} label="End on lesson count" />
                    </RadioGroup>
                  )}
                />

                {recurrenceEnd === RecurrenceEndType.EndOnLessonCount ? (
                  <Controller
                    control={control}
                    name="occurrences"
                    rules={{
                      required: !isLessonSet && recurrenceEnd === RecurrenceEndType.EndOnLessonCount,
                      validate: (val: number | '' | undefined) => {
                        if (!val) {
                          return 'Number of lessons is required.';
                        }

                        if (val && val < 1) {
                          return 'A minimum of 1 lesson is required.';
                        }

                        if (val && val > MAX_LESSONS) {
                          return `Maximum number of lessons allowed is ${MAX_LESSONS}.`;
                        }

                        return true;
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <Stack>
                        <TextField
                          sx={{
                            marginTop: '32px',
                            maxWidth: '156px',
                          }}
                          error={!!fieldState.error}
                          onChange={(e) => field.onChange(parseInt(e.currentTarget.value))}
                          value={field.value}
                          variant="outlined"
                          label="Number of lessons"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          helperText={fieldState.error ? fieldState.error.message : null}
                        />
                      </Stack>
                    )}
                  />
                ) : (
                  <FormControl
                    sx={{
                      marginTop: '32px',
                      maxWidth: '156px',
                    }}
                    fullWidth
                  >
                    <Controller
                      name="endAfterDate"
                      control={control}
                      rules={{
                        required: !isLessonSet && recurrenceEnd === RecurrenceEndType.EndAfterDate,
                        validate: validateEndDate,
                      }}
                      render={({ field, fieldState }) => (
                        <DesktopDatePicker
                          label={endDateLabel}
                          minDate={minLessonRecurrenceDate}
                          maxDate={maxLessonDate}
                          onChange={field.onChange}
                          value={field.value}
                          format="LL/dd/yyyy"
                          slotProps={{
                            textField: {
                              helperText: fieldState.error?.message,
                              error: !!fieldState.error,
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}
              </>
            ) : undefined}
          </>
        ) : undefined}
      </Stack>

      <CreateLessonFooter>
        <Button onClick={handlePreviousClick} variant="contained" size="large">
          Previous Step
        </Button>
        <Button disabled={!formState.isValid} type="submit" variant="contained" size="large">
          Next Step
        </Button>
      </CreateLessonFooter>
    </form>
  );
};

function DaysOfWeek(props: {
  onChange: (val: DayOfWeekForLesson[]) => void;
  value: DayOfWeekForLesson[];
  defaultTime: DateTime;
  errorMessage?: string;
  parentControl: Control<Form, any>;
  sameTimeEveryDay: boolean;
  onSameTimeEveryDayChanged: (checked: boolean) => void;
  setValue: UseFormSetValue<Form>;
}) {
  const handleClick = (dayOfWeek: DayOfWeek) => () => {
    const updatedValue = props.value.map((v) => (v.dayOfWeek === dayOfWeek ? { ...v, isSelected: !v.isSelected } : v));
    props.onChange(updatedValue);
  };

  const handleTimeChange = (date: string, dayOfWeek: DayOfWeek) => {
    const updatedValue = props.value.map((v) =>
      v.dayOfWeek === dayOfWeek
        ? {
            ...v,
            time: date,
          }
        : v,
    );
    props.onChange(updatedValue);
  };

  const handleSameTimeEverydayChecked = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    props.onSameTimeEveryDayChanged(checked);
  };

  return (
    <Stack>
      <Stack direction="row" spacing="8px">
        {props.value.map((dow) => (
          <Chip
            key={dow.dayOfWeek}
            icon={dow.isSelected ? <Check /> : undefined}
            sx={{
              backgroundColor: dow.isSelected ? '#D8E3F8' : '#FFFFFF',
            }}
            variant="outlined"
            label={daysOfWeekLabels.get(dow.dayOfWeek)}
            onClick={handleClick(dow.dayOfWeek)}
          />
        ))}
      </Stack>
      {props.errorMessage ? <FormHelperText error>{props.errorMessage}</FormHelperText> : null}
      <FormGroup sx={{ marginTop: '12px', width: '250px;' }}>
        <FormControlLabel
          control={<Checkbox onChange={handleSameTimeEverydayChecked} checked={props.sameTimeEveryDay} />}
          label="Same time every day"
        />
      </FormGroup>
      <Stack alignItems="center" sx={{ marginTop: '24px' }} direction="row" spacing="24px">
        {!props.sameTimeEveryDay ? (
          props.value
            .filter((dow) => dow.isSelected)
            .map((dow) => {
              return (
                <Fragment key={`weekday-${dow.dayOfWeek}`}>
                  <TimePicker2
                    label={daysOfWeekLabels.get(dow.dayOfWeek)}
                    value={dow.time ? DateTime.fromFormat(dow.time, 'HH:mm') : DateTime.now()}
                    onChange={(val) => {
                      if (val) {
                        handleTimeChange(val?.toFormat('HH:mm'), dow.dayOfWeek);
                      }
                    }}
                  />
                </Fragment>
              );
            })
        ) : (
          <FormControl>
            <Controller
              name="lessonTime"
              control={props.parentControl}
              rules={{
                required: true,
                validate: (val) => {
                  if (!val) {
                    return 'Selected Time is a required value';
                  }

                  return true;
                },
              }}
              render={({ field, fieldState }) => (
                <TimePicker2
                  onChange={field.onChange}
                  value={field.value}
                  slotProps={{
                    textField: {
                      helperText: fieldState.error?.message,
                      error: !!fieldState.error,
                    },
                  }}
                />
              )}
            />
          </FormControl>
        )}
      </Stack>
    </Stack>
  );
}

export default CreateLessonDateTimeStep;
