import { Button, Container, Grid, Paper, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { ChangeEvent, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import useExportAvailablity from '@hoot/hooks/api/availability/useExportAvailablity';
import useImportAvailablity from '@hoot/hooks/api/availability/useImportAvailability';
import { useAlert } from '../../../contexts/AlertContext';
import { QueryKey } from '../../../hooks/api/queryKeys';

const ImportExportAvailability = () => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { success, error } = useAlert();
  const [fetchExport, setFetchExport] = useState<boolean>(false);

  const queryClient = useQueryClient();
  useExportAvailablity({
    enabled: fetchExport,
    onSuccess: (data) => {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Teacher Availability (${DateTime.now().toFormat('yyyy-MM-dd H:mm:ss')}).csv`);
      document.body.appendChild(link);
      link.click();
      setFetchExport(false);
    },
  });

  const handleImportClick = () => {
    hiddenFileInput.current?.click();
  };

  const importAvailablityMutation = useImportAvailablity();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      try {
        const uploadFile = event.target.files[0];
        const formData = new FormData();
        formData.append('file', uploadFile);
        importAvailablityMutation.mutate(formData, {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.TeacherAvailability);
            success('Teacher Availability has been successfully imported.');
          },
          onError: (exc) => {
            console.log(exc);
            const message = exc.response?.data ? exc.response.data.message : 'An unexpected error occurred. Please try again.';
            error(message);
          },
        });
      } catch (exc) {
        console.error('Unexpected error in file upload', exc);
      }
    }
  };

  const handleExportClick = async () => {
    setFetchExport(true);
  };

  return (
    <Container
      component={motion.div}
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: 1, y: 0 }}
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        padding: 0,
      }}
      maxWidth="xl"
    >
      <Paper
        sx={{
          flex: '1 1 auto',
          padding: (theme) => theme.spacing(3),
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        <Grid direction="column" container alignItems="center">
          <Grid container item xs={4} justifyContent="center">
            <Typography variant="h4">Availability Import/Export</Typography>
          </Grid>
          <Grid container item xs={4} justifyContent="space-between">
            <Button variant="outlined" onClick={handleImportClick}>
              Import
            </Button>
            <input type="file" ref={hiddenFileInput} onChange={handleFileChange} style={{ display: 'none' }} />

            <Button onClick={handleExportClick} variant="outlined">
              Export
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ImportExportAvailability;
