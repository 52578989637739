import {
  ScheduledLessonLanguage,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonType,
  lessonLanguagesLookup,
  lessonStatusesLookup,
  lessonSubjectLookup,
  scheduledLessonTypeLookup,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Card, CardContent, Grid, IconButton, Link, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import useGetStudentProfile from '@hoot/hooks/api/end-users/useGetStudentProfile';
import { AdminLessonNoteDetails } from '@hoot/hooks/api/lesson-notes/useGetLessonNoteDetailsById';
import { routes } from '@hoot/routes/routes';

interface LessonNoteDetailsCardProps {
  lessonNoteDetails: AdminLessonNoteDetails | undefined;
  isLoading: boolean;
}

const LessonNoteDetailsCard = (props: LessonNoteDetailsCardProps) => {
  const { lessonNoteDetails, isLoading } = props;
  const { studentProfileId } = useParams() as { studentProfileId: string };
  const { info } = useAlert();

  const { data: studentProfile } = useGetStudentProfile(studentProfileId!, {
    enabled: !!studentProfileId,
    retry: false,
  });

  const renderGridItemField = (
    label: string,
    value: string | undefined,
    copyableProps?: {
      copyable: boolean;
      copyTextOverride?: string;
    },
    hyperLink?: string,
  ) => {
    const renderField = () => (
      <>
        {value ?? '-'}
        {copyableProps?.copyable && (copyableProps.copyTextOverride || value) ? (
          // If we're copying text that isn't displayed, then show the tooltip.
          <Tooltip title={copyableProps.copyTextOverride}>
            <IconButton
              onClick={handleCopyToClipboard(copyableProps.copyTextOverride ?? value ?? '')}
              sx={{ width: '14px', height: '14px', ml: 1.25 }}
            >
              <ContentCopyIcon sx={{ width: '20px', height: '20px' }} />
            </IconButton>
          </Tooltip>
        ) : null}
      </>
    );
    return (
      <Grid item xs={12}>
        <Stack>
          {!isLoading ? (
            <>
              <Typography variant="titleMedium">{label}</Typography>
              {hyperLink ? (
                <Link href={hyperLink} color="inherit" sx={{ width: 'max-content' }}>
                  <Typography variant="bodyMedium">{renderField()}</Typography>
                </Link>
              ) : (
                <Typography variant="bodyMedium">{renderField()}</Typography>
              )}
            </>
          ) : (
            <>
              <Skeleton variant="text" sx={{ fontSize: '16px' }} />
              <Skeleton variant="text" sx={{ fontSize: '14px' }} />
            </>
          )}
        </Stack>
      </Grid>
    );
  };

  const labels = useMemo(() => {
    if (!lessonNoteDetails) {
      return;
    }
    const lessonStartTime = DateTime.fromMillis(lessonNoteDetails.lessonStartTime).toFormat('cccc, MMMM d, y, t ZZZZ');
    const lessonNumber = lessonNoteDetails.lessonNumber;
    const lessonType = scheduledLessonTypeLookup[lessonNoteDetails.lessonType as ScheduledLessonType] ?? lessonNoteDetails.lessonType;
    const lessonStatus = lessonStatusesLookup[lessonNoteDetails.lessonStatus as ScheduledLessonStatus] ?? lessonNoteDetails.lessonStatus;
    const subject = lessonSubjectLookup[lessonNoteDetails.lessonSubject as ScheduledLessonSubject] ?? lessonNoteDetails.lessonSubject;
    const createdBy = lessonNoteDetails?.createdBy.fullName;
    const language = lessonLanguagesLookup[lessonNoteDetails.lessonLanguage as ScheduledLessonLanguage] ?? lessonNoteDetails.lessonLanguage;

    return {
      lessonStartTime,
      lessonNumber,
      lessonType,
      lessonStatus,
      subject,
      createdBy,
      language,
    };
  }, [lessonNoteDetails]);

  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    info(`Copied "${val}" to clipboard.`);
  };

  const studentName = studentProfile?.prefixedNumber ?? 'Number not found';
  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="titleLarge" sx={{ mb: 3, display: 'block' }}>
            Details
          </Typography>
          <Grid container spacing={2}>
            {renderGridItemField('Student Name', studentName, undefined, studentProfileId ? routes.users.students.details.url(studentProfileId) : '')}
            {renderGridItemField('Lesson Date', labels?.lessonStartTime ?? '-')}
            {renderGridItemField(
              'Lesson Number',
              labels?.lessonNumber ?? '-',
              undefined,
              lessonNoteDetails && lessonNoteDetails.lessonId ? routes.lessons.details.url(lessonNoteDetails.lessonId) : '',
            )}
            {renderGridItemField('Lesson Type', labels?.lessonType ?? '-')}
            {renderGridItemField('Lesson Status', labels?.lessonStatus ?? '-')}
            {renderGridItemField('Subject', labels?.subject)}
            {renderGridItemField('Created By', labels?.createdBy, {
              copyable: true,
              copyTextOverride: lessonNoteDetails?.createdBy.email ?? '',
            })}
            {renderGridItemField('Language', labels?.language)}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default LessonNoteDetailsCard;
