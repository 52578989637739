import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { InputAdornment, MenuItem, TextField, TextFieldProps, Theme, Tooltip } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FieldValues, Path, UseControllerProps, useController } from 'react-hook-form';
import { FormOption } from '../../interfaces/form';
import { AdapterProps } from './common';

type Props<T extends string | number, U extends FieldValues, UObj extends Path<U>> = {
  label: string;
  options: FormOption<T>[];
  toolTip?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  showInputAdornment?: boolean;
} & TextFieldProps &
  UseControllerProps<U, UObj> &
  AdapterProps & {
    includeEmpty?: boolean;
  };

export const Dropdown = <T extends string | number, U extends FieldValues, UObj extends Path<U>>(props: Props<T, U, UObj>) => {
  const { options, label, includeEmpty, showInputAdornment = false } = props;
  const { field, fieldState } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });
  const classes = useStyles();

  return (
    <Tooltip title={props.toolTip || ''}>
      <TextField
        select
        value={field.value ?? ''}
        onChange={(val) => {
          field.onChange(val);
          if (props.onChange) {
            props.onChange(val);
          }
        }}
        error={!!fieldState.error}
        label={`${label}`}
        variant={props.variant || 'standard'}
        fullWidth
        disabled={props.disabled}
        InputLabelProps={{
          className: clsx(fieldState.isDirty && classes.dirtyField),
        }}
        helperText={props.helperTextFn?.(fieldState)}
        size={props.size}
        InputProps={{
          startAdornment:
            showInputAdornment && !!field.value && field.value !== 'false' ? (
              <InputAdornment position="start">
                <CheckCircleIcon sx={{ color: '#1976D2' }} />
              </InputAdornment>
            ) : null,
        }}
      >
        {includeEmpty ? (
          <MenuItem key="default" value="">
            None
          </MenuItem>
        ) : null}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} style={{ minHeight: 36 }}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dirtyField: {
      color: theme.palette.primary.dark,
    },
  }),
);
