import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { Box, Chip, Grid, List, Table, TableBody, TableCell, TableFooter, TablePagination, TableRow, styled } from '@mui/material';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { defaultRowsPerPage, rowsPerPageOptions } from '@hoot/common/constants';
import SearchTextField from '@hoot/components/form/SearchTextField';
import EnrolmentStatusIcon from '@hoot/components/ui/enrolments/EnrolmentStatusIcon';
import ProductsStatusIcon from '@hoot/components/ui/products/ProductsStatusIcon';
import { GetLocationProductsResponse } from '@hoot/hooks/api/hfs/useSearchLocationProducts';
import { hootQualifications } from '@hoot/interfaces/teacher';
import { routes } from '@hoot/routes/routes';
import { millisToDateDisplay } from '@hoot/utils/dateTime';
import { NoneListItem, SortableTableHeader, TableHeaderProp } from '../details/Shared';
import { ActiveProductsTableFiltersContainer, ProductsFilters, ProductsTableFiltersContainer } from './ProductsTableFilters';

const EMPTY_TEXT = 'None';
const REQUIREMENTS_TO_SHOW = 4;

const DistrictSchoolLink = styled(Link)({
  textDecoration: 'none',
  color: '#1976D2',
});

const productsTableHeaders: TableHeaderProp<GetLocationProductsResponse>[] = [
  { property: 'friendlyId', label: 'Product ID' },
  { property: 'startDate', label: 'Start Date' },
  { property: 'endDate', label: 'End Date' },
  { property: 'requirements', label: 'Requirements' },
  { property: 'enrolments', label: 'Enrolments' },
  { property: 'status', label: 'Status' },
];

interface Props {
  locationId: string;
  products: GetLocationProductsResponse[];
}

const ProductsTable = (props: Props) => {
  const { locationId, products } = props;

  const [filters, setFilters] = useState<ProductsFilters | null>(null);

  const [query, setQuery] = useState<string>('');
  const [pageSize, setRowsPerPage] = useState<number>(defaultRowsPerPage);
  const [page, setPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState<keyof GetLocationProductsResponse | null>('friendlyId');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>('desc');
  const [requirementsExpanded, setIsRequirementsExpanded] = useState<boolean>(false);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, defaultRowsPerPage));
    setPage(0);
  };

  const handleSearchChange = (val: string) => {
    setQuery(val);
  };

  const filterFunc = (value: GetLocationProductsResponse): boolean => {
    const meetsCriteria: boolean[] = [];

    if (value.friendlyId.toLowerCase().includes(query.toLowerCase())) {
      meetsCriteria.push(true);
    }

    if (filters && filters.status && filters.status?.length > 0) {
      meetsCriteria.push(filters.status.includes(value.status));
    }

    if (filters && filters.requirements && filters.requirements?.length > 0) {
      filters.requirements.forEach((req) => {
        meetsCriteria.push((value.requirements as HootQualification[]).includes(req as HootQualification));
      });
    }

    if (filters && filters.startsAt) {
      meetsCriteria.push(value.startDate > DateTime.fromFormat(filters.startsAt, 'dd/LL/yyyy').toMillis());
    }

    if (filters && filters.endsAt) {
      meetsCriteria.push(value.endDate < DateTime.fromFormat(filters.endsAt, 'dd/LL/yyyy').toMillis());
    }

    return meetsCriteria.length > 0 && meetsCriteria.every((c) => c === true);
  };

  const sortFunc = (p1: GetLocationProductsResponse, p2: GetLocationProductsResponse): number => {
    if (!sortBy) {
      //Standard sort
      if (p1.status === ProductStatus.Active && p2.status !== ProductStatus.Active) {
        return -1;
      } else if (p1.status === ProductStatus.Requested && p2.status === ProductStatus.Expired) {
        return -1;
      } else if (p1.status === ProductStatus.Expired && p2.status !== ProductStatus.Expired) {
        return 1;
      } else if (p1.status === ProductStatus.Requested && p2.status === ProductStatus.Active) {
        return 1;
      } else {
        return p2.startDate - p1.startDate;
      }
    } else {
      if (typeof p1[sortBy] === 'number' && typeof p2[sortBy] === 'number') {
        if (sortOrder === 'asc') {
          return p1[sortBy] < p2[sortBy] ? 1 : -1;
        } else {
          return p2[sortBy] < p1[sortBy] ? 1 : -1;
        }
      } else if (typeof p1[sortBy] === 'string' && typeof p2[sortBy] === 'string') {
        if (sortOrder === 'asc') {
          return (p1[sortBy] as string).localeCompare(p2[sortBy] as string);
        } else {
          return (p2[sortBy] as string).localeCompare(p1[sortBy] as string);
        }
      } else if (sortBy === 'enrolments') {
        if (sortOrder === 'asc') {
          return (p1[sortBy][0] ? (p1[sortBy][0].status as string) : EMPTY_TEXT).localeCompare(
            p2[sortBy][0] ? (p2[sortBy][0].status as string) : EMPTY_TEXT,
          );
        } else {
          return (p2[sortBy][0] ? (p2[sortBy][0].status as string) : EMPTY_TEXT).localeCompare(
            p1[sortBy][0] ? (p1[sortBy][0].status as string) : EMPTY_TEXT,
          );
        }
      }
      return 0;
    }
  };

  const truncatedProducts = products
    .filter(filterFunc)
    .sort(sortFunc)
    .slice(page * pageSize, page * pageSize + pageSize);

  return (
    <>
      {products.length > 0 ? (
        <>
          <Grid container direction="column" mb={3}>
            <Grid item>
              <SearchTextField onSearchInputChanged={handleSearchChange} searchInput={query} />
            </Grid>
            <Grid sx={{ marginTop: '24px' }} container item xs={12}>
              <ProductsTableFiltersContainer value={filters} onChange={(val) => setFilters(val)} />
            </Grid>
            <Grid sx={{ marginTop: '24px' }} container item xs={12}>
              <ActiveProductsTableFiltersContainer value={filters} onChange={(val) => setFilters(val)} />
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="products table">
            <SortableTableHeader
              headers={productsTableHeaders}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
            <TableBody>
              {truncatedProducts.map((p) => (
                <TableRow key={p.productId}>
                  <TableCell>
                    <DistrictSchoolLink to={routes.districtsSchools.products.details.url(locationId, p.productId)}>{p.friendlyId}</DistrictSchoolLink>
                  </TableCell>
                  <TableCell>{millisToDateDisplay(p.startDate)}</TableCell>
                  <TableCell>{millisToDateDisplay(p.endDate)}</TableCell>
                  <TableCell>
                    {p.requirements && p.requirements.length > 0 ? (
                      <>
                        {p.requirements.slice(0, requirementsExpanded ? undefined : REQUIREMENTS_TO_SHOW).map((r, idx) => (
                          <Box key={idx}>{hootQualifications[r as HootQualification]}</Box>
                        ))}
                        {p.requirements.length > REQUIREMENTS_TO_SHOW ? (
                          <Box
                            sx={{ color: '#1976D2', display: 'flex', alignItems: 'center', marginBottom: '8px', cursor: 'pointer' }}
                            onClick={() => setIsRequirementsExpanded((current) => !current)}
                          >
                            <Box sx={{ color: '#1976D2' }}>
                              {requirementsExpanded ? <>(Collapse requirements)</> : <>(View {p.requirements.length - REQUIREMENTS_TO_SHOW} more)</>}
                            </Box>
                          </Box>
                        ) : null}
                      </>
                    ) : (
                      EMPTY_TEXT
                    )}
                  </TableCell>
                  <TableCell>
                    {p.enrolments && p.enrolments.length > 0
                      ? p.enrolments.map((e, idx) => {
                          return (
                            <Box key={idx}>
                              <Chip
                                icon={EnrolmentStatusIcon(e.status)}
                                label={`${capitalCase(e.status)} (${e.count})`}
                                sx={{ margin: '4px', fontSize: 12, backgroundColor: '#EFEFEF' }}
                              />
                            </Box>
                          );
                        })
                      : EMPTY_TEXT}
                  </TableCell>
                  <TableCell>
                    <Chip
                      icon={ProductsStatusIcon(p.status)}
                      label={`${capitalCase(p.status)}`}
                      sx={{ margin: '4px', fontSize: 12, backgroundColor: '#EFEFEF' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={productsTableHeaders.length}
                  rowsPerPageOptions={rowsPerPageOptions}
                  count={truncatedProducts.length || 0}
                  rowsPerPage={pageSize || 0}
                  page={(page || 1) - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </>
      ) : (
        <List sx={{ width: '100%' }}>
          <NoneListItem />
        </List>
      )}
    </>
  );
};

export default ProductsTable;
