import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';

const ProductsStatusIcon = (status: ProductStatus) => {
  switch (status) {
    case ProductStatus.Active:
      return <img src={'/images/open-icon.svg'} alt="Open Icon" />;
    case ProductStatus.Requested:
      return <img src={'/images/in-progress-icon.svg'} alt="In-progress/Scheduled Icon" />;
    case ProductStatus.Expired:
      return <img src={'/images/closed-icon.svg'} alt="Closed Icon" />;
  }
};

export default ProductsStatusIcon;
