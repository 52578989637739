import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton } from '@mui/material';

const ConfirmRemoveDialog = (props: { open: boolean; onClose: () => void; onContinue: () => void; title?: string; bodyText?: string }) => {
  const { open, title, bodyText, onClose, onContinue } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {title ?? 'Confirm Remove'}
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{bodyText ?? 'Are you sure you want to continue with this action?'}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3, position: 'relative' }}>
        <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
        <Button size="large" variant="outlined" color="primary" onClick={onClose} autoFocus>
          Cancel
        </Button>
        <Button size="large" variant="contained" color="error" onClick={onContinue}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmRemoveDialog;
