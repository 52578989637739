import axios from 'axios';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import { config } from '../../../config';
import { SortableQuery } from '../../../interfaces/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '../../../interfaces/pagination';
import { QueryKey } from '../queryKeys';

export enum TeacherInvoicePeriodsQuerySortKeyEnum {
  Identifier = 'identifier',
  Month = 'month',
  Year = 'year',
}

export interface SearchTeacherInvoicePeriodsQuery extends PaginatableQuery, SortableQuery<TeacherInvoicePeriodsQuerySortKeyEnum> {
  month?: string[];
  year?: string[];
}

export interface TeacherInvoicePeriodsResponse {
  id: string;
  identifier: string;
  year: string;
  month: string;
}

const searchTeacherInvoicePeriods = async (
  searchParams: SearchTeacherInvoicePeriodsQuery,
): Promise<GenericPaginatedResponse<TeacherInvoicePeriodsResponse>> => {
  const { data } = await axios.get(`${config.apiUrl}/admin/teacher-invoicing/search-periods`, {
    params: searchParams,
  });
  return data;
};

export default function useSearchTeacherInvoicePeriods(
  searchParams: SearchTeacherInvoicePeriodsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<TeacherInvoicePeriodsResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.SearchTeacherInvoicePeriods, searchParams], () => searchTeacherInvoicePeriods(searchParams), options);
}
