import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { StudentType } from '@hoot-reading/hoot-core/dist/enums/user/student/student-type.enum';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { REDACTED_NOTE } from '@hoot/common/constants';
import { StudentProfileResponse } from '@hoot/hooks/api/end-users/useGetStudentProfile';
import { StudentDetailsForm } from './form';

export default function useStudentDetailsForm(redactDetails: boolean, isEditMode: boolean, studentDetails?: StudentProfileResponse) {
  const getFormValues: (studentDetails: StudentProfileResponse, redactDetails: boolean) => StudentDetailsForm = (studentDetails, redactDetails) => {
    const formValues: StudentDetailsForm = {
      name: redactDetails ? REDACTED_NOTE : (studentDetails.name ?? ''),
      hfsStudentNumber: studentDetails.hfsStudentNumber,
      birthYear: studentDetails.birthYear,
      grade: studentDetails.grade ?? '',
      interests: studentDetails.interests ?? '',
      pronouns: studentDetails.pronouns ?? '',
      focusChallenges: studentDetails.focusChallenges ?? '',
      parentNotes: redactDetails ? REDACTED_NOTE : (studentDetails.parentNotes ?? ''),
      hootNotes: studentDetails.hootNotes ?? '',
      hootNotesExpireDate: studentDetails.hootNotesExpireDate,
      fundingPeriodEnd: studentDetails.fundingPeriodEnd,
      membershipOptions: studentDetails.membershipOptions ?? [],
      status: studentDetails.status ?? StudentStatus.Lead,
      previousStatus: studentDetails.previousStatus,
      leadInitiationDate: studentDetails.leadInitiationDate,
      conversionDate: studentDetails.conversionDate,
      notConvertedReason: studentDetails.notConvertedReason ?? '',
      pauseEndDate: studentDetails.pauseEndDate,
      inactiveDate: studentDetails.inactiveDate,
      inactiveReason: studentDetails.inactiveReason ?? '',
      pausedReason: studentDetails.pausedReason ?? '',
      futureStatus: studentDetails.futureStatus,
      effectiveDate: studentDetails.effectiveDate,
      pausedDate: studentDetails.pausedDate,
      actualReturnDate: studentDetails.actualReturnDate,
      studentNumber: studentDetails.number,
      parentInfo: studentDetails.parentInfo,
      hfsLocations: studentDetails.hfsLocations,
      enrolments: studentDetails.enrolments,
      studentType: studentDetails.studentType,
    };
    return formValues;
  };

  const defaults: StudentDetailsForm = {
    name: '',
    hfsStudentNumber: '',
    birthYear: null,
    grade: '',
    interests: '',
    pronouns: '',
    focusChallenges: '',
    parentNotes: '',
    hootNotes: '',
    hootNotesExpireDate: null,
    fundingPeriodEnd: null,
    membershipOptions: [],
    status: StudentStatus.Lead,
    previousStatus: null,
    leadInitiationDate: isEditMode ? null : DateTime.now().toISODate(),
    conversionDate: null,
    notConvertedReason: '',
    pauseEndDate: null,
    inactiveDate: isEditMode ? null : DateTime.now().toISODate(),
    inactiveReason: '',
    pausedReason: '',
    futureStatus: null,
    effectiveDate: null,
    pausedDate: null,
    actualReturnDate: null,
    studentNumber: null,
    parentInfo: null,
    hfsLocations: null,
    enrolments: null,
    studentType: StudentType.HootTutoring,
  };

  const formItems = useForm<StudentDetailsForm>({
    mode: 'onChange',
    defaultValues: studentDetails ? getFormValues(studentDetails, redactDetails) : defaults,
  });

  const resetForm = useCallback(() => {
    if (studentDetails) {
      formItems.reset(getFormValues(studentDetails, redactDetails));
    }
  }, [formItems, studentDetails, redactDetails]);

  return {
    ...formItems,
    resetForm,
  };
}
