import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useAlert } from '@hoot/contexts/AlertContext';
import { Subject } from '@hoot/hooks/api/library/enums';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { useUpdateLessonReview } from '@hoot/hooks/api/user/student/lesson-reviews/useUpdateLessonReview';
import {
  LessonReviewFocus,
  LessonReviewFocusSkill,
  LessonReviewFocusType,
  LessonReviewFocusUnit,
  lessonReviewFocusLabel,
  lessonReviewFocusSkillLabel,
  lessonReviewFocusUnitLabel,
  lessonReviewFocusesBySubject,
  lessonReviewFocusesBySubjectV2,
  lessonReviewSkillByUnit,
  lessonReviewUnitByFocus,
  lessonReviewUnitByFocusV2,
} from '../enum';

interface IProps {
  open: boolean;
  handleClose: () => void;
  subject: Subject | undefined;
  focusType: LessonReviewFocusType;
  lessonReviewId: string | undefined;
  lessonReviewVersion: number | undefined;
}

interface Form {
  focus: LessonReviewFocus;
  focusUnit: LessonReviewFocusUnit;
  focusSkill: LessonReviewFocusSkill;
  focusType: LessonReviewFocusType;
}

const focusesRequiringUnit = [
  LessonReviewFocus.PhonologicalAwareness,
  LessonReviewFocus.PhonemicAwareness,
  LessonReviewFocus.EarlyWordReading,
  LessonReviewFocus.ComplexWordReading,
  LessonReviewFocus.TextReading,
  LessonReviewFocus.PreWordReading,
];

const unitsRequiringSkill = [LessonReviewFocusUnit.LanguageComprehension, LessonReviewFocusUnit.ReadingComprehension];

const EditLessonReviewFocusModal = (props: IProps) => {
  const { open, handleClose, subject, focusType, lessonReviewId, lessonReviewVersion } = props;

  const { control, handleSubmit, reset, watch } = useForm<Form>({
    defaultValues: {
      focus: undefined,
      focusUnit: undefined,
      focusSkill: undefined,
      focusType: undefined,
    },
    mode: 'onSubmit',
  });

  const queryClient = useQueryClient();
  const updateLessonReview = useUpdateLessonReview(lessonReviewId);
  const { success, error } = useAlert();

  const selectedFocus = watch('focus');
  const selectedFocusUnit = watch('focusUnit');
  const selectedFocusSkill = watch('focusSkill');

  const focusLabel = `${capitalCase(focusType ?? '')} Focus`;
  const focusUnitLabel = `${focusLabel} Unit`;
  const focusSkillLabel = `${focusLabel} Skill`;

  const onSubmit = async (form: Form) => {
    const formValues: Form = {
      ...form,
      focusType,
    };
    updateLessonReview.mutate(formValues, {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.GetLessonReviewDetails, lessonReviewId]);
        success(`Lesson review updated successfully.`);
      },
      onError: () => {
        error('There was an error updating lesson review.');
      },
    });
    onClose();
  };

  const onClose = () => {
    reset();
    handleClose();
  };

  const focusOptions = useMemo(() => {
    if (!subject) {
      return [];
    }
    const lessonReviewFocuses = lessonReviewVersion === 1 ? lessonReviewFocusesBySubject : lessonReviewFocusesBySubjectV2;
    return lessonReviewFocuses[subject].map<{ value: LessonReviewFocus; label: string }>((focus) => {
      return {
        value: focus,
        label: lessonReviewFocusLabel[focus],
      };
    });
  }, [lessonReviewVersion, subject]);

  const focusUnitOptions = (lessonReviewFocus: LessonReviewFocus) => {
    // Remove this check and use "lessonReviewUnitByFocus"
    const lessonReviewUnits = lessonReviewVersion === 1 ? lessonReviewUnitByFocus : lessonReviewUnitByFocusV2;
    return lessonReviewUnits[lessonReviewFocus].map<{ value: LessonReviewFocusUnit; label: string }>((focus) => {
      return {
        value: focus,
        label: lessonReviewFocusUnitLabel[focus],
      };
    });
  };

  const focusSkillOptions = (lessonReviewFocusUnit: LessonReviewFocusUnit) => {
    return lessonReviewSkillByUnit[lessonReviewFocusUnit].map<{ value: LessonReviewFocusSkill; label: string }>((skill) => {
      return {
        value: skill,
        label: lessonReviewFocusSkillLabel[skill],
      };
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '400px' }}>
          Edit {focusLabel}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack gap={'24px'} marginTop={'8px'}>
            <Controller
              name="focus"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <InputLabel required shrink={true}>
                    {focusLabel}
                  </InputLabel>
                  <Select id="focus" label={focusLabel} name="focus" value={field.value ?? 'Select'} onChange={field.onChange} required>
                    <MenuItem value="Select" disabled>
                      Select
                    </MenuItem>
                    {focusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {!!selectedFocus && focusesRequiringUnit.includes(selectedFocus) ? (
              <Controller
                name="focusUnit"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <InputLabel required shrink={true}>
                      {focusUnitLabel}
                    </InputLabel>
                    <Select id="focusUnit" label={focusUnitLabel} name="focusUnit" value={field.value ?? 'select'} onChange={field.onChange} required>
                      <MenuItem value="select" disabled>
                        Select
                      </MenuItem>
                      {focusUnitOptions(selectedFocus).map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            ) : null}
            {!!selectedFocusUnit && unitsRequiringSkill.includes(selectedFocusUnit) ? (
              <Controller
                name="focusSkill"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <InputLabel required shrink={true}>
                      {focusSkillLabel}
                    </InputLabel>
                    <Select
                      id="focusSkill"
                      label={focusSkillLabel}
                      name="focusSkill"
                      value={field.value ?? 'select'}
                      onChange={field.onChange}
                      required
                    >
                      <MenuItem value="select" disabled>
                        Select
                      </MenuItem>
                      {focusSkillOptions(selectedFocusUnit).map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button size="large" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="large"
            variant="contained"
            type="submit"
            disabled={
              !selectedFocus ||
              (!!selectedFocus && focusesRequiringUnit.includes(selectedFocus) && !selectedFocusUnit) ||
              (!!selectedFocusUnit && unitsRequiringSkill.includes(selectedFocusUnit) && !selectedFocusSkill)
            }
          >
            Apply
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditLessonReviewFocusModal;
