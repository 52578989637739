import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { Operator } from '@hoot/components/form/OperatorFilter';
import { BookSortableQuery } from '@hoot/components/ui/SortableLibrary';
import { config } from '@hoot/config';
import { GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import { ReadingLevelType } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';
import { BookType, Grade, Language, SpellingType, Subject } from './enums';

export enum LibraryOrderByEnum {
  Title = 'title',
  BookLevel = 'bookLevel',
  BookNumber = 'bookNumber',
}

export interface AdminQueryLibraryV2 extends BookSortableQuery {
  query?: string;
  isActive?: boolean;
  publisher?: string;
  subject?: Subject;
  language?: Language;
  resourceType?: BookType;
  instructionalFocus?: string[];
  instructionalUnits?: string[];
  filterLevelPrioritizedSkills?: string[];
  levellingSystem?: ReadingLevelType;
  levels?: string[];
  decodableFormat?: string;
  genre?: string;
  subGenre?: string;
  topic?: string;
  subTopic?: string;
  theme?: string;
  subTheme?: string;
  format?: string;
  hiLo?: boolean;
  nonConforming?: boolean;
  series?: string;
  collections?: string[];
  contentTags?: string[];
  page: number;
  pageSize: number;
  minAge?: {
    operator: Operator;
    value: number;
  };
  grade?: Grade;
  spellingType?: SpellingType;
  seq?: {
    operator: Operator;
    value: number;
  };
  isDecodableLibrary?: boolean;
  isDoublePageEnabled?: boolean;
  isStandardDoublePage?: boolean;
  studentProfileId?: string;
  isInstructionalLibrary?: boolean;
}

export interface AdminLibrarySearchResponseV2 {
  id: string;
  title: string;
  cover: string;
  isActive: boolean;
  subject: Subject;
  language: Language;
  resourceType: BookType | null;
  instructionalFocus: string[];
  instructionalUnit: string[];
  filterLevelPrioritizedSkills: string[];
  level: string;
  number: string;
}

async function queryLibraryV2(query: AdminQueryLibraryV2): Promise<GenericPaginatedResponse<AdminLibrarySearchResponseV2>> {
  const { data } = await axios.post<GenericPaginatedResponse<AdminLibrarySearchResponseV2>>(`${config.apiUrl}/admin/library/search-v2`, query);
  return data;
}

export default function useQueryLibraryV2(
  query: AdminQueryLibraryV2,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<AdminLibrarySearchResponseV2>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.LibraryQuery, query], () => queryLibraryV2(query), options);
}
