import { Close } from '@mui/icons-material';
import { Divider, Drawer, IconButton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAlert } from '@hoot/contexts/AlertContext';
import useGetHootEmployee from '@hoot/hooks/api/hoot-employees/useGetHootEmployee';
import { HootEmployee } from '@hoot/interfaces/hoot-employee';
import ErrorText from './ErrorText';
import FormLoading from './FormLoading';
import HootEmployeeEdit from './HootEmployeeEdit';

interface Props {
  hootEmployeeUserId?: string;
  setHootEmployeeUserId: Dispatch<SetStateAction<string | undefined>>;
  showDrawer: boolean;
  setShowDrawer: (showAddHootEmployeeModal: boolean) => void;
}

const HootEmployeeDrawer = (props: Props) => {
  const { hootEmployeeUserId, setHootEmployeeUserId, setShowDrawer, showDrawer } = props;
  const [selectedHootEmployee, setSelectedHootEmployee] = useState<HootEmployee | undefined>(undefined);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const { error, sweetAlert } = useAlert();

  ///// Query items /////

  const { isLoading: queryIsLoading, isError } = useGetHootEmployee(hootEmployeeUserId!, {
    enabled: !!hootEmployeeUserId,
    onSuccess: (employee) => {
      setSelectedHootEmployee(employee);
    },
    onError: (e) => {
      console.error(e);
      error('Could not get the employee details. Try closing and reopening this window.');
    },
  });

  ///// Side effects /////

  useEffect(() => {
    if (!showDrawer) {
      setSelectedHootEmployee(undefined);
      setHootEmployeeUserId(undefined);
    }
  }, [showDrawer, setSelectedHootEmployee, setHootEmployeeUserId]);

  ///// Event handlers /////

  const handleClose = () => {
    if (formIsDirty) {
      sweetAlert
        .fire({
          title: 'Discard?',
          text: 'Closing without saving will discard your changes.',
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'Discard',
          confirmButtonText: 'Keep Editing',
        })
        .then((result) => {
          if (!result.isConfirmed) {
            setShowDrawer(false);
          }
        });
    } else {
      setShowDrawer(false);
    }
  };

  return (
    <Drawer anchor="right" open={showDrawer} onClose={handleClose}>
      <Box sx={{ minWidth: '320px' }}>
        <Box sx={{ position: 'relative' }}>
          <div
            style={{
              marginLeft: '24px',
              marginTop: '105px',
              marginBottom: '5px',
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '48px',
              color: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              flexGrow: 'row',
            }}
          >
            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 180 }}>
              {`${selectedHootEmployee?.firstName ?? ''} ${selectedHootEmployee?.lastName ?? ''}`}
            </div>
            <div>&nbsp;Details</div>
          </div>

          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '4px',
              cursor: 'pointer',
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Stack direction="row" justifyContent="center">
          {queryIsLoading && <FormLoading />}
          {isError && <ErrorText />}
          {!queryIsLoading && !isError && (
            <HootEmployeeEdit
              hootEmployeeUserId={hootEmployeeUserId}
              setHootEmployeeUserId={setHootEmployeeUserId}
              selectedHootEmployee={selectedHootEmployee}
              queryIsLoading={queryIsLoading}
              setFormIsDirty={setFormIsDirty}
            />
          )}
        </Stack>
      </Box>
    </Drawer>
  );
};

export default HootEmployeeDrawer;
