import { BoxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { IFilter, IFilterSelection } from '@hoot/components/form/searchFilters/FilterSelects';
import SearchFilters from '@hoot/components/form/searchFilters/SearchFilters';
import { UserSelectableProject } from '@hoot/hooks/api/teacher-invoice/useGetUserSelectableProjects';
import { useStableSearchParams } from '@hoot/hooks/useStableSearchParams';
import { InvoiceLineItemStatus } from '@hoot/pages/teacher-invoices/enums/invoice-line-item-status.enum';
import { TeacherInvoiceBillableTasksPageQueryParams } from './interfaces/TeacherInvoiceBillableTasksPageQueryFilterParams';

const getInitialFilterSelections = (searchParams: URLSearchParams, projects: UserSelectableProject[]) => {
  const projectOptions = projects?.map((project) => ({
    label: project.projectCode,
    queryValue: project.id,
  }));

  const teacherInvoiceBillableTasksFilters: IFilter[] = [
    {
      label: 'Project',
      queryName: TeacherInvoiceBillableTasksPageQueryParams.Project,
      options: projectOptions,
    },
    {
      label: 'Status',
      queryName: TeacherInvoiceBillableTasksPageQueryParams.Status,
      options: [
        { label: 'Pending', queryValue: InvoiceLineItemStatus.Pending },
        { label: 'Approved', queryValue: InvoiceLineItemStatus.Approved },
        { label: 'Rejected', queryValue: InvoiceLineItemStatus.Rejected },
      ],
    },
  ];

  return teacherInvoiceBillableTasksFilters.map((filter: IFilter) => ({
    ...filter,
    values: filter.options.map((option) => ({
      ...option,
      isSelected: searchParams.has(filter.queryName!) && searchParams.getAll(filter.queryName!).includes(option.queryValue!),
    })),
  }));
};

interface TeacherInvoiceBillableTasksSearchFiltersProps {
  sx?: BoxProps['sx'];
  projects: UserSelectableProject[];
}

const TeacherInvoiceBillableTasksFilters = (props: TeacherInvoiceBillableTasksSearchFiltersProps) => {
  const { sx, projects } = props;

  const [searchParams, setSearchParams] = useStableSearchParams();

  const [filterSelections, setFilterSelections] = useState<IFilterSelection[]>(getInitialFilterSelections(searchParams, projects));

  // When filters have been updated, then update the query args in the URL.
  useEffect(() => {
    // Transform the selected filters into an object that react-router-dom can recognize as query strings.
    const selectedFilters = filterSelections
      .map((filterSelection) => {
        const selectedOptionValues = filterSelection.values
          .filter((filterOption) => filterOption.isSelected)
          .map((filterOption) => filterOption.queryValue);
        return {
          filterName: filterSelection.queryName!,
          selectedOptionValues,
        };
      })
      .filter((filter) => filter.selectedOptionValues.length > 0)
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr.filterName]: curr.selectedOptionValues,
        };
      }, {});

    setSearchParams(
      (prevSearchParamsState) => {
        const newSearchParams = createSearchParams(selectedFilters);

        const retainPrevSearchArgIfExists = (key: string) => {
          if (prevSearchParamsState.has(key)) {
            newSearchParams.set(key, prevSearchParamsState.get(key)!);
          }
        };
        // Retain all other "non-filter" query properties.
        retainPrevSearchArgIfExists(TeacherInvoiceBillableTasksPageQueryParams.Search);
        retainPrevSearchArgIfExists(TeacherInvoiceBillableTasksPageQueryParams.PageSize);
        retainPrevSearchArgIfExists(TeacherInvoiceBillableTasksPageQueryParams.OrderBy);
        retainPrevSearchArgIfExists(TeacherInvoiceBillableTasksPageQueryParams.SortDirection);

        // Reset to the initial page when filter criteria changes, else result set may become out of sync.
        newSearchParams.set(TeacherInvoiceBillableTasksPageQueryParams.Page, '1');

        return newSearchParams;
      },
      { replace: true },
    );
  }, [filterSelections, setSearchParams]);

  return <SearchFilters sx={sx} filterSelections={filterSelections} setFilterSelections={setFilterSelections} />;
};

export default TeacherInvoiceBillableTasksFilters;
