import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { SortableQuery } from '@hoot/interfaces/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/interfaces/pagination';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';
import { LocationSummaryQuery } from './useGetLocationSummaryDashboard';

export enum GetLocationSummaryEnrolmentsQuerySortKeys {
  EnrolmentId = 'enrolmentId',
  ProductId = 'productId',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Type = 'type',
  Status = 'status',
}

export interface LocationSummaryEnrolmentsQuery
  extends LocationSummaryQuery,
    PaginatableQuery,
    SortableQuery<GetLocationSummaryEnrolmentsQuerySortKeys> {}

export interface GetLocationSummaryEnrolmentsResponse {
  id: string;
  friendlyId: string;
  productId: string;
  productFriendlyId: string;
  startDate: number;
  endDate: number;
  schoolName: string;
  schoolId: string;
  type: EnrolmentType;
  totalDosage: string;
  weeklyDosage: string;
  status: EnrolmentStatus;
}

export default function useGetLocationSummaryEnrolments(
  locationId: string,
  searchParams: LocationSummaryEnrolmentsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<GetLocationSummaryEnrolmentsResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetLocationSummaryEnrolments, locationId, searchParams],
    async () => {
      return (
        await axios.get(`${config.apiUrl}/admin/hfs/location/${locationId}/summary/enrolments`, {
          params: searchParams,
        })
      ).data;
    },
    options,
  );
}
