import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import FilterDropDown from '../../FilterDropDown';

function YesNoFilter(props: {
  onChange?: (val: boolean) => void;
  title: string;
  value?: boolean;
  dismissPopoverOnSelection?: boolean;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { title, onChange, value, popOverButtonSx } = props;

  const handleChange = (val: string[], selectedVal?: string) => {
    if (onChange && selectedVal) {
      if (selectedVal === 'Yes') {
        onChange(true);
      } else {
        onChange(false);
      }
    }
  };

  return (
    <FilterDropDown<string>
      title={title}
      dismissPopoverOnSelection
      value={value !== undefined ? (value ? ['Yes'] : ['No']) : undefined}
      options={[
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ]}
      onChange={handleChange}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveYesNoFilter(props: { label: string; value?: string; onChange: (val: string | undefined) => void }) {
  const { label, onChange, value } = props;

  const handleDelete = () => {
    onChange(undefined);
  };
  return (
    <>
      {value ? (
        <Chip key={`chip-date-${label}`} label={`${label}: ${value}`} variant="outlined" onDelete={handleDelete} deleteIcon={<Close />} />
      ) : null}
    </>
  );
}

export default YesNoFilter;
