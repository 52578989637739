import axios from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseMutationOptions } from '@hoot/interfaces/axios-options';
import { HootEmployee } from '@hoot/interfaces/hoot-employee';

interface CreateHootEmployeeRequest {
  email: string;
  firstName: string;
  lastName: string;
  isSuperUser: boolean;
  canUpdateBooks: boolean;
  canViewLessonVideos: boolean;
  canImpersonateUsers: boolean;
  canAccessReportingPage: boolean;
  canSoftDeleteUsers: boolean;
  canAccessPartnerManagement: boolean;
  canAdvancedEditLessonTeacher: boolean;
  canAdvancedEditLessonDetails: boolean;
  canAccessInvoicing: boolean;
  canManageLessonReviews: boolean;
  canImportTeacherReliabilityScore: boolean;
  canManageAdvancedStudentData: boolean;
  canViewDistrictDetails: boolean;
  canManageStudentDetails: boolean;
  canManageTeacherDetails: boolean;
  canManageDistrictReps: boolean;
  canAccessBillingInformation: boolean;
  canDeleteAssessments: boolean;
  canManageInstructionalLibraries: boolean;
}

export default function useCreateHootEmployee(options?: UseMutationOptions<CreateHootEmployeeRequest, HootEmployee>) {
  return useMutation(
    [QueryKey.CreateEmployee],
    async (data: CreateHootEmployeeRequest) => {
      return (await axios.post<HootEmployee>(`${config.apiUrl}/admin/hoot-employee`, data)).data;
    },
    options,
  );
}
