import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';
import React from 'react';

interface IConfirmDeleteDialogProps {
  open: boolean;
  onCancel: () => void;
  onContinue: (value: number) => void;
  title: string;
  value?: number;
}

const EditAllowedModal = (props: IConfirmDeleteDialogProps) => {
  const { open, title, onCancel, onContinue, value } = props;
  const [allowedValue, setAllowedValue] = React.useState<number | undefined>(value);

  return (
    <Dialog open={open} onClose={onCancel} scroll="paper">
      <Stack sx={{ padding: '24px', position: 'relative', minWidth: 500 }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">{title}</Typography>
            <IconButton onClick={onCancel} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <TextField
            type="number"
            fullWidth
            label={`Value`}
            variant="outlined"
            value={allowedValue}
            onChange={(e) => setAllowedValue(Number(e.target.value))}
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button fullWidth size="large" color="inherit" variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={() => {
                if (allowedValue) {
                  onContinue(allowedValue);
                }
              }}
            >
              Apply
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default EditAllowedModal;
