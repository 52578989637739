import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import SelectTeacher, { SelectedTeacher } from '../../../../components/modals/SelectTeacher';
import useGetProduct from '../../../../hooks/api/hfs/district-schools/products/useGetProduct';
import useGetLessonConflicts, { ConflictType } from '../../../../hooks/api/lessons/useGetConflicts';
import { LessonDetailResponse } from '../../../../hooks/api/lessons/useGetLesson';
import { TentativeLessonTeacherAccountFilter } from '../../../../hooks/api/user/teacher/useSearchTeacherAccounts';
import { ConflictMessage } from '../../create-v2/common/ConflictMessage';
import { TentativeLessonStatus } from '../../create-v2/context/StudentLessonDetailsProvider';
import { RescheduleWizardState, useRescheduleWizard } from '../RescheduleWizardContextProvider';
import { RescheduleWizardDialogBody } from '../RescheduleWizardModal';

interface Form {
  teacher: SelectedTeacher | null;
  tentativeLessons: TentativeLessonForm[];
}

interface TentativeLessonForm {
  lessonAt: DateTime;
  status: TentativeLessonStatus;
  conflict?: {
    conflictType: ConflictType;
    message: string;
  };
}

export function RescheduleWizardTeacherDetailsStep(props: { lessonDetails: LessonDetailResponse }) {
  const { rescheduledLesson, updateWizardState, updateRescheduledLesson } = useRescheduleWizard();

  const { control, handleSubmit, watch, setValue } = useForm<Form>({
    defaultValues: {
      teacher: rescheduledLesson?.teacher,
      tentativeLessons: rescheduledLesson.lessonAt
        ? [
            {
              lessonAt: DateTime.fromMillis(rescheduledLesson.lessonAt),
              status: TentativeLessonStatus.NoTeacher,
            },
          ]
        : [],
    },
  });

  const { teacher, tentativeLessons } = watch();

  const getProductRequest = useGetProduct(props.lessonDetails.enrolment?.productId ?? '', {
    enabled: !!props.lessonDetails.enrolment,
  });

  useGetLessonConflicts(
    {
      durationInMinutes: props.lessonDetails.duration ? props.lessonDetails.duration : 20,
      lessonStartTimes: tentativeLessons.map((t) => t.lessonAt.toMillis()),
      teacherAccountId: teacher?.id ?? null,
      studentProfileId: props.lessonDetails.studentProfileId,
    },
    {
      onSuccess: (response) => {
        const updatedTentativeLessons = tentativeLessons.map<TentativeLessonForm>((l) => {
          const conflict = response.find((r) => r.lessonStart === l.lessonAt.toMillis());

          const isBlockingConflict = conflict?.conflictType === 'CONFLICT';
          const isLessThanTwoHoursFromNow = l.lessonAt <= DateTime.now().plus({ hours: 2 });

          // NOTE: The status carried over from the previous step is either going to be:
          // - NotSet (lesson was NOT selected).
          // - Exclude (not selected b/c of a student conflict).
          // - NoTeacher (lesson _was_ selected).

          // If this lesson was selected in the previous step AND there are no blocking conflicts,
          // then auto-assign this lesson to the teacher.
          const newStatus =
            l.status === TentativeLessonStatus.NoTeacher && !isBlockingConflict
              ? TentativeLessonStatus.TeacherAssigned
              : // Else, if this lesson is scheduled within the cutoff period for creating lessons without a teacher (two
                // hours), then auto-set this lesson to NotSet.
                isLessThanTwoHoursFromNow
                ? TentativeLessonStatus.NotSet
                : // Else, carry over the previous selection status.
                  l.status;

          return {
            ...l,
            status: newStatus,
            conflict,
          };
        });

        setValue('tentativeLessons', updatedTentativeLessons);
      },
    },
  );

  const handlePrevButtonClick = () => {
    updateWizardState(RescheduleWizardState.LessonDetails);
  };

  const onSubmit = (form: Form) => {
    updateRescheduledLesson({
      teacher: form.teacher,
    });
    updateWizardState(RescheduleWizardState.Confirm);
  };

  const twoHoursFromNow = DateTime.now().plus({ hours: 2 });
  const lessonInNextTwoHours = tentativeLessons.some((l) => l.lessonAt <= twoHoursFromNow);

  const disableNextButton =
    (lessonInNextTwoHours && !teacher) ||
    tentativeLessons.some(
      (tl) =>
        tl.status === TentativeLessonStatus.Exclude || tl.status === TentativeLessonStatus.NotSet || tl.status === TentativeLessonStatus.NoTeacher,
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RescheduleWizardDialogBody onPreviousStep={handlePrevButtonClick} nextStepDisabled={disableNextButton}>
        <Typography sx={{ lineHeight: '24px', fontSize: '16px', fontWeight: 500 }}>Teacher</Typography>
        <Typography variant="titleMedium">Select Teacher</Typography>
        <Controller
          name="teacher"
          control={control}
          render={({ field }) => (
            <SelectTeacher
              value={field.value}
              onChange={field.onChange}
              queryDefaults={{
                countryRequirements: getProductRequest.data?.countryRequirements,
                hootQualificationRequirements: getProductRequest.data?.qualificationRequirements,
                backgroundCheckRequirements: getProductRequest.data?.backgroundCheckRequirements,
                lessonDetails: {
                  subject: props.lessonDetails.subject,
                  isCustomerBillable: rescheduledLesson.isCustomerBillable ?? props.lessonDetails.isCustomerBillable ?? false,
                  language: props.lessonDetails.language,
                  account: props.lessonDetails.account,
                  enrolmentId: props.lessonDetails.enrolment?.id,
                  theme: props.lessonDetails.theme,
                  isLessonSet: !!props.lessonDetails.lessonSet,
                  lessonType: props.lessonDetails.lessonType,
                },
              }}
              tentativePeriods={tentativeLessons.map<TentativeLessonTeacherAccountFilter>((tl) => ({
                startsAt: tl.lessonAt.toMillis(),
                endsAt: tl.lessonAt.plus({ minutes: props.lessonDetails.duration }).toMillis(),
              }))}
              periodIsLesson
              showHasConflictFilter
              error={lessonInNextTwoHours && !teacher}
              helperText={
                lessonInNextTwoHours && !teacher ? "A lesson you're trying to create starts within 2 hours. Please select a teacher" : undefined
              }
              lessonType={props.lessonDetails.lessonType}
            />
          )}
        />

        {teacher ? (
          <>
            <Typography sx={{ marginTop: '24px' }} variant="titleMedium">
              Select from these available dates for {teacher.name}
            </Typography>
            <Controller
              control={control}
              name="tentativeLessons"
              render={({ field }) => <TentativeLessons isLoading={false} onChange={field.onChange} value={field.value} />}
            />
          </>
        ) : null}
      </RescheduleWizardDialogBody>
    </form>
  );
}

function TentativeLessons(props: { isLoading?: boolean; value: TentativeLessonForm[]; onChange: (value: TentativeLessonForm[]) => void }) {
  if (props.isLoading) {
    return <CircularProgress />;
  }

  const handleClick = (lesson: TentativeLessonForm, status: TentativeLessonStatus) => {
    const updatedState = props.value.map<TentativeLessonForm>((v) =>
      lesson.lessonAt === v.lessonAt
        ? {
            ...v,
            status: status,
          }
        : v,
    );
    props.onChange(updatedState);
  };

  return (
    <Stack>
      <Stack sx={{ marginTop: '8px' }} direction="row">
        <Typography sx={{ width: '49px' }} variant="bodySmall">
          Selected Teacher
        </Typography>
        <Typography sx={{ marginLeft: '40px', width: '43px' }} variant="bodySmall">
          Without Teacher
        </Typography>
      </Stack>
      <FormGroup sx={{ marginTop: '8px' }}>
        {props.value.map((l) => (
          <TentativeLessonField key={l.lessonAt.toMillis()} value={l} onClick={handleClick} />
        ))}
      </FormGroup>
    </Stack>
  );
}

function TentativeLessonField(props: { value: TentativeLessonForm; onClick: (value: TentativeLessonForm, status: TentativeLessonStatus) => void }) {
  // AKA the lesson was not selected due to a conflict in the previous step.
  const wasLessonExcluded = props.value.status === TentativeLessonStatus.Exclude;

  const isBlockingConflict = props.value.conflict?.conflictType === 'CONFLICT';
  const isLessThanTwoHoursFromNow = props.value.lessonAt <= DateTime.now().plus({ hours: 2 });

  const canAssignTeacher = !isBlockingConflict;

  const canCreateLessonWithoutTeacher = !wasLessonExcluded && !isLessThanTwoHoursFromNow;

  if (props.value.status === TentativeLessonStatus.NotSet && !isBlockingConflict && !isLessThanTwoHoursFromNow) {
    return null;
  }

  const handleClick = (valueClicked: TentativeLessonStatus) => () => {
    if (props.value.status === valueClicked) {
      props.onClick(props.value, TentativeLessonStatus.NotSet);
    } else {
      props.onClick(props.value, valueClicked);
    }
  };

  return (
    <Stack direction="row">
      <FormControlLabel
        disabled={!canAssignTeacher && !canCreateLessonWithoutTeacher}
        control={
          <Stack direction="row">
            <Checkbox
              sx={{ marginLeft: '8px' }}
              disabled={!canAssignTeacher}
              onClick={handleClick(TentativeLessonStatus.TeacherAssigned)}
              checked={props.value.status === TentativeLessonStatus.TeacherAssigned}
            />
            <Checkbox
              sx={{ marginLeft: '52px' }}
              disabled // will always be disabled, should never be able to schedule with no teacher
              onClick={handleClick(TentativeLessonStatus.NoTeacher)}
              checked={props.value.status === TentativeLessonStatus.NoTeacher}
            />
          </Stack>
        }
        label={
          <Typography variant="bodyLarge" sx={{ marginLeft: '42px' }}>
            {props.value.lessonAt.toFormat('ccc, LLL d, yyyy @ h:mma')}
          </Typography>
        }
      />
      {props.value.conflict ? <ConflictMessage conflictType={props.value.conflict.conflictType} message={props.value.conflict.message} /> : null}
    </Stack>
  );
}
