import { Box, Breadcrumbs, CircularProgress, Grid, Link, Paper, Typography, styled } from '@mui/material';
import useGetMetabaseDashboardEmbed, { MetabaseDashboardEmbedResponse } from '@hoot/hooks/api/reporting/useGetMetabaseDashboardEmbed';
import { usePageTitle } from '../../hooks/usePageTitle';

interface Props {
  metabaseReportId: number;
}

const Title = styled('div')({
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: 800,
  fontSize: '30px',
  lineHeight: '48px',
  padding: '0px 48px',
});

const MetabaseReport = (metabaseReportEmbedData: MetabaseDashboardEmbedResponse) => {
  const url = new URL(metabaseReportEmbedData.iframeUrl);
  return <iframe title={metabaseReportEmbedData.name} src={`${url}`} width="100%" style={{ height: 'calc(100vh - 320px)', border: '0' }} />;
};

const Report = (props: Props) => {
  const { metabaseReportId } = props;
  const response = useGetMetabaseDashboardEmbed(metabaseReportId);
  usePageTitle(`${response.data?.name} | Admin Portal`);
  return (
    <Paper>
      <Box
        sx={{
          padding: '24px 0px',
        }}
      >
        <Title sx={{ textTransform: 'uppercase' }}>{response.data?.name}</Title>
        <Breadcrumbs
          sx={{
            marginTop: '8px',
            padding: '0px 48px',
          }}
        >
          <Link underline="hover" color="inherit" href="/reporting">
            Reporting
          </Link>
          <Typography color="text.primary">{response.data?.name}</Typography>
        </Breadcrumbs>
        <Grid container sx={{ marginTop: '72px', justifyContent: 'center' }}>
          {response.isLoading || !response.data ? (
            <CircularProgress sx={{ justifyContent: 'center' }} />
          ) : (
            <MetabaseReport iframeUrl={response.data.iframeUrl} name={response.data.name} />
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default Report;
