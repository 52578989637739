import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TentativeLessonTeacherAccountFilter } from '@hoot/hooks/api/user/teacher/useSearchTeacherAccounts';
import SelectTeacher from '../../../../components/modals/SelectTeacher';
import useGetProduct from '../../../../hooks/api/hfs/district-schools/products/useGetProduct';
import useGetLessonConflicts, { ConflictType } from '../../../../hooks/api/lessons/useGetConflicts';
import CreateLessonFooter from '../CreateLessonFooter';
import { ConflictMessage } from '../common/ConflictMessage';
import { CreateLessonWizardStepEnum, useCreateLessonContext } from '../context/CreateLessonContextProvider';
import { useStudentAndLessonContext } from '../context/StudentAndLessonContextProvider';
import { TentativeLesson, TentativeLessonStatus, useStudentLessonDetailsContext } from '../context/StudentLessonDetailsProvider';

interface TentativeLessonForm {
  lessonAt: DateTime;
  status: TentativeLessonStatus;
  conflict?: {
    conflictType: ConflictType;
    message: string;
  };
}

interface TeacherForm {
  id: string;
  name: string;
  number: string;
}

interface Form {
  teacher: TeacherForm | null;
  tentativeLessons: TentativeLessonForm[];
}

const TeacherConflictCheckStep = () => {
  const { goToNextStep, goToPrevStep, goToStep } = useCreateLessonContext();
  const { lessonDetails } = useStudentLessonDetailsContext();
  const { studentAndLessonData } = useStudentAndLessonContext();

  const productId = studentAndLessonData?.selectedEnrolment?.productId;
  const getProduct = useGetProduct(productId!, {
    enabled: !!productId,
  });

  const { control, handleSubmit, reset, watch } = useForm<Form>({
    defaultValues: {
      teacher: lessonDetails.teacher ?? null,
      tentativeLessons: [],
    },
  });

  const { teacher, tentativeLessons } = watch();

  const atLeastOneLessonSelected = useMemo(() => {
    return tentativeLessons.some((x) => x.status !== TentativeLessonStatus.NotSet && x.status !== TentativeLessonStatus.Exclude);
  }, [tentativeLessons]);

  const getConflicts = useGetLessonConflicts(
    {
      durationInMinutes: lessonDetails.duration ? lessonDetails.duration : 20,
      lessonStartTimes:
        lessonDetails.tentativeLessons?.filter((t) => t.status !== TentativeLessonStatus.NotSet).map((l) => l.lessonAt.toMillis()) ?? [],
      teacherAccountId: teacher?.id ?? null,
      studentProfileId: studentAndLessonData?.studentProfile.id,
    },
    {
      enabled: (lessonDetails.tentativeLessons?.length ?? 0) > 0,
      onSuccess: (response) => {
        if (lessonDetails.tentativeLessons) {
          const tentativeLessons = lessonDetails.tentativeLessons?.map<TentativeLessonForm>((l) => {
            const conflict = response.find((r) => r.lessonStart === l.lessonAt.toMillis());

            const isBlockingConflict = conflict?.conflictType === 'CONFLICT';
            const isLessThanTwoHoursFromNow = l.lessonAt <= DateTime.now().plus({ hours: 2 });

            // NOTE: The status carried over from the previous step is either going to be:
            // - NotSet (lesson was NOT selected).
            // - Exclude (not selected b/c of a student conflict).
            // - NoTeacher (lesson _was_ selected).

            // If this lesson was selected in the previous step AND there are no blocking conflicts,
            // then auto-assign this lesson to the teacher.
            const newStatus =
              l.status === TentativeLessonStatus.NoTeacher && !isBlockingConflict
                ? TentativeLessonStatus.TeacherAssigned
                : // Else, if this lesson is scheduled within the cutoff period for creating lessons without a teacher (two
                  // hours), then auto-set this lesson to NotSet.
                  isLessThanTwoHoursFromNow
                  ? TentativeLessonStatus.NotSet
                  : // Else, carry over the previous selection status.
                    l.status;

            return {
              ...l,
              status: newStatus,
              conflict,
            };
          });

          reset({
            teacher: teacher,
            tentativeLessons: tentativeLessons,
          });
        }
      },
    },
  );

  const handlePreviousClick = () => {
    goToPrevStep();
  };

  const onSubmit = (data: Form) => {
    if (!data.teacher) {
      lessonDetails.teacher = undefined;
    } else {
      lessonDetails.teacher = {
        id: data.teacher.id,
        name: data.teacher.name,
        number: data.teacher.number,
      };
      lessonDetails.tentativeLessons = data.tentativeLessons.map<TentativeLesson>((l) => ({
        lessonAt: l.lessonAt,
        status: l.status,
      }));
    }

    goToNextStep();
  };

  const isLoading = getConflicts.isLoading;

  const teacherName = teacher ? teacher.name : 'N/A';
  const twoHoursFromNow = DateTime.now().plus({ hours: 2 });
  const lessonInNextTwoHours = tentativeLessons.some((l) => l.lessonAt <= twoHoursFromNow);

  if (!studentAndLessonData) {
    goToStep(CreateLessonWizardStepEnum.SelectStudent);
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Typography variant="titleMedium">Select Teacher</Typography>
        <Controller
          name="teacher"
          control={control}
          render={({ field }) => (
            <SelectTeacher
              value={field.value}
              onChange={field.onChange}
              queryDefaults={{
                countryRequirements: getProduct.data?.countryRequirements,
                hootQualificationRequirements: getProduct.data?.qualificationRequirements,
                backgroundCheckRequirements: getProduct.data?.backgroundCheckRequirements,
                lessonDetails: {
                  subject: studentAndLessonData.subject,
                  isCustomerBillable: studentAndLessonData.isCustomerBilledLesson,
                  language: studentAndLessonData.language,
                  account: studentAndLessonData.accountId,
                  enrolmentId: studentAndLessonData.selectedEnrolment?.id,
                  theme: studentAndLessonData.theme,
                  isLessonSet: !!studentAndLessonData.lessonSetType,
                  lessonType: studentAndLessonData.lessonType,
                },
              }}
              tentativePeriods={tentativeLessons
                .filter((t) => t.status === TentativeLessonStatus.TeacherAssigned)
                .map<TentativeLessonTeacherAccountFilter>((t) => ({
                  startsAt: t.lessonAt.toMillis(),
                  endsAt: t.lessonAt.plus({ minutes: lessonDetails.duration }).toMillis(),
                }))}
              periodIsLesson
              showHasConflictFilter
              error={lessonInNextTwoHours && !teacher}
              helperText={
                lessonInNextTwoHours && !teacher ? "A lesson you're trying to create starts within 2 hours. Please select a teacher" : undefined
              }
              lessonType={studentAndLessonData.lessonType}
            />
          )}
        />
        {teacher ? (
          <>
            <Typography sx={{ marginTop: '24px' }} variant="titleMedium">
              Select from these available dates for {teacherName}
            </Typography>
            <Controller
              control={control}
              name="tentativeLessons"
              render={({ field }) => <TentativeLessons isLoading={isLoading} onChange={field.onChange} value={field.value} />}
            />
          </>
        ) : null}
      </Stack>
      <CreateLessonFooter>
        <Button onClick={handlePreviousClick} variant="contained" size="large">
          Previous Step
        </Button>
        <Stack direction="row" gap={3}>
          <Button type="submit" variant="contained" size="large" disabled={(!teacher && lessonInNextTwoHours) || !atLeastOneLessonSelected}>
            Next Step
          </Button>
        </Stack>
      </CreateLessonFooter>
    </form>
  );
};

function TentativeLessons(props: { isLoading?: boolean; value: TentativeLessonForm[]; onChange: (value: TentativeLessonForm[]) => void }) {
  if (props.isLoading) {
    return <CircularProgress />;
  }

  const handleClick = (lesson: TentativeLessonForm, status: TentativeLessonStatus) => {
    const updatedState = props.value.map<TentativeLessonForm>((v) =>
      lesson.lessonAt === v.lessonAt
        ? {
            ...v,
            status: status,
          }
        : v,
    );
    props.onChange(updatedState);
  };

  return (
    <Stack>
      <Stack sx={{ marginTop: '8px' }} direction="row">
        <Typography sx={{ width: '49px' }} variant="bodySmall">
          Selected Teacher
        </Typography>
        <Typography sx={{ marginLeft: '40px', width: '43px' }} variant="bodySmall">
          Without Teacher
        </Typography>
      </Stack>
      <FormGroup sx={{ marginTop: '8px' }}>
        {props.value.map((l) => (
          <TentativeLessonField key={l.lessonAt.toMillis()} value={l} onClick={handleClick} />
        ))}
      </FormGroup>
    </Stack>
  );
}

function TentativeLessonField(props: { value: TentativeLessonForm; onClick: (value: TentativeLessonForm, status: TentativeLessonStatus) => void }) {
  // AKA the lesson was not selected due to a conflict in the previous step.
  const wasLessonExcluded = props.value.status === TentativeLessonStatus.Exclude;

  const isBlockingConflict = props.value.conflict?.conflictType === 'CONFLICT';
  const isLessThanTwoHoursFromNow = props.value.lessonAt <= DateTime.now().plus({ hours: 2 });

  // We can assign a teacher to this lesson if there are no BLOCKING conflicts (teacher or student).
  const canAssignTeacher = !isBlockingConflict;

  // We can allow this lesson to be created WITHOUT a teacher if we did not exclude this lesson from the previous step
  // (b/c of a student conflict) and if this lesson does not have a starting time within the cut-off period (two hours).
  const canCreateLessonWithoutTeacher = !wasLessonExcluded && !isLessThanTwoHoursFromNow;

  // In case the lesson was deselected in the student conflict check step (NOT_SET)
  // we need to check for blocking conflicts and the 2hr cut-off here because the status could have
  // been updated to NOT_SET _after_ selecting a teacher and doing the conflict checking locally
  if (props.value.status === TentativeLessonStatus.NotSet && !isBlockingConflict && !isLessThanTwoHoursFromNow) {
    return null;
  }

  const handleClick = (valueClicked: TentativeLessonStatus) => () => {
    if (props.value.status === valueClicked) {
      props.onClick(props.value, TentativeLessonStatus.NotSet);
    } else {
      props.onClick(props.value, valueClicked);
    }
  };

  return (
    <Stack direction="row">
      <FormControlLabel
        disabled={!canAssignTeacher && !canCreateLessonWithoutTeacher}
        control={
          <Stack direction="row">
            {/* Select with Teacher */}
            <Checkbox
              sx={{ marginLeft: '8px' }}
              disabled={!canAssignTeacher}
              onClick={handleClick(TentativeLessonStatus.TeacherAssigned)}
              checked={props.value.status === TentativeLessonStatus.TeacherAssigned}
            />
            {/* Select _without_ Teacher */}
            <Checkbox
              sx={{ marginLeft: '52px' }}
              disabled={!canCreateLessonWithoutTeacher}
              onClick={handleClick(TentativeLessonStatus.NoTeacher)}
              checked={props.value.status === TentativeLessonStatus.NoTeacher}
            />
          </Stack>
        }
        label={
          <Typography variant="bodyLarge" sx={{ marginLeft: '42px' }}>
            {props.value.lessonAt.toFormat('ccc, LLL d, yyyy @ h:mma')}
          </Typography>
        }
      />
      {props.value.conflict ? <ConflictMessage conflictType={props.value.conflict.conflictType} message={props.value.conflict.message} /> : null}
    </Stack>
  );
}

export default TeacherConflictCheckStep;
