import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { ManualQualityAssuranceTaskOption } from '@hoot/hooks/api/tasks/enums';
import useCreatequalityAssuranceTask, { CreateQualityAssuranceRequest } from '@hoot/hooks/api/tasks/useCreateQualityAssuranceTask';
import { useRouteMatch } from '@hoot/hooks/useRouteMatch';
import { FormOption } from '@hoot/interfaces/form';
import { routes } from '@hoot/routes/routes';
import { Dropdown } from '../form/Dropdown';

export interface CreateTaskForm {
  option: ManualQualityAssuranceTaskOption | null;
  note: string | '';
}

interface Props {
  open: boolean;
  handleClose: () => void;
}

const CreateTaskModal = (props: Props) => {
  const { open, handleClose } = props;
  const { lessonId, studentProfileId } = useParams() as { lessonId: string | undefined; studentProfileId: string | undefined };

  const isOnLessonPage = useRouteMatch([`${routes.lessons.path}`]);
  const { success, error } = useAlert();
  const createQualityAssuranceTask = useCreatequalityAssuranceTask();

  const { control, handleSubmit, reset, formState } = useForm<CreateTaskForm>({
    mode: 'onSubmit',
    defaultValues: {
      option: isOnLessonPage ? ManualQualityAssuranceTaskOption.AllocateCredit : null,
      note: '',
    },
  });

  const onSubmit: SubmitHandler<CreateTaskForm> = (data) => {
    if (!data.option) {
      error(`Cannot submit an empty Task Option`);
      return;
    }

    if (!lessonId && !studentProfileId) {
      //Note: we always expect one or the other.... we will use the lessonId to get the student Id in the API
      error(`There was an error submitting your request.`);
      return;
    }

    const request: CreateQualityAssuranceRequest = {
      taskOption: data.option,
      note: data.note,
      studentProfileId: studentProfileId,
      lessonId: lessonId,
    };
    createQualityAssuranceTask.mutate(request, {
      onSuccess: () => {
        success(`Task Successfully Created`);
        handleOnClose();
      },
      onError: (errorData) => {
        console.error(errorData);
        error(`There was an error, the task has not been created.`);
      },
    });
  };

  const taskOptions: FormOption<ManualQualityAssuranceTaskOption>[] = [
    { value: ManualQualityAssuranceTaskOption.NewStudent, label: 'New Student' },
    { value: ManualQualityAssuranceTaskOption.StudentChange, label: 'Student Change' },
    { value: ManualQualityAssuranceTaskOption.StudentInactive, label: 'Student Inactive' },
    { value: ManualQualityAssuranceTaskOption.StudentPause, label: 'Student Pause' },
  ];

  if (isOnLessonPage) {
    taskOptions.push({ value: ManualQualityAssuranceTaskOption.AllocateCredit, label: 'Allocate Credit' });
  }

  const handleOnClose = () => {
    reset();
    handleClose();
  };

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ padding: '24px', position: 'relative', width: '456px', height: '440px' }} gap={3}>
          <DialogTitle sx={{ padding: 0 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="headlineSmall">Create Task</Typography>
              <IconButton onClick={handleOnClose} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
                <Close />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <Stack mt={1} gap={2}>
              <Stack>
                <Dropdown
                  name="option"
                  label="Option *"
                  required
                  options={taskOptions}
                  control={control}
                  rules={{ required: 'Option is required!' }}
                  variant="outlined"
                  disabled={!!isOnLessonPage}
                />
                <FormHelperText error={!!formState.errors?.option?.message} sx={{ ml: 2 }}>
                  {formState.errors?.option?.message}
                </FormHelperText>
              </Stack>
              <Controller
                name="note"
                control={control}
                rules={{
                  validate: (val: string) => {
                    if (!val) {
                      return 'Cannot submit an empty note!';
                    }

                    if (val.trim().length === 0) {
                      return 'Cannot submit an empty note!';
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    helperText={fieldState.error?.message}
                    error={!!fieldState.error}
                    multiline
                    rows={3}
                    fullWidth
                    label="Comments *"
                    variant="outlined"
                  />
                )}
              />
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ padding: 0 }}>
            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <Button fullWidth size="large" variant="outlined" onClick={handleOnClose}>
                Cancel
              </Button>
              <Button fullWidth size="large" variant="contained" type="submit">
                Create
              </Button>
            </Stack>
          </DialogActions>
        </Stack>
      </form>
    </Dialog>
  );
};

export default CreateTaskModal;
