import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { Box, Card, LinearProgress, Paper, TableContainer } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSearchDistrictSchoolDetails, {
  DistrictSchoolDetailSearchResult,
  DistrictSchoolQuerySortKeyEnum,
  SearchDistrictSchoolDetailsQuery,
} from '@hoot/hooks/api/hfs/useSearchDistrictSchoolDetails';
import { SortOrder } from '@hoot/interfaces/order-by';
import DistrictAndSchoolsActionsButton from '@hoot/pages/district-schools/DistrictsAndSchoolsActionsButton';
import QueryableTablePagination from '../../components/QueryableTablePagination';
import HeaderCard from '../../components/ui/HeaderCard';
import { useAlert } from '../../contexts/AlertContext';
import { usePageTitle } from '../../hooks/usePageTitle';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '../../interfaces/pagination';
import { removeUndefinedProperties } from '../../utils/remove-undefined-properties';
import DistrictsAndSchoolsFilters from './DistrictsAndSchoolsFilters';
import DistrictsAndSchoolsSearchInput from './DistrictsAndSchoolsSearchInput';
import DistrictsAndSchoolsTable from './DistrictsAndSchoolsTable';
import { DistrictSchoolPageQueryParams } from './models';

const DistrictsAndSchoolsPage = () => {
  const [searchParams] = useSearchParams();

  usePageTitle('Districts & Schools');

  const { error } = useAlert();

  const [districtSchoolQuery, setDistrictSchoolQuery] = useState<SearchDistrictSchoolDetailsQuery>({
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
  });

  const [districtsAndSchoolsPaginatedResponse, setDistrictsAndSchoolsPaginatedResponse] = useState<
    GenericPaginatedResponse<DistrictSchoolDetailSearchResult>
  >({
    data: [],
    count: 0,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { refetch, isFetching } = useSearchDistrictSchoolDetails(districtSchoolQuery, {
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setDistrictsAndSchoolsPaginatedResponse({ ...data });
    },
    onError: (err) => {
      console.error(err);
      error(`An error occurred while loading districts and schools.`);
    },
  });

  // When the URL query args have been updated, then transform the query args into a request body and fetch the results.
  useEffect(() => {
    // Updating the query will trigger a re-fetch.
    setDistrictSchoolQuery(() => {
      const typeFilters = searchParams.getAll(DistrictSchoolPageQueryParams.Type) as LocationType[];
      const productStatusFilters = searchParams.getAll(DistrictSchoolPageQueryParams.ProductStatus) as ProductStatus[];
      const enrolmentStatusFilters = searchParams.getAll(DistrictSchoolPageQueryParams.EnrolmentStatus) as EnrolmentStatus[];
      const countryFilters = searchParams.getAll(DistrictSchoolPageQueryParams.Country) as HootSupportedCountry[];

      const page = parseInt(searchParams.get(DistrictSchoolPageQueryParams.Page) ?? '1') ?? 1;
      const pageSize = parseInt(searchParams.get(DistrictSchoolPageQueryParams.PageSize) ?? `${DEFAULT_PAGE_SIZE}`) ?? DEFAULT_PAGE_SIZE;

      const orderBy = (searchParams.get(DistrictSchoolPageQueryParams.OrderBy) as DistrictSchoolQuerySortKeyEnum | null) ?? undefined;
      const sortDirection = (searchParams.get(DistrictSchoolPageQueryParams.SortDirection) as SortOrder | null) ?? undefined;

      const updatedQuery: SearchDistrictSchoolDetailsQuery = {
        search: searchParams.get(DistrictSchoolPageQueryParams.Search) ?? undefined,
        type: typeFilters.length ? typeFilters : undefined,
        productStatus: productStatusFilters.length ? productStatusFilters : undefined,
        enrolmentStatus: enrolmentStatusFilters.length ? enrolmentStatusFilters : undefined,
        country: countryFilters.length ? countryFilters : undefined,
        page,
        pageSize,
        orderBy,
        sortDirection,
      };
      return removeUndefinedProperties(updatedQuery) as SearchDistrictSchoolDetailsQuery;
    });
  }, [searchParams]);

  // When the query changes, then fetch the results.
  useEffect(() => {
    refetch();
  }, [districtSchoolQuery, refetch]);

  return (
    <>
      <HeaderCard title="Districts & Schools" actionButtons={<DistrictAndSchoolsActionsButton />} />
      <Card sx={{ mt: 3, p: 3 }}>
        <DistrictsAndSchoolsSearchInput />
        <DistrictsAndSchoolsFilters sx={{ mt: 3 }} />
        <Box sx={{ width: '100%', mt: 6.5, height: '4px' }}>{isFetching && <LinearProgress />}</Box>
        <TableContainer component={Paper}>
          <DistrictsAndSchoolsTable query={districtSchoolQuery} districtsAndSchoolsPaginatedResponse={districtsAndSchoolsPaginatedResponse} />
          <QueryableTablePagination paginatedResponse={districtsAndSchoolsPaginatedResponse} />
        </TableContainer>
      </Card>
    </>
  );
};

export default DistrictsAndSchoolsPage;
