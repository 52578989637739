import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import ConfirmRemoveDialog from '@hoot/components/modals/ConfirmRemoveDialog';
import { AdminLessonNoteDetailsBook } from '@hoot/hooks/api/lesson-notes/useGetLessonNoteDetailsById';
import { DetailedBook } from '@hoot/hooks/api/library/useGetBook';
import { SortOrder, TableHeaderCell, muiTableSortDirectionLookup } from '@hoot/interfaces/order-by';
import AddBooksModal from '../../../../../components/modals/AddBooksModal';
import NoneItem from '../../../../../components/ui/NoneItem';
import { ReadingLevelType, readingLevelType } from '../../../../lessons/enums';

export interface LessonNoteBooksCardProps {
  isLoading: boolean;
  validationErrorMessage: string | undefined;
  books: AdminLessonNoteDetailsBook[];
  onRemoveLessonBook: (book: AdminLessonNoteDetailsBook) => void;
  onAddBooksToLesson: (books: AdminLessonNoteDetailsBook[]) => void;
}

enum BookSortKeyEnum {
  BookTitle = 'BookTitle',
  BookLevel = 'BookLevel',
}

const tableHeaderCells: TableHeaderCell<BookSortKeyEnum>[] = [
  { label: 'Book Title', sortKey: BookSortKeyEnum.BookTitle },
  { label: 'Book Level', sortKey: BookSortKeyEnum.BookLevel },
];

const LessonNoteBooksCard = (props: LessonNoteBooksCardProps) => {
  const { isLoading, validationErrorMessage, books, onRemoveLessonBook, onAddBooksToLesson } = props;

  const [sortOptions, setSortOptions] = useState<{ orderBy: BookSortKeyEnum; sortDirection: SortOrder }>();
  const [showAddLessonBookModal, setShowAddLessonBookModal] = useState(false);
  const [bookToRemove, setBookToRemove] = useState<AdminLessonNoteDetailsBook>();

  const existingBookIds = useMemo(() => books.map((x) => x.bookId), [books]);

  const onShowConfirmRemoveBookDialog = (book: AdminLessonNoteDetailsBook) => () => {
    setBookToRemove(book);
  };

  const onRemoveLessonBookConfirmed = () => {
    onRemoveLessonBook(bookToRemove!);
    onDismissRemoveConfirmationDialog();
  };

  const onDismissRemoveConfirmationDialog = () => {
    setBookToRemove(undefined);
  };

  const onShowAddLessonBookModal = () => {
    setShowAddLessonBookModal(true);
  };

  const onDismissAddLessonBookModal = () => {
    setShowAddLessonBookModal(false);
  };

  const _onAddBooksToLesson = (booksToAdd: DetailedBook[]) => {
    onDismissAddLessonBookModal();

    const lessonNoteBooksToAdd = booksToAdd.map<AdminLessonNoteDetailsBook>((b) => {
      return {
        bookId: b.id,
        title: b.title,
        readingLevelSystem: b.readingLevel.type as ReadingLevelType,
        readingLevel: b.readingLevel.name as AdminLessonNoteDetailsBook['readingLevel'],
        lessonNoteId: '',
      };
    });
    onAddBooksToLesson(lessonNoteBooksToAdd);
  };

  const bookLevelLabelDictionary = useMemo<Record<string, string>>(() => {
    return books.reduce((acc, curr) => {
      const readingLevelSystemLabel = readingLevelType[curr.readingLevelSystem as ReadingLevelType] ?? curr.readingLevelSystem;
      return {
        ...acc,
        [curr.bookId]: `${readingLevelSystemLabel} ${curr.readingLevel}`,
      };
    }, {});
  }, [books]);

  const sortedBooks = useMemo(() => {
    if (!sortOptions) {
      return books;
    }
    return books.sort((a, b) => {
      const numericDirection = sortOptions.sortDirection === SortOrder.ASC ? 1 : -1;
      switch (sortOptions.orderBy) {
        case BookSortKeyEnum.BookLevel:
          return bookLevelLabelDictionary[a.bookId].localeCompare(bookLevelLabelDictionary[b.bookId]) * numericDirection;
        case BookSortKeyEnum.BookTitle:
        default:
          return a.title.localeCompare(b.title) * numericDirection;
      }
    });
  }, [bookLevelLabelDictionary, books, sortOptions]);

  // If we're toggling the same order-by key, then just flip the sort direction.
  // Else, we're sorting by a different column, and we can default the sort to 'asc'.
  const toggleOrderBy = (orderByKey: BookSortKeyEnum) => () => {
    const isAsc = sortOptions?.orderBy === orderByKey && sortOptions?.sortDirection === SortOrder.ASC;

    setSortOptions({ orderBy: orderByKey, sortDirection: isAsc ? SortOrder.DESC : SortOrder.ASC });
  };

  return (
    <>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 3,
              mb: 3,
            }}
          >
            <Typography variant="titleLarge" sx={{ display: 'block' }}>
              Book(s) Read*
            </Typography>
            {isLoading ? (
              <Skeleton variant="rounded" width="70px" height="36px" />
            ) : (
              <Button variant="contained" size="large" onClick={onShowAddLessonBookModal}>
                Add
              </Button>
            )}
          </Box>
          {isLoading ? (
            <LoadingContent />
          ) : (sortedBooks?.length ?? 0) === 0 ? (
            <NoneItem label="Books" errorMessage={validationErrorMessage} />
          ) : (
            <Table>
              <TableHead sx={{ background: 'unset' }}>
                <TableRow>
                  {tableHeaderCells.map((cell) => (
                    <TableCell
                      variant="head"
                      key={`headerCell-${cell.label}`}
                      sortDirection={
                        cell.sortKey
                          ? sortOptions?.orderBy === cell.sortKey
                            ? muiTableSortDirectionLookup[sortOptions?.sortDirection ?? SortOrder.ASC]
                            : false
                          : undefined
                      }
                    >
                      {cell.sortKey ? (
                        <TableSortLabel
                          active={sortOptions?.orderBy === cell.sortKey}
                          direction={
                            sortOptions?.orderBy === cell.sortKey
                              ? muiTableSortDirectionLookup[sortOptions?.sortDirection ?? SortOrder.ASC]
                              : undefined
                          }
                          onClick={toggleOrderBy(cell.sortKey)}
                        >
                          {cell.label}
                        </TableSortLabel>
                      ) : (
                        cell.label
                      )}
                    </TableCell>
                  ))}
                  {/* Placeholder column for "remove" button. */}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedBooks.map((row) => (
                  <TableRow key={row.bookId}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{bookLevelLabelDictionary[row.bookId]}</TableCell>
                    <TableCell align="right">
                      <Button color="error" onClick={onShowConfirmRemoveBookDialog(row)}>
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
      <ConfirmRemoveDialog
        open={!!bookToRemove}
        onClose={onDismissRemoveConfirmationDialog}
        onContinue={onRemoveLessonBookConfirmed}
        bodyText="Are you sure you want to remove this book from the lesson?"
      />
      <AddBooksModal
        show={showAddLessonBookModal}
        existingBookIds={existingBookIds}
        onDismiss={onDismissAddLessonBookModal}
        onAddBooksToLesson={_onAddBooksToLesson}
      />
    </>
  );
};

const LoadingContent = () => (
  <Grid container spacing={1}>
    {[...Array(3)].map((_, index) => (
      <React.Fragment key={`lesson-note-books-card-placeholder-${index}`}>
        <Grid item xs={7}>
          <Skeleton variant="text" sx={{ maxWidth: '200px' }} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="text" sx={{ maxWidth: '200px' }} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton variant="text" />
        </Grid>
      </React.Fragment>
    ))}
  </Grid>
);

export default LessonNoteBooksCard;
