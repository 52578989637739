import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';
import { Tokens } from '../../../interfaces/auth';
import { HootEmployee } from '../../../interfaces/hoot-employee';

export interface LoginRequest {
  googleAccessToken: string;
}

export interface HootEmployeeLoginResponse {
  hootEmployee: HootEmployee;
  tokens: Tokens;
}

async function login(request: LoginRequest) {
  const { data } = await axios.post<HootEmployeeLoginResponse>(`${config.apiUrl}/auth/admin-login`, request);
  return data;
}

export default function useLogin(
  options?: Omit<UseMutationOptions<HootEmployeeLoginResponse, AxiosError, LoginRequest>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation<HootEmployeeLoginResponse, AxiosError, LoginRequest>((request) => login(request), options);
}
