import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { TeacherCertificationArea } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-certification-area.enum';
import { TeacherSpecializedTraining } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-specialized-training.enum';
import { Card, CardContent, CardHeader, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import phone from 'phone';
import { Dispatch, SetStateAction } from 'react';
import { Control, FormState, UseFormSetValue } from 'react-hook-form';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { TextField } from '@hoot/components/form/TextField';
import { TeacherAccount } from '@hoot/hooks/api/user/teacher/useGetTeacherAccount';
import { BackgroundCheck } from '@hoot/interfaces/teacher';
import { TeacherAccountAndUserForm } from '@hoot/pages/users/interfaces/form';
import TeacherBillableLessonCard from '@hoot/pages/users/teachers/TeacherBillableLessonCard';
import TeacherSpecializedTrainingCard from '@hoot/pages/users/teachers/TeacherSpecializedTrainingCard';
import theme from '@hoot/theme';
import { VALID_EMAIL_REGEX } from '../../../../common/constants';
import TeacherCertificationAreasCard from '../TeacherCertificationAreasCard';
import TeacherContractDatesCard from '../TeacherContractDatesCard';
import TeacherLanguageAndQualificationsCard from '../TeacherLanguageAndQualificationsCard';
import TeacherSpokenLanguagesCard from '../TeacherSpokenLanguagesCard';
import TeacherStatusCard from '../TeacherStatusCard';
import TeacherUserDetails from '../TeacherUserDetails';

const TeacherDetailsTab = (props: {
  isEditMode: boolean;
  control: Control<TeacherAccountAndUserForm, any>;
  watch: UseFormWatch<TeacherAccountAndUserForm>;
  formState: FormState<TeacherAccountAndUserForm>;
  teacherAccount: TeacherAccount | undefined;
  country: string;
  setValue: UseFormSetValue<TeacherAccountAndUserForm>;
  selectedHootQualifications: HootQualification[];
  selectedSpokenLanguages: SpokenLanguage[];
  selectedCertificationAreas: TeacherCertificationArea[];
  selectedSpecializedTraining: TeacherSpecializedTraining[];
  selectedBackgroundChecks: BackgroundCheck[];
  setSelectedHootQualifications: Dispatch<SetStateAction<HootQualification[]>>;
  setSelectedSpokenLanguages: Dispatch<SetStateAction<SpokenLanguage[]>>;
  setSelectedCertificationAreas: Dispatch<SetStateAction<TeacherCertificationArea[]>>;
  setSelectedSpecializedTraining: Dispatch<SetStateAction<TeacherSpecializedTraining[]>>;
  setSelectedBackgroundChecks: Dispatch<SetStateAction<BackgroundCheck[]>>;
}) => {
  const {
    isEditMode,
    teacherAccount,
    control,
    watch,
    formState,
    country,
    setValue,
    selectedHootQualifications,
    selectedSpokenLanguages,
    selectedCertificationAreas,
    selectedSpecializedTraining,
    selectedBackgroundChecks,
    setSelectedHootQualifications,
    setSelectedSpokenLanguages,
    setSelectedCertificationAreas,
    setSelectedSpecializedTraining,
    setSelectedBackgroundChecks,
  } = props;

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Grid container marginTop="24px">
      <Grid container item xs={12} lg={4} alignContent="flex-start" paddingRight={isSmallScreen ? undefined : '12px'}>
        <Grid container item direction="column" rowGap={3}>
          <TeacherStatusCard
            control={control}
            watch={watch}
            setValue={setValue}
            teacherAccount={teacherAccount}
            isEnabledUpdateAt={teacherAccount?.user.isEnabledLastUpdatedAt ?? null}
            teacherStatusUpdatedAt={teacherAccount?.updatedStatusAt ?? null}
            isEditMode={isEditMode}
            formState={formState}
          />

          <TeacherContractDatesCard control={control} watch={watch} formState={formState} setValue={setValue} />

          <TeacherSpokenLanguagesCard
            selectedSpokenLanguages={selectedSpokenLanguages}
            setSelectedSpokenLanguages={setSelectedSpokenLanguages}
            formState={formState}
          />

          <TeacherCertificationAreasCard
            selectedCertificationAreas={selectedCertificationAreas}
            setSelectedCertificationAreas={setSelectedCertificationAreas}
            formState={formState}
          />

          <TeacherSpecializedTrainingCard
            selectedSpecializedTraining={selectedSpecializedTraining}
            setSelectedSpecializedTraining={setSelectedSpecializedTraining}
            formState={formState}
          />

          <TeacherBillableLessonCard billableLessonsCount={teacherAccount?.billableLessonsCount} />
        </Grid>
      </Grid>

      <Grid container item xs={12} lg={8} paddingTop={isSmallScreen ? '24px' : undefined} paddingLeft={isSmallScreen ? undefined : '12px'}>
        <Grid container item direction="column" rowGap={3}>
          <TeacherUserDetails
            control={control}
            formState={formState}
            setValue={setValue}
            country={country}
            teacherNumber={teacherAccount?.number ?? null}
          />

          <TeacherLanguageAndQualificationsCard
            setSelectedHootQualifications={setSelectedHootQualifications}
            selectedBackgroundChecks={selectedBackgroundChecks}
            selectedHootQualifications={selectedHootQualifications}
            setSelectedBackgroundChecks={setSelectedBackgroundChecks}
            formState={formState}
          />

          <Grid container item>
            <Card
              sx={{
                width: 1,
              }}
            >
              <CardHeader
                sx={{ p: 3, pb: 0 }}
                title={
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="titleLarge">Biography</Typography>
                  </Stack>
                }
              />
              <CardContent sx={{ p: 3, pt: 0 }}>
                <Grid marginTop="24px" container sx={{ rowGap: 3 }}>
                  <Grid item container xs={12}>
                    <TextField name="bio" label="Bio" control={control} variant="outlined" multiline rows={3} fullWidth />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid container item>
            <Card
              sx={{
                width: 1,
              }}
            >
              <CardHeader
                sx={{ p: 3, pb: 0 }}
                title={
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="titleLarge">Emergency Contacts</Typography>
                  </Stack>
                }
              />
              <CardContent sx={{ p: 3, pt: 0 }}>
                <Stack marginTop="24px" spacing="24px">
                  <Stack direction="row" spacing="24px">
                    <Stack flex={1}>
                      <TextField
                        name="emergencyContactFirstName"
                        label="Emergency Contact First Name"
                        control={control}
                        variant="outlined"
                        fullWidth
                      />
                    </Stack>
                    <Stack flex={1}>
                      <TextField name="emergencyContactLastName" label="Emergency Contact Last Name" control={control} variant="outlined" fullWidth />
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing="24px">
                    <Stack flex={1}>
                      <TextField
                        name="emergencyContactEmail"
                        label="Emergency Contact Email"
                        control={control}
                        variant="outlined"
                        fullWidth
                        rules={{
                          required: false,
                          maxLength: 48,
                          pattern: {
                            value: VALID_EMAIL_REGEX,
                            message: 'A valid email is required.',
                          },
                        }}
                      />
                    </Stack>
                    <Stack flex={1}>
                      <TextField
                        name="emergencyContactPhoneNumber"
                        label="Emergency Contact Phone Number"
                        control={control}
                        variant="outlined"
                        fullWidth
                        rules={{
                          required: false,
                          validate: (value: any) => {
                            if (!value?.length) {
                              return true;
                            }
                            const phoneNum = value ? value.toString().trim() : '';
                            if (phoneNum === '+' || phoneNum === '+1' || !phone(phoneNum || '')?.isValid) {
                              return 'Valid phone number is required: +x (xxx) xxx-xxxx';
                            }
                            return true;
                          },
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TeacherDetailsTab;
