import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Button, Card, CardContent, CardHeader, Divider, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types/form';
import { studentMembershipTypes } from '@hoot/interfaces/profile';
import NoneItem from '../../../../../components/ui/NoneItem';
import { StudentDetailsForm } from '../form';
import StudentMembershipModal from './studentMembershipModal';

const StudentMembershipCard = (props: {
  watch: UseFormWatch<StudentDetailsForm>;
  setValue: UseFormSetValue<StudentDetailsForm>;
  disableFields: boolean;
}) => {
  const { watch, setValue, disableFields } = props;
  const { membershipOptions } = watch();
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Card sx={{ pl: 3, pt: 3, pr: 3 }}>
      <CardHeader
        sx={{ p: 0, pb: 3 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="titleLarge">
              Membership
            </Typography>
            <Button type="button" variant="contained" onClick={() => setShowModal(true)} disabled={disableFields}>
              Edit
            </Button>
          </Stack>
        }
      />

      <CardContent sx={{ p: 0 }}>
        <Stack>
          {membershipOptions?.length > 0 ? (
            membershipOptions.map((membership, idx) => (
              <Stack key={idx}>
                {idx !== 0 ? <Divider /> : null}
                <Stack direction="row" gap={2} sx={{ pt: 2, pb: 2 }}>
                  <PersonOutlineOutlinedIcon />
                  <Typography>{studentMembershipTypes[membership]}</Typography>
                </Stack>
              </Stack>
            ))
          ) : (
            <NoneItem />
          )}
        </Stack>
      </CardContent>
      {showModal ? (
        <StudentMembershipModal open={showModal} setOpen={setShowModal} membershipOptions={membershipOptions} setValue={setValue} />
      ) : null}
    </Card>
  );
};

export default StudentMembershipCard;
