import { UserImportStatus } from '@hoot-reading/hoot-core/dist/enums/user/user-import/user-import-status.enum';
import { UserImportType } from '@hoot-reading/hoot-core/dist/enums/user/user-import/user-import-type.enum';
import { UserImportUserStatus } from '@hoot-reading/hoot-core/dist/enums/user/user-import/user-import-user-status.enum';
import { Error, PersonOutline, Warning } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Card, CircularProgress, DialogActions, DialogContent, Divider, Stack, Typography, styled } from '@mui/material';
import fileDownloader from 'js-file-download';
import useGetImport from '@hoot/hooks/api/hfs/district-schools/useGetImport';
import useGetUserImportError from '@hoot/hooks/api/hfs/district-schools/useGetUserImportError';
import { UserImportResponse } from '@hoot/hooks/api/hfs/district-schools/useUserImportCsv';
import theme from '@hoot/theme';
import { userImportLabelDictionary } from './util';

interface IUserImportSummaryProps {
  uploadedImport: UserImportResponse | undefined;
  handleClose: () => void;
}

const UserImportSummary = (props: IUserImportSummaryProps) => {
  const { uploadedImport, handleClose } = props;

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const { data: importData } = useGetImport(uploadedImport?.id!, {
    // Fetch import data every 2 seconds until the response status comes back as Completed.
    refetchInterval: (res) => (res?.status === UserImportStatus.Completed ? false : 2000),
  });

  const isLoading = !importData || importData?.status !== UserImportStatus.Completed;

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const userImportError = useGetUserImportError(uploadedImport?.id!, [UserImportUserStatus.HasErrors], {
    enabled: false,
    onSuccess: (res) => {
      const errorFileName = `Errors-${userImportLabelDictionary[uploadedImport?.type as UserImportType]}.csv`;
      fileDownloader(res.data, errorFileName);
    },
    onError: (err) => console.error('Error downloading CSV', err),
  });

  const userImportLessonSetError = useGetUserImportError(
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    uploadedImport?.id!,
    [UserImportUserStatus.CreatedWithoutLessonSet, UserImportUserStatus.UpdatedWithoutLessonSet],
    {
      enabled: false,
      onSuccess: (res) => {
        const errorFileName = `Lesson Set Errors-${userImportLabelDictionary[uploadedImport?.type as UserImportType]}.csv`;
        fileDownloader(res.data, errorFileName);
      },
      onError: (err) => console.error('Error downloading CSV', err),
    },
  );

  const handleCloseBtn = () => {
    handleClose();
  };

  const fetchErrorFile = () => userImportError.refetch();
  const fetchLessonSetErrorFile = () => userImportLessonSetError.refetch();

  const createdRows = importData?.userCounts?.created;
  const updatedRows = importData?.userCounts?.updated;
  const ignoredRows = importData?.userCounts?.ignored;
  const erroredRows = importData?.userCounts?.errored;
  const createdWithoutLessonSetRows = importData?.userCounts?.createdWithoutLessonSet;
  const updatedWithoutLessonSetRows = importData?.userCounts?.updatedWithoutLessonSet;

  const StyledCard = styled(Card)(() => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2.5),
    border: '1px solid rgba(0, 0, 0, 0.1)',
    height: 56,
    '& > svg': { marginRight: '20px' },
  }));

  return (
    <>
      <DialogContent sx={{ padding: 0, marginTop: 2 }}>
        <Typography variant="titleMedium" sx={{ color: '#000' }}>
          Step 3 - Summary of Changes
        </Typography>
        <Stack sx={{ padding: theme.spacing(3, 0, 1, 0) }} gap={2}>
          {importData?.type ? <Typography variant="bodySmall">{userImportLabelDictionary[importData?.type]}</Typography> : null}

          {isLoading ? <CircularProgress /> : null}

          {!!createdRows ? (
            <StyledCard>
              <PersonOutline />
              <Typography>{`Created (${createdRows})`}</Typography>
            </StyledCard>
          ) : null}

          {!!updatedRows ? (
            <StyledCard>
              <PersonOutline />
              <Typography>{`Updated (${updatedRows})`}</Typography>
            </StyledCard>
          ) : null}

          {!!createdWithoutLessonSetRows || !!updatedWithoutLessonSetRows ? (
            <StyledCard>
              <Warning htmlColor="#FC3" />
              <Stack direction="row" columnGap={2}>
                {createdWithoutLessonSetRows ? <Typography>{`Created without a Lesson Set (${createdWithoutLessonSetRows})`}</Typography> : null}
                {updatedWithoutLessonSetRows ? <Typography>{`Updated without a Lesson Set (${updatedWithoutLessonSetRows})`}</Typography> : null}
              </Stack>
              <LoadingButton
                onClick={fetchLessonSetErrorFile}
                loading={userImportLessonSetError.isRefetching}
                variant="text"
                sx={{ marginLeft: 'auto' }}
              >
                Download
              </LoadingButton>
            </StyledCard>
          ) : null}

          {!!ignoredRows ? (
            <StyledCard>
              <PersonOutline />
              <Typography>{`Ignored (${ignoredRows})`}</Typography>
            </StyledCard>
          ) : null}

          {!!erroredRows ? (
            <StyledCard>
              <Error htmlColor="#BA1A1A" />
              <Typography>{`Errors (${erroredRows})`}</Typography>
              <LoadingButton onClick={fetchErrorFile} loading={userImportError.isRefetching} variant="text" sx={{ marginLeft: 'auto' }}>
                Download
              </LoadingButton>
            </StyledCard>
          ) : null}
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ padding: 0 }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack direction="row"></Stack>

          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button onClick={handleCloseBtn} variant="contained" size="large">
              Done
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  );
};

export default UserImportSummary;
