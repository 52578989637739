import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import {
  ScheduleCombination,
  ScheduleCombinationSlot,
  UpdateEnrolmentCapacityForm,
} from '@hoot/pages/district-schools/details/enrolments/capacity/EnrolmentCapacity';
import DaysOfWeekCombination from '@hoot/pages/district-schools/details/enrolments/capacity/EnrolmentDaysOfWeekSelector';

interface Props {
  sequence: number;
  setSequence: Dispatch<SetStateAction<number | undefined>>;
  scheduleCombinations: ScheduleCombination[];
  setValue: UseFormSetValue<UpdateEnrolmentCapacityForm>;
}

function CombinationModal(props: Props) {
  const { setSequence, sequence, scheduleCombinations, setValue } = props;
  const now = DateTime.now().plus({ minutes: 1 });
  const isNewCombination = !scheduleCombinations.find((x) => x.sequence === sequence);
  const currentCombination = scheduleCombinations.find((x) => x.sequence === sequence) ?? {
    sequence,
    allowed: 0,
    combinationSlots: [],
    available: 0,
    booked: 0,
  };
  const [selectedDaySlots, setSelectedDaySlots] = useState<ScheduleCombinationSlot[]>(currentCombination.combinationSlots);
  const [sameTimeEveryDay, setSameTimeEveryDay] = useState<string>(
    currentCombination?.combinationSlots?.length > 0 &&
      currentCombination.combinationSlots.every((slot) => slot.time === currentCombination.combinationSlots[0].time)
      ? currentCombination.combinationSlots[0].time
      : '',
  );
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleApply = () => {
    if (!sameTimeEveryDay && selectedDaySlots.length > 0 && selectedDaySlots.some((slot) => !slot.time)) {
      setErrorMessage('Please select a time for each day');
      return;
    }
    if (selectedDaySlots.length === 0) {
      setErrorMessage('Please select one day');
      return;
    }
    const filteredCombinations = scheduleCombinations.filter((sc) => sc.sequence !== sequence);
    const updatedCombination = {
      ...currentCombination,
      combinationSlots: selectedDaySlots.map((sdl) => ({ ...sdl, time: !!sameTimeEveryDay ? sameTimeEveryDay : sdl.time })),
    };
    if (
      filteredCombinations.find((x) =>
        selectedDaySlots.every((sdl) => x.combinationSlots.every((cs) => cs.dayOfWeek === sdl.dayOfWeek && cs.time === sdl.time)),
      )
    ) {
      setErrorMessage('Combination already exists');
      return;
    }
    setValue('scheduleCombinations', [...filteredCombinations, updatedCombination], { shouldDirty: true });
    setSequence(undefined);
  };

  const closeModal = () => {
    setSequence(undefined);
  };

  return (
    <Dialog onBackdropClick={undefined} open={!!sequence} onClose={closeModal} maxWidth="md">
      <Stack sx={{ padding: '24px', position: 'relative' }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">{isNewCombination ? 'Create' : 'Update'} Day Combination</Typography>
            <IconButton onClick={closeModal} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <DaysOfWeekCombination
            defaultTime={now}
            selectedDaySlots={selectedDaySlots}
            setSelectedDaySlots={setSelectedDaySlots}
            sameTimeEveryDay={sameTimeEveryDay}
            setSameTimeEveryDay={setSameTimeEveryDay}
            errorMessage={errorMessage}
          />
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="center" gap={2}>
            <Button
              sx={{
                fontSize: 14,
              }}
              size="large"
              color="inherit"
              variant="outlined"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              sx={{
                fontSize: 14,
                textTransform: 'none',
              }}
              size="large"
              variant="contained"
              onClick={handleApply}
            >
              Apply
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}

export default CombinationModal;
