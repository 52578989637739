export enum QueryKey {
  CurrentTime = 'current-time',
  TeacherAvailabilityException = 'teacher-availability-exception',
  TeacherAvailabilityExceptionImpacts = 'teacher-availability-exception-impacts',
  TeacherAvailability = 'teacher-availability',
  TeacherReliabilityScore = 'teacher-reliability-score',
  GetTeacherShift = 'get-teacher-shift',
  GetTeacherShiftLessons = 'get-teacher-shift-lessons',
  GetAccounts = 'get-account',
  GetPartners = 'get-partners',
  GetSites = 'get-sites',
  StudentBillableLessonCount = 'student-billable-lesson-count',
  GetStudentProfile = 'get-student-profile',
  GetStudentProfiles = 'get-student-profiles',
  SearchStudentProfilesV2 = 'search-student-profiles-v2',
  GetTeacherAccount = 'get-teacher-account',
  GetCustomerAccount = 'get-customer-account',
  SearchTeacherAccounts = 'search-teacher-accounts',
  GetUser = 'get-user',
  GetTeacherHistory = 'get-teacher-history',
  GetStudentHistory = 'get-student-history',
  GetParentHistory = 'get-parent-history',
  GetDistrictRepHistory = 'get-district-rep-history',
  GetQualityAssuranceTaskHistory = 'get-quality-assurance-task-history',
  UserSearch = 'user-search',
  StudentSearch = 'student-search',
  GetFeatureFlags = 'get-feature-flags',
  GetHootEmployee = 'get-hoot-employee',
  HootEmployeeSearch = 'hoot-employee-search',
  StudentLessonNotes = 'student-lesson-notes',
  SearchStudentLessonNotes = 'search-student-lesson-notes',
  GetLessonNote = 'get-lesson-note',
  GetLessonNoteByLessonId = 'get-lesson-note-by-lesson-id',
  LessonConflicts = 'lesson-conflicts',
  LessonConflictsV2 = 'lesson-conflicts-v2',
  Lesson = 'lesson',
  LessonHistory = 'lesson-history',
  LessonComments = 'lesson-comments',
  Lessons = 'lessons',
  LiveLessons = 'live-lessons',
  UserScheduleLessons = 'user-schedule-lessons',
  LessonsWithTimeInfo = 'lessons-with-time-info',
  LessonsWithTimeInfoResultCount = 'lessons-with-time-info-result-count',
  MonthlyScheduledLessons = 'monthly-scheduled-lessons',
  ScheduledLessons = 'scheduled-lessons',
  GetBook = 'getBook',
  LibraryFilters = 'library-filters',
  LibraryQuery = 'library-query',
  GetLessonSummaryDashboard = 'get-lesson-summary-dashboard',
  GetMetabaseReportEmbed = 'get-metabase-report-embed',
  GetStudentHootAssessments = 'get-student-hoot-assessments',
  EnrolmentSearch = 'enrolment-search',
  ProductSearch = 'product-search',
  GetDistrictsSchool = 'get-districts-school',
  GetLocation = 'get-location',
  GetLocationSummaryDashboard = 'get-location-summary-dashboard',
  GetLocationSummaryProducts = 'get-location-summary-products',
  GetLocationSummaryEnrolments = 'get-location-summary-enrolments',
  GetLocationSummaryStudents = 'get-location-summary-students',
  GetLocationStudents = 'get-location-students',
  SearchDistrictsSchools = 'search-districts-schools',
  SearchDistrictsSchoolsDetails = 'search-districts-schools-details',
  GetDistrictRepresentative = 'get-district-representative',
  GetDistrictRepresentativeExistingLocation = 'get-district-representative-existing-location',
  GetParentProfile = 'get-parent-profile',
  GetPasswordResetLink = 'get-password-reset-link',
  SearchLocationProducts = 'search-location-products',
  SearchLocationEnrolments = 'search-location-enrolments',
  GetProduct = 'get-product',
  GetProductEnrolments = 'get-product-enrolments',
  GetSchoolsForDistrict = 'get-schools-for-district',
  GetParentProfiles = 'get-parent-profiles',
  GetEnrolment = 'get-enrolment',
  GetEnrolmentCapacity = 'get-enrolment-capacity',
  GetEnrolmentQualityMonitoring = 'get-enrolment-quality-monitoring',
  GetProductQualityMonitoring = 'get-product-quality-monitoring',
  GetEnrolmentPeriods = 'get-enrolment-period',
  GetUserImport = 'get-user-import',
  GetTRSImport = 'get-trs-import',
  GetInvoiceImport = 'get-invoice-import',
  GetUserImportError = 'get-user-import-error',
  GetTRSImportErrors = 'get-trs-import-errors',
  GetInvoiceImportErrorFile = 'get-invoice-import-error-file',
  GetAllBillableLocations = 'get-all-billable-locations',
  GetAllSchools = 'get-all-schools',
  GetAllEnrolments = 'get-all-enrolments',
  GetStudentLessonPreferences = 'get-lesson-preferences',
  GetDistricts = 'get-districts',
  SearchSchools = 'search-schools',
  GetLessonPayRateTiers = 'get-lesson-pay-rate-tiers',
  LessonSet = 'lesson-set',
  LessonSets = 'lesson-sets',
  LessonSetLessons = 'lesson-set-lessons',
  SearchTeacherInvoicePeriods = 'search-teacher-invoice-periods',
  SearchTeacherInvoiceBillableTasks = 'search-teacher-invoice-billable-tasks',
  SearchTeacherInvoiceUploadedTasks = 'search-teacher-invoice-uploaded-tasks',
  GetUserSelectableProjects = 'get-user-selectable-projects',
  GetInvoiceCsvUploadProject = 'get-invoice-csv-upload-projects',
  GetMetabaseReports = 'get-metabase-reports',
  GetInvoicesCSVReport = 'get-invoices-csv-report',
  GetBankingUpdatesCSVReport = 'get-banking-updates-csv-report',
  GetLessonReviews = 'get-lesson-reviews',
  GetLessonNotesLegacy = 'get-lesson-notes-legacy',
  GetLessonReviewDetails = 'get-lesson-review-details',
  GetWordReadingFormativeAssessmentDetails = 'get-word-reading-formative-assessment-details',
  GetFluencyFormativeAssessmentDetails = 'get-fluency-formative-assessment-details',
  GetComprehensionFormativeAssessmentDetails = 'get-comprehension-formative-assessment-details',
  GetLettersAndSoundsFormativeAssessmentDetails = 'get-letters-and-sounds-formative-assessment-details',
  GetStudentFormativeAssessments = 'get-student-formative-assessments',
  GetPublishers = 'get-publishers',
  GetInstructionalFocus = 'get-instructional-focus',
  GetInstructionalUnits = 'get-instructional-units',
  GetLevellingSystem = 'get-levelling-system',
  GetGenres = 'get-genres',
  GetSubGenres = 'get-sub-genres',
  GetThemes = 'get-themes',
  GetSubThemes = 'get-sub-themes',
  GetTopics = 'get-topics',
  GetSubTopics = 'get-sub-topics',
  GetFormats = 'get-formats',
  GetSeries = 'get-series',
  GetFilterLevelPrioritizedSkill = 'get-filter-level-prioritized-skill',
  GetCollections = 'get-collections',
  GetContentTags = 'get-content-tags',
  GetHootLevelsReport = 'get-hoot-levels-report',
  GetHootLevelsReportCSV = 'get-hoot-levels-report-csv',
  QualityAssuranceTasksQuery = 'quality-assurance-tasks-query',
  QualityAssurnaceTaskDetails = 'quality-assurance-task-details',
  LessonSetScheduleMetrics = 'lesson-set-schedule-metrics',
  LessonSetAssessmentMetrics = 'lesson-set-assessment-metrics',
  LessonSetInstructionsMetrics = 'lesson-set-instructions-metrics',
  GetStudentInstructionalPlanFavourites = 'get-student-instrunctional-plan-favourites',
  GetStudentInstructionalLessonPlan = 'get-student-instructional-lesson-plan',
  GetStudentInstructionalLibrary = 'get-student-instructional-library',
  GetDailyRecordings = 'get-daily-recordings',
  GetDailyRecordingsAccessLink = 'get-daily-recordings-access-link',
  getStudentLanguageOptions = 'get-student-language-options',
  getStudentSpecialIndicatorData = 'get-student-special-indicator-data',
  CreateEmployee = 'create-employee',
  UpdateEmployee = 'edit-employee',
  TeacherShiftConflicts = 'teacher-shift-conflicts',
  GetTeacherShifts = 'get-teacher-shifts',
  GetShiftDetails = 'get-shift-details',
  GetTeacherShiftHistory = 'get-teacher-shift-history',
  GetTeacherShiftUtilization = 'get-teacher-shift-utilization',
  GetStudentInstructionalFocusData = 'get-student-instructional-focus-data',
  GetStudentInstructionalLibraryUnits = 'get-student-instructional-library-units',
  GetLessonWhiteboards = 'get-lesson-whiteboards',
  GetLessonWhiteboardSnapshot = 'get-lesson-whiteboard-snapshot',
  GetStudentProgressMonitoringAssessments = 'get-student-progress-monitoring-assessments',
  GetStudentProgressMonitoringAssessmentDetails = 'get-student-progress-monitoring-assessment-details',
  GetStudentHootLevels = 'get-student-hoot-levels',
  GetStudentTimeline = 'get-student-timeline',
  GetEventsforLessonVisualizer = 'get-events-for-lesson-visualizer',
  GetTeacherBlock = 'get-teacher-block',
  GetTeacherBlockLessons = 'get-teacher-block-lessons',
  TeacherBlockConflicts = 'teacher-block-conflicts',
  GetTeacherBlocks = 'get-teacher-blocks',
  GetBlockDetails = 'get-block-details',
  GetTeacherBlockHistory = 'get-teacher-block-history',
  GetTeacherBlockUtilization = 'get-teacher-block-utilization',
}
