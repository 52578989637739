import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import {
  AssessmentType,
  ScheduledLessonLanguage,
  ScheduledLessonProgram,
  ScheduledLessonSubject,
  ScheduledLessonTheme,
  ScheduledLessonType,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { TeacherCertificationArea } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-certification-area.enum';
import { TeacherSpecializedTraining } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-specialized-training.enum';
import { TeacherStage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-stage.enum';
import { TeacherStanding } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-standing.enum';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { BackgroundCheck } from '@hoot/interfaces/teacher';
import { QueryKey } from '../../queryKeys';

export interface TentativeLessonTeacherAccountFilter {
  startsAt: number;
  endsAt: number;
}

export interface QueryTeacherAccount {
  name: string;
  excludeInactive: boolean;
  status?: TeacherStatus[];
  teacherStage?: TeacherStage[];
  isEnabled?: boolean;
  hasConflict?: boolean;
  withinShift?: boolean;
  withinAvailability?: boolean;
  noAvailabilityExceptions?: boolean;
  countryRequirements?: HootSupportedCountry;
  hootQualificationRequirements?: HootQualification[];
  backgroundCheckRequirements?: BackgroundCheck[];
  provinceState?: string[];
  tentativeLessons?: TentativeLessonTeacherAccountFilter[];
  spokenLanguages?: SpokenLanguage[];
  certificationAreas?: TeacherCertificationArea[];
  specializedTrainings?: TeacherSpecializedTraining[];
  page: number;
  pageSize: number;
  lessonDetails?: LessonDetails;
}

interface LessonDetails {
  enrolmentId?: string;
  isLessonSet?: boolean;
  lessonType?: ScheduledLessonType;
  program?: ScheduledLessonProgram;
  subject: ScheduledLessonSubject;
  language: ScheduledLessonLanguage;
  isCustomerBillable: boolean;
  theme?: ScheduledLessonTheme;
  assessmentType?: AssessmentType;
  partner?: string;
  partnerName?: string;
  site?: string;
  siteName?: string;
  account?: string;
  accountName?: string;
}

export interface TeacherAccountResponse {
  count: number;
  page: number;
  pageSize: number;
  teacherAccount: TeacherAccount[];
}

export interface TeacherAccount {
  id: string;
  displayName: string;
  hootQualifications: string[];
  bio: string | null;
  number: number;
  prefixedNumber: string;
  availability: string;
  teacherStatus: string;
  standing: TeacherStanding | null;
  languageQualifications: string[];
  spokenLanguages: string[];
  certificationAreas: any[];
  specializedTraining: any[];
  agreementDate?: string;
  goLiveDate?: string;
  diversitySelfIdentifications: string[];
  onLeaveReason?: string;
  agreementEndReason?: string;
  agreementEndDate?: string;
  createdAt: number;
  isDisabled: boolean;
  disabledReason?: string;
  branchNumber: number;
  institutionNumber: number;
  accountNumber: string;
  identificationNumber: number;
  updatedStatusAt?: number;
  status: TeacherStatus;
  user: User;
  conflictingLesson?: { startTime: number; endTime: number };
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isEnabled: boolean;
  isEnabledLastUpdatedAt: number;
  isDisabled: boolean;
  disabledReason: string;
  cookieConsentStatus: string;
  timeZone: string;
  profiles: Profile[];
  parentProfiles: any[];
  customerAccount: any;
  city: string | null;
  country: string | null;
  postalZipCode: string;
  provinceState: string | null;
  streetAddress: string;
  updatedIsEnabledAt: number;
}

export interface Profile {
  id: string;
  isEnabled: boolean;
  name: string;
  number: number;
  type: string;
}

async function searchTeacherAccounts(query: QueryTeacherAccount): Promise<TeacherAccountResponse> {
  const { data } = await axios.post(`${config.apiUrl}/admin/teacher-account/search`, query);
  return data;
}

export default function useSearchTeacherAccounts(
  query: QueryTeacherAccount,
  options?: Omit<UseQueryOptions<TeacherAccountResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.SearchTeacherAccounts, query], () => searchTeacherAccounts(query), options);
}
