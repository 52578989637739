import { CircularProgress, Stack } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import useCreateTeacherBlockComment from '../../../../hooks/api/blocks/useCreateTeacherBlockComment';
import useGetTeacherBlock from '../../../../hooks/api/blocks/useGetTeacherBlock';
import useGetTeacherBlockHistory from '../../../../hooks/api/blocks/useGetTeacherBlockHistory';
import { QueryKey } from '../../../../hooks/api/queryKeys';
import { CommentSubmission, HistoryItemResponse } from '../../../../interfaces/history';
import { CommonHistoryTimeline } from '../../common/CommonHistoryTimeline';
import { TeacherBlockHeaderCard } from './TeacherBlocksHeaderCard';

export function TeacherBlockHistoryPage() {
  const { blockId, teacherAccountId } = useParams() as { blockId: string; teacherAccountId: string };

  const getBlockRequest = useGetTeacherBlock(blockId ?? '', {
    enabled: blockId !== undefined,
    retry: false,
  });

  const queryClient = useQueryClient();
  const teacherBlockHistoryRequest = useGetTeacherBlockHistory(blockId);
  const createTeacherBlockCommentMutation = useCreateTeacherBlockComment(blockId);

  const handleCommentSubmitted = (comment: CommentSubmission) => {
    createTeacherBlockCommentMutation.mutate(
      {
        comment: comment.comment,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.GetTeacherBlockHistory);
        },
      },
    );
  };

  const data =
    teacherBlockHistoryRequest.data?.historyEntries.map<HistoryItemResponse>((h) => ({
      id: h.id,
      isComment: h.isComment,
      body: h.note,
      createdAt: h.createdAt,
      createdById: h.createdById,
      createdBy: h.createdBy,
    })) ?? [];

  if (!getBlockRequest.data) {
    return <CircularProgress />;
  }

  return (
    <Stack gap={3}>
      <TeacherBlockHeaderCard blockNumber={getBlockRequest.data?.prefixedNumber} teacherAccountId={teacherAccountId} blockId={blockId} />
      <CommonHistoryTimeline handleCommentSubmit={handleCommentSubmitted} historyItems={data} />
    </Stack>
  );
}
