import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { DistrictRepresentativeStatus } from '@hoot-reading/hoot-core/dist/enums/user/district-representative/district-representative-status.enum';
import { Close, MailOutline, PhoneOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { defaultRowsPerPage, rowsPerPageOptions } from '@hoot/common/constants';
import FilterDropDown from '@hoot/components/form/FilterDropDown';
import SearchTextField from '@hoot/components/form/SearchTextField';
import EmptyCircleIcon from '@hoot/components/icons/EmptyCircleIcon';
import FullCircleIcon from '@hoot/components/icons/FullCircle';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import { DistrictRepresentative } from '@hoot/hooks/api/hfs/useGetDistrictSchool';
import { FormOption } from '@hoot/interfaces/form';
import { routes } from '@hoot/routes/routes';
import { NoneListItem, SortableTableHeader, TableHeaderProp } from './Shared';

const allHeaders: TableHeaderProp<DistrictRepresentative>[] = [
  { property: 'prefixedNumber', label: 'Number' },
  { property: 'firstName', label: 'First Name' },
  { property: 'lastName', label: 'Last Name' },
  { property: 'email', label: 'Email Address' },
  { property: 'phoneNumber', label: 'Phone Number' },
  { property: 'enrolments', label: 'Enrolments' },
  { property: 'status', label: 'Status' },
];

const StatusChip = (props: { status: DistrictRepresentativeStatus }) => {
  let icon = <FullCircleIcon fill="#2E7D32" />;

  if (props.status === DistrictRepresentativeStatus.Inactive) {
    icon = <EmptyCircleIcon stroke="#B3261E" />;
  }

  return (
    <Chip
      sx={{
        '& > svg': {
          width: '8px',
          height: '8px',
        },
      }}
      size="small"
      icon={icon}
      label={props.status === DistrictRepresentativeStatus.Active ? 'Active' : 'Inactive'}
    />
  );
};

interface Filters {
  status: string[];
  hasEnrolments: string[];
}

export enum DistrictRepSearchFieldOptions {
  Name = 'LESSON_NUMBER',
  Number = 'NUMBER',
  Email = 'EMAIL',
  Phone = 'PHONE',
  EnrolmentFID = 'ENROLMENT_FID',
  All = 'ALL',
}

const searchFieldOptions: FormOption<DistrictRepSearchFieldOptions>[] = [
  { value: DistrictRepSearchFieldOptions.Name, label: 'Name' },
  { value: DistrictRepSearchFieldOptions.Number, label: 'Number' },
  { value: DistrictRepSearchFieldOptions.Email, label: 'Email' },
  { value: DistrictRepSearchFieldOptions.Phone, label: 'Phone' },
  { value: DistrictRepSearchFieldOptions.EnrolmentFID, label: 'Enrolment ID' },
  { value: DistrictRepSearchFieldOptions.All, label: 'All Fields' },
];

const FiltersContainer = (props: { value?: Filters; onChange?: (filters: Filters) => void }) => {
  const { value, onChange } = props;

  const handleChange = (property: keyof Filters) => (val: string[]) => {
    if (value) {
      if (onChange) {
        onChange({ ...value, [property]: val });
      }
    }
  };

  return (
    <Grid container alignItems="center">
      <Grid item sx={{ marginRight: '16px' }}>
        <Typography variant="bodySmall">Filters</Typography>
      </Grid>
      <Box sx={{ marginRight: '16px' }}>
        <FilterDropDown<string>
          title="Status"
          dismissPopoverOnSelection={true}
          value={value?.status}
          options={[
            { label: 'Active', value: 'ACTIVE' },
            { label: 'Inactive', value: 'INACTIVE' },
          ]}
          onChange={handleChange('status')}
        />
      </Box>
      <Box>
        <FilterDropDown<string>
          title="Has Enrolments"
          dismissPopoverOnSelection={true}
          value={value?.hasEnrolments}
          options={[
            { label: 'Yes', value: 'YES' },
            { label: 'No', value: 'NO' },
          ]}
          onChange={handleChange('hasEnrolments')}
        />
      </Box>
    </Grid>
  );
};

const ActiveFiltersContainer = (props: { value?: Filters; onChange?: (filters: Filters) => void }) => {
  const { value, onChange } = props;

  const handleDelete = (property: keyof Omit<Filters, 'searchFieldSelection'>, val: string) => () => {
    if (value) {
      const updatedFilters = { ...value };
      updatedFilters[property] = updatedFilters[property].filter((v) => v !== val);
      if (onChange) {
        onChange(updatedFilters);
      }
    }
  };

  return (
    <Grid container alignItems="center">
      <Grid item sx={{ marginRight: '16px' }}>
        <Typography variant="bodySmall">Active</Typography>
      </Grid>
      <Grid item>
        {value?.hasEnrolments.map((c) => (
          <Chip
            sx={{ marginRight: '16px' }}
            key={`chip-hasEnrolments-${c}`}
            label={`Has Enrolments: ${c}`}
            variant="outlined"
            onDelete={handleDelete('hasEnrolments', c)}
            deleteIcon={<Close />}
          />
        ))}
        {value?.status.map((c) => (
          <Chip
            sx={{ marginRight: '16px' }}
            key={`chip-status-${c}`}
            label={`Status: ${c}`}
            variant="outlined"
            onDelete={handleDelete('status', c)}
            deleteIcon={<Close />}
          />
        ))}
      </Grid>
    </Grid>
  );
};

interface Props {
  isEditMode: boolean;
  districtRepresentatives: DistrictRepresentative[];
  locationId: string;
  locationType: LocationType;
}

export const DistrictRepresentativesTable = (props: Props) => {
  const { districtRepresentatives } = props;

  const { info } = useAlert();
  const { scopes } = useAuth();
  const hasViewDistrictDetailsPermission = !!scopes.find((s) => s === HootEmployeeScope.ViewDistrictDetails);

  const [filter, setFilter] = useState<string>('');
  const [searchFieldSelection, setSearchFieldSelection] = useState<DistrictRepSearchFieldOptions>(DistrictRepSearchFieldOptions.All);
  const [sortBy, setSortBy] = useState<keyof DistrictRepresentative | null>('firstName');
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc' | null>('asc');
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(defaultRowsPerPage);
  const [filters, setFilters] = useState<Filters>({
    status: [],
    hasEnrolments: [],
  });

  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    info(`Copied "${val}" to clipboard.`);
  };

  const handleSearchChange = (val: string) => {
    setFilter(val);
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRows(parseInt(event.target.value, defaultRowsPerPage));
    setPage(0);
  };

  const handleFiltersChange = (val: Filters) => {
    setFilters(val);
  };

  const filterFunc = (value: DistrictRepresentative): boolean => {
    const meetsCriteria = [];
    const normalizedQueryString = filter.toLowerCase();
    if (
      value.prefixedNumber.toLowerCase().includes(normalizedQueryString) &&
      (searchFieldSelection === DistrictRepSearchFieldOptions.Number || searchFieldSelection === DistrictRepSearchFieldOptions.All)
    ) {
      meetsCriteria.push(true);
    }
    if (
      value.prefixedNumber.toLowerCase().includes(normalizedQueryString) &&
      (searchFieldSelection === DistrictRepSearchFieldOptions.Number || searchFieldSelection === DistrictRepSearchFieldOptions.All)
    ) {
      meetsCriteria.push(true);
    }
    if (
      (value.firstName.toLowerCase().includes(normalizedQueryString) || value.lastName.toLowerCase().includes(normalizedQueryString)) &&
      (searchFieldSelection === DistrictRepSearchFieldOptions.Name || searchFieldSelection === DistrictRepSearchFieldOptions.All)
    ) {
      meetsCriteria.push(true);
    }
    if (
      value.email.toLowerCase().includes(normalizedQueryString) &&
      (searchFieldSelection === DistrictRepSearchFieldOptions.Email || searchFieldSelection === DistrictRepSearchFieldOptions.All)
    ) {
      meetsCriteria.push(true);
    }
    if (
      value.phoneNumber.toLowerCase().includes(normalizedQueryString) &&
      (searchFieldSelection === DistrictRepSearchFieldOptions.Phone || searchFieldSelection === DistrictRepSearchFieldOptions.All)
    ) {
      meetsCriteria.push(true);
    }
    if (
      value.enrolments.toLowerCase().includes(normalizedQueryString) &&
      (searchFieldSelection === DistrictRepSearchFieldOptions.EnrolmentFID || searchFieldSelection === DistrictRepSearchFieldOptions.All)
    ) {
      meetsCriteria.push(true);
    }

    if (filters.status.length > 0) {
      meetsCriteria.push(filters.status.includes(value.status));
    }

    if (filters.hasEnrolments.length > 0) {
      if (filters.hasEnrolments.includes('YES')) {
        meetsCriteria.push(value.enrolments.length > 0);
      } else if (filters.hasEnrolments.includes('NO')) {
        meetsCriteria.push(value.enrolments.length === 0);
      }
    }
    return meetsCriteria.length > 0 && meetsCriteria.every((c) => c === true);
  };

  const searchFieldOnChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchFieldSelection(value as DistrictRepSearchFieldOptions);
  };

  const filerAvailableCols = (): TableHeaderProp<DistrictRepresentative>[] => {
    if (!hasViewDistrictDetailsPermission) {
      return allHeaders.filter((h) => !['firstName', 'lastName'].includes(h.property));
    }

    return allHeaders;
  };

  return (
    <Card
      sx={{
        width: 1,
      }}
    >
      <CardContent>
        <Grid container direction="column">
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item>
              <Typography align="center" variant="titleLarge">
                District Representatives
              </Typography>
            </Grid>
          </Grid>
          {!districtRepresentatives || districtRepresentatives.length === 0 ? (
            <Grid sx={{ marginTop: '24px' }}>
              <NoneListItem />
            </Grid>
          ) : (
            <>
              <Grid sx={{ marginTop: '24px' }} gap={2} container item xs={12}>
                <Grid item xs={9}>
                  <SearchTextField searchInput={filter} onSearchInputChanged={handleSearchChange} />
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth variant={'outlined'} size={'medium'}>
                    <InputLabel id={'fieldSearchField'}>Search in</InputLabel>
                    <Select labelId={'fieldSearchField'} label={'Search in'} value={searchFieldSelection as any} onChange={searchFieldOnChange}>
                      {searchFieldOptions.map((x) => (
                        <MenuItem key={`fieldSearchField-${x.value}`} value={x.value}>
                          {x.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: '24px' }} container item xs={12}>
                <FiltersContainer value={filters} onChange={handleFiltersChange} />
              </Grid>
              <Grid sx={{ marginTop: '24px' }} container item xs={12}>
                <ActiveFiltersContainer value={filters} onChange={handleFiltersChange} />
              </Grid>

              <Grid sx={{ marginTop: '24px' }} container item xs={12}>
                <Table sx={{ minWidth: 650 }} aria-label="schools and districts table">
                  <SortableTableHeader
                    headers={filerAvailableCols()}
                    setSortBy={setSortBy}
                    setSortOrder={setSortOrder}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                  />
                  <TableBody>
                    {districtRepresentatives
                      .filter(filterFunc)
                      .sort((a, b) => {
                        if (!sortBy) {
                          return 0;
                        } else if (sortOrder === 'asc') {
                          return a[sortBy].localeCompare(b[sortBy]);
                        } else {
                          return b[sortBy].localeCompare(a[sortBy]);
                        }
                      })
                      .slice(page * rows, page * rows + rows)
                      .map((dr) => (
                        <TableRow key={dr.email} sx={{ cursor: 'pointer' }}>
                          <TableCell>
                            <Link to={routes.users.districtReps.details.url(dr.id)}>{dr.prefixedNumber}</Link>
                          </TableCell>
                          {hasViewDistrictDetailsPermission ? (
                            <>
                              <TableCell>
                                <Link to={routes.users.districtReps.details.url(dr.id)}>{dr.firstName}</Link>
                              </TableCell>
                              <TableCell>
                                <Link to={routes.users.districtReps.details.url(dr.id)}>{dr.lastName}</Link>
                              </TableCell>
                            </>
                          ) : null}
                          <TableCell>
                            <Tooltip title={dr.email}>
                              <IconButton
                                sx={{ width: '14px', height: '14px', ml: 0, p: 1.5 }}
                                onClick={dr.email ? handleCopyToClipboard(dr.email) : () => null}
                              >
                                <MailOutline
                                  sx={{
                                    cursor: 'pointer',
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip title={dr.phoneNumber}>
                              <IconButton
                                sx={{ width: '14px', height: '14px', ml: 0, p: 1.5 }}
                                onClick={dr.email ? handleCopyToClipboard(dr.phoneNumber) : () => null}
                              >
                                <PhoneOutlined sx={{ marginLeft: '4px', marginRight: '4px', cursor: 'pointer' }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell>{dr.enrolments}</TableCell>
                          <TableCell>{<StatusChip status={dr.status} />}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        colSpan={7}
                        rowsPerPageOptions={rowsPerPageOptions}
                        count={districtRepresentatives.length}
                        rowsPerPage={rows}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DistrictRepresentativesTable;
