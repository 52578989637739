import { useMemo } from 'react';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { DistrictSummary } from '@hoot/hooks/api/hfs/useGetDistricts';
import { FormSelect, FormSelectOption, SelectProps } from './FormSelect';

export function DistrictSelect<T extends FieldValues, TObj extends Path<T>>(
  props: SelectProps & UseControllerProps<T, TObj> & { districts?: DistrictSummary[] },
) {
  const options = useMemo<FormSelectOption[]>(() => {
    return (
      props.districts
        ?.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        })
        .map((p) => {
          return {
            label: p.name,
            value: p.id,
          };
        }) ?? []
    );
  }, [props.districts]);

  return <FormSelect label="District" labelId="district-label" options={options} {...props} />;
}
