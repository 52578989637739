import { DateTime } from 'luxon';
import { DayOfWeek } from '@hoot/utils/dateTime';
import { TentativeBlockForm } from '../teacher-conflicts-step/TeacherConflictCheckStep';

type Occurrences = {
  occurrences: number;
  endDate?: never;
};

type EndsAt = {
  occurrences?: never;
  endDate: DateTime;
};

export type BlockTimeByDayOfWeek = {
  dayOfWeek: DayOfWeek;
  startTime: string;
  endTime: string;
};

export type GenerateBlock = {
  startsAt: DateTime;
  endsAt: DateTime;
  blockTimeByDayOfWeek: BlockTimeByDayOfWeek[];
} & (Occurrences | EndsAt);

export function generateBlockTimes(params: GenerateBlock): TentativeBlockForm[] {
  let offset = 0;
  const results = [];
  const now = DateTime.now();
  const yearFromNow = params.startsAt.plus({ year: 1 });

  loop1: for (;;) {
    const week = params.startsAt.plus({ week: offset }).startOf('day');

    // break if current week is greater than or equal to a year from now
    if (week >= yearFromNow) {
      break;
    }

    // if ends at is set, check to see if current week is less than or equal to ends at
    for (const blockTime of params.blockTimeByDayOfWeek) {
      if (blockTime) {
        const startTimeUnits = blockTime.startTime.split(':');
        const endTimeUnits = blockTime.endTime.split(':');

        const startHour = parseInt(startTimeUnits[0]);
        const startMinute = parseInt(startTimeUnits[1]);

        const endHour = parseInt(endTimeUnits[0]);
        const endMinute = parseInt(endTimeUnits[1]);

        const blockStartDate = week.set({
          weekday: blockTime.dayOfWeek,
          hour: startHour,
          minute: startMinute,
          second: 0,
          millisecond: 0,
        });

        const blockEndDate = week.set({
          weekday: blockTime.dayOfWeek,
          hour: endHour,
          minute: endMinute,
          second: 0,
          millisecond: 0,
        });

        // if using endDate to determine when blocks should be created up to
        if (params.endDate && blockStartDate >= params.endDate) {
          break;
        }

        if (blockStartDate > now && blockStartDate >= params.startsAt) {
          results.push({ startsAt: blockStartDate, endsAt: blockEndDate, skip: false });

          // if using occurrences to determine when blocks should be created up to
          if (params.occurrences && results.length >= params.occurrences) {
            break loop1;
          }
        }
      }
    }
    offset += 1;
  }
  return results;
}
