import { CancelOutlined, Close, Search } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import EnrolmentCheckboxTable from '../EnrolmentCheckBoxTable';
import SelectedFilterChips from '../SelectedFilterChips';
import FilterSelects, { IFilter, IFilterSelection } from '../form/searchFilters/FilterSelects';
import { IEnrolmentCheckBoxRow } from '../hfs-interfaces';
import { enrolmentStatusMapping } from '../hfs-utils';

const FILTERS_DATA: IFilter[] = [
  {
    label: 'Status',
    options: Object.values(enrolmentStatusMapping).map((x) => ({
      label: x,
    })),
  },
];

export const FILTERS_DATA_WITH_SELECTION: IFilterSelection[] = FILTERS_DATA.map((fl: IFilter) => ({
  ...fl,
  values: fl.options.map((o) => ({ ...o, isSelected: false })),
}));

export const transformFiltersData = (filtersData: IFilterSelection[]): string[] =>
  filtersData
    .flatMap((fl) => fl.values.filter((v) => v.isSelected).map((v) => ({ name: fl.label, value: v.label })))
    .map((v) => `${v.name}: ${v.value}`);

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onApply: (selectedRows: IEnrolmentCheckBoxRow[]) => void;
  onClose?: () => void;
  districtRepAccountId?: string;
  studentProfileId?: string;
  existingEnrolmentIds?: string[];
  locationId?: string;
}
const AddEnrolmentModal = (props: Props) => {
  const { open, setOpen, onApply, districtRepAccountId, studentProfileId, existingEnrolmentIds, locationId } = props;
  const [searchText, setSearchText] = useDebounce();
  const inputRef = useRef<HTMLInputElement>();
  const [filtersData, setFiltersData] = useState<IFilterSelection[]>(FILTERS_DATA_WITH_SELECTION);
  const selectedValues = transformFiltersData(filtersData);
  const [activeFilters, setActiveFilters] = useState<string[]>(selectedValues);
  const [selectedRows, setSelectedRows] = useState<IEnrolmentCheckBoxRow[]>([] as IEnrolmentCheckBoxRow[]);

  useEffect(() => {
    const selectedValues = transformFiltersData(filtersData);
    setActiveFilters(selectedValues);
  }, [filtersData]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleClear = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
      setSearchText('');
    }
  };

  const handleClose = () => {
    setFiltersData((current) =>
      current.map((fl) => ({
        ...fl,
        values: fl.values.map((v) => ({ ...v, isSelected: false })),
      })),
    );
    setActiveFilters([]);
    setSelectedRows([]);
    handleClear();
    setOpen(false);
  };

  const onApplyClick = (selectedRows: IEnrolmentCheckBoxRow[]) => {
    onApply(selectedRows);
    setSelectedRows([]);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          gap: '24px',
          flexDirection: 'column',
          p: 3,
          minWidth: '800px',
          height: '800px',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
            }}
          >
            <Box sx={{ textAlign: 'left', fontSize: '24px' }}>Add Enrolment</Box>
            <Close sx={{ '&:hover': { cursor: 'pointer' }, mr: '22px' }} onClick={handleClose} />
          </Box>
          <TextField
            defaultValue={searchText}
            onChange={handleInputChange}
            inputRef={inputRef}
            variant={'outlined'}
            label="Search for an enrolment"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ fill: '#49454F' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClear} disabled={!searchText}>
                    <CancelOutlined style={{ fill: '#49454F' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              textTransform: 'none',
              display: 'flex',
            }}
          >
            <FilterSelects filterSelections={filtersData} setFilterSelections={setFiltersData} />
          </Box>

          <Box
            sx={{
              alignSelf: 'center',
              display: 'flex',
              color: '#49454F',
              width: '100%',
            }}
          >
            <SelectedFilterChips
              activeFilters={activeFilters}
              selectedValues={selectedValues}
              filtersData={filtersData}
              setActiveFilters={setActiveFilters}
              setFiltersData={setFiltersData}
            />
          </Box>
          <EnrolmentCheckboxTable
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            searchText={searchText}
            activeFilters={activeFilters}
            districtRepAccountId={districtRepAccountId}
            studentProfileId={studentProfileId}
            existingEnrolmentIds={existingEnrolmentIds}
            locationId={locationId}
          />
        </Box>
        <Box
          sx={{
            textAlign: 'right',
            p: 1,
            pt: 3,
            pb: 3,
            gap: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            mt: '24px',
            position: 'sticky',
            bottom: '0',
            backgroundColor: 'rgb(255,255,255, 0.8)',
            borderTop: '1px solid #757575',
            backdropFilter: 'blur(2px)',
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              padding: '0px',
              gap: '8px',
              p: 1,
              px: 3,
              textTransform: 'none',
              backgroundColor: 'white',
              border: '1px solid #79747E',
              borderRadius: '20px',
              fontWeight: '600',
              color: 'black',
              '&:hover': {
                backgroundColor: '#f2f2f2',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={selectedRows.length === 0}
            onClick={() => onApplyClick(selectedRows)}
            sx={{
              padding: '0px',
              gap: '8px',
              p: 1,
              px: 3,
              color: 'white',
              textTransform: 'none',
              background: '#1976D2',
              ':disabled': {
                backgroundColor: 'gray',
                color: 'white',
              },
              borderRadius: '100px',
              fontWeight: '600',
              '&:hover': {
                backgroundColor: '#1976D2',
              },
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddEnrolmentModal;
