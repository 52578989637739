import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';
import { UserImportResponse } from './useUserImportCsv';

const getImport = async (importId: string): Promise<UserImportResponse> => {
  const { data } = await axios.get<UserImportResponse>(`${config.apiUrl}/admin/user-import/${importId}`);
  return data;
};

export default function useGetImport(importId: string, options?: Omit<UseQueryOptions<UserImportResponse, AxiosError>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetUserImport, importId], () => getImport(importId), options);
}
