import { Card, CardContent, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import ContractIcon from '@hoot/components/icons/ContractIcon';
import { ProductDetailsResponse } from '@hoot/hooks/api/hfs/district-schools/products/useGetProduct';
import { UpdateProductForm } from '@hoot/pages/district-schools/products/ProductPage';

interface Props {
  product: ProductDetailsResponse;
  control: Control<UpdateProductForm>;
}

const ProductCapacity = (props: Props) => {
  const { product, control } = props;

  return (
    <Grid item container mt={2} xs={12} spacing={2}>
      <Grid item container xs={12} md={6} alignContent="flex-start">
        <Card
          sx={{
            width: 1,
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Grid item container xs={12} spacing={3}>
              <Grid item container xs={12}>
                <Typography align="center" variant="titleLarge">
                  Contract Size
                </Typography>
              </Grid>

              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="numberOfLessons"
                    rules={{
                      required: `Number of lessons is required`,
                    }}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        helperText={fieldState.error?.message}
                        error={!!fieldState.error}
                        fullWidth
                        label={`Number of Lessons`}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name="numberOfLessonsPerSet"
                    rules={{
                      required: `Number of lessons per set is required`,
                    }}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        helperText={fieldState.error?.message}
                        error={!!fieldState.error}
                        fullWidth
                        label={`Lessons per Set`}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Stack spacing={2} p={3} width="100%">
                <Stack direction="row">
                  <Typography align="center" variant="bodySmall">
                    Calculated
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <ContractIcon />
                  <Typography align="center" variant="bodyLarge" pl={2}>
                    {product.numberOfAllowedLessonsSets} - Lesson Sets (Allowed)
                  </Typography>
                </Stack>
                <Divider />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <ContractIcon />
                  <Typography align="center" variant="bodyLarge" pl={2}>
                    {product.numberOfRegisteredLessonsSets} - Lesson Sets (Registered)
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack direction="column" width="100%">
          <Card
            sx={{
              width: 1,
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Typography variant="titleLarge">Utilization</Typography>
              <Stack spacing={2}>
                <Stack direction="row" pt={2}>
                  <Typography align="center" variant="bodySmall">
                    Calculated
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <ContractIcon />
                  <Typography align="center" variant="bodyLarge">
                    {product.numberOfScheduledLessonsSets} - Scheduled Lesson Sets
                  </Typography>
                </Stack>
                <Divider />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <ContractIcon />
                  <Typography align="center" variant="bodyLarge">
                    {product.numberOfScheduledLessons} - Scheduled Lessons
                  </Typography>
                </Stack>
                <Divider />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <ContractIcon />
                  <Typography align="center" variant="bodyLarge">
                    {product.numberOfOccurredLessons} - Occurred Lessons
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ProductCapacity;
