import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';

export enum TeacherReliabilityScoreImportStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED_WITH_ERRORS = 'COMPLETED_WITH_ERRORS',
  COMPLETED_SUCCESSFULLY = 'COMPLETED_SUCCESSFULLY',
}

export interface TeacherReliabilityScoreImportResponse {
  id: string;
  status: TeacherReliabilityScoreImportStatus;
  savedRows?: number;
  erroredRows?: number;
}

const importCSV = async (formData: FormData): Promise<TeacherReliabilityScoreImportResponse> => {
  const { data } = await axios.post<TeacherReliabilityScoreImportResponse>(`${config.apiUrl}/admin/teacher-reliability-score-import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};

export default function useTRSImportCsv(
  options?: Omit<UseMutationOptions<TeacherReliabilityScoreImportResponse, AxiosError<ErrorResponseDto>, FormData>, 'mutationFn' | 'mutationKey'>,
) {
  return useMutation((data) => importCSV(data), options);
}
