import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface GenreResponse {
  id: string;
  name: string;
}

async function getGenres(): Promise<GenreResponse[]> {
  const { data } = await axios.get<GenreResponse[]>(`${config.apiUrl}/admin/library/genres`);
  return data;
}

export default function useGetGenres(options?: Omit<UseQueryOptions<GenreResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetGenres], () => getGenres(), options);
}
