import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';

async function softDeleteUser(userId: string): Promise<void> {
  await axios.delete(`${config.apiUrl}/admin/user/${userId}`);
}

export default function useSoftDeleteUser(options?: Omit<UseMutationOptions<void, AxiosError, string>, 'mutationKey' | 'mutationFn'>) {
  return useMutation<void, AxiosError, string>((userId) => softDeleteUser(userId), options);
}
