import { UserImportType } from '@hoot-reading/hoot-core/dist/enums/user/user-import/user-import-type.enum';
import { Button, DialogActions, DialogContent, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import React from 'react';
import { ImportUserStep } from './UserImportDialog';

interface IUserImportTypeSelectionProps {
  nextStep: (step: ImportUserStep) => void;
  handleClose: () => void;
  importType: UserImportType | undefined;
  setImportType: React.Dispatch<React.SetStateAction<UserImportType | undefined>>;
}

const UserImportTypeSelection = (props: IUserImportTypeSelectionProps) => {
  const { nextStep, handleClose, importType, setImportType } = props;

  return (
    <>
      <DialogContent sx={{ p: 0, mt: 2 }}>
        <Typography variant="titleMedium" sx={{ color: '#000' }}>
          Step 1 - Type Selection
        </Typography>
        <Stack sx={{ pt: 3, pl: 2 }}>
          <FormControl>
            <RadioGroup onChange={(_, value) => setImportType(value as UserImportType)}>
              <FormControlLabel value={UserImportType.StudentImport} control={<Radio />} label="Student Import Template" />
            </RadioGroup>
          </FormControl>
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ p: 0 }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack direction="row"></Stack>

          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button onClick={handleClose} color="inherit" variant="outlined" size="large">
              Close
            </Button>
            <Button disabled={!importType} onClick={() => nextStep(ImportUserStep.UploadFile)} variant="contained" size="large">
              Next
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  );
};

export default UserImportTypeSelection;
