export enum DataImportType {
  TRS = 'TEACHER_RELIABILITY_SCORE',
}

export const dataImportTypeList: { value: DataImportType; label: string }[] = [{ value: DataImportType.TRS, label: 'Teacher Reliability Score' }];

export const dataImportTypeLabels = dataImportTypeList.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as Record<DataImportType, string>;
