import { Button, CircularProgress, Grid, Stack } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import useCreateTeacherRecurringShift, { TeacherRecurringShiftRequest } from '@hoot/hooks/api/user/teacher/useCreateTeacherRecurringShift';
import useCreateTeacherShift, { TeacherShiftRequest } from '@hoot/hooks/api/user/teacher/useCreateTeacherShift';
import { RequestedShiftForConflictChecker } from '@hoot/hooks/api/user/teacher/useGetTeacherShiftConflicts';
import { routes } from '@hoot/routes/routes';
import CreateShiftFooter from '../CreateShiftFooter';
import { useCreateShiftContext } from '../context/CreateShiftContextProvider';
import ReviewTeacherShiftCard from './ReviewTeacherShiftCard';

const CreateShiftReviewStep = () => {
  const { goToPrevStep, shiftDetails, closeShiftCreationModal } = useCreateShiftContext();
  const queryClient = useQueryClient();

  const { setIsDirty } = useBlockIfDirty();

  const navigate = useNavigate();
  const { error, success } = useAlert();

  const createTeacherShift = useCreateTeacherShift({
    onSuccess: () => {
      success('Successfully created shift.');
      queryClient.invalidateQueries([QueryKey.GetTeacherShifts]);
      if (!shiftDetails.preSelectedTeacher) {
        navigate(routes.users.teachers.details.url(shiftDetails!.teacher!.id));
      }
      closeShiftCreationModal();
    },
    onError: (err) => {
      console.error(err);

      // Show error message from API if exists.
      const errorMessage = `An unknown error occurred while creating shift(s).`;
      error(errorMessage);
    },
  });

  const createTeacherRecurringShift = useCreateTeacherRecurringShift({
    onSuccess: () => {
      success('Successfully created shifts.');
      queryClient.invalidateQueries([QueryKey.GetTeacherShifts]);
      if (!shiftDetails.preSelectedTeacher) {
        navigate(routes.users.teachers.details.url(shiftDetails!.teacher!.id));
      }
      closeShiftCreationModal();
    },
    onError: (err) => {
      console.error(err);

      // Show error message from API if exists.
      const errorMessage = `An unknown error occurred while creating recurring shift(s).`;
      error(errorMessage);
    },
  });

  const onSubmitShifts = () => {
    const { teacher, tentativeShifts, recurring, shiftType, shiftStartTime, shiftEndTime, recurrenceEnd } = shiftDetails!;

    const selectedShifts = tentativeShifts?.filter((shift) => !shift.skip) ?? [];

    if (teacher && selectedShifts.length > 0) {
      setIsDirty(false);
      if (recurring) {
        const request: TeacherRecurringShiftRequest = {
          teacherAccountId: teacher.id,
          startsAt: shiftStartTime.toMillis(),
          endsAt: shiftEndTime.toMillis(),
          type: shiftType,
          recurrenceRules: recurrenceEnd,
          requestedShifts: selectedShifts.map<RequestedShiftForConflictChecker>((shift) => ({
            startsAt: shift.startsAt.toMillis(),
            endsAt: shift.endsAt.toMillis(),
          })),
        };
        createTeacherRecurringShift.mutate(request);
      } else {
        const shift = selectedShifts[0];
        const request: TeacherShiftRequest = {
          teacherAccountId: teacher.id,
          startsAt: shift.startsAt.toMillis(),
          endsAt: shift.endsAt.toMillis(),
          type: shiftType,
        };
        createTeacherShift.mutate(request);
      }
    }
  };

  return (
    <>
      <Stack>
        <Grid container spacing={3}>
          {!!shiftDetails?.tentativeShifts?.length ? (
            <Grid item sm={12} md={6}>
              <ReviewTeacherShiftCard teacher={shiftDetails.teacher!} shifts={shiftDetails?.tentativeShifts} />
            </Grid>
          ) : null}
        </Grid>
        <CreateShiftFooter>
          <Button variant="contained" size="large" onClick={goToPrevStep}>
            Previous Step
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={onSubmitShifts}
            disabled={createTeacherShift.isLoading || createTeacherRecurringShift.isLoading}
          >
            {createTeacherShift.isLoading || createTeacherRecurringShift.isLoading ? <CircularProgress color="inherit" size={24} /> : 'Complete'}
          </Button>
        </CreateShiftFooter>
      </Stack>
    </>
  );
};

export default CreateShiftReviewStep;
