import { Button, Card, CircularProgress, Grid, Paper, Typography, styled } from '@mui/material';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useAlert } from '@hoot/contexts/AlertContext';
import {
  CreateTeacherAvailabilityRequest,
  Timeblock,
  useCreateTeacherAvailability,
  useGetTeacherAvailability,
} from '@hoot/hooks/api/availability/useAvailability';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { DayOfWeek } from '@hoot/utils/dateTime';
import useGetTeacherAccount, { TeacherAccount } from '../../../hooks/api/user/teacher/useGetTeacherAccount';
import { AVAILABLE_COLOR } from '../constants';
import { timeOptions } from '../enums';
import TimeSelection, { TimeBlock } from './TimeSelection';

interface Form {
  Mon: TimeBlock[];
  Tue: TimeBlock[];
  Wed: TimeBlock[];
  Thu: TimeBlock[];
  Fri: TimeBlock[];
  Sat: TimeBlock[];
  Sun: TimeBlock[];
}

type Weekday = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

const weekdays: { label: Weekday; value: number }[] = [
  { label: 'Mon', value: 1 },
  { label: 'Tue', value: 2 },
  { label: 'Wed', value: 3 },
  { label: 'Thu', value: 4 },
  { label: 'Fri', value: 5 },
  { label: 'Sat', value: 6 },
  { label: 'Sun', value: 7 },
];

const height = 18;

const Weekdays = styled('div')({
  display: 'grid',
  gridTemplateColumns: '60px repeat(7, 1fr)',
  columnGap: 8,
  userSelect: 'none',
});

const YAxisLabel = styled('div')({});

const WeekdaysHeader = styled('div')({
  fontWeight: 'bold',
  textAlign: 'center',
});

const ColumnLabel = styled('div')({
  fontSize: '12px',
  fontWeight: 'bold',
  height: height + 2,
});

const AvailabilityHeader = (props: { teacherAccount: TeacherAccount }) => {
  const { teacherAccount } = props;

  return (
    <Grid container mb={2}>
      <Grid item xs={4}>
        <Typography component="h3" variant="titleLarge">
          {teacherAccount.displayName} Availability
        </Typography>
        <Typography component="h4" variant="titleSmall">
          Timezone: {teacherAccount.user.timeZone}
        </Typography>
      </Grid>
    </Grid>
  );
};

const AvailabilityFooter = () => {
  return (
    <Grid container justifyContent="center" mt={2}>
      <Button type="submit" color="primary" variant="contained">
        Save Changes
      </Button>
    </Grid>
  );
};

const Availability = (props: { teacherAccount: TeacherAccount; availability: Timeblock[]; setFormIsDirty: Dispatch<SetStateAction<boolean>> }) => {
  const { availability, teacherAccount, setFormIsDirty } = props;
  const { success } = useAlert();

  const getFormValues = useCallback(
    () => ({
      Mon: availability.filter((tb) => tb.dayOfWeek === DayOfWeek.Monday),
      Tue: availability.filter((tb) => tb.dayOfWeek === DayOfWeek.Tuesday),
      Wed: availability.filter((tb) => tb.dayOfWeek === DayOfWeek.Wednesday),
      Thu: availability.filter((tb) => tb.dayOfWeek === DayOfWeek.Thursday),
      Fri: availability.filter((tb) => tb.dayOfWeek === DayOfWeek.Friday),
      Sat: availability.filter((tb) => tb.dayOfWeek === DayOfWeek.Saturday),
      Sun: availability.filter((tb) => tb.dayOfWeek === DayOfWeek.Sunday),
    }),
    [availability],
  );

  const { control, handleSubmit, formState, reset } = useForm<Form>({
    defaultValues: getFormValues(),
    mode: 'onSubmit',
  });

  const resetForm = useCallback(() => {
    if (availability) {
      reset(getFormValues());
    }
  }, [getFormValues, reset, availability]);

  useEffect(() => {
    resetForm();
  }, [resetForm, availability]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState, setFormIsDirty]);

  const queryClient = useQueryClient();
  const teacherAvailabilityMutation = useCreateTeacherAvailability({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.TeacherAvailability, {
        queryKey: teacherAccount.id,
      });
      success('Teacher availability has been saved');
    },
  });

  const onSubmit: SubmitHandler<Form> = (data) => {
    if (!teacherAccount || !teacherAccount.user) {
      throw new Error('No teacher account or user account');
    }

    const timeblocks = Object.entries(data).flatMap(([k, v]) => {
      return v
        .filter((x: TimeBlock) => !x.isDeleted)
        .map((x: TimeBlock) => {
          return {
            dayOfWeek: weekdays.find((w) => w.label === k)?.value || 0,
            startTime: x.startTime,
            endTime: x.endTime,
          };
        });
    });

    const request: CreateTeacherAvailabilityRequest = {
      teacherAccountId: teacherAccount.id,
      timeblocks,
    };

    teacherAvailabilityMutation.mutate(request);
  };

  if (!teacherAccount) {
    return <CircularProgress />;
  }

  return (
    <Card
      sx={{
        display: 'flex',
        flexFlow: 'column',
        width: '100%',
        padding: 0,
        mt: '24px',
        p: '24px',
      }}
    >
      <Grid item xs={12}>
        <AvailabilityHeader teacherAccount={teacherAccount} />
      </Grid>
      <Paper
        sx={{
          flex: '1 1 auto',
          padding: (theme) => theme.spacing(3),
          display: 'flex',
          flexFlow: 'column',
          width: '75%',
          mx: 'auto',
          mt: '24px',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              <Weekdays>
                <div />
                {weekdays.map((w) => (
                  <WeekdaysHeader key={w.value}>{w.label}</WeekdaysHeader>
                ))}

                <YAxisLabel>
                  {timeOptions.map((to, idx) => (idx % 2 === 0 ? <ColumnLabel key={to}>{to}</ColumnLabel> : <ColumnLabel key={to} />))}
                </YAxisLabel>

                {weekdays.map((w) => (
                  <Controller
                    key={w.value}
                    name={w.label}
                    control={control}
                    render={({ field }) => {
                      return <TimeSelection manual={false} value={field.value} onChange={field.onChange} selectionColor={AVAILABLE_COLOR} />;
                    }}
                  />
                ))}
              </Weekdays>
            </Grid>
            <Grid item xs={12}>
              <AvailabilityFooter />
            </Grid>
          </Grid>
          {/* <Prompt message="Are you sure you wish to leave the page before saving your changes?" when={formState.isDirty} /> */}
        </form>
      </Paper>
    </Card>
  );
};

const AvailabilityWrapper = (props: { teacherAccountId: string; setFormIsDirty: Dispatch<SetStateAction<boolean>> }) => {
  const { teacherAccountId, setFormIsDirty } = props;
  const [teacherAccount, setTeacherAccount] = useState<TeacherAccount>();
  const [availability, setAvailability] = useState<Timeblock[]>();

  useGetTeacherAccount(teacherAccountId, {
    onSuccess: (response) => {
      setTeacherAccount(response);
    },
  });

  useGetTeacherAvailability(teacherAccountId, {
    onSuccess: (response) => {
      if (!!response) {
        setAvailability(response.timeblocks);
      } else {
        setAvailability([]);
      }
    },
  });

  if (!teacherAccount) {
    return <CircularProgress />;
  }

  if (!availability) {
    return <CircularProgress />;
  }

  return <Availability teacherAccount={teacherAccount} availability={availability} setFormIsDirty={setFormIsDirty} />;
};

export default AvailabilityWrapper;
