import { UserImportMode } from '@hoot-reading/hoot-core/dist/enums/user/user-import/user-import-mode.enum';
import { UserImportStatus } from '@hoot-reading/hoot-core/dist/enums/user/user-import/user-import-status.enum';
import { UserImportType } from '@hoot-reading/hoot-core/dist/enums/user/user-import/user-import-type.enum';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';

export interface UserImportResponse {
  id: string;
  status: UserImportStatus;
  type: UserImportType;
  mode: UserImportMode | null;
  userCounts?: UserImportCounts;
}

export interface UserImportCounts {
  created: number;
  updated: number;
  errored: number;
  ignored: number;
  createdWithoutLessonSet: number;
  updatedWithoutLessonSet: number;
}

const importCSV = async (formData: FormData): Promise<UserImportResponse> => {
  const type = formData.get('importType');
  const enrolmentId = formData.get('enrolmentId');

  const { data } = await axios.post<UserImportResponse>(`${config.apiUrl}/admin/user-import?enrolmentId=${enrolmentId}&type=${type}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};

export default function useUserImportCsv(
  options?: Omit<UseMutationOptions<UserImportResponse, AxiosError<ErrorResponseDto>, FormData>, 'mutationFn' | 'mutationKey'>,
) {
  return useMutation((data) => importCSV(data), options);
}
