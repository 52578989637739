import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

const CreateShiftFooter = (props: PropsWithChildren<any>) => {
  const { children } = props;
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.1)', paddingTop: 3, marginTop: 3 }}>
      {children}
    </Stack>
  );
};

export default CreateShiftFooter;
