import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

async function softDeleteTeacherInvoiceTaskCsvLine(taskCsvLineId: string): Promise<void> {
  await axios.delete(`${config.apiUrl}/admin/invoicing-import/task-csv-line/${taskCsvLineId}`);
}

export default function useSoftDeleteTeacherInvoiceTaskCsvLine(
  options?: Omit<UseMutationOptions<void, AxiosError, string>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation<void, AxiosError, string>((taskCsvLineId) => softDeleteTeacherInvoiceTaskCsvLine(taskCsvLineId), options);
}
