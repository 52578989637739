import { CircularProgress, Stack } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import useGetStudentProfile from '../../../../hooks/api/end-users/useGetStudentProfile';
import { routes } from '../../../../routes/routes';
import LessonNotesLegacyCard from './LessonNotesLegacyCard';
import LessonReviewsCard from './LessonReviewsCard';

const StudentReviewsTab = () => {
  const { studentProfileId } = useParams();

  const studentQuery = useGetStudentProfile(studentProfileId!, { enabled: !!studentProfileId });

  if (!studentQuery.data) {
    if (studentQuery.isFetched) {
      return <Navigate to={routes.home.url} />;
    }
    return <CircularProgress />;
  }

  return (
    <Stack gap={2}>
      <LessonReviewsCard studentProfileId={studentProfileId!} />
      <LessonNotesLegacyCard studentProfileId={studentProfileId!} />
    </Stack>
  );
};

export default StudentReviewsTab;
