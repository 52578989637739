import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { ScheduledLessonLanguage, ScheduledLessonSubject } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { BackgroundCheck } from '@hoot/interfaces/teacher';
import { ScheduledLessonSetStatus } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';

type LocationResponse = {
  id: string;
  name: string;
  friendlyId: string;
  type: LocationType;
};

export interface LessonSetResponse {
  id: string;
  lastUpdatedAt: number;
  number: number;
  prefixedNumber: string;
  status: ScheduledLessonSetStatus;
  duration: number;
  subject: ScheduledLessonSubject;
  language: ScheduledLessonLanguage;
  startDate: string;
  endDate: string;

  student: {
    id: string;
    name: string;
    prefixedNumber: string;
    timeZone: string;
  };

  enrolment: {
    id: string;
    friendlyId: string;
    locationId: string;
    locationName: string;
    status: EnrolmentStatus;
    product: {
      hootQualificationRequirements?: HootQualification[];
      countryRequirements?: HootSupportedCountry;
      backgroundCheckRequirements?: BackgroundCheck[];
    };
  };

  locations: LocationResponse[];
}

async function getLessonSet(lessonSetId: string) {
  const { data } = await axios.get<LessonSetResponse>(`${config.apiUrl}/admin/lesson-set/${lessonSetId}`);
  return data;
}

export function useGetLessonSet(lessonSetId: string, options?: Omit<UseQueryOptions<LessonSetResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.LessonSet, lessonSetId], () => getLessonSet(lessonSetId), options);
}
