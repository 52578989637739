import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface EnrolmentPeriodResponse {
  id: string;
  type: string;
  startDate: number;
  endDate: number;
}

const getEnrolmentPeriods = async (enrolmentId: string): Promise<EnrolmentPeriodResponse[]> => {
  const { data } = await axios.get<EnrolmentPeriodResponse[]>(`${config.apiUrl}/admin/hfs/enrolments/${enrolmentId}/periods`);
  return data;
};

export default function useGetEnrolmentPeriods(
  enrolmentId: string,
  options?: Omit<UseQueryOptions<EnrolmentPeriodResponse[], AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetEnrolmentPeriods, enrolmentId], () => getEnrolmentPeriods(enrolmentId), options);
}
