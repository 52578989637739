import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
  ActiveEnrolmentSchoolFilterDropDown,
  EnrolmentSchoolFilterDropDown,
} from '@hoot/components/form/filterDropDown/enrolment/EnrolmentSchoolFilter';
import { EnrolmentLocation } from '@hoot/hooks/api/hfs/useSearchLocationEnrolments';
import DateFilter, { ActiveDateFilter } from '../../../components/form/filterDropDown/common/DateFilter';
import {
  ActiveEnrolmentStatusFilterDropDown,
  EnrolmentStatusFilterDropDown,
} from '../../../components/form/filterDropDown/enrolment/EnrolmentStatusFilter';

export interface EnrolmentsFilters {
  status?: EnrolmentStatus[];
  startsAt?: string;
  endsAt?: string;
  school?: EnrolmentLocation[];
}

export const EnrolmentsFiltersContainer = (props: {
  value?: EnrolmentsFilters | null;
  onChange?: (filters: EnrolmentsFilters) => void;
  locationType: LocationType;
  locationId: string;
}) => {
  const { value, onChange, locationType, locationId } = props;

  const handleChange = (property: keyof EnrolmentsFilters) => (val: string | string[] | number) => {
    if (onChange) {
      if (value) {
        onChange({ ...value, [property]: val });
      } else {
        onChange({ [property]: val });
      }
    }
  };

  const handleSchoolChange = (property: keyof EnrolmentsFilters) => (val: EnrolmentLocation | EnrolmentLocation[]) => {
    if (onChange) {
      if (value) {
        onChange({ ...value, [property]: val });
      } else {
        onChange({ [property]: val });
      }
    }
  };

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item sx={{ marginRight: '8px' }}>
        <Typography variant="bodySmall">Filters</Typography>
      </Grid>

      <EnrolmentStatusFilterDropDown onChange={handleChange('status')} value={value?.status || []} popOverButtonSx={{ ml: 0, mr: '8px' }} />

      <DateFilter
        title="Starts At"
        onChange={(val) => handleChange('startsAt')(val.toFormat('dd/LL/yyyy'))}
        value={value?.startsAt ? DateTime.fromFormat(value.startsAt, 'dd/LL/yyyy') : undefined}
        popOverButtonSx={{ ml: 0, mr: '8px' }}
      />

      <DateFilter
        title="Ends At"
        onChange={(val) => handleChange('endsAt')(val.toFormat('dd/LL/yyyy'))}
        value={value?.endsAt ? DateTime.fromFormat(value.endsAt, 'dd/LL/yyyy') : undefined}
        popOverButtonSx={{ ml: 0, mr: '8px' }}
      />

      {locationType === LocationType.District ? (
        <EnrolmentSchoolFilterDropDown
          onChange={handleSchoolChange('school')}
          value={value?.school || []}
          locationId={locationType ? locationId : ''}
          popOverButtonSx={{ ml: 0, mr: '8px' }}
        />
      ) : null}
    </Grid>
  );
};

export const ActiveEnrolmentsFiltersContainer = (props: {
  value?: EnrolmentsFilters | null;
  onChange?: (filters: EnrolmentsFilters) => void;
  locationType: LocationType;
}) => {
  const { value, onChange, locationType } = props;

  const handleChange = (property: keyof EnrolmentsFilters) => (updatedValues: any | any[]) => {
    const updatedFilters = { ...value };
    updatedFilters[property] = updatedValues;
    if (onChange) {
      onChange(updatedFilters);
    }
  };

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item sx={{ marginRight: '8px' }}>
        <Typography variant="bodySmall">Active</Typography>
      </Grid>
      <Grid
        item
        sx={{
          '& > div': {
            marginRight: '8px',
          },
        }}
      >
        <ActiveEnrolmentStatusFilterDropDown value={value?.status} onChange={handleChange('status')} />
        <ActiveDateFilter label="Starts At" value={value?.startsAt} onChange={handleChange('startsAt')} />
        <ActiveDateFilter label="Ends At" value={value?.endsAt} onChange={handleChange('endsAt')} />
        {locationType === LocationType.District ? (
          <ActiveEnrolmentSchoolFilterDropDown value={value?.school} onChange={handleChange('school')} />
        ) : null}
      </Grid>
    </Grid>
  );
};
