import React from 'react';
import { Control, FormState, UseFormGetValues, UseFormHandleSubmit, UseFormReset, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import useHootEmployeeFiltersForm from '../hooks/useHootEmployeeFiltersForm';
import { HootEmployeeFiltersForm } from '../interfaces/hoot-employee-filters-form';

interface Values {
  control: Control<HootEmployeeFiltersForm>;
  reset: UseFormReset<HootEmployeeFiltersForm>;
  handleSubmit: UseFormHandleSubmit<HootEmployeeFiltersForm>;
  getValues: UseFormGetValues<HootEmployeeFiltersForm>;
  formState: FormState<HootEmployeeFiltersForm>;
  watch: UseFormWatch<HootEmployeeFiltersForm>;
  setValue: UseFormSetValue<HootEmployeeFiltersForm>;
}

const FilterHootEmployeesFormContext = React.createContext<Values>(undefined!);

interface Props {
  children: React.ReactNode;
}

export const FilterHootEmployeesFormProvider = (props: Props) => {
  const { children } = props;

  const { control, handleSubmit, reset, getValues, formState, watch, setValue } = useHootEmployeeFiltersForm();

  return (
    <FilterHootEmployeesFormContext.Provider
      value={{
        control,
        handleSubmit,
        reset,
        getValues,
        formState,
        watch,
        setValue,
      }}
    >
      {children}
    </FilterHootEmployeesFormContext.Provider>
  );
};

export const useFilterHootEmployeesForm = () => {
  const context = React.useContext(FilterHootEmployeesFormContext);

  if (context === undefined) {
    throw new Error('useFilterHootEmployeesForm must be used within a FilterHootEmployeesFormProvider');
  }

  return context;
};
