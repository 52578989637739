import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { Control } from 'react-hook-form';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { DatePicker } from '@hoot/components/form/DatePicker';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { TextField } from '@hoot/components/form/TextField';
import { StudentProfileResponse } from '@hoot/hooks/api/end-users/useGetStudentProfile';
import { studentGradeOptionsWithNull, studentProfileFocusChallengesWithNull } from '@hoot/interfaces/profile';
import { StudentDetailsForm } from '../form';

interface Props {
  control: Control<StudentDetailsForm>;
  watch: UseFormWatch<StudentDetailsForm>;
  studentProfile?: StudentProfileResponse;
  disableFields: boolean;
}

const StudentEducationalDetailsCard = (props: Props) => {
  const { control, watch, studentProfile, disableFields } = props;
  const { hootNotes, hootNotesExpireDate } = watch();
  const savedHootNotes = studentProfile?.hootNotes;
  const savedHootNotesExpireDate = studentProfile?.hootNotesExpireDate;
  const isHootNotesRequired = !!savedHootNotes || !!hootNotesExpireDate;
  const isHootNotesExpireDateRequired = !!savedHootNotesExpireDate || !!hootNotes;

  return (
    <Card sx={{ pl: 3, pt: 3, pr: 3 }}>
      <CardHeader
        sx={{ p: 0, pb: 3 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="titleLarge">
              Educational Details
            </Typography>
          </Stack>
        }
      />

      <CardContent sx={{ p: 0 }}>
        <Stack gap={3}>
          <Stack direction="row" gap={3}>
            <Dropdown
              name="grade"
              label="Grade"
              options={studentGradeOptionsWithNull}
              control={control}
              variant="outlined"
              disabled={disableFields}
            />
            <Dropdown
              name="focusChallenges"
              label="Has focus challenges"
              options={studentProfileFocusChallengesWithNull}
              control={control}
              variant="outlined"
              disabled={disableFields}
            />
          </Stack>
          <TextField
            name="parentNotes"
            label="Notes from parent(s)"
            control={control}
            variant="outlined"
            multiline
            rows={3}
            fullWidth
            disabled={disableFields}
          />
          <DatePicker
            name="hootNotesExpireDate"
            label="Hoot Note Expiration Date"
            control={control}
            variant="outlined"
            rules={{
              required: isHootNotesExpireDateRequired,
            }}
            disabled={disableFields}
          />
          <TextField
            name="hootNotes"
            label="Hoot Notes"
            control={control}
            variant="outlined"
            multiline
            rows={3}
            fullWidth
            rules={{
              required: isHootNotesRequired,
            }}
            disabled={disableFields}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default StudentEducationalDetailsCard;
