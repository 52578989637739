import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { StatusChipIcon, StatusChipIconColor } from '../../components/ui/StatusChip';

export const productStatusChipLookup: Record<
  ProductStatus,
  {
    statusIcon: StatusChipIcon;
    iconColor: StatusChipIconColor;
  }
> = {
  [ProductStatus.Active]: {
    iconColor: StatusChipIconColor.Green,
    statusIcon: StatusChipIcon.FullCircle,
  },
  [ProductStatus.Requested]: {
    iconColor: StatusChipIconColor.Blue,
    statusIcon: StatusChipIcon.PartialCircle,
  },
  [ProductStatus.Expired]: {
    iconColor: StatusChipIconColor.Red,
    statusIcon: StatusChipIcon.EmptyCircle,
  },
};

export const enrolmentStatusChipLookup: Record<
  EnrolmentStatus,
  {
    statusIcon: StatusChipIcon;
    iconColor: StatusChipIconColor;
  }
> = {
  [EnrolmentStatus.Open]: {
    iconColor: StatusChipIconColor.Green,
    statusIcon: StatusChipIcon.FullCircle,
  },
  [EnrolmentStatus.InProgress]: {
    iconColor: StatusChipIconColor.Blue,
    statusIcon: StatusChipIcon.PartialCircle,
  },
  [EnrolmentStatus.Scheduled]: {
    iconColor: StatusChipIconColor.Blue,
    statusIcon: StatusChipIcon.PartialCircle,
  },
  [EnrolmentStatus.Closed]: {
    iconColor: StatusChipIconColor.Red,
    statusIcon: StatusChipIcon.EmptyCircle,
  },
};

export const studentProfileStatusChipLookup: Record<
  StudentStatus,
  {
    statusIcon: StatusChipIcon;
    iconColor: StatusChipIconColor;
  }
> = {
  [StudentStatus.Active]: {
    iconColor: StatusChipIconColor.Green,
    statusIcon: StatusChipIcon.FullCircle,
  },
  [StudentStatus.Lead]: {
    iconColor: StatusChipIconColor.Blue,
    statusIcon: StatusChipIcon.FullCircle,
  },
  [StudentStatus.Cold]: {
    iconColor: StatusChipIconColor.Grey,
    statusIcon: StatusChipIcon.FullCircle,
  },
  [StudentStatus.Paused]: {
    iconColor: StatusChipIconColor.Orange,
    statusIcon: StatusChipIcon.FullCircle,
  },
  [StudentStatus.Inactive]: {
    iconColor: StatusChipIconColor.Red,
    statusIcon: StatusChipIcon.FullCircle,
  },
};
