import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';
import { HootLevelModuleStatus } from '../../reporting/hoot-levels/enum';

interface AssessmentMetricsResponse {
  assessmentCompletion: {
    assessmentLessons: number;
    startedAssessments: number;
    completedAssessments: number;
  };

  assessmentState: {
    preWordReading: HootLevelModuleStatus;
    earlyWordReading: HootLevelModuleStatus;
    complexWordReading: HootLevelModuleStatus;
    textReading: HootLevelModuleStatus;
  };

  inProgressUnits: {
    moduleName: string;
    units: {
      name: string;
      status: string;
    }[];
  }[];
}

async function getAssessmentMetrics(lessonSetId: string) {
  const { data } = await axios.get<AssessmentMetricsResponse>(`${config.apiUrl}/admin/lesson-set/${lessonSetId}/assessment-metrics`);
  return data;
}

export function useGetAssessmentMetrics(lessonSetId: string, options?: Omit<UseQueryOptions<AssessmentMetricsResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.LessonSetAssessmentMetrics, lessonSetId], () => getAssessmentMetrics(lessonSetId), options);
}
