import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import { Card, CardContent, Grid, Table, TableBody, TableCell, TableFooter, TablePagination, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { defaultRowsPerPage, rowsPerPageOptions } from '@hoot/common/constants';
import { hootSupportedCountryMapping } from '@hoot/components/hfs-utils';
import { DistrictSchool } from '@hoot/hooks/api/hfs/useGetDistrictSchool';
import CreateDistrictSchoolModal from '@hoot/pages/district-schools/details/CreateDistrictSchoolModal';
import DistrictSchoolsTableActionsButton from '@hoot/pages/district-schools/details/DistrictsSchoolsTableActionsButton';
import { routes } from '@hoot/routes/routes';
import SearchTextField from '../../../components/form/SearchTextField';
import { NoneListItem, SortableTableHeader, TableHeaderProp } from './Shared';

const headers: TableHeaderProp<DistrictSchool>[] = [
  { property: 'name', label: 'Name' },
  { property: 'friendlyId', label: 'Id' },
  { property: 'city', label: 'City' },
  { property: 'state', label: 'Province/State' },
  { property: 'country', label: 'Country' },
  { property: 'students', label: 'Students' },
  { property: 'timezone', label: 'Timezone' },
];

interface Props {
  districtId: string;
  districtCountry: string;
  districtProvinceState: string;
  isEditMode: boolean;
  districtSchools: DistrictSchool[];
}

export const DistrictsAndSchoolsTable = (props: Props) => {
  const { districtId, districtCountry, districtProvinceState, isEditMode, districtSchools } = props;

  const [filter, setFilter] = useState<string>('');
  const [sortBy, setSortBy] = useState<keyof DistrictSchool | null>('name');
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc' | null>('asc');
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(defaultRowsPerPage);
  const [showCreateDistrictSchoolModal, setShowCreateDistrictSchoolModal] = useState<boolean>(false);

  const handleCloseCreateDistrictSchoolModal = () => {
    setShowCreateDistrictSchoolModal(false);
  };

  const handleSearchChange = (val: string) => {
    setFilter(val);
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRows(parseInt(event.target.value, defaultRowsPerPage));
    setPage(0);
  };

  const filterFunc = (value: DistrictSchool): boolean => {
    return value.name.toLowerCase().includes(filter);
  };

  return (
    <Card
      sx={{
        width: 1,
      }}
    >
      <CardContent>
        <Grid container direction="column">
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item>
              <Typography align="center" variant="titleLarge">
                District Schools
              </Typography>
            </Grid>
            <Grid item>
              <DistrictSchoolsTableActionsButton setShowCreateDistrictSchool={setShowCreateDistrictSchoolModal} disabled={!isEditMode} />
              <CreateDistrictSchoolModal
                districtId={districtId}
                districtCountry={districtCountry}
                districtProvinceState={districtProvinceState}
                open={showCreateDistrictSchoolModal}
                onClose={handleCloseCreateDistrictSchoolModal}
              />
            </Grid>
          </Grid>

          {!districtSchools || districtSchools.length === 0 ? (
            <Grid sx={{ marginTop: '24px' }}>
              <NoneListItem />
            </Grid>
          ) : (
            <>
              <Grid sx={{ marginTop: '24px' }} container item xs={12}>
                <SearchTextField searchInput={filter} onSearchInputChanged={handleSearchChange} />
              </Grid>
              <Grid sx={{ marginTop: '24px' }} container item xs={12}>
                <Table sx={{ minWidth: 650 }} aria-label="schools and districts table">
                  <SortableTableHeader headers={headers} setSortBy={setSortBy} setSortOrder={setSortOrder} sortBy={sortBy} sortOrder={sortOrder} />

                  <TableBody>
                    {districtSchools
                      .filter(filterFunc)
                      .sort((a, b) => {
                        if (!sortBy) {
                          return 0;
                        } else if (typeof a[sortBy] === 'number' && typeof b[sortBy] === 'number') {
                          if (sortOrder === 'asc') {
                            return a[sortBy] < b[sortBy] ? 1 : -1;
                          } else {
                            return b[sortBy] < a[sortBy] ? 1 : -1;
                          }
                        } else if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
                          if (sortOrder === 'asc') {
                            return (a[sortBy] as string).localeCompare(b[sortBy] as string);
                          } else {
                            return (b[sortBy] as string).localeCompare(a[sortBy] as string);
                          }
                        }
                        return 0;
                      })
                      .slice(page * rows, page * rows + rows)
                      .map((s) => (
                        <TableRow key={`tr-${s.id}`} sx={{ cursor: 'pointer' }}>
                          <TableCell>
                            <Link to={routes.districtsSchools.details.url(s.id)}>{s.name}</Link>
                          </TableCell>
                          <TableCell>{s.friendlyId}</TableCell>
                          <TableCell>{s.city}</TableCell>
                          <TableCell>{s.state}</TableCell>
                          <TableCell>{hootSupportedCountryMapping[s.country as HootSupportedCountry]}</TableCell>
                          <TableCell>{s.students}</TableCell>
                          <TableCell>{s.timezone}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        colSpan={7}
                        rowsPerPageOptions={rowsPerPageOptions}
                        count={districtSchools.length}
                        rowsPerPage={rows}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DistrictsAndSchoolsTable;
