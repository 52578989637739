import { AgreementEndReason } from '@hoot-reading/hoot-core/dist/enums/user/teacher/agreement-end-reason.enum';
import { DiversitySelfIdentification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/diversity-self-identification.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { LanguageQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/language-qualification.enum';
import { OnLeaveReason } from '@hoot-reading/hoot-core/dist/enums/user/teacher/on-leave-reason.enum';
import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { TeacherAvailability } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-availability.enum';
import { TeacherCertificationArea } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-certification-area.enum';
import { TeacherDateType } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-date-type.enum';
import { TeacherSpecializedTraining } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-specialized-training.enum';
import { TeacherStage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-stage.enum';
import { TeacherStanding } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-standing.enum';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import { FormOption } from './form';

export interface TeacherAccount {
  id: string;
  endUserId: string;
  number: number;
  displayName: string;
  status: TeacherStatus;
  standing: TeacherStanding;
  hootQualifications: HootQualification[];
  bio: string | null;
  languageQualifications: LanguageQualification[];
  spokenLanguages: SpokenLanguage[];
  certificationAreas: TeacherCertificationArea[];
  specializedTraining: TeacherSpecializedTraining[];
  agreementDate: string | null;
  goLiveDate: string | null;
  diversitySelfIdentifications: DiversitySelfIdentification[];
  onLeaveReason: OnLeaveReason | '';
  agreementEndDate: string | null;
  agreementEndReason: AgreementEndReason | '';
  isEnabled: boolean;
  isDisabled: boolean;
  disabledReason: string;
}

export const teacherStatusOptions: FormOption<TeacherStatus>[] = [
  { value: TeacherStatus.Candidate, label: 'Candidate' },
  { value: TeacherStatus.Active, label: 'Active' },
  { value: TeacherStatus.Inactive, label: 'Inactive' },
];

export const teachingStageOptions: FormOption<TeacherStage>[] = [
  { value: TeacherStage.Interviewing, label: 'Interviewing' },
  { value: TeacherStage.OfferPending, label: 'Offer Pending' },
  { value: TeacherStage.NotHired, label: 'Not Hired' },
  { value: TeacherStage.Onboarding, label: 'Onboarding' },
  { value: TeacherStage.Live, label: 'Live' },
  { value: TeacherStage.OnLeave, label: 'On Leave' },
  { value: TeacherStage.PreDeparture, label: 'Pre-Departure' },
];

export const activeTeacherStatusOptions = teacherStatusOptions.filter((option) => option.value !== TeacherStatus.Inactive);

export const teacherStandingOptions: FormOption<TeacherStanding>[] = [
  { value: TeacherStanding.Good, label: 'Good' },
  { value: TeacherStanding.Neutral, label: 'Neutral' },
  { value: TeacherStanding.Low, label: 'Low' },
  { value: TeacherStanding.Bad, label: 'Bad' },
];

export const teacherAvailabilityOptions: FormOption<TeacherAvailability>[] = [
  { value: TeacherAvailability.Regular, label: 'Regular' },
  { value: TeacherAvailability.SubbingOnly, label: 'Subbing Only' },
  { value: TeacherAvailability.OnLeave, label: 'On Leave' },
];

export const hootQualificationOptions: FormOption<HootQualification>[] = [
  { value: HootQualification.ORIENTATION_AND_TRAINING, label: 'Orientation & Training' },
  { value: HootQualification.INTERVIEW, label: 'Interview' },
  { value: HootQualification.ANTI_OPPRESSION, label: 'Anti-Oppression' },
  { value: HootQualification.TRAUMA_INFORMED, label: 'Trauma-Informed' },
];

export const hootQualifications = hootQualificationOptions.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as Record<HootQualification, string>;

export enum BackgroundCheck {
  SterlingBackcheck = 'STERLING_BACKCHECK',
  GeneralVSSFBICheck = 'GENERAL_VSS_FBI_CHECK',
  SAUSDORICheck = 'SAUSD_ORI_CHECK',
  OUSDChecks = 'OUSD_CHECKS',
}

export const backgroundCheckOptions: FormOption<BackgroundCheck>[] = [
  { value: BackgroundCheck.SterlingBackcheck, label: 'Sterling Backcheck' },
  { value: BackgroundCheck.GeneralVSSFBICheck, label: 'General VSS / FBI Check' },
  { value: BackgroundCheck.SAUSDORICheck, label: 'SAUSD ORI Check' },
  { value: BackgroundCheck.OUSDChecks, label: 'OUSD Checks' },
];

export const backgroundCheckTypes = backgroundCheckOptions.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in BackgroundCheck]: string };

export const languageQualificationOptions: FormOption<LanguageQualification>[] = [
  { value: LanguageQualification.English, label: 'English' },
  { value: LanguageQualification.French, label: 'French' },
  { value: LanguageQualification.Spanish, label: 'Spanish' },
];

export const languageQualificationTypes = languageQualificationOptions.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in LanguageQualification]: string };

export const spokenLanguageOptions: FormOption<SpokenLanguage>[] = [
  { value: SpokenLanguage.English, label: 'English' },
  { value: SpokenLanguage.French, label: 'French' },
  { value: SpokenLanguage.Spanish, label: 'Spanish' },
  { value: SpokenLanguage.Cantonese, label: 'Cantonese' },
  { value: SpokenLanguage.Arabic, label: 'Arabic' },
  { value: SpokenLanguage.Urdu, label: 'Urdu' },
  { value: SpokenLanguage.Korean, label: 'Korean' },
];

export const spokenLanguageTypes = spokenLanguageOptions.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in SpokenLanguage]: string };

export const teacherSpecializedTrainingOptions: FormOption<TeacherSpecializedTraining>[] = [
  { value: TeacherSpecializedTraining.LexiaLetrsForEarlyChildhoodEducators, label: 'Lexia LETRS for Early Childhood Educators' },
  { value: TeacherSpecializedTraining.OrtonGillingham, label: 'Orton-Gillingham' },
  { value: TeacherSpecializedTraining.WilsonReadingSystem, label: 'Wilson Reading System®' },
  { value: TeacherSpecializedTraining.HeggertyPhonemicAwareness, label: 'Heggerty Phonemic Awareness' },
  { value: TeacherSpecializedTraining.TexasReadingAcademiesSTR, label: 'Texas Reading Academies: STR' },
  { value: TeacherSpecializedTraining.SpecialEducation1, label: 'Special Education: 1' },
  { value: TeacherSpecializedTraining.SpecialEducation1And2, label: 'Special Education: 1 & 2' },
  { value: TeacherSpecializedTraining.TeachingEnglishLanguageLearners1, label: 'Teaching English Language Learners: 1' },
  { value: TeacherSpecializedTraining.TeachingEnglishLanguageLearners1And2, label: 'Teaching English Language Learners: 1 & 2' },
];

export const teacherSpecializedTrainingTypes = teacherSpecializedTrainingOptions.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in TeacherSpecializedTraining]: string };

export const teacherCertificationAreasOptions: FormOption<TeacherCertificationArea>[] = [
  { value: TeacherCertificationArea.EarlyChildhood, label: 'Early Childhood' },
  { value: TeacherCertificationArea.SpecialEducation, label: 'Special Education' },
  { value: TeacherCertificationArea.EAL, label: 'EAL' },
  { value: TeacherCertificationArea.TESOL, label: 'TESOL' },
];

export const teacherCertificationAreasTypes = teacherCertificationAreasOptions.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in TeacherCertificationArea]: string };

export const diversitySelfIdentificationOptions: FormOption<DiversitySelfIdentification>[] = [
  { value: DiversitySelfIdentification.White, label: 'White' },
  { value: DiversitySelfIdentification.AfricanAmerican, label: 'African-American' },
  { value: DiversitySelfIdentification.AfricanCanadian, label: 'African-Canadian' },
  { value: DiversitySelfIdentification.Black, label: 'Black' },
  { value: DiversitySelfIdentification.Asian, label: 'Asian' },
  { value: DiversitySelfIdentification.PacificIslander, label: 'Pacific Islander' },
  { value: DiversitySelfIdentification.SouthAsian, label: 'South Asian' },
  { value: DiversitySelfIdentification.IndoAmerican, label: 'Indo-American' },
  { value: DiversitySelfIdentification.IndoCanadian, label: 'Indo-Canadian' },
  { value: DiversitySelfIdentification.Hispanic, label: 'Hispanic' },
  { value: DiversitySelfIdentification.Latino, label: 'Latino' },
  { value: DiversitySelfIdentification.NativeAmerican, label: 'Native American' },
  { value: DiversitySelfIdentification.Indigenous, label: 'Indigenous' },
  { value: DiversitySelfIdentification.Other, label: 'Other' },
  { value: DiversitySelfIdentification.PreferNotToAnswer, label: 'Prefer Not to Answer' },
];

export const onLeaveReasonOptions: FormOption<OnLeaveReason>[] = [
  { value: OnLeaveReason.Unavailable, label: 'Unavailable' },
  { value: OnLeaveReason.Unresponsive, label: 'Unresponsive' },
  { value: OnLeaveReason.TeacherSituation, label: 'Teacher Situation' },
  { value: OnLeaveReason.HootPreference, label: 'Hoot Preference' },
];

export const agreementEndReasonOptions: FormOption<AgreementEndReason>[] = [
  { value: AgreementEndReason.OtherJob, label: 'Other Job' },
  { value: AgreementEndReason.Seasonal, label: 'Seasonal' },
  { value: AgreementEndReason.Terminated, label: 'Terminated' },
  { value: AgreementEndReason.Overwhelmed, label: 'Overwhelmed' },
  { value: AgreementEndReason.Unresponsive, label: 'Unresponsive' },
  { value: AgreementEndReason.TechDifficulties, label: 'Tech Difficulties' },
  { value: AgreementEndReason.TeacherSituation, label: 'Other: Teacher Situation' },
  { value: AgreementEndReason.HootPreference, label: 'Other: Hoot Preference' },
  { value: AgreementEndReason.ProgramSpecific, label: 'Program Specific' },
  { value: AgreementEndReason.TerminatedLocation, label: 'Terminated - Location' },
  { value: AgreementEndReason.TeacherPay, label: 'Teacher Pay' },
  { value: AgreementEndReason.DidNotReturnFromLeave, label: 'Did Not Return from Leave' },
];

export const teacherDateTypeOptions: FormOption<TeacherDateType>[] = [
  { value: TeacherDateType.GoLiveDate, label: 'Go Live Date' },
  { value: TeacherDateType.AgreementEndDate, label: 'Agreement End Date' },
];
