import { Stack } from '@mui/system';
import { ITotalsTableRow } from '../block-interfaces';
import { timeSlotBlocks, timeSlotLessons } from '../blocks-utils';
import TotalsTimeSlot from './timeslots/TotalsTimeSlot';

const TotalsTableRow = (props: ITotalsTableRow) => {
  const { timeSlots, teacherData, isLoading } = props;

  const allLessons = teacherData?.teachers.flatMap((teacher) => teacher.lessons) ?? [];
  const allBlocks = teacherData?.teachers.flatMap((teacher) => teacher.blocks) ?? [];

  return (
    <Stack
      direction="row"
      sx={{
        height: '65px',
        backgroundColor: '#E5E5E5',
        position: 'sticky',
        bottom: 0,
      }}
    >
      {timeSlots.map((timeSlot, idx) => (
        <TotalsTimeSlot
          key={`${timeSlot.start}-${idx}`}
          lessonCount={timeSlotLessons(allLessons, timeSlot).length}
          blockCount={timeSlotBlocks(allBlocks, timeSlot).length}
          isLoading={isLoading}
        />
      ))}
    </Stack>
  );
};

export default TotalsTableRow;
