import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, CircularProgress, Menu, MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import React, { useState } from 'react';

export interface DropdownButtonProps extends ButtonProps {
  label: string;
  menuItems: DropdownButtonMenuItem[];
  onMenuItemClicked: (menuItemId: DropdownButtonMenuItem['id']) => void;
  isLoading?: boolean;
}

export interface DropdownButtonMenuItem {
  id: string;
  label: MuiMenuItemProps['children'];
  onClick?: () => void;
  MenuItemProps?: MuiMenuItemProps;
}

const DropdownButton = (props: DropdownButtonProps) => {
  const { label, menuItems, onMenuItemClicked, isLoading, ...buttonProps } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const showMenu = Boolean(anchorEl);

  const onButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onDismissMenu = () => {
    setAnchorEl(null);
  };

  const _onMenuItemClicked = (menuItem: DropdownButtonMenuItem) => () => {
    onDismissMenu();
    if (menuItem.onClick) {
      menuItem.onClick();
    } else {
      onMenuItemClicked(menuItem.id);
    }
  };

  return (
    <>
      {/* Have to fudge w/ the padding a bit on the left b/c the icon adds contains whitespace. */}
      <Button
        sx={{ pl: 2 }}
        variant="outlined"
        size="large"
        startIcon={isLoading ? undefined : <ArrowDropDownIcon />}
        onClick={onButtonClick}
        {...buttonProps}
      >
        {isLoading ? <CircularProgress size={22} /> : label}
      </Button>
      <Menu anchorEl={anchorEl} open={showMenu} onClose={onDismissMenu}>
        {menuItems.map((m) => (
          <MuiMenuItem key={`menu-item-${m.id}`} onClick={_onMenuItemClicked(m)} {...(m.MenuItemProps ?? {})}>
            {m.label}
          </MuiMenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdownButton;
