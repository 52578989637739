import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { ArrowDropDown } from '@mui/icons-material';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { HootNavLink } from '@hoot/pages/home/AdminDashboard';
import { routes } from '@hoot/routes/routes';

const DistrictAndSchoolsActionsButton = () => {
  const [actionAnchorEl, setActionAnchorEl] = useState<Element | null>(null);

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleActionPopoverClose = () => {
    setActionAnchorEl(null);
  };

  return (
    <>
      <Button type="button" style={{ height: 36 }} startIcon={<ArrowDropDown />} color="inherit" variant="outlined" onClick={handleActionClick}>
        <Typography variant="labelLarge" color="black">
          Actions
        </Typography>
      </Button>
      <Menu
        open={Boolean(actionAnchorEl)}
        anchorEl={actionAnchorEl}
        onClose={handleActionPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ marginTop: 1 }}
      >
        <MenuItem component={HootNavLink} to={routes.districtsSchools.create.url(LocationType.District)} onClick={handleActionPopoverClose}>
          <Typography variant="titleSmall" color="black" width={200}>
            Create District
          </Typography>
        </MenuItem>
        <MenuItem component={HootNavLink} to={routes.districtsSchools.create.url(LocationType.School)} onClick={handleActionPopoverClose}>
          <Typography variant="titleSmall" color="black" width={200}>
            Create Standalone School
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default DistrictAndSchoolsActionsButton;
