import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, Stack, Typography } from '@mui/material';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import { Control, UseFormResetField } from 'react-hook-form';
import { FormState, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form/dist/types/form';
import { DatePicker } from '@hoot/components/form/DatePicker';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { StudentFutureField, pausedReasonOptions, studentProfileInactiveReasonOptions } from '@hoot/interfaces/profile';
import { StudentDetailsForm } from '../form';

interface Props {
  open: boolean;
  handleClose: () => void;
  futureStatusField: StudentFutureField;
  control: Control<StudentDetailsForm, object>;
  formState: FormState<StudentDetailsForm>;
  setValue: UseFormSetValue<StudentDetailsForm>;
  watch: UseFormWatch<StudentDetailsForm>;
  trigger: UseFormTrigger<StudentDetailsForm>;
  resetField: UseFormResetField<StudentDetailsForm>;
}

const FutureStatusModal = (props: Props) => {
  const { open, handleClose, futureStatusField, control, formState, setValue, watch, trigger, resetField } = props;
  const { effectiveDate, pauseEndDate } = watch();

  const handleCancel = () => {
    resetField('effectiveDate');
    resetField('inactiveReason');
    resetField('pauseEndDate');
    resetField('pausedReason');
    resetField('futureStatus');
    handleClose();
  };

  const handleApply = async (): Promise<void> => {
    if (futureStatusField === StudentStatus.Inactive) {
      const result = await trigger(['effectiveDate', 'inactiveReason']);
      if (result) {
        setValue('futureStatus', futureStatusField, { shouldDirty: true });
        handleClose();
      }
    }

    if (futureStatusField === StudentStatus.Paused) {
      const result = await trigger(['effectiveDate', 'pauseEndDate', 'pausedReason']);
      if (result) {
        setValue('futureStatus', futureStatusField, { shouldDirty: true });
        handleClose();
      }
    }
  };

  const validateEffectiveDate = (dateToCheck?: string | null): string | boolean => {
    if (!dateToCheck) {
      return 'Effective Date is required.';
    }
    const todayString = DateTime.now().toISODate()!;

    if (dateToCheck <= todayString) {
      return `Effective Date cannot be set on or before today's date.`;
    }

    if (pauseEndDate && futureStatusField === StudentStatus.Inactive) {
      return dateToCheck <= pauseEndDate ? 'Effective Date must be after the Pause End Date.' : true;
    }

    if (pauseEndDate && futureStatusField === StudentStatus.Paused) {
      return dateToCheck <= pauseEndDate ? true : 'Effective Date must be before the Pause End Date.';
    }
    return true;
  };

  const validatePauseEndDate = async (dateToCheck?: string | null): Promise<string | boolean> => {
    await trigger(['effectiveDate']);

    if (!dateToCheck) {
      return 'Pause End Date is required.';
    }
    const todayString = DateTime.now().toISODate()!;

    if (dateToCheck <= todayString) {
      return `Pause End Date cannot be set on or before today's date.`;
    }

    if (effectiveDate) {
      return dateToCheck <= effectiveDate ? 'Pause End Date must be after the Effective Date.' : true;
    }

    return true;
  };

  return (
    <Dialog open={open} onClose={handleCancel} scroll="paper">
      <Stack sx={{ padding: '24px', width: 400 }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Future {capitalCase(futureStatusField)} Status</Typography>
            <IconButton onClick={handleCancel} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Stack sx={{ padding: 1 }} gap={3}>
            <Stack>
              <DatePicker
                name="effectiveDate"
                label="Effective Date"
                control={control}
                disablePast
                rules={{
                  validate: (val) => validateEffectiveDate(val),
                }}
              />
              {formState.errors.effectiveDate ? (
                <FormHelperText style={{ padding: '0px 8px' }} error={!!formState.errors.effectiveDate}>
                  {formState.errors.effectiveDate.message}
                </FormHelperText>
              ) : null}
            </Stack>

            {futureStatusField === StudentStatus.Inactive ? (
              <Stack>
                <Dropdown
                  name="inactiveReason"
                  label="Inactive Reason*"
                  options={studentProfileInactiveReasonOptions}
                  control={control}
                  rules={{ required: true }}
                  variant="outlined"
                />
              </Stack>
            ) : null}

            {futureStatusField === StudentStatus.Paused ? (
              <Stack gap={3}>
                <Dropdown
                  name="pausedReason"
                  label="Paused Reason*"
                  options={pausedReasonOptions}
                  control={control}
                  rules={{ required: true }}
                  variant="outlined"
                />
                <Stack>
                  <DatePicker
                    name="pauseEndDate"
                    label="Paused End Date*"
                    control={control}
                    rules={{
                      validate: (val) => validatePauseEndDate(val),
                    }}
                  />
                  <FormHelperText sx={{ padding: '0px 8px', color: '#1C1B1F' }} error={!!formState.errors.pauseEndDate}>
                    {formState.errors.pauseEndDate
                      ? formState.errors.pauseEndDate.message
                      : `After this date, the user will automatically be set back to Active. Schedules and billing will resume.`}
                  </FormHelperText>
                </Stack>
              </Stack>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="flex-end" gap={3}>
            <Button
              sx={{
                fontSize: 14,
              }}
              fullWidth
              size="medium"
              color="inherit"
              variant="outlined"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              sx={{
                fontSize: 14,
              }}
              fullWidth
              size="medium"
              variant="contained"
              onClick={handleApply}
            >
              Apply
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default FutureStatusModal;
