import { OpenInNew } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { HootFormativeAssessmentResponse } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentFormativeAssessments';
import useGetFluencyFormativeAssessmentDetails from '@hoot/hooks/api/user/student/lesson-reviews/useGetFluencyFormativeAssessmentDetails';
import { routes } from '@hoot/routes/routes';

interface IProps {
  open: boolean;
  handleClose: () => void;
  formativeAssessment: HootFormativeAssessmentResponse | undefined;
}

const FluencyFormativeAssessmentDetailsModal = (props: IProps) => {
  const { open, handleClose, formativeAssessment } = props;
  const { data, isLoading } = useGetFluencyFormativeAssessmentDetails(formativeAssessment?.lessonReviewId ?? '', formativeAssessment?.bookId ?? '', {
    enabled: !!formativeAssessment,
    retry: false,
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        Formative Assessment Details
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack gap={'24px'}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="titleMedium">Lesson Number</Typography>
                <Typography variant="bodyMedium">{data?.lessonNumber}</Typography>
              </Box>
              <IconButton component="a" href={routes.lessons.details.url(data?.lessonId ?? '')} target="_blank" rel="noopener noreferrer">
                <OpenInNew />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="titleMedium">Lesson Date</Typography>
              <Typography variant="bodyMedium">{data?.lessonDate}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="titleMedium">Formative Assessment Type</Typography>
              <Typography variant="bodyMedium">Fluency</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="titleMedium">Assessed By</Typography>
                <Typography variant="bodyMedium">{data?.teacherName}</Typography>
              </Box>
              <IconButton
                component="a"
                href={routes.users.teachers.details.url(data?.teacherAccountId ?? '')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <OpenInNew />
              </IconButton>
            </Box>
            <Typography variant="titleLarge">Assessment</Typography>
            {data?.wordCount ? (
              <Stack>
                <Typography variant="titleMedium">Word Count</Typography>
                <Typography variant="bodyMedium">{data.wordCount}</Typography>
              </Stack>
            ) : null}
            {data?.errors ? (
              <Stack>
                <Typography variant="titleMedium">Errors</Typography>
                <Typography variant="bodyMedium">{data.errors}</Typography>
              </Stack>
            ) : null}
            {data?.runningTime ? (
              <Stack>
                <Typography variant="titleMedium">Running Time (mm:ss)</Typography>
                <Typography variant="bodyMedium">{data?.runningTime}</Typography>
              </Stack>
            ) : null}
            {data?.wordsCorrectPerMinute ? (
              <Stack>
                <Typography variant="titleMedium">Words Correct Per Minute</Typography>
                <Typography variant="bodyMedium">{data.wordsCorrectPerMinute}</Typography>
              </Stack>
            ) : null}
            {data?.accuracy ? (
              <Stack>
                <Typography variant="titleMedium">Accuracy</Typography>
                <Typography variant="bodyMedium">{data.accuracy}</Typography>
              </Stack>
            ) : null}
            <Typography variant="titleMedium">Resource Used</Typography>
            <Card elevation={2}>
              <Stack sx={{ padding: 2 }} gap={3} direction={'row'}>
                <img src={'/images/book-closed.svg'} alt="Book Closed Icon" height={24} width={24} />
                <Typography variant="titleMedium">{data?.bookTitle}</Typography>
              </Stack>
            </Card>
          </Stack>
        )}
      </DialogContent>
      <Divider sx={{ marginX: 3 }} />
      <DialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FluencyFormativeAssessmentDetailsModal;
