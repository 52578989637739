import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useBlockIfDirty } from '../../contexts/BlockIfDirtyContext';
import { useBlocker } from '../../hooks/useBlocker';

export function DiscardPrompt(props: { when: boolean; onClose?: () => void }) {
  const { when, onClose } = props;

  const [showPrompt, setShowPrompt] = useState(false);
  const [blocked, proceed, keepEditing] = useBlocker(when);

  useEffect(() => {
    if (blocked) {
      setShowPrompt(true);
    }
  }, [blocked]);

  function handleKeepEditing() {
    setShowPrompt(false);
    keepEditing();
  }

  function handleDiscard() {
    if (onClose) {
      onClose();
      setShowPrompt(false);
    }
    proceed();
  }

  return (
    <Dialog onBackdropClick={undefined} open={showPrompt} onClose={handleKeepEditing}>
      <Stack sx={{ padding: '24px', position: 'relative' }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">Unsaved Changes</Typography>
            <IconButton onClick={handleKeepEditing} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText textAlign="left" sx={{ color: '#1C1B1F' }}>
            Your unsaved changes will be lost. Are you sure you want to leave?
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button
              sx={{
                fontSize: 14,
              }}
              fullWidth
              size="medium"
              color="inherit"
              variant="outlined"
              onClick={handleKeepEditing}
            >
              Stay
            </Button>
            <Button
              sx={{
                fontSize: 14,
              }}
              fullWidth
              size="medium"
              variant="contained"
              onClick={handleDiscard}
            >
              Leave
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}

export function DiscardPromptHandler() {
  const { isDirty, setIsDirty } = useBlockIfDirty();

  return <DiscardPrompt when={isDirty} onClose={() => setIsDirty(false)} />;
}
