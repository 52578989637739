import { CustomerProgram } from '@hoot-reading/hoot-core/dist/enums/user/customer-program.enum';
import { CustomerStatus } from '@hoot-reading/hoot-core/dist/enums/user/customer-status.enum';
import { FormOption } from './form';

export const customerStatus: FormOption<CustomerStatus>[] = [
  { value: CustomerStatus.ACTIVE, label: 'Active' },
  { value: CustomerStatus.LEAD, label: 'Lead' },
  { value: CustomerStatus.COLD, label: 'Cold' },
  { value: CustomerStatus.PAUSED, label: 'Paused' },
  { value: CustomerStatus.LAPSED, label: 'Lapsed' },
];

export const customerProgram: FormOption<CustomerProgram>[] = [
  { value: CustomerProgram.HOOT_READING, label: 'Hoot Reading' },
  { value: CustomerProgram.HOOT_FOR_ALL, label: 'Hoot For All' },
  { value: CustomerProgram.HOOT_FOR_COMPANIES, label: 'Hoot For Companies' },
  { value: CustomerProgram.HOOT_FOR_SCHOOLS, label: 'Hoot For Schools' },
];

export interface CustomerAccount {
  isEnabled: boolean;
  isDisabled: boolean;
  disabledReason: string;
  status: CustomerStatus;
  program: CustomerProgram;
  partnerId: string | null;
  siteId: string | null;
  accountId: string | null;
  leadInitiationDate: string | null;
  conversionDate: string | null;
  returnDate: string | null;
  lapsedDate: string | null;
  referralCode: string | null;
}
