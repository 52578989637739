import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { DayOfWeek } from '@hoot/utils/dateTime';
import { QueryKey } from '../queryKeys';

export interface ScheduleCombinationSlotResponse {
  id: string;
  dayOfWeek: DayOfWeek;
  time: string;
}

export interface ScheduleCombinationResponse {
  id: string;
  allowed: number;
  combinationSlots: ScheduleCombinationSlotResponse[];
  available: number;
  booked: number;
}

export interface EnrolmentCapacityResponse {
  numberOfProductAllowedLessonsSets: number;
  numberOfAllowedLessonsSets: number;
  numberOfRegisteredLessonsSets: number;
  numberOfScheduledLessons: number;
  numberOfOccurredLessons: number;
  scheduleLessonsDuration: number;
  scheduleCombinations: ScheduleCombinationResponse[];
}

const getEnrolmentCapacity = async (enrolmentId: string): Promise<EnrolmentCapacityResponse> => {
  const { data } = await axios.get<EnrolmentCapacityResponse>(`${config.apiUrl}/admin/hfs/enrolments/${enrolmentId}/capacity`);
  return data;
};

export default function useGetEnrolmentCapacity(
  enrolmentId: string,
  options?: Omit<UseQueryOptions<EnrolmentCapacityResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetEnrolmentCapacity, enrolmentId], () => getEnrolmentCapacity(enrolmentId), options);
}
