import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { capitalCase } from 'change-case';
import React, { useState } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types/form';
import NoneItem from '@hoot/components/ui/NoneItem';
import StudentTypeModal from '@hoot/pages/users/students/details/components/studentTypeModal';
import { StudentDetailsForm } from '../form';

const StudentTypeCard = (props: {
  watch: UseFormWatch<StudentDetailsForm>;
  setValue: UseFormSetValue<StudentDetailsForm>;
  disableFields: boolean;
}) => {
  const { watch, setValue, disableFields } = props;
  const { studentType } = watch();
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Card sx={{ pl: 3, pt: 3, pr: 3 }}>
      <CardHeader
        sx={{ p: 0, pb: 3 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="titleLarge">
              Student Type
            </Typography>
            <Button type="button" variant="contained" onClick={() => setShowModal(true)} disabled={disableFields}>
              Edit
            </Button>
          </Stack>
        }
      />

      <CardContent sx={{ p: 0 }}>
        {studentType ? (
          <Stack direction="row" gap={2} sx={{ pt: 2, pb: 2 }}>
            <PersonOutlineOutlinedIcon />
            <Typography>{capitalCase(studentType ?? '')}</Typography>
          </Stack>
        ) : (
          <NoneItem />
        )}
      </CardContent>
      {showModal ? <StudentTypeModal open={showModal} setOpen={setShowModal} studentType={studentType} setValue={setValue} /> : null}
    </Card>
  );
};

export default StudentTypeCard;
