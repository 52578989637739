import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { FormControl, FormHelperText, InputLabel, MenuItem } from '@mui/material';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { SelectProps } from '@hoot/components/form/selectFields/FormSelect';
import { Select } from '../../SelectField';

export const productStatusesList: {
  value: ProductStatus;
  label: string;
}[] = [
  { value: ProductStatus.Active, label: 'Active' },
  { value: ProductStatus.Expired, label: 'Expired' },
  { value: ProductStatus.Requested, label: 'Requested' },
];

export const productStatuses = productStatusesList.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as Record<ProductStatus, string>;

export function ProductStatusSelect<T extends FieldValues, TObj extends Path<T>>(
  props: SelectProps &
    UseControllerProps<T, TObj> & {
      filter?: ProductStatus[];
    },
) {
  const { filter, size, errorMessage, includeEmpty, includeAll, variant, ...rest } = props;
  return (
    <FormControl variant={variant || 'outlined'} fullWidth size={size}>
      <InputLabel id="program-status-label">Program Status</InputLabel>
      <Select {...rest} labelId="program-status-label" label="Program Status">
        {includeEmpty ? (
          <MenuItem value="">
            <span>None</span>
          </MenuItem>
        ) : null}

        {includeAll ? (
          <MenuItem value="all">
            <span>All</span>
          </MenuItem>
        ) : null}

        {productStatusesList
          .filter((v) => (filter && filter.length > 0 ? filter.some((f) => f === v.value) : v))
          .map((s) => (
            <MenuItem key={s.value} value={s.value}>
              {s.label}
            </MenuItem>
          ))}
      </Select>
      {errorMessage ? <FormHelperText error={true}>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
}
