import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { ReadingLevelType } from '../../../../pages/lessons/enums';
import { QueryKey } from '../../queryKeys';

export interface LevellingSystemResponse {
  id: string;
  name: string;
  type: ReadingLevelType;
}

async function getLevellingSystems(): Promise<LevellingSystemResponse[]> {
  const { data } = await axios.get<LevellingSystemResponse[]>(`${config.apiUrl}/admin/library/levelling-systems`);
  return data;
}

export default function useGetLevellingSystems(options?: Omit<UseQueryOptions<LevellingSystemResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetLevellingSystem], () => getLevellingSystems(), options);
}
