import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface InstructionalUnitsResponse {
  id: string;
  name: string;
}

async function getInstructionalUnits(): Promise<InstructionalUnitsResponse[]> {
  const { data } = await axios.get<InstructionalUnitsResponse[]>(`${config.apiUrl}/admin/library/instructional-units`);
  return data;
}

export default function useGetInstructionalUnits(options?: Omit<UseQueryOptions<InstructionalUnitsResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetInstructionalUnits], () => getInstructionalUnits(), options);
}
