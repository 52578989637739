export const enum StatusColours {
  Scheduled = '#1976D2',
  Published = '#1976D2',
  Cancelled = '#B3261E',
  Completed = '#2E7D32',
}

export const enum ZIndexLayer {
  Bottom = 100,
  Middle = 200,
  Top = 300,
}
