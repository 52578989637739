import { OpenInNew } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { HootFormativeAssessmentResponse } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentFormativeAssessments';
import useGetComprehensionFormativeAssessmentDetails from '@hoot/hooks/api/user/student/lesson-reviews/useGetComprehensionFormativeAssessmentDetails';
import { routes } from '@hoot/routes/routes';

interface IProps {
  open: boolean;
  handleClose: () => void;
  formativeAssessment: HootFormativeAssessmentResponse | undefined;
}

const ComprehensionFormativeAssessmentDetailsModal = (props: IProps) => {
  const { open, handleClose, formativeAssessment } = props;
  const { data, isLoading } = useGetComprehensionFormativeAssessmentDetails(
    formativeAssessment?.lessonReviewId ?? '',
    formativeAssessment?.bookId ?? '',
    {
      enabled: !!formativeAssessment,
      retry: false,
    },
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        Formative Assessment Details
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack gap={'24px'}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="titleMedium">Lesson Number</Typography>
                <Typography variant="bodyMedium">{data?.lessonNumber}</Typography>
              </Box>
              <IconButton component="a" href={routes.lessons.details.url(data?.lessonId ?? '')} target="_blank" rel="noopener noreferrer">
                <OpenInNew />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="titleMedium">Lesson Date</Typography>
              <Typography variant="bodyMedium">{data?.lessonDate}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="titleMedium">Formative Assessment Type</Typography>
              <Typography variant="bodyMedium">Comprehension</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="titleMedium">Score</Typography>
              <Typography variant="bodyMedium">{data?.score}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="titleMedium">Assessed By</Typography>
                <Typography variant="bodyMedium">{data?.teacherName}</Typography>
              </Box>
              <IconButton
                component="a"
                href={routes.users.teachers.details.url(data?.teacherAccountId ?? '')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <OpenInNew />
              </IconButton>
            </Box>
            <Typography variant="titleLarge">Assessment</Typography>
            <Stack gap={2}>
              {data?.textSpecificKnowledge ? (
                <Stack>
                  <Typography variant="titleMedium">Text Specific Knowledge</Typography>
                  <Typography variant="bodyMedium">{data.textSpecificKnowledge}</Typography>
                </Stack>
              ) : null}
              {data?.textStructure ? (
                <Stack>
                  <Typography variant="titleMedium">Text Structure</Typography>
                  <Typography variant="bodyMedium">{data.textStructure}</Typography>
                </Stack>
              ) : null}
              {data?.sentenceAnalysis ? (
                <Stack>
                  <Typography variant="titleMedium">Sentence Analysis</Typography>
                  <Typography variant="bodyMedium">{data?.sentenceAnalysis}</Typography>
                </Stack>
              ) : null}
              {data?.verbalReasoning ? (
                <Stack>
                  <Typography variant="titleMedium">Verbal Reasoning</Typography>
                  <Typography variant="bodyMedium">{data.verbalReasoning}</Typography>
                </Stack>
              ) : null}
              {data?.vocabulary ? (
                <Stack>
                  <Typography variant="titleMedium">Vocabulary</Typography>
                  <Typography variant="bodyMedium">{data.vocabulary}</Typography>
                </Stack>
              ) : null}
            </Stack>
            <Typography variant="titleMedium">Resource Used</Typography>
            <Card elevation={2}>
              <Stack sx={{ padding: 2 }} gap={3}>
                <Stack direction="row" gap={2}>
                  <img src={'/images/book-closed.svg'} alt="Book Closed Icon" height={24} width={24} />
                  <Typography variant="titleMedium">{data?.bookTitle}</Typography>
                </Stack>
              </Stack>
            </Card>
          </Stack>
        )}
      </DialogContent>
      <Divider sx={{ marginX: 3 }} />
      <DialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ComprehensionFormativeAssessmentDetailsModal;
