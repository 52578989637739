import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { OrderBy } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';

export enum OrderColumn {
  FirstName = 'FIRST_NAME',
}

export interface EmployeesSearchParams {
  searchText: string;
  page: number;
  pageSize: number;
  isEnabled?: boolean;
  orderColumn: OrderColumn;
  orderBy: OrderBy;
}

export interface HootEmployeeResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isEnabled: boolean;
  isSuperUser: boolean;
  canUpdateBooks: boolean;
  canViewLessonVideos: boolean;
  canImpersonateUsers: boolean;
  canAccessReportingPage: boolean;
  canSoftDeleteUsers: boolean;
  canAccessPartnerManagement: boolean;
  canAdvancedEditLessonTeacher: boolean;
  canAdvancedEditLessonDetails: boolean;
  canAccessInvoicing: boolean;
  canManageLessonReviews: boolean;
  canImportTeacherReliabilityScore: boolean;
  canManageAdvancedStudentData: boolean;
  canViewDistrictDetails: boolean;
  canManageStudentDetails: boolean;
  canManageTeacherDetails: boolean;
  canManageDistrictReps: boolean;
  canAccessBillingInformation: boolean;
}

export interface EmployeeQueryResponse {
  page: number;
  limit: number;
  total: number;
  hootEmployees: HootEmployeeResponse[];
}

const getHootEmployees = async (searchParams: EmployeesSearchParams): Promise<EmployeeQueryResponse> => {
  const { data } = await axios.post(`${config.apiUrl}/admin/hoot-employee/search`, searchParams);
  return data;
};

export default function useGetHootEmployees(searchParams: EmployeesSearchParams) {
  return useQuery([QueryKey.HootEmployeeSearch, searchParams], () => getHootEmployees(searchParams));
}
