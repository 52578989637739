import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../../config';
import { QueryKey } from '../../../queryKeys';

export interface ProductEnrolment {
  id: string;
  friendlyId: string;
  locationName: string;
  type: EnrolmentType;
  startDate: number;
  endDate: number;
  status: EnrolmentStatus;
}

export interface ProductEnrolmentsResponse {
  count: number;
  page: number;
  pageSize: number;
  enrolments: ProductEnrolment[];
}

const getProductEnrolments = async (productId: string): Promise<ProductEnrolmentsResponse> => {
  const { data } = await axios.get<ProductEnrolmentsResponse>(`${config.apiUrl}/admin/hfs/product/${productId}/enrolments`);
  return data;
};

export default function useGetProductEnrolments(
  productId: string,
  options?: Omit<UseQueryOptions<ProductEnrolmentsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetProductEnrolments, productId], () => getProductEnrolments(productId), options);
}
