import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { Chip, Grid, List, Table, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@mui/material';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { defaultRowsPerPage, rowsPerPageOptions } from '@hoot/common/constants';
import SearchTextField from '@hoot/components/form/SearchTextField';
import EnrolmentStatusIcon from '@hoot/components/ui/enrolments/EnrolmentStatusIcon';
import { LocationEnrolment } from '@hoot/hooks/api/hfs/useSearchLocationEnrolments';
import { routes } from '@hoot/routes/routes';
import { millisToDateDisplay } from '@hoot/utils/dateTime';
import { NoneListItem, SortableTableHeader, TableHeaderProp } from '../details/Shared';
import { ActiveEnrolmentsFiltersContainer, EnrolmentsFilters, EnrolmentsFiltersContainer } from './EnrolmentsTableFilters';

const NOT_APPLICABLE = 'N/A';

const enrolmentsTableHeaders: TableHeaderProp<LocationEnrolment>[] = [
  { property: 'friendlyId', label: 'Enrolment ID' },
  { property: 'parentProductFriendlyId', label: 'Product ID' },
  { property: 'location', label: 'School' },
  { property: 'type', label: 'Type' },
  { property: 'lessons', label: 'Lessons' },
  { property: 'startDate', label: 'Start Date' },
  { property: 'endDate', label: 'End Date' },
  { property: 'status', label: 'Status' },
];

interface Props {
  enrolments: LocationEnrolment[];
  locationType: LocationType;
  locationId: string;
}

const EnrolmentsTable = (props: Props) => {
  const { enrolments, locationType, locationId } = props;

  const [filters, setFilters] = useState<EnrolmentsFilters | null>(null);

  const [query, setQuery] = useState<string>('');
  const [pageSize, setRowsPerPage] = useState<number>(defaultRowsPerPage);
  const [page, setPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<keyof LocationEnrolment | null>('friendlyId');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>('asc');

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, defaultRowsPerPage));
    setPage(1);
  };

  const handleSearchChange = (val: string) => {
    setQuery(val);
  };

  const filterFunc = (value: LocationEnrolment): boolean => {
    const meetsCriteria: boolean[] = [];

    if (value.friendlyId.toLowerCase().includes(query.toLowerCase())) {
      meetsCriteria.push(true);
    }

    if (filters && filters.status && filters.status?.length > 0) {
      meetsCriteria.push(filters.status.includes(value.status));
    }

    if (filters && filters.school && filters.school.length > 0) {
      if (!value.location) {
        return false;
      } else {
        meetsCriteria.push(!!filters.school.find((s) => s.id === value.location!.id));
      }
    }

    if (filters && filters.startsAt) {
      meetsCriteria.push(value.startDate > DateTime.fromFormat(filters.startsAt, 'LL/dd/yyyy').toMillis());
    }

    if (filters && filters.endsAt) {
      meetsCriteria.push(value.endDate < DateTime.fromFormat(filters.endsAt, 'LL/dd/yyyy').toMillis());
    }

    return meetsCriteria.length > 0 && meetsCriteria.every((c) => c === true);
  };

  const sortFunc = (e1: LocationEnrolment, e2: LocationEnrolment): number => {
    if (!sortBy) {
      //Standard sort
      if (e1.status === EnrolmentStatus.InProgress && e2.status !== EnrolmentStatus.InProgress) {
        return -1;
      } else if (e1.status === EnrolmentStatus.Open && (e2.status === EnrolmentStatus.Scheduled || e2.status === EnrolmentStatus.Closed)) {
        return -1;
      } else if (e1.status === EnrolmentStatus.Scheduled && e2.status === EnrolmentStatus.Closed) {
        return -1;
      } else if (e1.status === EnrolmentStatus.Closed && e2.status !== EnrolmentStatus.Closed) {
        return 1;
      } else {
        return e2.startDate - e1.startDate;
      }
    } else {
      if (typeof e1[sortBy] === 'number' && typeof e2[sortBy] === 'number') {
        if (sortOrder === 'asc') {
          return e1[sortBy]! < e2[sortBy]! ? 1 : -1;
        } else {
          return e2[sortBy]! < e1[sortBy]! ? 1 : -1;
        }
      } else if (typeof e1[sortBy] === 'string' && typeof e2[sortBy] === 'string') {
        if (sortOrder === 'asc') {
          return (e1[sortBy] as string).localeCompare(e2[sortBy] as string);
        } else {
          return (e2[sortBy] as string).localeCompare(e1[sortBy] as string);
        }
      } else if (sortBy === 'location') {
        if (sortOrder === 'asc') {
          return (e1[sortBy] ? (e1[sortBy]!.name as string) : NOT_APPLICABLE).localeCompare(
            e2[sortBy] ? (e2[sortBy]!.name as string) : NOT_APPLICABLE,
          );
        } else {
          return (e2[sortBy] ? (e2[sortBy]!.name as string) : NOT_APPLICABLE).localeCompare(
            e1[sortBy] ? (e1[sortBy]!.name as string) : NOT_APPLICABLE,
          );
        }
      }
      return 0;
    }
  };

  const filteredEnrolments = enrolments.filter(filterFunc);
  const startIdx = page * pageSize - pageSize;
  const endIdx = page * pageSize;
  const truncatedEnrolments = filteredEnrolments.sort(sortFunc).slice(startIdx, endIdx);

  return (
    <>
      {enrolments.length > 0 ? (
        <>
          <Grid container direction="column" mb={3}>
            <Grid item>
              <SearchTextField onSearchInputChanged={handleSearchChange} searchInput={query} />
            </Grid>
            <Grid sx={{ marginTop: '24px' }} container item xs={12}>
              <EnrolmentsFiltersContainer value={filters} onChange={(val) => setFilters(val)} locationType={locationType} locationId={locationId} />
            </Grid>
            <Grid sx={{ marginTop: '24px' }} container item xs={12}>
              <ActiveEnrolmentsFiltersContainer value={filters} onChange={(val) => setFilters(val)} locationType={locationType} />
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="enrolments table">
            <SortableTableHeader
              headers={enrolmentsTableHeaders}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
            <TableBody>
              {truncatedEnrolments.map((e) => (
                <TableRow
                  key={e.enrolmentId}
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  hover
                >
                  <TableCell>
                    <Link to={routes.districtsSchools.enrolments.details.url(locationId, e.enrolmentId)}>{e.friendlyId}</Link>
                  </TableCell>
                  <TableCell>{e.parentProductFriendlyId}</TableCell>
                  <TableCell>{e.location ? e.location.name : NOT_APPLICABLE}</TableCell>
                  <TableCell>{capitalCase(e.type)}</TableCell>
                  <TableCell>{e.lessons}</TableCell>
                  <TableCell>{millisToDateDisplay(e.startDate)}</TableCell>
                  <TableCell>{millisToDateDisplay(e.endDate)}</TableCell>
                  <TableCell>
                    <Chip
                      icon={EnrolmentStatusIcon(e.status)}
                      label={`${capitalCase(e.status)}`}
                      sx={{ margin: '4px', fontSize: 12, backgroundColor: '#EFEFEF' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={enrolmentsTableHeaders.length}
                  rowsPerPageOptions={rowsPerPageOptions}
                  count={filteredEnrolments.length || 0}
                  rowsPerPage={pageSize || 0}
                  page={(page || 1) - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </>
      ) : (
        <List sx={{ width: '100%' }}>
          <NoneListItem />
        </List>
      )}
    </>
  );
};

export default EnrolmentsTable;
