import React, { SetStateAction, createContext, useContext, useEffect, useRef, useState } from 'react';
import { LottieFile } from '@hoot/components/lottie/Lottie';
import useGetLessonEventsforVisualizer from '@hoot/hooks/api/lesson-event-visualizer/useGetLessonEventsforVisualizer';
import { LessonDetailResponse, useGetLesson } from '../../../../../hooks/api/lessons/useGetLesson';
import { LessonEvent } from '../LessonEventsCard';
import { useLessonEventVideo, videoPosition } from '../hooks/useLessonEventVideo';
import { HistoryEvent, TimelineSections, useLessonEventVisualiser } from '../hooks/useLessonEventVisualiser';

interface LessonVisualizerContextProps {
  lesson: LessonDetailResponse | undefined;
  isLoading: boolean;
  currentTime: React.MutableRefObject<number>;
  seek: (position: number) => void;
  syncVideoTimestamp: () => void;
  totalTime: number;
  initialLessonJoinEventAt: number;
  timelines: TimelineSections;
  playSpeed: number;
  togglePlaySpeed: () => void;
  isPlaying: boolean;
  toggleIsPlaying: () => void;
  teacherVideoRef: React.MutableRefObject<HTMLVideoElement | null>;
  studentVideoRef: React.MutableRefObject<HTMLVideoElement | null>;
  lessonEvents: LessonEvent[];
  setLessonEvents: React.Dispatch<SetStateAction<LessonEvent[]>>;
  currentEvent: CurrentEvent;
  celebration: LottieFile | undefined;
  eventHistory: React.MutableRefObject<HistoryEvent[]>;
  studentVideoPosition: videoPosition;
  teacherVideoPosition: videoPosition;
}

export enum ContentType {
  Library = 'Library',
  Reader = 'Reader',
  Whiteboard = 'Whiteboard',
  Assessment = 'Assessment',
}

export interface CurrentEvent {
  contentType: ContentType;
  whiteboardId?: string;
  bookId?: string;
  page?: string;
}

export enum CelebrationType {
  Confetti = 'Confetti',
  Star = 'Star',
  Thumb = 'Thumb',
}

const LessonVisualizerContext = createContext<LessonVisualizerContextProps>(undefined!);

export const LessonVisualizerContextProvider: React.FC<{ lessonId: string; children: React.ReactNode }> = ({ lessonId, children }) => {
  const currentTime = useRef(0);
  const timerIdForCurrentTimeIncrementer = useRef(0);
  const timerIdForJumpingToEvent = useRef(0);

  const [lessonEvents, setLessonEvents] = useState<LessonEvent[]>([]);
  const [currentEvent, setCurrentEvent] = useState<CurrentEvent>({ contentType: ContentType.Library });

  const [playSpeed, setPlaySpeed] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [celebration, setCelebration] = useState<LottieFile | undefined>();

  // Fetch required data
  const lessonRequest = useGetLesson(lessonId);

  const { timelineData, isLoading: lessonHistoryIsLoading, sortedLessonPageEvents, historyData } = useLessonEventVisualiser(lessonId);

  const {
    studentVideoRef,
    teacherVideoRef,
    playVideo,
    pauseVideo,
    setPlaybackSpeed,
    seekTo,
    syncVideoTimestamp,
    studentVideoPosition,
    teacherVideoPosition,
  } = useLessonEventVideo({
    lessonId: lessonId,
    initialLessonJoinEventAt: timelineData.initialLessonJoinEventAt,
    currentTime: currentTime,
    lessonEventSequence: timelineData.lessonEventSequence,
  });

  const lessonEventsRequest = useGetLessonEventsforVisualizer(lessonId, {
    enabled: !!timelineData,
    onSuccess: (data) => {
      const initialEvent = data.lessonEvents[0].timestamp;
      const formattedLessonEvents: LessonEvent[] = data.lessonEvents.map((event, idx) => ({
        ...event,
        timestamp: (event.timestamp - initialEvent) / 1000,
        isSelected: idx === 0,
        buttonText: event.button?.text,
        whiteboardId: event.button?.whiteboardId,
        bookId: event.button?.bookId,
      }));
      setLessonEvents(formattedLessonEvents);
    },
  });

  const isLoading = lessonRequest.isLoading || lessonHistoryIsLoading || lessonEventsRequest.isLoading;

  const handleCelebration = (event: LessonEvent): LottieFile | undefined => {
    if (event.title !== 'Celebration') {
      return;
    }
    const celebrationType = event.details[0] as CelebrationType;
    switch (celebrationType) {
      case CelebrationType.Confetti:
        return LottieFile.Confetti;
      case CelebrationType.Star:
        return LottieFile.Star;
      case CelebrationType.Thumb:
        return LottieFile.ThumbsUp;
    }
  };

  // Timer loop to increment current time
  useEffect(() => {
    if (isPlaying) {
      timerIdForCurrentTimeIncrementer.current = window.setInterval(() => {
        if (currentTime.current >= timelineData.totalTime) {
          clearInterval(timerIdForCurrentTimeIncrementer.current);
          setIsPlaying(false);
          pauseVideo();
          return;
        }
        currentTime.current += 1 * playSpeed;
      }, 1000);
    } else {
      clearInterval(timerIdForCurrentTimeIncrementer.current);
      pauseVideo();
    }

    return () => clearInterval(timerIdForCurrentTimeIncrementer.current);
  }, [isPlaying, playSpeed, sortedLessonPageEvents, timelineData.totalTime, pauseVideo]);

  useEffect(() => {
    timerIdForJumpingToEvent.current = window.setInterval(() => {
      setLessonEvents((prevEvents) =>
        prevEvents.map((event, index) => {
          const nextEvent = prevEvents[index + 1];
          const endTime = nextEvent ? nextEvent.timestamp : Infinity;
          const isBetween = currentTime.current >= event.timestamp && currentTime.current < endTime;

          if (isBetween) {
            setCelebration(handleCelebration(event));
            setCurrentEvent((prevEvent) => ({
              ...prevEvent,
              whiteboardId: event.whiteboardId,
              contentType:
                sortedLessonPageEvents.find(
                  (lessonPageEvent) => currentTime.current >= lessonPageEvent.startsAt && currentTime.current < lessonPageEvent.endsAt,
                )?.contentType ?? prevEvent.contentType,
            }));

            return { ...event, isSelected: true };
          }
          return { ...event, isSelected: false };
        }),
      );
    }, 1000);

    return () => clearInterval(timerIdForJumpingToEvent.current);
  }, [sortedLessonPageEvents]);

  const seek = (position: number) => {
    currentTime.current = position;
    seekTo(position);
  };

  const togglePlaySpeed = () => {
    setPlaySpeed((prev) => {
      const rate = prev === 1 ? 2 : 1;
      setPlaybackSpeed(rate);
      return rate;
    });
  };

  const toggleIsPlaying = () => {
    setIsPlaying((prev) => !prev);
    if (isPlaying) {
      pauseVideo();
    } else {
      playVideo();
    }
  };

  return (
    <LessonVisualizerContext.Provider
      value={{
        lesson: lessonRequest.data,
        isLoading,
        currentTime,
        seek,
        syncVideoTimestamp,
        initialLessonJoinEventAt: timelineData.initialLessonJoinEventAt,
        timelines: timelineData.timeline,
        totalTime: timelineData.totalTime,
        playSpeed,
        togglePlaySpeed,
        isPlaying,
        toggleIsPlaying,
        teacherVideoRef,
        studentVideoRef,
        lessonEvents,
        setLessonEvents,
        currentEvent,
        celebration,
        eventHistory: historyData,
        studentVideoPosition,
        teacherVideoPosition,
      }}
    >
      {children}
    </LessonVisualizerContext.Provider>
  );
};

export const useLessonVisualizer = () => {
  const context = useContext(LessonVisualizerContext);
  if (!context) {
    throw new Error('useLessonVisualizer must be used within a LessonVisualizerContextProvider');
  }
  return context;
};
