import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface TopicsResponse {
  id: string;
  name: string;
}

async function getTopics(): Promise<TopicsResponse[]> {
  const { data } = await axios.get<TopicsResponse[]>(`${config.apiUrl}/admin/library/topics`);
  return data;
}

export default function useGetTopics(options?: Omit<UseQueryOptions<TopicsResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetTopics], () => getTopics(), options);
}
