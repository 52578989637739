import { EventVisibilityOption } from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import FilterDropDown from '@hoot/components/form/FilterDropDown';
import { StudentTimelineRequest } from '@hoot/hooks/api/user/student/timeline/getStudentTimelineVis';
import { eventVisibilityLabels } from './common';

export type EventOptionType = {
  [key in EventVisibilityOption]: boolean;
};

interface EventVisibilityMenuProps {
  eventOptions: EventOptionType;
  setEventOptions: React.Dispatch<React.SetStateAction<EventOptionType>>;
  setQuery: React.Dispatch<React.SetStateAction<StudentTimelineRequest>>;
}

const EventVisibilityMenu = (props: EventVisibilityMenuProps) => {
  const { eventOptions, setEventOptions, setQuery } = props;

  const onChange = (options: EventVisibilityOption[]) => {
    const updatedEventOptions: EventOptionType = {
      [EventVisibilityOption.Absence]: options.includes(EventVisibilityOption.Absence),
      [EventVisibilityOption.Cancellation]: options.includes(EventVisibilityOption.Cancellation),
      [EventVisibilityOption.Hra]: options.includes(EventVisibilityOption.Hra),
      [EventVisibilityOption.Pma]: options.includes(EventVisibilityOption.Pma),
      [EventVisibilityOption.Lessons]: options.includes(EventVisibilityOption.Lessons),
    };

    setEventOptions(updatedEventOptions);

    const eventVisibilityOptions = Object.keys(updatedEventOptions).filter(
      (key) => updatedEventOptions[key as EventVisibilityOption] === true,
    ) as EventVisibilityOption[];
    setQuery((query) => ({
      ...query,
      eventVisibility: eventVisibilityOptions,
    }));
  };

  return (
    <>
      <FilterDropDown
        title={'Event Visibility'}
        value={Object.keys(eventOptions).filter((key) => eventOptions[key as EventVisibilityOption] === true) as EventVisibilityOption[]}
        options={Object.values(EventVisibilityOption).map((option) => ({
          label: eventVisibilityLabels[option],
          value: option,
        }))}
        onChange={onChange}
        popOverButtonSx={{ mx: 0 }}
        popOverSx={{ minHeight: '100%' }}
      />
    </>
  );
};

export default EventVisibilityMenu;
