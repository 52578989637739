import styled from '@emotion/styled';
import { TableCell, TableRow, tableCellClasses } from '@mui/material';

export const StripedTableRow = styled(TableRow)((props) => ({
  ':nth-child(even)': {
    backgroundColor: props.color === 'alert' ? '#F9DDDD' : '#FFF',
  },
  ':nth-child(odd)': {
    backgroundColor: props.color === 'alert' ? '#F9DDDD' : '#faf7f7b3',
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme: _theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F6F7F8',
    textTransform: 'uppercase',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: '#000000',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
