import { ArrowDropDown } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Menu, MenuItem } from '@mui/material';
import { DateTime } from 'luxon';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FieldValues, FormState } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { HUBSPOT_CONTACT_URL, IS_LOCAL, ZENDESK_CONTACT_URL, ZOHO_QA_TASK_REPORT_FORM_URL } from '@hoot/common/constants';
import CreatePasswordResetLinkItemButton from '@hoot/components/form/listItemButton/CreatePasswordResetLink';
import ConfirmDeleteDialog from '@hoot/components/modals/ConfirmDeleteDialog';
import HeaderCard, { HeaderCardProps, HeaderTab } from '@hoot/components/ui/HeaderCard';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { useImpersonateUser } from '@hoot/hooks/api/auth/useImpersonateUser';
import { useReSendUserAccountCreation } from '@hoot/hooks/api/end-users/useReSendUserAccountCreation';
import { useSendUserPasswordReset } from '@hoot/hooks/api/end-users/useSendUserPasswordReset';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import useSoftDeleteTeacherAccount from '@hoot/hooks/api/user/teacher/useSoftDeleteTeacherAccount';
import { routes } from '@hoot/routes/routes';
import { FeatureFlags } from '../../../common/featureFlags';
import { useFeatureFlags } from '../../../contexts/FeatureFlagContext';

const ActionButton = (props: { userData: AccountHeaderData }) => {
  const { email, teacherId, endUserId, hubspotId, zendeskId, isEditMode, teacherNumber, setShowFunctionalityDisabledModal } = props.userData;
  const [actionAnchorEl, setActionAnchorEl] = useState<Element | null>(null);
  const auth = useAuth();
  const alert = useAlert();
  const queryClient = useQueryClient();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isFeatureEnabled } = useFeatureFlags();
  const isBlocksAndOpportunitiesEnabled = isFeatureEnabled(FeatureFlags.ff13371_blocks_and_opportunities);

  const passwordReset = useSendUserPasswordReset({
    onSuccess: () => alert.success('Email successfully sent'),
    onError: () => alert.error('Error sending the password reset email'),
  });

  const resendAccountCreation = useReSendUserAccountCreation({
    onSuccess: () => alert.success('Email successfully sent'),
    onError: () => alert.error('Error resending the account creation email'),
  });

  const softDeleteTeacherAccount = useSoftDeleteTeacherAccount({
    onSuccess: () => {
      setShowConfirmDialog(false);
      alert.success(`Successfully soft-deleted the teacher account.`);
      navigate(routes.users.teachers.url);
      queryClient.invalidateQueries([QueryKey.GetTeacherAccount]);
      queryClient.invalidateQueries([QueryKey.SearchTeacherAccounts]);
    },
    onError: () => alert.error('Error soft-deleting the teacher account.'),
  });

  const impersonateUser = useImpersonateUser({
    onSuccess: (response) => {
      const url = IS_LOCAL ? 'http://' + response.impersonationUrl : response.impersonationUrl;
      window.open(url, '_blank');
    },
    onError: (err) => alert.error(`Error impersonating user: ${err.message}`),
  });

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isEditMode) {
      setActionAnchorEl(event.currentTarget);
    } else {
      setShowFunctionalityDisabledModal(true);
    }
  };

  const handleActionPopoverClose = () => {
    setActionAnchorEl(null);
  };

  const handlePasswordResetClick = () => {
    if (email) {
      passwordReset.mutate(email);
    }
    setActionAnchorEl(null);
  };

  const handleResendAccountCreationClick = () => {
    if (email) {
      resendAccountCreation.mutate(email);
    }
    setActionAnchorEl(null);
  };

  const handleSoftDeleteUser = () => {
    if (teacherId) {
      softDeleteTeacherAccount.mutate(teacherId);
    }
  };

  const handleImpersonateUserClick = () => {
    impersonateUser.mutate(endUserId);
    setActionAnchorEl(null);
  };

  const handleViewAllLessonsClick = () => {
    const url = window.location.origin + `/lessons?teacherAccountIds=${teacherId}`;
    window.open(url, '_blank');

    setActionAnchorEl(null);
  };

  const handleCreateShiftsClick = () => {
    const url = routes.users.teachers.shifts.create.urlWithId(teacherId);
    window.open(url, '_blank');
    setActionAnchorEl(null);
  };

  const handleCreateBlocksClick = () => {
    const url = routes.users.teachers.blocks.create.urlWithId(teacherId);
    window.open(url, '_blank');
    setActionAnchorEl(null);
  };

  const handleGoToHubspotContact = () => {
    const url = HUBSPOT_CONTACT_URL + hubspotId;
    window.open(url, '_blank');

    setActionAnchorEl(null);
  };

  const handleGoToZendeskContact = () => {
    const url = ZENDESK_CONTACT_URL + zendeskId;
    window.open(url, '_blank');

    setActionAnchorEl(null);
  };

  const handleGoToZohoQATaskForm = () => {
    const queryParams = new URLSearchParams();
    const formattedDate = DateTime.now().toFormat('dd-LLL-yyyy');

    if (auth?.hootEmployee) {
      queryParams.append('first', auth.hootEmployee.firstName);
      queryParams.append('last', auth.hootEmployee.lastName);
    }

    queryParams.append('date', formattedDate);
    queryParams.append('raised', 'Admin Task');

    if (teacherNumber) {
      queryParams.append('teacher', teacherNumber.toString());
    }

    const url = `${ZOHO_QA_TASK_REPORT_FORM_URL}?${queryParams.toString()}`;
    window.open(url, '_blank');
    setActionAnchorEl(null);
  };

  return (
    <>
      <Button type="button" startIcon={<ArrowDropDown />} color="inherit" variant="outlined" size="large" onClick={handleActionClick}>
        Action
      </Button>
      <Menu
        open={Boolean(actionAnchorEl)}
        anchorEl={actionAnchorEl}
        onClose={handleActionPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: 1 }}
      >
        <MenuItem onClick={handleViewAllLessonsClick}>View All Lessons</MenuItem>
        {isBlocksAndOpportunitiesEnabled ? null : <MenuItem onClick={handleCreateShiftsClick}>Create Shifts</MenuItem>}
        {isBlocksAndOpportunitiesEnabled ? <MenuItem onClick={handleCreateBlocksClick}>Create Blocks</MenuItem> : null}
        <MenuItem disabled={!hubspotId} onClick={handleGoToHubspotContact}>
          Go to Hubspot Contact <OpenInNewIcon sx={{ marginLeft: '10px' }} />
        </MenuItem>
        <MenuItem disabled={!zendeskId} onClick={handleGoToZendeskContact}>
          Go to Zendesk Contact <OpenInNewIcon sx={{ marginLeft: '10px' }} />
        </MenuItem>
        <MenuItem onClick={handleGoToZohoQATaskForm}>
          Task Report
          <OpenInNewIcon sx={{ marginLeft: '10px' }} />
        </MenuItem>
        <MenuItem onClick={handlePasswordResetClick}>Reset Password</MenuItem>
        <CreatePasswordResetLinkItemButton email={email} />
        <MenuItem onClick={handleResendAccountCreationClick}>Resend Account Creation</MenuItem>
        <MenuItem onClick={() => setShowConfirmDialog(true)}>Delete Teacher Account</MenuItem>

        {showConfirmDialog ? (
          <ConfirmDeleteDialog
            open={true}
            onCancel={() => setShowConfirmDialog(false)}
            onContinue={handleSoftDeleteUser}
            title="Soft Delete Teacher?"
            bodyText="This will disable and hide the teacher account from the Portal and App. For permanent deletions, please submit a Product Task."
          />
        ) : null}
        {auth.hootEmployee?.canImpersonateUsers ? <MenuItem onClick={handleImpersonateUserClick}>Impersonate</MenuItem> : null}
      </Menu>
    </>
  );
};

export interface AccountHeaderData {
  endUserId: string;
  teacherId: string;
  firstName: string;
  lastName: string;
  displayName: string;
  teacherNumber: string;
  teacherPrefixedNumber: string;
  email: string;
  hubspotId: number | null;
  zendeskId: number | null;
  isEditMode: boolean;
  setShowFunctionalityDisabledModal: Dispatch<SetStateAction<boolean>>;
}

interface Props<T extends FieldValues> {
  isEditMode: boolean;
  userData?: AccountHeaderData;
  formState: FormState<T>;
  setShowFunctionalityDisabledModal: Dispatch<SetStateAction<boolean>>;
}

const TeacherHeader = <T extends FieldValues>(props: Props<T>) => {
  const { isEditMode, userData, formState, setShowFunctionalityDisabledModal } = props;

  const navigate = useNavigate();
  const { hootEmployee } = useAuth();

  const hideReliabilityTab = !hootEmployee?.canManageTeacherDetails;

  const tabs = useMemo<HeaderTab[]>(() => {
    const detailsRoute = isEditMode ? `/users/teachers/${userData?.teacherId}/details` : `/teachers/create`;
    return [
      { id: 'details', label: 'Details', route: detailsRoute },
      { id: 'schedule', label: 'Schedule', route: `/users/teachers/${userData?.teacherId}/schedule` },
      { id: 'availability', label: 'Availability', route: `/users/teachers/${userData?.teacherId}/availability` },
      {
        id: 'availability-exceptions',
        label: 'Availability Exceptions',
        route: `/users/teachers/${userData?.teacherId}/availability-exceptions`,
      },
      { id: 'reliability', label: 'Reliability', route: `/users/teachers/${userData?.teacherId}/reliability`, isHidden: hideReliabilityTab },
      { id: 'tasks', label: 'Tasks', route: `/users/teachers/${userData?.teacherId}/tasks` },
      { id: 'history', label: 'History', route: `/users/teachers/${userData?.teacherId}/history` },
    ];
  }, [hideReliabilityTab, isEditMode, userData?.teacherId]);

  const onTabSelected: HeaderCardProps['onTabSelected'] = (selectedTab): boolean => {
    if (isEditMode) {
      navigate(selectedTab.route);
      return !formState.isDirty;
    } else {
      setShowFunctionalityDisabledModal(true);
      return false;
    }
  };

  return (
    <HeaderCard
      title={userData ? `${userData?.firstName} ${userData?.lastName} (${userData?.displayName})` : 'New Teacher'}
      subtitle={userData ? `Teacher Number: ${userData?.teacherPrefixedNumber}` : ''}
      canCopySubtitle
      copySubtitleOverride={`${userData?.teacherPrefixedNumber}`}
      showNavigateBackButton
      actionButtons={
        <>
          {isEditMode && userData ? <ActionButton userData={userData} /> : null}

          <Button type="submit" disabled={!formState.isDirty} sx={{ marginLeft: '8px' }} color="primary" variant="contained" size="large">
            Save
          </Button>
        </>
      }
      tabs={tabs}
      onTabSelected={onTabSelected}
    />
  );
};

export default TeacherHeader;
