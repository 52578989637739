import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Button, Collapse, Divider, Drawer, Grid, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { OperatorFilter, OperatorFilterValue } from '../../components/form/OperatorFilter';
import { BookType, Grade, Language, SpellingType, Subject } from '../../hooks/api/library/enums';
import { AdminQueryLibraryV2 } from '../../hooks/api/library/useQueryLibraryV2';
import { ReadingLevelType } from '../lessons/enums';
import {
  CollectionsAutocomplete,
  ContentTagsAutocomplete,
  DecodableFormatSelect,
  FilterLevelPrioritizedSkillAutocomplete,
  FormatSelect,
  GenreSelect,
  GradeSelect,
  InstructionalFocusAutocomplete,
  InstructionalUnitAutocomplete,
  LanguageSelect,
  LevelMultiAutocomplete,
  LevellingSystemSelect,
  PublisherSelect,
  ResourceTypeSelect,
  SeriesSelect,
  SpellingTypeSelect,
  SubGenreSelect,
  SubThemeSelect,
  SubTopicSelect,
  SubjectSelect,
  ThemeSelect,
  TopicSelect,
  YesNoSelect,
} from './LibrarySearchFilters';

interface Form {
  isActive: boolean | '';
  publisher: string | '';
  subject: Subject | '';
  language: Language | '';
  resourceType: BookType | '';
  instructionalFocus: string[];
  instructionalUnits: string[];
  filterLevelPrioritizedSkills: string[];
  levellingSystem: ReadingLevelType | '';
  levels: string[];
  isDecodableLibrary: boolean | '';
  isDoublePageEnabled: boolean | '';
  isStandardDoublePage: boolean | '';
  decodableFormat: string;
  seq: OperatorFilterValue<number>;
  genre: string;
  subGenre: string;
  topic: string;
  subTopic: string;
  theme: string;
  subTheme: string;
  format: string;
  hiLo: boolean | '';
  nonConforming: boolean | '';
  series: string;
  collections: string[];
  contentTags: string[];
  minAge: OperatorFilterValue<number>;
  grade: Grade | '';
  spellingType: SpellingType | '';
}

export function LibrarySearchV2Drawer(props: {
  show: boolean;
  onClose: () => void;
  value: AdminQueryLibraryV2;
  onApply: (updatedValue: Partial<AdminQueryLibraryV2>) => void;
}) {
  const { control, handleSubmit, setValue, reset, watch } = useForm<Form>({
    defaultValues: {
      isActive: props.value.isActive !== undefined ? props.value.isActive : '',
      publisher: props.value.publisher !== undefined ? props.value.publisher : '',
      subject: props.value.subject !== undefined ? props.value.subject : '',
      language: props.value.language !== undefined ? props.value.language : '',
      resourceType: props.value.resourceType !== undefined ? props.value.resourceType : '',
      instructionalFocus: props.value.instructionalFocus ?? [],
      instructionalUnits: props.value.instructionalUnits ?? [],
      filterLevelPrioritizedSkills: props.value.filterLevelPrioritizedSkills ?? [],
      levellingSystem: props.value.levellingSystem !== undefined ? props.value.levellingSystem : '',
      levels: props.value.levels ?? [],
      isDecodableLibrary: props.value.isDecodableLibrary ?? '',
      isDoublePageEnabled: props.value.isDoublePageEnabled ?? '',
      isStandardDoublePage: props.value.isStandardDoublePage ?? '',
      decodableFormat: props.value.decodableFormat !== undefined ? props.value.decodableFormat : '',
      seq: props.value.seq
        ? {
            isEnabled: true,
            operator: props.value.seq.operator,
            value: props.value.seq.value,
          }
        : { isEnabled: false },
      genre: props.value.genre !== undefined ? props.value.genre : '',
      subGenre: props.value.subGenre !== undefined ? props.value.subGenre : '',
      topic: props.value.topic !== undefined ? props.value.topic : '',
      subTopic: props.value.subTopic !== undefined ? props.value.subTopic : '',
      theme: props.value.theme !== undefined ? props.value.theme : '',
      subTheme: props.value.subTheme !== undefined ? props.value.subTheme : '',
      format: props.value.format !== undefined ? props.value.format : '',
      hiLo: props.value.hiLo !== undefined ? props.value.hiLo : '',
      nonConforming: props.value.nonConforming !== undefined ? props.value.nonConforming : '',
      series: props.value.series !== undefined ? props.value.series : '',
      collections: props.value.collections ?? [],
      contentTags: props.value.contentTags ?? [],
      minAge: props.value.minAge
        ? {
            isEnabled: true,
            operator: props.value.minAge.operator,
            value: props.value.minAge.value,
          }
        : { isEnabled: false },
      grade: props.value.grade !== undefined ? props.value.grade : '',
      spellingType: props.value.spellingType !== undefined ? props.value.spellingType : '',
    },
  });

  const [generalOpen, setGeneralOpen] = useState<boolean>(true);
  const [wordReadingOpen, setWordReadingOpen] = useState<boolean>(true);
  const [textReadingOpen, setTextReadingOpen] = useState<boolean>(true);

  const handleClose = () => {
    reset();
    props.onClose();
  };

  const handleClearFilters = () => {
    reset({
      isActive: '',
      publisher: '',
      subject: '',
      language: '',
      resourceType: '',
      instructionalFocus: [],
      instructionalUnits: [],
      filterLevelPrioritizedSkills: [],
      levellingSystem: '',
      levels: [],
      isDecodableLibrary: '',
      isDoublePageEnabled: '',
      isStandardDoublePage: '',
      decodableFormat: '',
      seq: { isEnabled: false },
      genre: '',
      subGenre: '',
      topic: '',
      subTopic: '',
      theme: '',
      subTheme: '',
      format: '',
      hiLo: '',
      nonConforming: '',
      series: '',
      collections: [],
      contentTags: [],
      minAge: { isEnabled: false },
      grade: '',
      spellingType: '',
    });
  };

  const handleGeneralClick = () => {
    setGeneralOpen((currentState) => !currentState);
  };

  const handleWordReadingClick = () => {
    setWordReadingOpen((currentState) => !currentState);
  };

  const handleTextReadingClick = () => {
    setTextReadingOpen((currentState) => !currentState);
  };

  const onSubmit = (data: Form) => {
    props.onApply({
      isActive: typeof data.isActive === 'boolean' ? data.isActive : undefined,
      publisher: !!data.publisher ? data.publisher : undefined,
      subject: !!data.subject ? data.subject : undefined,
      language: !!data.language ? data.language : undefined,
      resourceType: !!data.resourceType ? data.resourceType : undefined,
      instructionalFocus: data.instructionalFocus,
      instructionalUnits: data.instructionalUnits,
      filterLevelPrioritizedSkills: data.filterLevelPrioritizedSkills,
      levellingSystem: !!data.levellingSystem ? data.levellingSystem : undefined,
      levels: data.levels,
      isDecodableLibrary: typeof data.isDecodableLibrary === 'boolean' ? data.isDecodableLibrary : undefined,
      isDoublePageEnabled: typeof data.isDoublePageEnabled === 'boolean' ? data.isDoublePageEnabled : undefined,
      isStandardDoublePage: typeof data.isStandardDoublePage === 'boolean' ? data.isStandardDoublePage : undefined,
      decodableFormat: !!data.decodableFormat ? data.decodableFormat : undefined,
      seq:
        data.seq && data.seq.isEnabled && data.seq.operator && data.seq.value
          ? {
              operator: data.seq.operator,
              value: data.seq.value,
            }
          : undefined,
      genre: !!data.genre ? data.genre : undefined,
      subGenre: !!data.subGenre ? data.subGenre : undefined,
      topic: !!data.topic ? data.topic : undefined,
      subTopic: !!data.subTopic ? data.subTopic : undefined,
      theme: !!data.theme ? data.theme : undefined,
      subTheme: !!data.subTheme ? data.subTheme : undefined,
      format: !!data.format ? data.format : undefined,
      hiLo: typeof data.hiLo === 'boolean' ? data.hiLo : undefined,
      nonConforming: typeof data.nonConforming === 'boolean' ? data.nonConforming : undefined,
      series: !!data.series ? data.series : undefined,
      collections: data.collections,
      contentTags: data.contentTags,
      minAge:
        data.minAge && data.minAge.isEnabled && data.minAge.operator && data.minAge.value
          ? {
              operator: data.minAge.operator,
              value: data.minAge.value,
            }
          : undefined,
      grade: !!data.grade ? data.grade : undefined,
      spellingType: !!data.spellingType ? data.spellingType : undefined,
    });
  };

  const { levellingSystem } = watch();

  return (
    <Drawer
      sx={{
        width: '360px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          padding: '24px',
          paddingBottom: 0,
          width: '360px',
          boxSizing: 'border-box',
        },
      }}
      anchor="right"
      open={props.show}
      onClose={handleClose}
    >
      <Toolbar />
      <Stack marginTop="20px" direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          style={{
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '48px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          Library Filters
        </Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Stack>

      <Divider sx={{ marginTop: '8px' }} />

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* General */}

        <Stack alignItems="center" justifyContent="space-between" direction="row">
          <Typography variant="labelLarge">General</Typography>
          <IconButton onClick={handleGeneralClick}>{generalOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}</IconButton>
        </Stack>

        <Collapse in={generalOpen} timeout="auto">
          <Stack spacing="24px">
            {/* Is Active */}
            <Controller
              control={control}
              name="isActive"
              render={({ field }) => (
                <YesNoSelect id="is-active" labelId="is-active-label" label="Is Active" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Publisher */}
            <Controller
              control={control}
              name="publisher"
              render={({ field }) => (
                <PublisherSelect id="publisher" labelId="publisher-label" label="Publisher" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Subject */}
            <Controller
              control={control}
              name="subject"
              render={({ field }) => (
                <SubjectSelect id="subject" labelId="subject-label" label="Subject" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Language */}
            <Controller
              control={control}
              name="language"
              render={({ field }) => (
                <LanguageSelect id="language" labelId="language-label" label="Language" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Spelling Type */}
            <Controller
              control={control}
              name="spellingType"
              render={({ field }) => (
                <SpellingTypeSelect
                  id="spellingType"
                  labelId="spelling-type-label"
                  label="Spelling Type"
                  fullWidth
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />

            {/* Resource Type */}
            <Controller
              control={control}
              name="resourceType"
              render={({ field }) => (
                <ResourceTypeSelect
                  id="resource-type"
                  labelId="resource-type-label"
                  label="Resource Type"
                  fullWidth
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />

            {/* Instructional Focus */}
            <Controller
              control={control}
              name="instructionalFocus"
              render={({ field }) => <InstructionalFocusAutocomplete onChange={field.onChange} value={field.value} />}
            />

            {/* Instructional Unit */}
            <Controller
              control={control}
              name="instructionalUnits"
              render={({ field }) => <InstructionalUnitAutocomplete onChange={field.onChange} value={field.value} />}
            />

            {/* Prioritized Skill */}
            <Controller
              control={control}
              name="filterLevelPrioritizedSkills"
              render={({ field }) => <FilterLevelPrioritizedSkillAutocomplete onChange={field.onChange} value={field.value} />}
            />

            {/* Levelling System */}
            <Controller
              control={control}
              name="levellingSystem"
              render={({ field }) => (
                <LevellingSystemSelect
                  id="levelling-system"
                  labelId="levelling-system-label"
                  label="Levelling System"
                  fullWidth
                  onChange={(val) => {
                    field.onChange(val);
                    setValue('levels', []);
                  }}
                  value={field.value}
                />
              )}
            />

            {/* Level */}
            <Controller
              control={control}
              name="levels"
              render={({ field }) => <LevelMultiAutocomplete levelSytem={levellingSystem} onChange={field.onChange} value={field.value} />}
            />

            {/* Decodable Library */}
            <Controller
              control={control}
              name="isDecodableLibrary"
              render={({ field }) => (
                <YesNoSelect
                  id="is-decodable-library"
                  labelId="is-decodable-library-label"
                  label="Is Decodable Library"
                  fullWidth
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />

            {/* Double Page Enabled */}
            <Controller
              control={control}
              name="isDoublePageEnabled"
              render={({ field }) => (
                <YesNoSelect
                  id="is-double-page-enabled"
                  labelId="is-double-page-enabled-label"
                  label="Is Double Page Enabled"
                  fullWidth
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />

            {/* Standard Double Page */}
            <Controller
              control={control}
              name="isStandardDoublePage"
              render={({ field }) => (
                <YesNoSelect
                  id="is-standard-double-page"
                  labelId="is-standard-double-page-label"
                  label="Is Standard Double Page"
                  fullWidth
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </Stack>
        </Collapse>

        {/* Word Reading */}

        <Stack marginTop="24px" alignItems="center" justifyContent="space-between" direction="row">
          <Typography variant="labelLarge">Word Reading</Typography>
          <IconButton onClick={handleWordReadingClick}>{wordReadingOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}</IconButton>
        </Stack>

        <Collapse in={wordReadingOpen} timeout="auto">
          {/* Decodable Format */}
          <Stack spacing="24px">
            <Controller
              control={control}
              name="decodableFormat"
              render={({ field }) => (
                <DecodableFormatSelect
                  id="decodable-format"
                  labelId="decodable-format-label"
                  label="Decodable Format"
                  fullWidth
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />

            {/* Sequence */}
            <Controller
              name="seq"
              control={control}
              render={({ field }) => <OperatorFilter type="number" label="Sequence" onChange={field.onChange} value={field.value} />}
            />
          </Stack>
        </Collapse>

        {/* Text Reading */}

        <Stack marginTop="24px" alignItems="center" justifyContent="space-between" direction="row">
          <Typography variant="labelLarge">Text Reading</Typography>
          <IconButton onClick={handleTextReadingClick}>{textReadingOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}</IconButton>
        </Stack>

        <Collapse in={textReadingOpen} timeout="auto">
          <Stack spacing="24px">
            {/* Grade */}
            <Controller
              control={control}
              name="grade"
              render={({ field }) => (
                <GradeSelect id="grade" labelId="grade-label" label="Grade" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Genre */}
            <Controller
              control={control}
              name="genre"
              render={({ field }) => (
                <GenreSelect id="genre" labelId="genre-label" label="Genre" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Sub-Genre */}
            <Controller
              control={control}
              name="subGenre"
              render={({ field }) => (
                <SubGenreSelect id="sub-genre" labelId="sub-genre-label" label="SubGenre" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Topic */}
            <Controller
              control={control}
              name="topic"
              render={({ field }) => (
                <TopicSelect id="topic" labelId="topic-label" label="Topic" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Sub-Topic */}
            <Controller
              control={control}
              name="subTopic"
              render={({ field }) => (
                <SubTopicSelect id="sub-topic" labelId="sub-topic-label" label="SubTopic" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Theme */}
            <Controller
              control={control}
              name="theme"
              render={({ field }) => (
                <ThemeSelect id="theme" labelId="theme-label" label="Theme" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Sub-Theme */}
            <Controller
              control={control}
              name="subTheme"
              render={({ field }) => (
                <SubThemeSelect id="sub-theme" labelId="sub-theme-label" label="SubTheme" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Format */}
            <Controller
              control={control}
              name="format"
              render={({ field }) => (
                <FormatSelect id="format" labelId="format-label" label="Format" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Hi-Lo */}
            <Controller
              control={control}
              name="hiLo"
              render={({ field }) => (
                <YesNoSelect id="hi-lo" labelId="hi-lo-label" label="HiLo" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Non-Conforming */}
            <Controller
              control={control}
              name="nonConforming"
              render={({ field }) => (
                <YesNoSelect
                  id="non-conforming"
                  labelId="non-conforming-label"
                  label="NonConforming"
                  fullWidth
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />

            {/* Series */}
            <Controller
              control={control}
              name="series"
              render={({ field }) => (
                <SeriesSelect id="series" labelId="series-label" label="Series" fullWidth onChange={field.onChange} value={field.value} />
              )}
            />

            {/* Collections */}
            <Controller
              control={control}
              name="collections"
              render={({ field }) => <CollectionsAutocomplete onChange={field.onChange} value={field.value} />}
            />

            {/* Min Age */}
            <Stack>
              <Controller
                name="minAge"
                control={control}
                render={({ field }) => <OperatorFilter type="number" label="Age" onChange={field.onChange} value={field.value} />}
              />
            </Stack>

            {/* Content Tags */}
            <Controller
              control={control}
              name="contentTags"
              render={({ field }) => <ContentTagsAutocomplete onChange={field.onChange} value={field.value} />}
            />
          </Stack>
        </Collapse>

        <Grid container p={2} sx={{ position: 'sticky', bottom: 0, marginTop: '12px', zIndex: 1, background: '#fff' }}>
          <Grid item container>
            <Button type="submit" size="small" variant="contained" fullWidth>
              Apply
            </Button>
          </Grid>
          <Grid item container sx={{ marginTop: '16px' }}>
            <Button color="inherit" type="button" onClick={handleClearFilters} size="small" variant="contained" fullWidth>
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  );
}
