import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface UpdateDistrictSchoolRequest {
  name: string;
  country: string;
  provinceState: string;
  reporting: boolean;
  city?: string;
  timeZone?: string;
}

const updateDistrictSchool = async (locationId: string, request: UpdateDistrictSchoolRequest): Promise<void> => {
  const { data } = await axios.post<void>(`${config.apiUrl}/admin/hfs/district-schools/${locationId}`, request);
  return data;
};

const useUpdateDistrictSchoolRequest = (
  locationId: string,
  options?: Omit<UseMutationOptions<any, AxiosError, UpdateDistrictSchoolRequest>, 'mutationFn' | 'mutationKey'>,
) => {
  return useMutation<void, AxiosError, UpdateDistrictSchoolRequest>((request) => updateDistrictSchool(locationId, request), options);
};

export default useUpdateDistrictSchoolRequest;
