import { ArrowDropDown } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, Popover, Stack, Typography } from '@mui/material';
import { StaticTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import typography from '@hoot/theme/typography';
import { ICheckBoxValue } from '../../hfs-interfaces';

export interface IFilter {
  label: string;
  queryName?: string;
  options: IFilterOption[];
}

export interface IFilterOption {
  label: string;
  queryValue?: string;
}

export enum FilterSelectionType {
  Checkbox = 'checkbox',
  Timepicker = 'timepicker',
  Boolean = 'boolean',
}

export interface IFilterSelection {
  label: string;
  queryName?: string;
  values: IFilterSelectionValue[];
  type?: FilterSelectionType;
}

export type IFilterSelectionValue = IFilterOption & { isSelected: boolean };

export interface TimePickerPopoverProps {
  name: string;
  values: IFilterSelectionValue[];
  setFiltersData: Dispatch<SetStateAction<IFilterSelection[]>>;
  filtersData: IFilterSelection[];
}
interface CheckboxPopoverRowProps {
  option: IFilterSelectionValue;
  onChange: () => void;
}

export const CheckboxPopoverRow = (props: CheckboxPopoverRowProps) => {
  const { option, onChange } = props;
  const { label, isSelected } = option;

  return (
    <FormControlLabel
      sx={{
        pt: 2,
        pb: 2,
        pl: 3,
        pr: 3,
        m: 0,
        '& .MuiFormControlLabel-label': {
          ...typography.bodyLarge,
        },
      }}
      control={<Checkbox checked={isSelected} onChange={onChange} sx={{ mr: 1.5 }} />}
      label={label}
    />
  );
};

const CheckboxPopoverRows = (props: ICheckBoxValue) => {
  const { name, values, setFiltersData, filtersData } = props;

  const handleCheck = (index: number) => {
    const oldValue = values[index];

    const newValue: IFilterSelectionValue = { ...oldValue, isSelected: !oldValue.isSelected };

    const updated: IFilterSelection[] = filtersData.map((filter) => {
      return {
        ...filter,
        values: filter.values.map((v) => {
          return filter.label === name && v.label === newValue.label ? newValue : v;
        }),
      };
    });

    setFiltersData(updated);
  };

  return (
    <Stack
      sx={{
        '& > :nth-child(n+2)': {
          borderTop: '1px solid #CAC4D0',
        },
      }}
    >
      {values.map((v, index) => (
        <React.Fragment key={index}>
          <CheckboxPopoverRow option={v} onChange={() => handleCheck(index)} />
        </React.Fragment>
      ))}
    </Stack>
  );
};

const BooleanPopoverRows = (props: ICheckBoxValue) => {
  const { name, values, setFiltersData, filtersData } = props;

  const handleCheck = (index: number) => {
    if (values[index].isSelected) {
      return;
    }

    const updated: IFilterSelection[] = filtersData.map((filter) => {
      return {
        ...filter,
        values: filter.values.map((v) => {
          return filter.label === name ? { ...v, isSelected: !v.isSelected } : v;
        }),
      };
    });
    setFiltersData(updated);
  };

  return (
    <Stack
      sx={{
        '& > :nth-child(n+2)': {
          borderTop: '1px solid #CAC4D0',
        },
      }}
    >
      {values.map((v, index) => (
        <React.Fragment key={index}>
          <CheckboxPopoverRow option={v} onChange={() => handleCheck(index)} />
        </React.Fragment>
      ))}
    </Stack>
  );
};

const TimePickerPopover = (props: TimePickerPopoverProps) => {
  const { name, setFiltersData, filtersData } = props;

  const handleDateChange = (time: DateTime | null) => {
    if (!time) {
      return;
    }

    const newTime = time.toFormat('h:mm a');

    const newValue: IFilterSelectionValue = { isSelected: true, label: newTime, queryValue: newTime };

    const updated: IFilterSelection[] = filtersData.map((filter) => {
      return {
        ...filter,
        values: filter.values.map((v) => {
          return filter.label === name ? newValue : v;
        }),
      };
    });

    setFiltersData(updated);
  };

  return <StaticTimePicker onAccept={handleDateChange} />;
};

const BasicPopover = (props: {
  setFiltersData: React.Dispatch<React.SetStateAction<IFilterSelection[]>>;
  title: string;
  type?: FilterSelectionType;
  values: IFilterSelectionValue[];
  filtersData: IFilterSelection[];
  dismissPopoverOnSelection: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { title, values, type, setFiltersData, filtersData, dismissPopoverOnSelection } = props;

  useEffect(() => {
    if (dismissPopoverOnSelection) {
      handleClose();
    }
  }, [dismissPopoverOnSelection, filtersData]);

  const handlePopoverType = useCallback(() => {
    if (type === FilterSelectionType.Timepicker) {
      return <TimePickerPopover name={title} values={values} setFiltersData={setFiltersData} filtersData={filtersData} />;
    } else if (type === FilterSelectionType.Boolean) {
      return <BooleanPopoverRows name={title} values={values} setFiltersData={setFiltersData} filtersData={filtersData} />;
    } else {
      return <CheckboxPopoverRows name={title} values={values} setFiltersData={setFiltersData} filtersData={filtersData} />;
    }
  }, [filtersData, setFiltersData, title, type, values]);

  return (
    <div>
      <Button
        startIcon={<ArrowDropDown style={{ fill: '#1976D2' }} />}
        aria-describedby={id}
        sx={{
          height: '32px',
          background: 'linear-gradient(0deg, rgba(0, 95, 175, 0.05), rgba(0, 95, 175, 0.05)), #FDFCFF',
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
          borderRadius: '8px',
          textTransform: 'none',
          color: '#1A1C1E',
          fontWeight: '600',
          fontSize: '14px',
          lineHeight: '20px',
          mx: 1,
        }}
        onClick={handleClick}
      >
        {title}
      </Button>

      <Popover
        sx={{ mt: 1, maxHeight: '500px' }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {handlePopoverType()}
      </Popover>
    </div>
  );
};

interface FiltersDropDownProps {
  filterSelections: IFilterSelection[];
  setFilterSelections: React.Dispatch<React.SetStateAction<IFilterSelection[]>>;
  dismissPopoverOnSelection?: boolean;
}

const FilterSelects = (props: FiltersDropDownProps) => {
  const { filterSelections, setFilterSelections, dismissPopoverOnSelection = true } = props;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Typography
        sx={{
          fontSize: '12px',
          pt: 1,
          mr: 1,
        }}
      >
        Filters
      </Typography>

      {filterSelections.map((fl) => (
        <BasicPopover
          key={fl.label}
          title={fl.label}
          type={fl.type}
          values={fl.values}
          setFiltersData={setFilterSelections}
          filtersData={filterSelections}
          dismissPopoverOnSelection={dismissPopoverOnSelection}
        />
      ))}
    </Box>
  );
};

export default FilterSelects;
