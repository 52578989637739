import { ScheduledLessonStatus, ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';
import { SortOrder } from '@hoot/interfaces/order-by';
import { ShiftLessonOrderBy } from '@hoot/pages/users/teachers/shifts/TeacherShiftDetailsPage';

export interface TeacherShiftLessonsResponse {
  lessons: TeacherShiftLesson[];
}

export interface TeacherShiftLesson {
  id: string;
  number: number;
  preFixedNumber: string;
  type: ScheduledLessonType;
  startsAt: number;
  duration: number;
  status: ScheduledLessonStatus;
}

export interface TeacherShiftLessonsQuery {
  shiftId: string;
  sortColumn: ShiftLessonOrderBy;
  sortDirection: SortOrder;
}

export const teacherShiftLessonsQueryKey = (query: TeacherShiftLessonsQuery) => {
  return `${query.shiftId}-${query.sortColumn}-${query.sortDirection}`;
};

async function getTeacherShiftLessons(query: TeacherShiftLessonsQuery): Promise<TeacherShiftLessonsResponse> {
  const { data } = await axios.get(
    `${config.apiUrl}/admin/teacher-shift/${query.shiftId}/lessons?sortColumn=${query.sortColumn}&sortDirection=${query.sortDirection}`,
  );
  return data;
}

export default function useGetTeacherShiftLessons(
  query: TeacherShiftLessonsQuery,
  options?: Omit<UseQueryOptions<TeacherShiftLessonsResponse, AxiosError<ErrorResponseDto>>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TeacherShiftLessonsResponse, AxiosError<ErrorResponseDto>> {
  return useQuery([QueryKey.GetTeacherShiftLessons, teacherShiftLessonsQueryKey(query)], () => getTeacherShiftLessons(query), options);
}
