import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Card, CardContent, CardHeader, FormHelperText, Grid, InputAdornment, TextField as MUITextField, Stack, Typography } from '@mui/material';
import { Control } from 'react-hook-form';
import { FormState, UseFormWatch } from 'react-hook-form/dist/types/form';
import { YearPicker } from '@hoot/components/form/DatePicker';
import { TextField } from '@hoot/components/form/TextField';
import { StudentProfileResponse } from '@hoot/hooks/api/end-users/useGetStudentProfile';
import { StudentDetailsForm } from '../form';

const StudentPersonalInfoCard = (props: {
  watch: UseFormWatch<StudentDetailsForm>;
  control: Control<StudentDetailsForm>;
  formState: FormState<StudentDetailsForm>;
  studentProfile?: StudentProfileResponse;
  disableFields: boolean;
}) => {
  const { watch, control, studentProfile, disableFields } = props;
  const { hfsStudentNumber } = watch();

  return (
    <Card sx={{ pl: 3, pt: 3, pr: 3 }}>
      <CardHeader
        sx={{ p: 0, pb: 3 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="titleLarge">
              Personal Information
            </Typography>
          </Stack>
        }
      />

      <CardContent sx={{ p: 0 }}>
        <Stack gap={3}>
          <Grid container item xs={12}>
            <Grid sx={{ paddingRight: '12px' }} container item xs={6}>
              <TextField
                name="name"
                label="Full name*"
                rules={{
                  maxLength: { value: 100, message: 'Name is too long' },
                  required: { value: true, message: 'Name is required' },
                }}
                control={control}
                variant="outlined"
                fullWidth
                helperText={''}
                disabled={disableFields}
              />
            </Grid>
            <Grid sx={{ paddingRight: '12px', paddingLeft: '12px' }} container item xs={3}>
              <TextField name="pronouns" label="Pronouns" control={control} variant="outlined" fullWidth disabled={disableFields} />
            </Grid>
            <Grid sx={{ paddingLeft: '12px' }} container item xs={3}>
              <YearPicker name="birthYear" label="Birth Year" control={control} variant="outlined" disabled={disableFields} />
            </Grid>
          </Grid>
          <Grid sx={{ marginTop: '8px' }} container item xs={12}>
            <Grid container item xs={6} sx={{ paddingRight: '12px' }}>
              <MUITextField
                name="number"
                label="Hoot Student Number"
                value={studentProfile?.prefixedNumber ?? 'Empty/View-Only'}
                variant="outlined"
                fullWidth
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText sx={{ paddingLeft: 2, marginBottom: -2, color: '#1C1B1F' }}>This is a read only field</FormHelperText>
            </Grid>

            <Grid container item xs={6} sx={{ paddingLeft: '12px' }}>
              <TextField
                disabled={!hfsStudentNumber || disableFields}
                rules={{
                  required: !!hfsStudentNumber,
                  minLength: hfsStudentNumber ? 2 : undefined,
                }}
                name="hfsStudentNumber"
                label="HFS Student Number"
                control={control}
                variant="outlined"
                fullWidth
              />
              <FormHelperText sx={{ paddingLeft: 2, marginBottom: -2, color: '#1C1B1F' }}>Used for in-school logins</FormHelperText>
            </Grid>
          </Grid>
          <TextField name="interests" label="Interests" control={control} variant="outlined" fullWidth disabled={disableFields} />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default StudentPersonalInfoCard;
