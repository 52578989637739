import { DateTime } from 'luxon';
import { SelectedTeacher } from '@hoot/components/modals/SelectTeacher';
import CreateBlockWizard from './CreateBlockWizard';
import CreateBlockContextProvider from './context/CreateBlockContextProvider';

const CreateBlockPage = (props: {
  blockStartTime?: DateTime;
  blockEndTime?: DateTime;
  teacher?: SelectedTeacher;
  preSelectedTeacher?: boolean;
  closeModal?: () => void;
}) => {
  return (
    <CreateBlockContextProvider
      blockStartTime={props.blockStartTime}
      blockEndTime={props.blockEndTime}
      teacher={props.teacher}
      closeModal={props.closeModal}
      preSelectedTeacher={props.preSelectedTeacher}
    >
      <CreateBlockWizard />
    </CreateBlockContextProvider>
  );
};

export default CreateBlockPage;
