import { Box, BoxProps } from '@mui/material';
import React, { useMemo } from 'react';
import ActiveFilters from './ActiveFilters';
import FilterSelects, { IFilterSelection } from './FilterSelects';

interface SearchFiltersProps {
  filterSelections: IFilterSelection[];
  setFilterSelections: React.Dispatch<React.SetStateAction<IFilterSelection[]>>;
  sx?: BoxProps['sx'];
}

const SearchFilters = (props: SearchFiltersProps) => {
  const { sx, setFilterSelections, filterSelections } = props;

  const atLeastOneActiveFilter = useMemo(() => {
    return filterSelections.flatMap((x) => x.values).some((x) => x.isSelected);
  }, [filterSelections]);

  return (
    <Box sx={sx}>
      <FilterSelects filterSelections={filterSelections} setFilterSelections={setFilterSelections} />
      {atLeastOneActiveFilter && <ActiveFilters filterSelections={filterSelections} setFilterSelections={setFilterSelections} sx={{ mt: 3 }} />}
    </Box>
  );
};

export default SearchFilters;
