import { SvgIcon, SvgIconProps } from '@mui/material';

const FullCircleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="4.66699" cy="4" r="4" fill={props.fill ?? '#2E7D32'} />
    </SvgIcon>
  );
};

export default FullCircleIcon;
