import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { Close } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { DistrictSchoolDetailResponse } from '@hoot/hooks/api/hfs/useGetDistrictSchool';
import useSearchLocationEnrolments from '@hoot/hooks/api/hfs/useSearchLocationEnrolments';
import useSearchLocationProducts, { GetLocationProductsResponse } from '@hoot/hooks/api/hfs/useSearchLocationProducts';
import CreateProductModal from '@hoot/pages/district-schools/products-and-enrolments/CreateProductModal';
import { routes } from '@hoot/routes/routes';
import EnrolmentsTable from './EnrolmentsTable';
import ProductsTable from './ProductsTable';

const ProductsCard = (props: { locationId: string; products: GetLocationProductsResponse[] }) => {
  const { locationId, products } = props;

  const [showCreateProductModal, setShowCreateProductModal] = useState<boolean>(false);

  const handleCloseCreateProductModal = () => {
    setShowCreateProductModal(false);
  };

  const createButton = () => (
    <Button type="button" variant="contained" onClick={() => setShowCreateProductModal(true)}>
      Create
    </Button>
  );

  return (
    <Card>
      <CardHeader
        sx={{ p: 3 }}
        title={
          <Stack sx={{ p: 0 }} direction="row" justifyContent="space-between">
            <Typography component="h3" variant="titleLarge">
              Products
            </Typography>
          </Stack>
        }
        action={createButton()}
      />

      <CardContent>
        <ProductsTable locationId={locationId} products={products} />
      </CardContent>
      {showCreateProductModal ? (
        <CreateProductModal locationId={locationId} open={showCreateProductModal} onClose={handleCloseCreateProductModal} />
      ) : null}
    </Card>
  );
};

const EnrolmentsCard = (props: { location: DistrictSchoolDetailResponse; activeRequestedProductCount: number }) => {
  const { location, activeRequestedProductCount } = props;

  const navigate = useNavigate();
  const { error } = useAlert();

  const [productWarningDialogOpen, setProductWarningDialogOpen] = useState(false);

  const handleCreate = () => {
    if (activeRequestedProductCount === 0) {
      setProductWarningDialogOpen(true);
    } else {
      navigate(
        routes.districtsSchools.enrolments.create.url(location.id, location.type, location.name, location.friendlyId, location.isBillableEntity),
      );
    }
  };

  const handleDialogClose = () => {
    setProductWarningDialogOpen(false);
  };

  const enrolmentsSearchResult = useSearchLocationEnrolments(location.id, {
    onError: (err) => {
      console.error(err);
      error('There was an error getting the enrolments for the billable entity!');
    },
  });

  const createButton = () => (
    <Button type="button" variant="contained" onClick={handleCreate}>
      Create
    </Button>
  );

  return (
    <>
      <Card>
        <CardHeader
          sx={{ p: 3 }}
          title={
            <Typography component="h3" variant="titleLarge">
              Enrolments
            </Typography>
          }
          action={createButton()}
        />
        <CardContent>
          <EnrolmentsTable enrolments={enrolmentsSearchResult.data?.enrolments ?? []} locationType={location.type} locationId={location.id} />
        </CardContent>
      </Card>
      <Dialog open={productWarningDialogOpen} onClose={handleDialogClose} scroll="paper">
        <Stack sx={{ padding: '24px', position: 'relative' }} gap={3}>
          <DialogTitle sx={{ padding: 0 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5">Add a product first</Typography>
              <IconButton onClick={handleDialogClose} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
                <Close />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <Typography>You need a product associated to this school or district to add an enrolment.</Typography>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ padding: 0 }}>
            <Stack direction="row" justifyContent="flex-end" gap={2}>
              <Button
                sx={{
                  fontSize: 14,
                  color: '#000',
                }}
                fullWidth
                size="medium"
                color="inherit"
                variant="outlined"
                onClick={handleDialogClose}
              >
                Okay
              </Button>
            </Stack>
          </DialogActions>
        </Stack>
      </Dialog>
    </>
  );
};

const ProductsAndEnrolmentsPage = (props: { location: DistrictSchoolDetailResponse }) => {
  const { location } = props;
  const { error } = useAlert();

  const productsSearchResult = useSearchLocationProducts(location.id, {
    onError: (err) => {
      console.error(err);
      error('There was an error getting the products for the billable entity!');
    },
  });

  const requestedOrActiveCnt =
    productsSearchResult.data?.filter((x) => {
      return [ProductStatus.Active, ProductStatus.Requested].includes(x.status);
    }).length ?? 0;

  return (
    <Stack gap={3}>
      <ProductsCard locationId={location.id} products={productsSearchResult.data ?? []} />
      <EnrolmentsCard location={location} activeRequestedProductCount={requestedOrActiveCnt} />
    </Stack>
  );
};

export default ProductsAndEnrolmentsPage;
