import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

export enum AccountType {
  DistrictRepresentative = 'District Representative',
  Student = 'Student',
  Teacher = 'Teacher',
}

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  accountType: AccountType;
  onSave?: () => void;
  customMessage?: string;
}

export function ActionUnavailableModal(props: Props) {
  const { setOpen, open, accountType, onSave, customMessage } = props;

  const closeModal = () => setOpen(false);

  return (
    <Dialog onBackdropClick={undefined} open={open} onClose={closeModal}>
      <Stack sx={{ padding: '24px', position: 'relative' }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">Save Required</Typography>
            <IconButton onClick={closeModal} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText textAlign="left" sx={{ color: '#1C1B1F' }}>
            {customMessage ?? `To use this feature, please save the ${accountType} first.`}
            <br />
            Once you've saved the {accountType}, this feature will be enabled and ready for use.
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="center" gap={2}>
            <Button
              sx={{
                fontSize: 14,
              }}
              size="large"
              color="inherit"
              variant="outlined"
              onClick={closeModal}
            >
              Cancel
            </Button>
            {onSave ? (
              <Button
                sx={{
                  fontSize: 14,
                  textTransform: 'none',
                }}
                size="large"
                variant="contained"
                onClick={onSave}
              >
                Save now
              </Button>
            ) : null}
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}
