import { DistrictRepEnrolmentAccessLevel } from '@hoot-reading/hoot-core/dist/enums/hfs/district-rep-enrolment-access-level.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { PropsWithChildren, ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import useAssignDistrictRepPermissions, { AssignDistrictRepPermissionsRequest } from '@hoot/hooks/api/hfs/useAssignDistrictRepPermissions';
import useCreateAssociationDistrictRepLocation, {
  CreateAssociationDistrictRepLocationRequest,
} from '@hoot/hooks/api/hfs/useCreateAssociationDistrictRepLocation';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { DistrictRepresentativeResponse } from '@hoot/hooks/api/user/district-representative/useGetDistrictRepresentative';
import BillableEntitySelectionStep from '@hoot/pages/users/district-reps/district-rep-permissions-wizard/steps/BillableEntitySelectionStep';
import EnrolmentSelectionStep from './steps/EnrolmentSelectionStep';
import PermissionsStep from './steps/PermissionsStep';
import SchoolSelectionStep from './steps/SchoolSelectionStep';
import SummaryStep from './steps/SummaryStep';

export enum DistrictRepPermissionsWizardStepEnumCombo1 {
  BillableEntitySelection = 0,
  Permissions = 1,
  Summary = 2,
}

export enum DistrictRepPermissionsWizardStepEnumCombo2 {
  BillableEntitySelection = 0,
  Permissions = 1,
  SchoolSelection = 2,
  Summary = 3,
}

export enum DistrictRepPermissionsWizardStepEnumCombo3 {
  BillableEntitySelection = 0,
  Permissions = 1,
  EnrolmentSelection = 2,
  Summary = 3,
}

export enum DistrictRepPermissionsWizardStepEnumCombo4 {
  BillableEntitySelection = 0,
  Permissions = 1,
  SchoolSelection = 2,
  EnrolmentSelection = 3,
  Summary = 4,
}

type DistrictRepPermissionsWizardStepEnum =
  | DistrictRepPermissionsWizardStepEnumCombo1
  | DistrictRepPermissionsWizardStepEnumCombo2
  | DistrictRepPermissionsWizardStepEnumCombo3
  | DistrictRepPermissionsWizardStepEnumCombo4;

export enum PermissionsCombination {
  Combo1 = 1,
  Combo2 = 2,
  Combo3 = 3,
  Combo4 = 4,
}

export interface DistrictRepPermissionsWizardStep {
  id: DistrictRepPermissionsWizardStepEnum;
  render: () => ReactNode;
}

interface BillableEntityState {
  id: string;
  name: string;
  type: LocationType;
}

interface PermissionsState {
  districtEdit: boolean;
  schoolEdit: boolean;
  enrolmentEdit: boolean;
  enrolmentView: boolean;
}

export interface SchoolState {
  id: string;
  name: string;
}

export interface EnrolmentState {
  id: string;
  friendlyId: string;
  locationId: string;
}

interface DistrictRepPermissionsWizardContextProps {
  currentStep: DistrictRepPermissionsWizardStep;
  districtRep: DistrictRepresentativeResponse;
  billableEntityState: BillableEntityState | undefined;
  permissionsState: PermissionsState | undefined;
  schoolState: SchoolState | undefined;
  enrolmentState: EnrolmentState[] | undefined;
  actions: {
    goToNextStep: (stepId?: number) => void;
    goToPreviousStep: () => void;
    attachBillableEntityData: (
      selectedBillableEntityId: string,
      selectedBillableEntityName: string,
      selectedBillableEntityType: LocationType,
    ) => void;
    attachPermissionsData: (districtEdit: boolean, schoolEdit: boolean, enrolmentEdit: boolean, enrolmentView: boolean) => void;
    attachSchoolData: (schoolId: string, schoolName: string) => void;
    attachEnrolmentData: (selectedEnrolments: EnrolmentState[]) => void;
    submitPermissions: () => void;
    dismissPermissionsWizard: () => void;
    reset: () => void;
  };
}

export const districtRepPermissionsWizardStepsCombo1: DistrictRepPermissionsWizardStep[] = [
  {
    id: DistrictRepPermissionsWizardStepEnumCombo1.BillableEntitySelection,
    render: () => <BillableEntitySelectionStep />,
  },
  {
    id: DistrictRepPermissionsWizardStepEnumCombo1.Permissions,
    render: () => <PermissionsStep />,
  },
  {
    id: DistrictRepPermissionsWizardStepEnumCombo1.Summary,
    render: () => <SummaryStep />,
  },
];

export const districtRepPermissionsWizardStepsCombo2 = [
  {
    id: DistrictRepPermissionsWizardStepEnumCombo2.BillableEntitySelection,
    render: () => <BillableEntitySelectionStep />,
  },
  {
    id: DistrictRepPermissionsWizardStepEnumCombo2.Permissions,
    render: () => <PermissionsStep />,
  },
  {
    id: DistrictRepPermissionsWizardStepEnumCombo2.SchoolSelection,
    render: () => <SchoolSelectionStep />,
  },
  {
    id: DistrictRepPermissionsWizardStepEnumCombo2.Summary,
    render: () => <SummaryStep />,
  },
];

export const districtRepPermissionsWizardStepsCombo3: DistrictRepPermissionsWizardStep[] = [
  {
    id: DistrictRepPermissionsWizardStepEnumCombo3.BillableEntitySelection,
    render: () => <BillableEntitySelectionStep />,
  },
  {
    id: DistrictRepPermissionsWizardStepEnumCombo3.Permissions,
    render: () => <PermissionsStep />,
  },
  {
    id: DistrictRepPermissionsWizardStepEnumCombo3.EnrolmentSelection,
    render: () => <EnrolmentSelectionStep />,
  },
  {
    id: DistrictRepPermissionsWizardStepEnumCombo3.Summary,
    render: () => <SummaryStep />,
  },
];

export const districtRepPermissionsWizardStepsCombo4: DistrictRepPermissionsWizardStep[] = [
  {
    id: DistrictRepPermissionsWizardStepEnumCombo4.BillableEntitySelection,
    render: () => <BillableEntitySelectionStep />,
  },
  {
    id: DistrictRepPermissionsWizardStepEnumCombo4.Permissions,
    render: () => <PermissionsStep />,
  },
  {
    id: DistrictRepPermissionsWizardStepEnumCombo4.SchoolSelection,
    render: () => <SchoolSelectionStep />,
  },
  {
    id: DistrictRepPermissionsWizardStepEnumCombo4.EnrolmentSelection,
    render: () => <EnrolmentSelectionStep />,
  },
  {
    id: DistrictRepPermissionsWizardStepEnumCombo4.Summary,
    render: () => <SummaryStep />,
  },
];

const DistrictRepPermissionsWizardContext = createContext<DistrictRepPermissionsWizardContextProps>(undefined!);

interface DistrictRepPermissionsWizardContextProviderProps extends PropsWithChildren<any> {
  districtRep: DistrictRepresentativeResponse;
  dismissPermissionsWizard: () => void;
  onCompleted?: () => void;
}

const DistrictRepPermissionsWizardContextProvider = (props: DistrictRepPermissionsWizardContextProviderProps) => {
  const { dismissPermissionsWizard, children } = props;
  const queryClient = useQueryClient();

  const createAssociationDistrictRepLocations = useCreateAssociationDistrictRepLocation();
  const assignDistrictRepPermissions = useAssignDistrictRepPermissions();

  const [combination, setCombination] = useState<PermissionsCombination>(PermissionsCombination.Combo1);
  const [currentStep, setCurrentStep] = useState(districtRepPermissionsWizardStepsCombo1[0]);
  const [billableEntityState, setBillableEntityState] = useState<BillableEntityState | undefined>();
  const [permissionsState, setPermissionsState] = useState<PermissionsState | undefined>(undefined);
  const [schoolState, setSchoolState] = useState<SchoolState | undefined>(undefined);
  const [enrolmentState, setEnrolmentState] = useState<EnrolmentState[] | undefined>(undefined);

  const currentCombination = (): DistrictRepPermissionsWizardStep[] => {
    switch (combination) {
      case 1:
        return districtRepPermissionsWizardStepsCombo1;
      case 2:
        return districtRepPermissionsWizardStepsCombo2;
      case 3:
        return districtRepPermissionsWizardStepsCombo3;
      case 4:
        return districtRepPermissionsWizardStepsCombo4;
      default:
        return districtRepPermissionsWizardStepsCombo1;
    }
  };

  const goToStep = (stepId: DistrictRepPermissionsWizardStepEnum) => {
    switch (combination) {
      case 1:
        return setCurrentStep(districtRepPermissionsWizardStepsCombo1[stepId]);
      case 2:
        return setCurrentStep(districtRepPermissionsWizardStepsCombo2[stepId]);
      case 3:
        return setCurrentStep(districtRepPermissionsWizardStepsCombo3[stepId]);
      case 4:
        return setCurrentStep(districtRepPermissionsWizardStepsCombo4[stepId]);
    }
  };

  const goToNextStep = (stepId?: number) => {
    if (currentStep.id >= currentCombination().length) {
      return;
    } else if (stepId) {
      goToStep(stepId);
    } else {
      goToStep(currentStep.id + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep.id <= 0) {
      return;
    } else {
      goToStep(currentStep.id - 1);
    }
  };

  const attachBillableEntityData = (
    selectedBillableEntityId: string,
    selectedBillableEntityName: string,
    selectedBillableEntityLocationType: LocationType,
  ) => {
    setBillableEntityState({ id: selectedBillableEntityId, name: selectedBillableEntityName, type: selectedBillableEntityLocationType });

    const districtRep = props.districtRep;

    if (!districtRep?.locations.length) {
      const request: CreateAssociationDistrictRepLocationRequest = {
        locationId: selectedBillableEntityId,
        districtRepId: props.districtRep.id,
      };

      createAssociationDistrictRepLocations.mutate(request, {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKey.GetDistrictRepresentative, districtRep.id]);
        },
      });
    }
  };

  const attachPermissionsData = (districtEdit: boolean, schoolEdit: boolean, enrolmentEdit: boolean, enrolmentView: boolean) => {
    setPermissionsState({ districtEdit, schoolEdit, enrolmentEdit, enrolmentView });

    // we should clear out school/enrolment state when these changes
    setSchoolState(undefined);
    setEnrolmentState(undefined);

    // We need to determine which "combo" we are using so we can show the correct steps
    if (billableEntityState?.type === LocationType.District) {
      if (districtEdit) {
        setCombination(PermissionsCombination.Combo1);
      } else if (schoolEdit && !districtEdit) {
        setCombination(PermissionsCombination.Combo2);
      } else {
        setCombination(PermissionsCombination.Combo4);
      }
    } else {
      if (schoolEdit) {
        setCombination(PermissionsCombination.Combo1);
      } else {
        setCombination(PermissionsCombination.Combo3);
      }
    }
  };

  const attachEnrolmentData = (selectedEnrolments: EnrolmentState[]) => {
    setEnrolmentState(selectedEnrolments);
  };

  const attachSchoolData = (id: string, name: string) => {
    setSchoolState({ id, name });

    // we should clear out enrolment state when the school changes
    if (schoolState?.id !== id) {
      setEnrolmentState((existingEnrolmentState) => existingEnrolmentState?.filter((enrolment) => enrolment.locationId === id));
    }
  };

  const submitPermissions = () => {
    const request: AssignDistrictRepPermissionsRequest = {
      locationId: schoolState?.id ?? billableEntityState?.id,
      districtRepId: props.districtRep.id,
      enrolmentAccessLevel: !!permissionsState?.enrolmentEdit ? DistrictRepEnrolmentAccessLevel.EditFull : DistrictRepEnrolmentAccessLevel.ViewOnly,
      enrolmentIds: enrolmentState?.map((enrolment) => enrolment.id),
    };

    assignDistrictRepPermissions.mutate(request, {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.GetDistrictRepresentative, props.districtRep.id]);
      },
    });

    clearPermissionsState();
  };

  const clearPermissionsState = useCallback(() => {
    setPermissionsState(undefined);
    setSchoolState(undefined);
    setEnrolmentState(undefined);
    setCurrentStep(districtRepPermissionsWizardStepsCombo1[0]);
  }, []);

  const reset = useCallback(() => {
    if (props.districtRep) {
      // If the district rep already has permissions, we know we are editing and can set the state/steps accordingly
      const districtRepLocations = props.districtRep?.locations;
      const districtRepEnrolments = props.districtRep?.enrolments;
      const districtRepPermissions = props.districtRep?.permissions;
      const districtRepDistrict = districtRepLocations.find((location) => location.type === LocationType.District);
      const districtRepSchool = districtRepLocations.find((location) => location.type === LocationType.School);

      setPermissionsState({
        districtEdit: districtRepPermissions.editDistrict,
        schoolEdit: districtRepPermissions.editSchool,
        enrolmentEdit: districtRepPermissions.editEnrolment,
        enrolmentView: districtRepPermissions.viewEnrolment,
      });

      // billable entity = DISTRICT
      if (districtRepDistrict) {
        setBillableEntityState({
          id: districtRepDistrict.id,
          name: districtRepDistrict.name,
          type: districtRepDistrict.type,
        });
        if (districtRepPermissions.editDistrict) {
          setCombination(1);
          setCurrentStep(districtRepPermissionsWizardStepsCombo1[1]);
        } else if (districtRepSchool && districtRepPermissions.editSchool) {
          setCombination(2);
          setCurrentStep(districtRepPermissionsWizardStepsCombo2[1]);
          setSchoolState({ id: districtRepSchool.id, name: districtRepSchool.name });
        } else if (districtRepPermissions.editEnrolment || districtRepPermissions.viewEnrolment) {
          setCombination(4);
          setCurrentStep(districtRepPermissionsWizardStepsCombo4[1]);
          setEnrolmentState(
            districtRepEnrolments.map((enrolment) => ({
              id: enrolment.id,
              friendlyId: enrolment.friendlyId,
              locationId: enrolment.locationId,
            })),
          );

          // set school state if rep is associated to one
          if (districtRepSchool) {
            setSchoolState({ id: districtRepSchool.id, name: districtRepSchool.name });
          }
        } else {
          setCombination(1);
          setCurrentStep(districtRepPermissionsWizardStepsCombo1[1]);
        }
      }
      // billable entity = SCHOOL
      else if (districtRepSchool) {
        setBillableEntityState({
          id: districtRepSchool.id,
          name: districtRepSchool.name,
          type: districtRepSchool.type,
        });
        if (districtRepPermissions.editSchool) {
          setCombination(1);
          setCurrentStep(districtRepPermissionsWizardStepsCombo1[1]);
        } else if (districtRepPermissions.editEnrolment || districtRepPermissions.viewEnrolment) {
          setCombination(3);
          setCurrentStep(districtRepPermissionsWizardStepsCombo3[1]);
          setEnrolmentState(
            districtRepEnrolments.map((enrolment) => ({
              id: enrolment.id,
              friendlyId: enrolment.friendlyId,
              locationId: enrolment.locationId,
            })),
          );
        } else {
          setCombination(1);
          setCurrentStep(districtRepPermissionsWizardStepsCombo1[1]);
        }
      }
    } else {
      clearPermissionsState();
    }
  }, [clearPermissionsState, props.districtRep]);

  return (
    <DistrictRepPermissionsWizardContext.Provider
      value={{
        currentStep,
        districtRep: props.districtRep,
        billableEntityState,
        permissionsState,
        schoolState,
        enrolmentState,
        actions: {
          goToNextStep,
          goToPreviousStep,
          attachBillableEntityData,
          attachPermissionsData,
          attachSchoolData,
          attachEnrolmentData,
          submitPermissions,
          dismissPermissionsWizard,
          reset,
        },
      }}
    >
      {children}
    </DistrictRepPermissionsWizardContext.Provider>
  );
};

export const useDistrictRepPermissionsWizardContext = () => {
  const context = useContext(DistrictRepPermissionsWizardContext);

  if (context === undefined) {
    throw new Error('useDistrictRepPermissionsWizardContext must be used within a DistrictRepPermissionsWizardContextProvider');
  }
  return context;
};

export default DistrictRepPermissionsWizardContextProvider;
