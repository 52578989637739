import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import {
  FrustrationLevel,
  HootAssessmentInstructionIndicator,
  HootAssessmentModule,
  HootAssessmentStatus,
  UnitIdentifier,
  UnitIdentifierHRAV2,
} from '@hoot/interfaces/assessments-enums';
import { ReadingLevelType } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';

interface AssessmentBook {
  name: string;
  levelType: ReadingLevelType;
  level: string;
}
export interface CompletedUnit {
  unitIdentifier: UnitIdentifier | UnitIdentifierHRAV2;
  unitName: string;
  module: HootAssessmentModule;
  assessedByTeacher: AssessedByTeacher;

  //For SDQ
  frustrationLevel?: FrustrationLevel;

  //For modules 1 - 3
  score?: number;
  totalQuestions?: number;
  instructionIndicator?: HootAssessmentInstructionIndicator;

  //For module 4.1
  bookForRunningRecord?: AssessmentBook;
  accuracyRate?: number;
  wordCountPerMin?: number;

  //For module 4.2
  fluencyScore?: number;

  //For module 4.3
  comprehensionScore?: number;

  //For ATR
  feelingOnReadingOutLoud?: string;
  isReadingToYourselfLiked?: string;
  isListeningToABookReadToYouLiked?: string;
  engagement?: string;
}
export interface StudentHootAssessment {
  id: string;
  status: HootAssessmentStatus;
  abandonedReason: string | null;
  abandonedBySystem: boolean;
  startDateHRA: string;
  createdAt: number;
  updatedAt: number;
  teacherId: number;
  assessedByTeachers: AssessedByTeacher[];
  statusUpdateDate: string | null;
  exitPointUnit?: ExitPointUnit;
  completedUnits: CompletedUnit[];
  version: number;
}

export interface ExitPointUnit {
  unitName: string;
  unitIdentifier: UnitIdentifier;
  instructionIndicator: HootAssessmentInstructionIndicator | null;
}

export interface AssessedByTeacher {
  id: string;
  firstName: string;
  lastName: string;
  prefixedNumber: string;
}

export interface StudentHootAssessmentsQueryResponse {
  assessments: StudentHootAssessment[];
}

async function getStudentHootAssessments(studentProfileId: string): Promise<StudentHootAssessmentsQueryResponse> {
  const { data } = await axios.get<StudentHootAssessmentsQueryResponse>(`${config.apiUrl}/admin/hoot-assessment/student/${studentProfileId}`);
  return data;
}

export default function useGetStudentHootAssessments(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<StudentHootAssessmentsQueryResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetStudentHootAssessments, studentProfileId], () => getStudentHootAssessments(studentProfileId!), options);
}
