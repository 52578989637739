import { useMemo } from 'react';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import useGetAccounts from '../../../hooks/api/common/useGetAccounts';
import { FormSelect, FormSelectOption, SelectProps } from './FormSelect';

export function AccountSelect<T extends FieldValues, TObj extends Path<T>>(props: SelectProps & UseControllerProps<T, TObj>) {
  const accountsQuery = useGetAccounts(false);

  const options = useMemo<FormSelectOption[]>(() => {
    return (
      accountsQuery.data
        ?.map<FormSelectOption>((x) => {
          return { value: x.id, label: x.name };
        })
        ?.sort((a, b) => {
          return a.label > b.label ? 1 : -1;
        }) ?? []
    );
  }, [accountsQuery.data]);

  return <FormSelect label="Account" labelId="account-select-label" options={options} {...props} />;
}
