import { UserImportMode } from '@hoot-reading/hoot-core/dist/enums/user/user-import/user-import-mode.enum';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../../config';
import { UserImportResponse } from './useUserImportCsv';

export interface UserResumeImportPayload {
  importId: string;
  importMode: UserImportMode;
}

const resumeImport = async (payload: UserResumeImportPayload): Promise<UserImportResponse> => {
  const { importId } = payload;

  const { data } = await axios.post<UserImportResponse>(`${config.apiUrl}/admin/user-import/resume/${importId}`, payload);

  return data;
};

export default function useUserResumeImport(
  options?: Omit<UseMutationOptions<UserImportResponse, AxiosError, UserResumeImportPayload>, 'mutationFn' | 'mutationKey'>,
) {
  return useMutation((data) => resumeImport(data), options);
}
