import { Error, PersonOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Card, DialogActions, DialogContent, Divider, Stack, Typography, styled } from '@mui/material';
import fileDownloader from 'js-file-download';
import useGetInvoicingImport from '@hoot/hooks/api/teacher-invoice/useGetInvoiceImport';
import useGetInvoiceImportErrorFile from '@hoot/hooks/api/teacher-invoice/useGetInvoiceImportErrorFile';
import { InvoicingImportResponse } from '@hoot/hooks/api/teacher-invoice/useInvoiceImportCsv';
import { InvoicingImportType, invoiceImportLabelDictionary } from '@hoot/pages/teacher-invoices/enums/invoicing-import-type.enum';
import theme from '@hoot/theme';

interface IUserImportSummaryProps {
  uploadedImport: InvoicingImportResponse | undefined;
  importType: InvoicingImportType | undefined;
  handleClose: () => void;
}

const InvoiceImportSummary = (props: IUserImportSummaryProps) => {
  const { uploadedImport, importType, handleClose } = props;

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const { data: importData } = useGetInvoicingImport(uploadedImport?.id!, importType!);

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const invoiceImportError = useGetInvoiceImportErrorFile(uploadedImport?.id!, importType!, {
    enabled: false,
    onSuccess: (res) => fileDownloader(res.data, errorFileName),
    onError: (err) => console.error('Error downloading CSV', err),
  });

  const handleCloseBtn = () => {
    handleClose();
  };

  const fetchErrorFile = () => invoiceImportError.refetch();

  const errorFileName = `Errors-${invoiceImportLabelDictionary[uploadedImport?.type as InvoicingImportType]}.csv`;
  const createdRows = importData?.importCounts?.created;
  const erroredRows = importData?.importCounts?.errored;

  const StyledCard = styled(Card)(() => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2.5),
    border: '1px solid rgba(0, 0, 0, 0.1)',
    height: 56,
    '& > svg': { marginRight: '20px' },
  }));

  return (
    <>
      <DialogContent sx={{ padding: 0, marginTop: 2 }}>
        <Typography variant="titleMedium" sx={{ color: '#000' }}>
          Step 3 - Summary of Changes
        </Typography>
        <Stack sx={{ padding: theme.spacing(3, 0, 1, 0) }} gap={2}>
          {importData?.type && <Typography variant="bodySmall">{invoiceImportLabelDictionary[importData?.type]}</Typography>}

          {!!createdRows && (
            <StyledCard>
              <PersonOutline />
              <Typography>{`Created (${createdRows})`}</Typography>
            </StyledCard>
          )}

          {!!erroredRows && (
            <StyledCard>
              <Error htmlColor="#BA1A1A" />
              <Typography>{`Errors (${erroredRows})`}</Typography>
              <LoadingButton onClick={fetchErrorFile} loading={invoiceImportError.isRefetching} variant="text" sx={{ marginLeft: 'auto' }}>
                Download
              </LoadingButton>
            </StyledCard>
          )}
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ padding: 0 }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack direction="row"></Stack>

          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button onClick={handleCloseBtn} variant="contained" size="large">
              Done
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  );
};

export default InvoiceImportSummary;
