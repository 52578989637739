import { Chip, ChipProps } from '@mui/material';
import EmptyCircleIcon from '../icons/EmptyCircleIcon';
import FullCircleIcon from '../icons/FullCircle';
import PartialCircleIcon from '../icons/PartialCircleIcon';

interface StatusChipProps extends ChipProps {
  count?: number;
  label: string;
  statusIcon: StatusChipIcon;
  iconColor: StatusChipIconColor;
}

export enum StatusChipIcon {
  FullCircle = 'FullCircle',
  PartialCircle = 'PartialCircle',
  EmptyCircle = 'EmptyCircle',
}

export enum StatusChipIconColor {
  Green = '#2E7D32',
  Blue = '#1976D2',
  Red = '#B3261E',
  Grey = '#757575',
  Orange = '#FF8E00',
}

const StatusChip = (props: StatusChipProps) => {
  const { count, label, statusIcon, iconColor, sx, ...rest } = props;

  const renderLabel = () => {
    if (count) {
      return `${label} (${count})`;
    }
    return label;
  };

  const renderIcon = () => {
    switch (statusIcon) {
      case StatusChipIcon.FullCircle:
        return <FullCircleIcon fill={iconColor} />;
      case StatusChipIcon.PartialCircle:
        return <PartialCircleIcon fill={iconColor} />;
      case StatusChipIcon.EmptyCircle:
        return <EmptyCircleIcon stroke={iconColor} />;
    }
  };

  return (
    <Chip
      sx={{
        '& > svg': {
          width: '8px',
          height: '8px',
        },
        justifyContent: 'flex-start',
        paddingLeft: 1,
        paddingRight: 1,
        width: 'max-content',
        '& .MuiChip-icon': {
          color: 'transparent',
        },
        ...(sx ?? {}),
      }}
      size="small"
      icon={renderIcon()}
      label={renderLabel()}
      {...rest}
    />
  );
};

export default StatusChip;
