import { ArrowDropDown, ExitToApp, SettingsApplications } from '@mui/icons-material';
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { routes } from '../../routes/routes';
import { EditPreferencesDialog } from './EditPreferencesDialog';

const UserDropdown = () => {
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { hootEmployee, logout } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [showEditPreferences, setShowEditPreferences] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLogoutClick = () => {
    logout();
    setOpen(false);
    navigate(routes.home.url);
  };

  const handleEditPrerencesClick = () => {
    setOpen(false);
    setShowEditPreferences(true);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <Button ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle} role="user-dropdown">
        <Typography className={classes.userName}>
          {hootEmployee?.firstName} {hootEmployee?.lastName}
        </Typography>
        <ArrowDropDown className={classes.userName} />
      </Button>

      <Popper
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 50,
        })}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleEditPrerencesClick}>
                    <SettingsApplications className={classes.icon} /> Edit Preferences
                  </MenuItem>
                  <MenuItem onClick={handleLogoutClick}>
                    <ExitToApp className={classes.icon} /> Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {showEditPreferences ? <EditPreferencesDialog onClose={() => setShowEditPreferences(false)} /> : undefined}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  loadingSpinner: { color: '#e0e0e0', marginRight: '64px' },
  userName: {
    color: 'white',
    textTransform: 'none',
  },
  icon: {
    marginRight: '16px',
    color: '#757575',
  },
}));

export default UserDropdown;
