import { usePageTitle } from '@hoot/hooks/usePageTitle';
import HootEmployeesTable from './HootEmployeesTable';
import { FilterHootEmployeesFormProvider } from './contexts/FilterHootEmployeesFormContext';

const HootEmployeesView = () => {
  usePageTitle('Employees | Admin Portal');

  return (
    <FilterHootEmployeesFormProvider>
      <HootEmployeesTable />
    </FilterHootEmployeesFormProvider>
  );
};

export default HootEmployeesView;
