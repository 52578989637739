import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ManualQualityAssuranceTaskOption } from './enums';

export interface CreateQualityAssuranceRequest {
  taskOption: ManualQualityAssuranceTaskOption;
  note: string;
  studentProfileId?: string;
  lessonId?: string;
}

const createQualityAssuranceTask = async (request: CreateQualityAssuranceRequest): Promise<string> => {
  const { data } = await axios.post<string>(`${config.apiUrl}/admin/quality-assurance-tasks`, request);
  return data;
};

const useCreatequalityAssuranceTask = (
  options?: Omit<UseMutationOptions<any, AxiosError, CreateQualityAssuranceRequest>, 'mutationFn' | 'mutationKey'>,
) => {
  return useMutation<string, AxiosError, CreateQualityAssuranceRequest>((request) => createQualityAssuranceTask(request), options);
};

export default useCreatequalityAssuranceTask;
