import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Button, Chip, CircularProgress, ListItem, Menu, MenuItem, Stack } from '@mui/material';
import React, { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IS_LOCAL, REDACTED_NOTE } from '@hoot/common/constants';
import FullCircleIcon from '@hoot/components/icons/FullCircle';
import ConfirmDeleteDialog from '@hoot/components/modals/ConfirmDeleteDialog';
import CreateTaskModal from '@hoot/components/modals/CreateTaskModal';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { useImpersonateUser } from '@hoot/hooks/api/auth/useImpersonateUser';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { AccountType, ActionUnavailableModal } from '@hoot/pages/users/common/ActionUnavailableModal';
import { StudentTabs } from '@hoot/pages/users/students/components/StudentsTable/StudentsTable';
import StudentDetailsTab from '@hoot/pages/users/students/details/StudentDetailsTab';
import { routes } from '@hoot/routes/routes';
import CreatePasswordResetLinkItemButton from '../../../components/form/listItemButton/CreatePasswordResetLink';
import HeaderCard, { HeaderCardProps, HeaderTab } from '../../../components/ui/HeaderCard';
import useGetStudentProfile, { StudentEdlinkDataResponse, StudentProfileResponse } from '../../../hooks/api/end-users/useGetStudentProfile';
import useSoftDeleteStudentProfile from '../../../hooks/api/end-users/useSoftDeleteStudentProfile';
import StudentHistoryTab from './StudentHistoryTab';
import StudentSpecialIndicators from './StudentSpecialIndicators';
import StudentAssessmentsTab from './assessments/StudentAssessmentsTab';
import StudentInstructionalPlanTab from './instructional-plan/StudentinstructionalPlanTab';
import StudentReviewsTab from './lesson-reviews/StudentReviewsTab';
import { StudentScheduleTabV2 } from './schedule/StudentScheduleTabV2';
import StudentTasksTab from './tasks/StudentTasksTab';
import StudentTimelineTab from './timeline/StudentTimelineTab';

const StudentActionButton = (props: {
  studentProfile?: StudentProfileResponse;
  isEditMode: boolean;
  setShowFunctionalityDisabledModal: Dispatch<SetStateAction<boolean>>;
  disableFields: boolean;
}) => {
  const { studentProfile, isEditMode, setShowFunctionalityDisabledModal, disableFields } = props;
  const auth = useAuth();
  const { error } = useAlert();
  const [actionAnchorEl, setActionAnchorEl] = useState<Element | null>(null);
  const open = Boolean(actionAnchorEl);
  const queryClient = useQueryClient();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [showCreateTaskModal, setShowCreateTaskModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setIsDirty } = useBlockIfDirty();

  const softDeleteStudentProfile = useSoftDeleteStudentProfile({
    onSuccess: async () => {
      error(`Student successfully deleted!`);
      setIsDirty(false);
      await queryClient.invalidateQueries([QueryKey.GetStudentProfile, studentProfile?.id]);
      navigate(routes.users.students.url);
    },
    onError: () => error('Error deleting student!'),
  });

  const impersonateUser = useImpersonateUser({
    onSuccess: (response) => {
      const url = IS_LOCAL ? 'http://' + response.impersonationUrl : response.impersonationUrl;
      window.open(url, '_blank');
    },
    onError: (err) => error(`Error impersonating user: ${err.message}`),
  });

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isEditMode) {
      setActionAnchorEl(event.currentTarget);
    } else {
      setShowFunctionalityDisabledModal(true);
    }
  };

  const handleActionClose = () => {
    setActionAnchorEl(null);
  };

  const handleSoftDeleteStudent = () => {
    if (studentProfile?.id) {
      softDeleteStudentProfile.mutate(studentProfile.id);
    }
    setActionAnchorEl(null);
  };

  const handleImpersonateUserClick = () => {
    if (studentProfile?.id) {
      impersonateUser.mutate(studentProfile.endUserId);
    }
    setActionAnchorEl(null);
  };

  const handleViewAllLessonsClick = () => {
    if (studentProfile?.id) {
      const url = window.location.origin + `/lessons?studentProfileIds=${studentProfile.id}`;
      window.open(url, '_blank');
    }
    setActionAnchorEl(null);
  };

  return (
    <Stack>
      <Button
        type="button"
        color="inherit"
        variant="outlined"
        size="large"
        startIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={handleActionClick}
      >
        Action
      </Button>
      <Menu
        open={open}
        anchorEl={actionAnchorEl}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: 1 }}
      >
        <MenuItem onClick={handleViewAllLessonsClick}>View All Lessons</MenuItem>
        <MenuItem onClick={() => setShowConfirmDialog(true)} disabled={disableFields}>
          Delete Student Profile
        </MenuItem>
        {studentProfile?.parentInfo?.email ? (
          <ListItem disablePadding>
            <CreatePasswordResetLinkItemButton email={studentProfile.parentInfo.email} disabled={disableFields} />
          </ListItem>
        ) : null}
        {auth.hootEmployee?.canImpersonateUsers ? (
          <MenuItem onClick={handleImpersonateUserClick} disabled={disableFields}>
            Impersonate
          </MenuItem>
        ) : null}
        <MenuItem onClick={() => setShowCreateTaskModal((currVal) => !currVal)}>Create Task</MenuItem>
      </Menu>
      {showConfirmDialog ? (
        <ConfirmDeleteDialog
          open={true}
          onCancel={() => setShowConfirmDialog(false)}
          onContinue={handleSoftDeleteStudent}
          title="Soft Delete student?"
          bodyText="Soft delete to disable and hide from App & Portal. For permanent deletion, submit Product task."
        />
      ) : null}
      <CreateTaskModal open={showCreateTaskModal} handleClose={() => setShowCreateTaskModal(false)} />
    </Stack>
  );
};

export function StudentProfileEdlinkStatusChip(props: { edlinkData: StudentEdlinkDataResponse | null }) {
  const edlinkData = props.edlinkData;
  const handleClick = () => {
    if (!edlinkData) {
      return;
    }
    const url = `https://ed.link/Hoot/integrations/${edlinkData?.integrationId}/people#person/${edlinkData?.edlinkPersonId}`;
    window.open(url, '_blank');
  };

  if (!edlinkData) {
    return <></>;
  }

  return (
    <Chip
      sx={{
        paddingLeft: '0px',
        '& > svg': {
          width: '8px',
          height: '8px',
        },
      }}
      icon={<FullCircleIcon fill="#2E7D32" />}
      size="small"
      clickable={true}
      onClick={handleClick}
      label={`Edlink Person: ${edlinkData.edlinkPersonName} - ${edlinkData.integrationName}`}
    />
  );
}

const StudentHeader = (props: {
  isEditMode: boolean;
  studentProfile?: StudentProfileResponse;
  formRef: React.MutableRefObject<HTMLFormElement | undefined>;
  setShowFunctionalityDisabledModal: Dispatch<SetStateAction<boolean>>;
  specialIndicatorTabAccessGranted: boolean;
  disableAndRedactFields: boolean;
  showTimelineTab: boolean;
}) => {
  const {
    isEditMode,
    studentProfile,
    formRef,
    setShowFunctionalityDisabledModal,
    specialIndicatorTabAccessGranted,
    disableAndRedactFields,
    showTimelineTab,
  } = props;
  const navigate = useNavigate();
  const studentProfileId = studentProfile?.id;
  const { isDirty } = useBlockIfDirty();
  const tabs = useMemo<HeaderTab[]>(() => {
    const detailsRoute = isEditMode && studentProfileId ? routes.users.students.details.url(studentProfileId) : routes.users.students.create.url;

    if (!studentProfileId) {
      return [];
    }

    const tabs = [
      { id: 'details', label: 'Details', route: detailsRoute },
      ...(showTimelineTab ? [{ id: 'timeline', label: 'Timeline', route: routes.users.students.timeline.url(studentProfileId) }] : []),
      { id: 'schedule', label: 'Schedule', route: routes.users.students.schedule.url(studentProfileId) },
      { id: 'instructional-plan', label: 'Instructional Plan', route: routes.users.students.instructionalPlan.url(studentProfileId) },
      ...(studentProfileId
        ? [
            {
              id: 'lesson-reviews',
              label: 'Lesson Reviews',
              route: routes.users.students.lessonReviews.url(studentProfileId),
            },
          ]
        : []),
      { id: 'assessments', label: 'Assessments', route: routes.users.students.assessments.url(studentProfileId) },
      { id: 'tasks', label: 'Tasks', route: routes.users.students.tasks.url(studentProfileId) },
      { id: 'history', label: 'History', route: routes.users.students.history.url(studentProfileId) },
    ];

    if (specialIndicatorTabAccessGranted) {
      tabs.push({ id: 'special-indicators', label: 'Special Indicators', route: routes.users.students.specialIndicators.url(studentProfileId) });
    }

    return tabs;
  }, [isEditMode, studentProfileId, specialIndicatorTabAccessGranted, showTimelineTab]);

  const onTabSelected: HeaderCardProps['onTabSelected'] = (selectedTab): boolean => {
    if (isEditMode) {
      navigate(selectedTab.route);
      return !isDirty;
    } else {
      setShowFunctionalityDisabledModal(true);
      return false;
    }
  };

  return (
    <HeaderCard
      title={disableAndRedactFields ? REDACTED_NOTE : studentProfile ? (studentProfile.name ?? '') : 'New Student'}
      subtitle={studentProfile?.name ? `Student Number: ${studentProfile?.prefixedNumber}` : ''}
      canCopySubtitle
      copySubtitleOverride={`${studentProfile?.prefixedNumber}`}
      showNavigateBackButton
      renderCustomSubHeader={() => StudentProfileEdlinkStatusChip({ edlinkData: studentProfile?.edlinkData ?? null })}
      actionButtons={
        <>
          <StudentActionButton
            studentProfile={studentProfile}
            isEditMode={isEditMode}
            setShowFunctionalityDisabledModal={setShowFunctionalityDisabledModal}
            disableFields={disableAndRedactFields}
          />
          <Button
            size="large"
            type="button"
            variant="contained"
            disabled={!isDirty}
            onClick={() => formRef.current && formRef.current.requestSubmit()}
          >
            Save
          </Button>
        </>
      }
      tabs={tabs}
      onTabSelected={onTabSelected}
    />
  );
};

interface Props {
  tab: StudentTabs;
}

const StudentPage = (props: Props) => {
  const { tab } = props;
  usePageTitle('Student | Admin Portal');
  const { studentProfileId } = useParams();
  const [searchParams] = useSearchParams();
  const {
    isLoading,
    isError,
    data: studentProfile,
  } = useGetStudentProfile(studentProfileId!, {
    enabled: !!studentProfileId,
    retry: false,
  });

  const [showFunctionalityDisabledModal, setShowFunctionalityDisabledModal] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>();

  const parentAccountIdParam = searchParams.get('parentAccountId');
  const locationIdParam = searchParams.get('locationId');

  const isEditMode = !!studentProfileId;
  const { scopes } = useAuth();
  const specialIndicatorTabAccessGranted = !!scopes.find((s) => s === HootEmployeeScope.AdvancedStudentData);
  const { hootEmployee } = useAuth();
  const disableAndRedactFields = !hootEmployee?.canManageStudentDetails;
  const showTimelineTab = studentProfile?.isStudentTimelineAccessible ?? false;

  if (isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (isError) {
    return <Stack alignItems="center">Failed to load the page!</Stack>;
  }

  const onSaveRequired = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
      setShowFunctionalityDisabledModal(false);
    }
  };

  return (
    <Stack spacing={3} sx={{ maxWidth: '100vw' }}>
      <StudentHeader
        isEditMode={isEditMode}
        studentProfile={studentProfile}
        formRef={formRef}
        setShowFunctionalityDisabledModal={setShowFunctionalityDisabledModal}
        specialIndicatorTabAccessGranted={specialIndicatorTabAccessGranted}
        disableAndRedactFields={disableAndRedactFields}
        showTimelineTab={showTimelineTab}
      />
      {tab === StudentTabs.Details ? (
        <StudentDetailsTab
          isEditMode={isEditMode}
          parentAccountIdParam={parentAccountIdParam}
          locationIdParam={locationIdParam}
          studentProfile={studentProfile}
          formRef={formRef}
          setShowFunctionalityDisabledModal={setShowFunctionalityDisabledModal}
          disableAndRedactFields={disableAndRedactFields}
        />
      ) : null}
      {tab === StudentTabs.Timeline && isEditMode ? <StudentTimelineTab studentProfileId={studentProfileId} /> : null}
      {tab === StudentTabs.Schedule && isEditMode && studentProfile && <StudentScheduleTabV2 studentProfile={studentProfile} />}
      {tab === StudentTabs.InstructionalPlan && isEditMode && <StudentInstructionalPlanTab studentProfile={studentProfile} />}

      {tab === StudentTabs.LessonReviews && isEditMode ? <StudentReviewsTab /> : null}
      {tab === StudentTabs.Assessments && isEditMode ? <StudentAssessmentsTab /> : null}
      {tab === StudentTabs.Tasks && studentProfile && isEditMode ? <StudentTasksTab studentProfile={studentProfile} /> : null}
      {tab === StudentTabs.History && isEditMode ? <StudentHistoryTab /> : null}

      {tab === StudentTabs.SpecialIndicators && isEditMode && specialIndicatorTabAccessGranted ? (
        <StudentSpecialIndicators formRef={formRef} />
      ) : null}

      <ActionUnavailableModal
        setOpen={setShowFunctionalityDisabledModal}
        open={showFunctionalityDisabledModal}
        accountType={AccountType.Student}
        onSave={onSaveRequired}
      />
    </Stack>
  );
};

export default StudentPage;
