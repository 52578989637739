import { ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import React, { useState } from 'react';
import { SelectedTeacher } from '../../../components/modals/SelectTeacher';

interface Props {
  children: React.ReactNode;
}

/** Enums */

export enum RescheduleWizardType {
  SameTeacher = 'SAME_TEACHER',
  NewTeacher = 'NEW_TEACHER',
}

export enum RescheduleWizardState {
  ScheduleWizardType,
  LessonDetails = 1,
  TeacherDetails = 2,
  Confirm = 3,
  Schedules = 4,
}

/** Interfaces */

interface Values {
  rescheduledLesson: RescheduledLesson;
  updateRescheduledLesson: (updatedState: Partial<RescheduledLesson>) => void;
  wizardState: RescheduleWizardState;
  updateWizardState: (newState: RescheduleWizardState) => void;
  isPreviousStepDisabled: boolean;
}

export interface RescheduledLesson {
  wizardType: RescheduleWizardType;
  lessonAt?: number;
  lessonType?: ScheduledLessonType;
  isCustomerBillable?: boolean;
  teacher?: SelectedTeacher | null;
  duration?: number;
}

/** Context Provider */

const RescheduleWizardContext = React.createContext<Values>(undefined!);

const RescheduleWizardProvider = (props: Props) => {
  const { children } = props;

  const [rescheduledLesson, setRescheduledLesson] = useState<RescheduledLesson>({
    wizardType: RescheduleWizardType.SameTeacher,
  });
  const [wizardState, setWizardState] = useState<RescheduleWizardState>(RescheduleWizardState.ScheduleWizardType);

  const updateWizardState = (newState: RescheduleWizardState) => {
    setWizardState(newState);
  };

  const updateRescheduledLesson = (updatedState: Partial<RescheduledLesson>) => {
    setRescheduledLesson((currentState) => ({ ...currentState, ...updatedState }));
  };

  return (
    <RescheduleWizardContext.Provider
      value={{
        wizardState,
        updateWizardState,
        rescheduledLesson,
        updateRescheduledLesson,
        isPreviousStepDisabled: true,
      }}
    >
      {children}
    </RescheduleWizardContext.Provider>
  );
};

const useRescheduleWizard = () => {
  const context = React.useContext(RescheduleWizardContext);

  if (context === undefined) {
    throw new Error('useRescheduleWizard must be used within a CreateLessonProvider');
  }

  return context;
};

export { RescheduleWizardProvider, useRescheduleWizard };
