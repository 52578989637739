import { CircularProgress, Stack } from '@mui/material';
import React from 'react';

const FormLoading = () => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      sx={{
        height: '400px',
      }}
    >
      <CircularProgress />
    </Stack>
  );
};

export default FormLoading;
