import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface School {
  id: string;
  shortId: string;
  friendlyId: string;
  name: string;
  country: string;
  provinceState: string;
  city: string;
  timezone: string;
  districtId: string;
}

export interface SchoolsResponse {
  schools: School[];
}

async function getAllSchools() {
  const { data } = await axios.get<SchoolsResponse>(`${config.apiUrl}/admin/hfs/schools`);
  return data;
}

export default function useGetAllSchools() {
  return useQuery(QueryKey.GetAllSchools, () => getAllSchools());
}
