import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { Close } from '@mui/icons-material';
import { Box, Button, CircularProgress, Dialog, DialogContent, Divider, Grid, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { defaultRowsPerPage } from '@hoot/common/constants';
import { hootQualifications } from '@hoot/interfaces/teacher';
import SearchTextField from '../../../../../components/form/SearchTextField';
import DateFilter, { ActiveDateFilter } from '../../../../../components/form/filterDropDown/common/DateFilter';
import YesNoFilter, { ActiveYesNoFilter } from '../../../../../components/form/filterDropDown/common/YesNoFilter';
import {
  ActiveProductStatusFilterDropDown,
  ProductStatusFilterDropDown,
} from '../../../../../components/form/filterDropDown/product/ProductStatusFilter';
import { ActiveProductProgram, ProductProgamFilter } from '../../../../../components/form/filterDropDown/product/ProgramTypeFilter';
import { HeaderData, Table } from '../../../../../components/ui/Table';
import useSearchProducts, { ProductQuery } from '../../../../../hooks/api/hfs/useSearchProducts';
import { SortOrder } from '../../../../../interfaces/order-by';
import { OrderBy } from '../../../../lessons/enums';
import { productProgram } from '../../../products/enum';
import { EnrolmentsStatus } from './EnrolmentAssociatedProductCard';
import { groupBy } from './util';

interface TableColumn {
  id: string;
  selected: boolean;
  productFriendlyId: string;
  program: string;
  startsAt: string;
  endsAt: string;
  requirements: React.ReactNode;
  hasEnrolment: React.ReactNode;
  status: React.ReactNode;
}

const tableHeaders: HeaderData<TableColumn>[] = [
  { name: 'Product Id', property: 'productFriendlyId', isSortable: false },
  { name: 'Program', property: 'program', isSortable: false },
  { name: 'Start Date', property: 'startsAt', isSortable: false },
  { name: 'End Date', property: 'endsAt', isSortable: false },
  { name: 'Requirements', property: 'requirements', isSortable: false },
  { name: 'Enrolments', property: 'hasEnrolment', isSortable: false },
  { name: 'Status', property: 'status', isSortable: false },
];

export const ProductsTableFiltersContainer = (props: { value: ProductQuery; onChange?: (filters: ProductQuery) => void }) => {
  const { value, onChange } = props;

  const handleChange = (property: keyof ProductQuery) => (val: any | any[]) => {
    if (onChange) {
      onChange({ ...value, [property]: val });
    }
  };

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item sx={{ marginRight: '8px' }}>
        <Typography variant="bodySmall">Filters</Typography>
      </Grid>

      <ProductStatusFilterDropDown onChange={handleChange('status')} value={value.status || []} popOverButtonSx={{ ml: 0, mr: '8px' }} />
      <DateFilter
        dismissPopoverOnSelection
        title="Starts At"
        onChange={(val) => handleChange('startsAt')(val.toMillis())}
        value={value.startsAt ? DateTime.fromMillis(value.startsAt) : undefined}
        popOverButtonSx={{ ml: 0, mr: '8px' }}
      />
      <DateFilter
        dismissPopoverOnSelection
        title="Ends At"
        onChange={(val) => handleChange('endsAt')(val.toMillis())}
        value={value.endsAt ? DateTime.fromMillis(value.endsAt) : undefined}
        popOverButtonSx={{ ml: 0, mr: '8px' }}
      />
      <ProductProgamFilter onChange={handleChange('program')} value={value.program || []} popOverButtonSx={{ ml: 0, mr: '8px' }} />
      <YesNoFilter title="Has Enrolments" onChange={handleChange('hasEnrolment')} value={value.hasEnrolment} popOverButtonSx={{ ml: 0, mr: '8px' }} />
    </Grid>
  );
};

export const ActiveProductsTableFiltersContainer = (props: { value: ProductQuery; onChange?: (filters: ProductQuery) => void }) => {
  const { value, onChange } = props;

  const handleChange = (property: keyof ProductQuery) => (updatedValues: any | any[]) => {
    const updatedFilters = { ...value };
    updatedFilters[property] = updatedValues;
    if (onChange) {
      onChange(updatedFilters);
    }
  };

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item sx={{ marginRight: '8px' }}>
        <Typography variant="bodySmall">Active</Typography>
      </Grid>
      <Grid
        item
        sx={{
          '& > div': {
            marginRight: '8px',
          },
        }}
      >
        <ActiveProductStatusFilterDropDown value={value.status} onChange={handleChange('status')} />
        {value.startsAt ? (
          <ActiveDateFilter label="Starts At" value={DateTime.fromMillis(value.startsAt).toISODate() ?? ''} onChange={handleChange('startsAt')} />
        ) : null}
        {value.endsAt ? (
          <ActiveDateFilter label="Ends At" value={DateTime.fromMillis(value.endsAt).toISODate() ?? ''} onChange={handleChange('endsAt')} />
        ) : null}
        <ActiveProductProgram value={value.program} onChange={handleChange('program')} />
        <ActiveYesNoFilter
          label="Has Enrolments"
          value={value.hasEnrolment !== undefined ? (value.hasEnrolment ? 'Yes' : 'No') : undefined}
          onChange={handleChange('hasEnrolment')}
        />
      </Grid>
    </Grid>
  );
};

const ProductAssociationModal = (props: {
  close: () => void;
  isOpen: boolean;
  onApply?: (selectedRows?: string) => void;
  onCancel?: () => void;
  defaultFilters?: Partial<ProductQuery>;
}) => {
  const { defaultFilters, close, isOpen, onApply, onCancel } = props;

  const [query, setQuery] = useState<ProductQuery>({
    productFriendlyId: defaultFilters?.productFriendlyId || '',
    locationId: defaultFilters?.locationId || '',
    page: 1,
    pageSize: defaultRowsPerPage,
    sortDirection: SortOrder.ASC,
  });

  const response = useSearchProducts(query);

  const [selected, setSelected] = useState<string>();

  const handleSelectClick = (column: TableColumn, selected: boolean) => {
    if (selected) {
      setSelected(column.id);
    } else {
      setSelected(undefined);
    }
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize }));
  };

  const handleInputChange = (val: string) => {
    setQuery((currentState: any) => ({ ...currentState, productFriendlyId: val, name: val, page: 1 }));
  };

  const handleFilterChange = (query: ProductQuery) => {
    setQuery(query);
  };

  const handleCancelClick = () => {
    if (onCancel) {
      onCancel();
    }
    close();
  };

  const handleApplyClick = () => {
    if (onApply) {
      onApply(selected);
    }
    close();
  };

  const handleSortBy = (property: keyof TableColumn) => {
    setQuery((query) => ({ ...query, orderBy: property }));
  };

  const data =
    response.data?.products.map<TableColumn>((p) => {
      const mappedRequirements =
        (p.requirements || ([] as HootQualification[])).map((r, idx) => (
          <div key={`${r}-${idx}`}>{hootQualifications[r as HootQualification]}</div>
        )) ?? 'None';

      return {
        id: p.id,
        selected: selected === p.id,
        productFriendlyId: p.friendlyId,
        hasEnrolment: groupBy(p.enrolments, 'enrolmentStatus').map((e) => (
          <Box key={e.name} sx={{ marginTop: '4px' }}>
            <EnrolmentsStatus status={e.name as EnrolmentStatus} count={e.count} />
          </Box>
        )),
        startsAt: DateTime.fromMillis(p.startDate).toFormat('dd/LL/yyyy'),
        endsAt: DateTime.fromMillis(p.endDate).toFormat('dd/LL/yyyy'),
        program: productProgram[p.program],
        status: p.status,
        requirements: mappedRequirements,
      };
    }) || [];

  return (
    <Dialog
      sx={{
        padding: '24px',
      }}
      open={isOpen}
      fullWidth={true}
      maxWidth={'lg'}
      onClose={handleCancelClick}
    >
      <DialogContent>
        <Stack flex={1}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="headlineSmall">Add Product</Typography>
            <Close sx={{ '&:hover': { cursor: 'pointer' }, mr: '22px' }} onClick={handleCancelClick} />
          </Grid>
          <Grid sx={{ marginTop: '24px' }} item>
            <SearchTextField searchInput={query.productFriendlyId || ''} onSearchInputChanged={handleInputChange} />
          </Grid>
          <Grid container item sx={{ marginTop: '32px' }}>
            <ProductsTableFiltersContainer value={query} onChange={handleFilterChange} />
          </Grid>
          <Grid container item sx={{ marginTop: '32px' }}>
            <ActiveProductsTableFiltersContainer value={query} onChange={handleFilterChange} />
          </Grid>
          <Grid sx={{ marginTop: '24px' }} item>
            {response.isLoading || !response.data ? (
              <CircularProgress />
            ) : (
              <Table
                headers={tableHeaders}
                data={data}
                count={response.data.count}
                onSelect={handleSelectClick}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                isPaginated
                isSelectable
                isSortable
                sortBy="productFriendlyId"
                sortOrder={query.sortDirection === SortOrder.ASC ? OrderBy.Asc : OrderBy.Desc}
                onSortBy={handleSortBy}
                page={query.page ?? 1}
              />
            )}
          </Grid>
          <Divider sx={{ marginTop: '24px' }} />
          <Stack sx={{ marginTop: '24px' }} direction="row" justifyContent="flex-end">
            <Button color="inherit" variant="contained" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button sx={{ marginLeft: '8px' }} color="primary" variant="contained" onClick={handleApplyClick}>
              Apply
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ProductAssociationModal;
