import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

const getBankingUpdatesCSVReport = async (periodId: string) =>
  await axios.get<any>(`${config.apiUrl}/admin/teacher-invoicing/banking-updates-csv/${periodId}`);

export default function useDownloadBankingUpdatesCSVReport(
  periodId: string,
  options?: Omit<UseQueryOptions<any, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetBankingUpdatesCSVReport, periodId], () => getBankingUpdatesCSVReport(periodId), options);
}
