import { useEffect, useState } from 'react';

/**
 * Only works with strings at the momment.
 * This can be better generalized.
 */
export const useDebounce = (delay: number = 300): [string, React.Dispatch<React.SetStateAction<string>>] => {
  const [value, setValue] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useState<string>('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearInterval(timeoutId);
    };
  }, [value, delay]);
  return [debouncedValue, setValue];
};
