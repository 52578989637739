import { CancellationReason, ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { UserProfileType } from '@hoot/interfaces/user';
import { QueryKey } from '../queryKeys';

export interface UserLessonScheduleQueryRequest {
  profileType: UserProfileType;
  userProfileId: string;
  fromDate: number;
  toDate: number;
}

export interface LessonScheduleQueryLesson {
  id: string;
  number: number;
  prefixedStudentNumber: string;
  startsAt: number;
  endsAt: number;
  status: ScheduledLessonStatus;
  cancellationReason: CancellationReason | null;
}

export interface LessonScheduleQueryResponse {
  lessons: LessonScheduleQueryLesson[];
}

async function userLessonScheduleQuery(query: UserLessonScheduleQueryRequest) {
  const { data } = await axios.post<LessonScheduleQueryResponse>(`${config.apiUrl}/admin/scheduler/lessons/get-user-schedule`, query);
  return data;
}

export default function useUserLessonScheduleQuery(
  query: UserLessonScheduleQueryRequest,
  options?: Omit<UseQueryOptions<LessonScheduleQueryResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.UserScheduleLessons, query], () => userLessonScheduleQuery(query), options);
}
