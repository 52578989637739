import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { ScheduledLessonSetStatus, scheduledLessonSetStatusDisplay, scheduledLessonSetStatusType } from '../../../../pages/lessons/enums';
import FilterDropDown from '../../FilterDropDown';

export function LessonSetStatusFilterDropDown(props: {
  value: ScheduledLessonSetStatus[];
  onChange: (val: ScheduledLessonSetStatus[]) => void;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { value, onChange, popOverButtonSx } = props;

  return (
    <FilterDropDown<string>
      title="Status"
      dismissPopoverOnSelection={true}
      value={value}
      options={scheduledLessonSetStatusDisplay.map((s) => ({
        label: s.label,
        value: s.value,
      }))}
      onChange={(val) => onChange(val as ScheduledLessonSetStatus[])}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveLessonSetFilterDropDown(props: { value?: ScheduledLessonSetStatus[]; onChange: (val: ScheduledLessonSetStatus[]) => void }) {
  const { value = [], onChange } = props;

  const handleDelete = (val: ScheduledLessonSetStatus) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`Status: ${scheduledLessonSetStatusType[c]}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}
