import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { HTMLInputTypeAttribute } from 'react';

export type OperatorFilterType = 'numeric' | 'text' | 'date';

export interface OperatorFilterProps {
  label: string;
  type: OperatorFilterType;
}

export enum Operator {
  Is = 'IS',
  IsNot = 'IS_NOT',
  LessThan = 'LESS_THAN',
  GreaterThan = 'GREATER_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  Includes = 'INCLUDES',
  DoesNotInlude = 'DOES_NOT_INCLUDE',
}

const operatorLookUp: Record<Operator, string> = {
  DOES_NOT_INCLUDE: 'Does Not Include',
  GREATER_THAN: 'Greater Than',
  GREATER_THAN_OR_EQUAL_TO: 'Greater Than Or Equal To',
  INCLUDES: 'Includes',
  IS: 'Is',
  IS_NOT: 'Is Not',
  LESS_THAN: 'Less Than',
  LESS_THAN_OR_EQUAL_TO: 'Less Then or Equal To',
};

export interface OperatorFilterValue<T> {
  isEnabled: boolean;
  operator?: Operator;
  value?: T;
}

const numericFilters: Operator[] = [
  Operator.Is,
  Operator.IsNot,
  Operator.LessThan,
  Operator.GreaterThan,
  Operator.LessThanOrEqualTo,
  Operator.GreaterThanOrEqualTo,
];

const textFilters: Operator[] = [Operator.Is, Operator.IsNot];

export function OperatorFilter<T>(props: {
  value: OperatorFilterValue<T>;
  onChange: (value: OperatorFilterValue<T>) => void;
  label: string;
  type: HTMLInputTypeAttribute;
}) {
  const handleOperatorChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    props.onChange({
      ...props.value,
      operator: value as Operator,
    });
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    function castType() {
      if (props.type === 'number') {
        return Number.parseInt(value);
      }
      return value;
    }

    props.onChange({
      ...props.value,
      value: castType() as any,
    });
  };

  const handleIsEnabledChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    props.onChange({
      ...props.value,
      isEnabled: checked,
    });
  };

  function menuItems() {
    if (props.type === 'date' || props.type === 'number') {
      return numericFilters;
    } else {
      return textFilters;
    }
  }

  return (
    <Stack>
      <FormControlLabel control={<Checkbox checked={props.value?.isEnabled ?? false} onChange={handleIsEnabledChange} />} label={props.label} />

      <Stack marginTop="8px" spacing="16px" direction="row">
        <FormControl disabled={!props.value.isEnabled} fullWidth>
          <InputLabel id={`${props.label}-operator-label`}>Operator</InputLabel>
          <Select labelId={`${props.label}-operator-label`} value={props.value?.operator as any} label="Operator" onChange={handleOperatorChange}>
            {menuItems().map((f) => (
              <MenuItem key={f} value={f}>
                {operatorLookUp[f]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          value={props.value.value}
          onChange={handleValueChange}
          disabled={!props.value.isEnabled}
          type={props.type}
          label={props.label}
          variant="outlined"
        />
      </Stack>
    </Stack>
  );
}
