import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { Button, Checkbox, DialogActions, DialogContent, Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import {
  DistrictRepPermissionsWizardStepEnumCombo1,
  DistrictRepPermissionsWizardStepEnumCombo2,
  DistrictRepPermissionsWizardStepEnumCombo3,
  useDistrictRepPermissionsWizardContext,
} from '../DistrictRepPermissionsContextProvider';

const PermissionsStep = () => {
  const {
    billableEntityState,
    permissionsState,
    actions: { dismissPermissionsWizard, goToNextStep, goToPreviousStep, attachPermissionsData },
  } = useDistrictRepPermissionsWizardContext();

  const [districtEdit, setDistrictEdit] = useState<boolean>(permissionsState?.districtEdit ?? false);
  const [schoolEdit, setSchoolEdit] = useState<boolean>(permissionsState?.schoolEdit ?? false);
  const [enrolmentEdit, setEnrolmentEdit] = useState<boolean>(permissionsState?.enrolmentEdit ?? false);
  const [enrolmentView, setEnrolmentView] = useState<boolean>(permissionsState?.enrolmentView ?? false);

  const handleDistrictToggle = (checked: boolean) => {
    setDistrictEdit(checked);
    setSchoolEdit(checked);
    setEnrolmentEdit(checked);
    setEnrolmentView(checked);
    attachPermissionsData(checked, checked, checked, checked);
  };

  const handleSchoolToggle = (checked: boolean) => {
    setSchoolEdit(checked);
    setEnrolmentEdit(checked);
    setEnrolmentView(checked);
    attachPermissionsData(districtEdit, checked, checked, checked);
  };

  const handleEnrolmentEditToggle = (checked: boolean) => {
    setEnrolmentEdit(checked);
    setEnrolmentView(checked);
    attachPermissionsData(districtEdit, schoolEdit, checked, checked);
  };

  const handleEnrolmentViewToggle = (checked: boolean) => {
    setEnrolmentView(checked);
    attachPermissionsData(districtEdit, schoolEdit, enrolmentEdit, checked);
  };

  const showDistrictEditCheckbox = billableEntityState?.type === LocationType.District;

  const canGoToNextStep = () => {
    return !districtEdit && !schoolEdit && !enrolmentEdit && !enrolmentView;
  };

  const onGoToNextStep = () => {
    if (billableEntityState?.type === LocationType.District) {
      if (districtEdit) {
        goToNextStep(DistrictRepPermissionsWizardStepEnumCombo1.Summary);
      } else {
        goToNextStep(DistrictRepPermissionsWizardStepEnumCombo2.SchoolSelection);
      }
    } else {
      if (schoolEdit) {
        goToNextStep(DistrictRepPermissionsWizardStepEnumCombo1.Summary);
      } else {
        goToNextStep(DistrictRepPermissionsWizardStepEnumCombo3.EnrolmentSelection);
      }
    }
  };

  return (
    <>
      <DialogContent sx={{ pt: 0, pl: 0, pb: 0 }}>
        <Typography variant="titleMedium" sx={{ color: '#000' }}>
          Step 2 - Select Permissions
        </Typography>
        <Stack sx={{ pt: 2, pl: 2 }}>
          {showDistrictEditCheckbox ? (
            <FormControlLabel
              control={<Checkbox onChange={(event) => handleDistrictToggle(event.target.checked)} />}
              label="District Edit"
              checked={districtEdit}
            />
          ) : null}
          <FormControlLabel
            disabled={districtEdit}
            control={<Checkbox onChange={(event) => handleSchoolToggle(event.target.checked)} />}
            label="School Edit"
            checked={schoolEdit}
          />
          <FormControlLabel
            disabled={schoolEdit}
            control={<Checkbox onChange={(event) => handleEnrolmentEditToggle(event.target.checked)} />}
            label="Enrolment Edit"
            checked={enrolmentEdit}
          />
          <FormControlLabel
            disabled={enrolmentEdit}
            control={<Checkbox onChange={(event) => handleEnrolmentViewToggle(event.target.checked)} />}
            label="Enrolment View"
            checked={enrolmentView}
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 0 }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack direction="row">
            <Button onClick={() => goToPreviousStep()} disabled={!!billableEntityState} color="inherit" variant="outlined" size="large">
              Back
            </Button>
          </Stack>

          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button onClick={dismissPermissionsWizard} color="inherit" variant="outlined" size="large">
              Cancel
            </Button>
            <Button onClick={onGoToNextStep} variant="contained" size="large" disabled={canGoToNextStep()}>
              Next
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  );
};

export default PermissionsStep;
