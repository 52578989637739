import { CustomerStatus } from '@hoot-reading/hoot-core/dist/enums/user/customer-status.enum';
import { PasswordSetFilter } from '@hoot-reading/hoot-core/dist/enums/user/password-reset-filter.enum';
import { StudentDateType } from '@hoot-reading/hoot-core/dist/enums/user/student/student-date-type.enum';
import { StudentSearchFieldOptions } from '@hoot-reading/hoot-core/dist/enums/user/student/student-search-field-options.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { LanguageQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/language-qualification.enum';
import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export type LanguageQualificationWithIsEmpty =
  | LanguageQualification.English
  | LanguageQualification.French
  | LanguageQualification.Spanish
  | 'IS_EMPTY';

export interface CustomerSearchParams {
  customerStatus?: (CustomerStatus | 'IS_EMPTY')[];
  account?: string[];
  fromLeadInitiationDate?: string;
  toLeadInitiationDate?: string;
}

export interface StudentSearchParams {
  searchFieldSelection?: StudentSearchFieldOptions;
  studentStatus?: (StudentStatus | 'IS_EMPTY')[];
  dateType?: StudentDateType;
  fromDate?: string;
  toDate?: string;
  studentLocationIds?: string[];
  studentSchoolIds?: string[];
  enrolmentIds?: string[];
  lineOfBusiness?: string;
  passwordSet?: PasswordSetFilter;
}

export interface StudentQuery {
  searchText?: string;
  page: number;
  pageSize: number;
  isPasswordSet?: boolean;
  studentQuery: StudentSearchParams;
  customerQuery: CustomerSearchParams;
  country?: string;
  timeZones?: string[];
  provincesStates?: string[];
}

export interface StudentProfileResponse {
  id: string;
  name: string;
  status: StudentStatus;
  number: number;
  prefixedNumber: string;
}

export interface StudentAccountResponse {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  parentPrefixedNumber?: string;
  phoneNumber: string;
  accountName: string;
  hasCustomerAccount: boolean;
  studentProfile?: StudentProfileResponse;
}

export interface StudentQueryResponse {
  count: number;
  page: number;
  pageSize: number;
  users: StudentAccountResponse[];
}

const searchStudents = async (searchParams: StudentQuery): Promise<StudentQueryResponse> => {
  const { data } = await axios.post<StudentQueryResponse>(`${config.apiUrl}/admin/student-profile/search`, searchParams);
  return data;
};

export default function useSearchStudents(searchParams: StudentQuery) {
  return useQuery([QueryKey.StudentSearch, searchParams], () => searchStudents(searchParams));
}
