import { Close } from '@mui/icons-material';
import { Box, Chip, Typography } from '@mui/material';
import React from 'react';
import { IFilterSelection } from './form/searchFilters/FilterSelects';

const updateValues = (
  selectedValues: string[],
  index: number,
  filtersData: IFilterSelection[],
  setActiveFilters: React.Dispatch<React.SetStateAction<string[]>>,
  setFiltersData: React.Dispatch<React.SetStateAction<IFilterSelection[]>>,
) => {
  const selectedValueToRemove = selectedValues[index];

  const title = selectedValueToRemove.split(':')[0].trim();
  const value = selectedValueToRemove.split(':')[1].trim();

  const updatedSelectedValues = [...selectedValues.slice(0, index), ...selectedValues.slice(index + 1)];
  const filterIndex = filtersData.findIndex((fl) => fl.label === title);
  if (filterIndex >= 0) {
    const valueIndex = filtersData[filterIndex].values.findIndex((v) => v.label === value);
    if (valueIndex >= 0) {
      filtersData[filterIndex].values[valueIndex].isSelected = false;
    }
  }

  setActiveFilters(updatedSelectedValues);
  setFiltersData(filtersData);
};

const SelectedFilterChips = (props: {
  activeFilters: string[];
  selectedValues: string[];
  filtersData: IFilterSelection[];
  setActiveFilters: React.Dispatch<React.SetStateAction<string[]>>;
  setFiltersData: React.Dispatch<React.SetStateAction<IFilterSelection[]>>;
}) => {
  const { activeFilters, selectedValues, filtersData, setActiveFilters, setFiltersData } = props;
  if (selectedValues.length === 0) return null;
  return (
    <Box sx={{ maxWidth: '1000px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography
        sx={{
          fontSize: '12px',
          pt: 1,
          mr: '6px',
        }}
      >
        Active
      </Typography>
      {activeFilters.map((type, index) => (
        <Chip
          key={`${type}-${index}`}
          style={{ color: '#49454F' }}
          deleteIcon={<Close style={{ fill: '#49454F', height: '18px', width: '18px' }} />}
          sx={{
            height: '32px',
            borderRadius: 2,
            borderColor: '#79747E',
            mx: 1,
            mt: 1,
            fontWeight: '600',
          }}
          label={type}
          variant="outlined"
          onDelete={() => {
            updateValues(selectedValues, index, filtersData, setActiveFilters, setFiltersData);
          }}
        />
      ))}
    </Box>
  );
};

export default SelectedFilterChips;
