import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface SubThemesResponse {
  id: string;
  name: string;
}

async function getSubThemes(): Promise<SubThemesResponse[]> {
  const { data } = await axios.get<SubThemesResponse[]>(`${config.apiUrl}/admin/library/sub-themes`);
  return data;
}

export default function useGetSubThemes(options?: Omit<UseQueryOptions<SubThemesResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetSubThemes], () => getSubThemes(), options);
}
