import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { CommentSubmission } from '@hoot/interfaces/history';

async function createStudentComment(studentProfileId: string, payload: CommentSubmission) {
  await axios.post(`${config.apiUrl}/admin/student-profile/${studentProfileId}/comments`, payload);
}

export default function useCreateStudentComment(
  studentProfileId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, CommentSubmission>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((payload: CommentSubmission) => createStudentComment(studentProfileId, payload), options);
}
