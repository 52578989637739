import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import { Box, Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import DateFilter, { ActiveDateFilter } from '../../../components/form/filterDropDown/common/DateFilter';
import {
  ActiveEnrolmentStatusFilterDropDown,
  EnrolmentStatusFilterDropDown,
} from '../../../components/form/filterDropDown/enrolment/EnrolmentStatusFilter';
import {
  ActiveEnrolmentTypeFilterDropDown,
  EnrolmentTypeFilterDropDown,
} from '../../../components/form/filterDropDown/enrolment/EnrolmentTypeFilter';

export interface ProductEnrolmentFilters {
  status?: EnrolmentStatus[];
  type?: EnrolmentType[];
  startsAt?: string;
  endsAt?: string;
}

export const ProductEnrolmentFiltersContainer = (props: {
  value?: ProductEnrolmentFilters;
  onChange?: (filters: ProductEnrolmentFilters) => void;
}) => {
  const { value, onChange } = props;

  const handleChange = (property: keyof ProductEnrolmentFilters) => (val: string | string[] | number) => {
    if (value) {
      if (onChange) {
        onChange({ ...value, [property]: val });
      }
    }
  };

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item sx={{ marginRight: '16px' }}>
        <Typography variant="bodySmall">Filters</Typography>
      </Grid>
      <Box sx={{ marginRight: '16px' }}>
        <EnrolmentStatusFilterDropDown onChange={handleChange('status')} value={value?.status || []} />
      </Box>
      <Box sx={{ marginRight: '16px' }}>
        <EnrolmentTypeFilterDropDown onChange={handleChange('type')} value={value?.type || []} />
      </Box>
      <Box sx={{ marginRight: '16px' }}>
        <DateFilter
          title="Starts At"
          onChange={(val) => handleChange('startsAt')(val.toFormat('dd/LL/yyyy'))}
          value={value?.startsAt ? DateTime.fromFormat(value.startsAt, 'dd/LL/yyyy') : undefined}
        />
      </Box>
      <Box sx={{ marginRight: '16px' }}>
        <DateFilter
          title="Ends At"
          onChange={(val) => handleChange('endsAt')(val.toFormat('dd/LL/yyyy'))}
          value={value?.endsAt ? DateTime.fromFormat(value.endsAt, 'dd/LL/yyyy') : undefined}
        />
      </Box>
    </Grid>
  );
};

export const ActiveProductEnrolmentFiltersContainer = (props: {
  value?: ProductEnrolmentFilters;
  onChange?: (filters: ProductEnrolmentFilters) => void;
}) => {
  const { value, onChange } = props;

  const handleChange = (property: keyof ProductEnrolmentFilters) => (updatedValues: any | any[]) => {
    const updatedFilters = { ...value };
    updatedFilters[property] = updatedValues;
    if (onChange) {
      onChange(updatedFilters);
    }
  };

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item sx={{ marginRight: '16px' }}>
        <Typography variant="bodySmall">Active</Typography>
      </Grid>
      <Grid
        item
        sx={{
          '& > div': {
            marginRight: '16px',
          },
        }}
      >
        <ActiveEnrolmentStatusFilterDropDown value={value?.status} onChange={handleChange('status')} />
        <ActiveEnrolmentTypeFilterDropDown value={value?.type} onChange={handleChange('type')} />
        <ActiveDateFilter label="Starts At" value={value?.startsAt} onChange={handleChange('startsAt')} />
        <ActiveDateFilter label="Ends At" value={value?.endsAt} onChange={handleChange('endsAt')} />
      </Grid>
    </Grid>
  );
};
