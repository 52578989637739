import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface DistrictRepQuery {
  page: number;
  pageSize: number;
  name?: string;
  countries?: string[];
  districtRepresentativeAccountId?: string | null;
  isNotAssociatedToLocation?: boolean;
  locationId?: string;
  isNotAssociatedToEnrolmentId?: string;
}

export interface DistrictRepResponse {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  city: string | null;
  country: HootSupportedCountry | null;
  email: string;
}

export interface DistrictRepQueryResponse {
  count: number;
  page: number;
  pageSize: number;
  districtReps: DistrictRepResponse[];
}

const getDistrictReps = async (searchParams: DistrictRepQuery): Promise<DistrictRepQueryResponse> => {
  const { data } = await axios.post<DistrictRepQueryResponse>(`${config.apiUrl}/admin/district-representative/search`, searchParams);
  return data;
};

export default function useGetDistrictReps(searchParams: DistrictRepQuery) {
  return useQuery([QueryKey.GetDistrictRepresentative, searchParams], () => getDistrictReps(searchParams));
}
