import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { BoxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { IFilter, IFilterSelection } from '@hoot/components/form/searchFilters/FilterSelects';
import SearchFilters from '@hoot/components/form/searchFilters/SearchFilters';
import useSearchLocationEnrolments, { LocationEnrolment } from '@hoot/hooks/api/hfs/useSearchLocationEnrolments';
import { useStableSearchParams } from '@hoot/hooks/useStableSearchParams';
import { LocationStudentsPageQueryParams } from '@hoot/pages/district-schools/students/models';

const getSchoolStudentsFilters: IFilter[] = [
  {
    label: 'Status',
    queryName: LocationStudentsPageQueryParams.Status,
    options: [
      { label: 'Active', queryValue: StudentStatus.Active },
      { label: 'Cold', queryValue: StudentStatus.Cold },
      { label: 'Inactive', queryValue: StudentStatus.Inactive },
      { label: 'Lead', queryValue: StudentStatus.Lead },
      { label: 'Paused', queryValue: StudentStatus.Paused },
    ],
  },
  {
    label: 'Has Enrolments',
    queryName: LocationStudentsPageQueryParams.HasEnrolments,
    options: [
      { label: 'Yes', queryValue: 'true' },
      { label: 'No', queryValue: 'false' },
    ],
  },
];

const getInitialFilterSelections = (searchParams: URLSearchParams, enrolments: LocationEnrolment[]) => {
  const staticFilters = getSchoolStudentsFilters.map((filter: IFilter) => ({
    ...filter,
    values: filter.options.map((option) => ({
      ...option,
      isSelected: searchParams.has(filter.queryName!) && searchParams.getAll(filter.queryName!).includes(option.queryValue!),
    })),
  }));

  const enrolmentFilter = {
    label: 'Enrolment ID',
    queryName: LocationStudentsPageQueryParams.Enrolment,
    options: enrolments.map((e) => ({ label: e.friendlyId, queryValue: e.friendlyId })),
    values: enrolments.map((e) => ({
      label: e.friendlyId,
      queryValue: e.friendlyId,
      isSelected:
        searchParams.has(LocationStudentsPageQueryParams.Enrolment) &&
        searchParams.getAll(LocationStudentsPageQueryParams.Enrolment!).includes(e.friendlyId),
    })),
  };

  return [...staticFilters, enrolmentFilter];
};

interface SchoolStudentsFiltersProps {
  locationId: string;
  sx?: BoxProps['sx'];
}

const LocationStudentsFilters = (props: SchoolStudentsFiltersProps) => {
  const { locationId, sx } = props;

  const [searchParams, setSearchParams] = useStableSearchParams();

  const [filterSelections, setFilterSelections] = useState<IFilterSelection[]>([]);

  useSearchLocationEnrolments(locationId, {
    onSuccess: (d) => {
      setFilterSelections(getInitialFilterSelections(searchParams, d.enrolments));
    },
    onError: (err) => {
      console.error(err);
    },
  });

  // When filters have been updated, then update the query args in the URL.
  useEffect(() => {
    if (filterSelections.length === 0) return;
    // Transform the selected filters into an object that react-router-dom can recognize as query strings.
    const selectedFilters = filterSelections
      .map((filterSelection) => {
        const selectedOptionValues = filterSelection.values
          .filter((filterOption) => filterOption.isSelected)
          .map((filterOption) => filterOption.queryValue);
        return {
          filterName: filterSelection.queryName!,
          selectedOptionValues,
        };
      })
      .filter((filter) => filter.selectedOptionValues.length > 0)
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr.filterName]: curr.selectedOptionValues,
        };
      }, {});

    setSearchParams(
      (prevSearchParamsState) => {
        const newSearchParams = createSearchParams(selectedFilters);

        const retainPrevSearchArgIfExists = (key: string) => {
          if (prevSearchParamsState.has(key)) {
            newSearchParams.set(key, prevSearchParamsState.get(key)!);
          }
        };
        // Retain all other "non-filter" query properties.
        retainPrevSearchArgIfExists(LocationStudentsPageQueryParams.Search);
        retainPrevSearchArgIfExists(LocationStudentsPageQueryParams.PageSize);
        retainPrevSearchArgIfExists(LocationStudentsPageQueryParams.OrderBy);
        retainPrevSearchArgIfExists(LocationStudentsPageQueryParams.SortDirection);

        // Reset to the initial page when filter criteria changes, else result set may become out of sync.
        newSearchParams.set(LocationStudentsPageQueryParams.Page, '1');

        return newSearchParams;
      },
      { replace: true },
    );
  }, [filterSelections, setSearchParams]);

  return <SearchFilters sx={sx} filterSelections={filterSelections} setFilterSelections={setFilterSelections} />;
};

export default LocationStudentsFilters;
