import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SearchSelect } from '@hoot/components/SearchSelect';
import CountryDropDown from '@hoot/components/form/CountryDropDown';
import { ProvinceStateSelect } from '@hoot/components/form/ProvinceStateSelect';
import { useAlert } from '@hoot/contexts/AlertContext';
import useCreateDistrictSchoolRequest, { CreateBillableDistrictSchool } from '@hoot/hooks/api/hfs/useCreateDistrictSchool';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { routes } from '@hoot/routes/routes';
import { timeZoneData } from '@hoot/utils/timeZones';

export interface DistrictSchoolForm {
  name: string;
  country: string;
  provinceState: string;
  city: string;
  timeZone: string;
}

interface Props {
  districtId: string;
  districtCountry: string;
  districtProvinceState: string;
  open: boolean;
  onClose: () => void;
}

const CreateDistrictSchoolModal = (props: Props) => {
  const { districtId, districtCountry, districtProvinceState, open, onClose } = props;
  const queryClient = useQueryClient();
  const { success, error } = useAlert();
  const navigate = useNavigate();

  const { control, handleSubmit, watch } = useForm<DistrictSchoolForm>({
    defaultValues: {
      name: '',
      city: '',
      country: districtCountry,
      provinceState: districtProvinceState,
      timeZone: '',
    },
  });

  const createDistrictSchoolMutation = useCreateDistrictSchoolRequest();

  const onSubmit = (data: DistrictSchoolForm) => {
    const request: CreateBillableDistrictSchool = {
      name: data.name,
      country: data.country,
      provinceState: data.provinceState,
      locationType: LocationType.School,
      city: data.city,
      timeZone: data.timeZone,
      districtId: districtId,
      reporting: false,
    };
    createDistrictSchoolMutation.mutate(request, {
      onSuccess: (locationId) => {
        success(`School created.`);
        queryClient.invalidateQueries([QueryKey.GetDistrictsSchool, districtId]);
        navigate(routes.districtsSchools.details.url(locationId));
      },
      onError: (err) => {
        error(err.message);
      },
    });
  };

  const { country } = watch();

  return (
    <form id="create-district-school-form" autoComplete="off">
      <Dialog open={open} onClose={onClose}>
        <DialogTitle
          id="dialog-title"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="headlineSmall">Create New School</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container item spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12}>
              <Controller
                name="name"
                rules={{
                  required: `School name is required`,
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    helperText={fieldState.error?.message}
                    error={!!fieldState.error}
                    fullWidth
                    label={`Name of School*`}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item container xs={12}>
              <Controller
                name="country"
                control={control}
                rules={{
                  required: `Country is required`,
                }}
                render={({ field, fieldState }) => (
                  <CountryDropDown value={field.value} onChange={field.onChange} includeEmpty errorMessage={fieldState.error?.message} />
                )}
              />
            </Grid>

            <Grid item container xs={12}>
              <Controller
                name="provinceState"
                control={control}
                rules={{
                  required: `Province/State is required`,
                }}
                render={({ field, fieldState }) => (
                  <ProvinceStateSelect
                    onChange={field.onChange}
                    value={field.value}
                    countryCode={country}
                    disabled={!country}
                    errorMessage={fieldState.error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item container xs={12}>
              <Controller
                name="city"
                control={control}
                rules={{
                  required: 'A city is required',
                }}
                render={({ field, fieldState }) => (
                  <TextField {...field} helperText={fieldState.error?.message} error={!!fieldState.error} fullWidth label="City" variant="outlined" />
                )}
              />
            </Grid>

            <Grid item container xs={12}>
              <SearchSelect
                control={control}
                label="Timezone*"
                name="timeZone"
                data={timeZoneData}
                required={true}
                helperText={'Timezone is required'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button fullWidth size="large" color="inherit" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button fullWidth size="large" variant="contained" onClick={handleSubmit(onSubmit)}>
              Apply
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default CreateDistrictSchoolModal;
