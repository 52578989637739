import React, { useState } from 'react';
import { SearchTeacherInvoiceUploadedTasksQuery } from '@hoot/hooks/api/teacher-invoice/useSearchTeacherInvoiceUploadedBillableTasks';
import SearchTextField from '../../components/form/SearchTextField';

interface Props {
  query: SearchTeacherInvoiceUploadedTasksQuery;
  setQuery: React.Dispatch<React.SetStateAction<SearchTeacherInvoiceUploadedTasksQuery>>;
}

const TeacherInvoiceUploadedTasksSearchInput = (props: Props) => {
  const { query, setQuery } = props;
  const [searchInput, setSearchInput] = useState(query.search ?? '');

  const onSearchInputChanged = (searchInput: string) => {
    setSearchInput(searchInput);
  };

  // When search input is debounced, then update the search query arg in the URL.
  const onSearchInputDebounced = (searchInput: string) => {
    setQuery((prev) => ({
      ...prev,
      search: searchInput,
      page: 1,
    }));
  };

  return (
    <>
      <SearchTextField searchInput={searchInput} onSearchInputChanged={onSearchInputChanged} onSearchInputDebounced={onSearchInputDebounced} />
    </>
  );
};

export default TeacherInvoiceUploadedTasksSearchInput;
