import { EmailOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Divider, IconButton, Stack, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { VALID_HOOT_EMAIL_REGEX } from '@hoot/common/constants';
import SwitchField from '@hoot/components/form/SwitchField';
import { TextField } from '@hoot/components/form/TextField';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { useReSendHootEmployeeAccountCreation } from '@hoot/hooks/api/hoot-employees/useReSendHootEmployeeAccountCreation';
import useUpdateHootEmployee from '@hoot/hooks/api/hoot-employees/useUpdateHootEmployee';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { HootEmployee } from '@hoot/interfaces/hoot-employee';
import useHootEmployeeForm from '../hooks/useHootEmployeeForm';

interface Props {
  hootEmployeeUserId?: string;
  setHootEmployeeUserId: Dispatch<SetStateAction<string | undefined>>;
  selectedHootEmployee?: HootEmployee;
  queryIsLoading: boolean;
  setFormIsDirty: Dispatch<SetStateAction<boolean>>;
}

const HootEmployeeEdit = (props: Props) => {
  const { hootEmployeeUserId, selectedHootEmployee, queryIsLoading, setFormIsDirty } = props;
  const { hootEmployee } = useAuth();
  const isUserSuperEmployee = hootEmployee!.canGrantPermissions;
  const { success, error } = useAlert();

  ///// Query items /////

  const queryClient = useQueryClient();

  const updateHootEmployee = useUpdateHootEmployee(selectedHootEmployee?.id ?? '');

  const resendAccountCreation = useReSendHootEmployeeAccountCreation({
    onSuccess: () => success(`Account creation email resent to ${selectedHootEmployee!.email}`),
    onError: () => error('Error resending the account creation email'),
  });

  const isLoading = queryIsLoading || updateHootEmployee.isLoading;

  ///// Form items /////

  const { control, handleSubmit, formState, resetForm, setValue, watch } = useHootEmployeeForm(selectedHootEmployee);

  const isCurrHootEmployeeSuperUser = watch('isSuperUser');

  ///// Side effects /////

  useEffect(() => {
    resetForm();
  }, [resetForm, selectedHootEmployee]);

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState, setFormIsDirty]);

  /*
   * If the employee is set to a super user then they
   * automatically get all permissions.
   */
  useEffect(() => {
    if (isCurrHootEmployeeSuperUser) {
      setValue('canUpdateBooks', true);
      setValue('canViewLessonVideos', true);
      setValue('canImpersonateUsers', true);
      setValue('canAccessReportingPage', true);
      setValue('canSoftDeleteUsers', true);
      setValue('canAccessPartnerManagement', true);
      setValue('canAdvancedEditLessonTeacher', true);
      setValue('canAdvancedEditLessonDetails', true);
      setValue('canAccessInvoicing', true);
      setValue('canManageLessonReviews', true);
      setValue('canImportTeacherReliabilityScore', true);
      setValue('canManageAdvancedStudentData', true);
      setValue('canViewDistrictDetails', true);
      setValue('canManageStudentDetails', true);
      setValue('canManageTeacherDetails', true);
      setValue('canManageDistrictReps', true);
      setValue('canAccessBillingInformation', true);
      setValue('canDeleteAssessments', true);
      setValue('canManageInstructionalLibraries', true);
    } else {
      setValue('canUpdateBooks', !!selectedHootEmployee?.canUpdateBooks);
      setValue('canViewLessonVideos', !!selectedHootEmployee?.canViewLessonVideos);
      setValue('canImpersonateUsers', !!selectedHootEmployee?.canImpersonateUsers);
      setValue('canAccessReportingPage', !!selectedHootEmployee?.canAccessReportingPage);
      setValue('canSoftDeleteUsers', !!selectedHootEmployee?.canSoftDeleteUsers);
      setValue('canAccessPartnerManagement', !!selectedHootEmployee?.canAccessPartnerManagement);
      setValue('canAdvancedEditLessonTeacher', !!selectedHootEmployee?.canAdvancedEditLessonTeacher);
      setValue('canAdvancedEditLessonDetails', !!selectedHootEmployee?.canAdvancedEditLessonDetails);
      setValue('canAccessInvoicing', !!selectedHootEmployee?.canAccessInvoicing);
      setValue('canManageLessonReviews', !!selectedHootEmployee?.canManageLessonReviews);
      setValue('canImportTeacherReliabilityScore', !!selectedHootEmployee?.canImportTeacherReliabilityScore);
      setValue('canManageAdvancedStudentData', !!selectedHootEmployee?.canManageAdvancedStudentData);
      setValue('canViewDistrictDetails', !!selectedHootEmployee?.canViewDistrictDetails);
      setValue('canManageStudentDetails', !!selectedHootEmployee?.canManageStudentDetails);
      setValue('canManageTeacherDetails', !!selectedHootEmployee?.canManageTeacherDetails);
      setValue('canManageDistrictReps', !!selectedHootEmployee?.canManageDistrictReps);
      setValue('canAccessBillingInformation', !!selectedHootEmployee?.canAccessBillingInformation);
      setValue('canDeleteAssessments', !!selectedHootEmployee?.canDeleteAssessments);
      setValue('canManageInstructionalLibraries', !!selectedHootEmployee?.canManageInstructionalLibraries);
    }
  }, [isCurrHootEmployeeSuperUser, setValue, selectedHootEmployee]);

  ///// Event handlers /////

  const onSubmit = async (data: any) => {
    updateHootEmployee.mutate(data, {
      onSuccess: () => {
        success(`Employee successfully edited.`);
        queryClient.invalidateQueries([QueryKey.GetHootEmployee, hootEmployeeUserId]);
        queryClient.invalidateQueries(QueryKey.HootEmployeeSearch);
      },
      onError: (errorData: any) => {
        console.error(errorData);
        const message = `${errorData.response.data.message}` || `There was an error, the employee has not been edited.`;
        error(message);
      },
    });
  };

  const handleResendAccountCreation = () => {
    resendAccountCreation.mutate(selectedHootEmployee!.email);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="hoot-employee-form">
      <Stack direction="column" spacing={[4, 2]} sx={{ width: '320px' }}>
        <Stack direction="column" alignItems="flex-start" justifyContent="center" sx={{ paddingLeft: '20px' }}>
          <Tooltip title={`Resend account creation email to ${selectedHootEmployee?.email}`}>
            <IconButton
              color="primary"
              aria-label="resend account creation email"
              component="span"
              onClick={handleResendAccountCreation}
              size="large"
              sx={{
                pl: 0,
                '&.Mui-disabled': {
                  pointerEvents: 'unset',
                },
                color: 'rgba(0,0,0,0.54)',
              }}
            >
              <EmailOutlined />
              <Box sx={{ pl: 1, fontSize: 16, color: 'rgba(0,0,0,0.87)' }}>Resend Account Creation Email</Box>
              {resendAccountCreation.isLoading && (
                <Box sx={{ pl: 1 }}>
                  <CircularProgress size={16} />
                </Box>
              )}
            </IconButton>
          </Tooltip>
        </Stack>
        <Divider style={{ marginTop: 0 }} />
        <Stack direction="column" alignItems="center" alignSelf="center" sx={{ width: '320px', padding: '10px' }} spacing={3}>
          <SwitchField label="Enabled" control={control} name="isEnabled" />

          <TextField
            name="firstName"
            label={'First Name'}
            rules={{
              required: 'First Name is required',
              maxLength: {
                value: 48,
                message: 'First Name is too long',
              },
            }}
            control={control}
            variant="outlined"
            size="small"
            fullWidth
            helperText={''}
            helperTextFn={() => formState?.errors['firstName']?.message || ''}
          />
          <TextField
            name="lastName"
            label={'Last Name'}
            rules={{
              required: 'Last Name is required',
              maxLength: {
                value: 48,
                message: 'Last Name is too long',
              },
            }}
            control={control}
            variant="outlined"
            size="small"
            fullWidth
            helperText={''}
            helperTextFn={() => formState?.errors['lastName']?.message || ''}
          />

          <TextField
            name="email"
            label={'Email'}
            rules={{
              required: 'Email is required',
              maxLength: {
                value: 48,
                message: 'Email is too long',
              },
              pattern: {
                value: VALID_HOOT_EMAIL_REGEX,
                message: 'A valid @hootreading.com email is required.',
              },
            }}
            control={control}
            variant="outlined"
            size="small"
            fullWidth
            helperText={''}
            helperTextFn={() => formState?.errors['email']?.message || ''}
          />
          {isUserSuperEmployee ? (
            <Stack direction="column" alignItems="center" alignSelf="center" sx={{ width: '320px', padding: '10px' }} spacing={1}>
              <Divider>Permissions</Divider>
              <SwitchField label="Is Super User" control={control} name="isSuperUser" />
              <SwitchField label="Can Access Lesson Recordings" control={control} name="canViewLessonVideos" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField
                label="Can Access Partner Management"
                control={control}
                name="canAccessPartnerManagement"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField label="Can Access Invoicing" control={control} name="canAccessInvoicing" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField label="Can Access Reporting" control={control} name="canAccessReportingPage" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField label="Can Manage Library" control={control} name="canUpdateBooks" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField label="Can Impersonate Users" control={control} name="canImpersonateUsers" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField label="Can Soft-delete Users" control={control} name="canSoftDeleteUsers" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField
                label="Can Edit Assigned Teacher After Lesson Start Time"
                control={control}
                name="canAdvancedEditLessonTeacher"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField
                label="Can Update Lesson Details After Lesson Start Time"
                control={control}
                name="canAdvancedEditLessonDetails"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField label="Can Manage Lesson Reviews" control={control} name="canManageLessonReviews" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField label="Can Import TRS" control={control} name="canImportTeacherReliabilityScore" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField
                label="Can Manage Advanced Student Data"
                control={control}
                name="canManageAdvancedStudentData"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField label="Can View District Details" control={control} name="canViewDistrictDetails" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField
                label="Can Manage Student Details"
                control={control}
                name="canManageStudentDetails"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField
                label="Can Manage Teacher Details"
                control={control}
                name="canManageTeacherDetails"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField label="Can Manage District Reps" control={control} name="canManageDistrictReps" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField
                label="Can Access Billing Information"
                control={control}
                name="canAccessBillingInformation"
                disabled={isCurrHootEmployeeSuperUser}
              />
              <SwitchField label="Can Delete Assessments" control={control} name="canDeleteAssessments" disabled={isCurrHootEmployeeSuperUser} />
              <SwitchField
                label="Can Manage Instructional Libraries"
                control={control}
                name="canManageInstructionalLibraries"
                disabled={isCurrHootEmployeeSuperUser}
              />
            </Stack>
          ) : null}

          <Button
            form="hoot-employee-form"
            variant="contained"
            type="submit"
            disabled={!formState.isValid || isLoading}
            fullWidth
            style={{ height: 42, marginTop: 30 }}
          >
            {isLoading ? <CircularProgress size={22} /> : 'Apply'}
          </Button>
          <Button
            form="hoot-employee-form"
            variant="contained"
            type="reset"
            fullWidth
            style={{ height: 42, marginTop: 15, color: '#000', backgroundColor: '#E0E0E0' }}
            onClick={() => resetForm()}
          >
            Reset
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default HootEmployeeEdit;
