import { SortOrder } from '@hoot/interfaces/order-by';

export enum LibraryOrderByEnum {
  Title = 'title',
  BookLevel = 'bookLevel',
  BookNumber = 'bookNumber',
}

export interface BookSortableQuery {
  page: number;
  pageSize: number;
  orderBy?: SortOrder;
  orderColumn?: LibraryOrderByEnum;
}

export interface BookTableSortableFields {
  title: React.ReactNode;
  bookLevel: string;
  bookNumber: string;
}

export const handleSortBy = (column: keyof BookTableSortableFields, setQuery: (query: (prev: any) => any) => void): void => {
  function orderColumn() {
    switch (column) {
      case 'title':
        return LibraryOrderByEnum.Title;
      case 'bookLevel':
        return LibraryOrderByEnum.BookLevel;
      case 'bookNumber':
        return LibraryOrderByEnum.BookNumber;
      default:
        return LibraryOrderByEnum.Title;
    }
  }

  setQuery((prev) => ({
    ...prev,
    orderColumn: orderColumn(),
    orderBy: prev.orderBy === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
  }));
};

export const sortBy = (column?: keyof BookTableSortableFields): keyof BookTableSortableFields => {
  switch (column) {
    case LibraryOrderByEnum.Title:
      return 'title';
    case LibraryOrderByEnum.BookLevel:
      return 'bookLevel';
    case LibraryOrderByEnum.BookNumber:
      return 'bookNumber';
    default:
      return 'title';
  }
};
