import { DistrictRepEnrolmentAccessLevel } from '@hoot-reading/hoot-core/dist/enums/hfs/district-rep-enrolment-access-level.enum';
import axios, { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface AssignDistrictRepPermissionsRequest {
  districtRepId: string;
  enrolmentAccessLevel: DistrictRepEnrolmentAccessLevel;
  locationId?: string;
  enrolmentIds?: string[];
}

const assignDistrictRepPermissions = async (request: AssignDistrictRepPermissionsRequest): Promise<void> => {
  const { data } = await axios.put<void>(`${config.apiUrl}/admin/district-representative/assign-permissions`, request);
  return data;
};

const useAssignDistrictRepPermissions = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, AssignDistrictRepPermissionsRequest>((request) => assignDistrictRepPermissions(request), {
    onSuccess: (data, variables) => {
      return queryClient.invalidateQueries([QueryKey.SearchDistrictsSchools, variables.districtRepId]);
    },
  });
};

export default useAssignDistrictRepPermissions;
