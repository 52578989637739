import { ListItem, ListItemText, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';

export interface TableHeaderProp<T> {
  property: keyof T;
  label: string;
}

export function SortableTableHeader<T>(props: {
  headers: TableHeaderProp<T>[];
  sortBy: keyof T | null;
  setSortBy: React.Dispatch<React.SetStateAction<keyof T | null>>;
  sortOrder: 'asc' | 'desc' | null;
  setSortOrder: React.Dispatch<React.SetStateAction<'desc' | 'asc' | null>>;
}) {
  const { headers, sortBy, setSortBy, sortOrder, setSortOrder } = props;

  const handleHeaderClick = (property: keyof T) => () => {
    if (sortBy !== property) {
      setSortBy(property);
      setSortOrder('asc');
    } else {
      setSortOrder((current) => (current === 'asc' ? 'desc' : 'asc'));
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map((h) => (
          <TableCell key={`table-header-cell-${h.property.toString()}`}>
            <TableSortLabel
              active={sortBy === h.property}
              direction={sortBy === h.property && sortOrder ? sortOrder : 'asc'}
              onClick={handleHeaderClick(h.property)}
            >
              {h.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const NoneListItem = () => {
  return (
    <ListItem
      sx={{
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        borderRadius: '4px',
        padding: '8px 24px 8px 16px',
      }}
    >
      <ListItemText
        primary={
          <Typography component="div" variant="bodyLarge">
            None
          </Typography>
        }
      />
    </ListItem>
  );
};
