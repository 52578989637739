import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { SortableQuery } from '@hoot/interfaces/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/interfaces/pagination';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';
import { LocationSummaryQuery } from './useGetLocationSummaryDashboard';

interface Enrolment {
  id: string;
  friendlyId: string;
}

export enum GetLocationSummaryStudentsQuerySortKeys {
  StudentNumber = 'studentNumber',
  Grade = 'grade',
}

export interface LocationSummaryStudentsQuery
  extends LocationSummaryQuery,
    PaginatableQuery,
    SortableQuery<GetLocationSummaryStudentsQuerySortKeys> {}

export interface GetLocationSummaryStudentsResponse {
  id: string;
  number: string;
  prefixedNumber: string;
  grade: StudentGrade;
  attendanceRate: string;
  hoursInLesson: string;
  enrolments: Enrolment[];
}

export default function useGetLocationSummarySchools(
  locationId: string,
  searchParams: LocationSummaryStudentsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<GetLocationSummaryStudentsResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetLocationSummaryStudents, locationId, searchParams],
    async () => {
      return (
        await axios.get(`${config.apiUrl}/admin/hfs/location/${locationId}/summary/students`, {
          params: searchParams,
        })
      ).data;
    },
    options,
  );
}
