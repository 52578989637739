import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Stack, keyframes } from '@mui/material';
import { DateTime } from 'luxon';
import { TIME_FORMAT } from '@hoot/common/constants';
import { ONE_MINUTE_PX_HEIGHT } from './constants';
import { ZIndexLayer } from './enums';
import { LessonTimeSlot, SelectedItem } from './interfaces';
import { convertTimeToOffset, lessonColour } from './utils';

interface LessonProps {
  selectedItem?: SelectedItem;
  onSelected: (selectedItem: SelectedItem) => void;
  timeslots: LessonTimeSlot[];
  disabled: boolean;
  showStudentNumbers?: boolean;
  yAxisLabelsSliced: string[];
}

function Lesson(props: LessonProps) {
  const minStartsAt = DateTime.fromMillis(Math.min(...props.timeslots.map((ts) => ts.startAt.toMillis()))).toFormat(TIME_FORMAT);
  const maxEndsAt = DateTime.fromMillis(Math.max(...props.timeslots.map((ts) => ts.endsAt.toMillis()))).toFormat(TIME_FORMAT);

  const top = Math.max(convertTimeToOffset(minStartsAt, props.yAxisLabelsSliced), 0);
  const height = convertTimeToOffset(maxEndsAt, props.yAxisLabelsSliced) - top;

  const handleClick = () => {
    props.onSelected({
      ids: props.timeslots.map((ts) => ts.id),
      type: 'LESSON',
    });
  };

  const isSelected =
    props.selectedItem && props.selectedItem.ids.some((id) => props.timeslots.some((i) => i.id === id)) && props.selectedItem.type === 'LESSON';

  function label() {
    if (props.timeslots.length === 1) {
      return props.showStudentNumbers ? props.timeslots[0].studentNumber : props.timeslots[0].number;
    } else {
      return `Multi ${props.timeslots.length}`;
    }
  }

  function backgroundColor() {
    if (props.timeslots.length === 1) {
      return lessonColour(props.timeslots[0].status);
    } else {
      if (
        props.timeslots.some(
          (ts) => ts.status === ScheduledLessonStatus.CompletedSuccessfully || ts.status === ScheduledLessonStatus.CompletedUnsuccessfully,
        )
      ) {
        return lessonColour(ScheduledLessonStatus.CompletedSuccessfully);
      } else if (
        props.timeslots.some(
          (ts) =>
            ts.status === ScheduledLessonStatus.InProgress ||
            ts.status === ScheduledLessonStatus.Open ||
            ts.status === ScheduledLessonStatus.OpenWaitingForStudent ||
            ts.status === ScheduledLessonStatus.OpenWaitingForTeacher ||
            ts.status === ScheduledLessonStatus.Scheduled,
        )
      ) {
        return lessonColour(ScheduledLessonStatus.Scheduled);
      } else {
        return lessonColour(ScheduledLessonStatus.Cancelled);
      }
    }
  }

  function backgroundImage() {
    if (props.timeslots.some((ts) => ts.status === ScheduledLessonStatus.Cancelled)) {
      if (props.timeslots.some((ts) => ts.status === ScheduledLessonStatus.Scheduled)) {
        return `-webkit-linear-gradient(30deg, ${lessonColour(ScheduledLessonStatus.Scheduled)} 50%, ${lessonColour(
          ScheduledLessonStatus.Cancelled,
        )} 50%);`;
      }
    }

    return undefined;
  }

  return (
    <Stack
      onClick={props.disabled ? undefined : handleClick}
      sx={{
        position: 'absolute',
        zIndex: ZIndexLayer.Middle,
        right: '8px',
        top: `${top * ONE_MINUTE_PX_HEIGHT}px`,
        cursor: props.disabled ? undefined : 'pointer',
        pointerEvents: props.disabled ? 'none' : undefined,
      }}
    >
      <Stack
        sx={{
          borderRadius: '8px',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '12px',
          lineHeight: '16px',
          fontWeight: 400,
          color: '#FFFFFF',
          height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
          backgroundColor: backgroundColor(),
          backgroundImage: backgroundImage(),
          width: '70px',
          boxShadow: isSelected ? '0px 2px 6px 2px #FF8E00, 0px 1px 2px 0px #FF8E00' : undefined,
        }}
      >
        {label()}
      </Stack>
      {isSelected ? (
        <Stack
          sx={{
            position: 'absolute',
            height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
            width: '70px',
            borderRadius: '8px',
            border: '3px solid #CBA82C',
            animation: `${pulseAnimation} 2s ease 0s infinite`,
          }}
        />
      ) : null}
    </Stack>
  );
}

const pulseAnimation = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    80% {
        opacity: 0;
        transform: scale(1.5);
    }
    100% {
        opacity: 0;
        transform: scale(2);
    }
`;

export default Lesson;
