// @ts-nocheck
import { Box, Paper, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { config } from '../../../config';

interface GoogleSignInResponse {
  credential: string;
}

const Login = () => {
  const { login } = useAuth();
  const [error, setError] = useState<string | undefined>();
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);

  const handleCallbackResponse = useCallback(
    async (response: GoogleSignInResponse) => {
      await login(response.credential).catch((err) => {
        const errorMessage = err.response ? err.response.data.message : 'An unexpected error occurred. Please try again.';
        setError(errorMessage);
      });
    },
    [login, setError],
  );

  useEffect(() => {
    if (!window.google || gsiScriptLoaded) return;

    window.google.accounts.id.initialize({
      client_id: config.gCloudHootAdminPortalOAuth2ClientId,
      callback: handleCallbackResponse,
      context: 'signin',
    });

    window.google.accounts.id.renderButton(document.getElementById('signInDiv'), {
      theme: 'filled_blue',
    });
    setGsiScriptLoaded(true);
  }, [gsiScriptLoaded, handleCallbackResponse]);

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '32px',
      }}
      elevation={10}
    >
      <Box
        sx={{
          gap: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5" align="center">
          Hoot Reading Admin Portal Login
        </Typography>
        <Box visibility={'none'} sx={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }} id="signInDiv"></Box>
        <Box
          sx={{
            fontSize: '12px',
            color: '#FF0000',
            margin: '8px 0px',
            textAlign: 'center',
          }}
        >
          {error}
        </Box>
      </Box>
    </Paper>
  );
};

export default Login;
