import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface SubTopicsResponse {
  id: string;
  name: string;
}

async function getSubTopics(): Promise<SubTopicsResponse[]> {
  const { data } = await axios.get<SubTopicsResponse[]>(`${config.apiUrl}/admin/library/sub-topics`);
  return data;
}

export default function useGetSubTopics(options?: Omit<UseQueryOptions<SubTopicsResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetSubTopics], () => getSubTopics(), options);
}
