import { ThemeOptions, createTheme } from '@mui/material/styles';
import TickIcon from '../components/icons/TickIcon';
import { SwitchStyleOverrides } from './overrides/SwitchStyleOverrides';
import palette from './palette';
import typography from './typography';

const hootTheme = createTheme({
  typography,
  palette,
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'capitalize',
          borderRadius: '100px',
          ...typography.labelLarge,
          ...(ownerState.size === 'large' && {
            paddingTop: '10px',
            paddingBottom: '10px',
          }),
        }),
        outlinedPrimary: {
          borderColor: '#000000',
          color: '#000000',
          ':hover': {
            backgroundColor: '#f2f2f2',
            borderColor: '#000000',
            color: '#000000',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          paddingRight: '0px !important',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {},
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        autoComplete: 'do-not-autofill',
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: '#F6F7F8',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'head' && {
            ...typography.tableHeading,
          }),
          borderBottom: '#1px solid #E4E4E4',
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'outlined' && {
            border: '1px solid #79747E',
          }),
          borderRadius: '8px',
        }),
        labelSmall: {
          fontSize: '12px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-selected': {
            color: '#1A1C1E',
          },
        },
        textColorPrimary: {
          color: '#1A1C1E',
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
        checkedIcon: <TickIcon />,
        defaultChecked: false,
      },
      styleOverrides: SwitchStyleOverrides,
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          ...typography.headlineSmall,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          ...typography.bodyMedium,
          color: '#000000',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '24px',
          position: 'relative',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'rounded' && {
            borderRadius: '100px',
          }),
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '16px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#1A1C1E',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          color: '#1A1C1E',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          MenuListProps: {
            sx: {
              backgroundColor: '#EFEFEF',
            },
          },
        },
      },
    },
  },
} as ThemeOptions);

export default hootTheme;
