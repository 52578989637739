import { ScheduledLessonSubject } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { StudentGoal } from '@hoot-reading/hoot-core/dist/enums/user/student/student-goal.enum';
import { Box, Button, Card, CardContent, Grid, Skeleton, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { AdminLessonNoteDetails } from '@hoot/hooks/api/lesson-notes/useGetLessonNoteDetailsById';
import Bolt from '../../../../../components/icons/Bolt';
import GenericCheckListModal, { CheckListModalItemItem } from '../../../../../components/modals/GenericCheckListModal';
import NoneItem from '../../../../../components/ui/NoneItem';
import {
  MathStrengthsGoals,
  mathStrengthsGoals,
  mathStrengthsGoalsList,
  readingStrengthsGoals,
  readingStrengthsGoalsList,
} from '../../../../lessons/enums';

interface LessonNoteStrengthsCardProps {
  isLoading: boolean;
  lessonSubject: AdminLessonNoteDetails['lessonSubject'];
  strengths: AdminLessonNoteDetails['strengths'];
  onUpdateStrengths: (strengths: AdminLessonNoteDetails['strengths']) => void;
}

const LessonNoteStrengthsCard = (props: LessonNoteStrengthsCardProps) => {
  const { isLoading, lessonSubject, strengths, onUpdateStrengths } = props;

  const [showStrengthsModal, setShowStrengthsModal] = useState(false);

  // Set of strengths already applied.
  const appliedStrengthsSet = useMemo(() => new Set<string>(strengths), [strengths]);

  // List of strengths that we can select (for the modal).
  const strengthsList = useMemo<CheckListModalItemItem[]>(() => {
    return lessonSubject === ScheduledLessonSubject.Math ? [...mathStrengthsGoalsList] : [...readingStrengthsGoalsList];
  }, [lessonSubject]);

  const onShowStrengthsModal = () => {
    setShowStrengthsModal(true);
  };

  const onDismissStrengthsModal = () => {
    setShowStrengthsModal(false);
  };

  const onApplyStrengths = (checkedValues: string[]) => {
    onDismissStrengthsModal();
    onUpdateStrengths(checkedValues as AdminLessonNoteDetails['strengths']);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 3,
              mb: 3,
            }}
          >
            <Typography variant="titleLarge" sx={{ display: 'block' }}>
              Strengths
            </Typography>
            {isLoading ? (
              <Skeleton variant="rounded" width="70px" height="36px" />
            ) : (
              <Button variant="contained" size="large" onClick={onShowStrengthsModal}>
                {strengths && strengths.length > 0 ? 'Edit' : 'Add'}
              </Button>
            )}
          </Box>
          {isLoading ? (
            <LoadingContent />
          ) : strengths && strengths.length === 0 ? (
            <NoneItem />
          ) : (
            <Table>
              <TableBody>
                {strengths &&
                  strengths.map((strength) => (
                    <TableRow key={strength}>
                      <TableCell>
                        <Stack direction="row" spacing={2.5} alignItems="center">
                          <Bolt />
                          <Typography variant="bodyLarge">
                            {readingStrengthsGoals[strength as StudentGoal] ?? mathStrengthsGoals[strength as MathStrengthsGoals] ?? strength}
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
      <GenericCheckListModal
        show={showStrengthsModal}
        title="Strengths"
        listItems={strengthsList}
        defaultCheckedItems={appliedStrengthsSet}
        confirmButtonTitle="Apply"
        onDismiss={onDismissStrengthsModal}
        onConfirmButtonClicked={onApplyStrengths}
        dialogContentSx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            width: '480px',
          },
        })}
      />
    </>
  );
};

const LoadingContent = () => (
  <Grid container spacing={1}>
    {[...Array(4)].map((_, index) => (
      <React.Fragment key={`lesson-note-strengths-card-placeholder-${index}`}>
        <Grid item xs={1}>
          <Skeleton variant="text" sx={{ maxWidth: '200px' }} />
        </Grid>
        <Grid item xs={11}>
          <Skeleton variant="text" />
        </Grid>
      </React.Fragment>
    ))}
  </Grid>
);

export default LessonNoteStrengthsCard;
