import { Card, LinearProgress, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { TeacherShiftUtilizationResponse } from '@hoot/hooks/api/shifts/useGetShiftUtilization';
import NeedSubsTableRow from './components/NeedSubsTableRow';
import TeacherTableRows from './components/TeacherTableRows';
import TimeSlotsTableRow from './components/TimeSlotsTableRow';
import TotalsTableRow from './components/TotalsTableRow';
import TeacherTimeSlot from './components/timeslots/TeacherTimeSlot';
import { IShiftsTableBodyProps, IShiftsTableProps } from './shift-interfaces';

const ShiftsTable = (props: IShiftsTableProps) => {
  const { timeSlots, showCreateShiftWizard, setShowCreateShiftWizard, teacherData, isLoading } = props;
  return (
    <Card sx={{ width: '100%' }}>
      {isLoading ? <LinearProgress /> : null}
      <Stack direction="row" sx={{ overflowX: 'auto', maxHeight: '660px' }}>
        <TableHeader teacherData={teacherData} />
        <TableBody
          teacherData={teacherData}
          timeSlots={timeSlots}
          showCreateShiftWizard={showCreateShiftWizard}
          setShowCreateShiftWizard={setShowCreateShiftWizard}
          isLoading={isLoading}
        />
      </Stack>
    </Card>
  );
};

const TableHeader = (props: { teacherData: TeacherShiftUtilizationResponse | undefined }) => {
  const { teacherData } = props;
  const subRequiredLessons = teacherData?.subRequiredLessons ?? [];
  return (
    <Stack
      sx={{
        background: '#F2F2F2',
        minWidth: '212px',
        height: '100%',
        paddingX: 3,
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        position: 'sticky',
        left: 0,
        zIndex: 2,
      }}
    >
      <Stack
        sx={{
          borderBottom: '1px solid #CAC4D0',
          height: '65px',
          justifyContent: 'center',
          position: 'sticky',
          top: 0,
          background: '#F2F2F2',
        }}
      >
        <Typography variant="titleLarge" sx={{ fontWeight: 700 }}>
          Teachers
        </Typography>
      </Stack>
      {subRequiredLessons.length > 0 ? (
        <Stack
          sx={{
            borderBottom: '1px solid #CAC4D0',
            height: '65px',
            justifyContent: 'center',
          }}
        >
          <Stack
            direction="row"
            sx={{
              backgroundColor: '#F9B6A7',
              height: '65px',
              borderRadius: '8px',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              marginY: '12px',
              marginRight: '12px',
            }}
          >
            <Typography variant="titleMedium" sx={{ fontWeight: 700 }}>
              Sub Required
            </Typography>
            <Typography
              variant="titleSmall"
              sx={{
                borderRadius: '50%',
                width: '20px',
                background: '#BA1A1A',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
              }}
            >
              {subRequiredLessons.length}
            </Typography>
          </Stack>
        </Stack>
      ) : null}
      <Stack>
        {teacherData
          ? teacherData?.teachers.map((t) => <TeacherTimeSlot key={t.id} teacher={t} />)
          : Array(10)
              .fill(0)
              .map((_a, idx) => <TeacherTimeSlot key={idx} teacher={undefined} />)}
      </Stack>
      <Stack
        sx={{
          height: '65px',
          justifyContent: 'center',
          position: 'sticky',
          bottom: 0,
          background: '#F2F2F2',
          borderTop: '1px solid #CAC4D0',
        }}
      >
        <Typography variant="bodyLarge" sx={{ fontWeight: 700 }}>
          Totals
        </Typography>
      </Stack>
    </Stack>
  );
};

const TableBody = (props: IShiftsTableBodyProps) => {
  const { teacherData, timeSlots, showCreateShiftWizard, setShowCreateShiftWizard, isLoading } = props;
  const subRequiredLessons = teacherData?.subRequiredLessons ?? [];
  return (
    <Stack sx={{ height: '100%' }}>
      <TimeSlotsTableRow timeSlots={timeSlots!} />
      {subRequiredLessons.length > 0 ? <NeedSubsTableRow timeSlots={timeSlots!} subRequiredLessons={subRequiredLessons} /> : null}
      <TeacherTableRows
        teacherData={teacherData}
        timeSlots={timeSlots!}
        showCreateShiftWizard={showCreateShiftWizard}
        setShowCreateShiftWizard={setShowCreateShiftWizard}
      />
      <TotalsTableRow timeSlots={timeSlots!} teacherData={teacherData} isLoading={isLoading} />
    </Stack>
  );
};

export default ShiftsTable;
