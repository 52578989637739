import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../../queryKeys';
import { HootLevelModuleStatus, HootLevelUnitStatus } from './enum';

export interface AdminHootLevelReportRequest {
  onlyIncludeAssessmentsBefore?: number;
  studentProfileIds?: string[];
  enrolmentId?: string | null;
}

export interface AdminHootLevelReportResponse {
  count: number;
  version: number;
  hootLevels: HootLevels[];
}

export type HootLevels = AdminHootLevelReportDetails | AdminHootLevelV2ReportDetails;

export interface AdminHootLevelReportDetails {
  studentProfileId: string;
  studentName: string;
  studentNumber: number;
  version: 1;
  moduleOne: HootLevelModuleStatus;
  u_1_1a: HootLevelUnitStatus;
  u_1_1b: HootLevelUnitStatus;
  u_1_1c: HootLevelUnitStatus;
  u_1_1d: HootLevelUnitStatus;
  u_1_1e: HootLevelUnitStatus;
  u_1_2a: HootLevelUnitStatus;
  u_1_2b: HootLevelUnitStatus;
  u_1_2c: HootLevelUnitStatus;
  u_1_2d: HootLevelUnitStatus;
  u_1_3a: HootLevelUnitStatus;
  moduleTwo: HootLevelModuleStatus;
  u_2_1a: HootLevelUnitStatus;
  u_2_2a: HootLevelUnitStatus;
  u_2_2b: HootLevelUnitStatus;
  u_2_2c: HootLevelUnitStatus;
  u_2_3a: HootLevelUnitStatus;
  u_2_3b: HootLevelUnitStatus;
  u_2_3c: HootLevelUnitStatus;
  moduleThree: HootLevelModuleStatus;
  u_3_1a: HootLevelUnitStatus;
  u_3_1b: HootLevelUnitStatus;
  u_3_2a: HootLevelUnitStatus;
  u_3_2b: HootLevelUnitStatus;
  moduleFour: HootLevelModuleStatus;
  u_4_1a: HootLevelUnitStatus;
  u_4_2a: HootLevelUnitStatus;
  u_4_3a: HootLevelUnitStatus;
}

export interface AdminHootLevelV2ReportDetails {
  studentProfileId: string;
  studentNumber: number;
  version: 2;
  moduleOne: HootLevelModuleStatus;
  U_1_1_V1: HootLevelUnitStatus;
  U_1_2_V1: HootLevelUnitStatus;
  U_1_3_V1: HootLevelUnitStatus;
  U_1_4_V1: HootLevelUnitStatus;
  U_1_5_V1: HootLevelUnitStatus;
  moduleTwo: HootLevelModuleStatus;
  U_2_1_V1: HootLevelUnitStatus;
  U_2_2_V1: HootLevelUnitStatus;
  U_2_3_V1: HootLevelUnitStatus;
  U_2_4_V1: HootLevelUnitStatus;
  U_2_5_V1: HootLevelUnitStatus;
  U_2_6_V1: HootLevelUnitStatus;
  moduleThree: HootLevelModuleStatus;
  U_3_1_V1: HootLevelUnitStatus;
  U_3_2_V1: HootLevelUnitStatus;
  U_3_3_V1: HootLevelUnitStatus;
  U_3_4_V1: HootLevelUnitStatus;
  U_3_5_V1: HootLevelUnitStatus;
  U_3_6_V1: HootLevelUnitStatus;
  moduleFour: HootLevelModuleStatus;
  U_4_1_V1: HootLevelUnitStatus;
  U_4_2_V1: HootLevelUnitStatus;
  U_4_3_V1: HootLevelUnitStatus;
  U_4_4_V1: HootLevelUnitStatus;
}

async function getHootLevelsReport(request: AdminHootLevelReportRequest): Promise<AdminHootLevelReportResponse> {
  const { data } = await axios.post<AdminHootLevelReportResponse>(`${config.apiUrl}/admin/hoot-level/report`, request);
  return data;
}

export default function useGetHootLevelsReport(
  request: AdminHootLevelReportRequest,
  options?: Omit<UseQueryOptions<AdminHootLevelReportResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetHootLevelsReport, request], () => getHootLevelsReport(request), options);
}
