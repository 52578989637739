import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps, TextField } from '@mui/material';
import { SyntheticEvent } from 'react';
import { bookTypeLookUp, decodableFormatLookUp, gradeLookUp, languageLookUp, spellingTypeLookUp, subjectLookUp } from '../../hooks/api/library/enums';
import useGetCollections from '../../hooks/api/library/filters/useGetCollections';
import useGetContentTags from '../../hooks/api/library/filters/useGetContentTags';
import useGetFilterLevelPrioritizedSkill from '../../hooks/api/library/filters/useGetFilterLevelPrioritizedSkill';
import useGetFormats from '../../hooks/api/library/filters/useGetFormats';
import useGetGenres from '../../hooks/api/library/filters/useGetGenres';
import useGetInstructionalFocus, { InstructionalFocusResponse } from '../../hooks/api/library/filters/useGetInstructionalFocus';
import useGetInstructionalUnits from '../../hooks/api/library/filters/useGetInstructionalUnits';
import useGetLevellingSystems from '../../hooks/api/library/filters/useGetLevellingSystems';
import useGetPublishers from '../../hooks/api/library/filters/useGetPublishers';
import useGetSeries from '../../hooks/api/library/filters/useGetSeries';
import useGetSubGenres from '../../hooks/api/library/filters/useGetSubGenres';
import useGetSubThemes from '../../hooks/api/library/filters/useGetSubThemes';
import useGetSubTopics from '../../hooks/api/library/filters/useGetSubTopics';
import useGetThemes from '../../hooks/api/library/filters/useGetThemes';
import useGetTopics from '../../hooks/api/library/filters/useGetTopics';
import { ReadingLevelType, readingLevelTypeDisplay } from '../lessons/enums';

interface HelperText {
  helperText?: string;
}

/** YesNoSelect */

export function YesNoSelect(props: SelectProps & { onChange: (val: boolean | '') => void }) {
  const handleOnChange = (val: string) => {
    if (props.onChange) {
      if (val === 'yes') {
        props.onChange(true);
      } else if (val === 'no') {
        props.onChange(false);
      } else {
        props.onChange('');
      }
    }
  };

  function getValue(): string | '' {
    switch (props.value) {
      case true:
        return 'yes';
      case false:
        return 'no';
      default:
        return '';
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>
      <Select {...props} onChange={(event) => handleOnChange(event.target.value as string)} value={getValue()}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
    </FormControl>
  );
}

/** ActiveSelect */

export function ActiveSelect(props: SelectProps & { onChange: (val: boolean | '') => void }) {
  const handleOnChange = (val: string) => {
    if (props.onChange) {
      if (val === 'yes') {
        props.onChange(true);
      } else if (val === 'no') {
        props.onChange(false);
      } else {
        props.onChange('');
      }
    }
  };

  function getValue(): string | '' {
    switch (props.value) {
      case true:
        return 'yes';
      case false:
        return 'no';
      default:
        return '';
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>
      <Select {...props} onChange={(event) => handleOnChange(event.target.value as string)} value={getValue()}>
        <MenuItem value="yes">Is Active</MenuItem>
        <MenuItem value="no">Is Inactive</MenuItem>
      </Select>
    </FormControl>
  );
}

/** DecodableFormatSelect */

export function DecodableFormatSelect(props: SelectProps & HelperText) {
  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {Object.entries(decodableFormatLookUp).map(([key, val]) => (
          <MenuItem key={key} value={key}>
            {val}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** PublisherSelect */

export function PublisherSelect(props: SelectProps & HelperText) {
  const publishersRequest = useGetPublishers();

  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {publishersRequest.data?.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** SubjectSelect */

export function SubjectSelect(props: SelectProps & HelperText) {
  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {Object.entries(subjectLookUp).map(([key, val]) => (
          <MenuItem key={key} value={key}>
            {val}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** LanguageSelect */

export function LanguageSelect(props: SelectProps & HelperText) {
  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {Object.entries(languageLookUp).map(([key, val]) => (
          <MenuItem key={key} value={key}>
            {val}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** SpellingTypeSelect */

export function SpellingTypeSelect(props: SelectProps & HelperText) {
  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {Object.entries(spellingTypeLookUp).map(([key, val]) => (
          <MenuItem key={key} value={key}>
            {val}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** ResourceTypeSelect */

export function ResourceTypeSelect(props: SelectProps & HelperText) {
  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {Object.entries(bookTypeLookUp).map((d) => (
          <MenuItem key={d[0]} value={d[0]}>
            {d[1]}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}
/** LevellingSystemSelect */

export function LevellingSystemSelect(props: SelectProps & HelperText) {
  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {readingLevelTypeDisplay.map((d) => (
          <MenuItem key={d.value} value={d.value}>
            {d.label}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** LevelSelect */

export function LevelSelect(props: SelectProps & HelperText & { levelSytem: ReadingLevelType }) {
  const levellingSystemsRequest = useGetLevellingSystems();

  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {levellingSystemsRequest.data
          ?.filter((d) => d.type === props.levelSytem)
          .map((d) => (
            <MenuItem key={d.id} value={d.id}>
              {d.name}
            </MenuItem>
          ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** FormatSelect */

export function FormatSelect(props: SelectProps & HelperText) {
  const formatsRequest = useGetFormats();

  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {formatsRequest.data?.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** ThemeSelect */

export function ThemeSelect(props: SelectProps & HelperText) {
  const themeRequest = useGetThemes();

  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {themeRequest.data?.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** SubThemeSelect */

export function SubThemeSelect(props: SelectProps & HelperText) {
  const subThemeRequest = useGetSubThemes();

  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {subThemeRequest.data?.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** TopicSelect */

export function TopicSelect(props: SelectProps & HelperText) {
  const topicsRequest = useGetTopics();

  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {topicsRequest.data?.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** SubTopicSelect */

export function SubTopicSelect(props: SelectProps & HelperText) {
  const subTopicsRequest = useGetSubTopics();

  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {subTopicsRequest.data?.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** GradeSelect */
export function GradeSelect(props: SelectProps & HelperText) {
  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {Object.entries(gradeLookUp).map((d) => (
          <MenuItem key={d[0]} value={d[0]}>
            {d[1]}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** GenreSelect */

export function GenreSelect(props: SelectProps & HelperText) {
  const genresRequest = useGetGenres();

  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {genresRequest.data?.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** SubGenreSelect */

export function SubGenreSelect(props: SelectProps & HelperText) {
  const subGenresRequest = useGetSubGenres();

  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {subGenresRequest.data?.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** SeriesSelect */

export function SeriesSelect(props: SelectProps & HelperText) {
  const seriesRequest = useGetSeries();

  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>

      <Select {...props}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {seriesRequest.data?.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

/** LevelMultiAutocomplete */

export function LevelMultiAutocomplete(props: { levelSytem: ReadingLevelType | ''; value: string[]; onChange: (val: string[]) => void }) {
  const levellingSystemsRequest = useGetLevellingSystems();

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: InstructionalFocusResponse[]) => {
    props.onChange(value.map((v) => v.id));
  };

  const options = levellingSystemsRequest.data?.filter((d) => d.type === props.levelSytem) ?? [];
  const value = options.filter((o) => props.value.find((v) => v === o.id));

  return (
    <Autocomplete
      multiple
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.name}
      options={options}
      renderInput={(params) => <TextField {...params} label="Level" fullWidth />}
      fullWidth
    />
  );
}

/** InstructionalFocusAutocomplete */

export function InstructionalFocusAutocomplete(props: { value: string[]; onChange: (val: string[]) => void }) {
  const instructionalFocusRequest = useGetInstructionalFocus();

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: InstructionalFocusResponse[]) => {
    props.onChange(value.map((v) => v.id));
  };

  const options = instructionalFocusRequest.data ?? [];
  const value = options.filter((o) => props.value.find((v) => v === o.id));

  return (
    <Autocomplete
      multiple
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.name}
      options={options}
      renderInput={(params) => <TextField {...params} label="Instructional Focus" fullWidth />}
      fullWidth
    />
  );
}

/** InstructionalUnitAutocomplete  */

export function InstructionalUnitAutocomplete(props: { value: string[]; onChange: (val: string[]) => void }) {
  const instructionalUnitsRequest = useGetInstructionalUnits();

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: InstructionalFocusResponse[]) => {
    props.onChange(value.map((v) => v.id));
  };

  const options = instructionalUnitsRequest.data ?? [];
  const value = options.filter((o) => props.value.find((v) => v === o.id));

  return (
    <Autocomplete
      multiple
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.name}
      options={options}
      renderInput={(params) => <TextField {...params} label="Instructional Unit" fullWidth />}
      fullWidth
    />
  );
}

/** PrioritizedSkillAutocomplete */

export function FilterLevelPrioritizedSkillAutocomplete(props: { value: string[]; onChange: (val: string[]) => void }) {
  const filterLevelPrioritizedSkillRequest = useGetFilterLevelPrioritizedSkill();

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: InstructionalFocusResponse[]) => {
    props.onChange(value.map((v) => v.id));
  };

  const options = filterLevelPrioritizedSkillRequest.data ?? [];
  const value = options.filter((o) => props.value.find((v) => v === o.id));

  return (
    <Autocomplete
      multiple
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.name}
      options={options}
      renderInput={(params) => <TextField {...params} label="Prioritized Skill" fullWidth />}
      fullWidth
    />
  );
}

/** ThemeAutocomplete */

export function ThemeAutocomplete(props: { value: string[]; onChange: (val: string[]) => void }) {
  const themeRequest = useGetThemes();

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: InstructionalFocusResponse[]) => {
    props.onChange(value.map((v) => v.id));
  };

  const options = themeRequest.data ?? [];
  const value = options.filter((o) => props.value.find((v) => v === o.id));

  return (
    <Autocomplete
      multiple
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.name}
      options={options}
      renderInput={(params) => <TextField {...params} label="Theme" fullWidth />}
      fullWidth
    />
  );
}

/** SubThemeAutocomplete */

export function SubThemeAutocomplete(props: { value: string[]; onChange: (val: string[]) => void }) {
  const subThemeRequest = useGetSubThemes();

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: InstructionalFocusResponse[]) => {
    props.onChange(value.map((v) => v.id));
  };

  const options = subThemeRequest.data ?? [];
  const value = options.filter((o) => props.value.find((v) => v === o.id));

  return (
    <Autocomplete
      multiple
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.name}
      options={options}
      renderInput={(params) => <TextField {...params} label="Sub-Theme" fullWidth />}
      fullWidth
    />
  );
}

/** TopicAutcomplete */

export function TopicAutcomplete(props: { value: string[]; onChange: (val: string[]) => void }) {
  const topicsRequest = useGetTopics();

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: InstructionalFocusResponse[]) => {
    props.onChange(value.map((v) => v.id));
  };

  const options = topicsRequest.data ?? [];
  const value = options.filter((o) => props.value.find((v) => v === o.id));

  return (
    <Autocomplete
      multiple
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.name}
      options={options}
      renderInput={(params) => <TextField {...params} label="Topic" fullWidth />}
      fullWidth
    />
  );
}

/** SubTopicAutocomplete */

export function SubTopicAutocomplete(props: { value: string[]; onChange: (val: string[]) => void }) {
  const subTopicsRequest = useGetSubTopics();

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: InstructionalFocusResponse[]) => {
    props.onChange(value.map((v) => v.id));
  };

  const options = subTopicsRequest.data ?? [];
  const value = options.filter((o) => props.value.find((v) => v === o.id));

  return (
    <Autocomplete
      multiple
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.name}
      options={options}
      renderInput={(params) => <TextField {...params} label="Sub-Topic" fullWidth />}
      fullWidth
    />
  );
}

/** GenreAutocomplete */

export function GenreAutocomplete(props: { value: string[]; onChange: (val: string[]) => void }) {
  const genresRequest = useGetGenres();

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: InstructionalFocusResponse[]) => {
    props.onChange(value.map((v) => v.id));
  };

  const options = genresRequest.data ?? [];
  const value = options.filter((o) => props.value.find((v) => v === o.id));

  return (
    <Autocomplete
      multiple
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.name}
      options={options}
      renderInput={(params) => <TextField {...params} label="Genre" fullWidth />}
      fullWidth
    />
  );
}

/** SubGenreAutocomplete */

export function SubGenreAutocomplete(props: { value: string[]; onChange: (val: string[]) => void }) {
  const subGenresRequest = useGetSubGenres();

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: InstructionalFocusResponse[]) => {
    props.onChange(value.map((v) => v.id));
  };

  const options = subGenresRequest.data ?? [];
  const value = options.filter((o) => props.value.find((v) => v === o.id));

  return (
    <Autocomplete
      multiple
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.name}
      options={options}
      renderInput={(params) => <TextField {...params} label="Sub-Genre" fullWidth />}
      fullWidth
    />
  );
}

/** CollectionsAutocomplete */

export function CollectionsAutocomplete(props: { value: string[]; onChange: (val: string[]) => void }) {
  const collectionsRequest = useGetCollections();

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: InstructionalFocusResponse[]) => {
    props.onChange(value.map((v) => v.id));
  };

  const options = collectionsRequest.data ?? [];
  const value = options.filter((o) => props.value.find((v) => v === o.id));

  return (
    <Autocomplete
      multiple
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.name}
      options={options}
      renderInput={(params) => <TextField {...params} label="Collections" fullWidth />}
      fullWidth
    />
  );
}

/** ContentTagsAutocomplete */

export function ContentTagsAutocomplete(props: { value: string[]; onChange: (val: string[]) => void }) {
  const contentTags = useGetContentTags();

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: InstructionalFocusResponse[]) => {
    props.onChange(value.map((v) => v.id));
  };

  const options = contentTags.data ?? [];
  const value = options.filter((o) => props.value.find((v) => v === o.id));

  return (
    <Autocomplete
      multiple
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.name}
      options={options}
      renderInput={(params) => <TextField {...params} label="Content Tags" fullWidth />}
      fullWidth
    />
  );
}
