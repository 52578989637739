import { Disability, FoodServiceProgram, Race, SpecialIndicatorGrade } from '@hoot-reading/hoot-core/dist/enums/student-special-indicators';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../../queryKeys';

export interface StudentSpecialIndicatorDataResponse {
  studentProfileId: string;
  specialIndicatorGrade: SpecialIndicatorGrade | null;
  race: Race | null;
  primaryLanguageId: string | null;
  ell: boolean;
  hispanicLatino: boolean;
  homeless: boolean;
  fosterCare: boolean;
  newComer: boolean;
  migrant: boolean;
  specialEducation: boolean;
  iep: boolean;
  '504': boolean;
  disability: Disability | null;
  foodServiceProgram: FoodServiceProgram | null;
  sed: boolean;
  publicAssistance: boolean;
}

async function getStudentSpecialIndicatorData(studentProfileId: string): Promise<StudentSpecialIndicatorDataResponse> {
  const { data } = await axios.get<StudentSpecialIndicatorDataResponse>(
    `${config.apiUrl}/admin/student-profile/${studentProfileId}/special-indicator-data`,
  );
  return data;
}

export default function useGetStudentSpecialIndicatorData(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<StudentSpecialIndicatorDataResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.getStudentSpecialIndicatorData, studentProfileId], () => getStudentSpecialIndicatorData(studentProfileId), options);
}
