import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';

async function sendUserPasswordReset(email: string) {
  await axios.post(`${config.apiUrl}/admin/user/send-password-reset`, {
    email,
  });
}

export function useSendUserPasswordReset(options?: Omit<UseMutationOptions<void, AxiosError, string>, 'mutationKey' | 'mutationFn'>) {
  return useMutation((request) => sendUserPasswordReset(request), options);
}
