import { ScheduledLessonType, scheduledLessonTypeLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { useMemo } from 'react';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { isDeprecatedScheduledLessonType } from '@hoot/pages/lessons/enums';
import { FormSelect, FormSelectOption, SelectProps } from './FormSelect';

interface LessonTypeSelectProps<T extends FieldValues, TObj extends Path<T>> extends SelectProps, UseControllerProps<T, TObj> {
  filter?: ScheduledLessonType[];
}

export function LessonTypeSelect<T extends FieldValues, TObj extends Path<T>>(props: LessonTypeSelectProps<T, TObj>) {
  const { filter, ...rest } = props;

  const options = useMemo<FormSelectOption[]>(() => {
    return Object.entries(scheduledLessonTypeLookup)
      .filter((v) => props.disabled || !isDeprecatedScheduledLessonType(v[0]))
      .filter((v) => (filter && filter.length > 0 ? filter.some((f) => f === v[0]) : v))
      .map((lessonType) => {
        return {
          label: lessonType[1],
          value: lessonType[0],
        };
      });
  }, [filter, props.disabled]);

  return <FormSelect label="Lesson Type" labelId="lesson-type-label" options={options} {...rest} />;
}
