import { Close, ContentCopy } from '@mui/icons-material';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { useAlert } from '@hoot/contexts/AlertContext';
import { routes } from '@hoot/routes/routes';
import { SelectedSubNeededTimeSlot } from '../NeedSubsTableRow';

interface ISubRequiredDetailsProps {
  open: boolean;
  onClose: () => void;
  selectedSubRequiredTimeSlot: SelectedSubNeededTimeSlot | undefined;
}

const SubRequiredDetailsDialog = (props: ISubRequiredDetailsProps) => {
  const { open, onClose, selectedSubRequiredTimeSlot } = props;

  const { info } = useAlert();
  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    info(`Copied "${val}" to clipboard.`);
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { width: '20%' } }}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Substitute Menu</Typography>
          <IconButton onClick={onClose} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {selectedSubRequiredTimeSlot ? (
          <Stack gap={2} justifyContent="space-evenly">
            <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
              <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                <Typography variant="bodyMedium">Date</Typography>
                <Typography variant="titleMedium">
                  {DateTime.fromMillis(selectedSubRequiredTimeSlot.timeSlotStartTime).toFormat('MMMM dd, yyyy')}
                </Typography>
              </Stack>
            </Card>
            <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
              <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                <Typography variant="bodyMedium">Time Slot</Typography>
                <Typography variant="titleMedium">
                  {DateTime.fromMillis(selectedSubRequiredTimeSlot.timeSlotStartTime).toFormat('h:mm a')} -{' '}
                  {DateTime.fromMillis(selectedSubRequiredTimeSlot.timeSlotEndTime).toFormat('h:mm a')}
                </Typography>
              </Stack>
            </Card>
            <Typography variant="bodyMedium">Lesson(s) Requiring Substitute:</Typography>
            {selectedSubRequiredTimeSlot.lessons.map((lesson) => (
              <Card key={lesson.id} sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                  <Typography variant="bodyMedium">Lesson ID</Typography>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Link href={routes.lessons.details.url(lesson.id)}>
                      <Typography variant="titleMedium">{lesson.prefixedNumber}</Typography>
                    </Link>
                    <Tooltip title="Copy to clipboard">
                      <IconButton onClick={handleCopyToClipboard(lesson.prefixedNumber)} sx={{ height: '12px', width: '12px' }}>
                        <ContentCopy sx={{ height: '16px', width: '16px' }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Card>
            ))}
          </Stack>
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubRequiredDetailsDialog;
