import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Controller } from 'react-hook-form';
import { ConnectForm } from '@hoot/components/form/ConnectForm';
import { TimePicker2 } from '@hoot/components/form/DatePicker';
import { LessonDetailsForm, useEditLessonDetailsContext } from '@hoot/pages/lessons/edit/EditLessonDetailsContext';
import { TIME_FORMAT } from '../../../../common/constants';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const EditLessonDateTimeModal = (props: Props) => {
  const { open, handleClose } = props;
  const { lessonDetails, lessonDetailsForm } = useEditLessonDetailsContext();

  const handleCancelClick = () => {
    lessonDetailsForm.resetField('lessonDate', { keepDirty: false, keepTouched: false });
    lessonDetailsForm.resetField('startTime', { keepDirty: false, keepTouched: false });
    handleClose();
  };

  const handleSaveClick = () => {
    handleClose();
  };

  function calculateValidDateRange(): [DateTime, DateTime] {
    const now = DateTime.now().startOf('day');

    if (lessonDetails?.enrolment) {
      const { startDate, endDate } = lessonDetails.enrolment;
      if (startDate >= now.toMillis()) {
        return [DateTime.fromMillis(startDate).startOf('day'), DateTime.fromMillis(endDate).endOf('day')];
      } else {
        return [now, DateTime.fromMillis(endDate).endOf('day')];
      }
    }
    return [now, DateTime.now().plus({ year: 1 }).endOf('day')];
  }

  const [minValidDate, maxValidDate] = calculateValidDateRange();

  if (!lessonDetails) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        Edit Date & Time
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ marginTop: 2, width: 425 }}>
        <Grid container justifyContent="center" direction="column" gap={3} style={{ marginTop: 24, marginBottom: 32 }}>
          <ConnectForm<LessonDetailsForm>>
            {({ control }) => {
              return (
                <>
                  <Controller
                    name="lessonDate"
                    control={control}
                    rules={{
                      validate: (val: DateTime) => {
                        if (val < minValidDate || val > maxValidDate) {
                          return 'Lesson date(s) outside of Enrolment';
                        }
                        return true;
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <DesktopDatePicker
                        label="Lesson Date"
                        disablePast
                        onChange={field.onChange}
                        value={field.value}
                        minDate={minValidDate}
                        maxDate={maxValidDate}
                        format="dd/LL/yyyy"
                        slotProps={{
                          textField: {
                            error: !!fieldState.error,
                            helperText: fieldState.error?.message,
                            fullWidth: true,
                            variant: 'outlined',
                          },
                        }}
                      />
                    )}
                  />
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="startTime"
                      rules={{
                        validate: (val) => {
                          if (!val) {
                            return 'Selected Time is a required value';
                          }

                          const { lessonDate } = lessonDetailsForm.getValues();

                          // Skip validation if start time hasn't changed
                          if (val.toMillis() === lessonDetails.startsAt) {
                            return true;
                          }

                          if (!lessonDate) {
                            return 'Date cannot be empty!';
                          }

                          try {
                            const startsAtDateTime = lessonDate.set({
                              hour: val.hour,
                              minute: val.minute,
                              second: 0,
                              millisecond: 0,
                            });

                            const now = DateTime.now();
                            if (startsAtDateTime < now) {
                              return `Selected Time must be greater than ${now.toFormat(TIME_FORMAT)}`;
                            }
                          } catch (exc) {
                            console.error('Not a valid time.', exc);
                            return 'Not a valid time.';
                          }

                          return true;
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TimePicker2
                          slotProps={{ textField: { error: !!fieldState.error, helperText: fieldState.error?.message } }}
                          value={field.value}
                          onChange={field.onChange}
                          label="Lesson Time"
                        />
                      )}
                    />
                  </FormControl>
                </>
              );
            }}
          </ConnectForm>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3, position: 'relative' }}>
        <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
        <Button size="large" variant="outlined" color="primary" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button size="large" variant="contained" onClick={handleSaveClick} disabled={!lessonDetailsForm.formState.isValid}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLessonDateTimeModal;
