import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';

export interface UpdateTeacherBlockRequest {
  comment: string;
}

async function createTeacherBlockComment(blockId: string, request: UpdateTeacherBlockRequest) {
  await axios.put<void>(`${config.apiUrl}/admin/teacher-block/${blockId}/comment`, request);
}

export default function useCreateTeacherBlockComment(
  blockId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, UpdateTeacherBlockRequest>, 'queryKey' | 'queryFn'>,
) {
  return useMutation((request) => createTeacherBlockComment(blockId, request), options);
}
