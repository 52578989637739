import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Button, Divider, FormControlLabel, FormGroup, LinearProgress, Radio, Skeleton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import SelectTeacher, { SelectedTeacher } from '@hoot/components/modals/SelectTeacher';
import useGetLessonConflictsV2, {
  ConflictUserType,
  ConflictsRequestV2LessonTimeFrame,
  ConflictsV2Response,
} from '@hoot/hooks/api/lessons/useGetConflictsV2';
import { LessonSetTeacherAssignmentWizardState } from '@hoot/pages/lesson-sets/details/teacher-assignment-tab/lesson-set-wizard-modal/LessonSetWizardModal';
import { MultipleConflictsMessage } from '@hoot/pages/lessons/create-v2/common/ConflictMessage';

interface TeacherAssignmentTeacherSelectionStepProps {
  state: LessonSetTeacherAssignmentWizardState;
  onPreviousButtonClicked: () => void;
  onCancel: () => void;
  onTeacherAndLessonsSelected: (selectedTeacher: SelectedTeacher, teacherAssignedLessonIds: Set<string>) => void;
}

interface TeacherAssignmentForm {
  selectedTeacher: SelectedTeacher | null;
  lessonIdsAssignedToTeacher: Set<string> | null;
}

// NOTE: This component was basically derived from src/pages/lessons/create-v2/teacher-conflict-check/TeacherConflictCheckStep.tsx
// with some subtle differences.
const LessonSetWizardTeacherCheckStep = (props: TeacherAssignmentTeacherSelectionStepProps) => {
  const { state, onCancel, onPreviousButtonClicked, onTeacherAndLessonsSelected } = props;
  const [lessonIdToConflictsDictionary, setLessonIdToConflictsDictionary] = useState<Map<string, ConflictsV2Response[]>>(new Map());

  const {
    selectedTeacher: defaultSelectedTeacher,
    lessonIdsAssignedToTeacher: defaultLessonIdsAssignedToTeacher,
    selectedLessonGroups,
    isTimeSelection,
    lessonSet: {
      enrolment: {
        product: { hootQualificationRequirements, countryRequirements, backgroundCheckRequirements },
      },
    },
  } = state;

  // Get all upcoming lessons within the selected lesson groups.
  const upcomingLessons = useMemo(() => {
    return selectedLessonGroups
      .flatMap((x) => x.lessons)
      .filter((x) => x.status === ScheduledLessonStatus.Scheduled)
      .sort((a, b) => a.startsAt - b.startsAt);
  }, [selectedLessonGroups]);

  // Get all every lesson's time frame, so we can check for conflicts.
  const lessonTimeFrames = useMemo<ConflictsRequestV2LessonTimeFrame[]>(() => {
    return upcomingLessons.map((x) => ({
      lessonId: x.lessonId,
      startsAt: x.startsAt,
      endsAt: x.endsAt,
    }));
  }, [upcomingLessons]);

  // NOTE: We're assuming that every lesson in the set has the same details (student, subject, language, etc.)
  const lessonDetails = useMemo(() => {
    // There should be at least one lesson (can't have a lesson group without any lessons).
    const firstLesson = upcomingLessons.find((x) => x.lessonDetails)!;
    return {
      ...firstLesson.lessonDetails,
      studentProfileId: firstLesson.studentProfileId,
      durationInMinutes: firstLesson.durationInMinutes,
    };
  }, [upcomingLessons]);

  // If there are any student conflicts at all, we can't proceed. We need to pick a new timeslot.
  const studentConflictExists = useMemo(() => {
    return Array.from(lessonIdToConflictsDictionary.values())
      .flatMap((x) => x)
      .some((x) => x.userType === ConflictUserType.Student);
  }, [lessonIdToConflictsDictionary]);

  const { control, handleSubmit, watch, setValue } = useForm<TeacherAssignmentForm>({
    defaultValues: {
      selectedTeacher: defaultSelectedTeacher ?? null,
      lessonIdsAssignedToTeacher: defaultLessonIdsAssignedToTeacher ?? null,
    },
  });

  const { selectedTeacher, lessonIdsAssignedToTeacher } = watch();

  const canGoToNextStep = !!selectedTeacher && (lessonIdsAssignedToTeacher?.size ?? 0) > 0 && !studentConflictExists;

  const getConflictsRequest = useGetLessonConflictsV2(
    {
      studentProfileId: lessonDetails.studentProfileId,
      // By the time this request is executed, we should have a teacher ID.
      teacherAccountId: selectedTeacher?.id,
      teacherLessonTimeFrames: lessonTimeFrames,
      studentLessonTimeFrames: lessonTimeFrames,
    },
    {
      enabled: !!selectedTeacher?.id && lessonTimeFrames.length > 0,
      onSuccess: (conflictsResponse) => {
        // Group each conflict by lesson ID.
        const newLessonIdToConflictsDictionary = new Map<string, ConflictsV2Response[]>();

        conflictsResponse.forEach((x) => {
          // Get the current list of conflicts for this lesson ID.
          // Since we're updating lessons in this context, we can expect a lesson ID to be attached to every lesson conflict response.
          const currLessonIdConflicts = newLessonIdToConflictsDictionary.get(x.lessonId!) ?? [];

          // Add this conflict to the current list of lesson ID conflicts.
          currLessonIdConflicts.push(x);

          // Keep track of the entire set.
          newLessonIdToConflictsDictionary.set(x.lessonId!, currLessonIdConflicts);
        });

        setLessonIdToConflictsDictionary(newLessonIdToConflictsDictionary);

        // If we didn't already have a set of default of selections (lessons with/without teacher), then we'll want to
        // set them here based on the returned conflicts. Lessons without conflicts should be set to have a teacher
        // assigned by default.
        if (!defaultLessonIdsAssignedToTeacher) {
          // Set all lessons to be assigned to a teacher for now. We'll un-assign teachers as we find BLOCKING conflicts.
          const newLessonIdsAssignedToTeacher = new Set<string>(upcomingLessons.map((x) => x.lessonId));

          // Go through each lesson.
          upcomingLessons.forEach((l) => {
            // Is there a conflict for this lesson?
            if (newLessonIdToConflictsDictionary.has(l.lessonId)) {
              const conflicts = newLessonIdToConflictsDictionary.get(l.lessonId)!;
              // Is the conflict a teacher conflict and is it a BLOCKING conflict?
              // Note: we still want to auto-select "Selected with Teacher" if there's a non-blocking conflict.
              if (conflicts.some((c) => c.userType === ConflictUserType.Teacher && c.conflictType === 'CONFLICT')) {
                // Remove the teacher assigned lesson.
                newLessonIdsAssignedToTeacher.delete(l.lessonId);
              }
            }
          });
          setValue('lessonIdsAssignedToTeacher', newLessonIdsAssignedToTeacher);
        }
      },
    },
  );

  const onAssignTeacherToLesson = (lessonId: string) => () => {
    const newLessonIdsAssignedToTeacher = new Set(lessonIdsAssignedToTeacher);
    newLessonIdsAssignedToTeacher.add(lessonId);
    setValue('lessonIdsAssignedToTeacher', newLessonIdsAssignedToTeacher);
  };

  const onRemoveTeacherFromLesson = (lessonId: string) => () => {
    const newLessonIdsAssignedToTeacher = new Set(lessonIdsAssignedToTeacher);
    newLessonIdsAssignedToTeacher.delete(lessonId);
    setValue('lessonIdsAssignedToTeacher', newLessonIdsAssignedToTeacher);
  };

  const _handleSubmit: SubmitHandler<TeacherAssignmentForm> = (form) => {
    onTeacherAndLessonsSelected(form.selectedTeacher!, form.lessonIdsAssignedToTeacher!);
  };

  return (
    <form onSubmit={handleSubmit(_handleSubmit)}>
      <Stack>
        <Controller
          name="selectedTeacher"
          control={control}
          render={({ field }) => {
            // Clear out lesson selections when the teacher selection has changed.
            const onTeacherSelectionChanged = (val: SelectedTeacher | null) => {
              setValue('lessonIdsAssignedToTeacher', new Set());
              field.onChange(val ?? null);
            };
            return (
              <SelectTeacher
                value={field.value}
                onChange={onTeacherSelectionChanged}
                tentativePeriods={lessonTimeFrames}
                periodIsLesson
                showHasConflictFilter
                disabled={isTimeSelection}
                queryDefaults={{
                  countryRequirements: countryRequirements,
                  hootQualificationRequirements: hootQualificationRequirements,
                  backgroundCheckRequirements: backgroundCheckRequirements,
                  lessonDetails: {
                    subject: lessonDetails.subject,
                    isCustomerBillable: lessonDetails.isCustomerBillable,
                    language: lessonDetails.language,
                    account: lessonDetails.accountId,
                    enrolmentId: lessonDetails.enrolmentId,
                    theme: lessonDetails.theme,
                    isLessonSet: lessonDetails.isLessonSet,
                    lessonType: lessonDetails.lessonType,
                  },
                }}
              />
            );
          }}
        />
        {selectedTeacher ? (
          <>
            <Typography mt={3} variant="titleMedium">
              Select from these available dates for {selectedTeacher.name}
            </Typography>
            {getConflictsRequest.isFetching ? (
              <FormSkeleton />
            ) : (
              <Stack mt={3}>
                <Stack gap={5.25} direction="row">
                  <Typography sx={{ width: '49px' }} variant="bodySmall">
                    Selected Teacher
                  </Typography>
                  <Typography sx={{ width: '46px' }} variant="bodySmall">
                    Without Teacher
                  </Typography>
                </Stack>
                <FormGroup sx={{ marginTop: 1 }}>
                  {upcomingLessons.map((lesson) => {
                    const isTeacherAssignedToLesson = lessonIdsAssignedToTeacher?.has(lesson.lessonId);

                    // Get the conflicts reported for this lesson.
                    const lessonConflicts = lessonIdToConflictsDictionary.get(lesson.lessonId) ?? [];

                    // We can't schedule anything that's less than two hours from now.
                    const isLessonStartTimeBeforeThreshold = lesson.startsAt <= DateTime.now().plus({ hours: 2 }).toMillis();

                    // Check if there are conflicts for the current lesson ID.
                    const studentConflict = lessonConflicts.find((x) => x.userType === ConflictUserType.Student);
                    const teacherConflict = lessonConflicts.find((x) => x.userType === ConflictUserType.Teacher);

                    const canAssignTeacher =
                      !isLessonStartTimeBeforeThreshold &&
                      teacherConflict?.conflictType !== 'CONFLICT' &&
                      studentConflict?.conflictType !== 'CONFLICT';

                    const canAssignWithoutTeacher = !isLessonStartTimeBeforeThreshold && studentConflict?.conflictType !== 'CONFLICT';

                    return (
                      <Stack key={`lesson-id-${lesson.lessonId}`} direction="row">
                        <FormControlLabel
                          sx={{ marginLeft: 0 }}
                          disabled={!canAssignWithoutTeacher}
                          control={
                            <Stack direction="row" gap={5.25}>
                              {/* Select with Teacher */}
                              <Radio
                                sx={{ width: '48px', height: '48px' }}
                                disabled={!canAssignTeacher}
                                onClick={onAssignTeacherToLesson(lesson.lessonId)}
                                checked={isTeacherAssignedToLesson}
                              />
                              {/* Select _without_ Teacher */}
                              <Radio
                                sx={{ width: '48px', height: '48px' }}
                                disabled={!canAssignWithoutTeacher}
                                onClick={onRemoveTeacherFromLesson(lesson.lessonId)}
                                checked={!isTeacherAssignedToLesson && canAssignWithoutTeacher}
                              />
                            </Stack>
                          }
                          label={
                            <Typography variant="bodyLarge" sx={{ marginLeft: 5.25 }}>
                              {DateTime.fromMillis(lesson.startsAt).toFormat('ccc, LLL d, yyyy @ h:mma')}
                            </Typography>
                          }
                        />
                        {lessonConflicts.length > 0 && <MultipleConflictsMessage conflicts={lessonConflicts} />}
                      </Stack>
                    );
                  })}
                </FormGroup>
              </Stack>
            )}
          </>
        ) : null}
        <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
        <Stack direction="row" gap={2} justifyContent="space-between">
          {isTimeSelection ? (
            <Button variant="outlined" onClick={onPreviousButtonClicked} size="large">
              Previous
            </Button>
          ) : (
            <Stack />
          )}
          <Stack direction="row" gap={2}>
            <Button variant="outlined" onClick={onCancel} size="large">
              Cancel
            </Button>
            <Button type="submit" variant="contained" size="large" disabled={!canGoToNextStep}>
              Next
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

const FormSkeleton = () => {
  return (
    <Stack mt={3} gap={2}>
      <LinearProgress />
      <Stack gap={5.25} direction="row">
        <Skeleton variant="rectangular" width="49px" height="32px" />
        <Skeleton variant="rectangular" width="46px" height="32px" />
      </Stack>
      <Stack>
        {[...Array(3)].map((_, index) => (
          <Stack direction="row" gap={5.25} key={`skeleton-${index}`} sx={{ alignItems: 'center' }}>
            <Box
              sx={{
                width: '48px',
                height: '48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Skeleton variant="rectangular" width="24px" height="24px" />
            </Box>
            <Box
              sx={{
                width: '48px',
                height: '48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Skeleton variant="rectangular" width="24px" height="24px" />
            </Box>
            <Skeleton variant="rectangular" width="200px" />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default LessonSetWizardTeacherCheckStep;
