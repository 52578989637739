import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../../config';
import { QueryKey } from '../../../queryKeys';

export type useGetFluencyFormativeAssessmentDetailsResponse = {
  lessonDate: string;
  lessonNumber: string;
  lessonId: string;
  bookTitle: string;
  teacherName: string;
  teacherAccountId: string;
  wordCount: string;
  errors: string;
  runningTime: string;
  accuracy: string;
  wordsCorrectPerMinute: string;
};

const getFluencyFormativeAssessmentDetails = async (lessonReviewId: string, bookId: string) => {
  const { data } = await axios.get<useGetFluencyFormativeAssessmentDetailsResponse>(
    `${config.apiUrl}/admin/lesson-reviews/${lessonReviewId}/formative-assessment/fluency/${bookId}`,
  );
  return data;
};

export default function useGetFluencyFormativeAssessmentDetails(
  lessonReviewId: string,
  bookId: string,
  options?: Omit<UseQueryOptions<useGetFluencyFormativeAssessmentDetailsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetFluencyFormativeAssessmentDetails, lessonReviewId, bookId],
    () => getFluencyFormativeAssessmentDetails(lessonReviewId, bookId),
    options,
  );
}
