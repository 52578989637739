import { Container, Grid } from '@mui/material';
import { motion } from 'framer-motion';

const PermissionDenied = () => {
  return (
    <Container
      sx={{
        width: '100%',
        padding: 0,
      }}
      component={motion.div}
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <Grid item xs={12} justifyContent="center">
        <div>You do not have permission to view this page. Please contact your manager if you require permission.</div>
      </Grid>
    </Container>
  );
};

export default PermissionDenied;
