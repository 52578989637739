import { CircularProgress, Stack } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { QueryKey } from '../../../../hooks/api/queryKeys';
import useCreateTeacherShiftComment from '../../../../hooks/api/shifts/useCreateTeacherShiftComment';
import useGetTeacherShift from '../../../../hooks/api/shifts/useGetTeacherShift';
import useGetTeacherShiftHistory from '../../../../hooks/api/shifts/useGetTeacherShiftHistory';
import { CommentSubmission, HistoryItemResponse } from '../../../../interfaces/history';
import { CommonHistoryTimeline } from '../../common/CommonHistoryTimeline';
import { TeacherShiftHeaderCard } from './TeacherShiftHeaderCard';

export function TeacherShiftHistoryPage() {
  const { shiftId, teacherAccountId } = useParams() as { shiftId: string; teacherAccountId: string };

  const getShiftRequest = useGetTeacherShift(shiftId ?? '', {
    enabled: shiftId !== undefined,
    retry: false,
  });

  const queryClient = useQueryClient();
  const teacherShiftHistoryRequest = useGetTeacherShiftHistory(shiftId);
  const createTeacherShiftCommentMutation = useCreateTeacherShiftComment(shiftId);

  const handleCommentSubmitted = (comment: CommentSubmission) => {
    createTeacherShiftCommentMutation.mutate(
      {
        comment: comment.comment,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.GetTeacherShiftHistory);
        },
      },
    );
  };

  const data =
    teacherShiftHistoryRequest.data?.historyEntries.map<HistoryItemResponse>((h) => ({
      id: h.id,
      isComment: h.isComment,
      body: h.note,
      createdAt: h.createdAt,
      createdById: h.createdById,
      createdBy: h.createdBy,
    })) ?? [];

  if (!getShiftRequest.data) {
    return <CircularProgress />;
  }

  return (
    <Stack gap={3}>
      <TeacherShiftHeaderCard shiftNumber={getShiftRequest.data?.prefixedNumber} teacherAccountId={teacherAccountId} shiftId={shiftId} />
      <CommonHistoryTimeline handleCommentSubmit={handleCommentSubmitted} historyItems={data} />
    </Stack>
  );
}
