import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { Button, Chip, CircularProgress, Grid, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ENV_NAME, IS_LOCAL, IS_PROD } from '@hoot/common/constants';
import FullCircleIcon from '@hoot/components/icons/FullCircle';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import useGetEnrolment, { EnrolmentEdlinkDataResponse } from '@hoot/hooks/api/hfs/useGetEnrolment';
import EnrolmentCapacity from '@hoot/pages/district-schools/details/enrolments/capacity/EnrolmentCapacity';
import EnrolmentDetails from '@hoot/pages/district-schools/details/enrolments/details/EnrolmentDetails';
import EnrolmentQualityMonitoring from '@hoot/pages/district-schools/details/enrolments/quality-monitoring/EnrolmentQualityMonitoring';
import UserImportDialog from '@hoot/pages/district-schools/modals/user-import/UserImportDialog';
import { routes } from '@hoot/routes/routes';
import HeaderCard, { HeaderCardProps, HeaderTab } from '../../../../components/ui/HeaderCard';

export interface BillingDetails {
  locationFriendlyId: string;
  locationId: string;
  locationName: string;
  studentAccessPoint: string;
  isDistrict: boolean;
}

export enum EnrolmentTabs {
  Details = 'DETAILS',
  Capacity = 'CAPACITY',
  QualityMonitoring = 'QUALITY_MONITORING',
}

export function EnrolmentEdlinkStatusChip(props: { edlinkData: EnrolmentEdlinkDataResponse | null }) {
  const edlinkData = props.edlinkData;

  const handleClick = () => {
    if (!edlinkData) {
      return;
    }
    const url = `https://ed.link/Hoot/integrations/${edlinkData.integrationId}/classes#class/${edlinkData.edlinkClassId}`;
    window.open(url, '_blank');
  };

  if (!edlinkData) {
    return <></>;
  }

  return (
    <Chip
      sx={{
        paddingLeft: '0px',
        '& > svg': {
          width: '8px',
          height: '8px',
        },
      }}
      icon={<FullCircleIcon fill="#2E7D32" />}
      size="small"
      clickable={true}
      onClick={handleClick}
      label={`Edlink Class: ${edlinkData.edlinkClassName} - ${edlinkData.integrationName}`}
    />
  );
}

interface Props {
  tab: EnrolmentTabs;
}

const EnrolmentPage = (props: Props) => {
  const { tab } = props;
  const { locationId, enrolmentId } = useParams() as { locationId: string; enrolmentId: string };
  const [searchParams] = useSearchParams();
  const locationTypeParam = searchParams.get('locationType');
  const locationNameParam = searchParams.get('name');
  const locationFriendlyIdParam = searchParams.get('friendlyId');
  const locationIsBillableEntityParam = searchParams.get('isBillableEntity');
  const productIdParam = searchParams.get('productId');
  const locationType = locationTypeParam === LocationType.District ? LocationType.District : LocationType.School;
  const isEditMode = !!enrolmentId;
  const [billingDetails, setBillingDetails] = useState<BillingDetails>();
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const { isDirty } = useBlockIfDirty();
  const formRef = useRef<HTMLFormElement>();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useGetEnrolment(enrolmentId, {
    enabled: !!enrolmentId,
    onSuccess: (data) => {
      const billingDetails: BillingDetails = {
        locationFriendlyId: data.locationFriendlyId,
        locationId: data.locationId,
        locationName: data.locationName,
        isDistrict: data.isDistrict,
        studentAccessPoint: IS_LOCAL
          ? `http://localhost:3000/login/${data.locationFriendlyId}`
          : IS_PROD
            ? `https://app.hootreading.com/login/${data.locationFriendlyId}`
            : `https://app.${ENV_NAME}.hootreading.com/login/${data.locationFriendlyId}`,
      };
      setBillingDetails(billingDetails);
    },
  });

  const locationFriendlyIdSubtitle = (): string => {
    if (data?.locationFriendlyId) {
      return ` (${data.locationFriendlyId})`;
    }
    return locationFriendlyIdParam ? ` (${locationFriendlyIdParam})` : '';
  };
  const locationSubtitle = (): string => {
    if (data?.locationName) {
      return `${data.locationName}${locationFriendlyIdSubtitle() ? locationFriendlyIdSubtitle() : ''}`;
    }
    return locationNameParam ? `${locationNameParam}${locationFriendlyIdSubtitle()}` : '';
  };

  const tabs = (): HeaderTab[] => {
    if (!isEditMode || !enrolmentId) return [];

    return [
      {
        id: EnrolmentTabs.Details,
        label: 'Details',
        route: routes.districtsSchools.enrolments.details.url(locationId, enrolmentId),
      },
      {
        id: EnrolmentTabs.Capacity,
        label: 'Capacity',
        route: routes.districtsSchools.enrolments.capacity.url(locationId, enrolmentId),
      },
      {
        id: EnrolmentTabs.QualityMonitoring,
        label: 'Quality Monitoring',
        route: routes.districtsSchools.enrolments.qualityMonitoring.url(locationId, enrolmentId),
      },
    ];
  };

  if (isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (isError) {
    return <Stack alignItems="center">Failed to load the page!</Stack>;
  }

  const onTabSelected: HeaderCardProps['onTabSelected'] = (selectedTab) => {
    navigate(selectedTab.route);
    return !isDirty;
  };

  const onSave = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  return (
    <>
      <Grid container direction="column">
        <HeaderCard
          showNavigateBackButton={true}
          title="Enrolment"
          subtitle={data?.friendlyId ?? ''}
          subtitleTwo={locationSubtitle()}
          renderCustomSubHeader={() => EnrolmentEdlinkStatusChip({ edlinkData: data?.edlinkData ?? null })}
          tabs={tabs()}
          onTabSelected={onTabSelected}
          actionButtons={
            <>
              {isEditMode ? (
                <Button sx={{ mr: 1 }} variant="outlined" size="large" onClick={() => setOpenImportModal(true)}>
                  Add to Roster
                </Button>
              ) : null}
              <Button color="primary" variant="contained" size="large" disabled={!isDirty} onClick={onSave}>
                Save
              </Button>
            </>
          }
        />
        {tab === EnrolmentTabs.Details ? (
          <EnrolmentDetails
            isEditMode={isEditMode}
            locationId={locationId}
            enrolmentId={enrolmentId}
            productId={productIdParam}
            enrolment={data}
            locationType={locationType}
            locationName={locationNameParam}
            locationFriendlyId={locationFriendlyIdParam}
            locationIsBillableEntity={locationIsBillableEntityParam}
            billingDetails={billingDetails}
            formRef={formRef}
          />
        ) : null}
        {tab === EnrolmentTabs.Capacity ? <EnrolmentCapacity enrolmentId={enrolmentId} formRef={formRef} /> : null}
        {tab === EnrolmentTabs.QualityMonitoring ? (
          <EnrolmentQualityMonitoring enrolmentId={enrolmentId} locationId={locationId} isDistrict={billingDetails?.isDistrict} />
        ) : null}
      </Grid>
      {openImportModal && enrolmentId ? (
        <UserImportDialog isOpen={openImportModal} close={() => setOpenImportModal(false)} enrolmentId={enrolmentId} />
      ) : null}
    </>
  );
};

export default EnrolmentPage;
