import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { DateTime, Interval } from 'luxon';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { DatePicker } from '@hoot/components/form/DatePicker';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { useAlert } from '@hoot/contexts/AlertContext';
import { EnrolmentPeriodRequest, EnrolmentPeriodType, useCreateEnrolmentPeriod } from '@hoot/hooks/api/hfs/useCreateEnrolmentPeriod';
import { EnrolmentResponse } from '@hoot/hooks/api/hfs/useGetEnrolment';
import { EnrolmentPeriodResponse } from '@hoot/hooks/api/hfs/useGetEnrolmentPeriods';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { FormOption } from '@hoot/interfaces/form';

interface AddEnrolmentPeriodModalProps {
  open: boolean;
  onClose: () => void;
  enrolment: EnrolmentResponse | undefined;
  periodData: EnrolmentPeriodResponse[] | undefined;
}

interface FormValues {
  type: EnrolmentPeriodType;
  startDate: number;
  endDate: number;
}

const enrolmentPeriodTypeOptions: FormOption<EnrolmentPeriodType>[] = [
  { value: EnrolmentPeriodType.HRA, label: 'HRA Period' },
  { value: EnrolmentPeriodType.PMA, label: 'PMA Period' },
];

const AddEnrolmentPeriodModal = (props: AddEnrolmentPeriodModalProps) => {
  const { enrolmentId } = useParams() as { enrolmentId: string };
  const queryClient = useQueryClient();
  const { success, error } = useAlert();

  const {
    control,
    formState: { errors, isLoading },
    handleSubmit,
    reset,
    watch,
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      type: undefined,
      startDate: undefined,
      endDate: undefined,
    },
  });
  const { startDate, endDate } = watch();

  const { mutate } = useCreateEnrolmentPeriod(enrolmentId);

  const onReset = () => reset();

  const onCancel = () => {
    reset();
    props.onClose();
  };

  const onApply = (formData: FormValues) => {
    if (!formData) return;

    const request: EnrolmentPeriodRequest = {
      type: formData.type,
      startDate: formData.startDate,
      endDate: formData.endDate,
    };

    mutate(request, {
      onSuccess: () => {
        success('Enrolment period has been created successfully!');
        queryClient.invalidateQueries([QueryKey.GetEnrolmentPeriods, enrolmentId]);
        props.onClose();
      },
      onError: (err) => {
        error('There was an error, the enrolment period was not created.');
        console.error(err);
      },
    });
  };

  const validateDates = () => {
    if (!startDate || !endDate || !props.enrolment?.startDate || !props.enrolment.endDate) return;

    const enrolStart = DateTime.fromMillis(props.enrolment?.startDate);
    const enrolEnd = DateTime.fromMillis(props.enrolment?.endDate);
    const enrolmentRange = Interval.fromDateTimes(enrolStart, enrolEnd);

    const newStart = DateTime.fromMillis(startDate);
    const newEnd = DateTime.fromMillis(endDate);
    const newPeriodRange = Interval.fromDateTimes(newStart, newEnd);

    // Check if the new Start & End Dates are between the Enrolments Start & End dates.
    if (!enrolmentRange.contains(newStart) || !enrolmentRange.contains(newEnd)) {
      return 'Start & End Dates must be within the enrolment dates.';
    }
    // Check to make sure the Start Date comes before the End date.
    if (startDate > endDate) {
      return 'Start Date must be set before the End date.';
    }
    // Check to make sure the period is no longer than 1 week.
    if (newPeriodRange.length('week') > 1) {
      return 'Period can be a maximum of one week long.';
    }
    // Check if new Period will overlap with any existing periods.
    const doesOverlap = props.periodData?.some((p) => {
      const existingPeriodStart = DateTime.fromMillis(p.startDate);
      const existingPeriodEnd = DateTime.fromMillis(p.endDate);
      const existingPeriodRange = Interval.fromDateTimes(existingPeriodStart, existingPeriodEnd);

      if (newPeriodRange.overlaps(existingPeriodRange)) return true;

      return false;
    });

    if (doesOverlap) return 'Period overlaps with an existing period.';

    return true;
  };

  return (
    <Dialog open={props.open} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <Typography variant="headlineSmall">Add Schedule Event</Typography>
          <IconButton onClick={() => onCancel()} sx={{ marginLeft: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <form style={{ padding: '8px 24px 24px' }}>
        <Stack gap={3}>
          <Dropdown
            name="type"
            label="Type *"
            options={enrolmentPeriodTypeOptions}
            control={control}
            rules={{ required: true }}
            helperTextFn={() => `${errors.type ? 'Please select a Period type.' : ''}`}
            includeEmpty
            variant="outlined"
          />
          <DatePicker
            name="startDate"
            label="Start Date *"
            control={control}
            treatDateAsNumber
            rules={{
              required: 'Start Date is required.',
              validate: () => validateDates(),
            }}
            sx={{ width: '100%' }}
          />
          <DatePicker
            name="endDate"
            label="End Date *"
            control={control}
            treatDateAsNumber
            rules={{
              required: 'End Date is required.',
              validate: () => validateDates(),
            }}
            sx={{ width: '100%' }}
          />
        </Stack>
      </form>
      <DialogActions sx={{ p: 3, position: 'relative' }}>
        <Button onClick={onReset} size="large" sx={{ marginRight: 'auto' }}>
          Reset
        </Button>
        <Button onClick={onCancel} size="large" variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit(onApply)} disabled={isLoading} size="large" variant="contained">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEnrolmentPeriodModal;
