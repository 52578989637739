import { TeacherShiftType } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import { Stack, keyframes } from '@mui/material';
import { ShiftColor } from '@hoot/pages/shifts/shift-constants';
import { ONE_MINUTE_PX_HEIGHT } from './constants';
import { ZIndexLayer } from './enums';
import { SelectedItem, ShiftStatus } from './interfaces';
import { convertTimeToOffset, shiftColour } from './utils';

interface ShiftProps {
  id: string;
  startsAt: string;
  endsAt: string;
  status: ShiftStatus;
  type: TeacherShiftType;
  selectedItem?: SelectedItem;
  onSelected: (selectedItem: SelectedItem) => void;
  yAxisLabelsSliced: string[];
  layer: number;
}

function Shift(props: ShiftProps) {
  const top = Math.max(convertTimeToOffset(props.startsAt, props.yAxisLabelsSliced), 0);
  const height = convertTimeToOffset(props.endsAt, props.yAxisLabelsSliced) - top;
  const xOffset = props.layer < 1 ? 0 : props.layer * 4;

  const handleClick = () => {
    props.onSelected({
      ids: [props.id],
      type: 'SHIFT',
    });
  };

  const isSelected = props.selectedItem?.ids.some((id) => id === props.id) && props.selectedItem.type === 'SHIFT';

  return (
    <Stack
      onClick={handleClick}
      sx={{
        position: 'absolute',
        left: '16px',
        top: `${top * ONE_MINUTE_PX_HEIGHT}px`,
        zIndex: ZIndexLayer.Middle + props.layer,
        cursor: 'pointer',
      }}
    >
      <Stack
        sx={{
          backgroundColor: props.type === TeacherShiftType.Interview ? ShiftColor.InterviewDark : shiftColour(props.status),
          height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
          width: '8px',
          borderRadius: '4px',
          boxShadow: isSelected ? '0px 2px 6px 2px #FF8E00, 0px 1px 2px 0px #FF8E00' : undefined,
          marginLeft: `${xOffset}px`,
        }}
      />

      {isSelected ? (
        <Stack
          sx={{
            position: 'absolute',
            height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
            width: '8px',
            borderRadius: '4px',
            border: '3px solid #CBA82C',
            animation: `${pulseAnimation} 2s ease 0s infinite`,
          }}
        />
      ) : null}
    </Stack>
  );
}

const pulseAnimation = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    80% {
        opacity: 0;
        transform: scale(1.5);
    }
    100% {
        opacity: 0;
        transform: scale(2);
    }
`;

export default Shift;
