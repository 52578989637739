import { ScheduledLessonSubject } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { StudentGoal } from '@hoot-reading/hoot-core/dist/enums/user/student/student-goal.enum';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import { Box, Button, Card, CardContent, Grid, Skeleton, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { AdminLessonNoteDetails } from '@hoot/hooks/api/lesson-notes/useGetLessonNoteDetailsById';
import GenericCheckListModal, { CheckListModalItemItem } from '../../../../../components/modals/GenericCheckListModal';
import NoneItem from '../../../../../components/ui/NoneItem';
import {
  MathStrengthsGoals,
  mathStrengthsGoals,
  mathStrengthsGoalsList,
  readingStrengthsGoals,
  readingStrengthsGoalsList,
} from '../../../../lessons/enums';

interface LessonNoteAreasOfFocusCardProps {
  isLoading: boolean;
  lessonSubject: AdminLessonNoteDetails['lessonSubject'];
  goals: AdminLessonNoteDetails['goals'];
  onUpdateGoals: (strengths: AdminLessonNoteDetails['goals']) => void;
}

const LessonNoteAreasOfFocusCard = (props: LessonNoteAreasOfFocusCardProps) => {
  const { isLoading, lessonSubject, goals, onUpdateGoals } = props;

  const [showGoalsModal, setShowGoalsModal] = useState(false);

  // Set of goals already applied.
  const appliedStrengthsSet = useMemo(() => new Set<string>(goals), [goals]);

  // List of goals that we can select (for the modal).
  const goalsList = useMemo<CheckListModalItemItem[]>(() => {
    return lessonSubject === ScheduledLessonSubject.Math ? [...mathStrengthsGoalsList] : [...readingStrengthsGoalsList];
  }, [lessonSubject]);

  const onShowGoalsModal = () => {
    setShowGoalsModal(true);
  };

  const onDismissGoalsModal = () => {
    setShowGoalsModal(false);
  };

  const onApplyGoals = (checkedValues: string[]) => {
    onDismissGoalsModal();
    onUpdateGoals(checkedValues as AdminLessonNoteDetails['goals']);
  };

  const renderTableRow = (goal: string) => (
    <TableRow>
      <TableCell>
        <Stack direction="row" spacing={2.5} alignItems="center">
          <CrisisAlertIcon />
          <Typography variant="bodyLarge">{goal}</Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 3,
              mb: 3,
            }}
          >
            <Typography variant="titleLarge" sx={{ display: 'block' }}>
              Areas of Focus
            </Typography>
            {isLoading ? (
              <Skeleton variant="rounded" width="70px" height="36px" />
            ) : (
              <Button variant="contained" size="large" onClick={onShowGoalsModal}>
                {goals && goals.length > 0 ? 'Edit' : 'Add'}
              </Button>
            )}
          </Box>
          {isLoading ? (
            <LoadingContent />
          ) : goals && goals.length === 0 ? (
            <NoneItem />
          ) : (
            <Table>
              <TableBody>
                {goals?.map((goal) => (
                  <React.Fragment key={goal}>
                    {renderTableRow(readingStrengthsGoals[goal as StudentGoal] ?? mathStrengthsGoals[goal as MathStrengthsGoals] ?? goal)}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
      <GenericCheckListModal
        show={showGoalsModal}
        title="Goals"
        listItems={goalsList}
        defaultCheckedItems={appliedStrengthsSet}
        confirmButtonTitle="Apply"
        onDismiss={onDismissGoalsModal}
        onConfirmButtonClicked={onApplyGoals}
        dialogContentSx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            width: '480px',
          },
        })}
      />
    </>
  );
};

const LoadingContent = () => (
  <Grid container spacing={1}>
    {[...Array(4)].map((_, index) => (
      <React.Fragment key={`lesson-note-goals-card-placeholder-${index}`}>
        <Grid item xs={1}>
          <Skeleton variant="text" sx={{ maxWidth: '200px' }} />
        </Grid>
        <Grid item xs={11}>
          <Skeleton variant="text" />
        </Grid>
      </React.Fragment>
    ))}
  </Grid>
);

export default LessonNoteAreasOfFocusCard;
