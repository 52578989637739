import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import { ProductProgram } from '@hoot-reading/hoot-core/dist/enums/hfs/product-program.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface StudentEnrolment {
  id: string;
  shortId: string;
  name: string;
  startDate: number;
  endDate: number;
  type: EnrolmentType;
  status: EnrolmentStatus;
  numberOfLessons: number;
  productId: string;
  schoolId: string | null;
  friendlyId: string;
  hasLessonSet: boolean;
  productProgram: ProductProgram;
}

export default function useGetStudentEnrolments(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<StudentEnrolment[], AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.SearchStudentProfilesV2, studentProfileId],
    async () => {
      return (await axios.get(`${config.apiUrl}/admin/student-profile/${studentProfileId}/enrolments`)).data;
    },
    options,
  );
}
