import { ScheduledLessonLanguage, ScheduledLessonSubject } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { StudentGoal } from '@hoot-reading/hoot-core/dist/enums/user/student/student-goal.enum';
import { Close } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitErrorHandler, SubmitHandler } from 'react-hook-form/dist/types/form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { routes } from '@hoot/routes/routes';
import DropdownButton, { DropdownButtonMenuItem, DropdownButtonProps } from '../../../../../components/form/DropdownButton';
import HeaderCard from '../../../../../components/ui/HeaderCard';
import useDeleteLessonNote from '../../../../../hooks/api/lesson-notes/useDeleteLessonNote';
import useGetLessonNoteDetailsById, {
  AdminLessonNoteDetails,
  AdminLessonNoteDetailsBook,
} from '../../../../../hooks/api/lesson-notes/useGetLessonNoteDetailsById';
import useUpdateLessonNoteDetails, { UpdateLessonNoteRequest } from '../../../../../hooks/api/lesson-notes/useUpdateLessonNoteDetails';
import { MathStrengthsGoals, SightWordList } from '../../../../lessons/enums';
import LessonNoteAreasOfFocusCard from './LessonNoteAreasOfFocusCard';
import LessonNoteBooksCard, { LessonNoteBooksCardProps } from './LessonNoteBooksCard';
import LessonNoteDetailsCard from './LessonNoteDetailsCard';
import LessonNoteNotesCard from './LessonNoteNotesCard';
import LessonNoteSightWordListCard from './LessonNoteSightWordListCard';
import LessonNoteStrengthsCard from './LessonNoteStrengthsCard';

export interface LessonNoteForm {
  strengths: StudentGoal[] | MathStrengthsGoals[];
  goals: StudentGoal[] | MathStrengthsGoals[];
  sightWordList: SightWordList | '';
  sightWordsMissed: string;
  lessonNotes: string;
  lessonNoteBooks: AdminLessonNoteDetailsBook[];
}

enum ActionItemId {
  DeleteLessonNote = 'delete-lesson-note',
}

const actionItems: DropdownButtonMenuItem[] = [
  {
    id: ActionItemId.DeleteLessonNote,
    label: (
      <>
        <ListItemIcon>
          <DeleteOutlineIcon sx={{ color: '#000000' }} fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete Lesson Note</ListItemText>
      </>
    ),
  },
];

const LessonNoteDetailsPage = () => {
  const { studentProfileId, lessonNoteId } = useParams();
  const navigate = useNavigate();
  usePageTitle('Lesson Note Details | Admin Portal');
  const { success, warning, error } = useAlert();
  const { setIsDirty } = useBlockIfDirty();

  const [originalLessonNoteDetails, setOriginalLessonNoteDetails] = useState<AdminLessonNoteDetails>();
  const [showConfirmDeleteLessonNoteDialog, setShowConfirmDeleteLessonNoteDialog] = useState(false);

  const lessonDateLabel = useMemo(() => {
    return originalLessonNoteDetails ? DateTime.fromMillis(originalLessonNoteDetails.createdAt).toFormat('cccc, MMMM d, y, t ZZZZ') : '';
  }, [originalLessonNoteDetails]);

  const getStudentLessonNotesRequest = useGetLessonNoteDetailsById(lessonNoteId!, {
    retry: false,
    onSuccess: (lessonNote) => {
      if (lessonNote.studentProfileId !== studentProfileId) {
        console.error('Student profile ID does not match ID from response. Redirecting to home page.');
        navigate(routes.home.url, { replace: true });
        return;
      }
      resetForm(lessonNote);
    },
    onError: (err) => {
      console.error(err);
      error(`An error occurred while loading lesson note with ID "${lessonNoteId}".`);
    },
  });

  const updateLessonNoteDetailsRequest = useUpdateLessonNoteDetails(lessonNoteId!);

  const deleteLessonNoteRequest = useDeleteLessonNote(lessonNoteId!);

  const form = useForm<LessonNoteForm>({
    defaultValues: {
      lessonNoteBooks: [],
      strengths: [],
      goals: [],
      sightWordList: '',
      sightWordsMissed: '',
      lessonNotes: '',
    },
  });

  // Show "Unsaved Changes" dialog if user attempts to leave the page before saving changes.
  useEffect(() => {
    setIsDirty(form.formState.isDirty);
  }, [form.formState.isDirty, setIsDirty]);

  const resetForm = (lessonNote: AdminLessonNoteDetails) => {
    setOriginalLessonNoteDetails(lessonNote);
    form.reset({
      lessonNoteBooks: lessonNote.lessonNoteBooks ?? [],
      strengths: lessonNote.strengths ?? [],
      goals: lessonNote.goals ?? [],
      sightWordList: lessonNote.sightWordList ?? '',
      sightWordsMissed: lessonNote.sightWordsMissed ?? '',
      lessonNotes: lessonNote.lessonNotes ?? '',
    });
  };

  const onAddBooksToLesson: LessonNoteBooksCardProps['onAddBooksToLesson'] = (booksToAdd) => {
    const editedBooks = [...form.getValues('lessonNoteBooks'), ...booksToAdd];
    form.setValue('lessonNoteBooks', editedBooks, { shouldDirty: true });

    // There's probably a better way of handling validation here...
    if (editedBooks.length > 0) {
      form.clearErrors('lessonNoteBooks');
    }
  };

  const onRemoveLessonBook: LessonNoteBooksCardProps['onRemoveLessonBook'] = (bookToRemove) => {
    const existingBooks = [...form.getValues('lessonNoteBooks')];
    const editedBooks = existingBooks.filter((b) => b.bookId !== bookToRemove.bookId);
    form.setValue('lessonNoteBooks', editedBooks, { shouldDirty: true });

    if (editedBooks.length === 0) {
      form.setError('lessonNoteBooks', { type: 'minLength', message: 'There must must be at least one book.' });
    }
  };

  const onUpdateStrengths = (strengths: AdminLessonNoteDetails['strengths']) => {
    form.setValue('strengths', strengths ?? [], { shouldDirty: true });
  };

  const onUpdateGoals = (goals: AdminLessonNoteDetails['goals']) => {
    form.setValue('goals', goals ?? [], { shouldDirty: true });
  };

  const onActionItemClicked: DropdownButtonProps['onMenuItemClicked'] = (actionItemId) => {
    switch (actionItemId) {
      case ActionItemId.DeleteLessonNote: {
        setShowConfirmDeleteLessonNoteDialog(true);
      }
    }
  };

  const onCancelDeleteLessonNote = () => {
    setShowConfirmDeleteLessonNoteDialog(false);
  };

  const onConfirmDeleteLessonNote = () => {
    deleteLessonNoteRequest.mutate(undefined, {
      onSuccess: () => {
        success(`Lesson note deleted.`);
        if (studentProfileId) {
          navigate(routes.users.students.lessonNotes.url(studentProfileId), { replace: true });
        }
      },
      onError: (err) => {
        console.error(err);
        error(`An error occurred while deleting the lesson note.`);
      },
    });
  };

  const onSubmit: SubmitHandler<LessonNoteForm> = async (data) => {
    const cleanse = (val: string | undefined) => {
      return val?.length ? val : null;
    };

    const request: UpdateLessonNoteRequest = {
      lessonNoteBookIds: data.lessonNoteBooks.map((b) => b.bookId),
      strengths: data.strengths,
      goals: data.goals,
      sightWordList: cleanse(data.sightWordList.toString()) as SightWordList | null,
      sightWordsMissed: cleanse(data.sightWordsMissed),
      lessonNotes: cleanse(data.lessonNotes),
    };

    updateLessonNoteDetailsRequest.mutate(request, {
      onSuccess: (response) => {
        success(`Lesson notes updated.`);
        resetForm(response);
      },
      onError: (err) => {
        console.error(err);
        const msg = err?.response?.data?.message?.[0];
        error(msg ?? `An error occurred while updating the lesson note details.`);
      },
    });
  };

  const onSubmitValidationFailed: SubmitErrorHandler<LessonNoteForm> = async (data) => {
    // Just toast the first non-null field error.
    const fieldError = Object.values(data).find((x) => x.message !== undefined);
    warning(fieldError?.message ?? 'Form validation failed');
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit, onSubmitValidationFailed)} id="lesson-note-details-form">
      <HeaderCard
        sx={{ mb: 3 }}
        title="Lesson Note Details"
        subtitle={lessonDateLabel}
        showNavigateBackButton
        actionButtons={
          <>
            <DropdownButton label="Actions" menuItems={actionItems} onMenuItemClicked={onActionItemClicked} />
            <LoadingButton
              type="submit"
              size="large"
              variant="contained"
              disabled={!form.formState.isDirty}
              loading={updateLessonNoteDetailsRequest.isLoading}
            >
              Save
            </LoadingButton>
          </>
        }
      />
      <Grid container spacing={3}>
        <Grid item md={3} sm={12}>
          <LessonNoteDetailsCard isLoading={getStudentLessonNotesRequest.isLoading} lessonNoteDetails={originalLessonNoteDetails} />
        </Grid>
        <Grid item md={9} sm={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LessonNoteBooksCard
                isLoading={getStudentLessonNotesRequest.isLoading}
                validationErrorMessage={form.formState.errors.lessonNoteBooks?.message}
                books={form.watch('lessonNoteBooks')}
                onAddBooksToLesson={onAddBooksToLesson}
                onRemoveLessonBook={onRemoveLessonBook}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LessonNoteStrengthsCard
                isLoading={getStudentLessonNotesRequest.isLoading}
                lessonSubject={originalLessonNoteDetails?.lessonSubject ?? ScheduledLessonSubject.Reading}
                strengths={form.watch('strengths')}
                onUpdateStrengths={onUpdateStrengths}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LessonNoteAreasOfFocusCard
                isLoading={getStudentLessonNotesRequest.isLoading}
                lessonSubject={originalLessonNoteDetails?.lessonSubject ?? ScheduledLessonSubject.Reading}
                goals={form.watch('goals') ?? []}
                onUpdateGoals={onUpdateGoals}
              />
            </Grid>
            <Grid item xs={12}>
              <LessonNoteSightWordListCard
                isLoading={getStudentLessonNotesRequest.isLoading}
                lessonLanguage={originalLessonNoteDetails?.lessonLanguage ?? ScheduledLessonLanguage.English}
                control={form.control}
              />
            </Grid>
            <Grid item xs={12}>
              <LessonNoteNotesCard
                isLoading={getStudentLessonNotesRequest.isLoading}
                control={form.control}
                lessonNotes={form.getValues('lessonNotes')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={showConfirmDeleteLessonNoteDialog}>
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          Confirm Delete
          <IconButton onClick={onCancelDeleteLessonNote}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this lesson note?</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 3, position: 'relative' }}>
          <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
          <Button size="large" variant="outlined" color="primary" onClick={onCancelDeleteLessonNote} autoFocus>
            Cancel
          </Button>
          <LoadingButton
            size="large"
            variant="contained"
            color="error"
            onClick={onConfirmDeleteLessonNote}
            loading={deleteLessonNoteRequest.isLoading}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default LessonNoteDetailsPage;
