import { Box, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { LanguageSelect } from '@hoot/components/form/selectFields/LanguageSelect';
import { LessonTypeSelect } from '@hoot/components/form/selectFields/LessonTypeSelect';
import { SubjectSelect } from '@hoot/components/form/selectFields/SubjectSelect';
import useGetAccounts from '@hoot/hooks/api/common/useGetAccounts';
import { StudentAndLessonForm } from '@hoot/pages/lessons/create-v2/student-and-lesson-step/CreateLessonStudentSelectStep';

interface CreateLessonDetailsProps {
  studentAndLessonForm: UseFormReturn<StudentAndLessonForm>;
}

const CreateLessonDetails = (props: CreateLessonDetailsProps) => {
  const { studentAndLessonForm } = props;

  const lessonSetType = studentAndLessonForm.watch('lessonSetType');
  const accountsQuery = useGetAccounts(false);

  return (
    <>
      <Typography variant="titleMedium" display="block">
        Lesson Details
      </Typography>
      <Box mt={3} maxWidth="500px">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {/* If the user marks either "Lesson Set" or "Create within lesson set" as TRUE, then the Lesson Type should default to "Cycle-SoR" and be uneditable. */}
            <LessonTypeSelect
              required
              name="lessonType"
              control={studentAndLessonForm.control}
              disabled={lessonSetType === 'NEW_LESSON_SET' || lessonSetType === 'WITHIN_EXISTING_LESSON_SET'}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* NOTE: This field is never editable. */}
            <Controller
              name="accountId"
              control={studentAndLessonForm.control}
              render={({ field }) => (
                <FormControl
                  sx={{
                    maxWidth: '500px',
                  }}
                  fullWidth
                  disabled
                >
                  <InputLabel shrink={true}>Account</InputLabel>
                  <Select label="Account" value={field.value} notched displayEmpty>
                    <MenuItem value="">None</MenuItem>
                    {accountsQuery.data?.map((x) => (
                      <MenuItem key={`account-${x.id}`} value={x.id}>
                        {x.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubjectSelect
              required
              name="subject"
              control={studentAndLessonForm.control}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LanguageSelect
              required
              name="language"
              control={studentAndLessonForm.control}
              rules={{
                required: true,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Controller
        name="isCustomerBilledLesson"
        control={studentAndLessonForm.control}
        render={({ field }) => (
          <FormControlLabel sx={{ marginTop: 3 }} control={<Checkbox {...field} checked={field.value} />} label="Customer-Billed Lesson" />
        )}
      />
    </>
  );
};

export default CreateLessonDetails;
