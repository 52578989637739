import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface UserSelectableProject {
  id: string;
  projectCode: string;
}

export interface UserSelectableProjects {
  projects: UserSelectableProject[];
}

async function getUserSelectableProjects() {
  const {
    data: { projects },
  } = await axios.get<UserSelectableProjects>(`${config.apiUrl}/admin/teacher-invoicing/projects`);
  return projects;
}

export default function useUserSelectableProjects() {
  return useQuery(QueryKey.GetUserSelectableProjects, () => getUserSelectableProjects());
}
