import { Breadcrumbs, Container, Grid, Paper, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { Header, MyLink } from '@hoot/pages/live-lessons';
import ReportingTable from './ReportingTable';

const Reporting = () => {
  return (
    <Container
      sx={{
        width: '100%',
        padding: 0,
      }}
      component={motion.div}
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <Paper
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        <Grid
          container
          style={{
            marginTop: '32px',
            marginLeft: '48px',
          }}
        >
          <Grid item container justifyContent="left">
            <Header>Reporting</Header>
          </Grid>
          <Grid item container justifyContent="left">
            <Breadcrumbs>
              <MyLink to="/">Home</MyLink>
              <Typography color="text.primary">Reporting</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <ReportingTable />
        </Grid>
      </Paper>
    </Container>
  );
};

export default Reporting;
