import { useState } from 'react';
import useGetEnrolments, { EnrolmentQuery } from '@hoot/hooks/api/hfs/useGetEnrolments';
import { FormOption } from '@hoot/interfaces/form';
import SimpleMultiSelect from '../../selectFields/SimpleMultiSelect';

const DEFAULT_PAGE_SIZE = 5;
const MAX_PAGE_SIZE = DEFAULT_PAGE_SIZE * 10;

export function QualityAssuranceEnrolmentIDsDropDown(props: { onChange: (val: string[]) => void; teacherAccountId?: string }) {
  const { onChange, teacherAccountId } = props;
  const [enrolments, setEnrolments] = useState<FormOption<string>[]>([]);
  const [query, setQuery] = useState<EnrolmentQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    teacherAccountId: teacherAccountId,
  });

  const enrolmentResponse = useGetEnrolments(query, {
    keepPreviousData: true,
    onSuccess: (data) => {
      const newEnrolments: FormOption<string>[] = [];
      for (const enrolment of data.enrolments) {
        if (!enrolments.find((e) => e.value === enrolment.enrolmentId)) {
          newEnrolments.push({ value: enrolment.enrolmentId, label: enrolment.friendlyId });
        }
      }

      setEnrolments((curr) => [...curr, ...newEnrolments]);
    },
  });

  const options = enrolmentResponse.data
    ? enrolmentResponse.data.enrolments.map((e) => ({
        label: e.friendlyId,
        value: e.enrolmentId,
      }))
    : [];

  return (
    <SimpleMultiSelect
      onChange={onChange}
      allData={enrolments}
      displayData={options}
      label={'Enrolment ID'}
      variant={'outlined'}
      size={'small'}
      onInputChange={(event, val) => {
        setQuery((current) => ({ ...current, searchString: val, pageSize: !!val ? MAX_PAGE_SIZE : DEFAULT_PAGE_SIZE }));
      }}
    />
  );
}
