import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';
import { ScheduledLessonSetStatus } from '../../../pages/lessons/enums';

export interface BulkUpdateLessonSetsRequest {
  lessonSetIds: string[];
  updateToStatus: ScheduledLessonSetStatus;
}

async function bulkUpdateLessonSets(request: BulkUpdateLessonSetsRequest) {
  await axios.post<void>(`${config.apiUrl}/admin/lesson-set/bulk-update`, request);
}

export function useBulkUpdateLessonSets(options?: Omit<UseMutationOptions<void, AxiosError, BulkUpdateLessonSetsRequest>, 'queryKey' | 'queryFn'>) {
  return useMutation((request: BulkUpdateLessonSetsRequest) => bulkUpdateLessonSets(request), options);
}
