import { Close } from '@mui/icons-material';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { SetStateAction, useState } from 'react';
import useGetBookDetailsForLessonEventVisualizer, {
  LessonEventBookDetails,
} from '@hoot/hooks/api/lesson-event-visualizer/useGetBookDetailsForVisualizer';
import { useEditLessonDetailsContext } from '../../EditLessonDetailsContext';

interface IBookDetailsModalProps {
  bookId: string | undefined;
  setSelectedBook: React.Dispatch<SetStateAction<string | undefined>>;
}

const BookDetailsModal = (props: IBookDetailsModalProps) => {
  const { bookId, setSelectedBook } = props;
  const [bookDetails, setBookDetails] = useState<LessonEventBookDetails | undefined>(undefined);
  const { lessonDetails } = useEditLessonDetailsContext();
  const studentProfileId = lessonDetails?.studentProfileId;

  const { isFetching } = useGetBookDetailsForLessonEventVisualizer(bookId ?? '', studentProfileId ?? '', {
    enabled: !!bookId && !!studentProfileId,
    onSuccess: (data) => {
      setBookDetails(data);
    },
  });

  const onClose = () => {
    setSelectedBook(undefined);
    setBookDetails(undefined);
  };

  return (
    <Dialog open={!!bookId} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">{bookDetails?.title}</Typography>
          <IconButton onClick={onClose} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack sx={{ minHeight: '300px', minWidth: '550px', alignItems: 'center', justifyContent: 'center' }}>
          {isFetching ? (
            <CircularProgress size={100} />
          ) : (
            <Stack gap={2} justifyContent="space-evenly">
              <Grid container spacing={2}>
                <Grid size={6}>
                  <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                    <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                      <Typography variant="bodyMedium">Resource Progress</Typography>
                      <Typography variant="titleMedium">{bookDetails?.resourceProgress}</Typography>
                    </Stack>
                  </Card>
                </Grid>
                <Grid size={6}>
                  <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                    <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                      <Typography variant="bodyMedium">Resource State</Typography>
                      <Typography variant="titleMedium">{bookDetails?.resourceState}</Typography>
                    </Stack>
                  </Card>
                </Grid>
                <Grid size={6}>
                  <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                    <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                      <Typography variant="bodyMedium">Subject</Typography>
                      <Typography variant="titleMedium">{bookDetails?.subject}</Typography>
                    </Stack>
                  </Card>
                </Grid>
                {bookDetails?.minimumAge ? (
                  <Grid size={6}>
                    <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                      <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                        <Typography variant="bodyMedium">Minimum Age</Typography>
                        <Typography variant="titleMedium">{bookDetails?.minimumAge}</Typography>
                      </Stack>
                    </Card>
                  </Grid>
                ) : null}
                <Grid size={6}>
                  <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                    <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                      <Typography variant="bodyMedium">Instructional Focus</Typography>
                      <Typography variant="titleMedium">{bookDetails?.instructionalFocus}</Typography>
                    </Stack>
                  </Card>
                </Grid>
                {bookDetails?.decodableFormat ? (
                  <Grid size={6}>
                    <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                      <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                        <Typography variant="bodyMedium">Decodable Format</Typography>
                        <Typography variant="titleMedium">{bookDetails?.decodableFormat}</Typography>
                      </Stack>
                    </Card>
                  </Grid>
                ) : null}
                <Grid size={6}>
                  <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                    <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                      <Typography variant="bodyMedium">Hi-Lo</Typography>
                      <Typography variant="titleMedium">{bookDetails?.hiLo}</Typography>
                    </Stack>
                  </Card>
                </Grid>
                <Grid size={6}>
                  <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                    <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                      <Typography variant="bodyMedium">Non-Conforming</Typography>
                      <Typography variant="titleMedium">{bookDetails?.nonConformimg}</Typography>
                    </Stack>
                  </Card>
                </Grid>
                {bookDetails?.series ? (
                  <Grid size={6}>
                    <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                      <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                        <Typography variant="bodyMedium">Series</Typography>
                        <Typography variant="titleMedium">{bookDetails?.series}</Typography>
                      </Stack>
                    </Card>
                  </Grid>
                ) : null}
                {bookDetails?.contentTags ? (
                  <Grid size={6}>
                    <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                      <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                        <Typography variant="bodyMedium">Content Tags</Typography>
                        <Typography variant="titleMedium">{bookDetails?.contentTags}</Typography>
                      </Stack>
                    </Card>
                  </Grid>
                ) : null}
                <Grid size={6}>
                  <Card sx={{ paddingX: '16px', background: '#FAFAFA' }}>
                    <Stack sx={{ height: '60px', justifyContent: 'center' }}>
                      <Typography variant="bodyMedium">Level</Typography>
                      <Typography variant="titleMedium">{bookDetails?.level}</Typography>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookDetailsModal;
