import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface PasswordResetLinkResponse {
  link: string;
}

async function getPasswordResetLink(email: string): Promise<PasswordResetLinkResponse> {
  const { data } = await axios.get<PasswordResetLinkResponse>(`${config.apiUrl}/admin/user/password-reset-link`, { params: { email } });
  return data;
}

export default function useGetPasswordResetLink(
  email: string,
  options?: Omit<UseQueryOptions<PasswordResetLinkResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetPasswordResetLink, email], () => getPasswordResetLink(email), options);
}
