import ReactLottie, { LottieProps as ReactLottieProps } from 'react-lottie-player';

export const lottiePathPrefix = 'lottie-files';

export enum LottieFile {
  Star = 'celebration-star.json',
  Confetti = 'confetti.json',
  ThumbsUp = 'thumbs-up.json',
}

export type LottieProps = ReactLottieProps & {
  lottieFile: LottieFile;
};

const Lottie = (props: LottieProps) => {
  const { lottieFile, ...LottieProps } = props;
  return <ReactLottie path={`/${lottiePathPrefix}/${lottieFile}`} {...LottieProps} />;
};

export default Lottie;
