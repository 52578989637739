import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { DateTime } from 'luxon';
import { WeekdayNumbers } from 'luxon/src/datetime';
import React, { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { LessonSetLesson, useGetLessonSetLessons } from '@hoot/hooks/api/lesson-sets/useGetLessonSetLessons';
import useUpdateLessonSetLessons, { UpdateLessonSetLessonRequest } from '@hoot/hooks/api/lesson-sets/useUpdateLessonSetLessons';
import { DayAndTimeLessonGrouping } from '@hoot/pages/lesson-sets/details/teacher-assignment-tab/LessonSetTeacherAssignment';

interface LessonSetDetailsContextProps {
  isLoadingLessonSetLessons: boolean;
  dayAndTimeLessonGroups: DayAndTimeLessonGrouping[];
  isTeacherAssignmentPageDirty: boolean;
  isSavingChanges: boolean;
  actions: {
    removeTeacherFromLessonGroup: (groupIndex: number) => void;
    // ISO time e.g. "13:00:00.000-05:00" -> 1:00pm CST
    onApplyLessonGroupTimeChanges: (selectedGroupIndexes: Set<number>, isoTimeOfDay: string) => void;
    onApplyTeacherAssignmentChanges: (updatedLessons: LessonSetLesson[]) => void;
    saveTeacherAssignment: () => void;
  };
}

export interface TeacherAssignmentForm {
  dayAndTimeLessonGroups: DayAndTimeLessonGrouping[];
}

const LessonSetTeacherAssignmentContext = React.createContext<LessonSetDetailsContextProps>(undefined!);

export const useLessonSetTeacherAssignmentContext = () => {
  const context = React.useContext(LessonSetTeacherAssignmentContext);

  if (context === undefined) {
    throw new Error('useLessonSetTeacherAssignmentContext must be used within a LessonSetTeacherAssignmentContext');
  }
  return context;
};

const LessonSetTeacherAssignmentContextProvider = (props: PropsWithChildren<any>) => {
  const { children } = props;

  const { lessonSetId } = useParams<{ lessonSetId: string }>();

  const { success, error } = useAlert();

  const getLessonSetLessonsRequest = useGetLessonSetLessons(lessonSetId!, {
    enabled: lessonSetId !== undefined,
    onSuccess: (data) => {
      _setLessons(data);
    },
    onError: (err) => {
      console.error(err);
      error(`An error occurred while loading lessons for lesson set ID "${lessonSetId}".`);
    },
  });

  const updateLessonSetLessonsRequest = useUpdateLessonSetLessons(lessonSetId!, {
    onSuccess: (lessons) => {
      _setLessons(lessons);
      success('Lessons updated.');
    },
    onError: (err) => {
      console.error(err);
      const errorMessage = err.response?.data.message ?? `Sorry! An error occurred while updating lessons.`;
      error(errorMessage);
    },
  });

  const teacherAssignmentForm = useForm<TeacherAssignmentForm>({
    defaultValues: {
      dayAndTimeLessonGroups: [],
    },
  });
  const dayAndTimeLessonGroups = teacherAssignmentForm.watch('dayAndTimeLessonGroups');

  const _setLessons = (lessons: LessonSetLesson[]) => {
    // Put each lesson into groups by day-of-week, and time-of-day.
    teacherAssignmentForm.reset({ dayAndTimeLessonGroups: generateLessonGroups(lessons) });
  };

  const _removeTeacherFromLessonGroup = (groupIndex: number) => {
    // Make a copy of the lesson groups.
    const editedLessonGroups: DayAndTimeLessonGrouping[] = [...dayAndTimeLessonGroups];

    // Remove the teacher from all lessons of the group.
    const editedLessonsAtGroupIndex = editedLessonGroups[groupIndex].lessons.map((x) => {
      // We can only edit lessons that are SCHEDULED.
      if (x.status !== ScheduledLessonStatus.Scheduled) {
        return x;
      }
      return {
        ...x,
        teacher: undefined,
      };
    });
    // Everything else in the group remains the same.
    editedLessonGroups[groupIndex] = {
      ...editedLessonGroups[groupIndex],
      lessons: editedLessonsAtGroupIndex,
    };
    teacherAssignmentForm.setValue('dayAndTimeLessonGroups', editedLessonGroups, { shouldDirty: true });
  };

  /**
   * Changes the time-of-day for all SCHEDULED lessons for selected group indexes. Any other lesson type is ignored (we
   * don't want to change the lesson time of a lesson that already happened).
   */
  const _onApplyLessonGroupTimeChanges = (selectedGroupIndexes: Set<number>, isoTimeOfDay: string) => {
    const newIsoDateTime = DateTime.fromISO(isoTimeOfDay);

    // Make a copy of the lesson groups.
    const prevLessonGroups: DayAndTimeLessonGrouping[] = [...dayAndTimeLessonGroups];

    // Apply the time changes to each SCHEDULED lesson of the selected lesson groups. Lessons with any other status should not be changed.
    const lessonsWithTimeChange = Array.from(selectedGroupIndexes)
      .map((index) => prevLessonGroups[index])
      .flatMap((x) => x.lessons)
      .filter((x) => x.status === ScheduledLessonStatus.Scheduled)
      .map<LessonSetLesson>((x) => {
        // Take the previous lesson _date_, and set the new _time_.
        const newStartDateTime = DateTime.fromMillis(x.startsAt).set({
          hour: newIsoDateTime.hour,
          minute: newIsoDateTime.minute,
        });
        return {
          ...x,
          startsAt: newStartDateTime.toMillis(),
          endsAt: newStartDateTime.plus({ minute: x.durationInMinutes }).toMillis(),
        };
      });

    // Create a lookup dictionary for the edited lessons.
    const lessonsWithTimeChangeDictionary = new Map(lessonsWithTimeChange.map((x) => [x.lessonId, x]));

    // Take the entire previous set of lessons, and swap the lessons that have had a time change.
    const updatedLessons = prevLessonGroups
      .flatMap((x) => x.lessons)
      .map((x) => {
        if (lessonsWithTimeChangeDictionary.has(x.lessonId)) {
          return lessonsWithTimeChangeDictionary.get(x.lessonId)!;
        }
        return x;
      });

    // Since we're only editing lessons where lesson type is SCHEDULED, we may be in a state where _some_ of the lessons
    // in the lesson group have different time-of-day. If this is the case, then we need to regroup the lessons again.
    // Lessons are grouped together by day-of-week and time-of-day.
    const newLessonGroups = generateLessonGroups(updatedLessons);

    teacherAssignmentForm.setValue('dayAndTimeLessonGroups', newLessonGroups, { shouldDirty: true });
  };

  /**
   * After we've gone through the Teacher Assignment Wizard, we'll have some lessons assigned to a new teacher, and some
   * lessons without a teacher. We'll batch up the changes here.
   */
  const _onApplyTeacherAssignmentChanges = (updatedLessons: LessonSetLesson[]) => {
    // Convert the updated lessons into a hash map.
    const updatedLessonsDictionary = new Map(updatedLessons.map((x) => [x.lessonId, x]));

    // Iterate the entire set of lessons, and swap the updated lessons for the old ones.
    const allLessons = dayAndTimeLessonGroups
      .flatMap((x) => x.lessons)
      .map<LessonSetLesson>((x) => {
        // Check if this lesson was updated. If it was, then return the updated lesson. Else return the existing one.
        if (updatedLessonsDictionary.has(x.lessonId)) {
          return updatedLessonsDictionary.get(x.lessonId)!;
        } else {
          return x;
        }
      });
    // Recompute the entire set of lesson groupings again.
    const newLessonGroups = generateLessonGroups(allLessons);
    teacherAssignmentForm.setValue('dayAndTimeLessonGroups', newLessonGroups, { shouldDirty: true });
  };

  const _saveTeacherAssignment = async (form: TeacherAssignmentForm) => {
    // Gather all lessons
    const lessonsToUpdate = form.dayAndTimeLessonGroups
      .flatMap((x) => x.lessons)
      // We can only update "Scheduled" lessons.
      .filter((x) => x.status === ScheduledLessonStatus.Scheduled)
      // Map to API request obj.
      .map<UpdateLessonSetLessonRequest>((x) => ({
        lessonId: x.lessonId,
        startsAt: x.startsAt,
        teacherId: x.teacher?.id,
      }));

    await updateLessonSetLessonsRequest.mutate(lessonsToUpdate);
  };

  return (
    <LessonSetTeacherAssignmentContext.Provider
      value={{
        isLoadingLessonSetLessons: getLessonSetLessonsRequest.isFetching,
        dayAndTimeLessonGroups,
        isTeacherAssignmentPageDirty: teacherAssignmentForm.formState.isDirty,
        isSavingChanges: updateLessonSetLessonsRequest.isLoading,
        actions: {
          removeTeacherFromLessonGroup: _removeTeacherFromLessonGroup,
          onApplyLessonGroupTimeChanges: _onApplyLessonGroupTimeChanges,
          onApplyTeacherAssignmentChanges: _onApplyTeacherAssignmentChanges,
          saveTeacherAssignment: teacherAssignmentForm.handleSubmit(_saveTeacherAssignment),
        },
      }}
    >
      {children}
    </LessonSetTeacherAssignmentContext.Provider>
  );
};

/**
 * Groups lessons by common day-of-week and time-of-day. Returns a list of DayAndTimeLessonGrouping.
 */
const generateLessonGroups = (lessons: LessonSetLesson[]): DayAndTimeLessonGrouping[] => {
  const lessonsByDayOfWeekAndTimeOfDay = _groupLessonsByDayOfWeekAndTimeOfDay(lessons);
  return _flattenGroupedLessons(lessonsByDayOfWeekAndTimeOfDay);
};

const _flattenGroupedLessons = (lessonsByDayOfWeekAndTimeOfDay: Map<WeekdayNumbers, Map<string, LessonSetLesson[]>>): DayAndTimeLessonGrouping[] => {
  return (
    Array.from(lessonsByDayOfWeekAndTimeOfDay.entries())
      .flatMap(([dayOfWeek, lessonsByTimeOfDay]) => {
        return Array.from(lessonsByTimeOfDay.entries()).map(([timeOfDay, lessons]) => {
          return {
            dayOfWeek,
            isoTimeOfDay: timeOfDay,
            lessons,
          };
        });
      })
      // Sort the list by weekday and time-of-day.
      .sort((a, b) => {
        // There might be a better way to handle this sort.
        const aTime = DateTime.fromISO(a.isoTimeOfDay).set({ weekday: a.dayOfWeek });
        const bTime = DateTime.fromISO(b.isoTimeOfDay).set({ weekday: b.dayOfWeek });

        // Compare by weekdays if different.
        if (aTime.weekday !== bTime.weekday) {
          return aTime.weekday - bTime.weekday;
        }
        // Else the weekdays are the same, so compare by hour-of-the-day.
        if (aTime.hour !== bTime.hour) {
          return aTime.hour - bTime.hour;
        }
        // Else the hour-of-the-days are the same, so then compare by minute-of-the-hour.
        return aTime.minute - bTime.minute;
      })
  );
};

const _groupLessonsByDayOfWeekAndTimeOfDay = (lessons: LessonSetLesson[]): Map<WeekdayNumbers, Map<string, LessonSetLesson[]>> => {
  const lessonsByDayOfWeekAndTimeOfDay = new Map<WeekdayNumbers, Map<string, LessonSetLesson[]>>();
  const lessonsByWeekday = _groupLessonsByDayOfWeek(lessons);

  Array.from(lessonsByWeekday.entries()).forEach(([weekday, lessonsInWeekday]) => {
    // Each entry value is an array of lessons on a particular day.
    const lessonsByTimeOfDay = _groupLessonsByIsoTimeOfDay(lessonsInWeekday);
    lessonsByDayOfWeekAndTimeOfDay.set(weekday, lessonsByTimeOfDay);
  });

  return lessonsByDayOfWeekAndTimeOfDay;
};

const _groupLessonsByDayOfWeek = (lessons: LessonSetLesson[]): Map<WeekdayNumbers, LessonSetLesson[]> => {
  const dayOfWeekToLessonDictionary = new Map<WeekdayNumbers, LessonSetLesson[]>();
  lessons.forEach((lesson) => {
    const lessonStartDateTime = DateTime.fromMillis(lesson.startsAt);
    const weekday = lessonStartDateTime.weekday as WeekdayNumbers;
    if (!weekday) {
      console.error('Invalid week day for grouping lesson set groups.');
      return;
    }
    const currentWeekdayLessons = dayOfWeekToLessonDictionary.get(weekday) ?? [];

    currentWeekdayLessons.push(lesson);
    dayOfWeekToLessonDictionary.set(weekday, currentWeekdayLessons);
  });

  return dayOfWeekToLessonDictionary;
};

const _groupLessonsByIsoTimeOfDay = (lessons: LessonSetLesson[]): Map<string, LessonSetLesson[]> => {
  const timeOfDayToLessonDictionary = new Map<string, LessonSetLesson[]>();
  lessons.forEach((lesson) => {
    const lessonStartDateTime = DateTime.fromMillis(lesson.startsAt);
    const isoTimeOfDay = lessonStartDateTime.toISOTime();

    if (!isoTimeOfDay) {
      console.error('Invalid time for grouping lesson set groups.');
      return;
    }
    const currentTimeOfDayLessons = timeOfDayToLessonDictionary.get(isoTimeOfDay) ?? [];

    currentTimeOfDayLessons.push(lesson);
    timeOfDayToLessonDictionary.set(isoTimeOfDay, currentTimeOfDayLessons);
  });

  return timeOfDayToLessonDictionary;
};

export default LessonSetTeacherAssignmentContextProvider;
