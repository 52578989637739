import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useAuth } from '../contexts/Auth/AuthContext';

export const SENTRY_HOOT_SESSION_TAG = 'hootSessionId';

const useSentry = () => {
  const { hootEmployee } = useAuth();

  useEffect(() => {
    if (hootEmployee) {
      Sentry.setUser({
        id: hootEmployee.id,
        email: hootEmployee.email,
      });
    }
  }, [hootEmployee]);
};

export default useSentry;
