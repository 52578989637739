import { SchoolOutlined } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, FormHelperText, Grid, Stack, Tooltip, Typography } from '@mui/material';
import phone from 'phone';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Control, FormState, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { VALID_CANADA_POSTAL_CODE, VALID_EMAIL_REGEX, VALID_US_ZIP_CODE } from '@hoot/common/constants';
import { SearchSelect } from '@hoot/components/SearchSelect';
import { ParentDetailsForm, StudentInfo } from '@hoot/components/Secure/Users/parent/form';
import { PhoneNumberInput } from '@hoot/components/form/PhoneNumberInput';
import { TextField } from '@hoot/components/form/TextField';
import StatusChip from '@hoot/components/ui/StatusChip';
import { ParentProfileResponse } from '@hoot/hooks/api/end-users/useGetParentProfile';
import { SearchStudentResponse } from '@hoot/hooks/api/end-users/useSearchStudentsV2';
import { usePlaces } from '@hoot/hooks/usePlaces';
import { CountryOption } from '@hoot/interfaces/country';
import StudentModals from '@hoot/pages/users/parents/StudentModals';
import { routes } from '@hoot/routes/routes';
import { timeZoneData } from '@hoot/utils/timeZones';
import { getStatusChipColour, getStatusChipIcon } from '../utils/userFunctions';
import ParentsStatusCard from './ParentsStatusCard';

const ReferralCodeCard = (props: { control: Control<ParentDetailsForm> }) => {
  const { control } = props;
  return (
    <Card sx={{ pt: 3, pl: 3, pr: 3 }}>
      <CardHeader
        sx={{ p: 0, pb: 3 }}
        title={
          <Stack sx={{ p: 0 }}>
            <Typography component="h3" variant="titleLarge">
              Referral Code
            </Typography>
          </Stack>
        }
      />
      <CardContent sx={{ p: 0 }}>
        <TextField name="referralCode" label="Referral Code" control={control} variant="outlined" fullWidth />
      </CardContent>
    </Card>
  );
};

const ParentPersonalInfoCard = (props: {
  parentProfile?: ParentProfileResponse;
  control: Control<any>;
  formState: FormState<ParentDetailsForm>;
  watch: UseFormWatch<ParentDetailsForm>;
  countryOptions: CountryOption[];
  provinceStateOptions: CountryOption[];
  setValue: UseFormSetValue<ParentDetailsForm>;
}) => {
  const { control, formState, countryOptions, watch, provinceStateOptions, setValue } = props;

  const country = watch('country');

  return (
    <Card>
      <CardHeader
        sx={{ p: 3, pb: 0 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="titleLarge">
              Personal Information
            </Typography>
          </Stack>
        }
      />

      <CardContent sx={{ p: 3 }}>
        <Stack gap={3}>
          <Stack direction="row" gap={3}>
            <TextField
              name="firstName"
              label={'First name*'}
              rules={{
                maxLength: {
                  value: 48,
                  message: 'First Name is too long',
                },
                required: 'First Name is required',
              }}
              control={control}
              variant="outlined"
              fullWidth
              helperText={''}
              helperTextFn={() => formState?.errors['firstName']?.message || ''}
            />
            <TextField
              name="lastName"
              label={'Last name*'}
              rules={{
                maxLength: {
                  value: 48,
                  message: 'Last Name is too long',
                },
                required: 'Last Name is required',
              }}
              control={control}
              variant="outlined"
              fullWidth
              helperText={''}
              helperTextFn={() => formState?.errors['lastName']?.message || ''}
            />
          </Stack>
          <Stack direction="row" gap={3}>
            <Box sx={{ width: '100%' }}>
              <TextField
                name="emailAddress"
                label={'Email address*'}
                rules={{
                  maxLength: 48,
                  required: 'Email is required',
                  pattern: {
                    value: VALID_EMAIL_REGEX,
                    message: 'A valid email is required.',
                  },
                }}
                control={control}
                variant="outlined"
                fullWidth
                helperText={''}
                helperTextFn={() => formState?.errors['emailAddress']?.message || ''}
              />
            </Box>
            <Box sx={{ width: '100%' }}>
              <Stack>
                <PhoneNumberInput
                  id="phoneNumber"
                  name="phoneNumber"
                  control={control}
                  defaultCountry={'ca'}
                  disableDropdown={true}
                  label="Phone number*"
                  fullWidth
                  variant="outlined"
                  rules={{
                    minLength: {
                      value: 2,
                      message: 'Phone number is required!',
                    },
                    validate: (value) => {
                      const phoneNum = value ? value.toString().trim() : '';
                      if (phoneNum.length === 0 || phoneNum === '+' || phoneNum === '+1' || !phone(phoneNum || '')?.isValid) {
                        return 'Valid phone number is required: +x (xxx) xxx-xxxx';
                      }
                      return true;
                    },
                  }}
                  helperTextFn={() => formState?.errors['phoneNumber']?.message || ''}
                />
                {!formState.errors.phoneNumber ? (
                  <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                    <Tooltip title="Phone Format">
                      <Typography variant="labelSmall">+x (xxx) xxx-xxxx</Typography>
                    </Tooltip>
                  </FormHelperText>
                ) : null}
              </Stack>
            </Box>
          </Stack>
          <TextField
            name="streetAddress"
            label="Street address"
            control={control}
            variant="outlined"
            fullWidth
            helperTextFn={() => formState?.errors['streetAddress']?.message || ''}
          />
          <Stack direction="row" gap={3}>
            <SearchSelect
              onChangeCallback={() => {
                setValue('provinceState', '', { shouldValidate: true });
                setValue('postalZipCode', '', { shouldValidate: true });
              }}
              name="country"
              label="Country*"
              required
              helperText={formState?.errors.country ? 'A country selection is required!' : ''}
              control={control}
              data={countryOptions}
            />
            <SearchSelect
              required
              name="provinceState"
              label="Province / State*"
              disabled={!country}
              control={control}
              data={provinceStateOptions}
              helperText={formState?.errors.provinceState ? 'A province/state selection is required!' : ''}
            />

            <TextField
              name="city"
              label="City"
              rules={{
                maxLength: {
                  value: 65,
                  message: 'City is too long.',
                },
              }}
              control={control}
              variant="outlined"
              fullWidth
              helperTextFn={() => formState?.errors['city']?.message || ''}
            />
          </Stack>
          <Stack direction="row" gap={3}>
            <Grid item container xs={4}>
              <TextField
                name="postalZipCode"
                label="Postal / Zip Code"
                rules={{
                  maxLength: {
                    value: 48,
                    message: 'Postal/Zip Code is too long',
                  },
                  pattern: {
                    value: country === 'US' ? VALID_US_ZIP_CODE : VALID_CANADA_POSTAL_CODE,
                    message: 'Invalid Postal/Zip Code',
                  },
                }}
                control={control}
                variant="outlined"
                fullWidth
                helperTextFn={() => formState?.errors['postalZipCode']?.message || ''}
              />
            </Grid>
            <Grid item container xs={8}>
              <SearchSelect
                control={control}
                label="Timezone*"
                name="timeZone"
                data={timeZoneData}
                required
                helperText={formState?.errors.timeZone ? 'A timezone selection is required!' : ''}
              />
            </Grid>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

interface StudentCardProps {
  isEditMode: boolean;
  students: StudentInfo[];
  setShowAddStudentModal: Dispatch<SetStateAction<boolean>>;
}

const StudentsCard = (props: StudentCardProps) => {
  const { isEditMode, students, setShowAddStudentModal } = props;
  return (
    <Card sx={{ pt: 3, pl: 3, pr: 3 }}>
      <CardHeader
        sx={{ p: 0 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="titleLarge">
              Students
            </Typography>
            <Button type="button" variant="contained" disabled={!isEditMode} onClick={() => setShowAddStudentModal(true)}>
              Add
            </Button>
          </Stack>
        }
      />

      <CardContent sx={{ p: 0, pt: 3 }}>
        <Stack sx={{ paddingTop: 1, gap: 2 }}>
          {students?.length > 0 ? (
            students?.map((student, idx) => (
              <Stack
                direction="row"
                gap={3}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                  borderRadius: '4px',
                  padding: '8px 24px 8px 16px',
                }}
                key={`${student.id}-${idx}`}
              >
                <Stack direction="row" gap={3} alignItems="center" sx={{ p: 1 }}>
                  <SchoolOutlined />
                  <Typography variant="bodyLarge">
                    {student.name}: {student.prefixedNumbed}
                  </Typography>
                  <StatusChip
                    label={`${student.status ?? ''}`}
                    statusIcon={getStatusChipIcon(student.status)}
                    iconColor={getStatusChipColour(student.status)}
                  />
                </Stack>
                <Button variant="text" color="primary" size="large" href={routes.users.students.details.url(student.id)}>
                  View
                </Button>
              </Stack>
            ))
          ) : (
            <Stack
              direction="row"
              gap={3}
              justifyContent="space-between"
              alignItems="center"
              sx={{
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                borderRadius: '4px',
                padding: '8px 24px 8px 16px',
              }}
              key={1}
            >
              <Stack direction="row" gap={3} alignItems="center" sx={{ p: 1 }}>
                <SchoolOutlined />
                <Stack>
                  <Typography component="div" variant="bodyLarge">
                    None
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

interface Props {
  parentProfile?: ParentProfileResponse;
  control: Control<ParentDetailsForm, any>;
  formState: FormState<ParentDetailsForm>;
  watch: UseFormWatch<ParentDetailsForm>;
  setValue: UseFormSetValue<ParentDetailsForm>;
  isEditMode: boolean;
  userId: string;
  setFormIsDirty: Dispatch<SetStateAction<boolean>>;
}

const ParentsDetailsTab = (props: Props) => {
  const { parentProfile, control, formState, watch, setValue, isEditMode, userId, setFormIsDirty } = props;
  const country = watch('country');
  const { countryOptionsCanadaFirst, provinceStateOptions } = usePlaces(country ?? '');
  const countryOptions = countryOptionsCanadaFirst.filter((c) => c.value === 'CA' || c.value === 'US');
  const [students, setStudents] = useState<StudentInfo[]>(parentProfile?.students ?? []);
  const [showAddStudentModal, setShowAddStudentModal] = useState<boolean>(false);

  const handleStudentSelected = (selectedStudent: SearchStudentResponse) => {
    const newStudents: StudentInfo[] = [
      ...students,
      {
        id: selectedStudent.id,
        name: selectedStudent.name,
        status: selectedStudent.profileStatus,
        number: selectedStudent.studentNumber,
        prefixedNumbed: selectedStudent.studentPrefixedNumber,
      },
    ];
    setStudents(newStudents);
    setFormIsDirty(true);
    setValue('students', newStudents);
  };

  return (
    <>
      <Stack direction="row" spacing={3}>
        <Stack spacing={3} sx={{ width: '30%' }}>
          <ParentsStatusCard
            control={control}
            watch={watch}
            isEditMode={isEditMode}
            updatedStatusAt={parentProfile ? parentProfile.statusesAndPermissions.userAccountIsEnabledLastUpdatedAt : null}
          />
          <ReferralCodeCard control={control} />
        </Stack>
        <Stack spacing={3} sx={{ width: '70%' }}>
          <ParentPersonalInfoCard
            control={control}
            formState={formState}
            watch={watch}
            countryOptions={countryOptions}
            provinceStateOptions={provinceStateOptions}
            setValue={setValue}
          />
          <StudentsCard isEditMode={isEditMode} students={students} setShowAddStudentModal={setShowAddStudentModal} />
        </Stack>
      </Stack>
      <StudentModals
        userId={userId}
        showAddStudentModal={showAddStudentModal}
        setShowAddStudentModal={setShowAddStudentModal}
        handleStudentSelected={handleStudentSelected}
        parentName={`${parentProfile?.personalInformation?.firstName ?? ''} ${parentProfile?.personalInformation?.lastName ?? ''}`}
      />
    </>
  );
};

export default ParentsDetailsTab;
