import { ScheduledLessonHistoryAction } from '@hoot-reading/hoot-core/dist/enums/scheduled-lessons/scheduled-lesson-history-action';
import { Card, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tldraw, loadSnapshot } from 'tldraw';
import Lottie, { LottieFile } from '@hoot/components/lottie/Lottie';
import useGetLessonWhiteboardSnapshot, { WhiteboardStoreSnapshotResponse } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboardSnapshot';
import { routes } from '../../../../routes/routes';
import { useLessonVisualizer } from './context/LessonVisualizerContextProvider';
import { useLessonEventReader } from './hooks/useLessonEventReader';
import { videoPosition } from './hooks/useLessonEventVideo';

export type LibraryState = {
  page: 'library';
};

export type ReaderState = {
  page: 'reader';
  bookId: string;
  pageIds: string[];
};

export type AssessmentState = {
  page: 'assessment';
};

export type WhiteboardState = {
  page: 'whiteboard';
  whiteBoardId: string;
};

export type PageState = LibraryState | ReaderState | AssessmentState | WhiteboardState;

export function LessonEventsContent() {
  const { celebration } = useLessonVisualizer();
  const [showCelebration, setShowCelebration] = useState<LottieFile | undefined>();

  useEffect(() => {
    if (celebration) {
      setShowCelebration(celebration);
    }
  }, [celebration]);

  const onLoopEnd = () => {
    setShowCelebration(undefined);
  };

  return (
    <Stack flex={1}>
      <Stack direction="row" spacing="24px">
        <Videos />
        <Stack flex={1} alignItems="center" justifyContent="center" sx={{ height: '53vh' }}>
          <Card sx={{ height: '100%', width: '100%', position: 'relative' }}>
            <Stack sx={{ height: '100%', width: '100%' }} justifyContent="center" alignItems="center">
              <Stack position="relative" width="100%" height="100%" justifyContent="center" alignItems="center">
                <ContentView />
                {showCelebration && (
                  <Lottie lottieFile={showCelebration} play={true} style={{ height: '50%', position: 'absolute' }} onLoopComplete={onLoopEnd} />
                )}
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Stack>
  );
}

function Videos() {
  const { teacherVideoRef, studentVideoRef, lesson, studentVideoPosition, teacherVideoPosition } = useLessonVisualizer();

  const [videoLoading, setVideoLoading] = useState(true);

  useEffect(() => {
    const teacherVideo = teacherVideoRef.current;

    const handleLoadStart = () => {
      setVideoLoading(true);
    };

    const handleCanPlayThrough = () => {
      setVideoLoading(false);
    };

    if (teacherVideo) {
      teacherVideo.addEventListener('loadstart', handleLoadStart);
      teacherVideo.addEventListener('canplaythrough', handleCanPlayThrough);
    }

    return () => {
      if (teacherVideo) {
        teacherVideo.removeEventListener('loadstart', handleLoadStart);
        teacherVideo.removeEventListener('canplaythrough', handleCanPlayThrough);
      }
    };
  }, [teacherVideoRef]);

  return (
    <Stack spacing="24px">
      <Card sx={{ margin: '200px' }}>
        <Stack sx={{ alignItems: 'center', gap: 1, padding: 1 }}>
          <Card sx={{ width: '248px', height: '179px' }}>
            <Stack
              sx={{
                position: 'relative',
                height: '100%',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <Video videoRef={teacherVideoRef} videoPosition={teacherVideoPosition} videoLoading={videoLoading} />
              {teacherVideoPosition === 'none' ? <VideoUnavailable /> : null}
            </Stack>
          </Card>
          <Link to={routes.users.teachers.details.url(lesson?.teacherAccountId ?? '')} target="_blank" style={{ textDecoration: 'none' }}>
            <Typography variant="titleSmall">{lesson?.teacherName}</Typography>
          </Link>
        </Stack>
      </Card>

      <Card sx={{ margin: '200px' }}>
        <Stack sx={{ alignItems: 'center', gap: 1, padding: 1 }}>
          <Card sx={{ width: '248px', height: '179px' }}>
            <Stack
              sx={{
                position: 'relative',
                height: '100%',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <Video videoRef={studentVideoRef} videoPosition={studentVideoPosition} videoLoading={videoLoading} />
              {studentVideoPosition === 'none' ? <VideoUnavailable /> : null}
            </Stack>
          </Card>
          <Link to={routes.users.students.details.url(lesson?.studentProfileId ?? '')} target="_blank" style={{ textDecoration: 'none' }}>
            <Typography variant="titleSmall">{lesson?.studentPrefixedNumber}</Typography>
          </Link>
        </Stack>
      </Card>
    </Stack>
  );
}

function Video(props: { videoRef: React.MutableRefObject<HTMLVideoElement | null>; videoPosition: videoPosition; videoLoading: boolean }) {
  const { isPlaying, syncVideoTimestamp } = useLessonVisualizer();

  useEffect(() => {
    const videoRef = props.videoRef.current;

    const handleCanPlayThrough = () => {
      if (videoRef?.paused && isPlaying) {
        syncVideoTimestamp();
        videoRef?.play();
      }
    };

    videoRef?.addEventListener('canplaythrough', handleCanPlayThrough);

    return () => {
      videoRef?.removeEventListener('canplaythrough', handleCanPlayThrough);
    };
  }, [props.videoRef, isPlaying, syncVideoTimestamp]);

  return (
    <>
      <video
        ref={props.videoRef}
        preload="auto"
        controls={false}
        style={{
          width: props.videoPosition === 'full' ? '100%' : '200%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          left: props.videoPosition === 'right' ? '-100%' : undefined,
          right: props.videoPosition === 'left' ? '-100%' : undefined,
          display: props.videoLoading ? 'none' : 'block',
        }}
      />
      {props.videoLoading ? (
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            color: '#FFF',
            backgroundColor: '#000',
          }}
          justifyContent="center"
          alignItems="center"
        >
          Video Is Loading
        </Stack>
      ) : null}
    </>
  );
}

function VideoUnavailable() {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#cccccc',
      }}
    >
      <Typography variant="bodySmall">Video Unavailable</Typography>
    </Stack>
  );
}

const CONTENT_EVENTS = [
  ScheduledLessonHistoryAction.TeacherJoinedLesson,
  ScheduledLessonHistoryAction.StudentJoinedLesson,
  ScheduledLessonHistoryAction.StudentOpenedABook,
  ScheduledLessonHistoryAction.TeacherOpenedABook,
  ScheduledLessonHistoryAction.BookPageTurn,
  ScheduledLessonHistoryAction.TeacherCreatedAWhiteboard,
  ScheduledLessonHistoryAction.TeacherResumedAWhiteboard,
  ScheduledLessonHistoryAction.TeacherStartedAHRA,
  ScheduledLessonHistoryAction.TeacherStartedAPMA,
  ScheduledLessonHistoryAction.TeacherResumedAHRA,
  ScheduledLessonHistoryAction.TeacherResumedAPMA,
  ScheduledLessonHistoryAction.TeacherStartedAHRA,
  ScheduledLessonHistoryAction.TeacherStartedAPMA,
  ScheduledLessonHistoryAction.TeacherResumedAHRA,
  ScheduledLessonHistoryAction.TeacherResumedAPMA,
  ScheduledLessonHistoryAction.TeacherClosedABook,
  ScheduledLessonHistoryAction.TeacherClosedAPMA,
  ScheduledLessonHistoryAction.TeacherClosedAHRA,
  ScheduledLessonHistoryAction.TeacherClosedAWhiteboard,
  ScheduledLessonHistoryAction.CompletedPMA,
  ScheduledLessonHistoryAction.CompletedHRA,
];

function ContentView() {
  const intervalId = useRef<number>();
  const [pageState, setPageState] = useState<PageState>({ page: 'library' });
  const { currentTime, eventHistory } = useLessonVisualizer();

  useEffect(() => {
    const updatePageState = () => {
      const eventsBeforeCurrentTime = eventHistory.current.filter((e) => CONTENT_EVENTS.includes(e.action) && e.occuredAt <= currentTime.current);

      const event = eventsBeforeCurrentTime.length > 0 ? eventsBeforeCurrentTime[eventsBeforeCurrentTime.length - 1] : null;

      if (event) {
        switch (event.action) {
          case ScheduledLessonHistoryAction.TeacherJoinedLesson:
          case ScheduledLessonHistoryAction.StudentJoinedLesson:
            setPageState({
              page: 'library',
            });
            break;
          case ScheduledLessonHistoryAction.StudentOpenedABook:
          case ScheduledLessonHistoryAction.TeacherOpenedABook:
            if (event.properties && event.properties.action === ScheduledLessonHistoryAction.TeacherOpenedABook) {
              setPageState({
                page: 'reader',
                bookId: event.properties.bookId,
                pageIds: event.properties.pageIds,
              });
            }
            break;
          case ScheduledLessonHistoryAction.BookPageTurn:
            if (event.properties && event.properties.action === ScheduledLessonHistoryAction.BookPageTurn) {
              setPageState({
                page: 'reader',
                bookId: event.properties.bookId,
                pageIds: event.properties.pageIds,
              });
            }
            break;
          case ScheduledLessonHistoryAction.TeacherCreatedAWhiteboard:
          case ScheduledLessonHistoryAction.TeacherResumedAWhiteboard:
            if (event.properties && event.properties.action === ScheduledLessonHistoryAction.TeacherCreatedAWhiteboard) {
              setPageState({
                page: 'whiteboard',
                whiteBoardId: event.properties.whiteboardId,
              });
            }
            break;
          case ScheduledLessonHistoryAction.TeacherStartedAHRA:
          case ScheduledLessonHistoryAction.TeacherStartedAPMA:
          case ScheduledLessonHistoryAction.TeacherResumedAHRA:
          case ScheduledLessonHistoryAction.TeacherResumedAPMA:
            setPageState({
              page: 'assessment',
            });
            break;
          case ScheduledLessonHistoryAction.TeacherClosedABook:
          case ScheduledLessonHistoryAction.TeacherClosedAPMA:
          case ScheduledLessonHistoryAction.TeacherClosedAHRA:
          case ScheduledLessonHistoryAction.TeacherClosedAWhiteboard:
          case ScheduledLessonHistoryAction.CompletedPMA:
          case ScheduledLessonHistoryAction.CompletedHRA:
            setPageState({
              page: 'library',
            });
        }
      }
    };

    intervalId.current = window.setInterval(updatePageState, 200);

    return () => {
      window.clearInterval(intervalId.current);
    };
  }, [currentTime, eventHistory]);

  switch (pageState.page) {
    case 'assessment':
      return <AssessmentView />;
    case 'library':
      return <LibraryView />;
    case 'reader':
      return <ReaderView readerState={pageState} />;
    case 'whiteboard':
      return <WhiteboardView whiteboardState={pageState} />;
  }
  return null;
}

function LibraryView() {
  return (
    <Stack padding="24px" alignItems="center" justifyContent="center" minHeight="51vh">
      <Box sx={{ borderRadius: '16px', overflow: 'hidden', margin: '8px' }}>
        <Box sx={{ maxHeight: '34vh', minHeight: '34vh' }}>
          <Box
            component="img"
            sx={{ objectFit: 'contain', height: '100%', width: '100%', display: 'block' }}
            src="/images/visualizer/library.png"
            alt="Library"
          />
        </Box>
      </Box>
      <Typography variant="titleLarge">Library</Typography>
    </Stack>
  );
}

function WhiteboardView(props: { whiteboardState: WhiteboardState }) {
  const [whiteboardSnapshotResponse, setWhiteboardSnapshotResponse] = useState<WhiteboardStoreSnapshotResponse>();

  useGetLessonWhiteboardSnapshot(props.whiteboardState.whiteBoardId, {
    onSuccess: (response) => {
      setWhiteboardSnapshotResponse(response);
    },
  });

  return (
    <Box
      className="tldraw__editor"
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '51vh',
      }}
    >
      {whiteboardSnapshotResponse ? (
        <Tldraw
          components={{
            NavigationPanel: null,
            Toolbar: null,
            DebugPanel: null,
            MenuPanel: null,
          }}
          onMount={(editor) => {
            loadSnapshot(editor.store, { document: whiteboardSnapshotResponse.storeSnapshot });
            editor.updateInstanceState({ isReadonly: true });
            editor.zoomToFit();
          }}
        />
      ) : null}
    </Box>
  );
}

function ReaderView(props: { readerState: ReaderState }) {
  // placeholder book page

  const bookPages = useLessonEventReader(props.readerState.bookId, props.readerState.pageIds);
  const isDoublePage = bookPages.length === 2;

  return (
    <Stack padding="24px" alignItems="center" justifyContent="center" minHeight="51vh">
      <Stack sx={{ borderRadius: '16px', margin: '8px' }} alignItems="center" justifyContent="center">
        <Stack direction="row" sx={{ height: '100%', width: '100%', maxHeight: '51vh' }} alignItems="center" justifyContent="center">
          {bookPages.map((page, idx) => (
            <BookPage
              key={page.id}
              src={page.url}
              alt={`Page Number ${page.pageNumber}`}
              pageSide={!isDoublePage ? 'singlePage' : idx === 0 ? 'left' : 'right'}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}

type BookPageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  pageSide: 'left' | 'right' | 'singlePage';
};

function BookPage(props: BookPageProps) {
  const { pageSide, ...rest } = props;

  return (
    <Box
      component="img"
      sx={{
        objectFit: 'contain',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        // Left and right pages have a different border radius.
        borderRadius: pageSide === 'left' ? '10px 0px 0px 10px' : pageSide === 'right' ? '0px 10px 10px 0px' : '10px',
        width: '100%',
        height: '100%',
      }}
      {...rest}
    />
  );
}

function AssessmentView() {
  return (
    <Stack padding="24px" alignItems="center" justifyContent="center" minHeight="51vh">
      <Box sx={{ borderRadius: '16px', overflow: 'hidden', margin: '8px' }}>
        <Box sx={{ maxHeight: '34vh', maxWidth: '100%', display: 'flex', justifyContent: 'center' }}>
          <Box
            component="img"
            sx={{
              objectFit: 'contain',
              maxWidth: '100%',
              maxHeight: '100%',
              display: 'block',
            }}
            src="/images/visualizer/assessment.png"
            alt="Assessment"
          />
        </Box>
      </Box>
      <Typography variant="titleLarge">Assessment</Typography>
    </Stack>
  );
}
