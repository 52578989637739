import { ListItem, ListItemText, Typography } from '@mui/material';

const NoneListItem = () => {
  return (
    <ListItem
      sx={{
        borderBottom: '1px solid #CAC4D0',
      }}
    >
      <ListItemText
        primary={
          <Typography component="div" variant="bodyLarge">
            None
          </Typography>
        }
      />
    </ListItem>
  );
};

export default NoneListItem;
