import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  List,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { defaultRowsPerPage, rowsPerPageOptions } from '@hoot/common/constants';
import SearchTextField from '@hoot/components/form/SearchTextField';
import { enrolmentTypeMapping } from '@hoot/components/hfs-utils';
import { EnrolmentStatusChip } from '@hoot/components/ui/statusChips/EnrolmentStatusChip';
import { ProductLocation } from '@hoot/hooks/api/hfs/district-schools/products/useGetProduct';
import { routes } from '@hoot/routes/routes';
import useGetProductEnrolments, { ProductEnrolment } from '../../../hooks/api/hfs/district-schools/products/useGetProductEnrolments';
import { NoneListItem, SortableTableHeader, TableHeaderProp } from '../details/Shared';
import { ActiveProductEnrolmentFiltersContainer, ProductEnrolmentFilters, ProductEnrolmentFiltersContainer } from './ProductEnrolmentFilters';

const enrolmentsTableHeaders: TableHeaderProp<ProductEnrolment>[] = [
  { property: 'id', label: 'Enrolment Id' },
  { property: 'locationName', label: 'School' },
  { property: 'type', label: 'Type' },
  { property: 'startDate', label: 'Start Date' },
  { property: 'endDate', label: 'End Date' },
  { property: 'status', label: 'Status' },
];

const ProductEnrolments = (props: { productId?: string; productStatus: ProductStatus; location?: ProductLocation }) => {
  const { productId, productStatus, location } = props;
  const navigate = useNavigate();

  const [filters, setFilters] = useState<ProductEnrolmentFilters>({
    status: [],
    type: [],
  });

  const [query, setQuery] = useState<string>('');
  const [pageSize, setRowsPerPage] = useState<number>(defaultRowsPerPage);
  const [page, setPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState<keyof ProductEnrolment | null>('id');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>('asc');

  const enrolmentsResponse = useGetProductEnrolments(productId!, {
    enabled: !!productId,
  });

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, defaultRowsPerPage));
    setPage(0);
  };

  const handleSearchChange = (val: string) => {
    setQuery(val);
  };

  if (enrolmentsResponse.isLoading) {
    return <CircularProgress />;
  }

  const filterFunc = (value: ProductEnrolment): boolean => {
    const meetsCriteria: boolean[] = [];

    if (value.id.toLowerCase().includes(query.toLowerCase())) {
      meetsCriteria.push(true);
    }

    if (filters.status && filters.status?.length > 0) {
      meetsCriteria.push(filters.status.includes(value.status));
    }

    if (filters.type && filters.type?.length > 0) {
      meetsCriteria.push(filters.type.includes(value.type));
    }

    if (filters.startsAt) {
      meetsCriteria.push(value.startDate > DateTime.fromFormat(filters.startsAt, 'LL/dd/yyyy').toMillis());
    }

    if (filters.endsAt) {
      meetsCriteria.push(value.endDate < DateTime.fromFormat(filters.endsAt, 'LL/dd/yyyy').toMillis());
    }

    return meetsCriteria.length > 0 && meetsCriteria.every((c) => c === true);
  };

  const enrolments = enrolmentsResponse.data?.enrolments || [];

  const truncatedEnrolments = enrolments
    .filter(filterFunc)
    .sort((a, b) => {
      if (!sortBy) {
        return 0;
      } else if (typeof a[sortBy] === 'number' && typeof b[sortBy] === 'number') {
        if (sortOrder === 'asc') {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        } else {
          return b[sortBy] < a[sortBy] ? 1 : -1;
        }
      } else if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
        if (sortOrder === 'asc') {
          return (a[sortBy] as string).localeCompare(b[sortBy] as string);
        } else {
          return (b[sortBy] as string).localeCompare(a[sortBy] as string);
        }
      }
      return 0;
    })
    .slice(page * pageSize, page * pageSize + pageSize);

  const onCreateClick = () => {
    if (location) {
      navigate(
        routes.districtsSchools.enrolments.create.url(
          location.id,
          location.type,
          location.name,
          location.friendlyId,
          location.isBillableEntity,
          productId,
        ),
      );
    }
  };

  return (
    <Card
      sx={{
        width: 1,
        marginTop: '24px',
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <Grid container item direction="column" xs={12}>
          <Grid container item justifyContent="space-between">
            <Grid item>
              <Typography component="h3" variant="titleLarge">
                Enrolments
              </Typography>
            </Grid>
            <Grid item>
              <Button onClick={onCreateClick} variant="contained" color="primary" disabled={productStatus === ProductStatus.Expired}>
                Create
              </Button>
            </Grid>
          </Grid>
          <Grid sx={{ marginTop: '24px' }} container item xs={12}>
            <SearchTextField onSearchInputChanged={handleSearchChange} searchInput={query} />
          </Grid>
          <Grid sx={{ marginTop: '24px' }} container item xs={12}>
            <ProductEnrolmentFiltersContainer value={filters} onChange={(val) => setFilters(val)} />
          </Grid>

          <Grid sx={{ marginTop: '24px' }} container item xs={12}>
            <ActiveProductEnrolmentFiltersContainer value={filters} onChange={(val) => setFilters(val)} />
          </Grid>

          <Grid sx={{ marginTop: '24px' }} container item xs={12}>
            {enrolments.length > 0 ? (
              <Table sx={{ minWidth: 650 }} aria-label="product details table">
                <SortableTableHeader
                  headers={enrolmentsTableHeaders}
                  setSortBy={setSortBy}
                  setSortOrder={setSortOrder}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                />
                <TableBody>
                  {truncatedEnrolments.map((enrolment) => (
                    <TableRow key={enrolment.id}>
                      <TableCell>
                        {location ? (
                          <Link to={routes.districtsSchools.enrolments.details.url(location.id, enrolment.id)}>{enrolment.friendlyId}</Link>
                        ) : null}
                      </TableCell>
                      <TableCell>{enrolment.locationName}</TableCell>
                      <TableCell>{enrolmentTypeMapping[enrolment.type]}</TableCell>
                      <TableCell>{DateTime.fromMillis(enrolment.startDate).toFormat('LL/dd/yyyy')}</TableCell>
                      <TableCell>{DateTime.fromMillis(enrolment.endDate).toFormat('LL/dd/yyyy')}</TableCell>
                      <TableCell>
                        <EnrolmentStatusChip status={enrolment.status} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={enrolmentsTableHeaders.length}
                      rowsPerPageOptions={rowsPerPageOptions}
                      count={truncatedEnrolments.length || 0}
                      rowsPerPage={pageSize || 0}
                      page={(page || 1) - 1}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            ) : (
              <List sx={{ width: '100%' }}>
                <NoneListItem />
              </List>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProductEnrolments;
