import { CancellationReason, ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { SortOrder } from '@hoot/interfaces/order-by';
import { GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import { QueryKey } from '../../../queryKeys';

export enum LessonNotesLegacyOrderColumn {
  LessonDate = 'lesson_date',
  LessonStatus = 'lesson_status',
  CreatedBy = 'created_by',
  CancellationReason = 'cancellation_reason',
}

export interface LessonNotesLegacyQuery {
  page: number;
  pageSize: number;
  studentProfileId: string;
  orderColumn: LessonNotesLegacyOrderColumn;
  orderBy: SortOrder;
}

export type LessonNotesLegacyResponse = GenericPaginatedResponse<{
  id: string;
  lessonDate: string;
  lessonStatus: ScheduledLessonStatus;
  createdBy: string;
  bookLevels: string;
  cancellationReason: CancellationReason | null;
}>;

async function getLessonNotesLegacy(query: LessonNotesLegacyQuery) {
  const { data } = await axios.get<LessonNotesLegacyResponse>(`${config.apiUrl}/admin/lesson-reviews/legacy`, { params: query });
  return data;
}

export function useGetLessonNotesLegacy(
  query: LessonNotesLegacyQuery,
  options?: Omit<UseQueryOptions<LessonNotesLegacyResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetLessonNotesLegacy, query], () => getLessonNotesLegacy(query), options);
}
