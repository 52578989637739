import { EnrolmentLoginType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-login-type.enum';
import { EnrolmentRosterStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-roster-status.enum';
import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { Box, Card, CardContent, CardHeader, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { LegacyRef, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@hoot/components/form/DatePicker';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { TextField } from '@hoot/components/form/TextField';
import { EnrolmentLoginTypeList, EnrolmentTypeList } from '@hoot/components/hfs-enums';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import useCreateEnrolments, { CreateEnrolmentRequest } from '@hoot/hooks/api/hfs/useCreateEnrolments';
import { EnrolmentDistrictRepresentativeResponse, EnrolmentResponse } from '@hoot/hooks/api/hfs/useGetEnrolment';
import useUpdateEnrolment, { UpdateEnrolmentRequest } from '@hoot/hooks/api/hfs/useUpdateEnrolment';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { enrolmentRosterStatusList, enrolmentStatusList } from '@hoot/hooks/api/user/district-representative/useGetDistrictRepresentative';
import CardLine from '@hoot/pages/common/CardLine';
import { BillingDetails } from '@hoot/pages/district-schools/details/enrolments/EnrolmentPage';
import DistrictRepresentatives from '@hoot/pages/district-schools/details/enrolments/details/DistrictRepresentatives';
import { routes } from '@hoot/routes/routes';
import NoneItem from '../../../../../components/ui/NoneItem';
import { EnrolmentAssociatedProductCard } from './EnrolmentAssociatedProductCard';
import EnrolmentPeriodCard from './enrolment-period/EnrolmentPeriodCard';

export interface EnrolmentForm {
  locationName: string;
  locationFriendlyId: string;
  friendlyId: string;
  status: EnrolmentStatus;
  numberOfLessons: number;
  type: EnrolmentType;
  startDate: number;
  endDate: number;
  product: {
    id: string;
    startsAt: number;
    endsAt: number;
  };
  districtRepresentatives: EnrolmentDistrictRepresentativeResponse[];
  updatedStatusAt: number;
  rosterStatus: EnrolmentRosterStatus;
  loginType: EnrolmentLoginType;
}

const setEnrolmentType = (locationType: string | null, isBillableEntity: string | null): EnrolmentType => {
  if (locationType === LocationType.District) {
    return EnrolmentType.AtHome;
  }

  if (locationType === LocationType.School && isBillableEntity === 'false') {
    return EnrolmentType.InSchool;
  }

  return EnrolmentType.InSchool;
};

interface Props {
  isEditMode: boolean;
  locationId: string;
  enrolmentId?: string;
  productId: string | null;
  enrolment?: EnrolmentResponse;
  locationType: LocationType;
  locationName: string | null;
  locationFriendlyId: string | null;
  locationIsBillableEntity: string | null;
  billingDetails?: BillingDetails;
  formRef: React.MutableRefObject<HTMLFormElement | undefined>;
}

const EnrolmentDetails = (props: Props) => {
  const {
    isEditMode,
    locationId,
    productId,
    enrolmentId,
    enrolment,
    locationType,
    locationName,
    locationFriendlyId,
    locationIsBillableEntity,
    billingDetails,
    formRef,
  } = props;

  const { setIsDirty } = useBlockIfDirty();
  const { scopes } = useAuth();

  const isNavigatingToEnrolment = useRef<string | undefined>();

  const hasViewDistrictDetailsPermission = !!scopes.find((s) => s === HootEmployeeScope.ViewDistrictDetails);

  const { control, handleSubmit, setValue, watch, formState } = useForm<EnrolmentForm>({
    mode: 'all',
    defaultValues: enrolment
      ? {
          friendlyId: enrolment.friendlyId,
          locationName: enrolment.locationName,
          locationFriendlyId: enrolment.locationFriendlyId,
          status: enrolment.status,
          numberOfLessons: enrolment.numberOfLessons,
          type: enrolment.type,
          startDate: enrolment.startDate,
          endDate: enrolment.endDate,
          product: {
            id: enrolment.product.id,
            startsAt: enrolment.product.startDate,
            endsAt: enrolment.product.endDate,
          },
          districtRepresentatives: enrolment.districtRepresentatives,
          updatedStatusAt: enrolment.updatedStatusAt,
          rosterStatus: enrolment.rosterStatus,
          loginType: enrolment.loginType,
        }
      : {
          friendlyId: '',
          locationName: locationName ?? '',
          locationFriendlyId: locationFriendlyId ?? '',
          status: EnrolmentStatus.Open,
          numberOfLessons: 0,
          type: setEnrolmentType(locationType, locationIsBillableEntity),
          startDate: 0,
          endDate: 0,
          product: {
            id: productId ?? '',
            startsAt: 0,
            endsAt: 0,
          },
          districtRepresentatives: [],
          updatedStatusAt: 0,
          rosterStatus: EnrolmentRosterStatus.Pending,
          loginType: EnrolmentLoginType.Password,
        },
  });
  const { startDate, endDate, districtRepresentatives, product, status, updatedStatusAt, type } = watch();
  const hasProductDates = product?.startsAt && product?.endsAt;

  const createEnrolment = useCreateEnrolments();
  const updateEnrolment = useUpdateEnrolment(enrolmentId!);
  const { success, error } = useAlert();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { isDirty } = useBlockIfDirty();

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState, setIsDirty]);

  useEffect(() => {
    if (!isEditMode && !isDirty && isNavigatingToEnrolment.current) {
      const enrolmentId = isNavigatingToEnrolment.current;
      navigate(routes.districtsSchools.enrolments.details.url(locationId, enrolmentId));
    }
  }, [isDirty, isEditMode, navigate, locationId]);

  useEffect(() => {
    if (startDate !== 0 && endDate !== 0) {
      const _startDate = DateTime.fromMillis(startDate).toISODate();
      const _endDate = DateTime.fromMillis(endDate).toISODate();
      const today = DateTime.now().toISODate();
      if (_startDate && _endDate && today) {
        const changeToActive = _startDate <= today && today <= _endDate && status !== EnrolmentStatus.InProgress;
        const changeToScheduled = today < _startDate && status !== EnrolmentStatus.Scheduled;
        const changeToClosed = _endDate < today && status !== EnrolmentStatus.Closed;
        if (changeToActive) {
          setValue('status', EnrolmentStatus.InProgress, { shouldDirty: true });
        } else if (changeToScheduled) {
          setValue('status', EnrolmentStatus.Scheduled, { shouldDirty: true });
        } else if (changeToClosed) {
          setValue('status', EnrolmentStatus.Closed, { shouldDirty: true });
        }
      }
    }
  }, [startDate, endDate, status, setValue]);

  const isTypeDisable = (): boolean => {
    // Don't let the enrolment type change if the enrolment has already been created
    if (isEditMode) {
      return true;
    }

    // Don't let the enrolment type change if the location type is District - it is defaulted to At Home
    if (type && locationType === LocationType.District) {
      return true;
    }

    // Otherwise allow enrolment type to be changed
    // It means location type is a school OR enrolment type doesn't have a value (thought it should be set by the form defaults)
    return false;
  };

  const dateValidation = (epochDate: number | null, epochDateToCompare: number | null = null, checkingEndDate: boolean = false) => {
    if (epochDate === null) {
      return `${checkingEndDate ? 'End Date is required.' : 'Start Date is required.'}`;
    }

    const dateToCheck = DateTime.fromMillis(epochDate).startOf('day');

    if (epochDateToCompare) {
      const dateToCompare = DateTime.fromMillis(epochDateToCompare).startOf('day');

      if (checkingEndDate ? dateToCheck < dateToCompare : dateToCheck > dateToCompare) {
        return checkingEndDate ? 'End Date must be after or on the Start Date.' : 'Start Date must be before or on the End Date.';
      }
    }

    // If no product selected, then we can't validate the dates. Just let it slide, and our product validation should
    // handle this (products are required).
    if (!product) {
      return true;
    }

    const productStartDate = DateTime.fromMillis(product.startsAt).startOf('day');
    const productEndDate = DateTime.fromMillis(product.endsAt).endOf('day');
    if (dateToCheck < productStartDate || dateToCheck > productEndDate) {
      return checkingEndDate
        ? `End Date must be between ${DateTime.fromMillis(product.startsAt).toISODate()} and ${DateTime.fromMillis(product.endsAt).toISODate()}.`
        : `Start Date must be between ${DateTime.fromMillis(product.startsAt).toISODate()} and ${DateTime.fromMillis(product.endsAt).toISODate()}.`;
    }

    return true;
  };

  const openAndScheduledEnrolmentStatuses = [EnrolmentStatus.Open, EnrolmentStatus.Scheduled];
  const inProgressAndClosedEnrolmentStatus = [EnrolmentStatus.InProgress, EnrolmentStatus.Closed];
  const filteredEnrolmentStatuses = enrolmentStatusList.filter(({ value }) =>
    openAndScheduledEnrolmentStatuses.includes(status) ? openAndScheduledEnrolmentStatuses.includes(value) : true,
  );

  const onSubmit = (data: EnrolmentForm) => {
    const startDateBeginningOfDay = DateTime.fromMillis(data.startDate).startOf('day').toMillis();
    const endDateEndOfDay = DateTime.fromMillis(data.endDate).endOf('day').toMillis();

    if (isEditMode) {
      const request: UpdateEnrolmentRequest = {
        numberOfLessons: data.numberOfLessons,
        startDate: startDateBeginningOfDay,
        endDate: endDateEndOfDay,
        status: data.status,
        rosterStatus: data.rosterStatus,
        loginType: data.loginType,
      };
      updateEnrolment.mutate(request, {
        onSuccess: () => {
          setIsDirty(false);
          queryClient.invalidateQueries([QueryKey.GetEnrolment, enrolmentId]);
          success(`Enrolment successfully updated.`);
        },
        onError: (errorData) => {
          console.error(errorData);
          error(`There was an error, the enrolment has not been edited.`);
        },
      });
    } else {
      const request: CreateEnrolmentRequest = {
        numberOfLessons: parseInt(data.numberOfLessons as unknown as string),
        startDate: startDateBeginningOfDay,
        endDate: endDateEndOfDay,
        status: data.status,
        type: data.type,
        locationId: locationId,
        productId: data.product.id,
        rosterStatus: data.rosterStatus,
        loginType: data.loginType,
      };

      createEnrolment.mutate(request, {
        onSuccess: (enrolmentId) => {
          setIsDirty(false);
          success(`Enrolment successfully created.`);
          isNavigatingToEnrolment.current = enrolmentId;
        },
        onError: (errorData) => {
          console.error(errorData);
          error(`There was an error, the enrolment has not been edited.`);
        },
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef as LegacyRef<HTMLFormElement>} id="enrolment-form">
        <Grid container spacing={3} pt={3}>
          <Grid item xs={12} lg={4}>
            {/* Statuses  & Permissions card */}
            <Card>
              <CardHeader
                sx={{ p: 3, pb: 0 }}
                title={
                  <Stack sx={{ p: 0 }}>
                    <Typography variant="titleLarge">Statuses & Permissions</Typography>
                  </Stack>
                }
              />
              <CardContent>
                <Stack gap={2}>
                  <Box>
                    <Dropdown
                      name="status"
                      label="Enrolment Status *"
                      options={filteredEnrolmentStatuses}
                      control={control}
                      rules={{ required: true }}
                      variant="outlined"
                      disabled={inProgressAndClosedEnrolmentStatus.includes(status)}
                    />
                    {updatedStatusAt !== 0 ? (
                      <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                        Since {DateTime.fromMillis(updatedStatusAt).toFormat('MM/dd/yyyy')}
                      </FormHelperText>
                    ) : null}
                  </Box>
                  <Box>
                    <Dropdown
                      name="rosterStatus"
                      label="Roster Status"
                      options={enrolmentRosterStatusList}
                      control={control}
                      rules={{ required: true }}
                      variant="outlined"
                    />
                  </Box>
                </Stack>
              </CardContent>
            </Card>

            {/* Billing Details card */}
            <Card sx={{ mt: '24px' }}>
              <CardHeader
                sx={{ p: 3, pb: 0 }}
                title={
                  <Stack sx={{ p: 0 }}>
                    <Typography variant="titleLarge">Billing Details</Typography>
                  </Stack>
                }
              />
              <CardContent>
                <Stack gap={1}>
                  <CardLine
                    title="Name"
                    bodyText={billingDetails?.locationName || ''}
                    openInNewURL={billingDetails ? routes.districtsSchools.details.url(billingDetails.locationId) : undefined}
                  />
                  <CardLine title="ID" bodyText={billingDetails?.locationFriendlyId || ''} copyButton />
                  {type === EnrolmentType.InSchool ? (
                    <CardLine title="Student Access Point" bodyText={billingDetails?.studentAccessPoint || ''} copyButton />
                  ) : null}
                </Stack>
              </CardContent>
            </Card>

            {/* District Representatives card */}
            <Card sx={{ mt: '24px' }}>
              <CardHeader
                sx={{ p: 3, pb: 0 }}
                title={
                  <Stack sx={{ p: 0 }} justifyContent="space-between" alignItems="center" direction="row">
                    <Typography variant="titleLarge">District Representatives</Typography>
                  </Stack>
                }
              />
              <CardContent>
                <Typography sx={{ color: '#000' }} variant="bodySmall">
                  District Representatives
                </Typography>
                <Box mt={1}>
                  {districtRepresentatives ? <DistrictRepresentatives districtRepresentatives={districtRepresentatives} /> : <NoneItem />}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={8}>
            {/* Enrolment Details card */}
            <Card>
              <CardHeader
                sx={{ p: 3, pb: 0 }}
                title={
                  <Stack sx={{ p: 0 }}>
                    <Typography variant="titleLarge">Enrolment Details</Typography>
                  </Stack>
                }
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      control={control}
                      fullWidth
                      name="numberOfLessons"
                      label="Number of Lessons *"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      rules={{
                        validate: (val: number | '' | undefined) => {
                          if (!val) {
                            return 'Number of lessons is required.';
                          }

                          if (val && val < 1) {
                            return 'A minimum of 1 lesson is required.';
                          }
                          return true;
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Dropdown
                      name="type"
                      label="Type *"
                      disabled={isTypeDisable()}
                      options={EnrolmentTypeList}
                      control={control}
                      rules={{ required: true }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ width: 'full' }}>
                      <DatePicker
                        disablePast
                        disabled={!hasProductDates}
                        treatDateAsNumber
                        name="startDate"
                        control={control}
                        label="Start Date *"
                        rules={{
                          required: true,
                          validate: (val) => dateValidation(val, endDate, false),
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ width: 'full' }}>
                      <DatePicker
                        disablePast
                        disabled={!hasProductDates}
                        treatDateAsNumber
                        name="endDate"
                        control={control}
                        label="End Date *"
                        rules={{
                          required: true,
                          validate: (val) => dateValidation(val, startDate, true),
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Dropdown
                      name="loginType"
                      label="Login Type *"
                      disabled={!hasViewDistrictDetailsPermission}
                      options={EnrolmentLoginTypeList}
                      control={control}
                      rules={{ required: true }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Enrolment Schedule card */}
            <EnrolmentPeriodCard enrolment={enrolment} />

            {/* Associated Product card */}
            <Controller
              name="product"
              control={control}
              rules={{
                required: 'You must select a product.',
              }}
              render={({ field, fieldState }) => (
                <EnrolmentAssociatedProductCard fieldState={fieldState} value={field.value?.id} onChange={field.onChange} />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EnrolmentDetails;
