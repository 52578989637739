import { Today } from '@mui/icons-material';
import {
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DateTime } from 'luxon';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FormOption } from '@hoot/interfaces/form';
import { removePrefix } from '@hoot/utils/stringUtils';
import { defaultRowsPerPage } from '../../common/constants';
import SearchTextField from '../../components/form/SearchTextField';
import DateFilter, { ActiveDateFilter } from '../../components/form/filterDropDown/common/DateFilter';
import { ActiveLessonSetFilterDropDown, LessonSetStatusFilterDropDown } from '../../components/form/filterDropDown/lesson-sets/LessonSetStatusFilter';
import EmptyCircleIcon from '../../components/icons/EmptyCircleIcon';
import FullCircleIcon from '../../components/icons/FullCircle';
import PartialCircleIcon from '../../components/icons/PartialCircleIcon';
import HeaderCard from '../../components/ui/HeaderCard';
import { HeaderData, SelectAllState, Table } from '../../components/ui/Table';
import { useAlert } from '../../contexts/AlertContext';
import { useBulkUpdateLessonSets } from '../../hooks/api/lesson-sets/useBulkUpdateLessonSet';
import { GetLessonSetsRequest, LessonSetOrderBy, useGetLessonSets } from '../../hooks/api/lesson-sets/useGetLessonSets';
import { QueryKey } from '../../hooks/api/queryKeys';
import { useMappedSearchParams } from '../../hooks/useMappedSearchParams';
import { SortOrder, toggleSortOrder } from '../../interfaces/order-by';
import { routes } from '../../routes/routes';
import { groupBy } from '../district-schools/details/enrolments/details/util';
import { OrderBy, ScheduledLessonSetStatus, scheduledLessonSetStatusType } from '../lessons/enums';

interface SelectedLessonSet {
  lessonSetId: string;
  status: ScheduledLessonSetStatus;
}

interface TableColumn {
  id: string;
  selected: boolean;
  student: React.ReactNode;
  lessonSet: React.ReactNode;
  startDate: string;
  endDate: string;
  enrolmentId: React.ReactNode;
  location: React.ReactNode;
  status: React.ReactNode;
  action: React.ReactNode;
}

const tableHeaders: HeaderData<TableColumn>[] = [
  { name: 'ID', property: 'id', isHidden: true },
  { name: 'Student', property: 'student', isSortable: true, sortKey: LessonSetOrderBy.StudentName },
  { name: 'Lesson Set', property: 'lessonSet', isSortable: true, sortKey: LessonSetOrderBy.LessonSet },
  { name: 'Start Date', property: 'startDate', isSortable: true, sortKey: LessonSetOrderBy.StartDate },
  { name: 'End Date', property: 'endDate', isSortable: true, sortKey: LessonSetOrderBy.EndDate },
  { name: 'Enrolment Id', property: 'enrolmentId', isSortable: true, sortKey: LessonSetOrderBy.EnrolmentId },
  { name: 'District/School', property: 'location', isSortable: true, sortKey: LessonSetOrderBy.Location },
  { name: 'Status', property: 'status', isSortable: true, sortKey: LessonSetOrderBy.Status },
  { name: 'Action', property: 'action' },
];

export enum LessonSetSearchFieldOptions {
  LessonSetNumber = 'LESSON_SET_NUMBER',
  LocationName = 'LOCATION_NAME',
  StudentNumber = 'STUDENT_NUMBER',
  EnrolmentFriendlyId = 'ENROLMENT_FID',
  All = 'ALL',
}

const searchFieldOptions: FormOption<LessonSetSearchFieldOptions>[] = [
  { value: LessonSetSearchFieldOptions.LessonSetNumber, label: 'Lesson Set Number' },
  { value: LessonSetSearchFieldOptions.EnrolmentFriendlyId, label: 'Enrolment ID' },
  { value: LessonSetSearchFieldOptions.LocationName, label: 'District/School Name' },
  { value: LessonSetSearchFieldOptions.StudentNumber, label: 'Student Number' },
  { value: LessonSetSearchFieldOptions.All, label: 'All Fields' },
];

export function LessonSets() {
  const [, setSearchParams] = useSearchParams();

  const queryParams = useMappedSearchParams<GetLessonSetsRequest>([
    { name: 'page', default: 1, type: 'number' },
    { name: 'pageSize', default: defaultRowsPerPage, type: 'number' },
    { name: 'status', isArray: true, default: [ScheduledLessonSetStatus.Posted] },
    { name: 'searchFieldSelection', default: LessonSetSearchFieldOptions.All },
  ]);

  const [query, setQuery] = useState<GetLessonSetsRequest>(queryParams);
  const [selectedRow, setSelectedRow] = useState<SelectedLessonSet[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const alerts = useAlert();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const lessonSetsRequest = useGetLessonSets({ ...query, query: removePrefix(query.query) });
  const bulkUpdateLessonSets = useBulkUpdateLessonSets();

  useEffect(() => {
    const params = Object.entries(query)
      .filter(([, b]) => !!b)
      .reduce((a, [key, val]) => ({ ...a, [key]: val }), {}) as Record<string, string>;
    setSearchParams(params);
  }, [query, setSearchParams]);

  const handleSearchChange = (text: string) => {
    setQuery((currentState) => ({ ...currentState, query: text }));
  };

  const searchFieldOnChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setQuery((currentState) => ({
      ...currentState,
      searchFieldSelection: value as LessonSetSearchFieldOptions,
    }));
  };

  const handleSortBy = (selectedColumn: keyof TableColumn) => {
    function sortKey(): LessonSetOrderBy {
      switch (selectedColumn) {
        case 'student':
          return LessonSetOrderBy.StudentName;
        case 'startDate':
          return LessonSetOrderBy.StartDate;
        case 'endDate':
          return LessonSetOrderBy.EndDate;
        case 'enrolmentId':
          return LessonSetOrderBy.EnrolmentId;
        case 'lessonSet':
          return LessonSetOrderBy.LessonSet;
        case 'location':
          return LessonSetOrderBy.Location;
        case 'status':
          return LessonSetOrderBy.Status;
        default:
          return LessonSetOrderBy.StartDate;
      }
    }
    const sortColumn = sortKey();
    setQuery((q) => ({ ...q, sortColumn: sortColumn, sortDirection: toggleSortOrder(q.sortDirection), page: 1 }));
  };

  const handleFiltersChange = (updatedQuery: GetLessonSetsRequest) => {
    setQuery((currentState) => ({ ...currentState, ...updatedQuery }));
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setQuery((q) => ({ ...q, page: page + 1 }));
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setQuery((q) => ({ ...q, pageSize }));
  };

  const handleRowSelect = (row: TableColumn, checked: boolean) => {
    if (checked) {
      const lessonSet = lessonSetsRequest.data?.lessonSets.find((ls) => ls.lessonSetId === row.id);
      if (lessonSet) {
        setSelectedRow((currentState) => [
          ...currentState,
          {
            lessonSetId: lessonSet.lessonSetId,
            status: lessonSet.status,
          },
        ]);
      }
    } else {
      setSelectedRow((currentState) => currentState.filter((s) => s.lessonSetId !== row.id));
    }
  };

  const handleSelectAll = (currentSelectAllState: SelectAllState) => {
    if (lessonSetsRequest.data) {
      if (currentSelectAllState === 'none' || currentSelectAllState === 'indeterminate') {
        setSelectedRow(lessonSetsRequest.data.lessonSets.map<SelectedLessonSet>((ls) => ({ lessonSetId: ls.lessonSetId, status: ls.status })));
      } else {
        return setSelectedRow([]);
      }
    }
  };

  const handleBulkStatusChangeClick = () => {
    setShowModal(true);
  };

  const handleCreateButtonClick = () => {
    navigate(routes.lessons.create.url);
  };

  const handleBulkEditClose = () => {
    setShowModal(false);
  };

  const handleBulkEditApply = (updateToStatus: ScheduledLessonSetStatus) => {
    bulkUpdateLessonSets.mutate(
      {
        lessonSetIds: selectedRow.map((sr) => sr.lessonSetId),
        updateToStatus: updateToStatus,
      },
      {
        onSuccess: () => {
          alerts.success(`${selectedRow.length} Lesson Sets Successfully Updated!`);
          setSelectedRow([]);
          queryClient.invalidateQueries(QueryKey.LessonSets);
          setShowModal(false);
        },
        onError: () => {
          alerts.error('There was an issue Updating Lesson Sets');
        },
      },
    );
  };

  const data: TableColumn[] =
    lessonSetsRequest.data?.lessonSets.map((l) => ({
      selected: selectedRow.some((r) => r.lessonSetId === l.lessonSetId),
      id: l.lessonSetId,
      student: <Link to={routes.users.students.details.url(l.studentProfileId)}>{l.studentPrefixedNumber}</Link>,
      lessonSet: (
        <Link to={routes.lessonSets.details.url(l.lessonSetId)}>
          <Typography sx={{ color: '#000000' }} variant="bodyMedium">
            {l.lessonSetPrefixedNumber}
          </Typography>
        </Link>
      ),
      startDate: DateTime.fromSQL(l.startDate).toFormat('LL/dd/yyyy'),
      endDate: DateTime.fromSQL(l.endDate).toFormat('LL/dd/yyyy'),
      enrolmentId: <Link to={routes.districtsSchools.enrolments.details.url(l.locationId, l.enrolmentId)}>{l.enrolmentFriendlyId}</Link>,
      location: <Link to={routes.districtsSchools.details.url(l.locationId)}>{l.locationName}</Link>,
      status: <LessonSetStatusChip status={l.status} />,
      action: (
        <Link to={`/lessons?lessonSetNumber=${l.lessonSetNumber}`} target="_blank">
          {<Button>View all lessons</Button>}
        </Link>
      ),
    })) ?? [];

  return (
    <Stack>
      <HeaderCard title="Lesson Sets" />
      <Card title="Lesson Sets" sx={{ mt: 3, p: 3 }}>
        <Stack>
          <Stack direction="row" justifyContent="space-between" mb={2}>
            <Typography variant="titleLarge">Lesson Sets</Typography>
            <Box>
              <Button disabled={selectedRow.length === 0} variant="outlined" onClick={handleBulkStatusChangeClick}>
                Bulk Status Change
              </Button>
              <Button sx={{ marginLeft: '16px' }} variant="contained" onClick={handleCreateButtonClick}>
                Create
              </Button>
            </Box>
          </Stack>

          <Stack direction={'row'} gap={2} mt={3}>
            <Box width={'250px'}>
              <FormControl fullWidth variant={'outlined'} size={'medium'}>
                <InputLabel id={'fieldSearchField'}>Search in</InputLabel>
                <Select labelId={'fieldSearchField'} label={'Search in'} value={query.searchFieldSelection as any} onChange={searchFieldOnChange}>
                  {searchFieldOptions.map((x) => (
                    <MenuItem key={`fieldSearchField-${x.value}`} value={x.value}>
                      {x.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <SearchTextField onSearchInputChanged={handleSearchChange} debounceTime={300} searchInput={query?.query || ''} />
          </Stack>

          <Box sx={{ marginTop: '24px' }}>
            <TableFilters value={query} onChange={handleFiltersChange} />
          </Box>
          <Box sx={{ marginTop: '24px' }}>
            <ActiveFilters value={query} onChange={handleFiltersChange} />
          </Box>
          <Box sx={{ marginTop: '24px' }}>
            <Table
              data={data}
              headers={tableHeaders}
              isSortable
              isSelectable
              isPaginated
              isLoading={lessonSetsRequest.isFetching}
              page={lessonSetsRequest.data?.page ?? 1}
              count={lessonSetsRequest.data?.count ?? 0}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onSelect={handleRowSelect}
              onSelectAll={handleSelectAll}
              sortOrder={query.sortDirection === SortOrder.ASC ? OrderBy.Asc : OrderBy.Desc}
              sortBy={query.sortColumn}
              onSortBy={handleSortBy}
            />
          </Box>
        </Stack>
      </Card>
      {showModal ? <BulkEditDialog onApply={handleBulkEditApply} onClose={handleBulkEditClose} lessonSets={selectedRow} /> : null}
    </Stack>
  );
}

function TableFilters(props: { value: GetLessonSetsRequest; onChange?: (filters: GetLessonSetsRequest) => void }) {
  const { value, onChange } = props;

  const handleChange = (property: keyof GetLessonSetsRequest) => (val: any | any[]) => {
    if (onChange) {
      onChange({ ...value, [property]: val });
    }
  };

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item sx={{ marginRight: '8px' }}>
        <Typography variant="bodySmall">Filters</Typography>
      </Grid>
      <LessonSetStatusFilterDropDown onChange={handleChange('status')} value={value.status ?? []} popOverButtonSx={{ ml: 0, mr: '8px' }} />
      <DateFilter
        title="Starts At"
        onChange={(val) => handleChange('startDate')(val.toSQLDate())}
        value={value?.startDate ? DateTime.fromSQL(value.startDate) : undefined}
        popOverButtonSx={{ ml: 0, mr: '8px' }}
      />
      <DateFilter
        title="Ends At"
        onChange={(val) => handleChange('endDate')(val.toSQLDate())}
        value={value?.endDate ? DateTime.fromSQL(value.endDate) : undefined}
        popOverButtonSx={{ ml: 0, mr: '8px' }}
      />
    </Grid>
  );
}

function ActiveFilters(props: { value: GetLessonSetsRequest; onChange?: (filters: GetLessonSetsRequest) => void }) {
  const { value, onChange } = props;

  const handleDelete = (property: keyof Omit<GetLessonSetsRequest, 'sortDirection' | 'sortColumn' | 'searchFieldSelection'>) => () => {
    if (value) {
      const updatedFilters = { ...value };
      updatedFilters[property] = undefined;
      if (onChange) {
        onChange(updatedFilters);
      }
    }
  };

  return (
    <Grid container item xs={12} alignItems="center" spacing={2}>
      <Grid item sx={{ marginRight: '8px' }}>
        <Typography variant="bodySmall">Active</Typography>
      </Grid>
      <Grid
        item
        sx={{
          '& > div': {
            marginRight: '8px',
          },
        }}
      >
        <ActiveLessonSetFilterDropDown value={value.status} onChange={handleDelete('status')} />
        {value.startDate ? (
          <ActiveDateFilter label="Starts At" value={DateTime.fromSQL(value.startDate).toFormat('LL/dd/yyyy')} onChange={handleDelete('startDate')} />
        ) : null}
        {value.endDate ? (
          <ActiveDateFilter label="Ends At" value={DateTime.fromSQL(value.endDate).toFormat('LL/dd/yyyy')} onChange={handleDelete('endDate')} />
        ) : null}
      </Grid>
    </Grid>
  );
}

export function LessonSetStatusIcon(props: { status: ScheduledLessonSetStatus; sx?: SvgIconProps['sx'] }) {
  const { sx, status } = props;
  switch (status) {
    case ScheduledLessonSetStatus.Pending:
      return <PartialCircleIcon fill="#1976D2" sx={sx} />;
    case ScheduledLessonSetStatus.Cancelled:
      return <EmptyCircleIcon stroke="#B3261E" sx={sx} />;
    case ScheduledLessonSetStatus.Posted:
      return <FullCircleIcon fill="#2E7D32" sx={sx} />;
    case ScheduledLessonSetStatus.Rescheduling:
      return <PartialCircleIcon fill="#1976D2" sx={sx} />;
    case ScheduledLessonSetStatus.Closed:
    default:
      return <FullCircleIcon fill="#000000" sx={sx} />;
  }
}

export function LessonSetStatusChip(props: { status: ScheduledLessonSetStatus; count?: number; error?: boolean }) {
  return (
    <Chip
      sx={{
        backgroundColor: props.error ? '#FCDAD3' : undefined,
        border: props.error ? '1px solid #BF3A1C' : undefined,
        paddingLeft: '8px',
        '& > svg': {
          width: '8px',
          height: '8px',
        },
      }}
      icon={<LessonSetStatusIcon status={props.status} />}
      size="small"
      label={`${scheduledLessonSetStatusType[props.status]}${props.count ? ` (${props.count})` : ''}`}
    />
  );
}

function BulkEditDialog(props: {
  lessonSets: SelectedLessonSet[];
  onClose: () => void;
  onApply: (updateToStatus: ScheduledLessonSetStatus) => void;
}) {
  const [updatedToStatus, setUpdateToStatus] = useState<ScheduledLessonSetStatus>(ScheduledLessonSetStatus.Posted);

  const handleCloseClick = () => {
    props.onClose();
  };

  const handleApplyClick = () => {
    props.onApply(updatedToStatus);
  };

  const handleChange = (_event: ChangeEvent<HTMLInputElement>, value: string) => {
    setUpdateToStatus(value as ScheduledLessonSetStatus);
  };

  const error = props.lessonSets.some((ls) => ls.status === updatedToStatus);

  const ErrorMessage = () => {
    if (error) {
      return (
        <Box sx={{ marginTop: '8px' }}>
          <Typography sx={{ color: '#BF3A1C' }} variant="bodySmall">
            You cannot apply a status of {scheduledLessonSetStatusType[updatedToStatus]} to Lessons Sets that have a status of{' '}
            {scheduledLessonSetStatusType[updatedToStatus]}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Dialog open={true} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle>Bulk State Change</DialogTitle>
      <DialogContent>
        <Typography sx={{ color: error ? '#BF3A1C' : undefined }} variant="bodySmall">
          Selected
        </Typography>
        <Stack
          direction="row"
          sx={{
            marginTop: '8px',
            borderRadius: '8px',
            border: error ? '1px solid #BF3A1C' : '1px solid #B2B2B2',
            backgroundColor: error ? '#FEF6F4' : '#FAFAFA',
            padding: '16px',
          }}
          gap="8px"
        >
          <Today />
          <Stack>
            <Typography variant="bodyLarge">{props.lessonSets.length} Lesson Set(s)</Typography>
            <Stack direction="row" gap="8px" sx={{ marginTop: '8px' }}>
              {groupBy(props.lessonSets, 'status').map((ls) => (
                <LessonSetStatusChip
                  key={ls.name}
                  error={error && ls.name === updatedToStatus}
                  status={ls.name as ScheduledLessonSetStatus}
                  count={ls.count}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
        <ErrorMessage />
        <FormControl sx={{ marginTop: '16px' }}>
          <FormLabel>Change all selected lesson sets to</FormLabel>
          <RadioGroup onChange={handleChange} defaultValue={ScheduledLessonSetStatus.Posted} name="lesson-set-statuses">
            <FormControlLabel value={ScheduledLessonSetStatus.Posted} control={<Radio />} label="Posted" />
            <FormControlLabel value={ScheduledLessonSetStatus.Rescheduling} control={<Radio />} label="Rescheduling" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <Divider sx={{ marginTop: '16px' }} />
      <DialogActions>
        <Button onClick={handleCloseClick} size="large" variant="outlined">
          Cancel
        </Button>
        <Button disabled={error} onClick={handleApplyClick} size="large" sx={{ marginLeft: '16px' }} variant="contained">
          Apply to {props.lessonSets.length}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
