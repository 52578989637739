import { Card, CardContent, CardHeader, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Control } from 'react-hook-form';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { ParentDetailsForm } from '@hoot/components/Secure/Users/parent/form';
import SwitchField from '@hoot/components/form/SwitchField';
import { AccountSelect } from '@hoot/components/form/selectFields/AccountSelect';

interface Props {
  control: Control<ParentDetailsForm>;
  watch: UseFormWatch<ParentDetailsForm>;
  isEditMode: boolean;
  updatedStatusAt: number | null;
}

const ParentsStatusCard = (props: Props) => {
  const { control, watch, isEditMode, updatedStatusAt } = props;
  const { userAccountStatus, parentProfileStatus } = watch();
  return (
    <Card sx={{ pr: 3, pl: 3 }}>
      <CardHeader
        sx={{ pt: 3, pb: 3, pl: 0, pr: 0 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="titleLarge">
              Statuses & Permissions
            </Typography>
          </Stack>
        }
      />

      <CardContent sx={{ p: 0 }}>
        <Stack gap={3}>
          <AccountSelect control={control} name="accountId" includeEmpty />
          <Stack>
            <Grid sx={{ marginTop: '8px' }} container item xs={12}>
              <Typography variant={'bodySmall'} sx={{ mt: '1px', mb: '12px' }}>
                User Account Status*
              </Typography>
              <SwitchField label={`${userAccountStatus ? 'Enabled' : 'Disabled'}`} control={control} name="userAccountStatus" />
            </Grid>

            {isEditMode && updatedStatusAt ? (
              <FormHelperText sx={{ marginTop: '8px', color: '#1C1B1F' }}>
                Since {DateTime.fromMillis(updatedStatusAt).toFormat('MM/dd/yyyy')}
              </FormHelperText>
            ) : null}
          </Stack>
          <Stack>
            <Typography variant={'bodySmall'} sx={{ mt: '1px', mb: '12px' }}>
              Parent Profile Status
            </Typography>
            <SwitchField label={`${parentProfileStatus ? 'Enabled' : 'Disabled'}`} control={control} name="parentProfileStatus" />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ParentsStatusCard;
