import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import { ParentProfilesQuerySortKeyEnum } from '@hoot-reading/hoot-core/dist/enums/user/parent-profiles-query-sort-key.enum';
import { Box, Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getHootSupportedCountryKeyFromValue, hootSupportedCountryMapping } from '@hoot/components/hfs-utils';
import { SortOrder } from '@hoot/interfaces/order-by';
import { StyledTableCell } from '@hoot/styles/Table';
import SortIcon from '../../../../../components/icons/SortIcon';
import useGetParentProfiles, { ParentProfileResponse, ParentProfilesQuery } from '../../../../../hooks/api/hfs/useGetParents';

const ParentProfilesCheckBoxTable = (props: {
  activeFilters: string[];
  searchText: string;
  selectedRow: ParentProfileResponse;
  setSelectedRow: React.Dispatch<React.SetStateAction<ParentProfileResponse>>;
}) => {
  const { activeFilters, searchText, selectedRow, setSelectedRow } = props;
  const [parentProfilesQuery, setParentProfilesQuery] = useState<ParentProfilesQuery>({
    pageSize: 10,
    page: 1,
    orderBy: ParentProfilesQuerySortKeyEnum.Name,
    sortDirection: SortOrder.ASC,
  });
  const { data: parentProfilesResponseData } = useGetParentProfiles(parentProfilesQuery);

  useEffect(() => {
    setParentProfilesQuery((current) => ({ ...current, searchText: searchText, page: 1 }));
  }, [searchText]);

  useEffect(() => {
    const countryFilters = activeFilters
      .filter((filter) => filter.startsWith('Country'))
      .map((val) => val.split(':')[1].trim())
      .map((val) => getHootSupportedCountryKeyFromValue(val))
      .filter((key) => key !== undefined) as HootSupportedCountry[];

    const updatedQuery = {
      countries: countryFilters.length > 0 ? countryFilters : undefined,
    };
    setParentProfilesQuery((current) => ({ ...current, ...updatedQuery, page: 1 }));
    setSelectedRow({} as ParentProfileResponse);
  }, [activeFilters, setSelectedRow]);

  const handleRowClick = (row: ParentProfileResponse) => {
    if (selectedRow.id === row.id) {
      setSelectedRow({} as ParentProfileResponse);
    } else {
      setSelectedRow(row);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setParentProfilesQuery((current) => ({ ...current, page: newPage }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setParentProfilesQuery((current) => ({ ...current, pageSize: parseInt(event.target.value, 10), page: 1 }));
  };

  const handleSort = (column: ParentProfilesQuerySortKeyEnum) => {
    const isAsc = parentProfilesQuery.orderBy === column && parentProfilesQuery.sortDirection === SortOrder.ASC;
    const sortDirection = isAsc ? SortOrder.DESC : SortOrder.ASC;
    setParentProfilesQuery((current) => ({ ...current, orderBy: column, sortDirection: sortDirection, page: 1 }));
  };

  return (
    <Box sx={{ overflowY: 'auto' }}>
      <Table stickyHeader aria-label="parent profiles table">
        <TableHead>
          <TableRow>
            <StyledTableCell padding="checkbox"></StyledTableCell>
            <StyledTableCell onClick={() => handleSort(ParentProfilesQuerySortKeyEnum.Name)} align="left">
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                <span>Name</span>
                {parentProfilesQuery.orderBy === ParentProfilesQuerySortKeyEnum.Name ? <SortIcon /> : null}
              </Box>
            </StyledTableCell>
            <StyledTableCell align="left" onClick={() => handleSort(ParentProfilesQuerySortKeyEnum.Email)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                <span>E-mail</span>
                {parentProfilesQuery.orderBy === ParentProfilesQuerySortKeyEnum.Email ? <SortIcon /> : null}
              </Box>
            </StyledTableCell>
            <StyledTableCell align="left" onClick={() => handleSort(ParentProfilesQuerySortKeyEnum.PhoneNumber)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                <span>Phone</span>
                {parentProfilesQuery.orderBy === ParentProfilesQuerySortKeyEnum.PhoneNumber ? <SortIcon /> : null}
              </Box>
            </StyledTableCell>
            <StyledTableCell align="left" onClick={() => handleSort(ParentProfilesQuerySortKeyEnum.Country)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                Country
                {parentProfilesQuery.orderBy === ParentProfilesQuerySortKeyEnum.Country ? <SortIcon /> : null}
              </Box>
            </StyledTableCell>
            <StyledTableCell align="left" onClick={() => handleSort(ParentProfilesQuerySortKeyEnum.TimeZone)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                Timezone
                {parentProfilesQuery.orderBy === ParentProfilesQuerySortKeyEnum.TimeZone ? <SortIcon /> : null}
              </Box>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parentProfilesResponseData?.parentProfiles?.map((row) => {
            const isRowSelected = selectedRow.id === row.id;
            return (
              <TableRow
                hover
                onClick={() => handleRowClick(row)}
                role="checkbox"
                aria-checked={isRowSelected}
                tabIndex={-1}
                key={row.id}
                selected={isRowSelected}
                sx={{ '&:hover': { cursor: 'pointer' } }}
              >
                <TableCell padding="checkbox">
                  <Checkbox checked={isRowSelected} />
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.phoneNumber}</TableCell>
                <TableCell align="left">{hootSupportedCountryMapping[row.country as HootSupportedCountry]}</TableCell>
                <TableCell align="left">{row.timeZone}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 50, 100]}
        count={parentProfilesResponseData?.count ?? 0}
        page={parentProfilesQuery.page}
        onPageChange={handleChangePage}
        rowsPerPage={parentProfilesQuery.pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default ParentProfilesCheckBoxTable;
