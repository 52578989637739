import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { LessonQueryRequest } from '@hoot/hooks/api/lessons/lesson-query-request';
import { QueryKey } from '../queryKeys';

async function getLessonsWithTimeInfoResultCount(query: LessonQueryRequest) {
  const { data } = await axios.post<number>(`${config.apiUrl}/admin/scheduler/lessons/search/with-time-info/result-count`, query);
  return data;
}

export default function useLessonsWithTimeInfoTotalResultCountQuery(
  query: LessonQueryRequest,
  options?: Omit<UseQueryOptions<number>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.LessonsWithTimeInfoResultCount, query], () => getLessonsWithTimeInfoResultCount(query), options);
}
