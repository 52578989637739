import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { QualityAssuranceOwningTeam, qualityAssuranceOwningTeamLookup } from '../../../../hooks/api/tasks/enums';
import FilterDropDown from '../../FilterDropDown';

export function QualityAssuranceOwningTeamFilterDropDown(props: {
  value: QualityAssuranceOwningTeam[];
  onChange: (val: QualityAssuranceOwningTeam[]) => void;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { value, onChange, popOverButtonSx } = props;

  return (
    <FilterDropDown<string>
      title="Owning Team"
      dismissPopoverOnSelection={true}
      value={value}
      options={Object.entries(qualityAssuranceOwningTeamLookup).map(([value, label]) => ({
        label: label,
        value: value,
      }))}
      onChange={(val) => onChange(val as QualityAssuranceOwningTeam[])}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveQualityAssuranceOwningTeamFilterDropDown(props: {
  value?: QualityAssuranceOwningTeam[];
  onChange: (val: QualityAssuranceOwningTeam[]) => void;
}) {
  const { value = [], onChange } = props;

  const handleDelete = (val: QualityAssuranceOwningTeam) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`Owning Team: ${qualityAssuranceOwningTeamLookup[c]}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}
