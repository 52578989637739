import { FormOption } from '@hoot/interfaces/form';

export enum ResourceState {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Read = 'READ',
}

export const resourceStateLookup: Record<ResourceState, string> = {
  IN_PROGRESS: 'In Progress',
  NOT_STARTED: 'Not Started',
  READ: 'Read',
};

export enum ResourceProgress {
  NotStarted = 'NOT_STARTED',
  Practicing = 'PRACTICING',
  Finished = 'FINISHED',
}

export const resourceProgressLookup: Record<ResourceProgress, string> = {
  PRACTICING: 'Practicing',
  NOT_STARTED: 'Not Started',
  FINISHED: 'Finished',
};

export const resourceProgressOptions: FormOption<ResourceProgress>[] = [
  { value: ResourceProgress.NotStarted, label: 'Not Started' },
  { value: ResourceProgress.Practicing, label: 'Practicing' },
  { value: ResourceProgress.Finished, label: 'Finished' },
];
