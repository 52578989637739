import { FilterAltOutlined } from '@mui/icons-material';
import { Badge, Card, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { BookTableSortableFields, handleSortBy, sortBy } from '@hoot/components/ui/SortableLibrary';
import { SortOrder } from '@hoot/interfaces/order-by';
import { OrderBy } from '@hoot/pages/lessons/enums';
import { defaultRowsPerPage } from '../../common/constants';
import SearchTextField from '../../components/form/SearchTextField';
import StatusChip, { StatusChipIcon, StatusChipIconColor } from '../../components/ui/StatusChip';
import { HeaderData, Table } from '../../components/ui/Table';
import { bookTypeLookUp, languageLookUp, subjectLookUp } from '../../hooks/api/library/enums';
import useQueryLibraryV2, { AdminQueryLibraryV2, LibraryOrderByEnum } from '../../hooks/api/library/useQueryLibraryV2';
import { routes } from '../../routes/routes';
import { LibrarySearchV2Drawer } from './LibrarySearchDrawer';

interface LibraryTableRow extends BookTableSortableFields {
  active: React.ReactNode;
  subject: string;
  language: string;
  resourceType: string;
  instructionalFocus: string;
}

const headers: HeaderData<LibraryTableRow>[] = [
  { property: 'title', name: 'Title', isSortable: true, sortKey: LibraryOrderByEnum.Title },
  { property: 'active', name: 'Active' },
  { property: 'subject', name: 'Subject' },
  { property: 'language', name: 'Language' },
  { property: 'resourceType', name: 'Resource Type' },
  { property: 'instructionalFocus', name: 'Instructional Focus' },
  { property: 'bookLevel', name: 'Level', isSortable: true, sortKey: LibraryOrderByEnum.BookLevel },
  { property: 'bookNumber', name: 'Number', isSortable: true, sortKey: LibraryOrderByEnum.BookNumber },
];

export function Library() {
  return (
    <Stack spacing="16px">
      <HeaderCard />
      <SearchCard />
    </Stack>
  );
}

function HeaderCard() {
  return (
    <Card sx={{ padding: '24px' }}>
      <Typography variant="headlineLarge">Library</Typography>
    </Card>
  );
}

function SearchCard() {
  const [searchInput, setSearchInput] = useState<string>('');
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const [query, setQuery] = useState<AdminQueryLibraryV2>({
    page: 1,
    pageSize: 100,
    orderBy: SortOrder.ASC,
  });

  const queryLibrary = useQueryLibraryV2(query);

  const onSearchInputChanged = (searchText: string) => {
    setSearchInput(searchText);
  };

  const onSearchInputDebounced = (searchText: string) => {
    setQuery((currentState) => ({
      ...currentState,
      query: searchText,
    }));
  };

  const handleFilterClick = () => {
    setShowDrawer(true);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  const handleOnApplyFilters = (updatedValue: Partial<AdminQueryLibraryV2>) => {
    setQuery((currentState) => ({
      ...currentState,
      ...updatedValue,
    }));
    setShowDrawer(false);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setQuery((q) => ({ ...q, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setQuery((q) => ({ ...q, pageSize }));
  };

  const count = queryLibrary.data?.count ?? 0;

  const fetchedData = queryLibrary.data;

  const data =
    fetchedData && fetchedData.data
      ? fetchedData.data.map<LibraryTableRow>((d) => ({
          id: d.id,
          active: d.isActive ? (
            <StatusChip iconColor={StatusChipIconColor.Green} statusIcon={StatusChipIcon.FullCircle} label="True" />
          ) : (
            <StatusChip iconColor={StatusChipIconColor.Red} statusIcon={StatusChipIcon.EmptyCircle} label="False" />
          ),
          title: (
            <Link to={routes.library.book.url(d.id)}>
              <Typography variant="bodyMedium">{d.title}</Typography>
            </Link>
          ),
          language: d.language in languageLookUp ? languageLookUp[d.language] : d.language,
          subject: d.subject in subjectLookUp ? subjectLookUp[d.subject] : d.subject,
          instructionalFocus: d.instructionalFocus.join(', '),
          bookLevel: d.level,
          resourceType: d.resourceType && d.resourceType in bookTypeLookUp ? bookTypeLookUp[d.resourceType] : d.resourceType ?? '',
          bookNumber: d.number,
        }))
      : [];

  const appliedFilters = Object.values(query).filter((v) => (Array.isArray(v) ? !!v.length : !!v)).length - 2; // exclude page and pageSize

  return (
    <Card sx={{ padding: '24px' }}>
      <Stack>
        <Stack direction="row" alignItems="center">
          <SearchTextField
            debounceTime={300}
            searchInput={searchInput ?? ''}
            onSearchInputChanged={onSearchInputChanged}
            onSearchInputDebounced={onSearchInputDebounced}
          />

          <IconButton
            onClick={handleFilterClick}
            sx={{
              marginX: '16px',
            }}
          >
            <Badge badgeContent={appliedFilters} color="primary">
              <FilterAltOutlined sx={{ cursor: 'pointer' }} />
            </Badge>
          </IconButton>
        </Stack>
        <Stack sx={{ marginTop: '32px' }}>
          <Table
            isPaginated
            isSortable
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            headers={headers}
            data={data}
            count={count}
            page={query.page}
            rowsPerPage={query.pageSize}
            isLoading={false}
            sortOrder={query.orderBy === SortOrder.ASC ? OrderBy.Asc : OrderBy.Desc}
            sortBy={sortBy()}
            onSortBy={(column) => handleSortBy(column as keyof BookTableSortableFields, setQuery)}
          />
        </Stack>
      </Stack>
      <LibrarySearchV2Drawer
        key={`library-search-drawer-${showDrawer}`}
        value={query}
        onApply={handleOnApplyFilters}
        show={showDrawer}
        onClose={handleCloseDrawer}
      />
    </Card>
  );
}
