import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { LessonDetailResponse, useGetLesson } from '../../../hooks/api/lessons/useGetLesson';
import { RescheduleWizardProvider, RescheduleWizardState, RescheduleWizardType, useRescheduleWizard } from './RescheduleWizardContextProvider';
import { RescheduleWizardTypeStep } from './RescheduleWizardTypeStep';
import { RescheduleWizardConfirmStep } from './new-teacher-flow/RescheduleWizardConfirmStep';
import { RescheduleWizardLessonDetailsStep } from './new-teacher-flow/RescheduleWizardLessonDetailsStep';
import { RescheduleWizardTeacherDetailsStep } from './new-teacher-flow/RescheduleWizardTeacherDetailsStep';
import { RescheduleWizardLessonSameTeacherDetailsStep } from './same-teacher-flow/RescheduleWizardSameTeacherLessonDetailsStep';
import { RescheduleWizardSchedulesStep } from './same-teacher-flow/RescheduleWizardSchedulesStep';

export function RescheduleWizardModal(props: { lessonId: string; onClose: () => void }) {
  const getLessonRequest = useGetLesson(props.lessonId);

  if (!getLessonRequest.data) {
    return null;
  }

  return (
    <RescheduleWizardProvider>
      <Dialog fullWidth maxWidth="md" open={true} onClose={props.onClose} scroll="paper">
        <RescheduleWizardHeader onClose={props.onClose} />
        <Stack sx={{ padding: '24px 24px 0px 24px', position: 'relative' }} gap={3}>
          <RescheduleWizardContent onClose={props.onClose} lessonDetails={getLessonRequest.data} />
        </Stack>
        <Divider sx={{ position: 'absolute', top: 0, left: 0, right: 0, marginLeft: 3, marginRight: 3 }} />
      </Dialog>
    </RescheduleWizardProvider>
  );
}

function RescheduleWizardHeader(props: { onClose?: () => void }) {
  const handleCloseClick = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <DialogTitle>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5">Reschedule Wizard</Typography>
        <IconButton
          onClick={handleCloseClick}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '4px',
            cursor: 'pointer',
          }}
        >
          <Close />
        </IconButton>
      </Stack>
    </DialogTitle>
  );
}

function RescheduleWizardContent(props: { lessonDetails: LessonDetailResponse; onClose: () => void }) {
  const { rescheduledLesson, wizardState } = useRescheduleWizard();

  if (wizardState === RescheduleWizardState.ScheduleWizardType) {
    return <RescheduleWizardTypeStep lessonDetails={props.lessonDetails} />;
  } else {
    if (rescheduledLesson.wizardType === RescheduleWizardType.NewTeacher) {
      switch (wizardState) {
        case RescheduleWizardState.LessonDetails:
          return <RescheduleWizardLessonDetailsStep lessonDetails={props.lessonDetails} />;
        case RescheduleWizardState.TeacherDetails:
          return <RescheduleWizardTeacherDetailsStep lessonDetails={props.lessonDetails} />;
        case RescheduleWizardState.Confirm:
          return <RescheduleWizardConfirmStep onClose={props.onClose} lessonDetails={props.lessonDetails} />;
      }
    } else if (rescheduledLesson.wizardType === RescheduleWizardType.SameTeacher) {
      switch (wizardState) {
        case RescheduleWizardState.LessonDetails:
          return <RescheduleWizardLessonSameTeacherDetailsStep lessonDetails={props.lessonDetails} />;
        case RescheduleWizardState.Schedules:
          return <RescheduleWizardSchedulesStep lessonDetails={props.lessonDetails} />;
        case RescheduleWizardState.TeacherDetails:
          return <RescheduleWizardTeacherDetailsStep lessonDetails={props.lessonDetails} />;
        case RescheduleWizardState.Confirm:
          return <RescheduleWizardConfirmStep onClose={props.onClose} lessonDetails={props.lessonDetails} />;
      }
    }
  }
  return null;
}

export function RescheduleWizardDialogBody(props: {
  errorContainer?: React.ReactNode;
  onPreviousStep?: () => void;
  children: React.ReactNode;
  nextStepDisabled?: boolean;
  hideStepper?: boolean;
}) {
  const { wizardState } = useRescheduleWizard();

  const handlePrevStepClick = () => {
    if (props.onPreviousStep) {
      props.onPreviousStep();
    }
  };

  const showStepper = !props.hideStepper && wizardState > 0;

  return (
    <>
      <DialogContent>
        <Stack>
          {props.errorContainer}
          {showStepper ? (
            <Stepper activeStep={wizardState - 1} alternativeLabel>
              <Step>
                <StepLabel>Lesson Details</StepLabel>
              </Step>
              <Step>
                <StepLabel>Teacher Details</StepLabel>
              </Step>
              <Step>
                <StepLabel>Confirm</StepLabel>
              </Step>
            </Stepper>
          ) : null}
          <Stack marginTop="24px">{props.children}</Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flex={1} direction="row" justifyContent="space-between">
          <Button onClick={handlePrevStepClick} disabled={!props.onPreviousStep} variant="outlined">
            Previous Step
          </Button>
          <Button disabled={props.nextStepDisabled} type="submit" variant="contained">
            {wizardState === RescheduleWizardState.Confirm ? 'Create Lesson' : 'Next Step'}
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
}
