import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface FormatResponse {
  id: string;
  name: string;
}

async function getFormats(): Promise<FormatResponse[]> {
  const { data } = await axios.get<FormatResponse[]>(`${config.apiUrl}/admin/library/formats`);
  return data;
}

export default function useGetFormats(options?: Omit<UseQueryOptions<FormatResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetFormats], () => getFormats(), options);
}
