import * as Sentry from '@sentry/react';
import { Settings } from 'luxon';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ENV_NAME, IS_PROD, IS_STAGING, LOCAL_APP_VERSION, WINNIPEG_TIMEZONE } from './common/constants';
import { config } from './config';
import { AuthProvider } from './contexts/Auth/AuthContext';
import { BlockIfDirtyProvider } from './contexts/BlockIfDirtyContext';
import { CurrentTimeProvider } from './contexts/CurrentTimeContext';
import { FeatureFlagProvider } from './contexts/FeatureFlagContext';
import { UserPreferencesProvider } from './contexts/UserPreferencesContext';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container!);

// LUXON DEFAULT TIME ZONE SET TO WINNIPEG
Settings.defaultZone = WINNIPEG_TIMEZONE;

Sentry.init({
  dsn: config.sentryDSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  enabled: IS_PROD || IS_STAGING,
  environment: ENV_NAME,
  release: LOCAL_APP_VERSION,
  tracesSampler: ({ name, _attributes, inheritOrSampleWith }) => {
    // Examine provided context data (including parent decision, if any) along
    // with anything in the global namespace to compute the sample rate or
    // sampling decision for this transaction
    const busyRoutes = ['/lessons', '/live-lessons/attendance'];

    if (busyRoutes.includes(name)) {
      return 0.01;
    }

    // Default sample rate
    return inheritOrSampleWith(0.1);
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CurrentTimeProvider>
        <BrowserRouter>
          <FeatureFlagProvider>
            <UserPreferencesProvider>
              <AuthProvider>
                <BlockIfDirtyProvider>
                  <App />
                </BlockIfDirtyProvider>
              </AuthProvider>
            </UserPreferencesProvider>
          </FeatureFlagProvider>
        </BrowserRouter>
      </CurrentTimeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
