import { Box, Card, Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { StudentHootAssessment } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentHootAssessments';
import { HootAssessmentStatus } from '@hoot/interfaces/assessments-enums';
import { routes } from '@hoot/routes/routes';
import { enumToTitleCase } from '@hoot/utils/stringUtils';
import StudentAssessmentCard from './StudentAssessmentCard';
import StudentAssessmentV2Card from './StudentAssessmentV2Card';

interface Props {
  assessment: StudentHootAssessment | undefined;
}

const displayDate = (assessmentDate: number | undefined): string => {
  if (!assessmentDate) {
    return '';
  }
  const dateTime = DateTime.fromMillis(assessmentDate);
  return dateTime.toFormat('cccc, LLLL d yyyy, h:mma ZZZZ');
};

const GridContainer = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <Grid
      container
      xs={12}
      item
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </Grid>
  );
};

const CardContainer = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '16px',
        borderRadius: '10px',
        padding: '24px',
        background: '#FFFFFF',
      }}
    >
      {children}
    </Card>
  );
};

const HootReadingAssessmentDetailsPanels = (props: Props) => {
  const { assessment } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '24px',
        padding: '24px',
      }}
    >
      <Box>
        <Card>
          <Box
            sx={{
              display: 'grid',
              height: '100%',
              width: '100%',
              gap: '24px',
              overflowY: '',
              gridTemplateColumns: { sm: 'repeat(1, minmax(0, 2fr))' },
              padding: '24px',
            }}
          >
            <Box component="span" sx={{ fontSize: '22px' }}>
              Details
            </Box>
            <Box
              sx={{
                display: 'grid',
                height: '100%',
                width: '100%',
                gap: '24px',
                overflowY: '',
                gridTemplateColumns: {
                  sm: 'repeat(1, minmax(0, 1fr))',
                  md: 'repeat(2, minmax(0, 1fr))',
                  lg: 'repeat(2, minmax(0, 2fr))',
                },
              }}
            >
              <GridContainer>
                <Typography variant="titleMedium">Start Date</Typography>
                <Typography variant="bodyMedium">{displayDate(assessment?.createdAt)}</Typography>
              </GridContainer>
              <GridContainer>
                <Typography variant="titleMedium">Completion Date</Typography>
                <Typography variant="bodyMedium">
                  {assessment?.status === HootAssessmentStatus.Completed ? displayDate(assessment?.updatedAt) : '-'}
                </Typography>
              </GridContainer>
              <GridContainer>
                <Typography variant="titleMedium">Status</Typography>
                <Typography variant="bodyMedium">{assessment?.status ? enumToTitleCase(assessment?.status) : ''}</Typography>
              </GridContainer>
              {assessment?.abandonedReason ? (
                <GridContainer>
                  <Typography variant="titleMedium">Abandoned Reason</Typography>
                  <Typography variant="bodyMedium">{assessment.abandonedReason}</Typography>
                </GridContainer>
              ) : null}
              {assessment?.version === 1 ? (
                <GridContainer>
                  <Typography variant="titleMedium">Book Level(s)</Typography>
                  <Typography variant="bodyMedium">
                    {assessment?.completedUnits?.some((u) => !!u.bookForRunningRecord)
                      ? assessment?.completedUnits.map((u) => u.bookForRunningRecord?.level)
                      : '-'}
                  </Typography>
                </GridContainer>
              ) : null}
              <GridContainer>
                <Typography variant="titleMedium">Exit Point</Typography>
                <Typography variant="bodyMedium">{assessment?.exitPointUnit?.unitName ?? '-'}</Typography>
                <Typography variant="bodyMedium">{enumToTitleCase(assessment?.exitPointUnit?.instructionIndicator ?? '')}</Typography>
              </GridContainer>
              <GridContainer>
                <Typography variant="titleMedium">Assessed by</Typography>
                {assessment?.version === 1 ? (
                  <Typography variant="bodyMedium">{assessment?.assessedByTeachers.map((t) => `${t.firstName} ${t.lastName}`).join(', ')}</Typography>
                ) : (
                  assessment?.assessedByTeachers.map((x) => (
                    <Link key={x.id} to={routes.users.teachers.details.url(x.id)}>
                      {x.prefixedNumber}
                    </Link>
                  ))
                )}
              </GridContainer>
            </Box>
          </Box>
        </Card>
      </Box>
      <Box
        sx={{
          display: 'grid',
          height: '100%',
          width: '100%',
          gap: '24px',
          gridTemplateColumns: {
            sm: 'repeat(1, minmax(0, 1fr))',
            md: 'repeat(2, minmax(0, 1fr))',
            lg: 'repeat(2, minmax(0, 2fr))',
          },
        }}
      >
        {assessment?.completedUnits.map((unit) => (
          <CardContainer key={unit.unitName}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
              }}
            >
              {assessment?.version === 1 ? (
                <StudentAssessmentCard unit={unit} assessment={assessment} />
              ) : (
                <StudentAssessmentV2Card unit={unit} assessment={assessment} />
              )}
            </Box>
          </CardContainer>
        ))}
      </Box>
    </Box>
  );
};

export default HootReadingAssessmentDetailsPanels;
