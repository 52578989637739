import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { AssessedByTeacher, CompletedUnit, StudentHootAssessment } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentHootAssessments';
import { routes } from '@hoot/routes/routes';
import { enumToTitleCase } from '@hoot/utils/stringUtils';
import { UnitIdentifierHRAV2 } from '../../../../interfaces/assessments-enums';

interface Props {
  unit: CompletedUnit;
  assessment: StudentHootAssessment;
}

const renderScore = (score?: number | null, maxScore?: number | null) => {
  if (!score || !maxScore) return;

  const percentage = ((Number(score) / maxScore) * 100).toFixed(0);

  return `${score} / ${maxScore} (${percentage}%)`;
};

const BoxContainer = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const Footer = (props: { teacher: AssessedByTeacher }) => {
  const { teacher } = props;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '16px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Assessed by
        </Box>
        <Link to={routes.users.teachers.details.url(teacher.id)}>{teacher.prefixedNumber}</Link>
      </Box>
    </Box>
  );
};

const ReadingFluencyCard = (props: { unit: CompletedUnit }) => {
  const { unit } = props;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <Box component="span" sx={{ fontSize: '22px' }}>
          Reading Fluency
        </Box>
      </Box>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Book
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.bookForRunningRecord?.name}</Box>
        <Box sx={{ fontSize: '14px' }}>{unit.bookForRunningRecord?.level}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Accuracy Rate:
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.accuracyRate}%</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          WCPM
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.wordCountPerMin}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Net Fluency Score
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.fluencyScore}</Box>
      </BoxContainer>
      <Footer teacher={unit.assessedByTeacher} />
    </>
  );
};

const ComprehensionStrategiesCard = (props: { unit: CompletedUnit }) => {
  const { unit } = props;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <Box component="span" sx={{ fontSize: '22px' }}>
          Comprehension Strategies
        </Box>
      </Box>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Book
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.bookForRunningRecord?.name}</Box>
        <Box sx={{ fontSize: '14px' }}>{unit.bookForRunningRecord?.level}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Assessment Type
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.unitName}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Net Comprehension Score
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.comprehensionScore}</Box>
      </BoxContainer>
      <Footer teacher={unit.assessedByTeacher} />
    </>
  );
};

const Header = (props: { unit: CompletedUnit }) => {
  const { unit } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <Box component="span" sx={{ fontSize: '22px' }}>
        {unit.unitName}
      </Box>
    </Box>
  );
};

const ModulesCard = (props: { unit: CompletedUnit }) => {
  const { unit } = props;
  return (
    <>
      <Header unit={unit} />
      <BoxContainer>
        <Box component="span" sx={{ fontSize: '14px', fontWeight: 500 }}>
          Score
        </Box>
        <Box sx={{ fontSize: '14px' }}>{renderScore(unit.score, unit.totalQuestions)}</Box>
        <Box sx={{ fontSize: '14px' }}>{enumToTitleCase(unit?.instructionIndicator ?? '')}</Box>
      </BoxContainer>
      <Footer teacher={unit.assessedByTeacher} />
    </>
  );
};

const StudentAssessmentV2Card = (props: Props) => {
  const { unit } = props;
  if (unit.unitIdentifier === UnitIdentifierHRAV2.U_4_1) {
    return <ReadingFluencyCard unit={unit} />;
  } else if ([UnitIdentifierHRAV2.U_4_3, UnitIdentifierHRAV2.U_4_4].includes(unit.unitIdentifier as UnitIdentifierHRAV2)) {
    return <ComprehensionStrategiesCard unit={unit} />;
  } else {
    //For modules 1, 2, and 3
    return <ModulesCard unit={unit} />;
  }
};

export default StudentAssessmentV2Card;
