import { EnrolmentRosterStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-roster-status.enum';
import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { DistrictRepresentativeStatus } from '@hoot-reading/hoot-core/dist/enums/user/district-representative/district-representative-status.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../../queryKeys';

export const districtRepresentativeStatusList: { value: DistrictRepresentativeStatus; label: string }[] = [
  { value: DistrictRepresentativeStatus.Active, label: 'Active' },
  { value: DistrictRepresentativeStatus.Inactive, label: 'Inactive' },
];

export const enrolmentStatusList: { value: EnrolmentStatus; label: string }[] = [
  { value: EnrolmentStatus.Open, label: 'Open' },
  { value: EnrolmentStatus.Scheduled, label: 'Scheduled' },
  { value: EnrolmentStatus.InProgress, label: 'In Progress' },
  { value: EnrolmentStatus.Closed, label: 'Closed' },
];

export const enrolmentRosterStatusList: { value: EnrolmentRosterStatus; label: string }[] = [
  { value: EnrolmentRosterStatus.Pending, label: 'Pending' },
  { value: EnrolmentRosterStatus.Open, label: 'Open' },
  { value: EnrolmentRosterStatus.Waitlist, label: 'Waitlist' },
  { value: EnrolmentRosterStatus.Closed, label: 'Closed' },
];

export const productStatusList: { value: ProductStatus; label: string }[] = [
  { value: ProductStatus.Active, label: 'Active' },
  { value: ProductStatus.Expired, label: 'Expired' },
  { value: ProductStatus.Requested, label: 'Requested' },
];

export const enrolmentStatuses = enrolmentStatusList.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in EnrolmentStatus]: string };

export interface DistrictRepresentativeLocation {
  id: string;
  name: string;
  type: LocationType;
  shortId: string;
  districtName: string;
}

export interface DistrictRepresentativeEnrolment {
  id: string;
  name: string;
  locationName: string;
  locationId: string;
  locationType: LocationType;
  shortId: string;
  status: EnrolmentStatus;
  friendlyId: string;
}

export interface Permissions {
  editDistrict: boolean;
  editSchool: boolean;
  editEnrolment: boolean;
  viewEnrolment: boolean;
}

export interface DistrictRepresentativeResponse {
  id: string;
  endUserId: string;
  status: DistrictRepresentativeStatus;
  firstName: string;
  lastName: string;
  number: number;
  prefixedNumber: string;
  email: string | null;
  phoneNumber: string | null;
  timeZone: string;
  hubspotId: number | null;
  zendeskId: number | null;
  lastUpdatedAt: number;
  accountStatus: boolean;
  accountStatusLastUpdatedAt: number;
  locations: DistrictRepresentativeLocation[];
  enrolments: DistrictRepresentativeEnrolment[];
  permissions: Permissions;
}

async function getDistrictRepresentative(districtRepresentativeId: string): Promise<DistrictRepresentativeResponse> {
  const { data } = await axios.get<DistrictRepresentativeResponse>(`${config.apiUrl}/admin/district-representative/${districtRepresentativeId}`);
  return data;
}

export default function useGetDistrictRepresentative(
  districtRepresentativeId?: string,
  options?: Omit<UseQueryOptions<DistrictRepresentativeResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDistrictRepresentative, districtRepresentativeId],
    () => getDistrictRepresentative(districtRepresentativeId!),
    options,
  );
}
