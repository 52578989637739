import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Paper } from '@mui/material';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import ConfirmDeleteDialog from '@hoot/components/modals/ConfirmDeleteDialog';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import useDeleteProgressMonitoringAssessment from '@hoot/hooks/api/student-hoot-assessments/useDeleteProgressMonitoringAssessment';
import { useGetPMASubmissionDetails } from '@hoot/hooks/api/student-hoot-assessments/useGetPMASubmissionDetails';
import ProgressMonitoringAssessmentDetailsPanels from './StudentProgressMonitoringAssessmentDetailsPanels';

interface Props {
  assessmentId: string | undefined;
  onClose: () => void;
}

const ProgressMonitoringAssessmentDetailsModal = (props: Props) => {
  const { assessmentId, onClose } = props;
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);

  const { success, error } = useAlert();
  const queryClient = useQueryClient();
  const { scopes } = useAuth();

  const hasDeleteAssessmentsPermission = !!scopes.find((s) => s === HootEmployeeScope.DeleteAssessments);

  const { data } = useGetPMASubmissionDetails(assessmentId!, { enabled: !!assessmentId });

  const deletePMARequest = useDeleteProgressMonitoringAssessment(assessmentId!);

  const openConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(true);
  };

  const closeConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
  };

  const onConfirmDeleteAssessment = () => {
    deletePMARequest.mutate(undefined, {
      onSuccess: () => {
        success(`Progress Monitoring Assessment Deleted.`);
        setShowConfirmDeleteModal(false);
        queryClient.invalidateQueries([QueryKey.GetStudentProgressMonitoringAssessmentDetails, assessmentId]);
        onClose();
      },
      onError: (err) => {
        console.error(err);
        error(`An error occurred while deleting the Hoot Assessment.`);
      },
    });
  };

  const CloseButton = () => {
    return (
      <Button type="button" color="inherit" variant="outlined" onClick={onClose}>
        <Box
          sx={{
            paddingX: 2,
            paddingY: 1,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 500,
          }}
        >
          Close
        </Box>
      </Button>
    );
  };

  const DeleteButton = () => {
    return (
      <Button type="button" variant="contained" color="error" onClick={openConfirmDeleteModal}>
        <Box
          sx={{
            paddingX: 2,
            paddingY: 1,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 500,
          }}
        >
          Delete
        </Box>
      </Button>
    );
  };

  return (
    <>
      <Dialog
        open={!!assessmentId}
        onClose={onClose}
        PaperComponent={(props) => <Paper {...props} style={{ borderRadius: '20px', maxHeight: '90vh', maxWidth: '95vw' }} />}
      >
        <DialogTitle
          id="dialog-title"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box component="span" sx={{ fontSize: '24px', lineHeight: '32px' }}>
            Progress Monitoring Assessment Details
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '24px', paddingBottom: '80px' }}>
          <Box sx={{ backgroundColor: '#EFF7F8' }}>
            <ProgressMonitoringAssessmentDetailsPanels assessment={data} />
          </Box>
        </DialogContent>
        <Divider />
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '16px 24px',
            boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
            gap: 2,
          }}
        >
          {hasDeleteAssessmentsPermission && <DeleteButton />}
          <CloseButton />
        </Box>
      </Dialog>
      <ConfirmDeleteDialog
        open={showConfirmDeleteModal}
        onCancel={closeConfirmDeleteModal}
        onContinue={onConfirmDeleteAssessment}
        title="Delete Assessment"
        bodyText="This will permanently delete this assessment. Are you sure you want to continue?"
      />
    </>
  );
};

export default ProgressMonitoringAssessmentDetailsModal;
