import { FormControl, FormHelperText, InputLabel, MenuItem } from '@mui/material';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { Select } from '../SelectField';

export interface FormSelectProps {
  label: string;
  labelId: string;
  options: FormSelectOption[];
}

export interface FormSelectOption {
  label: string;
  value: string | number;
}

export interface SelectProps {
  includeEmpty?: boolean;
  emptyLabel?: string;
  includeAll?: boolean;
  allLabel?: string;
  disabled?: boolean;
  errorMessage?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  isFilterDropdown?: boolean;
  size?: 'medium' | 'small';
  required?: boolean;
}

export function FormSelect<T extends FieldValues, TObj extends Path<T>>(props: SelectProps & UseControllerProps<T, TObj> & FormSelectProps) {
  const {
    label,
    labelId,
    options,
    size,
    errorMessage,
    includeEmpty,
    emptyLabel = 'None',
    includeAll,
    allLabel = 'All',
    variant,
    required,
    ...rest
  } = props;

  return (
    // Probably should've just wrapped this all up in a single FormControlProps object.
    <FormControl fullWidth variant={variant || 'outlined'} size={size} required={required}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select {...rest} labelId={labelId} label={label}>
        {includeEmpty ? (
          <MenuItem value="">
            <span>{emptyLabel}</span>
          </MenuItem>
        ) : null}

        {includeAll ? (
          <MenuItem value="all">
            <span>{allLabel}</span>
          </MenuItem>
        ) : null}

        {options.map((x) => (
          <MenuItem key={`${rest.name}-${x.value}`} value={x.value}>
            {x.label}
          </MenuItem>
        ))}
      </Select>
      {errorMessage ? <FormHelperText error={true}>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
}
