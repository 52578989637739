import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface StudentDistrictResponse {
  id: string;
  name: string;
  friendlyId: string;
  type: LocationType;
}

export interface StudentSchoolResponse {
  id: string;
  name: string;
  friendlyId: string;
  type: LocationType;
  district?: StudentDistrictResponse;
}

const getLocation = async (locationId: string): Promise<StudentSchoolResponse> => {
  const { data } = await axios.get<StudentSchoolResponse>(`${config.apiUrl}/admin/hfs/location/${locationId}`);
  return data;
};

export default function useGetLocation(
  locationId: string,
  options?: Omit<UseQueryOptions<StudentSchoolResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetLocation, locationId], () => getLocation(locationId), options);
}
