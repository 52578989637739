import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import React from 'react';
import ErrorIcon from '@hoot/components/icons/ErrorIcon';

const WarningModal = (props: {
  title: string;
  subTitle: JSX.Element;
  warningText: JSX.Element;
  open: boolean;
  onCancel: () => void;
  primaryAction: {
    label: string;
    onClick: () => void;
    props?: ButtonProps;
    autoFocus?: boolean;
  };
  secondaryAction: {
    label: string;
    onClick: () => void;
    props?: ButtonProps;
    autoFocus?: boolean;
  };
}) => {
  const { title, subTitle, warningText, open, onCancel, primaryAction, secondaryAction } = props;

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'xs'}>
      <Stack p={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
            <ErrorIcon />
            <Typography variant="titleLarge">{title}</Typography>
            <IconButton onClick={onCancel}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Stack mt={3}>
            {subTitle}
            <Stack mt={2} p={2} rowGap={2} sx={{ background: 'rgba(179, 38, 30, 0.2)', borderRadius: '4px' }}>
              {warningText}
            </Stack>
          </Stack>
        </DialogContent>
        <Divider sx={{ mt: 3 }} />
        <DialogActions sx={{ padding: 0 }}>
          <Stack p={1} mt={3} gap={2} direction="row" justifyContent="flex-end">
            <Button
              onClick={secondaryAction.onClick}
              variant="outlined"
              size="large"
              {...(secondaryAction.props ?? {})}
              autoFocus={secondaryAction.autoFocus ?? false}
            >
              {secondaryAction.label}
            </Button>
            <Button
              onClick={primaryAction.onClick}
              variant="contained"
              size="large"
              {...(primaryAction.props ?? {})}
              autoFocus={primaryAction.autoFocus ?? false}
            >
              {primaryAction.label}
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default WarningModal;
