import { ArrowDropDown } from '@mui/icons-material';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';

const ActionsButton = (props: { setShowTRSImport: Dispatch<SetStateAction<boolean>> }) => {
  const { setShowTRSImport } = props;
  const [actionAnchorEl, setActionAnchorEl] = useState<Element | null>(null);

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleActionPopoverClose = () => {
    setActionAnchorEl(null);
  };

  const handleOpenTRS = () => {
    setShowTRSImport(true);
    setActionAnchorEl(null);
  };

  return (
    <>
      <Button type="button" style={{ height: 36 }} startIcon={<ArrowDropDown />} color="inherit" variant="outlined" onClick={handleActionClick}>
        <Typography variant="labelLarge" color="black">
          Actions
        </Typography>
      </Button>
      <Menu
        open={Boolean(actionAnchorEl)}
        anchorEl={actionAnchorEl}
        onClose={handleActionPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ marginTop: 1 }}
      >
        <MenuItem onClick={handleOpenTRS}>
          <Typography variant="titleSmall" color="black" width={200}>
            Import TRS
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ActionsButton;
