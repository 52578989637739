export enum TeacherTabs {
  Details = 'DETAILS',
  Schedule = 'SCHEDULE',
  Availability = 'AVAILABILITY',
  AvailabilityExceptions = 'AVAILABILITY_EXCEPTIONS',
  Reliability = 'RELIABILITY',
  Tasks = 'TASKS',
  History = 'HISTORY',
}

export enum InvoiceRateTier {
  Tier1 = 'TIER_1',
  Tier2 = 'TIER_2',
  Tier3 = 'TIER_3',
  Tier4 = 'TIER_4',
  Tier5 = 'TIER_5',
  Tier6 = 'TIER_6',
  Tier7 = 'TIER_7',
  Tier8 = 'TIER_8',
  Tier9 = 'TIER_9',
  Tier10 = 'TIER_10',
  Tier11 = 'TIER_11',
  Tier12 = 'TIER_12',
  Tier13 = 'TIER_13',
  Tier14 = 'TIER_14',
  Tier15 = 'TIER_15',
}
