import { CircularProgress, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { DateFormats } from '@hoot/common/constants';
import DeleteBinIcon from '@hoot/components/icons/DeleteBinIcon';
import StatusChip, { StatusChipIcon, StatusChipIconColor } from '@hoot/components/ui/StatusChip';
import { HeaderData, Table } from '@hoot/components/ui/Table';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import { EnrolmentPeriodResponse } from '@hoot/hooks/api/hfs/useGetEnrolmentPeriods';

interface EnrolmentPeriodTableProps {
  periodData: EnrolmentPeriodResponse[] | undefined;
  periodDataLoading: boolean;
  setOpen: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface EnrolmentPeriodRow {
  type: string;
  startDate: string;
  endDate: string;
  status: React.ReactNode;
  deleteBtn: React.ReactNode;
}

const headers: HeaderData<EnrolmentPeriodRow>[] = [
  { name: 'Type', property: 'type' },
  { name: 'Starts', property: 'startDate' },
  { name: 'Ends', property: 'endDate' },
  { name: 'Status', property: 'status' },
  { name: '', property: 'deleteBtn', width: '5%' },
];

enum EnrolmentPeriodStatus {
  SCHEDULED = 'Scheduled',
  COMPLETE = 'Complete',
}

const EnrolmentPeriodTable = (props: EnrolmentPeriodTableProps) => {
  const { scopes } = useAuth();
  const canViewDistrictDetails = !!scopes.find((s) => s === HootEmployeeScope.ViewDistrictDetails);

  const now = DateTime.now();
  const endOfDay = now.endOf('day').toMillis();

  const isStatusComplete = (endDate: number) => endDate <= now.toMillis(); // Anything in the past is considered "Complete".
  const isDeletable = (startDate: number) => canViewDistrictDetails && startDate > endOfDay; // Can only delete future Periods.

  const handleDeletePeriod = (periodId: string) => {
    props.setOpen(periodId);
  };

  const tableData =
    props.periodData?.map<EnrolmentPeriodRow>((x) => ({
      type: x.type,
      startDate: DateTime.fromMillis(x.startDate).toFormat(DateFormats.NUMERIC_MDY),
      endDate: DateTime.fromMillis(x.endDate).toFormat(DateFormats.NUMERIC_MDY),
      status: (
        <StatusChip
          size="small"
          statusIcon={isStatusComplete(x.endDate) ? StatusChipIcon.FullCircle : StatusChipIcon.PartialCircle}
          iconColor={isStatusComplete(x.endDate) ? StatusChipIconColor.Green : StatusChipIconColor.Blue}
          label={isStatusComplete(x.endDate) ? EnrolmentPeriodStatus.COMPLETE : EnrolmentPeriodStatus.SCHEDULED}
        />
      ),
      deleteBtn: isDeletable(x.startDate) ? (
        <IconButton onClick={() => handleDeletePeriod(x.id)}>
          <DeleteBinIcon />
        </IconButton>
      ) : null,
    })) ?? [];

  return (
    <div>
      {props.periodDataLoading ? (
        <Stack flexDirection="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Table headers={headers} data={tableData} stripedRows />
      )}
    </div>
  );
};

export default EnrolmentPeriodTable;
