import { ShiftUtilizationLesson, ShiftUtilizationShift } from '@hoot/hooks/api/shifts/useGetShiftUtilization';
import { StatusColours } from '../users/components/user-schedule/weekly-schedule/enums';
import { HoverColor } from './shift-constants';
import { ShiftTimeSlot } from './shift-interfaces';

export const timeSlotIsWithinShift = (shifts: ShiftUtilizationShift[], timeSlot: ShiftTimeSlot) => {
  return !!shifts.find((s) => timeSlot.start >= s.startsAt && timeSlot.end <= s.endsAt);
};

export const shiftStarts = (shifts: ShiftUtilizationShift[], timeSlot: ShiftTimeSlot) => {
  return !!shifts.filter((s) => s.startsAt >= timeSlot.start && s.startsAt < timeSlot.end).length;
};

export const shiftEnds = (shifts: ShiftUtilizationShift[], timeSlot: ShiftTimeSlot) => {
  return !!shifts.filter((s) => s.endsAt > timeSlot.start && s.endsAt <= timeSlot.end).length;
};

export const timeSlotLessons = (lessons: ShiftUtilizationLesson[], timeSlot: ShiftTimeSlot) => {
  return lessons.filter((l) => l.startsAt >= timeSlot.start && l.startsAt < timeSlot.end);
};

export const timeSlotShifts = (shifts: ShiftUtilizationShift[], timeSlot: ShiftTimeSlot) => {
  return shifts.filter((s) => shiftStarts([s], timeSlot) || shiftEnds([s], timeSlot) || timeSlotIsWithinShift([s], timeSlot));
};

export const lessonHoverColor = (color: StatusColours): HoverColor => {
  if (color === StatusColours.Completed) {
    return HoverColor.Completed;
  } else if (color === StatusColours.Scheduled) {
    return HoverColor.Scheduled;
  } else {
    return HoverColor.Cancelled;
  }
};
