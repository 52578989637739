import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { CancelOutlined, Close, Search } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { Dispatch, Fragment, SetStateAction, useEffect, useRef, useState } from 'react';
import WarningModal from '@hoot/components/modals/WarningModal';
import useGetDistrictReps from '../../hooks/api/hfs/useGetDistrictReps';
import { useDebounce } from '../../hooks/useDebounce';
import SchoolsDistrictsCheckBoxTable from '../SchoolsDistrictsCheckBoxTable';
import SelectedFilterChips from '../SelectedFilterChips';
import FilterSelects, { IFilter, IFilterSelection } from '../form/searchFilters/FilterSelects';
import { IDistrictSchoolsCheckBoxRow } from '../hfs-interfaces';

const FILTERS_DATA: IFilter[] = [
  {
    label: 'Type',
    options: [{ label: 'District' }, { label: 'School' }],
  },
  {
    label: 'Country',
    options: [{ label: 'Canada' }, { label: 'United States' }],
  },
];

const filtersDataWithSection = (isStudent?: boolean): IFilterSelection[] =>
  FILTERS_DATA.filter((fl: IFilter) => (isStudent ? !['Type'].includes(fl.label) : true)).map((fl: IFilter) => ({
    ...fl,
    values: fl.options.map((o) => ({ ...o, isSelected: false })),
  }));

const transformFiltersData = (filtersData: IFilterSelection[]): string[] =>
  filtersData
    .flatMap((fl) => fl.values.filter((v) => v.isSelected).map((v) => ({ name: fl.label, value: v.label })))
    .map((v) => `${v.name}: ${v.value}`);

const getModalLabelType = (isStudentMode: boolean, locationType?: LocationType): string => {
  const isDistrict = locationType === LocationType.District;
  if (!isStudentMode) {
    return 'School or District';
  }
  if (isDistrict) {
    return 'District';
  }
  return 'School';
};

const AddLocationModal = (props: {
  isStudentMode: boolean;
  locationType?: LocationType;
  nameToAssociate?: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  onApply: (selectedRow: IDistrictSchoolsCheckBoxRow) => void;
  onClose?: () => void;
  districtRepAccountId?: string;
}) => {
  const { isStudentMode, locationType, nameToAssociate, setOpen, open, onApply, districtRepAccountId } = props;
  const [filtersData, setFiltersData] = useState(filtersDataWithSection(isStudentMode));
  const selectedValues = transformFiltersData(filtersData);
  const [activeFilters, setActiveFilters] = useState<string[]>(selectedValues);
  const [searchText, setSearchText] = useDebounce();
  const inputRef = useRef<HTMLInputElement>();
  const [selectedRow, setSelectedRow] = useState<IDistrictSchoolsCheckBoxRow>({} as IDistrictSchoolsCheckBoxRow);
  const isDistrict = locationType === LocationType.District;
  const [openDistrictConfirmationModal, setOpenDistrictConfirmationModal] = useState(open && isDistrict);
  const [showModal, setShowModal] = useState(isDistrict ? !openDistrictConfirmationModal : open);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const modalType = getModalLabelType(isStudentMode, locationType);

  const { data: districtRepsResponseData } = useGetDistrictReps({
    page: 1,
    pageSize: 10,
    districtRepresentativeAccountId: districtRepAccountId,
  });

  useEffect(() => {
    const selectedValues = transformFiltersData(filtersData);
    setActiveFilters(selectedValues);
  }, [filtersData]);

  const nameToDisplay = (): string => {
    if (nameToAssociate) {
      return nameToAssociate;
    }

    return (districtRepsResponseData && districtRepsResponseData.districtReps[0].name) ?? '';
  };

  //Modal Functions
  const handleClose = () => {
    setOpen(false);
  };

  const handleApply = () => {
    const selectedId = selectedRow.id;
    if (!selectedId) {
      return;
    }

    //shows confirmation modal
    if (districtRepAccountId || isStudentMode) {
      setOpenConfirmationModal(true);
      setShowModal(false);
    }
  };

  //District Confirmation functions
  const handleDistrictConfirmation = () => {
    setOpenDistrictConfirmationModal(false);
    setShowModal(true);
  };

  const handleCloseDistrictConfirmation = () => {
    setOpenDistrictConfirmationModal(false);
    handleClose();
  };

  //Location Confirmation functions
  const handleCloseConfirmation = () => {
    setOpenConfirmationModal(false);
  };

  const handleGoBack = () => {
    setOpenConfirmationModal(false);
    setShowModal(true);
  };

  const handleConfirm = () => {
    onApply(selectedRow);
  };

  const handleClear = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
      setSearchText('');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <>
      <Dialog open={showModal} fullWidth={true} maxWidth={'lg'} onClose={handleClose}>
        <Box
          sx={{
            backgroundColor: 'white',
            display: 'flex',
            gap: '24px',
            flexDirection: 'column',
            p: 3,
            minWidth: '800px',
            height: '800px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center',
              }}
            >
              <Box sx={{ textAlign: 'left', fontSize: '24px' }}>Add {modalType}</Box>
              <Close sx={{ '&:hover': { cursor: 'pointer' }, mr: '22px' }} onClick={handleClose} />
            </Box>
            <Box>
              <TextField
                inputRef={inputRef}
                defaultValue={searchText}
                onChange={handleInputChange}
                variant={'outlined'}
                label={`Search for ${modalType}`}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search style={{ fill: '#49454F' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClear} disabled={!searchText}>
                        <CancelOutlined
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                          style={{ fill: '#49454F' }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                textTransform: 'none',
                display: 'flex',
              }}
            >
              <FilterSelects filterSelections={filtersData} setFilterSelections={setFiltersData} />
            </Box>

            <Box
              sx={{
                alignSelf: 'center',
                display: 'flex',
                color: '#49454F',
                width: '100%',
              }}
            >
              <SelectedFilterChips
                activeFilters={activeFilters}
                selectedValues={selectedValues}
                filtersData={filtersData}
                setActiveFilters={setActiveFilters}
                setFiltersData={setFiltersData}
              />
            </Box>

            <Box>
              <SchoolsDistrictsCheckBoxTable
                searchText={searchText}
                activeFilters={activeFilters}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                districtRepAccountId={districtRepAccountId}
                isStudentMode={isStudentMode}
                locationType={locationType}
              />
            </Box>
          </Box>
          <Box
            sx={{
              textAlign: 'right',
              pb: 3,
              gap: 2,
              display: 'flex',
              justifyContent: 'flex-end',
              pt: 3,
              borderTop: '1px solid #757575',
              position: 'sticky',
              bottom: '0',
              backgroundColor: 'rgb(255,255,255, 0.8)',
              backdropFilter: 'blur(2px)',
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                padding: '0px',
                gap: '8px',
                p: 1,
                px: 3,
                textTransform: 'none',
                backgroundColor: 'white',
                border: '1px solid #79747E',
                borderRadius: '20px',
                fontWeight: '600',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#f2f2f2',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!selectedRow?.id}
              onClick={handleApply}
              sx={{
                padding: '0px',
                gap: '8px',
                p: 1,
                px: 3,
                color: 'white',
                textTransform: 'none',
                background: '#1976D2',
                ':disabled': {
                  backgroundColor: 'gray',
                  color: 'white',
                },
                borderRadius: '100px',
                fontWeight: '600',
                '&:hover': {
                  backgroundColor: '#1976D2',
                },
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Dialog>
      <WarningModal
        title="Warning: Associating to District"
        subTitle={
          <Fragment>
            <Typography variant="bodyLarge">You have selected to associate a</Typography>
            <Typography variant="titleMedium">
              District <span style={{ fontWeight: 400 }}>to</span> {nameToDisplay()}
            </Typography>
          </Fragment>
        }
        warningText={
          <Fragment>
            <Typography variant="bodyMedium">You will not be able to associate this student to a school after associating to a district.</Typography>
            <Typography variant="bodyMedium">Please confirm this student will not require a school association first.</Typography>
          </Fragment>
        }
        open={openDistrictConfirmationModal}
        onCancel={handleCloseDistrictConfirmation}
        primaryAction={{
          label: 'Confirm',
          onClick: handleDistrictConfirmation,
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: handleCloseDistrictConfirmation,
        }}
      />
      <WarningModal
        title="Potential Data Privacy Risk"
        subTitle={
          <Fragment>
            <Typography variant="bodyLarge">You have selected to associate</Typography>
            <Typography variant="titleMedium">
              {selectedRow?.name} <span style={{ fontWeight: 400 }}>to</span> {nameToDisplay()}
            </Typography>
          </Fragment>
        }
        warningText={
          <Fragment>
            <Typography variant="bodyMedium">An incorrect association may share student data with unauthorized parties.</Typography>
            <Typography variant="bodyMedium">Please double-check before confirmation.</Typography>
          </Fragment>
        }
        open={openConfirmationModal}
        onCancel={handleCloseConfirmation}
        primaryAction={{
          label: 'Confirm',
          onClick: handleConfirm,
        }}
        secondaryAction={{
          label: 'Go back',
          onClick: handleGoBack,
        }}
      />
    </>
  );
};

export default AddLocationModal;
