import { UserImportMode } from '@hoot-reading/hoot-core/dist/enums/user/user-import/user-import-mode.enum';
import { UserImportStatus } from '@hoot-reading/hoot-core/dist/enums/user/user-import/user-import-status.enum';
import { UserImportType } from '@hoot-reading/hoot-core/dist/enums/user/user-import/user-import-type.enum';
import { AttachFile, CancelOutlined, CheckCircleOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAlert } from '@hoot/contexts/AlertContext';
import useUserImportCsv, { UserImportResponse } from '@hoot/hooks/api/hfs/district-schools/useUserImportCsv';
import useUserResumeImport, { UserResumeImportPayload } from '@hoot/hooks/api/hfs/district-schools/useUserResumeImport';
import theme from '@hoot/theme';
import { ImportUserStep } from './UserImportDialog';

interface IUserImportUploadFileProps {
  previousStep: () => void;
  nextStep: (step: ImportUserStep) => void;
  handleClose: () => void;
  importType: UserImportType | undefined;
  setFileNameCallback: React.Dispatch<React.SetStateAction<string>>;
  uploadedImport: UserImportResponse | undefined;
  setImportResponseCallback: React.Dispatch<React.SetStateAction<UserImportResponse | undefined>>;
  enrolmentId: string;
}

const UserImportUploadFile = (props: IUserImportUploadFileProps) => {
  const { previousStep, nextStep, handleClose, importType, setFileNameCallback, uploadedImport, setImportResponseCallback, enrolmentId } = props;

  const { handleSubmit } = useForm();
  const { mutate, isLoading: isUploading } = useUserImportCsv();
  const resumeImport = useUserResumeImport();
  const alert = useAlert();

  const [inputFileError, setInputFileError] = useState<string>('');
  const [isProcessFileEnabled, setIsProcessFileEnabled] = useState<boolean>(false);
  const [disableBackButton, setDisableBackButton] = useState<boolean>(false);
  const [isNextStepEnabled, setIsNextStepEnabled] = useState<boolean>(false);
  const [overwriteIgnore, setOverwriteIgnore] = useState<UserImportMode | undefined>();
  const [awaitingConfirmation, setAwaitingConfirmation] = useState<boolean>(false);
  const [showSuccessAlerts, setShowSuccessAlerts] = useState({
    regularImport: false,
    resumeImport: false,
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  const inputHasValue = !!fileInputRef.current?.files?.length;

  const handleClear = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsProcessFileEnabled(false);
    clearFileInput();
    setFileNameCallback('');
    setInputFileError('');
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onUpload = () => {
    if (!inputHasValue || !importType) {
      return;
    }

    if (fileInputRef.current.files?.[0].type !== 'text/csv') {
      setInputFileError('There was an error in the upload. Please check the file');
      return;
    }

    setFileNameCallback(fileInputRef.current.files[0].name);

    const uploadFile = fileInputRef.current?.files[0];
    const formData = new FormData();
    formData.append('file', uploadFile);
    formData.append('importType', importType);
    formData.append('enrolmentId', enrolmentId);

    mutate(formData, {
      onSuccess: (res) => {
        if (res.status === UserImportStatus.Started || res.status === UserImportStatus.Completed) {
          setIsNextStepEnabled(true);
          setDisableBackButton(true);
          setShowSuccessAlerts((prev) => {
            return { ...prev, regularImport: true };
          });
        }

        if (res.status === UserImportStatus.AwaitingConfirmation) {
          setAwaitingConfirmation(true);
        }
      },
      onError: (err) => {
        console.error(err);
        setInputFileError(err.response?.data?.message ?? 'Something went wrong, check the csv and try again.');
      },
      onSettled: (res) => setImportResponseCallback(res),
    });
  };

  const onResumeImport = () => {
    if (!uploadedImport?.id || !overwriteIgnore) return;

    const payload: UserResumeImportPayload = {
      importId: uploadedImport.id,
      importMode: overwriteIgnore,
    };

    resumeImport.mutate(payload, {
      onSuccess: () => {
        setIsNextStepEnabled(true);
        setDisableBackButton(true);
        setAwaitingConfirmation(false);
        setShowSuccessAlerts((prev) => {
          return { ...prev, resumeImport: true };
        });
      },
      onError: (err) => {
        console.error(err);
        alert.error('Something went wrong, check the csv and try again.');
      },
    });
  };

  const ClearButton = () => (
    <InputAdornment position="end">
      <IconButton onClick={(e) => handleClear(e)}>
        <CancelOutlined sx={{ cursor: 'pointer' }} />
      </IconButton>
    </InputAdornment>
  );

  const disableFileInput = awaitingConfirmation || showSuccessAlerts.regularImport || showSuccessAlerts.resumeImport;

  return (
    <>
      <DialogContent sx={{ p: 0, mt: 2 }}>
        <Typography variant="titleMedium" color="#000">
          Step 2 - Upload File
        </Typography>

        <Box sx={{ pt: 3, opacity: disableFileInput ? 0.4 : 1 }}>
          <form onSubmit={handleSubmit(onUpload)} encType="multipart/form-data">
            <Stack flexDirection="row" alignItems="center" gap={2}>
              <TextField
                type="file"
                name="file"
                inputRef={fileInputRef}
                onChange={() => setIsProcessFileEnabled(true)}
                label="File"
                disabled={disableFileInput}
                helperText={inputFileError || 'Must be a CSV file'}
                error={!!inputFileError}
                InputProps={{
                  startAdornment: inputHasValue && <AttachFile />,
                  endAdornment: inputHasValue && <ClearButton />,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: 400,
                  '& input::file-selector-button': {
                    display: 'none',
                  },
                  '& .MuiInputBase-input': {
                    pl: theme.spacing(2),
                  },
                }}
              />

              <Box sx={{ display: 'flex', position: 'relative', bottom: 11, gap: 2 }}>
                {!inputHasValue && (
                  <Button variant="outlined" size="large" onClick={(_) => fileInputRef.current?.click()}>
                    Select File
                  </Button>
                )}

                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={!isProcessFileEnabled || isUploading || disableFileInput}
                  loading={isUploading || awaitingConfirmation}
                >
                  Process File
                </LoadingButton>
              </Box>
            </Stack>
          </form>
        </Box>

        {/* Duplicate Entry message */}
        {awaitingConfirmation && (
          <Fade in={awaitingConfirmation} timeout={400} unmountOnExit>
            <Stack direction="column" sx={{ mt: 3, p: 3, backgroundColor: '#FFF7ED', borderRadius: '8px' }}>
              <Typography variant="titleMedium" sx={{ mb: theme.spacing(1) }}>
                This file includes users that already exist in the Admin Portal. Do you want to overwrite their information?
              </Typography>
              <FormControl>
                <RadioGroup
                  onChange={(_, value) => setOverwriteIgnore(value as UserImportMode)}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                >
                  <FormControlLabel value={UserImportMode.Overwrite} control={<Radio />} label="Yes, overwrite existing information" />
                  <FormControlLabel
                    value={UserImportMode.IgnoreExisting}
                    control={<Radio />}
                    label="No, do not upload information for existing users"
                  />
                </RadioGroup>
                <LoadingButton
                  onClick={() => onResumeImport()}
                  loading={resumeImport.isLoading}
                  variant="contained"
                  size="large"
                  sx={{ width: '80px', mt: 2 }}
                >
                  Apply
                </LoadingButton>
              </FormControl>
            </Stack>
          </Fade>
        )}

        {/* File Successfully Uploaded message  */}
        {(showSuccessAlerts.regularImport || showSuccessAlerts.resumeImport) && (
          <Fade in={showSuccessAlerts.regularImport || showSuccessAlerts.resumeImport} timeout={400} unmountOnExit>
            <Stack direction="row" sx={{ mt: 3, p: 2, gap: 2, backgroundColor: '#EDF7ED', borderRadius: '8px' }}>
              <CheckCircleOutline htmlColor="#2E7D32" />
              <Stack direction="column" gap={1}>
                <Typography variant="titleMedium">File successfully uploaded!</Typography>
                <Typography variant="bodySmall">
                  {showSuccessAlerts.regularImport && 'No user conflicts were found.'}
                  {showSuccessAlerts.resumeImport && 'Click Next Step to review summary of upload'}
                </Typography>
              </Stack>
            </Stack>
          </Fade>
        )}
      </DialogContent>

      <Divider />

      <DialogActions sx={{ p: 0 }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack direction="row">
            <Button onClick={previousStep} disabled={disableBackButton} color="inherit" variant="outlined" size="large">
              Back
            </Button>
          </Stack>

          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button onClick={handleClose} color="inherit" variant="outlined" size="large">
              Close
            </Button>
            <Button
              onClick={() => nextStep(ImportUserStep.Summary)}
              variant="contained"
              size="large"
              disabled={!isNextStepEnabled || (awaitingConfirmation && overwriteIgnore === undefined)}
            >
              Next
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  );
};

export default UserImportUploadFile;
