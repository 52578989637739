import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { BlockUtilizationLesson } from '@hoot/hooks/api/blocks/useGetBlockUtilization';
import { BlockTimeSlot } from '../block-interfaces';
import SubRequiredDetailsDialog from './dialogs/SubstituteMenuDialog';
import TimeSlot from './timeslots/TimeSlot';

interface INeedSubsTableRowProps {
  timeSlots: BlockTimeSlot[];
  subRequiredLessons: BlockUtilizationLesson[];
}

export interface SelectedSubNeededTimeSlot {
  lessons: BlockUtilizationLesson[];
  timeSlotStartTime: number;
  timeSlotEndTime: number;
}

const NeedSubsTableRow = (props: INeedSubsTableRowProps) => {
  const { timeSlots, subRequiredLessons } = props;
  const [selectedSubRequiredTimeSlot, setSelectedSubRequiredTimeSlot] = useState<SelectedSubNeededTimeSlot | undefined>(undefined);

  const handleSubRequiredClick = (timeSlot: BlockTimeSlot) => {
    const selectedTimeSlot: SelectedSubNeededTimeSlot = {
      lessons: timeSlotLessons(timeSlot),
      timeSlotStartTime: timeSlot.start,
      timeSlotEndTime: timeSlot.end,
    };
    setSelectedSubRequiredTimeSlot(selectedTimeSlot);
  };

  const handleCloseSubRequiredDetails = () => {
    setSelectedSubRequiredTimeSlot(undefined);
  };

  const timeSlotLessons = (timeSlot: BlockTimeSlot) => {
    const lessonsWithinTimeSlot = subRequiredLessons.filter((lesson) => lesson.startsAt >= timeSlot.start && lesson.startsAt < timeSlot.end);
    return lessonsWithinTimeSlot;
  };

  return (
    <>
      <Stack direction="row" sx={{ height: '65px' }}>
        {timeSlots.map((t, idx) => (
          <TimeSlot key={`${t.start}-${idx}`} timeSlot={t}>
            {timeSlotLessons(t).length > 0 ? (
              <Button
                variant="contained"
                sx={{
                  background: '#F9B6A7',
                  height: '42px',
                  borderRadius: 1,
                  '&:hover': {
                    background: '#E0A396',
                  },
                  fontSize: '12px',
                  color: 'black',
                  gap: '4px',
                }}
                onClick={() => handleSubRequiredClick(t)}
              >
                Need Sub
                {timeSlotLessons(t).length > 1 ? (
                  <Typography
                    variant="titleSmall"
                    sx={{
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      background: '#BA1A1A',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      fontSize: '10px',
                    }}
                  >
                    {timeSlotLessons(t).length}
                  </Typography>
                ) : null}
              </Button>
            ) : null}
          </TimeSlot>
        ))}
      </Stack>
      <SubRequiredDetailsDialog
        open={!!selectedSubRequiredTimeSlot}
        onClose={handleCloseSubRequiredDetails}
        selectedSubRequiredTimeSlot={selectedSubRequiredTimeSlot}
      />
    </>
  );
};

export default NeedSubsTableRow;
