import axios, { AxiosError } from 'axios';
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';
import { HistoryItemsResponse } from '@hoot/interfaces/history';
import { QueryKey } from '../queryKeys';

async function getQualityAssuranceTaskHistory(qualityAssuranceTaskId: string): Promise<HistoryItemsResponse> {
  const { data } = await axios.get(`${config.apiUrl}/admin/quality-assurance-tasks/${qualityAssuranceTaskId}/history`);
  return data;
}

export default function useGetQualityAssuranceTaskHistory(
  qualityAssuranceTaskId: string,
  options?: Omit<UseQueryOptions<HistoryItemsResponse, AxiosError<ErrorResponseDto>>, 'queryKey' | 'queryFn'>,
): UseQueryResult<HistoryItemsResponse, AxiosError> {
  return useQuery(
    [QueryKey.GetQualityAssuranceTaskHistory, qualityAssuranceTaskId],
    () => getQualityAssuranceTaskHistory(qualityAssuranceTaskId),
    options,
  );
}
