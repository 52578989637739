import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Checkbox, FormControlLabel, InputAdornment, Stack, TextField } from '@mui/material';
import { SelectedTeacher } from '@hoot/components/modals/SelectTeacher';
import { TentativeBlockForm } from '../teacher-conflicts-step/TeacherConflictCheckStep';

interface ReviewTeacherBlockCardProps {
  teacher: SelectedTeacher;
  blocks: TentativeBlockForm[];
}

const ReviewTeacherBlockCard = (props: ReviewTeacherBlockCardProps) => {
  const { teacher, blocks } = props;

  const teacherLabel = teacher ? `${teacher.name} (${teacher.number})` : 'None';

  return (
    <Stack
      sx={{
        borderRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.20)',
        padding: 3,
        gap: 2,
        width: '100%',
      }}
    >
      <TextField
        required
        fullWidth
        label="Teacher"
        value={teacherLabel}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <PermIdentityIcon />
            </InputAdornment>
          ),
        }}
      />
      <Stack>
        {blocks
          .filter((block) => !block.skip)
          .map((block) => (
            <FormControlLabel
              checked
              disabled
              control={<Checkbox />}
              label={`${block.startsAt.toFormat('ccc, LLL d, yyyy @ h:mma')} - ${block.endsAt.toFormat('h:mma')}`}
              key={`${block.startsAt}`}
            />
          ))}
      </Stack>
    </Stack>
  );
};

export default ReviewTeacherBlockCard;
