import { Link, Stack, Typography } from '@mui/material';
import { BlockUtilizationTeacher } from '@hoot/hooks/api/blocks/useGetBlockUtilization';
import { routes } from '@hoot/routes/routes';

interface ITeacherTimeSlotProps {
  teacher?: BlockUtilizationTeacher;
}

const TeacherTimeSlot = (props: ITeacherTimeSlotProps) => {
  const { teacher } = props;
  return (
    <Stack
      sx={{
        borderBottom: '1px solid #CAC4D0',
        height: '65px',
        justifyContent: 'center',
        '&:last-child': { borderBottom: 'none' },
      }}
    >
      {teacher ? (
        <>
          <Link href={routes.users.teachers.details.url(teacher.id)}>
            <Typography variant="titleMedium" sx={{ textDecoration: 'underline', color: '#1976D2', '&:hover': { cursor: 'pointer' } }}>
              {teacher.prefixedNumber}
            </Typography>
          </Link>
          <Typography variant="bodyMedium" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {teacher.name}
          </Typography>
        </>
      ) : null}
    </Stack>
  );
};

export default TeacherTimeSlot;
