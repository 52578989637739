import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';

async function submitFinalInvoice(periodId: string) {
  await axios.post(`${config.apiUrl}/admin/teacher-invoicing/period-invoice/${periodId}`);
}

export default function useSubmitFinalInvoice(periodId: string) {
  return useMutation<void, AxiosError>(() => submitFinalInvoice(periodId));
}
