import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export enum BlockConflictSeverity {
  Blocker = 'BLOCKER',
  HighRisk = 'HIGH_RISK',
  Caution = 'CAUTION',
}

export interface RequestedBlockForConflictChecker {
  startsAt: number;
  endsAt: number;
}

export interface TeacherBlockConflictsRequest {
  teacherAccountId: string;
  requestedBlocks: RequestedBlockForConflictChecker[];
}

export interface TeacherBlockConflictsResponse {
  id: string;
  number: number;
  startsAt: number;
  endsAt: number;
  severity: BlockConflictSeverity;
  message: string;
}

export async function getTeacherBlockConflicts(request: TeacherBlockConflictsRequest) {
  const { data } = await axios.post<TeacherBlockConflictsResponse[]>(`${config.apiUrl}/admin/teacher-block/conflicts`, request);
  return data;
}

export default function useGetTeacherBlockConflicts(
  conflictsRequest: TeacherBlockConflictsRequest,
  options?: Omit<UseQueryOptions<TeacherBlockConflictsResponse[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.TeacherBlockConflicts, conflictsRequest], () => getTeacherBlockConflicts(conflictsRequest), options);
}
