import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetLessonHistory } from '@hoot/hooks/api/lessons/useGetLesson';
import { UserProfileType } from '@hoot/interfaces/user';
import { routes } from '@hoot/routes/routes';
import { HeaderData, Table } from '../../../components/ui/Table';

interface Props {
  lessonId: string;
}

interface LessonHistoryRow {
  date: React.ReactNode;
  time: React.ReactNode;
  profile: React.ReactNode;
  action: React.ReactNode;
  note: React.ReactNode;
}

const headers: HeaderData<LessonHistoryRow>[] = [
  { property: 'date', name: 'Date', width: '15%' },
  { property: 'time', name: 'Time', width: '15%' },
  { property: 'profile', name: 'Profile', width: '15%' },
  { property: 'action', name: 'Action', width: '15%' },
  { property: 'note', name: 'Note', width: '40%' },
];

enum LessonHisoryTabs {
  KeyEvents = 0,
  AllEvents = 1,
}

const EditLessonHistoryV2 = (props: Props) => {
  const { lessonId } = props;
  const [selectedTab, setSelectedTab] = useState(LessonHisoryTabs.KeyEvents);

  const getAllEvents = selectedTab === LessonHisoryTabs.AllEvents;

  const getLessonHistoryRequest = useGetLessonHistory(lessonId, getAllEvents);

  const buildUserProfileDetailsLink = (profileType: UserProfileType, profileId: string): string => {
    switch (profileType) {
      case UserProfileType.Teacher:
        return routes.users.teachers.details.url(profileId);
      case UserProfileType.Student:
        return routes.users.students.details.url(profileId);
      case UserProfileType.Parent:
        return routes.users.parents.details.url(profileId);
      default:
        return routes.home.url;
    }
  };

  const data: LessonHistoryRow[] =
    getLessonHistoryRequest.data?.map<LessonHistoryRow>((h) => ({
      date: DateTime.fromMillis(h.date).toFormat('LLL dd, yyyy'),
      time: DateTime.fromMillis(h.date).toFormat('h:mm:ss a'),
      profile:
        h.profileType && h.profileId ? (
          <Link to={buildUserProfileDetailsLink(h.profileType, h.profileId)}>{h.profileName}</Link>
        ) : (
          h.profileName || h.user || 'N/A'
        ),
      action: h.action,
      note: h.note,
    })) ?? [];

  return (
    <Stack>
      <Typography variant="headlineLarge">Lesson Events</Typography>

      <Tabs value={selectedTab} onChange={(event, tab: LessonHisoryTabs) => setSelectedTab(tab)} sx={{ marginBottom: '64px' }}>
        <Tab sx={{ minWidth: '50%' }} label="Key Events" value={LessonHisoryTabs.KeyEvents} />
        <Tab sx={{ minWidth: '50%' }} label="All Events" value={LessonHisoryTabs.AllEvents} />
      </Tabs>

      <Table headers={headers} data={data} isLoading={getLessonHistoryRequest.isFetching} />
    </Stack>
  );
};

export default EditLessonHistoryV2;
