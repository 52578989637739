import { AssessmentReportStatus } from '@hoot-reading/hoot-core/dist/enums/hoot-assessment/assessment-reporting';
import { Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { capitalCase } from 'change-case';
import React, { Fragment, useState } from 'react';
import { InstructionalFocusModule } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentHootLevelReport';

export const unitStatusLookup: Record<AssessmentReportStatus, string> = {
  BYPASSED: 'Passed',
  MASTERED: 'Mastered',
  NEEDS_INSTRUCTION: 'Needs Instruction',
  NEEDS_REVIEW: 'Needs Review',
  NOT_STARTED: 'Not Started',
};

const notAssessedValues = [AssessmentReportStatus.NotStarted, AssessmentReportStatus.Bypassed];

const FiltersCard = (props: { filters: AssessmentReportStatus[]; setFilters: React.Dispatch<React.SetStateAction<AssessmentReportStatus[]>> }) => {
  const { filters, setFilters } = props;

  const handleFiltersChange = (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const value = _e.target.value as AssessmentReportStatus;
    setFilters(checked ? [...filters, value] : filters.filter((filter) => filter !== value));
  };

  const handleFilterNotAssessed = (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilters(checked ? [...filters, ...notAssessedValues] : filters.filter((filter) => !notAssessedValues.includes(filter)));
  };

  return (
    <Card sx={{ p: 3, pb: 0, backgroundColor: '#F2F2F2' }}>
      <CardHeader sx={{ p: 0, pb: 2 }} title={<Typography variant="titleMedium">Display Settings</Typography>} />
      <CardContent sx={{ p: 0 }}>
        <Stack direction="row" gap={3} justifyContent="flex-start">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleFiltersChange}
                  value={AssessmentReportStatus.Mastered}
                  checked={filters.includes(AssessmentReportStatus.Mastered)}
                  size="small"
                />
              }
              label="Show Mastered"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleFilterNotAssessed}
                  value={AssessmentReportStatus.NotStarted}
                  checked={filters.includes(AssessmentReportStatus.NotStarted)}
                  size="small"
                />
              }
              label="Show Not Assessed"
            />
          </FormGroup>
        </Stack>
      </CardContent>
    </Card>
  );
};

interface Props {
  instructionalFocusModule: InstructionalFocusModule[];
}

const StudentUnitsInProgress = (props: Props) => {
  const { instructionalFocusModule } = props;
  const [selectedStatusFilters, setSelectedStatusFilters] = useState<AssessmentReportStatus[]>([]);

  const unitStatus = (unitStatus: AssessmentReportStatus) => {
    if (notAssessedValues.includes(unitStatus)) {
      return 'Not Assessed';
    }
    return unitStatusLookup[unitStatus];
  };

  return (
    <Card sx={{ p: 2, backgroundColor: '#FAFAFA' }}>
      <CardHeader sx={{ p: 0, pb: 3 }} title={<Typography variant="titleLarge">Units in Progress</Typography>} />
      <CardContent sx={{ p: 0 }}>
        <FiltersCard filters={selectedStatusFilters} setFilters={setSelectedStatusFilters} />
        <Stack py={3}>
          {instructionalFocusModule.map((module) => (
            <Stack key={module.order} py={2}>
              <Typography variant="titleMedium">
                {module.label} ({capitalCase(module.status)})
              </Typography>
              <Divider sx={{ borderColor: '#000' }} />
              {module.units
                .filter((unit) =>
                  [AssessmentReportStatus.NeedsReview, AssessmentReportStatus.NeedsInstruction, ...selectedStatusFilters].includes(unit.status),
                )
                .map((unit) => (
                  <Fragment key={unit.order}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography variant="bodyMedium">{unit.label}</Typography>
                      <Typography variant="titleMedium">{unitStatus(unit.status)}</Typography>
                    </Stack>
                    <Divider sx={{ borderColor: '#000' }} />
                  </Fragment>
                ))}
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default StudentUnitsInProgress;
