import {
  AssessmentType,
  CancellationReason,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonType,
  SubRequired,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';
import { DayOfWeek } from '@hoot/utils/dateTime';
import { ConflictType } from './useGetConflicts';

export interface MassEditLessonsRequest {
  lessonIds: string[];
  status: ScheduledLessonStatus | null;
  lessonStartTime?: string | null;
  cancellationReason: CancellationReason | null;
  subRequired?: SubRequired | null;
  lessonType?: ScheduledLessonType | null;
  assessmentType?: AssessmentType;
  subject?: ScheduledLessonSubject | null;
  comment?: string | null;
  dayOfWeek?: DayOfWeek | null;
}

export interface MassEditResponse {
  isSuccess: boolean;
  conflicts: {
    lessonId?: string;
    lessonStart: number;
    lessonEnd: number;
    message: string;
    conflictType: ConflictType;
    availabilityMessage: string;
  }[];
}

export enum MassLessonEditRequestErrorCode {
  DayOfWeekConflicts = 'DayOfWeekConflicts',
}

async function massUpdateLessons(request: MassEditLessonsRequest): Promise<MassEditResponse> {
  const { data } = await axios.post<MassEditResponse>(`${config.apiUrl}/admin/scheduler/mass-update`, request);
  return data;
}

export default function useMassUpdateLessons(
  options?: Omit<
    UseMutationOptions<MassEditResponse, AxiosError<ErrorResponseDto<MassLessonEditRequestErrorCode, undefined>>, MassEditLessonsRequest>,
    'mutationFn' | 'mutationKey'
  >,
) {
  return useMutation((request) => massUpdateLessons(request), options);
}
