import Close from '@mui/icons-material/Close';
import {
  Breakpoint,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  PaperProps,
  Stack,
  SxProps,
} from '@mui/material';
import { ReactNode } from 'react';

export interface BasicAlertDialogProps {
  show: boolean;
  onDismiss: () => void;
  title: string;
  content: ReactNode;
  showClose?: boolean;
  backgroundSx?: SxProps;
  primaryAction?: {
    label: string;
    onClick: () => void;
    props?: Omit<ButtonProps, 'size'>;
    autoFocus?: boolean;
    dataHootCanaryId?: string;
  };
  secondaryAction?: {
    label: string;
    onClick: () => void;
    props?: Omit<ButtonProps, 'size'>;
    autoFocus?: boolean;
    dataHootCanaryId?: string;
  };
  maxWidth?: Breakpoint;
  dialogSx?: SxProps;
  dialogActionsSx?: SxProps;
  stackDialogActions?: boolean;
  paperProps?: Partial<PaperProps>;
  centerDialogActionButtons?: boolean;
}

const DialogActionsStack = (props: { stackDialogActions: boolean; children: ReactNode }) => {
  if (props.stackDialogActions) {
    return (
      <Stack width="100%" direction="column-reverse" alignItems="stretch" gap="8px">
        {props.children}
      </Stack>
    );
  } else {
    return props.children;
  }
};

const BasicAlertDialog = (props: BasicAlertDialogProps) => {
  const { show, onDismiss, title, content, primaryAction, secondaryAction, maxWidth, dialogActionsSx, stackDialogActions, showClose = true } = props;

  return (
    <Dialog
      slotProps={{
        backdrop: {
          sx: props.backgroundSx,
        },
      }}
      PaperProps={props.paperProps}
      open={show}
      onClose={onDismiss}
      fullWidth
      maxWidth={maxWidth}
    >
      <Stack sx={{ padding: '24px' }} gap={3}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px' }}>
          {title}
          {showClose ? (
            <IconButton onClick={onDismiss}>
              <Close />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '4px', padding: '0px' }}>{content}</DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: 'none', padding: '0px', ...dialogActionsSx }}>
          <DialogActionsStack stackDialogActions={stackDialogActions ?? false}>
            {secondaryAction ? (
              <Button
                data-hoot-canary-id={secondaryAction.dataHootCanaryId}
                onClick={secondaryAction.onClick}
                variant="outlined"
                {...(secondaryAction.props ?? {})}
                autoFocus={secondaryAction.autoFocus ?? false}
                size="large"
              >
                {secondaryAction.label}
              </Button>
            ) : null}

            {primaryAction && (
              <Button
                data-hoot-canary-id={primaryAction?.dataHootCanaryId}
                onClick={primaryAction?.onClick}
                variant="contained"
                {...(primaryAction?.props ?? {})}
                autoFocus={primaryAction?.autoFocus ?? false}
                size="large"
              >
                {primaryAction?.label}
              </Button>
            )}
          </DialogActionsStack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default BasicAlertDialog;
