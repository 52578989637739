import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SelectTeacher, { SelectedTeacher } from '@hoot/components/modals/SelectTeacher';
import useGetTeacherAccount from '@hoot/hooks/api/user/teacher/useGetTeacherAccount';
import { TentativeLessonTeacherAccountFilter } from '@hoot/hooks/api/user/teacher/useSearchTeacherAccounts';
import useGetTeacherBlockConflicts from '../../../../../hooks/api/blocks/useGetTeacherBlockConflicts';
import { BlockConflictSeverity } from '../../../../../hooks/api/blocks/useValidateRescheduleBlocks';
import { BlockConflictMessage } from '../../../../lessons/create-v2/common/ConflictMessage';
import CreateBlockFooter from '../CreateBlockFooter';
import { CreateBlockWizardStepEnum, useCreateBlockContext } from '../context/CreateBlockContextProvider';

export interface TentativeBlockForm {
  startsAt: DateTime;
  endsAt: DateTime;
  skip: boolean;
  conflict?: {
    severity: BlockConflictSeverity;
    message: string;
  };
}

interface Form {
  teacher: SelectedTeacher | null;
  tentativeBlocks: TentativeBlockForm[];
}

const TeacherConflictCheckStep = () => {
  const { goToNextStep, goToPrevStep, goToStep, blockDetails, setBlockDetails } = useCreateBlockContext();

  const { control, handleSubmit, watch, reset, setValue } = useForm<Form>({
    defaultValues: {
      teacher: blockDetails.teacher,
      tentativeBlocks: [],
    },
  });

  // If we are launching the wizard from a teacher's profile, we can populate the teacher now
  const { data: teacherData } = useGetTeacherAccount(blockDetails.urlParamTeacherAccountId ?? '', {
    enabled: !!blockDetails.urlParamTeacherAccountId,
  });

  useEffect(() => {
    if (teacherData) {
      setValue('teacher', {
        id: teacherData.id,
        name: `${teacherData.user.firstName} ${teacherData.user.lastName}`,
        number: `${teacherData.number}`,
      });
    }
  }, [teacherData, setValue]);

  const { teacher, tentativeBlocks } = watch();

  const atLeastOneBlockSelected = tentativeBlocks.filter((block) => !block.skip).length > 0;

  const getConflicts = useGetTeacherBlockConflicts(
    {
      teacherAccountId: teacher?.id ?? '',
      requestedBlocks:
        blockDetails.tentativeBlocks?.map((block) => ({
          startsAt: block.startsAt.toMillis(),
          endsAt: block.endsAt.toMillis(),
        })) ?? [],
    },
    {
      enabled: !!teacher && !!blockDetails?.tentativeBlocks?.length,
      onSuccess: (response) => {
        if (blockDetails?.tentativeBlocks) {
          const tentativeBlocks = blockDetails.tentativeBlocks?.map<TentativeBlockForm>((l) => {
            const conflict = response.find((r) => r.startsAt === l.startsAt.toMillis());
            return {
              startsAt: l.startsAt,
              endsAt: l.endsAt,
              skip: conflict?.severity === BlockConflictSeverity.Blocker || l.skip,
              conflict,
            };
          });
          reset({
            teacher: teacher,
            tentativeBlocks: tentativeBlocks,
          });
        }
      },
    },
  );

  const handlePreviousClick = () => {
    goToPrevStep();
  };

  const onSubmit = (data: Form) => {
    if (teacher) {
      setBlockDetails({
        ...blockDetails!,
        tentativeBlocks: data.tentativeBlocks,
        teacher: teacher,
      });
      goToNextStep();
    }
  };

  const isLoading = getConflicts.isFetching;

  const teacherName = teacher ? teacher.name : 'N/A';
  if (!blockDetails) {
    goToStep(CreateBlockWizardStepEnum.DateAndTime);
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Typography variant="titleMedium">Select Teacher</Typography>
        <Controller
          name="teacher"
          control={control}
          render={({ field }) => (
            <SelectTeacher
              disabled={!!blockDetails.preSelectedTeacher}
              value={field.value}
              onChange={field.onChange}
              tentativePeriods={blockDetails.tentativeBlocks.map<TentativeLessonTeacherAccountFilter>((block) => ({
                startsAt: block.startsAt.toMillis(),
                endsAt: block.endsAt.toMillis(),
              }))}
              periodIsLesson={false}
              showHasConflictFilter={false}
              error={!teacher}
            />
          )}
        />
        {teacher ? (
          <>
            <Typography sx={{ marginTop: '24px' }} variant="titleMedium">
              Select from these available dates for {teacherName}
            </Typography>
            <Controller
              control={control}
              name="tentativeBlocks"
              render={({ field }) => <TentativeBlocks isLoading={isLoading} onChange={field.onChange} value={field.value} />}
            />
          </>
        ) : null}
      </Stack>
      <CreateBlockFooter>
        <Button onClick={handlePreviousClick} variant="contained" size="large">
          Previous Step
        </Button>
        <Stack direction="row" gap={3}>
          <Button type="submit" variant="contained" size="large" disabled={!teacher || !atLeastOneBlockSelected}>
            Next Step
          </Button>
        </Stack>
      </CreateBlockFooter>
    </form>
  );
};

function TentativeBlocks(props: { isLoading?: boolean; value: TentativeBlockForm[]; onChange: (value: TentativeBlockForm[]) => void }) {
  if (props.isLoading) {
    return <CircularProgress />;
  }

  const handleClick = (block: TentativeBlockForm) => {
    const updatedState = props.value.map<TentativeBlockForm>((v) =>
      block.startsAt === v.startsAt
        ? {
            ...v,
            skip: !block.skip,
          }
        : v,
    );
    props.onChange(updatedState);
  };

  return (
    <Stack>
      <Stack sx={{ marginTop: '8px' }} direction="row">
        <Typography sx={{ width: '49px' }} variant="bodySmall">
          Create Block
        </Typography>
        <Typography sx={{ marginLeft: '40px', width: '43px' }} variant="bodySmall">
          Skip Block
        </Typography>
      </Stack>
      <FormGroup sx={{ marginTop: '8px' }}>
        {props.value.map((l) => (
          <TentativeBlockField key={l.startsAt.toMillis()} value={l} onClick={handleClick} />
        ))}
      </FormGroup>
    </Stack>
  );
}

function TentativeBlockField(props: { value: TentativeBlockForm; onClick: (value: TentativeBlockForm) => void }) {
  const canAssignTeacher = props.value.conflict?.severity !== 'BLOCKER';
  const handleClick = () => {
    props.onClick(props.value);
  };

  return (
    <Stack direction="row">
      <FormControlLabel
        disabled={!canAssignTeacher}
        control={
          <Stack direction="row">
            <Checkbox sx={{ marginLeft: '6px' }} disabled={!canAssignTeacher} onClick={handleClick} checked={!props.value.skip} />
            <Checkbox sx={{ marginLeft: '45px' }} disabled={!canAssignTeacher} onClick={handleClick} checked={props.value.skip} />
          </Stack>
        }
        label={
          <Typography variant="bodyLarge" sx={{ marginLeft: '42px' }}>
            {props.value.startsAt.toFormat('ccc, LLL d, yyyy @ h:mma')} - {props.value.endsAt.toFormat('h:mma')}
          </Typography>
        }
      />
      {props.value.conflict ? <BlockConflictMessage severity={props.value.conflict.severity} message={props.value.conflict.message} /> : null}
    </Stack>
  );
}

export default TeacherConflictCheckStep;
