import { TeacherBlockCancellationReason, TeacherBlockStatus } from '@hoot-reading/hoot-core/dist/enums/teacher-block';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';

export interface RescheduleBlocksRequest {
  status: TeacherBlockStatus;
  cancellationReason?: TeacherBlockCancellationReason;
  blocks: RescheduleBlockRequest[];
}

export interface RescheduleBlockRequest {
  blockId: string;
  startsAt: number;
  endsAt: number;
}

async function rescheduleBlocks(request: RescheduleBlocksRequest) {
  await axios.post<void>(`${config.apiUrl}/admin/teacher-block/reschedule`, request);
}

export default function useRescheduleBlocks(
  options?: Omit<UseMutationOptions<void, AxiosError<ErrorResponseDto>, RescheduleBlocksRequest>, 'queryKey' | 'queryFn'>,
) {
  return useMutation((request) => rescheduleBlocks(request), options);
}
