import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';
import useGetEnrolments, { EnrolmentQuery, EnrolmentResponse } from '@hoot/hooks/api/hfs/useGetEnrolments';

const DEFAULT_PAGE_SIZE = 5;
const MAX_PAGE_SIZE = DEFAULT_PAGE_SIZE * 10;

export function EnrolmentAutoComplete(props: { onChange: (value: string | null) => void; value: string | null; size?: 'medium' | 'small' }) {
  const { onChange, value, size } = props;

  const [enrolments, setEnrolments] = useState<EnrolmentResponse[]>([]);
  const [query, setQuery] = useState<EnrolmentQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { isLoading } = useGetEnrolments(query, {
    keepPreviousData: true,
    onSuccess: (data) => {
      setEnrolments(data.enrolments);
    },
  });

  const selectedEnrolment = enrolments.find((e) => e.enrolmentId === value);

  return (
    <Autocomplete
      onChange={(event, val) => {
        if (typeof val === 'object') {
          if (onChange) {
            onChange(val ? (val as EnrolmentResponse).enrolmentId : null);
          }
        }
      }}
      size={size}
      fullWidth
      loading={isLoading}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={enrolments}
      getOptionLabel={(option) => option.friendlyId}
      value={selectedEnrolment || null}
      filterOptions={(x) => x}
      onInputChange={(event, val) => {
        setQuery((current) => ({ ...current, searchString: val, pageSize: !!val ? MAX_PAGE_SIZE : DEFAULT_PAGE_SIZE }));
      }}
      renderInput={(params) => <TextField {...params} label="Enrolment" variant="outlined" />}
    />
  );
}
