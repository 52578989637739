import { Button, DialogActions, DialogContent, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import React from 'react';
import { InvoicingImportType } from '@hoot/pages/teacher-invoices/enums/invoicing-import-type.enum';
import { ImportInvoiceStep } from './InvoiceImportDialog';

interface IUserImportTypeSelectionProps {
  nextStep: (step: ImportInvoiceStep) => void;
  handleClose: () => void;
  importType: InvoicingImportType | undefined;
  setImportType: React.Dispatch<React.SetStateAction<InvoicingImportType | undefined>>;
}

const InvoiceImportTypeSelection = (props: IUserImportTypeSelectionProps) => {
  const { nextStep, handleClose, importType, setImportType } = props;

  return (
    <>
      <DialogContent sx={{ p: 0, mt: 2 }}>
        <Typography variant="titleMedium" sx={{ color: '#000' }}>
          Step 1 - Type Selection
        </Typography>
        <Stack sx={{ pt: 3, pl: 2 }}>
          <FormControl>
            <RadioGroup value={importType} onChange={(_, value) => setImportType(value as InvoicingImportType)}>
              <FormControlLabel value={InvoicingImportType.BillableTask} control={<Radio />} label="Billable Task Template" />
            </RadioGroup>
          </FormControl>
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ p: 0 }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack direction="row"></Stack>

          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button onClick={handleClose} color="inherit" variant="outlined" size="large">
              Close
            </Button>
            <Button disabled={!importType} onClick={() => nextStep(ImportInvoiceStep.UploadFile)} variant="contained" size="large">
              Next
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  );
};

export default InvoiceImportTypeSelection;
