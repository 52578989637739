import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, styled } from '@mui/material';
import { Link, createSearchParams, useSearchParams } from 'react-router-dom';
import { enrolmentStatusMapping, hootSupportedCountryMapping, productStatusLabels } from '../../components/hfs-utils';
import StatusChip from '../../components/ui/StatusChip';
import {
  DistrictSchoolDetailSearchResult,
  DistrictSchoolQuerySortKeyEnum,
  LocationTypeFilterOptions,
  SearchDistrictSchoolDetailsQuery,
} from '../../hooks/api/hfs/useSearchDistrictSchoolDetails';
import { SortOrder, TableHeaderCell, muiTableSortDirectionLookup } from '../../interfaces/order-by';
import { GenericPaginatedResponse } from '../../interfaces/pagination';
import { routes } from '../../routes/routes';
import { DistrictSchoolPageQueryParams } from './models';
import { enrolmentStatusChipLookup, productStatusChipLookup } from './types';

const DistrictSchoolLink = styled(Link)({
  textDecoration: 'none',
  color: '#000000',
});

interface DistrictsAndSchoolsTableProps {
  query: SearchDistrictSchoolDetailsQuery;
  districtsAndSchoolsPaginatedResponse: GenericPaginatedResponse<DistrictSchoolDetailSearchResult>;
}

const locationTypeMapping: Record<LocationTypeFilterOptions, string> = {
  [LocationTypeFilterOptions.School]: 'School',
  [LocationTypeFilterOptions.District]: 'District',
  [LocationTypeFilterOptions.StandaloneSchool]: 'Standalone School',
};

const tableHeaderCells: TableHeaderCell<DistrictSchoolQuerySortKeyEnum>[] = [
  { label: 'Name', sortKey: DistrictSchoolQuerySortKeyEnum.Name },
  { label: 'Type', sortKey: DistrictSchoolQuerySortKeyEnum.Type },
  { label: 'ID', sortKey: DistrictSchoolQuerySortKeyEnum.FriendlyId },
  // Kind of a hack. The table cell widths can vary quite a bit when some pages have "products" and "enrolments"
  // populated and other pages don't.
  { label: 'Products', cellWidth: '142px', sortKey: DistrictSchoolQuerySortKeyEnum.Products },
  { label: 'Enrolments', cellWidth: '142px', sortKey: DistrictSchoolQuerySortKeyEnum.Enrolments },
  { label: 'Schools', sortKey: DistrictSchoolQuerySortKeyEnum.Schools },
  { label: 'Enrolled Students', sortKey: DistrictSchoolQuerySortKeyEnum.Students },
  { label: 'Country', sortKey: DistrictSchoolQuerySortKeyEnum.Country },
];

const DistrictsAndSchoolsTable = (props: DistrictsAndSchoolsTableProps) => {
  const { query, districtsAndSchoolsPaginatedResponse } = props;
  const { orderBy, sortDirection } = query;

  const [, setSearchParams] = useSearchParams();

  // If we've toggling the same order-by key, then just flip the sort direction.
  // Else, we're sorting by a different column, and we can default the sort to 'asc'.
  const toggleOrderBy = (newOrderBy: string) => () => {
    const isAsc = orderBy === newOrderBy && sortDirection === SortOrder.ASC;

    setSearchParams(
      (prev) => {
        const newSearchParams = createSearchParams(prev);
        newSearchParams.set(DistrictSchoolPageQueryParams.OrderBy, newOrderBy);
        newSearchParams.set(DistrictSchoolPageQueryParams.SortDirection, isAsc ? SortOrder.DESC : SortOrder.ASC);
        return newSearchParams;
      },
      { replace: true },
    );
  };

  return (
    <>
      <Table sx={{ minWidth: 650 }} aria-label="schools and districts table">
        <TableHead>
          <TableRow>
            {tableHeaderCells.map((cell) => (
              <TableCell
                variant="head"
                sx={{ width: cell.cellWidth }}
                key={`headerCell-${cell.label}`}
                sortDirection={
                  cell.sortKey ? (orderBy === cell.sortKey ? muiTableSortDirectionLookup[sortDirection ?? SortOrder.ASC] : false) : undefined
                }
              >
                {cell.sortKey ? (
                  <TableSortLabel
                    active={orderBy === cell.sortKey}
                    direction={orderBy === cell.sortKey ? muiTableSortDirectionLookup[sortDirection ?? SortOrder.ASC] : undefined}
                    onClick={toggleOrderBy(cell.sortKey)}
                  >
                    {cell.label}
                  </TableSortLabel>
                ) : (
                  cell.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {districtsAndSchoolsPaginatedResponse.data.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '& > td': { verticalAlign: 'top' },
              }}
            >
              <TableCell>
                <DistrictSchoolLink to={routes.districtsSchools.details.url(row.id)}>{row.name}</DistrictSchoolLink>
              </TableCell>
              <TableCell>{locationTypeMapping[row.type]}</TableCell>
              <TableCell>{row.friendlyId}</TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                  }}
                >
                  {Object.entries(row.productStatusCounts ?? {}).map(([status, count]) => (
                    <StatusChip
                      key={`product-status-count-${status}`}
                      size="small"
                      statusIcon={productStatusChipLookup[status as ProductStatus].statusIcon}
                      iconColor={productStatusChipLookup[status as ProductStatus].iconColor}
                      label={productStatusLabels[status as ProductStatus]}
                      count={count}
                    />
                  ))}
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                  }}
                >
                  {Object.entries(row.enrolmentStatusCounts ?? {}).map(([status, count]) => (
                    <StatusChip
                      key={`enrolment-status-count-${status}`}
                      size="small"
                      statusIcon={enrolmentStatusChipLookup[status as EnrolmentStatus].statusIcon}
                      iconColor={enrolmentStatusChipLookup[status as EnrolmentStatus].iconColor}
                      label={enrolmentStatusMapping[status as EnrolmentStatus]}
                      count={count}
                    />
                  ))}
                </Box>
              </TableCell>
              <TableCell>{row.schoolCount ?? '-'}</TableCell>
              <TableCell>{row.studentCount ?? '-'}</TableCell>
              <TableCell>{hootSupportedCountryMapping[row.country]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default DistrictsAndSchoolsTable;
