import { Autocomplete, Box, Button, Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import fileDownloader from 'js-file-download';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { EnrolmentAutoComplete } from '../../../components/form/selectFields/EnrolmentAutoComplete';
import { HeaderData, Table } from '../../../components/ui/Table';
import useGetStudentProfiles, { QueryStudentProfile, StudentProfile } from '../../../hooks/api/end-users/useGetStudentProfiles';
import { hootLevelModuleStatusLookup, hootLevelUnitStatusLookup } from '../../../hooks/api/reporting/hoot-levels/enum';
import useGetHootLevelsReport, {
  AdminHootLevelReportDetails,
  AdminHootLevelV2ReportDetails,
} from '../../../hooks/api/reporting/hoot-levels/useGetHootLevelsReport';
import useGetHootLevelsReportCSV from '../../../hooks/api/reporting/hoot-levels/useGetHootLevelsReportCSV';

export interface StudentLevelRow {
  studentNumber: number;
  moduleOne: string;
  u_1_1a: string;
  u_1_1b: string;
  u_1_1c: string;
  u_1_1d: string;
  u_1_1e: string;
  u_1_2a: string;
  u_1_2b: string;
  u_1_2c: string;
  u_1_2d: string;
  u_1_3a: string;
  moduleTwo: string;
  u_2_1a: string;
  u_2_2a: string;
  u_2_2b: string;
  u_2_2c: string;
  u_2_3a: string;
  u_2_3b: string;
  u_2_3c: string;
  moduleThree: string;
  u_3_1a: string;
  u_3_1b: string;
  u_3_2a: string;
  u_3_2b: string;
  moduleFour: string;
  u_4_1a: string;
  u_4_2a: string;
  u_4_3a: string;
}

export interface StudentLevelV2Row {
  studentNumber: number;
  moduleOne: string;
  U_1_1_V1: string;
  U_1_2_V1: string;
  U_1_3_V1: string;
  U_1_4_V1: string;
  U_1_5_V1: string;
  moduleTwo: string;
  U_2_1_V1: string;
  U_2_2_V1: string;
  U_2_3_V1: string;
  U_2_4_V1: string;
  U_2_5_V1: string;
  U_2_6_V1: string;
  moduleThree: string;
  U_3_1_V1: string;
  U_3_2_V1: string;
  U_3_3_V1: string;
  U_3_4_V1: string;
  U_3_5_V1: string;
  U_3_6_V1: string;
  moduleFour: string;
  U_4_1_V1: string;
  U_4_2_V1: string;
  U_4_3_V1: string;
  U_4_4_V1: string;
}

const headersV1: HeaderData<StudentLevelRow>[] = [
  { name: 'Student Number', property: 'studentNumber', isHidden: false },
  { name: 'Module 1', property: 'moduleOne', isHidden: false },
  { name: 'Word Awareness', property: 'u_1_1a', isHidden: false },
  { name: 'Syllable Awareness', property: 'u_1_1b', isHidden: false },
  { name: 'Rhyme Identification', property: 'u_1_1c', isHidden: false },
  { name: 'Initial Sound Identification', property: 'u_1_1d', isHidden: false },
  { name: 'Onset-Rime Awareness', property: 'u_1_1e', isHidden: false },
  { name: 'Identification--Phoneme Counting/Segmentation', property: 'u_1_2a', isHidden: false },
  { name: 'Blending', property: 'u_1_2b', isHidden: false },
  { name: 'Deletion', property: 'u_1_2c', isHidden: false },
  { name: 'Substitution', property: 'u_1_2d', isHidden: false },
  { name: 'Letter Sounds', property: 'u_1_3a', isHidden: false },
  { name: 'Module 2', property: 'moduleTwo', isHidden: false },
  { name: 'Short Vowels CVC', property: 'u_2_1a', isHidden: false },
  { name: 'Digraphs', property: 'u_2_2a', isHidden: false },
  { name: 'Initial Consonant Blends', property: 'u_2_2b', isHidden: false },
  { name: 'Final Blends', property: 'u_2_2c', isHidden: false },
  { name: 'Long vowels with e and Open Vowels', property: 'u_2_3a', isHidden: false },
  { name: 'Basic R-Controlled Vowels', property: 'u_2_3b', isHidden: false },
  { name: 'Basic Vowel Teams', property: 'u_2_3c', isHidden: false },
  { name: 'Module 3', property: 'moduleThree', isHidden: false },
  { name: 'Advanced Vowel Teams, Variant Vowels and Diphthongs', property: 'u_3_1a', isHidden: false },
  { name: 'Advanced R-Controlled Vowels', property: 'u_3_1b', isHidden: false },
  { name: 'Silent Letters, Hard/Soft Consonants', property: 'u_3_2a', isHidden: false },
  { name: 'Two-Syllable Words', property: 'u_3_2b', isHidden: false },
  { name: 'Module 4', property: 'moduleFour', isHidden: false },
  { name: 'Vocabulary', property: 'u_4_1a', isHidden: false },
  { name: 'Knowledge & Synthesis', property: 'u_4_2a', isHidden: false },
  { name: 'Verbal Reasoning & Metacognition', property: 'u_4_3a', isHidden: false },
];

const headersV2: HeaderData<StudentLevelV2Row>[] = [
  { name: 'Student Number', property: 'studentNumber', isHidden: false },
  { name: 'Module 1', property: 'moduleOne', isHidden: false },
  { name: 'Segmentation', property: 'U_1_1_V1', isHidden: false },
  { name: 'Blending', property: 'U_1_2_V1', isHidden: false },
  { name: 'Deletion', property: 'U_1_3_V1', isHidden: false },
  { name: 'Substitution', property: 'U_1_4_V1', isHidden: false },
  { name: 'Initial Letter Sounds', property: 'U_1_5_V1', isHidden: false },
  { name: 'Module 2', property: 'moduleTwo', isHidden: false },
  { name: 'Short Vowels CVC', property: 'U_2_1_V1', isHidden: false },
  { name: 'Digraphs', property: 'U_2_2_V1', isHidden: false },
  { name: 'Blends', property: 'U_2_3_V1', isHidden: false },
  { name: 'Long Vowels', property: 'U_2_4_V1', isHidden: false },
  { name: 'Basic R-Controlled Vowels', property: 'U_2_5_V1', isHidden: false },
  { name: 'Basic Vowel Teams', property: 'U_2_6_V1', isHidden: false },
  { name: 'Module 3', property: 'moduleThree', isHidden: false },
  { name: 'Advanced Vowels', property: 'U_3_1_V1', isHidden: false },
  { name: 'Advanced R-Controlled Vowels', property: 'U_3_2_V1', isHidden: false },
  { name: 'Advanced Consonants', property: 'U_3_3_V1', isHidden: false },
  { name: 'Affixes', property: 'U_3_4_V1', isHidden: false },
  { name: 'Syllable Types', property: 'U_3_5_V1', isHidden: false },
  { name: 'Multisyllabic Words', property: 'U_3_6_V1', isHidden: false },
  { name: 'Module 4', property: 'moduleFour', isHidden: false },
  { name: 'Accuracy & Pace', property: 'U_4_1_V1', isHidden: false },
  { name: 'Phrasing & Expression', property: 'U_4_2_V1', isHidden: false },
  { name: 'Language Comprehension', property: 'U_4_3_V1', isHidden: false },
  { name: 'Reading Comprehension', property: 'U_4_4_V1', isHidden: false },
];

const StudentLevelsReport = () => {
  const [studentProfiles, setStudentProfiles] = useState<StudentProfile[]>([]);
  const [onlyIncludeAssessmentsBefore, setOnlyIncludeAssessmentsBefore] = useState<number | null>();
  const [enrolementId, setEnrolmentId] = useState<string | null>(null);

  const getHootLevelsReport = useGetHootLevelsReport({
    studentProfileIds: studentProfiles.map((sp) => sp.id),
    onlyIncludeAssessmentsBefore: onlyIncludeAssessmentsBefore ? onlyIncludeAssessmentsBefore : undefined,
    enrolmentId: enrolementId,
  });

  const isHootLevelV1 = getHootLevelsReport.data?.version === 1;
  const dataV1 = isHootLevelV1
    ? getHootLevelsReport.data?.hootLevels
        .filter((r): r is AdminHootLevelReportDetails => r.version === 1)
        .map<StudentLevelRow>((r) => ({
          studentNumber: r.studentNumber,
          moduleOne: hootLevelModuleStatusLookup[r.moduleOne],
          u_1_1a: hootLevelUnitStatusLookup[r.u_1_1a],
          u_1_1b: hootLevelUnitStatusLookup[r.u_1_1b],
          u_1_1c: hootLevelUnitStatusLookup[r.u_1_1c],
          u_1_1d: hootLevelUnitStatusLookup[r.u_1_1d],
          u_1_1e: hootLevelUnitStatusLookup[r.u_1_1e],
          u_1_2a: hootLevelUnitStatusLookup[r.u_1_2a],
          u_1_2b: hootLevelUnitStatusLookup[r.u_1_2b],
          u_1_2c: hootLevelUnitStatusLookup[r.u_1_2c],
          u_1_2d: hootLevelUnitStatusLookup[r.u_1_2d],
          u_1_3a: hootLevelUnitStatusLookup[r.u_1_3a],
          moduleTwo: hootLevelModuleStatusLookup[r.moduleTwo],
          u_2_1a: hootLevelUnitStatusLookup[r.u_2_1a],
          u_2_2a: hootLevelUnitStatusLookup[r.u_2_2a],
          u_2_2b: hootLevelUnitStatusLookup[r.u_2_2b],
          u_2_2c: hootLevelUnitStatusLookup[r.u_2_2c],
          u_2_3a: hootLevelUnitStatusLookup[r.u_2_3a],
          u_2_3b: hootLevelUnitStatusLookup[r.u_2_3b],
          u_2_3c: hootLevelUnitStatusLookup[r.u_2_3c],
          moduleThree: hootLevelModuleStatusLookup[r.moduleThree],
          u_3_1a: hootLevelUnitStatusLookup[r.u_3_1a],
          u_3_1b: hootLevelUnitStatusLookup[r.u_3_1b],
          u_3_2a: hootLevelUnitStatusLookup[r.u_3_2a],
          u_3_2b: hootLevelUnitStatusLookup[r.u_3_2b],
          moduleFour: hootLevelModuleStatusLookup[r.moduleFour],
          u_4_1a: hootLevelUnitStatusLookup[r.u_4_1a],
          u_4_2a: hootLevelUnitStatusLookup[r.u_4_2a],
          u_4_3a: hootLevelUnitStatusLookup[r.u_4_3a],
        })) ?? []
    : [];

  const dataV2 = !isHootLevelV1
    ? getHootLevelsReport.data?.hootLevels
        .filter((r): r is AdminHootLevelV2ReportDetails => r.version === 2)
        .map<StudentLevelV2Row>((r) => ({
          studentNumber: r.studentNumber,
          moduleOne: hootLevelModuleStatusLookup[r.moduleOne],
          U_1_1_V1: hootLevelUnitStatusLookup[r.U_1_1_V1],
          U_1_2_V1: hootLevelUnitStatusLookup[r.U_1_2_V1],
          U_1_3_V1: hootLevelUnitStatusLookup[r.U_1_3_V1],
          U_1_4_V1: hootLevelUnitStatusLookup[r.U_1_4_V1],
          U_1_5_V1: hootLevelUnitStatusLookup[r.U_1_5_V1],
          moduleTwo: hootLevelModuleStatusLookup[r.moduleTwo],
          U_2_1_V1: hootLevelUnitStatusLookup[r.U_2_1_V1],
          U_2_2_V1: hootLevelUnitStatusLookup[r.U_2_2_V1],
          U_2_3_V1: hootLevelUnitStatusLookup[r.U_2_3_V1],
          U_2_4_V1: hootLevelUnitStatusLookup[r.U_2_4_V1],
          U_2_5_V1: hootLevelUnitStatusLookup[r.U_2_5_V1],
          U_2_6_V1: hootLevelUnitStatusLookup[r.U_2_6_V1],
          moduleThree: hootLevelModuleStatusLookup[r.moduleThree],
          U_3_1_V1: hootLevelUnitStatusLookup[r.U_3_1_V1],
          U_3_2_V1: hootLevelUnitStatusLookup[r.U_3_2_V1],
          U_3_3_V1: hootLevelUnitStatusLookup[r.U_3_3_V1],
          U_3_4_V1: hootLevelUnitStatusLookup[r.U_3_4_V1],
          U_3_5_V1: hootLevelUnitStatusLookup[r.U_3_5_V1],
          U_3_6_V1: hootLevelUnitStatusLookup[r.U_3_6_V1],
          moduleFour: hootLevelModuleStatusLookup[r.moduleFour],
          U_4_1_V1: hootLevelUnitStatusLookup[r.U_4_1_V1],
          U_4_2_V1: hootLevelUnitStatusLookup[r.U_4_2_V1],
          U_4_3_V1: hootLevelUnitStatusLookup[r.U_4_3_V1],
          U_4_4_V1: hootLevelUnitStatusLookup[r.U_4_4_V1],
        })) ?? []
    : [];

  const handleStudentAutoCompleteChange = (studentProfiles: any) => {
    setStudentProfiles(studentProfiles);
  };

  const handleEnrolmentAutoCompleteChange = (selectedEnrolmentId: string | null) => {
    setEnrolmentId(selectedEnrolmentId);
  };

  return (
    <Card
      sx={{
        width: 1,
        maxWidth: 'calc(100vw - 48px)',
      }}
    >
      <CardContent sx={{}}>
        <Stack>
          <Stack justifyContent="space-between" direction="row">
            <Typography variant="titleLarge">Hoot Levels</Typography>
            <ExportButton studentProfiles={studentProfiles} enrolementId={enrolementId} onlyIncludeAssessmentsBefore={onlyIncludeAssessmentsBefore} />
          </Stack>
          <Stack
            sx={{
              marginTop: '24px',
            }}
          >
            <StudentAutoComplete onChange={handleStudentAutoCompleteChange} value={studentProfiles} />

            <Box sx={{ marginTop: '16px' }}>
              <EnrolmentAutoComplete value={enrolementId} onChange={handleEnrolmentAutoCompleteChange} />
            </Box>

            <Box sx={{ marginTop: '16px' }}>
              <DatePicker
                value={onlyIncludeAssessmentsBefore ? DateTime.fromMillis(onlyIncludeAssessmentsBefore) : undefined}
                onChange={(date) => setOnlyIncludeAssessmentsBefore((date as unknown as DateTime).toMillis())}
                slotProps={{
                  textField: {
                    sx: { maxWidth: '256px' },
                    label: 'Only Include Assessment Until',
                  },
                }}
              />
            </Box>
          </Stack>

          <Box
            sx={{
              marginTop: '24px',
              maxWidth: '100%',
              overflow: 'auto',
            }}
          >
            {isHootLevelV1 ? (
              <Table isLoading={getHootLevelsReport.isFetching} headers={headersV1} data={dataV1} />
            ) : (
              <Table isLoading={getHootLevelsReport.isFetching} headers={headersV2} data={dataV2} />
            )}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

const StudentAutoComplete = (props: { onChange: (studentProfile?: StudentProfile[]) => void; value?: StudentProfile[] }) => {
  const [query, setQuery] = useState<QueryStudentProfile>({
    name: '',
    page: 1,
    pageSize: 32,
  });

  const { data, isLoading } = useGetStudentProfiles(query, {
    keepPreviousData: true,
  });

  const options = data?.studentProfiles ?? [];

  return (
    <Autocomplete
      autoComplete={false}
      multiple
      onChange={(event, value) => props.onChange(value)}
      value={props.value}
      fullWidth
      loading={isLoading}
      options={options}
      getOptionLabel={(option) => `${option.name} (${option.number})`}
      onInputChange={(event, val) => {
        setQuery((current) => ({ ...current, name: val, pageSize: !!val ? 32 : 32 }));
      }}
      renderInput={(params) => <TextField {...params} label="Student Search" fullWidth />}
    />
  );
};

function ExportButton(props: { studentProfiles: StudentProfile[]; onlyIncludeAssessmentsBefore?: number | null; enrolementId: string | null }) {
  const getHootLevelsReportCSV = useGetHootLevelsReportCSV(
    {
      studentProfileIds: props.studentProfiles.map((sp) => sp.id),
      onlyIncludeAssessmentsBefore: props.onlyIncludeAssessmentsBefore ? props.onlyIncludeAssessmentsBefore : undefined,
      enrolmentId: props.enrolementId,
    },
    {
      enabled: false,
      onSuccess: (res) => {
        const filename = `Hoot Levels Report ${DateTime.now().toLocaleString()}.csv`;
        fileDownloader(res.data, filename);
      },
      onError: (err) => {
        console.error('Error downloading CSV', err);
      },
    },
  );

  const handleClick = () => {
    getHootLevelsReportCSV.refetch();
  };

  return (
    <Button sx={{ maxWidth: '240px' }} disabled={getHootLevelsReportCSV.isFetching} variant="contained" onClick={handleClick}>
      Export
    </Button>
  );
}

export default StudentLevelsReport;
