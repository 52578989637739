import { AgreementEndReason } from '@hoot-reading/hoot-core/dist/enums/user/teacher/agreement-end-reason.enum';
import { DiversitySelfIdentification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/diversity-self-identification.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { LanguageQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/language-qualification.enum';
import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { TeacherCertificationArea } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-certification-area.enum';
import { TeacherSpecializedTraining } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-specialized-training.enum';
import { TeacherStage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-stage.enum';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { BackgroundCheck } from '@hoot/interfaces/teacher';
import { InvoiceRateTier } from '@hoot/pages/users/teachers/enums/teacher-enums';
import { config } from '../../../../config';

export interface UpdateTeacherAccountWithUserDetails {
  firstName: string;
  lastName: string;
  displayName: string;
  emailAddress: string;
  phoneNumber: string;
  streetAddress: string | null;
  country: string;
  provinceState: string;
  postalZipCode: string | null;
  timeZone: string;
  diversitySelfIdentifications: DiversitySelfIdentification[];
  languageQualifications: LanguageQualification[];
  hootQualifications: HootQualification[];
  userAccountStatus: boolean;
  teacherStatus: TeacherStatus;
  employmentAgreementDate: string | null;
  icContractDate: string | null;
  goLiveDate?: string | null;
  spokenLanguages: SpokenLanguage[];
  certificationAreas: TeacherCertificationArea[];
  specializedTraining: TeacherSpecializedTraining[];
  bio: string | null;
  lessonPayTier: InvoiceRateTier | null;
  agreementEndDate?: string | null;
  agreementEndReason: AgreementEndReason | null;
  teacherStage: TeacherStage | null;
  trainingPayTier: InvoiceRateTier | null;
  payEffectiveDate: string | null;
  leaveEffectiveDate: string | null;
  leaveReturnDate: string | null;
  backgroundChecks: BackgroundCheck[];
}

async function updateTeacherAccountAndUser(teacherId: string, payload: UpdateTeacherAccountWithUserDetails) {
  const { data } = await axios.put(`${config.apiUrl}/admin/teacher-account/${teacherId}`, payload);

  return data;
}

export default function useUpdateTeacherAccountAndUser(
  teacherId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, UpdateTeacherAccountWithUserDetails>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation<void, AxiosError, UpdateTeacherAccountWithUserDetails>((request) => updateTeacherAccountAndUser(teacherId, request), options);
}
