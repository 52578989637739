import { Card, Grid, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ExceptionResponse,
  TeacherAvailabilityExceptionsImpactsResponse,
  useGetTeacherAvailabilityExceptionImpacts,
} from '@hoot/hooks/api/availability/useAvailabilityExceptionImpacts';
import { defaultRowsPerPage } from '../../../common/constants';
import { HeaderData, Table } from '../../../components/ui/Table';
import useGetTeacherAccount, { TeacherAccount } from '../../../hooks/api/user/teacher/useGetTeacherAccount';
import { routes } from '../../../routes/routes';

interface AvailabilityExceptionRow {
  startsAt: React.ReactNode;
  endsAt: React.ReactNode;
  submittedAt: React.ReactNode;
  lessons: React.ReactNode;
  shifts: React.ReactNode;
}

const headers: HeaderData<AvailabilityExceptionRow>[] = [
  { property: 'startsAt', name: 'Starts At', width: '20%' },
  { property: 'endsAt', name: 'Ends At', width: '20%' },
  { property: 'submittedAt', name: 'Submitted At', width: '20%' },
  { property: 'lessons', name: 'Lessons', width: '20%' },
  { property: 'shifts', name: 'Shifts', width: '20%' },
];

export function AvailabilityException(props: { teacherAccountId: string }) {
  const teacherAccountRequest = useGetTeacherAccount(props.teacherAccountId);

  const teacherAvailabilityExceptionImpactsRequest = useGetTeacherAvailabilityExceptionImpacts({
    teacherAccountId: props.teacherAccountId,
  });

  return (
    <Card sx={{ mt: '24px', px: '24px', py: '24px' }}>
      <Header teacherAccount={teacherAccountRequest.data} availabilityException={teacherAvailabilityExceptionImpactsRequest.data} />
      <Content teacherAccountId={props.teacherAccountId} exceptions={teacherAvailabilityExceptionImpactsRequest.data?.exceptions} />
    </Card>
  );
}

function Header(props: {
  teacherAccount: TeacherAccount | undefined;
  availabilityException: TeacherAvailabilityExceptionsImpactsResponse | undefined;
}) {
  return (
    <>
      <Grid container mb={2}>
        <Grid item xs={4}>
          <Typography component="h3" variant="titleLarge">
            {props.teacherAccount?.displayName} - Availability Exceptions
          </Typography>
          {props.availabilityException?.teacherTimezone ? (
            <Typography component="h4" variant="titleSmall">
              Timezone: {props.availabilityException?.teacherTimezone}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        {props.availabilityException?.exceptions && !props.availabilityException?.exceptions?.length ? (
          <Typography component="h3" variant="body1">
            No Exceptions
          </Typography>
        ) : null}
      </div>
    </>
  );
}

function Content(props: { teacherAccountId: string; exceptions?: ExceptionResponse[] }) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handlePageChange = (_event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const limit = parseInt(event.target.value, defaultRowsPerPage);
    setRowsPerPage(limit);
  };

  const data =
    props.exceptions
      ?.sort((a, b) => (a.startsAt < b.startsAt ? 1 : -1))
      ?.slice((page - 1) * rowsPerPage, page * rowsPerPage)
      ?.map<AvailabilityExceptionRow>((e) => ({
        startsAt: DateTime.fromMillis(e.startsAt).toFormat('yyyy-LL-dd h:mm a'),
        endsAt: DateTime.fromMillis(e.endsAt).toFormat('yyyy-LL-dd  h:mm a'),
        submittedAt: DateTime.fromMillis(e.createdAt).toFormat('yyyy-LL-dd  h:mm a'),
        lessons: (
          <Stack direction="column" gap="8px">
            {e.droppedLessons.map((l) => (
              <Link key={l.lessonId} to={routes.lessons.details.url(l.lessonId)}>
                {l.lessonNumber}
              </Link>
            ))}
          </Stack>
        ),
        shifts: (
          <Stack direction="row" gap="8px">
            {e?.droppedShifts.map((s) => (
              <Link key={s.shiftId} to={routes.users.teachers.shifts.details.url(props.teacherAccountId, s.shiftId)}>
                {s.shiftNumber}
              </Link>
            ))}
          </Stack>
        ),
      })) ?? [];

  return (
    <Stack sx={{ marginTop: '16px' }}>
      <Table
        isPaginated
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        headers={headers}
        data={data}
        count={props.exceptions?.length ?? 0}
        page={page}
        rowsPerPage={rowsPerPage}
        isLoading={false}
      />
    </Stack>
  );
}
