import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { QualityAssuranceStatus, qualityAssuranceStatusLookup } from '../../../../hooks/api/tasks/enums';
import FilterDropDown from '../../FilterDropDown';

export function QualityAssuranceTaskStatusFilterDropDown(props: {
  value: QualityAssuranceStatus[];
  onChange: (val: QualityAssuranceStatus[]) => void;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { value, onChange, popOverButtonSx } = props;
  const options = Object.entries(qualityAssuranceStatusLookup);

  return (
    <FilterDropDown<string>
      title="Task Status"
      dismissPopoverOnSelection={true}
      value={value}
      options={options.map(([value, label]) => ({
        label: label,
        value: value,
      }))}
      onChange={(val) => onChange(val as QualityAssuranceStatus[])}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveQualityAssuranceTaskStatusFilterDropDown(props: {
  value?: QualityAssuranceStatus[];
  onChange: (val: QualityAssuranceStatus[]) => void;
}) {
  const { value = [], onChange } = props;

  const handleDelete = (val: QualityAssuranceStatus) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`Task Status: ${qualityAssuranceStatusLookup[c]}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}
