import { Autocomplete, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

export interface SearchSelectOption<T extends string> {
  value: T;
  label: string;
}

interface Props<T extends string> {
  data: SearchSelectOption<T>[];
  control: Control<any>;
  name: string;
  label: string;
  disabled?: boolean;
  size?: 'small' | 'medium';
  required?: boolean;
  onChangeCallback?: () => void;
  helperText?: React.ReactNode;
}

export const SearchSelect = <T extends string>(props: Props<T>) => {
  const { data, control, name, label, disabled = false, onChangeCallback, required, helperText } = props;

  const idToName = data.reduce((acc: Record<string, string>, val) => {
    acc[val.value] = val.label;
    return acc;
  }, {});

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required ?? false }}
      render={({ field: { onChange, value }, fieldState }) => {
        return (
          <Autocomplete
            disableClearable
            disabled={disabled}
            onChange={(_event, item) => {
              onChange(item);
              if (onChangeCallback) {
                onChangeCallback();
              }
            }}
            value={value}
            options={data.map((record) => record.value)}
            getOptionLabel={(optionId: string) => {
              if (optionId) return idToName[optionId] ?? '';
              else return '';
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={!!fieldState.error && helperText}
                size={props.size}
                variant="outlined"
                label={label}
                error={!!fieldState.error}
                fullWidth
              />
            )}
            fullWidth
          />
        );
      }}
    />
  );
};
