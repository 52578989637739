import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { HootEmployee } from '@hoot/interfaces/hoot-employee';
import { HootEmployeeForm } from '../interfaces/form';

const defaults: HootEmployeeForm = {
  firstName: '',
  lastName: '',
  email: '',
  isEnabled: true,
  isSuperUser: false,
  canUpdateBooks: false,
  canViewLessonVideos: false,
  canImpersonateUsers: false,
  canAccessReportingPage: false,
  canSoftDeleteUsers: false,
  canAccessPartnerManagement: false,
  canAdvancedEditLessonDetails: false,
  canAdvancedEditLessonTeacher: false,
  canAccessInvoicing: false,
  canManageLessonReviews: false,
  canImportTeacherReliabilityScore: false,
  canManageAdvancedStudentData: false,
  canViewDistrictDetails: false,
  canManageStudentDetails: false,
  canManageTeacherDetails: false,
  canManageDistrictReps: false,
  canAccessBillingInformation: false,
  canDeleteAssessments: false,
  canManageInstructionalLibraries: false,
};

export default function useHootEmployeeForm(hootEmployee?: HootEmployee) {
  const getFormValues: (hootEmployee: HootEmployee) => HootEmployeeForm = (hootEmployee) => ({
    ...defaults,
    ...hootEmployee,
    // Following is for the user UI look, to show that a superuser has all permissions
    canUpdateBooks: hootEmployee.isSuperUser || hootEmployee.canUpdateBooks,
    canViewLessonVideos: hootEmployee.isSuperUser || hootEmployee.canViewLessonVideos,
    canImpersonateUsers: hootEmployee.isSuperUser || hootEmployee.canImpersonateUsers,
    canAccessReportingPage: hootEmployee.isSuperUser || hootEmployee.canAccessReportingPage,
    canSoftDeleteUsers: hootEmployee.isSuperUser || hootEmployee.canSoftDeleteUsers,
    canAccessPartnerManagement: hootEmployee.isSuperUser || hootEmployee.canAccessPartnerManagement,
    canAdvancedEditLessonTeacher: hootEmployee.isSuperUser || hootEmployee.canAdvancedEditLessonTeacher,
    canAdvancedEditLessonDetails: hootEmployee.isSuperUser || hootEmployee.canAdvancedEditLessonDetails,
    canAccessInvoicing: hootEmployee.isSuperUser || hootEmployee.canAccessInvoicing,
    canManageLessonReviews: hootEmployee.isSuperUser || hootEmployee.canManageLessonReviews,
    canImportTeacherReliabilityScore: hootEmployee.isSuperUser || hootEmployee.canImportTeacherReliabilityScore,
    canManageAdvancedStudentData: hootEmployee.isSuperUser || hootEmployee.canManageAdvancedStudentData,
    canViewDistrictDetails: hootEmployee.isSuperUser || hootEmployee.canViewDistrictDetails,
    canManageStudentDetails: hootEmployee.isSuperUser || hootEmployee.canManageStudentDetails,
    canManageTeacherDetails: hootEmployee.isSuperUser || hootEmployee.canManageTeacherDetails,
    canManageDistrictReps: hootEmployee.isSuperUser || hootEmployee.canManageDistrictReps,
    canAccessBillingInformation: hootEmployee.isSuperUser || hootEmployee.canAccessBillingInformation,
    canDeleteAssessments: hootEmployee.isSuperUser || hootEmployee.canDeleteAssessments,
    canManageInstructionalLibraries: hootEmployee.isSuperUser || hootEmployee.canManageInstructionalLibraries,
  });

  const formItems = useForm<HootEmployeeForm>({
    mode: 'onChange',
    defaultValues: hootEmployee ? getFormValues(hootEmployee) : defaults,
  });

  const resetForm = useCallback(() => {
    if (hootEmployee) {
      formItems.reset(getFormValues(hootEmployee));
    }
  }, [formItems, hootEmployee]);

  return {
    ...formItems,
    resetForm,
  };
}
