import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface CreateBillableDistrictSchool {
  name: string;
  country: string;
  provinceState: string;
  locationType: LocationType;
  reporting: boolean;
  city?: string;
  timeZone?: string;
  districtId?: string;
}

const createDistrictSchool = async (request: CreateBillableDistrictSchool): Promise<string> => {
  const { data } = await axios.post<string>(`${config.apiUrl}/admin/hfs/district-schools/create`, request);
  return data;
};

const useCreateDistrictSchoolRequest = (
  options?: Omit<UseMutationOptions<any, AxiosError, CreateBillableDistrictSchool>, 'mutationFn' | 'mutationKey'>,
) => {
  return useMutation<string, AxiosError, CreateBillableDistrictSchool>((request) => createDistrictSchool(request), options);
};

export default useCreateDistrictSchoolRequest;
