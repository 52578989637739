import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { studentProfileStatus, studentProfileStatusMapping } from '@hoot/interfaces/profile';
import FilterDropDown from '../../FilterDropDown';

export function StudentStatusFilterDropDown(props: {
  value: StudentStatus[];
  onChange: (val: StudentStatus[]) => void;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { value, onChange, popOverButtonSx } = props;

  return (
    <FilterDropDown<string>
      title="Student Status"
      dismissPopoverOnSelection={true}
      value={value}
      options={studentProfileStatus.map((s) => ({
        label: s.label,
        value: s.value,
      }))}
      onChange={(val) => onChange(val as StudentStatus[])}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveStudentStatusFilterChips(props: { value?: StudentStatus[]; onChange: (val: StudentStatus[]) => void }) {
  const { value = [], onChange } = props;

  const handleDelete = (val: StudentStatus) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`Status: ${studentProfileStatusMapping[c]}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}
