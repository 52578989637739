import { Card, CardContent, CardHeader, FormHelperText, Grid, TextField as MUITextField, Stack, Tooltip, Typography } from '@mui/material';
import phone from 'phone';
import React from 'react';
import { Control, FormState, UseFormSetValue } from 'react-hook-form';
import { VALID_CANADA_POSTAL_CODE, VALID_EMAIL_REGEX, VALID_US_ZIP_CODE } from '@hoot/common/constants';
import { SearchSelect } from '@hoot/components/SearchSelect';
import { PhoneNumberInput } from '@hoot/components/form/PhoneNumberInput';
import { TextField } from '@hoot/components/form/TextField';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { usePlaces } from '@hoot/hooks/usePlaces';
import { TeacherAccountAndUserForm } from '@hoot/pages/users/interfaces/form';
import { timeZoneData } from '@hoot/utils/timeZones';

interface Props {
  control: Control<TeacherAccountAndUserForm>;
  formState: FormState<TeacherAccountAndUserForm>;
  setValue: UseFormSetValue<TeacherAccountAndUserForm>;
  country: string;
  teacherNumber: number | null;
}

const TeacherUserDetails = (props: Props) => {
  const { control, setValue, formState, country, teacherNumber } = props;

  const { hootEmployee } = useAuth();
  const hidePiiField = !hootEmployee?.canAccessInvoicing;

  const { countryOptionsCanadaFirst, provinceStateOptions } = usePlaces(country);
  const countryOptions = countryOptionsCanadaFirst.filter((c) => c.value === 'CA' || c.value === 'US');

  return (
    <Grid container item>
      <Card
        sx={{
          width: 1,
        }}
      >
        <CardHeader
          sx={{ p: 3, pb: 0 }}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="titleLarge">User Details</Typography>
            </Stack>
          }
        />
        <CardContent sx={{ p: 3, pt: 0 }}>
          <Grid marginTop="24px" container sx={{ rowGap: 3 }}>
            <Grid container item xs={12}>
              <Grid item xs={6} paddingRight="12px">
                <TextField
                  name="firstName"
                  label={'First name*'}
                  rules={{
                    maxLength: {
                      value: 48,
                      message: 'First Name is too long.',
                    },
                    required: 'First Name is required!',
                  }}
                  control={control}
                  variant="outlined"
                  fullWidth
                  helperText={''}
                  helperTextFn={() => formState?.errors['firstName']?.message || ''}
                />
              </Grid>
              <Grid item container xs={6} paddingLeft="12px">
                <TextField
                  name="lastName"
                  label={'Last name*'}
                  rules={{
                    maxLength: {
                      value: 48,
                      message: 'Last Name is too long.',
                    },
                    required: 'Last Name is required!',
                  }}
                  control={control}
                  variant="outlined"
                  fullWidth
                  helperText={''}
                  helperTextFn={() => formState?.errors['lastName']?.message || ''}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              <Grid item container xs={6} paddingRight="12px">
                <TextField
                  name="displayName"
                  label={'Display name*'}
                  rules={{
                    maxLength: {
                      value: 100,
                      message: 'Display name must be less than 100 characters long.',
                    },
                    required: 'Display name is required!',
                  }}
                  control={control}
                  variant="outlined"
                  fullWidth
                  helperText={''}
                  helperTextFn={() => formState?.errors['displayName']?.message || ''}
                />
              </Grid>
              <Grid item container xs={6} paddingLeft="12px">
                <MUITextField
                  name="number"
                  label="Teacher Number"
                  value={teacherNumber}
                  variant="outlined"
                  fullWidth
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              <Grid item container xs={6} paddingRight="12px">
                <TextField
                  name="emailAddress"
                  label={'Email address*'}
                  rules={{
                    required: 'Email is required!',
                    pattern: {
                      value: VALID_EMAIL_REGEX,
                      message: 'A valid email is required!',
                    },
                  }}
                  control={control}
                  variant="outlined"
                  fullWidth
                  helperText={''}
                  helperTextFn={() => formState?.errors['emailAddress']?.message || ''}
                />
              </Grid>
              <Grid item container xs={6} paddingLeft="12px">
                <PhoneNumberInput
                  id="phoneNumber"
                  name="phoneNumber"
                  control={control}
                  defaultCountry={'ca'}
                  disableDropdown={true}
                  label="Phone number*"
                  fullWidth
                  variant="outlined"
                  rules={{
                    minLength: {
                      value: 2,
                      message: 'Phone number is required!',
                    },
                    validate: (value) => {
                      const phoneNum = value ? value.toString().trim() : '';
                      if (phoneNum.length === 0 || phoneNum === '+' || phoneNum === '+1' || !phone(phoneNum || '')?.isValid) {
                        return 'Valid phone number is required: +x (xxx) xxx-xxxx';
                      }
                      return true;
                    },
                  }}
                  helperTextFn={() => formState?.errors['phoneNumber']?.message || ''}
                />
                {!formState.errors.phoneNumber ? (
                  <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                    <Tooltip title="Phone Format">
                      <Typography variant="labelSmall">+x (xxx) xxx-xxxx</Typography>
                    </Tooltip>
                  </FormHelperText>
                ) : null}
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              {hidePiiField ? null : (
                <TextField
                  name="streetAddress"
                  label="Street address"
                  control={control}
                  variant="outlined"
                  fullWidth
                  helperTextFn={() => formState?.errors['streetAddress']?.message || ''}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </Grid>
            <Grid item container xs={12} spacing={3}>
              <Grid item container xs={4}>
                <SearchSelect
                  onChangeCallback={() => {
                    setValue('provinceState', '', { shouldValidate: true });
                    setValue('postalZipCode', '', { shouldValidate: true });
                  }}
                  name="country"
                  label="Country*"
                  control={control}
                  data={countryOptions}
                  required
                  helperText={formState.errors.country ? 'A country selection is required!' : ''}
                />
              </Grid>
              <Grid item container xs={4}>
                <SearchSelect
                  name="provinceState"
                  label="Province / State*"
                  disabled={!country}
                  control={control}
                  data={provinceStateOptions}
                  required
                  helperText={formState.errors.provinceState ? 'A province/state selection is required!' : ''}
                />
              </Grid>
              <Grid item container xs={4}>
                {hidePiiField ? null : (
                  <TextField
                    name="city"
                    label="City"
                    rules={{
                      maxLength: {
                        value: 65,
                        message: 'City is too long.',
                      },
                    }}
                    control={control}
                    variant="outlined"
                    fullWidth
                    helperTextFn={() => formState?.errors['city']?.message || ''}
                  />
                )}
              </Grid>
              <Grid item container xs={4}>
                {hidePiiField ? null : (
                  <TextField
                    name="postalZipCode"
                    label="Postal / Zip Code"
                    rules={{
                      maxLength: {
                        value: 48,
                        message: 'Postal/Zip Code is too long.',
                      },
                      pattern: {
                        value: country === 'US' ? VALID_US_ZIP_CODE : VALID_CANADA_POSTAL_CODE,
                        message: 'Invalid Postal/Zip Code',
                      },
                    }}
                    control={control}
                    variant="outlined"
                    fullWidth
                    helperTextFn={() => formState?.errors['postalZipCode']?.message || ''}
                  />
                )}
              </Grid>
              <Grid item container xs={8}>
                <SearchSelect
                  control={control}
                  label="Timezone*"
                  name="timeZone"
                  data={timeZoneData}
                  required
                  helperText={formState?.errors.timeZone ? 'A timezone selection is required!' : ''}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TeacherUserDetails;
