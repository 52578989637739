import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';
import { Tokens } from '../../../interfaces/auth';

async function logout() {
  axios.post<Tokens>(`${config.apiUrl}/auth/logout`);
}

export default function useLogout(options?: Omit<UseMutationOptions<void, AxiosError, void>, 'mutationKey' | 'mutationFn'>) {
  return useMutation<void, AxiosError, void>(() => logout(), options);
}
