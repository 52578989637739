import { EnrolmentLoginType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-login-type.enum';
import { EnrolmentRosterStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-roster-status.enum';
import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface UpdateEnrolmentRequest {
  numberOfLessons: number;
  startDate: number;
  endDate: number;
  status: EnrolmentStatus;
  rosterStatus: EnrolmentRosterStatus;
  loginType: EnrolmentLoginType;
}

const updateEnrolment = async (enrolmentId: string, request: UpdateEnrolmentRequest): Promise<void> => {
  const { data } = await axios.put<void>(`${config.apiUrl}/admin/hfs/enrolments/${enrolmentId}`, request);
  return data;
};

const useUpdateEnrolment = (
  enrolmentId: string,
  options?: Omit<UseMutationOptions<any, AxiosError, UpdateEnrolmentRequest>, 'mutationFn' | 'mutationKey'>,
) => {
  return useMutation<void, AxiosError, UpdateEnrolmentRequest>((request) => updateEnrolment(enrolmentId, request), options);
};

export default useUpdateEnrolment;
