import React, { PropsWithChildren } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { LessonSetResponse, useGetLessonSet } from '@hoot/hooks/api/lesson-sets/useGetLessonSet';
import { LessonSetRequest, useUpdateLessonSet } from '@hoot/hooks/api/lesson-sets/useUpdateLessonSet';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { ScheduledLessonSetStatus } from '@hoot/pages/lessons/enums';
import { useBlockIfDirty } from '../../../../contexts/BlockIfDirtyContext';

interface LessonSetDetailsContextProps {
  lessonSetId: string;
  isLoadingLessonSetDetails: boolean;
  lessonSetDetails: LessonSetResponse | undefined;
  isLessonSetDetailsPageDirty: boolean;
  lessonSetDetailsForm: UseFormReturn<LessonSetDetailsForm>;
  isSavingChanges: boolean;
  actions: {
    saveLessonSetDetails: () => void;
  };
}

export interface LessonSetDetailsForm {
  status: ScheduledLessonSetStatus;
  startDate: string | null;
  endDate: string | null;
}

const LessonSetDetailsContext = React.createContext<LessonSetDetailsContextProps>(undefined!);

export const useLessonSetDetailsContext = () => {
  const context = React.useContext(LessonSetDetailsContext);

  if (context === undefined) {
    throw new Error('useLessonSetDetailsContext must be used within a LessonSetDetailsContextProvider');
  }
  return context;
};

const LessonSetDetailsContextProvider = (props: PropsWithChildren<any>) => {
  const { children } = props;

  const { lessonSetId } = useParams<{ lessonSetId: string }>();
  const queryClient = useQueryClient();

  const { success, error } = useAlert();

  const updateSetRequest = useUpdateLessonSet(lessonSetId!);

  const lessonSetRequest = useGetLessonSet(lessonSetId!, {
    enabled: lessonSetId !== undefined,
    retry: false,
    onSuccess: (data) => {
      lessonSetDetailsForm.reset({
        status: data.status,
        startDate: data.startDate,
        endDate: data.endDate,
      });
    },
    onError: (err) => {
      console.error(err);
      error('Sorry. An error occurred while loading lesson set details.');
    },
  });

  const lessonSetDetailsForm = useForm<LessonSetDetailsForm>({
    defaultValues: {
      // These will get populated once the lesson set GET request comes back.
      status: ScheduledLessonSetStatus.Pending,
      startDate: null,
      endDate: null,
    },
  });

  const { setIsDirty } = useBlockIfDirty();
  setIsDirty(lessonSetDetailsForm.formState.isDirty);

  const _saveLessonSetDetails = (data: LessonSetDetailsForm) => {
    const request: LessonSetRequest = {
      status: data.status,
      startDate: data.startDate,
      endDate: data.endDate,
    };

    updateSetRequest.mutate(request, {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.LessonSet, lessonSetId]);
        success('Lesson set has been updated.');
      },
      onError: () => {
        error('There was an issue updating lesson set');
      },
    });
  };

  return (
    <LessonSetDetailsContext.Provider
      value={{
        lessonSetId: lessonSetId!,
        isLoadingLessonSetDetails: lessonSetRequest.isFetching,
        lessonSetDetails: lessonSetRequest.data,
        isLessonSetDetailsPageDirty: lessonSetDetailsForm.formState.isDirty,
        lessonSetDetailsForm,
        isSavingChanges: updateSetRequest.isLoading,
        actions: {
          saveLessonSetDetails: lessonSetDetailsForm.handleSubmit(_saveLessonSetDetails),
        },
      }}
    >
      {children}
    </LessonSetDetailsContext.Provider>
  );
};

export default LessonSetDetailsContextProvider;
