import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ResourceProgress } from '@hoot/hooks/api/user/student/instructional-plan/enums';

export interface UpdateLessonPlanBookRequest {
  books: { bookId: string; resourceProgress: ResourceProgress }[];
}

async function addUpdateLessonPlanBooks(studentProfileId: string, request: UpdateLessonPlanBookRequest): Promise<void> {
  await axios.put(`${config.apiUrl}/admin/student-profile/${studentProfileId}/update-resource-progress`, request);
}

export default function useUpdateLessonPlanBooks(
  studentProfileId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, UpdateLessonPlanBookRequest>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((request: UpdateLessonPlanBookRequest) => addUpdateLessonPlanBooks(studentProfileId, request), options);
}
