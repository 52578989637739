import { ArrowDropDown, ArrowDropUp, ChevronLeftOutlined } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Card, Menu, MenuItem, Stack, Toolbar, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import { FormState } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { NavLink, NavLinkProps, useNavigate } from 'react-router-dom';
import { HUBSPOT_CONTACT_URL, IS_LOCAL, ZENDESK_CONTACT_URL } from '@hoot/common/constants';
import { ParentDetailsForm } from '@hoot/components/Secure/Users/parent/form';
import CreatePasswordResetLinkItemButton from '@hoot/components/form/listItemButton/CreatePasswordResetLink';
import ConfirmDeleteDialog from '@hoot/components/modals/ConfirmDeleteDialog';
import Clipboard from '@hoot/components/ui/Clipboard';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { useImpersonateUser } from '@hoot/hooks/api/auth/useImpersonateUser';
import { ParentProfileResponse } from '@hoot/hooks/api/end-users/useGetParentProfile';
import { useReSendUserAccountCreation } from '@hoot/hooks/api/end-users/useReSendUserAccountCreation';
import { useSendUserPasswordReset } from '@hoot/hooks/api/end-users/useSendUserPasswordReset';
import useSoftDeleteUser from '@hoot/hooks/api/end-users/useSoftDeleteUser';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { ParentTabs } from '@hoot/pages/users/parents/components/ParentsTable/ParentsTable';
import { routes } from '@hoot/routes/routes';

const ParentActionButton = (props: { email: string | null; userId: string; hubspotId: number | null; zendeskId: number | null }) => {
  const { email, userId, hubspotId, zendeskId } = props;
  const { success, error } = useAlert();
  const [actionAnchorEl, setActionAnchorEl] = useState<Element | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const open = Boolean(actionAnchorEl);
  const auth = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const softDeleteUser = useSoftDeleteUser({
    onSuccess: () => {
      error(`Successfully soft-deleted user.`);
      queryClient.invalidateQueries([QueryKey.GetUser, userId]);
      queryClient.invalidateQueries(QueryKey.UserSearch);
      setShowConfirmDialog(false);
      navigate(routes.users.parents.url);
    },
    onError: () => error('Error soft-deleting user.'),
  });

  const handleSoftDeleteUser = () => {
    setShowConfirmDialog(true);
    setActionAnchorEl(null);
  };

  const handleContinueSoftDeleteUser = () => {
    if (userId) {
      softDeleteUser.mutate(userId);
    }
  };

  const passwordReset = useSendUserPasswordReset({
    onSuccess: () => success(`Password reset email sent to ${email}`),
    onError: () => error('Error sending the password reset email'),
  });

  const resendAccountCreation = useReSendUserAccountCreation({
    onSuccess: () => success(`Account creation email resent to ${email}`),
    onError: () => error('Error resending the account creation email'),
  });

  const impersonateUser = useImpersonateUser({
    onSuccess: (response) => {
      const url = IS_LOCAL ? 'http://' + response.impersonationUrl : response.impersonationUrl;
      window.open(url, '_blank');
    },
    onError: (err) => error(`Error impersonating user: ${err.message}`),
  });

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setActionAnchorEl(null);
  };

  const handleResetPassword = () => {
    if (email) {
      passwordReset.mutate(email);
    }
    setActionAnchorEl(null);
  };

  const handleResendAccountCreation = () => {
    if (email) {
      resendAccountCreation.mutate(email);
    }
    setActionAnchorEl(null);
  };

  const handleImpersonateUserClick = () => {
    impersonateUser.mutate(userId);
    setActionAnchorEl(null);
  };

  const handleGoToHubspotContact = () => {
    const url = HUBSPOT_CONTACT_URL + hubspotId;
    window.open(url, '_blank');

    setActionAnchorEl(null);
  };

  const handleGoToZendeskContact = () => {
    const url = ZENDESK_CONTACT_URL + zendeskId;
    window.open(url, '_blank');

    setActionAnchorEl(null);
  };

  return (
    <Stack>
      <Button
        type="button"
        color="inherit"
        size="large"
        variant="outlined"
        startIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={handleActionClick}
      >
        Action
      </Button>
      <Menu
        open={open}
        anchorEl={actionAnchorEl}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: 1 }}
      >
        <MenuItem disabled={!hubspotId} onClick={handleGoToHubspotContact}>
          Go to Hubspot Contact <OpenInNewIcon sx={{ marginLeft: '10px' }} />
        </MenuItem>
        <MenuItem disabled={!zendeskId} onClick={handleGoToZendeskContact}>
          Go to Zendesk Contact <OpenInNewIcon sx={{ marginLeft: '10px' }} />
        </MenuItem>
        <MenuItem onClick={handleResetPassword}>Reset Password</MenuItem>
        {email ? <CreatePasswordResetLinkItemButton email={email} /> : null}
        <MenuItem onClick={handleResendAccountCreation}>Resend Account Creation</MenuItem>
        {auth.hootEmployee?.canSoftDeleteUsers ? <MenuItem onClick={handleSoftDeleteUser}>Delete User</MenuItem> : null}
        {auth.hootEmployee?.canImpersonateUsers ? <MenuItem onClick={handleImpersonateUserClick}>Impersonate</MenuItem> : null}
      </Menu>

      {showConfirmDialog ? (
        <ConfirmDeleteDialog
          open={true}
          onCancel={() => setShowConfirmDialog(false)}
          onContinue={handleContinueSoftDeleteUser}
          title="Soft Delete user?"
          bodyText="Soft delete to disable and hide from App & Portal. For permanent deletion, submit Product task."
        />
      ) : null}
    </Stack>
  );
};

const CustomMenuLink = styled(NavLink)({
  width: '50%',
  textDecoration: 'none',
  marginLeft: '12px',
  marginRight: '12px',
  '&:first-of-type': {
    marginLeft: '0px',
  },
  '&:last-child': {
    marginRight: '0px',
  },
});

interface CustomNavLinkProps extends NavLinkProps, React.RefAttributes<HTMLAnchorElement> {
  tabNavigatedTo: boolean;
  children: React.ReactNode;
}

const CustomNavLink = (props: CustomNavLinkProps) => {
  const { tabNavigatedTo, children, ...rest } = props;
  return (
    <CustomMenuLink {...rest}>
      {({ isActive }) => (
        <Button
          fullWidth
          disableRipple
          sx={{
            textTransform: 'none',
            color: '#49454F',
            '&:hover': {
              backgroundColor: '#fff',
            },
          }}
        >
          <Box
            sx={{
              '&:after':
                isActive || tabNavigatedTo
                  ? {
                      content: '""',
                      position: 'absolute',
                      height: '2px',
                      background: '#1976D2',
                      width: 'calc(100% - 16px)',
                      borderRadius: '5px',
                      bottom: -6,
                      left: '8px',
                    }
                  : {},
            }}
            component="span"
          >
            {children}
          </Box>
        </Button>
      )}
    </CustomMenuLink>
  );
};

interface Props {
  parentProfile?: ParentProfileResponse;
  formState: FormState<ParentDetailsForm>;
  isDirty: boolean;
  userId?: string;
  hubspotId?: string | null;
  zendeskId?: string | null;
  tab: ParentTabs;
}

const ParentHeader = (props: Props) => {
  const { parentProfile, formState, isDirty, userId, tab } = props;
  //Note: need to manually call onSubmit as we have nested forms with the parent profile display (details/history)
  const hasErrors = Object.keys(formState.errors).length > 0;
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  const personalInfo = parentProfile?.personalInformation;
  const userExists = !!userId; // Note: both userId and parentProfile should be defined or undefined together
  const parentNumber = parentProfile?.parentPrefixedNumber;

  return (
    <Card sx={{ backgroundColor: '#FFF', width: 1 }}>
      <Stack direction="row" justifyContent="space-between" sx={{ width: '100%', height: 100, padding: '24px' }}>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Button type="button" color="inherit" variant="contained" size="large" startIcon={<ChevronLeftOutlined />} onClick={handleBackClick}>
            Back
          </Button>
          <Stack>
            <Typography variant="headlineLarge">
              {personalInfo?.firstName} {personalInfo?.lastName || 'New User'}
            </Typography>
            <Typography component="h6" variant="bodySmall">
              Parent: {parentNumber}
              <Clipboard toCopy={parentNumber ?? ''} iconProps={{ fontSize: 'small' }} />
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          {userExists ? (
            <ParentActionButton
              email={parentProfile?.personalInformation.emailAddress ?? null}
              userId={userId}
              hubspotId={parentProfile?.hubspotId || null}
              zendeskId={parentProfile?.zendeskId || null}
            />
          ) : null}
          <Button type="submit" size="large" variant="contained" disabled={!isDirty || hasErrors}>
            Save
          </Button>
        </Stack>
      </Stack>

      {userExists ? (
        <Stack sx={{ height: 50 }}>
          <Toolbar
            variant="dense"
            sx={{
              backgroundColor: '#FFFFFF',
            }}
          >
            <CustomNavLink tabNavigatedTo={tab === ParentTabs.Details} to={routes.users.parents.details.url(userId)}>
              Details
            </CustomNavLink>
            <CustomNavLink tabNavigatedTo={tab === ParentTabs.History} to={routes.users.parents.history.url(userId)}>
              History
            </CustomNavLink>
          </Toolbar>
        </Stack>
      ) : null}
    </Card>
  );
};

export default ParentHeader;
