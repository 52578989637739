import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { generatePath } from 'react-router-dom';

export const routes = {
  home: {
    path: '/',
    url: '/',
  },
  users: {
    path: 'users/*',
    url: '/users',
    parents: {
      path: 'parents/*',
      url: '/users/parents',
      urlWithId: (userId: string) => generatePath('/users/:userId/parents', { userId }),
      create: {
        path: 'create',
        url: '/users/parents/create',
      },
      details: {
        path: 'details',
        url: (parentId: string) => generatePath('/users/parents/:parentId/details', { parentId }),
      },
      history: {
        path: 'history',
        url: (parentId: string) => generatePath('/users/parents/:parentId/history', { parentId }),
      },
    },
    students: {
      path: 'students/*',
      url: '/users/students',
      create: {
        path: 'create',
        url: '/users/students/create',
      },
      details: {
        path: 'details',
        url: (studentProfileId: string) => generatePath('/users/students/:studentProfileId/details', { studentProfileId }),
      },
      timeline: {
        path: 'timeline',
        url: (studentProfileId: string) => generatePath('/users/students/:studentProfileId/timeline', { studentProfileId }),
      },
      schedule: {
        url: (studentProfileId: string) => generatePath('/users/students/:studentProfileId/schedule', { studentProfileId }),
        path: 'schedule',
      },
      instructionalPlan: {
        url: (studentProfileId: string) => generatePath('/users/students/:studentProfileId/instructional-plan', { studentProfileId }),
        path: 'instructional-plan',
      },
      lessonReviews: {
        path: 'lesson-reviews',
        url: (studentProfileId: string) => generatePath('/users/students/:studentProfileId/lesson-reviews', { studentProfileId }),
        urlWithId: (studentProfileId: string, lessonReviewId: string) =>
          generatePath('/users/students/:studentProfileId/lesson-reviews/:lessonReviewId', {
            studentProfileId,
            lessonReviewId,
          }),
      },
      lessonNotes: {
        path: 'lesson-notes',
        url: (studentProfileId: string) => generatePath('/users/students/:studentProfileId/lesson-notes', { studentProfileId }),
        urlWithId: (studentProfileId: string, lessonNotesId: string) =>
          generatePath('/users/students/:studentProfileId/lesson-notes/:lessonNotesId', {
            studentProfileId,
            lessonNotesId,
          }),
      },
      tasks: {
        url: (studentProfileId: string) => generatePath('/users/students/:studentProfileId/tasks', { studentProfileId }),
        path: 'tasks',
      },
      assessments: {
        url: (studentProfileId: string) => generatePath('/users/students/:studentProfileId/assessments', { studentProfileId }),
        path: 'assessments',
      },
      history: {
        url: (studentProfileId: string) => generatePath('/users/students/:studentProfileId/history', { studentProfileId }),
        path: 'history',
      },
      specialIndicators: {
        url: (studentProfileId: string) => generatePath('/users/students/:studentProfileId/special-indicators', { studentProfileId }),
        path: 'special-indicators',
      },
    },
    districtReps: {
      path: 'district-reps/*',
      url: '/users/district-reps',
      urlWithId: (districtRepsId: string) => generatePath('/users/district-reps/:districtRepsId', { districtRepsId }),
      create: {
        path: 'create',
        url: '/users/district-reps/create',
      },
      details: {
        path: 'details',
        url: (districtRepresentativeId: string) =>
          generatePath('/users/district-reps/:districtRepresentativeId/details', { districtRepresentativeId }),
      },
      history: {
        path: 'history',
        url: (districtRepresentativeId: string) =>
          generatePath('/users/district-reps/:districtRepresentativeId/history', { districtRepresentativeId }),
      },
    },
    teachers: {
      path: 'teachers/*',
      url: '/users/teachers',
      create: {
        path: 'create',
        url: '/users/teachers/create',
      },
      details: {
        path: 'details',
        url: (teacherAccountId: string) => generatePath('/users/teachers/:teacherAccountId/details', { teacherAccountId }),
      },
      availability: {
        path: 'availability',
        url: (teacherAccountId: string) => generatePath('/users/teachers/:teacherAccountId/availability', { teacherAccountId }),
      },
      schedule: {
        path: 'schedule',
        url: (teacherAccountId: string) => generatePath('/users/teachers/:teacherAccountId/schedule', { teacherAccountId }),
      },
      shifts: {
        path: 'shifts/*',
        create: {
          path: 'create',
          url: '/users/teachers/shifts/create',
          urlWithId: (teacherAccountId: string) => generatePath('/users/teachers/:teacherAccountId/shifts/create', { teacherAccountId }),
        },
        details: {
          path: 'details',
          url: (teacherAccountId: string, shiftId: string) =>
            generatePath('/users/teachers/:teacherAccountId/shifts/:shiftId/details', { teacherAccountId, shiftId }),
        },
        history: {
          path: 'history',
          url: (teacherAccountId: string, shiftId: string) =>
            generatePath('/users/teachers/:teacherAccountId/shifts/:shiftId/history', { teacherAccountId, shiftId }),
        },
      },
      blocks: {
        path: 'blocks/*',
        create: {
          path: 'create',
          url: '/users/teachers/blocks/create',
          urlWithId: (teacherAccountId: string) => generatePath('/users/teachers/:teacherAccountId/blocks/create', { teacherAccountId }),
        },
        details: {
          path: 'details',
          url: (teacherAccountId: string, blockId: string) =>
            generatePath('/users/teachers/:teacherAccountId/blocks/:blockId/details', { teacherAccountId, blockId }),
        },
        history: {
          path: 'history',
          url: (teacherAccountId: string, blockId: string) =>
            generatePath('/users/teachers/:teacherAccountId/blocks/:blockId/history', { teacherAccountId, blockId }),
        },
      },
      availabilityExceptions: {
        path: 'availability-exceptions',
        url: (teacherAccountId: string) => generatePath('/users/teachers/:teacherAccountId/availability-exceptions', { teacherAccountId }),
      },
      reliability: {
        path: 'reliability',
        url: (teacherAccountId: string) => generatePath('/users/teachers/:teacherAccountId/reliability', { teacherAccountId }),
      },
      tasks: {
        path: 'tasks',
        url: (teacherAccountId: string) => generatePath('/users/teachers/:teacherAccountId/tasks', { teacherAccountId }),
      },
      history: {
        path: 'history',
        url: (teacherAccountId: string) => generatePath('/users/teachers/:teacherAccountId/history', { teacherAccountId }),
      },
    },
    employees: {
      path: 'employees/*',
      url: '/users/employees',
    },
  },
  reporting: {
    path: 'reporting',

    metabase: {
      path: 'metabase/*',
      url: '/reporting/metabase',
      urlWithId: (metabaseReportId: number) => generatePath('/reporting/metabase/:metabaseReportId', { metabaseReportId: `${metabaseReportId}` }),
    },

    studentLevels: {
      path: 'student-levels',
      url: '/reporting/student-levels',
    },
  },

  library: {
    path: 'library/*',
    url: '/library',
    book: {
      path: ':bookId',
      url: (bookId: string) => generatePath('/library/:bookId', { bookId: bookId }),
    },
  },
  availabilityImportExport: {
    path: 'availability-import-export/*',
    url: '/availability-import-export',
  },
  partnerManagement: {
    path: 'partner-management/*',
    url: '/partner-management',
  },
  liveLessons: {
    path: 'live-lessons/*',
    subRequired: {
      url: '/live-lessons/sub-required',
    },
    attendance: {
      url: '/live-lessons/attendance',
    },
    outsideShift: {
      url: '/live-lessons/outside-shift',
    },
  },
  lessons: {
    path: 'lessons/*',
    url: '/lessons',
    create: {
      path: 'create',
      url: '/lessons/create',
    },
    // TODO: JVDV Remove when removing v1 Edit Details page
    edit: {
      path: 'edit',
      url: (lessonId: string) => generatePath('/lessons/:lessonId/edit', { lessonId }),
    },
    details: {
      path: 'details',
      url: (lessonId: string) => generatePath('/lessons/:lessonId/details', { lessonId }),
    },
    history: {
      path: 'history',
      url: (lessonId: string) => generatePath('/lessons/:lessonId/history', { lessonId }),
    },
    comments: {
      path: 'comments',
      url: (lessonId: string) => generatePath('/lessons/:lessonId/comments', { lessonId }),
    },
    dailyRecordings: {
      path: 'daily-recording',
      url: (lessonId: string) => generatePath('/lessons/:lessonId/daily-recording', { lessonId }),
    },
    visualizer: {
      path: 'visualizer',
      url: (lessonId: string) => generatePath('/lessons/:lessonId/visualizer', { lessonId }),
    },
  },
  districtsSchools: {
    path: 'districts-schools/*',
    url: '/districts-schools',
    urlWithId: (locationId: string) => generatePath('/districts-schools/:locationId', { locationId }),
    create: {
      path: 'create',
      url: (locationType: LocationType) => generatePath(`/districts-schools/create?locationType=${locationType}`, { locationType }),
    },
    summary: {
      path: 'summary',
      url: (locationId: string) => `${routes.districtsSchools.urlWithId(locationId)}/summary`,
    },
    details: {
      path: 'details',
      url: (locationId: string) => `${routes.districtsSchools.urlWithId(locationId)}/details`,
    },
    prodEnrol: {
      path: 'prod-enrol',
      url: (locationId: string) => `${routes.districtsSchools.urlWithId(locationId)}/prod-enrol`,
    },
    students: {
      path: 'students',
      url: (locationId: string) => `${routes.districtsSchools.urlWithId(locationId)}/students`,
    },
    products: {
      path: 'products',
      url: (locationId: string, productId: string) =>
        generatePath(`${routes.districtsSchools.urlWithId(locationId)}/products/:productId`, { productId }),
      details: {
        path: 'details',
        url: (locationId: string, productId: string) => `${routes.districtsSchools.products.url(locationId, productId)}/details`,
      },
      capacity: {
        path: 'capacity',
        url: (locationId: string, productId: string) => `${routes.districtsSchools.products.url(locationId, productId)}/capacity`,
      },
      qualityMonitoring: {
        path: 'quality-monitoring',
        url: (locationId: string, productId: string) =>
          generatePath(`${routes.districtsSchools.urlWithId(locationId)}/products/:productId/quality-monitoring`, { productId }),
      },
    },
    enrolments: {
      path: 'enrolments',
      url: (locationId: string) => `${routes.districtsSchools.urlWithId(locationId)}/enrolments`,
      create: {
        path: 'create',
        url: (locationId: string, locationType: LocationType, name: string, friendlyId: string, isBillableEntity: boolean, productId?: string) =>
          generatePath(
            `${routes.districtsSchools.urlWithId(
              locationId,
            )}/enrolments/create?locationType=${locationType}&name=${name}&friendlyId=${friendlyId}&isBillableEntity=${isBillableEntity}&productId=${
              productId ?? ''
            }`,
          ),
      },
      details: {
        path: 'details',
        url: (locationId: string, enrolmentId: string) =>
          generatePath(`${routes.districtsSchools.urlWithId(locationId)}/enrolments/:enrolmentId/details`, { enrolmentId }),
      },
      capacity: {
        path: 'capacity',
        url: (locationId: string, enrolmentId: string) =>
          generatePath(`${routes.districtsSchools.urlWithId(locationId)}/enrolments/:enrolmentId/capacity`, { enrolmentId }),
      },
      qualityMonitoring: {
        path: 'quality-monitoring',
        url: (locationId: string, enrolmentId: string) =>
          generatePath(`${routes.districtsSchools.urlWithId(locationId)}/enrolments/:enrolmentId/quality-monitoring`, { enrolmentId }),
      },
    },
  },
  lessonSets: {
    path: 'lesson-sets/*',
    url: '/lesson-sets',
    urlWithId: (lessonSetId: string) => generatePath('/lesson-sets/:lessonSetId', { lessonSetId }),
    details: {
      path: 'details',
      url: (lessonSetId: string) => `${routes.lessonSets.urlWithId(lessonSetId)}/details`,
    },
    qualityMonitoring: {
      path: 'quality-monitoring',
      url: (lessonSetId: string) => `${routes.lessonSets.urlWithId(lessonSetId)}/quality-monitoring`,
    },
    teacherAssignment: {
      path: 'teacher-assignment',
      url: (lessonSetId: string) => `${routes.lessonSets.urlWithId(lessonSetId)}/teacher-assignment`,
    },
    students: {
      path: 'students',
      lessonReviews: {
        path: 'lesson-reviews',
        url: (studentProfileId: string) => generatePath(`lesson-sets/students/:studentProfileId/lesson-reviews`, { studentProfileId }),
      },
    },
  },
  invoicing: {
    path: 'invoicing/*',
    url: '/invoicing',
    tasks: {
      path: '/tasks/:periodId',
      url: (periodId: string) => generatePath('/invoicing/tasks/:periodId', { periodId }),
    },
  },
  tasks: {
    path: 'tasks',
    url: '/tasks',
    urlWithId: (qualityAssuranceTaskId: string) => generatePath('/tasks/:qualityAssuranceTaskId', { qualityAssuranceTaskId }),
    details: {
      path: 'details',
      url: (qualityAssuranceTaskId: string) => generatePath('/tasks/:qualityAssuranceTaskId/details', { qualityAssuranceTaskId }),
    },
    history: {
      path: 'history',
      url: (qualityAssuranceTaskId: string) => generatePath('/tasks/:qualityAssuranceTaskId/history', { qualityAssuranceTaskId }),
    },
  },
  shifts: {
    path: 'shifts',
    url: '/shifts',
  },
  blocks: {
    path: 'blocks',
    url: '/blocks',
  },
};
