import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface Props {
  children: React.ReactNode;
}

interface Values {
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

const BlockIfDirtyContext = React.createContext<Values>(undefined!);

const BlockIfDirtyProvider = (props: Props) => {
  const { children } = props;
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  return <BlockIfDirtyContext.Provider value={{ isDirty, setIsDirty }}>{children}</BlockIfDirtyContext.Provider>;
};

const useBlockIfDirty = () => {
  const context = React.useContext(BlockIfDirtyContext);

  if (context === undefined) {
    throw new Error('useBlockIfDirty must be used within a LessonNotesProvider');
  }

  return context;
};

export { BlockIfDirtyProvider, useBlockIfDirty };
