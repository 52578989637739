import { CircularProgress } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import useCreateDistrictRepComment from '@hoot/hooks/api/user/district-representative/useCreateDistrictRepComment';
import useGetDistrictRepHistory from '@hoot/hooks/api/user/district-representative/useGetDistrictRepTimeline';
import useGetDistrictRepresentative from '@hoot/hooks/api/user/district-representative/useGetDistrictRepresentative';
import { CommentSubmission } from '@hoot/interfaces/history';
import { CommonHistoryTimeline } from '@hoot/pages/users/common/CommonHistoryTimeline';

const DistrictRepHistoryTab = () => {
  const { districtRepresentativeId } = useParams() as { districtRepresentativeId: string };

  const { error, success } = useAlert();
  const queryClient = useQueryClient();

  const districtRepAccountResponse = useGetDistrictRepresentative(districtRepresentativeId, {
    onError: () => {
      error('There was an issue fetching the district representative');
    },
  });

  const name = `${districtRepAccountResponse?.data?.firstName ?? '<first name>'} ${districtRepAccountResponse?.data?.lastName ?? '<last name>'}`;

  const historyResponse = useGetDistrictRepHistory(districtRepresentativeId, {
    cacheTime: 0,
    enabled: !!districtRepAccountResponse.data,
    onError: (err) => {
      const message =
        err.response && err.response.data?.message?.trim().length > 0
          ? err.response.data.message
          : `There was an issue getting the comments for ${name}!`;
      error(message);
      console.error(err);
    },
  });

  const createComment = useCreateDistrictRepComment(districtRepresentativeId, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.GetDistrictRepHistory, districtRepresentativeId]);
      success(`Successfully added an item to ${name}'s history timeline!`);
    },
    onError: (err) => {
      error(`There was an issue submitting the item for ${name}!`);
      console.error(err);
    },
  });

  if (historyResponse.isLoading) {
    return <CircularProgress />;
  }

  const existingItems = historyResponse.data?.historyItems ?? [];

  const handleCommentSubmit = (comment: CommentSubmission, cb: () => void) => {
    createComment.mutate(comment, {
      onSettled: () => {
        cb();
      },
    });
  };

  return <CommonHistoryTimeline historyItems={existingItems} handleCommentSubmit={handleCommentSubmit} />;
};

export default DistrictRepHistoryTab;
