import { CircularProgress, Stack } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ParentDetailsForm } from '@hoot/components/Secure/Users/parent/form';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import useGetParentProfile from '@hoot/hooks/api/end-users/useGetParentProfile';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import useCreateParentProfile, { CreateParentProfileResponse } from '@hoot/hooks/api/user/parent/useCreateParentProfile';
import useUpdateParentProfile from '@hoot/hooks/api/user/parent/useUpdateParentProfile';
import { ParentTabs } from '@hoot/pages/users/parents/components/ParentsTable/ParentsTable';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { routes } from '../../../routes/routes';
import ParentHeader from './ParentHeader';
import ParentHistoryTab from './ParentHistoryTab';
import ParentsDetailsTab from './ParentsDetailsTab';
import useParentDetailsForm from './useParentDetailsForm';

interface Props {
  tab: ParentTabs;
}

const ParentsPage = (props: Props) => {
  const { userId } = useParams() as { userId: string };
  const { isDirty, setIsDirty } = useBlockIfDirty();
  const { tab } = props;
  usePageTitle('Parent | Admin Portal');
  const isEditMode = !!userId;
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { success, error } = useAlert();
  const {
    isLoading,
    isError,
    data: parentProfile,
  } = useGetParentProfile(userId!, {
    enabled: !!userId,
    retry: false,
  });

  const updateProfile = useUpdateParentProfile(userId);
  const createProfile = useCreateParentProfile();

  const { control, handleSubmit, formState, watch, setValue, resetForm } = useParentDetailsForm(parentProfile);

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty, setIsDirty]);

  useEffect(() => {
    resetForm();
  }, [resetForm, parentProfile]);

  const onSubmit = (data: ParentDetailsForm) => {
    data.accountId = data.accountId !== '' ? data.accountId : null;
    if (isEditMode) {
      updateProfile.mutate(data, {
        onSuccess: () => {
          success(`Parent profile updated!`);
          setIsDirty(false);
          queryClient.invalidateQueries([QueryKey.GetParentProfile, userId]);
        },
        onError: (errorData) => {
          console.error(errorData);
          error(`There was an error, the parent profile has not been edited!`);
        },
      });
    } else {
      createProfile.mutate(data, {
        onSuccess: () => {
          setIsDirty(false);
          success(`Parent profile created!`);
        },
        onError: (errorData) => {
          console.error(errorData);
          error(`There was an error, the parent profile has not been created!`);
        },
        onSettled: (response?: AxiosResponse<CreateParentProfileResponse>) => {
          /*
           * Note: we need to call navigate in the onSettled function, otherwise the navigate hook will be blocked by
           * the useBlocker() hook.
           */
          if (response?.data.id) {
            navigate(routes.users.parents.details.url(response.data.id));
          }
        },
      });
    }
  };

  if (isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (isError) {
    return <Stack alignItems="center">Failed to load the page!</Stack>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="parent-details-form">
      <Stack spacing={3}>
        <ParentHeader parentProfile={parentProfile} formState={formState} isDirty={isDirty} userId={userId} tab={tab} />
        {tab === ParentTabs.Details ? (
          <ParentsDetailsTab
            userId={userId}
            parentProfile={parentProfile}
            control={control}
            formState={formState}
            watch={watch}
            setValue={setValue}
            isEditMode={isEditMode}
            setFormIsDirty={setIsDirty}
          />
        ) : null}
        {tab === ParentTabs.History && parentProfile ? <ParentHistoryTab parentProfile={parentProfile} /> : null}
      </Stack>
    </form>
  );
};

export default ParentsPage;
