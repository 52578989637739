import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  LinearProgress,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DateTime, Info } from 'luxon';
import { WeekdayNumbers } from 'luxon/src/datetime';
import { Fragment, useMemo, useState } from 'react';
import DropdownButton, { DropdownButtonMenuItem, DropdownButtonProps } from '@hoot/components/form/DropdownButton';
import { LessonSetLesson } from '@hoot/hooks/api/lesson-sets/useGetLessonSetLessons';
import { useLessonSetDetailsContext } from '@hoot/pages/lesson-sets/details/context/LessonSetDetailsContext';
import { useLessonSetTeacherAssignmentContext } from '@hoot/pages/lesson-sets/details/context/LessonSetTeacherAssignmentContext';
import LessonSetLessonGrouping from '@hoot/pages/lesson-sets/details/teacher-assignment-tab/LessonSetLessonGrouping';
import LessonSetLessonGroupingHeader from '@hoot/pages/lesson-sets/details/teacher-assignment-tab/LessonSetLessonGroupingHeader';
import LessonSetWizardModal, {
  LessonSetTeacherAssignmentWizardStepId,
} from '@hoot/pages/lesson-sets/details/teacher-assignment-tab/lesson-set-wizard-modal/LessonSetWizardModal';
import { ScheduledLessonSetStatus } from '@hoot/pages/lessons/enums';

export interface DayAndTimeLessonGrouping {
  dayOfWeek: WeekdayNumbers;
  // e.g. "13:00:00.000-05:00" -> 1:00pm CST
  isoTimeOfDay: string;
  lessons: LessonSetLesson[];
}

enum TeacherAssignmentActionItemIds {
  AssignTeacher = 'AssignTeacher',
  EditTime = 'EditTime',
}

const LessonSetTeacherAssignment = () => {
  const { lessonSetDetails } = useLessonSetDetailsContext();

  const {
    isLoadingLessonSetLessons,
    dayAndTimeLessonGroups: lessonGroups,
    actions: { removeTeacherFromLessonGroup, onApplyLessonGroupTimeChanges, onApplyTeacherAssignmentChanges },
  } = useLessonSetTeacherAssignmentContext();

  const [selectedLessonGroupIndexes, setSelectedLessonGroupIndexes] = useState<Set<number>>(new Set());
  const [showEditLessonGroupWizardModal, setShowEditLessonGroupWizardModal] = useState(false);
  const [lessonGroupWizardType, setLessonGroupWizardType] = useState<LessonSetTeacherAssignmentWizardStepId>();

  const [removeTeacherFromGroupIndex, setRemoveTeacherFromGroupIndex] = useState<number>();

  const removeTeacherDialogLabels = useMemo(() => {
    if (removeTeacherFromGroupIndex === undefined) {
      return undefined;
    }
    const lessonGroup = lessonGroups[removeTeacherFromGroupIndex];

    return {
      dayOfWeek: Info.weekdays('long')[lessonGroup.dayOfWeek - 1],
      timeOfDay: DateTime.fromISO(lessonGroup.isoTimeOfDay).toFormat('h:mm a (ZZZZZ)'),
    };
  }, [removeTeacherFromGroupIndex, lessonGroups]);

  const selectedLessonGroups = useMemo(() => {
    return Array.from(selectedLessonGroupIndexes).map((i) => lessonGroups[i]);
  }, [selectedLessonGroupIndexes, lessonGroups]);

  const areAllSelectedGroupsUnassigned = useMemo(() => {
    return selectedLessonGroups
      .flatMap((x) => x.lessons)
      .filter((x) => x.status === ScheduledLessonStatus.Scheduled)
      .every((x) => !x.teacher);
  }, [selectedLessonGroups]);

  const doAllSelectedGroupsHaveScheduledLessons = useMemo(() => {
    return selectedLessonGroups.every((group) => group.lessons.length > 0);
  }, [selectedLessonGroups]);

  const doWeHaveMoreThanOneTeacher = useMemo(() => {
    const teacherIds = selectedLessonGroups
      .flatMap((x) => x.lessons)
      .filter((x) => x.status === ScheduledLessonStatus.Scheduled)
      .reduce((acc, lesson) => {
        if (lesson.teacher?.id && !acc.includes(lesson.teacher.id)) {
          acc.push(lesson.teacher.id);
        }
        return acc;
      }, [] as string[]);
    return teacherIds.length > 1;
  }, [selectedLessonGroups]);

  // We can only assign a teacher when all selected groups are unassigned.
  const canAssignTeacher = selectedLessonGroupIndexes.size > 0 && doAllSelectedGroupsHaveScheduledLessons && areAllSelectedGroupsUnassigned;

  // We can only edit lesson times when all selected groups are unassigned or assigned under the same teacher.
  const canEditTime = selectedLessonGroupIndexes.size > 0 && doAllSelectedGroupsHaveScheduledLessons && !doWeHaveMoreThanOneTeacher;

  const canEditTimeOrAssignTeacher = canAssignTeacher || canEditTime;

  const actionItems: DropdownButtonMenuItem[] = [
    {
      id: TeacherAssignmentActionItemIds.AssignTeacher,
      label: 'Assign Teacher to Lessons',
      MenuItemProps: {
        disabled: !canAssignTeacher,
      },
    },
    {
      id: TeacherAssignmentActionItemIds.EditTime,
      label: 'Edit Time',
      MenuItemProps: {
        disabled: !canEditTime,
      },
    },
  ];

  const isSelectable =
    [ScheduledLessonSetStatus.Pending, ScheduledLessonSetStatus.Rescheduling].includes(lessonSetDetails!.status) &&
    lessonGroups.every((lg) => lg.lessons.some((l) => l.status === ScheduledLessonStatus.Scheduled));

  const isCheckedAllGroups = () => {
    const newSelectedLessonGroupIndexes = new Set(lessonGroups.keys());
    return (
      [...newSelectedLessonGroupIndexes].every((value) => selectedLessonGroupIndexes.has(value)) &&
      newSelectedLessonGroupIndexes.size === selectedLessonGroupIndexes.size
    );
  };

  const onToggleAllSelectLessonGroup = () => {
    const newSelectedLessonGroupIndexes = new Set(lessonGroups.keys());
    if (isCheckedAllGroups()) {
      setSelectedLessonGroupIndexes(new Set());
    } else {
      setSelectedLessonGroupIndexes(newSelectedLessonGroupIndexes);
    }
  };

  const onToggleSelectLessonGroup = (index: number) => {
    const newSelectedLessonGroupIndexes = new Set(selectedLessonGroupIndexes);
    if (newSelectedLessonGroupIndexes.has(index)) {
      newSelectedLessonGroupIndexes.delete(index);
    } else {
      newSelectedLessonGroupIndexes.add(index);
    }
    setSelectedLessonGroupIndexes(newSelectedLessonGroupIndexes);
  };

  const onActionItemClicked: DropdownButtonProps['onMenuItemClicked'] = (id) => {
    switch (id) {
      case TeacherAssignmentActionItemIds.AssignTeacher:
        setLessonGroupWizardType(LessonSetTeacherAssignmentWizardStepId.TeacherSelectionCheck);
        break;
      case TeacherAssignmentActionItemIds.EditTime:
        setLessonGroupWizardType(LessonSetTeacherAssignmentWizardStepId.TimeSelection);
        break;
    }
    setShowEditLessonGroupWizardModal(true);
  };

  const _onApplyLessonGroupTimeChanges = (isoTimeOfDay: string) => {
    onApplyLessonGroupTimeChanges(selectedLessonGroupIndexes, isoTimeOfDay);
    // NOTE: Changing a lesson group's time could impact the ordering of the list (because the list is sorted by DOW and
    // time-of-day). Any checkbox "selections" that we had before this action would then be obsolete, so clear them out.
    setSelectedLessonGroupIndexes(new Set());
  };

  const onDismissLessonSetWizardModal = () => {
    setShowEditLessonGroupWizardModal(false);
    setLessonGroupWizardType(undefined);
  };

  const onRemoveTeacher = (index: number) => {
    setRemoveTeacherFromGroupIndex(index);
  };

  const onDismissRemoveTeacherConfirmationModal = () => {
    setRemoveTeacherFromGroupIndex(undefined);
  };

  const onConfirmRemoveTeacher = () => {
    removeTeacherFromLessonGroup(removeTeacherFromGroupIndex!);
    onDismissRemoveTeacherConfirmationModal();
  };

  const _onApplyLessonSetWizardChanges = (lessons: LessonSetLesson[]) => {
    onDismissLessonSetWizardModal();
    onApplyTeacherAssignmentChanges(lessons);
    setSelectedLessonGroupIndexes(new Set());
  };

  return (
    <>
      <Card>
        <Box height="4px">
          <Fade in={isLoadingLessonSetLessons}>
            <LinearProgress color="secondary" />
          </Fade>
        </Box>
        <CardContent>
          <Stack gap={3}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={3}>
              <Typography variant="titleLarge">Scheduled Days</Typography>
              <DropdownButton
                label="Actions"
                disabled={!canEditTimeOrAssignTeacher}
                menuItems={actionItems}
                onMenuItemClicked={onActionItemClicked}
              />
            </Stack>
            <Stack sx={(theme) => ({ border: `1px solid ${theme.palette.divider}`, borderRadius: '4px' })}>
              <LessonSetLessonGroupingHeader
                isChecked={isCheckedAllGroups()}
                disabled={!isSelectable}
                onToggleAllSelectLessonGroup={onToggleAllSelectLessonGroup}
              />
              <Divider sx={(theme) => ({ borderColor: `${theme.palette.divider}` })} />
              {lessonGroups.map((group, index) => (
                <Fragment key={`lesson-group-${group.dayOfWeek}-${group.isoTimeOfDay}`}>
                  <LessonSetLessonGrouping
                    index={index}
                    lessonSetStatus={lessonSetDetails!.status}
                    dayAndTimeLessonGrouping={group}
                    isChecked={selectedLessonGroupIndexes.has(index)}
                    onToggleSelectLessonGroup={onToggleSelectLessonGroup}
                    onRemoveTeacher={onRemoveTeacher}
                  />
                  {index < lessonGroups.length - 1 && <Divider sx={(theme) => ({ borderColor: `${theme.palette.divider}` })} />}
                </Fragment>
              ))}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      {/* Remove Teacher from DoW confirmation modal. */}
      <Dialog open={removeTeacherFromGroupIndex !== undefined} onClose={onDismissRemoveTeacherConfirmationModal}>
        <DialogTitle>Remove Teacher from Lessons</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove the teacher(s) assigned to the scheduled lessons on{' '}
            <b>
              {removeTeacherDialogLabels?.dayOfWeek ?? '-'} at {removeTeacherDialogLabels?.timeOfDay ?? '-'}
            </b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onDismissRemoveTeacherConfirmationModal} size="large" autoFocus>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={onConfirmRemoveTeacher} size="large">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      {showEditLessonGroupWizardModal && selectedLessonGroups.length > 0 ? (
        <LessonSetWizardModal
          show={showEditLessonGroupWizardModal && selectedLessonGroups.length > 0}
          lessonGroupWizardType={lessonGroupWizardType}
          onApply={_onApplyLessonSetWizardChanges}
          onApplyTimeChange={_onApplyLessonGroupTimeChanges}
          onDismiss={onDismissLessonSetWizardModal}
          student={lessonSetDetails!.student}
          lessonSet={lessonSetDetails!}
          selectedLessonGroups={selectedLessonGroups}
          areAllSelectedGroupsUnassigned={areAllSelectedGroupsUnassigned}
        />
      ) : null}
    </>
  );
};

export default LessonSetTeacherAssignment;
