import { StudentAttendanceRate } from '@hoot-reading/hoot-core/dist/enums/hfs/student-attendance-rate.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import { OrderBy } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';

export enum EnrolmentQualityMonitoringOrderColumn {
  StudentNumber = 'studentNumber',
  StudentStatus = 'studentStatus',
  StudentGrade = 'studentGrade',
  LessonSetNumbers = 'lessonSetNumbers',
  TotalLessons = 'scheduledLessons',
  PaidLessons = 'paidLessons',
  CompletedLessons = 'completedLessons',
  CancelledLessons = 'cancelledLessons',
  StudentAbsences = 'studentAbsences',
  ScheduledLessonsRemaining = 'scheduledLessonsRemaining',
  StudentAttendanceRate = 'studentAttendanceRate',
  TeacherCount = 'teacherCount',
  RegularTeacherCount = 'regularTeacherCount',
  SubstituteTeacherCount = 'substituteTeacherCount',
  CompletedAssessmentLessons = 'completedAssessmentLessons',
  StartedAssessments = 'startedAssessments',
  CompletedAssessments = 'completedAssessments',
}

export interface EnrolmentQualityMonitoringLessonSet {
  lessonSetId: string;
  lessonSetNumber: string;
}

export interface EnrolmentQualityMonitoringResponse {
  studentProfileId: string;
  studentNumber: string;
  studentPrefixedNumber: string;
  studentStatus: StudentStatus;
  studentGrade: string;
  lessonSets: EnrolmentQualityMonitoringLessonSet[];
  scheduledLessons: string;
  completedLessons: string;
  cancelledLessons: string;
  studentAbsences: string;
  scheduledLessonsRemaining: string;
  studentAttendanceRate: string;
  teacherCount: string;
  regularTeacherCount: string;
  substituteTeacherCount: string;
  completedAssessmentLessons: string;
  startedAssessments: string;
  completedAssessments: string;
  paidLessons: string;
}

export interface EnrolmentQualityMonitoringQuery {
  page: number;
  pageSize: number;
  orderBy: OrderBy;
  orderColumn: EnrolmentQualityMonitoringOrderColumn;
  studentStatus?: StudentStatus[];
  studentAttendanceRate?: StudentAttendanceRate[];
  schoolId?: string[];
}

export interface EnrolmentQualitySummary {
  totalStudents: string;
  totalScheduledLessons: string;
  totalRegularTeachers: string;
  averageStudentAttendance: string;
  theyPay: string;
  gradesIncluded: string;
}

const getEnrolmentQualityMonitoring = async (
  enrolmentId: string,
  query: EnrolmentQualityMonitoringQuery,
): Promise<GenericPaginatedResponse<EnrolmentQualityMonitoringResponse, EnrolmentQualitySummary>> => {
  const { data } = await axios.get<GenericPaginatedResponse<EnrolmentQualityMonitoringResponse, EnrolmentQualitySummary>>(
    `${config.apiUrl}/admin/hfs/enrolments/${enrolmentId}/quality-monitoring`,
    { params: query },
  );
  return data;
};

export default function useGetEnrolmentQualityMonitoring(
  enrolmentId: string,
  query: EnrolmentQualityMonitoringQuery,
  options?: Omit<
    UseQueryOptions<GenericPaginatedResponse<EnrolmentQualityMonitoringResponse, EnrolmentQualitySummary>, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery([QueryKey.GetEnrolmentQualityMonitoring, enrolmentId, query], () => getEnrolmentQualityMonitoring(enrolmentId, query), options);
}
