import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { TeacherCertificationArea } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-certification-area.enum';
import { TeacherSpecializedTraining } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-specialized-training.enum';
import { TeacherStage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-stage.enum';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import useGetTeacherAccount, { TeacherAccount } from '@hoot/hooks/api/user/teacher/useGetTeacherAccount';
import useUpdateTeacherAccountAndUser, { UpdateTeacherAccountWithUserDetails } from '@hoot/hooks/api/user/teacher/useUpdateTeacherAccountAndUser';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { BackgroundCheck } from '@hoot/interfaces/teacher';
import Availability from '@hoot/pages/lessons/Availability';
import { AvailabilityException } from '@hoot/pages/lessons/Availability/AvailabilityExceptionV2';
import { AccountType, ActionUnavailableModal } from '@hoot/pages/users/common/ActionUnavailableModal';
import { TeacherAccountAndUserForm } from '@hoot/pages/users/interfaces/form';
import TeacherReliabilityTab from '@hoot/pages/users/teachers/tabs/TeacherReliabilityTab';
import { routes } from '@hoot/routes/routes';
import TeacherHeader, { AccountHeaderData } from './TeacherHeader';
import TeacherHistoryTab from './TeacherHistoryTab';
import { TeacherTabs } from './enums/teacher-enums';
import TeacherDetailsTab from './tabs/TeacherDetailsTab';
import { TeacherScheduleTabV2 } from './tabs/TeacherScheduleTabV2';
import TeacherTasksTab from './tabs/TeacherTasksTab';
import useCreateTeacherAccount, { CreateTeacherRequest } from './useCreateTeacherAccount';

const TeachersPage = (props: { tab: TeacherTabs }) => {
  const { tab } = props;
  usePageTitle('Teacher | Admin Portal');
  const { teacherAccountId } = useParams() as { teacherAccountId: string | undefined };

  const isEditMode = !!teacherAccountId;
  const { setIsDirty } = useBlockIfDirty();

  const { error, success } = useAlert();
  const [teacherAccount, setTeacherAccount] = useState<TeacherAccount>();
  const updateTeacherAccountAndUser = useUpdateTeacherAccountAndUser(isEditMode ? teacherAccountId : ''); //should only be called when in editMode exists
  const queryClient = useQueryClient();

  //form state controls
  const setFormIsDirty = () => {
    return;
  };
  const [selectedHootQualifications, setSelectedHootQualifications] = useState<HootQualification[]>([]);
  const [selectedBackgroundChecks, setSelectedBackgroundChecks] = useState<BackgroundCheck[]>([]);
  const [selectedSpokenLanguages, setSelectedSpokenLanguages] = useState<SpokenLanguage[]>([SpokenLanguage.English]);
  const [selectedCertificationAreas, setSelectedCertificationAreas] = useState<TeacherCertificationArea[]>([]);
  const [selectedSpecializedTraining, setSelectedSpecializedTraining] = useState<TeacherSpecializedTraining[]>([]);
  const [showFunctionalityDisabledModal, setShowFunctionalityDisabledModal] = useState<boolean>(false);

  const { isLoading, isError } = useGetTeacherAccount(teacherAccountId!, {
    enabled: !!teacherAccountId,
    onSuccess: (response) => {
      setTeacherAccount(response);
      resetForm(response);
    },
    onError: (exc: any) => {
      error('There was an error getting the Teacher Account data!');
      console.error(exc);
    },
  });

  const createTeacherAccount = useCreateTeacherAccount();

  const navigate = useNavigate();

  const { control, handleSubmit, watch, setValue, formState, reset, setError, clearErrors } = useForm<TeacherAccountAndUserForm>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      displayName: '',
      emailAddress: '',
      phoneNumber: '',
      streetAddress: null,
      country: '',
      city: '',
      provinceState: '',
      postalZipCode: null,
      timeZone: '',
      diversitySelfIdentifications: [],
      languageQualifications: [],
      hootQualifications: [],
      bio: '',
      userAccountStatus: true,
      teacherStatus: TeacherStatus.Candidate,
      teacherStage: TeacherStage.Interviewing,
      employmentAgreementDate: null,
      icContractDate: null,
      goLiveDate: null,
      spokenLanguages: [SpokenLanguage.English],
      certificationAreas: [],
      specializedTraining: [],
      payTier: null,
      trainingPayTier: null,
      agreementEndDate: null,
      agreementEndReason: '',
      payEffectiveDate: null,
      leaveEffectiveDate: null,
      leaveReturnDate: null,
      backgroundChecks: [],
    },
  });

  const resetForm = (teacherAccount: TeacherAccount) => {
    reset({
      ...teacherAccount,
      teacherStatus: teacherAccount.teacherStatus,
      firstName: teacherAccount.user.firstName,
      lastName: teacherAccount.user.lastName,
      emailAddress: teacherAccount.user.email,
      phoneNumber: teacherAccount.user.phoneNumber,
      streetAddress: teacherAccount.user.streetAddress,
      country: teacherAccount.user.country ?? '',
      provinceState: teacherAccount.user.provinceState ?? '',
      postalZipCode: teacherAccount.user.postalZipCode,
      timeZone: teacherAccount.user.timeZone,
      userAccountStatus: teacherAccount.user.isEnabled,
      payTier: teacherAccount.lessonPayTier ?? null,
      agreementEndDate: teacherAccount.agreementEndDate,
      agreementEndReason: teacherAccount.agreementEndReason ?? '',
      icContractDate: teacherAccount.icContractDate ?? '',
      employmentAgreementDate: teacherAccount.employmentAgreementDate ?? '',
      city: teacherAccount.user.city ?? '',
      languageQualifications: teacherAccount.languageQualifications ?? [],
      spokenLanguages: teacherAccount.spokenLanguages ?? [],
      specializedTraining: teacherAccount.specializedTraining ?? [],
      bio: teacherAccount.bio ?? '',
      displayName: teacherAccount.displayName ?? '',
      hootQualifications: teacherAccount.hootQualifications ?? [],
      diversitySelfIdentifications: teacherAccount.diversitySelfIdentifications ?? [],
      certificationAreas: teacherAccount.certificationAreas ?? [],
      goLiveDate: teacherAccount.goLiveDate,
      trainingPayTier: teacherAccount.trainingPayTier ?? null,
      payEffectiveDate: teacherAccount.payEffectiveDate,
      leaveEffectiveDate: teacherAccount.leaveEffectiveDate,
      leaveReturnDate: teacherAccount.leaveReturnDate,
      backgroundChecks: teacherAccount.backgroundChecks,
    });

    setSelectedHootQualifications(teacherAccount.hootQualifications);
    setSelectedBackgroundChecks(teacherAccount.backgroundChecks);
    setSelectedSpokenLanguages(teacherAccount.spokenLanguages);
    setSelectedCertificationAreas(teacherAccount.certificationAreas);
    setSelectedSpecializedTraining(teacherAccount.specializedTraining);
  };

  const { country, agreementEndDate } = watch();
  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty, setIsDirty]);

  useEffect(() => {
    setValue('hootQualifications', selectedHootQualifications, { shouldDirty: true });
  }, [clearErrors, selectedHootQualifications, setError, setValue]);

  useEffect(() => {
    setValue('backgroundChecks', selectedBackgroundChecks, { shouldDirty: true });
  }, [clearErrors, selectedBackgroundChecks, setError, setValue]);

  useEffect(() => {
    setValue('spokenLanguages', selectedSpokenLanguages, { shouldDirty: true });
    if (selectedSpokenLanguages.length > 0) {
      clearErrors('spokenLanguages');
    }
  }, [clearErrors, selectedSpokenLanguages, setValue]);

  useEffect(() => {
    setValue('certificationAreas', selectedCertificationAreas, { shouldDirty: true });
  }, [selectedCertificationAreas, setValue]);

  useEffect(() => {
    setValue('specializedTraining', selectedSpecializedTraining, { shouldDirty: true });
  }, [selectedSpecializedTraining, setValue]);

  useEffect(() => {
    if (!agreementEndDate) {
      setValue('agreementEndReason', '');
    }
  }, [agreementEndDate, setValue]);

  const accountHeaderData: AccountHeaderData | undefined = teacherAccount
    ? {
        endUserId: teacherAccount.user.id,
        teacherId: teacherAccount.id,
        firstName: teacherAccount.user.firstName,
        lastName: teacherAccount.user.lastName,
        displayName: teacherAccount.displayName,
        teacherNumber: `${teacherAccount.number}`,
        teacherPrefixedNumber: teacherAccount.prefixedNumber,
        email: teacherAccount.user.email,
        hubspotId: teacherAccount.user.hubspotId,
        zendeskId: teacherAccount.user.zendeskId,
        isEditMode: isEditMode,
        setShowFunctionalityDisabledModal: setShowFunctionalityDisabledModal,
      }
    : undefined;

  const onSubmit = async (data: TeacherAccountAndUserForm) => {
    if (!formState.isDirty) {
      return;
    }
    const dirtyFields = Object.keys(formState.dirtyFields);
    const isGoLiveDateDirty = dirtyFields.includes('goLiveDate');
    const isAgreementEndDateDirty = dirtyFields.includes('agreementEndDate');

    if (isEditMode) {
      const updateData: UpdateTeacherAccountWithUserDetails = {
        ...data,
        lessonPayTier: data.payTier,
        agreementEndReason: data.agreementEndReason === '' ? null : data.agreementEndReason,
        goLiveDate: isGoLiveDateDirty ? data.goLiveDate : undefined,
        agreementEndDate: isAgreementEndDateDirty ? data.agreementEndDate : undefined,
        icContractDate: data.icContractDate ? data.icContractDate : null,
        employmentAgreementDate: data.employmentAgreementDate ? data.employmentAgreementDate : null,
      };
      updateTeacherAccountAndUser.mutate(updateData, {
        onSuccess: () => {
          success(`${updateData.firstName} ${updateData.lastName} successfully saved!`);
          queryClient.invalidateQueries([QueryKey.GetTeacherAccount, teacherAccountId]);
        },
        onError: (exc: any) => {
          const message =
            exc.response && exc.response.data?.message?.length > 0
              ? exc.response.data.message
              : `There was an issue creating the account for ${updateData.firstName} ${updateData.lastName}`;
          error(message);
        },
      });
    } else {
      const createData: CreateTeacherRequest = {
        ...data,
        lessonPayTier: data.payTier,
      };
      setIsDirty(false);
      createTeacherAccount.mutate(createData, {
        onSuccess: (teacherAccountId) => {
          success(`Teacher account created.`);
          navigate(routes.users.teachers.details.url(teacherAccountId));
        },
        onError: (errorData) => {
          setIsDirty(true);
          console.error(errorData);
          error(`There was an error, the profile has not been created.`);
        },
      });
    }
  };

  const onSaveRequired = async () => {
    await handleSubmit(onSubmit)();
    setShowFunctionalityDisabledModal(false);
  };

  if (isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (isError) {
    return <Stack alignItems="center">Failed to load the page!</Stack>;
  }

  if (isEditMode && !teacherAccount) {
    return <CircularProgress />;
  }

  return (
    <>
      {tab === TeacherTabs.Details ? (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container direction="column">
            <TeacherHeader
              isEditMode={isEditMode}
              userData={accountHeaderData}
              formState={formState}
              setShowFunctionalityDisabledModal={setShowFunctionalityDisabledModal}
            />
            {tab === TeacherTabs.Details ? (
              <TeacherDetailsTab
                isEditMode={isEditMode}
                control={control}
                watch={watch}
                formState={formState}
                teacherAccount={teacherAccount}
                country={country}
                setValue={setValue}
                selectedHootQualifications={selectedHootQualifications}
                selectedBackgroundChecks={selectedBackgroundChecks}
                selectedSpokenLanguages={selectedSpokenLanguages}
                selectedCertificationAreas={selectedCertificationAreas}
                selectedSpecializedTraining={selectedSpecializedTraining}
                setSelectedHootQualifications={setSelectedHootQualifications}
                setSelectedBackgroundChecks={setSelectedBackgroundChecks}
                setSelectedSpokenLanguages={setSelectedSpokenLanguages}
                setSelectedCertificationAreas={setSelectedCertificationAreas}
                setSelectedSpecializedTraining={setSelectedSpecializedTraining}
              />
            ) : null}
          </Grid>
        </form>
      ) : (
        <Grid container direction="column">
          <TeacherHeader
            isEditMode={isEditMode}
            userData={accountHeaderData}
            formState={formState}
            setShowFunctionalityDisabledModal={setShowFunctionalityDisabledModal}
          />
          {tab === TeacherTabs.Schedule ? <TeacherScheduleTabV2 teacherAccount={teacherAccount!} /> : null}
          {tab === TeacherTabs.Availability ? <Availability teacherAccountId={teacherAccountId!} setFormIsDirty={setFormIsDirty} /> : null}
          {tab === TeacherTabs.AvailabilityExceptions ? <AvailabilityException teacherAccountId={teacherAccountId!} /> : null}
          {tab === TeacherTabs.Tasks ? <TeacherTasksTab /> : null}
          {tab === TeacherTabs.Reliability ? <TeacherReliabilityTab /> : null}
          {tab === TeacherTabs.History ? <TeacherHistoryTab /> : null}
        </Grid>
      )}
      <ActionUnavailableModal
        setOpen={setShowFunctionalityDisabledModal}
        open={showFunctionalityDisabledModal}
        accountType={AccountType.Teacher}
        onSave={onSaveRequired}
      />
    </>
  );
};

export default TeachersPage;
