import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../../queryKeys';

export interface StudentLanguageOptionsResponse {
  options: StudentLanguageOption[];
}

export interface StudentLanguageOption {
  id: string;
  name: string;
  code: string;
}

async function getStudentLanguageOptions(): Promise<StudentLanguageOptionsResponse> {
  const { data } = await axios.get<StudentLanguageOptionsResponse>(`${config.apiUrl}/admin/student-profile/language-options`);
  return data;
}

export default function useGetStudentLanguageOptions(options?: Omit<UseQueryOptions<StudentLanguageOptionsResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.getStudentLanguageOptions], () => getStudentLanguageOptions(), options);
}
