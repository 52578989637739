import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import DataImportSummary from './DataImportSummary';
import DataImportTypeSelectionStep from './DataImportTypeSelectionStep';
import DataImportUploadFile from './DataImportUploadFile';
import { DataImportType, dataImportTypeLabels } from './data-import-type-enums';

export enum DataImportSteps {
  TypeSelection = 1,
  UploadFile = 2,
  Summary = 3,
}

const STEPS = ['Import Type selection', 'Upload file', 'Summary'];
const DEFAULT_ACTIVE_STEP = DataImportSteps.TypeSelection;
const DEFAULT_IMPORT_TYPE = DataImportType.TRS;

interface Props {
  isOpen: boolean;
  close: () => void;
}

interface ImportStepProps {
  activeStep: DataImportSteps;
  importType: DataImportType;
  setImportType: (type: DataImportType) => void;
  setDisableNextButton: (val: boolean) => void;
  setDisableBackButton: (val: boolean) => void;
  setFileName: (name: string) => void;
  importId: string;
  setImportId: (id: string) => void;
}

const ImportStep = (props: ImportStepProps) => {
  const { activeStep, importType, setImportType, setDisableNextButton, setDisableBackButton, setFileName, importId, setImportId } = props;

  switch (activeStep) {
    case DataImportSteps.TypeSelection:
      return <DataImportTypeSelectionStep selectedImportType={importType} setImportType={setImportType} />;
    case DataImportSteps.UploadFile:
      return (
        <DataImportUploadFile
          setDisableNextButton={setDisableNextButton}
          setDisableBackButton={setDisableBackButton}
          importType={importType}
          setFileName={setFileName}
          setImportId={setImportId}
        />
      );
    case DataImportSteps.Summary:
      return <DataImportSummary importId={importId} importType={importType} />;
    default:
      throw new Error(`Import State ${activeStep} is not valid"`);
  }
};

const DataImportDialog = (props: Props) => {
  const { isOpen, close } = props;
  const [activeStep, setActiveStep] = useState<DataImportSteps>(DEFAULT_ACTIVE_STEP);
  const [importType, setImportType] = useState<DataImportType>(DataImportType.TRS);
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false);
  const [disableBackButton, setDisableBackButton] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [importId, setImportId] = useState<string>('');

  const onTypeSelectionStep = activeStep === DataImportSteps.TypeSelection;
  const onSummaryStep = activeStep === DataImportSteps.Summary;

  const importHistory = useRef<DataImportSteps[]>([]);

  useEffect(() => {
    if (activeStep === DataImportSteps.TypeSelection) {
      setDisableNextButton(false);
    }
  }, [activeStep]);

  const handleClose = () => {
    setActiveStep(DEFAULT_ACTIVE_STEP);
    setImportType(DEFAULT_IMPORT_TYPE);
    setFileName('');
    setImportId('');
    close();
  };

  const previousStep = () => {
    const previousStep = importHistory.current.pop();
    setFileName('');
    setImportId('');
    if (!!previousStep) {
      setActiveStep(previousStep);
    }
  };

  const nextStep = () => {
    setActiveStep((currentStepState) => {
      importHistory.current.push(currentStepState);
      return ++currentStepState;
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <Stack direction="column" sx={{ backgroundColor: 'white', p: 3, minHeight: '515px' }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="headlineSmall">
              {onTypeSelectionStep ? 'Teacher Data Import Tool' : `${dataImportTypeLabels[DataImportType.TRS]} Import Tool`}
            </Typography>
            <Close sx={{ '&:hover': { cursor: 'pointer' }, mr: '22px' }} onClick={handleClose} />
          </Stack>

          <Stepper sx={{ marginTop: 6 }} activeStep={activeStep - 1} alternativeLabel>
            {STEPS.map((label, idx) => {
              return (
                <Step key={label}>
                  <StepLabel StepIconProps={{ sx: { transform: 'scale(2)', zIndex: 1, color: '#757575' } }}>
                    <span style={{ fontWeight: activeStep - 1 === idx ? 'bold' : 'normal' }}>{label}</span>

                    <Typography variant="bodySmall" component="p" sx={{ mt: 0.5, color: '#757575', minHeight: 16 }}>
                      {/* Step 1 small label */}
                      {importType && idx === 0 && !onTypeSelectionStep ? `${dataImportTypeLabels[DataImportType.TRS]}` : null}
                      {/* Step 2 small label */}
                      {!!fileName && idx === 1 ? fileName : null}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent sx={{ p: 0, mt: 2 }}>
          <ImportStep
            activeStep={activeStep}
            importType={importType}
            setImportType={setImportType}
            setDisableNextButton={setDisableNextButton}
            setDisableBackButton={setDisableBackButton}
            setFileName={setFileName}
            importId={importId}
            setImportId={setImportId}
          />
        </DialogContent>

        <Divider />

        <DialogActions sx={{ p: 0 }}>
          {!onSummaryStep ? (
            <Stack direction="row" width="100%" justifyContent="space-between">
              <Stack direction="row">
                {onTypeSelectionStep ? null : (
                  <Button onClick={previousStep} disabled={disableBackButton} color="inherit" variant="outlined" size="large">
                    Back
                  </Button>
                )}
              </Stack>

              <Stack direction="row" justifyContent="flex-end" gap={1}>
                <Button onClick={handleClose} color="inherit" variant="outlined" size="large">
                  Close
                </Button>
                <Button onClick={nextStep} variant="contained" size="large" disabled={disableNextButton}>
                  Next
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" width="100%" justifyContent="flex-end">
              <Button onClick={handleClose} variant="contained" size="large">
                Done
              </Button>
            </Stack>
          )}
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default DataImportDialog;
