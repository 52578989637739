import { Box, Breadcrumbs, Card, CardContent, LinearProgress, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { createLessonWizardSteps, useCreateLessonContext } from '@hoot/pages/lessons/create-v2/context/CreateLessonContextProvider';
import { routes } from '@hoot/routes/routes';

const CreateLessonWizard = () => {
  const { loadingCustomer, loadingEnrolments, currentStep } = useCreateLessonContext();

  return (
    <>
      <Card>
        <Box sx={{ height: '4px' }}>{loadingCustomer || loadingEnrolments ? <LinearProgress /> : null}</Box>
        <CardContent>
          <Breadcrumbs>
            <Link style={{ textDecoration: 'none' }} to={routes.lessons.url}>
              <Typography color="text.primary" variant="bodySmall">
                Lessons
              </Typography>
            </Link>
            <Typography color="text.primary" variant="bodySmall">
              Create Lesson
            </Typography>
          </Breadcrumbs>
          <Typography variant="headlineLarge">Create Lesson Wizard</Typography>
          <Stepper activeStep={currentStep.id - 1} alternativeLabel sx={{ marginTop: 10, marginBottom: 10 }}>
            {createLessonWizardSteps.map((step) => (
              <Step key={step.id}>
                <StepLabel>{step.stepperLabel}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Stack>
            <Typography variant="titleLarge" sx={{ marginBottom: 3 }}>
              Step {currentStep.id} - {currentStep.contentLabel ?? currentStep.stepperLabel}
            </Typography>
            {currentStep.content()}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default CreateLessonWizard;
