import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

async function deleteLessonReview(lessonReviewId: string): Promise<void> {
  await axios.delete(`${config.apiUrl}/admin/lesson-reviews/${lessonReviewId}`);
}

export default function useDeleteLessonReview(options?: Omit<UseMutationOptions<void, AxiosError, string>, 'mutationKey' | 'mutationFn'>) {
  return useMutation<void, AxiosError, string>((lessonReviewId) => deleteLessonReview(lessonReviewId), options);
}
