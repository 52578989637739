import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface EnrolmentLocation {
  id: string;
  name: string;
  type: string;
}

export interface LocationEnrolment {
  enrolmentId: string;
  friendlyId: string;
  parentProductFriendlyId: string;
  location: EnrolmentLocation | null;
  type: EnrolmentType;
  lessons: number;
  startDate: number;
  endDate: number;
  status: EnrolmentStatus;
}

export interface GetLocationEnrolmentsResponse {
  enrolments: LocationEnrolment[];
}

const searchLocationEnrolments = async (locationId: string): Promise<GetLocationEnrolmentsResponse> => {
  const { data } = await axios.get(`${config.apiUrl}/admin/hfs/location/${locationId}/enrolments`);
  return data;
};

export default function useSearchLocationEnrolments(
  locationId: string,
  options?: Omit<UseQueryOptions<GetLocationEnrolmentsResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.SearchLocationEnrolments, locationId], () => searchLocationEnrolments(locationId), options);
}
