import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import React from 'react';
import NoneItem from '@hoot/components/ui/NoneItem';

const StudentBillableLessonCard = (props: { billableLessonsCount?: number }) => {
  const { billableLessonsCount } = props;

  return (
    <Card sx={{ pl: 3, pt: 3, pr: 3 }}>
      <CardHeader
        sx={{ p: 0, pb: 3 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="titleLarge">
              Billable Lesson Count
            </Typography>
          </Stack>
        }
      />
      <CardContent sx={{ p: 0 }}>
        {billableLessonsCount ? (
          <Stack direction="row" gap={3}>
            <PersonOutlineOutlinedIcon />
            <Typography>{billableLessonsCount}</Typography>
          </Stack>
        ) : (
          <NoneItem />
        )}
      </CardContent>
    </Card>
  );
};

export default StudentBillableLessonCard;
