import axios from 'axios';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import { InvoicePeriodStatus, InvoiceStatus } from '@hoot/pages/teacher-invoices/enums/enums';
import { InvoiceLineItemStatus } from '@hoot/pages/teacher-invoices/enums/invoice-line-item-status.enum';
import { config } from '../../../config';
import { SortableQuery } from '../../../interfaces/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '../../../interfaces/pagination';
import { QueryKey } from '../queryKeys';

export enum TeacherInvoiceBillableTasksQuerySortKeyEnum {
  Teacher = 'teacher',
  Project = 'project',
  QuantityDuration = 'quantity-duration',
  Date = 'date',
  Amount = 'amount',
  Status = 'status',
  Action = 'action',
}

export interface SearchTeacherInvoiceBillableTasksQuery extends PaginatableQuery, SortableQuery<TeacherInvoiceBillableTasksQuerySortKeyEnum> {
  search?: string;
  project?: string[];
  status?: string[];
}

export interface InvoicePeriodDetailsResponse {
  id: string;
  periodStartDate: string;
  periodEndDate: string;
  status: InvoicePeriodStatus;
  isPeriodPast: boolean;
  invoiceHasPendingBillableTasks: boolean;
}

export interface TeacherInvoiceBillableTasksResponse {
  id: string;
  teacherId: string;
  teacherName: string;
  teacherEmail: string;
  project: string;
  projectId: string;
  quantityDuration: string;
  quantity: number;
  duration: number;
  date: string;
  total: number;
  lesson: number;
  status: InvoiceLineItemStatus;
  action: boolean;
  teacherComments: string;
  reviewerComments: string;
  invoiceStatus: InvoiceStatus;
  invoicePeriodStatus: InvoicePeriodStatus;
}

const searchTeacherInvoiceBillableTasks = async (
  period: string,
  searchParams: SearchTeacherInvoiceBillableTasksQuery,
): Promise<GenericPaginatedResponse<TeacherInvoiceBillableTasksResponse, InvoicePeriodDetailsResponse>> => {
  const { data } = await axios.get(`${config.apiUrl}/admin/teacher-invoicing/search-billable-tasks/${period}`, {
    params: searchParams,
  });
  return data;
};

export default function useSearchTeacherInvoiceBillableTasks(
  period: string,
  searchParams: SearchTeacherInvoiceBillableTasksQuery,
  options?: Omit<
    UseQueryOptions<GenericPaginatedResponse<TeacherInvoiceBillableTasksResponse, InvoicePeriodDetailsResponse>>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery(
    [QueryKey.SearchTeacherInvoiceBillableTasks, period, searchParams],
    () => searchTeacherInvoiceBillableTasks(period, searchParams),
    options,
  );
}
