import { ArrowDropDown } from '@mui/icons-material';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';

const DistrictSchoolsTableActionsButton = (props: { disabled: boolean; setShowCreateDistrictSchool: Dispatch<SetStateAction<boolean>> }) => {
  const { disabled, setShowCreateDistrictSchool } = props;
  const [actionAnchorEl, setActionAnchorEl] = useState<Element | null>(null);

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleActionPopoverClose = () => {
    setActionAnchorEl(null);
  };

  const openCreateDistrictSchoolModal = () => {
    setShowCreateDistrictSchool(true);
    setActionAnchorEl(null);
  };

  return (
    <>
      <Button
        disabled={disabled}
        type="button"
        style={{ height: 36 }}
        startIcon={<ArrowDropDown />}
        color="inherit"
        variant="outlined"
        onClick={handleActionClick}
      >
        <Typography variant="labelLarge">Actions</Typography>
      </Button>
      <Menu
        open={Boolean(actionAnchorEl)}
        anchorEl={actionAnchorEl}
        onClose={handleActionPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ marginTop: 1 }}
      >
        <MenuItem onClick={openCreateDistrictSchoolModal}>
          <Typography variant="titleSmall" color="black" width={200}>
            Create New School
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default DistrictSchoolsTableActionsButton;
