import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';
import { ScheduledLessonSetStatus } from '../../../pages/lessons/enums';

export interface LessonSetRequest {
  status: ScheduledLessonSetStatus;
  startDate?: string | null;
  endDate?: string | null;
}

async function updateLessonSet(lessonSetId: string, request: LessonSetRequest) {
  await axios.post<void>(`${config.apiUrl}/admin/lesson-set/${lessonSetId}`, request);
}

export function useUpdateLessonSet(
  lessonSetId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, LessonSetRequest>, 'queryKey' | 'queryFn'>,
) {
  return useMutation((request: LessonSetRequest) => updateLessonSet(lessonSetId, request), options);
}
