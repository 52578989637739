import { Dialog, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { Dispatch, SetStateAction, useState } from 'react';
import { useAlert } from '@hoot/contexts/AlertContext';
import HootEmployeeCreate from './HootEmployeeCreate';

interface Props {
  setHootEmployeeUserId: Dispatch<SetStateAction<string | undefined>>;
  showModal: boolean;
  setShowModal: (showAddHootEmployeeModal: boolean) => void;
  setShowDrawer: (showAddHootEmployeeDrawer: boolean) => void;
}

const HootEmployeeModal = (props: Props) => {
  const { setHootEmployeeUserId, setShowModal, showModal, setShowDrawer } = props;
  const [formIsDirty, setFormIsDirty] = useState(false);

  const { sweetAlert } = useAlert();

  ///// Event handlers /////

  const handleClose = () => {
    if (formIsDirty) {
      sweetAlert
        .fire({
          title: 'Discard?',
          text: 'Closing without saving will discard your changes.',
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'Discard',
          confirmButtonText: 'Keep Editing',
        })
        .then((result) => {
          if (!result.isConfirmed) {
            setShowModal(false);
          }
        });
    } else {
      setShowModal(false);
    }
  };

  return (
    <Dialog open={showModal} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <HootEmployeeCreate
            setHootEmployeeUserId={setHootEmployeeUserId}
            setFormIsDirty={setFormIsDirty}
            setShowModal={setShowModal}
            setShowDrawer={setShowDrawer}
          />
        </Stack>
      </Box>
    </Dialog>
  );
};

export default HootEmployeeModal;
