import { FilterAltOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Badge, Box, Button, Card, Checkbox, Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { capitalCase } from 'change-case';
import React, { useState } from 'react';
import { defaultRowsPerPage } from '@hoot/common/constants';
import SearchTextField, { SearchTextFieldProps } from '@hoot/components/form/SearchTextField';
import { HeaderData, Table } from '@hoot/components/ui/Table';
import userQueryHootEmployees, { EmployeesSearchParams, OrderColumn } from '@hoot/hooks/api/hoot-employees/useGetHootEmployees';
import { OrderBy } from '@hoot/pages/lessons/enums';
import HootEmployeeDrawer from '../HootEmployeesDrawer/HootEmployeeDrawer';
import HootEmployeeModal from '../HootEmployeesModal/HootEmployeeModal';
import { HootEmployeeFiltersForm } from '../interfaces/hoot-employee-filters-form';
import { HootEmployeeFiltersDrawer } from './HootEmployeesFiltersDrawer/HootEmployeesFiltersDrawer';

type QueryKeys = keyof HootEmployeeFiltersForm;

const IGNORE_FILTERS = ['searchText', 'page', 'pageSize', 'orderColumn', 'orderBy'];

interface EmployeeTableRow {
  id: string;
  name: React.ReactNode;
  email: React.ReactNode;
  status: React.ReactNode;
}

const headers: HeaderData<EmployeeTableRow>[] = [
  { property: 'id', name: 'id', isHidden: true },
  { property: 'name', name: 'Name' },
  { property: 'email', name: 'Email' },
  { property: 'status', name: 'Enabled?' },
];

const FilterChips = (props: { badgesWithMultiSelectors: any[]; setEmployeeFilters: React.Dispatch<React.SetStateAction<EmployeesSearchParams>> }) => {
  const { badgesWithMultiSelectors, setEmployeeFilters } = props;
  return (
    <Grid container item sx={{ marginLeft: '24px' }}>
      {badgesWithMultiSelectors.map((badge) => {
        const [filterType, filterValue]: [QueryKeys, any] = badge;

        return (
          <Chip
            key={`${filterType}-${filterValue}`}
            sx={{
              margin: '4px',
            }}
            label={`${capitalCase(filterType)}: ${filterValue}`}
            onDelete={() => {
              setEmployeeFilters((current) => ({ ...current, [filterType]: undefined }));
            }}
          />
        );
      })}
    </Grid>
  );
};

function HootEmployeesTable() {
  return (
    <Stack spacing="16px">
      <HeaderCard />
      <SearchCard />
    </Stack>
  );
}

function HeaderCard() {
  return (
    <Card sx={{ padding: '24px' }}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Typography variant="headlineLarge">Employees</Typography>
      </Grid>
    </Card>
  );
}

const SearchCard = () => {
  const [searchText, setSearchText] = useState('');
  const [employeesQuery, setEmployeesQuery] = useState<EmployeesSearchParams>({
    searchText,
    page: 1,
    pageSize: 10,
    isEnabled: undefined,
    orderColumn: OrderColumn.FirstName,
    orderBy: OrderBy.Asc,
  });
  const [showHootEmployeeModal, setShowHootEmployeeModal] = useState(false);
  const [showHootEmployeeDrawer, setShowHootEmployeeDrawer] = useState(false);
  const [selectedHootEmployeeUserId, setSelectedHootEmployeeUserId] = useState<string | undefined>();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  // Search for users based on toolbar searchText, resulting data goes to table body as rows of users.
  const { isLoading: isLoadingHootEmployeeSearch, data: hootEmployeeResponseData } = userQueryHootEmployees(employeesQuery);

  const data =
    hootEmployeeResponseData && hootEmployeeResponseData.hootEmployees
      ? hootEmployeeResponseData.hootEmployees.map<EmployeeTableRow>((hootEmployee) => ({
          id: hootEmployee.id,
          name: `${hootEmployee.firstName} ${hootEmployee.lastName}`,
          email: hootEmployee.email,
          status: <Checkbox checked={hootEmployee.isEnabled} disabled />,
        }))
      : [];

  const badges = Object.entries(employeesQuery).filter(([k, v]) => !IGNORE_FILTERS.includes(k) && v !== undefined) as [QueryKeys, any][];

  const badgesWithMultiSelectors = badges.reduce((acc: any[], badge) => {
    if (Array.isArray(badge[1])) return [...acc, ...badge[1].map((val: any) => [badge[0], val])];
    return [...acc, badge];
  }, []);

  const handlePageChange = (_event: unknown, page: number) => {
    setEmployeesQuery((current) => ({ ...current, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setEmployeesQuery((current) => ({ ...current, pageSize: pageSize }));
  };

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchText(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (searchText) => {
    setEmployeesQuery((current) => ({ ...current, searchText, page: 1 }));
  };

  const handleAddHootEmployeeClick = () => {
    setSelectedHootEmployeeUserId(undefined);
    setShowHootEmployeeModal(true);
  };

  return (
    <Card sx={{ padding: '24px' }}>
      <Stack direction="row" sx={{ mb: 3 }} justifyContent="space-between" alignItems="center">
        <Typography variant="titleLarge">Employees</Typography>
        <Button onClick={handleAddHootEmployeeClick} style={{ height: 36 }} variant="contained" color="primary">
          <AddIcon fontSize="small" style={{ marginRight: 5 }} /> Add Employee
        </Button>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <SearchTextField
          searchInput={searchText}
          onSearchInputChanged={onSearchInputChanged}
          onSearchInputDebounced={onSearchInputDebounced}
          autoFocus
        />

        <Tooltip title={'Filter Results'}>
          <IconButton size="large" onClick={() => setShowFilters(true)} color="primary">
            <Badge color="primary">
              <FilterAltOutlined sx={{ cursor: 'pointer' }} />
            </Badge>
          </IconButton>
        </Tooltip>
      </Stack>

      <Box sx={{ marginY: '20px' }}>
        <FilterChips badgesWithMultiSelectors={badgesWithMultiSelectors} setEmployeeFilters={setEmployeesQuery} />
      </Box>

      <Stack sx={{ marginTop: '32px' }}>
        <Table
          isPaginated
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onRowClick={(rowId) => {
            setSelectedHootEmployeeUserId(rowId);
            setShowHootEmployeeDrawer(true);
          }}
          headers={headers}
          data={data}
          count={hootEmployeeResponseData?.total || 0}
          page={hootEmployeeResponseData?.page || 1}
          rowsPerPage={employeesQuery.pageSize}
          isLoading={isLoadingHootEmployeeSearch}
        />
      </Stack>
      {showHootEmployeeModal ? (
        <HootEmployeeModal
          setHootEmployeeUserId={setSelectedHootEmployeeUserId}
          showModal={showHootEmployeeModal}
          setShowModal={setShowHootEmployeeModal}
          setShowDrawer={setShowHootEmployeeDrawer}
        />
      ) : null}
      {showHootEmployeeDrawer ? (
        <HootEmployeeDrawer
          hootEmployeeUserId={selectedHootEmployeeUserId}
          setHootEmployeeUserId={setSelectedHootEmployeeUserId}
          showDrawer={showHootEmployeeDrawer}
          setShowDrawer={setShowHootEmployeeDrawer}
        />
      ) : null}
      {showFilters ? (
        <HootEmployeeFiltersDrawer open={showFilters} setOpen={setShowFilters} query={employeesQuery} setQuery={setEmployeesQuery} />
      ) : null}
    </Card>
  );
};

export default HootEmployeesTable;
