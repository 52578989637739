import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface PublisherResponse {
  id: string;
  name: string;
}

async function getPublishers(): Promise<PublisherResponse[]> {
  const { data } = await axios.get<PublisherResponse[]>(`${config.apiUrl}/admin/library/publishers`);
  return data;
}

export default function useGetPublishers(options?: Omit<UseQueryOptions<PublisherResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetPublishers], () => getPublishers(), options);
}
