import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';
import { QualityAssuranceStatus } from './enums';

export interface QualityAssuranceBulkStatusChangeRequest {
  taskIds: string[];
  status: QualityAssuranceStatus;
  isActioned?: boolean;
}

export default function useQualityAssuranceBulkStatusChange(
  options?: Omit<UseMutationOptions<void, AxiosError, QualityAssuranceBulkStatusChangeRequest>, 'mutationFn' | 'mutationKey'>,
) {
  return useMutation(async (request) => {
    await axios.post<void>(`${config.apiUrl}/admin/quality-assurance-tasks/bulk-edit`, request);
  }, options);
}
