import { DistrictRepresentativeStatus } from '@hoot-reading/hoot-core/dist/enums/user/district-representative/district-representative-status.enum';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { sanitizeInputPhoneNumber } from '@hoot/utils/formData';
import { config } from '../../../../config';

export interface UpdateDistrictRepresentative {
  districtRepresentativeId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  timeZone: string;
  status: DistrictRepresentativeStatus;
  userAccountStatus: boolean;
}

async function updateDistrictRepresentative(districtRepresentativeId: string, updateDistrictRepresentative: UpdateDistrictRepresentative) {
  await axios.post(`${config.apiUrl}/admin/district-representative/${districtRepresentativeId}`, updateDistrictRepresentative);
}

export default function useUpdateDistrictRepresentative(
  options?: Omit<UseMutationOptions<void, AxiosError, UpdateDistrictRepresentative>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((data: UpdateDistrictRepresentative) => {
    const phoneNumber = sanitizeInputPhoneNumber(data.phoneNumber);
    if (!phoneNumber) {
      throw new Error('Phone Number input for updating a district representative was incorrect.');
    }
    data.phoneNumber = phoneNumber;

    return updateDistrictRepresentative(data.districtRepresentativeId, data);
  }, options);
}
