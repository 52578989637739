import { TimelineEvent, TimelineLessonReviewFocus, TimelineOutcome } from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import {
  AdvancedBeyondIcon,
  CancellationIcon,
  CompletedLessonIcon,
  FormativeAssessmentIcon,
  HootReadingAssessmentIcon,
  InstructionRequiredIcon,
  LessonPrimaryFocusIcon,
  LessonSecondaryFocusIcon,
  MasteredIcon,
  ProgressMonitoringAssessmentIcon,
  ReviewRequiredIcon,
  ScheduledLessonIcon,
  StudentAbsenceIcon,
} from '@hoot/components/icons/timeline-legend';
import BlankIcon from '@hoot/components/icons/timeline-legend/BlankIcon';

export const timelineEventIconDictionary: Record<TimelineEvent, JSX.Element> = {
  [TimelineEvent.HRA]: <HootReadingAssessmentIcon />,
  [TimelineEvent.PMA]: <ProgressMonitoringAssessmentIcon />,
  [TimelineEvent.CompletedLesson]: <CompletedLessonIcon />,
  [TimelineEvent.ScheduledLesson]: <ScheduledLessonIcon />,
  [TimelineEvent.StudentAbsence]: <StudentAbsenceIcon />,
  [TimelineEvent.Cancellation]: <CancellationIcon />,
};

export const timelineOutcomeIconDictionary: Record<TimelineOutcome, JSX.Element> = {
  [TimelineOutcome.Mastered]: <MasteredIcon />,
  [TimelineOutcome.ReviewRequired]: <ReviewRequiredIcon />,
  [TimelineOutcome.InstructionRequired]: <InstructionRequiredIcon />,
  [TimelineOutcome.Blank]: <BlankIcon />,
};

export const timelineLessonReviewIconDictionary: Record<TimelineLessonReviewFocus, JSX.Element> = {
  [TimelineLessonReviewFocus.AdvancedBeyond]: <AdvancedBeyondIcon />,
  [TimelineLessonReviewFocus.LessonPrimaryFocus]: <LessonPrimaryFocusIcon />,
  [TimelineLessonReviewFocus.LessonSecondaryFocus]: <LessonSecondaryFocusIcon />,
  [TimelineLessonReviewFocus.FormativeAssessment]: <FormativeAssessmentIcon />,
};

export const timelineLegendLabel: Record<string, string> = {
  [TimelineEvent.HRA]: 'Hoot Reading Assessment',
  [TimelineEvent.PMA]: 'Progress Monitoring Assessment',
  [TimelineEvent.CompletedLesson]: 'Completed Lesson',
  [TimelineEvent.ScheduledLesson]: 'Scheduled Lesson',
  [TimelineEvent.StudentAbsence]: 'Student Absence',
  [TimelineEvent.Cancellation]: 'Cancellation',
  [TimelineOutcome.Mastered]: 'Mastered',
  [TimelineOutcome.ReviewRequired]: 'Review Required',
  [TimelineOutcome.InstructionRequired]: 'Instruction Required',
  [TimelineLessonReviewFocus.AdvancedBeyond]: 'Advanced Beyond',
  [TimelineLessonReviewFocus.LessonPrimaryFocus]: 'Lesson Primary Focus',
  [TimelineLessonReviewFocus.LessonSecondaryFocus]: 'Lesson Secondary Focus',
  [TimelineLessonReviewFocus.FormativeAssessment]: 'Formative Assessment',
};

export const timelineColourDictionary: Record<TimelineEvent | TimelineOutcome | TimelineLessonReviewFocus, string> = {
  [TimelineEvent.HRA]: '#F2F2F2',
  [TimelineEvent.PMA]: '#F2F2F2',
  [TimelineEvent.CompletedLesson]: '#A3B5CB',
  [TimelineEvent.ScheduledLesson]: '#DCE5FB',
  [TimelineEvent.StudentAbsence]: '#F9B6A7',
  [TimelineEvent.Cancellation]: '#F9B6A7',
  [TimelineOutcome.Mastered]: '#C4E3B4',
  [TimelineOutcome.ReviewRequired]: '#9EE0DE',
  [TimelineOutcome.InstructionRequired]: '#FCD2A5',
  [TimelineOutcome.Blank]: '#F2F2F2',
  [TimelineLessonReviewFocus.AdvancedBeyond]: '#A3B5CB',
  [TimelineLessonReviewFocus.LessonPrimaryFocus]: '#A3B5CB',
  [TimelineLessonReviewFocus.LessonSecondaryFocus]: '#A3B5CB',
  [TimelineLessonReviewFocus.FormativeAssessment]: '#A3B5CB',
};

type CombinedIconDictionary = Record<TimelineEvent, JSX.Element> &
  Record<TimelineOutcome, JSX.Element> &
  Record<TimelineLessonReviewFocus, JSX.Element>;

export const timelineIconsDictionary: CombinedIconDictionary = {
  ...timelineEventIconDictionary,
  ...timelineOutcomeIconDictionary,
  ...timelineLessonReviewIconDictionary,
};
