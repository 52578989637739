import { CancellationReason, ScheduledLessonStatus, ScheduledLessonType, SubRequired } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { DateTime } from 'luxon';
import React, { PropsWithChildren, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { ConflictsResponse } from '@hoot/hooks/api/lessons/useGetConflicts';
import { LessonDetailResponse, useGetLesson } from '@hoot/hooks/api/lessons/useGetLesson';
import useUpdateLesson, { UpdateLessonRequest, UpdateLessonResponse } from '@hoot/hooks/api/lessons/useUpdateLesson';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { LUXON_DATE_FORMAT } from '@hoot/pages/lessons/utils/date';
import { TIME_FORMAT } from '../../../common/constants';

interface EditLessonDetailsContextProps {
  lessonId: string;
  isLoadingLessonDetails: boolean;
  lessonDetails: LessonDetailResponse | undefined;
  lessonDetailsForm: UseFormReturn<LessonDetailsForm>;
  updateRequestConflicts: ConflictsResponse[] | undefined;
  actions: {
    saveLessonDetails: () => void;
  };
}

export interface LessonDetailsForm {
  studentProfileId: string;
  teacherAccountId: string | null;
  lessonType: ScheduledLessonType;
  duration: number;
  lessonStatus: ScheduledLessonStatus;
  cancellationReason: CancellationReason | '';
  lessonDate: DateTime;
  startTime: DateTime;
  subRequired: SubRequired;
}

const EditLessonDetailsContext = React.createContext<EditLessonDetailsContextProps>(undefined!);

export const useEditLessonDetailsContext = () => {
  const context = React.useContext(EditLessonDetailsContext);

  if (context === undefined) {
    throw new Error('useEditLessonDetailsContext must be used within a EditLessonDetailsContextProvider');
  }
  return context;
};

const EditLessonDetailsContextProvider = (props: PropsWithChildren<any>) => {
  const { children } = props;
  const { success, error } = useAlert();

  const { lessonId } = useParams<{ lessonId: string }>();
  const [updateRequestConflicts, setUpdateRequestConflicts] = useState<ConflictsResponse[] | undefined>(undefined);
  const queryClient = useQueryClient();

  const getLessonRequest = useGetLesson(lessonId!, {
    enabled: !!lessonId,
    retry: false,
    onSuccess: (data: LessonDetailResponse) => {
      lessonDetailsForm.reset({
        studentProfileId: data.studentProfileId,
        teacherAccountId: data.teacherAccountId,
        lessonType: data.lessonType,
        duration: data.duration,
        lessonStatus: data.lessonStatus,
        cancellationReason: data.cancellationReason || '',
        lessonDate: DateTime.fromMillis(data.startsAt),
        startTime: DateTime.fromMillis(data.startsAt),
        subRequired: data.subRequired,
      });
    },
    onError: (err) => {
      console.error(err);
      error('Sorry. An error occurred while loading lesson details.');
    },
  });

  const updateLessonRequest = useUpdateLesson(lessonId!, {
    onSuccess: (data: UpdateLessonResponse) => {
      if (data.isSuccess) {
        queryClient.invalidateQueries(QueryKey.Lessons);
        queryClient.invalidateQueries(QueryKey.LiveLessons);
        queryClient.invalidateQueries(QueryKey.Lesson);
        queryClient.invalidateQueries(QueryKey.ScheduledLessons);
        queryClient.invalidateQueries(QueryKey.MonthlyScheduledLessons);
        queryClient.invalidateQueries(QueryKey.UserScheduleLessons);

        lessonDetailsForm.reset({}, { keepValues: true });

        setUpdateRequestConflicts(undefined);
        success('Successfully edited lesson.');
      } else {
        setUpdateRequestConflicts(data.conflicts);
      }
    },
    onError: () => {
      error('There was an error while attempting to submit edited lesson.');
    },
  });

  const lessonDetailsForm = useForm<LessonDetailsForm>({
    defaultValues: {
      // These will get populated once the lesson GET request comes back.
      studentProfileId: undefined,
      teacherAccountId: undefined,
      lessonType: undefined,
      duration: undefined,
      lessonStatus: undefined,
      cancellationReason: '',
      lessonDate: undefined,
      startTime: undefined,
      subRequired: undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const saveLessonDetails = (data: LessonDetailsForm) => {
    const startsAtString = data.lessonDate.toFormat(LUXON_DATE_FORMAT) + ' ' + data.startTime.toFormat(TIME_FORMAT);
    const startsAt = DateTime.fromFormat(startsAtString, `${LUXON_DATE_FORMAT} ${TIME_FORMAT}`).toMillis();
    const request: UpdateLessonRequest = {
      studentProfileId: data.studentProfileId,
      teacherAccountId: data.subRequired === SubRequired.Yes ? null : data.teacherAccountId,
      lessonType: data.lessonType,
      durationInMinutes: data.duration,
      status: data.lessonStatus,
      cancellationReason: data.cancellationReason !== '' ? data.cancellationReason : null,
      startsAt: startsAt,
      subRequired: data.subRequired,
    };

    updateLessonRequest.mutate(request);
  };

  return (
    <EditLessonDetailsContext.Provider
      value={{
        lessonId: lessonId!,
        isLoadingLessonDetails: getLessonRequest.isFetching,
        lessonDetails: getLessonRequest.data,
        lessonDetailsForm: lessonDetailsForm,
        updateRequestConflicts,
        actions: {
          saveLessonDetails: lessonDetailsForm.handleSubmit(saveLessonDetails),
        },
      }}
    >
      {children}
    </EditLessonDetailsContext.Provider>
  );
};

export default EditLessonDetailsContextProvider;
