import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface MetabaseReportResponse {
  id: number;
  reportName: string;
  reportOwner: string;
}

async function getMetabaseReports(): Promise<MetabaseReportResponse[]> {
  const { data } = await axios.get<MetabaseReportResponse[]>(`${config.apiUrl}/admin/reporting/metabase/reports`);
  return data;
}

export default function useGetMetabaseReports(options?: Omit<UseQueryOptions<MetabaseReportResponse[], AxiosError>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetMetabaseReports], () => getMetabaseReports(), options);
}
