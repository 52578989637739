import { ReceiptLongOutlined } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import EnrolmentStatusIcon from '@hoot/components/ui/enrolments/EnrolmentStatusIcon';
import { DistrictRepresentativeEnrolment, enrolmentStatuses } from '@hoot/hooks/api/user/district-representative/useGetDistrictRepresentative';
import { routes } from '@hoot/routes/routes';
import { NoneListItem } from '../../../district-schools/details/Shared';

interface Props {
  enrolments: DistrictRepresentativeEnrolment[];
}

const DistrictRepresentativeEnrolmentsCard = (props: Props) => {
  const { enrolments } = props;

  return (
    <>
      <Grid container item marginTop="24px">
        <Card
          sx={{
            width: 1,
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Grid container item justifyContent="space-between">
              <Grid item>
                <Typography component="h3" variant="titleLarge">
                  Enrolments
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                marginTop: '24px',
              }}
            >
              <Typography component="h5" variant="bodySmall">
                Enrolments*
              </Typography>
              <List dense>
                {!!enrolments && enrolments.length > 0 ? (
                  enrolments.map((enrolment) => (
                    <ListItem
                      key={enrolment.id}
                      sx={{
                        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                        borderRadius: '4px',
                        padding: '8px 24px 8px 16px',
                      }}
                      secondaryAction={
                        <Box>
                          <Button
                            href={routes.districtsSchools.enrolments.details.url(enrolment.locationId, enrolment.id)}
                            variant="text"
                            color="primary"
                            size="large"
                          >
                            View
                          </Button>
                        </Box>
                      }
                    >
                      <ListItemIcon>
                        <ReceiptLongOutlined />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography component="div" variant="bodyLarge">
                            {enrolment.friendlyId}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography component="div" variant="labelMedium">
                              {enrolment.locationName}
                            </Typography>
                            <Typography component="div" variant="bodySmall">
                              {EnrolmentStatusIcon(enrolment.status)} {enrolmentStatuses[enrolment.status]}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))
                ) : (
                  <NoneListItem />
                )}
              </List>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default DistrictRepresentativeEnrolmentsCard;
