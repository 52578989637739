import moment from 'moment-timezone';

// Get all codes for countries then get all timeZoneName + timeZoneOffset pairs for every country.
const countryCodes = moment.tz.countries();
const timeZoneNamesAndOffsets = countryCodes.map((countryCode) => moment.tz.zonesForCountry(countryCode, true)).flat();

// Sort all pairs in ascending order by offset in minutes, then by time zone name.
timeZoneNamesAndOffsets.sort((a, b) => {
  if (a.offset < b.offset) {
    return -1;
  }

  if (a.offset === b.offset) {
    if (a.name.localeCompare(b.name) < 0) {
      return -1;
    }
  }

  return 1;
});

// Remove all duplicates from the zonesForCountry method.
const uniqueArray = timeZoneNamesAndOffsets.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);

export const dropDownArray = uniqueArray.map((object) => {
  return {
    ...object,
    displayName: `(GMT${object.offset < 0 ? '+' : '-'}${moment()
      .startOf('day')
      .add(Math.abs(object.offset), 'minutes')
      .format('hh:mm')}) ${object.name.replace('/', ' - ').replace('/', ', ').replaceAll('_', ' ')}`,
  };
});

// Create a dictionary that maps the name for each timeZone to their data.
export const nameToTimeZoneData = dropDownArray.reduce((acc: any, timeZone) => {
  acc[timeZone.name] = timeZone;
  return acc;
}, {});

export const timeZoneData = dropDownArray.map((element) => {
  return { value: element.name, label: element.displayName };
});
