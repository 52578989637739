import axios, { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface CreateAssociationDistrictRepLocationRequest {
  districtRepId: string;
  locationId: string;
}

const createAssociationDistrictRepLocation = async (request: CreateAssociationDistrictRepLocationRequest): Promise<void> => {
  const { data } = await axios.post<void>(`${config.apiUrl}/admin/district-representative/associate-location`, request);
  return data;
};

const useCreateAssociationDistrictRepLocation = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, CreateAssociationDistrictRepLocationRequest>((request) => createAssociationDistrictRepLocation(request), {
    onSuccess: (data, variables) => {
      return queryClient.invalidateQueries([QueryKey.SearchDistrictsSchools, variables.districtRepId]);
    },
  });
};

export default useCreateAssociationDistrictRepLocation;
