import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export type ConflictType = 'CONFLICT' | 'EXCEPTION' | 'OUTSIDE_OF_SHIFT' | 'OUTSIDE_OF_AVAILABILITY';

export interface ConflictsRequest {
  studentProfileId?: string;
  teacherAccountId: string | null;
  durationInMinutes: number;
  lessonStartTimes: number[];
}

export interface ConflictsResponse {
  lessonStart: number;
  lessonEnd: number;
  message: string;
  conflictType: ConflictType;
}

export async function getLessonConflicts(request: ConflictsRequest) {
  const { data } = await axios.post<ConflictsResponse[]>(`${config.apiUrl}/admin/scheduler/conflicts`, request);
  return data;
}

export default function useGetLessonConflicts(
  conflictsRequest: ConflictsRequest,
  options?: Omit<UseQueryOptions<ConflictsResponse[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.LessonConflicts, conflictsRequest], () => getLessonConflicts(conflictsRequest), options);
}
