import { TeacherShiftCancellationReason, TeacherShiftStatus, TeacherShiftType } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';

export interface TeacherShiftResponse {
  id: string;
  teacherId: string;
  number: number;
  prefixedNumber: string;
  prefixedTeacherNumber: string;
  teacherAccountId: string;
  teacherRecurringShiftId: string | null;
  type: TeacherShiftType;
  status: TeacherShiftStatus;
  startsAt: number;
  endsAt: number;
  cancelledAt: number | null;
  cancellationReason: TeacherShiftCancellationReason | null;
}

async function getTeacherShift(shiftId: string): Promise<TeacherShiftResponse> {
  const { data } = await axios.get(`${config.apiUrl}/admin/teacher-shift/${shiftId}`);
  return data;
}

export default function useGetTeacherShift(
  shiftId: string,
  options?: Omit<UseQueryOptions<TeacherShiftResponse, AxiosError<ErrorResponseDto>>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TeacherShiftResponse, AxiosError<ErrorResponseDto>> {
  return useQuery([QueryKey.GetTeacherShift, shiftId], () => getTeacherShift(shiftId), options);
}
