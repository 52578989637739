import { lessonSubjectLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { useMemo } from 'react';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { FormSelect, FormSelectOption, SelectProps } from './FormSelect';

export function SubjectSelect<T extends FieldValues, TObj extends Path<T>>(props: SelectProps & UseControllerProps<T, TObj>) {
  const options = useMemo<FormSelectOption[]>(() => {
    return Object.entries(lessonSubjectLookup).map((lessonSubject) => {
      return {
        label: lessonSubject[1],
        value: lessonSubject[0],
      };
    });
  }, []);

  return <FormSelect label="Subject" labelId="subject-label" options={options} {...props} />;
}
