import axios, { AxiosError } from 'axios';
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface TeacherShiftHistoryResponse {
  historyEntries: ShiftHistoryEntry[];
}

export interface ShiftHistoryEntry {
  id: string;
  note: string;
  isComment: boolean;
  createdBy: string;
  createdById: string;
  createdAt: number;
}

async function getTeacherShiftHistory(shiftId: string): Promise<TeacherShiftHistoryResponse> {
  const { data } = await axios.get(`${config.apiUrl}/admin/teacher-shift/${shiftId}/history`);
  return data;
}

export default function useGetTeacherShiftHistory(
  shiftId: string,
  options?: Omit<UseQueryOptions<TeacherShiftHistoryResponse, AxiosError>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TeacherShiftHistoryResponse, AxiosError> {
  return useQuery([QueryKey.GetTeacherShiftHistory, shiftId], () => getTeacherShiftHistory(shiftId), options);
}
