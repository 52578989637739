import { Box, Button, Card, Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import SearchTextField from '@hoot/components/form/SearchTextField';
import LocationPinIcon from '@hoot/components/icons/timeline-legend/LocationPinIcon';
import { useLessonVisualizer } from './context/LessonVisualizerContextProvider';
import BookDetailsModal from './modals/BookDetailsModal';
import WhiteboardModal from './modals/WhiteboardModal';

interface EventItemProps {
  event: LessonEvent;
  idx: number;
  onToggleSelected: (selectedEvent: LessonEvent) => void;
  handleShowModal: (selectedEvent: LessonEvent) => void;
}

export interface LessonEvent {
  id: string;
  title: string;
  timestamp: number;
  details: string[];
  isSelected: boolean;
  buttonText?: string;
  bookId?: string;
  whiteboardId?: string;
}

export const indexToStyleMapper: Record<number, string | undefined> = {
  0: 'linear-gradient(black, black) no-repeat bottom/1px 50%',
  1: 'linear-gradient(black, black) no-repeat top/1px 100%',
  2: 'linear-gradient(black, black) no-repeat top/1px 50%',
  3: undefined,
};

const LessonEventsCard = () => {
  const { lessonEvents, isLoading } = useLessonVisualizer();
  const [searchText, setSearchText] = useState<string>('');

  const handleSearchChange = (text: string) => {
    setSearchText(text);
  };

  const filteredLessonEvents: LessonEvent[] = useMemo(() => {
    return lessonEvents.filter((event) => {
      return event.title.toLowerCase().includes(searchText) || event.details.some((detail) => detail.toLowerCase().includes(searchText));
    });
  }, [lessonEvents, searchText]);

  return (
    <Card sx={{ py: 3, px: 2 }}>
      <Stack gap={3} height={'100%'}>
        <Typography variant="titleLarge">Lesson Events</Typography>
        <SearchTextField onSearchInputChanged={handleSearchChange} searchInput={searchText} />
        {isLoading ? <SkeletonItems /> : <LessonEventsList lessonEvents={filteredLessonEvents} />}
      </Stack>
    </Card>
  );
};

export default LessonEventsCard;

const LessonEventsList = (props: { lessonEvents: LessonEvent[] }) => {
  const { lessonEvents } = props;
  const { setLessonEvents, seek } = useLessonVisualizer();

  const [selectedBook, setSelectedBook] = useState<string | undefined>(undefined);
  const [selectedWhiteboard, setSelectedWhiteboard] = useState<string | undefined>(undefined);

  const handleIndex = (idx: number) => {
    if (idx === 0) {
      return lessonEvents.length === 1 ? 3 : 0;
    }
    return idx === lessonEvents.length - 1 ? 2 : 1;
  };

  const toggleSelected = (selectedEvent: LessonEvent) => {
    setLessonEvents((prevEvents) =>
      prevEvents.map((event) => (event.id === selectedEvent.id ? { ...event, isSelected: true } : { ...event, isSelected: false })),
    );
    seek(selectedEvent.timestamp);
  };

  const handleShowModal = (selectedEvent: LessonEvent) => {
    if (selectedEvent.bookId) {
      setSelectedBook(selectedEvent.bookId);
    } else if (selectedEvent.whiteboardId) {
      setSelectedWhiteboard(selectedEvent.whiteboardId);
    }
  };

  return (
    <>
      <Stack sx={{ overflowY: 'auto', padding: '4px' }}>
        {lessonEvents.map((event, idx) => (
          <LessonEventItem key={event.id} event={event} idx={handleIndex(idx)} onToggleSelected={toggleSelected} handleShowModal={handleShowModal} />
        ))}
      </Stack>
      <BookDetailsModal bookId={selectedBook} setSelectedBook={setSelectedBook} />
      <WhiteboardModal whiteboardId={selectedWhiteboard} setSelectedWhiteboard={setSelectedWhiteboard} />
    </>
  );
};

const LessonEventItem = (props: EventItemProps) => {
  const eventRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.event.isSelected && eventRef.current) {
      const rect = eventRef.current.getBoundingClientRect();
      const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (!isInView) {
        eventRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [props.event.isSelected]);

  return props.event.isSelected ? (
    <SelectedLessonEvent
      ref={eventRef}
      event={props.event}
      idx={props.idx}
      onToggleSelected={props.onToggleSelected}
      handleShowModal={props.handleShowModal}
    />
  ) : (
    <UnselectedLessonEvent event={props.event} idx={props.idx} onToggleSelected={props.onToggleSelected} handleShowModal={props.handleShowModal} />
  );
};

const UnselectedLessonEvent = (props: EventItemProps) => {
  return (
    <Stack direction={'row'} gap={2} paddingX={2}>
      <Box
        sx={{
          py: 2,
          background: indexToStyleMapper[props.idx],
        }}
      >
        <LocationPinIcon sx={{ background: 'white' }} />
      </Box>
      <Box sx={{ py: 2 }}>
        <Typography
          variant="titleMedium"
          sx={{
            textDecoration: 'underline',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => props.onToggleSelected(props.event)}
        >
          {props.event.title}
        </Typography>
      </Box>
    </Stack>
  );
};

const SelectedLessonEvent = React.forwardRef<HTMLDivElement, EventItemProps>((props, ref) => {
  return (
    <Box ref={ref}>
      <Card
        sx={{
          background: '#E6F0FF',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
        }}
      >
        <Stack direction={'row'} gap={2} paddingX={2}>
          <Box
            sx={{
              paddingTop: 1,
              background: [0, 3].includes(props.idx) ? undefined : 'linear-gradient(black, black) no-repeat top/1px 50%',
            }}
          >
            <LocationPinIcon sx={{ background: '#E6F0FF' }} />
          </Box>
          <Box sx={{ paddingTop: 1 }}>
            <Typography variant="titleMedium">{props.event.title}</Typography>
          </Box>
        </Stack>
        <Stack px={7} paddingBottom={1}>
          {props.event.details?.map((detail, idx) => (
            <Typography key={`event-detail-${idx}`} component="p" variant="bodyMedium">
              • {detail}
            </Typography>
          ))}
          {props.event.buttonText ? (
            <Button variant="contained" size="large" sx={{ maxWidth: '160px', marginTop: 1 }} onClick={() => props.handleShowModal(props.event)}>
              {props.event.buttonText}
            </Button>
          ) : null}
        </Stack>
      </Card>
    </Box>
  );
});

const SkeletonItems = () => {
  return Array.from({ length: 6 }).map((_, index) => (
    <Skeleton key={index} width="100%" height="100%" variant="rounded" sx={{ borderRadius: '8px' }} />
  ));
};
