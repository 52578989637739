import { BadgeOutlined } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { BackgroundCheck, backgroundCheckOptions, backgroundCheckTypes } from '@hoot/interfaces/teacher';
import { NoneListItem } from '../details/Shared';

export const BackgroundCheckRequirements = (props: { onChange: (val: BackgroundCheck[]) => void; value: BackgroundCheck[] }) => {
  const { onChange, value } = props;

  const [showModal, setShowModal] = useState<boolean>(false);

  const onEditClick = () => {
    setShowModal(true);
  };

  return (
    <Grid mt="24px" container item direction="column" xs={12}>
      <Grid container item xs={12}>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Typography component="h3" variant="titleLarge">
              Background Check Requirements
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={onEditClick} variant="contained" color="primary">
              Edit
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ marginTop: '24px' }} container item xs={12}>
        <List sx={{ width: '100%' }}>
          {value && value.length > 0 ? (
            value.map((val, idx) => (
              <ListItem key={`${val}-${idx}`}>
                <ListItemIcon>
                  <BadgeOutlined />
                </ListItemIcon>

                <ListItemText primary={backgroundCheckTypes[val]} />
              </ListItem>
            ))
          ) : (
            <NoneListItem />
          )}
        </List>
      </Grid>
      {showModal ? <BackgroundCheckRequirementsModal onChange={onChange} value={value} open={showModal} onClose={() => setShowModal(false)} /> : null}
    </Grid>
  );
};

const BackgroundCheckRequirementsModal = (props: {
  value: BackgroundCheck[];
  onChange: (val: BackgroundCheck[]) => void;
  open: boolean;
  onClose: () => void;
}) => {
  const { open, onChange, onClose, value } = props;

  const [selectedValues, setSelectedValues] = useState<BackgroundCheck[]>(value);

  const handleCheckboxChange = (val: BackgroundCheck) => (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      const valTyped = val as BackgroundCheck;
      setSelectedValues((currentState) => [...(currentState as BackgroundCheck[]), valTyped]);
    } else {
      const valTyped = val as BackgroundCheck;
      setSelectedValues((currentState) => (currentState as BackgroundCheck[]).filter((s) => s !== valTyped));
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleApply = () => {
    onChange(selectedValues);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Edit Background Check Requirements</DialogTitle>
      <DialogContent>
        <FormGroup>
          {backgroundCheckOptions.map((p) => (
            <FormControlLabel
              key={p.value}
              control={<Checkbox onChange={handleCheckboxChange(p.value)} checked={selectedValues.some((v) => v === p.value)} />}
              label={p.label}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button autoFocus onClick={handleCancel} variant="contained" color="inherit">
          Cancel
        </Button>
        <Button onClick={handleApply} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
