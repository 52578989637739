import { Tooltip } from '@mui/material';
import { DateTime, Interval } from 'luxon';
import { TIME_FORMAT } from '@hoot/common/constants';
import { ScheduleType } from '@hoot/pages/lessons/reschedule-v2/same-teacher-flow/RescheduleWizardSchedulesStep';
import { combineDateWithTime } from '@hoot/utils/dateTime';
import { enumToTitleCase } from '@hoot/utils/stringUtils';
import Availability from './Availability';
import AvailabilityException from './AvailabilityException';
import FreeShiftSlot from './FreeShiftSlot';
import Lesson from './Lesson';
import LessonPreference from './LessonPreference';
import SelectedTimeSlot from './SelectedTimeSlot';
import Shift from './Shift';
import { AvailabilityExceptionSlot, AvailabilityTimeSlot, LessonPreferencesSlot, LessonTimeSlot, SelectedItem, ShiftTimeSlot } from './interfaces';
import { BlockedTimeSlot, Column, RescheduleTimeSlot, TimeSlot } from './styled-components';
import { findOverlappingTimeslots } from './utils';

interface TimeSlotsProps {
  dayOfWeek: DateTime;
  idx: number;
  availability?: AvailabilityTimeSlot[];
  availabilityExceptions?: AvailabilityExceptionSlot[];
  lessonPreferences?: LessonPreferencesSlot[];
  shifts?: ShiftTimeSlot[];
  lessons?: LessonTimeSlot[];
  selectedItem?: SelectedItem;
  onSelected: (selectedItem: SelectedItem) => void;
  disableLessons?: boolean;
  selectedTimeSlot?: DateTime;
  setSelectedTimeSlot?: (selectedDateTime: DateTime) => void;
  scheduleType?: ScheduleType;
  showStudentNumbers?: boolean;
  yAxisLabelsSliced: string[];
  columnHeight: number;
}

function TimeSlots(props: TimeSlotsProps) {
  const endOfDay = props.dayOfWeek.endOf('day');

  // Filter each event for the current day of the week in the iteration.
  const shifts = props.shifts?.filter((s) => s.startAt >= props.dayOfWeek && s.startAt <= endOfDay) ?? [];
  const lessons = props.lessons?.filter((s) => s.startAt >= props.dayOfWeek && s.startAt <= endOfDay) ?? [];
  const availability = props.availability?.filter((a) => a.dayOfWeek === props.dayOfWeek.weekday) ?? [];
  const lessonPreferences = props.lessonPreferences?.filter((lp) => lp.dayOfWeek === props.dayOfWeek.weekday) ?? [];
  const availabilityExceptions = props.availabilityExceptions?.filter((s) => s.startsAt >= props.dayOfWeek && s.startsAt <= endOfDay) ?? [];

  const overlappingLessons = findOverlappingTimeslots(lessons);

  const handleDateClick = (time: string) => {
    const selectedDateTime = DateTime.fromFormat(time, 'h:mm a');
    const combinedDateTime = combineDateWithTime(props.dayOfWeek, selectedDateTime);
    if (props.setSelectedTimeSlot) {
      props.setSelectedTimeSlot(combinedDateTime);
    }
  };

  const isSelectedTimeSlot = props.selectedTimeSlot?.day === props.dayOfWeek?.day && props.selectedTimeSlot?.month === props.dayOfWeek?.month;

  const handleTimeSlot = (time: string): JSX.Element => {
    const slotDateTime = DateTime.fromFormat(time, 'h:mm a');
    const combinedDateTime = combineDateWithTime(props.dayOfWeek, slotDateTime);
    const timeSlotInMillis = combinedDateTime.toMillis();
    const timeSlotEndsAt = combinedDateTime.plus({ minute: 30 });
    const timeSlotEndsAtInMillis = timeSlotEndsAt.toMillis();

    const timeSlotHasLesson = lessons.find(
      (lesson) => lesson.startAt.toMillis() >= timeSlotInMillis && lesson.startAt.toMillis() < timeSlotEndsAtInMillis,
    );
    const timeSlotIsInPast = timeSlotInMillis < DateTime.now().toMillis();

    const freeFutureShiftTimeSlot =
      props.scheduleType === ScheduleType.Teacher &&
      !timeSlotIsInPast &&
      !shifts.find((sts) => {
        const shiftRange = Interval.fromDateTimes(sts.startAt, sts.endsAt);
        const timeSlotRange = Interval.fromDateTimes(combinedDateTime, timeSlotEndsAt);
        return shiftRange.engulfs(timeSlotRange);
      });

    if (props.disableLessons) {
      if (timeSlotIsInPast) {
        return (
          <Tooltip title={'Lesson cannot be scheduled in the past'}>
            <BlockedTimeSlot />
          </Tooltip>
        );
      } else if (timeSlotHasLesson) {
        return (
          <Tooltip title={`${enumToTitleCase(props.scheduleType ?? '')} has lesson scheduled at this time`}>
            <BlockedTimeSlot />
          </Tooltip>
        );
      } else {
        return <RescheduleTimeSlot onClick={() => handleDateClick(time)} />;
      }
    } else if (freeFutureShiftTimeSlot) {
      return <FreeShiftSlot shiftStartTime={combinedDateTime} shiftEndTime={timeSlotEndsAt} />;
    } else {
      return <TimeSlot />;
    }
  };

  return (
    <>
      <Column key={`${props.dayOfWeek.toMillis()}-${props.idx}`} sx={{ height: `${props.columnHeight}px` }}>
        {availability.map((a) => (
          <Availability
            key={`${a.dayOfWeek}-${a.startAt}-${a.endAt}`}
            startsAt={a.startAt}
            endsAt={a.endAt}
            dayOfWeek={props.dayOfWeek}
            zone={a.zone}
            yAxisLabelsSliced={props.yAxisLabelsSliced}
          />
        ))}
        {availabilityExceptions?.map((ae, idx) => (
          <AvailabilityException
            key={`${ae.startsAt}-${ae.endsAt}-${idx}`}
            startsAt={ae.startsAt.toFormat(TIME_FORMAT)}
            endsAt={ae.endsAt.toFormat(TIME_FORMAT)}
            yAxisLabelsSliced={props.yAxisLabelsSliced}
          />
        ))}
        {lessonPreferences.map((lp) => (
          <LessonPreference key={`${lp.dayOfWeek} - ${lp.startAt}`} time={lp.startAt} yAxisLabelsSliced={props.yAxisLabelsSliced} />
        ))}

        {shifts.map((s) => {
          const currentShiftInterval = Interval.fromDateTimes(s.startAt, s.endsAt);
          const otherOverlappingShifts = shifts.filter((x) => {
            const interval = Interval.fromDateTimes(x.startAt, x.endsAt);
            return x.id !== s.id && currentShiftInterval.overlaps(interval);
          });
          let layer = 0;
          if (s.status !== 'CANCELLED' && otherOverlappingShifts.find((x) => x.status === 'CANCELLED')) {
            layer = 1;
          }

          return (
            <Shift
              key={s.id}
              id={s.id}
              selectedItem={props.selectedItem}
              onSelected={props.onSelected}
              startsAt={s.startAt.toFormat(TIME_FORMAT)}
              endsAt={s.endsAt.toFormat(TIME_FORMAT)}
              status={s.status}
              type={s.type}
              yAxisLabelsSliced={props.yAxisLabelsSliced}
              layer={layer}
            />
          );
        })}
        {isSelectedTimeSlot ? (
          <SelectedTimeSlot
            startsAt={props.selectedTimeSlot?.toFormat(TIME_FORMAT) ?? ''}
            endsAt={props.selectedTimeSlot?.plus({ minute: 20 }).toFormat(TIME_FORMAT) ?? ''}
            yAxisLabelsSliced={props.yAxisLabelsSliced}
          />
        ) : null}
        {overlappingLessons.map((l, idx) => (
          <Lesson
            key={`${l}-${idx}`}
            selectedItem={props.selectedItem}
            onSelected={props.onSelected}
            timeslots={l}
            disabled={props.disableLessons ?? false}
            showStudentNumbers={props.showStudentNumbers}
            yAxisLabelsSliced={props.yAxisLabelsSliced}
          />
        ))}
        {props.yAxisLabelsSliced.map((l) => handleTimeSlot(l))}
      </Column>
    </>
  );
}

export default TimeSlots;
