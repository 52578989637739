import { AssessmentReportStatus, InstructionalFocusStatus } from '@hoot-reading/hoot-core/dist/enums/hoot-assessment/assessment-reporting';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { HootAssessmentModule, UnifiedUnitIdentifier } from '@hoot/interfaces/assessments-enums';
import { QueryKey } from '../queryKeys';

export interface InstructionalFocusSubmission {
  date: string;
  score: number;
  status: AssessmentReportStatus;
}

export interface StudentLibraryInstructionalFocusUnit {
  order: number;
  label: string;
  maxScore?: number;
  status: AssessmentReportStatus;
  submissions: InstructionalFocusSubmission[];
  unitIdentifier: UnifiedUnitIdentifier;
  instructionalPathUrl?: string;
  isOverriddenOntoInstructionalLibrary?: boolean;
}

export interface StudentLibraryInstructionalFocusModule {
  order: number;
  module: HootAssessmentModule;
  status: InstructionalFocusStatus;
  label: string;
  units: StudentLibraryInstructionalFocusUnit[];
}

export function useGetStudentInstructionalLibraryUnits(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<StudentLibraryInstructionalFocusModule[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetStudentInstructionalLibraryUnits, studentProfileId],
    async () => {
      return (
        await axios.get<StudentLibraryInstructionalFocusModule[]>(
          `${config.apiUrl}/admin/student-profile/${studentProfileId}/instructional-library-units`,
        )
      ).data;
    },
    options,
  );
}
