import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface QueryStudentProfile {
  name: string;
  page: number;
  pageSize: number;
}

export interface StudentProfile {
  id: string;
  name: string;
  number: number;
  parentName: string;
  email: string;
  phoneNumber: string;
  timezone: string;
}

export interface StudentProfileResponse {
  count: number;
  page: number;
  pageSize: number;
  studentProfiles: StudentProfile[];
}

async function getStudentProfiles(query: QueryStudentProfile): Promise<StudentProfileResponse> {
  const { data } = await axios.post(`${config.apiUrl}/admin/student-profile/search-for-autocomplete`, query);
  return data;
}

export default function useGetStudentProfiles(
  query: QueryStudentProfile,
  options?: Omit<UseQueryOptions<StudentProfileResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetStudentProfiles, query], () => getStudentProfiles(query), options);
}
