import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { CustomerStatus } from '@hoot-reading/hoot-core/dist/enums/user/customer-status.enum';
import { DistrictRepSearchFieldOptions } from '@hoot-reading/hoot-core/dist/enums/user/district-rep-search-field-options.enum';
import { DistrictRepresentativeStatus } from '@hoot-reading/hoot-core/dist/enums/user/district-representative/district-representative-status.enum';
import { AgreementEndReason } from '@hoot-reading/hoot-core/dist/enums/user/teacher/agreement-end-reason.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { LanguageQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/language-qualification.enum';
import { OnLeaveReason } from '@hoot-reading/hoot-core/dist/enums/user/teacher/on-leave-reason.enum';
import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { TeacherAvailability } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-availability.enum';
import { TeacherDateType } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-date-type.enum';
import { TeacherSearchFieldOptions } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-search-field-options.enum';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { UserAccount } from '@hoot/interfaces/user';
import { QueryKey } from '../queryKeys';

export type LanguageQualificationWithIsEmpty =
  | LanguageQualification.English
  | LanguageQualification.French
  | LanguageQualification.Spanish
  | 'IS_EMPTY';

export type HootQualificationWithIsEmpty =
  | 'REGULAR'
  | 'READING_READINESS_ASSESSMENT'
  | 'INFORMAL_ASSESSMENT'
  | 'LESSON_NOTE'
  | 'READING_FIRST_LESSON'
  | 'MATH_FIRST_LESSON'
  | 'OD_LESSON'
  | 'IS_EMPTY';

export interface TeacherSearchParams {
  searchFieldSelection?: TeacherSearchFieldOptions;
  teacherStatus?: (TeacherStatus | 'IS_EMPTY')[];
  hootQualifications?: HootQualificationWithIsEmpty[];
  languageQualifications?: LanguageQualificationWithIsEmpty[];
  spokenLanguages?: SpokenLanguage[];
  teacherAvailability?: (TeacherAvailability | 'IS_EMPTY')[];
  onLeaveReason?: OnLeaveReason | 'IS_EMPTY';
  dateType?: TeacherDateType;
  fromDate?: string;
  toDate?: string;
  agreementEndReason?: AgreementEndReason | 'IS_EMPTY';
  identificationSubmitted?: boolean;
}

export enum CustomerSearchFieldOptions {
  ParentName = 'PARENT_NAME',
  ParentNumber = 'PARENT_NUMBER',
  ParentEmail = 'PARENT_EMAIL',
  ParentPhoneNumber = 'PARENT_PHONE_NUMBER',
  All = 'ALL',
}

export interface CustomerSearchParams {
  searchFieldSelection?: CustomerSearchFieldOptions;
  customerStatus?: (CustomerStatus | 'IS_EMPTY')[];
  account?: string[];
  fromLeadInitiationDate?: string;
  toLeadInitiationDate?: string;
}

export interface DistrictRepSearchParams {
  searchFieldSelection?: DistrictRepSearchFieldOptions;
  districtRepStatus?: DistrictRepresentativeStatus;
  districtRepLocationIds?: string[];
  districtRepSchoolIds?: string[];
}

export interface UserQuery {
  searchText?: string;
  page: number;
  pageSize: number;
  accountType?: UserAccount;
  isEnabled?: boolean;
  isPasswordSet?: boolean;
  teacherQuery: TeacherSearchParams;
  customerQuery: CustomerSearchParams;
  districtRepQuery: DistrictRepSearchParams;
  country?: string;
  timeZones?: string[];
  provincesStates?: string[];
}

export interface TeacherAccountResponse {
  id: string;
  number: number;
  prefixedNumber: string;
  status: TeacherStatus;
  hootQualifications: HootQualification[];
  languageQualifications: LanguageQualification[];
}

export interface DistrictRepresentativeEnrolmentResponse {
  id: string;
  status: EnrolmentStatus;
  friendlyId: string;
}

export interface DistrictRepSchoolResponse {
  id: string;
  name: string;
}

export interface DistrictRepDistrictResponse {
  id: string;
  name: string;
  schools: DistrictRepSchoolResponse[];
}

export interface DistrictRepresentativeAccountResponse {
  id: string;
  number: number;
  prefixedNumber: string;
  status: DistrictRepresentativeStatus;
  district?: DistrictRepDistrictResponse; // should be only one district per district representative
  standAloneSchool?: DistrictRepSchoolResponse; // should be only one stand alone school per district representative
  enrolments: DistrictRepresentativeEnrolmentResponse[];
}

export interface CustomerAccountResponse {
  id: string;
  name: string;
  number: string;
}

export interface UserAccountResponse {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  hasTeacherAccount: boolean;
  hasCustomerAccount: boolean;
  program: string;
  partnerName: string;
  siteName: string;
  accountName: string;
  number?: number;
  teacherAccount?: TeacherAccountResponse;
  districtRepAccount?: DistrictRepresentativeAccountResponse;
  customerAccount?: CustomerAccountResponse;
}

export interface UserQueryResponse {
  count: number;
  page: number;
  pageSize: number;
  users: UserAccountResponse[];
}

const getUsers = async (searchParams: UserQuery): Promise<UserQueryResponse> => {
  const { data } = await axios.post<UserQueryResponse>(`${config.apiUrl}/admin/user/search`, searchParams);
  return data;
};

export default function useGetUsers(searchParams: UserQuery) {
  return useQuery([QueryKey.UserSearch, searchParams], () => getUsers(searchParams));
}
