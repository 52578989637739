import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchTextField, { SearchTextFieldProps } from '@hoot/components/form/SearchTextField';
import { FormOption } from '@hoot/interfaces/form';
import { LocationStudentsPageQueryParams } from '@hoot/pages/district-schools/students/models';

export enum LocationStudentsSearchFieldOptions {
  Name = 'NAME',
  Number = 'NUMBER',
  HfsNumber = 'HFS_NUMBER',
  All = 'ALL',
}

const searchFieldOptions: FormOption<LocationStudentsSearchFieldOptions>[] = [
  { value: LocationStudentsSearchFieldOptions.Name, label: 'Student Name' },
  { value: LocationStudentsSearchFieldOptions.Number, label: 'Student Number' },
  { value: LocationStudentsSearchFieldOptions.HfsNumber, label: 'HFS Number' },
  { value: LocationStudentsSearchFieldOptions.All, label: 'All Fields' },
];

type SchoolStudentsSearchTextFieldProps = Pick<SearchTextFieldProps, 'sx'>;

const LocationStudentsSearchTextField = (props: SchoolStudentsSearchTextFieldProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState(searchParams.get(LocationStudentsPageQueryParams.Search) ?? '');

  const onSearchInputChanged = (searchInput: string) => {
    setSearchInput(searchInput);
  };

  // When search input is debounced, then update the search query arg in the URL.
  const onSearchInputDebounced = (searchInput: string) => {
    setSearchParams(
      (prev) => {
        const newSearchParams = new URLSearchParams(prev);
        newSearchParams.set(LocationStudentsPageQueryParams.Search, searchInput);
        // Reset to the initial page when search criteria changes, else result set may become out of sync.
        newSearchParams.set(LocationStudentsPageQueryParams.Page, '1');
        return newSearchParams;
      },
      { replace: true },
    );
  };

  const searchFieldOnChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchParams(
      (prev) => {
        const newSearchParams = new URLSearchParams(prev);
        newSearchParams.set(LocationStudentsPageQueryParams.SearchByField, value as LocationStudentsSearchFieldOptions);
        return newSearchParams;
      },
      { replace: true },
    );
  };

  const selectedSearchField =
    (searchParams.get(LocationStudentsPageQueryParams.SearchByField) as LocationStudentsSearchFieldOptions) ?? LocationStudentsSearchFieldOptions.All;

  return (
    <Stack direction={'row'} gap={2}>
      <SearchTextField
        searchInput={searchInput}
        onSearchInputChanged={onSearchInputChanged}
        onSearchInputDebounced={onSearchInputDebounced}
        {...props}
      />
      <Box width={'250px'} mt={3}>
        <FormControl fullWidth variant={'outlined'} size={'medium'}>
          <InputLabel id={'fieldSearchField'}>Search in</InputLabel>
          <Select labelId={'fieldSearchField'} label={'Search in'} value={selectedSearchField as any} onChange={searchFieldOnChange}>
            {searchFieldOptions.map((x) => (
              <MenuItem key={`fieldSearchField-${x.value}`} value={x.value}>
                {x.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Stack>
  );
};

export default LocationStudentsSearchTextField;
