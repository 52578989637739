import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';

async function reSendHootEmployeeAccountCreation(email: string) {
  await axios.post(`${config.apiUrl}/admin/hoot-employee/send-account-created`, {
    email,
  });
}

export function useReSendHootEmployeeAccountCreation(options?: Omit<UseMutationOptions<void, AxiosError, string>, 'mutationKey' | 'mutationFn'>) {
  return useMutation((request) => reSendHootEmployeeAccountCreation(request), options);
}
