import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface GetLessonRecordingAccessResponse {
  accessLink: string;
  expires: number;
  recordingId: string;
}

async function getDailyRecordingsAccessLink(lessonId: string, recordingId: string): Promise<GetLessonRecordingAccessResponse> {
  const { data } = await axios.get<GetLessonRecordingAccessResponse>(
    `${config.apiUrl}/admin/scheduler/lesson/${lessonId}/recordings/${recordingId}/access-link`,
  );
  return data;
}

export default function useGetDailyRecordingsAccessLink(
  lessonId: string,
  recordingId: string,
  options?: Omit<UseQueryOptions<GetLessonRecordingAccessResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetDailyRecordingsAccessLink, lessonId, recordingId], () => getDailyRecordingsAccessLink(lessonId, recordingId), options);
}

export function useGetDailyRecordingsAccessLinks(
  lessonId: string,
  recordingIds: string[],
  options?: Omit<UseQueryOptions<GetLessonRecordingAccessResponse[], AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDailyRecordingsAccessLink, lessonId, recordingIds],
    async () => await Promise.all(recordingIds.map((id) => getDailyRecordingsAccessLink(lessonId, id))),
    options,
  );
}
