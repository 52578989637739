import { TeacherBlockType } from '@hoot-reading/hoot-core/dist/enums/teacher-block';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface RequestedBlockForConflictChecker {
  startsAt: number;
  endsAt: number;
}

export interface TeacherRecurringBlockRequest {
  teacherAccountId: string;
  startsAt: number;
  endsAt: number;
  type: TeacherBlockType;
  recurrenceRules: string;
  requestedBlocks: RequestedBlockForConflictChecker[];
}

const createTeacherRecurringBlock = async (request: TeacherRecurringBlockRequest): Promise<string> => {
  const { data } = await axios.post<string>(`${config.apiUrl}/admin/teacher-block/recurring`, request);
  return data;
};

const useCreateTeacherRecurringBlock = (
  options?: Omit<UseMutationOptions<string, AxiosError, TeacherRecurringBlockRequest>, 'mutationFn' | 'mutationKey'>,
) => {
  return useMutation<string, AxiosError, TeacherRecurringBlockRequest>((request) => createTeacherRecurringBlock(request), options);
};

export default useCreateTeacherRecurringBlock;
