import { Button, DialogActions, Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { LessonSetTeacherAssignmentWizardState } from '@hoot/pages/lesson-sets/details/teacher-assignment-tab/lesson-set-wizard-modal/LessonSetWizardModal';
import { TIME_FORMAT } from '../../../../../common/constants';
import { TimePicker2 } from '../../../../../components/form/DatePicker';

interface TimeSelectionStepProps {
  state: LessonSetTeacherAssignmentWizardState;
  onCancel: () => void;
  onTimeSelected: (selectedTime: DateTime) => void;
}

// NOTE: This component was basically derived from src/pages/lessons/create-v2/teacher-conflict-check/TeacherConflictCheckStep.tsx
// with some subtle differences.
const LessonSetLessonGroupingEditTimeStep = (props: TimeSelectionStepProps) => {
  const { state, onCancel, onTimeSelected } = props;
  const { student, selectedTime } = state;
  const [timeInput, setTimeInput] = useState<DateTime>(selectedTime);

  const [timeInputError, setTimeInputError] = useState(false);

  const studentTimeZoneLabel = useMemo(() => {
    if (!student?.timeZone) {
      return '-';
    }
    return DateTime.now().setZone(student.timeZone).toFormat('ZZZZZ');
  }, [student?.timeZone]);

  const lessonGroupTimeZone = useMemo(() => {
    return timeInput?.toFormat('ZZZZZ');
  }, [timeInput]);

  const handleTimeChange = (dateTime: DateTime | null) => {
    if (dateTime !== null) {
      setTimeInput(dateTime);
    }
  };

  const _onApplyTimeChange = () => {
    onTimeSelected(timeInput);
  };

  return (
    <Stack>
      <Stack>
        <Typography variant="bodyMedium" sx={{ background: '#FFE9CD', borderRadius: '4px', padding: 1 }}>
          Student time zone is {studentTimeZoneLabel}
        </Typography>
        <Typography variant="bodyMedium" sx={{ background: '#EFEFEF', borderRadius: '4px', padding: 1, marginTop: 1 }}>
          Your time zone is {lessonGroupTimeZone}
        </Typography>
        <Box sx={{ marginTop: 3 }}>
          <TimePicker2
            ampm
            orientation="landscape"
            label="Time"
            value={timeInput}
            onChange={handleTimeChange}
            // e.g. "1:30 PM"
            format={TIME_FORMAT}
            minutesStep={5}
            slotProps={{
              textField: {
                sx: { width: 'max-content' },
                variant: 'outlined',
                helperText: `Timezone: ${lessonGroupTimeZone}`,
              },
            }}
            onError={(reason, _) => {
              setTimeInputError(reason !== null);
            }}
          />
        </Box>
      </Stack>
      <Divider sx={{ marginLeft: 3, marginRight: 3 }} />
      <DialogActions>
        <Button variant="outlined" size="large" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" size="large" onClick={_onApplyTimeChange} disabled={timeInputError}>
          Apply
        </Button>
      </DialogActions>
    </Stack>
  );
};

export default LessonSetLessonGroupingEditTimeStep;
