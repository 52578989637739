import { StudentAttendanceRate } from '@hoot-reading/hoot-core/dist/enums/hfs/student-attendance-rate.enum';
import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { studentAttendance, studentAttendanceRateMapping } from '@hoot/interfaces/profile';
import FilterDropDown from '../../FilterDropDown';

export function StudentAttendanceFilterDropDown(props: {
  value: StudentAttendanceRate[];
  onChange: (val: StudentAttendanceRate[]) => void;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { value, onChange, popOverButtonSx } = props;

  return (
    <FilterDropDown<string>
      title="Student Attendance"
      dismissPopoverOnSelection={true}
      value={value}
      options={studentAttendance.map((s) => ({
        label: s.label,
        value: s.value,
      }))}
      onChange={(val) => onChange(val as StudentAttendanceRate[])}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveStudentAttendanceFilterChips(props: { value?: StudentAttendanceRate[]; onChange: (val: StudentAttendanceRate[]) => void }) {
  const { value = [], onChange } = props;

  const handleDelete = (val: StudentAttendanceRate) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`Attendance Rate: ${studentAttendanceRateMapping[c]}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}
