import axios from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';
import { UnifiedUnitIdentifier } from '@hoot/interfaces/assessments-enums';
import { UseMutationOptions } from '@hoot/interfaces/axios-options';

export interface OverrideStudentInstructionalLibraryUnitsRequest {
  unitIdentifiers: UnifiedUnitIdentifier[];
}

async function setOverriddenInstructionalUnits(studentProfileId: string, payload: OverrideStudentInstructionalLibraryUnitsRequest): Promise<void> {
  const { data } = await axios.post<void>(`${config.apiUrl}/admin/student-profile/${studentProfileId}/override-instructional-library-units`, payload);
  return data;
}

export default function useSetOverriddenInstructionalUnits(
  studentProfileId: string,
  options?: UseMutationOptions<OverrideStudentInstructionalLibraryUnitsRequest, void>,
) {
  return useMutation(
    (payload: OverrideStudentInstructionalLibraryUnitsRequest) => setOverriddenInstructionalUnits(studentProfileId, payload),
    options,
  );
}
