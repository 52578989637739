import { ScheduledLessonStatus, ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { TeacherShiftCancellationReason, TeacherShiftStatus, TeacherShiftType } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface TeacherShiftUtilizationQuery {
  date: string;
  teacherStatuses?: TeacherStatus[];
  shiftToday?: boolean;
  shiftType?: TeacherShiftType[];
  showCancelled?: boolean;
  fromStartTime?: string;
  toStartTime?: string;
}

export interface ShiftUtilizationLesson {
  id: string;
  prefixedNumber: string;
  status: ScheduledLessonStatus;
  type: ScheduledLessonType;
  startsAt: number;
  durationInMinutes: number;
  studentId: string;
  studentPrefixedNumber: string;
}

export interface ShiftUtilizationShift {
  id: string;
  prefixedNumber: string;
  teacherAccountId: string;
  type: TeacherShiftType;
  status: TeacherShiftStatus;
  startsAt: number;
  endsAt: number;
  cancelledAt: number | null;
  cancellationReason: TeacherShiftCancellationReason | null;
}

export interface ShiftUtilizationTeacher {
  id: string;
  name: string;
  prefixedNumber: string;
  shifts: ShiftUtilizationShift[];
  lessons: ShiftUtilizationLesson[];
}

export interface TeacherShiftUtilizationResponse {
  teachers: ShiftUtilizationTeacher[];
  subRequiredLessons: ShiftUtilizationLesson[];

  // Below fields are in the "summary bar" and not yet calculated
  // from requirements of SC-11291: "Note: the summary bar under the applied filters in the designs will be created in an iteration ticket."
  utilizedSlots: number;
  hoursOnShift: number;
  hoursInLesson: number;
}

async function getTeacherShiftUtilization(query: TeacherShiftUtilizationQuery) {
  const { data } = await axios.post<TeacherShiftUtilizationResponse>(`${config.apiUrl}/admin/teacher-shift/utilization`, query);
  return data;
}

export default function useTeacherGetShiftUtilization(
  query: TeacherShiftUtilizationQuery,
  options?: Omit<UseQueryOptions<TeacherShiftUtilizationResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetTeacherShiftUtilization, query], () => getTeacherShiftUtilization(query), options);
}
