import { CircularProgress } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useAlert } from '@hoot/contexts/AlertContext';
import useCreateParentComment from '@hoot/hooks/api/end-users/parent/useCreateParentComment';
import useGetParentHistory from '@hoot/hooks/api/end-users/parent/useGetParentTimeline';
import { ParentProfileResponse } from '@hoot/hooks/api/end-users/useGetParentProfile';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { CommentSubmission } from '@hoot/interfaces/history';
import { CommonHistoryTimeline } from '@hoot/pages/users/common/CommonHistoryTimeline';

interface Props {
  parentProfile: ParentProfileResponse; // TODO why is this different for parents?
}

const ParentHistoryTab = (props: Props) => {
  const { parentProfile } = props;
  const { error, success } = useAlert();
  const queryClient = useQueryClient();

  const name = `${parentProfile.personalInformation.firstName ?? '<first name>'} ${parentProfile.personalInformation.lastName ?? '<last name>'}`;

  const historyResponse = useGetParentHistory(parentProfile.customerAccountId, {
    cacheTime: 0,
    onError: (err) => {
      const message =
        err.response && err.response.data?.message?.trim().length > 0
          ? err.response.data.message
          : `There was an issue getting the comments for ${name}!`;
      error(message);
      console.error(err);
    },
  });

  const createComment = useCreateParentComment(parentProfile.customerAccountId, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.GetParentHistory, parentProfile.customerAccountId]);
      success(`Successfully added an item to ${name}'s history timeline!`);
    },
    onError: (err) => {
      error(`There was an issue submitting the item for ${name}!`);
      console.error(err);
    },
  });

  if (historyResponse.isLoading) {
    return <CircularProgress />;
  }

  const existingItems = historyResponse.data?.historyItems ?? [];

  const handleCommentSubmit = (comment: CommentSubmission, cb: () => void) => {
    createComment.mutate(comment, {
      onSettled: () => {
        cb();
      },
    });
  };

  return <CommonHistoryTimeline historyItems={existingItems} handleCommentSubmit={handleCommentSubmit} />;
};

export default ParentHistoryTab;
