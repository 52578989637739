import { SearchStudentsQuerySortKeyEnum } from '@hoot-reading/hoot-core/dist/enums/user/search-student-query-sort-key.enum';
import { Typography } from '@mui/material';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import WarningModal from '@hoot/components/modals/WarningModal';
import SelectStudentModal from '@hoot/components/modals/select-student-modal/SelectStudentModal';
import { SearchStudentResponse } from '@hoot/hooks/api/end-users/useSearchStudentsV2';
import AddStudentModal from '@hoot/pages/users/parents/AddStudentModal';

interface Props {
  userId: string;
  showAddStudentModal: boolean;
  setShowAddStudentModal: Dispatch<SetStateAction<boolean>>;
  handleStudentSelected: (selectedStudent: SearchStudentResponse) => void;
  parentName: string;
}
const StudentModals = (props: Props) => {
  const { userId, showAddStudentModal, setShowAddStudentModal, handleStudentSelected, parentName } = props;
  const [selectedStudent, setSelectedStudent] = useState<SearchStudentResponse>();
  const [showSelectStudentModal, setShowSelectStudentModal] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const warnStudentSelected = (selectedStudent: SearchStudentResponse) => {
    setSelectedStudent(selectedStudent);
    setShowWarningModal(true);
  };

  return (
    <>
      <AddStudentModal
        parentUserId={userId}
        open={showAddStudentModal}
        setOpen={setShowAddStudentModal}
        setShowSelectStudentModal={setShowSelectStudentModal}
      />
      <SelectStudentModal
        open={showSelectStudentModal}
        onDismiss={() => setShowSelectStudentModal(false)}
        onApply={warnStudentSelected}
        queryDefaults={{ hasParent: false }}
        hideColumns={[SearchStudentsQuerySortKeyEnum.Parent, SearchStudentsQuerySortKeyEnum.ParentEmail]}
      />

      <WarningModal
        title="Potential Data Privacy Risk"
        subTitle={
          <Fragment>
            <Typography variant="bodyLarge">You have selected to associate</Typography>
            <Typography variant="titleMedium">
              {selectedStudent?.name} <span style={{ fontWeight: 400 }}>to</span> {parentName}
            </Typography>
          </Fragment>
        }
        warningText={
          <Fragment>
            <Typography variant="bodyMedium">An incorrect association may share student data with unauthorized parties.</Typography>
            <Typography variant="bodyMedium">Please double-check before confirmation.</Typography>
          </Fragment>
        }
        open={showWarningModal}
        onCancel={() => setShowWarningModal(false)}
        primaryAction={{
          label: 'Confirm',
          onClick: () => {
            if (selectedStudent) {
              handleStudentSelected(selectedStudent);
            }
            setShowSelectStudentModal(false);
            setShowWarningModal(false);
          },
        }}
        secondaryAction={{
          label: 'Go back',
          onClick: () => setShowWarningModal(false),
        }}
      />
    </>
  );
};

export default StudentModals;
