import AddIcon from '@mui/icons-material/Add';
import { Button, Card, CardContent, CircularProgress, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { LegacyRef, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { DurationSelect } from '@hoot/components/form/selectFields/DurationSelect';
import ContractIcon from '@hoot/components/icons/ContractIcon';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import useGetEnrolmentCapacity from '@hoot/hooks/api/hfs/useGetEnrolmentCapacity';
import useUpdateEnrolmentCapacity, { UpdateEnrolmentCapacityRequest } from '@hoot/hooks/api/hfs/useUpdateEnrolmentCapacity';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import CombinationModal from '@hoot/pages/district-schools/details/enrolments/capacity/CombinationModal';
import EnrolmentSchedulingCard from '@hoot/pages/district-schools/details/enrolments/capacity/EnrolmentSchedulingCard';
import { DayOfWeek, convert12HourClockTo24, convert24HourClockTo12 } from '@hoot/utils/dateTime';

export interface ScheduleCombinationSlot {
  id?: string;
  dayOfWeek: DayOfWeek;
  time: string;
}

export interface ScheduleCombination {
  id?: string;
  allowed: number;
  combinationSlots: ScheduleCombinationSlot[];
  available: number;
  booked: number;
  sequence: number;
}

export interface UpdateEnrolmentCapacityForm {
  numberOfAllowedLessonsSets: number | '';
  scheduleLessonsDuration: number | '';
  scheduleCombinations: ScheduleCombination[];
}

interface Props {
  enrolmentId: string;
  formRef: React.MutableRefObject<HTMLFormElement | undefined>;
}

const EnrolmentCapacity = (props: Props) => {
  const { enrolmentId, formRef } = props;
  const { setIsDirty } = useBlockIfDirty();
  const [combinationModalSequence, setCombinationModalSequence] = useState<number | undefined>(undefined);
  const { control, handleSubmit, setValue, watch, formState, reset } = useForm<UpdateEnrolmentCapacityForm>({
    mode: 'onSubmit',
    defaultValues: {
      numberOfAllowedLessonsSets: '',
      scheduleLessonsDuration: '',
      scheduleCombinations: [],
    },
  });

  const {
    data: enrolment,
    isLoading,
    isError,
  } = useGetEnrolmentCapacity(enrolmentId, {
    enabled: !!enrolmentId,
  });

  const { scheduleCombinations } = watch();
  const isCreateCombinationsDisabled = !enrolment?.scheduleLessonsDuration || scheduleCombinations?.length >= 25;
  const updateEnrolmentCapacity = useUpdateEnrolmentCapacity(enrolmentId);
  const { success, error } = useAlert();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (enrolment) {
      reset({
        numberOfAllowedLessonsSets: enrolment.numberOfAllowedLessonsSets,
        scheduleLessonsDuration: enrolment.scheduleLessonsDuration,
        scheduleCombinations: enrolment.scheduleCombinations.map((sc, index) => ({
          id: sc.id,
          allowed: sc.allowed,
          sequence: index + 1,
          booked: sc.booked,
          available: sc.available,
          combinationSlots: sc.combinationSlots.map((cs) => ({
            id: cs.id,
            dayOfWeek: cs.dayOfWeek,
            time: convert12HourClockTo24(cs.time),
          })),
        })),
      });
    }
  }, [enrolment, reset]);

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState, setIsDirty]);

  const handleNewCombination = () => {
    const nextSequence = (scheduleCombinations?.length ?? 0) + 1;
    setCombinationModalSequence(nextSequence);
  };

  if (isLoading || !enrolment) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (isError) {
    return <Stack alignItems="center">Failed to load the page!</Stack>;
  }

  const onSubmit = (data: UpdateEnrolmentCapacityForm) => {
    const request: UpdateEnrolmentCapacityRequest = {
      numberOfAllowedLessonsSets: +data.numberOfAllowedLessonsSets,
      scheduleLessonsDuration: +data.scheduleLessonsDuration,
      scheduleCombinations: data.scheduleCombinations.map((sc) => ({
        id: sc.id,
        allowed: sc.allowed,
        combinationSlots: sc.combinationSlots.map((cs) => ({
          id: cs.id,
          dayOfWeek: cs.dayOfWeek,
          time: convert24HourClockTo12(cs.time),
        })),
      })),
    };
    updateEnrolmentCapacity.mutate(request, {
      onSuccess: () => {
        success('Enrolment updated successfully');
        queryClient.invalidateQueries([QueryKey.GetEnrolmentCapacity, enrolmentId]);
      },
      onError: (errorData) => {
        console.error(errorData);
        error(`There was an error, the enrolment has not been edited.`);
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef as LegacyRef<HTMLFormElement>} id="enrolment-capacity-form">
        <Grid item container mt={2} spacing={2}>
          <Grid item container xs={12} md={6}>
            <Card sx={{ width: 1 }}>
              <CardContent sx={{ p: 3 }}>
                <Grid item container xs={12} spacing={3}>
                  <Grid item container xs={12}>
                    <Typography align="center" variant="titleLarge">
                      Contract Size
                    </Typography>
                  </Grid>

                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        name="numberOfAllowedLessonsSets"
                        rules={{
                          validate: (val: number | '' | undefined) => {
                            if (!val) {
                              return 'Number of allowed lessons is required.';
                            }

                            if (val && val < 1) {
                              return 'A minimum of 1 lesson is required.';
                            }

                            if (val && enrolment?.numberOfProductAllowedLessonsSets && val > enrolment.numberOfProductAllowedLessonsSets) {
                              return `This can not exceed the product allowed: ${enrolment?.numberOfProductAllowedLessonsSets}.`;
                            }
                            return true;
                          },
                        }}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="number"
                            helperText={fieldState.error?.message}
                            error={!!fieldState.error}
                            fullWidth
                            label={`Number of Lessons Sets (Allowed)*`}
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Stack spacing={2} p={3} width="100%">
                    <Stack direction="row">
                      <Typography align="center" variant="bodySmall">
                        Calculated
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <ContractIcon />
                      <Typography align="center" variant="bodyLarge" pl={2}>
                        {enrolment.numberOfRegisteredLessonsSets} - Lesson Sets (Registered)
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item container xs={12} md={6}>
            <Stack direction="column" width="100%">
              <Card sx={{ width: 1, height: 1 }}>
                <CardContent sx={{ p: 3 }}>
                  <Typography variant="titleLarge">Utilization</Typography>
                  <Stack spacing={2}>
                    <Stack direction="row" pt={2}>
                      <Typography align="center" variant="bodySmall">
                        Calculated
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <ContractIcon />
                      <Typography align="center" variant="bodyLarge">
                        {enrolment.numberOfScheduledLessons} - Scheduled Lessons
                      </Typography>
                    </Stack>
                    <Divider />
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <ContractIcon />
                      <Typography align="center" variant="bodyLarge">
                        {enrolment.numberOfOccurredLessons} - Occurred Lessons
                      </Typography>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          </Grid>
        </Grid>

        <Grid item container mt={1} spacing={1}>
          <Grid item xs={12}>
            <Card sx={{ width: 1 }}>
              <CardContent>
                <Grid item container xs={12} gap={3}>
                  <Grid item container xs={12}>
                    <Typography variant="titleLarge">Scheduling Capacity</Typography>
                  </Grid>
                  <Grid item container direction="row" xs={12} md={6} spacing={3}>
                    <Grid item container xs={6}>
                      <DurationSelect label="Lesson Duration" name="scheduleLessonsDuration" control={control} />
                    </Grid>
                    <Grid item container xs={6}>
                      <Button disabled={isCreateCombinationsDisabled} color="primary" variant="contained" size="large" onClick={handleNewCombination}>
                        <AddIcon /> Create Combination
                      </Button>
                    </Grid>
                  </Grid>
                  {scheduleCombinations?.length > 0 ? (
                    <EnrolmentSchedulingCard setValue={setValue} watch={watch} setSequence={setCombinationModalSequence} />
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {combinationModalSequence ? (
          <CombinationModal
            sequence={combinationModalSequence}
            setSequence={setCombinationModalSequence}
            scheduleCombinations={scheduleCombinations}
            setValue={setValue}
          />
        ) : null}
      </form>
    </>
  );
};
export default EnrolmentCapacity;
