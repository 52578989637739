import { OpenInNew } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import ClosedBook from '@hoot/components/icons/ClosedBookIcon';
import GreenCircle from '@hoot/components/icons/GreenCircleIcon';
import RedCircle from '@hoot/components/icons/RedCircleIcon';
import { HootFormativeAssessmentResponse } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentFormativeAssessments';
import useGetLettersAndSoundsFormativeAssessmentDetails from '@hoot/hooks/api/user/student/lesson-reviews/useGetLettersAndSoundsFormativeAssessmentDetails';
import { routes } from '@hoot/routes/routes';

interface IProps {
  open: boolean;
  handleClose: () => void;
  formativeAssessment: HootFormativeAssessmentResponse | undefined;
}

const LettersAndSoundsFormativeAssessmentDetailsModal = (props: IProps) => {
  const { open, handleClose, formativeAssessment } = props;
  const { data, isLoading } = useGetLettersAndSoundsFormativeAssessmentDetails(
    formativeAssessment?.lessonReviewId ?? '',
    formativeAssessment?.bookId ?? '',
    {
      enabled: !!formativeAssessment,
      retry: false,
    },
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        Formative Assessment Details
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack gap={'24px'}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="titleMedium">Lesson Number</Typography>
                <Typography variant="bodyMedium">{data?.lessonNumber}</Typography>
              </Box>
              <IconButton component="a" href={routes.lessons.details.url(data?.lessonId ?? '')} target="_blank" rel="noopener noreferrer">
                <OpenInNew />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="titleMedium">Lesson Date</Typography>
              <Typography variant="bodyMedium">{data?.lessonDate}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="titleMedium">Formative Assessment Type</Typography>
              <Typography variant="bodyMedium">Fluency</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="titleMedium">Score</Typography>
              <Typography variant="bodyMedium">{data?.score}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="titleMedium">Assessed By</Typography>
                {data?.teacherName ? <Typography variant="bodyMedium">{data?.teacherName}</Typography> : null}
                {data?.prefixedTeacherNumber ? <Typography variant="bodyMedium">{data?.prefixedTeacherNumber}</Typography> : null}
              </Box>
              <IconButton
                component="a"
                href={routes.users.teachers.details.url(data?.teacherAccountId ?? '')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <OpenInNew />
              </IconButton>
            </Box>
            <Typography variant="titleMedium">Resource Used</Typography>
            <Stack>
              <Card
                elevation={2}
                sx={{
                  width: '506px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingLeft: '16px',
                  paddingY: '4px',
                  gap: '16px',
                }}
              >
                <ClosedBook />
                <Stack>
                  <Typography variant="bodyLarge">{data?.bookTitle}</Typography>
                  <Stack direction="column" gap={'4px'}>
                    {data?.lettersReadCorrectly ? (
                      <Stack direction="row" gap={'4px'} alignItems={'center'}>
                        <GreenCircle />
                        <Typography variant="bodyMedium" sx={{ padding: '4px' }}>
                          {data?.lettersReadCorrectly}
                        </Typography>
                      </Stack>
                    ) : null}
                    {data?.lettersReadIncorrectly ? (
                      <Stack direction="row" gap={'4px'} alignItems={'center'}>
                        <RedCircle />
                        <Typography variant="bodyMedium" sx={{ padding: '4px' }}>
                          {data?.lettersReadIncorrectly}
                        </Typography>
                      </Stack>
                    ) : null}
                  </Stack>
                </Stack>
              </Card>
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <Divider sx={{ marginX: 3 }} />
      <DialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LettersAndSoundsFormativeAssessmentDetailsModal;
