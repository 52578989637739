import { CircularProgress } from '@mui/material';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import AlertPopup from '@hoot/components/ui/AlertPopup';
import { AlertProvider } from '@hoot/contexts/AlertContext';
import { DiscardPromptHandler } from './components/modals/DiscardPrompt';
import ErrorBoundary from './components/ui/ErrorBoundary';
import { useAuth } from './contexts/Auth/AuthContext';
import PublicRoutes from './routes/PublicRoutes';
import SecureRoutes from './routes/SecureRoutes';
import hootTheme from './theme';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={hootTheme}>
            <AlertProvider>
              {isAuthenticated === undefined ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <CircularProgress style={{ color: '#1976d2' }} />
                </div>
              ) : isAuthenticated ? (
                <SecureRoutes />
              ) : (
                <PublicRoutes />
              )}
              <AlertPopup />
              <DiscardPromptHandler />
            </AlertProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
};

export default App;
