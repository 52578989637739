import { DistrictRepSearchFieldOptions } from '@hoot-reading/hoot-core/dist/enums/user/district-rep-search-field-options.enum';
import { DistrictRepresentativeStatus } from '@hoot-reading/hoot-core/dist/enums/user/district-representative/district-representative-status.enum';
import { Close } from '@mui/icons-material';
import { Button, Divider, Drawer, FormControl, Grid, IconButton, InputLabel, List, ListItem, MenuItem, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { MultiSelect } from '@hoot/components/form/MultiSelect';
import { Select } from '@hoot/components/form/SelectField';
import { UserQuery } from '@hoot/hooks/api/end-users/useGetUsers';
import useGetAllBillableLocations from '@hoot/hooks/api/hfs/useGetAllBillableLocations';
import useGetAllSchools from '@hoot/hooks/api/hfs/useGetAllSchools';
import { UserAccount } from '@hoot/interfaces/user';
import { districtRepStatusFilterOptions } from '@hoot/pages/users/interfaces/user-filters-form';

interface Form {
  accountType: UserAccount | '';
  timeZone: string[];
  province: string[];
  userIsEnabled: string | '';
  country: string | '';
  isPasswordSet: string | '';
  districtRep: {
    searchFieldSelection?: DistrictRepSearchFieldOptions;
    districtRepLocationIds?: (string | 'IS_EMPTY')[] | '';
    districtRepSchoolIds?: (string | 'IS_EMPTY')[] | '';
    districtRepStatus?: DistrictRepresentativeStatus | '';
  };
}

interface Props {
  showDrawer: boolean;
  setShowDrawer: (showAddHootEmployeeModal: boolean) => void;
  userQuery: UserQuery;
  setUserQuery: React.Dispatch<React.SetStateAction<UserQuery>>;
}

const DistrictRepFilters = (props: { control: Control<Form> }) => {
  const { control } = props;
  const districts = useGetAllBillableLocations();
  const schools = useGetAllSchools();

  const districtOptions =
    districts?.data?.billableLocations.map((option) => {
      return { value: option.id, label: option.name };
    }) ?? [];

  const schoolsOptions =
    schools?.data?.schools.map((option) => {
      return { value: option.id, label: option.name };
    }) ?? [];

  return (
    <>
      <ListItem>
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel id="district-rep-status-label">Status</InputLabel>
          <Select size="small" control={control} label="Status" labelId="district-rep-status-label" name="districtRep.districtRepStatus" fullWidth>
            {districtRepStatusFilterOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItem>
      <ListItem>
        <MultiSelect size="small" control={control} label="District" name="districtRep.districtRepLocationIds" data={districtOptions} />
      </ListItem>

      <ListItem>
        <MultiSelect size="small" control={control} label="School" name="districtRep.districtRepSchoolIds" data={schoolsOptions} />
      </ListItem>
    </>
  );
};

const DistrictRepFiltersDrawer = (props: Props) => {
  const { showDrawer, setShowDrawer, userQuery, setUserQuery } = props;

  const { control, reset, handleSubmit } = useForm<Form>({
    mode: 'onSubmit',
    defaultValues: {
      accountType: userQuery.accountType || '',
      country: userQuery.country || '',
      timeZone: userQuery.timeZones || [],
      province: userQuery.provincesStates || [],
      userIsEnabled: userQuery.isEnabled?.toString() || '',
      isPasswordSet: userQuery.isPasswordSet?.toString() || '',
      districtRep: {
        searchFieldSelection: userQuery.districtRepQuery.searchFieldSelection || DistrictRepSearchFieldOptions.All,
        districtRepStatus: userQuery.districtRepQuery.districtRepStatus || '',
        districtRepLocationIds: userQuery.districtRepQuery.districtRepLocationIds || [],
        districtRepSchoolIds: userQuery.districtRepQuery.districtRepSchoolIds || [],
      },
    },
  });

  const handleClose = () => {
    setShowDrawer(false);
  };

  const handleClearFilters = () => {
    reset();
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    setUserQuery((current) => ({
      ...current,
      page: 1,
      country: data.country !== '' ? data.country : undefined,
      isEnabled: data.userIsEnabled !== '' ? data.userIsEnabled === 'true' : undefined,
      timeZones: data.timeZone?.length === 0 ? undefined : data.timeZone,
      account: data.accountType !== '' ? data.accountType : undefined,
      provincesStates: data.province?.length === 0 ? undefined : data.province,
      isPasswordSet: data.isPasswordSet !== '' ? data.isPasswordSet === 'true' : undefined,
      districtRepQuery: {
        searchFieldSelection: data.districtRep.searchFieldSelection || DistrictRepSearchFieldOptions.All,
        districtRepStatus: data.districtRep.districtRepStatus !== '' ? data.districtRep.districtRepStatus : undefined,
        districtRepLocationIds: data.districtRep.districtRepLocationIds !== '' ? data.districtRep.districtRepLocationIds : undefined,
        districtRepSchoolIds: data.districtRep.districtRepSchoolIds !== '' ? data.districtRep.districtRepSchoolIds : undefined,
      },
    }));

    handleClose();
  };

  return (
    <Drawer anchor="right" open={showDrawer} onClose={handleClose}>
      <Box sx={{ minWidth: '320px' }}>
        <Box sx={{ position: 'relative' }}>
          <Typography
            style={{
              marginLeft: '24px',
              marginTop: '105px',
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '48px',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            District Rep Filters
          </Typography>

          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '4px',
              cursor: 'pointer',
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ marginTop: '16px' }} />
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <List disablePadding sx={{ marginTop: '16px' }}>
          <ListItem>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="is-employee-enabled-label">Is Enabled</InputLabel>
              <Select control={control} name="userIsEnabled" labelId="is-employee-enabled-label" label="Is Enabled" fullWidth>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </ListItem>
          <DistrictRepFilters control={control} />
        </List>
        <Grid container p={2} sx={{ position: 'sticky', bottom: 0, zIndex: 1, background: '#fff' }}>
          <Grid item container>
            <Button type="submit" size="small" variant="contained" fullWidth>
              Apply
            </Button>
          </Grid>
          <Grid item container sx={{ marginTop: '16px' }}>
            <Button color="inherit" type="button" onClick={handleClearFilters} size="small" variant="contained" fullWidth>
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  );
};

export default DistrictRepFiltersDrawer;
