import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

const getInvoicesCSVReport = async (periodId: string) => await axios.get<any>(`${config.apiUrl}/admin/teacher-invoicing/invoices-csv/${periodId}`);

export default function useDownloadInvoicesCSVReport(periodId: string, options?: Omit<UseQueryOptions<any, AxiosError>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetInvoicesCSVReport, periodId], () => getInvoicesCSVReport(periodId), options);
}
