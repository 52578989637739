import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface EventItemButton {
  text: string;
  whiteboardId?: string;
  bookId?: string;
}

export interface LessonEventForVisualizer {
  id: string;
  title: string;
  timestamp: number;
  details: string[];
  button?: EventItemButton;
}

export interface LessonEventVisualizationResponse {
  lessonEvents: LessonEventForVisualizer[];
}

async function getLessonEventsforVisualizer(lessonId: string) {
  const { data } = await axios.get<LessonEventVisualizationResponse>(`${config.apiUrl}/admin/lesson-event-visualizer/${lessonId}`);
  return data;
}

export default function useGetLessonEventsforVisualizer(
  lessonId: string,
  options?: Omit<UseQueryOptions<LessonEventVisualizationResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetEventsforLessonVisualizer, lessonId], () => getLessonEventsforVisualizer(lessonId), options);
}
