import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { lessonLanguagesLookup, lessonSubjectLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Domain, Home, ReceiptLongOutlined } from '@mui/icons-material';
import { Button, Card, CardContent, FormHelperText, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FormProvider } from 'react-hook-form';
import { ConnectForm } from '@hoot/components/form/ConnectForm';
import { DatePicker } from '@hoot/components/form/DatePicker';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { enrolmentStatuses } from '@hoot/hooks/api/user/district-representative/useGetDistrictRepresentative';
import { LessonSetDetailsForm, useLessonSetDetailsContext } from '@hoot/pages/lesson-sets/details/context/LessonSetDetailsContext';
import { routes } from '@hoot/routes/routes';
import EnrolmentStatusIcon from '../../../../components/ui/enrolments/EnrolmentStatusIcon';
import { ScheduledLessonSetStatus, scheduledLessonSetStatusDisplay } from '../../../lessons/enums';
import NoneListItem from '../../../users/teachers/NoneListItem';

function LessonSetsDetails() {
  const { lessonSetDetails, lessonSetDetailsForm } = useLessonSetDetailsContext();

  if (!lessonSetDetails) {
    return null;
  }

  return (
    <FormProvider {...lessonSetDetailsForm}>
      <Grid container item xs={12} spacing={3}>
        <Grid item md={3} sm={12}>
          <Stack gap={3}>
            <StatusCard />
            <LessonSetDetailsCard />
            <ReportingDataCard />
          </Stack>
        </Grid>
        <Grid item md={9} sm={12}>
          <Stack gap={3}>
            <AssociatedStudentCard />
            <AssociatedEnrolmentCard />
            <DistrictSchoolCard />
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

function StatusCard() {
  const lessonSet = useLessonSetDetailsContext().lessonSetDetails!;
  const isDisabled = lessonSet.status === ScheduledLessonSetStatus.Cancelled;

  function options() {
    const today = DateTime.now().startOf('day');
    const isEndDateValid = DateTime.fromISO(lessonSet.endDate).startOf('day') >= today;

    const allowedStatuses = [lessonSet.status];

    if (isEndDateValid) {
      switch (lessonSet.status) {
        case ScheduledLessonSetStatus.Pending:
          allowedStatuses.push(ScheduledLessonSetStatus.Posted, ScheduledLessonSetStatus.Rescheduling, ScheduledLessonSetStatus.Cancelled);
          break;
        case ScheduledLessonSetStatus.Posted:
          allowedStatuses.push(ScheduledLessonSetStatus.Rescheduling, ScheduledLessonSetStatus.Cancelled);
          break;
        case ScheduledLessonSetStatus.Rescheduling:
          allowedStatuses.push(ScheduledLessonSetStatus.Posted, ScheduledLessonSetStatus.Cancelled);
          break;
        case ScheduledLessonSetStatus.Closed:
          allowedStatuses.push(ScheduledLessonSetStatus.Posted, ScheduledLessonSetStatus.Rescheduling);
          break;
        default:
          break;
      }
    }

    return scheduledLessonSetStatusDisplay.filter((s) => allowedStatuses.includes(s.value));
  }

  return (
    <ConnectForm<LessonSetDetailsForm>>
      {({ control }) => {
        return (
          <Card>
            <CardContent>
              <Typography variant="titleLarge">Status</Typography>
              <Stack sx={{ marginTop: '24px' }}>
                <Dropdown
                  name="status"
                  required
                  disabled={isDisabled}
                  label="Lesson Set Status"
                  options={options()}
                  control={control}
                  variant="outlined"
                />
                <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                  Since {DateTime.fromMillis(lessonSet.lastUpdatedAt).toFormat('MM/dd/yyyy')}
                </FormHelperText>
              </Stack>
            </CardContent>
          </Card>
        );
      }}
    </ConnectForm>
  );
}

function LessonSetDetailsCard() {
  const lessonSet = useLessonSetDetailsContext().lessonSetDetails!;
  return (
    <Card>
      <CardContent>
        <Typography variant="titleLarge">Lesson Set Details </Typography>
        <Stack sx={{ marginTop: '24px' }}>
          <Typography variant="labelLarge">Duration</Typography>
          <Typography variant="bodyMedium">{lessonSet.duration} Minutes</Typography>

          <Typography sx={{ marginTop: '16px' }} variant="labelLarge">
            Subject
          </Typography>
          <Typography variant="bodyMedium">{lessonSubjectLookup[lessonSet.subject]}</Typography>

          <Typography sx={{ marginTop: '16px' }} variant="labelLarge">
            Language
          </Typography>
          <Typography variant="bodyMedium">{lessonLanguagesLookup[lessonSet.language]}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

function ReportingDataCard() {
  return (
    <ConnectForm<LessonSetDetailsForm>>
      {({ control }) => (
        <Card>
          <CardContent>
            <Typography variant="titleLarge">Reporting Data</Typography>
            <Stack mt="24px" spacing="16px">
              <DatePicker name="startDate" label="Lesson Start Date" control={control} />
              <DatePicker name="endDate" label="Lesson End Date" control={control} />
            </Stack>
          </CardContent>
        </Card>
      )}
    </ConnectForm>
  );
}

function AssociatedStudentCard() {
  const student = useLessonSetDetailsContext().lessonSetDetails!.student;
  return (
    <Card>
      <CardContent>
        <Typography variant="titleLarge">Associated Student</Typography>
        <List dense>
          {!!student ? (
            <ListItem
              key={student.id}
              sx={{
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                borderRadius: '4px',
                padding: '8px 24px 8px 16px',
              }}
              secondaryAction={
                <Button href={routes.users.students.details.url(student.id)} variant="text" color="primary" size="large">
                  View
                </Button>
              }
            >
              <ListItemIcon>
                <ReceiptLongOutlined />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography component="div" variant="bodyLarge">
                    {student.prefixedNumber}
                  </Typography>
                }
              />
            </ListItem>
          ) : (
            <NoneListItem />
          )}
        </List>
      </CardContent>
    </Card>
  );
}

function AssociatedEnrolmentCard() {
  const enrolment = useLessonSetDetailsContext().lessonSetDetails!.enrolment;
  return (
    <Card>
      <CardContent sx={{ p: 3 }}>
        <Typography component="h5" variant="bodySmall">
          Enrolments*
        </Typography>
        <List dense>
          {!!enrolment ? (
            <ListItem
              key={enrolment.id}
              sx={{
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                borderRadius: '4px',
                padding: '8px 24px 8px 16px',
              }}
              secondaryAction={
                <Button
                  href={routes.districtsSchools.enrolments.details.url(enrolment.locationId, enrolment.id)}
                  variant="text"
                  color="primary"
                  size="large"
                >
                  View
                </Button>
              }
            >
              <ListItemIcon>
                <ReceiptLongOutlined />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography component="div" variant="bodyLarge">
                    {enrolment.friendlyId}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography variant="labelMedium" sx={{ display: 'block' }}>
                      {enrolment.locationName}
                    </Typography>
                    <Typography variant="bodySmall" sx={{ display: 'block' }}>
                      {EnrolmentStatusIcon(enrolment.status)} {enrolmentStatuses[enrolment.status]}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ) : (
            <NoneListItem />
          )}
        </List>
      </CardContent>
    </Card>
  );
}

function DistrictSchoolCard() {
  const locations = useLessonSetDetailsContext().lessonSetDetails!.locations;

  return (
    <Card>
      <CardContent>
        <Typography variant="titleLarge">District & Schools</Typography>
        <List dense>
          {!!locations?.length ? (
            locations.map((l) => (
              <ListItem
                key={l.id}
                sx={{
                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                  borderRadius: '4px',
                  padding: '8px 24px 8px 16px',
                }}
                secondaryAction={
                  <Button href={routes.districtsSchools.details.url(l.id)} variant="text" color="primary" size="large">
                    View
                  </Button>
                }
              >
                <ListItemIcon>{l.type === LocationType.District ? <Domain /> : <Home />}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography component="div" variant="bodyLarge">
                      {l.name}
                    </Typography>
                  }
                  secondary={l.friendlyId}
                />
              </ListItem>
            ))
          ) : (
            <NoneListItem />
          )}
        </List>
      </CardContent>
    </Card>
  );
}

export default LessonSetsDetails;
