import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { SortableQuery } from '@hoot/interfaces/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/interfaces/pagination';
import { FormativeAssessmentType } from '@hoot/pages/users/students/lesson-reviews/enum';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface HootFormativeAssessmentResponse {
  lessonReviewId: string;
  assessedById: string;
  assessedByPrefixedNumber: string;
  bookId: string;
  bookTitle: string;
  lastUpdatedAt: number;
  formativeAssessmentType: FormativeAssessmentType;
  score?: string;
}

export enum StudentFormativeAssessmentsQuerySortKeyEnum {
  Date = 'Date',
  AssessedBy = 'AssessedBy',
  ResourceUsed = 'ResourceUsed',
}

export type GetStudentFormativeAssessmentsQuery = PaginatableQuery & SortableQuery<StudentFormativeAssessmentsQuerySortKeyEnum>;

export function useGetFormativeAssessments(
  studentProfileId: string,
  query: GetStudentFormativeAssessmentsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<HootFormativeAssessmentResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetStudentFormativeAssessments, studentProfileId, query],
    async () => {
      return (
        await axios.get<GenericPaginatedResponse<HootFormativeAssessmentResponse>>(
          `${config.apiUrl}/admin/lesson-reviews/formative-assessment/${studentProfileId}`,
          {
            params: query,
          },
        )
      ).data;
    },
    options,
  );
}
