import { Close } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  Theme,
  Typography,
} from '@mui/material';
import { SxProps } from '@mui/system';
import React, { useEffect, useState } from 'react';

interface GenericCheckListModalProps {
  show: boolean;
  title: string;
  listItems: CheckListModalItemItem[];
  defaultCheckedItems: Set<CheckListModalItemItem['value']>;
  confirmButtonTitle?: string;
  onConfirmButtonClicked: (checkedValues: string[]) => void;
  dismissButtonTitle?: string;
  onDismiss: () => void;
  dialogContentSx?: SxProps<Theme>;
}

export interface CheckListModalItemItem {
  label: string;
  value: string;
}

const GenericCheckListModal = (props: GenericCheckListModalProps) => {
  const {
    show,
    title,
    listItems,
    defaultCheckedItems,
    confirmButtonTitle = 'Ok',
    onConfirmButtonClicked,
    dismissButtonTitle = 'Cancel',
    onDismiss,
    dialogContentSx,
  } = props;

  const [checkedItems, setCheckedItems] = useState(defaultCheckedItems);

  // When the modal is opened, then reset the default selected values.
  useEffect(() => {
    if (show) {
      setCheckedItems(defaultCheckedItems);
    }
  }, [defaultCheckedItems, show]);

  const onItemClicked =
    (listItem: CheckListModalItemItem): ListItemButtonProps['onClick'] =>
    (event) => {
      // Else this might get triggered twice when you click on the checkbox.
      event.stopPropagation();
      const newCheckedItems = new Set(checkedItems);
      if (newCheckedItems.has(listItem.value)) {
        newCheckedItems.delete(listItem.value);
      } else {
        newCheckedItems.add(listItem.value);
      }
      setCheckedItems(newCheckedItems);
    };

  const _onConfirmButtonClicked = () => {
    onConfirmButtonClicked(Array.from(checkedItems));
  };

  return (
    <>
      <Dialog open={show} onClose={onDismiss}>
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          {title}
          <IconButton onClick={onDismiss}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={dialogContentSx}>
          <List disablePadding>
            {listItems.map((x) => (
              <ListItem disablePadding key={`${title}-checklist-modal-${x.value}`}>
                <ListItemButton onClick={onItemClicked(x)}>
                  <Checkbox checked={checkedItems.has(x.value)} />
                  <Typography variant="bodyLarge">{x.label}</Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions sx={{ p: 3, position: 'relative' }}>
          <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
          <Button size="large" variant="outlined" color="primary" onClick={onDismiss}>
            {dismissButtonTitle}
          </Button>
          <Button size="large" variant="contained" onClick={_onConfirmButtonClicked}>
            {confirmButtonTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenericCheckListModal;
