import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface AccountResponse {
  id: string;
  name: string;
  active: boolean;
  additionalBackgroundCheck: boolean;
}

async function getAccounts(includeInactive: boolean) {
  const { data } = await axios.get<AccountResponse[]>(`${config.apiUrl}/admin/partner/accounts?includeInactive=${includeInactive}`);
  return data;
}

export default function useGetAccounts(includeInactive: boolean) {
  return useQuery(QueryKey.GetAccounts, () => getAccounts(includeInactive));
}
