import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { Lock } from '@mui/icons-material';
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useRef } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SearchSelect } from '@hoot/components/SearchSelect';
import CountryDropDown from '@hoot/components/form/CountryDropDown';
import { ProvinceStateSelect } from '@hoot/components/form/ProvinceStateSelect';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import useCreateDistrictSchoolRequest, { CreateBillableDistrictSchool } from '@hoot/hooks/api/hfs/useCreateDistrictSchool';
import { DistrictSchoolDetailResponse } from '@hoot/hooks/api/hfs/useGetDistrictSchool';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { routes } from '@hoot/routes/routes';
import { enumToTitleCase } from '@hoot/utils/stringUtils';
import { timeZoneData } from '@hoot/utils/timeZones';
import useUpdateDistrictSchoolRequest, { UpdateDistrictSchoolRequest } from '../../../hooks/api/hfs/useUpdateDistrictSchool';
import { getDistrictSchoolLabel } from '../LocationPage';
import DistrictRepresentativesTable from './DistrictSchoolDistrictRepresentativesTable';
import DistrictsAndSchoolsTable from './DistrictSchoolsTable';

export interface Form {
  type: LocationType;
  isBillableEntity: boolean;
  name: string;
  friendlyId: string;
  country: string;
  provinceState: string;
  city: string;
  timeZone: string;
  reporting: boolean;
}

const Details = (props: { control: Control<Form>; watch: UseFormWatch<Form>; locationType: LocationType }) => {
  const { control, watch, locationType } = props;
  const { country, isBillableEntity } = watch();
  const label = getDistrictSchoolLabel(locationType);

  const { scopes } = useAuth();
  const canViewDistrictDetails = !!scopes.find((s) => s === HootEmployeeScope.ViewDistrictDetails);

  return (
    <Card
      sx={{
        width: 1,
      }}
    >
      <CardContent>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Typography align="center" variant="titleLarge">
              {label} Details
            </Typography>
          </Grid>
          <Grid sx={{ marginTop: '24px' }} container item xs={12}>
            <FormControl disabled={true}>
              <FormLabel id="district-school-type">
                <Typography sx={{ color: '#1C1B1F' }} variant="bodySmall">
                  Type
                </Typography>
              </FormLabel>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <RadioGroup value={locationType} onChange={field.onChange} row aria-labelledby="district-school-type">
                    <FormControlLabel value={LocationType.District} control={<Radio />} label="District" />
                    <FormControlLabel
                      value={LocationType.School}
                      control={<Radio />}
                      label={isBillableEntity ? 'Standalone School (No District)' : 'School (With District)'}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item container sx={{ marginTop: '24px' }} spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="name"
                  rules={{
                    required: `${label} name is required`,
                  }}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      helperText={fieldState.error?.message}
                      error={!!fieldState.error}
                      fullWidth
                      label={`${label} Name`}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item container xs={12} md={6}>
                <Controller
                  name="friendlyId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                      }}
                      helperText={fieldState.error?.message}
                      error={!!fieldState.error}
                      fullWidth
                      label={`${label} ID`}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item container xs={12} md={locationType === LocationType.School ? 3 : 6}>
                <Controller
                  name="country"
                  control={control}
                  rules={{
                    required: `Country is required`,
                  }}
                  render={({ field, fieldState }) => (
                    <CountryDropDown value={field.value} onChange={field.onChange} includeEmpty errorMessage={fieldState.error?.message} />
                  )}
                />
              </Grid>

              <Grid item container xs={12} md={locationType === LocationType.School ? 3 : 6}>
                <Controller
                  name="provinceState"
                  control={control}
                  rules={{
                    required: `Province/State is required`,
                  }}
                  render={({ field, fieldState }) => (
                    <ProvinceStateSelect
                      onChange={field.onChange}
                      value={field.value}
                      countryCode={country}
                      disabled={!country}
                      errorMessage={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>

              {locationType === LocationType.School ? (
                <Grid item container xs={12} md={locationType === LocationType.School ? 3 : 6}>
                  <Controller
                    name="city"
                    control={control}
                    rules={{
                      required: locationType === LocationType.School ? 'A city is required' : false,
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        helperText={fieldState.error?.message}
                        error={!!fieldState.error}
                        fullWidth
                        label="City"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              ) : null}

              {locationType === LocationType.School ? (
                <Grid item container xs={12} md={locationType === LocationType.School ? 3 : 6}>
                  <SearchSelect
                    control={control}
                    label="Timezone*"
                    name="timeZone"
                    data={timeZoneData}
                    required={locationType === LocationType.School}
                    helperText={'Timezone is required'}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {isBillableEntity ? (
          <Tooltip title={!canViewDistrictDetails ? 'You do not have permission to edit this field' : ''}>
            <FormControl disabled={!canViewDistrictDetails} sx={{ paddingTop: 1 }}>
              <FormLabel id="district-school-type">
                <Typography sx={{ color: '#1C1B1F' }} variant="bodySmall">
                  Reporting
                </Typography>
              </FormLabel>
              <Controller
                name="reporting"
                control={control}
                render={({ field }) => (
                  <RadioGroup value={field.value} onChange={(event) => field.onChange(event.target.value === 'true')} row aria-labelledby="reporting">
                    <FormControlLabel value="true" control={<Radio />} label="Enabled" />
                    <FormControlLabel value="false" control={<Radio />} label="Disabled" />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Tooltip>
        ) : null}
      </CardContent>
    </Card>
  );
};

interface Props {
  isEditMode: boolean;
  locationId: string;
  locationData?: DistrictSchoolDetailResponse;
  formRef: React.RefObject<HTMLFormElement>;
  setIsFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
  locationType: LocationType;
}

const DistrictSchoolDetails = (props: Props) => {
  const { isEditMode, locationId, locationData, formRef, setIsFormDirty, locationType } = props;
  const isDistrict = locationType === LocationType.District;
  const navigate = useNavigate();
  const isNavigatingToLocation = useRef<string | undefined>();

  const { control, formState, handleSubmit, reset, watch } = useForm<Form>({
    defaultValues: {
      type: undefined,
      isBillableEntity: isEditMode ? false : true,
      name: '',
      city: '',
      country: '',
      friendlyId: '',
      provinceState: '',
      timeZone: '',
      reporting: false,
    },
  });

  const { isDirty, setIsDirty } = useBlockIfDirty();

  useEffect(() => {
    setIsDirty(formState.isDirty);
    setIsFormDirty(formState.isDirty);
  }, [formState.isDirty, setIsDirty, setIsFormDirty]);

  useEffect(() => {
    if (!isEditMode && !isDirty && isNavigatingToLocation.current) {
      const locationId = isNavigatingToLocation.current;
      navigate(routes.districtsSchools.details.url(locationId));
    }
  }, [isDirty, isEditMode, navigate]);

  useEffect(() => {
    if (locationData) {
      reset({
        type: locationData.type,
        isBillableEntity: locationData.isBillableEntity,
        name: locationData.name,
        friendlyId: locationData.friendlyId,
        city: locationData.city,
        country: locationData.country,
        provinceState: locationData.state,
        timeZone: locationData.timeZone,
        reporting: locationData.reporting,
      });
    }
  }, [locationData, reset]);

  const queryClient = useQueryClient();

  const { success, error } = useAlert();

  const createDistrictSchoolMutation = useCreateDistrictSchoolRequest();
  const updateDistrictSchoolMutation = useUpdateDistrictSchoolRequest(locationId);

  const onSubmit = (data: Form) => {
    if (isEditMode) {
      const request: UpdateDistrictSchoolRequest = {
        name: data.name,
        country: data.country,
        provinceState: data.provinceState,
        city: data.city,
        timeZone: data.timeZone,
        reporting: data.reporting,
      };
      updateDistrictSchoolMutation.mutate(request, {
        onSuccess: () => {
          success(`${data.name} successfully saved!`);
          queryClient.invalidateQueries([QueryKey.GetDistrictsSchool, locationId]);
        },
        onError: (err) => {
          error(err.message);
        },
      });
    } else {
      const request: CreateBillableDistrictSchool = {
        name: data.name,
        country: data.country,
        provinceState: data.provinceState,
        locationType: locationType,
        city: data.city,
        timeZone: data.timeZone,
        reporting: data.reporting,
      };
      createDistrictSchoolMutation.mutate(request, {
        onSuccess: (locationId) => {
          setIsDirty(false);
          success(`${enumToTitleCase(locationType)} created.`);
          isNavigatingToLocation.current = locationId;
        },
        onError: (err) => {
          error(err.message);
        },
      });
    }
  };

  return (
    <Stack direction="column">
      <Stack>
        <form id="district-school-form" onSubmit={handleSubmit(onSubmit)} autoComplete="off" ref={formRef}>
          <Details control={control} watch={watch} locationType={locationType} />
        </form>
      </Stack>
      {isDistrict ? (
        <Stack sx={{ marginTop: '24px' }}>
          <DistrictsAndSchoolsTable
            districtId={locationId}
            districtCountry={locationData?.country ?? ''}
            districtProvinceState={locationData?.state ?? ''}
            isEditMode={isEditMode}
            districtSchools={locationData?.districtSchools ?? []}
          />
        </Stack>
      ) : null}
      <Stack sx={{ marginTop: '24px' }}>
        <DistrictRepresentativesTable
          isEditMode={isEditMode}
          locationId={locationId}
          locationType={locationType}
          districtRepresentatives={locationData?.districtRepresentatives ?? []}
        />
      </Stack>
    </Stack>
  );
};

export default DistrictSchoolDetails;
