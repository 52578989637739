import { DeleteOutlined, ReceiptLong } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { SortOrder } from '@hoot/components/hfs-enums';
import { IEnrolmentCheckBoxRow } from '@hoot/components/hfs-interfaces';
import SortIcon from '@hoot/components/icons/SortIcon';
import AddEnrolmentModal from '@hoot/components/modals/AddEnrolmentModal';
import {
  EnrolmentState,
  useDistrictRepPermissionsWizardContext,
} from '@hoot/pages/users/district-reps/district-rep-permissions-wizard/DistrictRepPermissionsContextProvider';
import { StyledTableCell } from '@hoot/styles/Table';

export interface EnrolmentSelectionForm {
  enrolmentId: string;
}

const EnrolmentSelectionStep = () => {
  const {
    billableEntityState,
    schoolState,
    currentStep,
    enrolmentState,
    actions: { attachEnrolmentData, goToNextStep, goToPreviousStep, dismissPermissionsWizard },
  } = useDistrictRepPermissionsWizardContext();

  const locationIdForEnrolments = schoolState ? schoolState.id : billableEntityState!.id;

  const [selectedEnrolments, setSelectedEnrolments] = useState<EnrolmentState[]>(enrolmentState ?? []);
  const [showAddEnrolmentModal, setShowAddEnrolmentModal] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<SortOrder>(SortOrder.ASC);

  const submitEnrolmentSelectionData = () => {
    attachEnrolmentData(selectedEnrolments);
    goToNextStep();
  };

  const sortedEnrolments = useMemo(() => {
    return selectedEnrolments.sort((a, b) => {
      if (sortDirection === SortOrder.ASC) {
        return a.friendlyId.localeCompare(b.friendlyId);
      } else {
        return b.friendlyId.localeCompare(a.friendlyId);
      }
    });
  }, [sortDirection, selectedEnrolments]);

  const handleApply = (addedEnrolments: IEnrolmentCheckBoxRow[]) => {
    const enrolmentsToAdd: EnrolmentState[] = addedEnrolments.map((enrolment) => ({
      id: enrolment.id,
      friendlyId: enrolment.friendlyId,
      locationId: enrolment.locationId,
    }));
    setSelectedEnrolments((currentSelectedEnrolments) => [...currentSelectedEnrolments, ...enrolmentsToAdd]);
    setShowAddEnrolmentModal(false);
  };

  const handleSort = () => {
    setSortDirection((currentSortDirection) => (currentSortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC));
  };

  const handleRemoveEnrolment = (enrolmentIdToRemove: string) => {
    setSelectedEnrolments((currentSelectedEnrolments) => [...currentSelectedEnrolments.filter((enrolment) => enrolment.id !== enrolmentIdToRemove)]);
  };

  const renderEmptyState = (): JSX.Element => (
    <TableRow>
      <TableCell
        sx={{
          paddingLeft: 7,
        }}
      >
        <Typography variant="bodyLarge">None</Typography>
      </TableCell>
      <TableCell />
    </TableRow>
  );

  return (
    <form>
      <DialogContent sx={{ px: 0, pt: 1 }}>
        <Stack direction="row" justifyContent={'space-between'} paddingBottom={3} alignItems={'center'}>
          <Typography variant="titleMedium" sx={{ color: '#000' }}>
            Step {currentStep.id + 1} - Add Enrolment(s)
          </Typography>
          <Button size="large" variant="contained" onClick={() => setShowAddEnrolmentModal(true)}>
            + Add
          </Button>
        </Stack>
        <Table stickyHeader aria-label="enrolments table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="right" onClick={handleSort}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                    paddingLeft: 5,
                  }}
                >
                  <span>Enrolment ID</span>
                  <SortIcon />
                </Box>
              </StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {!sortedEnrolments.length && renderEmptyState()}

            {sortedEnrolments.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Stack direction={'row'} gap={2} alignItems={'center'}>
                      <ReceiptLong />
                      <Typography variant="bodyLarge">{row.friendlyId}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleRemoveEnrolment(row.id)}>
                      <DeleteOutlined color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 0, mt: 3 }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack direction="row">
            <Button onClick={() => goToPreviousStep()} color="inherit" variant="outlined" size="large">
              Back
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button onClick={dismissPermissionsWizard} color="inherit" variant="outlined" size="large">
              Cancel
            </Button>
            <Button onClick={submitEnrolmentSelectionData} variant="contained" size="large" disabled={!sortedEnrolments.length}>
              Next
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
      <AddEnrolmentModal
        open={showAddEnrolmentModal}
        setOpen={setShowAddEnrolmentModal}
        onApply={handleApply}
        existingEnrolmentIds={selectedEnrolments.map((enrolment) => enrolment.id)}
        locationId={locationIdForEnrolments}
      />
    </form>
  );
};

export default EnrolmentSelectionStep;
