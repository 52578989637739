import {
  AssessmentType,
  CancellationReason,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonType,
  SubRequired,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useAlert } from '@hoot/contexts/AlertContext';
import { LessonWithTimeInfoResponse } from '@hoot/hooks/api/lessons/useLessonsWithTimeInfoQuery';
import useMassUpdateConflicts from '@hoot/hooks/api/lessons/useMassEditConflict';
import useMassUpdateLessons, { MassEditLessonsRequest } from '@hoot/hooks/api/lessons/useMassUpdateLessons';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { DayOfWeek } from '@hoot/utils/dateTime';
import { TIME_FORMAT } from '../../common/constants';
import { TimePicker } from '../../components/form/DatePicker';
import { CancellationReasonSelect } from '../../components/form/selectFields/CancellationReasonSelect';
import { DayOfWeekSelect } from '../../components/form/selectFields/DayOfWeekSelect';
import { LessonStatusSelect } from '../../components/form/selectFields/LessonStatusSelect';
import { LessonTypeSelect } from '../../components/form/selectFields/LessonTypeSelect';
import { SubRequiredSelect } from '../../components/form/selectFields/SubRequiredSelect';
import { SubjectSelect } from '../../components/form/selectFields/SubjectSelect';

interface DialogProps {
  numberOfChanges: number;
  numberOfLessons: number;
  isOpen: boolean;
  handleSubmit: () => void;
  handleClose: () => void;
}

const ConfirmDialog = (props: DialogProps) => {
  const { numberOfChanges, numberOfLessons, isOpen, handleSubmit, handleClose } = props;

  return (
    <Dialog open={isOpen} onClose={handleClose} scroll="paper">
      <DialogTitle>
        <Typography
          sx={{
            padding: '20px',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '32px',
            textAlign: 'center',
          }}
        >
          Edit {numberOfLessons} Lesson(s)?
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '4px',
            cursor: 'pointer',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          width: 444,
          height: 40,
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '20px',
          textAlign: 'center',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        {numberOfChanges} change(s) to {numberOfLessons} lesson(s) will apply immediately.
      </DialogContent>
      <Box p={2} sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
        <Button sx={{ width: '109px' }} size="medium" color="inherit" variant="contained" type="button" onClick={handleClose}>
          Cancel
        </Button>
        <Button sx={{ width: '109px' }} size="medium" variant="contained" type="button" onClick={handleSubmit}>
          Apply
        </Button>
      </Box>
    </Dialog>
  );
};

interface Props {
  open: boolean;
  handleClose: () => void;
  request?: MassEditLessonsRequest;
  lessons: LessonWithTimeInfoResponse[];
}

interface Form {
  lessonStatus: ScheduledLessonStatus | '';
  cancellationReason: CancellationReason | '';
  startTime: number | null;
  subRequired: SubRequired | '';
  lessonType: ScheduledLessonType | '';
  assessmentType: AssessmentType | null;
  subject: ScheduledLessonSubject | '';
  comment: string | '';
  dayOfWeek: DayOfWeek | '';
}

const LessonMassEditDrawer = (props: Props) => {
  const { open, handleClose, lessons } = props;
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [error, setError] = useState<string>();

  const hasCancelledLesson = useMemo<boolean>(() => lessons.some((l) => l.status === ScheduledLessonStatus.Cancelled), [lessons]);

  const { handleSubmit, control, reset, formState, watch, setValue } = useForm<Form>({
    mode: 'onChange',

    defaultValues: {
      lessonStatus: hasCancelledLesson ? ScheduledLessonStatus.Cancelled : '',
      cancellationReason: '',
      startTime: null,
      subRequired: '',
      lessonType: '',
      assessmentType: null,
      subject: '',
      comment: '',
      dayOfWeek: '',
    },
  });

  const { isDirty } = formState;
  const { lessonStatus, lessonType } = watch();

  useEffect(() => {
    if (lessonStatus === ScheduledLessonStatus.Cancelled) {
      setValue('startTime', null);
      setValue('lessonType', '');
      setValue('subRequired', '');
      setValue('subject', '');
    }
  }, [lessonStatus, setValue]);

  useEffect(() => {
    if (lessonType === ScheduledLessonType.Assessment) {
      setValue('assessmentType', AssessmentType.Word);
    } else {
      setValue('assessmentType', null);
    }
  }, [lessonType, setValue]);

  const queryClient = useQueryClient();

  const { success } = useAlert();

  const massUpdateLessonsMutation = useMassUpdateLessons({
    onSuccess: (response) => {
      const { isSuccess, conflicts } = response;
      if (isSuccess) {
        queryClient.invalidateQueries(QueryKey.Lesson);
        queryClient.invalidateQueries(QueryKey.Lessons);
        queryClient.invalidateQueries(QueryKey.LiveLessons);
        queryClient.invalidateQueries(QueryKey.ScheduledLessons);
        queryClient.invalidateQueries(QueryKey.MonthlyScheduledLessons);
        queryClient.invalidateQueries(QueryKey.UserScheduleLessons);
        success('Successfully updated the lessons.');
        handleClose();
      } else {
        const lessonsNumbersWithIssues = lessons
          .filter((l) => conflicts.some((conflict) => conflict.lessonId === l.lessonId))
          .map((l) => l.lessonNumber)
          .join(', ');
        setError(`The following lesson(s) have a conflict: [${lessonsNumbersWithIssues}]. Please deselect and try again.`);
      }
    },
    onError: (err) => {
      console.error(err);
      const errorMessage = err.response?.data.message ?? `Sorry! An error occurred while updating lessons.`;
      setError(errorMessage);
    },
  });

  const massUpdateConflictsMutation = useMassUpdateConflicts();

  const handleCancelClick = () => {
    handleClose();
  };

  const onDialogSubmit: SubmitHandler<Form> = (data) => {
    setShowConfirmDialog(false);
    massUpdateLessonsMutation.mutate({
      lessonIds: lessons.map((l) => l.lessonId),
      status: data.lessonStatus !== '' ? data.lessonStatus : null,
      cancellationReason: data.cancellationReason !== '' ? data.cancellationReason : null,
      dayOfWeek: data.dayOfWeek !== '' ? data.dayOfWeek : null,
      lessonStartTime: !!data.startTime ? DateTime.fromMillis(data.startTime).toFormat(TIME_FORMAT) : null,
      lessonType: data.lessonType !== '' ? data.lessonType : null,
      assessmentType: data.assessmentType ?? undefined,
      subject: data.subject !== '' ? data.subject : null,
      subRequired: data.subRequired !== '' ? data.subRequired : null,
      comment: data.comment !== '' ? data.comment : null,
    });
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    setError(undefined);
    if (data.startTime || data.dayOfWeek) {
      massUpdateConflictsMutation.mutate(
        {
          lessonIds: lessons.map((l) => l.lessonId),
          lessonStartTime: !!data.startTime ? DateTime.fromMillis(data.startTime).toFormat(TIME_FORMAT) : null,
          dayOfWeek: data.dayOfWeek !== '' ? data.dayOfWeek : null,
        },
        {
          onSuccess: (response) => {
            const { isSuccess, conflicts } = response;
            if (isSuccess) {
              setShowConfirmDialog(true);
            } else {
              const lessonsNumbersWithIssues = lessons
                .filter((l) => conflicts.some((conflict) => conflict.lessonId === l.lessonId))
                .map((l) => l.lessonNumber)
                .join(', ');
              setError(`The following lesson(s) have a conflict: [${lessonsNumbersWithIssues}]. Please deselect and try again.`);
            }
          },
          onError: (err) => {
            console.error(err);
            const errorMessage = err.response?.data?.message ?? `Sorry! An error occurred while updating lessons.`;
            setError(errorMessage);
          },
        },
      );
    } else {
      setShowConfirmDialog(true);
    }
  };

  const disableFields = hasCancelledLesson || lessonStatus === ScheduledLessonStatus.Cancelled;

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Box sx={{ minWidth: '320px', maxWidth: '560px' }}>
        <Box sx={{ position: 'relative' }}>
          <Typography
            sx={{
              marginLeft: '24px',
              marginTop: '105px',
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '48px',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            {`Bulk Edit ${lessons.length} Lessons`}
          </Typography>

          <IconButton
            onClick={handleCancelClick}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '4px',
              cursor: 'pointer',
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ marginTop: '16px' }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <List disablePadding sx={{ marginTop: '16px' }}>
            <ListItem>
              <LessonStatusSelect
                name="lessonStatus"
                control={control}
                filter={[ScheduledLessonStatus.Cancelled]}
                disabled={hasCancelledLesson}
                size="small"
                includeEmpty
              />
            </ListItem>
            {lessonStatus === ScheduledLessonStatus.Cancelled ? (
              <ListItem>
                <CancellationReasonSelect name="cancellationReason" control={control} size="small" includeEmpty required />
              </ListItem>
            ) : null}
            <ListItem>
              <DayOfWeekSelect control={control} name="dayOfWeek" disabled={disableFields} size="small" includeEmpty />
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <TimePicker control={control} name="startTime" disabled={disableFields} label="Start Time" />
              </FormControl>
            </ListItem>
            <ListItem>
              <SubRequiredSelect name="subRequired" control={control} disabled={disableFields} includeEmpty size="small" />
            </ListItem>
            <ListItem>
              <LessonTypeSelect name="lessonType" control={control} disabled={disableFields} includeEmpty size="small" />
            </ListItem>
            <ListItem>
              <SubjectSelect control={control} name="subject" disabled={disableFields} includeEmpty size="small" />
            </ListItem>
            <ListItem>
              <Controller
                name="comment"
                control={control}
                render={({ field }) => <TextField {...field} fullWidth rows={5} label="Comment" multiline variant="outlined" size="small" />}
              />
            </ListItem>
          </List>
          {error ? (
            <Grid sx={{ padding: '24px 16px', color: 'red' }} container>
              <Alert severity="error">{error}</Alert>
            </Grid>
          ) : null}
          <Grid container p={2}>
            <Grid container item>
              <Button disabled={!isDirty} type="submit" size="small" variant="contained" fullWidth>
                Apply
              </Button>
            </Grid>
            <Grid container item sx={{ marginTop: '16px' }}>
              <Button color="inherit" type="button" onClick={() => reset()} size="small" variant="contained" fullWidth>
                Reset
              </Button>
            </Grid>
          </Grid>
          <ConfirmDialog
            numberOfChanges={Object.values(formState.dirtyFields).filter((df) => df === true).length}
            numberOfLessons={lessons.length}
            isOpen={showConfirmDialog}
            handleClose={() => setShowConfirmDialog(false)}
            handleSubmit={handleSubmit(onDialogSubmit)}
          />
        </form>
      </Box>
    </Drawer>
  );
};

export default LessonMassEditDrawer;
