import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { Stack } from '@mui/material';
import React, { Dispatch, LegacyRef, SetStateAction, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { StudentProfileResponse } from '@hoot/hooks/api/end-users/useGetStudentProfile';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { useUpdateStudentDetails } from '@hoot/hooks/api/user/student/useUpdateStudentProfile';
import StudentMembershipCard from '@hoot/pages/users/students/details/components/studentMembershipCard';
import StudentTypeCard from '@hoot/pages/users/students/details/components/studentTypeCard';
import { routes } from '@hoot/routes/routes';
import useCreateStudentProfile from '../../../../hooks/api/user/student/useCreateStudentProfile';
import StudentEnrolmentsCard from './components/StudentEnrolmentsCard';
import StudentLocationCard from './components/StudentLocationCard';
import StudentBillableLessonCard from './components/studentBillableLessonCard';
import StudentEducationalDetailsCard from './components/studentEducationalDetailsCard';
import StudentParentCard from './components/studentParentCard';
import StudentPersonalInfoCard from './components/studentPersonalInfoCard';
import StudentStatusCard from './components/studentStatusCard';
import { StudentDetailsForm } from './form';
import MissingAssociationModal from './modals/MissingAssociationModal';
import { studentFormToRequest } from './student';
import useStudentDetailsForm from './useStudentDetailsForm';

interface Props {
  isEditMode: boolean;
  parentAccountIdParam: string | null;
  locationIdParam: string | null;
  studentProfile?: StudentProfileResponse;
  formRef: React.MutableRefObject<HTMLFormElement | undefined>;
  setShowFunctionalityDisabledModal: Dispatch<SetStateAction<boolean>>;
  disableAndRedactFields: boolean;
}

const StudentDetailsTab = (props: Props) => {
  const { isEditMode, parentAccountIdParam, locationIdParam, studentProfile, formRef, setShowFunctionalityDisabledModal, disableAndRedactFields } =
    props;

  const { control, handleSubmit, resetForm, formState, setValue, watch, trigger, resetField } = useStudentDetailsForm(
    disableAndRedactFields,
    isEditMode,
    studentProfile,
  );
  const { isDirty, setIsDirty } = useBlockIfDirty();
  const { name } = watch();
  const { success, error } = useAlert();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const createProfile = useCreateStudentProfile();
  const updateProfile = useUpdateStudentDetails(studentProfile?.endUserId, studentProfile?.id);

  const [showParentsModal, setShowParentsModal] = useState<boolean>(false);
  const [showDistrictsModal, setShowDistrictsModal] = useState<boolean>(false);
  const [showSchoolsModal, setShowSchoolsModal] = useState<boolean>(false);
  const [showMissingAssociationModal, setShowMissingAssociationModal] = useState<boolean>(false);

  const hasSavedLocation = !!studentProfile?.hfsLocations && studentProfile.hfsLocations?.length > 0;
  const showParentCard = !isEditMode || (isEditMode && studentProfile?.parentInfo);
  const isNavigatingToStudent = useRef<string | undefined>();

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty, setIsDirty]);

  useEffect(() => {
    resetForm();
  }, [resetForm, studentProfile]);

  useEffect(() => {
    if (!isEditMode && !isDirty && isNavigatingToStudent.current) {
      const studentProfileId = isNavigatingToStudent.current;
      navigate(routes.users.students.details.url(studentProfileId));
    }
  }, [isDirty, isEditMode, navigate]);

  const onSubmit = async (formData: StudentDetailsForm) => {
    type studentDetailsType = keyof StudentDetailsForm;
    const fieldsWithNull = Object.keys(formData)
      .filter((key): key is studentDetailsType => Object.keys(formState.dirtyFields).includes(key))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: formData[key] === 'None' ? null : formData[key],
        });
      }, {});

    const studentRequestData = studentFormToRequest(fieldsWithNull, isEditMode);

    if (studentRequestData.hootNotes || studentRequestData.hootNotesExpireDate) {
      studentRequestData.hootNotes = studentRequestData.hootNotes || formData.hootNotes;
      studentRequestData.hootNotesExpireDate = studentRequestData.hootNotesExpireDate || formData.hootNotesExpireDate;
    }

    if (isEditMode) {
      updateProfile.mutate(studentRequestData, {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKey.GetStudentProfile, studentProfile?.id]);
          setIsDirty(false);
          success(`Student profile updated.`);
        },
        onError: (errorData) => {
          console.error(errorData);
          error(`There was an error, the profile has not been edited.`);
        },
      });
    } else {
      if (!studentRequestData.parentId && !studentRequestData.locationId) {
        setShowMissingAssociationModal(true);
        return;
      }
      createProfile.mutate(studentRequestData, {
        onSuccess: (studentProfileId) => {
          setIsDirty(false);
          isNavigatingToStudent.current = studentProfileId;
          success(`Student profile created.`);
        },
        onError: (errorData) => {
          console.error(errorData);
          error(`There was an error, the profile has not been created.`);
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef as LegacyRef<HTMLFormElement>} id="student-details-form">
      <Stack direction="row" spacing={3}>
        <Stack spacing={3} sx={{ width: '30%' }}>
          <StudentStatusCard
            isEditMode={isEditMode}
            studentProfile={studentProfile}
            control={control}
            formState={formState}
            setValue={setValue}
            watch={watch}
            trigger={trigger}
            resetField={resetField}
            setShowFunctionalityDisabledModal={setShowFunctionalityDisabledModal}
            disableFields={disableAndRedactFields}
          />
          {showParentCard ? (
            <StudentParentCard
              isEditMode={isEditMode}
              parentAccountIdParam={parentAccountIdParam}
              watch={watch}
              setValue={setValue}
              navigate={navigate}
              showParentsModal={showParentsModal}
              setShowParentsModal={setShowParentsModal}
            />
          ) : null}
          <StudentTypeCard watch={watch} setValue={setValue} disableFields={disableAndRedactFields} />
          <StudentMembershipCard watch={watch} setValue={setValue} disableFields={disableAndRedactFields} />
          <StudentBillableLessonCard billableLessonsCount={studentProfile?.billableLessonsCount} />
        </Stack>

        <Stack spacing={3} sx={{ width: '70%' }}>
          <StudentPersonalInfoCard
            watch={watch}
            control={control}
            formState={formState}
            studentProfile={studentProfile}
            disableFields={disableAndRedactFields}
          />
          <StudentEducationalDetailsCard control={control} watch={watch} studentProfile={studentProfile} disableFields={disableAndRedactFields} />
          <StudentLocationCard
            locationIdParam={locationIdParam}
            watch={watch}
            setValue={setValue}
            hasSavedLocation={hasSavedLocation}
            showLocationsModal={showDistrictsModal}
            setShowLocationsModal={setShowDistrictsModal}
            studentName={studentProfile?.name || name}
            viewLocationType={LocationType.District}
            disableFields={disableAndRedactFields}
          />
          <StudentLocationCard
            locationIdParam={locationIdParam}
            watch={watch}
            setValue={setValue}
            hasSavedLocation={hasSavedLocation}
            showLocationsModal={showSchoolsModal}
            setShowLocationsModal={setShowSchoolsModal}
            studentName={studentProfile?.name || name}
            viewLocationType={LocationType.School}
            disableFields={disableAndRedactFields}
          />
          <StudentEnrolmentsCard
            studentProfileId={studentProfile?.id}
            watch={watch}
            setValue={setValue}
            hasSavedLocation={hasSavedLocation}
            disableFields={disableAndRedactFields}
          />
        </Stack>
      </Stack>
      <MissingAssociationModal
        open={showMissingAssociationModal}
        setOpen={setShowMissingAssociationModal}
        setShowDistrictsModal={setShowDistrictsModal}
        setShowSchoolsModal={setShowSchoolsModal}
        setShowParentsModal={setShowParentsModal}
      />
    </form>
  );
};

export default StudentDetailsTab;
