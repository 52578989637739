import { CancellationReason, ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { ScheduledLessonHistoryAction } from '@hoot-reading/hoot-core/dist/enums/scheduled-lessons/scheduled-lesson-history-action';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Avatar, Button, Card, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CreateTaskModal from '@hoot/components/modals/CreateTaskModal';
import HeaderCard, { HeaderCardProps, HeaderTab } from '@hoot/components/ui/HeaderCard';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import DailyRecordings from '@hoot/pages/lessons/edit/DailyRecordings';
import EditLessonDetailsContext, { useEditLessonDetailsContext } from '@hoot/pages/lessons/edit/EditLessonDetailsContext';
import LessonDetails from '@hoot/pages/lessons/edit/LessonDetails';
import { routes } from '@hoot/routes/routes';
import { useGetLessonHistory } from '../../../hooks/api/lessons/useGetLesson';
import { RescheduleWizardModal } from '../reschedule-v2/RescheduleWizardModal';
import EditLessonComments from './Comments';
import EditLessonHistoryV2 from './HistoryV2';
import { LessonVisualizer } from './lesson-visualizer/LessonVisualizer';

const allowedCancellationReasonForRescheduling = [
  CancellationReason.NoSubFound,
  CancellationReason.TeacherUnavailableAboveNotice,
  CancellationReason.TeacherUnavailableBelowNotice,
  CancellationReason.TeacherNoShow,
  CancellationReason.HootClosure,
  CancellationReason.StudentUnavailableAboveNotice,
  CancellationReason.TechDisruption,
];

const ActionButton = (props: { lessonId: string; canRescheduleLesson: boolean }) => {
  const [showRescheduleWizard, setShowRescheduleWizard] = useState<boolean>(false);
  const [showCreateTaskModal, setShowCreateTaskModal] = useState<boolean>(false);
  const [actionAnchorEl, setActionAnchorEl] = useState<Element | null>(null);
  const open = Boolean(actionAnchorEl);

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setActionAnchorEl(null);
  };

  const handleRescheduleClick = () => {
    setShowRescheduleWizard(true);
  };

  const handleRescheduleWizardClose = () => {
    setShowRescheduleWizard(false);
  };

  return (
    <Stack>
      <Button
        type="button"
        color="inherit"
        variant="outlined"
        size="large"
        startIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={handleActionClick}
      >
        Action
      </Button>
      <Menu
        open={open}
        anchorEl={actionAnchorEl}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: 1 }}
      >
        <MenuItem disabled={!props.canRescheduleLesson} onClick={handleRescheduleClick}>
          Reschedule
        </MenuItem>
        <MenuItem onClick={() => setShowCreateTaskModal((currVal) => !currVal)}>Create Task</MenuItem>
      </Menu>
      {showRescheduleWizard ? <RescheduleWizardModal lessonId={props.lessonId} onClose={handleRescheduleWizardClose} /> : null}
      <CreateTaskModal open={showCreateTaskModal} handleClose={() => setShowCreateTaskModal(false)} />
    </Stack>
  );
};

export enum LessonDetailTabs {
  Details = 'DETAILS',
  History = 'HISTORY',
  Comments = 'COMMENTS',
  DailyRecording = 'DAILY_RECORDING',
  Visualizer = 'VISUALIZER',
}

interface Props {
  tab: LessonDetailTabs;
}

const Contents = (props: Props) => {
  const { tab } = props;
  usePageTitle('Lessons | Admin Portal');
  const navigate = useNavigate();
  const { setIsDirty } = useBlockIfDirty();

  const {
    lessonId,
    lessonDetails,
    lessonDetailsForm,
    actions: { saveLessonDetails },
  } = useEditLessonDetailsContext();

  const lessonHistoryRequest = useGetLessonHistory(lessonId);

  useEffect(() => {
    setIsDirty(lessonDetailsForm.formState.isDirty);
  }, [lessonDetailsForm.formState.isDirty, setIsDirty]);

  const onTabSelected: HeaderCardProps['onTabSelected'] = (selectedTab) => {
    navigate(selectedTab.route);
    return !lessonDetailsForm.formState.isDirty;
  };

  const lessonHasEnded = lessonDetails ? DateTime.fromMillis(lessonDetails.startsAt).plus({ minutes: lessonDetails.duration }) : false;
  const participantHasJoined =
    lessonHistoryRequest.data?.some((e) =>
      [ScheduledLessonHistoryAction.StudentJoinedLesson, ScheduledLessonHistoryAction.TeacherJoinedLesson].includes(e.action),
    ) ?? false;
  const showVisualiserTab = lessonHasEnded && participantHasJoined;

  const tabs = (): HeaderTab[] => {
    const commentsTab =
      lessonDetails?.lessonCommentsCount && lessonDetails.lessonCommentsCount > 0 ? (
        <Stack direction="row" alignItems="center" gap={1}>
          Comments
          <Avatar sx={{ width: 16, height: 16 }}>
            <Typography variant="labelSmall" color="#000">
              {lessonDetails?.lessonCommentsCount}
            </Typography>
          </Avatar>
        </Stack>
      ) : (
        'Comments'
      );

    const tabs = [
      {
        id: LessonDetailTabs.Details,
        label: 'Details',
        route: routes.lessons.details.url(lessonId),
      },
      {
        id: LessonDetailTabs.History,
        label: 'History',
        route: routes.lessons.history.url(lessonId),
      },
      {
        id: LessonDetailTabs.Comments,
        label: commentsTab,
        route: routes.lessons.comments.url(lessonId),
      },
      {
        id: LessonDetailTabs.DailyRecording,
        label: 'Recording',
        route: routes.lessons.dailyRecordings.url(lessonId),
      },
    ];

    if (showVisualiserTab) {
      tabs.push({
        id: LessonDetailTabs.Visualizer,
        label: 'Visualizer',
        route: routes.lessons.visualizer.url(lessonId),
      });
    }

    return tabs;
  };

  if (!lessonId || !lessonDetails) {
    return null;
  }

  const enableSave = lessonDetailsForm.formState.isValid && lessonDetailsForm.formState.isDirty;
  const canReschedule =
    lessonDetails.lessonStatus === ScheduledLessonStatus.Cancelled &&
    !!lessonDetails?.cancellationReason &&
    allowedCancellationReasonForRescheduling.includes(lessonDetails.cancellationReason) &&
    !lessonDetails.rescheduledLessonId;

  const SubHeader = () => {
    const { info } = useAlert();

    const handleCopyToClipboard = (val: string) => () => {
      navigator.clipboard.writeText(val);
      info(`Copied "${val}" to clipboard.`);
    };

    return (
      <>
        <Stack direction="row">
          <Typography component="h6" variant="bodySmall">
            Lesson Number: {lessonDetails.lessonPrefixedNumber}
          </Typography>
          <Tooltip title="Copy to clipboard">
            <IconButton onClick={handleCopyToClipboard(lessonDetails.lessonPrefixedNumber)} sx={{ width: '14px', height: '14px', ml: 1.25 }}>
              <ContentCopyIcon style={{ marginTop: 0 }} sx={{ width: '11px', height: '13px' }} />
            </IconButton>
          </Tooltip>
        </Stack>

        {lessonDetails.rescheduledLessonId && lessonDetails.rescheduledLessonNumber ? (
          <Typography component="h6" variant="bodySmall">
            Rescheduled Lesson:&nbsp;
            {
              <Link style={{ fontSize: '12px', lineHeight: '16px' }} to={routes.lessons.details.url(lessonDetails.rescheduledLessonId)}>
                {lessonDetails.rescheduledLessonPrefixedNumber}
              </Link>
            }
          </Typography>
        ) : null}
        {lessonDetails.parentLessonId && lessonDetails.parentLessonNumber ? (
          <Typography component="h6" variant="bodySmall">
            Created From Lesson:&nbsp;
            {
              <Link style={{ fontSize: '12px', lineHeight: '16px' }} to={routes.lessons.details.url(lessonDetails.parentLessonId)}>
                {lessonDetails.parentLessonPrefixedNumber}
              </Link>
            }
          </Typography>
        ) : null}
        {lessonDetails.lessonNoteId ? (
          <Typography component="h6" variant="bodySmall">
            Lesson Notes:&nbsp;
            <Link
              style={{ fontSize: '12px', lineHeight: '16px' }}
              to={routes.users.students.lessonNotes.urlWithId(lessonDetails.studentProfileId, lessonDetails.lessonNoteId)}
            >
              View
            </Link>
          </Typography>
        ) : null}
        {lessonDetails.lessonReviewId ? (
          <Typography component="h6" variant="bodySmall">
            Lesson Review:&nbsp;
            <Link
              style={{ fontSize: '12px', lineHeight: '16px' }}
              to={routes.users.students.lessonReviews.urlWithId(lessonDetails.studentProfileId, lessonDetails.lessonReviewId)}
            >
              View
            </Link>
          </Typography>
        ) : null}
      </>
    );
  };

  return (
    <>
      <HeaderCard
        title={`Lesson Details`}
        breadcrumbs={[{ label: 'Lessons', route: routes.lessons.url }]}
        renderCustomSubHeader={SubHeader}
        showNavigateBackButton
        tabs={tabs()}
        onTabSelected={onTabSelected}
        actionButtons={
          <>
            <ActionButton lessonId={lessonId} canRescheduleLesson={canReschedule} />
            <Button size="large" variant="contained" type="submit" disabled={!enableSave} onClick={saveLessonDetails}>
              Save
            </Button>
          </>
        }
      />

      {tab === LessonDetailTabs.Details ? <LessonDetails /> : null}
      {tab === LessonDetailTabs.History ? (
        <Card sx={{ mt: 3, p: 3 }}>
          <EditLessonHistoryV2 lessonId={lessonId!} />
        </Card>
      ) : null}
      {tab === LessonDetailTabs.Comments ? (
        <Card sx={{ mt: 3, p: 3 }}>
          <EditLessonComments lessonId={lessonId!} />
        </Card>
      ) : null}
      {tab === LessonDetailTabs.DailyRecording ? (
        <Card sx={{ mt: 3, p: 3 }}>
          <DailyRecordings lesson={lessonDetails} />
        </Card>
      ) : null}
      {tab === LessonDetailTabs.Visualizer ? <LessonVisualizer lessonId={lessonId!} /> : null}
    </>
  );
};

const EditLessonPage = (props: Props) => {
  return (
    <EditLessonDetailsContext>
      <Contents tab={props.tab} />
    </EditLessonDetailsContext>
  );
};

export default EditLessonPage;
