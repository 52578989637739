import { ProductProgram } from '@hoot-reading/hoot-core/dist/enums/hfs/product-program.enum';
import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { productProgram } from '@hoot/pages/district-schools/products/enum';
import FilterDropDown from '../../FilterDropDown';
import { productProgramList } from '../../selectFields/products/ProductProgramSelect';

export function ProductProgamFilter(props: {
  value: ProductProgram[];
  onChange: (val: ProductProgram[]) => void;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { value, onChange, popOverButtonSx } = props;

  return (
    <FilterDropDown<ProductProgram>
      title="Program Type"
      dismissPopoverOnSelection={true}
      value={value}
      options={productProgramList.map((s) => ({
        label: s.label,
        value: s.value,
      }))}
      onChange={(val) => onChange(val)}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveProductProgram(props: { value?: ProductProgram[]; onChange: (val: ProductProgram[]) => void }) {
  const { value = [], onChange } = props;

  const handleDelete = (val: ProductProgram) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`Product Program: ${productProgram[c]}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}
