import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Paper, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import ConfirmDeleteDialog from '@hoot/components/modals/ConfirmDeleteDialog';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import useDeleteHootAssessment from '@hoot/hooks/api/student-hoot-assessments/useDeleteHootAssessment';
import { StudentHootAssessment } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentHootAssessments';
import useReopenHootAssessment from '@hoot/hooks/api/student-hoot-assessments/useReopenHootAssessment';
import { HootAssessmentStatus } from '@hoot/interfaces/assessments-enums';
import HootReadingAssessmentDetailsPanels from './StudentAssessmentDetailsPanels';

interface Props {
  open: boolean;
  assessment: StudentHootAssessment | undefined;
  onClose: () => void;
  refetchAssessments: () => void;
}

const HootReadingAssessmentDetailsModal = (props: Props) => {
  const { assessment, open, onClose, refetchAssessments } = props;
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const { success, error } = useAlert();

  const { scopes } = useAuth();
  const hasDeleteAssessmentsPermission = !!scopes.find((s) => s === HootEmployeeScope.DeleteAssessments);

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const deleteAssessmentRequest = useDeleteHootAssessment(assessment?.id!);
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const reopenAssessmentRequest = useReopenHootAssessment(assessment?.id!);

  const abandonedBySystem = assessment?.status === HootAssessmentStatus.Abandoned && assessment?.abandonedBySystem;
  const showReopenButton = hasDeleteAssessmentsPermission && abandonedBySystem;
  const disableReopenButton = (assessment?.createdAt ?? 0) < DateTime.now().minus({ days: 60 }).startOf('day').toMillis();

  const openConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(true);
  };

  const closeConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
  };

  const onConfirmDeleteAssessment = () => {
    deleteAssessmentRequest.mutate(undefined, {
      onSuccess: () => {
        success(`Hoot Assessment Deleted.`);
        setShowConfirmDeleteModal(false);
        onClose();
        refetchAssessments();
      },
      onError: (err) => {
        console.error(err);
        error(`An error occurred while deleting the Hoot Assessment.`);
      },
    });
  };

  const onReopenAssessment = () => {
    reopenAssessmentRequest.mutate(undefined, {
      onSuccess: () => {
        success(`Hoot Assessment Reopened.`);
        onClose();
        refetchAssessments();
      },
      onError: (err) => {
        console.error(err);
        error(`An error occurred while reopening the Hoot Assessment.`);
      },
    });
  };

  const ReopenButton = () => {
    return (
      <Button type="button" color="primary" variant="contained" onClick={onReopenAssessment} disabled={disableReopenButton}>
        <Box
          sx={{
            paddingX: 2,
            paddingY: 1,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 500,
          }}
        >
          Reopen
        </Box>
      </Button>
    );
  };

  const ReopenButtonWithTooltip = () => {
    // hack to show tooltip on disabled button
    return disableReopenButton ? (
      <Tooltip title={'This assessment was created more than 60 days ago and can no longer be reopened.'}>
        <span>
          <ReopenButton />
        </span>
      </Tooltip>
    ) : (
      <ReopenButton />
    );
  };

  const CloseButton = () => {
    return (
      <Button type="button" color="inherit" variant="outlined" onClick={onClose}>
        <Box
          sx={{
            paddingX: 2,
            paddingY: 1,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 500,
          }}
        >
          Close
        </Box>
      </Button>
    );
  };

  const DeleteButton = () => {
    return (
      <Button type="button" variant="contained" color="error" onClick={openConfirmDeleteModal}>
        <Box
          sx={{
            paddingX: 2,
            paddingY: 1,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 500,
          }}
        >
          Delete
        </Box>
      </Button>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperComponent={(props) => <Paper {...props} style={{ borderRadius: '20px', maxHeight: '90vh', maxWidth: '95vw' }} />}
      >
        <DialogTitle
          id="dialog-title"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box component="span" sx={{ fontSize: '24px', lineHeight: '32px' }}>
            Reading Assessment Details
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '24px', paddingBottom: '80px' }}>
          <Box sx={{ backgroundColor: '#EFF7F8' }}>
            <HootReadingAssessmentDetailsPanels assessment={assessment} />
          </Box>
        </DialogContent>
        <Divider />
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '16px 24px',
            boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
            gap: 2,
          }}
        >
          {showReopenButton && <ReopenButtonWithTooltip />}
          {hasDeleteAssessmentsPermission && <DeleteButton />}
          <CloseButton />
        </Box>
      </Dialog>
      <ConfirmDeleteDialog
        open={showConfirmDeleteModal}
        onCancel={closeConfirmDeleteModal}
        onContinue={onConfirmDeleteAssessment}
        title="Delete Assessment"
        bodyText="This will permanently delete this assessment. Are you sure you want to continue?"
      />
    </>
  );
};

export default HootReadingAssessmentDetailsModal;
