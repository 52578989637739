import {
  AssessmentType,
  CancellationReason,
  ScheduledLessonLanguage,
  ScheduledLessonProgram,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonTheme,
  ScheduledLessonType,
  SubRequired,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import {
  ScheduledLessonHistoryAction,
  ScheduledLessonHistoryProperty,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lessons/scheduled-lesson-history-action';
import { TeacherBlockStatus } from '@hoot-reading/hoot-core/dist/enums/teacher-block';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { UserProfileType } from '@hoot/interfaces/user';
import { ScheduledLessonSetStatus } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';

export interface LessonCommentResponse {
  id: string;
  createdTime: number;
  user: string;
  comment: string;
}

export interface LessonHistoryResponse {
  id: string;
  date: number;
  user: string;
  action: ScheduledLessonHistoryAction;
  profileId: string | null;
  profileType: UserProfileType | null;
  profileName: string | null;
  note: string;
  properties: ScheduledLessonHistoryProperty;
}

export interface LessonDetailResponse {
  lessonId: string;
  lessonNumber: number;
  lessonPrefixedNumber: string;
  studentProfileId: string;
  studentName: string;
  studentNumber: number;
  studentPrefixedNumber: string;
  parentProfileId?: string;
  parentPrefixedNumber?: string;
  parentName: string;
  parentEmail: string;
  parentTimezone: string;
  teacherAccountId: string | null;
  teacherName: string | null;
  teacherNumber: number | null;
  teacherPrefixedNumber: string | null;
  teacherEmail: string | null;
  teacherTimeZone: string;
  lessonType: ScheduledLessonType;
  theme: ScheduledLessonTheme;
  assessmentType?: AssessmentType;
  program: ScheduledLessonProgram | null;
  partner: string;
  site: string;
  account: string;
  lessonStatus: ScheduledLessonStatus;
  cancellationReason?: CancellationReason | null;
  lessonDate: string;
  startsAt: number;
  duration: number;
  subject: ScheduledLessonSubject;
  language: ScheduledLessonLanguage;
  isCustomerBillable: boolean | null;
  isRecurring: boolean;
  parentLessonId?: string;
  parentLessonNumber?: number;
  parentLessonPrefixedNumber?: string;
  rescheduledLessonNumber?: number;
  rescheduledLessonPrefixedNumber?: string;
  rescheduledLessonId?: string;
  subRequired: SubRequired;
  enrolment: EnrolmentSummary | null;
  lessonSet: LessonSetSummary | null;
  lessonNoteId: string | null;
  lessonReviewId: string | null;
  dailyRoomName: string | null;
  lessonCommentsCount: number;
  teacherBlock: TeacherBlockSummary | null;
}

interface LessonSetSummary {
  id: string;
  lessonSetNumber: number;
  lessonSetPrefixedNumber: string;
  status: ScheduledLessonSetStatus;
}

interface EnrolmentSummary {
  id: string;
  locationId: string;
  friendlyId: string;
  startDate: number;
  endDate: number;
  billableEntityId: string;
  billableEntityName: string;
  productId: string;
}

interface TeacherBlockSummary {
  id: string;
  prefixedNumber: string;
  status: TeacherBlockStatus;
}

export interface CreateLessonCommentRequest {
  comment: string;
}

async function getLesson(lessonId: string) {
  const { data } = await axios.get<LessonDetailResponse>(`${config.apiUrl}/admin/scheduler/lesson/${lessonId}`);
  return data;
}

async function getLessonHistory(lessonId: string, allEvents?: boolean) {
  const { data } = await axios.get<LessonHistoryResponse[]>(`${config.apiUrl}/admin/scheduler/lesson/${lessonId}/history`, {
    params: { allEvents: allEvents },
  });
  return data;
}

async function getLessonComments(lessonId: string) {
  const { data } = await axios.get<LessonCommentResponse[]>(`${config.apiUrl}/admin/scheduler/lesson/${lessonId}/comment`);
  return data;
}

async function createLessonComment(lessonId: string, request: CreateLessonCommentRequest) {
  await axios.post(`${config.apiUrl}/admin/scheduler/lesson/${lessonId}/comment`, request);
}

export function useGetLesson(lessonId: string, options?: Omit<UseQueryOptions<LessonDetailResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.Lesson, lessonId], () => getLesson(lessonId), options);
}

export function useGetLessonHistory(
  lessonId: string,
  allEvents?: boolean,
  options?: Omit<UseQueryOptions<LessonHistoryResponse[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.LessonHistory, lessonId, allEvents], () => getLessonHistory(lessonId, allEvents), options);
}

export function useGetLessonComments(lessonId: string, options?: Omit<UseQueryOptions<LessonCommentResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.LessonComments, lessonId], () => getLessonComments(lessonId), options);
}

export function useCreateLessonComment(
  lessonId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, CreateLessonCommentRequest>, 'queryKey' | 'queryFn'>,
) {
  return useMutation((comment) => createLessonComment(lessonId, comment), options);
}
