import { Breadcrumbs, Card, CardContent, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { createBlockWizardSteps, useCreateBlockContext } from './context/CreateBlockContextProvider';

const CreateBlockWizard = () => {
  const { currentStep } = useCreateBlockContext();

  return (
    <>
      <Card>
        <CardContent>
          <Breadcrumbs>
            <Typography color="text.primary" variant="bodySmall">
              Blocks
            </Typography>
            <Typography color="text.primary" variant="bodySmall">
              Create Block
            </Typography>
          </Breadcrumbs>
          <Typography variant="headlineLarge">Create Block Wizard</Typography>
          <Stepper activeStep={currentStep.id - 1} alternativeLabel sx={{ marginTop: 10, marginBottom: 5 }}>
            {createBlockWizardSteps.map((step) => (
              <Step key={step.id}>
                <StepLabel>{step.stepperLabel}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Stack>
            <Typography variant="titleLarge" sx={{ marginBottom: 3 }}>
              Step {currentStep.id} - {currentStep.contentLabel ?? currentStep.stepperLabel}
            </Typography>
            {currentStep.content()}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default CreateBlockWizard;
