import phone from 'phone';

export function sanitizeInputPhoneNumber(phoneNumber: string) {
  // Hack to exclude phone number from the payload if the phone number is only a plus sign which the
  // phone number field doesn't let us remove.
  if (phoneNumber?.toString().length === 0 || phoneNumber?.toString() === '+') {
    return undefined;
  } else {
    // convert phone number to E.164 Standard for API
    return phone(phoneNumber).phoneNumber;
  }
}
