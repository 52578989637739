import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface ContentTagResponse {
  id: string;
  name: string;
}

async function getContentTags(): Promise<ContentTagResponse[]> {
  const { data } = await axios.get<ContentTagResponse[]>(`${config.apiUrl}/admin/library/content-tags`);
  return data;
}

export default function useGetContentTags(options?: Omit<UseQueryOptions<ContentTagResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetContentTags], () => getContentTags(), options);
}
