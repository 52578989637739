import {
  ScheduledLessonLanguage,
  ScheduledLessonSubject,
  ScheduledLessonTheme,
  ScheduledLessonType,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ConflictsResponse } from '@hoot/hooks/api/lessons/useCreateLesson';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';

export interface CreateLessonRequestV2 {
  studentProfileId: string;
  enrolmentId?: string;
  isLessonSet?: boolean;
  lessonType: ScheduledLessonType;
  subject: ScheduledLessonSubject;
  language: ScheduledLessonLanguage;
  theme?: ScheduledLessonTheme;
  isCustomerBillable: boolean;
  teacherAccountId: string | undefined;
  startsAt: number;
  endsAt?: number;
  durationInMinutes: number;
  isRecurring: boolean;
  lessonDatesWithTeacher: number[];
  lessonDatesWithoutTeacher: number[];
}

export enum CreateLessonRequestV2ErrorCode {
  LessonConflicts = 'LessonConflicts',
}

export interface CreateLessonRequestV2ErrorResponse {
  conflicts: ConflictsResponse[];
}

export interface CreateLessonResponseV2 {
  firstLessonId?: string;
  lessonSetId?: string;
}

export default function useCreateLessonsV2(
  options?: Omit<
    UseMutationOptions<
      CreateLessonResponseV2,
      AxiosError<ErrorResponseDto<CreateLessonRequestV2ErrorCode, CreateLessonRequestV2ErrorResponse>>,
      CreateLessonRequestV2
    >,
    'mutationFn' | 'mutationKey'
  >,
) {
  return useMutation(async (request) => {
    const { data } = await axios.post<CreateLessonResponseV2>(`${config.apiUrl}/admin/scheduler/v2`, request);
    return data;
  }, options);
}
