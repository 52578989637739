import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { FieldValues, Path, UseControllerProps, useController } from 'react-hook-form';
import { AdapterProps } from './common';

export type TextFieldProps<T extends FieldValues, TObj extends Path<T>> = MuiTextFieldProps & UseControllerProps<T, TObj> & AdapterProps;

export function TextField<T extends FieldValues, TObj extends Path<T>>(props: TextFieldProps<T, TObj>) {
  const { field, fieldState } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });

  return (
    <MuiTextField
      {...field}
      {...props}
      value={field.value ?? ''}
      error={!!fieldState.error}
      helperText={fieldState.error?.message ?? props.helperText}
    />
  );
}
