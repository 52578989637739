import { Close } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

interface IConfirmSubmitDialogProps {
  open: boolean;
  onCancel: () => void;
  onContinue: () => void;
  title: string;
  bodyText: string;
  submitButtonText?: string;
  cancelButtonText?: string;
  isLoading?: boolean;
}

const ConfirmSubmitDialog = (props: IConfirmSubmitDialogProps) => {
  const { open, title, bodyText, onCancel, onContinue, submitButtonText, cancelButtonText, isLoading } = props;

  return (
    <Dialog open={open} onClose={onCancel} scroll="paper">
      <Stack sx={{ padding: '24px', position: 'relative', minWidth: 500 }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">{title}</Typography>
            <IconButton onClick={onCancel} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText textAlign="left" sx={{ color: '#1C1B1F' }}>
            {bodyText}
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button
              sx={{
                width: '100px',
              }}
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              onClick={onCancel}
            >
              {cancelButtonText ?? 'Cancel'}
            </Button>
            <Button
              sx={{
                width: '175px',
              }}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              onClick={onContinue}
            >
              {isLoading ? <CircularProgress size={22} /> : submitButtonText ?? 'Submit'}
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ConfirmSubmitDialog;
