import { DateTime } from 'luxon';
import { TIME_FORMAT, WINNIPEG_TIMEZONE } from '@hoot/common/constants';

const DATE_FORMAT_ISO_8601 = 'yyyy-MM-dd';

const DATE_FORMAT_MMDDYYYY = 'MM/dd/yyyy';

/**
 * These values align with luxon DateTime 'weekday' getter
 * https://moment.github.io/luxon/api-docs/index.html#datetimeweekday
 *
 * As well as postgres EXTRACT(ISODOW FROM TIMESTAMP ...)
 * https://www.postgresql.org/docs/current/functions-datetime.html#FUNCTIONS-DATETIME-EXTRACT
 */
export enum DayOfWeek {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export const daysOfWeekList: { value: DayOfWeek; label: string }[] = [
  { value: DayOfWeek.Sunday, label: 'Sunday' },
  { value: DayOfWeek.Monday, label: 'Monday' },
  { value: DayOfWeek.Tuesday, label: 'Tuesday' },
  { value: DayOfWeek.Wednesday, label: 'Wednesday' },
  { value: DayOfWeek.Thursday, label: 'Thursday' },
  { value: DayOfWeek.Friday, label: 'Friday' },
  { value: DayOfWeek.Saturday, label: 'Saturday' },
];

export const daysOfWeekType = daysOfWeekList.reduce(
  (a, b) => ({
    ...a,
    [b.value.toString()]: b.label,
  }),
  {},
) as { [key in DayOfWeek as string]: number };

/**
 * This is only used for student schedule preferences, but keeping it here as it must
 * align with the above DayOfWeek enum.
 */
export enum DayOfWeekWithOther {
  Other = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export const daysOfWeekWithOtherList: { value: DayOfWeekWithOther; label: string }[] = [
  { value: DayOfWeekWithOther.Monday, label: 'Monday' },
  { value: DayOfWeekWithOther.Tuesday, label: 'Tuesday' },
  { value: DayOfWeekWithOther.Wednesday, label: 'Wednesday' },
  { value: DayOfWeekWithOther.Thursday, label: 'Thursday' },
  { value: DayOfWeekWithOther.Friday, label: 'Friday' },
  { value: DayOfWeekWithOther.Saturday, label: 'Saturday' },
  { value: DayOfWeekWithOther.Sunday, label: 'Sunday' },
  { value: DayOfWeekWithOther.Other, label: 'Other' },
];

export const daysOfWeekWithOtherType = daysOfWeekWithOtherList.reduce(
  (a, b) => ({
    ...a,
    [b.value.toString()]: b.label,
  }),
  {},
) as { [key in DayOfWeekWithOther as string]: number };

export function dateFromString(dateString: string): DateTime {
  return DateTime.fromFormat(dateString, DATE_FORMAT_ISO_8601);
}

export function timeFromUnixMillis(unixTime: number): string {
  return DateTime.fromMillis(unixTime).setZone(WINNIPEG_TIMEZONE).toFormat(TIME_FORMAT);
}

export function millisToDateDisplay(unixTime: number): string {
  return DateTime.fromMillis(unixTime).setZone(WINNIPEG_TIMEZONE).toFormat(DATE_FORMAT_MMDDYYYY);
}

export function convert24HourClockTo12(value: string): string {
  return DateTime.fromFormat(`1970-01-01 ${value}`, 'yyyy-LL-dd HH:mm').toFormat('h:mm a');
}

export function convert12HourClockTo24(value: string): string {
  return DateTime.fromFormat(`1970-01-01 ${value}`, 'yyyy-LL-dd h:mm a').toLocaleString(DateTime.TIME_24_SIMPLE);
}

export function combineDateWithTime(date: DateTime, time: DateTime): DateTime {
  return DateTime.fromObject({
    year: date.year,
    month: date.month,
    day: date.day,
    hour: time.hour,
    minute: time.minute,
    second: time.second,
    millisecond: time.millisecond,
  });
}

export interface ChunkedTimeSlot {
  startsAt: DateTime;
  endsAt: DateTime;
}

/**
 *
 * @param startsAt - start of your timespan
 * @param endsAt - end of your timespan
 * @returns An array of DateTime objects split up into each day spanning to start and end
 */
export function chunkTimeSpan(startsAt: DateTime, endsAt: DateTime): ChunkedTimeSlot[] {
  const results: ChunkedTimeSlot[] = [];
  let currentDate = startsAt;

  while (currentDate < endsAt) {
    const endOfDay = currentDate.endOf('day');
    const timeSlotEndsAt = endOfDay < endsAt ? endOfDay : endsAt;
    results.push({ startsAt: currentDate, endsAt: timeSlotEndsAt });
    currentDate = currentDate.plus({ day: 1 }).startOf('day');
  }

  return results;
}
