import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { BookType } from '@hoot/hooks/api/library/enums';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseGetOptions } from '@hoot/interfaces/axios-options';

export interface LessonWhiteboardResponse {
  whiteboardId: string;
  lessonId: string;
  whiteboardTemplate: WhiteboardTemplateResponse;
}

export interface WhiteboardTemplateResponse {
  templateType: WhiteboardTemplateTypeEnum;
  name: string;
  description: string;
  imageUrl: string;
  instructionalFocuses: WhiteboardTemplateInstructionalFocus[];
  instructionalUnits: WhiteboardTemplateInstructionalUnit[];
}

export interface WhiteboardTemplateInstructionalFocus {
  id: string;
  bookResourceType: BookType;
  name: string;
}

export interface WhiteboardTemplateInstructionalUnit {
  id: string;
  name: string;
}

export enum WhiteboardTemplateTypeEnum {
  ShortVowelsCVC = 'SHORT_VOWELS_CVC',
  Digraphs = 'DIGRAPHS',
  BlendsLongVowels = 'BLENDS_LONG_VOWELS',
  BasicRControlledVowels = 'BASIC_R_CONTROLLED_VOWELS',
  BasicVowelTeams = 'BASIC_VOWEL_TEAMS',
  SpellingWordFourLetters = 'SPELLING_WORDS_4_LETTERS',
  SpellingWordFiveLetters = 'SPELLING_WORDS_5_LETTERS',
  SpellingWordSixLetters = 'SPELLING_WORDS_6_LETTERS',
  VennDiagram = 'VENN_DIAGRAM',
  BasicSituationModel = 'BASIC_SITUATION_MODEL',
}

export default function useGetLessonWhiteboards(lessonId: string, options?: UseGetOptions<LessonWhiteboardResponse[]>) {
  return useQuery(
    [QueryKey.GetLessonWhiteboards, lessonId],
    async () => {
      return (await axios.get<LessonWhiteboardResponse[]>(`${config.apiUrl}/admin/whiteboard/lesson/${lessonId}`)).data;
    },
    options,
  );
}
