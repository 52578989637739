//==== Lesson Enums ====
import { CancellationReason, DeprecatedScheduledLessonType, SubRequired } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { StudentGoal } from '@hoot-reading/hoot-core/dist/enums/user/student/student-goal.enum';
import { FormOption } from '@hoot/interfaces/form';

export function isDeprecatedScheduledLessonType(value: string): value is DeprecatedScheduledLessonType {
  return Object.values(DeprecatedScheduledLessonType).includes(value as DeprecatedScheduledLessonType);
}

export const shorthandCancellationReasonsList: {
  value: CancellationReason;
  label: string;
}[] = [
  {
    value: CancellationReason.TeacherUnavailableAboveNotice,
    label: 'TU - Change req',
  },
  {
    value: CancellationReason.TeacherUnavailableBelowNotice,
    label: 'TU - Late notice',
  },
  {
    value: CancellationReason.StudentUnavailableAboveNotice,
    label: 'SU - Unavailable',
  },
  {
    value: CancellationReason.StudentUnavailableBelowNotice,
    label: 'SU - Late notice',
  },
  { value: CancellationReason.TeacherNoShow, label: 'No Show' },
  { value: CancellationReason.StudentAbsence, label: 'Absence' },
  { value: CancellationReason.NoOneJoined, label: 'No One Joined' },
  { value: CancellationReason.HootClosure, label: 'Hoot Closure' },
  { value: CancellationReason.MembershipLapse, label: 'Lapsed' },
  { value: CancellationReason.SiteClosure, label: 'Site Closure' },
  { value: CancellationReason.NoSubFound, label: 'No Sub Found' },
  { value: CancellationReason.UnsuccessfulPayment, label: 'Unsuccessful Payment' },
  { value: CancellationReason.Other, label: 'Other' },
  { value: CancellationReason.ParentChangeRequest, label: 'Change Request' },
];

export const shortHandCancellationReasons = shorthandCancellationReasonsList.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in CancellationReason]: string };

export const subRequiredList: {
  value: SubRequired;
  label: string;
}[] = [
  { value: SubRequired.Yes, label: 'Yes' },
  { value: SubRequired.No, label: 'No' },
  { value: SubRequired.Updated, label: 'Updated' },
];

export enum TeacherAssigned {
  Yes = 'YES',
  No = 'NO',
}

export const teacherAssignedList: {
  value: TeacherAssigned;
  label: string;
}[] = [
  { value: TeacherAssigned.Yes, label: 'Yes' },
  { value: TeacherAssigned.No, label: 'No' },
];

export const subRequired = subRequiredList.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in SubRequired]: string };

export type LessonDuration = 5 | 10 | 15 | 20 | 25 | 30 | 35 | 40 | 45 | 50 | 55 | 60;

export const lessonDurationsList: FormOption<LessonDuration>[] = [
  { value: 5, label: '5 minutes' },
  { value: 10, label: '10 minutes' },
  { value: 15, label: '15 minutes' },
  { value: 20, label: '20 minutes' },
  { value: 25, label: '25 minutes' },
  { value: 30, label: '30 minutes' },
  { value: 35, label: '35 minutes' },
  { value: 40, label: '40 minutes' },
  { value: 45, label: '45 minutes' },
  { value: 50, label: '50 minutes' },
  { value: 55, label: '55 minutes' },
  { value: 60, label: '60 minutes' },
];

export const lessonDurations = lessonDurationsList.reduce(
  (a, b) => ({
    ...a,
    [b.value.toString()]: b.label,
  }),
  {},
) as { [key in LessonDuration as string]: string };

export const timeOptions = [
  '12:00 AM',
  '12:30 AM',
  '1:00 AM',
  '1:30 AM',
  '2:00 AM',
  '2:30 AM',
  '3:00 AM',
  '3:30 AM',
  '4:00 AM',
  '4:30 AM',
  '5:00 AM',
  '5:30 AM',
  '6:00 AM',
  '6:30 AM',
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
  '9:00 PM',
  '9:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
];

//==== Lesson Note Enums ====

export enum SightWordList {
  AssessmentSightWordsListA = 'ASSESSMENT_SIGHT_WORDS_LIST_A',
  AssessmentSightWordsListB = 'ASSESSMENT_SIGHT_WORDS_LIST_B',
  AssessmentSightWordsListC = 'ASSESSMENT_SIGHT_WORDS_LIST_C',
  AssessmentSightWordsListD = 'ASSESSMENT_SIGHT_WORDS_LIST_D',
  AssessmentSightWordsListE = 'ASSESSMENT_SIGHT_WORDS_LIST_E',
  AssessmentSightWordsListF = 'ASSESSMENT_SIGHT_WORDS_LIST_F',
  AssessmentSightWordsListG = 'ASSESSMENT_SIGHT_WORDS_LIST_G',
  DolchSightWordsList1Point1 = 'DOLCH_SIGHT_WORDS_LIST_1_1',
  DolchSightWordsList1Point2 = 'DOLCH_SIGHT_WORDS_LIST_1_2',
  DolchSightWordsList2Point1 = 'DOLCH_SIGHT_WORDS_LIST_2_1',
  DolchSightWordsList2Point2 = 'DOLCH_SIGHT_WORDS_LIST_2_2',
  DolchSightWordsList3Point1 = 'DOLCH_SIGHT_WORDS_LIST_3_1',
  DolchSightWordsList3Point2 = 'DOLCH_SIGHT_WORDS_LIST_3_2',
  DolchSightWordsList4Point1 = 'DOLCH_SIGHT_WORDS_LIST_4_1',
  DolchSightWordsList4Point2 = 'DOLCH_SIGHT_WORDS_LIST_4_2',
  DolchSightWordsList5Point1 = 'DOLCH_SIGHT_WORDS_LIST_5_1',
  DolchSightWordsList5Point2 = 'DOLCH_SIGHT_WORDS_LIST_5_2',
  LesMotsFrequents1Point1 = 'LES_MOTS_FREQUENTS_1_1',
  LesMotsFrequents1Point2 = 'LES_MOTS_FREQUENTS_1_2',
  LesMotsFrequents2Point1 = 'LES_MOTS_FREQUENTS_2_1',
  LesMotsFrequents2Point2 = 'LES_MOTS_FREQUENTS_2_2',
  LesMotsFrequents3Point1 = 'LES_MOTS_FREQUENTS_3_1',
  LesMotsFrequents3Point2 = 'LES_MOTS_FREQUENTS_3_2',
  LesMotsFrequents4Point1 = 'LES_MOTS_FREQUENTS_4_1',
  LesMotsFrequents4Point2 = 'LES_MOTS_FREQUENTS_4_2',
  LesMotsFrequents5Point1 = 'LES_MOTS_FREQUENTS_5_1',
  LesMotsFrequents5Point2 = 'LES_MOTS_FREQUENTS_5_2',
}

export const englishSightWordListList: FormOption<SightWordList>[] = [
  {
    value: SightWordList.AssessmentSightWordsListA,
    label: 'Assessment - Sight Words List A',
  },
  {
    value: SightWordList.AssessmentSightWordsListB,
    label: 'Assessment - Sight Words List B',
  },
  {
    value: SightWordList.AssessmentSightWordsListC,
    label: 'Assessment - Sight Words List C',
  },
  {
    value: SightWordList.AssessmentSightWordsListD,
    label: 'Assessment - Sight Words List D',
  },
  {
    value: SightWordList.AssessmentSightWordsListE,
    label: 'Assessment - Sight Words List E',
  },
  {
    value: SightWordList.AssessmentSightWordsListF,
    label: 'Assessment - Sight Words List F',
  },
  {
    value: SightWordList.AssessmentSightWordsListG,
    label: 'Assessment - Sight Words List G',
  },
  {
    value: SightWordList.DolchSightWordsList1Point1,
    label: 'Dolch Sight Words 1.1',
  },
  {
    value: SightWordList.DolchSightWordsList1Point2,
    label: 'Dolch Sight Words 1.2',
  },
  {
    value: SightWordList.DolchSightWordsList2Point1,
    label: 'Dolch Sight Words 2.1',
  },
  {
    value: SightWordList.DolchSightWordsList2Point2,
    label: 'Dolch Sight Words 2.2',
  },
  {
    value: SightWordList.DolchSightWordsList3Point1,
    label: 'Dolch Sight Words 3.1',
  },
  {
    value: SightWordList.DolchSightWordsList3Point2,
    label: 'Dolch Sight Words 3.2',
  },
  {
    value: SightWordList.DolchSightWordsList4Point1,
    label: 'Dolch Sight Words 4.1',
  },
  {
    value: SightWordList.DolchSightWordsList4Point2,
    label: 'Dolch Sight Words 4.2',
  },
  {
    value: SightWordList.DolchSightWordsList5Point1,
    label: 'Dolch Sight Words 5.1',
  },
  {
    value: SightWordList.DolchSightWordsList5Point2,
    label: 'Dolch Sight Words 5.2',
  },
];

export const englishSightWordLists = englishSightWordListList.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in SightWordList | '']: string };

export const frenchSightWordListList: FormOption<SightWordList>[] = [
  {
    value: SightWordList.LesMotsFrequents1Point1,
    label: 'Les Mots Fréquents 1.1',
  },
  {
    value: SightWordList.LesMotsFrequents1Point2,
    label: 'Les Mots Fréquents 1.2',
  },
  {
    value: SightWordList.LesMotsFrequents2Point1,
    label: 'Les Mots Fréquents 2.1',
  },
  {
    value: SightWordList.LesMotsFrequents2Point2,
    label: 'Les Mots Fréquents 2.2',
  },
  {
    value: SightWordList.LesMotsFrequents3Point1,
    label: 'Les Mots Fréquents 3.1',
  },
  {
    value: SightWordList.LesMotsFrequents3Point2,
    label: 'Les Mots Fréquents 3.2',
  },
  {
    value: SightWordList.LesMotsFrequents4Point1,
    label: 'Les Mots Fréquents 4.1',
  },
  {
    value: SightWordList.LesMotsFrequents4Point2,
    label: 'Les Mots Fréquents 4.2',
  },
  {
    value: SightWordList.LesMotsFrequents5Point1,
    label: 'Les Mots Fréquents 5.1',
  },
  {
    value: SightWordList.LesMotsFrequents5Point2,
    label: 'Les Mots Fréquents 5.2',
  },
];

export const frenchSightWordLists = frenchSightWordListList.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in SightWordList | '']: string };

export const readingStrengthsGoalsList: { value: StudentGoal; label: string }[] = [
  { value: StudentGoal.PHONICS, label: 'Phonics' },
  { value: StudentGoal.DECODING, label: 'Decoding' },
  { value: StudentGoal.EXPRESSION, label: 'Expression' },
  { value: StudentGoal.USING_PUNCTUATION, label: 'Using Punctuation' },
  { value: StudentGoal.FLUENCY, label: 'Fluency' },
  { value: StudentGoal.COMPREHENSION_SUMMARIZING, label: 'Comprehension Summarizing' },
  { value: StudentGoal.COMPREHENSION_PREDICTING, label: 'Comprehension Predicting' },
  { value: StudentGoal.COMPREHENSION_MAKING_INFERENCES, label: 'Comprehension Making Inferences' },
  { value: StudentGoal.VOCABULARY, label: 'Vocabulary' },
  { value: StudentGoal.OTHER, label: 'Other' },
];

export const readingStrengthsGoals = readingStrengthsGoalsList.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in StudentGoal]: string };

export enum MathStrengthsGoals {
  Subitization = 'SUBITIZATION',
  Counting = 'COUNTING',
  OralCounting = 'ORAL_COUNTING',
  NumberRecognition = 'NUMBER_RECOGNITION',
  Sequencing = 'SEQUENCING',
  Money = 'MONEY',
  Addition = 'ADDITION',
  Subtraction = 'SUBTRACTION',
  Multiplication = 'MULTIPLICATION',
  Division = 'DIVISION',
  Fractions = 'FRACTIONS',
  Time = 'TIME',
  Other = 'OTHER',
}

export const mathStrengthsGoalsList: { value: MathStrengthsGoals; label: string }[] = [
  { value: MathStrengthsGoals.Subitization, label: 'Subitization' },
  { value: MathStrengthsGoals.Counting, label: 'Counting' },
  { value: MathStrengthsGoals.OralCounting, label: 'Oral Counting' },
  { value: MathStrengthsGoals.NumberRecognition, label: 'Number Recognition' },
  { value: MathStrengthsGoals.Money, label: 'Money' },
  { value: MathStrengthsGoals.Addition, label: 'Addition' },
  { value: MathStrengthsGoals.Subtraction, label: 'Subtraction' },
  { value: MathStrengthsGoals.Multiplication, label: 'Multiplication' },
  { value: MathStrengthsGoals.Division, label: 'Division' },
  { value: MathStrengthsGoals.Fractions, label: 'Fractions' },
  { value: MathStrengthsGoals.Time, label: 'Time' },
  { value: MathStrengthsGoals.Other, label: 'Other' },
];

export const mathStrengthsGoals = mathStrengthsGoalsList.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in MathStrengthsGoals]: string };

//==== Book Enums ====
export enum ReadingLevelType {
  FAndP = 'F_AND_P',
  GBPlus = 'GB_PLUS',
  Math = 'MATH',
  Decodables = 'DECODABLES',
  Lexile = 'LEXILE',
  Assess = 'ASSESS',
  Preword = 'PRE_WORD',
}

export const readingLevelTypeDisplay: { value: ReadingLevelType; label: string }[] = [
  { value: ReadingLevelType.FAndP, label: 'F&P' },
  { value: ReadingLevelType.GBPlus, label: 'GB Plus' },
  { value: ReadingLevelType.Math, label: 'Math' },
  { value: ReadingLevelType.Decodables, label: 'Decodables' },
  { value: ReadingLevelType.Lexile, label: 'Lexile' },
  { value: ReadingLevelType.Assess, label: 'Assess' },
  { value: ReadingLevelType.Preword, label: 'Pre-word' },
];

export const readingLevelType = readingLevelTypeDisplay.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in ReadingLevelType]: string };

//==== Other Enums ====

export enum OrderColumn {
  StartsAt = 'STARTS_AT',
  StudentName = 'STUDENT_NAME',
  ParentName = 'PARENT_NAME',
  TeacherName = 'TEACHER_NAME',
}

export enum OrderBy {
  Desc = 'DESC',
  Asc = 'ASC',
}

export enum ConflictCheckerType {
  Student = 'STUDENT',
  Teacher = 'TEACHER',
}

export enum RecurringScheduledLessonType {
  WithTeacher = 'WITH_TEACHER',
  WithoutTeacher = 'WITHOUT_TEACHER',
}

export enum ScheduledLessonSetStatus {
  Pending = 'PENDING',
  Posted = 'POSTED',
  Rescheduling = 'RESCHEDULING',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
}

export const scheduledLessonSetStatusDisplay: { value: ScheduledLessonSetStatus; label: string }[] = [
  { value: ScheduledLessonSetStatus.Pending, label: 'Pending' },
  { value: ScheduledLessonSetStatus.Posted, label: 'Posted' },
  { value: ScheduledLessonSetStatus.Rescheduling, label: 'Rescheduling' },
  { value: ScheduledLessonSetStatus.Cancelled, label: 'Cancelled' },
  { value: ScheduledLessonSetStatus.Closed, label: 'Closed' },
];

export const scheduledLessonSetStatusType = scheduledLessonSetStatusDisplay.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in ScheduledLessonSetStatus]: string };
