import { AgreementEndReason } from '@hoot-reading/hoot-core/dist/enums/user/teacher/agreement-end-reason.enum';
import { OnLeaveReason } from '@hoot-reading/hoot-core/dist/enums/user/teacher/on-leave-reason.enum';
import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { TeacherAvailability } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-availability.enum';
import { TeacherDateType } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-date-type.enum';
import { TeacherSearchFieldOptions } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-search-field-options.enum';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import { Close } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Control, FormState, SubmitHandler, UseFormWatch, useForm } from 'react-hook-form';
import { OperatorFilter } from '@hoot/common/constants';
import { SearchSelect } from '@hoot/components/SearchSelect';
import { DatePicker } from '@hoot/components/form/DatePicker';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { MultiSelect } from '@hoot/components/form/MultiSelect';
import { Select } from '@hoot/components/form/SelectField';
import { HootQualificationWithIsEmpty, LanguageQualificationWithIsEmpty, UserQuery } from '@hoot/hooks/api/end-users/useGetUsers';
import { usePlaces } from '@hoot/hooks/usePlaces';
import { booleanFilterOptions } from '@hoot/interfaces/form';
import { spokenLanguageOptions, teacherDateTypeOptions } from '@hoot/interfaces/teacher';
import { UserAccount } from '@hoot/interfaces/user';
import {
  agreementEndReasonFilterOptions,
  hootQualificationFilterOptions,
  languageQualificationFilterOptions,
  onLeaveReasonFilterOptions,
  operatorFilterOptions,
  teacherAvailabilityFilterOptions,
  teacherStatusFilterOptions,
} from '@hoot/pages/users/interfaces/user-filters-form';
import { timeZoneData } from '@hoot/utils/timeZones';

interface Form {
  accountType: UserAccount | '';
  timeZone: string[];
  province: string[];
  userIsEnabled: string | '';
  country: string | '';
  isPasswordSet: string | '';
  teacher: {
    searchFieldSelection?: TeacherSearchFieldOptions;
    hootQualificationsOperator?: string;
    hootQualifications?: HootQualificationWithIsEmpty[];
    spokenLanguages?: SpokenLanguage[];
    teacherStatus: (TeacherStatus | 'IS_EMPTY')[] | '';
    teacherAvailability: (TeacherAvailability | 'IS_EMPTY')[] | '';
    languageQualificationsOperator?: string;
    languageQualifications: LanguageQualificationWithIsEmpty[];
    agreementEndReason: AgreementEndReason | 'IS_EMPTY' | '';
    dateType?: TeacherDateType | '';
    fromDate?: string;
    toDate?: string;
    onLeaveReason: OnLeaveReason | 'IS_EMPTY' | '';
    identificationSubmitted: string | '';
  };
}

interface Props {
  showDrawer: boolean;
  setShowDrawer: (showAddHootEmployeeModal: boolean) => void;
  userQuery: UserQuery;
  setUserQuery: React.Dispatch<React.SetStateAction<UserQuery>>;
}

const TeacherFilters = (props: { control: Control<Form>; watch: UseFormWatch<Form>; formState: FormState<Form> }) => {
  const { control, watch, formState } = props;

  const { teacher } = watch();

  return (
    <>
      <ListItem>
        <MultiSelect size="small" control={control} label="Status" name="teacher.teacherStatus" data={teacherStatusFilterOptions} />
      </ListItem>

      <ListItem>
        <MultiSelect size="small" control={control} label="Availability" name="teacher.teacherAvailability" data={teacherAvailabilityFilterOptions} />
      </ListItem>

      <ListItem>
        <MultiSelect size="small" control={control} data={spokenLanguageOptions} label="Spoken Languages" name="teacher.spokenLanguages" />
      </ListItem>

      <ListItem>
        <FormControl fullWidth size="small">
          <Dropdown
            variant="outlined"
            size="small"
            name="teacher.dateType"
            control={control}
            includeEmpty
            label="Date Type"
            options={teacherDateTypeOptions}
          />
        </FormControl>
      </ListItem>

      <ListItem>
        <FormControl variant="outlined" fullWidth>
          <DatePicker
            size="small"
            name="teacher.fromDate"
            control={control}
            label="From Date"
            disabled={!teacher.dateType}
            rules={{ required: !!teacher.dateType ? 'Field is required while Date Type is selected.' : false }}
          />
          {formState.errors.teacher?.fromDate ? (
            <FormHelperText style={{ marginTop: '10px' }} error={true}>
              {formState.errors.teacher?.fromDate?.message}
            </FormHelperText>
          ) : null}
        </FormControl>
      </ListItem>

      <ListItem>
        <FormControl variant="outlined" fullWidth>
          <DatePicker
            size="small"
            name="teacher.toDate"
            control={control}
            label="To Date"
            disabled={!teacher.dateType}
            rules={{ required: !!teacher.dateType ? 'Field is required while Date Type is selected.' : false }}
          />
          {formState.errors.teacher?.fromDate ? (
            <FormHelperText style={{ marginTop: '10px' }} error={true}>
              {formState.errors.teacher?.fromDate?.message}
            </FormHelperText>
          ) : null}
        </FormControl>
      </ListItem>

      <ListItem>
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel id="on-leave-reason-label">On Leave Reason</InputLabel>
          <Select control={control} name="teacher.onLeaveReason" labelId="on-leave-reason-label" label="On Leave Reason" fullWidth>
            {onLeaveReasonFilterOptions.map((o) => {
              return (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ListItem>

      <ListItem>
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel id="resignation-reason-label">Resignation Reason</InputLabel>
          <Select control={control} name="teacher.agreementEndReason" labelId="resignation-reason-label" label="Resignation Reason" fullWidth>
            <MenuItem value={''}>None</MenuItem>
            {agreementEndReasonFilterOptions.map((o) => {
              return (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ListItem>

      <ListItem>
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel id="identification-submitted-label">Identification Number Submitted</InputLabel>
          <Select
            size="small"
            control={control}
            name="teacher.identificationSubmitted"
            labelId="identification-submitted-label"
            label="Identification Number Submitted"
          >
            <MenuItem value={''}>None</MenuItem>
            {booleanFilterOptions.map((o) => {
              return (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ListItem>
    </>
  );
};

const TeacherFiltersDrawer = (props: Props) => {
  const { showDrawer, setShowDrawer, userQuery, setUserQuery } = props;

  const [hootFilterChecked, setHootFilterChecked] = useState<boolean>(!!userQuery.teacherQuery?.hootQualifications?.length);
  const [languageFilterChecked, setLanguageFilterChecked] = useState<boolean>(!!userQuery.teacherQuery?.languageQualifications?.length);

  const { watch, control, reset, handleSubmit, setValue, formState } = useForm<Form>({
    mode: 'onSubmit',
    defaultValues: {
      accountType: userQuery.accountType || '',
      country: userQuery.country || '',
      timeZone: userQuery.timeZones || [],
      province: userQuery.provincesStates || [],
      userIsEnabled: userQuery.isEnabled?.toString() || '',
      isPasswordSet: userQuery.isPasswordSet?.toString() || '',
      teacher: {
        searchFieldSelection: userQuery.teacherQuery.searchFieldSelection || TeacherSearchFieldOptions.All,
        hootQualifications: userQuery.teacherQuery.hootQualifications || [],
        languageQualifications: userQuery.teacherQuery.languageQualifications || [],
        teacherAvailability: userQuery.teacherQuery.teacherAvailability || [],
        onLeaveReason: userQuery.teacherQuery.onLeaveReason || '',
        dateType: userQuery.teacherQuery.dateType || '',
        fromDate: userQuery.teacherQuery.fromDate || '',
        toDate: userQuery.teacherQuery.toDate || '',
        agreementEndReason: userQuery.teacherQuery.agreementEndReason || '',
        spokenLanguages: userQuery.teacherQuery.spokenLanguages || [],
        teacherStatus: userQuery.teacherQuery.teacherStatus || [],
        identificationSubmitted: userQuery.teacherQuery.identificationSubmitted?.toString() || '',
      },
    },
  });

  const { country } = watch();
  const { provinceStateOptions } = usePlaces(country);
  const countryOptionsCanadaFirst = [
    { label: 'Canada', value: 'CA' },
    { label: 'United States', value: 'US' },
  ];

  const handleClose = () => {
    setShowDrawer(false);
  };

  const handleClearFilters = () => {
    reset();
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    setUserQuery((current) => ({
      ...current,
      page: 1,
      country: data.country !== '' ? data.country : undefined,
      isEnabled: data.userIsEnabled !== '' ? data.userIsEnabled === 'true' : undefined,
      timeZones: data.timeZone?.length === 0 ? undefined : data.timeZone,
      account: data.accountType !== '' ? data.accountType : undefined,
      provincesStates: data.province?.length === 0 ? undefined : data.province,
      isPasswordSet: data.isPasswordSet !== '' ? data.isPasswordSet === 'true' : undefined,
      teacherQuery: {
        searchFieldSelection: data.teacher.searchFieldSelection || TeacherSearchFieldOptions.All,
        hootQualifications:
          data.teacher.hootQualifications?.length === 0
            ? undefined
            : data.teacher.hootQualificationsOperator === OperatorFilter.Is
              ? data.teacher.hootQualifications
              : hootQualificationFilterOptions
                  .filter((o) =>
                    data.teacher.hootQualifications ? !data.teacher.hootQualifications.includes(o.value as HootQualificationWithIsEmpty) : false,
                  )
                  .map((q) => q.value as HootQualificationWithIsEmpty),
        languageQualifications:
          data.teacher.languageQualifications?.length === 0
            ? undefined
            : data.teacher.languageQualificationsOperator === OperatorFilter.Is
              ? data.teacher.languageQualifications
              : languageQualificationFilterOptions
                  .filter((o) =>
                    data.teacher.languageQualifications
                      ? !data.teacher.languageQualifications.includes(o.value as LanguageQualificationWithIsEmpty)
                      : false,
                  )
                  .map((q) => q.value as LanguageQualificationWithIsEmpty),
        onLeaveReason: data.teacher.onLeaveReason !== '' ? data.teacher.onLeaveReason : undefined,
        dateType: data.teacher.dateType !== '' ? data.teacher.dateType : undefined,
        toDate: data.teacher.toDate !== '' ? data.teacher.toDate : undefined,
        fromDate: data.teacher.fromDate !== '' ? data.teacher.fromDate : undefined,
        agreementEndReason: data.teacher.agreementEndReason !== '' ? data.teacher.agreementEndReason : undefined,
        spokenLanguages: data.teacher.spokenLanguages?.length === 0 ? undefined : data.teacher.spokenLanguages,
        teacherStatus: data.teacher.teacherStatus !== '' ? data.teacher.teacherStatus : undefined,
        identificationSubmitted: data.teacher.identificationSubmitted !== '' ? data.teacher.identificationSubmitted === 'true' : undefined,
        teacherAvailability: data.teacher.teacherAvailability !== '' ? data.teacher.teacherAvailability : undefined,
      },
    }));
    handleClose();
  };

  return (
    <Drawer anchor="right" open={showDrawer} onClose={handleClose}>
      <Box sx={{ minWidth: '320px' }}>
        <Box sx={{ position: 'relative' }}>
          <Typography
            style={{
              marginLeft: '24px',
              marginTop: '105px',
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '48px',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            Teacher Filters
          </Typography>

          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '4px',
              cursor: 'pointer',
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ marginTop: '16px' }} />
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <List disablePadding sx={{ marginY: '16px' }}>
            <ListItem>
              <Box>
                <span>
                  <strong>Operator Filters</strong>
                </span>
              </Box>
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={<Checkbox checked={hootFilterChecked} onClick={() => setHootFilterChecked(!hootFilterChecked)} />}
                label="Hoot Qualifications"
              />
            </ListItem>
            {hootFilterChecked ? (
              <>
                <ListItem>
                  <FormControl fullWidth size="small">
                    <Dropdown
                      variant="outlined"
                      size="small"
                      control={control}
                      label="Operator"
                      name="teacher.hootQualificationsOperator"
                      options={operatorFilterOptions}
                      defaultValue={'IS'}
                    />
                  </FormControl>
                </ListItem>
                <ListItem>
                  <MultiSelect
                    size="small"
                    control={control}
                    data={hootQualificationFilterOptions}
                    label="Hoot Qualifications"
                    name="teacher.hootQualifications"
                  />
                </ListItem>
              </>
            ) : null}
            <ListItem>
              <FormControlLabel
                control={<Checkbox checked={languageFilterChecked} onClick={() => setLanguageFilterChecked(!languageFilterChecked)} />}
                label="Language Qualifications"
              />
            </ListItem>
            {languageFilterChecked ? (
              <>
                <ListItem>
                  <FormControl fullWidth size="small">
                    <Dropdown
                      variant="outlined"
                      size="small"
                      control={control}
                      label="Operator"
                      name="teacher.languageQualificationsOperator"
                      options={operatorFilterOptions}
                      defaultValue={'IS'}
                    />
                  </FormControl>
                </ListItem>
                <ListItem>
                  <MultiSelect
                    size="small"
                    control={control}
                    data={languageQualificationFilterOptions}
                    label="Language Qualifications"
                    name="teacher.languageQualifications"
                  />
                </ListItem>
              </>
            ) : null}
          </List>
          <List disablePadding sx={{ marginTop: '16px' }}>
            <ListItem>
              <Box>
                <span>
                  <strong>Filters</strong>
                </span>
              </Box>
            </ListItem>
            <ListItem>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="is-employee-enabled-label">Is Enabled</InputLabel>
                <Select control={control} name="userIsEnabled" labelId="is-employee-enabled-label" label="Is Enabled" fullWidth>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
            <TeacherFilters control={control} watch={watch} formState={formState} />
            <>
              <ListItem>
                <MultiSelect size="small" control={control} label="Time Zone" name="timeZone" data={timeZoneData} />
              </ListItem>

              <ListItem>
                <SearchSelect
                  size="small"
                  onChangeCallback={() => setValue('province', [])}
                  control={control}
                  label="Country"
                  name="country"
                  data={countryOptionsCanadaFirst}
                />
              </ListItem>

              <ListItem>
                <MultiSelect
                  size="small"
                  control={control}
                  label="Province / State"
                  name="province"
                  data={provinceStateOptions}
                  disabled={country === 'all'}
                />
              </ListItem>

              <ListItem>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="is-password-set-label">Is Password Set</InputLabel>
                  <Select control={control} name="isPasswordSet" labelId="is-password-set-label" label="Is Password Set" fullWidth>
                    <MenuItem value={''}>None</MenuItem>
                    {booleanFilterOptions.map((o) => {
                      return (
                        <MenuItem key={o.value} value={o.value}>
                          {o.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </ListItem>
            </>
          </List>
        </>
        <Grid container p={2} sx={{ position: 'sticky', bottom: 0, zIndex: 1, background: '#fff' }}>
          <Grid item container>
            <Button type="submit" size="small" variant="contained" fullWidth>
              Apply
            </Button>
          </Grid>
          <Grid item container sx={{ marginTop: '16px' }}>
            <Button color="inherit" type="button" onClick={handleClearFilters} size="small" variant="contained" fullWidth>
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  );
};

export default TeacherFiltersDrawer;
