import { Stack, Typography } from '@mui/material';

export enum IllustrationEnum {
  Coffee = 'Coffee',
  NoResults = 'NoResults',
  SetupComplete = 'SetupComplete',
  EmptyCart = 'EmptyCart',
  NoCreditCards = 'NoCreditCards',
  NoMessages = 'NoMessages',
  EmptyInbox = 'EmptyInbox',
  NoInternet = 'NoInternet',
  Error = 'Error',
  NoGps = 'NoGps',
  NoDocuments = 'NoDocuments',
  NoImages = 'NoImages',
}

export interface ViewStateIllustrationProps {
  illustration: IllustrationEnum;
  // Overrides;
  title?: string;
  subtitle?: string;
}

interface Illustration {
  title: string;
  subtitle: string;
  imgSrc: string;
  imgAlt: string;
}

const defaults: Record<IllustrationEnum, Illustration> = {
  [IllustrationEnum.Coffee]: {
    title: "You're all caught up!",
    subtitle: 'Check back later',
    imgSrc: '/images/illustrations/coffee.svg',
    imgAlt: 'Coffee',
  },
  [IllustrationEnum.NoResults]: {
    title: 'No results found',
    subtitle: 'Try adjusting your search to find what you are looking for',
    imgSrc: '/images/illustrations/no-search-results.svg',
    imgAlt: 'No results',
  },
  [IllustrationEnum.SetupComplete]: {
    title: 'Setup completed',
    subtitle: 'Congratulations! You are all set up!',
    imgSrc: '/images/illustrations/check.svg',
    imgAlt: 'Setup Complete',
  },
  [IllustrationEnum.EmptyCart]: {
    title: 'Your cart is empty',
    subtitle: 'Check back later',
    imgSrc: '/images/illustrations/empty-cart.svg',
    imgAlt: 'Empty Cart',
  },
  [IllustrationEnum.NoCreditCards]: {
    title: 'No credit cards',
    subtitle: 'Please add a credit card to your account',
    imgSrc: '/images/illustrations/no-credit-cards.svg',
    imgAlt: 'No credit cards.',
  },
  [IllustrationEnum.NoMessages]: {
    title: 'No messages',
    subtitle: 'You have no new messages',
    imgSrc: '/images/illustrations/no-messages.svg',
    imgAlt: 'No messages',
  },
  [IllustrationEnum.EmptyInbox]: {
    title: 'Your inbox is empty',
    subtitle: 'All incoming requests will be listed in this folder',
    imgSrc: '/images/illustrations/empty-inbox.svg',
    imgAlt: 'Empty inbox',
  },
  [IllustrationEnum.NoInternet]: {
    title: 'No internet connection',
    subtitle: 'Please check your internet connection',
    imgSrc: '/images/illustrations/no-internet.svg',
    imgAlt: 'No internet',
  },
  [IllustrationEnum.Error]: {
    title: 'Oops!',
    subtitle: 'Something went wrong, please try again',
    imgSrc: '/images/illustrations/error.svg',
    imgAlt: 'Error',
  },
  [IllustrationEnum.NoGps]: {
    title: 'No GPS connection',
    subtitle: 'Please check for location permission',
    imgSrc: '/images/illustrations/no-gps.svg',
    imgAlt: 'No GPS',
  },
  [IllustrationEnum.NoDocuments]: {
    title: 'No documents',
    subtitle: 'Start creating your documents',
    imgSrc: '/images/illustrations/no-documents.svg',
    imgAlt: 'No Documents',
  },
  [IllustrationEnum.NoImages]: {
    title: 'No images',
    subtitle: 'Start uploading images',
    imgSrc: '/images/illustrations/no-images.svg',
    imgAlt: 'No images',
  },
};

const ViewStateIllustration = (props: ViewStateIllustrationProps) => {
  const { illustration, title: titleOverride, subtitle: subtitleOverride } = props;
  const { title: defaultTitle, subtitle: defaultSubtitle, imgSrc, imgAlt } = defaults[illustration];
  return (
    <>
      <Stack alignItems="center" padding={4}>
        <img src={imgSrc} alt={imgAlt} width={250} height={200} />
        <Typography variant="titleSmall" sx={{ color: '#4d4d4d' }}>
          {titleOverride ?? defaultTitle}
        </Typography>
        <Typography variant="bodySmall" sx={{ color: '#4d4d4d' }}>
          {subtitleOverride ?? defaultSubtitle}
        </Typography>
      </Stack>
    </>
  );
};

export default ViewStateIllustration;
