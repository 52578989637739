import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../../config';
import { QueryKey } from '../../../queryKeys';

export type LettersAndSoundsFormativeAssessmentsDetailsResponse = {
  lessonDate: string;
  lessonNumber: string;
  lessonId: string;
  bookTitle: string;
  teacherName?: string;
  prefixedTeacherNumber?: string;
  teacherAccountId: string;
  score: string;
  lettersReadCorrectly: string;
  lettersReadIncorrectly: string;
};

const getLettersAndSoundsFormativeAssessmentDetails = async (lessonReviewId: string, bookId: string) => {
  const { data } = await axios.get<LettersAndSoundsFormativeAssessmentsDetailsResponse>(
    `${config.apiUrl}/admin/lesson-reviews/${lessonReviewId}/formative-assessment/letters-and-sounds/${bookId}`,
  );
  return data;
};

export default function useGetLettersAndSoundsFormativeAssessmentDetails(
  lessonReviewId: string,
  bookId: string,
  options?: Omit<UseQueryOptions<LettersAndSoundsFormativeAssessmentsDetailsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetLettersAndSoundsFormativeAssessmentDetails, lessonReviewId, bookId],
    () => getLettersAndSoundsFormativeAssessmentDetails(lessonReviewId, bookId),
    options,
  );
}
