import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { CompletedUnit, StudentHootAssessment } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentHootAssessments';
import { enumToSentence, enumToTitleCase } from '@hoot/utils/stringUtils';
import { HootAssessmentModule, UnitIdentifier, frustrationLevelLabelDictionary } from '../../../../interfaces/assessments-enums';

interface Props {
  unit: CompletedUnit;
  assessment: StudentHootAssessment;
}

const renderScore = (score?: number | null, maxScore?: number | null) => {
  if (!score || !maxScore) return;

  const percentage = ((Number(score) / maxScore) * 100).toFixed(0);

  return `${score} / ${maxScore} (${percentage}%)`;
};

const BoxContainer = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const SDQCard = (props: { unit: CompletedUnit }) => {
  const { unit } = props;
  const teacherName = `${unit.assessedByTeacher.firstName} ${unit.assessedByTeacher.lastName}`;
  return (
    <>
      <Header unit={unit} />
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Frustration Level
        </Box>
        <Box sx={{ fontSize: '14px' }}>{frustrationLevelLabelDictionary[unit.frustrationLevel!]}</Box>
      </BoxContainer>
      <Footer teacherName={teacherName} />
    </>
  );
};

const Footer = (props: { teacherName: string }) => {
  const { teacherName } = props;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '16px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Assessed by
        </Box>
        <Box sx={{ fontSize: '14px' }}>{teacherName}</Box>
      </Box>
    </Box>
  );
};

const Unit41ACard = (props: { unit: CompletedUnit }) => {
  const { unit } = props;
  const teacherName = `${unit.assessedByTeacher.firstName} ${unit.assessedByTeacher.lastName}`;
  return (
    <>
      <Header unit={unit} />
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Book
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.bookForRunningRecord?.name}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Book Level:
        </Box>
        <Box sx={{ fontSize: '14px' }}>{`${unit.bookForRunningRecord?.level}`}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Accuracy Rate:
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.accuracyRate}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Word count per minute
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.wordCountPerMin}</Box>
      </BoxContainer>
      <Footer teacherName={teacherName} />
    </>
  );
};

const Unit42ACard = (props: { unit: CompletedUnit }) => {
  const { unit } = props;
  const teacherName = `${unit.assessedByTeacher.firstName} ${unit.assessedByTeacher.lastName}`;
  return (
    <>
      <Header unit={unit} />
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Net Fluency Score
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.fluencyScore}</Box>
      </BoxContainer>
      <Footer teacherName={teacherName} />
    </>
  );
};

const Unit43ACard = (props: { unit: CompletedUnit }) => {
  const { unit } = props;
  const teacherName = `${unit.assessedByTeacher.firstName} ${unit.assessedByTeacher.lastName}`;
  return (
    <>
      <Header unit={unit} />
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Net Comprehension Score
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.comprehensionScore}</Box>
      </BoxContainer>
      <Footer teacherName={teacherName} />
    </>
  );
};

const ATRCard = (props: { unit: CompletedUnit }) => {
  const { unit } = props;
  const teacherName = `${unit.assessedByTeacher.firstName} ${unit.assessedByTeacher.lastName}`;
  return (
    <>
      <Header unit={unit} />
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          How do you feel about reading out loud?
        </Box>
        <Box sx={{ fontSize: '14px' }}>{enumToSentence(unit.feelingOnReadingOutLoud!)}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Do you like reading to yourself?
        </Box>
        <Box sx={{ fontSize: '14px' }}>{enumToSentence(unit.isReadingToYourselfLiked!)}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Do you like listening to a book being read to you?
        </Box>
        <Box sx={{ fontSize: '14px' }}>{enumToSentence(unit.isListeningToABookReadToYouLiked!)}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Student was engaged for...
        </Box>
        <Box sx={{ fontSize: '14px' }}>{enumToSentence(unit.engagement!)}</Box>
      </BoxContainer>
      <Footer teacherName={teacherName} />
    </>
  );
};

const Header = (props: { unit: CompletedUnit }) => {
  const { unit } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <Box component="span" sx={{ fontSize: '22px' }}>
        {unit.unitName}
      </Box>
    </Box>
  );
};
const ModulesCard = (props: { unit: CompletedUnit }) => {
  const { unit } = props;
  const teacherName = `${unit.assessedByTeacher.firstName} ${unit.assessedByTeacher.lastName}`;
  return (
    <>
      <Header unit={unit} />
      <BoxContainer>
        <Box component="span" sx={{ fontSize: '14px', fontWeight: 500 }}>
          Score
        </Box>
        <Box sx={{ fontSize: '14px' }}>{renderScore(unit.score, unit.totalQuestions)}</Box>
        <Box sx={{ fontSize: '14px' }}>{enumToTitleCase(unit?.instructionIndicator ?? '')}</Box>
      </BoxContainer>
      <Footer teacherName={teacherName} />
    </>
  );
};

const StudentAssessmentCard = (props: Props) => {
  const { unit } = props;
  if (unit.module === HootAssessmentModule.SDQ) {
    return <SDQCard unit={unit} />;
  } else if (unit.module === HootAssessmentModule.Four && unit.unitIdentifier === UnitIdentifier.U_4_1A) {
    return <Unit41ACard unit={unit} />;
  } else if (unit.module === HootAssessmentModule.Four && unit.unitIdentifier === UnitIdentifier.U_4_2A) {
    return <Unit42ACard unit={unit} />;
  } else if (unit.module === HootAssessmentModule.Four && unit.unitIdentifier === UnitIdentifier.U_4_3A) {
    return <Unit43ACard unit={unit} />;
  } else if (unit.module === HootAssessmentModule.ATR) {
    return <ATRCard unit={unit} />;
  } else {
    //For modules 1, 2, and 3
    return <ModulesCard unit={unit} />;
  }
};

export default StudentAssessmentCard;
