import { useCallback, useEffect, useRef, useState } from 'react';
import useGetDailyRecordings from '../../../../../hooks/api/daily/useGetDailyRecording';
import { useGetDailyRecordingsAccessLinks } from '../../../../../hooks/api/daily/useGetDailyRecordingsAccessLink';
import { isBetweenStrict } from '../../../../../utils/isBetween';
import { LessonEventSequence } from './useLessonEventVisualiser';

export interface LessonEventVideo {
  recordingId: string;
  dailyRoomName: string;
  duration: number;
  videoStartedAt: number;
  videoEndsAt: number;
  videoLink?: string;
}

export type videoPosition = 'left' | 'right' | 'full' | 'none';

export function useLessonEventVideo(props: {
  lessonId: string;
  initialLessonJoinEventAt: number;
  currentTime: React.MutableRefObject<number>;
  lessonEventSequence: LessonEventSequence[];
}) {
  const teacherVideoRef = useRef<HTMLVideoElement | null>(null);
  const studentVideoRef = useRef<HTMLVideoElement | null>(null);

  const [studentVideoPosition, setStudentVideoPosition] = useState<videoPosition>('none');
  const [teacherVideoPosition, setTeacherVideoPosition] = useState<videoPosition>('none');

  const [dailyRecordings, setDailyRecordings] = useState<LessonEventVideo[]>([]);

  useGetDailyRecordings(props.lessonId, {
    onSuccess: (data) => {
      setDailyRecordings(
        data.map<LessonEventVideo>((d) => ({
          recordingId: d.recordingId,
          dailyRoomName: d.dailyRoomName,
          duration: d.duration,
          videoStartedAt: Math.floor(d.recordingStartedAt - props.initialLessonJoinEventAt / 1000),
          videoEndsAt: Math.floor(d.recordingStartedAt - props.initialLessonJoinEventAt / 1000 + d.duration),
        })),
      );
    },
  });

  const recordingIds = dailyRecordings.map((dr) => dr.recordingId);

  useGetDailyRecordingsAccessLinks(props.lessonId, recordingIds, {
    enabled: recordingIds.length > 0,
    onSuccess: (data) => {
      setDailyRecordings((recording) => {
        return recording.map<LessonEventVideo>((r) => {
          const videoLink = data.find((d) => d.recordingId === r.recordingId)?.accessLink ?? undefined;
          return {
            ...r,
            videoLink,
          };
        });
      });
    },
  });

  const timerId = useRef<number>();

  useEffect(() => {
    const updateVideo = () => {
      if (teacherVideoRef.current && studentVideoRef.current) {
        const video = dailyRecordings.find((r) => isBetweenStrict(props.currentTime.current, r.videoStartedAt, r.videoEndsAt));
        if (!!video && !!video.videoLink) {
          if (teacherVideoRef.current.src !== video.videoLink) {
            teacherVideoRef.current.src = video.videoLink;
            studentVideoRef.current.src = video.videoLink;
          }
        }
      }

      const lessonEventSequence = props.lessonEventSequence.filter((le) => le.occuredAt <= props.currentTime.current);
      if (lessonEventSequence.length > 0) {
        const lastEventSequence = lessonEventSequence[lessonEventSequence.length - 1];
        setStudentVideoPosition(lastEventSequence.studentVideoPosition);
        setTeacherVideoPosition(lastEventSequence.teacherVideoPosition);
      }
    };

    timerId.current = window.setInterval(updateVideo, 500);

    return () => {
      clearInterval(timerId.current);
    };
  }, [dailyRecordings, props.currentTime, props.lessonEventSequence]);

  const seekTo = useCallback(
    (position: number) => {
      const video = dailyRecordings.find((r) => isBetweenStrict(position, r.videoStartedAt, r.videoEndsAt));
      if (video && video.videoLink) {
        if (teacherVideoRef.current && studentVideoRef.current) {
          if (teacherVideoRef.current.src !== video.videoLink) {
            teacherVideoRef.current.src = video.videoLink;
            studentVideoRef.current.src = video.videoLink;
          }

          const offset = position - video.videoStartedAt;
          teacherVideoRef.current.currentTime = offset;
          studentVideoRef.current.currentTime = offset;
        }
      }
    },
    [dailyRecordings],
  );

  const syncVideoTimestamp = useCallback(() => {
    seekTo(props.currentTime.current);
  }, [props.currentTime, seekTo]);

  const pauseVideo = useCallback(() => {
    teacherVideoRef.current?.pause();
    studentVideoRef.current?.pause();
  }, []);

  const playVideo = useCallback(() => {
    teacherVideoRef.current?.play();
    studentVideoRef.current?.play();
  }, []);

  const setPlaybackSpeed = useCallback((rate: number) => {
    if (teacherVideoRef.current) {
      teacherVideoRef.current.playbackRate = rate;
    }
    if (studentVideoRef.current) {
      studentVideoRef.current.playbackRate = rate;
    }
  }, []);

  return {
    teacherVideoRef,
    studentVideoRef,
    dailyRecordings,
    seekTo,
    syncVideoTimestamp,
    playVideo,
    pauseVideo,
    setPlaybackSpeed,
    studentVideoPosition,
    teacherVideoPosition,
  };
}
