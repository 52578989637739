import { TeacherShiftCancellationReason, TeacherShiftStatus } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';

export interface RescheduleShiftsRequest {
  status: TeacherShiftStatus;
  cancellationReason?: TeacherShiftCancellationReason;
  shifts: RescheduleShiftRequest[];
}

export interface RescheduleShiftRequest {
  shiftId: string;
  startsAt: number;
  endsAt: number;
}

async function rescheduleShifts(request: RescheduleShiftsRequest) {
  await axios.post<void>(`${config.apiUrl}/admin/teacher-shift/reschedule`, request);
}

export default function useRescheduleShifts(
  options?: Omit<UseMutationOptions<void, AxiosError<ErrorResponseDto>, RescheduleShiftsRequest>, 'queryKey' | 'queryFn'>,
) {
  return useMutation((request) => rescheduleShifts(request), options);
}
