import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, Button, Card, CardContent, LinearProgress, Menu, MenuItem, Stack, TableContainer, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import QueryableTablePagination from '@hoot/components/QueryableTablePagination';
import { useAlert } from '@hoot/contexts/AlertContext';
import useEdlinkLinkLocationStudents from '@hoot/hooks/api/edlink/useEdlinkLinkLocationStudents';
import useGetSchoolStudents, {
  GetLocationStudentsQuery,
  GetLocationStudentsQuerySortKeys,
  LocationStudentSearchResult,
} from '@hoot/hooks/api/hfs/district-schools/students/useGetLocationStudents';
import { SortOrder } from '@hoot/interfaces/order-by';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import LocationStudentsSearchTextField, { LocationStudentsSearchFieldOptions } from '@hoot/pages/district-schools/students/LocationSearchTextField';
import LocationStudentsFilters from '@hoot/pages/district-schools/students/LocationStudentsFilters';
import SchoolStudentsTable from '@hoot/pages/district-schools/students/LocationStudentsTable';
import { LocationStudentsPageQueryParams } from '@hoot/pages/district-schools/students/models';
import { routes } from '@hoot/routes/routes';
import { removeUndefinedProperties } from '@hoot/utils/remove-undefined-properties';

const ActionButton = (props: { isLocationConnectedToEdlink: boolean }) => {
  const { isLocationConnectedToEdlink } = props;
  const { locationId } = useParams();
  const { success, error } = useAlert();
  const [actionAnchorEl, setActionAnchorEl] = useState<Element | null>(null);
  const open = Boolean(actionAnchorEl);

  const startEdlinkLocationStudentLinkingRequest = useEdlinkLinkLocationStudents(locationId!);

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setActionAnchorEl(null);
  };

  const handleSsoSyncClick = () => {
    startEdlinkLocationStudentLinkingRequest.mutate(undefined, {
      onSuccess: () => {
        success(`Submitted request successfully. Please wait a few minutes and refresh.`);
      },
      onError: (err) => {
        console.error(err);
        error(`An error occurred`);
      },
    });
    setActionAnchorEl(null);
  };

  return (
    <Stack>
      <Button
        type="button"
        color="inherit"
        variant="outlined"
        size="large"
        startIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={handleActionClick}
      >
        Action
      </Button>
      <Menu
        open={open}
        anchorEl={actionAnchorEl}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: 1 }}
      >
        <MenuItem disabled={!isLocationConnectedToEdlink} onClick={handleSsoSyncClick}>
          Request SSO Sync
        </MenuItem>
      </Menu>
    </Stack>
  );
};

const LocationStudents = (props: { isLocationConnectedToEdlink: boolean }) => {
  const { isLocationConnectedToEdlink } = props;
  const { locationId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { error } = useAlert();

  const [getSchoolStudentsQuery, setGetSchoolStudentsQuery] = useState<GetLocationStudentsQuery>({
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
    searchFieldSelection: LocationStudentsSearchFieldOptions.All,
  });

  const [schoolStudentsPaginatedResponse, setSchoolStudentsPaginatedResponse] = useState<GenericPaginatedResponse<LocationStudentSearchResult>>({
    data: [],
    count: 0,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { refetch, isFetching } = useGetSchoolStudents(locationId!, getSchoolStudentsQuery, {
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setSchoolStudentsPaginatedResponse({ ...data });
    },
    onError: (err) => {
      console.error(err);
      error(`An error occurred while loading districts and schools.`);
    },
  });

  // When the URL query args have been updated, then transform the query args into a request body and fetch the results.
  useEffect(() => {
    setGetSchoolStudentsQuery(() => {
      const search = searchParams.get(LocationStudentsPageQueryParams.Search) ?? undefined;
      const searchField =
        (searchParams.get(LocationStudentsPageQueryParams.SearchByField) as LocationStudentsSearchFieldOptions) ??
        LocationStudentsSearchFieldOptions.All;

      const statusFilters = searchParams.getAll(LocationStudentsPageQueryParams.Status) as StudentStatus[];
      const hasEnrolmentsFilters = searchParams.getAll(LocationStudentsPageQueryParams.HasEnrolments);
      const enrolmentFilters = searchParams.getAll(LocationStudentsPageQueryParams.Enrolment);

      const page = parseInt(searchParams.get(LocationStudentsPageQueryParams.Page) ?? '1') ?? 1;
      const pageSize = parseInt(searchParams.get(LocationStudentsPageQueryParams.PageSize) ?? `${DEFAULT_PAGE_SIZE}`) ?? DEFAULT_PAGE_SIZE;

      const orderBy = (searchParams.get(LocationStudentsPageQueryParams.OrderBy) as GetLocationStudentsQuerySortKeys | null) ?? undefined;
      const sortDirection = (searchParams.get(LocationStudentsPageQueryParams.SortDirection) as SortOrder | null) ?? undefined;

      const updatedQuery: GetLocationStudentsQuery = {
        search: (search?.length ?? 0) > 0 ? search : undefined,
        searchFieldSelection: searchField,
        hasEnrolments: hasEnrolmentsFilters?.length ? hasEnrolmentsFilters.map((x) => x === 'true') : undefined,
        enrolments: enrolmentFilters?.length ? enrolmentFilters : undefined,
        statuses: statusFilters?.length ? statusFilters : undefined,
        page,
        pageSize,
        orderBy,
        sortDirection,
      };
      return removeUndefinedProperties(updatedQuery) as GetLocationStudentsQuery;
    });
  }, [searchParams]);

  // When the query changes, then fetch the results.
  useEffect(() => {
    refetch();
  }, [getSchoolStudentsQuery, refetch]);

  /**
   * Navigate to the Create Student page with school field pre-populated.
   */
  const onAddStudent = () => {
    navigate(`${routes.users.students.create.url}?locationId=${locationId}`);
  };

  return (
    <>
      <Card>
        <Box sx={{ width: '100%', height: '4px' }}>{isFetching && <LinearProgress />}</Box>
        <CardContent>
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="titleLarge">Students ({schoolStudentsPaginatedResponse.count})</Typography>
              <Stack direction="row" gap={1}>
                <ActionButton isLocationConnectedToEdlink={isLocationConnectedToEdlink} />
                <Button size="large" variant="contained" onClick={onAddStudent}>
                  Add
                </Button>
              </Stack>
            </Stack>
            <LocationStudentsSearchTextField sx={{ mt: 3 }} />
            <LocationStudentsFilters locationId={locationId!} sx={{ mt: 3 }} />
            <TableContainer sx={{ mt: 3 }}>
              <SchoolStudentsTable
                query={getSchoolStudentsQuery}
                results={schoolStudentsPaginatedResponse.data}
                isLocationConnectedToEdlink={isLocationConnectedToEdlink}
              />
              <QueryableTablePagination paginatedResponse={schoolStudentsPaginatedResponse} />
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default LocationStudents;
