import {
  ScheduledLessonLanguage,
  ScheduledLessonProgram,
  ScheduledLessonSubject,
  ScheduledLessonTheme,
  ScheduledLessonType,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ConflictsResponse } from './useGetConflicts';

export interface RescheduleLessonRequest {
  teacherAccountId: string | null;
  lessonType: ScheduledLessonType;
  theme: ScheduledLessonTheme;
  program?: ScheduledLessonProgram;
  partnerId?: string | null;
  siteId?: string | null;
  accountId?: string | null;
  subject: ScheduledLessonSubject;
  language: ScheduledLessonLanguage;
  startsAt: number;
  durationInMinutes: number;
  enrolmentId?: string | null;
  isCustomerBillable?: boolean;
  lessonSetId?: string | null;
}

export interface RescheduleLessonResponse {
  isSuccess: boolean;
  conflicts: ConflictsResponse[];
  rescheduledLessonId?: string;
}

async function rescheduleLesson(lessonId: string, request: RescheduleLessonRequest) {
  const { data } = await axios.post<RescheduleLessonResponse>(`${config.apiUrl}/admin/scheduler/reschedule/${lessonId}`, request);

  return data;
}

export default function useRescheduleLesson(
  lessonId: string,
  options?: Omit<UseMutationOptions<RescheduleLessonResponse, AxiosError, RescheduleLessonRequest>, 'queryKey' | 'queryFn'>,
) {
  return useMutation((request) => rescheduleLesson(lessonId, request), options);
}
