import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { studentGradeLookup } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { Close } from '@mui/icons-material';
import { Chip, Stack, Typography } from '@mui/material';
import FilterDropDown from '@hoot/components/form/FilterDropDown';
import { FilterOption } from '@hoot/components/form/filterDropDown/common/DateFilter';
import { enrolmentStatusMapping, productStatusLabels, studentSpecialIndicatorMapping, studentSpecialIndicators } from '@hoot/components/hfs-utils';
import { useAlert } from '@hoot/contexts/AlertContext';
import useGetDistrictSchool from '@hoot/hooks/api/hfs/useGetDistrictSchool';
import { LocationSummaryQuery } from '@hoot/hooks/api/hfs/useGetLocationSummaryDashboard';
import useSearchLocationEnrolments from '@hoot/hooks/api/hfs/useSearchLocationEnrolments';
import useSearchLocationProducts from '@hoot/hooks/api/hfs/useSearchLocationProducts';
import { studentGradeOptions, studentProfileStatus, studentProfileStatusMapping } from '@hoot/interfaces/profile';

interface FilterProps {
  value: LocationSummaryQuery;
  onChange: (filters: LocationSummaryQuery) => void;
  locationId?: string;
}

const FiltersContainer = (props: FilterProps) => {
  const { value, onChange, locationId } = props;
  const { error } = useAlert();

  const { data: locationProducts } = useSearchLocationProducts(locationId ?? '', {
    enabled: !!locationId,
    onError: (err) => {
      console.error(err);
      error('There was an error getting the products for the billable entity!');
    },
  });

  const locationProductsOptions: FilterOption<string>[] = (locationProducts ?? []).map((product) => ({
    label: product.friendlyId,
    value: product.friendlyId,
  }));

  const { data: locationEnrolments } = useSearchLocationEnrolments(locationId ?? '', {
    enabled: !!locationId,
    onError: (err) => {
      console.error(err);
      error('There was an error getting the enrolments for the billable entity!');
    },
  });

  const { data: locationSchools } = useGetDistrictSchool(locationId ?? '', {
    enabled: !!locationId,
    onError: (err) => {
      console.error(err);
      error('There was an error getting the schools for the billable entity!');
    },
  });

  const locationEnrolmentOptions: FilterOption<string>[] = (locationEnrolments?.enrolments ?? []).map((enrolment) => ({
    label: enrolment.friendlyId,
    value: enrolment.friendlyId,
  }));

  const locationSchoolsOptions: FilterOption<string>[] = (locationSchools?.districtSchools ?? [])
    .sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    })
    .map((school) => ({
      label: school.name,
      value: school.id,
    }));

  const handleChange = (property: keyof LocationSummaryQuery, val: string[]): void => {
    if (value) {
      if (onChange) {
        onChange({ ...value, [property]: val });
      }
    }
  };

  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="bodySmall">Filters</Typography>
      <FilterDropDown<string>
        title="Product ID"
        dismissPopoverOnSelection={true}
        value={value?.productId}
        options={locationProductsOptions}
        onChange={(val, _selectedVal) => handleChange('productId', val)}
      />
      <FilterDropDown<string>
        title="Product Status"
        dismissPopoverOnSelection={true}
        value={value?.productStatus}
        options={[
          { label: productStatusLabels[ProductStatus.Active], value: ProductStatus.Active },
          { label: productStatusLabels[ProductStatus.Requested], value: ProductStatus.Requested },
          { label: productStatusLabels[ProductStatus.Expired], value: ProductStatus.Expired },
        ]}
        onChange={(val, _selectedVal) => handleChange('productStatus', val)}
      />
      <FilterDropDown<string>
        title="Enrolment ID"
        dismissPopoverOnSelection={true}
        value={value?.enrolmentId}
        options={locationEnrolmentOptions}
        onChange={(val, _selectedVal) => handleChange('enrolmentId', val)}
      />
      <FilterDropDown<string>
        title="Enrolment Status"
        dismissPopoverOnSelection={true}
        value={value?.enrolmentStatus}
        options={[
          { label: enrolmentStatusMapping[EnrolmentStatus.Open], value: EnrolmentStatus.Open },
          { label: enrolmentStatusMapping[EnrolmentStatus.Scheduled], value: EnrolmentStatus.Scheduled },
          { label: enrolmentStatusMapping[EnrolmentStatus.InProgress], value: EnrolmentStatus.InProgress },
          { label: enrolmentStatusMapping[EnrolmentStatus.Closed], value: EnrolmentStatus.Closed },
        ]}
        onChange={(val, _selectedVal) => handleChange('enrolmentStatus', val)}
      />
      <FilterDropDown<string>
        title="School"
        dismissPopoverOnSelection={true}
        value={value?.schoolId}
        options={locationSchoolsOptions}
        onChange={(val, _selectedVal) => handleChange('schoolId', val)}
      />
      <FilterDropDown<string>
        title="Student Status"
        dismissPopoverOnSelection={true}
        value={value?.studentStatus}
        options={studentProfileStatus}
        onChange={(val, _selectedVal) => handleChange('studentStatus', val)}
      />
      <FilterDropDown<string>
        title="Student Grade"
        dismissPopoverOnSelection={true}
        value={value?.studentGrade}
        options={studentGradeOptions}
        onChange={(val, _selectedVal) => handleChange('studentGrade', val)}
      />
      <FilterDropDown<string>
        title="Student Indicators"
        dismissPopoverOnSelection={true}
        value={value?.studentIndicator}
        options={studentSpecialIndicators}
        onChange={(val, _selectedVal) => handleChange('studentIndicator', val)}
      />
    </Stack>
  );
};

const ActiveFiltersContainer = (props: FilterProps) => {
  const { value, onChange } = props;

  const handleDelete = (property: keyof LocationSummaryQuery, val: string) => () => {
    if (value) {
      const updatedFilters = { ...value };
      updatedFilters[property] = updatedFilters[property].filter((v) => v !== val) as any;
      if (onChange) {
        onChange(updatedFilters);
      }
    }
  };

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Typography variant="bodySmall">Active</Typography>
      {value?.productId.map((c) => (
        <Chip
          key={`chip-productId-${c}`}
          label={`Product ID: ${c}`}
          variant="outlined"
          onDelete={handleDelete('productId', c)}
          deleteIcon={<Close />}
        />
      ))}
      {value?.productStatus.map((c) => (
        <Chip
          key={`chip-productStatus-${c}`}
          label={`Product Status: ${productStatusLabels[c]}`}
          variant="outlined"
          onDelete={handleDelete('productStatus', c)}
          deleteIcon={<Close />}
        />
      ))}
      {value?.enrolmentId.map((c) => (
        <Chip
          key={`chip-enrolmentId-${c}`}
          label={`Enrolment Id: ${c}`}
          variant="outlined"
          onDelete={handleDelete('enrolmentId', c)}
          deleteIcon={<Close />}
        />
      ))}
      {value?.enrolmentStatus.map((c) => (
        <Chip
          key={`chip-enrolmentStatus-${c}`}
          label={`Enrolment Status: ${enrolmentStatusMapping[c]}`}
          variant="outlined"
          onDelete={handleDelete('enrolmentStatus', c)}
          deleteIcon={<Close />}
        />
      ))}
      {value?.studentStatus.map((c) => (
        <Chip
          key={`chip-studentStatus-${c}`}
          label={`Student Status: ${studentProfileStatusMapping[c]}`}
          variant="outlined"
          onDelete={handleDelete('studentStatus', c)}
          deleteIcon={<Close />}
        />
      ))}
      {value?.studentGrade.map((c) => (
        <Chip
          key={`chip-studentGrade-${c}`}
          label={`Student Grade: ${studentGradeLookup[c]}`}
          variant="outlined"
          onDelete={handleDelete('studentGrade', c)}
          deleteIcon={<Close />}
        />
      ))}
      {value?.studentIndicator.map((c) => (
        <Chip
          key={`chip-studentIndicator-${c}`}
          label={`Student Indicator: ${studentSpecialIndicatorMapping[c]}`}
          variant="outlined"
          onDelete={handleDelete('studentIndicator', c)}
          deleteIcon={<Close />}
        />
      ))}
    </Stack>
  );
};

const SummaryFilters = (props: FilterProps) => {
  const { value, onChange, locationId } = props;
  const filtersSelected = Object.values(value).some((arr) => arr.length > 0);
  return (
    <Stack gap={3}>
      <FiltersContainer value={value} onChange={onChange} locationId={locationId} />
      {filtersSelected ? <ActiveFiltersContainer value={value} onChange={onChange} locationId={locationId} /> : null}
    </Stack>
  );
};

export default SummaryFilters;
