import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useState } from 'react';
import { TABLE_ROW_HOVER_COLOUR } from '@hoot/common/constants';
import useGetMetabaseReports, { MetabaseReportResponse } from '@hoot/hooks/api/reporting/useGetMetabaseReports';
import { MyLink } from '@hoot/pages/live-lessons';
import { routes } from '@hoot/routes/routes';

interface ReportTableRowData {
  reportName: string;
  owner: string;
  linkTo: string;
}

const ReportTableRow = (props: ReportTableRowData) => {
  const { reportName, owner, linkTo } = props;
  return (
    <TableRow
      key={reportName}
      hover
      sx={{
        boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
        '&.MuiTableRow-root:hover': {
          backgroundColor: TABLE_ROW_HOVER_COLOUR,
        },
      }}
    >
      <TableCell>
        <Grid container alignItems="center">
          <Grid item>
            <MyLink to={linkTo} sx={{ '&:hover': { color: '#1976D2' }, textDecoration: 'none' }}>
              <strong>{reportName}</strong>
            </MyLink>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>{owner}</TableCell>
    </TableRow>
  );
};

const ReportingTable = () => {
  const { data } = useGetMetabaseReports();
  const [isOrderAsc, setIsOrderAsc] = useState<boolean>(true);
  const reportingTableRowData: MetabaseReportResponse[] = data ?? [];

  const handleSortColumn = () => {
    reportingTableRowData.reverse();
    // note: without state change below we would need to use .slice() on set above to allocate new memory and trigger rerender
    setIsOrderAsc(!isOrderAsc);
  };

  const SortOrderIcon = () =>
    isOrderAsc ? <ArrowUpward style={{ transform: 'scale(0.8)' }} /> : <ArrowDownward style={{ transform: 'scale(0.8)' }} />;

  return (
    <Grid container sx={{ marginTop: '20px' }}>
      <TableContainer style={{ maxHeight: 550 }}>
        <Table
          stickyHeader
          sx={{
            border: 'solid 1px rgba(224, 224, 224, 1)',
          }}
        >
          <TableHead
            sx={{
              '& .MuiTableCell-head': {
                backgroundColor: '#f0f0f4',
              },
            }}
          >
            <TableRow>
              <TableCell width="50%">
                <div style={{ fontWeight: 600, cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={handleSortColumn}>
                  <SortOrderIcon />
                  Report Name
                </div>
              </TableCell>
              <TableCell width="50%" sx={{ fontWeight: 600 }}>
                Owner
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportingTableRowData.map((rowData) => (
              <ReportTableRow
                key={rowData.id}
                reportName={rowData.reportName}
                owner={rowData.reportOwner}
                linkTo={routes.reporting.metabase.urlWithId(rowData.id)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default ReportingTable;
