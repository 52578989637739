import { Close, Search } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TablePagination,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { FormOption } from '@hoot/interfaces/form';
import { MembershipOption, membershipOptionsList } from '@hoot/interfaces/profile';
import { OrderBy } from '../../../../lessons/enums';
import { StudentDetailsForm } from '../form';

const StudentMembershipModal = (props: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  membershipOptions: MembershipOption[];
  setValue: UseFormSetValue<StudentDetailsForm>;
}) => {
  const { setOpen, open, membershipOptions, setValue } = props;
  const [selectedOptions, setSelectedOption] = useState<MembershipOption[]>(membershipOptions);
  const [currentOptions, setCurrentOptions] = React.useState<FormOption<MembershipOption>[]>(membershipOptionsList);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [searchText, setSearchText] = React.useState<string>('');
  const [order, setOrder] = React.useState<OrderBy>(OrderBy.Asc);

  useEffect(() => {
    if (searchText.trim() !== '') {
      const filteredOptions = membershipOptionsList.filter((o) => o.label.toLowerCase().includes(searchText.toLowerCase()));
      setCurrentOptions(filteredOptions);
      setPage(0);
      return;
    }
    setCurrentOptions(membershipOptionsList);
  }, [searchText]);

  const handleOnClick = (field: MembershipOption) => {
    const isSelected = selectedOptions.find((o) => o === field);
    if (isSelected) {
      setSelectedOption(selectedOptions.filter((o) => o !== field));
    } else {
      setSelectedOption([...selectedOptions, field]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSort = () => {
    if (order === OrderBy.Asc) {
      const sortedOptionsDesc = currentOptions.sort((a, b) => {
        return a > b ? 1 : -1;
      });
      setCurrentOptions(sortedOptionsDesc);
      setOrder(OrderBy.Desc);
    } else {
      const sortedOptionsAsc = currentOptions.sort((a, b) => {
        return a > b ? 1 : -1;
      });
      setCurrentOptions(sortedOptionsAsc);
      setOrder(OrderBy.Asc);
    }
  };

  const handleSave = () => {
    const sortedOptions = selectedOptions.sort((a, b) => {
      return a > b ? 1 : -1;
    });
    setValue('membershipOptions', sortedOptions, { shouldDirty: true });
    setOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSearchInputChanged: TextFieldProps['onChange'] = (event) => {
    const input = event.target.value;
    setSearchText(input);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper">
      <Stack sx={{ padding: '24px', position: 'relative' }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">Membership</Typography>
            <IconButton onClick={handleClose} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
              <Close />
            </IconButton>
          </Stack>
          <Stack sx={{ padding: '24px 0px 0px' }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Search"
              value={searchText}
              onChange={onSearchInputChanged}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment:
                  searchText?.length > 0 ? (
                    <InputAdornment position="end">
                      <IconButton aria-label="clear search text" onClick={handleClearSearch} edge="end">
                        <HighlightOffIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : undefined,
              }}
            />
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Stack sx={{ width: 400 }}>
            <Stack direction="row" alignItems="center" sx={{ height: '40px', backgroundColor: '#F6F7F8' }}>
              <Button
                type="button"
                sx={{
                  color: '#000',
                  fontWeight: 500,
                  fontSize: 12,
                  margin: '0 0 0 50px',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}
                endIcon={<UnfoldMoreIcon />}
                disableElevation
                disableRipple
                onClick={handleSort}
              >
                NAME
              </Button>
            </Stack>
            {currentOptions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((option) => {
              return (
                <Stack key={option.value}>
                  <Divider />
                  <Stack key={option.value} direction="row" gap={2} alignItems="center">
                    <Checkbox onChange={() => handleOnClick(option.value)} checked={!!selectedOptions?.includes(option.value)} />
                    <Typography>{option.label}</Typography>
                  </Stack>
                </Stack>
              );
            })}
            <Divider />
          </Stack>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={currentOptions?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ backgroundColor: '#F6F7F8' }}
          />
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button
              sx={{
                fontSize: 14,
                color: '#000',
              }}
              fullWidth
              size="medium"
              color="inherit"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                fontSize: 14,
              }}
              fullWidth
              size="medium"
              variant="contained"
              onClick={handleSave}
            >
              Apply
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default StudentMembershipModal;
