import {
  ScheduledLessonLanguage,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonType,
  SubRequired,
  lessonLanguagesLookup,
  lessonSubjectLookup,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { FilterAltOutlined } from '@mui/icons-material';
import {
  Badge,
  Box,
  Card,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { Stack } from '@mui/system';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import SearchTextField, { SearchTextFieldProps } from '@hoot/components/form/SearchTextField';
import useGetAccounts from '@hoot/hooks/api/common/useGetAccounts';
import useLiveLessonsQuery, {
  LiveLessonQueryRequest,
  LiveLessonResponse,
  LiveLessonSearchFieldOptions,
} from '@hoot/hooks/api/lessons/useLiveLessonsQuery';
import { useInterval } from '@hoot/hooks/useInterval';
import { FormOption } from '@hoot/interfaces/form';
import { removePrefix } from '@hoot/utils/stringUtils';
import { usePageTitle } from '../../hooks/usePageTitle';
import AttendanceTab from './AttendanceTab';
import LiveLessonFilters from './LiveLessonFilters';
import OutsideShiftTab from './OutsideShiftTab';
import SubRequiredTab from './SubRequiredTab';

const IGNORE_FILTERS = ['page', 'pageSize', 'query', 'lessonEndDateAfter', 'status', 'subRequired', 'querySearchFieldSelection', 'outsideShift'];

export type LIVE_LESSON_TABS = 'ATTENDANCE' | 'SUB_REQUIRED' | 'OUTSIDE_SHIFT';

type QueryKeys = keyof LiveLessonQueryRequest;

const searchFieldOptions: FormOption<LiveLessonSearchFieldOptions>[] = [
  { value: LiveLessonSearchFieldOptions.LessonNumber, label: 'Lesson Number' },
  { value: LiveLessonSearchFieldOptions.EnrolmentId, label: 'Enrolment Id' },
  { value: LiveLessonSearchFieldOptions.StudentNumber, label: 'Student Number' },
  { value: LiveLessonSearchFieldOptions.ParentNumber, label: 'Parent Number' },
  { value: LiveLessonSearchFieldOptions.ParentEmail, label: 'Parent Email' },
  { value: LiveLessonSearchFieldOptions.TeacherNumber, label: 'Teacher Number' },
  { value: LiveLessonSearchFieldOptions.TeacherEmail, label: 'Teacher Email' },
  { value: LiveLessonSearchFieldOptions.All, label: 'All Fields' },
];

const attendanceDefaultQuery: LiveLessonQueryRequest = {
  status: [ScheduledLessonStatus.OpenWaitingForTeacher, ScheduledLessonStatus.Open],
  querySearchFieldSelection: LiveLessonSearchFieldOptions.All,
  page: 1,
  pageSize: 100,
};

const subRequiredDefaultFilter: LiveLessonQueryRequest = {
  status: [ScheduledLessonStatus.Scheduled],
  querySearchFieldSelection: LiveLessonSearchFieldOptions.All,
  subRequired: SubRequired.Yes,
  page: 1,
  pageSize: 100,
};

const outsideShiftDefaultFilter: LiveLessonQueryRequest = {
  status: [ScheduledLessonStatus.Scheduled],
  ignoreTypes: [ScheduledLessonType.Interview],
  querySearchFieldSelection: LiveLessonSearchFieldOptions.All,
  subRequired: SubRequired.No,
  outsideShift: true,
  page: 1,
  pageSize: 100,
};

export const Header = styled('h1')({
  fontWeight: 800,
  fontSize: '30px',
  lineHeight: '160%',
  letterSpacing: '0.15px',
  textTransform: 'uppercase',
});

export const MyLink = styled(Link)({
  color: 'inherit',
  ':visited': {
    color: 'inherit',
  },
});

const FilterChips = (props: { setLessonQuery: React.Dispatch<React.SetStateAction<LiveLessonQueryRequest>>; badgesWithMultiSelectors: any[] }) => {
  const { badgesWithMultiSelectors, setLessonQuery } = props;
  const accounts = useGetAccounts(false);

  return (
    <Grid container item sx={{ marginLeft: '24px' }}>
      {badgesWithMultiSelectors.map((badge) => {
        let value;
        const [filterType, filterValue]: [QueryKeys, any] = badge;
        if (filterType === 'fromDate' || filterType === 'toDate') {
          value =
            filterType === 'fromDate' || filterType === 'toDate' ? DateTime.fromMillis(filterValue).toFormat('LLL dd, yyyy hh:mm a') : filterValue;
        } else if (filterType === 'accountId') {
          value = accounts.data?.find((a) => a.id === filterValue)?.name;
        } else if (filterType === 'subject') {
          value = lessonSubjectLookup[filterValue as ScheduledLessonSubject];
        } else if (filterType === 'language') {
          value = lessonLanguagesLookup[filterValue as ScheduledLessonLanguage];
        } else {
          value = filterValue;
        }

        return (
          <Chip
            key={`${filterType}-${filterValue}`}
            sx={{
              margin: '4px',
            }}
            label={`${capitalCase(filterType)}: ${value}`}
            onDelete={() => {
              setLessonQuery((q: LiveLessonQueryRequest) => ({
                ...q,
                [filterType]: Array.isArray(q[filterType]) ? (q[filterType] as any)!.filter((val: string) => val !== filterValue) : undefined,
              }));
            }}
          />
        );
      })}
    </Grid>
  );
};

function LiveLessons() {
  return (
    <Stack spacing="16px">
      <HeaderCard />
      <SearchCard />
    </Stack>
  );
}

function HeaderCard() {
  return (
    <Card sx={{ padding: '24px' }}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Typography variant="headlineLarge">Live Lessons</Typography>
      </Grid>
    </Card>
  );
}

const renderPaginationTotalCount = (props: { from: number; to: number; count: number }) => {
  const { from, to, count } = props;
  // NOTE: To improve performance, the live-lessons api will cap the lesson count returned
  //       If we receive a large number, show the user that it's a large number (but not the exact count).
  //       All lessons are still available to page through still, it's only the total count we display that is affected
  return `${from}–${to} of ${count > 2000 ? '2000+' : count}`;
};

const SearchCard = () => {
  usePageTitle('Live Lessons | Admin Portal');

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const params = useParams() as { type: string };
  const [searchText, setSearchText] = useState('');

  const [lessonCount, setLessonCount] = useState<number>(0);
  const [lessons, setLessons] = useState<LiveLessonResponse[]>([]);
  const [selectedTab, setSelectedTab] = useState<LIVE_LESSON_TABS>(() => {
    if (params.type === 'sub-required') {
      return 'SUB_REQUIRED';
    }
    if (params.type === 'outside-shift') {
      return 'OUTSIDE_SHIFT';
    }
    return 'ATTENDANCE';
  });

  const [lessonQuery, setLessonQuery] = useState<LiveLessonQueryRequest>(() => {
    if (params.type === 'sub-required') {
      return subRequiredDefaultFilter;
    }
    if (params.type === 'outside-shift') {
      return {
        ...outsideShiftDefaultFilter,
      };
    }
    return attendanceDefaultQuery;
  });

  const { isFetching, refetch } = useLiveLessonsQuery(
    { ...lessonQuery, query: removePrefix(lessonQuery.query) },
    {
      onSuccess: (response) => {
        setLessonCount(response.count);
        setLessons(response.lessons);
      },
    },
  );

  useEffect(() => {
    setLessons([]);
    setLessonCount(0);
    setSearchText('');
    switch (selectedTab) {
      case 'ATTENDANCE':
        setLessonQuery(attendanceDefaultQuery);
        break;
      case 'OUTSIDE_SHIFT':
        setLessonQuery({
          ...outsideShiftDefaultFilter,
          fromDate: DateTime.now().toMillis(),
        });
        break;
      case 'SUB_REQUIRED':
        setLessonQuery(subRequiredDefaultFilter);
        break;
    }
  }, [selectedTab]);

  useInterval(() => {
    refetch();
  }, 30000);

  const handleFilterClick = () => {
    setShowFilters(true);
  };

  const handleCloseDrawer = () => {
    setShowFilters(false);
  };

  const badges = Object.entries(lessonQuery).filter(([k, v]) => !IGNORE_FILTERS.includes(k) && v !== undefined) as [QueryKeys, any][];

  const badgesWithMultiSelectors = badges.reduce((acc: any[], badge) => {
    if (Array.isArray(badge[1])) return [...acc, ...badge[1].map((val: any) => [badge[0], val])];
    return [...acc, badge];
  }, []);

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchText(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (debouncedText) => {
    setLessonQuery((prevState) => ({
      ...prevState,
      page: 1,
      query: debouncedText,
    }));
  };

  const searchFieldOnChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLessonQuery((prevState) => ({
      ...prevState,
      page: 1,
      querySearchFieldSelection: value as LiveLessonSearchFieldOptions,
    }));
  };

  return (
    <Card sx={{ padding: '24px' }}>
      <Stack sx={{ mb: 3 }}>
        <Typography variant="titleLarge">Live Lessons</Typography>
      </Stack>

      <Stack direction="row" alignItems="center" gap={2}>
        <Box width={'250px'}>
          <FormControl fullWidth variant={'outlined'} size={'medium'}>
            <InputLabel id={'fieldSearchField'}>Search in</InputLabel>
            <Select
              labelId={'fieldSearchField'}
              label={'Search in'}
              value={lessonQuery.querySearchFieldSelection as any}
              onChange={searchFieldOnChange}
            >
              {searchFieldOptions.map((x) => (
                <MenuItem key={`fieldSearchField-${x.value}`} value={x.value}>
                  {x.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <SearchTextField
          searchInput={searchText}
          onSearchInputChanged={onSearchInputChanged}
          onSearchInputDebounced={onSearchInputDebounced}
          autoFocus
        />

        <Tooltip title={'Filter Results'}>
          <IconButton size="large" onClick={handleFilterClick} color={'primary'}>
            <Badge badgeContent={badgesWithMultiSelectors.length} color="primary">
              <FilterAltOutlined sx={{ cursor: 'pointer' }} />
            </Badge>
          </IconButton>
        </Tooltip>
      </Stack>

      <Box sx={{ marginY: '20px' }}>
        <FilterChips badgesWithMultiSelectors={badgesWithMultiSelectors} setLessonQuery={setLessonQuery} />
      </Box>

      <Box sx={{ paddingBottom: '24px' }}>
        <Tabs value={selectedTab} onChange={(event, tab: LIVE_LESSON_TABS) => setSelectedTab(tab)}>
          <Tab sx={{ width: '33%' }} label="ATTENDANCE" value="ATTENDANCE" />
          <Tab sx={{ width: '33%' }} label="OUTSIDE SHIFT" value="OUTSIDE_SHIFT" />
          <Tab sx={{ width: '33%' }} label="SUB REQUIRED" value="SUB_REQUIRED" />
        </Tabs>
      </Box>

      {selectedTab === 'ATTENDANCE' ? (
        <AttendanceTab
          isLoading={isFetching}
          lessons={lessons}
          lessonCount={lessonCount}
          lessonQuery={lessonQuery}
          setLessonQuery={setLessonQuery}
          renderPaginationTotalCount={renderPaginationTotalCount}
        />
      ) : null}

      {selectedTab === 'OUTSIDE_SHIFT' ? (
        <OutsideShiftTab
          isLoading={isFetching}
          lessons={lessons}
          lessonCount={lessonCount}
          lessonQuery={lessonQuery}
          setLessonQuery={setLessonQuery}
          renderPaginationTotalCount={renderPaginationTotalCount}
        />
      ) : null}

      {selectedTab === 'SUB_REQUIRED' ? (
        <SubRequiredTab
          isLoading={isFetching}
          lessons={lessons}
          lessonCount={lessonCount}
          lessonQuery={lessonQuery}
          setLessonQuery={setLessonQuery}
          renderPaginationTotalCount={renderPaginationTotalCount}
        />
      ) : null}

      {showFilters ? (
        <LiveLessonFilters tab={selectedTab} handleClose={handleCloseDrawer} open={showFilters} query={lessonQuery} setQuery={setLessonQuery} />
      ) : null}
    </Card>
  );
};

export default LiveLessons;
