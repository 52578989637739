import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { ShiftTimeSlot } from '../shift-interfaces';

interface ITimeSlotsTableRowProps {
  timeSlots: ShiftTimeSlot[];
}

const TimeSlotsTableRow = (props: ITimeSlotsTableRowProps) => {
  const { timeSlots } = props;
  return (
    <Stack direction="row" sx={{ background: '#E5E5E5', height: '65px', borderBottom: '1px solid #CAC4D0', position: 'sticky', top: 0, zIndex: 1 }}>
      {timeSlots.map((slot, idx) => (
        <Stack key={`${slot.start}-${idx}`} sx={{ width: '130px', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid #CAC4D0' }}>
          <Typography variant="bodyLarge">{DateTime.fromMillis(slot.start).toFormat('t')}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default TimeSlotsTableRow;
