import { AssessmentReportStatus } from '@hoot-reading/hoot-core/dist/enums/hoot-assessment/assessment-reporting';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import React, { Fragment, useState } from 'react';
import BasicAlertDialog from '@hoot/components/modals/BasicAlertDialog';
import { useAlert } from '@hoot/contexts/AlertContext';
import {
  StudentLibraryInstructionalFocusModule,
  StudentLibraryInstructionalFocusUnit,
} from '@hoot/hooks/api/student-hoot-assessments/useGetStudentInstructionalLibraryUnits';
import useSetOverriddenInstructionalUnits, {
  OverrideStudentInstructionalLibraryUnitsRequest,
} from '@hoot/hooks/api/student-hoot-assessments/useSetOverriddenInstructionalUnits';
import { UnifiedUnitIdentifier } from '@hoot/interfaces/assessments-enums';

export const unitStatusLookup: Record<AssessmentReportStatus, string> = {
  BYPASSED: 'Passed',
  MASTERED: 'Mastered',
  NEEDS_INSTRUCTION: 'Needs Instruction',
  NEEDS_REVIEW: 'Needs Review',
  NOT_STARTED: 'Not Started',
};

const notAssessedValues = [AssessmentReportStatus.NotStarted, AssessmentReportStatus.Bypassed];

const FiltersCard = (props: { filters: AssessmentReportStatus[]; setFilters: React.Dispatch<React.SetStateAction<AssessmentReportStatus[]>> }) => {
  const { filters, setFilters } = props;

  const handleFiltersChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const value = e.target.value as AssessmentReportStatus;
    setFilters(checked ? [...filters, value] : filters.filter((filter) => filter !== value));
  };

  const handleFilterNotAssessed = (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilters(checked ? [...filters, ...notAssessedValues] : filters.filter((filter) => !notAssessedValues.includes(filter)));
  };

  return (
    <Card sx={{ p: 3, pb: 0, backgroundColor: '#F2F2F2' }}>
      <CardHeader sx={{ p: 0, pb: 2 }} title={<Typography variant="titleMedium">Display Settings</Typography>} />
      <CardContent sx={{ p: 0 }}>
        <Stack direction="row" gap={3} justifyContent="flex-start">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleFiltersChange}
                  value={AssessmentReportStatus.Mastered}
                  checked={filters.includes(AssessmentReportStatus.Mastered)}
                  size="small"
                />
              }
              label="Show Mastered"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleFilterNotAssessed}
                  value={AssessmentReportStatus.NotStarted}
                  checked={filters.includes(AssessmentReportStatus.NotStarted)}
                  size="small"
                />
              }
              label="Show Not Assessed"
            />
          </FormGroup>
        </Stack>
      </CardContent>
    </Card>
  );
};

interface StudentUnitsInProgressProps {
  instructionalFocusModule: StudentLibraryInstructionalFocusModule[];
  studentId: string;
  refetchStudentLibraryInstructionalUnitsFn: () => void;
}

const StudentInstructionalLibraryUnits = (props: StudentUnitsInProgressProps) => {
  const { instructionalFocusModule, studentId, refetchStudentLibraryInstructionalUnitsFn } = props;
  const [selectedStatusFilters, setSelectedStatusFilters] = useState<AssessmentReportStatus[]>([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

  const inProgressUnitStatuses = [AssessmentReportStatus.NeedsInstruction, AssessmentReportStatus.NeedsReview];
  const overrideInstructionalUnits = useSetOverriddenInstructionalUnits(studentId);

  const currentOverriddenUnits = instructionalFocusModule
    .flatMap((m) => m.units.filter((u) => (u as StudentLibraryInstructionalFocusUnit).isOverriddenOntoInstructionalLibrary))
    .map((u) => {
      return {
        unitIdentifier: (u as StudentLibraryInstructionalFocusUnit).unitIdentifier,
        unitLabel: (u as StudentLibraryInstructionalFocusUnit).label,
      };
    });
  const [overriddenUnits, setOverrideUnits] = useState<{ unitIdentifier: UnifiedUnitIdentifier; unitLabel: string }[]>(currentOverriddenUnits);
  const { error, success } = useAlert();

  const changedOverriddenUnitsForDisplay = [
    ...currentOverriddenUnits.filter((u) => !overriddenUnits.map((u) => u.unitIdentifier).includes(u.unitIdentifier)),
    ...overriddenUnits.filter((u) => !currentOverriddenUnits.map((u) => u.unitIdentifier).includes(u.unitIdentifier)),
  ];

  const onCheckBoxClick = (unitIdentifier: UnifiedUnitIdentifier, unitLabel: string) => {
    if (overriddenUnits.findIndex((u) => u.unitIdentifier === unitIdentifier) >= 0) {
      setOverrideUnits((curr) => [...curr.filter((unit) => unit.unitIdentifier !== unitIdentifier)]);
    } else {
      setOverrideUnits((curr) => [...curr, { unitIdentifier: unitIdentifier, unitLabel: unitLabel }]);
    }
  };

  const onApplyClick = () => {
    setConfirmationModalOpen(true);
  };

  const onConfirmApply = () => {
    submitData();
    setConfirmationModalOpen(false);
  };

  const submitData = () => {
    const request: OverrideStudentInstructionalLibraryUnitsRequest = {
      unitIdentifiers: overriddenUnits.map((u) => u.unitIdentifier),
    };
    overrideInstructionalUnits.mutate(request, {
      onSuccess: () => {
        success(`Student instructional library updated!`);
        refetchStudentLibraryInstructionalUnitsFn();
      },
      onError: (err) => {
        error(err.message);
      },
    });
  };

  return (
    <Card sx={{ p: 2, backgroundColor: '#FAFAFA' }}>
      <CardContent sx={{ p: 0 }}>
        <Stack direction={'row'} justifyContent={'flex-end'} sx={{ mb: 2 }}>
          <Button onClick={onApplyClick} size="large" variant="contained" color="primary" disabled={changedOverriddenUnitsForDisplay.length === 0}>
            Apply
          </Button>
        </Stack>
        <FiltersCard filters={selectedStatusFilters} setFilters={setSelectedStatusFilters} />
        <Stack py={3}>
          {instructionalFocusModule.map((module) => (
            <Stack key={module.order} py={2}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant="titleMedium">
                  {module.label} ({capitalCase(module.status)})
                </Typography>
                <Typography variant="titleMedium">In Instructional Plan</Typography>
              </Stack>
              <Divider sx={{ borderColor: '#000' }} />
              {module.units
                .filter(
                  (unit) =>
                    [AssessmentReportStatus.NeedsReview, AssessmentReportStatus.NeedsInstruction, ...selectedStatusFilters].includes(unit.status) ||
                    overriddenUnits.findIndex((u) => u.unitIdentifier === unit.unitIdentifier) >= 0,
                )
                .map((unit) => (
                  <Fragment key={unit.order}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography variant="bodyMedium">{unit.label}</Typography>
                      <Box width={'150px'} display={'flex'} justifyContent={'center'}>
                        <Checkbox
                          onChange={() => onCheckBoxClick(unit.unitIdentifier, unit.label)}
                          disabled={inProgressUnitStatuses.includes(unit.status)}
                          checked={
                            inProgressUnitStatuses.includes(unit.status) ||
                            overriddenUnits.findIndex((u) => u.unitIdentifier === unit.unitIdentifier) >= 0
                          }
                          size="small"
                        />
                      </Box>
                    </Stack>
                    <Divider sx={{ borderColor: '#000' }} />
                  </Fragment>
                ))}
            </Stack>
          ))}
        </Stack>
      </CardContent>
      {confirmationModalOpen ? (
        <BasicAlertDialog
          show={confirmationModalOpen}
          title={'Apply Changes to Instructional Plan?'}
          content={
            <Stack gap={3}>
              <Typography>Are you sure you want to make changes to the following units on the student's instructional library?</Typography>
              <List sx={{ width: '100%', listStyleType: 'disc', pl: 4 }}>
                {changedOverriddenUnitsForDisplay.map((u) => (
                  <ListItem key={u.unitIdentifier} sx={{ display: 'list-item' }}>
                    {u.unitLabel}
                  </ListItem>
                ))}
              </List>
            </Stack>
          }
          primaryAction={{
            label: 'Confirm',
            onClick: onConfirmApply,
          }}
          secondaryAction={{
            label: 'Cancel',
            onClick: () => setConfirmationModalOpen(false),
          }}
          onDismiss={() => setConfirmationModalOpen(false)}
        />
      ) : null}
    </Card>
  );
};

export default StudentInstructionalLibraryUnits;
