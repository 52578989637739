import { Grid, styled } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import { timeOptions } from '../enums';

interface Slot {
  id?: string;
  startIndex: number;
  endIndex: number;
  isDeleted: boolean;
}

interface HighlightSlot {
  startIndex: number;
  endIndex: number;
  color: string;
}

export interface TimeBlock {
  id?: string;
  startTime: string;
  endTime: string;
  isDeleted?: boolean;
}

export interface HighlightTimeBlock {
  startTime: string;
  endTime: string;
  color: string;
}

const height = 20;

type Action = 'NONE' | 'SELECT' | 'MOVE' | 'RESIZE' | 'DELETE';

const Weekday = styled('div')({
  display: 'flex',
  border: 'solid 1px #cecece',
  flexDirection: 'column',
  userSelect: 'none',
});

const ResizeDown = styled('div')({
  cursor: 's-resize',
  height: 6,
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 10000,
});

const CloseButton = styled('div')({
  width: 16,
  height: 16,
  position: 'absolute',
  right: 4,
  top: 0,
  zIndex: 10000,
  cursor: 'pointer',
  backgroundColor: '#fff',
  borderRadius: 100,
});

const Timeslot = styled('div')({
  fontSize: 10,
  position: 'relative',
  textAlign: 'center',
});

const Highlight = styled('div')({
  backgroundColor: '#fcc',
  height: height,
});

const Available = styled('div')({
  position: 'relative',
  cursor: 'move',
  height: height,
});

const Unavailable = styled('div')({
  backgroundColor: '#efefef',
  height: height,
});

const TimeSelection = (props: {
  value?: TimeBlock[];
  highlighted?: HighlightTimeBlock[];
  onChange?: (timeBlocks: TimeBlock[]) => void;
  manual: boolean;
  selectionColor: string;
}) => {
  const { highlighted, value, onChange, selectionColor } = props;
  const actionState = useRef<Action>('NONE');
  const originRow = useRef<number>();
  const [selectedTimeBlock, setSelectedTimeBlock] = useState<Slot>();
  const [selected, setSelected] = useState<{
    originStart: number;
    originEnd: number;
    start: number;
    end: number;
  }>();

  const convertValueBlockToSlot = useCallback(() => {
    if (!value) {
      return [];
    }

    return value.map<Slot>((v) => {
      const startIndex = timeOptions.findIndex((x) => x === v.startTime);
      const endIndex = timeOptions.findIndex((x) => x === v.endTime);
      return {
        id: v.id,
        startIndex,
        endIndex: endIndex === 0 ? timeOptions.length - 1 : endIndex - 1,
        isDeleted: v.isDeleted || false,
      };
    });
  }, [value]);

  const [timeBlocks, setTimeBlocks] = useState<Slot[]>(convertValueBlockToSlot);

  useEffect(() => {
    if (props.manual) {
      setTimeBlocks(convertValueBlockToSlot);
    }
  }, [value, props.manual, convertValueBlockToSlot]);

  const generateHighlightTimeBlocks = () => {
    if (!highlighted) {
      return [];
    }

    return highlighted.map<HighlightSlot>((v) => {
      const startIndex = timeOptions.findIndex((x) => x === v.startTime);
      const endIndex = timeOptions.findIndex((x) => x === v.endTime);

      return {
        startIndex,
        endIndex: endIndex === 0 ? timeOptions.length - 1 : endIndex - 1,
        color: v.color,
      };
    });
  };

  useEffect(() => {
    if (onChange) {
      const result = timeBlocks.map<TimeBlock>((tb) => {
        const endIndex = tb.endIndex + 1 >= timeOptions.length ? 0 : tb.endIndex + 1;

        return {
          id: tb.id,
          startTime: timeOptions[tb.startIndex],
          endTime: timeOptions[endIndex],
          isDeleted: tb.isDeleted || false,
        };
      });
      onChange(result);
    }
  }, [onChange, timeBlocks]);

  useEffect(() => {
    const unselectTimeBlock = () => {
      actionState.current = 'NONE';
      originRow.current = undefined;
      setSelectedTimeBlock(undefined);
    };

    window.addEventListener('mouseup', unselectTimeBlock);
    return () => {
      window.removeEventListener('mouseup', unselectTimeBlock);
    };
  }, []);

  const detectCollision = (startRow: number, endRow: number) => {
    for (let x = startRow; x <= endRow; x++) {
      const hasCollision = timeBlocks
        .filter((tb) => !tb.isDeleted)
        .some((tb) => tb.id !== selectedTimeBlock?.id && x >= tb.startIndex && x <= tb.endIndex);
      if (hasCollision) {
        return true;
      }
    }
    return false;
  };

  const move = (row: number) => {
    setTimeBlocks((currentState) => {
      const results = [];
      for (const stateRow of currentState) {
        if (stateRow.id !== selectedTimeBlock?.id) {
          results.push({ ...stateRow });
        } else {
          const startIndex = Math.max(0, (selectedTimeBlock?.startIndex || 0) + (row - (originRow.current || 0)));
          const endIndex = Math.min((selectedTimeBlock?.endIndex || 0) + (row - (originRow.current || 0)), timeOptions.length);
          const hasCollision = detectCollision(startIndex, endIndex);
          const updatedState: Slot = hasCollision ? { ...stateRow } : { ...stateRow, startIndex: startIndex, endIndex: endIndex };
          results.push(updatedState);
        }
      }
      return results;
    });
  };

  const resize = (row: number) => {
    setTimeBlocks((currentState) => {
      const results: Slot[] = [];
      for (const stateRow of currentState) {
        if (stateRow.id !== selectedTimeBlock?.id) {
          results.push({ ...stateRow });
        } else {
          const resizedState: Slot = detectCollision(stateRow.startIndex, row) ? stateRow : { ...stateRow, endIndex: row };
          results.push(resizedState);
        }
      }
      return results;
    });
  };

  const select = (row: number) => {
    setSelected((currentSelected) => {
      if (currentSelected) {
        const [start, end] = currentSelected.originStart < row ? [currentSelected.originStart, row] : [row, currentSelected.originEnd];
        const hasCollision = detectCollision(start, end);
        if (hasCollision) {
          return currentSelected;
        } else {
          return {
            start,
            end,
            originStart: currentSelected.originStart,
            originEnd: currentSelected.originEnd,
          };
        }
      }
    });
  };

  const deleteTimeBlock = (timeBlock: Slot) => {
    setTimeBlocks((currentTimeBlocks) => currentTimeBlocks.map((tb) => (tb.id !== timeBlock.id ? tb : { ...tb, isDeleted: true })));
  };

  const handleOnMouseDown = (row: number, action: Action, timeBlock?: Slot) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    actionState.current = action;
    originRow.current = row;

    if (action === 'SELECT') {
      setSelected({ originStart: row, originEnd: row, start: row, end: row });
    } else if (action === 'MOVE' || action === 'RESIZE') {
      setSelectedTimeBlock(timeBlock);
    } else if (action === 'DELETE') {
      if (timeBlock) {
        deleteTimeBlock(timeBlock);
      }
    }
  };

  const handleOnMouseEnter = (row: number) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (actionState.current) {
      switch (actionState.current) {
        case 'MOVE':
          move(row);
          break;
        case 'RESIZE':
          resize(row);
          break;
        case 'SELECT':
          select(row);
          break;
      }
    }
  };

  const handleOnMouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    actionState.current = 'NONE';

    if (selected) {
      setSelected(undefined);
    }
  };

  const handleOnMouseUp = () => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (actionState.current === 'SELECT') {
      if (selected) {
        setTimeBlocks((currentTimeBlocks) => [
          ...currentTimeBlocks,
          {
            id: uuid4(),
            startIndex: selected.start,
            endIndex: selected.end,
            isNew: true,
            isDeleted: false,
          },
        ]);
      }
      setSelected(undefined);
    }
    actionState.current = 'NONE';

    if (selectedTimeBlock) {
      setSelectedTimeBlock(undefined);
    }
  };

  const highlightTimeBlocks = generateHighlightTimeBlocks();

  const Slot = (props: { row: number }) => {
    const { row } = props;
    let label = '';
    const timeBlock = timeBlocks.filter((tb) => !tb.isDeleted).find((tb) => row >= tb.startIndex && row <= tb.endIndex);
    if (timeBlock) {
      if (timeBlock.startIndex === row) {
        const endIndex = timeBlock.endIndex + 1 >= timeOptions.length ? 0 : timeBlock.endIndex + 1;
        label = `${timeOptions[timeBlock.startIndex]} - ${timeOptions[endIndex]}`;
      }
      return (
        <Available
          onMouseDown={handleOnMouseDown(row, 'MOVE', timeBlock)}
          title={timeOptions[row]}
          sx={{
            backgroundColor: selectedTimeBlock?.id === timeBlock.id ? '#748fff' : selectionColor,
            borderTop: row === timeBlock.startIndex ? 'solid 1px #999' : undefined,
            borderBottom: row === timeBlock.endIndex ? 'solid 1px #999' : undefined,
          }}
        >
          <Grid container alignItems="center" justifyContent="center">
            {timeBlock.startIndex === row ? label : null}
            {timeBlock.startIndex === row ? <CloseButton onMouseDown={handleOnMouseDown(row, 'DELETE', timeBlock)}>X</CloseButton> : null}
          </Grid>
          {row === timeBlock.endIndex ? <ResizeDown onMouseDown={handleOnMouseDown(row, 'RESIZE', timeBlock)} /> : null}
        </Available>
      );
    } else if (selected && row >= selected.start && row <= selected.end) {
      let label = '';
      if (selected.start === row) {
        const endIndex = selected.end + 1 >= timeOptions.length ? 0 : selected.end + 1;
        label = `${timeOptions[selected.start]} - ${timeOptions[endIndex]}`;
      }
      return (
        <Highlight
          title={timeOptions[row]}
          onMouseUp={handleOnMouseUp()}
          sx={{
            borderBottom: row !== 0 ? (row % 2 ? 'solid 1px #ececec' : 'solid 1px #cecece') : 'solid 0px transparent',
          }}
        >
          {label}
        </Highlight>
      );
    } else {
      const highlight = highlightTimeBlocks.find((tb) => row >= tb.startIndex && row <= tb.endIndex);

      return (
        <Unavailable
          onMouseDown={handleOnMouseDown(row, 'SELECT')}
          title={timeOptions[row]}
          sx={{
            backgroundColor: highlight ? highlight.color : null,
            borderTop: row !== 0 ? (row % 2 ? 'solid 1px #dedede' : 'solid 1px #cecece') : 'solid 0px transparent',
          }}
        />
      );
    }
  };

  return (
    <Weekday onMouseLeave={handleOnMouseLeave}>
      {timeOptions.map((to, row) => (
        <Timeslot key={to} onMouseEnter={handleOnMouseEnter(row)} onMouseUp={handleOnMouseUp()}>
          <Slot row={row} />
        </Timeslot>
      ))}
    </Weekday>
  );
};

export default TimeSelection;
