import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { ChevronLeftOutlined } from '@mui/icons-material';
import { Breadcrumbs, Button, Card, CardContent, Chip, Grid, Tabs, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import FullCircleIcon from '@hoot/components/icons/FullCircle';
import Clipboard from '@hoot/components/ui/Clipboard';
import { LinkTab } from '@hoot/components/ui/LinkTab';
import { LocationEdlinkDataResponse } from '@hoot/hooks/api/hfs/useGetDistrictSchool';
import { useRouteMatch } from '@hoot/hooks/useRouteMatch';
import { getDistrictSchoolLabel } from './LocationPage';

export function DistrictSchoolEdlinkStatusChip(props: { edlinkData: LocationEdlinkDataResponse | null }) {
  const edlinkData = props.edlinkData;
  const handleClick = () => {
    if (!edlinkData) {
      return;
    }

    const url =
      edlinkData.locationType === LocationType.School
        ? `https://ed.link/Hoot/integrations/${edlinkData?.integrationId}/schools#school/${edlinkData.edlinkLocationId}`
        : `https://ed.link/Hoot/integrations/${edlinkData?.integrationId}/districts#district/${edlinkData.edlinkLocationId}`;

    window.open(url, '_blank');
  };

  if (!edlinkData) {
    return <></>;
  }

  return (
    <Chip
      sx={{
        paddingLeft: '0px',
        '& > svg': {
          width: '8px',
          height: '8px',
        },
      }}
      icon={<FullCircleIcon fill="#2E7D32" />}
      size="small"
      clickable={true}
      onClick={handleClick}
      label={`Edlink ${edlinkData.locationType === LocationType.School ? 'School' : 'District'}:\
       ${edlinkData.edlinkLocationName} - ${edlinkData.integrationName}`}
    />
  );
}

interface Props {
  isEditMode: boolean;
  name: string;
  isDirty: boolean;
  friendlyId?: string;
  locationType?: LocationType;
  edlinkData: LocationEdlinkDataResponse | null;
  formRef: React.RefObject<HTMLFormElement>;
  isBillableEntity?: boolean;
}

const DistrictsAndSchoolsHeader = (props: Props) => {
  const { isEditMode, friendlyId, isDirty, name, locationType, edlinkData, formRef, isBillableEntity } = props;

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const routeMatch = useRouteMatch([
    '/districts-schools/:locationId/details',
    '/districts-schools/:locationId/prod-enrol',
    '/districts-schools/:locationId/students',
    '/districts-schools/:locationId/summary',
  ]);

  const currentTab = routeMatch?.pattern?.path;

  const label = locationType ? getDistrictSchoolLabel(locationType) : '';

  const handleSaveClick = () => {
    if (formRef && formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  return (
    <Grid container item xs={12}>
      <Card
        sx={{
          width: 1,
        }}
      >
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid container alignItems="center" justifyContent="flex-start" item xs>
              <Button type="button" color="inherit" variant="contained" startIcon={<ChevronLeftOutlined />} onClick={handleBackClick} size="large">
                Back
              </Button>
              <Grid marginLeft="24px" item>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link style={{ textDecoration: 'none' }} to="/districts-schools">
                    <Typography sx={{ color: '#000000' }} component="h6" variant="bodySmall">
                      Districts & Schools
                    </Typography>
                  </Link>
                  <Typography color="text.primary"></Typography>
                </Breadcrumbs>
                <Typography component="h2" variant="headlineLarge">
                  {name}
                </Typography>
                {isEditMode ? (
                  <>
                    <DistrictSchoolEdlinkStatusChip edlinkData={edlinkData} />
                    <Grid container item alignItems="center">
                      <Typography component="h6" variant="bodySmall">
                        {locationType === LocationType.School ? 'School' : 'District'}: {friendlyId}
                      </Typography>
                      {friendlyId ? <Clipboard sx={{ marginLeft: '16px' }} toCopy={friendlyId} iconProps={{ fontSize: 'small' }} /> : null}
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end" item xs={1}>
              <Button
                type="button"
                disabled={!isDirty}
                sx={{ marginLeft: '8px' }}
                color="primary"
                variant="contained"
                size="large"
                onClick={handleSaveClick}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          {isEditMode ? (
            <Grid container item xs={12} sx={{ marginTop: '24px' }}>
              <Tabs value={currentTab} variant="fullWidth" sx={{ width: '100%', marginBottom: '-24px' }}>
                <LinkTab label={`${label} Details`} value="/districts-schools/:locationId/details" to="../details" LinkComponent={Link} />
                <LinkTab label="Products & Enrolments" value="/districts-schools/:locationId/prod-enrol" to="../prod-enrol" LinkComponent={Link} />
                <LinkTab label="Students" value="/districts-schools/:locationId/students" to="../students" LinkComponent={Link} />
                {isBillableEntity ? (
                  <LinkTab label={'Summary'} value="/districts-schools/:locationId/summary" to="../summary" LinkComponent={Link} replace />
                ) : null}
              </Tabs>
            </Grid>
          ) : null}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DistrictsAndSchoolsHeader;
