import React from 'react';
import { FEATURE_FLAG_SYNC_PERIOD } from '../common/constants';
import useGetFeatureFlags from '../hooks/api/feature-flags/useGetFeatureFlags';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { FeatureFlag } from '../interfaces/featureFlag';

interface Props {
  children: React.ReactNode;
}

interface Values {
  isFeatureEnabled: (featureName: string) => boolean;
}

const FeatureFlagContext = React.createContext<Values>(undefined!);

export const FeatureFlagProvider = (props: Props) => {
  const { children } = props;
  const [features, setFeatures] = useLocalStorage<FeatureFlag[]>('FEATURE_FLAGS', []);

  useGetFeatureFlags({
    refetchInterval: FEATURE_FLAG_SYNC_PERIOD,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    onSuccess: (response) => setFeatures(response.features),
  });

  const isFeatureEnabled = (featureName: string) => {
    const feature = features.find((f) => f.name === featureName);

    if (!feature) {
      return false;
    }

    return feature.isEnabled;
  };

  return <FeatureFlagContext.Provider value={{ isFeatureEnabled }}>{children}</FeatureFlagContext.Provider>;
};

export const useFeatureFlags = () => {
  const context = React.useContext(FeatureFlagContext);

  if (context === undefined) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider');
  }

  return context;
};
