import { Button, FormHelperText, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { TextField } from '@hoot/components/form/TextField';
import { LessonCommentResponse, useCreateLessonComment, useGetLessonComments } from '../../../hooks/api/lessons/useGetLesson';
import { QueryKey } from '../../../hooks/api/queryKeys';

interface Form {
  comment: string;
}

interface Props {
  lessonId: string;
}

const EditLessonComments = (props: Props) => {
  const { lessonId } = props;
  const [comments, setComments] = useState<LessonCommentResponse[]>();

  useGetLessonComments(lessonId, {
    onSuccess: (data) => setComments(data),
  });

  const queryClient = useQueryClient();

  const lessonCommentMutation = useCreateLessonComment(lessonId, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.LessonComments);
      queryClient.invalidateQueries(QueryKey.Lesson);
      reset();
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: {
      comment: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = (data: Form) => {
    const request = {
      comment: data.comment.trim(),
    };

    lessonCommentMutation.mutate(request);
  };

  const Comments = () => {
    if (!comments || comments.length === 0) {
      return (
        <Typography align="center" variant="h5">
          There are currently no comments
        </Typography>
      );
    }

    return (
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell width="15%">Date</StyledTableCell>
            <StyledTableCell width="15%">Time</StyledTableCell>
            <StyledTableCell width="15%">User</StyledTableCell>
            <StyledTableCell width="65%">Comment</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {comments.map((comment) => (
            <StyledTableRow key={comment.id} hover>
              <StyledTableCell>{DateTime.fromMillis(comment.createdTime).toFormat('LLL dd, yyyy')}</StyledTableCell>
              <StyledTableCell>{DateTime.fromMillis(comment.createdTime).toFormat('hh:mm a')}</StyledTableCell>
              <StyledTableCell>{comment.user}</StyledTableCell>
              <StyledTableCell>{comment.comment}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid alignItems="center" container direction="column">
        <Grid container item xs={12} style={{ marginBottom: 32 }}>
          <Comments />
        </Grid>
        <Grid item container xs={6} justifyContent="flex-start">
          <TextField
            fullWidth
            control={control}
            name="comment"
            maxRows={8}
            multiline
            variant="outlined"
            label="Comment"
            InputLabelProps={{
              shrink: true,
            }}
            rules={{
              validate: (val) => val.trim().length > 4 || 'Comments must be at least 5 characters long.',
            }}
          />
          {errors.comment ? (
            <FormHelperText style={{}} error={true}>
              {errors.comment.message}
            </FormHelperText>
          ) : null}
          <Button fullWidth color="primary" type="submit" variant="contained" style={{ marginTop: 32, marginLeft: 'auto', maxWidth: 122 }}>
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    fontWeight: 900,
  },
  body: {
    fontSize: 14,
    cursor: 'pointer',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {},
  },
}))(TableRow);

export default EditLessonComments;
