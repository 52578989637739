import { Disability, FoodServiceProgram, Race, SpecialIndicatorGrade } from '@hoot-reading/hoot-core/dist/enums/student-special-indicators';
import axios from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';
import { UseMutationOptions } from '@hoot/interfaces/axios-options';

export interface StudentSpecialIndicatorDataSaveRequest {
  specialIndicatorGrade: SpecialIndicatorGrade | null;
  race: Race | null;
  primaryLanguageId: string | null;
  ell: boolean;
  hispanicLatino: boolean;
  homeless: boolean;
  fosterCare: boolean;
  newComer: boolean;
  migrant: boolean;
  specialEducation: boolean;
  iep: boolean;
  '504': boolean;
  disability: Disability | null;
  foodServiceProgram: FoodServiceProgram | null;
  sed: boolean;
  publicAssistance: boolean;
}

async function createOrUpdateSpecialIndicatorData(studentProfileId: string, payload: StudentSpecialIndicatorDataSaveRequest): Promise<void> {
  const { data } = await axios.post<void>(`${config.apiUrl}/admin/student-profile/${studentProfileId}/special-indicator-data`, payload);
  return data;
}

export default function useCreateOrUpdateStudentSpecialIndicatorData(
  studentProfileId: string,
  options?: UseMutationOptions<StudentSpecialIndicatorDataSaveRequest, void>,
) {
  return useMutation((payload: StudentSpecialIndicatorDataSaveRequest) => createOrUpdateSpecialIndicatorData(studentProfileId, payload), options);
}
