import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { Account } from '@hoot/interfaces/parent';
import { QueryKey } from '../queryKeys';

export interface StatusesAndPermissions {
  accountId: string | null;
  userAccountStatus: boolean;
  userAccountCreatedAt: number | null;
  parentProfileStatus: boolean;
  userAccountIsEnabledLastUpdatedAt: number | null;
}

export interface ParentPersonalInformation {
  firstName: string | null;
  lastName: string | null;
  emailAddress: string | null;
  phoneNumber: string | null;
  streetAddress: string | null;
  country: string | null;
  city: string | null;
  provinceState: string | null;
  postalZipCode: string | null;
  timeZone: string | null;
  prefixedNumber: string;
}

export interface ParentStudentProfile {
  id: string;
  name: string;
  status: StudentStatus;
  number: string;
  prefixedNumbed: string;
}

export interface ParentProfileResponse {
  customerAccountId: string;
  parentNumber: number;
  parentPrefixedNumber: string;
  statusesAndPermissions: StatusesAndPermissions;
  referralCode: string | null;
  personalInformation: ParentPersonalInformation;
  students: ParentStudentProfile[];
  account: Account | null;
  hubspotId: number | null;
  zendeskId: number | null;
}

const getParentProfile = async (userId: string) => {
  const { data } = await axios.get<ParentProfileResponse>(`${config.apiUrl}/admin/parent/${userId}`);
  return data;
};

export default function useGetParentProfile(
  userId: string,
  options?: Omit<UseQueryOptions<ParentProfileResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetParentProfile, userId], () => getParentProfile(userId), options);
}
