import { ParentProfilesQuerySortKeyEnum } from '@hoot-reading/hoot-core/dist/enums/user/parent-profiles-query-sort-key.enum';
import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { SortableQuery } from '@hoot/interfaces/order-by';
import { PaginatableQuery } from '@hoot/interfaces/pagination';
import { Account } from '@hoot/interfaces/parent';
import { QueryKey } from '../queryKeys';

export interface ParentProfilesQuery extends PaginatableQuery, SortableQuery<ParentProfilesQuerySortKeyEnum> {
  searchText?: string;
  countries?: string[];
}

export interface ParentProfileResponse {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
  country: string | null;
  timeZone: string;
  account: Account | null;
  prefixedNumber: string;
}

export interface ParentProfilesQueryResponse {
  count: number;
  page: number;
  pageSize: number;
  parentProfiles: ParentProfileResponse[];
}

const getParentProfiles = async (searchParams: ParentProfilesQuery): Promise<ParentProfilesQueryResponse> => {
  const { data } = await axios.post<ParentProfilesQueryResponse>(`${config.apiUrl}/admin/parent/search`, searchParams);
  return data;
};

export default function useGetParentProfiles(searchParams: ParentProfilesQuery) {
  return useQuery([QueryKey.GetParentProfiles, searchParams], () => getParentProfiles(searchParams));
}
