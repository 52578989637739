import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { CommentSubmission } from '@hoot/interfaces/history';

async function createDistrictRepComment(districtRepId: string, payload: CommentSubmission) {
  await axios.post(`${config.apiUrl}/admin/district-representative/${districtRepId}/comments`, payload);
}

export default function useCreateDistrictRepComment(
  districtRepId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, CommentSubmission>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((payload: CommentSubmission) => createDistrictRepComment(districtRepId, payload), options);
}
