import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { useCallback, useState } from 'react';
import { BlockUtilizationBlock, BlockUtilizationLesson, BlockUtilizationTeacher } from '@hoot/hooks/api/blocks/useGetBlockUtilization';
import { StatusColours } from '@hoot/pages/users/components/user-schedule/weekly-schedule/enums';
import { lessonColour } from '@hoot/pages/users/components/user-schedule/weekly-schedule/utils';
import { ITeacherTableRowsProps, ITeacherTimeSlot } from '../block-interfaces';
import { blockEnds, blockStarts, lessonHoverColor, timeSlotBlocks, timeSlotLessons } from '../blocks-utils';
import BlockDetailsDialog from './dialogs/BlockDetailsDialog';
import LessonDetailsDialog from './dialogs/LessonDetailsDialog';
import TimeSlot from './timeslots/TimeSlot';

const TeacherTableRows = (props: ITeacherTableRowsProps) => {
  const { teacherData, timeSlots, showCreateBlockWizard, setShowCreateBlockWizard } = props;
  const [selectedTimeSlotBlock, setSelectedTimeSlotBlock] = useState<BlockUtilizationBlock | undefined>(undefined);
  const [selectedTimeSlotLessons, setSelectedTimeSlotLessons] = useState<BlockUtilizationLesson[]>([]);

  const handleLessonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, lessons: BlockUtilizationLesson[]) => {
    event.stopPropagation();
    setSelectedTimeSlotLessons(lessons);
  };

  const handleCloseBlockDetails = () => {
    setSelectedTimeSlotBlock(undefined);
  };

  const handleCloseLessonDetails = () => {
    setSelectedTimeSlotLessons([]);
  };

  const handleLessonColour = (lessons: BlockUtilizationLesson[]): StatusColours => {
    if (lessons.length === 1) {
      return lessonColour(lessons[0].status);
    } else {
      const atLeastOneLessonScheduled = lessons.some((lesson) => lesson.status === ScheduledLessonStatus.Scheduled);
      const atLeastOneLessonCompleted = lessons.some((lesson) =>
        [ScheduledLessonStatus.CompletedSuccessfully, ScheduledLessonStatus.CompletedUnsuccessfully].includes(lesson.status),
      );
      if (atLeastOneLessonScheduled) {
        return StatusColours.Scheduled;
      } else if (atLeastOneLessonCompleted) {
        return StatusColours.Completed;
      } else {
        return StatusColours.Cancelled;
      }
    }
  };

  const teacherTableData = useCallback(
    (teacher: BlockUtilizationTeacher) => {
      const rows: ITeacherTimeSlot[] = [];

      for (const timeSlot of timeSlots) {
        const lessons = timeSlotLessons(teacher.lessons, timeSlot);
        const block = timeSlotBlocks(teacher.blocks, timeSlot);
        const blockStartsInTimeSlot = blockStarts(teacher.blocks, timeSlot);
        const blockEndsInTimeSlot = blockEnds(teacher.blocks, timeSlot);
        const lessonColor = handleLessonColour(lessons);

        const t: ITeacherTimeSlot = {
          timeSlot: timeSlot,
          teacher: teacher,
          lessons: lessons,
          block: block[0],
          blockStarts: blockStartsInTimeSlot,
          blockEnds: blockEndsInTimeSlot,
          lessonColor: lessonColor,
        };
        rows.push(t);
      }
      return rows;
    },
    [timeSlots],
  );

  return (
    <>
      <Stack>
        {teacherData
          ? teacherData.teachers.map((d) => (
              <Stack key={d.id} direction="row" sx={{ height: '65px' }}>
                {teacherTableData(d).map((t) => (
                  <TimeSlot
                    key={d.id}
                    timeSlot={t.timeSlot}
                    teacher={t.teacher}
                    hasLesson={!!t.lessons.length}
                    block={t.block}
                    blockStarts={t.blockStarts}
                    blockEnds={t.blockEnds}
                    onBlockClick={setSelectedTimeSlotBlock}
                    showCreateBlockWizard={showCreateBlockWizard}
                    setShowCreateBlockWizard={setShowCreateBlockWizard}
                  >
                    {!!t.lessons.length ? (
                      <Button
                        variant="contained"
                        sx={{
                          height: '100%',
                          width: '100%',
                          borderRadius: '8px',
                          background: t.lessonColor,
                          '&:hover': { background: lessonHoverColor(t.lessonColor) },
                          ...(!t.block ? { marginY: 1 } : {}),
                        }}
                        onClick={(event) => handleLessonClick(event, t.lessons)}
                      >
                        {t.lessons.length > 1 ? t.lessons.length : null}
                      </Button>
                    ) : null}
                  </TimeSlot>
                ))}
              </Stack>
            ))
          : Array(10)
              .fill(0)
              .map((_a, idx) => (
                <Stack key={idx} direction="row" sx={{ height: '65px' }}>
                  {timeSlots.map((t, idx) => (
                    <TimeSlot key={`${t.start}-${idx}`} timeSlot={t} />
                  ))}
                </Stack>
              ))}
      </Stack>
      <BlockDetailsDialog open={!!selectedTimeSlotBlock} onClose={handleCloseBlockDetails} block={selectedTimeSlotBlock} />
      <LessonDetailsDialog open={!!selectedTimeSlotLessons.length} onClose={handleCloseLessonDetails} lessons={selectedTimeSlotLessons} />
    </>
  );
};

export default TeacherTableRows;
