import { ArrowDropDown } from '@mui/icons-material';
import { AppBar, Box, Button, CssBaseline, Menu, MenuItem, Toolbar, styled } from '@mui/material';
import React from 'react';
import { NavLink, NavLinkProps, matchPath, useLocation } from 'react-router-dom';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import { FeatureFlags } from '../../common/featureFlags';
import HootReadingAdminLogo from '../../components/icons/HootReadingAdminLogo';
import { useFeatureFlags } from '../../contexts/FeatureFlagContext';
import { routes } from '../../routes/routes';
import UserDropdown from './UserDropdown';

interface Props {
  children: React.ReactNode;
}

const MenuLink = styled(NavLink)({
  textDecoration: 'none',
  marginLeft: '12px',
  marginRight: '12px',
  '&:first-of-type': {
    marginLeft: '0px',
  },
  '&:last-child': {
    marginRight: '0px',
  },
});

interface HootNavLinkProps extends NavLinkProps, React.RefAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
}

export const HootNavLink = (props: HootNavLinkProps) => {
  const { children, ...rest } = props;
  return (
    <MenuLink {...rest}>
      {({ isActive }) => (
        <Button
          sx={{
            textTransform: 'capitalize',
            color: isActive ? '#1976D2' : '#49454F',
            '&:hover': {
              color: '#1976D2',
              backgroundColor: 'transparent',
            },
            '&:active': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <Box
            sx={{
              '&:after': isActive
                ? {
                    content: '""',
                    position: 'absolute',
                    height: '3px',
                    background: '#1976D2',
                    width: 'calc(100% - 16px)',
                    borderRadius: '100px 100px 0px 0px',
                    bottom: -4,
                    left: '8px',
                  }
                : {},
            }}
            component="span"
          >
            {children}
          </Box>
        </Button>
      )}
    </MenuLink>
  );
};

const AdminDashboard = (props: Props) => {
  const location = useLocation();
  const { children } = props;
  const { scopes } = useAuth();
  const hasInvoicingAccess = !!scopes.find((s) => s === HootEmployeeScope.AccessInvoicing);
  const hasPartnerManagementAccess = !!scopes.find((s) => s === HootEmployeeScope.AccessPartnerManagement);
  const { isFeatureEnabled } = useFeatureFlags();
  const isBlocksAndOpportunitiesEnabled = isFeatureEnabled(FeatureFlags.ff13371_blocks_and_opportunities);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const usersDropdownOpen = Boolean(anchorEl);
  const handleUsersDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUsersDropdownClose = () => {
    setAnchorEl(null);
  };
  const usersPathActive = matchPath(routes.users.path, location.pathname);

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100%',
        backgroundColor: '#F0F4FB',
      }}
    >
      <CssBaseline />
      <AppBar
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 1,
          paddingRight: 0,
        })}
      >
        <Toolbar variant="dense">
          <HootReadingAdminLogo />
          <Box
            sx={{
              marginLeft: 'auto',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <UserDropdown />
          </Box>
        </Toolbar>
        <Toolbar
          variant="dense"
          sx={{
            backgroundColor: '#FFFFFF',
          }}
        >
          <HootNavLink to={routes.home.url}>dashboard</HootNavLink>
          <HootNavLink to={routes.liveLessons.attendance.url}>live lessons</HootNavLink>
          <HootNavLink to={routes.lessons.url}>lessons</HootNavLink>
          <HootNavLink to={routes.lessonSets.url}>lesson sets</HootNavLink>
          {isBlocksAndOpportunitiesEnabled ? null : <HootNavLink to={routes.shifts.url}>shifts</HootNavLink>}
          {isBlocksAndOpportunitiesEnabled ? <HootNavLink to={routes.blocks.url}>Blocks</HootNavLink> : null}

          <Button onClick={handleUsersDropdownClick} sx={{ color: usersPathActive ? '#1976D2' : '#49454F' }}>
            Users
            <ArrowDropDown />
          </Button>
          <Menu anchorEl={anchorEl} open={usersDropdownOpen} onClose={handleUsersDropdownClose}>
            <MenuItem component={HootNavLink} to={routes.users.students.url} onClick={handleUsersDropdownClose}>
              students
            </MenuItem>
            <MenuItem component={HootNavLink} to={routes.users.parents.url} onClick={handleUsersDropdownClose}>
              parents
            </MenuItem>
            <MenuItem component={HootNavLink} to={routes.users.teachers.url} onClick={handleUsersDropdownClose}>
              teachers
            </MenuItem>
            <MenuItem component={HootNavLink} to={routes.users.districtReps.url} onClick={handleUsersDropdownClose}>
              district reps
            </MenuItem>
            <MenuItem component={HootNavLink} to={routes.users.employees.url} onClick={handleUsersDropdownClose}>
              employees
            </MenuItem>
          </Menu>

          <HootNavLink to={routes.districtsSchools.url}>Districts & Schools</HootNavLink>
          <HootNavLink to={routes.library.url}>library</HootNavLink>
          <HootNavLink to={routes.reporting.metabase.url}>reporting</HootNavLink>
          {hasInvoicingAccess ? <HootNavLink to={routes.invoicing.url}>invoicing</HootNavLink> : null}
          {hasPartnerManagementAccess ? <HootNavLink to={routes.partnerManagement.url}>partner management</HootNavLink> : null}
          <HootNavLink to={routes.tasks.url}>Tasks</HootNavLink>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          padding: theme.spacing(3),
          height: '100%',
        })}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default AdminDashboard;
