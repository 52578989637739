import { AppBar, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode } from 'react';
import HootReadingAdminLogo from '@hoot/components/icons/HootReadingAdminLogo';

interface Props {
  children: ReactNode;
}

const PublicView = (props: Props) => {
  const { children } = props;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar>
        <Toolbar role="toolbar">
          <HootReadingAdminLogo />
        </Toolbar>
      </AppBar>
      {children}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    cursor: 'pointer',
  },
  red: {
    color: '#ff4400',
  },
  orange: {
    color: '#ff9602',
  },
  green: {
    color: '#8ad62c',
  },
  blue: {
    color: '#4fcfcd',
  },
  reading: {
    marginLeft: '6px',
    fontWeight: 400,
  },
}));

export default PublicView;
