import { DistrictRepSearchFieldOptions } from '@hoot-reading/hoot-core/dist/enums/user/district-rep-search-field-options.enum';
import { DistrictRepresentativeStatus } from '@hoot-reading/hoot-core/dist/enums/user/district-representative/district-representative-status.enum';
import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { ArrowDropDown, ArrowDropUp, FilterAltOutlined, MailOutline, PhoneOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Badge,
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { defaultRowsPerPage } from '@hoot/common/constants';
import SearchTextField, { SearchTextFieldProps } from '@hoot/components/form/SearchTextField';
import { HeaderData, Table } from '@hoot/components/ui/Table';
import EnrolmentStatusIcon from '@hoot/components/ui/enrolments/EnrolmentStatusIcon';
import { useAlert } from '@hoot/contexts/AlertContext';
import useGetUsers, { DistrictRepSearchParams, UserAccountResponse, UserQuery } from '@hoot/hooks/api/end-users/useGetUsers';
import useGetAllBillableLocations from '@hoot/hooks/api/hfs/useGetAllBillableLocations';
import useGetAllSchools from '@hoot/hooks/api/hfs/useGetAllSchools';
import { usePlaces } from '@hoot/hooks/usePlaces';
import { FormOption } from '@hoot/interfaces/form';
import { UserAccount } from '@hoot/interfaces/user';
import DistrictRepFiltersDrawer from '@hoot/pages/users/district-reps/components/DistrictRepsTable/DistrictRepFiltersDrawer';
import { routes } from '@hoot/routes/routes';
import { removePrefix } from '@hoot/utils/stringUtils';

const ENROLMENTS_TO_SHOW = 4;

const IGNORE_FILTERS = [
  'searchText',
  'page',
  'pageSize',
  'account',
  'accountType',
  'teacherQuery',
  'customerQuery',
  'districtRepQuery',
  'studentQuery',
];

const IGNORE_DISTRICT_REP_FILTERS = ['searchFieldSelection'];

type QueryKeys = keyof UserQuery;

type DistrictRepKeys = keyof DistrictRepSearchParams;

type AllKeys = QueryKeys | DistrictRepKeys;

const searchFieldOptions: FormOption<DistrictRepSearchFieldOptions>[] = [
  { value: DistrictRepSearchFieldOptions.DistrictRepName, label: 'DR Name' },
  { value: DistrictRepSearchFieldOptions.DistrictRepNumber, label: 'DR Number' },
  { value: DistrictRepSearchFieldOptions.DistrictRepEmail, label: 'DR Email' },
  { value: DistrictRepSearchFieldOptions.DistrictRepPhoneNumber, label: 'DR Phone' },
  { value: DistrictRepSearchFieldOptions.EnrolmentId, label: 'Enrolment Id' },
  { value: DistrictRepSearchFieldOptions.All, label: 'All Fields' },
];

interface TeacherTableRow {
  status: React.ReactNode;
  name: React.ReactNode;
  number: React.ReactNode;
  location: React.ReactNode;
  enrolments: React.ReactNode;
}

const headers: HeaderData<TeacherTableRow>[] = [
  { property: 'status', name: 'Status' },
  { property: 'name', name: 'Name' },
  { property: 'number', name: 'Number' },
  { property: 'location', name: 'District/School(s)' },
  { property: 'enrolments', name: 'Enrolments' },
];

function getChipStatusColor(value: DistrictRepresentativeStatus | undefined): string {
  switch (value) {
    case 'ACTIVE':
      return '#338F59';
    case 'INACTIVE':
      return '#D32D41';
    default:
      return '#E0E0E0';
  }
}

export enum DistrictRepTabs {
  Details = 'DETAILS',
  History = 'HISTORY',
}

const FilterChips = (props: {
  badgesWithMultiSelectors: any[];
  userQuery: UserQuery;
  setUserQuery: React.Dispatch<React.SetStateAction<UserQuery>>;
}) => {
  const { badgesWithMultiSelectors, userQuery, setUserQuery } = props;
  const billableLocations = useGetAllBillableLocations();
  const schools = useGetAllSchools();
  const { countryOptionsCanadaFirst } = usePlaces('CA');

  const countryTypes = [...countryOptionsCanadaFirst].reduce(
    (a, b) => ({
      ...a,
      [b.value]: b.label,
    }),
    {},
  ) as { [key: string]: string };

  return (
    <Grid container item sx={{ marginLeft: '24px' }}>
      {badgesWithMultiSelectors?.map((badge) => {
        const [filterType, filterValue]: [AllKeys, any] = badge;
        let key = filterType;
        let value = filterValue;
        if (filterType === 'isEnabled') {
          value = filterValue ? 'Yes' : 'No';
        }
        if (filterType === 'country') {
          value = countryTypes[filterValue as SpokenLanguage];
        } else if (['studentLocationIds', 'districtRepLocationIds'].includes(filterType)) {
          key = 'District' as AllKeys;
          value = billableLocations.data?.billableLocations?.find((a) => a.id === filterValue)?.name;
        } else if (['studentSchoolIds', 'districtRepSchoolIds'].includes(filterType)) {
          key = 'School' as AllKeys;
          value = schools.data?.schools?.find((a) => a.id === filterValue)?.name;
        } else {
          if (typeof filterValue === 'string') {
            value = capitalCase(filterValue);
          }
        }
        return (
          <Chip
            key={`${key}-${value}`}
            sx={{
              margin: '4px',
            }}
            label={`${capitalCase(key)}: ${value}`}
            onDelete={() => {
              if (Object.keys(userQuery.districtRepQuery).includes(filterType)) {
                setUserQuery((q: UserQuery) => ({
                  ...q,
                  districtRepQuery: {
                    ...q.districtRepQuery,
                    [filterType]: Array.isArray(q.districtRepQuery[filterType as DistrictRepKeys])
                      ? (q.districtRepQuery[filterType as DistrictRepKeys] as any)?.filter((val: string) => val !== filterValue)
                      : undefined,
                  },
                }));
              } else {
                setUserQuery((q: UserQuery) => ({
                  ...q,
                  [filterType]: Array.isArray(q[filterType as QueryKeys])
                    ? (q[filterType as QueryKeys] as any)?.filter((val: string) => val !== filterValue)
                    : undefined,
                }));
              }
            }}
          />
        );
      })}
    </Grid>
  );
};

export function DistrictRepsTable() {
  return (
    <Stack spacing="16px">
      <HeaderCard />
      <SearchCard />
    </Stack>
  );
}

function HeaderCard() {
  return (
    <Card sx={{ padding: '24px' }}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Typography variant="headlineLarge">District Reps</Typography>
        <AddDistrictRepAction />
      </Grid>
    </Card>
  );
}

const SearchCard = () => {
  const [searchText, setSearchText] = useState('');
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [enrolmentsExpanded, setIsEnrolmentsExpanded] = useState<boolean>(false);
  const [userQuery, setUserQuery] = useState<UserQuery>({
    pageSize: 10,
    page: 1,
    ...{ isEnabled: true },
    searchText,
    customerQuery: {},
    teacherQuery: {},
    districtRepQuery: { searchFieldSelection: DistrictRepSearchFieldOptions.All },
    accountType: UserAccount.DistrictRep,
  });

  // Search for users based on toolbar searchText, resulting data goes to table body as rows of users.
  const { isFetching: isLoadingDistrictRepSearch, data: districtRepResponseData } = useGetUsers({
    ...userQuery,
    searchText: removePrefix(userQuery.searchText),
  });

  const { success } = useAlert();

  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    success('Copied to clipboard');
  };

  const getLocationDisplay = (user: UserAccountResponse) => {
    if (user.districtRepAccount?.district) {
      return (
        <>
          <Typography variant="bodyMedium" sx={{ fontWeight: 600 }}>
            District:
          </Typography>{' '}
          {user.districtRepAccount?.district.name}
          {user.districtRepAccount?.district.schools.map((school) => (
            <Box
              key={school.id}
              sx={{
                borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginY: '8px',
              }}
            >
              {school.name}
            </Box>
          ))}
        </>
      );
    } else if (user.districtRepAccount?.standAloneSchool) {
      return (
        <>
          <Box component={'span'} sx={{ fontWeight: 600 }}>
            School:
          </Box>{' '}
          {user.districtRepAccount?.standAloneSchool?.name}
        </>
      );
    } else {
      // This should almost never actually be hit, but theoretically possible as a district rep is being created
      return (
        <Box component={'span'} sx={{ fontWeight: 600 }}>
          No billable entities currently being represented.
        </Box>
      );
    }
  };

  const data =
    districtRepResponseData && districtRepResponseData.users
      ? districtRepResponseData.users.map<TeacherTableRow>((user) => ({
          status: (
            <Chip
              label={`${capitalCase(user.districtRepAccount?.status ?? '')}`}
              sx={{ margin: '4px', fontSize: 10, color: '#fff', backgroundColor: getChipStatusColor(user.districtRepAccount?.status) }}
            />
          ),
          name: (
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography sx={{ color: '#000000' }} variant="bodyMedium">
                  {user.firstName} {user.lastName}
                </Typography>
              </Grid>

              <Grid item>
                <Tooltip title={user?.email ?? ''}>
                  <IconButton onClick={handleCopyToClipboard(user.email)}>
                    <MailOutline sx={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title={user?.phoneNumber ?? ''}>
                  <IconButton onClick={handleCopyToClipboard(user.phoneNumber)}>
                    <PhoneOutlined sx={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ),
          number: (
            <Grid item>
              <Link to={routes.users.districtReps.details.url(user.districtRepAccount!.id)}>
                <Typography sx={{ color: '#000000' }} variant="bodyMedium">
                  {user.districtRepAccount?.prefixedNumber}
                </Typography>
              </Link>
            </Grid>
          ),
          location: <Box sx={{ maxWidth: '95%' }}>{getLocationDisplay(user)}</Box>,
          enrolments: (
            <Grid sx={{ maxWidth: '95%' }}>
              {user.districtRepAccount?.enrolments && user.districtRepAccount?.enrolments.length > 0 ? (
                <>
                  {user.districtRepAccount?.enrolments.slice(0, enrolmentsExpanded ? undefined : ENROLMENTS_TO_SHOW).map((enrolment) => (
                    <Box
                      key={enrolment.id}
                      sx={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                      {enrolment.friendlyId}
                      <Chip
                        icon={EnrolmentStatusIcon(enrolment.status)}
                        label={`${capitalCase(enrolment.status)}`}
                        sx={{ margin: '4px', fontSize: 12, backgroundColor: '#EFEFEF' }}
                      />
                    </Box>
                  ))}
                  {user.districtRepAccount?.enrolments.length > ENROLMENTS_TO_SHOW ? (
                    <Box
                      sx={{ color: '#1976D2', display: 'flex', alignItems: 'center', marginBottom: '8px', cursor: 'pointer' }}
                      onClick={() => setIsEnrolmentsExpanded((current) => !current)}
                    >
                      {enrolmentsExpanded ? (
                        <>
                          Collapse enrolments <ArrowDropUp />
                        </>
                      ) : (
                        <>
                          {user.districtRepAccount?.enrolments.length - ENROLMENTS_TO_SHOW} more <ArrowDropDown />
                        </>
                      )}
                    </Box>
                  ) : null}
                </>
              ) : (
                <Box component={'span'} sx={{ fontWeight: 600 }}>
                  No Enrolments
                </Box>
              )}
            </Grid>
          ),
        }))
      : [];

  const badges = () => {
    const mainBadges = Object.entries(userQuery).filter(
      ([k, v]) => !IGNORE_FILTERS.includes(k) && v !== undefined && (Array.isArray(v) ? v.length !== 0 : true),
    ) as [QueryKeys, any][];

    const DistrictRepBadges = Object.entries(userQuery.districtRepQuery).filter(
      ([k, v]) => !IGNORE_DISTRICT_REP_FILTERS.includes(k) && v !== undefined && v !== '' && (Array.isArray(v) ? v.length !== 0 : true),
    ) as [QueryKeys, any][];
    mainBadges.push(...DistrictRepBadges);
    return mainBadges;
  };

  const badgesWithMultiSelectors = badges().reduce((acc: any[], badge) => {
    if (Array.isArray(badge[1])) return [...acc, ...badge[1].map((val: any) => [badge[0], val])];
    return [...acc, badge];
  }, []);

  const handlePageChange = (_event: unknown, newPage: number) => {
    setUserQuery((current) => ({ ...current, page: newPage + 1 }));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setUserQuery((current) => ({ ...current, pageSize: pageSize }));
  };

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchText(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (searchText) => {
    setUserQuery((current) => ({ ...current, searchText, page: 1 }));
  };

  const searchFieldOnChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUserQuery((current) => ({
      ...current,
      districtRepQuery: { ...current.districtRepQuery, searchFieldSelection: value as DistrictRepSearchFieldOptions },
    }));
  };

  return (
    <Card sx={{ padding: '24px' }}>
      <Stack sx={{ mb: 3 }}>
        <Typography variant="titleLarge">District Reps</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <Box width={'250px'}>
          <FormControl fullWidth variant={'outlined'} size={'medium'}>
            <InputLabel id={'fieldSearchField'}>Field to search in</InputLabel>
            <Select
              labelId={'fieldSearchField'}
              label={'Field to search in'}
              value={userQuery.districtRepQuery.searchFieldSelection as any}
              onChange={searchFieldOnChange}
            >
              {searchFieldOptions.map((x) => (
                <MenuItem key={`fieldSearchField-${x.value}`} value={x.value}>
                  {x.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <SearchTextField
          searchInput={searchText}
          onSearchInputChanged={onSearchInputChanged}
          onSearchInputDebounced={onSearchInputDebounced}
          autoFocus
        />

        <Tooltip title={'Filter Results'}>
          <IconButton size="large" onClick={() => setShowFilters(true)} color="primary">
            <Badge color="primary">
              <FilterAltOutlined sx={{ cursor: 'pointer' }} />
            </Badge>
          </IconButton>
        </Tooltip>
      </Stack>

      <Box sx={{ marginY: '20px' }}>
        <FilterChips badgesWithMultiSelectors={badgesWithMultiSelectors} userQuery={userQuery} setUserQuery={setUserQuery} />
      </Box>

      <Stack sx={{ marginTop: '32px' }}>
        <Table
          isPaginated
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          headers={headers}
          data={data}
          count={districtRepResponseData?.count || 0}
          page={districtRepResponseData?.page || 1}
          rowsPerPage={userQuery.pageSize}
          isLoading={isLoadingDistrictRepSearch}
        />
      </Stack>

      {showFilters ? (
        <DistrictRepFiltersDrawer showDrawer={showFilters} setShowDrawer={setShowFilters} userQuery={userQuery} setUserQuery={setUserQuery} />
      ) : null}
    </Card>
  );
};

const AddDistrictRepAction = () => {
  const navigate = useNavigate();
  return (
    <>
      <Button onClick={() => navigate(routes.users.districtReps.create.url)} style={{ height: 36 }} variant="contained" color="primary">
        <AddIcon fontSize="small" style={{ marginRight: 5 }} /> Add District Rep
      </Button>
    </>
  );
};
export default DistrictRepsTable;
