import { Close } from '@mui/icons-material';
import { Box, Button, Divider, Drawer, FormControl, Grid, IconButton, InputLabel, List, ListItem, MenuItem, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Select } from '@hoot/components/form/SelectField';
import { EmployeesSearchParams } from '@hoot/hooks/api/hoot-employees/useGetHootEmployees';
import { HootEmployeeFiltersForm } from '../../interfaces/hoot-employee-filters-form';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  query: EmployeesSearchParams;
  setQuery: React.Dispatch<React.SetStateAction<EmployeesSearchParams>>;
}

export const HootEmployeeFiltersDrawer = (props: Props) => {
  const { open, setOpen, query, setQuery } = props;
  const { handleSubmit, control, setValue } = useForm<HootEmployeeFiltersForm>({
    defaultValues: {
      isEnabled: query.isEnabled ? 'true' : query.isEnabled === undefined ? undefined : 'false',
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = () => {
    setValue('isEnabled', undefined);
  };

  const onSubmit: SubmitHandler<HootEmployeeFiltersForm> = (data) => {
    setQuery((q) => ({
      ...q,
      isEnabled: data.isEnabled === 'true' ? true : data.isEnabled === undefined ? undefined : false,
    }));
    handleClose();
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Box sx={{ minWidth: '320px' }}>
        <Box sx={{ position: 'relative' }}>
          <Typography
            sx={{
              marginLeft: '24px',
              marginTop: '105px',
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '48px',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            Employee Filters
          </Typography>

          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '4px',
              cursor: 'pointer',
            }}
          >
            <Close />
          </IconButton>
        </Box>

        <Divider sx={{ marginTop: '16px' }} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <List disablePadding sx={{ marginTop: '16px' }}>
            <ListItem>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="is-employee-enabled-label">Employee is Enabled</InputLabel>
                <Select control={control} name="isEnabled" labelId="is-employee-enabled-label" label="Employee is Enabled" fullWidth>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
          </List>

          <Grid container p={2} sx={{ position: 'sticky', bottom: 0, zIndex: 1, background: '#fff' }}>
            <Grid item container>
              <Button type="submit" size="small" variant="contained" fullWidth>
                Apply
              </Button>
            </Grid>
            <Grid item container sx={{ marginTop: '16px' }}>
              <Button color="inherit" type="button" onClick={handleReset} size="small" variant="contained" fullWidth>
                Reset
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Drawer>
  );
};
