import { useMemo } from 'react';
import { CountryOption } from '@hoot/interfaces/country';
import countriesStates from '../common/countriesStates.json';

export const usePlaces = (country?: string) => {
  const countryOptionsCanadaFirst = useMemo(() => {
    const countryOptions = countriesStates.reduce((acc: CountryOption[], val) => {
      acc = [
        ...acc,
        {
          value: val.code2,
          label: val.name,
        },
      ];
      return acc;
    }, []);

    return [
      ...countryOptions.filter((item) => item.value === 'CA' || item.value === 'US'),
      ...countryOptions.filter((item) => item.value !== 'CA' && item.value !== 'US'),
    ];
  }, []);

  const provinceStateOptions = useMemo(() => {
    interface CountryToProvinceMap {
      [countryName: string]: string[];
    }

    const countryToProvinceMap: CountryToProvinceMap = countriesStates.reduce((acc, val) => {
      acc = {
        ...acc,
        [val.code2]: val.states,
      };

      return acc;
    }, {});

    if (!country) {
      let provincesStates = countryToProvinceMap['CA'].map((province: string) => {
        return {
          value: province.toUpperCase(),
          label: province,
        };
      });

      provincesStates = provincesStates.concat(
        countryToProvinceMap['US'].map((state: string) => {
          return {
            value: state.toUpperCase(),
            label: state,
          };
        }),
      );

      return provincesStates;
    }

    return countryToProvinceMap?.[country] !== undefined
      ? countryToProvinceMap[country].map((province: string) => {
          return {
            value: province.toUpperCase(),
            label: province,
          };
        })
      : [];
  }, [country]);

  return { countryOptionsCanadaFirst, provinceStateOptions };
};
