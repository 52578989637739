import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { LessonSummary } from '@hoot/pages/home/interfaces/lesson-summary';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

async function getLessonSummaryDashboard(): Promise<LessonSummary> {
  const { data } = await axios.get<LessonSummary>(`${config.apiUrl}/admin/reporting/lesson-summary-dashboard`);

  return data;
}

export default function useGetLessonSummaryDashboard(options?: Omit<UseQueryOptions<LessonSummary, AxiosError>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetLessonSummaryDashboard], () => getLessonSummaryDashboard(), options);
}
