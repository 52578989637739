import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface LessonEventBookDetails {
  id: string;
  title: string;
  resourceProgress: string;
  resourceState: string;
  subject: string;
  instructionalFocus: string;
  hiLo: string;
  nonConformimg: string;
  level: string;
  minimumAge?: string;
  series?: string;
  decodableFormat?: string;
  contentTags?: string;
}

async function getBookDetailsForLessonEventVisualizer(bookId: string, studentProfileId: string) {
  const { data } = await axios.get<LessonEventBookDetails>(
    `${config.apiUrl}/admin/student-profile/${studentProfileId}/resources/resource-progress/${bookId}`,
  );
  return data;
}

export default function useGetBookDetailsForLessonEventVisualizer(
  lessonId: string,
  studentProfileId: string,
  options?: Omit<UseQueryOptions<LessonEventBookDetails>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetEventsforLessonVisualizer, lessonId, studentProfileId],
    () => getBookDetailsForLessonEventVisualizer(lessonId, studentProfileId),
    options,
  );
}
