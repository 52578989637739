import { AttachFile, CancelOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTRSImportCsv from '@hoot/hooks/api/end-users/teacher/useTRSImportCsv';
import theme from '@hoot/theme';
import { DataImportType } from './data-import-type-enums';

interface Props {
  setDisableNextButton: (val: boolean) => void;
  setDisableBackButton: (val: boolean) => void;
  importType: DataImportType;
  setFileName: (name: string) => void;
  setImportId: (id: string) => void;
}

const DataImportUploadFile = (props: Props) => {
  const { setDisableNextButton, setDisableBackButton, importType, setFileName, setImportId } = props;
  const { handleSubmit } = useForm();
  const [inputFileError, setInputFileError] = useState<string>('');
  const [isProcessFileEnabled, setIsProcessFileEnabled] = useState<boolean>(false);
  const [disableFileInput, setDisableFileInput] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const fileSelected = !!fileInputRef.current?.files?.length;

  useEffect(() => {
    setDisableNextButton(true);
    setDisableBackButton(false);
  }, [setDisableBackButton, setDisableNextButton]);

  //Note: we will instantiate all specific import hooks here but will have to only logically call the ones being used related to the selected import type
  const { mutate, isLoading: isUploading } = useTRSImportCsv();

  const handleClear = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsProcessFileEnabled(false);
    clearFileInput();
    setFileName('');
    setInputFileError('');
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const ClearButton = () => (
    <InputAdornment position="end">
      <IconButton onClick={(e) => handleClear(e)} disabled={disableFileInput}>
        <CancelOutlined sx={{ cursor: 'pointer' }} />
      </IconButton>
    </InputAdornment>
  );

  const onUpload = () => {
    if (!fileSelected || !importType) {
      return;
    }

    if (fileInputRef.current.files?.[0].type !== 'text/csv') {
      setInputFileError('There was an error in the upload. Please check the file');
      return;
    }

    setFileName(fileInputRef.current.files[0].name);

    const uploadFile = fileInputRef.current?.files[0];
    const formData = new FormData();
    formData.append('file', uploadFile);

    mutate(formData, {
      onSuccess: (res) => {
        setImportId(res.id);
        setDisableNextButton(false);
        setDisableFileInput(true);
        setDisableBackButton(true);
      },
      onError: (err) => {
        console.error(err);
        setInputFileError(err.response?.data?.message ?? 'Something went wrong, check the csv and try again.');
      },
    });
  };

  return (
    <>
      <Typography variant="titleMedium" color="#000">
        Step 2 - Upload File
      </Typography>

      <Box sx={{ pt: 3, opacity: disableFileInput ? 0.4 : 1 }}>
        <form onSubmit={handleSubmit(onUpload)} encType="multipart/form-data">
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <TextField
              type="file"
              name="file"
              inputRef={fileInputRef}
              onChange={() => setIsProcessFileEnabled(true)}
              label="File"
              disabled={disableFileInput}
              helperText={inputFileError || 'Must be a CSV file'}
              error={!!inputFileError}
              InputProps={{
                startAdornment: fileSelected && <AttachFile />,
                endAdornment: fileSelected && <ClearButton />,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: 400,
                '& input::file-selector-button': {
                  display: 'none',
                },
                '& .MuiInputBase-input': {
                  pl: theme.spacing(2),
                },
              }}
            />

            <Box sx={{ display: 'flex', position: 'relative', bottom: 11, gap: 2 }}>
              {!fileSelected && (
                <Button variant="outlined" size="large" onClick={() => fileInputRef.current?.click()}>
                  Select File
                </Button>
              )}

              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                disabled={!isProcessFileEnabled || isUploading || disableFileInput}
                loading={isUploading}
              >
                Process File
              </LoadingButton>
            </Box>
          </Stack>
        </form>
      </Box>
    </>
  );
};

export default DataImportUploadFile;
