import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';
import { StudentRequest } from '@hoot/pages/users/students/details/student';

async function updateStudentDetails(userId?: string, profileId?: string, payload?: Partial<StudentRequest>) {
  const { data } = await axios.put(`${config.apiUrl}/admin/student-profile/${profileId}`, payload);

  return data;
}

export function useUpdateStudentDetails(userId?: string, profileId?: string) {
  return useMutation<void, AxiosError, Partial<StudentRequest>>((request) => updateStudentDetails(userId, profileId, request));
}
