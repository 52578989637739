import { ArrowDropDown } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, CircularProgress, Menu, MenuItem, Stack } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HUBSPOT_CONTACT_URL, IS_LOCAL, ZENDESK_CONTACT_URL } from '@hoot/common/constants';
import CreatePasswordResetLinkItemButton from '@hoot/components/form/listItemButton/CreatePasswordResetLink';
import HeaderCard, { HeaderCardProps, HeaderTab } from '@hoot/components/ui/HeaderCard';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { useImpersonateUser } from '@hoot/hooks/api/auth/useImpersonateUser';
import { useReSendUserAccountCreation } from '@hoot/hooks/api/end-users/useReSendUserAccountCreation';
import { useSendUserPasswordReset } from '@hoot/hooks/api/end-users/useSendUserPasswordReset';
import useGetDistrictRepresentative, {
  DistrictRepresentativeResponse,
} from '@hoot/hooks/api/user/district-representative/useGetDistrictRepresentative';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { AccountType, ActionUnavailableModal } from '@hoot/pages/users/common/ActionUnavailableModal';
import DistrictRepHistoryTab from '@hoot/pages/users/district-reps/DistrictRepHistoryTab';
import DistrictRepresentativeDetailsTab from '@hoot/pages/users/district-reps/DistrictRepresentativeDetailsTab';
import { DistrictRepTabs } from '@hoot/pages/users/district-reps/components/DistrictRepsTable/DistrictRepsTable';
import { routes } from '@hoot/routes/routes';

interface Props {
  tab: DistrictRepTabs;
}

const DistrictRepresentativeActionButton = (props: { districtRepresentative?: DistrictRepresentativeResponse }) => {
  const { districtRepresentative } = props;
  const [actionAnchorEl, setActionAnchorEl] = useState<Element | null>(null);
  const alert = useAlert();
  const auth = useAuth();

  const passwordReset = useSendUserPasswordReset({
    onSuccess: () => alert.success('Email sent!'),
    onError: () => alert.error('Error sending the password reset email'),
  });

  const resendAccountCreation = useReSendUserAccountCreation({
    onSuccess: () => alert.success('Email sent!'),
    onError: () => alert.error('Error resending the account creation email'),
  });

  const impersonateUser = useImpersonateUser({
    onSuccess: (response) => {
      const url = IS_LOCAL ? 'http://' + response.impersonationUrl : response.impersonationUrl;
      window.open(url, '_blank');
    },
    onError: (err) => alert.error(`Error impersonating user: ${err.message}`),
  });

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleActionPopoverClose = () => {
    setActionAnchorEl(null);
  };

  const handlePasswordResetClick = () => {
    if (districtRepresentative?.email) {
      passwordReset.mutate(districtRepresentative.email);
    }
    setActionAnchorEl(null);
  };

  const handleResendAccountCreationClick = () => {
    if (districtRepresentative?.email) {
      resendAccountCreation.mutate(districtRepresentative.email);
    }
    setActionAnchorEl(null);
  };

  const handleImpersonateUserClick = () => {
    if (districtRepresentative) {
      impersonateUser.mutate(districtRepresentative.endUserId);
    }
    setActionAnchorEl(null);
  };

  const handleGoToHubspotContact = () => {
    if (districtRepresentative) {
      const url = HUBSPOT_CONTACT_URL + districtRepresentative.hubspotId;
      window.open(url, '_blank');
    }

    setActionAnchorEl(null);
  };

  const handleGoToZendeskContact = () => {
    if (districtRepresentative) {
      const url = ZENDESK_CONTACT_URL + districtRepresentative.zendeskId;
      window.open(url, '_blank');
    }

    setActionAnchorEl(null);
  };

  return (
    <>
      <Button type="button" startIcon={<ArrowDropDown />} color="inherit" variant="outlined" size="large" onClick={handleActionClick}>
        Action
      </Button>
      <Menu
        open={Boolean(actionAnchorEl)}
        anchorEl={actionAnchorEl}
        onClose={handleActionPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: 1 }}
      >
        <MenuItem disabled={!districtRepresentative?.hubspotId} onClick={handleGoToHubspotContact}>
          Go to Hubspot Contact <OpenInNewIcon sx={{ marginLeft: '10px' }} />
        </MenuItem>
        <MenuItem disabled={!districtRepresentative?.zendeskId} onClick={handleGoToZendeskContact}>
          Go to Zendesk Contact <OpenInNewIcon sx={{ marginLeft: '10px' }} />
        </MenuItem>
        <MenuItem onClick={handlePasswordResetClick}>Reset Password</MenuItem>
        {districtRepresentative?.email ? <CreatePasswordResetLinkItemButton email={districtRepresentative.email} /> : null}
        <MenuItem onClick={handleResendAccountCreationClick}>Resend Account Creation</MenuItem>
        {auth.hootEmployee?.canImpersonateUsers ? <MenuItem onClick={handleImpersonateUserClick}>Impersonate</MenuItem> : null}
      </Menu>
    </>
  );
};

const DistrictRepresentativePage = (props: Props) => {
  const { tab } = props;
  usePageTitle('District Representative | Admin Portal');
  const { districtRepresentativeId } = useParams() as { districtRepresentativeId: string | undefined };
  const {
    isLoading,
    isError,
    data: districtRepAccount,
    refetch,
  } = useGetDistrictRepresentative(districtRepresentativeId!, {
    enabled: !!districtRepresentativeId,
    retry: false,
  });
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [showFunctionalityDisabledModal, setShowFunctionalityDisabledModal] = useState<boolean>(false);
  const { setIsDirty } = useBlockIfDirty();
  const formRef = useRef<HTMLFormElement>();
  const isEditMode = !!districtRepresentativeId;

  useEffect(() => {
    setIsDirty(formIsDirty);
  }, [formIsDirty, setIsDirty]);

  if (isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (isError) {
    return <Stack alignItems="center">Failed to load the page!</Stack>;
  }

  const onSaveRequired = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
      setShowFunctionalityDisabledModal(false);
    }
  };

  return (
    <Stack spacing={3}>
      <DistrictRepresentativeHeader
        isEditMode={isEditMode}
        districtRepAccount={districtRepAccount}
        formIsDirty={formIsDirty}
        formRef={formRef}
        setShowFunctionalityDisabledModal={setShowFunctionalityDisabledModal}
      />
      {tab === DistrictRepTabs.Details ? (
        <DistrictRepresentativeDetailsTab
          isEditMode={isEditMode}
          districtRep={districtRepAccount}
          formRef={formRef}
          setFormIsDirty={setFormIsDirty}
          refetchFn={refetch}
        />
      ) : null}
      {tab === DistrictRepTabs.History && isEditMode ? <DistrictRepHistoryTab /> : null}
      <ActionUnavailableModal
        setOpen={setShowFunctionalityDisabledModal}
        open={showFunctionalityDisabledModal}
        accountType={AccountType.DistrictRepresentative}
        onSave={onSaveRequired}
      />
    </Stack>
  );
};

const DistrictRepresentativeHeader = (props: {
  isEditMode: boolean;
  districtRepAccount?: DistrictRepresentativeResponse;
  formIsDirty: boolean;
  formRef: React.MutableRefObject<HTMLFormElement | undefined>;
  setShowFunctionalityDisabledModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isEditMode, districtRepAccount, formIsDirty, formRef, setShowFunctionalityDisabledModal } = props;

  const navigate = useNavigate();

  const districtRepId = districtRepAccount?.id;
  const tabs = useMemo<HeaderTab[]>(() => {
    const detailsRoute = isEditMode && districtRepId ? routes.users.districtReps.details.url(districtRepId) : routes.users.districtReps.create.url;
    const historyRoute = isEditMode && districtRepId ? routes.users.districtReps.history.url(districtRepId) : routes.users.districtReps.create.url;
    return [
      { id: 'details', label: 'Details', route: detailsRoute },
      { id: 'history', label: 'History', route: historyRoute },
    ];
  }, [isEditMode, districtRepId]);

  const onTabSelected: HeaderCardProps['onTabSelected'] = (selectedTab): boolean => {
    if (isEditMode) {
      navigate(selectedTab.route);
      return !formIsDirty;
    } else {
      setShowFunctionalityDisabledModal(true);
      return false;
    }
  };

  return (
    <HeaderCard
      title={districtRepAccount ? `${districtRepAccount.firstName} ${districtRepAccount.lastName}` : 'New User'}
      subtitle={districtRepAccount ? `District Representative Number: ${districtRepAccount?.prefixedNumber}` : ''}
      canCopySubtitle
      copySubtitleOverride={`${districtRepAccount?.prefixedNumber}`}
      showNavigateBackButton
      actionButtons={
        <>
          {isEditMode && districtRepAccount ? <DistrictRepresentativeActionButton districtRepresentative={districtRepAccount} /> : null}
          <Button
            size="large"
            type="button"
            variant="contained"
            disabled={!formIsDirty}
            onClick={() => formRef.current && formRef.current.requestSubmit()}
          >
            Save
          </Button>
        </>
      }
      tabs={tabs}
      onTabSelected={onTabSelected}
    />
  );
};

export default DistrictRepresentativePage;
