import { Pause, PlayArrow } from '@mui/icons-material';
import { Button, Card, IconButton, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import { Box, Stack, styled } from '@mui/system';
import { useEffect, useRef } from 'react';
import { convertSecondsToTime } from '../../../../utils/convertSecondsToTime';
import { useLessonVisualizer } from './context/LessonVisualizerContextProvider';
import { TimelineProps } from './hooks/useLessonEventVisualiser';

const TIMELINE_HEIGHT = 44;

export function LessonEventsTimeline() {
  return (
    <Stack flex={1}>
      <Card>
        <Stack flex={1} p="16px">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="titleLarge">Lesson Timeline</Typography>
            <Stack flex={1} spacing="16px" direction="row" alignItems="center" justifyContent="flex-end">
              <PlayButton />
              <PlaySpeedButton />
              <Timer />
            </Stack>
          </Stack>
          <Timelines />
        </Stack>
      </Card>
    </Stack>
  );
}

function Timer() {
  const { currentTime, totalTime } = useLessonVisualizer();
  const timerId = useRef(0);
  const ref = useRef<HTMLSpanElement>();

  useEffect(() => {
    timerId.current = window.setInterval(() => {
      if (ref.current) {
        ref.current.innerText = convertSecondsToTime(currentTime.current);
      }
    }, 1000);

    return () => {
      clearInterval(timerId.current);
    };
  }, [currentTime]);

  return (
    <Typography variant="bodyLarge">
      <Box component="span" ref={ref}>
        {convertSecondsToTime(currentTime.current)}
      </Box>
      / {convertSecondsToTime(totalTime)}
    </Typography>
  );
}

function PlayButton() {
  const { isPlaying, toggleIsPlaying } = useLessonVisualizer();
  return (
    <Box sx={{ backgroundColor: '#1976D2', borderRadius: '100px' }}>
      <IconButton onClick={toggleIsPlaying}>{isPlaying ? <Pause sx={{ color: '#FFFFFF' }} /> : <PlayArrow sx={{ color: '#FFFFFF' }} />}</IconButton>
    </Box>
  );
}

function PlaySpeedButton() {
  const { togglePlaySpeed, playSpeed } = useLessonVisualizer();
  return (
    <IconButton sx={{ height: '40px', width: '40px' }} onClick={togglePlaySpeed}>
      <Button variant="outlined">{playSpeed}X</Button>
    </IconButton>
  );
}

function Needle() {
  const { currentTime, isPlaying } = useLessonVisualizer();
  const timerId = useRef(0);
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    timerId.current = window.setInterval(() => {
      if (ref.current) {
        ref.current.style.transform = `translateX(${currentTime.current}px)`;
      }
    }, 500);

    return () => {
      clearInterval(timerId.current);
    };
  }, [isPlaying, currentTime]);

  return (
    <Box ref={ref} sx={{ position: 'absolute', top: '-24px', left: '65px' }}>
      <svg width="14" height="245" viewBox="0 0 14 245" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" y="3" width="2" height="300px" fill="#FF0000" />
        <circle cx="7" cy="7" r="7" fill="#FF0000" />
      </svg>
    </Box>
  );
}

function Timelines() {
  const { timelines } = useLessonVisualizer();

  return (
    <Stack sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
      <Stack mt="24px" sx={{ backgroundColor: '#FAFAFA', position: 'relative' }}>
        {timelines.library.length > 0 ? <Timeline title="Library" timeline={timelines.library} /> : null}
        {timelines.book.length > 0 ? <Timeline title="Book" timeline={timelines.book} /> : null}
        {timelines.hra.length > 0 ? <Timeline title="HRA" timeline={timelines.hra} /> : null}
        {timelines.pma.length > 0 ? <Timeline title="PMA" timeline={timelines.pma} /> : null}
        {timelines.whiteboard.length > 0 ? <Timeline title="WB" timeline={timelines.whiteboard} /> : null}
        <Needle />
      </Stack>
    </Stack>
  );
}

function Timeline(props: { title: string; timeline: TimelineProps[] }) {
  const { seek, totalTime } = useLessonVisualizer();
  const handleClick = (event: React.MouseEvent) => {
    const bounds = event.currentTarget.getBoundingClientRect();
    if (bounds) {
      const x = event.clientX - bounds.left;
      seek(x);
    }
  };

  return (
    <Stack direction="row" flex={1}>
      <Stack ml="16px" justifyContent="center" sx={{ height: `${TIMELINE_HEIGHT}px`, width: '56px', borderRight: 'solid 4px #000' }}>
        <Typography variant="bodySmall">{props.title}</Typography>
      </Stack>
      <Stack
        position="relative"
        onClick={handleClick}
        sx={{
          position: 'relative',
          borderBottom: 'solid 1px rgba(0, 0, 0, .2)',
        }}
      >
        <Stack
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: `${totalTime + 3}px`,
            borderRight: 'solid 4px #000',
          }}
        >
          {props.timeline.map((tl, idx) => (
            <>
              <Box
                key={`timeline-${idx}-${tl.startsAt}`}
                position="absolute"
                left={`${tl.startsAt}px`}
                width={`${tl.length}px`}
                height="100%"
                sx={{ backgroundColor: '#81C0FF' }}
              />
              {tl.pointOfInterest?.map((poi, idx) => <PointOfInterest key={`${poi.id}-${idx}`} title={poi.note} left={poi.occuredAt} />)}
            </>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}

function PointOfInterest(props: { left: number | string; title: string }) {
  return (
    <Box sx={{ position: 'absolute', left: props.left, transform: 'translateX(-4px)' }}>
      <CustomTooltip title={props.title} arrow placement="top">
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4" cy="4" r="4" fill="black" />
        </svg>
      </CustomTooltip>
    </Box>
  );
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }),
);
