import { DateTime } from 'luxon';
import { DayOfWeek } from '@hoot/utils/dateTime';
import { TIME_FORMAT } from '../../../common/constants';
import { timeOptions } from '../enums';

export const LUXON_DATE_FORMAT = 'yyyy-LL-dd';

export function setTimeOnDateTime(date: DateTime, time: string, timeFormat = TIME_FORMAT): DateTime {
  return DateTime.fromFormat(`${date.toISODate()} ${time}`, `${LUXON_DATE_FORMAT} ${timeFormat}`);
}

export function roundStartTime(time: string) {
  const [hour, minutes] = time
    .slice(0, -3)
    .split(':')
    .map((t) => parseInt(t));
  if (minutes < 30) {
    return `${hour}:00${time.slice(-3)}`;
  } else {
    return `${hour}:30${time.slice(-3)}`;
  }
}

/**
 * Will round the time up or down on a 30 minute basis. Will round the time to the closest
 * 30 minute mark.
 * Ex: 10:07 --> 10:00
 * Ex: 10:17 --> 10:30
 * Ex: 10:47 --> 11:00
 * Ex: 10:43 --> 10:30
 */
export function roundTime(time: string) {
  const [hour, minutes] = time
    .slice(0, -3)
    .split(':')
    .map((t) => parseInt(t));
  if (minutes === 0 || minutes <= 14) {
    return `${hour}:00${time.slice(-3)}`;
  } else if (minutes <= 44) {
    return `${hour}:30${time.slice(-3)}`;
  } else {
    return `${hour === 12 ? 1 : hour + 1}:00${time.slice(-3)}`;
  }
}

/**
 * Creates a sequence of numbers starting from "from" and ending at "to".
 * Will be incremented by "step". Default for "step" is 1.
 */
export function range(from: number, to: number, step: number = 1) {
  return [...Array(Math.floor((to - from) / step) + 1)].map((_, i) => from + i * step);
}

/**
 * Returns all indexes between and including the start time and end time
 */
export function getTimeblockRange(startTime: string, endTime: string) {
  const startIndex = timeOptions.findIndex((to) => to === roundTime(startTime));
  let endIndex = timeOptions.findIndex((to) => to === roundTime(endTime));

  if (startIndex > endIndex) {
    endIndex = timeOptions.length - 1;
  } else if (startIndex === endIndex) {
    endIndex += 1; //Note: this is a temporary fix... but not a perfect fix as it still allows for overwrites (edge case for 5 minute lessons)
  }
  const timeBlockIndexes = range(startIndex, endIndex);

  if (endTime === '12:00 AM') {
    timeBlockIndexes.push(0);
  }

  return timeBlockIndexes;
}

export const daysOfWeekLabels = new Map([
  [DayOfWeek.Sunday, 'Sunday'],
  [DayOfWeek.Monday, 'Monday'],
  [DayOfWeek.Tuesday, 'Tuesday'],
  [DayOfWeek.Wednesday, 'Wednesday'],
  [DayOfWeek.Thursday, 'Thursday'],
  [DayOfWeek.Friday, 'Friday'],
  [DayOfWeek.Saturday, 'Saturday'],
]);
