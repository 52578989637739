import {
  ScheduledLessonHistoryAction,
  ScheduledLessonHistoryProperty,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lessons/scheduled-lesson-history-action';
import { useRef, useState } from 'react';
import { LessonHistoryResponse, useGetLessonHistory } from '../../../../../hooks/api/lessons/useGetLesson';
import { ContentType } from '../context/LessonVisualizerContextProvider';
import { videoPosition } from './useLessonEventVideo';

/** INTERFACES */

export interface PointOfInterestProp {
  id: string;
  occuredAt: number;
  action: ScheduledLessonHistoryAction;
  note: string;
}

export interface TimelineProps {
  startsAt: number;
  endsAt: number;
  length: number;
  pointOfInterest?: PointOfInterestProp[];
}

export interface TimelineSections {
  library: TimelineProps[];
  book: TimelineProps[];
  hra: TimelineProps[];
  pma: TimelineProps[];
  whiteboard: TimelineProps[];
}

interface TimelineData {
  timeline: TimelineSections;
  totalTime: number;
  initialLessonJoinEventAt: number;
  lessonEventSequence: LessonEventSequence[];
}

export interface SortedLessonEvent {
  startsAt: number;
  endsAt: number;
  contentType: ContentType;
}

export interface HistoryEvent {
  id: string;
  action: ScheduledLessonHistoryAction;
  occuredAt: number;
  properties: ScheduledLessonHistoryProperty;
}

export interface LessonEventSequence {
  occuredAt: number;
  isTeacherInLesson: boolean;
  teacherVideoPosition: videoPosition;
  isStudentInLesson: boolean;
  studentVideoPosition: videoPosition;
}

/** CONSTANTS */

const FILTERED_LESSON_EVENTS = [
  ScheduledLessonHistoryAction.StudentOpenedABook,
  ScheduledLessonHistoryAction.StudentClosedABook,
  ScheduledLessonHistoryAction.TeacherOpenedABook,
  ScheduledLessonHistoryAction.TeacherClosedABook,
  ScheduledLessonHistoryAction.TeacherStartedAHRA,
  ScheduledLessonHistoryAction.TeacherResumedAHRA,
  ScheduledLessonHistoryAction.CompletedHRA,
  ScheduledLessonHistoryAction.TeacherClosedAHRA,
  ScheduledLessonHistoryAction.TeacherStartedAPMA,
  ScheduledLessonHistoryAction.TeacherResumedAPMA,
  ScheduledLessonHistoryAction.CompletedPMA,
  ScheduledLessonHistoryAction.TeacherClosedAPMA,
  ScheduledLessonHistoryAction.TeacherCreatedAWhiteboard,
  ScheduledLessonHistoryAction.TeacherResumedAWhiteboard,
  ScheduledLessonHistoryAction.TeacherClosedAWhiteboard,
  ScheduledLessonHistoryAction.TeacherJoinedLesson,
  ScheduledLessonHistoryAction.TeacherLeftLesson,
  ScheduledLessonHistoryAction.TeacherLeftLessonJoinedFromAnotherTab,
  ScheduledLessonHistoryAction.StudentJoinedLesson,
  ScheduledLessonHistoryAction.StudentLeftLesson,
  ScheduledLessonHistoryAction.StudentLeftLessonJoinedFromAnotherTab,
  ScheduledLessonHistoryAction.BookPageTurn,
  ScheduledLessonHistoryAction.HRASubmitted,
  ScheduledLessonHistoryAction.PMASubmitted,
  ScheduledLessonHistoryAction.TeacherTriggeredCelebration,
  ScheduledLessonHistoryAction.MediaUpdate,
];

const JOIN_AND_LEAVE_EVENTS = [
  ScheduledLessonHistoryAction.StudentJoinedLesson,
  ScheduledLessonHistoryAction.StudentLeftLesson,
  ScheduledLessonHistoryAction.StudentLeftLessonJoinedFromAnotherTab,
  ScheduledLessonHistoryAction.TeacherJoinedLesson,
  ScheduledLessonHistoryAction.TeacherLeftLesson,
  ScheduledLessonHistoryAction.TeacherLeftLessonJoinedFromAnotherTab,
];

/** HOOK */

export function useLessonEventVisualiser(lessonId: string) {
  const [sortedLessonPageEvents, setSortedLessonPageEvents] = useState<SortedLessonEvent[]>([]);

  const historyData = useRef<HistoryEvent[]>([]);

  const [timelineData, setTimelineData] = useState<TimelineData>({
    timeline: {
      library: [],
      book: [],
      hra: [],
      pma: [],
      whiteboard: [],
    },
    totalTime: 0,
    initialLessonJoinEventAt: 0,
    lessonEventSequence: [],
  });

  const { isLoading } = useGetLessonHistory(lessonId, true, {
    onSuccess: (data) => {
      const joinLeaveEvents = data?.filter((d) => JOIN_AND_LEAVE_EVENTS.includes(d.action)).sort((a, b) => (a.date < b.date ? -1 : 1)) ?? [];
      const initialLessonJoinEventAt = joinLeaveEvents[0]?.date || 0;
      const lastLessonJoinEventsAt = joinLeaveEvents[joinLeaveEvents.length - 1]?.date || 0;
      const totalTime = (lastLessonJoinEventsAt - initialLessonJoinEventAt) / 1000;
      const [timeline, sortedLessonPageEvents] = createTimeLine(initialLessonJoinEventAt, lastLessonJoinEventsAt, data);

      const lessonEventSequence = createLessonEventSequence(initialLessonJoinEventAt, joinLeaveEvents);

      historyData.current = data
        .filter((e) => FILTERED_LESSON_EVENTS.includes(e.action))
        .map<HistoryEvent>((e) => ({
          id: e.id,
          action: e.action,
          occuredAt: (e.date - initialLessonJoinEventAt) / 1000,
          properties: e.properties,
        }))
        .sort((a, b) => (a.occuredAt < b.occuredAt ? -1 : 1));

      setTimelineData({ timeline, initialLessonJoinEventAt, totalTime, lessonEventSequence });
      setSortedLessonPageEvents(sortedLessonPageEvents);
    },
  });

  return {
    sortedLessonPageEvents,
    timelineData,
    historyData,
    isLoading,
  };
}

/** Helper Functions */

/** createTimeLine creates the timeline sections */

const LESSON_PAGE_EVENTS = [
  ScheduledLessonHistoryAction.StudentOpenedABook,
  ScheduledLessonHistoryAction.StudentClosedABook,
  ScheduledLessonHistoryAction.TeacherOpenedABook,
  ScheduledLessonHistoryAction.TeacherClosedABook,
  ScheduledLessonHistoryAction.TeacherStartedAHRA,
  ScheduledLessonHistoryAction.TeacherResumedAHRA,
  ScheduledLessonHistoryAction.CompletedHRA,
  ScheduledLessonHistoryAction.TeacherClosedAHRA,
  ScheduledLessonHistoryAction.TeacherStartedAPMA,
  ScheduledLessonHistoryAction.TeacherResumedAPMA,
  ScheduledLessonHistoryAction.CompletedPMA,
  ScheduledLessonHistoryAction.TeacherClosedAPMA,
  ScheduledLessonHistoryAction.TeacherCreatedAWhiteboard,
  ScheduledLessonHistoryAction.TeacherResumedAWhiteboard,
  ScheduledLessonHistoryAction.TeacherClosedAWhiteboard,
];

const POINTS_OF_INTEREST_EVENTS = [
  ScheduledLessonHistoryAction.TeacherJoinedLesson,
  ScheduledLessonHistoryAction.TeacherLeftLesson,
  ScheduledLessonHistoryAction.TeacherLeftLessonJoinedFromAnotherTab,
  ScheduledLessonHistoryAction.StudentJoinedLesson,
  ScheduledLessonHistoryAction.StudentLeftLesson,
  ScheduledLessonHistoryAction.StudentLeftLessonJoinedFromAnotherTab,
  ScheduledLessonHistoryAction.BookPageTurn,
  ScheduledLessonHistoryAction.HRASubmitted,
  ScheduledLessonHistoryAction.PMASubmitted,
  ScheduledLessonHistoryAction.TeacherTriggeredCelebration,
  ScheduledLessonHistoryAction.MediaUpdate,
];

export function createTimeLine(
  initialStartTime: number,
  lastLessonJoinEventsAt: number,
  lessonHistory: LessonHistoryResponse[],
): [TimelineSections, SortedLessonEvent[]] {
  const lessonPageEvents = lessonHistory.filter((lh) => LESSON_PAGE_EVENTS.some((e) => e === lh.action)).sort((a, b) => (a.date > b.date ? 1 : -1));
  const pointsOfInterestEvents = lessonHistory
    .filter((lh) => POINTS_OF_INTEREST_EVENTS.some((e) => e === lh.action))
    .sort((a, b) => (a.date > b.date ? 1 : -1))
    .map<PointOfInterestProp>((e) => ({
      id: e.id,
      occuredAt: (e.date - initialStartTime) / 1000,
      action: e.action,
      note: e.note,
    }));

  const timelineSections: TimelineSections = {
    library: [],
    book: [],
    hra: [],
    pma: [],
    whiteboard: [],
  };

  // we need the sorted the events to determine what is displayed on the LeventEventsContent component
  const sortedLessonPageEvents: SortedLessonEvent[] = [];

  lessonPageEvents.forEach((val, idx) => {
    const eventTimestamp = (val.date - initialStartTime) / 1000;
    // peek at the next value
    const nextEvent =
      idx + 1 < lessonPageEvents.length
        ? (lessonPageEvents[idx + 1].date - initialStartTime) / 1000
        : (lastLessonJoinEventsAt - initialStartTime) / 1000;
    const length = nextEvent ? nextEvent - eventTimestamp : 0;

    // we always start in the library, so the first event would be reading book/assessment/whiteboard
    if (idx === 0) {
      timelineSections.library.push({
        startsAt: 0,
        endsAt: eventTimestamp,
        length: eventTimestamp - 0,
        pointOfInterest: pointsOfInterestEvents.filter((poi) => poi.occuredAt >= 0 && poi.occuredAt <= eventTimestamp),
      });
    }

    const pointsOfInterest = pointsOfInterestEvents.filter((poi) => poi.occuredAt >= eventTimestamp && poi.occuredAt <= nextEvent);

    switch (val.action) {
      case ScheduledLessonHistoryAction.StudentJoinedLesson:
        break;
      case ScheduledLessonHistoryAction.StudentLeftLesson:
      case ScheduledLessonHistoryAction.StudentLeftLessonJoinedFromAnotherTab:
        break;
      case ScheduledLessonHistoryAction.TeacherJoinedLesson:
        break;
      case ScheduledLessonHistoryAction.TeacherLeftLesson:
      case ScheduledLessonHistoryAction.TeacherLeftLessonJoinedFromAnotherTab:
        break;
      case ScheduledLessonHistoryAction.StudentOpenedABook:
      case ScheduledLessonHistoryAction.TeacherOpenedABook:
        timelineSections.book.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          length: length,
          pointOfInterest: pointsOfInterest,
        });
        sortedLessonPageEvents.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          contentType: ContentType.Reader,
        });
        break;
      case ScheduledLessonHistoryAction.StudentClosedABook:
      case ScheduledLessonHistoryAction.TeacherClosedABook:
        timelineSections.library.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          length: length,
          pointOfInterest: pointsOfInterest,
        });
        sortedLessonPageEvents.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          contentType: ContentType.Library,
        });
        break;
      case ScheduledLessonHistoryAction.TeacherStartedAHRA:
      case ScheduledLessonHistoryAction.TeacherResumedAHRA:
        timelineSections.hra.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          length: length,
          pointOfInterest: pointsOfInterest,
        });
        sortedLessonPageEvents.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          contentType: ContentType.Assessment,
        });
        break;
      case ScheduledLessonHistoryAction.TeacherClosedAHRA:
        timelineSections.library.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          length: length,
          pointOfInterest: pointsOfInterest,
        });
        sortedLessonPageEvents.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          contentType: ContentType.Library,
        });
        break;
      case ScheduledLessonHistoryAction.TeacherClosedAPMA:
        timelineSections.library.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          length: length,
          pointOfInterest: pointsOfInterest,
        });
        sortedLessonPageEvents.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          contentType: ContentType.Library,
        });
        break;
      case ScheduledLessonHistoryAction.TeacherStartedAPMA:
      case ScheduledLessonHistoryAction.TeacherResumedAPMA:
        timelineSections.hra.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          length: length,
          pointOfInterest: pointsOfInterest,
        });
        sortedLessonPageEvents.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          contentType: ContentType.Assessment,
        });
        break;
      case ScheduledLessonHistoryAction.TeacherCreatedAWhiteboard:
      case ScheduledLessonHistoryAction.TeacherResumedAWhiteboard:
        timelineSections.whiteboard.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          length: length,
          pointOfInterest: pointsOfInterest,
        });
        sortedLessonPageEvents.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          contentType: ContentType.Whiteboard,
        });
        break;
      case ScheduledLessonHistoryAction.TeacherClosedAWhiteboard:
        timelineSections.library.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          length: length,
          pointOfInterest: pointsOfInterest,
        });
        sortedLessonPageEvents.push({
          startsAt: eventTimestamp,
          endsAt: nextEvent,
          contentType: ContentType.Library,
        });
    }
  });

  return [timelineSections, sortedLessonPageEvents];
}

/** This function maps out all of the Join and Leave events to determine when the teacher/student are in the lesson */
function createLessonEventSequence(initialStartTime: number, events: LessonHistoryResponse[]): LessonEventSequence[] {
  const result: LessonEventSequence[] = [];

  let previousEvent: LessonEventSequence = {
    isStudentInLesson: false,
    isTeacherInLesson: false,
    studentVideoPosition: 'none',
    teacherVideoPosition: 'none',
    occuredAt: 0,
  };

  result.push(previousEvent);
  for (const event of events) {
    function getNewEvent(previousEvent: LessonEventSequence): LessonEventSequence | undefined {
      const occuredAt = (event.date - initialStartTime) / 1000;

      switch (event.action) {
        case ScheduledLessonHistoryAction.TeacherJoinedLesson:
          return {
            isStudentInLesson: previousEvent.isStudentInLesson,
            isTeacherInLesson: true,
            occuredAt: occuredAt,
            studentVideoPosition: !previousEvent.isStudentInLesson ? 'none' : 'left',
            teacherVideoPosition: !previousEvent.isStudentInLesson ? 'full' : 'right',
          };
        case ScheduledLessonHistoryAction.StudentJoinedLesson:
          return {
            isStudentInLesson: true,
            isTeacherInLesson: previousEvent.isTeacherInLesson,
            occuredAt: occuredAt,
            studentVideoPosition: !previousEvent.isTeacherInLesson ? 'full' : 'right',
            teacherVideoPosition: !previousEvent.isTeacherInLesson ? 'none' : 'left',
          };
        case ScheduledLessonHistoryAction.TeacherLeftLesson:
          return {
            isStudentInLesson: previousEvent.isStudentInLesson,
            isTeacherInLesson: false,
            occuredAt: occuredAt,
            studentVideoPosition: previousEvent.isStudentInLesson ? 'full' : 'none',
            teacherVideoPosition: 'none',
          };

        case ScheduledLessonHistoryAction.StudentLeftLesson:
          return {
            isStudentInLesson: false,
            isTeacherInLesson: previousEvent.isTeacherInLesson,
            occuredAt: occuredAt,
            studentVideoPosition: 'none',
            teacherVideoPosition: previousEvent.isTeacherInLesson ? 'full' : 'none',
          };
      }
    }

    const newEvent = getNewEvent(previousEvent);
    if (newEvent) {
      result.push(newEvent);
      previousEvent = newEvent;
    }
  }

  return result;
}
