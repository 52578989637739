import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useAlert } from '@hoot/contexts/AlertContext';
import { DetailedBook } from '@hoot/hooks/api/library/useGetBook';
import { SortOrder, TableHeaderCell, muiTableSortDirectionLookup } from '@hoot/interfaces/order-by';
import useQueryLibrary, { BookOrderBy, LibraryResponse, QueryLibrary } from '../../hooks/api/library/useQueryLibrary';
import { ReadingLevelType, readingLevelType } from '../../pages/lessons/enums';
import SearchTextField from '../form/SearchTextField';

interface AddBooksModalProps {
  show: boolean;
  /**
   * Books that are already "added" (in whatever context this is used in). These book titles will still appear, but you
   * will not be able to (re)add them.
   */
  existingBookIds: string[];
  onAddBooksToLesson: (books: DetailedBook[]) => void;
  onDismiss: () => void;
}

const tableHeaderCells: TableHeaderCell<BookOrderBy>[] = [
  { label: 'Book Title', sortKey: 'title' },
  { label: 'Book Level', sortKey: 'readingLevel' },
];

const DEFAULT_PAGE_SIZE = 10;

interface SortOptions {
  orderBy: TableHeaderCell<BookOrderBy>['sortKey'];
  sortDirection: SortOrder;
}

const sortOptionsInitialState: SortOptions = {
  sortDirection: SortOrder.ASC,
  orderBy: 'title',
};

const searchQueryInitialState: QueryLibrary = {
  searchText: '',
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  orderBy: sortOptionsInitialState.orderBy,
  orderSort: sortOptionsInitialState.sortDirection,
};

const AddBooksModal = (props: AddBooksModalProps) => {
  const { show, existingBookIds, onAddBooksToLesson, onDismiss } = props;

  const [sortOptions, setSortOptions] = useState<SortOptions>({
    ...sortOptionsInitialState,
  });
  const [searchQuery, setSearchQuery] = useState<QueryLibrary>({
    ...searchQueryInitialState,
  });
  const [searchInput, setSearchInput] = useState('');
  const [libraryResponse, setLibraryResponse] = useState<LibraryResponse>();
  const [booksToAddDictionary, setBooksToAddDictionary] = useState<Record<string, DetailedBook>>({});
  const booksToAddList = Object.values(booksToAddDictionary);

  const { error } = useAlert();

  const existingBookIdsSet = useMemo(() => {
    return new Set(existingBookIds);
  }, [existingBookIds]);

  const { refetch, isFetching } = useQueryLibrary(searchQuery, {
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setLibraryResponse(data);
    },
    onError: (err) => {
      console.error(err);
      error(`An error occurred while searching books.`);
    },
  });

  // Update the request query when the sort criteria changes.
  useEffect(() => {
    setSearchQuery((prev) => ({
      ...prev,
      orderBy: sortOptions.orderBy,
      orderSort: sortOptions.sortDirection,
    }));
  }, [sortOptions]);

  // Reset everything when modal is (re)opened.
  useEffect(() => {
    if (show) {
      setBooksToAddDictionary({});
      setLibraryResponse(undefined);
      setSearchInput('');
      setSearchQuery({
        ...searchQueryInitialState,
      });
      setSortOptions({
        ...sortOptionsInitialState,
      });
    }
  }, [show]);

  // Fetch results when search query is updated.
  useEffect(() => {
    refetch();
  }, [searchQuery, refetch]);

  const bookLevelLabelDictionary = useMemo<Record<string, string>>(() => {
    return (
      libraryResponse?.books.reduce((acc, curr) => {
        const readingLevelSystemLabel = readingLevelType[curr.readingLevel.type as ReadingLevelType] ?? curr.readingLevel.type;
        return {
          ...acc,
          [curr.id]: `${readingLevelSystemLabel} ${curr.readingLevel.name}`,
        };
      }, {}) ?? {}
    );
  }, [libraryResponse]);

  const onSearchInputChanged = (searchText: string) => {
    setSearchInput(searchText);
  };

  const onSearchInputDebounced = (searchText: string) => {
    setSearchQuery((prev) => ({
      ...prev,
      searchText,
      // Reset pagination when we change the search criteria.
      page: 1,
    }));
  };

  const _onAddBooksToLesson = () => {
    onAddBooksToLesson(booksToAddList);
  };

  const onAddBookToQueue = (book: DetailedBook) => () => {
    setBooksToAddDictionary({ ...booksToAddDictionary, [book.id]: book });
  };

  const onRemoveQueuedBook = (book: DetailedBook) => () => {
    const newBooksToAddDictionary = { ...booksToAddDictionary };
    delete newBooksToAddDictionary[book.id];
    setBooksToAddDictionary(newBooksToAddDictionary);
  };

  // If we're toggling the same order-by key, then just flip the sort direction.
  // Else, we're sorting by a different column, and we can default the sort to 'asc'.
  const toggleOrderBy = (orderByKey: TableHeaderCell<BookOrderBy>['sortKey']) => () => {
    const isAsc = sortOptions?.orderBy === orderByKey && sortOptions?.sortDirection === SortOrder.ASC;
    setSortOptions({ orderBy: orderByKey, sortDirection: isAsc ? SortOrder.DESC : SortOrder.ASC });
  };

  const onPageChange: TablePaginationProps['onPageChange'] = (_, newPage) => {
    setSearchQuery((prev) => ({
      ...prev,
      page: newPage + 1,
    }));
  };

  const onRowsPerPageChange: TablePaginationProps['onRowsPerPageChange'] = (event) => {
    setSearchQuery((prev) => ({
      ...prev,
      pageSize: Number(event.target.value),
    }));
  };

  return (
    <>
      <Dialog open={show} onClose={onDismiss}>
        <DialogContent
          sx={(theme) => ({
            [theme.breakpoints.up('sm')]: {
              width: '600px',
            },
            height: '80vh',
          })}
        >
          {/*
          FYI: normally, this would be in a <DialogTitle> component ouitside of <DialogContent>, but I moved it here
          instead b/c of a clipping issue with the <SearchTextField> component being at the top / first child of <DialogContent>
          */}
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="headlineSmall">Add Book</Typography>
            <IconButton onClick={onDismiss}>
              <Close />
            </IconButton>
          </Box>
          <Box mt={3}>
            <SearchTextField
              searchInput={searchInput ?? ''}
              onSearchInputChanged={onSearchInputChanged}
              onSearchInputDebounced={onSearchInputDebounced}
            />
          </Box>
          {!booksToAddList.length ? null : (
            <Box
              sx={{
                mt: 3,
                p: 2,
                background: '#F6F7F8',
                borderBottom: '1px solid #E4E4E4',
                borderRadius: '8px',
              }}
            >
              <Stack direction="row" gap={2}>
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '100px',
                    background: '#1976D2',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#ffffff',
                    fontSize: '10px',
                    fontWeight: 400,
                  }}
                >
                  {booksToAddList.length}
                </Box>
                <Stack direction="column" flex={1}>
                  <Typography variant="tableHeading">Books QUEUED to be added</Typography>
                  <Stack direction="column" gap={0.25}>
                    {booksToAddList.map((b) => (
                      <Stack key={`queue-book-${b.id}`} flexDirection="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography variant="bodyMedium">{b.title}</Typography>
                        <Button color="error" onClick={onRemoveQueuedBook(b)}>
                          Remove
                        </Button>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          )}
          <Box sx={{ width: '100%', mt: 3, height: '4px' }}>{isFetching && <LinearProgress />}</Box>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaderCells.map((cell) => (
                  <TableCell
                    variant="head"
                    sx={{ width: cell.cellWidth }}
                    key={`headerCell-${cell.label}`}
                    sortDirection={
                      cell.sortKey
                        ? sortOptions?.orderBy === cell.sortKey
                          ? muiTableSortDirectionLookup[sortOptions?.sortDirection ?? SortOrder.ASC]
                          : false
                        : undefined
                    }
                  >
                    {cell.sortKey ? (
                      <TableSortLabel
                        active={sortOptions?.orderBy === cell.sortKey}
                        direction={
                          sortOptions?.orderBy === cell.sortKey ? muiTableSortDirectionLookup[sortOptions?.sortDirection ?? SortOrder.ASC] : undefined
                        }
                        onClick={toggleOrderBy(cell.sortKey)}
                      >
                        {cell.label}
                      </TableSortLabel>
                    ) : (
                      cell.label
                    )}
                  </TableCell>
                ))}
                {/* Placeholder column for "Add" button. */}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {libraryResponse?.books.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography variant="bodyMedium">{row.title}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="bodyMedium">{bookLevelLabelDictionary[row.id]}</Typography>
                  </TableCell>
                  <TableCell>
                    <Button onClick={onAddBookToQueue(row)} disabled={!!booksToAddDictionary[row.id] || existingBookIdsSet.has(row.id)}>
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            // Hiding the row per page options here.
            labelRowsPerPage={<></>}
            rowsPerPageOptions={[]}
            component="div"
            count={libraryResponse?.count ?? 0}
            page={(libraryResponse?.page ?? 1) - 1}
            rowsPerPage={libraryResponse?.pageSize ?? DEFAULT_PAGE_SIZE}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </DialogContent>
        <DialogActions sx={{ p: 3, position: 'relative' }}>
          <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
          <Button size="large" variant="outlined" color="primary" onClick={onDismiss}>
            Cancel
          </Button>
          <Button size="large" variant="contained" onClick={_onAddBooksToLesson}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddBooksModal;
