import { UserImportUserStatus } from '@hoot-reading/hoot-core/dist/enums/user/user-import/user-import-user-status.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../../queryKeys';

const getImportErrorFile = async (importId: string, importTypes: UserImportUserStatus[]) =>
  await axios.get<any>(`${config.apiUrl}/admin/user-import/${importId}/errors-file`, { params: { importTypes } });

export default function useGetUserImportError(
  importId: string,
  importTypes: UserImportUserStatus[],
  options?: Omit<UseQueryOptions<any, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetUserImportError, importId, importTypes], () => getImportErrorFile(importId, importTypes), options);
}
