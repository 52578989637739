import { TeacherShiftType } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import { DateTime } from 'luxon';
import React, { PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectedTeacher } from '@hoot/components/modals/SelectTeacher';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { useStableSearchParams } from '@hoot/hooks/useStableSearchParams';
import { FormOption } from '@hoot/interfaces/form';
import { DayOfWeek } from '@hoot/utils/dateTime';
import CreateShiftDateTimeStep, { RecurrenceEndType } from '../date-time-step/CreateShiftDateTimeStep';
import CreateShiftReviewStep from '../review-step/CreateShiftReviewStep';
import TeacherConflictCheckStep, { TentativeShiftForm } from '../teacher-conflicts-step/TeacherConflictCheckStep';

export enum CreateShiftWizardStepEnum {
  DateAndTime = 1,
  TeacherAndConflicts,
  Review,
}

export interface CreateShiftWizardStep {
  id: CreateShiftWizardStepEnum;
  stepperLabel: string;
  contentLabel?: string;
  content: () => ReactNode;
}

export interface DayOfWeekForShift {
  isSelected: boolean;
  dayOfWeek: DayOfWeek;
}

export const teacherShiftTypeOptions: FormOption<TeacherShiftType>[] = [
  { label: 'Regular', value: TeacherShiftType.Regular },
  { label: 'On Call', value: TeacherShiftType.OnCall },
  { label: 'Interview', value: TeacherShiftType.Interview },
];

export interface ShiftDetails {
  shiftDate: DateTime;
  shiftStartTime: DateTime;
  shiftEndTime: DateTime;
  shiftType: TeacherShiftType;
  recurring: boolean;
  recurrenceEnd: RecurrenceEndType;
  occurrences: number;
  endAfterDate: DateTime;
  daysOfWeek: DayOfWeekForShift[];
  tentativeShifts: TentativeShiftForm[];
  preSelectedTeacher: boolean;
  urlParamTeacherAccountId?: string;
  teacher?: SelectedTeacher;
}

interface CreateShiftContextProps {
  shiftDetails: ShiftDetails;
  setShiftDetails: React.Dispatch<React.SetStateAction<ShiftDetails>>;
  currentStep: CreateShiftWizardStep;
  goToStep: (step: CreateShiftWizardStepEnum) => void;
  goToPrevStep: () => void;
  goToNextStep: () => void;
  closeShiftCreationModal: () => void;
}

export const createShiftWizardSteps: CreateShiftWizardStep[] = [
  {
    id: CreateShiftWizardStepEnum.DateAndTime,
    stepperLabel: 'Date & Time',
    content: () => <CreateShiftDateTimeStep />,
  },
  {
    id: CreateShiftWizardStepEnum.TeacherAndConflicts,
    stepperLabel: 'Teacher & Conflicts',
    content: () => <TeacherConflictCheckStep />,
  },
  {
    id: CreateShiftWizardStepEnum.Review,
    stepperLabel: 'Review',
    content: () => <CreateShiftReviewStep />,
  },
];

const CreateShiftContext = React.createContext<CreateShiftContextProps>(undefined!);
CreateShiftContext.displayName = 'CreateShiftContext';

interface CreateShiftContextProviderProps extends PropsWithChildren<any> {
  shiftStartTime?: DateTime;
  shiftEndTime?: DateTime;
  teacher?: SelectedTeacher;
  preSelectedTeacher?: boolean;
  closeModal?: () => void;
}

const CreateShiftContextProvider = (props: CreateShiftContextProviderProps) => {
  const { children, shiftStartTime, shiftEndTime, teacher, preSelectedTeacher, closeModal } = props;
  const { setIsDirty } = useBlockIfDirty();
  const { teacherAccountId } = useParams() as { teacherAccountId: string | undefined };
  const [searchParams, setSearchParams] = useStableSearchParams();

  const stepSearchParam = searchParams.get('step');
  const numericStep = Number(stepSearchParam);

  const now = DateTime.now().plus({ minutes: 1 });
  const minShiftRecurrenceDate = DateTime.now().plus({ days: 7 }).endOf('day');

  const defaultValues: ShiftDetails = {
    shiftDate: shiftStartTime ?? now,
    shiftStartTime: shiftStartTime ?? now,
    shiftEndTime: shiftEndTime ?? now,
    shiftType: TeacherShiftType.Regular,
    recurring: false,
    recurrenceEnd: RecurrenceEndType.EndAfterDate,
    occurrences: 0,
    endAfterDate: minShiftRecurrenceDate,
    daysOfWeek: [
      { isSelected: false, dayOfWeek: DayOfWeek.Monday },
      { isSelected: false, dayOfWeek: DayOfWeek.Tuesday },
      { isSelected: false, dayOfWeek: DayOfWeek.Wednesday },
      { isSelected: false, dayOfWeek: DayOfWeek.Thursday },
      { isSelected: false, dayOfWeek: DayOfWeek.Friday },
      { isSelected: false, dayOfWeek: DayOfWeek.Saturday },
      { isSelected: false, dayOfWeek: DayOfWeek.Sunday },
    ],
    tentativeShifts: [],
    preSelectedTeacher: preSelectedTeacher ?? !!teacherAccountId,
    urlParamTeacherAccountId: teacherAccountId,
    teacher: teacher ?? undefined,
  };

  const [shiftDetails, setShiftDetails] = useState<ShiftDetails>(defaultValues);

  // Hacky workaround so that we can guarantee that we only ever render step #1 on page load.
  const mountedRef = useRef(false);

  const redirectToInitialStep = useCallback(() => {
    setSearchParams(
      {
        step: `${CreateShiftWizardStepEnum.DateAndTime}`,
      },
      {
        replace: true,
      },
    );
  }, [setSearchParams]);

  // On page load, always start at step #1. All data is lost on refresh, so we need to start over.
  useEffect(() => {
    mountedRef.current = true;
    setIsDirty(true);
    redirectToInitialStep();
  }, [redirectToInitialStep, setIsDirty]);

  // Ensure step is always within bounds.
  useEffect(() => {
    // If invalid step, then start at the beginning.
    if (isNaN(numericStep) || numericStep < CreateShiftWizardStepEnum.DateAndTime || numericStep > CreateShiftWizardStepEnum.Review) {
      redirectToInitialStep();
    }
  }, [numericStep, redirectToInitialStep, stepSearchParam]);

  const currentStep = useMemo(() => {
    // Ensure the step query is an actual number.
    const numericStep = !isNaN(Number(stepSearchParam)) ? Number(stepSearchParam) : CreateShiftWizardStepEnum.DateAndTime;

    // If the step is out of bounds, then just default to the beginning.
    const step: CreateShiftWizardStepEnum =
      numericStep < CreateShiftWizardStepEnum.DateAndTime || numericStep > CreateShiftWizardStepEnum.Review
        ? CreateShiftWizardStepEnum.DateAndTime
        : numericStep;

    return createShiftWizardSteps.find((x) => x.id === step) ?? createShiftWizardSteps[0];
  }, [stepSearchParam]);

  const goToStep = (step: CreateShiftWizardStepEnum) => {
    setSearchParams(
      {
        step: `${step}`,
      },
      {
        replace: true,
      },
    );
  };

  const goToPrevStep = () => {
    if (currentStep.id > CreateShiftWizardStepEnum.DateAndTime) {
      goToStep(currentStep.id - 1);
    } else {
      console.error(`Can not go to previous step. There is no step before step ${CreateShiftWizardStepEnum.DateAndTime}.`);
    }
  };

  const goToNextStep = () => {
    if (currentStep.id < CreateShiftWizardStepEnum.Review) {
      goToStep(currentStep.id + 1);
    } else {
      console.error(`Can not go to next step. There is no step after step ${CreateShiftWizardStepEnum.Review}.`);
    }
  };

  const closeShiftCreationModal = () => {
    closeModal?.();
  };

  return (
    <CreateShiftContext.Provider
      value={{
        shiftDetails,
        setShiftDetails,
        currentStep,
        goToStep,
        goToPrevStep,
        goToNextStep,
        closeShiftCreationModal,
      }}
    >
      {mountedRef.current ? children : null}
    </CreateShiftContext.Provider>
  );
};

export const useCreateShiftContext = () => {
  const context = React.useContext(CreateShiftContext);

  if (context === undefined) {
    throw new Error('useCreateShiftContext must be used within a CreateShiftContextProvider');
  }
  return context;
};

export default CreateShiftContextProvider;
