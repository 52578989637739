import { BlockUtilizationBlock, BlockUtilizationLesson } from '@hoot/hooks/api/blocks/useGetBlockUtilization';
import { StatusColours } from '../users/components/user-schedule/weekly-schedule/enums';
import { HoverColor } from './block-constants';
import { BlockTimeSlot } from './block-interfaces';

export const timeSlotIsWithinBlock = (blocks: BlockUtilizationBlock[], timeSlot: BlockTimeSlot) => {
  return !!blocks.find((s) => timeSlot.start >= s.startsAt && timeSlot.end <= s.endsAt);
};

export const blockStarts = (blocks: BlockUtilizationBlock[], timeSlot: BlockTimeSlot) => {
  return !!blocks.filter((s) => s.startsAt >= timeSlot.start && s.startsAt < timeSlot.end).length;
};

export const blockEnds = (blocks: BlockUtilizationBlock[], timeSlot: BlockTimeSlot) => {
  return !!blocks.filter((s) => s.endsAt > timeSlot.start && s.endsAt <= timeSlot.end).length;
};

export const timeSlotLessons = (lessons: BlockUtilizationLesson[], timeSlot: BlockTimeSlot) => {
  return lessons.filter((l) => l.startsAt >= timeSlot.start && l.startsAt < timeSlot.end);
};

export const timeSlotBlocks = (blocks: BlockUtilizationBlock[], timeSlot: BlockTimeSlot) => {
  return blocks.filter((s) => blockStarts([s], timeSlot) || blockEnds([s], timeSlot) || timeSlotIsWithinBlock([s], timeSlot));
};

export const lessonHoverColor = (color: StatusColours): HoverColor => {
  if (color === StatusColours.Completed) {
    return HoverColor.Completed;
  } else if (color === StatusColours.Scheduled) {
    return HoverColor.Scheduled;
  } else {
    return HoverColor.Cancelled;
  }
};
