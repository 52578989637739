import { Outlet } from 'react-router-dom';
import { LessonsProvider } from './LessonContextProvider';

const LessonsLayout = () => {
  return (
    <LessonsProvider>
      <Outlet />
    </LessonsProvider>
  );
};

export default LessonsLayout;
