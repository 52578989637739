import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';

interface Props {
  title: string;
  bodyText: string;
  copyButton?: boolean;
  openInNewURL?: string;
}

const CardLine = (props: Props) => {
  const { title, bodyText, copyButton, openInNewURL } = props;
  const { success } = useAlert();
  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    success('Copied to clipboard');
  };

  const handleOpenInNew = (url: string) => () => {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  };

  return (
    <Grid container xs={12} justifyContent="space-between" alignItems="center" sx={{ paddingRight: 2, marginTop: 2 }}>
      <Grid container xs={9}>
        <Stack>
          <Typography variant="titleSmall" sx={{ color: '#000' }}>
            {title}
          </Typography>

          {!!openInNewURL ? (
            <Link to={openInNewURL} target="_blank">
              <Typography variant="bodyMedium" color="secondary" sx={{ overflowWrap: 'anywhere' }}>
                {bodyText}
              </Typography>
            </Link>
          ) : (
            <Typography variant="bodyMedium" sx={{ color: '#000', overflowWrap: 'anywhere' }}>
              {bodyText}
            </Typography>
          )}
        </Stack>
      </Grid>
      <Grid container xs={2} justifyContent="flex-end" alignItems="center">
        {copyButton && bodyText ? (
          <IconButton onClick={handleCopyToClipboard(bodyText)} title="Copy to clipboard">
            <ContentCopyIcon />
          </IconButton>
        ) : null}
        {!!openInNewURL && bodyText ? (
          <IconButton onClick={handleOpenInNew(openInNewURL)} title="Open in new tab">
            <OpenInNewIcon />
          </IconButton>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default CardLine;
