import { TimelineEvent, TimelineLessonReviewFocus, TimelineOutcome } from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import InfoBubble from '@hoot/components/InfoBubble';
import { routes } from '@hoot/routes/routes';
import { enumToTitleCase } from '@hoot/utils/stringUtils';
import { timelineColourDictionary } from './models';
import { HeaderCell, TimelineCell } from './useGetStudentTimeline';

interface InfoBubbleProps {
  selectedOutcomeCell: TimelineCell | null;
  selectedEventCell: HeaderCell | null;
  onClose: () => void;
  anchorEl: HTMLElement | null;
}

const InfoBubbles = (props: InfoBubbleProps) => {
  const { selectedEventCell, selectedOutcomeCell, onClose, anchorEl } = props;

  const { studentProfileId } = useParams() as { studentProfileId: string };
  const navigate = useNavigate();

  switch (selectedEventCell?.event) {
    case TimelineEvent.HRA:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={enumToTitleCase(selectedEventCell.event)}
          content={selectedEventCell.date!}
          button={{
            text: 'View HRA Details',
            action: () => navigate(routes.users.students.assessments.url(studentProfileId), { state: { hraId: selectedEventCell.hraId } }),
          }}
          color={timelineColourDictionary[selectedEventCell.event]}
        />
      );
    case TimelineEvent.PMA:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={enumToTitleCase(selectedEventCell.event)}
          content={selectedEventCell.date!}
          button={{
            text: 'View PMA Details',
            action: () => navigate(routes.users.students.assessments.url(studentProfileId), { state: { pmaId: selectedEventCell.pmaId } }),
          }}
          color={timelineColourDictionary[selectedEventCell.event]}
        />
      );
    case TimelineEvent.ScheduledLesson:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={enumToTitleCase(selectedEventCell.event)}
          content={selectedEventCell.date!}
          color={timelineColourDictionary[selectedEventCell.event]}
        />
      );
    case TimelineEvent.CompletedLesson:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={enumToTitleCase(selectedEventCell.event)}
          content={
            <Stack sx={{ gap: 1 }}>
              <Typography variant="labelSmall">{selectedEventCell.date}</Typography>
              <Typography variant="labelSmall">w/ {selectedEventCell.teacherName}</Typography>
            </Stack>
          }
          button={{
            text: 'View Lesson Review',
            action: () => navigate(routes.users.students.lessonReviews.urlWithId(studentProfileId, selectedEventCell.lessonReviewId!)),
          }}
          color={timelineColourDictionary[selectedEventCell.event]}
        />
      );
    case TimelineEvent.Cancellation:
    case TimelineEvent.StudentAbsence:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={enumToTitleCase(selectedEventCell.event)}
          content={selectedEventCell.date!}
          color={timelineColourDictionary[selectedEventCell.event]}
        />
      );
  }

  switch (selectedOutcomeCell?.outcome) {
    case TimelineOutcome.Blank:
      return null;
    case TimelineOutcome.InstructionRequired:
    case TimelineOutcome.Mastered:
    case TimelineOutcome.ReviewRequired:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={enumToTitleCase(selectedOutcomeCell.outcome)}
          content={
            <Stack>
              {selectedOutcomeCell.unit ? <Typography variant="labelSmall">{selectedOutcomeCell.unit.name}</Typography> : null}
              {selectedOutcomeCell.unit.results ? <Typography variant="labelSmall">{selectedOutcomeCell.unit.results}</Typography> : null}
            </Stack>
          }
          button={
            selectedOutcomeCell.pmaId || selectedOutcomeCell.hraId
              ? {
                  text: selectedOutcomeCell.hraId ? 'View HRA Details' : 'View PMA Details',
                  action: () =>
                    navigate(routes.users.students.assessments.url(studentProfileId), {
                      state: { hraId: selectedOutcomeCell.hraId, pmaId: selectedOutcomeCell.pmaId },
                    }),
                }
              : undefined
          }
          color={timelineColourDictionary[selectedOutcomeCell.outcome]}
        />
      );
    case TimelineLessonReviewFocus.FormativeAssessment:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={enumToTitleCase(selectedOutcomeCell.outcome)}
          content={selectedOutcomeCell.date!}
          button={{
            text: 'View Lesson Review',
            action: () =>
              selectedOutcomeCell.lessonReviewId
                ? navigate(routes.users.students.lessonReviews.urlWithId(studentProfileId, selectedOutcomeCell.lessonReviewId!))
                : undefined,
          }}
          color={timelineColourDictionary[selectedOutcomeCell.outcome]}
        />
      );
    case TimelineLessonReviewFocus.LessonPrimaryFocus:
    case TimelineLessonReviewFocus.LessonSecondaryFocus:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={enumToTitleCase(selectedOutcomeCell.outcome)}
          content={
            <Stack>
              {selectedOutcomeCell.unit ? <Typography variant="labelSmall">{selectedOutcomeCell.unit.name}</Typography> : null}
              {selectedOutcomeCell.unit.results ? <Typography variant="labelSmall">{selectedOutcomeCell.unit.results}</Typography> : null}
            </Stack>
          }
          button={{
            text: 'View Lesson Review',
            action: () =>
              selectedOutcomeCell.lessonReviewId
                ? navigate(routes.users.students.lessonReviews.urlWithId(studentProfileId, selectedOutcomeCell.lessonReviewId!))
                : undefined,
          }}
          color={timelineColourDictionary[selectedOutcomeCell.outcome]}
        />
      );
    case TimelineLessonReviewFocus.AdvancedBeyond:
      return (
        <InfoBubble
          anchorEl={anchorEl}
          onClose={onClose}
          title={enumToTitleCase(selectedOutcomeCell.outcome)}
          content={
            <Stack>
              {selectedOutcomeCell.unit ? <Typography variant="labelSmall">{selectedOutcomeCell.unit.name}</Typography> : null}
              {selectedOutcomeCell.unit.results ? <Typography variant="labelSmall">{selectedOutcomeCell.unit.results}</Typography> : null}
            </Stack>
          }
          color={timelineColourDictionary[selectedOutcomeCell.outcome]}
        />
      );
  }
};

export default InfoBubbles;
