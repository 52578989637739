import { DescriptionOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import React, { ChangeEvent, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import DateFilter, { ActiveDateFilter } from '@hoot/components/form/filterDropDown/common/DateFilter';
import { QualityAssuranceEnrolmentIDsDropDown } from '@hoot/components/form/filterDropDown/tasks/QualityAssuranceEnrolmentIDsFilter';
import {
  ActiveQualityAssuranceMetricsFilterDropDown,
  QualityAssuranceMetricsFilterDropDown,
} from '@hoot/components/form/filterDropDown/tasks/QualityAssuranceMetricsFilter';
import { HeaderData, SelectState, Table } from '@hoot/components/ui/Table';
import { FormOption } from '@hoot/interfaces/form';
import { millisToDateDisplay } from '@hoot/utils/dateTime';
import { removePrefix } from '@hoot/utils/stringUtils';
import { defaultRowsPerPage } from '../../common/constants';
import SearchTextField from '../../components/form/SearchTextField';
import { LessonStatusChip } from '../../components/form/chips/LessonStatusChip';
import {
  ActiveQualityAssuranceFilterDropDown,
  QualityAssuranceCategoryFilterDropDown,
} from '../../components/form/filterDropDown/tasks/QualityAssuranceCategoryFilter';
import {
  ActiveQualityAssuranceOwningTeamFilterDropDown,
  QualityAssuranceOwningTeamFilterDropDown,
} from '../../components/form/filterDropDown/tasks/QualityAssuranceOwningTeamFilter';
import {
  ActiveQualityAssuranceTaskStatusFilterDropDown,
  QualityAssuranceTaskStatusFilterDropDown,
} from '../../components/form/filterDropDown/tasks/QualityAssuranceTaskStatusFilter';
import {
  ActiveQualityAssuranceTypeFilterDropDown,
  QualityAssuranceTypeFilterDropDown,
} from '../../components/form/filterDropDown/tasks/QualityAssuranceTypeFilter';
import FullCircleIcon from '../../components/icons/FullCircle';
import { useAlert } from '../../contexts/AlertContext';
import { QueryKey } from '../../hooks/api/queryKeys';
import {
  QATaskSearchFieldOptions,
  QualityAssuranceCategory,
  QualityAssuranceOwningTeam,
  QualityAssuranceStatus,
  QualityAssuranceType,
  qualityAssuranceCategoryLookup,
  qualityAssuranceMetricLookup,
  qualityAssuranceOwningTeamLookup,
  qualityAssuranceStatusLookup,
  qualityAssuranceTypeLabel,
} from '../../hooks/api/tasks/enums';
import useQualityAssuranceBulkStatusChange from '../../hooks/api/tasks/useQualityAssuranceBulkStatusChange';
import useQueryQualityTasks, { QualityAssuranceTasksResponse, QueryQualityAssuranceTasks } from '../../hooks/api/tasks/useQueryQualityAssuranceTasks';
import { routes } from '../../routes/routes';
import { groupBy } from '../district-schools/details/enrolments/details/util';

/** INTERFACES */

export interface QualityAssuranceTasksFilters {
  categories: QualityAssuranceCategory[];
  types: QualityAssuranceType[];
  statuses: QualityAssuranceStatus[];
  owningTeams: QualityAssuranceOwningTeam[];
  enrolmentIds: string[];
  searchFieldSelection: QATaskSearchFieldOptions;
  metrics: string[];
  startDate: string | undefined;
  endDate: string | undefined;
}

export interface TaskRow {
  id: string;
  selected: boolean;
  task: React.ReactNode;
  taskType: React.ReactNode;
  taskCreatedAt: React.ReactNode;
  status: React.ReactNode;
  owningTeam: React.ReactNode;
  student: React.ReactNode;
  metric: React.ReactNode;
  teacher: React.ReactNode;
  enrolment: React.ReactNode;
  lesson: React.ReactNode;
}

const headers: HeaderData<TaskRow>[] = [
  { name: 'id', property: 'id', isHidden: true },
  { name: 'Task', property: 'task' },
  { name: 'Task Type', property: 'taskType' },
  { name: 'Creation Date', property: 'taskCreatedAt' },
  { name: 'Status', property: 'status' },
  { name: 'Owning Team', property: 'owningTeam' },
  { name: 'Student', property: 'student' },
  { name: 'Metric', property: 'metric' },
  { name: 'Teacher', property: 'teacher' },
  { name: 'Enrolment', property: 'enrolment' },
  { name: 'Lesson', property: 'lesson' },
];

/** Components */

const QualityAssuranceTasks = (props: { teacherAccountId?: string; studentProfileId?: string }) => {
  const { teacherAccountId, studentProfileId } = props;
  const [showBulkStatusChange, setShowBulkStatusChange] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [query, setQuery] = useState<QueryQualityAssuranceTasks>({
    page: 1,
    categories: [],
    statuses: [QualityAssuranceStatus.New],
    enrolmentIds: [],
    types: [],
    owningTeams: [],
    pageSize: defaultRowsPerPage,
    query: '',
    searchFieldSelection: QATaskSearchFieldOptions.All,
    studentProfileId: studentProfileId,
    teacherAccountId: teacherAccountId,
    startDate: undefined,
    endDate: undefined,
    metrics: [],
  });

  const queryClient = useQueryClient();
  const alert = useAlert();
  const { data, isFetching } = useQueryQualityTasks({ ...query, query: removePrefix(query.query) ?? '' });
  const bulkStatusChangeMutation = useQualityAssuranceBulkStatusChange();

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setSelected([]);
    setQuery((q) => ({ ...q, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setQuery((q) => ({ ...q, pageSize }));
  };

  const handleSelect = (val: TaskRow, selected: boolean) => {
    if (selected) {
      setSelected((currentState) => [...currentState, val.id]);
    } else {
      setSelected((currentState) => currentState.filter((t) => t !== val.id));
    }
  };

  const handleSelectAll = (selectState: SelectState) => {
    if (selectState === 'all') {
      setSelected([]);
    } else {
      if (data) {
        const selectedTasks = data.data.map((s) => s.id);
        setSelected(selectedTasks);
      }
    }
  };

  const handleFiltersChange = (filters: QualityAssuranceTasksFilters) => {
    setSelected([]);
    setQuery((currentState) => ({
      ...currentState,
      categories: filters.categories,
      types: filters.types,
      statuses: filters.statuses,
      owningTeams: filters.owningTeams,
      enrolmentIds: filters.enrolmentIds,
      searchFieldSelection: filters.searchFieldSelection,
      startDate: filters.startDate,
      endDate: filters.endDate,
      metrics: filters.metrics,
    }));
  };

  const handleBulkStateChangeClick = () => {
    setShowBulkStatusChange(true);
  };

  const handleBulkStatusChangeApply = (status: QualityAssuranceStatus, isActioned?: boolean) => {
    bulkStatusChangeMutation.mutate(
      {
        taskIds: selected,
        status: status,
        isActioned: isActioned,
      },
      {
        onSuccess: () => {
          setShowBulkStatusChange(false);
          queryClient.invalidateQueries(QueryKey.QualityAssuranceTasksQuery);
          alert.success(`${selected.length} Tasks Successfully Updated!`);
          setSelected([]);
        },
        onError: () => {
          alert.error(`There was an issue bulk updating tasks`);
        },
      },
    );
  };

  const handleBulkStatusChangeCancel = () => {
    setShowBulkStatusChange(false);
  };

  const rows: TaskRow[] =
    data?.data.map((d) => ({
      id: d.id,
      task: <Link to={routes.tasks.urlWithId(d.id)}>{d.taskPrefixedNumber}</Link>,
      taskType: (
        <Stack>
          <Typography>{qualityAssuranceTypeLabel[d.taskType]}</Typography>
          <Typography>{qualityAssuranceCategoryLookup[d.taskCategory]}</Typography>
        </Stack>
      ),
      taskCreatedAt: millisToDateDisplay(d.taskCreatedAt),
      status: <StatusChip status={d.status} />,
      owningTeam: qualityAssuranceOwningTeamLookup[d.owningTeam],
      student: (
        <Stack>
          <Link to={routes.users.students.details.url(d.studentId)}>{d.studentPrefixedNumber}</Link>
        </Stack>
      ),
      metric: qualityAssuranceMetricLookup[d.failedThreshold],
      teacher: d.teacherId ? (
        <Stack>
          <Link to={routes.users.teachers.details.url(d.teacherId)}>{d.teacherPrefixedNumber}</Link>
        </Stack>
      ) : (
        'N/A'
      ),
      enrolment:
        d.enrolmentId && d.enrolmentLocationId ? (
          <Stack>
            <Link to={routes.districtsSchools.enrolments.details.url(d.enrolmentLocationId, d.enrolmentId)}>{d.enrolmentFriendlyId}</Link>
          </Stack>
        ) : (
          'N/A'
        ),
      lesson: d.lessonId ? (
        <Stack alignItems="center" spacing="8px" direction="row">
          <Link to={routes.lessons.details.url(d.lessonId)}>{d.lessonPrefixedNumber}</Link>
          <LessonStatusChip status={d.lessonStatus} />
        </Stack>
      ) : (
        'N/A'
      ),
      selected: selected.some((s) => d.id === s),
    })) ?? [];

  const bulkStateChangeButtonDisabled = selected.length === 0;

  const searchFieldOnChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    handleFiltersChange({ ...query, searchFieldSelection: value as QATaskSearchFieldOptions });
  };

  const searchFieldOptions: FormOption<QATaskSearchFieldOptions>[] = [
    { value: QATaskSearchFieldOptions.LessonNumber, label: 'Lesson Number' },
    { value: QATaskSearchFieldOptions.LessonSetNumber, label: 'Lesson Set Number' },
    { value: QATaskSearchFieldOptions.StudentNumber, label: 'Student Number' },
    { value: QATaskSearchFieldOptions.TeacherNumber, label: 'Teacher Number' },
    { value: QATaskSearchFieldOptions.TaskNumber, label: 'Task Number' },
    { value: QATaskSearchFieldOptions.All, label: 'All Fields' },
  ];

  if (!!studentProfileId) {
    // removing search by Teacher Number option
    searchFieldOptions.splice(2, 1);
  }

  if (!!teacherAccountId) {
    // removing search by Teacher Number option
    searchFieldOptions.splice(3, 1);
  }

  return (
    <Stack spacing="16px">
      {!teacherAccountId || !studentProfileId ? (
        <Card sx={{ padding: '24px' }}>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Typography variant="headlineLarge">Tasks</Typography>
            <Stack direction="row" spacing="8px">
              <Button disabled={bulkStateChangeButtonDisabled} onClick={handleBulkStateChangeClick} variant="outlined">
                Bulk State Change
              </Button>
              <Button variant="contained" disabled>
                Create
              </Button>
            </Stack>
          </Grid>
        </Card>
      ) : null}

      <Card
        sx={{
          width: 1,
        }}
      >
        <CardContent>
          <Stack spacing="24px">
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="titleLarge">Tasks</Typography>
              {!!teacherAccountId ? (
                <Button disabled={bulkStateChangeButtonDisabled} onClick={handleBulkStateChangeClick} variant="outlined">
                  Bulk State Change
                </Button>
              ) : null}
            </Stack>

            <Stack direction={'row'} gap={2}>
              <Box width={'250px'}>
                <FormControl fullWidth variant={'outlined'} size={'medium'}>
                  <InputLabel id={'fieldSearchField'}>Search in</InputLabel>
                  <Select labelId={'fieldSearchField'} label={'Search in'} value={query.searchFieldSelection as any} onChange={searchFieldOnChange}>
                    {searchFieldOptions.map((x) => (
                      <MenuItem key={`fieldSearchField-${x.value}`} value={x.value}>
                        {x.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <SearchTextField
                searchInput={query.query}
                onSearchInputChanged={(text) => setQuery((currentState) => ({ ...currentState, query: text }))}
              />
            </Stack>

            <Filters
              onChange={handleFiltersChange}
              value={{
                types: query.types,
                categories: query.categories,
                statuses: query.statuses,
                owningTeams: query.owningTeams,
                enrolmentIds: query.enrolmentIds,
                searchFieldSelection: query.searchFieldSelection,
                startDate: query.startDate,
                endDate: query.endDate,
                metrics: query.metrics,
              }}
              teacherAccountId={teacherAccountId}
            />
            <ActiveFilters
              onChange={handleFiltersChange}
              value={{
                types: query.types,
                categories: query.categories,
                statuses: query.statuses,
                owningTeams: query.owningTeams,
                enrolmentIds: query.enrolmentIds,
                searchFieldSelection: query.searchFieldSelection,
                startDate: query.startDate,
                endDate: query.endDate,
                metrics: query.metrics,
              }}
            />

            <Box sx={{ marginTop: '24px' }}>
              <Table
                isSelectable
                isLoading={isFetching}
                onSelect={handleSelect}
                onSelectAll={handleSelectAll}
                headers={headers}
                data={rows}
                isPaginated
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={data?.page ?? 1}
                count={data?.count ?? 0}
                rowsPerPage={query.pageSize}
              />
            </Box>
          </Stack>
        </CardContent>
      </Card>
      {showBulkStatusChange && data ? (
        <BulkStatusChangeDialog
          tasks={data.data.filter((d) => selected.some((s) => s === d.id))}
          onApply={handleBulkStatusChangeApply}
          onCancel={handleBulkStatusChangeCancel}
        />
      ) : null}
    </Stack>
  );
};

/** Filters */

const Filters = (props: {
  value?: QualityAssuranceTasksFilters;
  onChange?: (filters: QualityAssuranceTasksFilters) => void;
  teacherAccountId?: string;
}) => {
  const { value, onChange, teacherAccountId } = props;

  const handleChange = (property: keyof QualityAssuranceTasksFilters) => (val: string | string[] | number) => {
    if (value) {
      if (onChange) {
        onChange({ ...value, [property]: val });
      }
    }
  };

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item sx={{ marginRight: '16px' }}>
        <Typography variant="bodySmall">Filters</Typography>
      </Grid>
      <Box sx={{ marginRight: '16px' }}>
        <QualityAssuranceCategoryFilterDropDown onChange={handleChange('categories')} value={value?.categories ?? []} />
      </Box>
      <Box sx={{ marginRight: '16px' }}>
        <QualityAssuranceTypeFilterDropDown onChange={handleChange('types')} value={value?.types ?? []} />
      </Box>
      <Box sx={{ marginRight: '16px' }}>
        <QualityAssuranceMetricsFilterDropDown onChange={handleChange('metrics')} value={value?.metrics ?? []} />
      </Box>
      <Box sx={{ marginRight: '16px' }}>
        <QualityAssuranceTaskStatusFilterDropDown onChange={handleChange('statuses')} value={value?.statuses ?? []} />
      </Box>
      <Box sx={{ marginRight: '16px' }}>
        <DateFilter
          title="Starts At"
          onChange={(val) => handleChange('startDate')(val.toSQLDate()!)}
          value={value?.startDate ? DateTime.fromSQL(value.startDate) : undefined}
          popOverButtonSx={{ ml: 0, mr: '8px' }}
        />
      </Box>
      <Box sx={{ marginRight: '16px' }}>
        <DateFilter
          title="Ends At"
          onChange={(val) => handleChange('endDate')(val.toSQLDate()!)}
          value={value?.endDate ? DateTime.fromSQL(value.endDate) : undefined}
          popOverButtonSx={{ ml: 0, mr: '8px' }}
        />
      </Box>
      <Box sx={{ marginRight: '16px' }}>
        <QualityAssuranceOwningTeamFilterDropDown onChange={handleChange('owningTeams')} value={value?.owningTeams ?? []} />
      </Box>
      <Box sx={{ marginRight: '16px', width: '200px' }}>
        <QualityAssuranceEnrolmentIDsDropDown onChange={handleChange('enrolmentIds')} teacherAccountId={teacherAccountId} />
      </Box>
    </Grid>
  );
};

export const ActiveFilters = (props: { value?: QualityAssuranceTasksFilters; onChange?: (filters: QualityAssuranceTasksFilters) => void }) => {
  const { value, onChange } = props;

  const handleChange = (property: keyof QualityAssuranceTasksFilters) => (updatedValues: any | any[]) => {
    const updatedFilters = { ...value };
    updatedFilters[property] = updatedValues;
    if (onChange) {
      onChange(updatedFilters as any);
    }
  };

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item sx={{ marginRight: '16px' }}>
        <Typography variant="bodySmall">Active</Typography>
      </Grid>
      <Grid
        item
        sx={{
          '& > div': {
            marginRight: '16px',
          },
        }}
      >
        <ActiveQualityAssuranceFilterDropDown onChange={handleChange('categories')} value={value?.categories ?? []} />
        <ActiveQualityAssuranceTypeFilterDropDown onChange={handleChange('types')} value={value?.types ?? []} />
        <ActiveQualityAssuranceTaskStatusFilterDropDown onChange={handleChange('statuses')} value={value?.statuses ?? []} />
        <ActiveQualityAssuranceOwningTeamFilterDropDown onChange={handleChange('owningTeams')} value={value?.owningTeams ?? []} />
        <ActiveQualityAssuranceMetricsFilterDropDown onChange={handleChange('metrics')} value={value?.metrics ?? []} />
        {value?.startDate ? (
          <ActiveDateFilter label="Starts At" value={DateTime.fromSQL(value.startDate).toFormat('LL/dd/yyyy')} onChange={handleChange('startDate')} />
        ) : null}
        {value?.endDate ? (
          <ActiveDateFilter label="Ends At" value={DateTime.fromSQL(value.endDate).toFormat('LL/dd/yyyy')} onChange={handleChange('endDate')} />
        ) : null}
      </Grid>
    </Grid>
  );
};

enum QualityAssuranceIsActionedStatus {
  True = 'TRUE',
  False = 'FALSE',
  NotRequired = 'NOT_REQUIRED',
}

/** Bulk Change Dialog */

export const BulkStatusChangeDialog = (props: {
  onCancel: () => void;
  onApply: (status: QualityAssuranceStatus, isActioned?: boolean) => void;
  tasks: QualityAssuranceTasksResponse[];
}) => {
  const groupedTasks = groupBy(props.tasks, 'status');

  const [selectedStatus, setSelectedStatus] = useState<QualityAssuranceStatus>(QualityAssuranceStatus.New);
  const [isActioned, setIsActioned] = useState<QualityAssuranceIsActionedStatus>(QualityAssuranceIsActionedStatus.NotRequired);

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setSelectedStatus(value as QualityAssuranceStatus);
  };

  const handleIsActionedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setIsActioned(value as QualityAssuranceIsActionedStatus);
  };

  const handleApply = () => {
    const isActionedValue = isActioned === QualityAssuranceIsActionedStatus.NotRequired ? undefined : isActioned === QualityAssuranceIsActionedStatus.True;
    props.onApply(selectedStatus, isActionedValue);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={true}>
      <DialogContent>
        <Typography variant="headlineSmall">Bulk Status Change</Typography>

        <Typography marginTop="32px">Selected</Typography>
        <Stack
          sx={{
            border: 'solid 1px #B2B2B2',
            padding: '16px',
            borderRadius: '8px',
            backgroundColor: '#FAFAFA',
          }}
        >
          <Stack spacing="8px" direction="row">
            <DescriptionOutlined />
            <Stack spacing="8px">
              <Typography variant="bodyLarge">{groupedTasks.length} Tasks</Typography>
              <Stack direction="row">
                {groupedTasks.map((task, idx) => (
                  <StatusChip key={`${task}-${idx}`} status={task.name as QualityAssuranceStatus} count={task.count} />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack marginTop="24px">
          <Typography variant="bodySmall">Change all selected statuses to:</Typography>
          <FormControl>
            <RadioGroup onChange={handleStatusChange} value={selectedStatus}>
              <List>
                <ListItem>
                  <FormControlLabel value={QualityAssuranceStatus.New} control={<Radio />} label="Not Started" />
                </ListItem>
                <ListItem>
                  <FormControlLabel value={QualityAssuranceStatus.InProgress} control={<Radio />} label="Started" />
                </ListItem>
                <ListItem>
                  <FormControlLabel value={QualityAssuranceStatus.Done} control={<Radio />} label="Done" />
                </ListItem>
              </List>
            </RadioGroup>
          </FormControl>
        </Stack>

        <Stack marginTop="24px">
          <Typography variant="bodySmall">Change all selected tasks to as an Is Actioned value</Typography>
          <FormControl>
            <RadioGroup onChange={handleIsActionedChange} value={isActioned}>
              <List>
                <ListItem>
                  <FormControlLabel value={QualityAssuranceIsActionedStatus.NotRequired} control={<Radio />} label="Not Required" />
                </ListItem>
                <ListItem>
                  <FormControlLabel value={QualityAssuranceIsActionedStatus.True} control={<Radio />} label="Yes" />
                </ListItem>
                <ListItem>
                  <FormControlLabel value={QualityAssuranceIsActionedStatus.False} control={<Radio />} label="No" />
                </ListItem>
              </List>
            </RadioGroup>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, position: 'relative' }}>
        <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
        <Button size="large" variant="outlined" color="primary" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button size="large" variant="contained" onClick={handleApply}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/** Status Chips */

function StatusChip(props: { status: QualityAssuranceStatus; count?: number; error?: boolean }) {
  return (
    <Chip
      sx={{
        backgroundColor: props.error ? '#FCDAD3' : undefined,
        border: props.error ? '1px solid #BF3A1C' : undefined,
        paddingLeft: '8px',
        '& > svg': {
          width: '8px',
          height: '8px',
        },
      }}
      icon={<StatusIcon status={props.status} />}
      size="small"
      label={`${qualityAssuranceStatusLookup[props.status]}${props.count ? ` (${props.count})` : ''}`}
    />
  );
}

function StatusIcon(props: { status: QualityAssuranceStatus; sx?: SvgIconProps['sx'] }) {
  const { sx, status } = props;
  switch (status) {
    case QualityAssuranceStatus.New:
      return <FullCircleIcon fill="#000000" sx={sx} />;
    case QualityAssuranceStatus.InProgress:
      return <FullCircleIcon fill="#70AF22" sx={sx} />;
    case QualityAssuranceStatus.Done:
      return <FullCircleIcon fill="#1976D2" sx={sx} />;
  }
}

export default QualityAssuranceTasks;
