import { ReceiptLongOutlined } from '@mui/icons-material';
import { Button, Card, CardContent, CardHeader, Grid2, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Control } from 'react-hook-form';
import { UseFormResetField } from 'react-hook-form/dist/types/form';
import { ZOHO_QA_TASK_REPORT_FORM_URL } from '@hoot/common/constants';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import CardLine from '@hoot/pages/common/CardLine';
import { enumToTitleCase } from '@hoot/utils/stringUtils';
import { Dropdown } from '../../components/form/Dropdown';
import {
  QualityAssuranceStatus,
  qualityAssuranceCategoryLookup,
  qualityAssuranceDescriptionLookup,
  qualityAssuranceMetricLookup,
  qualityAssuranceOwningTeamLookup,
  qualityAssuranceStatusLookup,
  qualityAssuranceTypeLabel,
} from '../../hooks/api/tasks/enums';
import { QualityAssuranceTaskDetailsResource, QualityAssuranceTaskDetailsResponse } from '../../hooks/api/tasks/useGetQualityAssuranceTaskDetails';
import { FormOption } from '../../interfaces/form';
import { routes } from '../../routes/routes';
import NoneListItem from '../users/teachers/NoneListItem';
import { Form } from './QualityAssuranceTaskPage';

/** Interfaces */

type AssociatedParticipantProps =
  | {
      participantId: string;
      participantNumber: string;
      isParticipantStudent: true;
    }
  | {
      participantId?: string;
      participantNumber?: string;
      isParticipantStudent: false;
    };

interface QAProps {
  data: QualityAssuranceTaskDetailsResponse;
  control: Control<Form, any>;
  resetField: UseFormResetField<Form>;
}

/** Controls */

const QualityAssuranceDetails = (props: QAProps) => {
  const { data, control, resetField } = props;

  return (
    <Stack>
      <Grid2 spacing="16px" container>
        <Grid2 size={3}>
          <Stack spacing="16px">
            <StatusCard control={control} resetField={resetField} />
            <ActionedCard control={control} />
            <DetailsCard details={data} />
          </Stack>
        </Grid2>
        <Grid2 size={9}>
          <Stack spacing="16px">
            <FlaggedResourcesCard resources={data.resources} />
            <AssociatedLessonReviewCard studentName={data.studentName} studentProfileId={data.studentId} lessonReviewId={data.lessonReviewId} />
            <AssociatedParticipantCard participantId={data.studentId} participantNumber={data.studentPrefixedNumber} isParticipantStudent={true} />
            <AssociatedParticipantCard participantId={data.teacherId} participantNumber={data.teacherPrefixedNumber} isParticipantStudent={false} />
            <AssociatedScheduledLessonCard lessonId={data.lessonId} lessonNumber={data.lessonPrefixedNumber} />
            <AssociatedScheduledLessonSetCard lessonSetId={data.lessonSetId} lessonSetNumber={data.lessonSetPrefixedNumber} />
          </Stack>
        </Grid2>
      </Grid2>
    </Stack>
  );
};

function DetailsCard(props: { details: QualityAssuranceTaskDetailsResponse }) {
  const { hootEmployee } = useAuth();

  const buildTaskFormUrl = (details: QualityAssuranceTaskDetailsResponse) => {
    const queryParams = new URLSearchParams();

    const formattedDate = DateTime.now().toFormat('dd-LLL-yyyy');

    if (hootEmployee) {
      queryParams.append('first', hootEmployee.firstName);
      queryParams.append('last', hootEmployee.lastName);
    }

    queryParams.append('date', formattedDate);
    queryParams.append('team', qualityAssuranceOwningTeamLookup[details.owningTeam]);
    queryParams.append('raised', 'Admin Task');
    queryParams.append('task', details.taskNumber.toString());
    queryParams.append('category', qualityAssuranceTypeLabel[details.taskType]);
    queryParams.append('taskcategory', qualityAssuranceCategoryLookup[details.taskCategory]);

    if (details.lessonSetNumber) {
      queryParams.append('set', details.lessonSetNumber.toString());
    }

    if (details.lessonNumber) {
      queryParams.append('lesson', details.lessonNumber.toString());
    }

    queryParams.append('sub', enumToTitleCase(details.subRequired));

    if (details.studentNumber) {
      queryParams.append('student', details.studentNumber.toString());
    }

    if (details.teacherNumber) {
      queryParams.append('teacher', details.teacherNumber.toString());
    }

    return `${ZOHO_QA_TASK_REPORT_FORM_URL}?${queryParams.toString()}`;
  };

  const taskFormUrl = buildTaskFormUrl(props.details);

  return (
    <Card>
      <CardHeader title={'Task Details'} />
      <CardContent sx={{ pt: 0 }}>
        <CardLine title="Task Form" bodyText="Open in Zoho" openInNewURL={taskFormUrl} />
        <CardLine title="Type" bodyText={qualityAssuranceTypeLabel[props.details.taskType]} />
        <CardLine title="Category" bodyText={qualityAssuranceCategoryLookup[props.details.taskCategory]} />
        <CardLine title="Metric" bodyText={qualityAssuranceMetricLookup[props.details.failedThreshold]} />
        <CardLine title="Description" bodyText={qualityAssuranceDescriptionLookup[props.details.issue]} />
        <CardLine title="Failed Threshold" bodyText={enumToTitleCase(props.details.failedThreshold)} />
      </CardContent>
    </Card>
  );
}

/** Status Card */

function StatusCard(props: { control: Control<any>; resetField: UseFormResetField<Form> }) {
  const options = Object.entries(qualityAssuranceStatusLookup).map<FormOption<QualityAssuranceStatus>>(([key, label]) => ({
    label: label,
    value: key as QualityAssuranceStatus,
  }));

  return (
    <Card>
      <CardHeader title={'Status'} />
      <CardContent sx={{ pt: 0 }}>
        <Dropdown name="status" required label="Task Status" options={options} control={props.control} variant="outlined" />
      </CardContent>
    </Card>
  );
}

/** Actioned Card */

function ActionedCard(props: { control: Control<any> }) {
  const options: FormOption<string>[] = [
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' },
  ];

  return (
    <Card>
      <CardHeader title={'Is Actioned'} />
      <CardContent sx={{ pt: 0 }}>
        <Dropdown name="isActioned" required label="Is Actioned" options={options} control={props.control} variant="outlined" />
      </CardContent>
    </Card>
  );
}

/** Associated Participant Card */

function AssociatedParticipantCard(props: AssociatedParticipantProps) {
  return (
    <Card>
      <CardHeader title={props.isParticipantStudent ? 'Associated Student' : 'Associated Teacher'} />
      <CardContent sx={{ pt: 0 }}>
        <List dense>
          {!!props.participantId ? (
            <ListItem
              key={props.participantId}
              sx={{
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                borderRadius: '4px',
                padding: '8px 24px 8px 16px',
              }}
              secondaryAction={
                <Button
                  href={
                    props.isParticipantStudent
                      ? routes.users.students.details.url(props.participantId)
                      : routes.users.teachers.details.url(props.participantId)
                  }
                  variant="text"
                  color="primary"
                  size="large"
                >
                  View
                </Button>
              }
            >
              <ListItemIcon>
                <ReceiptLongOutlined />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography component="div" variant="bodyLarge">
                    {props.participantNumber ?? 'N/A'}
                  </Typography>
                }
              />
            </ListItem>
          ) : (
            <NoneListItem />
          )}
        </List>
      </CardContent>
    </Card>
  );
}

/** Associated Lesson Card */

function AssociatedScheduledLessonCard(props: { lessonId?: string; lessonNumber?: string }) {
  return (
    <Card>
      <CardHeader title={'Associated Scheduled Lesson'} />
      <CardContent sx={{ pt: 0 }}>
        <List dense>
          {!!props.lessonId ? (
            <ListItem
              key={props.lessonId}
              sx={{
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                borderRadius: '4px',
                padding: '8px 24px 8px 16px',
              }}
              secondaryAction={
                <Button href={routes.lessons.details.url(props.lessonId)} variant="text" color="primary" size="large">
                  View
                </Button>
              }
            >
              <ListItemIcon>
                <ReceiptLongOutlined />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography component="div" variant="bodyLarge">
                    {props.lessonNumber}
                  </Typography>
                }
              />
            </ListItem>
          ) : (
            <NoneListItem />
          )}
        </List>
      </CardContent>
    </Card>
  );
}

/** Associated Scheduled Lesson Set Card  */

function AssociatedScheduledLessonSetCard(props: { lessonSetId?: string; lessonSetNumber?: string }) {
  return (
    <Card>
      <CardHeader title={'Associated Scheduled Lesson Set'} />
      <CardContent sx={{ pt: 0 }}>
        <List dense>
          {!!props.lessonSetId && !!props.lessonSetNumber ? (
            <ListItem
              key={props.lessonSetId}
              sx={{
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                borderRadius: '4px',
                padding: '8px 24px 8px 16px',
              }}
              secondaryAction={
                <Button href={routes.lessonSets.details.url(props.lessonSetId)} variant="text" color="primary" size="large">
                  View
                </Button>
              }
            >
              <ListItemIcon>
                <ReceiptLongOutlined />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography component="div" variant="bodyLarge">
                    {props.lessonSetNumber}
                  </Typography>
                }
              />
            </ListItem>
          ) : (
            <NoneListItem />
          )}
        </List>
      </CardContent>
    </Card>
  );
}

/** Associated Lesson Review Card */

function AssociatedLessonReviewCard(props: { studentProfileId: string; studentName: string; lessonReviewId?: string }) {
  return (
    <Card>
      <CardHeader title={'Associated Lesson Review'} />
      <CardContent sx={{ pt: 0 }}>
        <List dense>
          {!!props.lessonReviewId ? (
            <ListItem
              key={props.lessonReviewId}
              sx={{
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                borderRadius: '4px',
                padding: '8px 24px 8px 16px',
              }}
              secondaryAction={
                <Button
                  href={routes.users.students.lessonReviews.urlWithId(props.studentProfileId, props.lessonReviewId)}
                  variant="text"
                  color="primary"
                  size="large"
                >
                  View
                </Button>
              }
            >
              <ListItemIcon>
                <ReceiptLongOutlined />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography component="div" variant="bodyLarge">
                    Lesson Review for {props.studentName}
                  </Typography>
                }
              />
            </ListItem>
          ) : (
            <NoneListItem />
          )}
        </List>
      </CardContent>
    </Card>
  );
}

/** Flagged Resources Card */

function FlaggedResourcesCard(props: { resources: QualityAssuranceTaskDetailsResource[] }) {
  return (
    <Card>
      <CardHeader title={'Flagged Resources'} />
      <CardContent sx={{ pt: 0 }}>
        <List dense>
          {props.resources.length > 0 ? (
            props.resources.map((resource) => (
              <ListItem
                key={resource.id}
                sx={{
                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                  borderRadius: '4px',
                  padding: '8px 24px 8px 16px',
                }}
                secondaryAction={
                  <Button href={routes.library.book.url(resource.id)} variant="text" color="primary" size="large">
                    View
                  </Button>
                }
              >
                <ListItemIcon>
                  <ReceiptLongOutlined />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography component="div" variant="bodyLarge">
                      {resource.title}
                    </Typography>
                  }
                />
              </ListItem>
            ))
          ) : (
            <NoneListItem />
          )}
        </List>
      </CardContent>
    </Card>
  );
}

export default QualityAssuranceDetails;
