import { Stack, Typography } from '@mui/material';

interface NoneItemProps {
  label?: string;
  value?: string;
  errorMessage?: string;
}

const NoneItem = (props: NoneItemProps) => {
  const { label, value = 'None', errorMessage } = props;
  return (
    <Stack direction="column">
      {label && (
        <Typography
          variant="bodySmall"
          color={errorMessage ? 'error' : 'unset'}
          sx={{
            userSelect: 'none',
          }}
        >
          {label}
        </Typography>
      )}
      <Typography
        variant="bodyLarge"
        sx={(theme) => ({
          p: 2,
          border: errorMessage ? `1px solid ${theme.palette.error.light}` : '1px solid rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          mt: 0.75,
          userSelect: 'none',
        })}
      >
        {value}
      </Typography>
      {errorMessage && (
        <Typography variant="bodySmall" color="error" sx={{ mt: 1 }}>
          {errorMessage}
        </Typography>
      )}
    </Stack>
  );
};

export default NoneItem;
