import axios, { AxiosError } from 'axios';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { DayOfWeek } from '@hoot/utils/dateTime';
import { QueryKey } from '../queryKeys';

export interface CreateTeacherAvailabilityRequest {
  teacherAccountId: string;
  timeblocks: {
    dayOfWeek: DayOfWeek;
    startTime: string;
    endTime: string;
  }[];
}

export interface Timeblock {
  id: string;
  dayOfWeek: number;
  startTime: string;
  endTime: string;
}

export interface GetTeacherAvailabilityResponse {
  timezone: string;
  timeblocks: Timeblock[];
}

async function createTeacherAvailability(request: CreateTeacherAvailabilityRequest): Promise<void> {
  const { data } = await axios.post<void>(`${config.apiUrl}/admin/availability`, request);
  return data;
}

export function useCreateTeacherAvailability(
  options: Omit<UseMutationOptions<void, AxiosError, CreateTeacherAvailabilityRequest>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((request) => createTeacherAvailability(request), options);
}

async function getTeacherAvailability(teacherAccountId: string) {
  const { data } = await axios.get<GetTeacherAvailabilityResponse>(`${config.apiUrl}/admin/availability`, { params: { teacherAccountId } });
  return data;
}

export function useGetTeacherAvailability(
  teacherAccountId: string,
  options?: Omit<UseQueryOptions<GetTeacherAvailabilityResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.TeacherAvailability, teacherAccountId], () => getTeacherAvailability(teacherAccountId), options);
}
