import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { DomainOutlined } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { DistrictRepresentativeLocation } from '@hoot/hooks/api/user/district-representative/useGetDistrictRepresentative';
import { routes } from '@hoot/routes/routes';
import { NoneListItem } from '../../../district-schools/details/Shared';

interface Props {
  locations: DistrictRepresentativeLocation[] | undefined;
}

const DistrictRepresentativeLocationsCard = (props: Props) => {
  const { locations } = props;
  const districts = locations?.filter((l) => l.type === LocationType.District);
  const schools = locations?.filter((l) => l.type === LocationType.School);

  return (
    <Grid container item marginTop="24px">
      <Card
        sx={{
          width: 1,
        }}
      >
        <CardContent sx={{ p: 3 }}>
          <Grid container item justifyContent="space-between">
            <Grid item>
              <Typography component="h3" variant="titleLarge">
                Locations Represented
              </Typography>
            </Grid>
            <Grid item></Grid>
          </Grid>
          <Box
            sx={{
              marginTop: '24px',
            }}
          >
            <Typography component="h5" variant="bodySmall">
              Districts
            </Typography>
            <List dense>
              {!!districts?.length ? (
                districts?.map((location) => (
                  <ListItem
                    key={location.id}
                    sx={{
                      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                      borderRadius: '4px',
                      padding: '8px 24px 8px 16px',
                    }}
                    secondaryAction={
                      <Button href={routes.districtsSchools.details.url(location.id)} variant="text" color="primary" size="large">
                        View
                      </Button>
                    }
                  >
                    <ListItemIcon>{<DomainOutlined />} </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography component="div" variant="bodyLarge">
                          {location.name}
                        </Typography>
                      }
                      secondary={
                        <Typography component="div" variant="labelMedium">
                          {location.shortId}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))
              ) : (
                <NoneListItem />
              )}
            </List>
          </Box>
          <Box
            sx={{
              marginTop: '24px',
            }}
          >
            <Typography component="h5" variant="bodySmall">
              Schools
            </Typography>
            <List dense>
              {!!schools?.length ? (
                schools?.map((location) => (
                  <ListItem
                    key={location.id}
                    sx={{
                      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                      borderRadius: '4px',
                      padding: '8px 24px 8px 16px',
                    }}
                    secondaryAction={
                      <Button href={routes.districtsSchools.details.url(location.id)} variant="text" color="primary" size="large">
                        View
                      </Button>
                    }
                  >
                    <ListItemIcon>{<DomainOutlined />} </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography component="div" variant="bodyLarge">
                          {location.name}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography component="h5" variant="bodySmall">
                            {location.districtName}
                          </Typography>
                          <Typography component="div" variant="labelMedium">
                            {location.shortId}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))
              ) : (
                <NoneListItem />
              )}
            </List>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DistrictRepresentativeLocationsCard;
