import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { FocusChallenges } from '@hoot-reading/hoot-core/dist/enums/user/student/focus-challenges.enum';
import { NotConvertedReason } from '@hoot-reading/hoot-core/dist/enums/user/student/not-converted-reason.enum';
import { PausedReason } from '@hoot-reading/hoot-core/dist/enums/user/student/paused-reason.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { StudentType } from '@hoot-reading/hoot-core/dist/enums/user/student/student-type.enum';
import { MembershipOption, StudentFutureField, StudentProfileInactiveReason } from '@hoot/interfaces/profile';
import { StudentDetailsForm } from './form';

export interface StudentRequest {
  name: string;
  hfsStudentNumber: string | null;
  birthYear: number | null;
  grade: StudentGrade | null;
  interests: string | null;
  pronouns: string | null;
  focusChallenges: FocusChallenges | null;
  parentNotes: string | null;
  hootNotes: string | null;
  hootNotesExpireDate: string | null;
  fundingPeriodEnd: string | null; // Funding Period status has been deprecated.
  membershipOptions: MembershipOption[];
  status: StudentStatus;
  previousStatus: StudentStatus | null;
  leadInitiationDate: string | null;
  conversionDate: string | null;
  notConvertedReason: NotConvertedReason | null;
  pauseEndDate: string | null;
  inactiveDate: string | null;
  inactiveReason: StudentProfileInactiveReason | null;
  pausedReason: PausedReason | null;
  futureStatus: StudentFutureField | null;
  effectiveDate: string | null;
  pausedDate: string | null;
  actualReturnDate: string | null;
  studentNumber: number | null;
  parentId: string;
  locationId: string;
  enrolmentIds: string[];
  studentType: StudentType;
}

export interface PartialStudentRequest extends Partial<StudentRequest> {}

export const studentFormToRequest = (updatedFormData: Partial<StudentDetailsForm>, isEditMode: boolean): PartialStudentRequest => {
  const request: PartialStudentRequest = {
    name: updatedFormData.name,
    hfsStudentNumber: updatedFormData.hfsStudentNumber,
    birthYear: updatedFormData.birthYear,
    grade: nullOrUndefined(updatedFormData.grade),
    interests: updatedFormData.interests,
    pronouns: updatedFormData.pronouns,
    focusChallenges: nullOrUndefined(updatedFormData.focusChallenges),
    parentNotes: updatedFormData.parentNotes,
    hootNotes: updatedFormData.hootNotes,
    hootNotesExpireDate: updatedFormData.hootNotesExpireDate,
    fundingPeriodEnd: updatedFormData.fundingPeriodEnd,
    membershipOptions: updatedFormData.membershipOptions,
    status: updatedFormData.status,
    previousStatus: updatedFormData.previousStatus,
    leadInitiationDate: updatedFormData.leadInitiationDate,
    conversionDate: updatedFormData.conversionDate,
    notConvertedReason: nullOrUndefined(updatedFormData.notConvertedReason),
    pauseEndDate: updatedFormData.pauseEndDate,
    inactiveDate: updatedFormData.inactiveDate,
    inactiveReason: nullOrUndefined(updatedFormData.inactiveReason),
    pausedReason: nullOrUndefined(updatedFormData.pausedReason),
    futureStatus: updatedFormData.futureStatus,
    effectiveDate: updatedFormData.effectiveDate,
    pausedDate: updatedFormData.pausedDate,
    actualReturnDate: updatedFormData.actualReturnDate,
    studentNumber: updatedFormData.studentNumber,
    parentId: updatedFormData.parentInfo?.id,
    locationId: updatedFormData?.hfsLocations ? updatedFormData?.hfsLocations[0].id : undefined,
    enrolmentIds: updatedFormData.enrolments?.map((e) => e.id),
    studentType: updatedFormData.studentType,
  };

  if (!isEditMode && !updatedFormData.status) {
    request.status = StudentStatus.Lead;
  }

  if (!isEditMode && !updatedFormData.studentType) {
    request.studentType = StudentType.HootTutoring;
  }

  return request;
};

function nullOrUndefined(data: any): any {
  if (data === null) {
    return null;
  }
  return data ? data : undefined;
}
