import { SubRequired } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import React from 'react';
import { FieldValues, Path, UseControllerProps, useController } from 'react-hook-form';
import { ConnectForm } from '@hoot/components/form/ConnectForm';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { DurationSelect } from '@hoot/components/form/selectFields/DurationSelect';
import { LessonTypeSelect } from '@hoot/components/form/selectFields/LessonTypeSelect';
import { SubRequiredSelect } from '@hoot/components/form/selectFields/SubRequiredSelect';
import { LessonDetailsForm, useEditLessonDetailsContext } from '@hoot/pages/lessons/edit/EditLessonDetailsContext';

interface Props {
  open: boolean;
  label: string;
  handleClose: () => void;
}

function EditDropdownValueModal<T extends FieldValues, TObj extends Path<T>>(props: Props & UseControllerProps<T, TObj>) {
  const { open, label, handleClose } = props;
  const { field } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });
  const { lessonDetails, lessonDetailsForm } = useEditLessonDetailsContext();

  const handleCancelClick = () => {
    const typedFieldName = field.name as Path<LessonDetailsForm>;
    lessonDetailsForm.resetField(typedFieldName, { keepDirty: false, keepTouched: false });

    handleClose();
  };

  const handleSaveClick = () => {
    const typedFieldName = field.name as Path<LessonDetailsForm>;
    if (typedFieldName === 'subRequired') {
      if (field.value === SubRequired.Yes) {
        lessonDetailsForm.setValue('teacherAccountId', null, { shouldDirty: true });
      }
    }
    handleClose();
  };

  if (!lessonDetails) {
    handleClose();
  }

  return (
    <>
      <Dialog open={open} onClose={handleCancelClick}>
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          Edit {label}
          <IconButton onClick={handleCancelClick}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: 425 }}>
          <Box sx={{ mt: 2 }}>
            <ConnectForm<LessonDetailsForm>>
              {({ control }) => {
                const typedFormFieldName = field.name as Path<LessonDetailsForm>;
                if (typedFormFieldName === 'duration') {
                  return <DurationSelect control={control} name={typedFormFieldName} />;
                } else if (typedFormFieldName === 'lessonType') {
                  return <LessonTypeSelect control={control} name={typedFormFieldName} />;
                } else if (typedFormFieldName === 'subRequired') {
                  return <SubRequiredSelect control={control} name={typedFormFieldName} />;
                } else {
                  return <Dropdown name={typedFormFieldName} control={control} label="unknonw" options={[]} />;
                }
              }}
            </ConnectForm>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3, position: 'relative' }}>
          <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
          <Button size="large" variant="outlined" color="primary" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button size="large" variant="contained" onClick={handleSaveClick}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditDropdownValueModal;
