import {
  ScheduledLessonLanguage,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonTheme,
  ScheduledLessonType,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface LessonSetLesson {
  lessonId: string;
  lessonNumber: number;
  status: ScheduledLessonStatus;
  startsAt: number;
  endsAt: number;
  durationInMinutes: number;
  studentProfileId: string;
  teacher?: LessonSetLessonTeacher;
  lessonDetails: LessonSetLessonDetails;
}

interface LessonSetLessonTeacher {
  id: string;
  name: string;
}

interface LessonSetLessonDetails {
  subject: ScheduledLessonSubject;
  isCustomerBillable: boolean;
  language: ScheduledLessonLanguage;
  accountId: string | undefined;
  enrolmentId: string | undefined;
  theme: ScheduledLessonTheme | undefined;
  isLessonSet: boolean;
  lessonType: ScheduledLessonType;
}

export function useGetLessonSetLessons(lessonSetId: string, options?: Omit<UseQueryOptions<LessonSetLesson[]>, 'queryKey' | 'queryFn'>) {
  return useQuery(
    [QueryKey.LessonSetLessons, lessonSetId],
    async () => {
      return (await axios.get<LessonSetLesson[]>(`${config.apiUrl}/admin/lesson-set/${lessonSetId}/lessons`)).data;
    },
    options,
  );
}
