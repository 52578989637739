import { ButtonProps, Checkbox, Popover, PopoverProps, TableCell, TableRow, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { PopoverButton } from './filterDropDown/common/PopoverButton';

export interface FilterOption<T> {
  label: string;
  value: T;
}

function CheckboxPopoverRow<T>(props: { option: FilterOption<T>; onChange?: (val: T, selected: boolean) => void; isSelected?: boolean }) {
  const { option, onChange, isSelected } = props;

  const handleClick = () => {
    if (onChange) {
      onChange(option.value, !isSelected);
    }
  };

  return (
    <TableRow
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={handleClick}
    >
      <TableCell>
        <Checkbox checked={isSelected} />
      </TableCell>
      <TableCell>
        <Typography variant="body1">{option.label}</Typography>
      </TableCell>
    </TableRow>
  );
}

function FilterDropDown<T>(props: {
  onChange?: (val: T[], selectedVal?: T) => void;
  title: string;
  value?: T[];
  options: FilterOption<T>[];
  dismissPopoverOnSelection?: boolean;
  popOverButtonSx?: ButtonProps['sx'];
  popOverSx?: PopoverProps['sx'];
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { title, onChange, dismissPopoverOnSelection, value = [], options, popOverButtonSx, popOverSx } = props;

  useEffect(() => {
    if (dismissPopoverOnSelection) {
      handleClose();
    }
  }, [dismissPopoverOnSelection]);

  const handleChange = (val: T, isSelected: boolean) => {
    if (onChange) {
      if (isSelected) {
        onChange([...value, val], val);
      } else {
        onChange(
          value.filter((v) => v !== val),
          val,
        );
      }
      handleClose(); // Close on every selection even though a multi select
    }
  };

  return (
    <div>
      <PopoverButton title={title} onClick={handleClick} popOverButtonSx={popOverButtonSx} />
      <Popover
        sx={{ mt: 1, maxHeight: '400px', ...popOverSx }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography>
          {options.map((option, idx) => (
            <CheckboxPopoverRow key={idx} option={option} onChange={handleChange} isSelected={value?.some((v) => v === option.value)} />
          ))}
        </Typography>
      </Popover>
    </div>
  );
}

export default FilterDropDown;
