import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface DistrictRepLocationResponse {
  doesDistrictRepHaveExistingLocation: boolean;
}

const getDistrictRepLocation = async (districtRepresentativeId: string): Promise<DistrictRepLocationResponse> => {
  const { data } = await axios.get<DistrictRepLocationResponse>(`${config.apiUrl}/admin/district-representative/location`, {
    params: { districtRepresentativeId },
  });

  return data;
};

export default function useGetDistrictRepLocation(
  districtRepresentativeId: string,
  options?: Omit<UseQueryOptions<DistrictRepLocationResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDistrictRepresentativeExistingLocation, districtRepresentativeId],
    () => getDistrictRepLocation(districtRepresentativeId),
    options,
  );
}
