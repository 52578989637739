import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import { ProductProgram } from '@hoot-reading/hoot-core/dist/enums/hfs/product-program.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { Button, CircularProgress, Grid, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { BackgroundCheck } from '@hoot/interfaces/teacher';
import ProductCapacity from '@hoot/pages/district-schools/products/ProductCapacity';
import ProductDetails from '@hoot/pages/district-schools/products/ProductDetails';
import { routes } from '@hoot/routes/routes';
import HeaderCard, { HeaderCardProps, HeaderTab } from '../../../components/ui/HeaderCard';
import useGetProduct from '../../../hooks/api/hfs/district-schools/products/useGetProduct';
import useUpdateProductRequest, { UpdateProductRequest } from '../../../hooks/api/hfs/district-schools/products/useUpdateProduct';
import ProductQualityMonitoring from './ProductQualityMonitoring';

type SearchParam = {
  locationId: string;
  productId: string;
};

export interface UpdateProductForm {
  name: string;
  status: ProductStatus;
  program: ProductProgram;
  startDate: string;
  endDate: string;
  numberOfEnrolments: number | '';
  numberOfStudents: number | '';
  numberOfLessons: number | '';
  numberOfLessonsPerSet: number | '';
  qualificationRequirements: HootQualification[];
  countryRequirements: HootSupportedCountry;
  backgroundCheckRequirements: BackgroundCheck[];
}

export enum ProductTabs {
  Details = 'DETAILS',
  Capacity = 'CAPACITY',
  QualityMonitoring = 'QUALITY_MONITORING',
}

interface Props {
  tab: ProductTabs;
}

const ProductsPage = (props: Props) => {
  const { tab } = props;
  const { locationId, productId } = useParams<SearchParam>() as SearchParam;

  const alert = useAlert();
  const navigate = useNavigate();

  const tabs: HeaderTab[] = [
    {
      id: ProductTabs.Details,
      label: 'Details',
      route: routes.districtsSchools.products.details.url(locationId, productId),
    },
    {
      id: ProductTabs.Capacity,
      label: 'Capacity',
      route: routes.districtsSchools.products.capacity.url(locationId, productId),
    },
    {
      id: ProductTabs.QualityMonitoring,
      label: 'Quality Monitoring',
      route: routes.districtsSchools.products.qualityMonitoring.url(locationId, productId),
    },
  ];

  const { control, formState, handleSubmit, reset } = useForm<UpdateProductForm>({
    defaultValues: {
      name: '',
      status: ProductStatus.Active,
      program: ProductProgram.HootForAll,
      startDate: '',
      endDate: '',
      numberOfEnrolments: '',
      numberOfStudents: '',
      numberOfLessons: '',
      numberOfLessonsPerSet: '',
      qualificationRequirements: [],
      countryRequirements: undefined,
      backgroundCheckRequirements: [],
    },
  });

  const { setIsDirty } = useBlockIfDirty();
  setIsDirty(formState.isDirty);

  const { data, isLoading, isError } = useGetProduct(productId, {
    enabled: !!productId,
    onSuccess: (data) => {
      reset({
        name: data.name,
        status: data.status,
        program: data.program,
        startDate: DateTime.fromMillis(data.startDate).toISODate()!,
        endDate: DateTime.fromMillis(data.endDate).toISODate()!,
        numberOfEnrolments: data.numberOfEnrolments,
        numberOfStudents: data.numberOfStudents,
        qualificationRequirements: data.qualificationRequirements,
        countryRequirements: data.countryRequirements,
        numberOfLessons: data.numberOfLessons,
        numberOfLessonsPerSet: data.numberOfLessonsPerSet,
        backgroundCheckRequirements: data.backgroundCheckRequirements,
      });
    },
  });

  const updateProductMutation = useUpdateProductRequest(productId);
  const queryClient = useQueryClient();

  const onTabSelected: HeaderCardProps['onTabSelected'] = (selectedTab) => {
    navigate(selectedTab.route);
    return !formState.isDirty;
  };

  const onSubmit = (data: UpdateProductForm) => {
    const request: UpdateProductRequest = {
      name: data.name,
      status: data.status,
      program: data.program,
      startDate: DateTime.fromISO(data.startDate).toMillis(),
      endDate: DateTime.fromISO(data.endDate).toMillis(),
      numberOfEnrolments: +data.numberOfEnrolments,
      numberOfStudents: +data.numberOfStudents,
      requirements: data.qualificationRequirements,
      countryRequirements: data.countryRequirements,
      numberOfLessons: +data.numberOfLessons,
      numberOfLessonsPerSet: +data.numberOfLessonsPerSet,
      backgroundCheckRequirements: data.backgroundCheckRequirements,
    };

    updateProductMutation.mutate(request, {
      onSuccess: () => {
        alert.success('Product successfully saved!');
        queryClient.invalidateQueries([QueryKey.GetProduct, productId]);
      },
      onError: () => {
        alert.error('There was an issue saving product!');
      },
    });
  };

  if (!data || isLoading) {
    return <CircularProgress />;
  } else if (isError || (!isLoading && !data) || !productId) {
    return <Stack alignItems="center">Failed to load the page!</Stack>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container direction="column">
        <HeaderCard
          title="Product"
          subtitle={data?.friendlyId || ''}
          canCopySubtitle
          showNavigateBackButton
          tabs={tabs}
          onTabSelected={onTabSelected}
          actionButtons={
            <>
              <Button type="submit" disabled={!formState.isDirty} color="primary" variant="contained" size="large">
                Save
              </Button>
            </>
          }
        />
        {tab === ProductTabs.Details ? <ProductDetails productId={productId} product={data} control={control} /> : null}
        {tab === ProductTabs.Capacity ? <ProductCapacity product={data} control={control} /> : null}
        {tab === ProductTabs.QualityMonitoring ? <ProductQualityMonitoring productId={productId} /> : null}
      </Grid>
    </form>
  );
};

export default ProductsPage;
