import { SortByQueryParams } from '../../interfaces/order-by';
import { PaginationQueryParams } from '../../interfaces/pagination';

enum DistrictSchoolPageQueryFilterParams {
  Search = 'search',
  Type = 'type',
  ProductStatus = 'product-status',
  EnrolmentStatus = 'enrolment-status',
  Country = 'country',
}

export const DistrictSchoolPageQueryParams = { ...DistrictSchoolPageQueryFilterParams, ...PaginationQueryParams, ...SortByQueryParams };
