import {
  EventVisibilityOption,
  FocusVisibilityOption,
  TimelineFilterType,
  TimelineViewOption,
} from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import { Button, Card, Fade, LinearProgress, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useState } from 'react';
import useGetStudentHootLevels from '@hoot/hooks/api/user/student/timeline/getStudentHootLevels';
import { FocusOptionType } from './FocusVisibilityMenu';
import TimelineFilters from './TimelineFilters';
import TimelineGrid from './TimelineGrid';
import TimelineLegendDialog from './TimelineLegendDialog';
import useGetStudentTimeline, { StudentTimelineRequest } from './useGetStudentTimeline';

const StudentTimelineTab = (props: { studentProfileId: string }) => {
  const { studentProfileId } = props;

  // check student levels before populating filters/
  const { data: hootLevels } = useGetStudentHootLevels(studentProfileId ?? '', {
    onSuccess: (data) => {
      const focusVisibilityOptions = [
        data.moduleOneInProgress ? FocusVisibilityOption.PreWordReading : undefined,
        data.moduleTwoInProgress ? FocusVisibilityOption.EarlyWordReading : undefined,
        data.moduleThreeInProgress ? FocusVisibilityOption.ComplexReading : undefined,
        data.hasAttemptedTextReading ? FocusVisibilityOption.TextReading : undefined,
        data.hasAttemptedTextLanguage ? FocusVisibilityOption.TextLanguage : undefined,
      ].filter((option) => !!option);
      setQuery({
        filterType: TimelineFilterType.DateBased,
        viewOption: TimelineViewOption.None,
        eventVisibility: [EventVisibilityOption.Hra, EventVisibilityOption.Pma, EventVisibilityOption.Lessons],
        focusVisibility: focusVisibilityOptions,
      });
      setFocusOptions({
        [FocusVisibilityOption.PreWordReading]: data.moduleOneInProgress,
        [FocusVisibilityOption.EarlyWordReading]: data.moduleTwoInProgress,
        [FocusVisibilityOption.ComplexReading]: data.moduleThreeInProgress,
        [FocusVisibilityOption.TextLanguage]: data.hasAttemptedTextLanguage,
        [FocusVisibilityOption.TextReading]: data.hasAttemptedTextReading,
        [FocusVisibilityOption.OtherAreas]: false,
      });
    },
  });

  const [focusOptions, setFocusOptions] = useState<FocusOptionType>({
    [FocusVisibilityOption.PreWordReading]: false,
    [FocusVisibilityOption.EarlyWordReading]: false,
    [FocusVisibilityOption.ComplexReading]: false,
    [FocusVisibilityOption.TextLanguage]: false,
    [FocusVisibilityOption.TextReading]: false,
    [FocusVisibilityOption.OtherAreas]: false,
  });

  const [query, setQuery] = useState<StudentTimelineRequest>({
    filterType: TimelineFilterType.DateBased,
    viewOption: TimelineViewOption.None,
    eventVisibility: [EventVisibilityOption.Hra, EventVisibilityOption.Pma, EventVisibilityOption.Lessons],
  });

  const { data: timelineData, isFetching } = useGetStudentTimeline(studentProfileId ?? '', query, {
    enabled: !!studentProfileId && !!hootLevels,
  });

  const [isTimelineLegendOpen, setIsTimelineLegendOpen] = useState<boolean>(false);

  const toggleLegendOpen = () => setIsTimelineLegendOpen((prev) => !prev);

  return (
    <>
      <Card sx={{ padding: 3 }}>
        <Box height="4px">
          <Fade in={isFetching}>
            <LinearProgress color="primary" />
          </Fade>
        </Box>
        <Stack gap={3}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="titleLarge">Timeline</Typography>
            <Button variant="outlined" size="large" onClick={toggleLegendOpen}>
              {isTimelineLegendOpen ? 'Hide Legend' : 'Show Legend'}
            </Button>
          </Stack>
          <TimelineFilters query={query} setQuery={setQuery} focusOptions={focusOptions} setFocusOptions={setFocusOptions} />
        </Stack>
        <TimelineGrid data={timelineData} studentProfileId={studentProfileId!} />
      </Card>
      <TimelineLegendDialog open={isTimelineLegendOpen} onDismiss={toggleLegendOpen} />
    </>
  );
};

export default StudentTimelineTab;
