import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { LocationSummaryQuery } from '@hoot/hooks/api/hfs/useGetLocationSummaryDashboard';
import { LocationSummaryEnrolmentsQuery } from '@hoot/hooks/api/hfs/useGetLocationSummaryEnrolments';
import { LocationSummaryProductsQuery } from '@hoot/hooks/api/hfs/useGetLocationSummaryProducts';
import { LocationSummaryStudentsQuery } from '@hoot/hooks/api/hfs/useGetLocationSummaryStudents';
import { DEFAULT_PAGE_SIZE } from '@hoot/interfaces/pagination';
import { getDistrictSchoolLabel } from '../LocationPage';
import SummaryDashboard from './SummaryDashboard';
import SummaryEnrolmentTable from './SummaryEnrolmentTable';
import SummaryFilters from './SummaryFilters';
import SummaryProductTable from './SummaryProductTable';
import SummaryStudentTable from './SummaryStudentTable';

interface Props {
  locationType: LocationType;
}

const defaultQueryFilters: LocationSummaryQuery = {
  productId: [],
  productStatus: [],
  enrolmentId: [],
  enrolmentStatus: [],
  schoolId: [],
  studentStatus: [],
  studentGrade: [],
  studentIndicator: [],
};

const LocationSummary = (props: Props) => {
  const { locationType } = props;
  const { locationId } = useParams();

  const label = getDistrictSchoolLabel(locationType);

  const [summaryDashboardQuery, setSummaryDashboardQuery] = useState<LocationSummaryQuery>({
    ...defaultQueryFilters,
  });

  const [summarySchoolQuery, setSummarySchoolQuery] = useState<LocationSummaryStudentsQuery>({
    ...defaultQueryFilters,
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
  });

  const [summaryEnrolmentQuery, setSummaryEnrolmentQuery] = useState<LocationSummaryEnrolmentsQuery>({
    ...defaultQueryFilters,
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
  });

  const [summaryProductQuery, setSummaryProductQuery] = useState<LocationSummaryProductsQuery>({
    ...defaultQueryFilters,
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
  });

  const handleFiltersChange = (val: LocationSummaryQuery) => {
    setSummaryDashboardQuery(val);
    setSummarySchoolQuery({
      ...summarySchoolQuery,
      ...val,
    });
    setSummaryProductQuery({
      ...summaryProductQuery,
      ...val,
    });
    setSummaryEnrolmentQuery({
      ...summaryEnrolmentQuery,
      ...val,
    });
  };

  return (
    <Card>
      <CardContent>
        <Stack gap={2}>
          <Typography variant="headlineSmall">{label} Summary</Typography>
          <Stack direction="column" gap={9}>
            <SummaryFilters value={summaryDashboardQuery} onChange={handleFiltersChange} locationId={locationId} />
            <SummaryDashboard locationId={locationId} query={summaryDashboardQuery} />
            <SummaryProductTable locationId={locationId} query={summaryProductQuery} setQuery={setSummaryProductQuery} />
            <SummaryEnrolmentTable locationId={locationId} query={summaryEnrolmentQuery} setQuery={setSummaryEnrolmentQuery} />
            <SummaryStudentTable locationId={locationId} query={summarySchoolQuery} setQuery={setSummarySchoolQuery} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default LocationSummary;
