import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '../../../config';

export interface UpdateAccountRequest {
  active: boolean;
}

export interface UpdateAccountResponse {
  id: string;
  name: string;
  active: boolean;
}

async function updateAccount(accountId: string, payload: UpdateAccountRequest) {
  const { data } = await axios.put(`${config.apiUrl}/admin/partner/account/${accountId}`, payload);
  return data;
}

export default function useUpdateAccount(siteId: string) {
  return useMutation<UpdateAccountResponse, AxiosError, UpdateAccountRequest>((data) => updateAccount(siteId, data));
}
