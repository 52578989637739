import { lessonLanguagesLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { useMemo } from 'react';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { FormSelect, FormSelectOption, SelectProps } from './FormSelect';

export function LanguageSelect<T extends FieldValues, TObj extends Path<T>>(props: SelectProps & UseControllerProps<T, TObj>) {
  const options = useMemo<FormSelectOption[]>(() => {
    return Object.entries(lessonLanguagesLookup).map((lessonLanguage) => {
      return {
        label: lessonLanguage[1],
        value: lessonLanguage[0],
      };
    });
  }, []);

  return <FormSelect label="Language" labelId="language-label" options={options} {...props} />;
}
