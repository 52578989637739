import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Checkbox, FormControlLabel, Grid, InputAdornment, Stack, TextField } from '@mui/material';
import React from 'react';
import { SearchStudentResponse } from '@hoot/hooks/api/end-users/useSearchStudentsV2';
import { LessonDetails, TentativeLesson } from '@hoot/pages/lessons/create-v2/context/StudentLessonDetailsProvider';

interface ReviewStudentTeacherLessonsCardProps {
  student: SearchStudentResponse;
  teacher: LessonDetails['teacher'];
  lessons: TentativeLesson[];
  hideStudentName: boolean;
}

const ReviewStudentTeacherLessonsCard = (props: ReviewStudentTeacherLessonsCardProps) => {
  const { student, teacher, lessons, hideStudentName } = props;

  const studentLabel = `${hideStudentName ? '' : student.name} ${student.studentPrefixedNumber}`;
  const teacherLabel = teacher ? `${teacher.name} (${teacher.number})` : 'None';

  return (
    <Stack
      sx={{
        borderRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.20)',
        padding: 3,
        gap: 3,
      }}
    >
      <TextField
        required
        fullWidth
        label="Student"
        value={studentLabel}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <PermIdentityIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        required
        fullWidth
        label="Teacher"
        value={teacherLabel}
        helperText={teacher ? undefined : 'These lessons will go up to the opportunities page for substitutes.'}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <PermIdentityIcon />
            </InputAdornment>
          ),
        }}
      />
      <Grid container>
        {lessons.map((lesson, index) => (
          <Grid key={`lesson-${index}`} item xs={12} sm={6}>
            <FormControlLabel checked disabled control={<Checkbox />} label={lesson.lessonAt.toFormat('ccc, LLL d, yyyy @ h:mma')} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default ReviewStudentTeacherLessonsCard;
