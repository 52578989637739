import { AttachFile, CancelOutlined, CheckCircleOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, Divider, Fade, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import useInvoiceImportCsv, { InvoicingImportResponse } from '@hoot/hooks/api/teacher-invoice/useInvoiceImportCsv';
import { InvoicingImportType } from '@hoot/pages/teacher-invoices/enums/invoicing-import-type.enum';
import theme from '@hoot/theme';
import { ImportInvoiceStep } from './InvoiceImportDialog';

interface IUserImportUploadFileProps {
  previousStep: () => void;
  nextStep: (step: ImportInvoiceStep) => void;
  handleClose: () => void;
  importType: InvoicingImportType | undefined;
  setFileNameCallback: React.Dispatch<React.SetStateAction<string>>;
  setImportResponseCallback: React.Dispatch<React.SetStateAction<InvoicingImportResponse | undefined>>;
  periodId: string;
}

const InvoiceImportUploadFile = (props: IUserImportUploadFileProps) => {
  const { previousStep, nextStep, handleClose, importType, setFileNameCallback, setImportResponseCallback, periodId } = props;

  const { handleSubmit } = useForm();
  const { mutate, isLoading: isUploading } = useInvoiceImportCsv();

  const [inputFileError, setInputFileError] = useState<string>('');
  const [isProcessFileEnabled, setIsProcessFileEnabled] = useState<boolean>(false);
  const [disableBackButton, setDisableBackButton] = useState<boolean>(false);
  const [isNextStepEnabled, setIsNextStepEnabled] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const queryClient = useQueryClient();

  const inputHasValue = !!fileInputRef.current?.files?.length;

  const handleClear = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsProcessFileEnabled(false);
    clearFileInput();
    setFileNameCallback('');
    setInputFileError('');
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onUpload = () => {
    if (!inputHasValue || !importType) {
      return;
    }

    if (fileInputRef.current.files?.[0].type !== 'text/csv') {
      setInputFileError('There was an error in the upload. Please check the file');
      return;
    }

    setFileNameCallback(fileInputRef.current.files[0].name);

    const uploadFile = fileInputRef.current?.files[0];
    const formData = new FormData();
    formData.append('file', uploadFile);
    formData.append('importType', importType);
    formData.append('periodId', periodId);

    mutate(formData, {
      onSuccess: () => {
        setIsNextStepEnabled(true);
        setDisableBackButton(true);
        setShowSuccessAlert(true);
        queryClient.invalidateQueries([QueryKey.SearchTeacherInvoiceUploadedTasks]);
      },
      onError: (err) => {
        console.error(err);
        setInputFileError(err.response?.data.message ?? 'Something went wrong, check the csv and try again.');
      },
      onSettled: (res) => setImportResponseCallback(res),
    });
  };

  const ClearButton = () => (
    <InputAdornment position="end">
      <IconButton onClick={(e) => handleClear(e)}>
        <CancelOutlined sx={{ cursor: 'pointer' }} />
      </IconButton>
    </InputAdornment>
  );

  const disableFileInput = showSuccessAlert;

  return (
    <>
      <DialogContent sx={{ p: 0, mt: 2 }}>
        <Typography variant="titleMedium" color="#000">
          Step 2 - Upload File
        </Typography>

        <Box sx={{ pt: 3, opacity: disableFileInput ? 0.4 : 1 }}>
          <form onSubmit={handleSubmit(onUpload)} encType="multipart/form-data">
            <Stack flexDirection="row" alignItems="center" gap={2}>
              <TextField
                type="file"
                name="file"
                inputRef={fileInputRef}
                onChange={() => setIsProcessFileEnabled(true)}
                label="File"
                disabled={disableFileInput}
                helperText={inputFileError || 'Must be a CSV file'}
                error={!!inputFileError}
                InputProps={{
                  startAdornment: inputHasValue && <AttachFile />,
                  endAdornment: inputHasValue && <ClearButton />,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: 400,
                  '& input::file-selector-button': {
                    display: 'none',
                  },
                  '& .MuiInputBase-input': {
                    pl: theme.spacing(2),
                  },
                }}
              />

              <Box sx={{ display: 'flex', position: 'relative', bottom: 11, gap: 2 }}>
                {!inputHasValue && (
                  <Button variant="outlined" size="large" onClick={(_) => fileInputRef.current?.click()}>
                    Select File
                  </Button>
                )}

                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={!isProcessFileEnabled || isUploading || disableFileInput}
                  loading={isUploading}
                >
                  Process File
                </LoadingButton>
              </Box>
            </Stack>
          </form>
        </Box>

        {/* File Successfully Uploaded message  */}
        {showSuccessAlert && (
          <Fade in={showSuccessAlert} timeout={400} unmountOnExit>
            <Stack direction="row" sx={{ mt: 3, p: 2, gap: 2, backgroundColor: '#EDF7ED', borderRadius: '8px' }}>
              <CheckCircleOutline htmlColor="#2E7D32" />
              <Stack direction="column" gap={1}>
                <Typography variant="titleMedium">File successfully uploaded!</Typography>
                <Typography variant="bodySmall">{showSuccessAlert && 'Click Next Step to review summary of upload'}</Typography>
              </Stack>
            </Stack>
          </Fade>
        )}
      </DialogContent>

      <Divider />

      <DialogActions sx={{ p: 0 }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack direction="row">
            <Button onClick={previousStep} disabled={disableBackButton} color="inherit" variant="outlined" size="large">
              Back
            </Button>
          </Stack>

          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button onClick={handleClose} color="inherit" variant="outlined" size="large">
              Close
            </Button>
            <Button onClick={() => nextStep(ImportInvoiceStep.Summary)} variant="contained" size="large" disabled={!isNextStepEnabled}>
              Next
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  );
};

export default InvoiceImportUploadFile;
