export enum Environment {
  Local = 'local',
  Staging = 'staging',
  Prod = 'prod',
  Demo = 'demo',
  Homer = 'homer',
  Flanders = 'flanders',
  Seymour = 'seymour',
  Wiggum = 'wiggum',
  Marge = 'marge',
  Bart = 'bart',
  Moe = 'moe',
}

export enum OperatorFilterType {
  IsAndIsNot = 'IS/IS_NOT',
  LessThanGreaterThan = 'LESS_THAN/GREATER_THAN',
  LessThanGreaterThanWithEquals = 'LESS_THAN/GREATER_THAN_WITH_EQUALS',
  IncludesDoesntInclude = 'INCLUDES/DOES_NOT_INCLUDE',
}

export enum FilterType {
  Date = 'DATE',
  Time = 'TIME',
  DateTime = 'DATE_TIME',
  SingleSelect = 'SINGLE_SELECT',
  MultiSelect = 'MULTI_SELECT',
}

export enum OperatorFilter {
  Is = 'IS',
  IsNot = 'IS_NOT',
  LessThan = 'LESS_THAN',
  GreaterThan = 'GREATER_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  Includes = 'INCLUDES',
  DoesNotInlude = 'DOES_NOT_INCLUDE',
}

export const ENV_NAME = import.meta.env.VITE_HOOT_ENV?.toString() || Environment.Local;

export const IS_PROD = ENV_NAME === Environment.Prod;
export const IS_STAGING = ENV_NAME === Environment.Staging;
export const IS_LOCAL = ENV_NAME === Environment.Local;

export const IS_DEMO = [
  Environment.Demo,
  Environment.Homer,
  Environment.Flanders,
  Environment.Seymour,
  Environment.Wiggum,
  Environment.Marge,
  Environment.Bart,
  Environment.Moe,
].includes(ENV_NAME as Environment);

export const WINNIPEG_TIMEZONE = 'America/Winnipeg';

export const ASPECT_RATIO = 0.742;

export const LOCAL_APP_VERSION = import.meta.env.VITE_VERSION;

export const VALID_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const VALID_HOOT_EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@hootreading\.com$/;

export const VALID_ZIP_POSTAL_ZIP4_CODE = /^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$|^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/;
export const VALID_CANADA_POSTAL_CODE = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
export const VALID_US_ZIP_CODE = /^(?!0{3})[0-9]{3,5}$/;

export const rowsPerPageOptions = [10, 25, 100];
export const defaultRowsPerPage = rowsPerPageOptions[0];

export const ONE_MINUTE_IN_MS = 60 * 1000;
export const ONE_WEEK_IN_MS = 604800000;
export const SERVER_TIME_SYNC_PERIOD = 30 * ONE_MINUTE_IN_MS;
export const FEATURE_FLAG_SYNC_PERIOD = 5 * ONE_MINUTE_IN_MS;

export const MAX_HOURS_UNTIL_SCHEDULED_START_TIME_WITHOUT_SUB = 2;

export const TABLE_ROW_HOVER_COLOUR = 'rgba(25, 118, 210, 0.08)';

export const HUBSPOT_CONTACT_URL = 'https://app.hubspot.com/contacts/21171605/contact/';
export const ZENDESK_CONTACT_URL = 'https://hootreading.zendesk.com/agent/users/';
export const ZOHO_QA_TASK_REPORT_FORM_URL =
  'https://forms.zohopublic.com/hootreading1/form/QATaskReport/formperma/aKZwOZU7USr-1XAxPL4NHsissspIqv1wFcUjG_6R3vQ';

export enum Months {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export enum DateFormats {
  /** Example -> `Nov 8, 2024` */
  SHORT_MDY = 'LLL d, yyyy',
  /** Example -> `November 27, 2024` */
  FULL_MDY = 'MMMM d, yyyy',
  /** Example -> `07/19/2024` */
  NUMERIC_MDY = 'MM/dd/yyyy',
}

export const MONTHS_WITH_NONE = ['None', ...Object.values(Months)];

export const MYLTorontoSchoolBoard = 'b59e7f96-47c6-4cc5-8847-f50f124ed11a';

export const TIME_FORMAT = 'h:mm a';

export const REDACTED_NOTE = 'REDACTED';

export const NumberPrefixes = {
  Student: 'ST-',
  Parent: 'PR-',
  DistrictRep: 'DR-',
  Teacher: 'T-',
  Lesson: 'LN-',
  LessonSet: 'LS-',
  Task: 'TK-',
  TeacherShift: 'SH-',
};

export enum SessionStorageKey {
  /** Remembers the last coordinates of FloatingDialog.tsx. */
  FLOATING_DIALOG_POSITION = 'floatingDialogPosition',
}
