import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';
import { ShiftConflictSeverity } from '../user/teacher/useGetTeacherShiftConflicts';

export interface ValidateRescheduleShiftsRequest {
  updatedShiftStartsAt: number;
  updatedShiftEndsAt: number;
}

export interface ValidateRescheduledShiftsResponse {
  proposedShifts: ProposedShift[];
}

export interface ProposedShift {
  shiftId: string;
  originalStartsAt: number;
  originalEndsAt: number;
  proposedStartsAt: number;
  proposedEndsAt: number;
  conflictSeverity?: ShiftConflictSeverity;
  conflictMessage?: string;
}

async function validateRescheduleShifts(shiftId: string, request: ValidateRescheduleShiftsRequest) {
  const { data } = await axios.post<ValidateRescheduledShiftsResponse>(
    `${config.apiUrl}/admin/teacher-shift/${shiftId}/validate-rescheduled`,
    request,
  );
  return data;
}

export default function useValidateRescheduleShifts(
  shiftId: string,
  options?: Omit<
    UseMutationOptions<ValidateRescheduledShiftsResponse, AxiosError<ErrorResponseDto>, ValidateRescheduleShiftsRequest>,
    'queryKey' | 'queryFn'
  >,
) {
  return useMutation((request) => validateRescheduleShifts(shiftId, request), options);
}
