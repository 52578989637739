import axios from 'axios';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import { config } from '@hoot/config';
import { InvoicePeriodDetailsResponse } from '@hoot/hooks/api/teacher-invoice/useSearchTeacherInvoiceBillableTasks';
import { SortableQuery } from '@hoot/interfaces/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/interfaces/pagination';
import { InvoiceStatus } from '@hoot/pages/teacher-invoices/enums/enums';
import { QueryKey } from '../queryKeys';

export enum TeacherInvoiceUploadedTasksQuerySortKeyEnum {
  Teacher = 'teacher',
  Date = 'date',
  Project = 'project',
  Description = 'description',
  Amount = 'amount',
  Action = 'action',
}

export interface SearchTeacherInvoiceUploadedTasksQuery extends PaginatableQuery, SortableQuery<TeacherInvoiceUploadedTasksQuerySortKeyEnum> {
  search?: string;
  project?: string[];
}

export interface TeacherInvoiceUploadedTasksResponse {
  id: string;
  teacherId: string;
  teacherName: string;
  project: string;
  projectId: string;
  date: string;
  total: number;
  lesson: number;
  description: string;
  invoiceStatus: InvoiceStatus;
}

const searchTeacherInvoiceUploadedBillableTasks = async (
  period: string,
  searchParams: SearchTeacherInvoiceUploadedTasksQuery,
): Promise<GenericPaginatedResponse<TeacherInvoiceUploadedTasksResponse, InvoicePeriodDetailsResponse>> => {
  const { data } = await axios.get(`${config.apiUrl}/admin/invoicing-import/search-uploaded-tasks/${period}`, {
    params: searchParams,
  });
  return data;
};

export default function useSearchTeacherInvoiceUploadedBillableTasks(
  periodId: string,
  searchParams: SearchTeacherInvoiceUploadedTasksQuery,
  options?: Omit<
    UseQueryOptions<GenericPaginatedResponse<TeacherInvoiceUploadedTasksResponse, InvoicePeriodDetailsResponse>>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery(
    [QueryKey.SearchTeacherInvoiceUploadedTasks, periodId, searchParams],
    () => searchTeacherInvoiceUploadedBillableTasks(periodId, searchParams),
    options,
  );
}
