import { NumberPrefixes } from '@hoot/common/constants';

export const capitalizeFirstLetter = (word: string) => {
  if (!word) {
    return '';
  }
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

//for names that are too long to display (should show full name with title attribute)
export function formatLongName(name: string, cutoff: number) {
  const displayName: string = name.length > cutoff ? name.slice(0, cutoff).trim() + '...' : name;
  return displayName.trim();
}

/*
 * This function returns the enum as a legible sentence.
 */
export function enumToSentence(text: string): string {
  if (!text) {
    return '';
  }

  const words = text.split(/_/g);

  let sentence = words[0].slice(0, 1).toUpperCase() + words[0].slice(1).toLowerCase() + ' ';

  words.forEach((word, index) => {
    if (index !== 0) {
      sentence += word.toLowerCase() + ' ';
    }
  });

  return sentence.trim();
}

export function enumToURLString(text: string): string {
  if (!text) {
    return '';
  }

  return text.replace(/_/g, '-').toLowerCase().trim();
}

/*
 * This function capitalizes only the first letter on each word in the string.
 */
export function enumToTitleCase(text: string): string {
  if (!text) {
    return '';
  }

  const words = text.split(/_/g);

  let properCaseString = '';

  words.forEach((word) => {
    properCaseString += word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase() + ' ';
  });

  return properCaseString.trim();
}

export const removePrefix = (text?: string): string | undefined => {
  if (!text) return text;
  let result = text;
  Object.values(NumberPrefixes).forEach((prefix) => {
    if (text.toUpperCase().startsWith(prefix)) {
      result = text.slice(prefix.length);
    }
  });
  return result;
};
