import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const EmptyCircleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="4.66699" cy="4" r="3" stroke={props.stroke ?? '#B3261E'} strokeWidth="2" />
    </SvgIcon>
  );
};

export default EmptyCircleIcon;
