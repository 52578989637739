import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Button, Card, CardContent, CardHeader, FormHelperText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Control, UseFormResetField } from 'react-hook-form';
import { FormState, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form/dist/types/form';
import { DatePicker } from '@hoot/components/form/DatePicker';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { StudentProfileResponse } from '@hoot/hooks/api/end-users/useGetStudentProfile';
import {
  StudentFutureField,
  pausedReasonOptions,
  studentProfileInactiveReasonOptions,
  studentProfileNotConvertedReason,
  studentProfileStatus,
} from '@hoot/interfaces/profile';
import { StudentDetailsForm } from '../form';
import FutureStatusModal from './futureStatusModal';

interface Props {
  isEditMode?: boolean;
  studentProfile?: StudentProfileResponse;
  control: Control<StudentDetailsForm>;
  formState: FormState<StudentDetailsForm>;
  setValue: UseFormSetValue<StudentDetailsForm>;
  watch: UseFormWatch<StudentDetailsForm>;
  trigger: UseFormTrigger<StudentDetailsForm>;
  resetField: UseFormResetField<StudentDetailsForm>;
  setShowFunctionalityDisabledModal: Dispatch<SetStateAction<boolean>>;
  disableFields: boolean;
}

const FutureStatusInfoCard = (props: { setValue: UseFormSetValue<StudentDetailsForm>; watch: UseFormWatch<StudentDetailsForm> }) => {
  const { setValue, watch } = props;
  const { futureStatus, pauseEndDate, effectiveDate, inactiveReason, pausedReason } = watch();

  const handleDeleteFutureStatus = () => {
    if (futureStatus === StudentStatus.Inactive) {
      setValue('inactiveReason', '', { shouldDirty: true });
    }
    if (futureStatus === StudentStatus.Paused) {
      setValue('pausedReason', '', { shouldDirty: true });
      setValue('pauseEndDate', null, { shouldDirty: true });
    }
    setValue('effectiveDate', null, { shouldDirty: true });
    setValue('futureStatus', null, { shouldDirty: true });
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: '100%', backgroundColor: '#FEF4E9', borderRadius: 4, padding: 3 }}
    >
      <Stack gap={1}>
        <Typography variant="bodyLarge">Scheduled to {futureStatus === StudentStatus.Inactive ? 'Inactive' : 'Pause'}</Typography>
        <Typography variant="bodySmall">When: {effectiveDate}</Typography>
        <Typography variant="bodySmall">
          Why: {futureStatus === StudentStatus.Inactive ? capitalCase(inactiveReason) : capitalCase(pausedReason)}
        </Typography>
        {futureStatus === StudentStatus.Paused ? <Typography variant="bodySmall">Returns: {pauseEndDate}</Typography> : null}
      </Stack>
      <Stack>
        <Button type="button" color="inherit" onClick={handleDeleteFutureStatus} sx={{ color: '#BF3A1C' }}>
          Remove
        </Button>
      </Stack>
    </Stack>
  );
};

const StudentStatusActionButton = (props: {
  disabled: boolean;
  control: Control<StudentDetailsForm, object>;
  formState: FormState<StudentDetailsForm>;
  setValue: UseFormSetValue<StudentDetailsForm>;
  watch: UseFormWatch<StudentDetailsForm>;
  trigger: UseFormTrigger<StudentDetailsForm>;
  resetField: UseFormResetField<StudentDetailsForm>;
  isEditMode?: boolean;
  setShowFunctionalityDisabledModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { disabled, control, formState, setValue, watch, trigger, resetField, isEditMode, setShowFunctionalityDisabledModal } = props;
  const [actionAnchorEl, setActionAnchorEl] = useState<Element | null>(null);
  const open = Boolean(actionAnchorEl);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [futureStatusField, setFutureStatusField] = useState<StudentFutureField>();
  const { status } = watch();

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isEditMode) {
      setActionAnchorEl(event.currentTarget);
    } else {
      setShowFunctionalityDisabledModal(true);
    }
  };

  const handleActionClose = () => {
    setActionAnchorEl(null);
  };

  const handleShowModalType = (futureStatusField: StudentFutureField) => () => {
    setShowModal(true);
    setFutureStatusField(futureStatusField);
    setActionAnchorEl(null);
  };

  return (
    <Stack>
      <Button
        type="button"
        color="inherit"
        variant="outlined"
        disabled={disabled}
        startIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={handleActionClick}
      >
        Action
      </Button>
      <Menu
        open={open}
        anchorEl={actionAnchorEl}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: 1 }}
      >
        <MenuItem onClick={handleShowModalType(StudentStatus.Inactive)} disabled={status === StudentStatus.Inactive}>
          Create Future Inactive
        </MenuItem>
        <MenuItem onClick={handleShowModalType(StudentStatus.Paused)} disabled={status === StudentStatus.Paused}>
          Create Future Pause
        </MenuItem>
      </Menu>
      {showModal && futureStatusField ? (
        <FutureStatusModal
          open={showModal}
          handleClose={() => setShowModal(false)}
          futureStatusField={futureStatusField}
          control={control}
          formState={formState}
          setValue={setValue}
          watch={watch}
          trigger={trigger}
          resetField={resetField}
        />
      ) : null}
    </Stack>
  );
};

const StudentStatusCard = (props: Props) => {
  const { isEditMode, studentProfile, control, formState, setValue, watch, trigger, resetField, setShowFunctionalityDisabledModal, disableFields } =
    props;
  const { status, futureStatus } = watch();
  const [prevStatus, setPreviousStatus] = useState<StudentStatus | undefined>(studentProfile?.previousStatus ?? undefined);
  const filteredStudentProfileStatus = studentProfileStatus.filter((s) => s.value !== futureStatus);
  const shouldShowFundingPeriodEnd = studentProfile?.parentInfo?.account?.isActive;

  useEffect(() => {
    if (status !== studentProfile?.status) {
      setPreviousStatus(studentProfile?.status ?? undefined);
    } else {
      setPreviousStatus(studentProfile?.previousStatus ?? undefined);
    }
  }, [status, studentProfile?.status, studentProfile?.previousStatus]);

  useEffect(() => {
    switch (status) {
      case StudentStatus.Active:
        setValue('inactiveDate', null, { shouldDirty: true });
        setValue('notConvertedReason', '', { shouldDirty: true });
        if (prevStatus === StudentStatus.Paused || prevStatus === StudentStatus.Inactive) {
          setValue('conversionDate', null, { shouldDirty: true });
          setValue('actualReturnDate', DateTime.now().toISODate(), { shouldDirty: true });
        }
        if (prevStatus === StudentStatus.Lead) {
          setValue('conversionDate', DateTime.now().toISODate(), { shouldDirty: true });
        }
        break;
      case StudentStatus.Cold:
        setValue('conversionDate', null, { shouldDirty: true });
        setValue('inactiveDate', null, { shouldDirty: true });
        break;
      case StudentStatus.Inactive:
        setValue('conversionDate', null, { shouldDirty: true });
        setValue('notConvertedReason', '', { shouldDirty: true });
        setValue('inactiveReason', '', { shouldDirty: true });
        setValue('inactiveDate', DateTime.now().toISODate(), { shouldDirty: true });
        break;
      case StudentStatus.Lead:
        setValue('conversionDate', null, { shouldDirty: true });
        setValue('notConvertedReason', '', { shouldDirty: true });
        setValue('inactiveDate', DateTime.now().toISODate(), { shouldDirty: true });
        setValue('leadInitiationDate', DateTime.now().toISODate(), { shouldDirty: true });
        break;
      case StudentStatus.Paused:
        setValue('conversionDate', null, { shouldDirty: true });
        setValue('inactiveDate', null, { shouldDirty: true });
        setValue('notConvertedReason', '', { shouldDirty: true });
        break;
    }

    if (futureStatus === StudentStatus.Inactive) {
      setValue('pausedReason', '', { shouldDirty: true });
      setValue('pauseEndDate', null, { shouldDirty: true });
    }
    if (futureStatus === StudentStatus.Paused) {
      setValue('inactiveReason', '', { shouldDirty: true });
    }
    if (!futureStatus) {
      setValue('effectiveDate', null, { shouldDirty: true });
      setValue('pauseEndDate', null, { shouldDirty: true });
      if (status === StudentStatus.Paused) {
        setValue('inactiveReason', '', { shouldDirty: true });
      }
      if (status === StudentStatus.Inactive) {
        setValue('pausedReason', '', { shouldDirty: true });
      }
    }

    if (prevStatus) {
      setValue('previousStatus', prevStatus, { shouldDirty: true });
    }
  }, [status, prevStatus, futureStatus, setValue]);

  return (
    <Card sx={{ pl: 3, pt: 3, pr: 3 }}>
      <CardHeader
        sx={{ p: 0, pb: 3 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="titleLarge">
              Status
            </Typography>
            <StudentStatusActionButton
              disabled={!!futureStatus || disableFields}
              control={control}
              formState={formState}
              setValue={setValue}
              watch={watch}
              trigger={trigger}
              resetField={resetField}
              isEditMode={isEditMode}
              setShowFunctionalityDisabledModal={setShowFunctionalityDisabledModal}
            />
          </Stack>
        }
      />

      <CardContent sx={{ p: 0 }}>
        <Stack gap={3}>
          <Stack>
            <Dropdown
              name="status"
              label="Student Profile Status*"
              options={filteredStudentProfileStatus}
              control={control}
              variant="outlined"
              disabled={disableFields}
            />
            {!formState.dirtyFields.status && isEditMode ? (
              <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                Since {DateTime.fromMillis(Number(studentProfile?.updatedStatusAt)).toFormat('MM/dd/yyyy')}
              </FormHelperText>
            ) : null}
          </Stack>

          {status === StudentStatus.Lead ? (
            <Stack>
              <DatePicker
                name="leadInitiationDate"
                label="Lead Initiation Date*"
                control={control}
                rules={{
                  required: !futureStatus,
                  validate: (val: string | null) => {
                    if (val && val > DateTime.now().toISODate()!) {
                      return "Lead Initiation Date must be on or before today's date";
                    }
                    return true;
                  },
                }}
                disableFuture
              />
              {formState.errors.leadInitiationDate ? (
                <FormHelperText error={true} sx={{ paddingLeft: 2 }}>
                  {formState.errors.leadInitiationDate.message}
                </FormHelperText>
              ) : null}
            </Stack>
          ) : null}

          {status === StudentStatus.Cold ? (
            <Dropdown
              name="notConvertedReason"
              label="Not Converted Reason*"
              options={studentProfileNotConvertedReason}
              control={control}
              rules={{ required: !futureStatus }}
              variant="outlined"
            />
          ) : null}

          {status === StudentStatus.Paused ? (
            <Stack gap={4}>
              <Dropdown
                name="pausedReason"
                label="Paused Reason*"
                options={pausedReasonOptions}
                control={control}
                rules={{ required: true }}
                variant="outlined"
              />
              <Stack>
                <DatePicker name="pauseEndDate" label="Paused End Date*" control={control} rules={{ required: true }} />
                <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                  After this date, the user will automatically be set back to Active. Schedules and billing will resume.
                </FormHelperText>
              </Stack>
            </Stack>
          ) : null}

          {status === StudentStatus.Inactive ? (
            <Stack gap={4}>
              <Dropdown
                name="inactiveReason"
                label="Inactive Reason*"
                options={studentProfileInactiveReasonOptions}
                control={control}
                rules={{ required: true }}
                variant="outlined"
              />
              {formState.dirtyFields.status ? (
                <DatePicker name="inactiveDate" label="Inactive Start Date*" control={control} rules={{ required: true }} />
              ) : null}
            </Stack>
          ) : null}

          {status === StudentStatus.Active && formState.dirtyFields.status ? (
            prevStatus === StudentStatus.Inactive || prevStatus === StudentStatus.Paused ? (
              <DatePicker name="actualReturnDate" label="Return Date" control={control} rules={{ required: true }} />
            ) : (
              <DatePicker name="conversionDate" label="Conversion Date" control={control} rules={{ required: true }} />
            )
          ) : null}

          {futureStatus ? <FutureStatusInfoCard setValue={setValue} watch={watch} /> : null}

          {shouldShowFundingPeriodEnd ? (
            <DatePicker name="fundingPeriodEnd" label="Funding Period End Date" control={control} rules={{ required: false }} />
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default StudentStatusCard;
