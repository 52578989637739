import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import React from 'react';
import { InstructionalFocusModule } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentHootLevelReport';

interface Props {
  instructionalFocusModule: InstructionalFocusModule[];
}

const StudentAssessmentState = (props: Props) => {
  const { instructionalFocusModule } = props;

  return (
    <Card sx={{ p: 2, backgroundColor: '#FAFAFA' }}>
      <CardHeader sx={{ p: 0, pb: 3 }} title={<Typography variant="titleLarge">Assessment State</Typography>} />
      <CardContent sx={{ p: 0 }}>
        <Stack gap={2}>
          {instructionalFocusModule.map((module) => (
            <Stack key={module.order}>
              <Typography variant="titleMedium">{module.label}</Typography>
              <Typography variant="bodyMedium">{sentenceCase(module.status)}</Typography>
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default StudentAssessmentState;
