import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

async function softDeleteStudentProfile(studentProfileId: string): Promise<void> {
  await axios.delete(`${config.apiUrl}/admin/student-profile/${studentProfileId}`);
}

export default function useSoftDeleteStudentProfile(options?: Omit<UseMutationOptions<void, AxiosError, string>, 'mutationKey' | 'mutationFn'>) {
  return useMutation<void, AxiosError, string>((studentProfileId) => softDeleteStudentProfile(studentProfileId), options);
}
