import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { ConnectForm } from '@hoot/components/form/ConnectForm';
import { CancellationReasonSelect } from '@hoot/components/form/selectFields/CancellationReasonSelect';
import { LessonDetailsForm, useEditLessonDetailsContext } from '@hoot/pages/lessons/edit/EditLessonDetailsContext';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export interface CheckListModalItemItem {
  label: string;
  value: ScheduledLessonStatus;
}

const allLessonStatuses: Array<CheckListModalItemItem> = new Array<CheckListModalItemItem>(
  { value: ScheduledLessonStatus.Scheduled, label: 'Scheduled' },
  { value: ScheduledLessonStatus.CompletedUnsuccessfully, label: 'Completed Unsuccessfully' },
  { value: ScheduledLessonStatus.CompletedSuccessfully, label: 'Completed Successfully' },
  { value: ScheduledLessonStatus.Rescheduled, label: 'Rescheduled' },
  { value: ScheduledLessonStatus.Cancelled, label: 'Cancelled' },
);

const statusesRequiringCancellationReason = [ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled];

const EditLessonStatusModal = (props: Props) => {
  const { open, handleClose } = props;
  const { lessonDetails, lessonDetailsForm } = useEditLessonDetailsContext();
  const { lessonStatus } = lessonDetailsForm.watch();
  const handleCancelClick = () => {
    lessonDetailsForm.resetField('lessonStatus', { keepDirty: false, keepTouched: false });
    lessonDetailsForm.resetField('cancellationReason', { keepDirty: false, keepTouched: false });
    handleClose();
  };

  const handleSaveClick = () => {
    handleClose();
  };

  const onItemClicked =
    (listItem: CheckListModalItemItem, lessonStatusFieldOnChange: (...event: any[]) => void): ListItemButtonProps['onClick'] =>
    (event) => {
      // Else this might get triggered twice when you click on the checkbox.
      event.stopPropagation();

      // if there is a cancellation reason but the status is no longer cancelled, remove it
      if (listItem.value !== ScheduledLessonStatus.Cancelled && !!lessonDetailsForm.getValues('cancellationReason')) {
        lessonDetailsForm.setValue('cancellationReason', '');
      }

      lessonStatusFieldOnChange(listItem.value);
      lessonDetailsForm.trigger('cancellationReason');
    };

  const selectableLessonStatuses =
    lessonDetails?.lessonStatus !== ScheduledLessonStatus.Rescheduled
      ? allLessonStatuses.filter((s) => s.value !== ScheduledLessonStatus.Rescheduled)
      : allLessonStatuses;

  const cancellationReasonErr = lessonDetailsForm.formState.errors.cancellationReason?.message;

  return (
    <>
      <Dialog open={open} onClose={handleCancelClick}>
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          Edit Lesson Status
          <IconButton onClick={handleCancelClick}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ marginTop: 2, width: 425 }}>
          <ConnectForm<LessonDetailsForm>>
            {({ control }) => (
              <>
                <Typography>Lesson Status</Typography>
                <Controller
                  name={'lessonStatus'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <List style={{ marginTop: 16 }}>
                      {selectableLessonStatuses.map((x) => (
                        <ListItem disablePadding key={`edit-lesson-status-chk-${x.label}`}>
                          <ListItemButton onClick={onItemClicked(x, onChange)}>
                            <Checkbox checked={value === x.value} />
                            <Typography variant="body1">{x.label}</Typography>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  )}
                />

                {/*
                Using display: block/none to avoid a weird edge case where after page reload, **only the first**
                lesson status transition from anything -> CANCELLED wouldn't show the field in an error state.
                Looks like it was because conditionally rendering the cancellation reason select wouldn't validate
                the field when it was first rendered
                */}
                <Box sx={{ mt: 3, display: statusesRequiringCancellationReason.includes(lessonStatus) ? 'block' : 'none' }}>
                  <CancellationReasonSelect
                    name="cancellationReason"
                    label="Reason for Cancellation"
                    control={control}
                    size="medium"
                    includeEmpty
                    errorMessage={cancellationReasonErr}
                    rules={{
                      validate: (value, formValues) =>
                        statusesRequiringCancellationReason.includes(formValues.lessonStatus) && value === ''
                          ? 'Cancellation Reason is required.'
                          : undefined,
                    }}
                  />
                </Box>
              </>
            )}
          </ConnectForm>
        </DialogContent>
        <DialogActions sx={{ p: 3, position: 'relative' }}>
          <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
          <Button size="large" variant="outlined" color="primary" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button size="large" variant="contained" onClick={handleSaveClick} disabled={!lessonDetailsForm.formState.isValid}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditLessonStatusModal;
