import {
  CancellationReason,
  ScheduledLessonLanguage,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonType,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { StudentGoal } from '@hoot-reading/hoot-core/dist/enums/user/student/student-goal.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { DecodableReadingLevel, FAndPReadingLevel, GBPlusReadingLevel, MathReadingLevel } from '@hoot/interfaces/profile';
import { MathStrengthsGoals, ReadingLevelType, SightWordList } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';

export interface AdminLessonNoteDetails {
  lessonNoteId: string;
  createdAt: number;
  lessonId: string | null;
  lessonNumber: string;
  lessonStartTime: number;
  lessonDate: string;
  lessonType: ScheduledLessonType;
  lessonLanguage: ScheduledLessonLanguage;
  lessonStatus: ScheduledLessonStatus;
  lessonSubject: ScheduledLessonSubject;
  lessonCancellationReason: CancellationReason | null;
  studentProfileId: string;
  teacherAccountId: string;
  strengths: StudentGoal[] | MathStrengthsGoals[] | null;
  strengthsOther: string | null;
  goals: StudentGoal[] | MathStrengthsGoals[] | null;
  goalsOther: string | null;
  sightWordList: SightWordList | null;
  sightWordsMissed: string | null;
  lessonNotes: string | null;
  lessonNoteBooks: AdminLessonNoteDetailsBook[];
  createdBy: AdminLessonNoteDetailsCreatedByUser;
}

export interface AdminLessonNoteDetailsCreatedByUser {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
}

export interface AdminLessonNoteDetailsBook {
  lessonNoteId: string;
  bookId: string;
  title: string;
  readingLevelSystem: ReadingLevelType;
  readingLevel: FAndPReadingLevel | DecodableReadingLevel | GBPlusReadingLevel | MathReadingLevel;
}

export default function useGetLessonNoteDetailsById(
  lessonNoteId: string,
  options?: Omit<UseQueryOptions<AdminLessonNoteDetails, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetLessonNote, lessonNoteId],
    async () => (await axios.get(`${config.apiUrl}/admin/lesson-notes/${lessonNoteId}`)).data,
    options,
  );
}
