import { EventVisibilityOption, FocusVisibilityOption, PeriodBasedValues } from '@hoot-reading/hoot-core/dist/enums/student-timeline';

export const focusVisibilityLabels: Record<FocusVisibilityOption, string> = {
  [FocusVisibilityOption.PreWordReading]: 'Pre-Word Reading',
  [FocusVisibilityOption.EarlyWordReading]: 'Early Word Reading',
  [FocusVisibilityOption.ComplexReading]: 'Complex Word Reading',
  [FocusVisibilityOption.TextLanguage]: 'Text Language',
  [FocusVisibilityOption.TextReading]: 'Text Reading',
  [FocusVisibilityOption.OtherAreas]: 'Other Areas',
};

export const eventVisibilityLabels: Record<EventVisibilityOption, string> = {
  [EventVisibilityOption.Hra]: 'HRAs',
  [EventVisibilityOption.Pma]: 'PMAs',
  [EventVisibilityOption.Absence]: 'Absences',
  [EventVisibilityOption.Cancellation]: 'Cancellations',
  [EventVisibilityOption.Lessons]: 'Lessons',
};

export const periodBasedLabels: Record<PeriodBasedValues, string> = {
  [PeriodBasedValues.EntireEnrolment]: 'Entire Enrolment',
  [PeriodBasedValues.AllTimeLessonHistory]: 'All Time Lesson History',
  [PeriodBasedValues.MostRecentUntilNextHRA]: 'Most Recent Until Next HRA',
  [PeriodBasedValues.MostRecentUntilNextHRAOrPMA]: 'Most Recent Until Next HRA or PMA',
};
