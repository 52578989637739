import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { FocusChallenges } from '@hoot-reading/hoot-core/dist/enums/user/student/focus-challenges.enum';
import { NotConvertedReason } from '@hoot-reading/hoot-core/dist/enums/user/student/not-converted-reason.enum';
import { PausedReason } from '@hoot-reading/hoot-core/dist/enums/user/student/paused-reason.enum';
import { CustomerAccount } from './customer';
import { MembershipOption, StudentFutureField, StudentProfileInactiveReason } from './profile';
import { TeacherAccount } from './teacher';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isEnabled: boolean;
  isDisabled: boolean;
  disabledReason: string;
  profiles: UserProfile[];
  parentProfiles: ParentProfile[];
  studentProfiles: StudentProfile[];
  phoneNumber: string;
  customerAccount: CustomerAccount;
  teacherAccount: TeacherAccount;
  timeZone: string;
  isOnline?: boolean;
  streetAddress: string;
  city: string | null;
  provinceState: string | null;
  country: string | null;
  postalZipCode: string;
}

export enum UserAccount {
  Customer = 'CUSTOMER',
  Teacher = 'TEACHER',
  Student = 'STUDENT',
  DistrictRep = 'DISTRICT_REP',
}

export interface UserProfile {
  id: string;
  name: string;
  type: UserProfileType;
  isEnabled: boolean;
  createdAt: number;
  number: number;
  email: string;
  parentName: string;
  status: ProfileStatus;
}

export enum UserProfileType {
  Teacher = 'TEACHER',
  Parent = 'PARENT',
  Student = 'STUDENT',
}

export enum ProfileStatus {
  Online = 'ONLINE',
  Offline = 'OFFLINE',
  Busy = 'BUSY',
}

export interface ParentProfile {
  id: string;
  name: string;
  type: UserProfileType;
  isEnabled: boolean;
  isDisabled: boolean;
  disabledReason: string;
  createdAt: number;
  number: number;
}

export interface StudentProfile {
  id: string;
  name: string;
  type: UserProfileType;
  isEnabled: boolean;
  isDisabled: boolean;
  disabledReason: string;
  createdAt: number;
  number: number;
  birthYear: number;
  grade: StudentGrade;
  interests: string;
  pronouns: string;
  focusChallenges: FocusChallenges;
  parentNotes: string;
  status: StudentStatus;
  previousStatus: StudentStatus;
  futureStatus: StudentFutureField;
  effectiveDate: string | null;
  leadInitiationDate: string | null;
  fundingPeriodEnd: string | null;
  membershipOptions: MembershipOption[];
  conversionDate: string | null;
  notConvertedReason: NotConvertedReason;
  pauseEndDate: string | null;
  inactiveDate: string | null;
  inactiveReason: StudentProfileInactiveReason;
  pausedDate: string | null;
  pausedReason: PausedReason;
  actualReturnDate: string | null;
}

export interface StudentStatus {
  id: string;
  isEnabled: boolean;
  isDisabled: boolean;
  disabledReason: string;
  status: StudentStatus;
  previousStatus: StudentStatus;
  leadInitiationDate: string | null;
  conversionDate: string | null;
  notConvertedReason: NotConvertedReason;
  pauseEndDate: string | null;
  inactiveDate: string | null;
  inactiveReason: StudentProfileInactiveReason;
  pausedReason: PausedReason;
  futureStatus: StudentFutureField;
  effectiveDate: string | null;
  pausedDate: string | null;
  actualReturnDate: string | null;
}
