import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import { ScheduledLessonStatus, lessonStatusesLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { MailOutline, NotInterestedOutlined, PhoneOutlined, WarningOutlined } from '@mui/icons-material';
import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultRowsPerPage, rowsPerPageOptions } from '@hoot/common/constants';
import { useAlert } from '@hoot/contexts/AlertContext';
import { LiveLessonQueryRequest, LiveLessonResponse } from '@hoot/hooks/api/lessons/useLiveLessonsQuery';
import { routes } from '../../routes/routes';

const AttendanceRow = (props: { lesson: LiveLessonResponse }) => {
  const { lesson } = props;
  const { success } = useAlert();

  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    success('Copied to clipboard');
  };

  const now = Date.now();
  const ttl = Math.round((now - lesson.startTime) / 60000);
  const showAlert = ttl >= 0;

  return (
    <TableRow
      key={lesson.lessonId}
      hover
      sx={{
        backgroundColor: showAlert ? '#F9DDDD' : '#FFF',
      }}
    >
      <TableCell>
        <Grid container alignItems="center">
          {showAlert ? <WarningOutlined color="error" sx={{ marginLeft: '8px', marginRight: '8px' }} /> : null}
          <Grid item>
            <Link
              color="#000000"
              target="_blank"
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  color: '#1976d2',
                },
              }}
              onClick={() => window.open(routes.lessons.details.url(lesson.lessonId), '_blank')}
            >
              {lesson.lessonPrefixedNumber}
            </Link>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>{lessonStatusesLookup[lesson.status as ScheduledLessonStatus]}</TableCell>
      <TableCell
        sx={{
          color: showAlert ? '#D32D41' : '#000',
          fontWeight: showAlert ? 700 : 0,
        }}
      >
        {ttl > 0 ? `+${ttl}` : ttl}
      </TableCell>
      <TableCell>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {lesson.teacherId ? (
              <Link
                color="#000000"
                target="_blank"
                href={routes.users.teachers.details.url(lesson.teacherId)}
                sx={{
                  fontSize: '14px',
                  '&:hover': {
                    cursor: 'pointer',
                    color: '#1976d2',
                  },
                }}
              >
                {lesson.teacherPrefixedNumber}
              </Link>
            ) : (
              'No teacher yet'
            )}
          </Grid>
          <Grid item>
            <Tooltip title={lesson.teacherEmail!}>
              <IconButton onClick={handleCopyToClipboard(lesson.teacherEmail!)}>
                <MailOutline sx={{ marginLeft: '4px', marginRight: '4px', cursor: 'pointer' }} />{' '}
              </IconButton>
            </Tooltip>

            <Tooltip title={lesson.teacherPhone!}>
              <IconButton onClick={handleCopyToClipboard(lesson.teacherPhone!)}>
                <PhoneOutlined sx={{ marginLeft: '4px', marginRight: '4px', cursor: 'pointer' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Link
              color="#000000"
              target="_blank"
              href={routes.users.students.details.url(lesson.studentId)}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  color: '#1976d2',
                },
              }}
            >
              {lesson.studentPrefixedNumber}
            </Link>
          </Grid>
          {[StudentStatus.Inactive, StudentStatus.Cold, StudentStatus.Paused].includes(lesson.studentProfileStatus) ? (
            <Grid item>
              <Tooltip title={lesson.studentProfileStatus}>
                <IconButton>
                  <NotInterestedOutlined sx={{ marginRight: '4px', cursor: 'pointer', color: '#D84455' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
      </TableCell>
      <TableCell>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {lesson.enrolment && lesson.enrolment?.type === EnrolmentType.InSchool ? (
              <Chip
                key={lesson.enrolment.friendlyId}
                label={lesson.enrolment.friendlyId}
                variant="outlined"
                onClick={() =>
                  lesson.enrolment
                    ? window.open(routes.districtsSchools.enrolments.details.url(lesson.enrolment.locationId, lesson.enrolment.id), '_blank')
                    : undefined
                }
              />
            ) : (
              <Link
                color="#000000"
                target="_blank"
                href={lesson.parentId ? routes.users.parents.details.url(lesson.parentId!) : '#'}
                sx={{
                  fontSize: '14px',
                  '&:hover': {
                    cursor: 'pointer',
                    color: '#1976d2',
                  },
                }}
              >
                {lesson.parentPrefixedNumber ?? 'None'}
              </Link>
            )}
          </Grid>
          <Grid item>
            {!lesson.enrolment || lesson.enrolment?.type !== EnrolmentType.InSchool ? (
              <>
                {!!lesson.parentEmail ? (
                  <Tooltip title={lesson.parentEmail}>
                    <IconButton onClick={handleCopyToClipboard(lesson.parentEmail)}>
                      <MailOutline sx={{ marginLeft: '4px', marginRight: '4px', cursor: 'pointer' }} />{' '}
                    </IconButton>
                  </Tooltip>
                ) : null}
                {!!lesson.parentPhone ? (
                  <Tooltip title={lesson.parentPhone}>
                    <IconButton onClick={handleCopyToClipboard(lesson.parentPhone)}>
                      <PhoneOutlined sx={{ marginLeft: '4px', marginRight: '4px', cursor: 'pointer' }} />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </>
            ) : null}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const AttendanceTab = (props: {
  isLoading: boolean;
  lessons: LiveLessonResponse[];
  lessonCount: number;
  lessonQuery: LiveLessonQueryRequest;
  setLessonQuery: React.Dispatch<React.SetStateAction<LiveLessonQueryRequest>>;
  renderPaginationTotalCount: (props: { from: number; to: number; count: number }) => string;
}) => {
  const { lessons, lessonCount, lessonQuery, setLessonQuery, isLoading, renderPaginationTotalCount } = props;

  const navigate = useNavigate();

  useEffect(() => {
    navigate(routes.liveLessons.attendance.url, { replace: true });
  }, [navigate]);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setLessonQuery((q) => ({ ...q, page: page + 1 }));
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setLessonQuery((q) => ({ ...q, pageSize }));
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress style={{ color: '#1976d2' }} />
      </div>
    );
  }

  if (lessonCount === 0) {
    return (
      <Typography align="center" variant="subtitle1">
        No lessons were found.
      </Typography>
    );
  }

  return (
    <Grid container sx={{ marginTop: '24px' }}>
      <Table
        sx={{
          border: 'solid 1px rgba(224, 224, 224, 1)',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell width="10%">Lesson Number</TableCell>
            <TableCell width="10%">Status</TableCell>
            <TableCell width="10%">+ / - Start</TableCell>
            <TableCell width="25%">Teacher</TableCell>
            <TableCell width="20%">Student</TableCell>
            <TableCell width="25%">Parent / Enrolment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lessons.map((lesson) => (
            <AttendanceRow key={lesson.lessonId} lesson={lesson} />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              count={lessonCount}
              rowsPerPage={lessonQuery.pageSize}
              labelDisplayedRows={renderPaginationTotalCount}
              page={lessonQuery.page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Grid>
  );
};

export default AttendanceTab;
