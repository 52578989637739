/** Quality Assurance Type */
export enum QualityAssuranceType {
  LessonSet = 'LESSON_SET',
  Lesson = 'LESSON',
  Assessment = 'ASSESSMENT',
  Workflow = 'WORKFLOW',
  Student = 'STUDENT',
}

export const qualityAssuranceTypeLabel: Record<QualityAssuranceType, string> = {
  ASSESSMENT: 'Assessment',
  LESSON: 'Lesson',
  LESSON_SET: 'Lesson Set',
  WORKFLOW: 'Workflow',
  STUDENT: 'Student',
};

/** Quality Assurance Category */
export enum QualityAssuranceCategory {
  Schedule = 'SCHEDULE',
  Assessment = 'ASSESSMENT',
  Instruction = 'INSTRUCTION',
  Billing = 'BILLING',
}

export const qualityAssuranceCategoryLookup: Record<QualityAssuranceCategory, string> = {
  ASSESSMENT: 'Assessment',
  INSTRUCTION: 'Instruction',
  SCHEDULE: 'Schedule',
  BILLING: 'Billing',
};

/** Quality Assurance Status */
export enum QualityAssuranceStatus {
  New = 'NEW',
  InProgress = 'IN_PROGRESS',
  Done = 'DONE',
}

export const qualityAssuranceStatusLookup: Record<QualityAssuranceStatus, string> = {
  DONE: 'Done',
  IN_PROGRESS: 'In Progress',
  NEW: 'New',
};

export enum ManualQualityAssuranceTaskOption {
  NewStudent = 'NEW_STUDENT',
  StudentInactive = 'STUDENT_INACTIVE',
  StudentPause = 'STUDENT_PAUSE',
  StudentChange = 'STUDENT_CHANGE',
  AllocateCredit = 'ALLOCATE_CREDIT',
}

/** Quality Assurance Issues  */

export enum QualityAssuranceTaskIssue {
  MissingLessonReviewOne = 'MISSING_LESSSON_REVIEW_ONE',
  MissingLessonReviewTwo = 'MISSING_LESSSON_REVIEW_TWO',
  AssessmentMsgOne = 'ASSESSMENT_MSG_ONE',
  AssessmentMsgTwo = 'ASSESSMENT_MSG_TWO',
  AssessmentMsgThree = 'ASSESSMENT_MSG_THREE',
  AssessmentMsgFour = 'ASSESSMENT_MSG_FOUR',
  AssessmentMsgFive = 'ASSESSMENT_MSG_FIVE',
  AssessmentMsgSix = 'ASSESSMENT_MSG_SIX',
  AssessmentMsgSeven = 'ASSESSMENT_MSG_SEVEN',
  AssessmentMsgEight = 'ASSESSMENT_MSG_EIGHT',
  AssessmentMsgNine = 'ASSESSMENT_MSG_NINE',
  AssessmentMsgTen = 'ASSESSMENT_MSG_TEN',
  AssessmentMsgEleven = 'ASSESSMENT_MSG_ELEVEN',
  AssessmentMsgTwelve = 'ASSESSMENT_MSG_TWELVE',
  AssessmentMsgThirteen = 'ASSESSMENT_MSG_THIRTEEN',
  AssessmentMsgFourteen = 'ASSESSMENT_MSG_FOURTEEN',
  AssessmentMsgFifteen = 'ASSESSMENT_MSG_FIFTEEN',
  AssessmentMsgSixteen = 'ASSESSMENT_MSG_SIXTEEN',
  AssessmentMsgSeventeen = 'ASSESSMENT_MSG_SEVENTEEN',
  LessonPlanMsgOne = 'LESSON_PLAN_MSG_ONE',
  LessonPlanMsgTwo = 'LESSON_PLAN_MSG_TWO',
  LessonPlanMsgThree = 'LESSON_PLAN_MSG_THREE',
  LessonMsgOne = 'LESSON_MSG_ONE',
  LessonMsgTwo = 'LESSON_MSG_TWO',
  LessonMsgThree = 'LESSON_MSG_THREE',
  LessonMsgFour = 'LESSON_MSG_FOUR',
  LessonMsgFive = 'LESSON_MSG_FIVE',
  LessonMsgSix = 'LESSON_MSG_SIX',
  LessonMsgSeven = 'LESSON_MSG_SEVEN',
  LessonMsgEight = 'LESSON_MSG_EIGHT',
  LessonMsgNine = 'LESSON_MSG_NINE',
  LessonMsgTen = 'LESSON_MSG_TEN',
  LessonMsgEleven = 'LESSON_MSG_ELEVEN',
  LessonMsgTwelve = 'LESSON_MSG_TWELVE',
  LessonMsgThirteen = 'LESSON_MSG_THIRTEEN',
  LessonMsgFourteen = 'LESSON_MSG_FOURTEEN',
  LessonMsgFifteen = 'LESSON_MSG_FIFTEEN',
  LessonSetMsgOne = 'LESSON_SET_MSG_ONE',
  LessonSetMsgTwo = 'LESSON_SET_MSG_TWO',
  LessonSetMsgThree = 'LESSON_SET_MSG_THREE',
  LessonSetMsgFour = 'LESSON_SET_MSG_FOUR',
  LessonSetMsgFive = 'LESSON_SET_MSG_FIVE',
  LessonSetMsgSix = 'LESSON_SET_MSG_SIX',
  LessonSetMsgSeven = 'LESSON_SET_MSG_SEVEN',
  LessonSetMsgEight = 'LESSON_SET_MSG_EIGHT',
  LessonSetMsgNine = 'LESSON_SET_MSG_NINE',
  LessonSetMsgTen = 'LESSON_SET_MSG_TEN',
  LessonBillingOne = 'LESSON_BILLING_ONE',
  ManualQATaskByLessonOne = 'MANUAL_QA_TASK_BY_LESSON_ONE',
  ManualQATaskByStudentOne = 'MANUAL_QA_TASK_BY_STUDENT_ONE',
  ManualQATaskByStudentTwo = 'MANUAL_QA_TASK_BY_STUDENT_TWO',
  ManualQATaskByStudentThree = 'MANUAL_QA_TASK_BY_STUDENT_THREE',
  ManualQATaskByStudentFour = 'MANUAL_QA_TASK_BY_STUDENT_FOUR',
  ApproachingStudentReturnDate = 'APPROACHING_STUDENT_RETURN_DATE',
}

export const qualityAssuranceDescriptionLookup: Record<QualityAssuranceTaskIssue, string> = {
  MISSING_LESSSON_REVIEW_ONE: 'Lesson Review Past Due',
  MISSING_LESSSON_REVIEW_TWO: 'Lesson Review is late and will soon be considered incomplete',
  ASSESSMENT_MSG_ONE: 'Assessment Book used in non-Assessment Lesson.',
  ASSESSMENT_MSG_TWO: "Book used from outside of Student's Instructional Library.",
  ASSESSMENT_MSG_THREE: 'Instructional Unit does not match select Focus Area.',
  ASSESSMENT_MSG_FOUR: 'Instructional Focuses do not match.',
  ASSESSMENT_MSG_FIVE: "Book used from outside of Student's Grade Band.",
  ASSESSMENT_MSG_SIX: "Instructional Focuses do not match. Text Reading as the Primary is outside of Student's Instructional Pathway.",
  ASSESSMENT_MSG_SEVEN: 'Instructional Focuses do not match. Student indicated as Reader for Text Reading Instruction.',
  ASSESSMENT_MSG_EIGHT: 'Student indicated as Reader for Text Reading Instruction.',
  ASSESSMENT_MSG_NINE: "Text Reading as the Primary is outside of Student's Instructional Pathway.",
  ASSESSMENT_MSG_TEN: 'Wrong Text Reading Book used for Assessment.',
  ASSESSMENT_MSG_ELEVEN: 'Assessment has been left in progress and will soon be abandoned automatically.',
  ASSESSMENT_MSG_TWELVE: 'An assessment task was deferred. Please verify the indicated reason for deferral.',
  ASSESSMENT_MSG_THIRTEEN: 'Assessment was updated to Abandoned. Please verify the indicated reason for abandonment.',
  ASSESSMENT_MSG_FOURTEEN: 'Incorrect word count indicated for the selected resource.',
  ASSESSMENT_MSG_FIFTEEN: 'Teacher indicated PMA as a focus of this non-Assessment Lesson.',
  ASSESSMENT_MSG_SIXTEEN: 'PMA has been left in progress and will soon be abandoned automatically.',
  ASSESSMENT_MSG_SEVENTEEN: 'Teacher indicated HRA as a focus of this non-Assessment Lesson.',
  LESSON_PLAN_MSG_ONE: 'Assessment Book added to Lesson Plan.',
  LESSON_PLAN_MSG_TWO: "Book from outside of Student's Instructional Library added to Lesson Plan.",
  LESSON_PLAN_MSG_THREE: "Book from outside of Student's Grade Band added to Lesson Plan.",
  LESSON_MSG_ONE: 'An upcoming lesson does not yet have a sub.',
  LESSON_MSG_TWO: 'An upcoming lesson does not have a sub and is about to be cancelled.',
  LESSON_MSG_THREE: 'Lesson was cancelled because no sub was found.',
  LESSON_MSG_FOUR: 'Teacher and Student were both in the lesson but connection errors resulted in cancellation.',
  LESSON_MSG_FIVE: 'An upcoming lesson was dropped with < 48 Hours Notice.',
  LESSON_MSG_SIX: 'Lesson was cancelled because of a last minute availability exception.',
  LESSON_MSG_SEVEN: 'Teacher Joined Lesson Late',
  LESSON_MSG_EIGHT: 'Teacher Left Lesson Early',
  LESSON_MSG_NINE: 'Teacher and Student both did not join the lesson.',
  LESSON_MSG_TEN: 'Teacher Incident: Teacher did not join lesson',
  LESSON_MSG_ELEVEN: 'No Assessment was updated in an Assessment lesson.',
  LESSON_MSG_TWELVE: 'Assessment was deferred. Please verify the indicated reason for deferral:',
  LESSON_MSG_THIRTEEN: 'An upcoming lesson was dropped with < 7 Days Notice.',
  LESSON_MSG_FOURTEEN: 'No PMA was updated in a PMA lesson.',
  LESSON_MSG_FIFTEEN: 'Block was not created due to a conflict with other block(s).',
  LESSON_SET_MSG_ONE: 'More than 2 Teaches have been assigned to the set.',
  LESSON_SET_MSG_TWO: 'Student was absent for their first lesson.',
  LESSON_SET_MSG_THREE: 'Student was absent for two consecutive lessons.',
  LESSON_SET_MSG_FOUR: '# of Lessons Impacted by Teacher Attendance is high.',
  LESSON_SET_MSG_FIVE: 'Validate assessment logic before the end of the set.',
  LESSON_SET_MSG_SIX: 'Check for incomplete assessments.',
  LESSON_SET_MSG_SEVEN: 'Focus on Rapport in Lessons is breaking KPIs.',
  LESSON_SET_MSG_EIGHT: 'Focus on Rapport in Lessons is High',
  LESSON_SET_MSG_NINE: "Student's Absence Rate is breaking KPIs.",
  LESSON_SET_MSG_TEN: "Ratio of books from outside student's grade band was exceeded.",
  LESSON_BILLING_ONE: 'Unknown combination of attributes on lesson. Metrics could not be assigned.',
  MANUAL_QA_TASK_BY_LESSON_ONE: 'Credit required for lesson.',
  MANUAL_QA_TASK_BY_STUDENT_ONE: 'New student registered, billing adjustment and schedule confirmation required.',
  MANUAL_QA_TASK_BY_STUDENT_TWO: 'Student is inactive, billing adjustment required.',
  MANUAL_QA_TASK_BY_STUDENT_THREE: 'Student has paused, billing adjustment required.',
  MANUAL_QA_TASK_BY_STUDENT_FOUR: 'Student has updated their subscription, billing adjustment required.',
  APPROACHING_STUDENT_RETURN_DATE: "Paused student's return date is approaching. Confirm parent schedule.",
};

export enum ThresholdType {
  MissingLessonReviewThreshold1 = 'MISSING_LESSON_REVIEW_THRESHOLD_1',
  MissingLessonReviewThreshold2 = 'MISSING_LESSON_REVIEW_THRESHOLD_2',
  LessonReviewThreshold1 = 'LESSON_REVIEW_THRESHOLD_1',
  LessonReviewThreshold2 = 'LESSON_REVIEW_THRESHOLD_2',
  LessonReviewThreshold3 = 'LESSON_REVIEW_THRESHOLD_3',
  LessonReviewThreshold4 = 'LESSON_REVIEW_THRESHOLD_4',
  LessonReviewThreshold5 = 'LESSON_REVIEW_THRESHOLD_5',
  LessonReviewThreshold6 = 'LESSON_REVIEW_THRESHOLD_6',
  LessonReviewThreshold7 = 'LESSON_REVIEW_THRESHOLD_7',
  LessonReviewThreshold8 = 'LESSON_REVIEW_THRESHOLD_8',
  LessonReviewThreshold9 = 'LESSON_REVIEW_THRESHOLD_9',
  LessonReviewThreshold10 = 'LESSON_REVIEW_THRESHOLD_10',
  LessonReviewThreshold11 = 'LESSON_REVIEW_THRESHOLD_11',
  LessonReviewThreshold12 = 'LESSON_REVIEW_THRESHOLD_12',
  LessonReviewThreshold13 = 'LESSON_REVIEW_THRESHOLD_13',
  LessonReviewThreshold14 = 'LESSON_REVIEW_THRESHOLD_14',
  LessonReviewThreshold15 = 'LESSON_REVIEW_THRESHOLD_15',
  LessonReviewThreshold16 = 'LESSON_REVIEW_THRESHOLD_16',
  LessonReviewThreshold17 = 'LESSON_REVIEW_THRESHOLD_17',
  LessonReviewThreshold18 = 'LESSON_REVIEW_THRESHOLD_18',
  LessonReviewThreshold19 = 'LESSON_REVIEW_THRESHOLD_19',
  LessonReviewThreshold20 = 'LESSON_REVIEW_THRESHOLD_20',
  LessonReviewThreshold21 = 'LESSON_REVIEW_THRESHOLD_21',
  LessonReviewThreshold22 = 'LESSON_REVIEW_THRESHOLD_22',
  LessonReviewThreshold23 = 'LESSON_REVIEW_THRESHOLD_23',
  LessonReviewThreshold24 = 'LESSON_REVIEW_THRESHOLD_24',
  LessonReviewThreshold25 = 'LESSON_REVIEW_THRESHOLD_25',
  LessonReviewThreshold26 = 'LESSON_REVIEW_THRESHOLD_26',
  LessonPlanThreshold1 = 'LESSON_PLAN_THRESHOLD_1',
  LessonPlanThreshold2 = 'LESSON_PLAN_THRESHOLD_2',
  LessonPlanThreshold3 = 'LESSON_PLAN_THRESHOLD_3',
  LessonThreshold1A = 'LESSON_THRESHOLD_1A',
  LessonThreshold1B = 'LESSON_THRESHOLD_1B',
  LessonThreshold1C = 'LESSON_THRESHOLD_1C',
  LessonThreshold2 = 'LESSON_THRESHOLD_2',
  LessonThreshold3A = 'LESSON_THRESHOLD_3A',
  LessonThreshold3B = 'LESSON_THRESHOLD_3B',
  LessonThreshold4 = 'LESSON_THRESHOLD_4',
  LessonThreshold5 = 'LESSON_THRESHOLD_5',
  LessonThreshold6 = 'LESSON_THRESHOLD_6',
  LessonThreshold7 = 'LESSON_THRESHOLD_7',
  LessonThreshold9 = 'LESSON_THRESHOLD_9',
  LessonThreshold10 = 'LESSON_THRESHOLD_10',
  LessonThreshold11 = 'LESSON_THRESHOLD_11',
  LessonThreshold12 = 'LESSON_THRESHOLD_12',
  LessonSetThreshold1 = 'LESSON_SET_THRESHOLD_1',
  LessonSetThreshold2 = 'LESSON_SET_THRESHOLD_2',
  LessonSetThreshold3 = 'LESSON_SET_THRESHOLD_3',
  LessonSetThreshold4 = 'LESSON_SET_THRESHOLD_4',
  LessonSetThreshold5 = 'LESSON_SET_THRESHOLD_5',
  LessonSetThreshold6 = 'LESSON_SET_THRESHOLD_6',
  LessonSetThreshold7 = 'LESSON_SET_THRESHOLD_7',
  LessonSetThreshold8 = 'LESSON_SET_THRESHOLD_8',
  LessonSetThreshold9 = 'LESSON_SET_THRESHOLD_9',
  AssessmentThreshold1 = 'ASSESSMENT_THRESHOLD_1',
  AssessmentThreshold2 = 'ASSESSMENT_THRESHOLD_2',
  AssessmentThreshold3 = 'ASSESSMENT_THRESHOLD_3',
  AssessmentDeferralThreshold1 = 'ASSESSMENT_DEFERRAL_THRESHOLD_1',
  AssessmentDeferralThreshold2 = 'ASSESSMENT_DEFERRAL_THRESHOLD_2',
  AssessmentAbandonedThreshold1 = 'ASSESSMENT_ABANDONED_THRESHOLD_1',
  AssessmentAbandonedThreshold2 = 'ASSESSMENT_ABANDONED_THRESHOLD_2',
  AssessmentAbandonedThreshold3 = 'ASSESSMENT_ABANDONED_THRESHOLD_3',
  Manual = 'MANUAL',
  ApproachingStudentReturnDate = 'APPROACHING_STUDENT_RETURN_DATE',
}

export const qualityAssuranceMetricLookup: Record<ThresholdType, string> = {
  LESSON_REVIEW_THRESHOLD_1: 'Lesson Review: Misaligned Focus Area',
  LESSON_REVIEW_THRESHOLD_2: 'Lesson Review: Book Selection',
  LESSON_REVIEW_THRESHOLD_3: 'Lesson Review: Book Selection',
  LESSON_REVIEW_THRESHOLD_4: 'Lesson Review: Book Selection',
  LESSON_REVIEW_THRESHOLD_5: 'Lesson Review: Book Selection',
  LESSON_REVIEW_THRESHOLD_6: 'Lesson Review: Book Selection',
  LESSON_REVIEW_THRESHOLD_7: 'Lesson Review: Book Selection',
  LESSON_REVIEW_THRESHOLD_8: 'Lesson Review: Book Selection',
  LESSON_REVIEW_THRESHOLD_9: 'Lesson Review: Book Selection',
  LESSON_REVIEW_THRESHOLD_10: 'Lesson Review: Book Selection',
  LESSON_REVIEW_THRESHOLD_11: 'Lesson Review: Book Selection',
  LESSON_REVIEW_THRESHOLD_12: 'Lesson Review: Misaligned Focus Area',
  LESSON_REVIEW_THRESHOLD_13: 'Lesson Review: Misaligned Focus Area',
  LESSON_REVIEW_THRESHOLD_14: 'Lesson Review: Misaligned Focus Area',
  LESSON_REVIEW_THRESHOLD_15: 'Lesson Review: Misaligned Focus Area',
  LESSON_REVIEW_THRESHOLD_16: 'Lesson Review: Misaligned Focus Area',
  LESSON_REVIEW_THRESHOLD_17: 'Lesson Review: Misaligned Focus Area',
  LESSON_REVIEW_THRESHOLD_18: 'Lesson Review: Book Selection',
  LESSON_REVIEW_THRESHOLD_19: 'Lesson Review: Misaligned Focus Area',
  LESSON_REVIEW_THRESHOLD_20: 'Lesson Review: Misaligned Focus Area, Instructional Focus',
  LESSON_REVIEW_THRESHOLD_21: 'Lesson Review: Misaligned Focus Area',
  LESSON_REVIEW_THRESHOLD_22: 'Lesson Review: Misaligned Focus Area, Instructional Focus',
  LESSON_REVIEW_THRESHOLD_23: 'Lesson Review: Instructional Focus',
  LESSON_REVIEW_THRESHOLD_24: 'Lesson Review: Instructional Focus',
  LESSON_REVIEW_THRESHOLD_25: 'Lesson Review: Misaligned Focus Area',
  LESSON_REVIEW_THRESHOLD_26: 'Lesson Review: Incorrect Word Count',
  MISSING_LESSON_REVIEW_THRESHOLD_1: 'Missing Lesson Review',
  MISSING_LESSON_REVIEW_THRESHOLD_2: 'Missing Lesson Review',
  LESSON_PLAN_THRESHOLD_1: 'Lesson Plan: Book Selection',
  LESSON_PLAN_THRESHOLD_2: 'Lesson Plan: Book Selection',
  LESSON_PLAN_THRESHOLD_3: 'Lesson Plan: Book Selection',
  LESSON_THRESHOLD_1A: 'No Sub Found',
  LESSON_THRESHOLD_1B: 'No Sub Found',
  LESSON_THRESHOLD_1C: 'No Sub Found',
  LESSON_THRESHOLD_2: 'Connection Error',
  LESSON_THRESHOLD_3A: 'Availability Infraction',
  LESSON_THRESHOLD_3B: 'Availability Infraction',
  LESSON_THRESHOLD_4: 'Teacher Join Time',
  LESSON_THRESHOLD_5: 'Teacher Join Time',
  LESSON_THRESHOLD_6: 'Teacher Unavailable',
  LESSON_THRESHOLD_7: 'Teacher Unavailable',
  LESSON_THRESHOLD_9: 'Assessment Lessons',
  LESSON_THRESHOLD_10: 'Assessment Lessons',
  LESSON_THRESHOLD_11: 'Lesson Metric Failed',
  LESSON_THRESHOLD_12: 'Block Conflict',
  LESSON_SET_THRESHOLD_1: 'Assigned Teacher(s)',
  LESSON_SET_THRESHOLD_2: 'Student Absences',
  LESSON_SET_THRESHOLD_3: 'Student Absences',
  LESSON_SET_THRESHOLD_4: 'Teacher Unavailable Rate',
  LESSON_SET_THRESHOLD_5: 'Missing Assessments',
  LESSON_SET_THRESHOLD_6: 'Rapport Primary Focus',
  LESSON_SET_THRESHOLD_7: 'Rapport Secondary Focus',
  LESSON_SET_THRESHOLD_8: 'Student Absence Rate',
  LESSON_SET_THRESHOLD_9: 'Text Reading Resource Ratio',
  ASSESSMENT_THRESHOLD_1: 'Text Reading Book',
  ASSESSMENT_THRESHOLD_2: 'Incorrect Word Count',
  ASSESSMENT_THRESHOLD_3: 'Incorrect Word Count',
  ASSESSMENT_DEFERRAL_THRESHOLD_1: 'Deferred Assessment',
  ASSESSMENT_DEFERRAL_THRESHOLD_2: 'Deferred Assessment',
  ASSESSMENT_ABANDONED_THRESHOLD_1: 'Assessment Abandoned',
  ASSESSMENT_ABANDONED_THRESHOLD_2: 'Assessment Abandoned',
  ASSESSMENT_ABANDONED_THRESHOLD_3: 'Assessment Abandoned',
  MANUAL: 'Manual',
  APPROACHING_STUDENT_RETURN_DATE: 'Approaching Student Return Date',
};

export function groupQualityAssuranceMetricByValue(): string[] {
  return Array.from(new Set(Object.values(qualityAssuranceMetricLookup))).sort((a, b) => a.localeCompare(b));
}

/** Quality Assurance Owning Team */

export enum QualityAssuranceOwningTeam {
  LearningSupport = 'LEARNING_SUPPORT',
  LessonSuccess = 'LESSON_SUCCESS',
  TeacherSuccess = 'TEACHER_SUCCESS',
  CustomerExperience = 'CUSTOMER_EXPERIENCE',
}

export const qualityAssuranceOwningTeamLookup: Record<QualityAssuranceOwningTeam, string> = {
  LEARNING_SUPPORT: 'Learning Support',
  CUSTOMER_EXPERIENCE: 'Customer Experience',
  LESSON_SUCCESS: 'Lesson Success',
  TEACHER_SUCCESS: 'Teacher Success',
};

/** QA Tasks Search Field Options */
export enum QATaskSearchFieldOptions {
  LessonNumber = 'LESSON_NUMBER',
  LessonSetNumber = 'LESSON_SET_NUMBER',
  TeacherNumber = 'TEACHER_NUMBER',
  StudentNumber = 'STUDENT_NUMBER',
  TaskNumber = 'TASK_NUMBER',
  All = 'ALL',
}
