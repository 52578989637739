import { DistrictRepresentativeStatus } from '@hoot-reading/hoot-core/dist/enums/user/district-representative/district-representative-status.enum';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { sanitizeInputPhoneNumber } from '@hoot/utils/formData';

export interface CreateDistrictRepresentativeRequest {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  timeZone: string;
  status: DistrictRepresentativeStatus;
  userAccountStatus: boolean;
}

export interface CreateDistrictRepresentativeResponse {
  id: string;
}

async function createDistrictRepresentative(
  payload: CreateDistrictRepresentativeRequest,
): Promise<AxiosResponse<CreateDistrictRepresentativeResponse>> {
  return await axios.post<CreateDistrictRepresentativeResponse>(`${config.apiUrl}/admin/district-representative`, payload);
}

export default function useCreateDistrictRepresentative(
  options?: Omit<
    UseMutationOptions<AxiosResponse<CreateDistrictRepresentativeResponse>, AxiosError, CreateDistrictRepresentativeRequest>,
    'mutationKey' | 'mutationFn'
  >,
) {
  return useMutation((data: CreateDistrictRepresentativeRequest) => {
    const phoneNumber = sanitizeInputPhoneNumber(data.phoneNumber);
    if (!phoneNumber) {
      throw new Error('Phone Number input for updating a district representative was incorrect.');
    }
    data.phoneNumber = phoneNumber;

    return createDistrictRepresentative(data);
  }, options);
}
