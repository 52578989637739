import { Error, SaveOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Card, CircularProgress, Stack, Typography, styled } from '@mui/material';
import fileDownloader from 'js-file-download';
import useGetTRSImport from '@hoot/hooks/api/end-users/teacher/useGetTRSImport';
import useGetTRSImportErrors from '@hoot/hooks/api/end-users/teacher/useGetTRSImportErrors';
import theme from '@hoot/theme';
import { DataImportType, dataImportTypeLabels } from './data-import-type-enums';

interface Props {
  importId: string;
  importType: DataImportType;
}

const DataImportSummary = (props: Props) => {
  const { importId, importType } = props;
  const importTypeIsTRS = importType === DataImportType.TRS;

  //Note: we will instantiate all specific import hooks here but will have to only logically call the ones being used related to the selected import type
  const { data: trsImportData, isLoading } = useGetTRSImport(importId, { enabled: importTypeIsTRS });

  const trsImportErrors = useGetTRSImportErrors(importId, {
    enabled: false,
    onSuccess: (res) => {
      const errorFileName = `Errors-${dataImportTypeLabels[importType]}.csv`;
      fileDownloader(res.data, errorFileName);
    },
    onError: (err) => console.error('Error downloading CSV', err),
  });

  const fetchErrorFile = () => {
    if (importTypeIsTRS) {
      trsImportErrors.refetch();
    }
  };

  //Note: we will logically show the saved and errored rows for other import types as done below
  const savedRows = (importTypeIsTRS && trsImportData?.savedRows) || null;
  const erroredRows = (importTypeIsTRS && trsImportData?.erroredRows) || null;

  const StyledCard = styled(Card)(() => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2.5),
    border: '1px solid rgba(0, 0, 0, 0.1)',
    height: 56,
    '& > svg': { marginRight: '20px' },
  }));

  return (
    <>
      <Typography variant="titleMedium" sx={{ color: '#000' }}>
        Step 3 - Summary of Changes
      </Typography>
      <Stack sx={{ padding: theme.spacing(3, 0, 1, 0) }} gap={2}>
        {trsImportData ? <Typography variant="bodySmall">{dataImportTypeLabels[importType]}</Typography> : null}

        {isLoading ? <CircularProgress /> : null}

        {!!savedRows ? (
          <StyledCard>
            <SaveOutlined />
            <Typography>{`Saved (${savedRows}) row(s)`}</Typography>
          </StyledCard>
        ) : null}

        {!!erroredRows ? (
          <StyledCard>
            <Error htmlColor="#BA1A1A" />
            <Typography>{`(${erroredRows}) row(s) have errors`}</Typography>
            <LoadingButton onClick={fetchErrorFile} loading={trsImportErrors.isRefetching} variant="text" sx={{ marginLeft: 'auto' }}>
              Download
            </LoadingButton>
          </StyledCard>
        ) : null}
      </Stack>
    </>
  );
};

export default DataImportSummary;
