import React from 'react';

const ProductIcon = () => {
  return (
    <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.12565 37.334V14.2632C1.54718 13.8888 1.06228 13.4125 0.670963 12.834C0.279644 12.2555 0.0839844 11.575 0.0839844 10.7923V4.66732C0.0839844 3.5444 0.483811 2.58312 1.28346 1.78346C2.08312 0.983811 3.0444 0.583984 4.16732 0.583984H36.834C37.9569 0.583984 38.9182 0.983811 39.7178 1.78346C40.5175 2.58312 40.9173 3.5444 40.9173 4.66732V10.7923C40.9173 11.575 40.7217 12.2555 40.3303 12.834C39.939 13.4125 39.4541 13.8888 38.8756 14.2632V37.334C38.8756 38.4569 38.4758 39.4182 37.6762 40.2178C36.8765 41.0175 35.9152 41.4173 34.7923 41.4173H6.20898C5.08607 41.4173 4.12478 41.0175 3.32513 40.2178C2.52548 39.4182 2.12565 38.4569 2.12565 37.334ZM6.20898 14.8757V37.334H34.7923V14.8757H6.20898ZM36.834 10.7923V4.66732H4.16732V10.7923H36.834ZM14.3757 25.084H26.6256V21.0006H14.3757V25.084Z"
        fill="#1C1B1F"
      />
    </svg>
  );
};
export default ProductIcon;
