import { DateTime } from 'luxon';
import { DayOfWeek } from '@hoot/utils/dateTime';
import { TentativeShiftForm } from '../teacher-conflicts-step/TeacherConflictCheckStep';

type Occurrences = {
  occurrences: number;
  endDate?: never;
};

type EndsAt = {
  occurrences?: never;
  endDate: DateTime;
};

export type ShiftTimeByDayOfWeek = {
  dayOfWeek: DayOfWeek;
  startTime: string;
  endTime: string;
};

export type GenerateShift = {
  startsAt: DateTime;
  endsAt: DateTime;
  shiftTimeByDayOfWeek: ShiftTimeByDayOfWeek[];
} & (Occurrences | EndsAt);

export function generateShiftTimes(params: GenerateShift): TentativeShiftForm[] {
  let offset = 0;
  const results = [];
  const now = DateTime.now();
  const yearFromNow = params.startsAt.plus({ year: 1 });

  loop1: for (;;) {
    const week = params.startsAt.plus({ week: offset }).startOf('day');

    // break if current week is greater than or equal to a year from now
    if (week >= yearFromNow) {
      break;
    }

    // if ends at is set, check to see if current week is less than or equal to ends at
    for (const shiftTime of params.shiftTimeByDayOfWeek) {
      if (shiftTime) {
        const startTimeUnits = shiftTime.startTime.split(':');
        const endTimeUnits = shiftTime.endTime.split(':');

        const startHour = parseInt(startTimeUnits[0]);
        const startMinute = parseInt(startTimeUnits[1]);

        const endHour = parseInt(endTimeUnits[0]);
        const endMinute = parseInt(endTimeUnits[1]);

        const shiftStartDate = week.set({
          weekday: shiftTime.dayOfWeek,
          hour: startHour,
          minute: startMinute,
          second: 0,
          millisecond: 0,
        });

        const shiftEndDate = week.set({
          weekday: shiftTime.dayOfWeek,
          hour: endHour,
          minute: endMinute,
          second: 0,
          millisecond: 0,
        });

        // if using endDate to determine when shifts should be created up to
        if (params.endDate && shiftStartDate >= params.endDate) {
          break;
        }

        if (shiftStartDate > now && shiftStartDate >= params.startsAt) {
          results.push({ startsAt: shiftStartDate, endsAt: shiftEndDate, skip: false });

          // if using occurrences to determine when shifts should be created up to
          if (params.occurrences && results.length >= params.occurrences) {
            break loop1;
          }
        }
      }
    }
    offset += 1;
  }
  return results;
}
