import { Box, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, Paper } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Tldraw, loadSnapshot } from 'tldraw';
import 'tldraw/tldraw.css';
import { HeaderData, Table, TableProps } from '@hoot/components/ui/Table';
import useGetLessonWhiteboardSnapshot, { WhiteboardStoreSnapshotResponse } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboardSnapshot';
import { LessonWhiteboardResponse } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboards';

interface LessonWhiteboardCardProps {
  whiteboards: LessonWhiteboardResponse[];
}

interface LessonWhiteboardTableRow {
  id: string;
  templateName: string;
  instructionalFocuses: string;
  instructionalUnits: string;
}

const headers: HeaderData<LessonWhiteboardTableRow>[] = [
  { name: 'Assessment ID', property: 'id', isHidden: true },
  { name: 'Template Type', property: 'templateName' },
  { name: 'Focus', property: 'instructionalFocuses' },
  { name: 'Unit', property: 'instructionalUnits' },
];

const LessonWhiteboardsCard = (props: LessonWhiteboardCardProps) => {
  const { whiteboards } = props;

  const [selectedWhiteboardId, setSelectedWhiteboardId] = useState<string>();
  const [whiteboardSnapshotResponse, setWhiteboardSnapshotResponse] = useState<WhiteboardStoreSnapshotResponse>();

  useGetLessonWhiteboardSnapshot(selectedWhiteboardId!, {
    enabled: !!selectedWhiteboardId,
    onSuccess: (response) => {
      setWhiteboardSnapshotResponse(response);
    },
  });

  const whiteboardTableRows = useMemo<LessonWhiteboardTableRow[]>(() => {
    return whiteboards.map<LessonWhiteboardTableRow>((wb) => ({
      id: wb.whiteboardId,
      templateName: wb.whiteboardTemplate.name,
      instructionalUnits: wb.whiteboardTemplate.instructionalUnits.map((x) => x.name).join(', '),
      instructionalFocuses: wb.whiteboardTemplate.instructionalFocuses.map((x) => x.name).join(', '),
    }));
  }, [whiteboards]);

  const onRowClick: TableProps<LessonWhiteboardTableRow>['onRowClick'] = (whiteboardId: string) => {
    setSelectedWhiteboardId(whiteboardId);
  };

  const onDismissWhiteboard = () => {
    setWhiteboardSnapshotResponse(undefined);
    setSelectedWhiteboardId(undefined);
  };

  return (
    <>
      <Card>
        <CardHeader title="Whiteboards" />
        <CardContent sx={{ pt: 0 }}>
          <Paper>
            <Table headers={headers} data={whiteboardTableRows} onRowClick={onRowClick} />
          </Paper>
        </CardContent>
      </Card>
      {!!whiteboardSnapshotResponse && (
        <>
          <Dialog open={!!whiteboardSnapshotResponse?.storeSnapshot} onClose={onDismissWhiteboard} fullWidth maxWidth="xl">
            <DialogTitle>Whiteboard - {whiteboardSnapshotResponse.templateName}</DialogTitle>
            <DialogContent>
              <Box
                className="tldraw__editor"
                sx={{
                  height: '80vh',
                }}
              >
                <Tldraw
                  components={{
                    StylePanel: null,
                    PageMenu: null,
                    DebugPanel: null,
                    DebugMenu: null,
                    SharePanel: null,
                    CursorChatBubble: null,
                  }}
                  onMount={(editor) => {
                    loadSnapshot(editor.store, { document: whiteboardSnapshotResponse.storeSnapshot });
                    editor.updateInstanceState({ isReadonly: true });
                    editor.zoomToFit();
                  }}
                />
              </Box>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default LessonWhiteboardsCard;
