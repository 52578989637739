import { ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { SearchStudentsQuerySortKeyEnum } from '@hoot-reading/hoot-core/dist/enums/user/search-student-query-sort-key.enum';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import SelectStudentModal, { SelectStudentModalProps } from '@hoot/components/modals/select-student-modal/SelectStudentModal';
import { StudentEnrolment } from '@hoot/hooks/api/end-users/useGetStudentEnrolments';
import { SearchStudentResponse } from '@hoot/hooks/api/end-users/useSearchStudentsV2';
import { StudentAndLessonForm } from '@hoot/pages/lessons/create-v2/student-and-lesson-step/CreateLessonStudentSelectStep';

interface CreateLessonSelectStudentProps {
  studentAndLessonForm: UseFormReturn<StudentAndLessonForm>;
  studentEnrolmentsDictionary: Map<string, StudentEnrolment>;
  onStudentSelected: (student: SearchStudentResponse | undefined) => void;
  onSelectStudentEnrolment: (enrolmentId: StudentEnrolment | undefined) => void;
  hideStudentName: boolean;
}

const CreateLessonSelectStudent = (props: CreateLessonSelectStudentProps) => {
  const { studentAndLessonForm, studentEnrolmentsDictionary, onStudentSelected, onSelectStudentEnrolment, hideStudentName } = props;

  const studentProfile = studentAndLessonForm.watch('studentProfile');
  const selectedEnrolmentId = studentAndLessonForm.watch('selectedEnrolmentId');
  const lessonSetType = studentAndLessonForm.watch('lessonSetType');

  const selectedEnrolment = studentEnrolmentsDictionary.get(selectedEnrolmentId);

  const [showSelectStudentModal, setShowSelectStudentModal] = useState(false);

  const onShowSelectStudentModal = () => {
    setShowSelectStudentModal(true);
  };

  const onDismissSelectStudentModal = () => {
    setShowSelectStudentModal(false);
  };

  const onApplyStudent: SelectStudentModalProps['onApply'] = (selectedStudent) => {
    onStudentSelected(selectedStudent);
    onDismissSelectStudentModal();
  };

  const onRemoveSelectedStudent = () => {
    onStudentSelected(undefined);
  };

  const onStudentEnrolmentChanged: SelectProps['onChange'] = (event) => {
    const newSelectedEnrolmentId = event.target.value as string;
    onSelectStudentEnrolment(studentEnrolmentsDictionary.get(newSelectedEnrolmentId));
  };

  const onSetLessonSetType = (newLessonSetType: StudentAndLessonForm['lessonSetType']) => () => {
    studentAndLessonForm.setValue('lessonSetType', newLessonSetType);

    // If the user marks "Create within lesson set" as TRUE, then the Lesson Type should default to "Cycle-SoR" (and be un-editable).
    if (newLessonSetType === 'WITHIN_EXISTING_LESSON_SET') {
      studentAndLessonForm.setValue('lessonType', ScheduledLessonType.Instruction);
    }
  };

  const studentValue = (student: SearchStudentResponse | null) => {
    if (!student) {
      return 'None';
    }
    return hideStudentName ? student.studentPrefixedNumber : student.name;
  };

  return (
    <Box pb={5}>
      <Typography variant="titleMedium" mt={3} display="block">
        Select a Student
      </Typography>
      <Stack>
        <Stack direction="row" alignItems="baseline" gap={2} mt={3}>
          <Controller
            name="studentProfile"
            control={studentAndLessonForm.control}
            rules={{
              required: 'Must select a student.',
            }}
            render={({ field, fieldState }) => (
              <TextField
                required
                fullWidth
                label="Student"
                value={studentValue(field.value)}
                helperText={fieldState.error?.message}
                error={!!fieldState.error?.message}
                sx={{
                  maxWidth: '500px',
                }}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PermIdentityIcon />
                    </InputAdornment>
                  ),
                  endAdornment: !!studentProfile ? (
                    <InputAdornment position="end">
                      <IconButton onClick={onRemoveSelectedStudent}>
                        <HighlightOffIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : undefined,
                }}
              />
            )}
          />
          {!studentProfile ? (
            <Button variant="outlined" size="large" onClick={onShowSelectStudentModal}>
              Select
            </Button>
          ) : (
            <Button variant="outlined" size="large" onClick={onRemoveSelectedStudent}>
              Remove
            </Button>
          )}
        </Stack>
        {!!studentProfile && (
          <Controller
            name="selectedEnrolmentId"
            control={studentAndLessonForm.control}
            render={({ field }) => (
              <FormControl
                sx={{
                  marginTop: 3,
                  maxWidth: '500px',
                }}
                fullWidth
                disabled={studentEnrolmentsDictionary.size === 0}
              >
                <InputLabel shrink={true}>Enrolment</InputLabel>
                <Select label="Enrolment" value={field.value} onChange={onStudentEnrolmentChanged} notched displayEmpty>
                  <MenuItem value="select" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="">None</MenuItem>
                  {Array.from(studentEnrolmentsDictionary.values()).map((x) => (
                    <MenuItem key={`student-enrolment-menu-item-${x.id}`} value={x.id}>
                      {x.friendlyId}
                    </MenuItem>
                  ))}
                </Select>
                {selectedEnrolment?.hasLessonSet && (
                  <FormHelperText>A lesson set already exists in this Enrolment for {studentProfile.name}.</FormHelperText>
                )}
              </FormControl>
            )}
          />
        )}
      </Stack>
      {selectedEnrolment && (
        <Stack
          mt={3}
          gap={3}
          sx={(theme) => ({
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          })}
        >
          {/* NOTE: These checkboxes behave more like a radio group (sorta); only ONE or NONE of them can be selected, but not both. */}
          <FormControlLabel
            disabled={selectedEnrolment.hasLessonSet}
            control={<Checkbox onClick={onSetLessonSetType(lessonSetType === '' ? 'NEW_LESSON_SET' : '')} />}
            label="Lesson Set"
            checked={lessonSetType === 'NEW_LESSON_SET'}
          />
          <FormControlLabel
            disabled={!selectedEnrolment.hasLessonSet}
            control={<Checkbox onClick={onSetLessonSetType(lessonSetType === '' ? 'WITHIN_EXISTING_LESSON_SET' : '')} />}
            label="Create Within Lesson Set"
            checked={lessonSetType === 'WITHIN_EXISTING_LESSON_SET'}
          />
        </Stack>
      )}
      <SelectStudentModal
        open={showSelectStudentModal}
        onDismiss={onDismissSelectStudentModal}
        onApply={onApplyStudent}
        hideColumns={hideStudentName ? [SearchStudentsQuerySortKeyEnum.Name] : undefined}
      />
    </Box>
  );
};

export default CreateLessonSelectStudent;
