import {
  EventVisibilityOption,
  FocusVisibilityOption,
  PeriodBasedValues,
  TimelineFilterType,
  TimelineViewOption,
} from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import { Close } from '@mui/icons-material';
import { Button, Chip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import React from 'react';
import { DateFormats } from '@hoot/common/constants';
import { StudentTimelineRequest } from '@hoot/hooks/api/user/student/timeline/getStudentTimelineVis';
import EventVisibilityMenu, { EventOptionType } from './EventVisibilityMenu';
import FocusVisibilityMenu, { FocusOptionType } from './FocusVisibilityMenu';
import TimelineFilterDialog, { TimelineFilterFormValues } from './TimelineFilterDialog';
import { eventVisibilityLabels, focusVisibilityLabels, periodBasedLabels } from './common';

interface ITimelineFilterProps {
  query: StudentTimelineRequest;
  setQuery: React.Dispatch<React.SetStateAction<StudentTimelineRequest>>;
  focusOptions: FocusOptionType;
  setFocusOptions: React.Dispatch<React.SetStateAction<FocusOptionType>>;
}

const TimelineFilters = (props: ITimelineFilterProps) => {
  const { query, setQuery, focusOptions, setFocusOptions } = props;
  const [isTlFilterDialogOpen, setIsTlFilterDialogOpen] = useState<boolean>(false);

  const [tlFilterOptions, setTlFilterOptions] = useState<TimelineFilterFormValues>({
    filterType: TimelineFilterType.DateBased,
    startDate: null,
    endDate: null,
    viewOption: null,
  });

  const [eventOptions, setEventOptions] = useState<EventOptionType>({
    [EventVisibilityOption.Hra]: true,
    [EventVisibilityOption.Pma]: true,
    [EventVisibilityOption.Absence]: false,
    [EventVisibilityOption.Cancellation]: false,
    [EventVisibilityOption.Lessons]: true,
  });

  const toggleTimelineFilter = () => setIsTlFilterDialogOpen((prev) => !prev);

  const handleDeleteFilterChip = () => {
    setTlFilterOptions({
      filterType: TimelineFilterType.DateBased,
      viewOption: null,
      startDate: null,
      endDate: null,
    });
    setQuery((query) => ({
      ...query,
      filterType: TimelineFilterType.DateBased,
      viewOption: TimelineViewOption.None,
      startDate: null,
      endDate: null,
    }));
  };

  const handleDeleteEventChip = (option: EventVisibilityOption) => {
    const currentEventOptions = eventOptions;
    const updatedEventOptions = { ...currentEventOptions, [option]: !currentEventOptions[option] };
    setEventOptions(updatedEventOptions);

    const eventVisibilityOptions = Object.keys(updatedEventOptions).filter(
      (key) => updatedEventOptions[key as EventVisibilityOption] === true,
    ) as EventVisibilityOption[];
    setQuery((query) => ({
      ...query,
      eventVisibility: eventVisibilityOptions,
    }));
  };

  const handleDeleteFocusChip = (option: FocusVisibilityOption) => {
    const currentFocusOptions = focusOptions;
    const updatedFocusOptions = { ...currentFocusOptions, [option]: !currentFocusOptions[option] };
    setFocusOptions(updatedFocusOptions);

    const focusVisibilityOptions = Object.keys(updatedFocusOptions).filter(
      (key) => updatedFocusOptions[key as FocusVisibilityOption] === true,
    ) as FocusVisibilityOption[];
    setQuery((query) => ({
      ...query,
      focusVisibility: focusVisibilityOptions,
    }));
  };

  const atLeastOneActiveFilter = useMemo(() => {
    return (
      !!tlFilterOptions.startDate ||
      !!tlFilterOptions.endDate ||
      !!tlFilterOptions.viewOption ||
      Object.values(focusOptions).some((option) => option === true) ||
      Object.values(eventOptions).some((option) => option === true)
    );
  }, [eventOptions, focusOptions, tlFilterOptions]);

  return (
    <>
      <Stack direction="row" gap={2}>
        <Button
          sx={{
            height: '32px',
            background: 'linear-gradient(0deg, rgba(0, 95, 175, 0.05), rgba(0, 95, 175, 0.05)), #FDFCFF',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
            borderRadius: '8px',
            textTransform: 'none',
            color: '#1A1C1E',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            p: 2,
          }}
          onClick={toggleTimelineFilter}
        >
          Timeline Filter
        </Button>
        <FocusVisibilityMenu focusOptions={focusOptions} setFocusOptions={setFocusOptions} setQuery={setQuery} />
        <EventVisibilityMenu eventOptions={eventOptions} setEventOptions={setEventOptions} setQuery={setQuery} />
      </Stack>

      {/* Chip row(s) */}
      <Stack direction="row" gap={2} flexWrap={'wrap'} alignItems={'center'}>
        {atLeastOneActiveFilter ? <Typography variant="bodySmall">Active Timeline Filter:</Typography> : null}
        {/* Timeline Filter Chips */}
        {tlFilterOptions.startDate && tlFilterOptions.endDate && (
          <Chip
            label={`Timeline Date: 
                    ${DateTime.fromMillis(tlFilterOptions.startDate).toFormat(DateFormats.SHORT_MDY)} - 
                    ${DateTime.fromMillis(tlFilterOptions.endDate).toFormat(DateFormats.SHORT_MDY)}
                  `}
            onDelete={handleDeleteFilterChip}
            deleteIcon={<Close />}
            variant="outlined"
          />
        )}
        {tlFilterOptions.viewOption && (
          <Chip
            label={`Timeline Option:
                    ${periodBasedLabels[tlFilterOptions.viewOption as PeriodBasedValues]}
                  `}
            onDelete={handleDeleteFilterChip}
            deleteIcon={<Close />}
            variant="outlined"
          />
        )}

        {/* Focus Chips */}
        {Object.entries(focusOptions).map(
          ([key, checked]) =>
            checked && (
              <Chip
                key={key}
                label={`Focus: ${focusVisibilityLabels[key as FocusVisibilityOption]}`}
                onDelete={() => handleDeleteFocusChip(key as FocusVisibilityOption)}
                deleteIcon={<Close />}
                variant="outlined"
              />
            ),
        )}

        {/* Event Chips */}
        {Object.entries(eventOptions).map(
          ([key, checked]) =>
            checked && (
              <Chip
                key={key}
                label={`Event: ${eventVisibilityLabels[key as EventVisibilityOption]}`}
                onDelete={() => handleDeleteEventChip(key as EventVisibilityOption)}
                deleteIcon={<Close />}
                variant="outlined"
              />
            ),
        )}
      </Stack>
      <TimelineFilterDialog
        open={isTlFilterDialogOpen}
        onDismiss={toggleTimelineFilter}
        setTlFilterOptions={setTlFilterOptions}
        query={query}
        setQuery={setQuery}
      />
    </>
  );
};

export default TimelineFilters;
