import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { LessonSetLesson } from '@hoot/hooks/api/lesson-sets/useGetLessonSetLessons';
import { ConflictsResponse } from '@hoot/hooks/api/lessons/useCreateLesson';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';

export interface UpdateLessonSetLessonRequest {
  lessonId: string;
  startsAt: number;
  teacherId?: string;
}

export enum UpdateLessonSetLessonRequestErrorCode {
  LessonConflicts = 'LessonConflicts',
}

export interface UpdateLessonSetLessonRequestErrorResponse {
  conflicts: ConflictsResponse[];
}

export default function useUpdateLessonSetLessons(
  lessonSetId: string,
  options?: Omit<
    UseMutationOptions<
      LessonSetLesson[],
      AxiosError<ErrorResponseDto<UpdateLessonSetLessonRequestErrorCode, UpdateLessonSetLessonRequestErrorResponse>>,
      UpdateLessonSetLessonRequest[]
    >,
    'mutationFn' | 'mutationKey'
  >,
) {
  return useMutation(async (request) => {
    return (await axios.put<LessonSetLesson[]>(`${config.apiUrl}/admin/lesson-set/${lessonSetId}/lessons`, request)).data;
  }, options);
}
