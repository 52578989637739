import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import { ProductProgram } from '@hoot-reading/hoot-core/dist/enums/hfs/product-program.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { BackgroundCheck } from '@hoot/interfaces/teacher';

export interface UpdateProductRequest {
  name: string;
  status: ProductStatus;
  program: ProductProgram;
  startDate: number;
  endDate: number;
  numberOfEnrolments: number;
  numberOfStudents: number;
  requirements: HootQualification[];
  countryRequirements: HootSupportedCountry;
  numberOfLessons: number;
  numberOfLessonsPerSet: number;
  backgroundCheckRequirements: BackgroundCheck[];
}

const updateProduct = async (productId: string, request: UpdateProductRequest): Promise<void> => {
  const { data } = await axios.post<void>(`${config.apiUrl}/admin/hfs/product/${productId}`, request);
  return data;
};

const useUpdateProductRequest = (
  productId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, UpdateProductRequest>, 'mutationFn' | 'mutationKey'>,
) => {
  return useMutation<void, AxiosError, UpdateProductRequest>((request) => updateProduct(productId, request), options);
};

export default useUpdateProductRequest;
