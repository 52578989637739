import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface InstructionsMetricsResponse {
  instructionResources: {
    rapportPrimaryFocus: number;
    rapportSecondaryFocus: number;
    preWordReadingPrimaryFocus: number;
    preWordReadingSecondaryFocus: number;
    preWordReadingPlan: number;
    earlyWordReadingPrimaryFocus: number;
    earlyWordReadingSecondaryFocus: number;
    earlyWordReadingPlan: number;
    complexWordReadingPrimaryFocus: number;
    complexWordReadingSecondaryFocus: number;
    complexWordReadingPlan: number;
    textReadingPrimaryFocusReadAloud: number;
    textReadingSecondaryFocusReadAloud: number;
    textReadingPrimaryFocusReading: number;
    textReadingSecondaryFocusReading: number;
    textReadingPlan: number;
  };

  resourceUseDistribution: {
    rapportFocus: number;
    preWordReading: number;
    earlyWordReading: number;
    complexWordReading: number;
    textReadingReadAloud: number;
    textReadingReading: number;
  };

  lessonPlanDistribution: {
    preWordReading: number;
    earlyWordReading: number;
    complexWordReading: number;
    textReading: number;
  };
}

async function getInstructionsMetrics(lessonSetId: string) {
  const { data } = await axios.get<InstructionsMetricsResponse>(`${config.apiUrl}/admin/lesson-set/${lessonSetId}/instructions-metrics`);
  return data;
}

export function useGetInstructionsMetrics(lessonSetId: string, options?: Omit<UseQueryOptions<InstructionsMetricsResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.LessonSetInstructionsMetrics, lessonSetId], () => getInstructionsMetrics(lessonSetId), options);
}
