export enum HootAssessmentStatus {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Abandoned = 'ABANDONED',
}

export enum ProgressMonitoringAssessmentStatus {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Abandoned = 'ABANDONED',
}

export const assessmentStatusLabelDictionary: { [key in HootAssessmentStatus]: string } = {
  [HootAssessmentStatus.Abandoned]: 'Abandoned',
  [HootAssessmentStatus.Completed]: 'Completed',
  [HootAssessmentStatus.InProgress]: 'In Progress',
};

export enum HootAssessmentInstructionIndicator {
  NeedsInstruction = 'NEEDS_INSTRUCTION',
  NeedsReview = 'NEEDS_REVIEW',
  Mastered = 'MASTERED',
}

export const instructionIndicatorLabelDictionary: { [key in HootAssessmentInstructionIndicator]: string } = {
  [HootAssessmentInstructionIndicator.NeedsInstruction]: 'Needs Instruction',
  [HootAssessmentInstructionIndicator.NeedsReview]: 'Needs Review',
  [HootAssessmentInstructionIndicator.Mastered]: 'Mastered',
};

export enum UnitIdentifier {
  U_SDQ = 'U_SDQ',
  U_1_1A = 'U_1_1A',
  U_1_1B = 'U_1_1B',
  U_1_1C = 'U_1_1C',
  U_1_1D = 'U_1_1D',
  U_1_1E = 'U_1_1E',
  U_1_2A = 'U_1_2A',
  U_1_2B = 'U_1_2B',
  U_1_2C = 'U_1_2C',
  U_1_2D = 'U_1_2D',
  U_1_3A = 'U_1_3A',
  U_2_1A = 'U_2_1A',
  U_2_2A = 'U_2_2A',
  U_2_2B = 'U_2_2B',
  U_2_2C = 'U_2_2C',
  U_2_3A = 'U_2_3A',
  U_2_3B = 'U_2_3B',
  U_2_3C = 'U_2_3C',
  U_3_1A = 'U_3_1A',
  U_3_1B = 'U_3_1B',
  U_3_2A = 'U_3_2A',
  U_3_2B = 'U_3_2B',
  U_4_1A = 'U_4_1A',
  U_4_2A = 'U_4_2A',
  U_4_3A = 'U_4_3A',
  U_ATR = 'U_ATR',
  U_HRAC = 'U_HRAC',
}

export enum UnitIdentifierHRAV2 {
  U_1_1 = 'U_1_1',
  U_1_2 = 'U_1_2',
  U_1_3 = 'U_1_3',
  U_1_4 = 'U_1_4',
  U_1_5 = 'U_1_5',
  U_2_1 = 'U_2_1',
  U_2_2 = 'U_2_2',
  U_2_3 = 'U_2_3',
  U_2_4 = 'U_2_4',
  U_2_5 = 'U_2_5',
  U_2_6 = 'U_2_6',
  U_3_1 = 'U_3_1',
  U_3_2 = 'U_3_2',
  U_3_3 = 'U_3_3',
  U_3_4 = 'U_3_4',
  U_3_5 = 'U_3_5',
  U_3_6 = 'U_3_6',
  U_4_1 = 'U_4_1',
  U_4_2 = 'U_4_2',
  U_4_3 = 'U_4_3',
  U_4_4 = 'U_4_4',
  U_HRAV2C = 'U_HRAV2C',
}

export type UnifiedUnitIdentifier = UnitIdentifier | UnitIdentifierHRAV2;

export const unitLookup: Record<UnitIdentifier, string> = {
  U_SDQ: 'San Diego Quick',
  U_1_1A: 'Word Awareness',
  U_1_1B: 'Syllable Awareness',
  U_1_1C: 'Rhyme Identification',
  U_1_1D: 'Initial Sound Identification',
  U_1_1E: 'Onset-Rime Awareness',
  U_1_2A: 'Identification - Phoneme Counting/Segmentation',
  U_1_2B: 'Blending',
  U_1_2C: 'Deletion',
  U_1_2D: 'Substitution',
  U_1_3A: 'Initial Letter Sounds',
  U_2_1A: 'Short Vowels CVC',
  U_2_2A: 'Digraphs',
  U_2_2B: 'Initial Consonant Blends + -ck',
  U_2_2C: 'Final Blends',
  U_2_3A: 'Long vowels with e and Open Vowels',
  U_2_3B: 'Basic R-Controlled Vowels',
  U_2_3C: 'Basic Vowel Teams',
  U_3_1A: 'Advanced Vowel Teams, Variant Vowels and Diphthongs',
  U_3_1B: 'Advanced R-Controlled Vowels',
  U_3_2A: 'Silent Letters, Hard/Soft Consonants',
  U_3_2B: 'Two-Syllable Words',
  U_4_1A: 'Running Record',
  U_4_2A: 'Fluency',
  U_4_3A: 'Comprehension Strategies',
  U_ATR: 'Attitude Towards Reading',
  U_HRAC: 'Hoot Reading Assessment Completed',
};

export const unitIdentifierLabelDictionary: { [key in UnitIdentifier]: string } = {
  [UnitIdentifier.U_SDQ]: 'SDQ',
  [UnitIdentifier.U_1_1A]: '1.1a',
  [UnitIdentifier.U_1_1B]: '1.1b',
  [UnitIdentifier.U_1_1C]: '1.1c',
  [UnitIdentifier.U_1_1D]: '1.1d',
  [UnitIdentifier.U_1_1E]: '1.1e',
  [UnitIdentifier.U_1_2A]: '1.2a',
  [UnitIdentifier.U_1_2B]: '1.2b',
  [UnitIdentifier.U_1_2C]: '1.2c',
  [UnitIdentifier.U_1_2D]: '1.2d',
  [UnitIdentifier.U_1_3A]: '1.3a',
  [UnitIdentifier.U_2_1A]: '2.1a',
  [UnitIdentifier.U_2_2A]: '2.2a',
  [UnitIdentifier.U_2_2B]: '2.2b',
  [UnitIdentifier.U_2_2C]: '2.2c',
  [UnitIdentifier.U_2_3A]: '2.3a',
  [UnitIdentifier.U_2_3B]: '2.3b',
  [UnitIdentifier.U_2_3C]: '2.3c',
  [UnitIdentifier.U_3_1A]: '3.1a',
  [UnitIdentifier.U_3_1B]: '3.1b',
  [UnitIdentifier.U_3_2A]: '3.2a',
  [UnitIdentifier.U_3_2B]: '3.2b',
  [UnitIdentifier.U_4_1A]: '4.1a',
  [UnitIdentifier.U_4_2A]: '4.2a',
  [UnitIdentifier.U_4_3A]: '4.3a',
  [UnitIdentifier.U_ATR]: 'ATR',
  [UnitIdentifier.U_HRAC]: 'HRAC',
};

export enum HootAssessmentModule {
  SDQ = 'SDQ',
  One = 'ONE',
  Two = 'TWO',
  Three = 'THREE',
  Four = 'FOUR',
  ATR = 'ATR',
  HRAC = 'HRAC',
}

export enum FrustrationLevel {
  PrePrimer = 'PRE_PRIMER',
  Primer = 'PRIMER',
  Grade1 = 'GRADE_1',
  Grade2 = 'GRADE_2',
  Grade3 = 'GRADE_3',
  Grade4 = 'GRADE_4',
  Grade5 = 'GRADE_5',
  Grade6 = 'GRADE_6',
  Grade7 = 'GRADE_7',
  Grade8 = 'GRADE_8',
  Grade9 = 'GRADE_9',
  Grade10 = 'GRADE_10',
  Grade11 = 'GRADE_11',
  Grade12 = 'GRADE_12',
}

export const frustrationLevelLabelDictionary: { [key in FrustrationLevel]: string } = {
  [FrustrationLevel.PrePrimer]: 'Pre-Primer (pg. 1)',
  [FrustrationLevel.Primer]: 'Primer (pg. 2)',
  [FrustrationLevel.Grade1]: 'Grade 1 (pg. 3)',
  [FrustrationLevel.Grade2]: 'Grade 2 (pg. 4)',
  [FrustrationLevel.Grade3]: 'Grade 3 (pg. 5)',
  [FrustrationLevel.Grade4]: 'Grade 4 (pg. 6)',
  [FrustrationLevel.Grade5]: 'Grade 5 (pg. 7)',
  [FrustrationLevel.Grade6]: 'Grade 6 (pg. 8)',
  [FrustrationLevel.Grade7]: 'Grade 7 (pg. 9)',
  [FrustrationLevel.Grade8]: 'Grade 8 (pg. 10)',
  [FrustrationLevel.Grade9]: 'Grade 9 (pg. 11)',
  [FrustrationLevel.Grade10]: 'Grade 10 (pg. 12)',
  [FrustrationLevel.Grade11]: 'Grade 11 (pg. 13)',
  [FrustrationLevel.Grade12]: 'Grade 12 (pg. 13)',
};
