import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { FocusChallenges } from '@hoot-reading/hoot-core/dist/enums/user/student/focus-challenges.enum';
import { NotConvertedReason } from '@hoot-reading/hoot-core/dist/enums/user/student/not-converted-reason.enum';
import { PausedReason } from '@hoot-reading/hoot-core/dist/enums/user/student/paused-reason.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { StudentType } from '@hoot-reading/hoot-core/dist/enums/user/student/student-type.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { Account } from '@hoot/interfaces/parent';
import { MembershipOption, StudentFutureField, StudentProfileInactiveReason } from '@hoot/interfaces/profile';
import { UserProfileType } from '@hoot/interfaces/user';
import { QueryKey } from '../queryKeys';

export interface ParentProfileResponse {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
  country: string | null;
  timeZone: string;
  account: Account | null;
  prefixedNumber: string;
}

export interface StudentDistrictResponse {
  id: string;
  name: string;
  friendlyId: string;
  type: LocationType;
}

export interface StudentLocationResponse {
  id: string;
  name: string;
  friendlyId: string;
  type: LocationType;
  district?: StudentDistrictResponse;
}

export interface StudentEnrolmentResponse {
  id: string;
  name: string;
  locationName: string;
  locationId: string;
  friendlyId: string;
  status: EnrolmentStatus;
}

export interface StudentEdlinkDataResponse {
  edlinkPersonId: string;
  edlinkPersonName: string;
  integrationId: string;
  integrationName: string;
}

export interface StudentProfileResponse {
  id: string;
  endUserId: string;
  name?: string; //note: the name is undefined here as we don't always want to return it to the FE for security reasons
  number: number;
  prefixedNumber: string;
  timeZone: string;
  hfsStudentNumber: string | null;
  type: UserProfileType;
  isDisabled: boolean;
  disabledReason: string | null;
  createdAt: number;
  birthYear: number | null;
  grade: StudentGrade | null;
  interests: string | null;
  pronouns: string | null;
  focusChallenges: FocusChallenges | null;
  parentNotes?: string | null; //note: the parentNotes is undefined here as we don't always want to return it to the FE for security reasons
  hootNotes: string | null;
  hootNotesExpireDate: string | null;
  status: StudentStatus | null;
  previousStatus: StudentStatus | null;
  futureStatus: StudentFutureField | null;
  effectiveDate: string | null;
  leadInitiationDate: string | null;
  fundingPeriodEnd: string | null;
  membershipOptions: MembershipOption[] | null;
  conversionDate: string | null;
  notConvertedReason: NotConvertedReason | null;
  pauseEndDate: string | null;
  inactiveDate: string | null;
  inactiveReason: StudentProfileInactiveReason | null;
  pausedDate: string | null;
  pausedReason: PausedReason | null;
  billableLessonsCount: number;
  parentInfo: ParentProfileResponse | null;
  hfsLocations: StudentLocationResponse[] | null;
  enrolments: StudentEnrolmentResponse[] | null;
  updatedStatusAt: number;
  actualReturnDate: string | null;
  studentType: StudentType;
  edlinkData: StudentEdlinkDataResponse | null;
  isStudentTimelineAccessible: boolean;
}

const getStudentProfile = async (studentProfileId: string) => {
  const { data } = await axios.get<StudentProfileResponse>(`${config.apiUrl}/admin/student-profile/${studentProfileId}`);
  return data;
};

export default function useGetStudentProfile(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<StudentProfileResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetStudentProfile, studentProfileId], () => getStudentProfile(studentProfileId), options);
}
