import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { ReadingLevelType } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';
import { BookType, DecodableFormat, Language, SpellingType } from './enums';

export interface ReadingLevel {
  id: string;
  name: string;
  type: string;
}

export interface Publisher {
  id: string;
  name: string;
}

export interface Category {
  id: string;
  name: string;
}

export interface Contributor {
  id: string;
  name: string;
}

export enum Subject {
  Reading = 'READING',
  Math = 'MATH',
}

export interface BookResponse {
  id: string;
  coverImageUrl: string | null;
  isActive: boolean;
  isbn: string;
  title: string;
  description: string | null;
  publisher: { id: string; name: string };
  minAge: number | null;
  maxAge: number | null;
  subject: Subject;
  language: Language;
  resourceType: BookType | null;
  decodableFormatId: DecodableFormat | null;
  instructionalFocus: { id: string; name: string }[];
  instructionalUnits: { id: string; name: string }[];
  prioritizedSkill: { id: string; name: string }[];
  genre: string | null;
  subGenre: string | null;
  topic: { id: string; name: string }[];
  subTopic: { id: string; name: string }[];
  theme: { id: string; name: string }[];
  subTheme: { id: string; name: string }[];
  format: string | null;
  series: string | null;
  nonConforming: boolean;
  hiLo: boolean;
  readingLevel: { id: string; name: string; type: ReadingLevelType };
  collections: { id: string; name: string }[];
  contentTags: { id: string; name: string }[];
  wordCount: number | null;
  spellingType: SpellingType | null;
  seq?: number;
  isDecodableLibrary: boolean;
  isDoublePageEnabled: boolean;
  isStandardDoublePage: boolean;
}

export interface Page {
  id: string;
  pageNumber: number;
  url: string;
}

export interface DetailedBook extends BookResponse {
  pages: Page[];
}

async function getBook(bookId: string): Promise<DetailedBook> {
  const { data } = await axios.get<DetailedBook>(`${config.apiUrl}/admin/library/${bookId}`);
  return data;
}

export default function useGetBook(bookId: string, options?: Omit<UseQueryOptions<DetailedBook>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetBook, bookId], () => getBook(bookId), options);
}
