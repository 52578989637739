import { TeacherSpecializedTraining } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-specialized-training.enum';
import { Bolt } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FormState } from 'react-hook-form';
import GenericCheckListModal from '@hoot/components/modals/GenericCheckListModal';
import { teacherSpecializedTrainingOptions, teacherSpecializedTrainingTypes } from '@hoot/interfaces/teacher';
import { TeacherAccountAndUserForm } from '@hoot/pages/users/interfaces/form';
import NoneListItem from './NoneListItem';

interface Props {
  selectedSpecializedTraining: TeacherSpecializedTraining[];
  setSelectedSpecializedTraining: Dispatch<SetStateAction<TeacherSpecializedTraining[]>>;
  formState: FormState<TeacherAccountAndUserForm>;
}

const TeacherSpecializedTrainingCard = (props: Props) => {
  const { selectedSpecializedTraining, setSelectedSpecializedTraining, formState } = props;
  const noneSelected = selectedSpecializedTraining.length === 0;

  const [openTeacherSpecializedTrainingModal, setOpenSpecializedTrainingModal] = useState<boolean>(false);

  const specializedTrainingSet = useMemo(() => new Set<string>(selectedSpecializedTraining), [selectedSpecializedTraining]);

  const onConfirm = (checkedValues: string[]) => {
    setSelectedSpecializedTraining(checkedValues as TeacherSpecializedTraining[]);
    setOpenSpecializedTrainingModal(false);
  };

  const onDismiss = () => {
    setOpenSpecializedTrainingModal(false);
  };

  return (
    <>
      <GenericCheckListModal
        show={openTeacherSpecializedTrainingModal}
        title={'Speciailized Training'}
        listItems={teacherSpecializedTrainingOptions}
        defaultCheckedItems={specializedTrainingSet}
        onConfirmButtonClicked={onConfirm}
        onDismiss={onDismiss}
        confirmButtonTitle="Apply"
        dialogContentSx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            width: '480px',
          },
        })}
      />
      <Grid container item>
        <Card
          sx={{
            width: 1,
          }}
        >
          <CardHeader
            sx={{ p: 3, pb: 0 }}
            title={
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="titleLarge">Specialized Training</Typography>
                <Button
                  type="button"
                  sx={{ marginLeft: '8px' }}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => setOpenSpecializedTrainingModal(true)}
                >
                  {noneSelected ? 'Add' : 'Edit'}
                </Button>
              </Stack>
            }
          />
          <CardContent sx={{ p: 3, pt: 0 }}>
            <Grid marginTop="24px" container sx={{ rowGap: 3 }}>
              <Grid item container xs={12} flexDirection="column">
                <List dense>
                  {selectedSpecializedTraining.length > 0 ? (
                    selectedSpecializedTraining.map((training, idx) => (
                      <>
                        <ListItem
                          key={training}
                          sx={{
                            borderTop: `${idx !== 0 ? '1px solid #CAC4D0' : undefined}`,
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: '40px' }}>
                            <Bolt />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography component="div" variant="bodyLarge">
                                {teacherSpecializedTrainingTypes[training]}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </>
                    ))
                  ) : (
                    <NoneListItem />
                  )}
                </List>
                {formState.errors.certificationAreas ? <FormHelperText sx={{ color: '#B3261E' }}>A selection is required</FormHelperText> : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default TeacherSpecializedTrainingCard;
