import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { InvoicingImportResponse } from '@hoot/hooks/api/teacher-invoice/useInvoiceImportCsv';
import { InvoicingImportType, invoiceImportLabelDictionary } from '@hoot/pages/teacher-invoices/enums/invoicing-import-type.enum';
import InvoiceImportSummary from './InvoiceImportSummary';
import InvoiceImportTypeSelection from './InvoiceImportTypeSelection';
import InvoiceImportUploadFile from './InvoiceImportUploadFile';

const steps = ['Type selection', 'Upload file', 'Summary'];

export enum ImportInvoiceStep {
  TypeSelection = 1,
  UploadFile = 2,
  Summary = 3,
}

interface ImportStepProps {
  activeStep: ImportInvoiceStep;
  setActiveStep: React.Dispatch<React.SetStateAction<ImportInvoiceStep>>;
  handleClose: () => void;
  importType: InvoicingImportType | undefined;
  importTypeCallback: React.Dispatch<React.SetStateAction<InvoicingImportType | undefined>>;
  setFileNameCallback: React.Dispatch<React.SetStateAction<string>>;
  uploadedImport: InvoicingImportResponse | undefined;
  setImportResponseCallback: React.Dispatch<React.SetStateAction<InvoicingImportResponse | undefined>>;
  periodId: string;
}

interface UserImportDialogProps {
  close: () => void;
  isOpen: boolean;
  periodId: string;
}

const ImportStep = (props: ImportStepProps) => {
  const {
    activeStep,
    setActiveStep,
    handleClose,
    importType,
    importTypeCallback,
    setFileNameCallback,
    uploadedImport,
    setImportResponseCallback,
    periodId,
  } = props;

  const importHistory = useRef<ImportInvoiceStep[]>([]);

  const previousStep = () => {
    const previousStep = importHistory.current.pop();
    setFileNameCallback('');
    importTypeCallback(undefined);
    if (previousStep) {
      setActiveStep(previousStep);
    }
  };

  const nextStep = (step: ImportInvoiceStep) => {
    setActiveStep((currentStepState) => {
      importHistory.current.push(currentStepState);
      return step;
    });
  };

  switch (activeStep) {
    case ImportInvoiceStep.TypeSelection:
      return <InvoiceImportTypeSelection nextStep={nextStep} handleClose={handleClose} importType={importType} setImportType={importTypeCallback} />;
    case ImportInvoiceStep.UploadFile:
      return (
        <InvoiceImportUploadFile
          previousStep={previousStep}
          nextStep={nextStep}
          handleClose={handleClose}
          importType={importType}
          setFileNameCallback={setFileNameCallback}
          setImportResponseCallback={setImportResponseCallback}
          periodId={periodId}
        />
      );
    case ImportInvoiceStep.Summary:
      return <InvoiceImportSummary uploadedImport={uploadedImport} handleClose={handleClose} importType={importType} />;
    default:
      throw new Error(`Import State ${activeStep} is not valid"`);
  }
};

const InvoiceImportDialog = (props: UserImportDialogProps) => {
  const { close, isOpen, periodId } = props;
  const [activeStep, setActiveStep] = useState<ImportInvoiceStep>(ImportInvoiceStep.TypeSelection);
  const step = activeStep - 1;

  const [importType, setImportType] = useState<InvoicingImportType | undefined>(InvoicingImportType.BillableTask);
  const [fileName, setFileName] = useState<string>('');
  const [importResponse, setImportResponse] = useState<InvoicingImportResponse | undefined>();

  const handleClose = () => close();

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md" onClose={handleClose}>
      <Stack direction="column" sx={{ backgroundColor: 'white', p: 3, minHeight: '515px' }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="headlineSmall">Bulk Import Tool</Typography>
            <Close sx={{ '&:hover': { cursor: 'pointer' }, mr: '22px' }} onClick={handleClose} />
          </Stack>

          <Stepper sx={{ marginTop: 6 }} activeStep={step} alternativeLabel>
            {steps.map((label, idx) => {
              return (
                <Step key={label}>
                  <StepLabel StepIconProps={{ sx: { transform: 'scale(2)', zIndex: 1, color: '#757575' } }}>
                    <span style={{ fontWeight: step === idx ? 'bold' : 'normal' }}>{label}</span>

                    <Typography variant="bodySmall" component="p" sx={{ mt: 0.5, color: '#757575', minHeight: 16 }}>
                      {/* Step 1 small label */}
                      {importType && idx === 0 && invoiceImportLabelDictionary[importType]}
                      {/* Step 2 small label */}
                      {fileName && idx === 1 && fileName}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <ImportStep
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleClose={handleClose}
          importType={importType}
          importTypeCallback={setImportType}
          setFileNameCallback={setFileName}
          uploadedImport={importResponse}
          setImportResponseCallback={setImportResponse}
          periodId={periodId}
        />
      </Stack>
    </Dialog>
  );
};

export default InvoiceImportDialog;
