import { CustomerProgram } from '@hoot-reading/hoot-core/dist/enums/user/customer-program.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface CustomerAccountResponse {
  customerAccountId: string;
  customerAccountNumber: number;
  account?: {
    id: string;
    name: string;
    isActive: boolean;
  };
  program?: CustomerProgram;
  siteName?: string;
  partnerName?: string;
}

export default function useGetCustomerAccount(
  userId: string,
  options?: Omit<UseQueryOptions<CustomerAccountResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetCustomerAccount, userId],
    async () => {
      return (await axios.get(`${config.apiUrl}/admin/parent/${userId}/customer-account`)).data;
    },
    options,
  );
}
