import CreateLessonWizard from '@hoot/pages/lessons/create-v2/CreateLessonWizard';
import CreateLessonContextProvider from '@hoot/pages/lessons/create-v2/context/CreateLessonContextProvider';
import StudentAndLessonContextProvider from '@hoot/pages/lessons/create-v2/context/StudentAndLessonContextProvider';
import StudentLessonDetailsProvider from './context/StudentLessonDetailsProvider';

const CreateLessonPage = () => {
  return (
    <>
      <CreateLessonContextProvider>
        <StudentAndLessonContextProvider>
          <StudentLessonDetailsProvider>
            <CreateLessonWizard />
          </StudentLessonDetailsProvider>
        </StudentAndLessonContextProvider>
      </CreateLessonContextProvider>
    </>
  );
};

export default CreateLessonPage;
