import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';

export enum BlockConflictSeverity {
  Blocker = 'BLOCKER',
  HighRisk = 'HIGH_RISK',
  Caution = 'CAUTION',
}

export interface ValidateRescheduleBlocksRequest {
  updatedBlockStartsAt: number;
  updatedBlockEndsAt: number;
}

export interface ValidateRescheduledBlocksResponse {
  proposedBlocks: ProposedBlock[];
}

export interface ProposedBlock {
  blockId: string;
  originalStartsAt: number;
  originalEndsAt: number;
  proposedStartsAt: number;
  proposedEndsAt: number;
  conflictSeverity?: BlockConflictSeverity;
  conflictMessage?: string;
}

async function validateRescheduleBlocks(blockId: string, request: ValidateRescheduleBlocksRequest) {
  const { data } = await axios.post<ValidateRescheduledBlocksResponse>(
    `${config.apiUrl}/admin/teacher-block/${blockId}/validate-rescheduled`,
    request,
  );
  return data;
}

export default function useValidateRescheduleBlocks(
  blockId: string,
  options?: Omit<
    UseMutationOptions<ValidateRescheduledBlocksResponse, AxiosError<ErrorResponseDto>, ValidateRescheduleBlocksRequest>,
    'queryKey' | 'queryFn'
  >,
) {
  return useMutation((request) => validateRescheduleBlocks(blockId, request), options);
}
