import { SwitchProps as MuiSwitchProps, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { FieldValues, Path, UseControllerProps, useController } from 'react-hook-form';

type Props<T extends FieldValues, TObj extends Path<T>> = {
  label: string;
  toolTip?: string;
} & Omit<MuiSwitchProps, 'defaultValue'> &
  UseControllerProps<T, TObj>;

function SwitchField<T extends FieldValues, TObj extends Path<T>>(props: Props<T, TObj>) {
  const { field } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });

  return (
    <Tooltip title={props.toolTip || ''}>
      <Stack direction="column" sx={{ width: '100%' }}>
        <Stack direction="row" justifyContent="left" alignItems="center" sx={{ width: '100%', gap: 2 }}>
          <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} disabled={props.disabled} />
          <Typography>{props.label}</Typography>
        </Stack>
      </Stack>
    </Tooltip>
  );
}

export default SwitchField;
