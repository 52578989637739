import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { SortOrder } from '@hoot/interfaces/order-by';
import { LessonSetSearchFieldOptions } from '@hoot/pages/lesson-sets/LessonSets';
import { ScheduledLessonSetStatus } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';

export enum LessonSetOrderBy {
  StudentName = 'student',
  LessonSet = 'lessonSet',
  StartDate = 'startDate',
  EndDate = 'endDate',
  EnrolmentId = 'enrolmentId',
  Location = 'location',
  Status = 'status',
}

export interface GetLessonSetsRequest {
  query?: string;
  status?: ScheduledLessonSetStatus[];
  startDate?: string;
  endDate?: string;
  page?: number;
  pageSize?: number;
  sortColumn: LessonSetOrderBy;
  sortDirection: SortOrder;
  searchFieldSelection: LessonSetSearchFieldOptions;
}

export interface GetLessonSetsResponse {
  page: number;
  pageSize: number;
  count: number;
  lessonSets: LessonSetResponse[];
}

export interface LessonSetResponse {
  lessonSetId: string;
  studentProfileId: string;
  studentNumber: string;
  studentPrefixedNumber: string;
  studentName: string;
  lessonSetNumber: number;
  lessonSetPrefixedNumber: string;
  startDate: string;
  endDate: string;
  enrolmentId: string;
  enrolmentFriendlyId: string;
  locationId: string;
  locationName: string;
  status: ScheduledLessonSetStatus;
}

async function getLessonSets(request: GetLessonSetsRequest) {
  const { data } = await axios.post<GetLessonSetsResponse>(`${config.apiUrl}/admin/lesson-set`, request);
  return data;
}

export function useGetLessonSets(request: GetLessonSetsRequest, options?: Omit<UseQueryOptions<GetLessonSetsResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.LessonSets, request], () => getLessonSets(request), options);
}
