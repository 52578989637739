import { Close } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom';
import DropdownButton, { DropdownButtonMenuItem, DropdownButtonProps } from '@hoot/components/form/DropdownButton';
import HeaderCard, { HeaderCardProps, HeaderTab } from '@hoot/components/ui/HeaderCard';
import { useAlert } from '@hoot/contexts/AlertContext';
import { LessonSetRequest, useUpdateLessonSet } from '@hoot/hooks/api/lesson-sets/useUpdateLessonSet';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import LessonSetDetailsContext, { useLessonSetDetailsContext } from '@hoot/pages/lesson-sets/details/context/LessonSetDetailsContext';
import LessonSetTeacherAssignmentContext, {
  useLessonSetTeacherAssignmentContext,
} from '@hoot/pages/lesson-sets/details/context/LessonSetTeacherAssignmentContext';
import { ScheduledLessonSetStatus } from '@hoot/pages/lessons/enums';
import { routes } from '@hoot/routes/routes';

const LessonSetDetailsPage = () => {
  return (
    <>
      {/* Context provider is used by the tabs within this page.*/}
      <LessonSetDetailsContext>
        <LessonSetTeacherAssignmentContext>
          <LessonSetDetailsPageContent />
        </LessonSetTeacherAssignmentContext>
      </LessonSetDetailsContext>
    </>
  );
};

enum OverflowActionItemId {
  ViewLessons = 'view-lessons',
}

const overflowActionItems: DropdownButtonMenuItem[] = [
  {
    id: OverflowActionItemId.ViewLessons,
    label: <ListItemText>View Lessons</ListItemText>,
  },
];

const LessonSetDetailsPageContent = () => {
  const navigate = useNavigate();

  const {
    lessonSetId,
    isLoadingLessonSetDetails,
    lessonSetDetails,
    lessonSetDetailsForm,
    isLessonSetDetailsPageDirty,
    isSavingChanges: isSavingSetDetailsChanges,
    actions: { saveLessonSetDetails },
  } = useLessonSetDetailsContext();

  const {
    isTeacherAssignmentPageDirty,
    isSavingChanges: isSavingTeacherAssignmentChanges,
    actions: { saveTeacherAssignment },
  } = useLessonSetTeacherAssignmentContext();

  const location = useLocation();

  const [showCancelLessonSetModal, setShowCancelLessonSetModal] = useState(false);

  const activePath = {
    lessonSetDetails: !!matchPath(routes.lessonSets.details.url(lessonSetId), location.pathname),
    teacherAssignment: !!matchPath(routes.lessonSets.teacherAssignment.url(lessonSetId), location.pathname),
  };

  const tabs: HeaderTab[] = [
    { id: '1', label: 'Details', route: routes.lessonSets.details.url(lessonSetId) },
    { id: '2', label: 'Quality Monitoring', route: routes.lessonSets.qualityMonitoring.url(lessonSetId) },
    { id: '3', label: 'Teacher Assignment', route: routes.lessonSets.teacherAssignment.url(lessonSetId) },
  ];

  const onOverflowActionItemClicked: DropdownButtonProps['onMenuItemClicked'] = (menuItemId) => {
    switch (menuItemId) {
      case OverflowActionItemId.ViewLessons:
        navigate(`${routes.lessons.url}?lessonSetNumber=${lessonSetDetails!.number}`);
        break;
    }
  };

  const onTabSelected: HeaderCardProps['onTabSelected'] = (selectedTab) => {
    navigate(selectedTab.route);
    return !isLessonSetDetailsPageDirty;
  };

  const handleSave = () => {
    if (activePath.lessonSetDetails) {
      if (lessonSetDetailsForm.getValues('status') === ScheduledLessonSetStatus.Cancelled) {
        setShowCancelLessonSetModal(true);
      } else {
        saveLessonSetDetails();
      }
    } else if (activePath.teacherAssignment) {
      saveTeacherAssignment();
    }
  };

  const onDismissCancelLessonDialog = () => {
    setShowCancelLessonSetModal(false);
  };

  const canSave = (activePath.lessonSetDetails && isLessonSetDetailsPageDirty) || (activePath.teacherAssignment && isTeacherAssignmentPageDirty);
  const isSavingChanges = isSavingSetDetailsChanges || isSavingTeacherAssignmentChanges;

  return (
    <>
      <Stack gap={3}>
        <HeaderCard
          isLoading={isLoadingLessonSetDetails}
          showNavigateBackButton
          canCopySubtitle
          subtitle={`Lesson Set Number: ${lessonSetDetails?.prefixedNumber ?? '-'}`}
          copySubtitleOverride={lessonSetDetails?.prefixedNumber}
          actionButtons={
            <>
              <DropdownButton label="Actions" menuItems={overflowActionItems} onMenuItemClicked={onOverflowActionItemClicked} />
              <Button
                type="button"
                sx={{ marginLeft: '8px' }}
                color="primary"
                variant="contained"
                size="large"
                disabled={!canSave}
                onClick={handleSave}
              >
                {isSavingChanges ? <CircularProgress size={25} color="inherit" /> : 'Save'}
              </Button>
            </>
          }
          title="Lesson Set Details"
          tabs={tabs}
          onTabSelected={onTabSelected}
          breadcrumbs={[{ label: 'Lesson Sets', route: routes.lessonSets.url }]}
        />
        {/* Tab content is rendered here, but don't render it until the lesson set has been loaded. */}
        {lessonSetDetails && <Outlet />}
      </Stack>

      {showCancelLessonSetModal && <CancelLessonSetDialog lessonSetId={lessonSetId} onDismiss={onDismissCancelLessonDialog} />}
    </>
  );
};

interface CancelLessonSetDialogProps {
  lessonSetId: string;
  onDismiss: () => void;
}

function CancelLessonSetDialog(props: CancelLessonSetDialogProps) {
  const { lessonSetId, onDismiss } = props;
  const queryClient = useQueryClient();

  const { success, error } = useAlert();

  const updateSetRequest = useUpdateLessonSet(lessonSetId!);

  const onCancelLessonSetConfirmed = () => {
    const request: LessonSetRequest = {
      status: ScheduledLessonSetStatus.Cancelled,
    };
    updateSetRequest.mutate(request, {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.LessonSet, lessonSetId]);
        success('Lesson set has been cancelled.');
      },
      onError: () => {
        error('There was an issue updating lesson set');
      },
      onSettled: () => {
        onDismiss();
      },
    });
  };

  return (
    <Dialog open={true} onClose={onDismiss}>
      <Stack sx={{ padding: '24px', position: 'relative' }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">Cancel Lesson Set</Typography>
            <IconButton onClick={onDismiss} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText textAlign="left">
            <Stack>
              <Typography variant="bodyMedium">Cancelling the Set does not cancel the lessons within it.</Typography>
              <Typography sx={{ marginTop: '24px' }} variant="bodyMedium">
                Tip: it’s easier to cancel lessons first via “View Lessons” & Bulk Update before you cancel the Lesson Set.
              </Typography>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="center" gap={2}>
            <Button size="large" color="error" variant="contained" onClick={onCancelLessonSetConfirmed}>
              Yes, Cancel
            </Button>
            <Button size="large" variant="outlined" onClick={onDismiss}>
              Dismiss
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}

export default LessonSetDetailsPage;
