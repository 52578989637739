import { StudentAttendanceRate } from '@hoot-reading/hoot-core/dist/enums/hfs/student-attendance-rate.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { ReceiptLong } from '@mui/icons-material';
import { Card, CardContent, CardHeader, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { FilterOption } from '@hoot/components/form/filterDropDown/common/DateFilter';
import {
  ActiveStudentAttendanceFilterChips,
  StudentAttendanceFilterDropDown,
} from '@hoot/components/form/filterDropDown/enrolment/StudentAttendanceFilter';
import { ActiveStudentSchoolFilterChips, StudentSchoolFilterDropDown } from '@hoot/components/form/filterDropDown/enrolment/StudentSchoolFilter';
import { ActiveStudentStatusFilterChips, StudentStatusFilterDropDown } from '@hoot/components/form/filterDropDown/enrolment/StudentStatusFilter';
import { HeaderData, Table } from '@hoot/components/ui/Table';
import { useAlert } from '@hoot/contexts/AlertContext';
import useGetDistrictSchool from '@hoot/hooks/api/hfs/useGetDistrictSchool';
import useGetEnrolmentQualityMonitoring, {
  EnrolmentQualityMonitoringOrderColumn,
  EnrolmentQualityMonitoringQuery,
} from '@hoot/hooks/api/hfs/useGetEnrolmentQualityMonitoring';
import { DEFAULT_PAGE_SIZE } from '@hoot/interfaces/pagination';
import { OrderBy } from '@hoot/pages/lessons/enums';
import { routes } from '@hoot/routes/routes';
import { enumToTitleCase } from '@hoot/utils/stringUtils';

interface Props {
  enrolmentId: string;
  locationId: string;
  isDistrict?: boolean;
}

interface QualityMonitoringRow {
  studentId: string;
  studentNumber: ReactNode;
  studentStatus: StudentStatus;
  studentGrade: ReactNode;
  lessonSetNumbers: ReactNode;
  scheduledLessons: ReactNode;
  completedLessons: ReactNode;
  cancelledLessons: ReactNode;
  studentAbsences: ReactNode;
  scheduledLessonsRemaining: ReactNode;
  studentAttendanceRate: ReactNode;
  teacherCount: ReactNode;
  regularTeacherCount: ReactNode;
  substituteTeacherCount: ReactNode;
  completedAssessmentLessons: ReactNode;
  startedAssessments: ReactNode;
  completedAssessments: ReactNode;
  paidLessons: ReactNode;
}

const headers: HeaderData<QualityMonitoringRow>[] = [
  { name: 'Student Number', property: 'studentNumber', isSortable: true },
  { name: 'Status', property: 'studentStatus', isSortable: true },
  { name: 'Grade', property: 'studentGrade', isSortable: true },
  { name: 'Lesson Set', property: 'lessonSetNumbers', isSortable: true },
  { name: 'Total Lessons', property: 'scheduledLessons', isSortable: true },
  { name: 'Paid Lessons', property: 'paidLessons', isSortable: true },
  { name: 'Completed Lessons', property: 'completedLessons', isSortable: true },
  { name: 'Hoot Cancelled Lessons', property: 'cancelledLessons', isSortable: true },
  { name: 'Student Absences', property: 'studentAbsences', isSortable: true },
  { name: 'Scheduled Lessons Remaining', property: 'scheduledLessonsRemaining', isSortable: true },
  { name: 'Student Attendance', property: 'studentAttendanceRate', isSortable: true },
  { name: 'Teacher Count', property: 'teacherCount', isSortable: true },
  { name: 'Regular Teacher Count', property: 'regularTeacherCount', isSortable: true },
  { name: 'Substitute Teacher Count', property: 'substituteTeacherCount', isSortable: true },
  { name: 'Completed Assessment Lessons', property: 'completedAssessmentLessons', isSortable: true },
  { name: 'Started Assessments', property: 'startedAssessments', isSortable: true },
  { name: 'Completed Assessments', property: 'completedAssessments', isSortable: true },
];

const EnrolmentQualityMonitoring = (props: Props) => {
  const { enrolmentId, locationId, isDistrict } = props;
  const [query, setQuery] = useState<EnrolmentQualityMonitoringQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: OrderBy.Desc,
    orderColumn: EnrolmentQualityMonitoringOrderColumn.StudentNumber,
  });
  const { error } = useAlert();

  const {
    data: rows,
    isLoading,
    isError,
  } = useGetEnrolmentQualityMonitoring(enrolmentId, query, {
    enabled: !!enrolmentId,
  });

  const { data: locationSchools } = useGetDistrictSchool(locationId ?? '', {
    enabled: !!locationId && isDistrict,
    onError: (err) => {
      console.error(err);
      error('There was an error getting the schools for the billable entity!');
    },
  });

  const locationSchoolsOptions: FilterOption<string>[] = (locationSchools?.districtSchools ?? [])
    .sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    })
    .map((school) => ({
      label: school.name,
      value: school.id,
    }));

  const summary = rows?.extraData;

  if (isLoading || !rows) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (isError) {
    return <Stack alignItems="center">Failed to load the page!</Stack>;
  }

  const mapScheduleCombinationsToTableRows = (): QualityMonitoringRow[] => {
    return rows.data.map((row) => {
      return {
        studentId: row.studentProfileId,
        studentNumber: (
          <Link to={routes.users.students.details.url(row.studentProfileId)}>
            <Typography sx={{ color: '#000000' }} variant="bodyMedium">
              {row.studentPrefixedNumber}
            </Typography>
          </Link>
        ),
        studentStatus: row.studentStatus,
        studentGrade: enumToTitleCase(row.studentGrade),
        lessonSetNumbers:
          row.lessonSets.length > 0 ? (
            row.lessonSets.map((ls) => {
              return (
                <Link key={ls.lessonSetId} to={routes.lessonSets.details.url(ls.lessonSetId)}>
                  <Typography sx={{ color: '#000000' }} variant="bodyMedium">
                    {ls.lessonSetNumber}
                  </Typography>
                  <br />
                </Link>
              );
            })
          ) : (
            <Typography sx={{ color: '#000000' }} variant="bodyMedium">
              N/A
            </Typography>
          ),
        scheduledLessons: row.scheduledLessons,
        completedLessons: row.completedLessons,
        cancelledLessons: row.cancelledLessons,
        studentAbsences: row.studentAbsences,
        scheduledLessonsRemaining: row.scheduledLessonsRemaining,
        studentAttendanceRate: `${Math.round(+row.studentAttendanceRate * 100)}%`,
        teacherCount: row.teacherCount,
        regularTeacherCount: row.regularTeacherCount,
        substituteTeacherCount: row.substituteTeacherCount,
        completedAssessmentLessons: row.completedAssessmentLessons,
        startedAssessments: row.startedAssessments,
        completedAssessments: row.completedAssessments,
        paidLessons: row.paidLessons,
      };
    });
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((prev) => ({ ...prev, page: 1, pageSize }));
  };

  const handleSortBy = (column: keyof QualityMonitoringRow) => {
    setQuery((prev) => ({
      ...prev,
      orderColumn: column as EnrolmentQualityMonitoringOrderColumn,
      orderBy: prev.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
      page: 1,
    }));
  };

  const onChangeStudentStatus = (val: StudentStatus[]) => {
    setQuery((prev) => ({
      ...prev,
      studentStatus: val,
    }));
  };

  const onChangeStudentAttendance = (val: StudentAttendanceRate[]) => {
    setQuery((prev) => ({
      ...prev,
      studentAttendanceRate: val,
    }));
  };

  const onChangeStudentSchool = (val: string[]) => {
    setQuery((prev) => ({
      ...prev,
      schoolId: val,
    }));
  };

  const showActiveFilters = !!query.studentStatus?.length || !!query.studentAttendanceRate?.length || !!query.schoolId?.length;

  return (
    <Grid item container mt={1} spacing={2} sx={{ width: '97vw' }}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            sx={{ p: 3, pb: 0 }}
            title={
              <Stack sx={{ p: 0 }}>
                <Typography variant="titleLarge">Quality Monitoring</Typography>
              </Stack>
            }
          />
          <CardContent>
            <Stack width="100%" gap={2}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography variant="bodyMedium">Filters</Typography>
                <StudentStatusFilterDropDown value={query?.studentStatus ?? []} onChange={onChangeStudentStatus} />
                <StudentAttendanceFilterDropDown value={query?.studentAttendanceRate ?? []} onChange={onChangeStudentAttendance} />
                {isDistrict ? (
                  <StudentSchoolFilterDropDown value={query?.schoolId ?? []} onChange={onChangeStudentSchool} options={locationSchoolsOptions} />
                ) : null}
              </Stack>
              {showActiveFilters ? (
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography variant="bodyMedium">Active</Typography>
                  <ActiveStudentStatusFilterChips value={query?.studentStatus} onChange={onChangeStudentStatus} />
                  <ActiveStudentAttendanceFilterChips value={query?.studentAttendanceRate} onChange={onChangeStudentAttendance} />
                  {isDistrict ? (
                    <ActiveStudentSchoolFilterChips value={query?.schoolId} onChange={onChangeStudentSchool} options={locationSchoolsOptions} />
                  ) : null}
                </Stack>
              ) : null}
              <Table
                isSortable
                headers={headers}
                data={mapScheduleCombinationsToTableRows()}
                isPaginated
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onSortBy={handleSortBy}
                sortBy={query.orderColumn}
                page={query.page}
                count={rows.count}
                sortOrder={query.orderBy}
                rowsPerPage={query.pageSize}
                stripedRows
              />
              <Stack gap={2}>
                <Typography variant="titleLarge">Summary</Typography>
                <Stack gap={2}>
                  <Typography variant="bodyMedium">Total Count</Typography>
                  <Stack direction="row" gap={3}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <ReceiptLong />
                      <Typography variant="bodyMedium">{summary?.totalStudents} - Students</Typography>
                    </Stack>
                    <Stack direction="row" gap={1} alignItems="center">
                      <ReceiptLong />
                      <Typography variant="bodyMedium">{summary?.totalRegularTeachers} - Regular Teachers</Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack gap={2}>
                  <Typography variant="bodyMedium">Sum</Typography>
                  <Stack direction="row" gap={3}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <ReceiptLong />
                      <Typography variant="bodyMedium">{summary?.totalScheduledLessons} - Scheduled Lessons</Typography>
                    </Stack>

                    <Stack direction="row" gap={1} alignItems="center">
                      <ReceiptLong />
                      <Typography variant="bodyMedium">{summary?.theyPay} - They Pay</Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack gap={2}>
                  <Typography variant="bodyMedium">Average</Typography>
                  <Stack direction="row" gap={1} alignItems="center">
                    <ReceiptLong />
                    <Typography variant="bodyMedium">{summary?.averageStudentAttendance} - Student Attendance</Typography>
                  </Stack>
                </Stack>
                <Stack gap={2}>
                  <Typography variant="bodyMedium">Grades Included</Typography>
                  <Stack direction="row" gap={1} alignItems="center">
                    <ReceiptLong />
                    <Typography variant="bodyMedium">{summary?.gradesIncluded}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default EnrolmentQualityMonitoring;
