import { StudentAttendanceRate } from '@hoot-reading/hoot-core/dist/enums/hfs/student-attendance-rate.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import { OrderBy } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';

export enum ProductQualityMonitoringOrderColumn {
  StudentNumber = 'studentNumber',
  StudentStatus = 'studentStatus',
  StudentGrade = 'studentGrade',
  LessonSetNumbers = 'lessonSetNumbers',
  ScheduledLessons = 'scheduledLessons',
  PaidLessons = 'paidLessons',
  CompletedLessons = 'completedLessons',
  CancelledLessons = 'cancelledLessons',
  StudentAbsences = 'studentAbsences',
  ScheduledLessonsRemaining = 'scheduledLessonsRemaining',
  StudentAttendanceRate = 'studentAttendanceRate',
  TeacherCount = 'teacherCount',
  RegularTeacherCount = 'regularTeacherCount',
  SubstituteTeacherCount = 'substituteTeacherCount',
  CompletedAssessmentLessons = 'completedAssessmentLessons',
  StartedAssessments = 'startedAssessments',
  CompletedAssessments = 'completedAssessments',
}

export interface ProductQualityMonitoringLessonSet {
  lessonSetId: string;
  lessonSetNumber: string;
}

export interface ProductQualityMonitoringResponse {
  studentProfileId: string;
  studentNumber: string;
  studentPrefixedNumber: string;
  studentStatus: StudentStatus;
  studentGrade: string;
  lessonSets: ProductQualityMonitoringLessonSet[];
  scheduledLessons: string;
  paidLessons: string;
  completedLessons: string;
  cancelledLessons: string;
  studentAbsences: string;
  scheduledLessonsRemaining: string;
  studentAttendanceRate: string;
  teacherCount: string;
  regularTeacherCount: string;
  substituteTeacherCount: string;
  completedAssessmentLessons: string;
  startedAssessments: string;
  completedAssessments: string;
}

export interface ProductQualityMonitoringQuery {
  page: number;
  pageSize: number;
  orderBy: OrderBy;
  orderColumn: ProductQualityMonitoringOrderColumn;
  studentStatus?: StudentStatus[];
  studentAttendanceRate?: StudentAttendanceRate[];
}

export interface ProductQualitySummary {
  totalStudents: string;
  totalScheduledLessons: string;
  totalTheyPay: string;
  totalRegularTeachers: string;
  averageStudentAttendance: string;
  gradesIncluded: string;
}

const getProductQualityMonitoring = async (
  productId: string,
  query: ProductQualityMonitoringQuery,
): Promise<GenericPaginatedResponse<ProductQualityMonitoringResponse, ProductQualitySummary>> => {
  const { data } = await axios.get<GenericPaginatedResponse<ProductQualityMonitoringResponse, ProductQualitySummary>>(
    `${config.apiUrl}/admin/hfs/products/${productId}/quality-monitoring`,
    { params: query },
  );
  return data;
};

export default function useGetProductQualityMonitoring(
  productId: string,
  query: ProductQualityMonitoringQuery,
  options?: Omit<
    UseQueryOptions<GenericPaginatedResponse<ProductQualityMonitoringResponse, ProductQualitySummary>, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery([QueryKey.GetProductQualityMonitoring, productId, query], () => getProductQualityMonitoring(productId, query), options);
}
