import CloseIcon from '@mui/icons-material/Close';
import { Box, BoxProps, Chip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { FilterSelectionType, IFilterSelection } from './FilterSelects';

interface ActiveFiltersProps {
  filterSelections: IFilterSelection[];
  setFilterSelections: React.Dispatch<React.SetStateAction<IFilterSelection[]>>;
  sx?: BoxProps['sx'];
}

const ActiveFilters = (props: ActiveFiltersProps) => {
  const { filterSelections, setFilterSelections, sx } = props;

  const removeFilter = (groupLabel: string, valueLabel: string) => () => {
    const newFilterSelections = [
      ...filterSelections.map((fs) => ({
        ...fs,
        values: fs.values.map((value) => ({
          ...value,
          isSelected: groupLabel === fs.label && value.label === valueLabel ? false : value.isSelected,
        })),
      })),
    ];
    setFilterSelections(newFilterSelections);
  };

  const activeFilters = useMemo(() => {
    return filterSelections.flatMap((group) => {
      return group.values
        .filter((selection) => selection.isSelected)
        .map((selection) => ({
          groupLabel: group.label,
          valueLabel: selection.label,
          queryKey: group.queryName!,
          queryArg: selection.queryValue!,
          type: group.type,
        }));
    });
  }, [filterSelections]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Typography variant="bodySmall" mr={2}>
        Active
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        {activeFilters.map((f) => {
          const { groupLabel, valueLabel, type } = f;
          return (
            <Chip
              key={`chip-${groupLabel}-${valueLabel}`}
              label={`${groupLabel}: ${valueLabel}`}
              variant="outlined"
              onDelete={type === FilterSelectionType.Boolean ? undefined : removeFilter(groupLabel, valueLabel)}
              deleteIcon={<CloseIcon />}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ActiveFilters;
