import { Card } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { StudentProfileResponse } from '@hoot/hooks/api/end-users/useGetStudentProfile';
import useUserLessonScheduleQuery from '@hoot/hooks/api/lessons/useUserLessonScheduleQuery';
import { UserProfileType } from '@hoot/interfaces/user';
import { ScheduleType } from '@hoot/pages/lessons/reschedule-v2/same-teacher-flow/RescheduleWizardSchedulesStep';
import { UserScheduleDetailsDialog } from '../../components/user-schedule/UserScheduleDetailsDialog';
import { WeeklySchedule } from '../../components/user-schedule/WeeklySchedule';
import { LessonPreferencesSlot, LessonTimeSlot, SelectedItem } from '../../components/user-schedule/weekly-schedule/interfaces';
import useGetStudentLessonPreferences from '../../hooks/useGetStudentLessonPreferences';

export function StudentScheduleTabV2(props: { studentProfile: StudentProfileResponse }) {
  const [startOfWeek, setStartOfWeek] = useState(DateTime.now().startOf('week')); // starts on Monday

  const [selectedItem, setSelectedItem] = useState<SelectedItem>();

  const endsAt = startOfWeek.plus({ week: 1 });

  const studentLessonPreferencesRequest = useGetStudentLessonPreferences(props.studentProfile.id);

  const lessonsRequest = useUserLessonScheduleQuery({
    profileType: UserProfileType.Student,
    userProfileId: props.studentProfile.id,
    fromDate: startOfWeek.toMillis(),
    toDate: endsAt.toMillis(),
  });

  const handleSelected = (newSelectedItem: SelectedItem) => {
    setSelectedItem(newSelectedItem);
  };

  const handleClose = () => setSelectedItem(undefined);

  const handleStartofWeekChange = (newStartOfWeek: DateTime) => {
    setStartOfWeek(newStartOfWeek);
  };

  const lessons =
    lessonsRequest.data?.lessons.map<LessonTimeSlot>((l) => ({
      id: l.id,
      number: l.number,
      startAt: DateTime.fromMillis(l.startsAt),
      endsAt: DateTime.fromMillis(l.endsAt),
      status: l.status,
    })) ?? [];

  const lessonPreferences = studentLessonPreferencesRequest.data?.preferredDateTimes.map<LessonPreferencesSlot>((p) => ({
    dayOfWeek: p.weekDay,
    startAt: p.time,
  }));

  return (
    <Card sx={{ marginTop: '24px', padding: '24px', height: '820px', overflowY: 'scroll' }}>
      <WeeklySchedule
        startOfWeek={startOfWeek}
        onStartOfWeekChange={handleStartofWeekChange}
        selectedItem={selectedItem}
        onSelected={handleSelected}
        lessons={lessons}
        lessonPreferences={lessonPreferences}
        scheduleType={ScheduleType.Student}
        userTimeZone={props.studentProfile.timeZone}
      />
      {selectedItem ? <UserScheduleDetailsDialog selectedItem={selectedItem} isStudentView handleClose={handleClose} /> : null}
    </Card>
  );
}
