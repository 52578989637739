import { Autocomplete, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { FormOption } from '@hoot/interfaces/form';

interface Props<T extends string | number> {
  data: FormOption<T>[];
  control: Control<any>;
  name: string;
  label?: string;
  disabled?: boolean;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  size?: 'medium' | 'small';
  required?: string;
}

export const MultiSelect = <T extends string | number>(props: Props<T>) => {
  const { data, control, name, label, disabled = false, variant = 'outlined', required = false } = props;

  const idToName = data.reduce((acc: Record<string | number, string>, val) => {
    acc[val.value] = val.label;
    return acc;
  }, {});

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
        validate: (value) => {
          if (required && value.length === 0) {
            return required;
          }
          return true;
        },
      }}
      render={({ field: { onChange, value }, fieldState }) => {
        return (
          <Autocomplete
            onChange={(_event, selectedItems) => {
              // Newly selected items in 'selectedItems' from Autocomplete will always be string values
              // which can cause issues if the original options data are enums with numeric values.
              // We also need to consider/handle the case where the control is populated based on previous selections.
              // In this scenario, selected items can be a mix of strings (new selections) and numbers (old selections
              // loaded on render).
              // Do a string based comparison of the selectedItems and source data values, but return the selected values
              // in its original type
              const selectedStrVals = selectedItems.map((si) => si.toString());
              const selectedData = data.filter((x) => selectedStrVals.includes(x.value.toString()));
              onChange(selectedData.map((x) => x.value).sort());
            }}
            disabled={disabled}
            multiple
            size={props.size}
            value={value?.filter((v: T) => !!idToName[v]).sort()}
            options={data.map((record) => record.value.toString())}
            getOptionLabel={(optionId: string) => {
              if (optionId) return idToName[optionId];
              else return '';
            }}
            isOptionEqualToValue={(optionId, valueId) => optionId === undefined || valueId === '' || optionId === valueId}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={variant}
                label={required && label ? `${label} ${fieldState.isDirty ? '*' : ''}` : `${label ? `${label}` : ''}`}
                error={!!fieldState.error}
                fullWidth
              />
            )}
            fullWidth
          />
        );
      }}
    />
  );
};
