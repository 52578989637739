import { TeacherShiftType } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import { Check } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Control, Controller, UseFormSetValue, useForm } from 'react-hook-form';
import { TimePicker2 } from '@hoot/components/form/DatePicker';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { daysOfWeekLabels } from '@hoot/pages/lessons/utils/date';
import { DayOfWeek } from '@hoot/utils/dateTime';
import CreateShiftFooter from '../CreateShiftFooter';
import { DayOfWeekForShift, teacherShiftTypeOptions, useCreateShiftContext } from '../context/CreateShiftContextProvider';
import { TentativeShiftForm } from '../teacher-conflicts-step/TeacherConflictCheckStep';
import { GenerateShift, ShiftTimeByDayOfWeek, generateShiftTimes } from '../utils/generateShiftTimes';

interface Form {
  shiftDate: DateTime;
  shiftStartTime: DateTime;
  shiftEndTime: DateTime;
  shiftType: TeacherShiftType;
  recurring: boolean;
  recurrenceEnd: RecurrenceEndType;
  occurrences: number;
  endAfterDate: DateTime;
  daysOfWeek: DayOfWeekForShift[];
}

const MAX_SHIFTS = 365;

export enum RecurrenceEndType {
  EndAfterDate = 'end_after_date',
  EndOnShiftCount = 'end_on_shift_count',
}

const CreateShiftDateTimeStep = () => {
  const { goToNextStep, shiftDetails, setShiftDetails } = useCreateShiftContext();

  const now = DateTime.now().plus({ minutes: 1 });

  function getMinStartDate() {
    return DateTime.now();
  }

  function getMaxStartDate() {
    return DateTime.now().plus({ year: 1 }).endOf('day');
  }

  const minShiftRecurrenceDate = DateTime.now().plus({ days: 7 }).endOf('day');

  const { control, handleSubmit, formState, getValues, setValue, watch } = useForm<Form>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      shiftDate: shiftDetails.shiftDate,
      shiftStartTime: shiftDetails.shiftStartTime,
      shiftEndTime: shiftDetails.shiftEndTime,
      shiftType: shiftDetails.shiftType,
      recurring: shiftDetails.recurring,
      recurrenceEnd: shiftDetails.recurrenceEnd,
      occurrences: shiftDetails.occurrences,
      endAfterDate: shiftDetails.endAfterDate,
      daysOfWeek: shiftDetails.daysOfWeek,
    },
  });

  const { recurring, recurrenceEnd } = watch();

  function validateEndDate(value: DateTime | undefined) {
    const { shiftStartTime } = getValues();
    if (value) {
      if (value <= shiftStartTime) {
        return 'End Date cannot be before Start Date';
      }
    }
    return true;
  }

  const onSubmit = (data: Form) => {
    const shiftStartsAt = DateTime.fromObject({
      year: data.shiftDate.year,
      month: data.shiftDate.month,
      day: data.shiftDate.day,
      hour: data.shiftStartTime.hour,
      minute: data.shiftStartTime.minute,
      second: 0,
      millisecond: 0,
    });

    const shiftEndsAt = DateTime.fromObject({
      year: data.shiftDate.year,
      month: data.shiftDate.month,
      day: data.shiftDate.day,
      hour: data.shiftEndTime.hour,
      minute: data.shiftEndTime.minute,
      second: 0,
      millisecond: 0,
    });

    let tentativeShifts: TentativeShiftForm[] = [];

    if (data.recurring) {
      const daysOfWeek = data.daysOfWeek
        .filter((d) => d.isSelected)
        .map<ShiftTimeByDayOfWeek>((d) => ({
          dayOfWeek: d.dayOfWeek,
          startTime: shiftStartsAt?.toFormat('H:mm') ?? DateTime.now().toFormat('H:mm'),
          endTime: shiftEndsAt?.toFormat('H:mm') ?? DateTime.now().toFormat('H:mm'),
        }));

      const request: GenerateShift =
        data.recurrenceEnd === RecurrenceEndType.EndAfterDate && data.endAfterDate
          ? {
              // FYI: `startsAt` just represents the start day range from which we can start creating shifts from.
              // It doesn't necessarily mean that the initial shifts will land on this day (`shiftTimeByDayOfWeek`
              // will determine first shift day starting from the `startsAt`).
              startsAt: shiftStartsAt.startOf('day'),
              endsAt: shiftEndsAt.startOf('day'),
              shiftTimeByDayOfWeek: daysOfWeek,
              endDate: data.endAfterDate,
            }
          : {
              startsAt: shiftStartsAt.startOf('day'),
              endsAt: shiftEndsAt.startOf('day'),
              shiftTimeByDayOfWeek: daysOfWeek,
              occurrences: data.occurrences,
            };

      tentativeShifts = generateShiftTimes(request);
    } else {
      tentativeShifts = [
        {
          startsAt: shiftStartsAt,
          endsAt: shiftEndsAt,
          skip: false,
        },
      ];
    }

    setShiftDetails({
      shiftDate: data.shiftDate,
      shiftStartTime: data.shiftStartTime,
      shiftEndTime: data.shiftEndTime,
      shiftType: data.shiftType,
      recurring: data.recurring,
      recurrenceEnd: data.recurrenceEnd,
      occurrences: data.occurrences,
      endAfterDate: data.endAfterDate,
      daysOfWeek: data.daysOfWeek,
      tentativeShifts: tentativeShifts,
      teacher: shiftDetails.teacher,
      preSelectedTeacher: shiftDetails.preSelectedTeacher,
      urlParamTeacherAccountId: shiftDetails.urlParamTeacherAccountId,
    });
    goToNextStep();
  };

  const minStartDate = getMinStartDate();
  const maxStartDate = getMaxStartDate();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Typography variant="titleMedium">Scheduling</Typography>
        <Stack sx={{ marginTop: '24px' }} direction="row" spacing="12px" gap={2}>
          <Controller
            name="shiftDate"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <DesktopDatePicker
                label={'Date'}
                disablePast
                minDate={minStartDate}
                onChange={field.onChange}
                value={field.value}
                format="LL/dd/yyyy"
                slotProps={{
                  textField: {
                    helperText: fieldState.error?.message,
                    error: !!fieldState.error,
                  },
                }}
              />
            )}
          />
          <Controller
            name="shiftStartTime"
            control={control}
            rules={{
              required: true,
              validate: (val) => {
                if (!val) {
                  return 'Selected Time is a required value';
                }

                const { shiftDate, shiftStartTime } = getValues();

                if (!shiftDate) {
                  return 'Date cannot be empty!';
                }

                try {
                  const startsAtDateTime = shiftDate.set({
                    hour: shiftStartTime.hour,
                    minute: shiftStartTime.minute,
                    second: 0,
                    millisecond: 0,
                  });

                  const now = DateTime.now();
                  if (startsAtDateTime.toMillis() < now.toMillis()) {
                    return `Selected Time must be greater than ${now.toFormat('h:mm a')}`;
                  }
                } catch (exc) {
                  console.error('Not a valid time', exc);
                  return 'Not a valid time.';
                }

                return true;
              },
            }}
            render={({ field, fieldState }) => (
              <TimePicker2
                onChange={field.onChange}
                value={field.value}
                label="Start Time"
                slotProps={{ textField: { error: !!fieldState.error, helperText: fieldState.error?.message } }}
              />
            )}
          />
          <Controller
            name="shiftEndTime"
            control={control}
            rules={{
              required: true,
              validate: (val) => {
                if (!val) {
                  return 'Selected Time is a required value';
                }

                const { shiftStartTime } = getValues();

                if (!shiftStartTime) {
                  return 'Date cannot be empty!';
                }

                if (val.toMillis() < shiftStartTime.toMillis()) {
                  return `Selected Time must be greater than ${shiftStartTime.toFormat('h:mm a')}`;
                }

                return true;
              },
            }}
            render={({ field, fieldState }) => (
              <TimePicker2
                onChange={field.onChange}
                value={field.value}
                label="End Time"
                slotProps={{ textField: { error: !!fieldState.error, helperText: fieldState.error?.message } }}
              />
            )}
          />
        </Stack>
        <FormGroup sx={{ marginTop: '24px', width: '150px', gap: 2 }}>
          <Dropdown name="shiftType" label="Type" options={teacherShiftTypeOptions} control={control} rules={{ required: true }} variant="outlined" />
          <Controller
            control={control}
            name="recurring"
            render={({ field }) => <FormControlLabel control={<Checkbox onChange={field.onChange} checked={field.value} />} label="Recurring" />}
          />
        </FormGroup>
        {recurring ? (
          <Stack marginLeft={10} marginTop="16px" gap={2}>
            <Typography variant="titleMedium">Recurrence Details</Typography>
            <Box>
              <Controller
                control={control}
                name="daysOfWeek"
                rules={{
                  validate: (data) => {
                    if (data.every((v) => v.isSelected === false)) {
                      return 'You must select 1 day of the week.';
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState }) => (
                  <DaysOfWeek
                    defaultTime={now}
                    onChange={field.onChange}
                    value={field.value}
                    errorMessage={fieldState.error?.message}
                    parentControl={control}
                    sameTimeEveryDay={false}
                    onSameTimeEveryDayChanged={(_checked) => {}}
                    setValue={setValue}
                  />
                )}
              />
            </Box>
            <Typography sx={{ marginTop: '24px' }} variant="titleMedium">
              Recurrence End
            </Typography>

            <Controller
              name="recurrenceEnd"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  onChange={field.onChange}
                  value={field.value}
                  sx={{
                    width: '250px;',
                  }}
                >
                  <FormControlLabel value={RecurrenceEndType.EndAfterDate} control={<Radio />} label="End after date" />
                  <FormControlLabel value={RecurrenceEndType.EndOnShiftCount} control={<Radio />} label="End on shift count" />
                </RadioGroup>
              )}
            />

            {recurrenceEnd === RecurrenceEndType.EndOnShiftCount ? (
              <Controller
                control={control}
                name="occurrences"
                rules={{
                  required: recurrenceEnd === RecurrenceEndType.EndOnShiftCount,
                  validate: (val: number | '' | undefined) => {
                    if (!val) {
                      return 'Number of shifts is required.';
                    }

                    if (val && val < 1) {
                      return 'A minimum of 1 shift is required.';
                    }

                    if (val && val > MAX_SHIFTS) {
                      return `Maximum number of shifts allowed is ${MAX_SHIFTS}.`;
                    }

                    return true;
                  },
                }}
                render={({ field, fieldState }) => (
                  <Stack>
                    <TextField
                      sx={{
                        marginLeft: '32px',
                        maxWidth: '156px',
                      }}
                      error={!!fieldState.error}
                      onChange={(e) => field.onChange(parseInt(e.currentTarget.value))}
                      value={field.value}
                      variant="outlined"
                      label="Number of shifts"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputProps: { min: 1, defaultValue: 1 },
                      }}
                      helperText={fieldState.error ? fieldState.error.message : null}
                    />
                  </Stack>
                )}
              />
            ) : (
              <FormControl
                sx={{
                  marginLeft: '32px',
                  maxWidth: '156px',
                }}
                fullWidth
              >
                <Controller
                  name="endAfterDate"
                  control={control}
                  rules={{
                    required: recurrenceEnd === RecurrenceEndType.EndAfterDate,
                    validate: validateEndDate,
                  }}
                  render={({ field, fieldState }) => (
                    <DesktopDatePicker
                      label={'Recurrance End'}
                      minDate={minShiftRecurrenceDate}
                      maxDate={maxStartDate}
                      onChange={field.onChange}
                      value={field.value}
                      format="LL/dd/yyyy"
                      slotProps={{
                        textField: {
                          helperText: fieldState.error?.message,
                          error: !!fieldState.error,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            )}
          </Stack>
        ) : undefined}
        <CreateShiftFooter>
          <Button disabled={true} variant="contained" size="large">
            Previous Step
          </Button>
          <Button disabled={!formState.errors} type="submit" variant="contained" size="large">
            Next Step
          </Button>
        </CreateShiftFooter>
      </Stack>
    </form>
  );
};

function DaysOfWeek(props: {
  onChange: (val: DayOfWeekForShift[]) => void;
  value: DayOfWeekForShift[];
  defaultTime: DateTime;
  errorMessage?: string;
  parentControl: Control<Form, any>;
  sameTimeEveryDay: boolean;
  onSameTimeEveryDayChanged: (checked: boolean) => void;
  setValue: UseFormSetValue<Form>;
}) {
  const handleClick = (dayOfWeek: DayOfWeek) => () => {
    const updatedValue = props.value.map((v) => (v.dayOfWeek === dayOfWeek ? { ...v, isSelected: !v.isSelected } : v));
    props.onChange(updatedValue);
  };

  return (
    <Stack>
      <Stack direction="row" spacing="8px">
        {props.value.map((dow) => (
          <Chip
            key={dow.dayOfWeek}
            icon={dow.isSelected ? <Check /> : undefined}
            sx={{
              backgroundColor: dow.isSelected ? '#D8E3F8' : '#FFFFFF',
            }}
            variant="outlined"
            label={daysOfWeekLabels.get(dow.dayOfWeek)}
            onClick={handleClick(dow.dayOfWeek)}
          />
        ))}
      </Stack>
      {props.errorMessage ? <FormHelperText error>{props.errorMessage}</FormHelperText> : null}
    </Stack>
  );
}

export default CreateShiftDateTimeStep;
