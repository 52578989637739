import React from 'react';

const ErrorIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM14.4 24V20.8H17.6V24H14.4ZM14.4 8V17.6H17.6V8H14.4Z"
      fill="#B3261E"
    />
  </svg>
);

export default ErrorIcon;
