import axios, { AxiosError, AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { InvoicingImportType } from '@hoot/pages/teacher-invoices/enums/invoicing-import-type.enum';

const getImportErrorFile = async (importId: string, importType: InvoicingImportType) =>
  await axios.get<string>(`${config.apiUrl}/admin/invoicing-import/${importId}/errors-file?&type=${importType}`);

export default function useGetInvoiceImportErrorFile(
  importId: string,
  importType: InvoicingImportType,
  options?: Omit<UseQueryOptions<AxiosResponse<string>, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetInvoiceImportErrorFile, importId], () => getImportErrorFile(importId, importType), options);
}
