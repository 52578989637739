import { useSearchParams } from 'react-router-dom';

type QueryParamMap<T> = {
  name: keyof T;
  type?: 'number' | 'boolean';
  isArray?: boolean;
  default?: any;
};

export function useMappedSearchParams<T>(mapping: QueryParamMap<T>[]) {
  const [searchParams] = useSearchParams();

  const paramKeys = Array.from(searchParams.keys());
  const paramValues = paramKeys.length > 0 ? paramKeys : (mapping.filter((m) => !!m.default).map((m) => m.name) as string[]);

  const params = paramValues.reduce((a, key) => {
    const mappedParameter = mapping.find((m) => m.name === key);

    function getValue(): any {
      // assumed that the parameter is a string
      if (!mappedParameter) {
        return searchParams.get(key);
      }

      if (mappedParameter.isArray) {
        const parameterValues = searchParams.getAll(key);

        if (!parameterValues.length && !!mappedParameter?.default.length) {
          return mappedParameter.default;
        }

        if (!mappedParameter.type) {
          return parameterValues;
        } else if (mappedParameter.type === 'number') {
          return parameterValues.map((v) => parseInt(v)).filter((v) => !isNaN(v));
        } else if (mappedParameter.type === 'boolean') {
          return parameterValues.map((v) => v === 'true');
        }
      } else {
        const parameterValue = searchParams.get(key);
        if (!parameterValue) {
          return mappedParameter.default ?? undefined;
        }

        if (!mappedParameter.type) {
          return parameterValue;
        } else if (mappedParameter.type === 'number') {
          const val = parseInt(parameterValue);
          if (isNaN(val)) {
            return mappedParameter.default ?? undefined;
          }
          return val;
        } else if (mappedParameter.type === 'boolean') {
          return parameterValue.toLowerCase() === 'true';
        }
      }

      return '';
    }

    const value = getValue();

    return { ...a, [key]: value };
  }, {}) as T;

  return params;
}
