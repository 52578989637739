import axios, { AxiosError } from 'axios';
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface TeacherBlockHistoryResponse {
  historyEntries: BlockHistoryEntry[];
}

export interface BlockHistoryEntry {
  id: string;
  note: string;
  isComment: boolean;
  createdBy: string;
  createdById: string;
  createdAt: number;
}

async function getTeacherBlockHistory(blockId: string): Promise<TeacherBlockHistoryResponse> {
  const { data } = await axios.get(`${config.apiUrl}/admin/teacher-block/${blockId}/history`);
  return data;
}

export default function useGetTeacherBlockHistory(
  blockId: string,
  options?: Omit<UseQueryOptions<TeacherBlockHistoryResponse, AxiosError>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TeacherBlockHistoryResponse, AxiosError> {
  return useQuery([QueryKey.GetTeacherBlockHistory, blockId], () => getTeacherBlockHistory(blockId), options);
}
