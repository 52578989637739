import { Breadcrumbs, Card, CardContent, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { createShiftWizardSteps, useCreateShiftContext } from './context/CreateShiftContextProvider';

const CreateShiftWizard = () => {
  const { currentStep } = useCreateShiftContext();

  return (
    <>
      <Card>
        <CardContent>
          <Breadcrumbs>
            <Typography color="text.primary" variant="bodySmall">
              Shifts
            </Typography>
            <Typography color="text.primary" variant="bodySmall">
              Create Shift
            </Typography>
          </Breadcrumbs>
          <Typography variant="headlineLarge">Create Shift Wizard</Typography>
          <Stepper activeStep={currentStep.id - 1} alternativeLabel sx={{ marginTop: 10, marginBottom: 5 }}>
            {createShiftWizardSteps.map((step) => (
              <Step key={step.id}>
                <StepLabel>{step.stepperLabel}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Stack>
            <Typography variant="titleLarge" sx={{ marginBottom: 3 }}>
              Step {currentStep.id} - {currentStep.contentLabel ?? currentStep.stepperLabel}
            </Typography>
            {currentStep.content()}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default CreateShiftWizard;
