import React from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';

interface Props {
  children: React.ReactNode;
}

export enum TimePickerStyle {
  Analog = 'analog',
  Digital = 'digital',
}

interface Values {
  timePickerStyle: TimePickerStyle;
  setTimePickerStyle: React.Dispatch<React.SetStateAction<TimePickerStyle>>;
}

const UserPreferencesContext = React.createContext<Values>(undefined!);

export const UserPreferencesProvider = (props: Props) => {
  const [timePickerStyle, setTimePickerStyle] = useLocalStorage<TimePickerStyle>('', TimePickerStyle.Analog);

  return <UserPreferencesContext.Provider value={{ timePickerStyle, setTimePickerStyle }}>{props.children}</UserPreferencesContext.Provider>;
};

export const useUserPreferences = () => {
  const context = React.useContext(UserPreferencesContext);

  if (context === undefined) {
    throw new Error('useUserPreferences must be used within a UserPreferencesProvider');
  }

  return context;
};
