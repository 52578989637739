import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';
import { InvoicingImportType } from '@hoot/pages/teacher-invoices/enums/invoicing-import-type.enum';

export interface InvoicingImportResponse {
  id: string;
  type: InvoicingImportType;
  importCounts?: ImportedInvoicingCounts;
}

export interface ImportedInvoicingCounts {
  created: number;
  errored: number;
}

const importCSV = async (formData: FormData): Promise<InvoicingImportResponse> => {
  const type = formData.get('importType');
  const periodId = formData.get('periodId');

  const { data } = await axios.post<InvoicingImportResponse>(`${config.apiUrl}/admin/invoicing-import?periodId=${periodId}&type=${type}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};

export default function useInvoiceImportCsv(
  options?: Omit<UseMutationOptions<InvoicingImportResponse, AxiosError<ErrorResponseDto>, FormData>, 'mutationFn' | 'mutationKey'>,
) {
  return useMutation((data) => importCSV(data), options);
}
