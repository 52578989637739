import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { StudentSpecialIndicators } from '@hoot-reading/hoot-core/dist/enums/user/student/student-special-indicators.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface LocationSummaryDashboardResponse {
  scheduledLessons: string;
  paidLessons: string;
  uniqueStudentAssociations: string;
  hoursInLesson: string;
  attendanceRate: string;
  uniqueTeachers: string;
}

export interface LocationSummaryQuery {
  productId: string[];
  productStatus: ProductStatus[];
  enrolmentId: string[];
  enrolmentStatus: EnrolmentStatus[];
  schoolId: string[];
  studentStatus: StudentStatus[];
  studentGrade: StudentGrade[];
  studentIndicator: StudentSpecialIndicators[];
}

const getLocationSummary = async (locationId: string, query: LocationSummaryQuery): Promise<LocationSummaryDashboardResponse> => {
  const { data } = await axios.get<LocationSummaryDashboardResponse>(`${config.apiUrl}/admin/hfs/location/${locationId}/summary/dashboard`, {
    params: query,
  });
  return data;
};

export default function useGetLocationSummaryDashboard(
  locationId: string,
  query: LocationSummaryQuery,
  options?: Omit<UseQueryOptions<LocationSummaryDashboardResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetLocationSummaryDashboard, locationId, query], () => getLocationSummary(locationId, query), options);
}
