import {
  CancellationReason,
  ScheduledLessonLanguage,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonType,
  SubRequired,
  cancellationReasonLookup,
  lessonStatusesLookup,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { Close } from '@mui/icons-material';
import { Box, Button, Drawer, FormControl, Grid, IconButton, InputLabel, List, ListItem, MenuItem, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DateTimePicker2, TimePicker2 } from '@hoot/components/form/DatePicker';
import { Select } from '@hoot/components/form/SelectField';
import { AccountSelect } from '@hoot/components/form/selectFields/AccountSelect';
import { DistrictSelect } from '@hoot/components/form/selectFields/DistrictSelect';
import LessonSetAutoComplete from '@hoot/components/form/selectFields/LessonSetAutoComplete';
import { SchoolAutoComplete } from '@hoot/components/form/selectFields/SchoolAutoComplete';
import useGetAllEnrolments from '@hoot/hooks/api/hfs/useGetAllEnrolments';
import useGetDistricts from '@hoot/hooks/api/hfs/useGetDistricts';
import { SchoolSummary } from '@hoot/hooks/api/hfs/useSearchSchools';
import { LessonQueryRequest } from '@hoot/hooks/api/lessons/lesson-query-request';
import { DayOfWeek, daysOfWeekList } from '@hoot/utils/dateTime';
import { TIME_FORMAT } from '../../common/constants';
import { MultiSelect } from '../../components/form/MultiSelect';
import TeacherAutoComplete from '../../components/form/TeacherAutoComplete';
import { LanguageSelect } from '../../components/form/selectFields/LanguageSelect';
import { LessonTypeSelect } from '../../components/form/selectFields/LessonTypeSelect';
import { StudentProfileStatusSelect } from '../../components/form/selectFields/StudentProfileStatusSelect';
import { SubRequiredSelect } from '../../components/form/selectFields/SubRequiredSelect';
import { SubjectSelect } from '../../components/form/selectFields/SubjectSelect';
import { TeacherAssignedSelect } from '../../components/form/selectFields/TeacherAssignedSelect';
import { TeacherAccount } from '../../hooks/api/user/teacher/useSearchTeacherAccounts';
import { useLessons } from './LessonContextProvider';
import { TeacherAssigned } from './enums';

interface Props {
  open: boolean;
  handleClose: () => void;
  query: LessonQueryRequest;
  setQuery: React.Dispatch<React.SetStateAction<LessonQueryRequest>>;
  setSelectedSchoolFilter: React.Dispatch<React.SetStateAction<SchoolSummary | undefined>>;
  selectedSchoolFilter: SchoolSummary | undefined;
}

interface Form {
  fromDate: number | null;
  toDate: number | null;
  fromStartTime: number | null;
  toStartTime: number | null;
  status: ScheduledLessonStatus[] | [];
  cancelledReason: CancellationReason[] | [];
  lessonType: ScheduledLessonType | '';
  account: string | '';
  studentProfileStatus: StudentStatus | '';
  subject: ScheduledLessonSubject | '';
  language: ScheduledLessonLanguage | '';
  isRecurring: 'yes' | 'no' | '';
  lessonSetNumber: string;
  previousTeacherAccount: TeacherAccount;
  subRequired: SubRequired | '';
  daysOfWeek: DayOfWeek[] | [];
  teacherAssigned: TeacherAssigned;
  isCustomerBillable: 'yes' | 'no' | '';
  district: string | null;
  school: string | null;
  enrolmentIds: string[] | [];
}

const LessonFilters = (props: Props) => {
  const { handleClose, open, query, setQuery, selectedSchoolFilter, setSelectedSchoolFilter } = props;
  const { previousTeacherAccountFilter, setPreviousTeacherAccountFilter } = useLessons();
  const { data: enrolmentsResponse } = useGetAllEnrolments();
  const [districtFieldDisabled, setDistrictFieldDisabled] = useState<boolean>(false);
  const { handleSubmit, control, reset, watch, setValue } = useForm<Form>({
    defaultValues: {
      fromDate: query.fromDate ?? null,
      toDate: query.toDate ?? null,
      fromStartTime: query.fromStartTime ? DateTime.fromFormat(query.fromStartTime, TIME_FORMAT).toMillis() : null,
      toStartTime: query.toStartTime ? DateTime.fromFormat(query.toStartTime, TIME_FORMAT).toMillis() : null,
      account: query.accountId || '',
      status: query.status || [],
      studentProfileStatus: query.studentProfileStatus || '',
      subject: query.subject || '',
      language: query.language || '',
      lessonType: query.type || '',
      cancelledReason: query.cancelledReason || [],
      isRecurring: query.isRecurring === undefined ? '' : query.isRecurring ? 'yes' : 'no',
      lessonSetNumber: query.lessonSetNumber,
      subRequired: query.subRequired || '',
      previousTeacherAccount: previousTeacherAccountFilter,
      daysOfWeek: query.daysOfWeek || [],
      teacherAssigned: query.teacherAssigned,
      isCustomerBillable: query.isCustomerBillable === undefined ? '' : query.isCustomerBillable ? 'yes' : 'no',
      district: query.districtId ?? null,
      school: query.schoolId ?? null,
      enrolmentIds: query.enrolmentId ?? [],
    },
  });
  const districtsQuery = useGetDistricts();

  const enrolmentsOptions =
    enrolmentsResponse && enrolmentsResponse.enrolments
      ? enrolmentsResponse.enrolments.map((option) => {
          return { value: option.id, label: option.friendlyId };
        })
      : [];

  const { district, school } = watch();

  useEffect(() => {
    if ((query.districtId && query.districtId !== district) || (query.schoolId && query.schoolId !== school)) {
      setValue('enrolmentIds', []);
    }
  }, [setValue, district, school, query.districtId, query.schoolId]);

  useEffect(() => {
    if (selectedSchoolFilter?.districtId !== district) {
      setValue('school', null);
    }
  }, [setValue, district, selectedSchoolFilter?.districtId]);

  useEffect(() => {
    if (selectedSchoolFilter) {
      const matchedDistrict = districtsQuery.data?.districts.find((d) => d.id === selectedSchoolFilter.districtId);

      if (!selectedSchoolFilter.isStandAloneSchool && selectedSchoolFilter.districtId && matchedDistrict) {
        if (matchedDistrict.id !== district) {
          setValue('district', matchedDistrict.id);
          setDistrictFieldDisabled(false);
        }
      } else {
        setValue('district', null);
        setDistrictFieldDisabled(true);
      }
    }
  }, [setValue, selectedSchoolFilter, districtsQuery.data?.districts, district]);

  const handleCancelClick = () => {
    handleClose();
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    if (data.previousTeacherAccount) {
      setPreviousTeacherAccountFilter(data.previousTeacherAccount);
    }

    setQuery((q) => ({
      ...q,
      page: 1,
      fromDate: !!data.fromDate ? data.fromDate : undefined,
      toDate: !!data.toDate ? data.toDate : undefined,
      fromStartTime: !!data.fromStartTime ? DateTime.fromMillis(data.fromStartTime).toFormat(TIME_FORMAT) : undefined,
      toStartTime: !!data.toStartTime ? DateTime.fromMillis(data.toStartTime).toFormat(TIME_FORMAT) : undefined,
      accountId: !!data.account ? data.account : undefined,
      cancelledReason: !!data.cancelledReason ? data.cancelledReason : undefined,
      status: !!data.status ? data.status : undefined,
      type: !!data.lessonType ? data.lessonType : undefined,
      studentProfileStatus: !!data.studentProfileStatus ? data.studentProfileStatus : undefined,
      subject: !!data.subject ? data.subject : undefined,
      language: !!data.language ? data.language : undefined,
      isRecurring: data.isRecurring === '' ? undefined : data.isRecurring === 'yes',
      lessonSetNumber: !!data.lessonSetNumber ? data.lessonSetNumber : undefined,
      previousTeacherAccountEmails: data.previousTeacherAccount ? [data.previousTeacherAccount.user.email] : undefined,
      subRequired: !!data.subRequired ? data.subRequired : undefined,
      daysOfWeek: !!data.daysOfWeek ? data.daysOfWeek : undefined,
      teacherAssigned: !!data.teacherAssigned ? data.teacherAssigned : undefined,
      isCustomerBillable: data.isCustomerBillable === '' ? undefined : data.isCustomerBillable === 'yes',
      districtId: data.district ?? undefined,
      schoolId: data.school ?? undefined,
      enrolmentId: data.enrolmentIds ?? undefined,
    }));

    handleClose();
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ flex: '1 1 auto', overflowY: 'auto', position: 'relative' }}>
          <Box sx={{ maxWidth: '320px', minWidth: '320px' }}>
            <Box sx={{ position: 'relative', paddingBottom: '64px' }}>
              <Typography
                sx={{
                  marginLeft: '24px',
                  marginTop: '105px',
                  fontWeight: 600,
                  fontSize: '20px',
                  lineHeight: '48px',
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
              >
                Lesson Filters
              </Typography>

              <IconButton
                onClick={handleCancelClick}
                sx={{
                  position: 'absolute',
                  right: '8px',
                  top: '4px',
                  cursor: 'pointer',
                }}
              >
                <Close />
              </IconButton>
              <form id={'lesson-form'} onSubmit={handleSubmit(onSubmit)}>
                <List disablePadding sx={{ marginTop: '16px' }}>
                  <ListItem>
                    <FormControl variant="outlined" fullWidth sx={{ gap: '2px' }}>
                      <Controller
                        control={control}
                        name="fromDate"
                        render={({ field }) => <DateTimePicker2 onChange={field.onChange} value={field.value} label="From Date" />}
                      />
                    </FormControl>
                  </ListItem>

                  <ListItem>
                    <FormControl variant="outlined" fullWidth sx={{ gap: '2px' }}>
                      <Controller
                        control={control}
                        name="toDate"
                        render={({ field }) => <DateTimePicker2 onChange={field.onChange} value={field.value} label="To Date" />}
                      />
                    </FormControl>
                  </ListItem>

                  <ListItem>
                    <FormControl variant="outlined" fullWidth>
                      <Controller
                        name="fromStartTime"
                        control={control}
                        render={({ field }) => (
                          <TimePicker2
                            ampm
                            label="From Start Time"
                            onChange={(val) => val?.toMillis()}
                            value={field.value ? DateTime.fromMillis(field.value) : undefined}
                            slotProps={{
                              textField: {
                                size: 'small',
                              },
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </ListItem>
                  <ListItem>
                    <FormControl variant="outlined" fullWidth>
                      <Controller
                        name="toStartTime"
                        control={control}
                        render={({ field }) => (
                          <TimePicker2
                            ampm
                            label="To Start Time"
                            onChange={(val) => val?.toMillis()}
                            value={field.value ? DateTime.fromMillis(field.value) : undefined}
                            slotProps={{
                              textField: {
                                size: 'small',
                              },
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </ListItem>

                  <ListItem>
                    <MultiSelect size="small" name="daysOfWeek" variant="outlined" label="Days of Week" data={daysOfWeekList} control={control} />
                  </ListItem>
                  <ListItem>
                    <MultiSelect
                      size="small"
                      name="status"
                      variant="outlined"
                      label="Status"
                      data={Object.entries(lessonStatusesLookup).map((v) => ({ label: v[1], value: v[0] }))}
                      control={control}
                    />
                  </ListItem>
                  <ListItem>
                    <MultiSelect
                      size="small"
                      name="cancelledReason"
                      variant="outlined"
                      label="Cancellation Reason"
                      data={Object.entries(cancellationReasonLookup).map((v) => ({ label: v[1], value: v[0] }))}
                      control={control}
                    />
                  </ListItem>
                  <ListItem>
                    <LessonTypeSelect size="small" control={control} name="lessonType" includeEmpty />
                  </ListItem>
                  <ListItem>
                    <AccountSelect size="small" name="account" control={control} includeEmpty />
                  </ListItem>
                  <ListItem>
                    <DistrictSelect
                      size="small"
                      control={control}
                      name="district"
                      includeEmpty
                      disabled={districtFieldDisabled}
                      districts={districtsQuery.data?.districts}
                    />
                  </ListItem>
                  <ListItem>
                    <Controller
                      name="school"
                      control={control}
                      render={({ field }) => (
                        <SchoolAutoComplete
                          {...field}
                          size="small"
                          districtId={district ?? undefined}
                          setSelectedSchoolFilter={setSelectedSchoolFilter}
                        />
                      )}
                    />
                  </ListItem>
                  <ListItem>
                    <MultiSelect size="small" control={control} label="Enrolment" name="enrolmentIds" data={enrolmentsOptions} />
                  </ListItem>
                  <ListItem>
                    <StudentProfileStatusSelect size="small" control={control} name="studentProfileStatus" includeEmpty />
                  </ListItem>
                  <ListItem>
                    <SubjectSelect size="small" control={control} name="subject" includeEmpty />
                  </ListItem>
                  <ListItem>
                    <LanguageSelect size="small" control={control} name="language" includeEmpty />
                  </ListItem>

                  <ListItem>
                    <FormControl size="small" variant="outlined" fullWidth>
                      <InputLabel id="is-recurring-label">Is Recurring Lesson?</InputLabel>
                      <Select control={control} name="isRecurring" labelId="is-recurring-label" label="Is Recurring" fullWidth>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                      </Select>
                    </FormControl>
                  </ListItem>
                  <ListItem>
                    <Controller name="lessonSetNumber" control={control} render={({ field }) => <LessonSetAutoComplete {...field} size="small" />} />
                  </ListItem>

                  <ListItem>
                    <Controller
                      name="previousTeacherAccount"
                      control={control}
                      render={({ field }) => <TeacherAutoComplete {...field} size="small" label="Previously Assigned Teacher" />}
                    />
                  </ListItem>

                  <ListItem>
                    <SubRequiredSelect size="small" name="subRequired" control={control} />
                  </ListItem>

                  <ListItem>
                    <TeacherAssignedSelect size="small" name="teacherAssigned" control={control} includeEmpty />
                  </ListItem>

                  {/* Customer Billable filter.*/}
                  <ListItem>
                    <FormControl size="small" variant="outlined" fullWidth>
                      <InputLabel id="is-lesson-set-label">Is Customer Billable?</InputLabel>
                      <Select control={control} name="isCustomerBillable" labelId="is-customer-billable-label" label="Is Customer Billable" fullWidth>
                        <MenuItem value="">None</MenuItem>
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                      </Select>
                    </FormControl>
                  </ListItem>
                </List>
              </form>
            </Box>
          </Box>
        </Box>
        <Box sx={{ flex: '0 0 auto', borderTop: '1px solid rgba(0, 0, 0, 0.12)', position: 'sticky', bottom: 0, background: '#fff' }}>
          <Grid container p={2}>
            <Grid item container>
              <Button type="submit" size="small" variant="contained" fullWidth form="lesson-form">
                Apply
              </Button>
            </Grid>
            <Grid item container sx={{ marginTop: '16px' }}>
              <Button color="inherit" type="button" onClick={() => reset()} size="small" variant="contained" fullWidth>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

export default LessonFilters;
