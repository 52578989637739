import { cancellationReasonLookup, lessonStatusesLookup, scheduledLessonTypeLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { teacherShiftStatusLookup } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import { Close } from '@mui/icons-material';
import { Box, Button, CircularProgress, Dialog, DialogActions, IconButton, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { DateFormats, TIME_FORMAT } from '@hoot/common/constants';
import { NonFormDisplayField } from '@hoot/components/form/NonFormDisplayField';
import { useGetShiftDetails } from '@hoot/hooks/api/availability/useGetTeacherShiftDetails';
import { useGetLesson } from '@hoot/hooks/api/lessons/useGetLesson';
import { routes } from '@hoot/routes/routes';
import FullCircleIcon from '../../../../components/icons/FullCircle';
import { SelectedItem } from './weekly-schedule/interfaces';
import { lessonColour, shiftColour } from './weekly-schedule/utils';

interface UserScheduleDetailsDialogProps {
  teacherAccountId?: string;
  selectedItem?: SelectedItem;
  isStudentView?: boolean;
  handleClose: () => void;
}

export function UserScheduleDetailsDialog(props: UserScheduleDetailsDialogProps) {
  return (
    <Dialog open onClose={props.handleClose} maxWidth={'xs'} fullWidth>
      <Stack sx={{ padding: '24px 24px 0px' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="headlineSmall">
            {props.selectedItem?.type === 'LESSON' && 'Lesson Details'}
            {props.selectedItem?.type === 'SHIFT' && 'Shift Details'}
          </Typography>
          <IconButton onClick={props.handleClose}>
            <Close />
          </IconButton>
        </Box>
        {props.selectedItem?.type === 'SHIFT'
          ? props.selectedItem.ids.map((id) =>
              props.teacherAccountId ? <ShiftDetails key={id} teacherAccountId={props.teacherAccountId} shiftId={id} /> : null,
            )
          : null}
        {props.selectedItem?.type === 'LESSON'
          ? props.selectedItem.ids.map((id) => <LessonDetails key={id} isStudentView={props.isStudentView} lessonId={id} />)
          : null}
      </Stack>
      <DialogActions>
        <Button onClick={props.handleClose} size="large" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ShiftDetails(props: { teacherAccountId: string; shiftId: string }) {
  const getShiftRequest = useGetShiftDetails(props.teacherAccountId, props.shiftId);

  if (!getShiftRequest.data) {
    return null;
  }

  const startTime = DateTime.fromMillis(getShiftRequest.data.startsAt);
  const endTime = DateTime.fromMillis(getShiftRequest.data.endsAt);

  return (
    <>
      <NonFormDisplayField
        label="Shift ID"
        value={
          <Link to={routes.users.teachers.shifts.details.url(props.teacherAccountId, props.shiftId)}>{getShiftRequest.data.number.toString()}</Link>
        }
        paddingX="16px"
        isElevated
      />
      <NonFormDisplayField
        label="Shift Status"
        value={
          <>
            <FullCircleIcon sx={{ height: '8px', width: '8px' }} fill={shiftColour(getShiftRequest.data.status)} />{' '}
            {teacherShiftStatusLookup[getShiftRequest.data.status]}
          </>
        }
        paddingX="16px"
        isElevated
      />
      <NonFormDisplayField label="Date" value={startTime.toFormat(DateFormats.SHORT_MDY)} paddingX="16px" isElevated />

      <NonFormDisplayField label="Start Time" value={startTime.toFormat(TIME_FORMAT)} paddingX="16px" isElevated />

      <NonFormDisplayField label="End Time" value={endTime.toFormat(TIME_FORMAT)} paddingX="16px" isElevated />
    </>
  );
}

function LessonDetails(props: { lessonId: string; isStudentView?: boolean }) {
  const lessonDetailsRequest = useGetLesson(props.lessonId);

  if (!lessonDetailsRequest.data) {
    return <CircularProgress />;
  }

  const lessonStartsAt = DateTime.fromMillis(lessonDetailsRequest.data.startsAt);

  return (
    <>
      <NonFormDisplayField
        paddingX="16px"
        label="Lesson Number"
        value={lessonDetailsRequest.data.lessonNumber.toString()}
        linkTo={routes.lessons.details.url(lessonDetailsRequest.data.lessonId)}
        isElevated
      />
      <NonFormDisplayField
        paddingX="16px"
        label="Lesson Status"
        value={
          <>
            <FullCircleIcon sx={{ height: '8px', width: '8px' }} fill={lessonColour(lessonDetailsRequest.data.lessonStatus)} />{' '}
            {lessonStatusesLookup[lessonDetailsRequest.data.lessonStatus]}
          </>
        }
        isCopyable={false}
        isElevated
      />
      {lessonDetailsRequest.data.cancellationReason ? (
        <NonFormDisplayField
          paddingX="16px"
          label="Cancellation Reason"
          value={cancellationReasonLookup[lessonDetailsRequest.data.cancellationReason]}
          isCopyable={false}
          isElevated
        />
      ) : null}
      <NonFormDisplayField
        paddingX="16px"
        label="Type"
        value={scheduledLessonTypeLookup[lessonDetailsRequest.data.lessonType]}
        isCopyable={false}
        isElevated
      />
      <NonFormDisplayField paddingX="16px" label="Date" value={lessonStartsAt.toFormat(DateFormats.SHORT_MDY)} isCopyable={false} isElevated />
      <NonFormDisplayField paddingX="16px" label="Start Time" value={lessonStartsAt.toFormat(TIME_FORMAT)} isCopyable={false} isElevated />
      <NonFormDisplayField paddingX="16px" label="Duration" value={`${lessonDetailsRequest.data.duration} Minutes`} isCopyable={false} isElevated />
      {props.isStudentView ? (
        <NonFormDisplayField
          paddingX="16px"
          label="Teacher"
          value={lessonDetailsRequest.data.teacherNumber?.toString() ?? 'N/A'}
          linkTo={
            lessonDetailsRequest.data.teacherAccountId ? routes.users.teachers.details.url(lessonDetailsRequest.data.teacherAccountId) : undefined
          }
          isElevated
        />
      ) : (
        <NonFormDisplayField
          paddingX="16px"
          label="Student"
          value={lessonDetailsRequest.data.studentNumber.toString()}
          linkTo={routes.users.students.details.url(lessonDetailsRequest.data.studentProfileId)}
          isElevated
        />
      )}
    </>
  );
}
