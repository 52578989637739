import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../../queryKeys';

const getImportErrorFile = async (importId: string) =>
  await axios.get<any>(`${config.apiUrl}/admin/teacher-reliability-score-import/${importId}/errors-file`);

export default function useGetTRSImportErrors(importId: string, options?: Omit<UseQueryOptions<any, AxiosError>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetTRSImportErrors, importId], () => getImportErrorFile(importId), options);
}
