import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface CurrentServerTime {
  time: number;
}

async function fetchCurrentTime(): Promise<CurrentServerTime> {
  const { data } = await axios.get<CurrentServerTime>(`${config.apiUrl}/app/current-server-time`);
  return data;
}

export default function useFetchCurrentTime(options?: Omit<UseQueryOptions<CurrentServerTime>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.CurrentTime], () => fetchCurrentTime(), options);
}
