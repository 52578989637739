import { TeacherBlockType } from '@hoot-reading/hoot-core/dist/enums/teacher-block';
import { AddCircle, Cancel } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { SelectedTeacher } from '@hoot/components/modals/SelectTeacher';
import { BlockUtilizationBlock, BlockUtilizationTeacher } from '@hoot/hooks/api/blocks/useGetBlockUtilization';
import { BaseComponentProps } from '@hoot/types';
import { BlockColor } from '../../block-constants';
import { BlockTimeSlot, SelectedTimeSlot } from '../../block-interfaces';

interface ITimeSlotProps {
  timeSlot: BlockTimeSlot;
  hasLesson?: boolean;
  teacher?: BlockUtilizationTeacher;
  block?: BlockUtilizationBlock;
  blockStarts?: boolean;
  blockEnds?: boolean;
  onBlockClick?: React.Dispatch<React.SetStateAction<BlockUtilizationBlock | undefined>>;
  showCreateBlockWizard?: boolean;
  setShowCreateBlockWizard?: React.Dispatch<React.SetStateAction<SelectedTimeSlot | undefined>>;
}

const TimeSlot = (props: ITimeSlotProps & BaseComponentProps) => {
  const { timeSlot, children, block, blockEnds, blockStarts, onBlockClick, setShowCreateBlockWizard, hasLesson, teacher } = props;
  const [isHovered, setIsHovered] = useState(false);
  const now = DateTime.now().toMillis();

  const timeSlotIsInThePast = now > timeSlot.start;

  const handleBlockClick = () => {
    if (onBlockClick && block) {
      onBlockClick(block);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleSelectTimeSlot = () => {
    if (!block && !hasLesson && !!setShowCreateBlockWizard && teacher && !timeSlotIsInThePast) {
      const selectedTeacher: SelectedTeacher = {
        id: teacher.id,
        name: teacher.name,
        number: teacher.prefixedNumber,
      };
      setShowCreateBlockWizard({
        startDate: DateTime.fromMillis(timeSlot.start),
        endDate: DateTime.fromMillis(timeSlot.end),
        teacher: selectedTeacher,
      });
    }
  };

  const handleTimeSlotIcon = () => {
    if (isHovered && !!setShowCreateBlockWizard && !hasLesson && !block) {
      if (now > timeSlot.start) {
        return (
          <Tooltip title={'Can not create block in the past'}>
            <Cancel sx={{ color: 'red', fontSize: 32 }} />
          </Tooltip>
        );
      } else {
        return <AddCircle sx={{ color: 'green', fontSize: 32 }} />;
      }
    }
  };

  const handleBlockColor = (block: BlockUtilizationBlock) => {
    if (block.cancelledAt) {
      return BlockColor.Cancelled;
    }
    if (block.type === TeacherBlockType.Interview) {
      return BlockColor.Interview;
    }
    return BlockColor.Regular;
  };

  return (
    <Stack
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleSelectTimeSlot}
      justifyContent={'center'}
      sx={{
        width: '130px',
        borderLeft: '1px solid #CAC4D0',
        borderBottom: '1px solid #CAC4D0',
        '&:last-child': { borderRight: '1px solid #CAC4D0' },
        '&:hover': {
          cursor: !!setShowCreateBlockWizard && !timeSlotIsInThePast ? 'pointer' : undefined,
        },
      }}
    >
      <Stack
        onClick={handleBlockClick}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: block ? '8px' : '0px 8px',
          marginY: 1,
          ...(block
            ? {
                background: handleBlockColor(block),
                '&:hover': {
                  cursor: 'pointer',
                },
              }
            : {}),
          ...(blockStarts ? { paddingLeft: 1, marginLeft: 1, borderRadius: '8px 0px 0px 8px' } : {}),
          ...(blockEnds ? { paddingRight: 1, marginRight: 1, borderRadius: '0px 8px 8px 0px' } : {}),
          ...(blockStarts && blockEnds ? { borderRadius: '8px' } : {}),
        }}
      >
        {children}
        {handleTimeSlotIcon()}
      </Stack>
    </Stack>
  );
};

export default TimeSlot;
