import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface AdminHootLevelReportCSVRequest {
  onlyIncludeAssessmentsBefore?: number;
  studentProfileIds?: string[];
  enrolmentId?: string | null;
}

const getHootLevelsReportCSV = async (request: AdminHootLevelReportCSVRequest) =>
  await axios.post<any>(`${config.apiUrl}/admin/hoot-level/report/csv`, request);

export default function useGetHootLevelsReportCSV(
  request: AdminHootLevelReportCSVRequest,
  options?: Omit<UseQueryOptions<any, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetHootLevelsReportCSV, request], () => getHootLevelsReportCSV(request), options);
}
