import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { Card, CardContent, CardHeader, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { FormState } from 'react-hook-form';
import ChipToggleDisplay from '@hoot/components/form/ChipToggleDisplay';
import { BackgroundCheck, backgroundCheckOptions, hootQualificationOptions } from '@hoot/interfaces/teacher';
import { TeacherAccountAndUserForm } from '@hoot/pages/users/interfaces/form';

interface Props {
  setSelectedHootQualifications: Dispatch<SetStateAction<HootQualification[]>>;
  setSelectedBackgroundChecks: Dispatch<SetStateAction<BackgroundCheck[]>>;
  selectedHootQualifications: HootQualification[];
  selectedBackgroundChecks: BackgroundCheck[];
  formState: FormState<TeacherAccountAndUserForm>;
}

const TeacherLanguageAndQualificationsCard = (props: Props) => {
  const { selectedHootQualifications, setSelectedHootQualifications, formState, selectedBackgroundChecks, setSelectedBackgroundChecks } = props;

  const handleHootQualificationClick = (selectedOption: HootQualification, isAdded: boolean) => {
    if (isAdded) {
      const typedSelectedOption = selectedOption as HootQualification;
      setSelectedHootQualifications((currentArray) => [...(currentArray as HootQualification[]), typedSelectedOption]);
    } else {
      const typedSelectedOption = selectedOption as HootQualification;
      setSelectedHootQualifications((currentArray) => (currentArray as HootQualification[]).filter((x) => x !== typedSelectedOption));
    }
  };

  const handleBackgroundCheckClick = (selectedOption: BackgroundCheck, isAdded: boolean) => {
    if (isAdded) {
      setSelectedBackgroundChecks((currentArray) => [...currentArray, selectedOption]);
    } else {
      setSelectedBackgroundChecks((currentArray) => currentArray.filter((x) => x !== selectedOption));
    }
  };

  return (
    <Grid container item>
      <Card
        sx={{
          width: 1,
        }}
      >
        <CardHeader
          sx={{ p: 3, pb: 0 }}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="titleLarge">{'Qualifications'}</Typography>
            </Stack>
          }
        />
        <CardContent sx={{ p: 3, pt: 0 }}>
          <Grid marginTop="24px" container sx={{ rowGap: 3 }}>
            <Grid item container xs={12} flexDirection="column">
              <Grid item>
                <Typography variant="bodySmall" sx={{ color: `${formState.errors.hootQualifications ? '#B3261E' : '#1C1B1F'}` }}>
                  Hoot Qualifications
                </Typography>
              </Grid>
              <Grid item>
                <ChipToggleDisplay options={hootQualificationOptions} values={selectedHootQualifications} onChange={handleHootQualificationClick} />
                {formState.errors.hootQualifications ? <FormHelperText sx={{ color: '#B3261E' }}>A selection is required</FormHelperText> : null}
              </Grid>
            </Grid>
            <Grid item container xs={12} flexDirection="column">
              <Grid item>
                <Typography variant="bodySmall" sx={{ color: `${formState.errors.hootQualifications ? '#B3261E' : '#1C1B1F'}` }}>
                  Background Checks
                </Typography>
              </Grid>
              <Grid item>
                <ChipToggleDisplay options={backgroundCheckOptions} values={selectedBackgroundChecks} onChange={handleBackgroundCheckClick} />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TeacherLanguageAndQualificationsCard;
