import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface AddResourcesToFavouritesRequest {
  bookIds: string[];
}

async function deleteResourcesFromLessonPlan(studentProfileId: string, request: AddResourcesToFavouritesRequest): Promise<void> {
  await axios.delete(`${config.apiUrl}/admin/student-profile/${studentProfileId}/resources/lesson-plan`, { data: request });
}

export default function useDeleteResourcesFromLessonPlan(
  studentProfileId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, AddResourcesToFavouritesRequest>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((request: AddResourcesToFavouritesRequest) => deleteResourcesFromLessonPlan(studentProfileId, request), options);
}
