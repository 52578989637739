import { DistrictRepresentativeStatus } from '@hoot-reading/hoot-core/dist/enums/user/district-representative/district-representative-status.enum';
import { DomainOutlined, HouseOutlined, ReceiptLongOutlined } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import phone from 'phone';
import React, { Dispatch, LegacyRef, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { WINNIPEG_TIMEZONE } from '@hoot/common/constants';
import { SearchSelect } from '@hoot/components/SearchSelect';
import { PhoneNumberInput } from '@hoot/components/form/PhoneNumberInput';
import SwitchField from '@hoot/components/form/SwitchField';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import useCreateDistrictRepresentative, {
  CreateDistrictRepresentativeRequest,
  CreateDistrictRepresentativeResponse,
} from '@hoot/hooks/api/user/district-representative/useCreateDistrictRepresentative';
import { DistrictRepresentativeResponse } from '@hoot/hooks/api/user/district-representative/useGetDistrictRepresentative';
import useUpdateDistrictRepresentative, {
  UpdateDistrictRepresentative,
} from '@hoot/hooks/api/user/district-representative/useUpdateDistrictRepresentative';
import { routes } from '@hoot/routes/routes';
import { timeZoneData } from '@hoot/utils/timeZones';
import DistrictRepresentativeEnrolmentsCard from './CommonFormComponents/DistrictRepresentativeEnrolmentsCard';
import DistrictRepresentativeLocationsCard from './CommonFormComponents/DistrictRepresentativeLocationsCard';
import DistrictRepPermissionsDialog from './district-rep-permissions-wizard/DistrictRepPermissionsWizardDialog';

interface Props {
  isEditMode: boolean;
  districtRep?: DistrictRepresentativeResponse;
  setFormIsDirty: Dispatch<SetStateAction<boolean>>;
  formRef: React.MutableRefObject<HTMLFormElement | undefined>;
  refetchFn: () => void;
}

interface Form {
  status: DistrictRepresentativeStatus;
  userAccountStatus: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  timeZone: string;
}

const DistrictRepresentativeDetailsTab = (props: Props) => {
  const { isEditMode, districtRep, setFormIsDirty, formRef } = props;
  const { districtRepresentativeId } = useParams() as { districtRepresentativeId: string | undefined };

  const { error, success } = useAlert();
  const { hootEmployee } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setIsDirty } = useBlockIfDirty();

  const updateDistrictRepresentative = useUpdateDistrictRepresentative();
  const createDistrictRepresentative = useCreateDistrictRepresentative();

  const [showPermissionsWizard, setShowPermissionsWizard] = useState<boolean>(false);

  const hasSavedLocation = !!districtRep?.locations && districtRep.locations?.length > 0;
  const hasPermissions = !!districtRep && Object.values(districtRep.permissions).includes(true);
  const disableAccountStatus = !hasSavedLocation && !hasPermissions;
  const canEditPermissions = !!districtRep && hootEmployee?.canManageDistrictReps;

  const { control, formState, handleSubmit, watch } = useForm<Form>({
    defaultValues: {
      status: districtRep?.status ?? DistrictRepresentativeStatus.Inactive,
      userAccountStatus: districtRep?.accountStatus ?? true,
      firstName: districtRep?.firstName ?? '',
      lastName: districtRep?.lastName ?? '',
      email: districtRep?.email ?? '',
      phoneNumber: districtRep?.phoneNumber ?? '',
      timeZone: districtRep?.timeZone ?? WINNIPEG_TIMEZONE,
    },
  });

  const { userAccountStatus } = watch();
  const updatedStatusAt = districtRep?.accountStatusLastUpdatedAt;

  useEffect(() => {
    setFormIsDirty(formState.isDirty);
  }, [formState, setFormIsDirty]);

  if (isEditMode && !districtRep) {
    return <div>District Representative is not valid</div>;
  }

  const onSubmit = (data: Form) => {
    if (isEditMode) {
      const updateRequest: UpdateDistrictRepresentative = {
        districtRepresentativeId: districtRepresentativeId!,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        timeZone: data.timeZone,
        status: data.status,
        userAccountStatus: data.userAccountStatus,
      };

      updateDistrictRepresentative.mutate(updateRequest, {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKey.GetDistrictRepresentative, districtRepresentativeId]);
          setIsDirty(false);
          success(`${data.firstName} ${data.lastName} successfully saved!`);
        },
        onError: (exc: any) => {
          const message =
            exc.response && exc.response.data?.message?.trim().length > 0
              ? exc.response.data.message
              : `There was an issue creating the account for ${updateRequest.firstName} ${updateRequest.lastName}`;
          error(message);
          console.error(exc);
        },
      });
    } else {
      const createRequest: CreateDistrictRepresentativeRequest = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        timeZone: data.timeZone,
        status: data.status,
        userAccountStatus: data.userAccountStatus,
      };

      createDistrictRepresentative.mutate(createRequest, {
        onSuccess: () => {
          setIsDirty(false);
          success(`${createRequest.firstName} ${createRequest.lastName} successfully saved!`);
        },
        onError: (exc: any) => {
          const message =
            exc.response && exc.response.data?.message?.trim().length > 0
              ? exc.response.data.message
              : `There was an issue creating the account for ${createRequest.firstName} ${createRequest.lastName}`;
          error(message);
          console.error(exc);
        },
        onSettled: (response?: AxiosResponse<CreateDistrictRepresentativeResponse>) => {
          /*
           * Note: we need to call navigate in the onSettled function, otherwise the navigate hook will be blocked by
           * the useBlocker() hook.
           */
          if (response?.data.id) {
            navigate(routes.users.districtReps.details.url(response.data.id));
          }
        },
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef as LegacyRef<HTMLFormElement>} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Stack gap={3}>
              <Card>
                <CardContent>
                  <Grid container item direction="column" xs={12}>
                    <Grid container item xs={12}>
                      <Typography align="center" variant="titleLarge">
                        Status
                      </Typography>
                    </Grid>
                    <Grid sx={{ marginTop: '24px' }} container item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="account-status">Account Status*</InputLabel>
                        <Controller
                          name="status"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Select
                              {...field}
                              error={!!fieldState.error}
                              labelId="account-status"
                              label="Account Status"
                              disabled={disableAccountStatus}
                            >
                              <MenuItem value={DistrictRepresentativeStatus.Active}>Active</MenuItem>
                              <MenuItem value={DistrictRepresentativeStatus.Inactive}>Inactive</MenuItem>
                            </Select>
                          )}
                        />
                        {isEditMode && districtRep && districtRep.lastUpdatedAt && !isNaN(districtRep.lastUpdatedAt) ? (
                          <FormHelperText>Since {DateTime.fromMillis(districtRep.lastUpdatedAt).toISODate()}</FormHelperText>
                        ) : null}
                        <Stack>
                          <Grid sx={{ marginTop: '8px' }} container item xs={12}>
                            <Typography variant={'bodySmall'} sx={{ mt: '1px', mb: '12px' }}>
                              User Account Status*
                            </Typography>
                            <SwitchField label={`${userAccountStatus ? 'Enabled' : 'Disabled'}`} control={control} name="userAccountStatus" />
                          </Grid>
                          {isEditMode && !!updatedStatusAt ? (
                            <FormHelperText sx={{ marginTop: '8px', color: '#1C1B1F' }}>
                              Since {DateTime.fromMillis(updatedStatusAt).toFormat('MM/dd/yyyy')}
                            </FormHelperText>
                          ) : null}
                        </Stack>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Stack gap={3}>
                    <Stack direction="row" justifyContent={'space-between'}>
                      <Typography variant="titleLarge">Permissions</Typography>
                      <Button variant="contained" onClick={() => setShowPermissionsWizard(true)} disabled={!canEditPermissions}>
                        {hasPermissions ? 'Edit' : 'Add'}
                      </Button>
                    </Stack>
                    <List dense>
                      {hasPermissions ? (
                        <>
                          {districtRep?.permissions.editDistrict ? (
                            <ListItem
                              key={1}
                              sx={{
                                borderBottom: '1px solid #CAC4D0',
                                paddingY: '10px',
                              }}
                            >
                              <ListItemIcon>{<DomainOutlined />} </ListItemIcon>
                              <ListItemText primary={<Typography variant="bodyLarge">Edit District</Typography>} />
                            </ListItem>
                          ) : null}
                          {districtRep?.permissions.editSchool ? (
                            <ListItem
                              key={2}
                              sx={{
                                borderBottom: '1px solid #CAC4D0',
                                paddingY: '10px',
                              }}
                            >
                              <ListItemIcon>{<HouseOutlined />} </ListItemIcon>
                              <ListItemText primary={<Typography variant="bodyLarge">Edit School</Typography>} />
                            </ListItem>
                          ) : null}
                          {districtRep?.permissions.editEnrolment ? (
                            <ListItem
                              key={3}
                              sx={{
                                borderBottom: '1px solid #CAC4D0',
                                paddingY: '10px',
                              }}
                            >
                              <ListItemIcon>{<ReceiptLongOutlined />} </ListItemIcon>
                              <ListItemText primary={<Typography variant="bodyLarge">Edit Enrolment</Typography>} />
                            </ListItem>
                          ) : null}
                          {districtRep?.permissions.viewEnrolment ? (
                            <ListItem key={4} sx={{ paddingY: '10px' }}>
                              <ListItemIcon>{<ReceiptLongOutlined />} </ListItemIcon>
                              <ListItemText primary={<Typography variant="bodyLarge">View Enrolment</Typography>} />
                            </ListItem>
                          ) : null}
                        </>
                      ) : (
                        <ListItem>
                          <ListItemText primary={<Typography variant="bodyLarge">None</Typography>} />
                        </ListItem>
                      )}
                    </List>
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Stack gap={3}>
              <Card>
                <CardContent sx={{ p: 3 }}>
                  <Typography variant="titleLarge">User Details</Typography>
                  <Grid item marginTop="24px" container xs={12} sx={{ rowGap: 3 }}>
                    <Grid item container xs={12}>
                      <Grid item xs={6} paddingRight="12px">
                        <Controller
                          name="firstName"
                          rules={{
                            required: 'A first name is required',
                          }}
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              helperText={fieldState.error?.message}
                              error={!!fieldState.error}
                              fullWidth
                              label="First name*"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item container xs={6} paddingLeft="12px">
                        <Controller
                          name="lastName"
                          rules={{
                            required: 'A last name is required',
                          }}
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              helperText={fieldState.error?.message}
                              error={!!fieldState.error}
                              fullWidth
                              label="Last name*"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container xs={12}>
                      <Grid item container xs={6} paddingRight="12px">
                        <Controller
                          name="email"
                          rules={{
                            required: 'An email is required',
                          }}
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              helperText={fieldState.error?.message}
                              error={!!fieldState.error}
                              fullWidth
                              label="Email address*"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item container xs={6} paddingLeft="12px">
                        <Stack sx={{ width: '100%' }}>
                          <PhoneNumberInput
                            id="phoneNumber"
                            name="phoneNumber"
                            control={control}
                            defaultCountry={'ca'}
                            disableDropdown={true}
                            label="Phone number*"
                            fullWidth
                            variant="outlined"
                            rules={{
                              minLength: {
                                value: 2,
                                message: 'Phone number is required!',
                              },
                              validate: (value) => {
                                const phoneNum = value ? value.toString().trim() : '';
                                if (phoneNum.length === 0 || phoneNum === '+' || phoneNum === '+1' || !phone(phoneNum || '')?.isValid) {
                                  return 'Valid phone number is required: +x (xxx) xxx-xxxx';
                                }
                                return true;
                              },
                            }}
                            helperTextFn={() => formState?.errors['phoneNumber']?.message || ''}
                          />
                          {!formState.errors.phoneNumber ? (
                            <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                              <Tooltip title="Phone Format">
                                <Typography variant="labelSmall">+x (xxx) xxx-xxxx</Typography>
                              </Tooltip>
                            </FormHelperText>
                          ) : null}
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid item container xs={12}>
                        <SearchSelect
                          control={control}
                          label="Time Zone*"
                          name="timeZone"
                          data={timeZoneData}
                          required
                          helperText={formState.errors.timeZone ? 'A Time Zone is required!' : ''}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {!!districtRep?.locations.length ? (
                <DistrictRepresentativeLocationsCard locations={districtRep ? districtRep.locations : undefined} />
              ) : null}
              {!!districtRep?.enrolments.length ? (
                <DistrictRepresentativeEnrolmentsCard enrolments={districtRep ? districtRep.enrolments : []} />
              ) : null}
            </Stack>
          </Grid>
        </Grid>
      </form>
      <DistrictRepPermissionsDialog show={showPermissionsWizard} onDismiss={() => setShowPermissionsWizard(false)} districtRep={districtRep!} />
    </>
  );
};

export default DistrictRepresentativeDetailsTab;
