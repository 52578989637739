import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { Chip } from '@mui/material';
import EmptyCircleIcon from '@hoot/components/icons/EmptyCircleIcon';
import FullCircleIcon from '@hoot/components/icons/FullCircle';
import PartialCircleIcon from '@hoot/components/icons/PartialCircleIcon';
import { enrolmentStatusMapping } from '../../hfs-utils';

export function EnrolmentStatusChip(props: { status: EnrolmentStatus }) {
  let icon = <FullCircleIcon fill="#2E7D32" />;

  if (props.status === EnrolmentStatus.InProgress || props.status === EnrolmentStatus.Scheduled) {
    icon = <PartialCircleIcon stroke="#1976D2" />;
  }

  if (props.status === EnrolmentStatus.Closed) {
    icon = <EmptyCircleIcon stroke="#B3261E" />;
  }

  const label = enrolmentStatusMapping[props.status];

  return (
    <Chip
      sx={{
        '& > svg': {
          width: '8px',
          height: '8px',
        },
      }}
      size="small"
      icon={icon}
      label={label}
    />
  );
}
