import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';
import { InvoiceLineItemStatus } from '@hoot/pages/teacher-invoices/enums/invoice-line-item-status.enum';

export interface UpdateInvoiceBillableTaskDto {
  status: InvoiceLineItemStatus;
  reviewerComments: string | null;
  total?: number;
}

async function updateTeacherInvoiceBillableTask(taskId: string, payload: UpdateInvoiceBillableTaskDto) {
  const { data } = await axios.put(`${config.apiUrl}/admin/teacher-invoicing/billable-task/${taskId}`, payload);
  return data;
}

export default function useUpdateTeacherInvoiceBillableTask(taskId: string) {
  return useMutation<void, AxiosError, UpdateInvoiceBillableTaskDto>((payload) => updateTeacherInvoiceBillableTask(taskId, payload));
}
