import { Checkbox, Typography } from '@mui/material';
import { Stack } from '@mui/system';

interface LessonSetLessonGroupingHeaderProps {
  isChecked: boolean;
  disabled: boolean;
  onToggleAllSelectLessonGroup: () => void;
}

const LessonSetLessonGroupingHeader = (props: LessonSetLessonGroupingHeaderProps) => {
  const { isChecked, disabled, onToggleAllSelectLessonGroup } = props;

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={(theme) => ({
        padding: theme.spacing(0, 3, 0, 0.5),
      })}
    >
      <Checkbox sx={{ padding: 1.5, marginRight: 0.5 }} onClick={onToggleAllSelectLessonGroup} checked={isChecked} disabled={disabled} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} gap={2} width={'100%'}>
        <Stack flex={1}>
          <Typography variant="bodyLarge">Day</Typography>
        </Stack>
        <Stack alignItems="center" width={160}>
          <Typography variant="bodyLarge">Action</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LessonSetLessonGroupingHeader;
