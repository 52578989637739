import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';
import { LessonReviewFocus, LessonReviewFocusType, LessonReviewFocusUnit } from '@hoot/pages/users/students/lesson-reviews/enum';

interface LessonReviewNote {
  notes?: string;
  focus?: LessonReviewFocus;
  focusDetails?: LessonReviewFocusUnit;
  focusType?: LessonReviewFocusType;
}

async function updateLessonReview(lessonReviewId?: string, payload?: LessonReviewNote) {
  const { data } = await axios.put(`${config.apiUrl}/admin/lesson-reviews/${lessonReviewId}`, payload);

  return data;
}

export function useUpdateLessonReview(lessonReviewId?: string) {
  return useMutation<void, AxiosError, LessonReviewNote>((request) => updateLessonReview(lessonReviewId, request));
}
