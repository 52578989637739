import { Card, CardContent, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Control, Controller } from 'react-hook-form';
import { DatePicker } from '@hoot/components/form/DatePicker';
import { ProductProgramSelect } from '@hoot/components/form/selectFields/products/ProductProgramSelect';
import { ProductStatusSelect } from '@hoot/components/form/selectFields/products/ProductStatusSelect';
import { ProductDetailsResponse } from '@hoot/hooks/api/hfs/district-schools/products/useGetProduct';
import ProductEnrolments from '@hoot/pages/district-schools/products/ProductEnrolments';
import { UpdateProductForm } from '@hoot/pages/district-schools/products/ProductPage';
import { BackgroundCheckRequirements } from './BackgroundCheckRequirements';
import { CountryRequirementSection } from './CountryRequirementsSection';
import { QualificationsSection } from './ProductRequirements';

interface Props {
  productId: string;
  product: ProductDetailsResponse;
  control: Control<UpdateProductForm>;
}

const ProductDetails = (props: Props) => {
  const { productId, product, control } = props;

  return (
    <Grid item container mt={2} xs={12} spacing={2}>
      <Grid item container xs={12} lg={4} alignContent="flex-start">
        <Card
          sx={{
            width: 1,
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Grid item container xs={12} spacing={3}>
              <Grid item container xs={12}>
                <Typography align="center" variant="titleLarge">
                  Statuses
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <ProductStatusSelect disabled name="status" control={control} />
                {product?.lastUpdatedAt ? (
                  <FormHelperText sx={{ color: '#1C1B1F', marginLeft: '16px' }}>
                    Since {DateTime.fromMillis(product.lastUpdatedAt).toFormat('LL/dd/yyyy')}
                  </FormHelperText>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card
          sx={{
            width: 1,
            marginTop: '24px',
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Controller
              control={control}
              name="qualificationRequirements"
              render={({ field }) => <QualificationsSection onChange={field.onChange} value={field.value} />}
            />

            <Controller
              control={control}
              name="backgroundCheckRequirements"
              render={({ field }) => <BackgroundCheckRequirements onChange={field.onChange} value={field.value} />}
            />

            <Controller
              control={control}
              name="countryRequirements"
              render={({ field }) => <CountryRequirementSection onChange={field.onChange} value={field.value} />}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item container xs={12} lg={8}>
        <Grid container direction="column">
          <Grid item container>
            <Card
              sx={{
                width: 1,
              }}
            >
              <CardContent sx={{ p: 3 }}>
                <Typography variant="titleLarge">Product Details</Typography>

                <Grid marginTop={3} item container xs={12} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="name"
                      rules={{
                        required: `Product name is required`,
                      }}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          helperText={fieldState.error?.message}
                          error={!!fieldState.error}
                          fullWidth
                          label={`Product Name`}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item container xs={12} md={6}>
                    <ProductProgramSelect control={control} name="program" />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="numberOfEnrolments"
                      rules={{
                        required: `Number of enrolments is required`,
                      }}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          helperText={fieldState.error?.message}
                          error={!!fieldState.error}
                          fullWidth
                          label={`Enrolments`}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="numberOfStudents"
                      rules={{
                        required: `Number of students is required`,
                      }}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          helperText={fieldState.error?.message}
                          error={!!fieldState.error}
                          fullWidth
                          label={`Students`}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Start Date"
                      name="startDate"
                      control={control}
                      rules={{
                        required: 'A start date is required',
                      }}
                    />
                    <FormHelperText sx={{ color: '#1C1B1F', marginLeft: '16px' }}>MM/DD/YYYY</FormHelperText>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="End Date"
                      name="endDate"
                      control={control}
                      rules={{
                        required: 'An end date is required',
                      }}
                    />
                    <FormHelperText sx={{ color: '#1C1B1F', marginLeft: '16px' }}>MM/DD/YYYY</FormHelperText>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <ProductEnrolments productId={productId} productStatus={product.status} location={product.location} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductDetails;
