import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '@hoot/routes/routes';

enum CardType {
  CreateNewStudent = 'CREATE_NEW_STUDENT',
  ChooseExistingStudent = 'CHOOSE_EXISTING_STUDENT',
}

const ActionCard = (props: { type: CardType; onClick: () => void }) => {
  const { type, onClick } = props;

  const bodyText = () => {
    return type === CardType.CreateNewStudent ? 'create a new student' : 'link an existing student';
  };

  const actionText = () => {
    return type === CardType.CreateNewStudent ? 'Create New' : 'Link Student';
  };

  return (
    <Stack
      direction="row"
      onClick={onClick}
      sx={{ height: 70, background: '#ECF5FF', padding: 3, border: '1px solid #1976D2', borderRadius: '4px', cursor: 'pointer' }}
      justifyContent="space-between"
    >
      <Typography variant="bodyMedium" sx={{ color: '#000' }}>
        {`I would like to ${bodyText()}.`}
      </Typography>
      <Typography variant="labelLarge" sx={{ color: '#1976D2' }}>
        {actionText()}
      </Typography>
    </Stack>
  );
};

export function AddStudentModal(props: {
  parentUserId: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setShowSelectStudentModal: Dispatch<SetStateAction<boolean>>;
}) {
  const { parentUserId, open, setOpen, setShowSelectStudentModal } = props;
  const navigate = useNavigate();

  function handleClose() {
    setOpen(false);
  }

  function handleCreateNewStudent() {
    navigate(`${routes.users.students.create.url}?parentAccountId=${parentUserId}`);
  }

  function handleLinkExistingStudent() {
    setShowSelectStudentModal(true);
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Stack sx={{ padding: '24px', position: 'relative' }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Add Student</Typography>
            <IconButton onClick={handleClose} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Stack gap={3}>
            <DialogContentText textAlign="left" sx={{ color: '#1C1B1F' }}>
              It looks like you're trying to add a student to the selected parent account. Please choose between creating a new student or associating
              an existing one.
            </DialogContentText>
            <ActionCard type={CardType.CreateNewStudent} onClick={handleCreateNewStudent} />
            <ActionCard type={CardType.ChooseExistingStudent} onClick={handleLinkExistingStudent} />
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button
              sx={{
                fontSize: 14,
              }}
              fullWidth
              size="medium"
              color="inherit"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}

export default AddStudentModal;
