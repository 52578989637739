import { Container, Paper } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { motion } from 'framer-motion';
import LessonSummaryDash from './components/LessonSummaryDash';

const Dashboard = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root} component={motion.div} initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0 }}>
      <Paper className={classes.paper}>
        <LessonSummaryDash />
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      padding: 0,
    },
    paper: {
      padding: '16px',
    },
  }),
);

export default Dashboard;
