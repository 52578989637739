import { MailOutline, PhoneOutlined } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types/form';
import { REDACTED_NOTE } from '@hoot/common/constants';
import NoneItem from '@hoot/components/ui/NoneItem';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { ParentProfileResponse } from '@hoot/hooks/api/end-users/useGetStudentProfile';
import CardLine from '@hoot/pages/common/CardLine';
import { routes } from '@hoot/routes/routes';
import useGetParentProfile from '../../../../../hooks/api/end-users/useGetParentProfile';
import { StudentDetailsForm } from '../form';
import AddParentModal from '../modals/AddParentModal';

const StudentParentCard = (props: {
  isEditMode: boolean;
  parentAccountIdParam: string | null;
  watch: UseFormWatch<StudentDetailsForm>;
  setValue: UseFormSetValue<StudentDetailsForm>;
  navigate: (route: string) => void;
  showParentsModal: boolean;
  setShowParentsModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isEditMode, parentAccountIdParam, watch, setValue, navigate, showParentsModal, setShowParentsModal } = props;
  const { parentInfo } = watch();
  const { success } = useAlert();
  const { hootEmployee } = useAuth();
  const canManageStudentDetails = hootEmployee?.canManageStudentDetails;

  useGetParentProfile(parentAccountIdParam!, {
    enabled: !!parentAccountIdParam,
    retry: false,
    onSuccess: (response) => {
      const data = response.personalInformation;
      const parentInfo: ParentProfileResponse = {
        id: parentAccountIdParam || '',
        name: `${data.firstName} ${data.lastName}`,
        phoneNumber: data.phoneNumber || '',
        email: data.emailAddress || '',
        country: data.country || '',
        timeZone: data.timeZone || '',
        account: response.account,
        prefixedNumber: data.prefixedNumber,
      };
      setValue('parentInfo', parentInfo, { shouldDirty: true });
    },
  });

  const handleClick = () => {
    if (isEditMode || parentAccountIdParam) {
      navigate(routes.users.parents.details.url(parentInfo!.id));
    } else {
      setShowParentsModal(true);
    }
  };

  const onApply = (selectedRow: ParentProfileResponse) => {
    const parentInfo: ParentProfileResponse = {
      id: selectedRow.id || '',
      name: selectedRow.name,
      phoneNumber: selectedRow.phoneNumber || '',
      email: selectedRow.email || '',
      country: selectedRow.country || '',
      timeZone: selectedRow.timeZone || '',
      account: selectedRow.account,
      prefixedNumber: selectedRow.prefixedNumber,
    };
    setValue('parentInfo', parentInfo, { shouldDirty: true });
    setShowParentsModal(false);
  };

  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    success('Copied to clipboard');
  };

  return (
    <Card sx={{ pl: 3, pt: 3, pr: 3 }}>
      <CardHeader
        sx={{ p: 0, pb: 3 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="titleLarge">
              Parent
            </Typography>
            <Button type="button" variant="contained" onClick={handleClick}>
              {isEditMode || parentAccountIdParam ? 'View' : 'Add'}
            </Button>
          </Stack>
        }
      />
      <CardContent sx={{ p: 0 }}>
        {parentInfo ? (
          <Stack gap={1}>
            <CardLine title="Parent Number" bodyText={parentInfo.prefixedNumber} copyButton />
            <CardLine title="Name" bodyText={canManageStudentDetails ? parentInfo.name || '' : REDACTED_NOTE} copyButton />
            <Stack alignItems={'flex-start'} sx={{ mt: 2 }}>
              <Typography variant="titleSmall" sx={{ color: '#000' }}>
                Contact Information
              </Typography>
              <Box>
                <Tooltip title={parentInfo.email ?? ''}>
                  <IconButton onClick={handleCopyToClipboard(parentInfo.email)}>
                    <MailOutline sx={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={parentInfo.phoneNumber ?? ''}>
                  <IconButton onClick={handleCopyToClipboard(parentInfo.phoneNumber)}>
                    <PhoneOutlined sx={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
            <CardLine title="Country" bodyText={parentInfo.country || ''} />
            <CardLine title="Timezone" bodyText={parentInfo.timeZone || ''} />
          </Stack>
        ) : (
          <NoneItem />
        )}
      </CardContent>
      {showParentsModal ? <AddParentModal setOpen={setShowParentsModal} open={showParentsModal} onApply={onApply} /> : null}
    </Card>
  );
};

export default StudentParentCard;
