import {
  ScheduledLessonLanguage,
  ScheduledLessonSubject,
  ScheduledLessonTheme,
  ScheduledLessonType,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import React, { PropsWithChildren, useState } from 'react';
import { StudentEnrolment } from '@hoot/hooks/api/end-users/useGetStudentEnrolments';
import { SearchStudentResponse } from '@hoot/hooks/api/end-users/useSearchStudentsV2';

interface StudentAndLessonContextProps {
  studentAndLessonData: StudentAndLessonSelection | undefined;
  setStudentAndLessonData: (data: StudentAndLessonSelection) => void;
}

export interface StudentAndLessonSelection {
  studentProfile: SearchStudentResponse;
  selectedEnrolment: StudentEnrolment | undefined;
  lessonSetType: 'NEW_LESSON_SET' | 'WITHIN_EXISTING_LESSON_SET' | undefined;
  lessonType: ScheduledLessonType;
  accountId: string;
  isCustomerBilledLesson: boolean;
  subject: ScheduledLessonSubject;
  language: ScheduledLessonLanguage;
  theme: ScheduledLessonTheme | undefined;
}

const StudentAndLessonContext = React.createContext<StudentAndLessonContextProps>(undefined!);
StudentAndLessonContext.displayName = 'StudentAndLessonContext';

export const useStudentAndLessonContext = () => {
  const context = React.useContext(StudentAndLessonContext);

  if (context === undefined) {
    throw new Error('useStudentAndLessonContext must be used within a StudentAndLessonContextProvider');
  }
  return context;
};

const StudentAndLessonContextProvider = (props: PropsWithChildren<any>) => {
  const { children } = props;

  const [data, setData] = useState<StudentAndLessonSelection>();

  return (
    <StudentAndLessonContext.Provider
      value={{
        studentAndLessonData: data,
        setStudentAndLessonData: (data) => {
          setData(data);
        },
      }}
    >
      {children}
    </StudentAndLessonContext.Provider>
  );
};

export default StudentAndLessonContextProvider;
