import { Box, Card, Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { GetPMADetailsResponse } from '@hoot/hooks/api/student-hoot-assessments/useGetPMASubmissionDetails';
import { ProgressMonitoringAssessmentStatus } from '@hoot/interfaces/assessments-enums';
import { routes } from '@hoot/routes/routes';
import { enumToTitleCase } from '@hoot/utils/stringUtils';
import StudentProgressMonitoringAssessmentCard from './StudentProgressMonitoringAssessmentCard';

interface Props {
  assessment: GetPMADetailsResponse | undefined;
}

const displayDate = (assessmentDate: number | undefined): string => {
  if (!assessmentDate) {
    return '';
  }
  const dateTime = DateTime.fromMillis(assessmentDate);
  return dateTime.toFormat('cccc, LLLL d yyyy, h:mma ZZZZ');
};

const GridContainer = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <Grid
      container
      xs={12}
      item
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </Grid>
  );
};

const CardContainer = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '16px',
        borderRadius: '10px',
        padding: '24px',
        background: '#FFFFFF',
      }}
    >
      {children}
    </Card>
  );
};

const ProgressMonitoringAssessmentDetailsPanels = (props: Props) => {
  const { assessment } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '24px',
        padding: '24px',
      }}
    >
      <Box>
        <Card>
          <Box
            sx={{
              display: 'grid',
              height: '100%',
              width: '100%',
              gap: '24px',
              overflowY: '',
              gridTemplateColumns: { sm: 'repeat(1, minmax(0, 2fr))' },
              padding: '24px',
            }}
          >
            <Box component="span" sx={{ fontSize: '22px' }}>
              Details
            </Box>
            <Box
              sx={{
                display: 'grid',
                height: '100%',
                width: '100%',
                gap: '24px',
                overflowY: '',
                gridTemplateColumns: {
                  sm: 'repeat(1, minmax(0, 1fr))',
                  md: 'repeat(2, minmax(0, 1fr))',
                  lg: 'repeat(2, minmax(0, 2fr))',
                },
              }}
            >
              <GridContainer>
                <Typography variant="titleMedium">Start Date</Typography>
                <Typography variant="bodyMedium">{displayDate(assessment?.createdAt)}</Typography>
              </GridContainer>
              <GridContainer>
                <Typography variant="titleMedium">Completion Date</Typography>
                <Typography variant="bodyMedium">
                  {assessment?.status === ProgressMonitoringAssessmentStatus.Completed ? displayDate(assessment?.updatedAt) : '-'}
                </Typography>
              </GridContainer>
              <GridContainer>
                <Typography variant="titleMedium">Status</Typography>
                <Typography variant="bodyMedium">{assessment?.status ? enumToTitleCase(assessment?.status) : ''}</Typography>
              </GridContainer>
              <GridContainer>
                <Typography variant="titleMedium">Assessed by</Typography>
                <Link to={routes.users.teachers.details.url(assessment?.assessedBy.id ?? '')}>{assessment?.assessedBy.prefixedNumber}</Link>
              </GridContainer>
            </Box>
          </Box>
        </Card>
      </Box>
      <Box
        sx={{
          display: 'grid',
          height: '100%',
          width: '100%',
          gap: '24px',
          gridTemplateColumns: {
            sm: 'repeat(1, minmax(0, 1fr))',
            md: 'repeat(2, minmax(0, 1fr))',
            lg: 'repeat(2, minmax(0, 2fr))',
          },
        }}
      >
        {assessment?.completedUnits.map((unit) => (
          <CardContainer key={unit.unit.unitId}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
              }}
            >
              <StudentProgressMonitoringAssessmentCard unit={unit} assessment={assessment} />
            </Box>
          </CardContainer>
        ))}
      </Box>
    </Box>
  );
};

export default ProgressMonitoringAssessmentDetailsPanels;
