import { useForm } from 'react-hook-form';
import { HootEmployeeFiltersForm } from '../interfaces/hoot-employee-filters-form';

const defaultValues: HootEmployeeFiltersForm = {
  isEnabled: undefined,
};

export default function useHootEmployeeFiltersForm() {
  return useForm<HootEmployeeFiltersForm>({
    mode: 'onChange',
    defaultValues,
  });
}
