import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export enum EnrolmentPeriodType {
  HRA = 'HRA',
  PMA = 'PMA',
}

export interface EnrolmentPeriodRequest {
  type: EnrolmentPeriodType;
  startDate: number;
  endDate: number;
}

const createEnrolmentPeriod = async (request: EnrolmentPeriodRequest, enrolmentId: string): Promise<string> => {
  const { data } = await axios.post<string>(`${config.apiUrl}/admin/hfs/enrolments/${enrolmentId}/period`, request);
  return data;
};

export const useCreateEnrolmentPeriod = (
  enrolmentId: string,
  options?: Omit<UseMutationOptions<any, AxiosError, EnrolmentPeriodRequest>, 'mutationFn' | 'mutationKey'>,
) => {
  return useMutation<string, AxiosError, EnrolmentPeriodRequest>((request) => createEnrolmentPeriod(request, enrolmentId), options);
};
