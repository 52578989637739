import { Disability, FoodServiceProgram, Race, SpecialIndicatorGrade } from '@hoot-reading/hoot-core/dist/enums/student-special-indicators';
import {
  foodServiceProgramOptionsWithNull,
  studentDisabilityOptionsWithNull,
  studentRaceOptionsWithNull,
  studentSpecialGradeOptionsWithNull,
} from '@hoot-reading/hoot-core/dist/form-options/student-special-indicators';
import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import React, { LegacyRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import useCreateOrUpdateStudentSpecialIndicatorData, {
  StudentSpecialIndicatorDataSaveRequest,
} from '@hoot/hooks/api/end-users/student/useCreateOrUpdateStudentSpecialIndicatorData';
import useGetStudentLanguageOptions from '@hoot/hooks/api/end-users/student/useGetStudentLanguageOptions';
import useGetStudentSpecialIndicatorData from '@hoot/hooks/api/end-users/student/useGetStudentSpecialIndicatorData';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { FormOption, booleanFilterOptions } from '@hoot/interfaces/form';

interface Props {
  formRef: React.MutableRefObject<HTMLFormElement | undefined>;
}

interface Form {
  specialIndicatorGrade: SpecialIndicatorGrade | '';
  race: Race | '';
  primaryLanguageId: string;
  ell: string;
  hispanicLatino: string;
  homeless: string;
  fosterCare: string;
  newComer: string;
  migrant: string;
  specialEducation: string;
  iep: string;
  '504': string;
  disability: Disability | '';
  foodServiceProgram: FoodServiceProgram | '';
  sed: string;
  publicAssistance: string;
}

const StudentSpecialIndicators = (props: Props) => {
  const { formRef } = props;
  const { setIsDirty } = useBlockIfDirty();
  const { studentProfileId } = useParams();
  useGetStudentSpecialIndicatorData(studentProfileId ?? '', {
    enabled: !!studentProfileId,
    retry: false,
    onSuccess: (data) => {
      setValue('specialIndicatorGrade', data.specialIndicatorGrade ?? '');
      setValue('race', data.race ?? '');
      setValue('primaryLanguageId', data.primaryLanguageId ?? '');
      setValue('ell', data.ell ? `${data.ell}` : 'false');
      setValue('hispanicLatino', data.hispanicLatino ? `${data.hispanicLatino}` : 'false');
      setValue('homeless', data.homeless ? `${data.homeless}` : 'false');
      setValue('fosterCare', data.fosterCare ? `${data.fosterCare}` : 'false');
      setValue('newComer', data.newComer ? `${data.newComer}` : 'false');
      setValue('migrant', data.migrant ? `${data.migrant}` : 'false');
      setValue('specialEducation', data.specialEducation ? `${data.specialEducation}` : 'false');
      setValue('iep', data.iep ? `${data.iep}` : 'false');
      setValue('504', data[504] ? `${data[504]}` : 'false');
      setValue('disability', data.disability ?? '');
      setValue('foodServiceProgram', data.foodServiceProgram ?? '');
      setValue('sed', data.sed ? `${data.sed}` : 'false');
      setValue('publicAssistance', data.publicAssistance ? `${data.publicAssistance}` : 'false');
    },
    onError: (err: any) => {
      if (err.response?.status === 404) {
        setIsDirty(true);
      } else {
        console.error(err);
        error(`An error occurred while grabbing the student's (${studentProfileId}) special indicator data!`);
      }
    },
  });
  const queryClient = useQueryClient();
  const { error, success } = useAlert();
  const studentSpecialIndicator = useCreateOrUpdateStudentSpecialIndicatorData(studentProfileId ?? '');

  const { control, handleSubmit, formState, setValue } = useForm<Form>({
    defaultValues: {
      specialIndicatorGrade: '',
      race: '',
      primaryLanguageId: '',
      ell: 'false',
      hispanicLatino: 'false',
      homeless: 'false',
      fosterCare: 'false',
      newComer: 'false',
      migrant: 'false',
      specialEducation: 'false',
      iep: 'false',
      '504': 'false',
      disability: '',
      foodServiceProgram: '',
      sed: 'false',
      publicAssistance: 'false',
    },
  });

  const { data: languages } = useGetStudentLanguageOptions();
  const languageFormOptions: FormOption<string>[] = [];

  if (!!languages) {
    languageFormOptions.push({ value: '', label: 'None' });
    for (const lang of languages.options) {
      languageFormOptions.push({ value: lang.id, label: lang.name });
    }
  }

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [setIsDirty, formState.isDirty]);

  const onSubmit = async (data: Form) => {
    const request: StudentSpecialIndicatorDataSaveRequest = {
      specialIndicatorGrade: data.specialIndicatorGrade === '' ? null : data.specialIndicatorGrade,
      race: data.race === '' ? null : data.race,
      primaryLanguageId: data.primaryLanguageId,
      ell: data.ell === 'true',
      hispanicLatino: data.hispanicLatino === 'true',
      homeless: data.homeless === 'true',
      fosterCare: data.fosterCare === 'true',
      newComer: data.newComer === 'true',
      migrant: data.migrant === 'true',
      specialEducation: data.specialEducation === 'true',
      iep: data.iep === 'true',
      '504': data[504] === 'true',
      disability: data.disability === '' ? null : data.disability,
      foodServiceProgram: data.foodServiceProgram === '' ? null : data.foodServiceProgram,
      sed: data.sed === 'true',
      publicAssistance: data.publicAssistance === 'true',
    };
    studentSpecialIndicator.mutate(request, {
      onSuccess: () => {
        setIsDirty(false);
        success(`Student (${studentProfileId}) Special Indicator data successfully saved!`);
        queryClient.invalidateQueries([QueryKey.getStudentSpecialIndicatorData, studentProfileId]);
      },
      onError: (err) => {
        error(err.message);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef as LegacyRef<HTMLFormElement>}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={3} width="100%">
          <Card sx={{ pl: 3, pt: 3, pr: 3, borderRadius: '4px' }}>
            <CardHeader
              sx={{ p: 0, pb: 3 }}
              title={
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography component="h3" variant="titleLarge">
                    General Information
                  </Typography>
                </Stack>
              }
            />
            <CardContent sx={{ p: 0 }}>
              <Stack spacing={3}>
                <Dropdown
                  name="specialIndicatorGrade"
                  label="Grade Level"
                  options={studentSpecialGradeOptionsWithNull}
                  control={control}
                  variant="outlined"
                  showInputAdornment
                />
              </Stack>
            </CardContent>
          </Card>

          <Card sx={{ pl: 3, pt: 3, pr: 3, borderRadius: '4px' }}>
            <CardHeader
              sx={{ p: 0, pb: 3 }}
              title={
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography component="h3" variant="titleLarge">
                    Demographic Information
                  </Typography>
                </Stack>
              }
            />
            <CardContent sx={{ p: 0 }}>
              <Stack spacing={3}>
                <Dropdown name="race" label="Race" options={studentRaceOptionsWithNull} control={control} variant="outlined" showInputAdornment />
                <Dropdown
                  name="primaryLanguageId"
                  label="Primary Language"
                  options={languageFormOptions}
                  control={control}
                  variant="outlined"
                  showInputAdornment
                />
                <Dropdown name="ell" label="ELL" options={booleanFilterOptions} control={control} variant="outlined" showInputAdornment />
                <Dropdown
                  name="hispanicLatino"
                  label="Hispanic or Latino"
                  options={booleanFilterOptions}
                  control={control}
                  variant="outlined"
                  showInputAdornment
                />
                <Dropdown name="homeless" label="Homeless" options={booleanFilterOptions} control={control} variant="outlined" showInputAdornment />
                <Dropdown
                  name="fosterCare"
                  label="Foster Care"
                  options={booleanFilterOptions}
                  control={control}
                  variant="outlined"
                  showInputAdornment
                />
                <Dropdown name="newComer" label="Newcomer" options={booleanFilterOptions} control={control} variant="outlined" showInputAdornment />
                <Dropdown name="migrant" label="Migrant" options={booleanFilterOptions} control={control} variant="outlined" showInputAdornment />
              </Stack>
            </CardContent>
          </Card>
        </Stack>

        <Stack spacing={3} width="100%">
          <Card sx={{ pl: 3, pt: 3, pr: 3, borderRadius: '4px' }}>
            <CardHeader
              sx={{ p: 0, pb: 3 }}
              title={
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography component="h3" variant="titleLarge">
                    Special Indicators
                  </Typography>
                </Stack>
              }
            />
            <CardContent sx={{ p: 0 }}>
              <Stack spacing={3}>
                <Dropdown
                  name="specialEducation"
                  label="Special Education"
                  options={booleanFilterOptions}
                  control={control}
                  variant="outlined"
                  showInputAdornment
                />
                <Dropdown name="iep" label="IEP" options={booleanFilterOptions} control={control} variant="outlined" showInputAdornment />
                <Dropdown name="504" label="504" options={booleanFilterOptions} control={control} variant="outlined" showInputAdornment />
              </Stack>
            </CardContent>
          </Card>

          <Card sx={{ pl: 3, pt: 3, pr: 3, borderRadius: '4px' }}>
            <CardHeader
              sx={{ p: 0, pb: 3 }}
              title={
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography component="h3" variant="titleLarge">
                    Other Indicators
                  </Typography>
                </Stack>
              }
            />
            <CardContent sx={{ p: 0 }}>
              <Stack spacing={3}>
                <Dropdown
                  name="disability"
                  label="Disability"
                  options={studentDisabilityOptionsWithNull}
                  control={control}
                  variant="outlined"
                  showInputAdornment
                />
                <Dropdown
                  name="foodServiceProgram"
                  label="Food Service Program"
                  options={foodServiceProgramOptionsWithNull}
                  control={control}
                  variant="outlined"
                  showInputAdornment
                />
                <Dropdown name="sed" label="SED" options={booleanFilterOptions} control={control} variant="outlined" showInputAdornment />
                <Dropdown
                  name="publicAssistance"
                  label="Public Assistance"
                  options={booleanFilterOptions}
                  control={control}
                  variant="outlined"
                  showInputAdornment
                />
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </form>
  );
};

export default StudentSpecialIndicators;
