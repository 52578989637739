import { useParams } from 'react-router-dom';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import { usePageTitle } from '../../hooks/usePageTitle';
import PermissionDenied from '../home/PermissionDenied';
import Report from './Report';
import Reporting from './Reporting';

export const ReportingView = () => {
  const { scopes } = useAuth();
  usePageTitle('Reporting | Admin Portal');
  const { type } = useParams<{ type: string }>();
  const accessGranted = !!scopes.find((s) => s === HootEmployeeScope.AccessReporting);

  if (!accessGranted) {
    return <PermissionDenied />;
  } else if (!type) {
    return <Reporting />;
  } else {
    const metabaseReportId = parseInt(type);
    return <Report metabaseReportId={metabaseReportId} />;
  }
};

export default ReportingView;
