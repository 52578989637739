import { Box, Stack, Table, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SortOrder } from '@hoot/components/hfs-enums';
import SortIcon from '@hoot/components/icons/SortIcon';
import { useAlert } from '@hoot/contexts/AlertContext';
import useGetStudentHootAssessments, { StudentHootAssessment } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentHootAssessments';
import StudentHootLevelCard from '@hoot/pages/users/students/assessments/StudentHootLevelAssessmentsCard';
import { StyledTableCell } from '@hoot/styles/Table';
import HootReadingAssessmentDetailsModal from './StudentAssessmentsDetailsModal';
import StudentAssessmentsTableBody from './StudentAssessmentsTableBody';
import StudentFormativeAssessmentsCard from './StudentFormativeAssessmentsCard';
import StudentProgressMonitoringAssessmentsCard from './StudentProgressMonitoringAssessmentsCard';

const StudentAssessmentsTab = () => {
  const { studentProfileId } = useParams() as { studentProfileId: string };
  const { error } = useAlert();
  const { data, refetch } = useGetStudentHootAssessments(studentProfileId, {
    onError: () => error(`Error retrieving the lesson notes for student profile id: ${studentProfileId}`),
    onSettled(data) {
      if (data) {
        setSortedRows(data.assessments);
      }
    },
  });
  const [open, setOpen] = useState<boolean>(false);
  const [selectedAssessment, setSelectedAssessment] = useState<StudentHootAssessment | undefined>(undefined);

  const [sortDirection, setSortDirection] = useState<SortOrder>(SortOrder.ASC);
  const [sortColumn, setSortColumn] = useState<keyof StudentHootAssessment>('status');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortedRows, setSortedRows] = useState(data?.assessments ?? []);

  if (!studentProfileId || studentProfileId === '') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          fontWeight: 600,
        }}
      >
        Error! Student Profile Id lost.
      </div>
    );
  }

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column: keyof StudentHootAssessment) => {
    const isAsc = sortColumn === column && sortDirection === SortOrder.ASC;
    setSortDirection(isAsc ? SortOrder.DESC : SortOrder.ASC);
    setSortColumn(column);
    setSortedRows(sortRows());
  };

  const sortRows = () => {
    return [...sortedRows].sort((a, b) => {
      if (a[sortColumn] === null) return 1;
      if (b[sortColumn] === null) return -1;
      if (a[sortColumn] === null && b[sortColumn] === null) return 0;
      if (sortColumn === 'exitPointUnit') {
        const aUnit = a.exitPointUnit?.unitName ?? '';
        const bUnit = b.exitPointUnit?.unitName ?? '';
        return (
          aUnit.localeCompare(bUnit, 'en', {
            numeric: true,
          }) * (sortDirection === SortOrder.ASC ? 1 : -1)
        );
      }
      if (sortColumn === 'assessedByTeachers') {
        const aUnit = a.assessedByTeachers[0].firstName ?? '';
        const bUnit = b.assessedByTeachers[0].firstName ?? '';
        return (
          aUnit.localeCompare(bUnit, 'en', {
            numeric: true,
          }) * (sortDirection === SortOrder.ASC ? 1 : -1)
        );
      }
      const aString = a[sortColumn]?.toString() ?? '';
      const bString = b[sortColumn]?.toString() ?? '';
      return (
        aString.localeCompare(bString, 'en', {
          numeric: true,
        }) * (sortDirection === SortOrder.ASC ? 1 : -1)
      );
    });
  };

  return (
    <Stack gap={2}>
      <StudentHootLevelCard studentProfileId={studentProfileId} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          gap: '24px',
          borderRadius: '4px',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        }}
      >
        <Box sx={{ padding: '24px 24px 0px 24px' }}>
          <Typography variant="titleLarge">Hoot Reading Assessments</Typography>
        </Box>
        <TableContainer
          sx={{
            padding: '0px 24px 24px 24px',
          }}
        >
          <Table style={{ border: 'solid 1px rgba(224, 224, 224, 1)' }} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
            <TableHead>
              <TableRow>
                <StyledTableCell onClick={() => handleSort('status')} align="left">
                  <TableHeaderItem>
                    <span>Status</span>
                    {sortColumn === 'status' ? <SortIcon /> : null}
                  </TableHeaderItem>
                </StyledTableCell>
                <StyledTableCell onClick={() => handleSort('createdAt')} align="left">
                  <TableHeaderItem>
                    <span>Start Date</span>
                    {sortColumn === 'createdAt' ? <SortIcon /> : null}
                  </TableHeaderItem>
                </StyledTableCell>
                <StyledTableCell onClick={() => handleSort('updatedAt')} align="left">
                  <TableHeaderItem>
                    <span>Completion Date</span>
                    {sortColumn === 'updatedAt' ? <SortIcon /> : null}
                  </TableHeaderItem>
                </StyledTableCell>
                <StyledTableCell onClick={() => handleSort('assessedByTeachers')} align="left">
                  <TableHeaderItem>
                    <span>Assessed By</span>
                    {sortColumn === 'assessedByTeachers' ? <SortIcon /> : null}
                  </TableHeaderItem>
                </StyledTableCell>
                <StyledTableCell onClick={() => handleSort('exitPointUnit')} align="left">
                  <TableHeaderItem>
                    <span>Exit Point</span>
                    {sortColumn === 'exitPointUnit' ? <SortIcon /> : null}
                  </TableHeaderItem>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <StudentAssessmentsTableBody
              setOpen={setOpen}
              assessments={sortedRows}
              setSelectedAssessment={setSelectedAssessment}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={data?.assessments.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <HootReadingAssessmentDetailsModal
          open={open}
          assessment={selectedAssessment}
          refetchAssessments={refetch}
          onClose={() => {
            setOpen(false);
          }}
        />
      </Box>
      <StudentProgressMonitoringAssessmentsCard studentProfileId={studentProfileId} />
      <StudentFormativeAssessmentsCard studentProfileId={studentProfileId} />
    </Stack>
  );
};
const TableHeaderItem = (props: { children: React.ReactNode }) => {
  const { children } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 600,
      }}
    >
      {children}
    </Box>
  );
};

export default StudentAssessmentsTab;
