import { Chip, Skeleton, Stack, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { capitalCase } from 'change-case';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { rowsPerPageOptions } from '@hoot/common/constants';
import { SortOrder } from '@hoot/components/hfs-enums';
import SortIcon from '@hoot/components/icons/SortIcon';
import EnrolmentStatusIcon from '@hoot/components/ui/enrolments/EnrolmentStatusIcon';
import { useAlert } from '@hoot/contexts/AlertContext';
import useGetLocationSummaryEnrolments, {
  GetLocationSummaryEnrolmentsQuerySortKeys,
  GetLocationSummaryEnrolmentsResponse,
  LocationSummaryEnrolmentsQuery,
} from '@hoot/hooks/api/hfs/useGetLocationSummaryEnrolments';
import { SortOrder as QuerySortOrder } from '@hoot/interfaces/order-by';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import { routes } from '@hoot/routes/routes';
import { StyledTableCell } from '@hoot/styles/Table';
import { millisToDateDisplay } from '@hoot/utils/dateTime';
import { enumToTitleCase } from '@hoot/utils/stringUtils';
import { NoneListItem } from '../details/Shared';
import { TableHeaderItem } from './SummaryStudentTable';

interface IProductProps {
  query: LocationSummaryEnrolmentsQuery;
  setQuery: React.Dispatch<React.SetStateAction<LocationSummaryEnrolmentsQuery>>;
  locationId?: string;
}

export const SummaryEnrolmentTable = (props: IProductProps) => {
  const { locationId, query, setQuery } = props;

  const [sortBy, setSortBy] = useState<GetLocationSummaryEnrolmentsQuerySortKeys>(GetLocationSummaryEnrolmentsQuerySortKeys.ProductId);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(SortOrder.ASC);
  const { error } = useAlert();
  const [summaryData, setSummaryData] = useState<GenericPaginatedResponse<GetLocationSummaryEnrolmentsResponse>>({
    data: [],
    count: 0,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((current) => ({ ...current, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuery((current) => ({ ...current, pageSize: parseInt(event.target.value, 10), page: 1 }));
  };

  const handleSort = (column: GetLocationSummaryEnrolmentsQuerySortKeys) => {
    const isAsc = sortBy === column && sortOrder === SortOrder.ASC;
    setQuery({
      ...query,
      sortDirection: isAsc ? ('ASC' as QuerySortOrder) : ('DESC' as QuerySortOrder),
      orderBy: column,
    });
    setSortOrder(isAsc ? SortOrder.DESC : SortOrder.ASC);
    setSortBy(column);
  };

  const { isFetching } = useGetLocationSummaryEnrolments(locationId ?? '', query, {
    enabled: !!locationId,
    onSuccess: (data) => {
      setSummaryData(data);
    },
    onError: (err) => {
      console.error(err);
      error('There was an issue with the summary enrolments.');
    },
  });

  return (
    <Stack gap={2}>
      <Typography variant="titleLarge">Enrolments</Typography>
      {isFetching ? (
        <Skeleton width="100%" height="300px" variant="rectangular" />
      ) : !summaryData || summaryData.data.length === 0 ? (
        <NoneListItem />
      ) : (
        <>
          <Table sx={{ minWidth: 650 }} aria-label="schools and districts table">
            <TableHead>
              <TableRow>
                <StyledTableCell onClick={() => handleSort(GetLocationSummaryEnrolmentsQuerySortKeys.EnrolmentId)} align="left">
                  <TableHeaderItem>
                    <span>Enrolment ID</span>
                    <SortIcon />
                  </TableHeaderItem>
                </StyledTableCell>
                <StyledTableCell onClick={() => handleSort(GetLocationSummaryEnrolmentsQuerySortKeys.ProductId)} align="left">
                  <TableHeaderItem>
                    <span>Product ID</span>
                    <SortIcon />
                  </TableHeaderItem>
                </StyledTableCell>
                <StyledTableCell onClick={() => handleSort(GetLocationSummaryEnrolmentsQuerySortKeys.StartDate)} align="left">
                  <TableHeaderItem>
                    <span>Start Date</span>
                    <SortIcon />
                  </TableHeaderItem>
                </StyledTableCell>
                <StyledTableCell onClick={() => handleSort(GetLocationSummaryEnrolmentsQuerySortKeys.StartDate)} align="left">
                  <TableHeaderItem>
                    <span>End Date</span>
                  </TableHeaderItem>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TableHeaderItem>
                    <span>School</span>
                  </TableHeaderItem>
                </StyledTableCell>
                <StyledTableCell onClick={() => handleSort(GetLocationSummaryEnrolmentsQuerySortKeys.Type)} align="left">
                  <TableHeaderItem>
                    <span>Type</span>
                  </TableHeaderItem>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TableHeaderItem>
                    <span>Total Dosage</span>
                  </TableHeaderItem>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TableHeaderItem>
                    <span>Weekly Dosage</span>
                  </TableHeaderItem>
                </StyledTableCell>
                <StyledTableCell onClick={() => handleSort(GetLocationSummaryEnrolmentsQuerySortKeys.Status)} align="left">
                  <TableHeaderItem>
                    <span>Status</span>
                  </TableHeaderItem>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {summaryData.data.map((s) => (
                <TableRow key={`tr-${s.id}`} sx={{ cursor: 'pointer' }}>
                  <TableCell>
                    <Link to={routes.districtsSchools.enrolments.details.url(locationId!, s.id)}>{s.friendlyId}</Link>
                  </TableCell>
                  <TableCell>
                    <Link to={routes.districtsSchools.products.details.url(locationId!, s.productId)}>{s.productFriendlyId}</Link>
                  </TableCell>
                  <TableCell>{millisToDateDisplay(s.startDate)}</TableCell>
                  <TableCell>{millisToDateDisplay(s.endDate)}</TableCell>
                  <TableCell>
                    {s.schoolId ? (
                      <Link to={routes.districtsSchools.details.url(s.schoolId)}>{s.schoolName}</Link>
                    ) : (
                      <Typography variant="bodyMedium">{s.schoolName}</Typography>
                    )}
                  </TableCell>
                  <TableCell>{enumToTitleCase(s.type)}</TableCell>
                  <TableCell>{s.totalDosage}</TableCell>
                  <TableCell>{s.weeklyDosage}</TableCell>
                  <TableCell>
                    <Chip
                      icon={EnrolmentStatusIcon(s.status)}
                      label={`${capitalCase(s.status)}`}
                      sx={{ margin: '4px', fontSize: 12, backgroundColor: '#EFEFEF' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={9}
                  rowsPerPageOptions={rowsPerPageOptions}
                  count={summaryData.count}
                  rowsPerPage={summaryData.pageSize}
                  page={query.page ? query.page - 1 : 0}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </>
      )}
    </Stack>
  );
};

export default SummaryEnrolmentTable;
