import { Column, Label } from './styled-components';

interface YAxisLabelProps {
  yAxisLabelsSliced: string[];
  columnHeight: number;
}

// Renders the left  column that displays the Time.
function YAxisLabels(props: YAxisLabelProps) {
  return (
    <Column
      sx={{
        background: '#F2F2F2',
        borderRight: '1px solid #CCC',
        borderRadius: '0px 0px 0px 8px',
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.30),0px 1px 0px 1px rgba(0, 0, 0, 0.15)',
        height: `${props.columnHeight}px`,
      }}
    >
      {props.yAxisLabelsSliced.map((yAxisLabel) => (
        <Label
          key={yAxisLabel}
          sx={{
            margin: '0px 24px',
            borderTop: '1px solid #CCC',
          }}
        >
          {yAxisLabel}
        </Label>
      ))}
    </Column>
  );
}

export default YAxisLabels;
