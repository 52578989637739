import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';
import { DayOfWeek } from '@hoot/utils/dateTime';
import { ConflictType } from './useGetConflicts';

export interface MassEditConflictsRequest {
  lessonIds: string[];
  lessonStartTime?: string | null;
  dayOfWeek?: DayOfWeek | null;
}

export interface MassEditConflictsResponse {
  isSuccess: boolean;
  conflicts: {
    lessonId?: string;
    lessonStart: number;
    lessonEnd: number;
    message: string;
    conflictType: ConflictType;
    availabilityMessage: string;
  }[];
}

async function massUpdateConflicts(request: MassEditConflictsRequest): Promise<MassEditConflictsResponse> {
  const { data } = await axios.post<MassEditConflictsResponse>(`${config.apiUrl}/admin/scheduler/mass-update-conflict-check`, request);
  return data;
}

export default function useMassUpdateConflicts(
  options?: Omit<UseMutationOptions<MassEditConflictsResponse, AxiosError<ErrorResponseDto>, MassEditConflictsRequest>, 'mutationFn' | 'mutationKey'>,
) {
  return useMutation((request) => massUpdateConflicts(request), options);
}
