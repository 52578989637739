import { FocusVisibilityOption } from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import FilterDropDown from '@hoot/components/form/FilterDropDown';
import { StudentTimelineRequest } from '@hoot/hooks/api/user/student/timeline/getStudentTimelineVis';
import { focusVisibilityLabels } from './common';

export type FocusOptionType = {
  [key in FocusVisibilityOption]: boolean;
};

interface FocusVisibilityMenuProps {
  focusOptions: FocusOptionType;
  setFocusOptions: React.Dispatch<React.SetStateAction<FocusOptionType>>;
  setQuery: React.Dispatch<React.SetStateAction<StudentTimelineRequest>>;
}

const FocusVisibilityMenu = (props: FocusVisibilityMenuProps) => {
  const { focusOptions, setFocusOptions, setQuery } = props;

  const onChange = (options: FocusVisibilityOption[]) => {
    const updatedFocusOptions: FocusOptionType = {
      [FocusVisibilityOption.PreWordReading]: options.includes(FocusVisibilityOption.PreWordReading),
      [FocusVisibilityOption.EarlyWordReading]: options.includes(FocusVisibilityOption.EarlyWordReading),
      [FocusVisibilityOption.ComplexReading]: options.includes(FocusVisibilityOption.ComplexReading),
      [FocusVisibilityOption.TextLanguage]: options.includes(FocusVisibilityOption.TextLanguage),
      [FocusVisibilityOption.TextReading]: options.includes(FocusVisibilityOption.TextReading),
      [FocusVisibilityOption.OtherAreas]: options.includes(FocusVisibilityOption.OtherAreas),
    };

    setFocusOptions(updatedFocusOptions);

    const focusVisibilityOptions = Object.keys(updatedFocusOptions).filter(
      (key) => updatedFocusOptions[key as FocusVisibilityOption] === true,
    ) as FocusVisibilityOption[];
    setQuery((query) => ({
      ...query,
      focusVisibility: focusVisibilityOptions,
    }));
  };

  return (
    <>
      <FilterDropDown
        title={'Focus Visibility'}
        value={Object.keys(focusOptions).filter((key) => focusOptions[key as FocusVisibilityOption] === true) as FocusVisibilityOption[]}
        options={Object.values(FocusVisibilityOption).map((option) => ({
          label: focusVisibilityLabels[option],
          value: option,
        }))}
        onChange={onChange}
        popOverButtonSx={{ mx: 0 }}
        popOverSx={{ minHeight: '100%' }}
      />
    </>
  );
};

export default FocusVisibilityMenu;
