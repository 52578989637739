import {
  ScheduledLessonLanguage,
  ScheduledLessonSubject,
  ScheduledLessonTheme,
  ScheduledLessonType,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { DefaultValues } from 'react-hook-form/dist/types/form';
import { useAlert } from '@hoot/contexts/AlertContext';
import useGetCustomerAccount from '@hoot/hooks/api/end-users/useGetCustomerAccount';
import useGetStudentEnrolments, { StudentEnrolment } from '@hoot/hooks/api/end-users/useGetStudentEnrolments';
import { SearchStudentResponse } from '@hoot/hooks/api/end-users/useSearchStudentsV2';
import CreateLessonFooter from '@hoot/pages/lessons/create-v2/CreateLessonFooter';
import { useCreateLessonContext } from '@hoot/pages/lessons/create-v2/context/CreateLessonContextProvider';
import { useStudentAndLessonContext } from '@hoot/pages/lessons/create-v2/context/StudentAndLessonContextProvider';
import { useStudentLessonDetailsContext } from '@hoot/pages/lessons/create-v2/context/StudentLessonDetailsProvider';
import CreateLessonDetails from '@hoot/pages/lessons/create-v2/student-and-lesson-step/CreateLessonDetails';
import CreateLessonSelectStudent from '@hoot/pages/lessons/create-v2/student-and-lesson-step/CreateLessonSelectStudent';

export interface StudentAndLessonForm {
  studentProfile: SearchStudentResponse | null;
  selectedEnrolmentId: string | '';
  lessonSetType: 'NEW_LESSON_SET' | 'WITHIN_EXISTING_LESSON_SET' | '';
  lessonType: ScheduledLessonType;
  accountId: string;
  isCustomerBilledLesson: boolean;
  subject: ScheduledLessonSubject;
  language: ScheduledLessonLanguage;
  theme: ScheduledLessonTheme | '';
}

const formDefaultValues: DefaultValues<StudentAndLessonForm> = {
  studentProfile: null,
  lessonSetType: '',
  selectedEnrolmentId: '',
  lessonType: ScheduledLessonType.Instruction,
  accountId: '',
  subject: ScheduledLessonSubject.Reading,
  language: ScheduledLessonLanguage.English,
  isCustomerBilledLesson: true,
  theme: '',
};

const CreateLessonStudentSelectStep = () => {
  const { goToNextStep, setLoadingCustomer, setLoadingEnrolments, hideStudentName } = useCreateLessonContext();
  const { studentAndLessonData, setStudentAndLessonData } = useStudentAndLessonContext();
  const { reset: resetLessonDetails, lessonDetails } = useStudentLessonDetailsContext();

  const { error } = useAlert();

  const [studentEnrolmentsDictionary, setStudentEnrolmentsDictionary] = useState<Map<string, StudentEnrolment>>(new Map());

  const studentAndLessonForm = useForm<StudentAndLessonForm>({
    defaultValues: {
      studentProfile: studentAndLessonData?.studentProfile ?? formDefaultValues.studentProfile,
      lessonSetType: studentAndLessonData?.lessonSetType ?? formDefaultValues.lessonSetType,
      selectedEnrolmentId: studentAndLessonData?.selectedEnrolment?.id ?? formDefaultValues.selectedEnrolmentId,
      lessonType: studentAndLessonData?.lessonType ?? formDefaultValues.lessonType,
      accountId: studentAndLessonData?.accountId ?? formDefaultValues.accountId,
      subject: studentAndLessonData?.subject ?? formDefaultValues.subject,
      language: studentAndLessonData?.language ?? formDefaultValues.language,
      isCustomerBilledLesson: studentAndLessonData?.isCustomerBilledLesson ?? formDefaultValues.isCustomerBilledLesson,
      theme: studentAndLessonData?.theme ?? formDefaultValues.theme,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const studentProfile = studentAndLessonForm.watch('studentProfile');
  const enrolmentId = studentAndLessonForm.watch('selectedEnrolmentId');

  const { isFetching: isFetchingCustomers } = useGetCustomerAccount(studentProfile?.userId ?? '', {
    // Fetch student account information when student is selected.
    enabled: !!studentProfile?.userId,
    retry: false,
    onSuccess: (data) => {
      // If the account is active, then pre-populate the account field.
      if (data.account?.isActive) {
        studentAndLessonForm.setValue('accountId', data.account.id);
      }
    },
    onError: (err) => {
      // We're expecting that not every user has a customer account, for a 404 is OK. Any other error code is a legit error though.
      if (err.response?.status !== 404) {
        console.error(err);
      }
    },
  });

  const { isFetching: isFetchingEnrolments } = useGetStudentEnrolments(studentProfile?.id ?? '', {
    // Fetch student enrolments when student is selected.
    enabled: !!studentProfile?.id,
    retry: false,
    onSuccess: (data) => {
      const studentEnrolmentsDictionary = new Map(data.map((x) => [x.id, x]));
      setStudentEnrolmentsDictionary(studentEnrolmentsDictionary);

      // If student has an enrolment, make them select, otherwise show None and disable
      // However, don't auto-select it if we're navigating back from step 2; that would override the previous submission.
      if (studentProfile?.id !== studentAndLessonData?.studentProfile.id) {
        studentAndLessonForm.setValue('selectedEnrolmentId', !!data.length ? 'select' : '', { shouldValidate: true });
      }
    },
    onError: (err) => {
      console.error(err);
      error('An error occurred while retrieving student enrolments.');
    },
  });

  // Show loading indicator when we're loading customer account.
  useEffect(() => {
    if (isFetchingCustomers) {
      setLoadingCustomer(true);
    } else {
      setLoadingCustomer(false);
    }
  }, [isFetchingCustomers, setLoadingCustomer]);

  // Show loading indicator when we're loading student enrolments.
  useEffect(() => {
    if (isFetchingEnrolments) {
      setLoadingEnrolments(true);
    } else {
      setLoadingEnrolments(false);
    }
  }, [isFetchingEnrolments, setLoadingEnrolments]);

  const onStudentSelected = (selectedStudent: SearchStudentResponse | undefined) => {
    if (selectedStudent) {
      studentAndLessonForm.setValue('studentProfile', selectedStudent ?? null, {
        shouldValidate: true,
      });
    } else {
      // If the student was cleared out, then reset the form.
      studentAndLessonForm.reset({
        ...formDefaultValues,
      });
    }
  };

  const onSelectStudentEnrolment = (selectedEnrolment: StudentEnrolment | undefined) => {
    studentAndLessonForm.setValue('selectedEnrolmentId', selectedEnrolment?.id ?? '', { shouldValidate: true });

    // If no enrolment is selected, then set the lesson set type to nil.
    if (selectedEnrolment === undefined) {
      studentAndLessonForm.setValue('lessonSetType', '');
    } else {
      // Else, we've selected an enrolment.
      // Set the "Lesson Type" to INSTRUCTION by default.
      studentAndLessonForm.setValue('lessonType', ScheduledLessonType.Instruction);

      // If the selected enrolment does NOT have a lesson set, then check the "Lesson Set" checkbox by default.
      if (!selectedEnrolment.hasLessonSet) {
        studentAndLessonForm.setValue('lessonSetType', 'NEW_LESSON_SET');
      } else {
        // Else, the selected enrolment DOES have a lesson set, so check off the "Create within Lesson Set" checkbox by default.
        studentAndLessonForm.setValue('lessonSetType', 'WITHIN_EXISTING_LESSON_SET');
      }
    }
  };

  const handleSubmit: SubmitHandler<StudentAndLessonForm> = (form) => {
    const { studentProfile, selectedEnrolmentId, lessonSetType, ...rest } = form;

    // When we've selected a different student than previous selection (AKA we've come back to step 1 and picked someone
    // else), then reset all the data from subsequent steps.
    if (studentProfile?.id !== studentAndLessonData?.studentProfile?.id) {
      resetLessonDetails();
    } else if (selectedEnrolmentId && lessonSetType !== 'NEW_LESSON_SET') {
      // Else if we've selected the same student, also selected an enrolment, but we're not doing anything with lesson sets,
      // then default `recurring` to false. This is an invalid state.
      lessonDetails.recurring = false;
    }

    setStudentAndLessonData({
      // Student profile if a required form prop, so it should be defined here if this passed validation.
      studentProfile: studentProfile!,
      selectedEnrolment: studentEnrolmentsDictionary.get(selectedEnrolmentId),
      lessonSetType: lessonSetType !== '' ? lessonSetType : undefined,
      ...rest,
      theme: undefined,
    });
    goToNextStep();
  };

  return (
    <form onSubmit={studentAndLessonForm.handleSubmit(handleSubmit)}>
      <CreateLessonSelectStudent
        studentAndLessonForm={studentAndLessonForm}
        studentEnrolmentsDictionary={studentEnrolmentsDictionary}
        onStudentSelected={onStudentSelected}
        onSelectStudentEnrolment={onSelectStudentEnrolment}
        hideStudentName={hideStudentName}
      />
      {/* Next section becomes visible once we've selected a student. */}
      {!!studentProfile && <CreateLessonDetails studentAndLessonForm={studentAndLessonForm} />}
      <CreateLessonFooter>
        {/* This is the first step, so you can't go back. */}
        <Button disabled variant="contained" size="large">
          Previous Step
        </Button>
        <Button type="submit" variant="contained" size="large" disabled={enrolmentId === 'select'}>
          Next Step
        </Button>
      </CreateLessonFooter>
    </form>
  );
};

export default CreateLessonStudentSelectStep;
