import { Card } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useGetTeacherAvailability } from '@hoot/hooks/api/availability/useAvailability';
import { useGetTeacherAvailabilityException } from '@hoot/hooks/api/availability/useAvailabilityException';
import { useGetTeacherShifts } from '@hoot/hooks/api/availability/useGetTeacherShifts';
import useUserLessonScheduleQuery from '@hoot/hooks/api/lessons/useUserLessonScheduleQuery';
import { TeacherAccount } from '@hoot/hooks/api/user/teacher/useGetTeacherAccount';
import { UserProfileType } from '@hoot/interfaces/user';
import { ScheduleType } from '@hoot/pages/lessons/reschedule-v2/same-teacher-flow/RescheduleWizardSchedulesStep';
import { chunkTimeSpan } from '@hoot/utils/dateTime';
import { UserScheduleDetailsDialog } from '../../components/user-schedule/UserScheduleDetailsDialog';
import { WeeklySchedule } from '../../components/user-schedule/WeeklySchedule';
import { AvailabilityTimeSlot, LessonTimeSlot, SelectedItem, ShiftTimeSlot } from '../../components/user-schedule/weekly-schedule/interfaces';

export function TeacherScheduleTabV2(props: { teacherAccount: TeacherAccount }) {
  const [startOfWeek, setStartOfWeek] = useState(DateTime.now().startOf('week')); // starts on Monday

  const [selectedItem, setSelectedItem] = useState<SelectedItem>();

  const endsAt = startOfWeek.plus({ week: 1 });

  const shiftsRequest = useGetTeacherShifts(props.teacherAccount.id, {
    startsAt: startOfWeek.toMillis(),
    endsAt: endsAt.toMillis(),
    includeCancelled: true,
  });

  const lessonsRequest = useUserLessonScheduleQuery({
    profileType: UserProfileType.Teacher,
    userProfileId: props.teacherAccount.id,
    fromDate: startOfWeek.toMillis(),
    toDate: endsAt.toMillis(),
  });

  const availabilityRequest = useGetTeacherAvailability(props.teacherAccount.id);

  const availablityExceptionsRequest = useGetTeacherAvailabilityException({
    startsAt: startOfWeek.minus({ week: 2 }).toMillis(),
    endsAt: startOfWeek.plus({ week: 2 }).toMillis(),
    teacherAccountId: props.teacherAccount.id,
  });

  const handleSelected = (newSelectedItem: SelectedItem) => {
    setSelectedItem(newSelectedItem);
  };

  const handleClose = () => setSelectedItem(undefined);

  const handleStartofWeekChange = (newStartOfWeek: DateTime) => {
    setStartOfWeek(newStartOfWeek);
  };

  const shifts =
    shiftsRequest.data?.shifts.map<ShiftTimeSlot>((s) => ({
      id: s.id,
      startAt: DateTime.fromMillis(s.startsAt),
      endsAt: DateTime.fromMillis(s.endsAt),
      status: s.status,
      type: s.type,
    })) ?? [];

  const lessons =
    lessonsRequest.data?.lessons.map<LessonTimeSlot>((l) => ({
      id: l.id,
      number: l.number,
      studentNumber: l.prefixedStudentNumber,
      startAt: DateTime.fromMillis(l.startsAt),
      endsAt: DateTime.fromMillis(l.endsAt),
      status: l.status,
    })) ?? [];

  const availability =
    availabilityRequest.data?.timeblocks?.map<AvailabilityTimeSlot>((tb) => ({
      startAt: tb.startTime,
      endAt: tb.endTime,
      dayOfWeek: tb.dayOfWeek,
      zone: availabilityRequest.data.timezone,
    })) ?? [];

  const availabilityExceptions = availablityExceptionsRequest.data?.flatMap((exception) =>
    chunkTimeSpan(DateTime.fromMillis(exception.startsAt), DateTime.fromMillis(exception.endsAt)),
  );

  return (
    <Card sx={{ marginTop: '24px', padding: '24px', height: '820px', overflowY: 'scroll' }}>
      <WeeklySchedule
        startOfWeek={startOfWeek}
        onStartOfWeekChange={handleStartofWeekChange}
        selectedItem={selectedItem}
        onSelected={handleSelected}
        availability={availability}
        availabilityExceptions={availabilityExceptions}
        lessons={lessons}
        shifts={shifts}
        scheduleType={ScheduleType.Teacher}
        userTimeZone={props.teacherAccount.user.timeZone}
      />
      {selectedItem ? (
        <UserScheduleDetailsDialog teacherAccountId={props.teacherAccount.id} selectedItem={selectedItem} handleClose={handleClose} />
      ) : null}
    </Card>
  );
}
