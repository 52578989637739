import { ProductProgram } from '@hoot-reading/hoot-core/dist/enums/hfs/product-program.enum';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid2,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@hoot/components/form/DatePicker';
import { ProductProgramSelect } from '@hoot/components/form/selectFields/products/ProductProgramSelect';
import { useAlert } from '@hoot/contexts/AlertContext';
import useCreateProduct, { CreateProductRequest } from '@hoot/hooks/api/hfs/district-schools/products/useCreateProduct';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { routes } from '@hoot/routes/routes';

export interface CreateProductForm {
  name: string;
  program: ProductProgram | '';
  startDate: string;
  endDate: string;
  numberOfEnrolments: number | '';
  numberOfStudents: number | '';
}

interface Props {
  locationId: string;
  open: boolean;
  onClose: () => void;
}

const CreateProductModal = (props: Props) => {
  const { locationId, open, onClose } = props;
  const queryClient = useQueryClient();
  const { success, error } = useAlert();
  const navigate = useNavigate();

  const { formState, control, handleSubmit, watch } = useForm<CreateProductForm>({
    defaultValues: {
      name: '',
      program: '',
      startDate: '',
      endDate: '',
      numberOfEnrolments: '',
      numberOfStudents: '',
    },
  });

  const { startDate, endDate } = watch();

  const createProductMutation = useCreateProduct();

  const onSubmit = (data: CreateProductForm) => {
    if (!data.program) {
      return;
    }

    const request: CreateProductRequest = {
      name: data.name,
      program: data.program,
      startDate: DateTime.fromISO(data.startDate).toMillis(),
      endDate: DateTime.fromISO(data.endDate).toMillis(),
      locationId: locationId,
      cycle: +data.numberOfEnrolments,
      numberOfStudents: +data.numberOfStudents,
    };

    createProductMutation.mutate(request, {
      onSuccess: (productId) => {
        success(`Product created.`);
        queryClient.invalidateQueries([QueryKey.SearchLocationProducts, locationId]);
        navigate(routes.districtsSchools.products.details.url(locationId, productId));
      },
      onError: (err) => {
        error(err.message);
      },
    });
  };

  const dateValidation = (stringDate: string, stringDateToCompare: string, checkingEndDate: boolean) => {
    if (!stringDate) {
      return `${checkingEndDate ? 'End Date is required.' : 'Start Date is required.'}`;
    }

    const today = DateTime.now().endOf('day');
    const dateToCheck = DateTime.fromISO(stringDate).endOf('day');

    if (dateToCheck <= today) {
      return checkingEndDate ? 'End Date must be after today.' : 'Start Date must be after today.';
    }

    if (stringDateToCompare) {
      const dateToCompare = DateTime.fromISO(stringDateToCompare).endOf('day');
      if (checkingEndDate ? dateToCheck <= dateToCompare : dateToCheck >= dateToCompare) {
        return checkingEndDate ? 'End Date must be after the Start Date.' : 'Start Date must be before the End Date.';
      }
    }
    return true;
  };

  return (
    <form id="create-product-form" autoComplete="off">
      <Dialog open={open} onClose={onClose}>
        <DialogTitle
          id="dialog-title"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box component="span" sx={{ fontSize: '24px', lineHeight: '32px' }}>
            Create New Product
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: 3 }}>
          <Grid2 container spacing={2}>
            <Controller
              name="name"
              rules={{
                required: `Product name is required`,
              }}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  helperText={fieldState.error?.message}
                  error={!!fieldState.error}
                  fullWidth
                  label={`Product Name*`}
                  variant="outlined"
                  sx={{ mt: 1 }}
                />
              )}
            />

            <ProductProgramSelect
              control={control}
              name="program"
              rules={{
                required: `Program is required`,
              }}
              errorMessage={formState.errors?.program?.message}
            />

            <Controller
              name="numberOfEnrolments"
              rules={{
                required: `Number of enrolments is required`,
              }}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number"
                  helperText={fieldState.error?.message}
                  error={!!fieldState.error}
                  fullWidth
                  label={`Enrolments*`}
                  variant="outlined"
                />
              )}
            />

            <Controller
              name="numberOfStudents"
              rules={{
                required: `Number of students is required`,
              }}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number"
                  helperText={fieldState.error?.message}
                  error={!!fieldState.error}
                  fullWidth
                  label={`Students*`}
                  variant="outlined"
                />
              )}
            />

            <Grid2 size={6}>
              <DatePicker
                label="Start Date"
                name="startDate"
                control={control}
                disablePast
                minDate={DateTime.now().plus({ days: 1 })}
                sx={{ width: '100%' }}
                rules={{
                  validate: (val) => dateValidation(val, endDate, false),
                }}
              />
              <FormHelperText error={!!formState.errors?.startDate?.message} sx={{ ml: 2 }}>
                {formState.errors?.startDate?.message ? '' : 'MM/DD/YYYY'}
              </FormHelperText>
            </Grid2>

            <Grid2 size={6}>
              <DatePicker
                label="End Date"
                name="endDate"
                control={control}
                disablePast
                sx={{ width: '100%' }}
                rules={{
                  validate: (val) => dateValidation(val, startDate, true),
                }}
              />
              <FormHelperText error={!!formState.errors?.endDate?.message} sx={{ ml: 2 }}>
                {formState.errors?.endDate?.message ? '' : 'MM/DD/YYYY'}
              </FormHelperText>
            </Grid2>
          </Grid2>
        </DialogContent>

        <Divider />

        <DialogActions>
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button fullWidth size="large" color="inherit" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button fullWidth size="large" variant="contained" onClick={handleSubmit(onSubmit)}>
              Apply
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default CreateProductModal;
