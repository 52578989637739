import React from 'react';
import Swal from 'sweetalert2';
import withReactContent, { ReactSweetAlert, SweetAlert2 } from 'sweetalert2-react-content';

type Severity = 'success' | 'info' | 'warning' | 'error';

interface Props {
  children: React.ReactNode;
}

interface Values {
  message?: string;
  showAlert: boolean;
  severity?: Severity;
  success: (message: string) => void;
  info: (message: string) => void;
  warning: (message: string) => void;
  error: (message: string) => void;
  handleClose: () => void;
  sweetAlert: SweetAlert2 & ReactSweetAlert;
}

const AlertContext = React.createContext<Values>(undefined!);

const AlertProvider = (props: Props) => {
  const { children } = props;
  const [message, setMessage] = React.useState<string>();
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [severity, setSeverity] = React.useState<Severity>();
  const swal = withReactContent(Swal);

  const success = (message: string) => {
    handleAlert('success', message);
  };

  const info = (message: string) => {
    handleAlert('info', message);
  };

  const warning = (message: string) => {
    handleAlert('warning', message);
  };

  const error = (message: string) => {
    handleAlert('error', message);
  };

  const handleAlert = (severity: Severity, message: string) => {
    setMessage(message);
    setSeverity(severity);
    setShowAlert(true);
  };

  const handleClose = () => {
    setMessage(undefined);
    setSeverity(undefined);
    setShowAlert(false);
  };

  return (
    <AlertContext.Provider
      value={{
        message,
        showAlert,
        success,
        info,
        error,
        warning,
        severity,
        handleClose,
        sweetAlert: swal,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

const useAlert = () => {
  const context = React.useContext(AlertContext);

  if (context === undefined) {
    throw new Error('useAlert must be used within a AlertProvider');
  }

  return context;
};

export { AlertProvider, useAlert };
