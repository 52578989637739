import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface CollectionResponse {
  id: string;
  name: string;
}

async function getCollections(): Promise<CollectionResponse[]> {
  const { data } = await axios.get<CollectionResponse[]>(`${config.apiUrl}/admin/library/collections`);
  return data;
}

export default function useGetCollections(options?: Omit<UseQueryOptions<CollectionResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetCollections], () => getCollections(), options);
}
