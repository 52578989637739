import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { DistrictRepresentativeStatus } from '@hoot-reading/hoot-core/dist/enums/user/district-representative/district-representative-status.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export enum EdlinkIntegrationProvider {
  Canvas = 'CANVAS',
  Clever = 'CLEVER',
  Google = 'GOOGLE',
  //... TODO: more
}

export interface DistrictSchool {
  id: string;
  friendlyId: string;
  name: string;
  city: string;
  state: string;
  country: string;
  students: number;
  timezone: string;
}

export interface DistrictRepresentative {
  id: string;
  prefixedNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  enrolments: string;
  status: DistrictRepresentativeStatus;
}

export interface LocationEdlinkDataResponse {
  integrationId: string;
  integrationName: string;
  integrationProvider: EdlinkIntegrationProvider;
  edlinkLocationId: string;
  edlinkLocationName: string;
  locationType: LocationType;
}

export interface DistrictSchoolDetailResponse {
  id: string;
  type: LocationType;
  isBillableEntity: boolean;
  name: string;
  friendlyId: string;
  country: string;
  state: string;
  city: string;
  timeZone: string;
  districtSchools: DistrictSchool[];
  districtRepresentatives: DistrictRepresentative[];
  edlinkData: LocationEdlinkDataResponse | null;
  reporting: boolean;
}

const getDistrictSchool = async (locationId: string): Promise<DistrictSchoolDetailResponse> => {
  const { data } = await axios.get<DistrictSchoolDetailResponse>(`${config.apiUrl}/admin/hfs/district-schools/${locationId}`);
  return data;
};

export default function useGetDistrictSchool(
  locationId: string,
  options?: Omit<UseQueryOptions<DistrictSchoolDetailResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetDistrictsSchool, locationId], () => getDistrictSchool(locationId), options);
}
