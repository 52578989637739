import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';

async function reSendUserAccountCreation(email: string) {
  await axios.post(`${config.apiUrl}/admin/user/send-account-created`, {
    email,
  });
}

export function useReSendUserAccountCreation(options?: Omit<UseMutationOptions<void, AxiosError, string>, 'mutationKey' | 'mutationFn'>) {
  return useMutation((request) => reSendUserAccountCreation(request), options);
}
