import { TeacherBlockCancellationReason, TeacherBlockType } from '@hoot-reading/hoot-core/dist/enums/teacher-block';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface UpdateTeacherBlockRequest {
  startsAt: number;
  endsAt: number;
  type: TeacherBlockType;
  cancellationReason?: TeacherBlockCancellationReason;
}

export interface UpdateTeacherBlockResponse {
  isSuccess: boolean;
}

async function updateTeacherBlock(blockId: string, request: UpdateTeacherBlockRequest) {
  const { data } = await axios.put<UpdateTeacherBlockResponse>(`${config.apiUrl}/admin/teacher-block/${blockId}`, request);

  return data;
}

export default function useUpdateTeacherBlock(
  blockId: string,
  options?: Omit<UseMutationOptions<UpdateTeacherBlockResponse, AxiosError, UpdateTeacherBlockRequest>, 'queryKey' | 'queryFn'>,
) {
  return useMutation((request) => updateTeacherBlock(blockId, request), options);
}
