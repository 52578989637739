import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { DayOfWeekWithOther } from '@hoot/utils/dateTime';

export interface PreferredDateTime {
  weekDay: DayOfWeekWithOther;
  time: string;
}

export interface StudentLessonPreferences {
  lessonsPerWeek: number | null;
  lessonDuration: number | null;
  preferredDateTimes: PreferredDateTime[];
}

const getStudentLessonPreferences = async (studentId: string) => {
  const { data } = await axios.get<StudentLessonPreferences>(`${config.apiUrl}/admin/student-profile/${studentId}/lesson-preferences/`);
  return data;
};

export default function useGetStudentLessonPreferences(
  studentId: string,
  options?: Omit<UseQueryOptions<StudentLessonPreferences, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetStudentLessonPreferences, studentId], () => getStudentLessonPreferences(studentId), options);
}
