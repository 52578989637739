import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { HootAssessmentInstructionIndicator, ProgressMonitoringAssessmentStatus } from '@hoot/interfaces/assessments-enums';
import { QueryKey } from '../queryKeys';

export interface PMAAssessedByTeacher {
  prefixedNumber: string;
  id: string;
}

interface PMABook {
  name: string;
  level: string;
}

export interface CompletedPMAUnit {
  unit: {
    unitId: PMAUnitIdentifier;
    unitName: string;
  };
  assessedBy: PMAAssessedByTeacher;
  totalQuestions?: number;
  score?: number;
  instructionIndicator?: HootAssessmentInstructionIndicator;
  bookForRunningRecord?: PMABook;
  accuracyRate?: number;
  wordCountPerMin?: number;
  fluencyScore?: number;
  comprehensionScore?: number;
}

export interface GetPMADetailsResponse {
  createdAt: number;
  updatedAt: number;
  status: ProgressMonitoringAssessmentStatus;
  assessedBy: PMAAssessedByTeacher;
  completedUnits: CompletedPMAUnit[];
}

async function getPMASubmissionDetails(assessmentId: string) {
  const { data } = await axios.get<GetPMADetailsResponse>(`${config.apiUrl}/admin/progress-monitoring-assessment/${assessmentId}/details`);
  return data;
}

export function useGetPMASubmissionDetails(assessmentId: string, options?: Omit<UseQueryOptions<GetPMADetailsResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetStudentProgressMonitoringAssessmentDetails, assessmentId], () => getPMASubmissionDetails(assessmentId), options);
}
