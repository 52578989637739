import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { TIME_FORMAT } from '@hoot/common/constants';
import { ONE_MINUTE_PX_HEIGHT } from './constants';
import { ZIndexLayer } from './enums';
import { convertTimeToOffset } from './utils';

interface AvailabilityExceptionProps {
  startsAt: string;
  endsAt: string;
  yAxisLabelsSliced: string[];
}

function AvailabilityException(props: AvailabilityExceptionProps) {
  const startTime = DateTime.fromFormat(props.startsAt, TIME_FORMAT);
  const endsAt = DateTime.fromFormat(props.endsAt, TIME_FORMAT);

  const top = Math.max(convertTimeToOffset(startTime.toFormat(TIME_FORMAT), props.yAxisLabelsSliced), 0);
  const height = convertTimeToOffset(endsAt.toFormat(TIME_FORMAT), props.yAxisLabelsSliced) - top;

  return (
    <Stack
      sx={{
        top: `${top * ONE_MINUTE_PX_HEIGHT}px`,
        height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
        width: '100%',
        paddingX: '4px',
        position: 'absolute',
        zIndex: ZIndexLayer.Bottom,
      }}
    >
      <Stack
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: '#FCDAD3',
          borderRadius: '8px',
        }}
      />
    </Stack>
  );
}

export default AvailabilityException;
