import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { FormativeAssessmentType } from '@hoot/pages/users/students/lesson-reviews/enum';
import { QueryKey } from '../../../queryKeys';

export interface FormativeAssessment {
  id: string;
  title: string;
  score: string;
  formativeAssessmentType: FormativeAssessmentType;
}

export enum ResourceReader {
  Student = 'STUDENT',
  Teacher = 'TEACHER',
  MostlyStudent = 'MOSTLY_STUDENT',
  MostlyTeacher = 'MOSTLY_TEACHER',
}

interface FocusBook {
  title: string;
  readingLevel: string;
  isFinished: boolean;
  reader?: ResourceReader;
}

interface LessonFocus {
  focus: string;
  focusBooks: FocusBook[];
}

interface LessonPlanBook {
  id: string;
  title: string;
  resourceProgress: string;
  resourceType: string;
  instructionalFocus: string;
  instructionalUnit: string;
}

export interface LessonReviewEngagementExternalFactors {
  classroomOrSurroundingsWereLoud: boolean;
  peopleInSurroundingsDistracting: boolean;
  audioOrVideoIssues: boolean;
}

export type LessonReviewsDetailsResponse = {
  lessonReviewDate: string;
  lessonDate: string;
  studentPrefixedNumber: string;
  studentName: string;
  lessonType: string;
  lessonNumber: string;
  lessonId: string;
  subject: string;
  formativeAssessment: string;
  studentEngagement: string;
  studentEngagementExternalFactors: LessonReviewEngagementExternalFactors | undefined;
  teacherPrefixedNumber: string;
  teacherName: string;
  teacherAccountId: string;
  notes: string;
  formativeAssessments?: FormativeAssessment[];
  primaryFocus: LessonFocus;
  secondaryFocus?: LessonFocus;
  lessonPlan: LessonPlanBook[];
  version: number;
};

const getLessonReviewDetails = async (lessonReviewId: string) => {
  const { data } = await axios.get<LessonReviewsDetailsResponse>(`${config.apiUrl}/admin/lesson-reviews/${lessonReviewId}/details`);
  return data;
};

export default function useGetLessonReviewDetails(
  lessonReviewId: string,
  options?: Omit<UseQueryOptions<LessonReviewsDetailsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetLessonReviewDetails, lessonReviewId], () => getLessonReviewDetails(lessonReviewId), options);
}
