import { Box, Button, Card, CardHeader, LinearProgress, Paper, Stack, TableContainer, Typography } from '@mui/material';
import { useState } from 'react';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/components/ui/ViewStateIllustration';
import useCsvProjects from '@hoot/hooks/api/teacher-invoice/useGetCsvProjects';
import { InvoicePeriodDetailsResponse } from '@hoot/hooks/api/teacher-invoice/useSearchTeacherInvoiceBillableTasks';
import useSearchTeacherInvoiceUploadedBillableTasks, {
  SearchTeacherInvoiceUploadedTasksQuery,
  TeacherInvoiceUploadedTasksResponse,
} from '@hoot/hooks/api/teacher-invoice/useSearchTeacherInvoiceUploadedBillableTasks';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import TeacherInvoiceUploadedTasksFilters from '@hoot/pages/teacher-invoices/TeacherInvoiceUploadedTasksFilters';
import TeacherInvoiceUploadedTasksSearchInput from '@hoot/pages/teacher-invoices/TeacherInvoiceUploadedTasksSearchInput';
import TeacherInvoiceUploadedTasksTable from '@hoot/pages/teacher-invoices/TeacherInvoiceUploadedTasksTable';
import { InvoicePeriodStatus } from '@hoot/pages/teacher-invoices/enums/enums';
import InvoiceImportDialog from '@hoot/pages/teacher-invoices/modals/invoicing-import/InvoiceImportDialog';

interface Props {
  periodId: string;
  periodStatus: InvoicePeriodStatus | undefined;
}

const TeacherInvoiceBillableTasksUploadCard = (props: Props) => {
  const { periodId, periodStatus } = props;
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);

  const [teacherInvoiceUploadedTasksQuery, setTeacherInvoiceUploadedTasksQuery] = useState<SearchTeacherInvoiceUploadedTasksQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [teacherInvoiceUploadTasksPaginatedResponse, setTeacherInvoiceUploadTasksPaginatedResponse] = useState<
    GenericPaginatedResponse<TeacherInvoiceUploadedTasksResponse, InvoicePeriodDetailsResponse>
  >({
    data: [],
    count: 0,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const isPeriodOpen = periodStatus === InvoicePeriodStatus.InProgress;

  const { data: projects } = useCsvProjects();
  const { isLoading } = useSearchTeacherInvoiceUploadedBillableTasks(periodId, teacherInvoiceUploadedTasksQuery, {
    onSuccess: (res) => setTeacherInvoiceUploadTasksPaginatedResponse(res),
  });

  return (
    <Card sx={{ mt: 3, p: 3 }}>
      <CardHeader
        sx={{ p: 0, pb: 3 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="titleLarge">
              Uploaded Tasks
            </Typography>
            <Button type="button" color="primary" variant="contained" size="large" disabled={!isPeriodOpen} onClick={() => setOpenImportModal(true)}>
              Upload
            </Button>
          </Stack>
        }
      />
      <>
        <TeacherInvoiceUploadedTasksSearchInput query={teacherInvoiceUploadedTasksQuery} setQuery={setTeacherInvoiceUploadedTasksQuery} />
        {projects ? (
          <TeacherInvoiceUploadedTasksFilters
            sx={{ mt: 3 }}
            projects={projects}
            query={teacherInvoiceUploadedTasksQuery}
            setQuery={setTeacherInvoiceUploadedTasksQuery}
          />
        ) : null}
        <Box sx={{ width: '100%', mt: 6.5, height: '4px' }}>{isLoading && <LinearProgress />}</Box>
        {teacherInvoiceUploadTasksPaginatedResponse.data.length > 0 ? (
          <TableContainer component={Paper}>
            <TeacherInvoiceUploadedTasksTable
              query={teacherInvoiceUploadedTasksQuery}
              setQuery={setTeacherInvoiceUploadedTasksQuery}
              teacherInvoiceUploadedTasksPaginatedResponse={teacherInvoiceUploadTasksPaginatedResponse}
            />
          </TableContainer>
        ) : (
          <ViewStateIllustration illustration={IllustrationEnum.EmptyInbox} title="No Tasks" subtitle="Uploaded Tasks will appear here" />
        )}
      </>

      {openImportModal && periodId ? (
        <InvoiceImportDialog isOpen={openImportModal} close={() => setOpenImportModal(false)} periodId={periodId} />
      ) : null}
    </Card>
  );
};

export default TeacherInvoiceBillableTasksUploadCard;
