import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import useGetSchoolsForDistrict from '@hoot/hooks/api/hfs/useGetSchools';
import { EnrolmentLocation } from '@hoot/hooks/api/hfs/useSearchLocationEnrolments';
import FilterDropDown from '../../FilterDropDown';

export function EnrolmentSchoolFilterDropDown(props: {
  value: EnrolmentLocation[];
  onChange: (val: EnrolmentLocation[]) => void;
  locationId: string;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { value, onChange, locationId, popOverButtonSx } = props;

  const { data } = useGetSchoolsForDistrict(locationId, { enabled: locationId !== '' });

  if (!data || data.schools.length === 0) {
    return null;
  }

  const handleOnChange = (val: string[]) => {
    const schools = data.schools.filter((s) => val.includes(s.id));
    onChange(
      schools.map((s): EnrolmentLocation => {
        return { id: s.id, name: s.name, type: LocationType.School };
      }),
    );
  };

  return (
    <FilterDropDown<string>
      title="School"
      dismissPopoverOnSelection={true}
      value={value.map((school) => school.id)}
      options={data.schools.map((s) => ({
        label: s.name,
        value: s.id,
      }))}
      onChange={handleOnChange}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveEnrolmentSchoolFilterDropDown(props: { value?: EnrolmentLocation[]; onChange: (val: EnrolmentLocation[]) => void }) {
  const { value = [], onChange } = props;

  const handleDelete = (val: string) => () => {
    const updated = value.filter((v) => v.id !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip key={`chip-status-${c}`} label={`School: ${c.name}`} variant="outlined" onDelete={handleDelete(c.id)} deleteIcon={<Close />} />
      ))}
    </>
  );
}
