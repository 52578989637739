import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { Box, CircularProgress, Grid, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useGetDistrictSchool, { LocationEdlinkDataResponse } from '@hoot/hooks/api/hfs/useGetDistrictSchool';
import LocationStudents from '@hoot/pages/district-schools/students/LocationStudents';
import { enumToTitleCase } from '@hoot/utils/stringUtils';
import DistrictsAndSchoolsHeader from './DistrictsAndSchoolsHeader';
import DistrictSchoolDetails from './details/DistrictSchoolDetails';
import ProductsAndEnrolmentsPage from './products-and-enrolments/ProductsAndEnrolmentsPage';
import LocationSummary from './summary/LocationSummary';

export enum LocationTabs {
  Summary = 'SUMMARY',
  Details = 'DETAILS',
  ProductsAndEnrolments = 'PRODUCTS_AND_ENROLMENTS',
  Students = 'STUDENTS',
}

interface Props {
  tab: LocationTabs;
}

export function getDistrictSchoolLabel(districtSchoolType: LocationType) {
  if (districtSchoolType === LocationType.School) {
    return 'School';
  } else if (districtSchoolType === LocationType.District) {
    return 'District';
  }
  return '';
}

const LocationPage = (props: Props) => {
  const { tab } = props;
  const formRef = useRef<HTMLFormElement>(null);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const { locationId } = useParams() as { locationId: string };
  const [searchParams] = useSearchParams();
  const locationTypeParam = searchParams.get('locationType') as LocationType | null;
  const isEditMode = !!locationId;
  const initialLocationName = isEditMode ? '' : `New ${locationTypeParam ? enumToTitleCase(locationTypeParam) : ''}`;
  const [locationName, setLocationName] = useState<string>(initialLocationName);
  const [locationType, setLocationType] = useState<LocationType | undefined>(locationTypeParam ?? undefined);
  const [edlinkData, setEdlinkDate] = useState<LocationEdlinkDataResponse | null>(null);

  const { data, isLoading, isError } = useGetDistrictSchool(locationId, {
    enabled: isEditMode,
    onSuccess: (data) => {
      setLocationName(data.name);
      setLocationType(data.type);
      setEdlinkDate(data.edlinkData);
    },
  });

  if (isEditMode && (isLoading || !data)) {
    return <CircularProgress />;
  } else if (isEditMode && (isError || (!isLoading && !data) || !locationId)) {
    return <Stack alignItems="center">Failed to load the page!</Stack>;
  }

  return (
    <Grid container direction="column">
      <DistrictsAndSchoolsHeader
        isEditMode={isEditMode}
        isDirty={isFormDirty}
        name={locationName}
        friendlyId={data?.friendlyId}
        locationType={locationType}
        edlinkData={edlinkData}
        formRef={formRef}
        isBillableEntity={data?.isBillableEntity}
      />
      <Box mt={3} />
      {tab === LocationTabs.Details ? (
        <DistrictSchoolDetails
          isEditMode={isEditMode}
          locationId={locationId}
          locationData={data}
          formRef={formRef}
          setIsFormDirty={setIsFormDirty}
          locationType={locationType ?? LocationType.District}
        />
      ) : null}
      {tab === LocationTabs.ProductsAndEnrolments && data && isEditMode ? <ProductsAndEnrolmentsPage location={data} /> : null}
      {tab === LocationTabs.Students && isEditMode ? <LocationStudents isLocationConnectedToEdlink={!!data?.edlinkData} /> : null}
      {tab === LocationTabs.Summary ? <LocationSummary locationType={locationType ?? LocationType.District} /> : null}
    </Grid>
  );
};

export default LocationPage;
