import { Box, Card, LinearProgress, Paper } from '@mui/material';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import QueryableTablePagination from '@hoot/components/QueryableTablePagination';
import HeaderCard from '@hoot/components/ui/HeaderCard';
import { useAlert } from '@hoot/contexts/AlertContext';
import useSearchTeacherInvoicePeriods, {
  SearchTeacherInvoicePeriodsQuery,
  TeacherInvoicePeriodsQuerySortKeyEnum,
  TeacherInvoicePeriodsResponse,
} from '@hoot/hooks/api/teacher-invoice/useSearchTeacherInvoicePeriods';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { SortOrder } from '@hoot/interfaces/order-by';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import TeacherInvoicePeriodsFilters from '@hoot/pages/teacher-invoices/TeacherInvoicePeriodsFilters';
import TeacherInvoicePeriodsTable from '@hoot/pages/teacher-invoices/TeacherInvoicePeriodsTable';
import { TeacherInvoicePeriodsPageQueryParams } from '@hoot/pages/teacher-invoices/interfaces/TeacherInvoicePeriodsPageQueryFilterParams';
import { removeUndefinedProperties } from '@hoot/utils/remove-undefined-properties';

const TeacherInvoicePeriodsPage = () => {
  usePageTitle('Teacher Invoicing');
  const [searchParams] = useSearchParams();
  const { error } = useAlert();

  const [teacherInvoicePeriodsQuery, setTeacherInvoicePeriodsQuery] = useState<SearchTeacherInvoicePeriodsQuery>({
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
  });

  const [teacherInvoicePeriodsPaginatedResponse, setTeacherInvoicePeriodsPaginatedResponse] = useState<
    GenericPaginatedResponse<TeacherInvoicePeriodsResponse>
  >({
    data: [],
    count: 0,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { refetch, isFetching } = useSearchTeacherInvoicePeriods(teacherInvoicePeriodsQuery, {
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setTeacherInvoicePeriodsPaginatedResponse({ ...data });
    },
    onError: (err) => {
      console.error(err);
      error(`An error occurred while loading invoicing periods`);
    },
  });

  useEffect(() => {
    // Updating the query will trigger a re-fetch.
    setTeacherInvoicePeriodsQuery(() => {
      const monthFilters = searchParams.getAll(TeacherInvoicePeriodsPageQueryParams.Month) as string[];
      const yearFilters = searchParams.getAll(TeacherInvoicePeriodsPageQueryParams.Year) as string[];

      const page = parseInt(searchParams.get(TeacherInvoicePeriodsPageQueryParams.Page) ?? '1') ?? 1;
      const pageSize = parseInt(searchParams.get(TeacherInvoicePeriodsPageQueryParams.PageSize) ?? `${DEFAULT_PAGE_SIZE}`) ?? DEFAULT_PAGE_SIZE;

      const orderBy = (searchParams.get(TeacherInvoicePeriodsPageQueryParams.OrderBy) as TeacherInvoicePeriodsQuerySortKeyEnum | null) ?? undefined;
      const sortDirection = (searchParams.get(TeacherInvoicePeriodsPageQueryParams.SortDirection) as SortOrder | null) ?? undefined;

      const updatedQuery: SearchTeacherInvoicePeriodsQuery = {
        month: monthFilters.length ? monthFilters : undefined,
        year: yearFilters.length ? yearFilters : undefined,
        page,
        pageSize,
        orderBy,
        sortDirection,
      };
      return removeUndefinedProperties(updatedQuery) as SearchTeacherInvoicePeriodsQuery;
    });
  }, [searchParams]);

  useEffect(() => {
    refetch();
  }, [teacherInvoicePeriodsQuery, refetch]);

  return (
    <>
      <HeaderCard title="Teacher Invoicing" />
      <Card sx={{ mt: 3, p: 3 }}>
        <TeacherInvoicePeriodsFilters sx={{ mt: 3 }} />
        <Box sx={{ width: '100%', mt: 6.5, height: '4px' }}>{isFetching && <LinearProgress />}</Box>
        <TableContainer component={Paper}>
          <TeacherInvoicePeriodsTable
            query={teacherInvoicePeriodsQuery}
            teacherInvoicePeriodsPaginatedResponse={teacherInvoicePeriodsPaginatedResponse}
          />
          <QueryableTablePagination paginatedResponse={teacherInvoicePeriodsPaginatedResponse} />
        </TableContainer>
      </Card>
    </>
  );
};

export default TeacherInvoicePeriodsPage;
