import { Autocomplete, TextField } from '@mui/material';
import { usePlaces } from '../../hooks/usePlaces';

interface AutocompleteOption {
  id: string;
  label: string;
}

export function ProvinceStateSelect(props: {
  countryCode: string;
  disabled?: boolean;
  errorMessage?: string;
  onChange?: (provinceState: string) => void;
  value?: string;
}) {
  const { countryCode, disabled, errorMessage, onChange, value } = props;

  const { provinceStateOptions } = usePlaces(countryCode);

  const options = provinceStateOptions
    .sort((a, b) => a.label.localeCompare(b.label))
    .map<AutocompleteOption>((p) => ({ label: p.label, id: p.value }));

  const handleChange = (_event: React.SyntheticEvent, selectedOption: AutocompleteOption) => {
    if (onChange) {
      onChange(selectedOption.id);
    }
  };

  if (!options || options.length === 0) {
    return null;
  }

  const selectedOption = value ? options.find((o) => o.id === value) : { id: '', label: '' };

  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option) => option.label}
      options={options}
      value={selectedOption}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Province/State" error={!!errorMessage} fullWidth helperText={errorMessage} />
      )}
      fullWidth
    />
  );
}
