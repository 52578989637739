import { useMemo } from 'react';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { subRequiredList } from '@hoot/pages/lessons/enums';
import { FormSelect, FormSelectOption, SelectProps } from './FormSelect';

export function SubRequiredSelect<T extends FieldValues, TObj extends Path<T>>(props: SelectProps & UseControllerProps<T, TObj>) {
  const options = useMemo<FormSelectOption[]>(() => {
    return subRequiredList.map((item) => {
      return {
        label: item.label,
        value: item.value,
      };
    });
  }, []);

  return <FormSelect label="Sub Required" labelId="sub-required-label" options={options} {...props} />;
}
