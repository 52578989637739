import { SearchStudentsQuerySortKeyEnum } from '@hoot-reading/hoot-core/dist/enums/user/search-student-query-sort-key.enum';
import { Checkbox, Stack, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import StatusChip from '@hoot/components/ui/StatusChip';
import { SearchStudentResponse, SearchStudentsQuery } from '@hoot/hooks/api/end-users/useSearchStudentsV2';
import { SortOrder, TableHeaderCell, muiTableSortDirectionLookup } from '@hoot/interfaces/order-by';
import { GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import { studentProfileStatusType } from '@hoot/interfaces/profile';
import { studentProfileStatusChipLookup } from '@hoot/pages/district-schools/types';

export interface SelectStudentTableProps {
  query: SearchStudentsQuery;
  results: GenericPaginatedResponse<SearchStudentResponse>;
  onOrderByChanged: (orderByColumn: SearchStudentsQuerySortKeyEnum, sortOrder: SortOrder) => void;
  selectedStudent: SearchStudentResponse | undefined;
  onStudentClicked: (student: SearchStudentResponse) => void;
  hideColumns?: SearchStudentsQuerySortKeyEnum[];
}

const tableHeaderCells: TableHeaderCell<SearchStudentsQuerySortKeyEnum>[] = [
  { label: 'Name', sortKey: SearchStudentsQuerySortKeyEnum.Name },
  { label: 'Student Number', sortKey: SearchStudentsQuerySortKeyEnum.StudentNumber },
  { label: 'Parent', sortKey: SearchStudentsQuerySortKeyEnum.Parent },
  { label: 'Parent Email', sortKey: SearchStudentsQuerySortKeyEnum.ParentEmail },
  { label: 'Profile Status', sortKey: SearchStudentsQuerySortKeyEnum.ProfileStatus },
];

const SelectStudentTable = (props: SelectStudentTableProps) => {
  const { query, results, onOrderByChanged, selectedStudent, onStudentClicked, hideColumns } = props;
  const { orderBy, sortDirection } = query;

  // If we've toggling the same order-by key, then just flip the sort direction.
  // Else, we're sorting by a different column, and we can default the sort to 'asc'.
  const toggleOrderBy = (newOrderByColumnName: string) => () => {
    const newSortDirection = newOrderByColumnName === orderBy && sortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;

    onOrderByChanged(newOrderByColumnName as SearchStudentsQuerySortKeyEnum, newSortDirection);
  };

  const _onStudentClicked = (student: SearchStudentResponse) => () => {
    onStudentClicked(student);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {/* Placeholder for checkbox/radio column in table. */}
            <TableCell />
            {tableHeaderCells.map((cell) =>
              hideColumns?.find((hc) => hc === cell.sortKey) ? null : (
                <TableCell
                  variant="head"
                  sx={{ width: cell.cellWidth }}
                  key={`headerCell-${cell.label}`}
                  sortDirection={
                    cell.sortKey ? (orderBy === cell.sortKey ? muiTableSortDirectionLookup[sortDirection ?? SortOrder.ASC] : false) : undefined
                  }
                >
                  {cell.sortKey ? (
                    <TableSortLabel
                      active={orderBy === cell.sortKey}
                      direction={orderBy === cell.sortKey ? muiTableSortDirectionLookup[sortDirection ?? SortOrder.ASC] : undefined}
                      onClick={toggleOrderBy(cell.sortKey)}
                    >
                      {cell.label}
                    </TableSortLabel>
                  ) : (
                    cell.label
                  )}
                </TableCell>
              ),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {results.data.length > 0 ? (
            results.data.map((row) => (
              <TableRow
                key={row.id}
                hover
                sx={{
                  cursor: 'pointer',
                }}
                onClick={_onStudentClicked(row)}
              >
                <TableCell>
                  <Checkbox checked={selectedStudent?.id === row.id} />
                </TableCell>
                {hideColumns?.find((hc) => hc === SearchStudentsQuerySortKeyEnum.Name) ? null : <TableCell>{row.name}</TableCell>}
                {hideColumns?.find((hc) => hc === SearchStudentsQuerySortKeyEnum.StudentNumber) ? null : (
                  <TableCell>{row.studentPrefixedNumber}</TableCell>
                )}
                {hideColumns?.find((hc) => hc === SearchStudentsQuerySortKeyEnum.Parent) ? null : <TableCell>{row.parent ?? '-'}</TableCell>}
                {hideColumns?.find((hc) => hc === SearchStudentsQuerySortKeyEnum.ParentEmail) ? null : (
                  <TableCell>{row.parentEmail ?? '-'}</TableCell>
                )}
                {hideColumns?.find((hc) => hc === SearchStudentsQuerySortKeyEnum.ProfileStatus) ? null : (
                  <TableCell>
                    <StatusChip
                      size="small"
                      statusIcon={studentProfileStatusChipLookup[row.profileStatus].statusIcon}
                      iconColor={studentProfileStatusChipLookup[row.profileStatus].iconColor}
                      label={studentProfileStatusType[row.profileStatus] ?? '-'}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableCell colSpan={5}>
              <EmptyState />
            </TableCell>
          )}
        </TableBody>
      </Table>
    </>
  );
};

const EmptyState = () => {
  return (
    <Stack alignItems="center" padding={4}>
      <Typography variant="titleSmall">No results found</Typography>
      <Typography variant="bodySmall">Try adjusting your search to find what you are looking for</Typography>
    </Stack>
  );
};

export default SelectStudentTable;
