import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { Box, Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useGetEnrolments, { EnrolmentQuery } from '../hooks/api/hfs/useGetEnrolments';
import { StyledTableCell } from '../styles/Table';
import { SortOrder } from './hfs-enums';
import { IEnrolmentCheckBoxRow } from './hfs-interfaces';
import { enrolmentStatusMapping, getEnrolmentStatusKeyFromValue } from './hfs-utils';
import SortIcon from './icons/SortIcon';
import EnrolmentStatusIcon from './ui/enrolments/EnrolmentStatusIcon';

const EnrolmentCheckboxTable = (props: {
  activeFilters: string[];
  searchText: string;
  selectedRows: IEnrolmentCheckBoxRow[];
  setSelectedRows: React.Dispatch<React.SetStateAction<IEnrolmentCheckBoxRow[]>>;
  districtRepAccountId?: string;
  studentProfileId?: string;
  existingEnrolmentIds?: string[];
  locationId?: string;
}) => {
  const { activeFilters, searchText, selectedRows, setSelectedRows, districtRepAccountId, studentProfileId, existingEnrolmentIds, locationId } =
    props;

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortDirection, setSortDirection] = useState<SortOrder>(SortOrder.ASC);
  const [sortColumn, setSortColumn] = useState<keyof IEnrolmentCheckBoxRow>('locationName');
  const [enrolmentQuery, setEnrolmentQuery] = useState<EnrolmentQuery>({
    pageSize: rowsPerPage,
    page: page,
    districtRepresentativeAccountId: districtRepAccountId,
    studentProfileId: studentProfileId,
    locationId: locationId,
  });
  const { data: enrolmentResponseData } = useGetEnrolments(enrolmentQuery);

  const [sortedRows, setSortedRows] = useState(
    enrolmentResponseData?.enrolments?.map((enrolment) => {
      const row: IEnrolmentCheckBoxRow = {
        id: enrolment.enrolmentId,
        friendlyId: enrolment.friendlyId,
        locationName: enrolment.locationName,
        locationId: enrolment.locationId,
        status: enrolment.enrolmentStatus,
      };
      return row;
    }) ?? [],
  );

  useEffect(() => {
    setPage(1);
    setEnrolmentQuery((current) => ({ ...current, searchString: searchText, page: 1 }));
  }, [searchText]);

  useEffect(() => {
    const enrolmentStatuses = activeFilters
      .map((v) => v.split(':')[1].trim())
      .map((value) => getEnrolmentStatusKeyFromValue(value))
      .filter((key) => key !== undefined) as EnrolmentStatus[];

    setPage(1);
    setEnrolmentQuery((current) => ({
      ...current,
      status: enrolmentStatuses.length > 0 ? enrolmentStatuses : undefined,
      page: 1,
    }));
  }, [activeFilters]);

  useEffect(() => {
    setSortedRows(
      enrolmentResponseData?.enrolments?.map((enrolment) => {
        const row: IEnrolmentCheckBoxRow = {
          id: enrolment.enrolmentId,
          friendlyId: enrolment.friendlyId,
          locationName: enrolment.locationName,
          locationId: enrolment.locationId,
          status: enrolment.enrolmentStatus,
        };
        return row;
      }) ?? [],
    );
  }, [enrolmentResponseData]);

  const handleRowClick = (row: IEnrolmentCheckBoxRow) => {
    if (existingEnrolmentIds?.includes(row.id)) {
      return;
    }

    const selectedIndex = selectedRows.indexOf(row);
    let newSelectedRows: IEnrolmentCheckBoxRow[] = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, row);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, selectedIndex), selectedRows.slice(selectedIndex + 1));
    }

    setSelectedRows(newSelectedRows);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage + 1);
    setEnrolmentQuery((current) => ({ ...current, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    setEnrolmentQuery((current) => ({ ...current, pageSize: parseInt(event.target.value, 10), page: 1 }));
  };

  const sortRows = () => {
    return [...sortedRows].sort((a, b) => {
      if (a[sortColumn] === null) return 1;
      if (b[sortColumn] === null) return -1;
      if (a[sortColumn] === null && b[sortColumn] === null) return 0;
      const aString = a[sortColumn] !== undefined ? a[sortColumn].toString() : '';
      const bString = b[sortColumn] !== undefined ? b[sortColumn].toString() : '';
      return (
        aString.localeCompare(bString, 'en', {
          numeric: true,
        }) * (sortDirection === SortOrder.ASC ? 1 : -1)
      );
    });
  };
  const handleSort = (column: keyof IEnrolmentCheckBoxRow) => {
    const isAsc = sortColumn === column && sortDirection === SortOrder.ASC;
    setSortDirection(isAsc ? SortOrder.DESC : SortOrder.ASC);
    setSortColumn(column);
    setSortedRows(sortRows());
  };

  const noEnrolments = enrolmentResponseData?.count === 0 && sortedRows.length === 0;

  const renderEmptyState = (): JSX.Element => (
    <TableRow>
      <TableCell />
      <TableCell />
      <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography>No enrolments found</Typography>
      </TableCell>
      <TableCell />
    </TableRow>
  );

  return (
    <Box sx={{ overflowY: 'auto' }}>
      <Table stickyHeader aria-label="enrolments table">
        <TableHead>
          <TableRow>
            <StyledTableCell padding="checkbox" />
            <StyledTableCell align="left" onClick={() => handleSort('id')}>
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                <span>ID</span>
                {sortColumn === 'id' ? <SortIcon /> : null}
              </Box>
            </StyledTableCell>
            <StyledTableCell onClick={() => handleSort('locationName')} align="left">
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                <span>Name</span>
                {sortColumn === 'locationName' ? <SortIcon /> : null}
              </Box>
            </StyledTableCell>
            <StyledTableCell align="left" onClick={() => handleSort('status')}>
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                Status
                {sortColumn === 'status' ? <SortIcon /> : null}
              </Box>
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {noEnrolments && renderEmptyState()}

          {sortedRows.map((row) => {
            const isRowDisabled = existingEnrolmentIds?.includes(row.id);
            const isRowSelected = selectedRows.indexOf(row) !== -1 || isRowDisabled;

            return (
              <TableRow
                hover
                onClick={() => handleRowClick(row)}
                role="checkbox"
                aria-checked={isRowSelected}
                tabIndex={-1}
                key={row.id}
                selected={isRowSelected}
                sx={{ '&:hover': { cursor: 'pointer' } }}
              >
                <TableCell padding="checkbox">
                  <Checkbox checked={isRowSelected} disabled={isRowDisabled} />
                </TableCell>
                <TableCell align="left">{row.friendlyId}</TableCell>
                <TableCell scope="row" align="left">
                  {row.locationName}
                </TableCell>
                <TableCell align="left">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyItems: 'center',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <Box>{EnrolmentStatusIcon(row.status)}</Box>
                    <Box> {enrolmentStatusMapping[row.status]}</Box>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={enrolmentResponseData?.count ?? 0}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={10}
        rowsPerPageOptions={[{ label: '10', value: 10 }]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default EnrolmentCheckboxTable;
