import { TeacherStage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-stage.enum';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import { Card, CardContent, CardHeader, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Control, FormState, UseFormSetValue } from 'react-hook-form';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { Dropdown } from '@hoot/components/form/Dropdown';
import SwitchField from '@hoot/components/form/SwitchField';
import { TeacherAccount } from '@hoot/hooks/api/user/teacher/useGetTeacherAccount';
import { teacherStatusOptions, teachingStageOptions } from '@hoot/interfaces/teacher';
import { TeacherAccountAndUserForm } from '@hoot/pages/users/interfaces/form';

interface Props {
  control: Control<TeacherAccountAndUserForm>;
  watch: UseFormWatch<TeacherAccountAndUserForm>;
  setValue: UseFormSetValue<TeacherAccountAndUserForm>;
  formState: FormState<TeacherAccountAndUserForm>;
  isEnabledUpdateAt?: number | null;
  teacherStatusUpdatedAt?: number | null;
  isEditMode: boolean;
  teacherAccount?: TeacherAccount;
}

const TeacherStatusCard = (props: Props) => {
  const { control, watch, setValue, isEnabledUpdateAt, teacherStatusUpdatedAt, isEditMode, teacherAccount, formState } = props;
  const { goLiveDate, agreementEndDate, userAccountStatus, teacherStatus } = watch();
  const dirtyFields = Object.keys(formState.dirtyFields);
  const isAgreementEndDateDirty = dirtyFields.includes('agreementEndDate');
  const isGoLiveDateDirty = dirtyFields.includes('goLiveDate');

  const optionsForTeacherStatus = teacherStatusOptions.filter((x) => {
    if (teacherAccount?.teacherStatus === TeacherStatus.Inactive) {
      return [TeacherStatus.Candidate, TeacherStatus.Inactive].includes(x.value);
    }
    return true;
  });

  const optionsForTeachingStatus = teachingStageOptions.filter((o) => {
    if (teacherStatus === TeacherStatus.Candidate) {
      return [TeacherStage.Interviewing, TeacherStage.OfferPending, TeacherStage.NotHired].includes(o.value);
    } else if (teacherStatus === TeacherStatus.Active) {
      return [TeacherStage.Onboarding, TeacherStage.Live, TeacherStage.OnLeave, TeacherStage.PreDeparture].includes(o.value);
    } else {
      return o;
    }
  });

  useEffect(() => {
    const todayString = DateTime.now().toISODate();
    const isResigningToday = agreementEndDate === todayString && isAgreementEndDateDirty;
    const isGoingLiveToday = goLiveDate === todayString && isGoLiveDateDirty;
    if (isResigningToday) {
      setValue('teacherStatus', TeacherStatus.Inactive, { shouldDirty: teacherAccount?.teacherStatus !== TeacherStatus.Inactive });
      return;
    }

    if (isGoingLiveToday) {
      setValue('teacherStatus', TeacherStatus.Active, { shouldDirty: teacherAccount?.teacherStatus !== TeacherStatus.Active });
      return;
    }

    if (teacherAccount?.teacherStatus && !isResigningToday && !isGoingLiveToday) {
      setValue('teacherStatus', teacherAccount.teacherStatus, { shouldDirty: false });
    }
  }, [goLiveDate, agreementEndDate, teacherAccount?.teacherStatus, setValue, isAgreementEndDateDirty, isGoLiveDateDirty]);

  const handleTeacherStatusChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newStatus = event.target.value as TeacherStatus;
    if (newStatus === TeacherStatus.Candidate) {
      setValue('teacherStage', TeacherStage.Interviewing, { shouldDirty: true });
    } else if (newStatus === TeacherStatus.Active) {
      setValue('teacherStage', TeacherStage.Onboarding, { shouldDirty: true });
    } else if (newStatus === TeacherStatus.Inactive) {
      setValue('teacherStage', null, { shouldDirty: true });
    }
  };

  return (
    <Grid container item>
      <Card
        sx={{
          width: 1,
        }}
      >
        <CardHeader
          sx={{ p: 3, pb: 0 }}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="titleLarge">Status</Typography>
            </Stack>
          }
        />
        <CardContent sx={{ p: 3 }}>
          <Stack gap={3}>
            <Stack>
              <>
                <Typography variant={'bodySmall'} sx={{ mt: '1px', mb: '12px' }}>
                  User Account Status
                </Typography>
                <SwitchField label={`${userAccountStatus ? 'Enabled' : 'Disabled'}`} control={control} name="userAccountStatus" />
              </>
              {isEnabledUpdateAt && (
                <FormHelperText sx={{ marginTop: '8px', color: '#1C1B1F' }}>
                  Since {DateTime.fromMillis(isEnabledUpdateAt).toFormat('MM/dd/yyyy')}
                </FormHelperText>
              )}
            </Stack>
            <Stack>
              <Dropdown
                name="teacherStatus"
                variant="outlined"
                label="Teaching status*"
                options={optionsForTeacherStatus}
                control={control}
                onChange={handleTeacherStatusChange}
                rules={{ required: true }}
                disabled={!isEditMode || teacherAccount?.teacherStatus !== TeacherStatus.Inactive}
              />
              {teacherStatusUpdatedAt && (
                <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                  Since {DateTime.fromMillis(teacherStatusUpdatedAt).toFormat('MM/dd/yyyy')}
                </FormHelperText>
              )}
            </Stack>
            <Stack>
              {teacherStatus !== TeacherStatus.Inactive ? (
                <Dropdown
                  name="teacherStage"
                  variant="outlined"
                  label="Teaching Stage*"
                  options={optionsForTeachingStatus}
                  control={control}
                  rules={{ required: [TeacherStatus.Candidate, TeacherStatus.Active].includes(teacherStatus) }}
                  disabled={!isEditMode}
                />
              ) : null}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TeacherStatusCard;
