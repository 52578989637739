import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { Box, Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SortOrder } from '@hoot/interfaces/order-by';
import useGetDistrictRepLocation from '../hooks/api/hfs/useGetDistrictRepLocation';
import useGetDistrictSchools, { DistrictSchoolQuery, SearchDistrictSchoolsQuerySortKeyEnum } from '../hooks/api/hfs/useGetDistrictSchools';
import { StyledTableCell } from '../styles/Table';
import { IDistrictSchoolsCheckBoxRow } from './hfs-interfaces';
import {
  districtSchoolMapping,
  getDistrictSchoolTypeKeyFromValue,
  getHootSupportedCountryKeyFromValue,
  hootSupportedCountryMapping,
} from './hfs-utils';
import SortIcon from './icons/SortIcon';

interface ICheckBoxTableProps {
  isStudentMode: boolean;
  locationType?: LocationType;
  activeFilters: string[];
  searchText: string;
  selectedRow: IDistrictSchoolsCheckBoxRow;
  setSelectedRow: React.Dispatch<React.SetStateAction<IDistrictSchoolsCheckBoxRow>>;
  districtRepAccountId?: string;
}

const SchoolsDistrictsCheckBoxTable = (props: ICheckBoxTableProps) => {
  const { isStudentMode, locationType, activeFilters, searchText, selectedRow, setSelectedRow, districtRepAccountId } = props;

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [districtSchoolQuery, setDistrictSchoolQuery] = useState<DistrictSchoolQuery>({
    pageSize: rowsPerPage,
    page: page,
    isLocation: isStudentMode ? undefined : true,
    orderBy: SearchDistrictSchoolsQuerySortKeyEnum.Name,
    sortDirection: SortOrder.ASC,
  });

  const [sortedRows, setSortedRows] = useState<IDistrictSchoolsCheckBoxRow[]>([]);
  const [sortDirection, setSortDirection] = useState<SortOrder>(SortOrder.ASC);
  const [sortColumn, setSortColumn] = useState<SearchDistrictSchoolsQuerySortKeyEnum>(SearchDistrictSchoolsQuerySortKeyEnum.Name);

  const { data: districtSchoolResponseData } = useGetDistrictSchools(districtSchoolQuery);
  const { data: disableRowSelection } = useGetDistrictRepLocation(districtRepAccountId!, { enabled: !!districtRepAccountId });

  useEffect(() => {
    setSortedRows(
      districtSchoolResponseData?.data?.map((districtSchool) => {
        const row: IDistrictSchoolsCheckBoxRow = {
          id: districtSchool.id,
          friendlyId: districtSchool.friendlyId,
          name: districtSchool.name,
          city: districtSchool.city,
          country: districtSchool.country,
          type: districtSchool.type,
        };
        return row;
      }) ?? [],
    );
  }, [districtSchoolResponseData]);

  useEffect(() => {
    setPage(1);
    setDistrictSchoolQuery((current) => ({ ...current, name: searchText, page: 1 }));
  }, [searchText]);

  useEffect(() => {
    const typesFilters =
      isStudentMode && locationType
        ? [locationType]
        : (activeFilters
            .filter((filter) => filter.startsWith('Type'))
            .map((val) => val.split(': ')[1].trim())
            .map((val) => getDistrictSchoolTypeKeyFromValue(val))
            .filter((key) => key !== undefined) as LocationType[]);

    const countryFilters = activeFilters
      .filter((filter) => filter.startsWith('Country'))
      .map((val) => val.split(':')[1].trim())
      .map((val) => getHootSupportedCountryKeyFromValue(val))
      .filter((key) => key !== undefined) as HootSupportedCountry[];

    const updatedQuery = {
      type: typesFilters.length > 0 ? typesFilters : undefined,
      country: countryFilters.length > 0 ? countryFilters : undefined,
    };
    setPage(1);
    setDistrictSchoolQuery((current) => ({
      ...current,
      ...updatedQuery,
      page: 1,
      sortDirection: sortDirection,
      orderBy: sortColumn,
    }));
    setSelectedRow({} as IDistrictSchoolsCheckBoxRow);
  }, [activeFilters, isStudentMode, locationType, setSelectedRow, sortColumn, sortDirection]);

  const handleRowClick = (row: IDistrictSchoolsCheckBoxRow) => {
    if (selectedRow.id === row.id) {
      setSelectedRow({} as IDistrictSchoolsCheckBoxRow);
    } else {
      setSelectedRow(row);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage + 1);
    setDistrictSchoolQuery((current) => ({ ...current, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    setDistrictSchoolQuery((current) => ({ ...current, pageSize: parseInt(event.target.value, 10), page: 1 }));
  };

  const handleSort = (column: SearchDistrictSchoolsQuerySortKeyEnum) => {
    const isAsc = sortColumn === column && sortDirection === SortOrder.ASC;
    setSortDirection(isAsc ? SortOrder.DESC : SortOrder.ASC);
    setSortColumn(column);
    setDistrictSchoolQuery((current) => ({
      ...current,
      sortDirection: isAsc ? SortOrder.DESC : SortOrder.ASC,
      orderBy: column,
    }));
  };

  return (
    <Box sx={{ overflowY: 'auto' }}>
      <Table stickyHeader aria-label="schools districts table">
        <TableHead>
          <TableRow>
            <StyledTableCell padding="checkbox"></StyledTableCell>
            <StyledTableCell onClick={() => handleSort(SearchDistrictSchoolsQuerySortKeyEnum.Name)} align="left">
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                <span>Name</span>
                {sortColumn === 'name' ? <SortIcon /> : null}
              </Box>
            </StyledTableCell>
            <StyledTableCell align="left" onClick={() => handleSort(SearchDistrictSchoolsQuerySortKeyEnum.ID)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                <span> ID</span>
                {sortColumn === 'id' ? <SortIcon /> : null}
              </Box>
            </StyledTableCell>
            <StyledTableCell align="left" onClick={() => handleSort(SearchDistrictSchoolsQuerySortKeyEnum.City)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                <span> City </span>
                {sortColumn === 'city' ? <SortIcon /> : null}
              </Box>
            </StyledTableCell>
            <StyledTableCell align="left" onClick={() => handleSort(SearchDistrictSchoolsQuerySortKeyEnum.Country)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                Country
                {sortColumn === 'country' ? <SortIcon /> : null}
              </Box>
            </StyledTableCell>
            <StyledTableCell align="left" onClick={() => handleSort(SearchDistrictSchoolsQuerySortKeyEnum.Type)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                Type
                {sortColumn === 'type' ? <SortIcon /> : null}
              </Box>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row) => {
            const isRowSelected = selectedRow.id === row.id;

            return (
              <TableRow
                hover
                onClick={() => handleRowClick(row)}
                role="checkbox"
                aria-checked={isRowSelected}
                tabIndex={-1}
                key={row.id}
                selected={isRowSelected}
                style={disableRowSelection?.doesDistrictRepHaveExistingLocation ? { pointerEvents: 'none' } : {}}
                sx={{ '&:hover': { cursor: 'pointer' } }}
              >
                <TableCell padding="checkbox">
                  <Checkbox checked={isRowSelected} />
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.friendlyId}</TableCell>
                <TableCell align="left">{row.city}</TableCell>
                <TableCell align="left">{hootSupportedCountryMapping[row.country]}</TableCell>
                <TableCell align="left">{districtSchoolMapping[row.type]}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={districtSchoolResponseData?.count ?? 0}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={10}
        rowsPerPageOptions={[{ label: '10', value: 10 }]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default SchoolsDistrictsCheckBoxTable;
