import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { FilterAltOutlined, MailOutline, PhoneOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Badge,
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { defaultRowsPerPage } from '@hoot/common/constants';
import SearchTextField, { SearchTextFieldProps } from '@hoot/components/form/SearchTextField';
import { accountDisplay } from '@hoot/components/hfs-utils';
import { HeaderData, Table } from '@hoot/components/ui/Table';
import { useAlert } from '@hoot/contexts/AlertContext';
import useGetAccounts from '@hoot/hooks/api/common/useGetAccounts';
import useGetUsers, { CustomerSearchFieldOptions, CustomerSearchParams, UserAccountResponse, UserQuery } from '@hoot/hooks/api/end-users/useGetUsers';
import useGetAllBillableLocations from '@hoot/hooks/api/hfs/useGetAllBillableLocations';
import useGetAllSchools from '@hoot/hooks/api/hfs/useGetAllSchools';
import { usePlaces } from '@hoot/hooks/usePlaces';
import { FormOption } from '@hoot/interfaces/form';
import { UserAccount } from '@hoot/interfaces/user';
import { routes } from '@hoot/routes/routes';
import { removePrefix } from '@hoot/utils/stringUtils';
import ParentFiltersDrawer from './ParentFiltersDrawer';

const IGNORE_FILTERS = [
  'searchText',
  'page',
  'pageSize',
  'account',
  'accountType',
  'teacherQuery',
  'customerQuery',
  'districtRepQuery',
  'studentQuery',
];

const IGNORE_CUSTOMER_FILTERS = ['searchFieldSelection'];

type QueryKeys = keyof UserQuery;

type CustomerKeys = keyof CustomerSearchParams;

type AllKeys = QueryKeys | CustomerKeys;

const searchFieldOptions: FormOption<CustomerSearchFieldOptions>[] = [
  { value: CustomerSearchFieldOptions.ParentName, label: 'Parent Name' },
  { value: CustomerSearchFieldOptions.ParentNumber, label: 'Parent Number' },
  { value: CustomerSearchFieldOptions.ParentEmail, label: 'Parent Email' },
  { value: CustomerSearchFieldOptions.ParentPhoneNumber, label: 'Parent Phone' },
  { value: CustomerSearchFieldOptions.All, label: 'All Fields' },
];

interface ParentTableRow {
  parentNumber: React.ReactNode;
  account: React.ReactNode;
}

export enum ParentTabs {
  Details = 'DETAILS',
  History = 'HISTORY',
}

const FilterChips = (props: {
  badgesWithMultiSelectors: any[];
  userQuery: UserQuery;
  setUserQuery: React.Dispatch<React.SetStateAction<UserQuery>>;
}) => {
  const { badgesWithMultiSelectors, userQuery, setUserQuery } = props;
  const accounts = useGetAccounts(false);
  const billableLocations = useGetAllBillableLocations();
  const schools = useGetAllSchools();
  const { countryOptionsCanadaFirst } = usePlaces('CA');

  const countryTypes = [...countryOptionsCanadaFirst].reduce(
    (a, b) => ({
      ...a,
      [b.value]: b.label,
    }),
    {},
  ) as { [key: string]: string };

  return (
    <Grid container item sx={{ marginLeft: '24px' }}>
      {badgesWithMultiSelectors?.map((badge) => {
        const [filterType, filterValue]: [AllKeys, any] = badge;
        let key = filterType;
        let value = filterValue;
        if (filterType === 'fromLeadInitiationDate' || filterType === 'toLeadInitiationDate') {
          value = DateTime.fromISO(filterValue).toFormat('LLL dd, yyyy');
        } else if (filterType === 'isEnabled') {
          value = filterValue ? 'Yes' : 'No';
        } else if (filterType === 'account') {
          value = accounts.data?.find((a) => a.id === filterValue)?.name;
        } else if (filterType === 'country') {
          value = countryTypes[filterValue as SpokenLanguage];
        } else if (['studentLocationIds', 'districtRepLocationIds'].includes(filterType)) {
          key = 'District' as AllKeys;
          value = billableLocations.data?.billableLocations?.find((a) => a.id === filterValue)?.name;
        } else if (['studentSchoolIds', 'districtRepSchoolIds'].includes(filterType)) {
          key = 'School' as AllKeys;
          value = schools.data?.schools?.find((a) => a.id === filterValue)?.name;
        } else {
          if (typeof filterValue === 'string') {
            value = capitalCase(filterValue);
          }
        }
        return (
          <Chip
            key={`${key}-${value}`}
            sx={{
              margin: '4px',
            }}
            label={`${capitalCase(key)}: ${value}`}
            onDelete={() => {
              if (Object.keys(userQuery.customerQuery).includes(filterType)) {
                setUserQuery((q: UserQuery) => ({
                  ...q,
                  customerQuery: {
                    ...q.customerQuery,
                    [filterType]: Array.isArray(q.customerQuery[filterType as CustomerKeys])
                      ? (q.customerQuery[filterType as CustomerKeys] as any)?.filter((val: string) => val !== filterValue)
                      : undefined,
                  },
                }));
              } else {
                setUserQuery((q: UserQuery) => ({
                  ...q,
                  [filterType]: Array.isArray(q[filterType as QueryKeys])
                    ? (q[filterType as QueryKeys] as any)?.filter((val: string) => val !== filterValue)
                    : undefined,
                }));
              }
            }}
          />
        );
      })}
    </Grid>
  );
};

export function ParentsTable() {
  return (
    <Stack spacing="16px">
      <HeaderCard />
      <SearchCard />
    </Stack>
  );
}

function HeaderCard() {
  return (
    <Card sx={{ padding: '24px' }}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Typography variant="headlineLarge">Parents</Typography>
        <AddParentAction />
      </Grid>
    </Card>
  );
}

const SearchCard = () => {
  const [searchText, setSearchText] = useState('');
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [userQuery, setUserQuery] = useState<UserQuery>({
    pageSize: 10,
    page: 1,
    ...{ isEnabled: true },
    searchText,
    customerQuery: { searchFieldSelection: CustomerSearchFieldOptions.All },
    teacherQuery: {},
    districtRepQuery: {},
    accountType: UserAccount.Customer,
  });

  // Search for users based on toolbar searchText, resulting data goes to table body as rows of users.
  const { isFetching: isLoadingParentSearch, data: parentResponseData } = useGetUsers({
    ...userQuery,
    searchText: removePrefix(userQuery.searchText),
  });
  const { success } = useAlert();

  const headers: HeaderData<ParentTableRow>[] = [
    { property: 'parentNumber', name: 'Parent #' },
    { property: 'account', name: 'Account' },
  ];

  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    success('Copied to clipboard');
  };

  const PhoneAndMailIcons = (props: { user: UserAccountResponse }) => {
    const { user } = props;
    return (
      <Stack direction="row" justifyContent={'space-between'}>
        <Tooltip title={user?.email ?? ''}>
          <IconButton onClick={handleCopyToClipboard(user.email)}>
            <MailOutline sx={{ cursor: 'pointer' }} />
          </IconButton>
        </Tooltip>

        <Tooltip title={user?.phoneNumber ?? ''}>
          <IconButton onClick={handleCopyToClipboard(user.phoneNumber)}>
            <PhoneOutlined sx={{ cursor: 'pointer' }} />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  const data =
    parentResponseData && parentResponseData.users
      ? parentResponseData.users.map<ParentTableRow>((user) => ({
          parentNumber: (
            <Stack direction="row" justifyContent={'space-between'}>
              <Link to={routes.users.parents.details.url(user.customerAccount!.id)}>
                <Typography variant="bodyMedium" noWrap sx={{ maxWidth: 140 }}>
                  {user?.customerAccount?.number}
                </Typography>
              </Link>
              <PhoneAndMailIcons user={user} />
            </Stack>
          ),
          account: (
            <Tooltip title={user?.accountName ?? ''}>
              <Typography variant="bodyMedium" noWrap>
                {accountDisplay(user.accountName)}
              </Typography>
            </Tooltip>
          ),
        }))
      : [];

  const badges = () => {
    const mainBadges = Object.entries(userQuery).filter(
      ([k, v]) => !IGNORE_FILTERS.includes(k) && v !== undefined && (Array.isArray(v) ? v.length !== 0 : true),
    ) as [QueryKeys, any][];

    const customerBadges = Object.entries(userQuery.customerQuery).filter(
      ([k, v]) => !IGNORE_CUSTOMER_FILTERS.includes(k) && v !== undefined && v !== '' && (Array.isArray(v) ? v.length !== 0 : true),
    ) as [QueryKeys, any][];
    mainBadges.push(...customerBadges);
    return mainBadges;
  };

  const badgesWithMultiSelectors = badges().reduce((acc: any[], badge) => {
    if (Array.isArray(badge[1])) return [...acc, ...badge[1].map((val: any) => [badge[0], val])];
    return [...acc, badge];
  }, []);

  const handlePageChange = (_event: unknown, page: number) => {
    setUserQuery((current) => ({ ...current, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setUserQuery((current) => ({ ...current, pageSize: pageSize }));
  };

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (searchText) => {
    setSearchText(searchText);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (searchText) => {
    setUserQuery((current) => ({ ...current, searchText, page: 1 }));
  };

  const searchFieldOnChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUserQuery((current) => ({
      ...current,
      customerQuery: { ...current.customerQuery, searchFieldSelection: value as CustomerSearchFieldOptions },
    }));
  };

  return (
    <Card sx={{ padding: '24px' }}>
      <Stack sx={{ mb: 3 }}>
        <Typography variant="titleLarge">Parents</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <Box width={'250px'}>
          <FormControl fullWidth variant={'outlined'} size={'medium'}>
            <InputLabel id={'fieldSearchField'}>Search By</InputLabel>
            <Select
              labelId={'fieldSearchField'}
              label={'Search By'}
              value={userQuery.customerQuery.searchFieldSelection as any}
              onChange={searchFieldOnChange}
            >
              {searchFieldOptions.map((x) => (
                <MenuItem key={`fieldSearchField-${x.value}`} value={x.value}>
                  {x.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <SearchTextField
          searchInput={searchText}
          onSearchInputChanged={onSearchInputChanged}
          onSearchInputDebounced={onSearchInputDebounced}
          autoFocus
        />

        <Tooltip title={'Filter Results'}>
          <IconButton size="large" onClick={() => setShowFilters(true)} color="primary">
            <Badge color="primary">
              <FilterAltOutlined sx={{ cursor: 'pointer' }} />
            </Badge>
          </IconButton>
        </Tooltip>
      </Stack>

      <Box sx={{ marginY: '20px' }}>
        <FilterChips badgesWithMultiSelectors={badgesWithMultiSelectors} userQuery={userQuery} setUserQuery={setUserQuery} />
      </Box>

      <Stack sx={{ marginTop: '32px' }}>
        <Table
          isPaginated
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          headers={headers}
          data={data}
          count={parentResponseData?.count || 0}
          page={parentResponseData?.page || 1}
          rowsPerPage={userQuery.pageSize}
          isLoading={isLoadingParentSearch}
        />
      </Stack>

      {showFilters ? (
        <ParentFiltersDrawer showDrawer={showFilters} setShowDrawer={setShowFilters} userQuery={userQuery} setUserQuery={setUserQuery} />
      ) : null}
    </Card>
  );
};

const AddParentAction = () => {
  const navigate = useNavigate();
  return (
    <>
      <Button onClick={() => navigate(routes.users.parents.create.url)} style={{ height: 36 }} variant="contained" color="primary">
        <AddIcon fontSize="small" style={{ marginRight: 5 }} /> Add Parent
      </Button>
    </>
  );
};
export default ParentsTable;
