import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  GetStudentFormativeAssessmentsQuery,
  HootFormativeAssessmentResponse,
  useGetFormativeAssessments,
} from '@hoot/hooks/api/student-hoot-assessments/useGetStudentFormativeAssessments';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import { routes } from '@hoot/routes/routes';
import { HeaderData, Table } from '../../../../components/ui/Table';
import { SortOrder, toggleSortOrder } from '../../../../interfaces/order-by';
import { OrderBy } from '../../../lessons/enums';
import ComprehensionFormativeAssessmentDetailsModal from '../lesson-reviews/details/ComprehensionFormativeAssessmentDetailsModal';
import FluencyFormativeAssessmentDetailsModal from '../lesson-reviews/details/FluencyFormativeAssessmentDetailsModal';
import LettersAndSoundsFormativeAssessmentDetailsModal from '../lesson-reviews/details/LettersAndSoundsFormativeAssessmentDetailsModal';
import WordReadingFormativeAssessmentDetailsModal from '../lesson-reviews/details/WordReadingFormativeAssessmentDetailsModal';
import { FormativeAssessmentType } from '../lesson-reviews/enum';

interface FormativeAssessmentTableRow {
  date: string;
  assessedBy: ReactNode;
  type: string;
  resourceUsed: ReactNode;
  score: string;
}

const headers: HeaderData<FormativeAssessmentTableRow>[] = [
  { name: 'Date', property: 'date', isSortable: true, width: '20%' },
  { name: 'Type', property: 'type', isSortable: false, width: '20%' },
  { name: 'Resource Used', property: 'resourceUsed', isSortable: true, width: '30%' },
  { name: 'Score', property: 'score', width: '10%' },
  { name: 'Assessed By', property: 'assessedBy', isSortable: true, width: '20%' },
];

export enum StudentFormativeAssessmentsQuerySortKeyEnum {
  Date = 'Date',
  AssessedBy = 'AssessedBy',
  ResourceUsed = 'ResourceUsed',
}

const StudentFormativeAssessmentsCard = (props: { studentProfileId: string }) => {
  const [query, setQuery] = useState<GetStudentFormativeAssessmentsQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: StudentFormativeAssessmentsQuerySortKeyEnum.Date,
    sortDirection: SortOrder.DESC,
  });

  const [results, setResults] = useState<GenericPaginatedResponse<FormativeAssessmentTableRow>>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    data: [],
    count: 0,
  });

  const [selectedWordReadingFormativeAssessment, setSelectedWordReadingFormativeAssessment] = useState<HootFormativeAssessmentResponse | undefined>();
  const [selectedFluencyFormativeAssessment, setSelectedFluencyFormativeAssessment] = useState<HootFormativeAssessmentResponse | undefined>();
  const [selectedComprehensionFormativeAssessment, setSelectedComprehensionFormativeAssessment] = useState<
    HootFormativeAssessmentResponse | undefined
  >();
  const [selectedLettersAndSoundsFormativeAssessment, setSelectedLettersAndSoundsFormativeAssessment] = useState<
    HootFormativeAssessmentResponse | undefined
  >();

  useGetFormativeAssessments(props.studentProfileId!, query, {
    enabled: !!props.studentProfileId,
    onSuccess: (response) => {
      setResults({
        ...response,
        data: mapAssessmentResponseToTableRows(response.data),
      });
    },
  });

  const handleFormativeAssessmentClick = (formativeAssessment: HootFormativeAssessmentResponse) => {
    if (formativeAssessment.formativeAssessmentType === FormativeAssessmentType.Fluency) {
      setSelectedFluencyFormativeAssessment(formativeAssessment);
    } else if (formativeAssessment.formativeAssessmentType === FormativeAssessmentType.Comprehension) {
      setSelectedComprehensionFormativeAssessment(formativeAssessment);
    } else if (formativeAssessment.formativeAssessmentType === FormativeAssessmentType.WordReading) {
      setSelectedWordReadingFormativeAssessment(formativeAssessment);
    } else {
      setSelectedLettersAndSoundsFormativeAssessment(formativeAssessment);
    }
  };

  const mapAssessmentResponseToTableRows = (formativeAssessments: HootFormativeAssessmentResponse[]): FormativeAssessmentTableRow[] => {
    return formativeAssessments.map<FormativeAssessmentTableRow>((x) => {
      const tableDateFormatted = DateTime.fromMillis(x.lastUpdatedAt).toFormat('LLLL dd, yyyy');
      return {
        date: tableDateFormatted,
        assessedBy: <Link to={routes.users.teachers.details.url(x.assessedById)}>{x.assessedByPrefixedNumber}</Link>,
        resourceUsed: (
          <Typography
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              '&:hover': {
                color: '#1976D2',
              },
            }}
            variant="bodyMedium"
            onClick={() => handleFormativeAssessmentClick(x)}
          >
            {x.bookTitle}
          </Typography>
        ),
        score: x.score ?? '-',
        type: [FormativeAssessmentType.LettersAndSounds, FormativeAssessmentType.WordReading].includes(x.formativeAssessmentType)
          ? 'Quick Checks'
          : capitalCase(x.formativeAssessmentType),
      };
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize }));
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleSortBy = (selectedColumn: keyof FormativeAssessmentTableRow) => {
    const getQueryOrderByColumn = (): StudentFormativeAssessmentsQuerySortKeyEnum => {
      switch (selectedColumn) {
        case 'assessedBy':
          return StudentFormativeAssessmentsQuerySortKeyEnum.AssessedBy;
        case 'date':
          return StudentFormativeAssessmentsQuerySortKeyEnum.Date;
        case 'resourceUsed':
          return StudentFormativeAssessmentsQuerySortKeyEnum.ResourceUsed;
        default:
          return StudentFormativeAssessmentsQuerySortKeyEnum.Date;
      }
    };
    const queryOrderByColumn = getQueryOrderByColumn();
    setQuery((currentState) => ({
      ...currentState,
      orderBy: queryOrderByColumn,
      sortDirection: toggleSortOrder(currentState.sortDirection),
    }));
  };

  function orderByColumn(): keyof FormativeAssessmentTableRow {
    switch (query.orderBy) {
      case StudentFormativeAssessmentsQuerySortKeyEnum.AssessedBy:
        return 'assessedBy';
      case StudentFormativeAssessmentsQuerySortKeyEnum.Date:
        return 'date';
      case StudentFormativeAssessmentsQuerySortKeyEnum.ResourceUsed:
        return 'resourceUsed';
      default:
        return 'date';
    }
  }

  if (!results.data) {
    return null;
  }

  return (
    <>
      <WordReadingFormativeAssessmentDetailsModal
        open={!!selectedWordReadingFormativeAssessment}
        handleClose={() => setSelectedWordReadingFormativeAssessment(undefined)}
        formativeAssessment={selectedWordReadingFormativeAssessment}
      />
      <FluencyFormativeAssessmentDetailsModal
        open={!!selectedFluencyFormativeAssessment}
        handleClose={() => setSelectedFluencyFormativeAssessment(undefined)}
        formativeAssessment={selectedFluencyFormativeAssessment}
      />
      <ComprehensionFormativeAssessmentDetailsModal
        open={!!selectedComprehensionFormativeAssessment}
        handleClose={() => setSelectedComprehensionFormativeAssessment(undefined)}
        formativeAssessment={selectedComprehensionFormativeAssessment}
      />
      <LettersAndSoundsFormativeAssessmentDetailsModal
        open={!!selectedLettersAndSoundsFormativeAssessment}
        handleClose={() => setSelectedLettersAndSoundsFormativeAssessment(undefined)}
        formativeAssessment={selectedLettersAndSoundsFormativeAssessment}
      />
      <Card
        sx={{
          width: 1,
        }}
      >
        <CardContent>
          <Stack>
            <Typography variant="titleLarge">Formative Assessments</Typography>
            <Box sx={{ marginTop: '24px' }}>
              <Table
                isSortable
                isPaginated
                data={results.data}
                headers={headers}
                onRowsPerPageChange={handleChangeRowsPerPage}
                count={results.count}
                page={query.page}
                onPageChange={handleChangePage}
                onSortBy={handleSortBy}
                sortBy={orderByColumn()}
                sortOrder={query.sortDirection === SortOrder.ASC ? OrderBy.Asc : OrderBy.Desc}
                rowsPerPage={query.pageSize}
              />
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default StudentFormativeAssessmentsCard;
