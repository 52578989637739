import { TimelineEvent, TimelineOutcome } from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import { Chip, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { DateFormats, WINNIPEG_TIMEZONE } from '@hoot/common/constants';
import EllipsisIcon from '@hoot/components/icons/timeline-legend/EllipsisIcon';
import PinIcon from '@hoot/components/icons/timeline-legend/PinIcon';
import InfoBubbles from './InfoBubbles';
import { timelineEventIconDictionary, timelineIconsDictionary } from './models';
import { EnrolmentPeriodType, HeaderCell, StudentTimelineResponse, TimelineCell } from './useGetStudentTimeline';

interface TimelineGridProps {
  data: StudentTimelineResponse | undefined;
  studentProfileId: string;
  onExpand?: () => void;
}

const TimelineGrid = (props: TimelineGridProps) => {
  const { data } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedOutcomeCell, setSelectedOutcomeCell] = useState<TimelineCell | null>(null);
  const [selectedEventCell, setSelectedEventCell] = useState<HeaderCell | null>(null);

  const handleHeaderClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, cell: HeaderCell) => {
    setAnchorEl(event.currentTarget);
    setSelectedEventCell(cell);
  };

  const handleRowClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, cell: TimelineCell) => {
    if (cell.outcome !== TimelineOutcome.Blank) {
      setAnchorEl(event.currentTarget);
      setSelectedOutcomeCell(cell);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedOutcomeCell(null);
    setSelectedEventCell(null);
  };

  return (
    <Stack sx={{ overflowX: 'auto', marginTop: 2 }}>
      <Stack sx={{ background: 'white', height: '114px', width: '256px', position: 'sticky', left: 0, zIndex: 1, mt: '24px' }} />
      {data?.modules.map((module, moduleIndex) => (
        <Stack key={`${module.name}-${moduleIndex}`} direction="row" sx={{ maxWidth: '95vw' }}>
          <Stack direction="row" sx={{ marginBottom: 2, left: 0, width: 'fit-content' }}>
            <Stack key={moduleIndex} direction="row" sx={{ position: 'sticky', left: 0, zIndex: 1, background: 'white' }}>
              {/* Vertical module name section */}
              <Chip
                label={module.name}
                sx={{
                  height: 'auto',
                  padding: '8px',
                  alignSelf: 'center',
                  writingMode: 'vertical-rl',
                  transform: 'rotate(180deg)',
                  '& .MuiChip-label': {
                    paddingLeft: 0,
                    paddingRight: 0,
                  },
                }}
              />

              {/* Unit name section */}
              <Stack sx={{ px: 1, minWidth: '220px' }} alignSelf="center">
                {module.units.map((unit, unitIndex) => (
                  <Stack key={unitIndex} direction="row" alignItems="center" sx={{ height: '25px' }}>
                    <Typography variant="bodySmall">{unit.name}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>

            {/* Table */}
            <Stack sx={{ borderRight: '1px solid #000' }} alignSelf="center" marginTop={moduleIndex === 0 ? '-24px' : undefined}>
              {/* Table header */}
              {moduleIndex === 0 ? (
                <>
                  <Stack direction="row">
                    {/* Header Row cells & Assessment Period Date */}
                    {data?.headerRow.map(
                      (cell, colIndex) =>
                        cell && (
                          <Stack
                            key={colIndex}
                            sx={{
                              borderTop: '1px solid #000',
                              borderLeft: '1px solid #000',
                              borderBottom: '1px solid #000',
                              position: 'relative',
                              '&:hover': {
                                cursor: 'pointer',
                              },
                            }}
                            onClick={(event) => handleHeaderClick(event, cell)}
                          >
                            <DatePin cell={cell} />

                            {/* Header Icon cell */}
                            {timelineEventIconDictionary[cell.event]}
                          </Stack>
                        ),
                    )}
                  </Stack>
                </>
              ) : null}

              {/* Table rows */}
              {module.units.map((unit, unitIndex) => (
                <Stack direction="row" key={unitIndex}>
                  {unit.cells.map((cell, colIndex) =>
                    cell ? (
                      <Stack
                        key={colIndex}
                        sx={{
                          borderTop: moduleIndex !== 0 && unitIndex === 0 ? '1px solid #000' : undefined,
                          borderLeft: '1px solid #000',
                          borderBottom: '1px solid #000',
                          '&:hover': {
                            cursor: cell.outcome === TimelineOutcome.Blank ? undefined : 'pointer',
                          },
                        }}
                        onClick={(event) => handleRowClick(event, cell)}
                      >
                        {timelineIconsDictionary[cell.outcome]}
                      </Stack>
                    ) : (
                      <Stack
                        key={colIndex}
                        sx={{
                          borderLeft: '1px solid #000',
                        }}
                      >
                        <EllipsisIcon />
                      </Stack>
                    ),
                  )}
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      ))}

      <InfoBubbles selectedOutcomeCell={selectedOutcomeCell} selectedEventCell={selectedEventCell} anchorEl={anchorEl} onClose={handlePopoverClose} />
    </Stack>
  );
};

/**
 * We only render the pin when:
 * - a. The cell is an HRA or PMA type and we have a date set.
 * - b. The cell is a ScheduledLesson type and we have an enrolment period start date.
 * - Otherwise, don't show the pin.
 */
const DatePin = (props: { cell: HeaderCell }) => {
  const {
    cell: { date, enrolmentPeriodStart, enrolmentPeriodType },
  } = props;
  const isAssessmentEvent = [TimelineEvent.HRA, TimelineEvent.PMA].includes(props.cell.event);
  const isScheduledLesson = props.cell.event === TimelineEvent.ScheduledLesson;

  if ((isAssessmentEvent && !date) || (isScheduledLesson && !enrolmentPeriodStart) || (!isAssessmentEvent && !isScheduledLesson && date)) return null;

  return (
    <Stack alignItems="center" position="relative">
      {isAssessmentEvent && date ? <AssessmentLabel date={date} /> : null}
      {isScheduledLesson && enrolmentPeriodStart && enrolmentPeriodType ? (
        <PeriodLabel enrolmentPeriodType={enrolmentPeriodType} enrolmentPeriodStart={enrolmentPeriodStart} />
      ) : null}
      <PinIcon sx={{ position: 'absolute', top: '-24px' }} />
    </Stack>
  );
};

const AssessmentLabel = (props: { date: string | undefined }) => {
  if (!props.date) return;

  return (
    <Typography
      variant="bodySmall"
      sx={{
        position: 'absolute',
        top: '-76px',
        left: '-6px',
        rotate: '-45deg',
        minWidth: '100px',
      }}
    >
      {DateTime.fromFormat(props.date, DateFormats.FULL_MDY).toFormat(DateFormats.SHORT_MDY)}
    </Typography>
  );
};

const PeriodLabel = (props: { enrolmentPeriodType: EnrolmentPeriodType; enrolmentPeriodStart: number | undefined }) => {
  if (!props.enrolmentPeriodStart) return;

  return (
    <>
      <Typography
        variant="tableHeading"
        sx={{
          position: 'absolute',
          top: '-60px',
          minWidth: '122px',
        }}
      >
        {props.enrolmentPeriodType} Period Start
      </Typography>
      <Typography
        variant="bodySmall"
        textAlign="center"
        sx={{
          position: 'absolute',
          top: '-44px',
          minWidth: '90px',
        }}
      >
        {DateTime.fromMillis(props.enrolmentPeriodStart).setZone(WINNIPEG_TIMEZONE).toFormat(DateFormats.SHORT_MDY)}
      </Typography>
    </>
  );
};

export default TimelineGrid;
