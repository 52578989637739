import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useAlert } from '@hoot/contexts/AlertContext';
import useDeleteEnrolmentPeriod from '@hoot/hooks/api/hfs/useDeleteEnrolmentPeriod';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

interface DeleteEnrolmentPeriodModalProps {
  periodIdToDelete: string | undefined;
  onClose: () => void;
  enrolmentId: string;
}

const DeleteEnrolmentPeriodModal = (props: DeleteEnrolmentPeriodModalProps) => {
  const queryClient = useQueryClient();
  const { success, error } = useAlert();
  const { periodIdToDelete, onClose, enrolmentId } = props;

  const deleteMutation = useDeleteEnrolmentPeriod();

  const onCancel = () => props.onClose();

  const onApply = () => {
    deleteMutation.mutate(periodIdToDelete ?? '', {
      onSuccess: () => {
        success('Enrolment period has been deleted successfully!');
        queryClient.invalidateQueries([QueryKey.GetEnrolmentPeriods, enrolmentId]);
        onClose();
      },
      onError: (err) => {
        error('There was an error, the enrolment period was not deleted.');
        console.error(err);
      },
    });
  };

  return (
    <Dialog open={!!periodIdToDelete} onClose={onCancel} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <Typography variant="headlineSmall">Are you sure?</Typography>
          <IconButton onClick={() => onCancel()} sx={{ marginLeft: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography>
          You are about to delete an assessment period from this enrolment. This action cannot be undone. Are you sure you want to continue?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 3, position: 'relative' }}>
        <Button onClick={onCancel} size="large" variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={onApply} disabled={deleteMutation.isLoading} color="error" size="large" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteEnrolmentPeriodModal;
