import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../../queryKeys';

interface ScheduleMetricsResponse {
  setProgress: {
    scheduledLessons: number;
    occurredLessons: number;
    setProgressPercentage: number;
  };

  teacherAssignment: {
    assignedTeachers: number;
    assignedTeachersPercentage: number;
    regularScheduledTeachers: number;
    regularScheduledTeachersPercentage: number;
    subbedInTeachers: number;
    subbedInTeachersPercentage: number;
  };

  lessonSuccess: {
    studentAttendance: number;
    studentAttendancePercentage: number;
    completedLessons: number;
    completedLessonsPercentage: number;
  };
}

async function getScheduleMetrics(lessonSetId: string) {
  const { data } = await axios.get<ScheduleMetricsResponse>(`${config.apiUrl}/admin/lesson-set/${lessonSetId}/schedule-metrics`);
  return data;
}

export function useGetScheduleMetrics(lessonSetId: string, options?: Omit<UseQueryOptions<ScheduleMetricsResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.LessonSetScheduleMetrics, lessonSetId], () => getScheduleMetrics(lessonSetId), options);
}
