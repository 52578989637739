import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { BookSortableQuery } from '@hoot/components/ui/SortableLibrary';
import { config } from '@hoot/config';
import { GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import { QueryKey } from '../../../queryKeys';
import { ResourceProgress, ResourceState } from './enums';

export interface StudentResourceFavouritesResponse {
  bookId: string;
  bookTitle: string;
  bookLevel: string;
  resourceProgress: ResourceProgress;
  resourceState: ResourceState;
  instructionalUnit: string;
  added: number;
  addedById: string;
  addedBy: string;
  number: string;
}

const getStudentResourcesLessonPlan = async (
  studentProfileId: string,
  request: BookSortableQuery,
): Promise<GenericPaginatedResponse<StudentResourceFavouritesResponse>> => {
  const { data } = await axios.get(`${config.apiUrl}/admin/student-profile/${studentProfileId}/resources/lesson-plan`, { params: request });
  return data;
};

export default function useGetStudentResourcesLessonPlan(
  studentProfileId: string,
  request: BookSortableQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<StudentResourceFavouritesResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetStudentInstructionalLessonPlan, studentProfileId, request],
    () => getStudentResourcesLessonPlan(studentProfileId, request),
    options,
  );
}
