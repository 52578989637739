import { MovieOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useAuth } from '../../../../contexts/Auth/AuthContext';
import { HootEmployeeScope } from '../../../../contexts/Auth/enums/hoot-employee.scope';
import LessonEventsCard from './LessonEventsCard';
import { LessonEventsContent } from './LessonEventsContent';
import { LessonEventsTimeline } from './LessonEventsTimeline';
import { LessonVisualizerContextProvider } from './context/LessonVisualizerContextProvider';

export function LessonVisualizer(props: { lessonId: string }) {
  const { scopes } = useAuth();

  const accessGranted = !!scopes.find((s) => s === HootEmployeeScope.ViewLessonVideos);

  if (!accessGranted) {
    return (
      <CommentBlock
        title="Permission Not Granted"
        message="You do not have permission to view this page. Please contact your manager if you require permission."
      />
    );
  }

  return (
    <LessonVisualizerContextProvider lessonId={props.lessonId}>
      <Stack flex={1} direction="row" sx={{ height: '80vh', mt: 2, maxWidth: '100%' }} spacing="24px">
        <Stack flex={1} spacing="24px">
          <LessonEventsContent />
          <LessonEventsTimeline />
        </Stack>
        <Stack flex="0 0 356px">
          <LessonEventsCard />
        </Stack>
      </Stack>
    </LessonVisualizerContextProvider>
  );
}

function CommentBlock(props: { title: string; message: string }) {
  return (
    <Stack maxWidth="50%" sx={{ borderRadius: '8px', backgroundColor: '#fef4e9', padding: '32px' }}>
      <Stack direction="row" textAlign="center" alignItems="center" spacing="32px">
        <MovieOutlined />
        <Stack alignItems="flex-start">
          <Typography variant="titleMedium">{props.title}</Typography>
          <Typography variant="body1">{props.message}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
