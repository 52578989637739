import { Button, CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderCard, { HeaderCardProps, HeaderTab } from '@hoot/components/ui/HeaderCard';
import { useAlert } from '@hoot/contexts/AlertContext';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { QualityAssuranceStatus } from '@hoot/hooks/api/tasks/enums';
import useGetQualityTaskDetails from '@hoot/hooks/api/tasks/useGetQualityAssuranceTaskDetails';
import useUpdateQualityAssuranceTask, { UpdateQualityAssuranceTaskRequest } from '@hoot/hooks/api/tasks/useUpdateQualityAssuranceTask';
import { routes } from '@hoot/routes/routes';
import QualityAssuranceDetails from './QualityAssuranceDetails';
import QualityAssuranceHistory from './QualityAssuranceHistory';

export enum QATasksTabs {
  Details = 'DETAILS',
  History = 'HISTORY',
}

interface Props {
  tab: QATasksTabs;
}

export interface Form {
  status: QualityAssuranceStatus;
  isActioned: string;
}

const QualityAssuranceTaskPage = (props: Props) => {
  const { tab } = props;
  const { qualityAssuranceTaskId } = useParams() as { qualityAssuranceTaskId: string };
  const navigate = useNavigate();

  const { formState, reset, control, handleSubmit, resetField } = useForm<Form>({});
  const queryClient = useQueryClient();
  const updateQualityAssuranceTaskMutation = useUpdateQualityAssuranceTask();
  const alert = useAlert();

  const { isFetching, data } = useGetQualityTaskDetails(qualityAssuranceTaskId, {
    onSuccess: (data) => {
      reset({
        status: data.status,
        isActioned: data.actioned ? 'Y' : 'N',
      });
    },
  });

  if (!data || isFetching) {
    return <CircularProgress />;
  }

  const tabs: HeaderTab[] = [
    { id: 'details', label: 'Details', route: routes.tasks.details.url(qualityAssuranceTaskId) },
    { id: 'history', label: 'History & Comments', route: routes.tasks.history.url(qualityAssuranceTaskId) },
  ];

  const onTabSelected: HeaderCardProps['onTabSelected'] = (selectedTab) => {
    navigate(selectedTab.route);
    return true;
  };

  const onNavigateBack = () => {
    navigate(routes.tasks.url);
    return false;
  };

  const onSubmit = (data: Form) => {
    const request: UpdateQualityAssuranceTaskRequest = {
      taskId: qualityAssuranceTaskId,
      isActioned: data.isActioned === 'Y' ? true : false,
      status: data.status,
    };
    updateQualityAssuranceTaskMutation.mutate(request, {
      onSuccess: () => {
        alert.success(`Successfully saved quality assurance task`);
        queryClient.invalidateQueries([QueryKey.QualityAssurnaceTaskDetails, qualityAssuranceTaskId]);
        queryClient.invalidateQueries([QueryKey.QualityAssuranceTasksQuery]);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2} height={'100%'}>
        <HeaderCard
          showNavigateBackButton
          interceptNavigateBackButtonClicked={onNavigateBack}
          title={`Task ${data.taskPrefixedNumber}`}
          subtitle={`${DateTime.fromMillis(data.dateTaskCreated).toLocaleString(DateTime.DATE_FULL)}`}
          actionButtons={
            <Button type="submit" disabled={!formState.isDirty} sx={{ marginLeft: '8px' }} color="primary" variant="contained" size="large">
              Save
            </Button>
          }
          tabs={tabs}
          onTabSelected={onTabSelected}
        />
        {tab === QATasksTabs.Details ? <QualityAssuranceDetails data={data} control={control} resetField={resetField} /> : null}
        {tab === QATasksTabs.History ? <QualityAssuranceHistory qualityAssuranceTaskId={qualityAssuranceTaskId} /> : null}
      </Stack>
    </form>
  );
};

export default QualityAssuranceTaskPage;
