import { TeacherShiftType } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { RequestedShiftForConflictChecker } from './useGetTeacherShiftConflicts';

export interface TeacherRecurringShiftRequest {
  teacherAccountId: string;
  startsAt: number;
  endsAt: number;
  type: TeacherShiftType;
  recurrenceRules: string;
  requestedShifts: RequestedShiftForConflictChecker[];
}

const createTeacherRecurringShift = async (request: TeacherRecurringShiftRequest): Promise<string> => {
  const { data } = await axios.post<string>(`${config.apiUrl}/admin/teacher-shift/recurring`, request);
  return data;
};

const useCreateTeacherRecurringShift = (
  options?: Omit<UseMutationOptions<string, AxiosError, TeacherRecurringShiftRequest>, 'mutationFn' | 'mutationKey'>,
) => {
  return useMutation<string, AxiosError, TeacherRecurringShiftRequest>((request) => createTeacherRecurringShift(request), options);
};

export default useCreateTeacherRecurringShift;
