import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface AddResourcesToLessonPlanRequest {
  bookIds: string[];
}

async function addResourcesToLessonPlan(studentProfileId: string, request: AddResourcesToLessonPlanRequest): Promise<void> {
  await axios.post(`${config.apiUrl}/admin/student-profile/${studentProfileId}/resources/lesson-plan`, request);
}

export default function useAddResourcesToLessonPlan(
  studentProfileId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, AddResourcesToLessonPlanRequest>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((request: AddResourcesToLessonPlanRequest) => addResourcesToLessonPlan(studentProfileId, request), options);
}
