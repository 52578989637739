import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Checkbox, FormControlLabel, InputAdornment, Stack, TextField } from '@mui/material';
import { SelectedTeacher } from '@hoot/components/modals/SelectTeacher';
import { TentativeShiftForm } from '../teacher-conflicts-step/TeacherConflictCheckStep';

interface ReviewTeacherShiftCardProps {
  teacher: SelectedTeacher;
  shifts: TentativeShiftForm[];
}

const ReviewTeacherShiftCard = (props: ReviewTeacherShiftCardProps) => {
  const { teacher, shifts } = props;

  const teacherLabel = teacher ? `${teacher.name} (${teacher.number})` : 'None';

  return (
    <Stack
      sx={{
        borderRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.20)',
        padding: 3,
        gap: 2,
        width: '100%',
      }}
    >
      <TextField
        required
        fullWidth
        label="Teacher"
        value={teacherLabel}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <PermIdentityIcon />
            </InputAdornment>
          ),
        }}
      />
      <Stack>
        {shifts
          .filter((shift) => !shift.skip)
          .map((shift) => (
            <FormControlLabel
              checked
              disabled
              control={<Checkbox />}
              label={`${shift.startsAt.toFormat('ccc, LLL d, yyyy @ h:mma')} - ${shift.endsAt.toFormat('h:mma')}`}
              key={`${shift.startsAt}`}
            />
          ))}
      </Stack>
    </Stack>
  );
};

export default ReviewTeacherShiftCard;
