import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { HomeOutlined } from '@mui/icons-material';
import { Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types/form';
import { IDistrictSchoolsCheckBoxRow } from '@hoot/components/hfs-interfaces';
import NoneItem from '@hoot/components/ui/NoneItem';
import { StudentLocationResponse } from '@hoot/hooks/api/end-users/useGetStudentProfile';
import { routes } from '@hoot/routes/routes';
import AddLocationModal from '../../../../../components/modals/AddLocationModal';
import useGetLocation from '../../../../../hooks/api/hfs/useGetLocation';
import { StudentDetailsForm } from '../form';

interface IStudentsLocationCardProps {
  locationIdParam: string | null;
  hasSavedLocation?: boolean;
  watch: UseFormWatch<StudentDetailsForm>;
  setValue: UseFormSetValue<StudentDetailsForm>;
  showLocationsModal: boolean;
  setShowLocationsModal: Dispatch<SetStateAction<boolean>>;
  studentName?: string;
  viewLocationType: LocationType;
  disableFields: boolean;
}

const getLocations = (isDistrict: boolean, hfsLocations?: StudentLocationResponse[] | null): StudentLocationResponse[] => {
  if (!hfsLocations) {
    return [];
  }
  if (isDistrict) {
    // using uniqBy to deal with cases where we might end up with the district being listed multiple times.
    // This could happen if
    // - a user is associated directly to the district and a school under the district (we used to do this
    //   but don't anymore, however there is still some old data in the DB & the chance it could happen again),
    // - or if a user for some reason is connected to multiple schools under the same district.
    return _.uniqBy(
      [...hfsLocations.filter((l) => !!l.district).map((l) => l.district!), ...hfsLocations.filter((l) => l.type === LocationType.District)],
      'id',
    );
  } else {
    return hfsLocations.filter((l) => l.type === LocationType.School);
  }
};

const StudentLocationCard = (props: IStudentsLocationCardProps) => {
  const {
    locationIdParam,
    hasSavedLocation,
    watch,
    setValue,
    showLocationsModal,
    setShowLocationsModal,
    studentName,
    viewLocationType,
    disableFields,
  } = props;
  const { hfsLocations } = watch();
  const isDistrictView = viewLocationType === LocationType.District;
  const locations = getLocations(isDistrictView, hfsLocations);

  useGetLocation(locationIdParam!, {
    enabled: !!locationIdParam,
    retry: false,
    onSuccess: (data) => {
      setValue('hfsLocations', [data], { shouldDirty: true });
    },
  });

  const onAddClick = () => {
    setShowLocationsModal(true);
  };

  const onApply = (selectedRow: IDistrictSchoolsCheckBoxRow) => {
    if (!selectedRow) {
      return;
    }

    const school: StudentLocationResponse = {
      id: selectedRow.id,
      name: selectedRow.name,
      friendlyId: selectedRow.friendlyId,
      type: selectedRow.type,
    };

    setValue('hfsLocations', [school], { shouldDirty: true });
    setShowLocationsModal(false);
  };

  return (
    <Card sx={{ pl: 3, pt: 3, pr: 3 }}>
      <CardHeader
        sx={{ p: 0, mb: 3 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="titleLarge">
              {isDistrictView ? 'District' : 'School'}
            </Typography>
            <Button type="button" onClick={onAddClick} variant="contained" disabled={hasSavedLocation || disableFields}>
              Add
            </Button>
          </Stack>
        }
      />

      <CardContent sx={{ p: 0 }}>
        <Typography component="h5" variant="bodySmall">
          {isDistrictView ? 'District' : 'School'}
        </Typography>
        <Stack sx={{ paddingTop: 1 }}>
          {locations.length > 0 ? (
            locations.map((location, idx) => (
              <Stack
                direction="row"
                gap={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ border: '1px solid #CAC4D0', borderRadius: '4px', p: 2 }}
                key={`${location.id}-${idx}`}
              >
                <Stack direction="row" gap={3} alignItems="center">
                  <HomeOutlined />
                  <Stack>
                    <Typography variant="bodyLarge">{location.name}</Typography>
                    {location.district ? (
                      <Typography component="h5" variant="bodySmall">
                        {location.district.name}
                      </Typography>
                    ) : null}
                    <Typography variant="labelMedium">{location.friendlyId}</Typography>
                  </Stack>
                </Stack>
                <Button href={routes.districtsSchools.details.url(location.id)} variant="text" color="primary" size="large">
                  View
                </Button>
              </Stack>
            ))
          ) : (
            <NoneItem />
          )}
        </Stack>
      </CardContent>
      {!hasSavedLocation && showLocationsModal ? (
        <AddLocationModal
          isStudentMode={true}
          locationType={viewLocationType}
          nameToAssociate={studentName}
          setOpen={setShowLocationsModal}
          open={showLocationsModal}
          onApply={onApply}
        />
      ) : null}
    </Card>
  );
};

export default StudentLocationCard;
