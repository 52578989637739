import { AgreementEndReason } from '@hoot-reading/hoot-core/dist/enums/user/teacher/agreement-end-reason.enum';
import { DiversitySelfIdentification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/diversity-self-identification.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { LanguageQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/language-qualification.enum';
import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { TeacherAvailability } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-availability.enum';
import { TeacherCertificationArea } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-certification-area.enum';
import { TeacherSpecializedTraining } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-specialized-training.enum';
import { TeacherStage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-stage.enum';
import { TeacherStanding } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-standing.enum';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { BackgroundCheck } from '@hoot/interfaces/teacher';
import { InvoiceRateTier } from '@hoot/pages/users/teachers/enums/teacher-enums';
import { QueryKey } from '../../queryKeys';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isEnabled: boolean;
  streetAddress: string;
  country: string | null;
  city: string | null;
  provinceState: string | null;
  postalZipCode: string;
  timeZone: string;
  hubspotId: number | null;
  zendeskId: number | null;
  isEnabledLastUpdatedAt: number | null;
}

export interface TeacherAccount {
  id: string;
  displayName: string;
  number: number;
  prefixedNumber: string;
  isEnabled: boolean;
  isDisabled: boolean;
  user: User;
  diversitySelfIdentifications: DiversitySelfIdentification[];
  languageQualifications: LanguageQualification[];
  hootQualifications: HootQualification[];
  teacherStatus: TeacherStatus;
  standing: TeacherStanding;
  availability: TeacherAvailability;
  employmentAgreementDate: string | null;
  icContractDate: string | null;
  goLiveDate: string | null;
  spokenLanguages: SpokenLanguage[];
  certificationAreas: TeacherCertificationArea[];
  specializedTraining: TeacherSpecializedTraining[];
  bio: string | null;
  updatedStatusAt: number | null;
  agreementEndDate: string | null;
  agreementEndReason: AgreementEndReason | null;
  lessonPayTier: InvoiceRateTier | null;
  billableLessonsCount: number;
  teacherStage: TeacherStage | null;
  emergencyContactFirstName: string | null;
  emergencyContactLastName: string | null;
  emergencyContactEmail: string | null;
  emergencyContactPhoneNumber: string | null;
  trainingPayTier: InvoiceRateTier | null;
  payEffectiveDate: string | null;
  leaveEffectiveDate: string | null;
  leaveReturnDate: string | null;
  backgroundChecks: BackgroundCheck[];
}

const getTeacherAccount = async (teacherAccountId: string) => {
  const { data } = await axios.get<TeacherAccount>(`${config.apiUrl}/admin/teacher-account/${teacherAccountId}`);
  return data;
};

export default function useGetTeacherAccount(
  teacherAccountId: string,
  options?: Omit<UseQueryOptions<TeacherAccount, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetTeacherAccount, teacherAccountId], () => getTeacherAccount(teacherAccountId), options);
}
