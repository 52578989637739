import { CancellationReason } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useAlert } from '@hoot/contexts/AlertContext';
import { WeeklySchedule } from '@hoot/pages/users/components/user-schedule/WeeklySchedule';
import {
  AvailabilityTimeSlot,
  LessonPreferencesSlot,
  LessonTimeSlot,
  SelectedItem,
  ShiftTimeSlot,
} from '@hoot/pages/users/components/user-schedule/weekly-schedule/interfaces';
import { useGetTeacherAvailability } from '../../../../hooks/api/availability/useAvailability';
import { useGetTeacherShifts } from '../../../../hooks/api/availability/useGetTeacherShifts';
import { LessonDetailResponse } from '../../../../hooks/api/lessons/useGetLesson';
import useLessonQuery, { LessonsResponse } from '../../../../hooks/api/lessons/useLessonQuery';
import { notIn } from '../../../../utils/notIn';
import useGetStudentLessonPreferences from '../../../users/hooks/useGetStudentLessonPreferences';
import { OrderBy, OrderColumn } from '../../enums';
import { RescheduleWizardState, useRescheduleWizard } from '../RescheduleWizardContextProvider';

enum RescheduleWizardTabs {
  TeacherSchedule = 0,
  StudentSchedule = 1,
}

export enum ScheduleType {
  Student = 'STUDENT',
  Teacher = 'TEACHER',
}

export function RescheduleWizardSchedulesStep(props: { lessonDetails: LessonDetailResponse }) {
  const { updateRescheduledLesson, updateWizardState, rescheduledLesson } = useRescheduleWizard();
  const { error } = useAlert();
  const [blocked, setBlocked] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState(RescheduleWizardTabs.TeacherSchedule);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<DateTime | undefined>(
    rescheduledLesson?.lessonAt ? DateTime.fromMillis(rescheduledLesson.lessonAt) : undefined,
  );

  const [startOfWeek, setStartOfWeek] = useState(DateTime.now().startOf('week'));
  const endsAt = startOfWeek.plus({ week: 1 });

  const { data: teacherLessonsResponse } = useLessonQuery({
    teacherAccountIds: [props.lessonDetails.teacherAccountId!],
    fromDate: startOfWeek.toMillis(),
    toDate: endsAt.toMillis(),
    orderBy: OrderBy.Asc,
    orderColumn: OrderColumn.StartsAt,
    page: 1,
    pageSize: 1000,
  });

  const { data: studentLessonsResponse } = useLessonQuery({
    studentProfileIds: [props.lessonDetails.studentProfileId],
    fromDate: startOfWeek.toMillis(),
    toDate: endsAt.toMillis(),
    orderBy: OrderBy.Asc,
    orderColumn: OrderColumn.StartsAt,
    page: 1,
    pageSize: 1000,
  });

  const updateSelectedTimeSlot = (timeSlot: DateTime) => {
    setSelectedTimeSlot(timeSlot);
    updateRescheduledLesson({ lessonAt: timeSlot.toMillis() });
  };

  const onStartofWeekChange = (newStartOfWeek: DateTime) => {
    setStartOfWeek(newStartOfWeek);
  };

  const onApply = () => {
    if (!selectedTimeSlot) {
      return;
    } else {
      const timeSlotInMillis = selectedTimeSlot?.toMillis();
      const timeSlotEndsAt = selectedTimeSlot.plus({ minute: 30 }).toMillis();
      const teacherLessons =
        teacherLessonsResponse?.lessons.filter((l) =>
          notIn(l.cancellationReason, [
            CancellationReason.SchedulingError,
            CancellationReason.HootClosure,
            CancellationReason.MembershipLapse,
            CancellationReason.ParentChangeRequest,
            CancellationReason.StudentUnavailableAboveNotice,
            CancellationReason.UnsuccessfulPayment,
          ]),
        ) ?? [];
      const studentLessons = studentLessonsResponse?.lessons?.filter((l) => notIn(l.cancellationReason, [CancellationReason.SchedulingError])) ?? [];
      const teacherLessonConflict = teacherLessons.find((lesson) => lesson.startTime >= timeSlotInMillis && lesson.startTime < timeSlotEndsAt);
      const studentLessonConflict = studentLessons.find((lesson) => lesson.startTime >= timeSlotInMillis && lesson.startTime < timeSlotEndsAt);

      // check for conflicts before proceeding
      if (teacherLessonConflict) {
        error('Teacher has lesson during selected timeslot');
      } else if (studentLessonConflict) {
        error('Student has lesson during selected timeslot');
      } else {
        setBlocked(false);
        updateWizardState(RescheduleWizardState.LessonDetails);
      }
    }
  };

  const onCancel = () => {
    if (selectedTimeSlot) {
      setBlocked(true);
    } else {
      onContinue();
    }
  };

  const onContinue = () => {
    setBlocked(false);
    updateRescheduledLesson({ lessonAt: undefined });
    updateWizardState(RescheduleWizardState.LessonDetails);
  };

  return (
    <Stack sx={{ filter: 'grayscale(50%)' }}>
      <Tabs value={selectedTab} onChange={(event, tab: RescheduleWizardTabs) => setSelectedTab(tab)} sx={{ marginBottom: '64px' }}>
        <Tab sx={{ minWidth: '50%' }} label="Teacher Schedule" value={RescheduleWizardTabs.TeacherSchedule} />
        <Tab sx={{ minWidth: '50%' }} label="Student Schedule" value={RescheduleWizardTabs.StudentSchedule} />
      </Tabs>

      {selectedTab === RescheduleWizardTabs.TeacherSchedule && props.lessonDetails.teacherAccountId ? (
        <TeacherSchedule
          teacherAccountId={props.lessonDetails.teacherAccountId}
          teacherTimeZone={props.lessonDetails.teacherTimeZone}
          startOfWeek={startOfWeek}
          onStartOfWeekChange={onStartofWeekChange}
          lessons={teacherLessonsResponse}
          selectedTimeSlot={selectedTimeSlot}
          updateSelectedTimeSlot={updateSelectedTimeSlot}
        />
      ) : null}
      {selectedTab === RescheduleWizardTabs.StudentSchedule ? (
        <StudentSchedule
          studentProfileId={props.lessonDetails.studentProfileId}
          studentTimeZone={props.lessonDetails.parentTimezone}
          startOfWeek={startOfWeek}
          onStartOfWeekChange={onStartofWeekChange}
          lessons={studentLessonsResponse}
          selectedTimeSlot={selectedTimeSlot}
          updateSelectedTimeSlot={updateSelectedTimeSlot}
        />
      ) : null}
      <Stack
        direction="row"
        justifyContent="space-between"
        marginTop="24px"
        paddingTop="24px"
        borderTop="1px solid black"
        sx={{ position: 'sticky', bottom: 0, paddingBottom: '24px', backgroundColor: 'white', zIndex: 99999 }}
      >
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" disabled={!selectedTimeSlot} onClick={onApply}>
          Apply
        </Button>
      </Stack>
      <ConfirmDialog showPrompt={blocked} handleKeepEditing={() => setBlocked(false)} handleDiscard={onContinue} />
    </Stack>
  );
}

function ConfirmDialog(props: { showPrompt: boolean; handleKeepEditing: () => void; handleDiscard: () => void }) {
  const { showPrompt, handleKeepEditing, handleDiscard } = props;

  return (
    <Dialog open={showPrompt} onClose={handleKeepEditing}>
      <Stack sx={{ padding: '24px', position: 'relative' }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">Unsaved Changes</Typography>
            <IconButton onClick={handleKeepEditing} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText textAlign="left" sx={{ color: '#1C1B1F' }}>
            Your unsaved changes will be lost. Are you sure you want to leave?
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button
              sx={{
                fontSize: 14,
              }}
              fullWidth
              size="medium"
              color="inherit"
              variant="outlined"
              onClick={handleKeepEditing}
            >
              Stay
            </Button>
            <Button
              sx={{
                fontSize: 14,
              }}
              fullWidth
              size="medium"
              variant="contained"
              onClick={handleDiscard}
            >
              Leave
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}

function TeacherSchedule(props: {
  teacherAccountId: string;
  teacherTimeZone: string;
  startOfWeek: DateTime;
  onStartOfWeekChange: (startOfWeek: DateTime) => void;
  lessons?: LessonsResponse;
  selectedTimeSlot?: DateTime;
  updateSelectedTimeSlot?: (timeSlot: DateTime) => void;
}) {
  const [selectedItem, setSelectedItem] = useState<SelectedItem>();

  const endsAt = props.startOfWeek.plus({ week: 1 });

  const shiftsRequest = useGetTeacherShifts(props.teacherAccountId, {
    startsAt: props.startOfWeek.toMillis(),
    endsAt: endsAt.toMillis(),
    includeCancelled: true,
  });

  const availabilityRequest = useGetTeacherAvailability(props.teacherAccountId);

  const handleSelected = (newSelectedItem: SelectedItem) => {
    setSelectedItem(newSelectedItem);
  };

  const shifts =
    shiftsRequest.data?.shifts.map<ShiftTimeSlot>((s) => ({
      id: s.id,
      startAt: DateTime.fromMillis(s.startsAt),
      endsAt: DateTime.fromMillis(s.endsAt),
      status: s.status,
      type: s.type,
    })) ?? [];

  const lessons =
    props.lessons?.lessons
      ?.filter((l) =>
        notIn(l.cancellationReason, [
          CancellationReason.SchedulingError,
          CancellationReason.HootClosure,
          CancellationReason.MembershipLapse,
          CancellationReason.ParentChangeRequest,
          CancellationReason.StudentUnavailableAboveNotice,
          CancellationReason.UnsuccessfulPayment,
        ]),
      )
      .map<LessonTimeSlot>((l) => ({
        id: l.lessonId,
        number: l.lessonNumber,
        startAt: DateTime.fromMillis(l.startTime),
        endsAt: DateTime.fromMillis(l.endTime),
        status: l.status,
      })) ?? [];

  const availability =
    availabilityRequest.data?.timeblocks?.map<AvailabilityTimeSlot>((tb) => ({
      startAt: tb.startTime,
      endAt: tb.endTime,
      dayOfWeek: tb.dayOfWeek,
      zone: availabilityRequest.data.timezone,
    })) ?? [];

  return (
    <WeeklySchedule
      startOfWeek={props.startOfWeek}
      onStartOfWeekChange={props.onStartOfWeekChange}
      selectedItem={selectedItem}
      onSelected={handleSelected}
      availability={availability}
      lessons={lessons}
      shifts={shifts}
      selectedTimeSlot={props.selectedTimeSlot}
      updateSelectedTimeSlot={props.updateSelectedTimeSlot}
      disableLessons={true}
      scheduleType={ScheduleType.Teacher}
      userTimeZone={props.teacherTimeZone}
    />
  );
}

function StudentSchedule(props: {
  studentProfileId: string;
  studentTimeZone: string;
  startOfWeek: DateTime;
  onStartOfWeekChange: (startOfWeek: DateTime) => void;
  lessons?: LessonsResponse;
  selectedTimeSlot?: DateTime;
  updateSelectedTimeSlot?: (timeSlot: DateTime) => void;
}) {
  const [selectedItem, setSelectedItem] = useState<SelectedItem>();

  const studentLessonPreferencesRequest = useGetStudentLessonPreferences(props.studentProfileId);

  const handleSelected = (newSelectedItem: SelectedItem) => {
    setSelectedItem(newSelectedItem);
  };

  const lessons =
    props.lessons?.lessons
      ?.filter((l) => notIn(l.cancellationReason, [CancellationReason.SchedulingError]))
      .map<LessonTimeSlot>((l) => ({
        id: l.lessonId,
        number: l.lessonNumber,
        startAt: DateTime.fromMillis(l.startTime),
        endsAt: DateTime.fromMillis(l.endTime),
        status: l.status,
      })) ?? [];

  const lessonPreferences = studentLessonPreferencesRequest.data?.preferredDateTimes.map<LessonPreferencesSlot>((p) => ({
    dayOfWeek: p.weekDay,
    startAt: p.time,
  }));

  return (
    <WeeklySchedule
      startOfWeek={props.startOfWeek}
      onStartOfWeekChange={props.onStartOfWeekChange}
      selectedItem={selectedItem}
      onSelected={handleSelected}
      lessons={lessons}
      lessonPreferences={lessonPreferences}
      selectedTimeSlot={props.selectedTimeSlot}
      updateSelectedTimeSlot={props.updateSelectedTimeSlot}
      disableLessons={true}
      scheduleType={ScheduleType.Student}
      userTimeZone={props.studentTimeZone}
    />
  );
}
