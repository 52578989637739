import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Box, Button, Checkbox, Divider, Grid, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { LessonSetTeacherAssignmentWizardState } from '@hoot/pages/lesson-sets/details/teacher-assignment-tab/lesson-set-wizard-modal/LessonSetWizardModal';

interface TeacherAssignmentReviewStepProps {
  state: LessonSetTeacherAssignmentWizardState;
  onPreviousButtonClicked: () => void;
  onCancel: () => void;
  onApplyChanges: () => void;
}

const LessonSetWizardReviewStep = (props: TeacherAssignmentReviewStepProps) => {
  const { state, onPreviousButtonClicked, onCancel, onApplyChanges } = props;

  const { selectedTeacher, selectedLessonGroups, lessonIdsAssignedToTeacher } = state;

  // Get all upcoming lessons within the selected lesson groups (we don't want to update lessons that already happened).
  const upcomingLessonGroupLessons = useMemo(() => {
    return selectedLessonGroups.flatMap((x) => x.lessons).filter((x) => x.status === ScheduledLessonStatus.Scheduled);
  }, [selectedLessonGroups]);

  // Get all lessons assigned to teacher.
  const lessonsAssignedToTeacher = useMemo(() => {
    return upcomingLessonGroupLessons.filter((x) => lessonIdsAssignedToTeacher!.has(x.lessonId)).sort((a, b) => a.startsAt - b.startsAt);
  }, [upcomingLessonGroupLessons, lessonIdsAssignedToTeacher]);

  // Get all lessons without an assigned teacher.
  const lessonsWithoutTeacher = useMemo(() => {
    return upcomingLessonGroupLessons.filter((x) => !lessonIdsAssignedToTeacher!.has(x.lessonId)).sort((a, b) => a.startsAt - b.startsAt);
  }, [upcomingLessonGroupLessons, lessonIdsAssignedToTeacher]);

  return (
    <>
      <Grid container spacing={7}>
        {lessonsAssignedToTeacher.length > 0 && (
          <Grid item xs={12} md={6}>
            <Box sx={{ padding: 3, gap: 3, borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.20)' }}>
              <Typography>
                {/* Note: the teacher _should_ exist by this point since it's required to be selected in the previous step. */}
                Lessons to be assigned to {selectedTeacher!.name} ({selectedTeacher!.number})
              </Typography>
              <Stack>
                {lessonsAssignedToTeacher.map((x) => (
                  <Stack key={`updated-teacher-lesson-${x.lessonId}`} direction="row" alignItems="center">
                    <Checkbox checked disabled />
                    <Typography>{DateTime.fromMillis(x.startsAt).toFormat('ccc, LLL d, yyyy @ h:mma')}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Grid>
        )}
        {lessonsWithoutTeacher.length > 0 && (
          <Grid item xs={12} md={6}>
            <Box sx={{ padding: 3, gap: 3, borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.20)' }}>
              <Typography>Lessons to be created without a teacher</Typography>
              <Stack>
                {lessonsWithoutTeacher.map((x) => (
                  <Stack key={`updated-teacherless-lesson-${x.lessonId}`} direction="row" alignItems="center">
                    <Checkbox checked disabled />
                    <Typography>{DateTime.fromMillis(x.startsAt).toFormat('ccc, LLL d, yyyy @ h:mma')}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Grid>
        )}
      </Grid>
      <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
      <Stack direction="row" gap={2} justifyContent="space-between">
        <Button variant="outlined" onClick={onPreviousButtonClicked} size="large">
          Previous
        </Button>
        <Stack direction="row" gap={2}>
          <Button variant="outlined" onClick={onCancel} size="large">
            Cancel
          </Button>
          <Button variant="contained" size="large" onClick={onApplyChanges}>
            Apply
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default LessonSetWizardReviewStep;
