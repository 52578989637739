import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
  ActiveProductRequirementsFilterDropDown,
  ProductRequirementsFilterDropDown,
} from '@hoot/components/form/filterDropDown/product/ProductRequirementsFilter';
import { ActiveProductStatusFilterDropDown, ProductStatusFilterDropDown } from '@hoot/components/form/filterDropDown/product/ProductStatusFilter';
import DateFilter, { ActiveDateFilter } from '../../../components/form/filterDropDown/common/DateFilter';

export interface ProductsFilters {
  status?: ProductStatus[];
  startsAt?: string;
  endsAt?: string;
  requirements?: HootQualification[];
}

export const ProductsTableFiltersContainer = (props: { value?: ProductsFilters | null; onChange?: (filters: ProductsFilters) => void }) => {
  const { value, onChange } = props;

  const handleChange = (property: keyof ProductsFilters) => (val: string | string[] | number) => {
    if (onChange) {
      if (value) {
        onChange({ ...value, [property]: val });
      } else {
        onChange({ [property]: val });
      }
    }
  };

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item sx={{ marginRight: '8px' }}>
        <Typography variant="bodySmall">Filters</Typography>
      </Grid>

      <ProductStatusFilterDropDown onChange={handleChange('status')} value={value?.status || []} popOverButtonSx={{ ml: 0, mr: '8px' }} />

      <DateFilter
        title="Starts At"
        onChange={(val) => handleChange('startsAt')(val.toFormat('dd/LL/yyyy'))}
        value={value?.startsAt ? DateTime.fromFormat(value.startsAt, 'dd/LL/yyyy') : undefined}
        popOverButtonSx={{ ml: 0, mr: '8px' }}
      />

      <DateFilter
        title="Ends At"
        onChange={(val) => handleChange('endsAt')(val.toFormat('dd/LL/yyyy'))}
        value={value?.endsAt ? DateTime.fromFormat(value.endsAt, 'dd/LL/yyyy') : undefined}
        popOverButtonSx={{ ml: 0, mr: '8px' }}
      />

      <ProductRequirementsFilterDropDown
        onChange={handleChange('requirements')}
        value={value?.requirements || []}
        popOverButtonSx={{ ml: 0, mr: '8px' }}
      />
    </Grid>
  );
};

export const ActiveProductsTableFiltersContainer = (props: { value?: ProductsFilters | null; onChange?: (filters: ProductsFilters) => void }) => {
  const { value, onChange } = props;

  const handleChange = (property: keyof ProductsFilters) => (updatedValues: any | any[]) => {
    const updatedFilters = { ...value };
    updatedFilters[property] = updatedValues;
    if (onChange) {
      onChange(updatedFilters);
    }
  };

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item sx={{ marginRight: '8px' }}>
        <Typography variant="bodySmall">Active</Typography>
      </Grid>
      <Grid
        item
        sx={{
          '& > div': {
            marginRight: '8px',
          },
        }}
      >
        <ActiveProductStatusFilterDropDown value={value?.status} onChange={handleChange('status')} />
        <ActiveDateFilter label="Starts At" value={value?.startsAt} onChange={handleChange('startsAt')} />
        <ActiveDateFilter label="Ends At" value={value?.endsAt} onChange={handleChange('endsAt')} />
        <ActiveProductRequirementsFilterDropDown value={value?.requirements} onChange={handleChange('requirements')} />
      </Grid>
    </Grid>
  );
};
