import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../../queryKeys';

export interface TeacherReliabilityScoreInput {
  [key: string]: number;
}

export interface TeacherReliabilityScoreResponse {
  id: string;
  trsDate: string;
  inputs: TeacherReliabilityScoreInput[];
  calculatedScore: number;
}

const getTeacherReliabilityScore = async (teacherAccountId: string) => {
  const { data } = await axios.get<TeacherReliabilityScoreResponse[]>(
    `${config.apiUrl}/admin/teacher-reliability-score/teacher-account/${teacherAccountId}`,
  );
  return data;
};

export default function useGetTeacherReliabilityScore(
  teacherAccountId: string,
  options?: Omit<UseQueryOptions<TeacherReliabilityScoreResponse[], AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.TeacherReliabilityScore, teacherAccountId], () => getTeacherReliabilityScore(teacherAccountId), options);
}
