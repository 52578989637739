import { ArrowDropDown, ArrowDropUp, OpenInNew } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import React, { useEffect, useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmDeleteDialog from '@hoot/components/modals/ConfirmDeleteDialog';
import HeaderCard from '@hoot/components/ui/HeaderCard';
import { HeaderData, Table } from '@hoot/components/ui/Table';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { Subject } from '@hoot/hooks/api/library/enums';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { HootFormativeAssessmentResponse } from '@hoot/hooks/api/student-hoot-assessments/useGetStudentFormativeAssessments';
import useDeleteLessonReview from '@hoot/hooks/api/user/student/lesson-reviews/useDeleteLessonReview';
import useGetLessonReviewDetails, {
  FormativeAssessment,
  LessonReviewEngagementExternalFactors,
} from '@hoot/hooks/api/user/student/lesson-reviews/useGetLessonReviewDetails';
import { useUpdateLessonReview } from '@hoot/hooks/api/user/student/lesson-reviews/useUpdateLessonReview';
import useGetLessonWhiteboards from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboards';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import LessonWhiteboardsCard from '@hoot/pages/users/students/lesson-reviews/details/LessonWhiteboardsCard';
import { routes } from '@hoot/routes/routes';
import { FormativeAssessmentType, LessonReviewFocusType } from '../enum';
import ComprehensionFormativeAssessmentDetailsModal from './ComprehensionFormativeAssessmentDetailsModal';
import EditLessonReviewFocusModal from './EditLessonReviewFocusModal';
import FluencyFormativeAssessmentDetailsModal from './FluencyFormativeAssessmentDetailsModal';
import LettersAndSoundsFormativeAssessmentDetailsModal from './LettersAndSoundsFormativeAssessmentDetailsModal';
import WordReadingFormativeAssessmentDetailsModal from './WordReadingFormativeAssessmentDetailsModal';

interface FormativeAssessmentRow {
  title: React.ReactNode;
  type: string;
  score: string;
}

interface LessonPlanRow {
  id: string;
  title: string;
  resourceProgress: string;
  resourceType: string;
  instructionalFocus: string;
  instructionalUnit: string;
}

interface Form {
  notes: string;
}

const ActionButton = (props: { setShowConfirmDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>; canManageLessonReviews: boolean }) => {
  const { setShowConfirmDeleteDialog, canManageLessonReviews } = props;
  const [actionAnchorEl, setActionAnchorEl] = useState<Element | null>(null);
  const open = Boolean(actionAnchorEl);

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setActionAnchorEl(null);
  };

  return (
    <Stack>
      <Button
        type="button"
        color="inherit"
        variant="outlined"
        size="large"
        startIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={handleActionClick}
      >
        Action
      </Button>
      <Menu
        open={open}
        anchorEl={actionAnchorEl}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: 1 }}
      >
        <MenuItem disabled={!canManageLessonReviews} onClick={() => setShowConfirmDeleteDialog(true)}>
          Delete Lesson Review
        </MenuItem>
      </Menu>
    </Stack>
  );
};

const LessonReviewDetails = () => {
  const { studentProfileId, lessonReviewId } = useParams();
  const { setIsDirty } = useBlockIfDirty();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { success, error } = useAlert();
  usePageTitle('Lesson Review Details');

  const { scopes } = useAuth();
  const canManageLessonReviews = !!scopes.find((s) => s === HootEmployeeScope.ManageLessonReviews);

  const [selectedWordReadingFormativeAssessment, setSelectedWordReadingFormativeAssessment] = useState<HootFormativeAssessmentResponse | undefined>();
  const [selectedFluencyFormativeAssessment, setSelectedFluencyFormativeAssessment] = useState<HootFormativeAssessmentResponse | undefined>();
  const [selectedComprehensionFormativeAssessment, setSelectedComprehensionFormativeAssessment] = useState<
    HootFormativeAssessmentResponse | undefined
  >();
  const [selectedLettersAndSoundsFormativeAssessment, setSelectedLettersAndSoundsFormativeAssessment] = useState<
    HootFormativeAssessmentResponse | undefined
  >();

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<boolean>(false);
  const [selectedFocusType, setSelectedFocusType] = useState<LessonReviewFocusType | undefined>();

  const formativeAssessmentHeaders: HeaderData<FormativeAssessmentRow>[] = [
    { name: 'Resource Used', property: 'title', width: '40%' },
    { name: 'Type', property: 'type', width: '30%' },
    { name: 'Score', property: 'score', width: '30%' },
  ];

  const lessonPlanHeaders: HeaderData<LessonPlanRow>[] = [
    { name: 'Assessment ID', property: 'id', isHidden: true },
    { name: 'Title', property: 'title' },
    { name: 'Resource Progress', property: 'resourceProgress' },
    { name: 'Resource Type', property: 'resourceType' },
    { name: 'Instructional Focus', property: 'instructionalFocus' },
    { name: 'Instructional Unit', property: 'instructionalUnit' },
  ];

  const { control, handleSubmit, setValue, formState, reset } = useForm<Form>({
    defaultValues: {
      notes: '',
    },
    mode: 'onSubmit',
  });

  const { data, isLoading: isLoadingLessonReview } = useGetLessonReviewDetails(lessonReviewId ?? '', {
    onSuccess: (lessonReviewData) => {
      setValue('notes', lessonReviewData.notes);
    },
  });
  const lessonId = data?.lessonId;

  const { data: lessonWhiteboards, isLoading: isLoadingLessonWhiteboards } = useGetLessonWhiteboards(lessonId ?? '', {
    enabled: !!lessonId,
  });

  const updateLessonReviewNote = useUpdateLessonReview(lessonReviewId);

  const deleteLessonReview = useDeleteLessonReview({
    onSuccess: async () => {
      setShowConfirmDeleteDialog(false);
      success(`Lesson Review successfully deleted!`);
      navigate(routes.users.students.lessonReviews.url(studentProfileId!));
      await queryClient.invalidateQueries([QueryKey.GetLessonReviewDetails, lessonReviewId]);
    },
    onError: () => error('Error deleting lesson review!'),
  });

  const lessonPlanTableData = data?.lessonPlan ?? [];
  const primaryFocusItems = data?.primaryFocus;
  const secondaryFocusItems = data?.secondaryFocus;

  const lessonReviewVersion = data?.version;

  const onSubmit = async (form: Form) => {
    updateLessonReviewNote.mutate(form, {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.GetLessonReviewDetails, lessonReviewId]);
        success(`Lesson review note updated.`);
      },
      onError: () => {
        error('There was an error submitting.');
      },
    });
    reset();
  };

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty, setIsDirty]);

  if (isLoadingLessonReview) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  const mapFormativeAssessment = (formativeAssessment: FormativeAssessment): HootFormativeAssessmentResponse => {
    return {
      lessonReviewId: lessonReviewId ?? '',
      assessedById: '',
      assessedByPrefixedNumber: '',
      bookId: formativeAssessment.id,
      bookTitle: '',
      lastUpdatedAt: 0,
      formativeAssessmentType: formativeAssessment.formativeAssessmentType,
    };
  };

  const handleFormativeAssessmentClick = (formativeAssessment: FormativeAssessment) => {
    const mappedFormativeAssessment = mapFormativeAssessment(formativeAssessment);
    if (formativeAssessment.formativeAssessmentType === FormativeAssessmentType.Fluency) {
      setSelectedFluencyFormativeAssessment(mappedFormativeAssessment);
    } else if (formativeAssessment.formativeAssessmentType === FormativeAssessmentType.Comprehension) {
      setSelectedComprehensionFormativeAssessment(mappedFormativeAssessment);
    } else if (formativeAssessment.formativeAssessmentType === FormativeAssessmentType.WordReading) {
      setSelectedWordReadingFormativeAssessment(mappedFormativeAssessment);
    } else {
      setSelectedLettersAndSoundsFormativeAssessment(mappedFormativeAssessment);
    }
  };

  const formativeAssessmentTableData =
    data?.formativeAssessments?.map<FormativeAssessmentRow>((fa) => ({
      title: (
        <Typography
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            '&:hover': {
              color: '#1976D2',
            },
          }}
          variant="bodyMedium"
          onClick={() => handleFormativeAssessmentClick(fa)}
        >
          {fa.title}
        </Typography>
      ),
      type: [FormativeAssessmentType.LettersAndSounds, FormativeAssessmentType.WordReading].includes(fa.formativeAssessmentType)
        ? 'Quick Checks'
        : capitalCase(fa.formativeAssessmentType),
      score: fa.score,
    })) ?? [];

  const handleDeleteLessonReview = () => {
    if (lessonReviewId) {
      deleteLessonReview.mutate(lessonReviewId);
    }
  };

  const Notes = (props: { field: ControllerRenderProps<Form, 'notes'> }) => {
    if (!canManageLessonReviews) {
      return <Typography variant="bodyMedium">{props.field?.value ?? ''}</Typography>;
    }
    return (
      <TextField
        {...props.field}
        value={props.field?.value ?? ''}
        fullWidth
        label="Notes"
        multiline
        variant="outlined"
        disabled={!canManageLessonReviews}
      />
    );
  };

  const StudentEngagementExternalFactors = (props: { externalFactors: LessonReviewEngagementExternalFactors }) => {
    const items: string[] = [];

    if (props.externalFactors.classroomOrSurroundingsWereLoud) {
      items.push('Their classroom or surroundings were loud.');
    }
    if (props.externalFactors.peopleInSurroundingsDistracting) {
      items.push('There were people in their surroundings distracting them.');
    }
    if (props.externalFactors.audioOrVideoIssues) {
      items.push('There was audio or video issues in the lesson.');
    }

    return (
      <Stack>
        {items.map((item, idx) => (
          <Typography key={`${item}-${idx}`} variant="bodyMedium">
            {item}
          </Typography>
        ))}
      </Stack>
    );
  };

  return (
    <>
      <Box sx={{ height: '2px' }}>{isLoadingLessonWhiteboards && <LinearProgress />}</Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HeaderCard
          title={'Lesson Review Details'}
          subtitle={data?.lessonReviewDate}
          showNavigateBackButton
          actionButtons={
            <>
              <ActionButton setShowConfirmDeleteDialog={setShowConfirmDeleteDialog} canManageLessonReviews={canManageLessonReviews} />
              <Button size="large" variant="contained" type="submit" disabled={!formState.isDirty || !canManageLessonReviews}>
                Save
              </Button>
            </>
          }
        />
        <Box sx={{ width: '100%', display: 'flex', gap: '24px' }}>
          <Box sx={{ width: '25%', paddingTop: '24px' }}>
            <Grid container rowSpacing={'24px'}>
              <Grid item lg={12}>
                <Card color="white" sx={{ width: '100%' }}>
                  <CardHeader title="Details" sx={{ pl: '22px', pb: 0 }} />
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                      gap: '18px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="titleMedium">Student</Typography>
                        <Typography variant="bodyMedium">{data?.studentPrefixedNumber}</Typography>
                      </Box>
                      <IconButton onClick={() => navigate(routes.users.students.details.url(studentProfileId!))}>
                        <OpenInNew />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="titleMedium">Teacher</Typography>
                        <Typography variant="bodyMedium">{data?.teacherPrefixedNumber}</Typography>
                      </Box>
                      <IconButton onClick={() => navigate(routes.users.teachers.details.url(data?.teacherAccountId ?? ''))}>
                        <OpenInNew />
                      </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="titleMedium">Lesson Date</Typography>
                      <Typography variant="bodyMedium">{data?.lessonDate}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="titleMedium">Lesson Number</Typography>
                        <Typography variant="bodyMedium">{data?.lessonNumber}</Typography>
                      </Box>
                      <IconButton onClick={() => navigate(routes.lessons.details.url(data?.lessonId ?? ''))}>
                        <OpenInNew />
                      </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="titleMedium">Lesson Type</Typography>
                      <Typography variant="bodyMedium">{data?.lessonType}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="titleMedium">Subject</Typography>
                      <Typography variant="bodyMedium">{data?.subject}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="titleMedium">Formative Assessment</Typography>
                      <Typography variant="bodyMedium">{data?.formativeAssessment}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="titleMedium">Student Engagement</Typography>
                      <Typography variant="bodyMedium">{data?.studentEngagement}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="titleMedium">External Factors</Typography>

                      {data?.studentEngagementExternalFactors ? (
                        <StudentEngagementExternalFactors externalFactors={data?.studentEngagementExternalFactors} />
                      ) : (
                        <Typography variant="bodyMedium">N/A</Typography>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={12}>
                <Card color="white" sx={{ width: '100%' }}>
                  <CardHeader title="Lesson Note" sx={{ pl: '22px', pb: 0 }} />
                  <CardContent>
                    <Controller name="notes" control={control} render={({ field }) => <Notes field={field} />} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ width: '75%', paddingTop: '24px' }}>
            <Grid container item lg={12} spacing={'24px'}>
              {!!formativeAssessmentTableData.length ? (
                <Grid item lg={12}>
                  <Card color="white" sx={{ width: '100%', padding: '22px' }}>
                    <CardHeader title="Formative Assessment" sx={{ paddingLeft: '0px', paddingTop: '0px' }} />
                    <Table headers={formativeAssessmentHeaders} data={formativeAssessmentTableData} />
                  </Card>
                </Grid>
              ) : null}
              <Grid item lg={6}>
                <Card color="white" sx={{ width: '100%', height: '100%', paddingLeft: '22px' }}>
                  <CardHeader
                    title={
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="titleLarge">Primary Focus</Typography>
                        <Button
                          size="large"
                          variant="contained"
                          disabled={!canManageLessonReviews}
                          onClick={() => setSelectedFocusType(LessonReviewFocusType.Primary)}
                        >
                          Edit
                        </Button>
                      </Box>
                    }
                    sx={{ pl: '0px' }}
                  />
                  <Typography variant="titleMedium">{data?.primaryFocus.focus}</Typography>
                  <List dense sx={{ pt: '24px' }}>
                    {primaryFocusItems?.focusBooks?.map((focusItem, index) => (
                      <>
                        <ListItem
                          key={focusItem.title}
                          sx={{
                            padding: '8px 24px 8px 16px',
                          }}
                        >
                          <ListItemIcon>
                            <img src={'/images/book-outline.svg'} alt="Book Icon" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography component="div" variant="bodyLarge">
                                {focusItem.title}
                              </Typography>
                            }
                            secondary={
                              <Stack>
                                <Typography variant="bodyMedium">{focusItem.readingLevel}</Typography>
                                {focusItem.reader ? <Typography variant="bodyMedium">Reader: {capitalCase(focusItem.reader)}</Typography> : null}
                              </Stack>
                            }
                          />
                          {focusItem.isFinished ? <Typography variant="labelSmall">Finished</Typography> : null}
                        </ListItem>
                        {index < primaryFocusItems?.focusBooks?.length - 1 ? <Divider sx={{ marginRight: '22px' }} /> : null}
                      </>
                    ))}
                  </List>
                </Card>
              </Grid>
              {data?.secondaryFocus ? (
                <Grid item lg={6}>
                  <Card color="white" sx={{ width: '100%', height: '100%', paddingLeft: '22px', pb: 0 }}>
                    <CardHeader
                      title={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography variant="titleLarge">Secondary Focus</Typography>
                          <Button
                            size="large"
                            variant="contained"
                            disabled={!canManageLessonReviews}
                            onClick={() => setSelectedFocusType(LessonReviewFocusType.Secondary)}
                          >
                            Edit
                          </Button>
                        </Box>
                      }
                      sx={{ pl: '0px' }}
                    />
                    <Typography variant="titleMedium">{data?.secondaryFocus.focus}</Typography>
                    <List dense sx={{ pt: '24px' }}>
                      {secondaryFocusItems?.focusBooks?.map((focusItem, index) => (
                        <>
                          <ListItem
                            key={focusItem.title}
                            sx={{
                              padding: '8px 24px 8px 16px',
                            }}
                          >
                            <ListItemIcon>
                              <img src={'/images/book-outline.svg'} alt="Book Icon" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography component="div" variant="bodyLarge">
                                  {focusItem.title}
                                </Typography>
                              }
                              secondary={
                                <Stack>
                                  <Typography component="h5" variant="bodyMedium">
                                    {focusItem.readingLevel}
                                  </Typography>
                                  {focusItem.reader ? <Typography variant="bodyMedium">Reader: {capitalCase(focusItem.reader)}</Typography> : null}
                                </Stack>
                              }
                            />
                            {focusItem.isFinished ? (
                              <Typography component="h5" variant="labelSmall">
                                Finished
                              </Typography>
                            ) : null}
                          </ListItem>
                          {index < secondaryFocusItems?.focusBooks.length - 1 ? <Divider sx={{ marginRight: '22px' }} /> : null}
                        </>
                      ))}
                    </List>
                  </Card>
                </Grid>
              ) : null}
              <Grid item lg={12}>
                <Card color="white" sx={{ width: '100%', padding: '22px' }}>
                  <CardHeader title="Lesson Plan Snapshot" sx={{ paddingLeft: '0px', paddingTop: '0px' }} />
                  <Table headers={lessonPlanHeaders} data={lessonPlanTableData} />
                </Card>
              </Grid>
              {lessonWhiteboards && (lessonWhiteboards?.length ?? 0) > 0 && (
                <Grid item xs={12}>
                  <LessonWhiteboardsCard whiteboards={lessonWhiteboards} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </form>
      <ConfirmDeleteDialog
        open={showConfirmDeleteDialog}
        onCancel={() => setShowConfirmDeleteDialog(false)}
        onContinue={handleDeleteLessonReview}
        title="Delete Lesson Review?"
        bodyText="Are you sure you want to delete this lesson review?"
      />
      <WordReadingFormativeAssessmentDetailsModal
        open={!!selectedWordReadingFormativeAssessment}
        handleClose={() => setSelectedWordReadingFormativeAssessment(undefined)}
        formativeAssessment={selectedWordReadingFormativeAssessment}
      />
      <FluencyFormativeAssessmentDetailsModal
        open={!!selectedFluencyFormativeAssessment}
        handleClose={() => setSelectedFluencyFormativeAssessment(undefined)}
        formativeAssessment={selectedFluencyFormativeAssessment}
      />
      <ComprehensionFormativeAssessmentDetailsModal
        open={!!selectedComprehensionFormativeAssessment}
        handleClose={() => setSelectedComprehensionFormativeAssessment(undefined)}
        formativeAssessment={selectedComprehensionFormativeAssessment}
      />
      <LettersAndSoundsFormativeAssessmentDetailsModal
        open={!!selectedLettersAndSoundsFormativeAssessment}
        handleClose={() => setSelectedLettersAndSoundsFormativeAssessment(undefined)}
        formativeAssessment={selectedLettersAndSoundsFormativeAssessment}
      />
      <EditLessonReviewFocusModal
        open={!!selectedFocusType}
        handleClose={() => setSelectedFocusType(undefined)}
        subject={data?.subject.toUpperCase() as Subject}
        focusType={selectedFocusType!}
        lessonReviewId={lessonReviewId}
        lessonReviewVersion={lessonReviewVersion}
      />
    </>
  );
};

export default LessonReviewDetails;
