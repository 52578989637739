import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Link,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import { ChangeEvent, Dispatch, useState } from 'react';
import { useQueryClient } from 'react-query';
import { TABLE_ROW_HOVER_COLOUR } from '@hoot/common/constants';
import { ENABLED_ACCOUNTS } from '@hoot/components/hfs-utils';
import { useAlert } from '../../contexts/AlertContext';
import { useAuth } from '../../contexts/Auth/AuthContext';
import { HootEmployeeScope } from '../../contexts/Auth/enums/hoot-employee.scope';
import useCreateAccount, { AddAccountRequest } from '../../hooks/api/common/useCreateAccount';
import useGetAccounts from '../../hooks/api/common/useGetAccounts';
import useUpdateAccount, { UpdateAccountRequest } from '../../hooks/api/common/useUpdateAccount';
import { QueryKey } from '../../hooks/api/queryKeys';
import PermissionDenied from '../home/PermissionDenied';

export enum Flags {
  Active = 'Active',
  AdditionalBackgroundCheck = 'AdditionalBackgroundCheck',
}

interface IRow {
  id: string;
  name: string;
  active: boolean;
  additionalBackgroundCheck: boolean;
}

interface ITabPanel {
  rows: IRow[];
}

type Order = 'asc' | 'desc';

function RowItem(props: { row: IRow; disabled?: boolean }) {
  const { row, disabled } = props;
  const { success, error } = useAlert();

  const [openEdit, setOpenEdit] = useState(false);
  const [editFlag, setEditFlag] = useState<Flags | undefined>(undefined);
  const [applyEditChanges, setApplyEditChanges] = useState(false);

  const updateAccount = useUpdateAccount(row.id);

  const queryClient = useQueryClient();

  const mutatedFlag = () => {
    if (editFlag === Flags.Active) {
      return { active: !row.active };
    }
    if (editFlag === Flags.AdditionalBackgroundCheck) {
      return {
        active: row.active,
        additionalBackgroundCheck: !row.additionalBackgroundCheck,
      };
    }
  };

  function modifyAccount() {
    updateAccount.mutate({ ...mutatedFlag() } as UpdateAccountRequest, {
      onSuccess: () => {
        setOpenEdit(false);
        setEditFlag(undefined);
        setApplyEditChanges(false);
        success(`Success! ${row.name}  updated.`);
        queryClient.invalidateQueries(QueryKey.GetAccounts);
      },
      onError: () => {
        setOpenEdit(false);
        setEditFlag(undefined);
        setApplyEditChanges(false);
        error(`Failed to update ${row.name}`);
      },
    });
  }

  return (
    <TableRow
      key={row.name}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell component="th" scope="row" sx={{ width: '1000px', paddingLeft: '60px' }}>
        {row.name}
      </TableCell>
      <TableCell component="th" scope="row">
        <Switch
          disableRipple
          sx={{ marginLeft: '20px' }}
          disabled={disabled}
          checked={row.active}
          onClick={() => {
            setOpenEdit(true);
            setEditFlag(Flags.Active);
          }}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <Switch
          disableRipple
          sx={{ marginLeft: '20px' }}
          disabled={disabled}
          checked={row.additionalBackgroundCheck}
          onClick={() => {
            setOpenEdit(true);
            setEditFlag(Flags.AdditionalBackgroundCheck);
          }}
        />
      </TableCell>
      <DialogEdit
        modifyRow={modifyAccount}
        applyEditChanges={applyEditChanges}
        setApplyEditChanges={setApplyEditChanges}
        open={openEdit}
        setOpenEdit={setOpenEdit}
      />
    </TableRow>
  );
}

function AccountListingPanel({ rows }: ITabPanel) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IRow>('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /*  sort implementation */
  const createSortHandler = (property: keyof IRow) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof IRow) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    const A = a[orderBy] as unknown as string;
    const B = b[orderBy] as unknown as string;
    if (B.toLocaleLowerCase() < A.toLocaleLowerCase()) {
      return -1;
    }
    if (B.toLocaleLowerCase() > A.toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: string }, b: { [key in Key]: string }) => number {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return rows?.length > 0 ? (
    <Box sx={{ padding: 0 }}>
      <TableContainer>
        <Table aria-label="partner management table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingLeft: '60px' }} sortDirection={orderBy === 'name' ? order : false}>
                <TableSortLabel active={orderBy === 'name'} direction={orderBy === 'name' ? order : 'asc'} onClick={createSortHandler('name')}>
                  <Typography fontWeight={'bold'} color="text.primary">
                    Name
                  </Typography>
                  {orderBy === 'name' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <Typography fontWeight={'bold'} color="text.primary">
                  Enabled?
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={'bold'} color="text.primary">
                  Addtl BackCheck
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              '& .MuiTableRow-root:hover': {
                backgroundColor: TABLE_ROW_HOVER_COLOUR,
              },
            }}
          >
            {rows
              .sort(getComparator(order, 'name'))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <RowItem key={row.id} row={row} disabled={!ENABLED_ACCOUNTS.includes(row.name)} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  ) : (
    <Typography>No data available</Typography>
  );
}

function CloseIcon(props: { onClick: () => void }) {
  return (
    <IconButton onClick={props.onClick}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
          fill="black"
          fillOpacity="0.54"
        />
      </svg>
    </IconButton>
  );
}

function DialogAdd(props: {
  open: boolean;
  onClose: () => void;
  name: string;
  onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
  errorTextField: boolean;
  errorHelperText: string;
}) {
  const { open, onClose, name, onNameChange, onClick, errorTextField, errorHelperText } = props;

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll={'paper'}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
          <Grid
            item
            xs={11}
            direction="column"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '24px',
                paddingLeft: '50px',
              }}
            >
              Create New Account
            </Typography>
          </Grid>
          <Grid xs={1}>
            <CloseIcon onClick={onClose} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid></Grid>
        <Box
          sx={{
            width: '250px',
            marginTop: '25px',
            alignItems: 'center',
            display: 'flex',
            marginX: 'auto',
          }}
        >
          <FormControl fullWidth>
            <TextField
              error={errorTextField}
              value={name}
              onChange={onNameChange}
              id="outlined-basic"
              label="Name"
              helperText={errorHelperText}
              variant="outlined"
            />
          </FormControl>
        </Box>
        <DialogContentText
          sx={{
            width: '180px',
            alignItems: 'center',
            display: 'flex',
            flexFlow: 'row',
            marginX: 'auto',
            marginTop: '10px',
            fontSize: '8px',
          }}
          id="alert-dialog-description"
        >
          <Typography fontWeight={'light'} fontSize={'12px'}>
            Please add this Account option to the Account property in Hubspot <span style={{ fontWeight: 'bold' }}> exactly </span> as it’s written
            here.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ alignItems: 'center', display: 'flex', marginX: 'auto', marginBottom: '30px' }}>
        <Button disabled={!name} sx={{ width: '240px' }} variant="contained" size="medium" onClick={onClick}>
          <Typography color="white" variant={'subtitle2'}>
            <Box sx={{ fontWeight: 'bold', m: 1 }}>Apply</Box>
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DialogDiscard(props: {
  open: boolean;
  onClose: any;
  setDiscardOpen: Dispatch<React.SetStateAction<boolean>>;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  dismissDialogs: () => void;
}) {
  const { open, setDiscardOpen, dismissDialogs } = props;
  return (
    <Dialog
      PaperProps={{ sx: { height: '220px', width: '440px' } }}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-discard-title">
        <Grid container alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
          <Grid
            item
            xs={11}
            direction="column"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '-50px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '24px',
                paddingLeft: '50px',
              }}
            >
              Discard?
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        Closing without saving will discard your changes.
      </DialogContent>
      <DialogActions
        sx={{
          alignItems: 'center',
          display: 'flex',
          marginX: 'auto',
          marginBottom: '30px',
        }}
      >
        <Button
          variant="contained"
          color="inherit"
          sx={{ height: '35px', width: '160px' }}
          size="small"
          onClick={() => {
            dismissDialogs();
          }}
        >
          <Typography variant={'subtitle2'}>
            <Box sx={{ fontWeight: 'bold', m: 1 }} fontSize={'14px'}>
              Discard
            </Box>
          </Typography>
        </Button>
        <Button variant="contained" sx={{ height: '35px', width: '160px' }} onClick={() => setDiscardOpen(false)}>
          <Typography color="white" variant={'subtitle2'}>
            <Box sx={{ fontWeight: 'bold', m: 1, fontSize: '13px' }}>Keep Editing</Box>
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DialogEdit(props: {
  open: boolean;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  applyEditChanges: boolean;
  setApplyEditChanges: React.Dispatch<React.SetStateAction<boolean>>;
  modifyRow: () => void;
}) {
  const { open, setOpenEdit, setApplyEditChanges, modifyRow } = props;

  return (
    <Dialog
      PaperProps={{ sx: { height: '220px', width: '440px' } }}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-discard-title">
        <Grid container alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
          <Grid
            item
            xs={11}
            direction="column"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '-50px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '24px',
                paddingLeft: '50px',
              }}
            >
              Apply?
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        Changes will apply immediately.
      </DialogContent>
      <DialogActions
        sx={{
          alignItems: 'center',
          display: 'flex',
          marginX: 'auto',
          marginBottom: '30px',
        }}
      >
        <Button
          variant="contained"
          color="inherit"
          sx={{ height: '35px', width: '160px' }}
          size="small"
          onClick={() => {
            setOpenEdit(false);
            setApplyEditChanges(false);
          }}
        >
          <Typography variant={'subtitle2'}>
            <Box sx={{ fontWeight: 'bold', m: 1 }} fontSize={'14px'}>
              Cancel
            </Box>
          </Typography>
        </Button>
        <Button
          variant="contained"
          sx={{ height: '35px', width: '160px' }}
          onClick={() => {
            setApplyEditChanges(true);
            setOpenEdit(false);
            modifyRow();
          }}
        >
          <Typography color="white" variant={'subtitle2'}>
            <Box sx={{ fontWeight: 'bold', m: 1, fontSize: '13px' }}>Apply</Box>
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function PartnerManagement() {
  const [open, setOpen] = React.useState(false);
  const [openDiscard, setDiscardOpen] = React.useState(false);
  const [name, setName] = useState<string>('');
  const [errorName, setErrorName] = useState<string>('');

  const accountsQuery = useGetAccounts(true);
  const createAccount = useCreateAccount();

  const queryClient = useQueryClient();
  const { success } = useAlert();

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setDiscardOpen(true);
  };

  const dismissDialogs = () => {
    setOpen(false);
    setDiscardOpen(false);
    setName('');
    setErrorName('');
  };

  function addAccount(addAccountRequest: AddAccountRequest) {
    createAccount.mutate(addAccountRequest, {
      onSuccess: () => {
        setName('');
        success(`Success! ${addAccountRequest.name}  added.`);
        setOpen(false);
        queryClient.invalidateQueries(QueryKey.GetAccounts);
      },
      onError: (errorData) => {
        setErrorName(errorData?.response?.data?.message ?? `Failed to add ${addAccountRequest.name}.`);
      },
    });
  }

  const handleSubmit = () => {
    const addAccountRequest: AddAccountRequest = { name };
    addAccount(addAccountRequest);
  };
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const { scopes } = useAuth();
  const accessGranted = !!scopes.find((s) => s === HootEmployeeScope.AccessPartnerManagement);

  if (!accessGranted) {
    return <PermissionDenied />;
  }
  return (
    <Container
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        padding: 0,
        '& .MuiTableCell-head': {
          fontWeight: 'bold',
        },
      }}
    >
      <Paper
        sx={{
          width: '100%',
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          <h2
            style={{
              marginTop: '32px',
              marginLeft: '50px',
              textTransform: 'uppercase',
            }}
          >
            Partner Management
          </h2>
          <Button
            sx={{
              height: '36px',
              width: '105px',
              marginTop: '32px',
              marginRight: '50px',
            }}
            variant="contained"
            size="medium"
            onClick={handleDialogOpen}
          >
            <Typography color="white" variant={'subtitle2'}>
              <Box sx={{ fontWeight: 'bold', m: 1 }}>+ New</Box>
            </Typography>
          </Button>
        </div>
        <div
          style={{
            marginTop: '8px',
            marginLeft: '50px',
          }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Typography color="text.primary">Partner Management</Typography>
          </Breadcrumbs>
        </div>
        <Box
          sx={{
            width: '100%',
            typography: 'body1',
            marginTop: '20px',
          }}
        >
          <AccountListingPanel rows={accountsQuery.data as IRow[]} />
        </Box>
        <DialogAdd
          open={open}
          onClose={handleDialogClose}
          name={name}
          onNameChange={handleNameChange}
          onClick={handleSubmit}
          errorHelperText={errorName}
          errorTextField={!!errorName}
        />
        <DialogDiscard
          open={openDiscard}
          onClose={dismissDialogs}
          setDiscardOpen={setDiscardOpen}
          setOpen={setOpen}
          dismissDialogs={dismissDialogs}
        />
      </Paper>
    </Container>
  );
}

export default PartnerManagement;
