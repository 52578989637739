import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface CsvProject {
  id: string;
  projectCode: string;
}

export interface CsvProjects {
  projects: CsvProject[];
}

async function getCsvProjects() {
  const {
    data: { projects },
  } = await axios.get<CsvProjects>(`${config.apiUrl}/admin/teacher-invoicing/csv-projects`);
  return projects;
}

export default function useCsvProjects() {
  return useQuery(QueryKey.GetInvoiceCsvUploadProject, () => getCsvProjects());
}
