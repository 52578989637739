import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { SkipNextOutlined, SkipPreviousOutlined, Today } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, FormGroup, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { ScheduleType } from '@hoot/pages/lessons/reschedule-v2/same-teacher-flow/RescheduleWizardSchedulesStep';
import TableHeader from './weekly-schedule/TableHeader';
import TimeSlots from './weekly-schedule/TimeSlots';
import YAxisLabels from './weekly-schedule/YAxisLabels';
import { CELL_HEIGHT } from './weekly-schedule/constants';
import {
  AvailabilityExceptionSlot,
  AvailabilityTimeSlot,
  LessonPreferencesSlot,
  LessonTimeSlot,
  SelectedItem,
  ShiftTimeSlot,
} from './weekly-schedule/interfaces';

interface WeeklyScheduleProps {
  startOfWeek: DateTime;
  onStartOfWeekChange: (startOfWeek: DateTime) => void;
  availability?: AvailabilityTimeSlot[];
  availabilityExceptions?: AvailabilityExceptionSlot[];
  shifts?: ShiftTimeSlot[];
  lessons: LessonTimeSlot[];
  lessonPreferences?: LessonPreferencesSlot[];
  selectedItem?: SelectedItem;
  onSelected: (selectedItem: SelectedItem) => void;
  selectedTimeSlot?: DateTime;
  updateSelectedTimeSlot?: (timeslot: DateTime) => void;
  disableLessons?: boolean;
  scheduleType: ScheduleType;
  userTimeZone?: string;
}

export function WeeklySchedule(props: WeeklyScheduleProps) {
  const calendarYAxisLabels = [
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
  ];

  const daysOfWeek = [...Array(7).keys()].map((d) => props.startOfWeek.plus({ day: d }));
  const [firstDay, lastDay] = [daysOfWeek[0].toFormat('MMM dd'), daysOfWeek[6].toFormat('MMM dd')]; // Ex: `Sep 16`
  const currentWeekRange = `${firstDay} - ${lastDay}`;

  const [showAvailability, setShowAvailability] = useState(true);
  const [showLessonPreferences, setShowLessonPreferences] = useState(true);
  const [showStudentNumbers, setShowStudentNumbers] = useState(false);
  const [showCancelled, setShowCancelled] = useState(true);
  const [hideEmptyRows, setHideEmptyRows] = useState<boolean>(true);
  const [indexCollapsedTo, setIndexCollapsedTo] = useState<number>();

  const yAxisLabelsSliced = indexCollapsedTo ? calendarYAxisLabels.slice(indexCollapsedTo) : calendarYAxisLabels;

  const COLUMN_HEIGHT = CELL_HEIGHT * yAxisLabelsSliced.length;

  const isCurrentWeek = daysOfWeek[0].toMillis() < DateTime.now().toMillis();
  const disablePreviousButton = isCurrentWeek && (props.disableLessons ?? false);

  useEffect(() => {
    if (hideEmptyRows && (!!props.lessons.length || !!props.shifts?.length)) {
      calculateRowsToCollapse();
    }
    if (hideEmptyRows && !props.lessons.length && !props.shifts?.length) {
      setIndexCollapsedTo(calendarYAxisLabels.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarYAxisLabels, hideEmptyRows, props.lessons.length, props.shifts?.length]);

  const onCurrentWeekClick = () => {
    props.onStartOfWeekChange(DateTime.now().startOf('week'));
  };

  const onPrevClick = () => {
    props.onStartOfWeekChange(props.startOfWeek.minus({ week: 1 }));
  };

  const onNextClick = () => {
    props.onStartOfWeekChange(props.startOfWeek.plus({ week: 1 }));
  };

  const handleShowAvailabilityChange = (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShowAvailability(checked);
  };

  const handleShowLessonPreferencesChange = (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShowLessonPreferences(checked);
  };

  const handleShowStudentNumbersChange = (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShowStudentNumbers(checked);
  };

  const handleShowCancelledChange = (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShowCancelled(checked);
  };

  const toggleShowHide = () => setHideEmptyRows((prev) => !prev);

  const onShowHideBtnClick = () => {
    if (hideEmptyRows) {
      setIndexCollapsedTo(0); // Setting to 0 will show all time slot rows.
    } else {
      calculateRowsToCollapse();
    }
    toggleShowHide();
  };

  const calculateRowsToCollapse = () => {
    const timesToCompare: Array<number> = [];

    // Check for earliest Lesson start time
    if (!!props.lessons.length) {
      const earliestLessonTime = props.lessons
        .map((l) => DateTime.now().set({ hour: l.startAt.hour, minute: l.startAt.minute }))
        .reduce((prev, curr) => {
          if (prev.toMillis() <= curr.toMillis()) return prev;
          return curr;
        });

      timesToCompare.push(earliestLessonTime.toMillis());
    }

    // Check for earliest Shift start time
    if (!!props.shifts?.length) {
      const earliestShiftTime = props.shifts
        .map((s) => DateTime.now().set({ hour: s.startAt.hour, minute: s.startAt.minute }))
        .reduce((prev, curr) => {
          if (prev.toMillis() <= curr.toMillis()) return prev;
          return curr;
        });

      timesToCompare.push(earliestShiftTime.toMillis());
    }

    const earliestTime = Math.min(...timesToCompare); // Return the earliest time between all lessons & shifts.
    const formattedTime = DateTime.fromMillis(earliestTime).toFormat('t'); // Format to compare with calendarYAxisLabels[] - Ex: `3:30 PM`.

    if (formattedTime === '8:00 AM') return setIndexCollapsedTo(0); // Show all rows if the 1st event begins in the 1st time slot.

    const indexToCollapseTo = calendarYAxisLabels.findIndex((x) => x === formattedTime);

    if (indexToCollapseTo < 0) {
      setIndexCollapsedTo(calendarYAxisLabels.length); // If findIndex() gives us undefined it returns -1. So we check for that here and return zero rows.
    } else {
      setIndexCollapsedTo(indexToCollapseTo);
    }
  };

  const lessons = showCancelled
    ? props.lessons
    : props.lessons.filter((l) => ![ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled].includes(l.status));

  const shifts = showCancelled ? props.shifts : props.shifts?.filter((s) => s.status !== 'CANCELLED');

  return (
    <div>
      {/* Schedule Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <div>
          <Box display="flex" gap={2} alignItems="center">
            <Typography variant="headlineLarge">Schedule</Typography>
            <Tooltip title="Go to current Week">
              <IconButton
                onClick={onCurrentWeekClick}
                sx={{
                  backgroundColor: '#1976D2',
                  '&:hover': { backgroundColor: '#4791DB' },
                }}
              >
                <Today htmlColor="#FFF" />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography variant="labelLarge" component="p">
            Calendar shown in Central Time (Winnipeg)
          </Typography>
          <Typography variant="labelLarge" component="p">
            {capitalCase(props.scheduleType)}'s Timezone is: {props.userTimeZone}
          </Typography>
        </div>

        <Box display="flex" p={2} gap={3} sx={{ backgroundColor: '#F2F2F2', borderRadius: '8px' }}>
          <Box display="flex" alignItems="center" gap={3}>
            <IconButton
              onClick={onPrevClick}
              disabled={disablePreviousButton}
              sx={{
                backgroundColor: '#1976D2',
                '&:hover': { backgroundColor: '#4791DB' },
              }}
            >
              <SkipPreviousOutlined htmlColor="#FFF" />
            </IconButton>
            <Typography fontWeight={600}>{currentWeekRange}</Typography>
            <IconButton
              onClick={onNextClick}
              sx={{
                backgroundColor: '#1976D2',
                '&:hover': { backgroundColor: '#4791DB' },
              }}
            >
              <SkipNextOutlined htmlColor="#FFF" />
            </IconButton>
          </Box>
          <Stack>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleShowCancelledChange} checked={showCancelled} size="small" />}
                label={props.scheduleType === ScheduleType.Teacher ? 'Show Cancelled' : 'Show Cancelled Lessons'}
              />
            </FormGroup>

            {props.availability ? (
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox onChange={handleShowAvailabilityChange} checked={showAvailability} size="small" />}
                  label="Show Availability"
                />
              </FormGroup>
            ) : null}

            {props.lessonPreferences ? (
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox onChange={handleShowLessonPreferencesChange} checked={showLessonPreferences} size="small" />}
                  label="Show Lesson Preferences"
                />
              </FormGroup>
            ) : null}

            {props.scheduleType === ScheduleType.Teacher ? (
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox onChange={handleShowStudentNumbersChange} checked={showStudentNumbers} size="small" />}
                  label="Use Student Numbers"
                />
              </FormGroup>
            ) : null}
          </Stack>
        </Box>
      </Box>

      <TableHeader daysOfWeek={daysOfWeek} showHide={hideEmptyRows} setShowHide={onShowHideBtnClick} />

      <Stack direction="row">
        <YAxisLabels yAxisLabelsSliced={yAxisLabelsSliced} columnHeight={COLUMN_HEIGHT} />

        {daysOfWeek.map((dayOfWeek, idx) => (
          <TimeSlots
            key={`${dayOfWeek.toMillis()}-${idx}`}
            selectedItem={props.selectedItem}
            onSelected={props.onSelected}
            dayOfWeek={dayOfWeek}
            idx={idx}
            availability={showAvailability ? props.availability : []}
            availabilityExceptions={props.availabilityExceptions}
            lessonPreferences={showLessonPreferences ? props.lessonPreferences : []}
            lessons={lessons}
            shifts={shifts}
            disableLessons={props.disableLessons}
            selectedTimeSlot={props.selectedTimeSlot}
            setSelectedTimeSlot={props.updateSelectedTimeSlot}
            scheduleType={props.scheduleType}
            showStudentNumbers={showStudentNumbers}
            yAxisLabelsSliced={yAxisLabelsSliced}
            columnHeight={COLUMN_HEIGHT}
          />
        ))}
      </Stack>
    </div>
  );
}
