import { MenuItem } from '@mui/material';
import { useAlert } from '../../../contexts/AlertContext';
import useGetPasswordResetLink from '../../../hooks/api/auth/useGetPasswordResetLink';

export function CreatePasswordResetLinkItemButton(props: { email: string; disabled?: boolean }) {
  const { email, disabled } = props;
  const alert = useAlert();

  const getPasswordResetLink = useGetPasswordResetLink(email || '', {
    enabled: false,
    cacheTime: 0,
    onSuccess: (response) => {
      window.navigator.clipboard.writeText(response.link);
      alert.success('Password reset link copied to clipboard');
    },
  });

  const handleCreatePasswordResetLink = () => {
    getPasswordResetLink.refetch();
  };

  return (
    <MenuItem onClick={handleCreatePasswordResetLink} disabled={disabled}>
      Create Password Reset Link
    </MenuItem>
  );
}

export default CreatePasswordResetLinkItemButton;
