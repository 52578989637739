import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TeacherInvoiceBillableTasksPageQueryParams } from '@hoot/pages/teacher-invoices/interfaces/TeacherInvoiceBillableTasksPageQueryFilterParams';
import SearchTextField from '../../components/form/SearchTextField';

const TeacherInvoiceBillableTasksSearchInput = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchInput, setSearchInput] = useState(searchParams.get(TeacherInvoiceBillableTasksPageQueryParams.Search) ?? '');

  const onSearchInputChanged = (searchInput: string) => {
    setSearchInput(searchInput);
  };

  // When search input is debounced, then update the search query arg in the URL.
  const onSearchInputDebounced = (searchInput: string) => {
    setSearchParams(
      (prev) => {
        const newSearchParams = new URLSearchParams(prev);
        newSearchParams.set(TeacherInvoiceBillableTasksPageQueryParams.Search, searchInput);
        // Reset to the initial page when search criteria changes, else result set may become out of sync.
        newSearchParams.set(TeacherInvoiceBillableTasksPageQueryParams.Page, '1');
        return newSearchParams;
      },
      { replace: true },
    );
  };

  return (
    <>
      <SearchTextField searchInput={searchInput} onSearchInputChanged={onSearchInputChanged} onSearchInputDebounced={onSearchInputDebounced} />
    </>
  );
};

export default TeacherInvoiceBillableTasksSearchInput;
