import { ChevronLeftOutlined } from '@mui/icons-material';
import { Box, Button, Card, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField } from '../../components/form/TextField';
import { useAlert } from '../../contexts/AlertContext';
import { BookType, DecodableFormat, Language, SpellingType, Subject } from '../../hooks/api/library/enums';
import useGetBook, { BookResponse } from '../../hooks/api/library/useGetBook';
import useUpdateBook, { UpdateBookRequest } from '../../hooks/api/library/useUpdateBook';
import { QueryKey } from '../../hooks/api/queryKeys';
import { ReadingLevelType } from '../lessons/enums';
import {
  ActiveSelect,
  CollectionsAutocomplete,
  ContentTagsAutocomplete,
  DecodableFormatSelect,
  FilterLevelPrioritizedSkillAutocomplete,
  FormatSelect,
  GenreSelect,
  InstructionalFocusAutocomplete,
  InstructionalUnitAutocomplete,
  LanguageSelect,
  LevelSelect,
  LevellingSystemSelect,
  PublisherSelect,
  ResourceTypeSelect,
  SeriesSelect,
  SpellingTypeSelect,
  SubGenreSelect,
  SubThemeAutocomplete,
  SubTopicAutocomplete,
  SubjectSelect,
  ThemeAutocomplete,
  TopicAutcomplete,
  YesNoSelect,
} from './LibrarySearchFilters';

interface Form {
  title: string;
  isbn: string;
  isActive: boolean;
  publisher: string;
  description: string;
  minAge: number | '';
  maxAge: number | '';
  subject: Subject;
  language: Language;
  resourceType: BookType | '';
  decodableFormat: DecodableFormat | '';
  instructionalFocus: string[];
  instructionalUnit: string[];
  prioritizedSkill: string[];
  genre: string | '';
  subGenre: string | '';
  topic: string[];
  subTopic: string[];
  theme: string[];
  subTheme: string[];
  format: string | '';
  series: string | '';
  nonConforming: boolean;
  hiLo: boolean;
  levellingSystem: ReadingLevelType;
  level: string;
  collections: string[];
  contentTags: string[];
  wordCount: number | '';
  spellingType: SpellingType | '';
  seq: number | '';
  isDecodableLibrary: boolean;
  isDoublePageEnabled: boolean;
  isStandardDoublePage: boolean;
}

export function BookDetails() {
  const { bookId } = useParams() as { bookId: string };
  const { data: book, isFetching } = useGetBook(bookId);

  if (!book) {
    return <CircularProgress />;
  }

  return (
    <Stack spacing="16px">
      <BookDetailsCards book={book} isFetching={isFetching} />
    </Stack>
  );
}

function BookDetailsCards(props: { isFetching: boolean; book: BookResponse }) {
  const { book } = props;
  const { success, error } = useAlert();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const updateBookMutation = useUpdateBook(book.id);

  const { control, handleSubmit, watch } = useForm<Form>({
    defaultValues: {
      isActive: book.isActive,
      isbn: book.isbn,
      title: book.title,
      publisher: book.publisher.id,
      description: book.description ?? '',
      minAge: book.minAge ?? '',
      maxAge: book.maxAge ?? '',
      subject: book.subject,
      language: book.language,
      resourceType: book.resourceType ?? '',
      decodableFormat: book.decodableFormatId ?? '',
      instructionalFocus: book.instructionalFocus?.map((i) => i.id) ?? [],
      instructionalUnit: book.instructionalUnits?.map((i) => i.id) ?? [],
      prioritizedSkill: book.prioritizedSkill?.map((p) => p.id) ?? [],
      genre: book.genre ?? '',
      subGenre: book.subGenre ?? '',
      topic: book.topic?.map((t) => t.id) ?? [],
      subTopic: book.subTopic?.map((t) => t.id) ?? [],
      theme: book.theme?.map((t) => t.id) ?? [],
      subTheme: book.subTheme?.map((t) => t.id) ?? [],
      format: book.format ?? '',
      series: book.series ?? '',
      nonConforming: book.nonConforming,
      hiLo: book.hiLo,
      levellingSystem: book.readingLevel.type,
      level: book.readingLevel.id,
      contentTags: book.contentTags?.map((c) => c.id) ?? [],
      collections: book.collections?.map((c) => c.id) ?? [],
      wordCount: book.wordCount ?? '',
      spellingType: book.spellingType ?? '',
      seq: book.seq ?? '',
      isDecodableLibrary: book.isDecodableLibrary,
      isDoublePageEnabled: book.isDoublePageEnabled,
      isStandardDoublePage: book.isStandardDoublePage,
    },
  });

  const { levellingSystem } = watch();

  const handleBackClick = () => {
    navigate(-1);
  };

  const onSubmit = (data: Form) => {
    const updateBookRequest: UpdateBookRequest = {
      title: data.title,
      isbn: data.isbn,
      isActive: data.isActive,
      publisher: data.publisher,
      description: data.description,
      minAge: data.minAge !== '' ? data.minAge : undefined,
      maxAge: data.maxAge !== '' ? data.maxAge : undefined,
      subject: data.subject,
      language: data.language,
      resourceType: data.resourceType !== '' ? data.resourceType : undefined,
      decodableFormatId: !!data.decodableFormat ? data.decodableFormat : undefined,
      instructionalFocus: data.instructionalFocus,
      instructionalUnit: data.instructionalUnit,
      prioritizedSkill: data.prioritizedSkill,
      genre: data.genre !== '' ? data.genre : undefined,
      subGenre: data.subGenre !== '' ? data.subGenre : undefined,
      topic: data.topic,
      subTopic: data.subTopic,
      theme: data.theme,
      subTheme: data.subTheme,
      format: data.format !== '' ? data.format : undefined,
      series: data.series !== '' ? data.series : undefined,
      nonConforming: data.nonConforming,
      hiLo: data.hiLo,
      level: data.level,
      collections: data.collections,
      contentTags: data.contentTags,
      wordCount: data.wordCount ? data.wordCount : undefined,
      spellingType: data.spellingType !== '' ? data.spellingType : null,
      seq: data.seq !== '' ? data.seq : undefined,
      isDecodableLibrary: data.isDecodableLibrary,
      isDoublePageEnabled: data.isDoublePageEnabled,
      isStandardDoublePage: data.isStandardDoublePage,
    };

    updateBookMutation.mutate(updateBookRequest, {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.GetBook, book.id]);
        success(`${data.title} has been successfully updated.`);
      },
      onError: () => {
        error('There was an issue updating the book.');
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ padding: '24px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box>
            <Button type="button" color="inherit" variant="contained" startIcon={<ChevronLeftOutlined />} onClick={handleBackClick} size="large">
              Back
            </Button>

            <Typography marginLeft="24px" variant="headlineLarge">
              {props.book.title}
            </Typography>
          </Box>

          <Box>
            <Button type="button" variant="contained" onClick={handleSubmit(onSubmit)} size="large">
              Save
            </Button>
          </Box>
        </Stack>
      </Card>

      <Grid mt="16px" container spacing="24px">
        <Grid item sm={9}>
          <Card sx={{ padding: '24px' }}>
            <Stack spacing="24px">
              <Typography variant="titleLarge">Primary Details</Typography>
              <Stack spacing="24px" direction="row">
                <TextField rules={{ required: 'Title is required' }} fullWidth required label="Title" name="title" control={control} />
                <TextField rules={{ required: 'ISBN is required' }} fullWidth required label="ISBN" name="isbn" control={control} />
              </Stack>

              <Controller
                name="publisher"
                control={control}
                render={({ field }) => <PublisherSelect required label="Publisher" onChange={field.onChange} value={field.value} />}
              />
              <TextField multiline rows={4} label="Description" name="description" control={control} />

              <Stack direction="row" spacing="24px">
                <TextField required fullWidth label="Min Age" name="minAge" control={control} />
                <TextField required fullWidth label="Max Age" name="maxAge" control={control} />
              </Stack>
              <Stack direction="row" spacing="24px">
                <Controller
                  control={control}
                  name="subject"
                  rules={{
                    required: 'Subject is required',
                  }}
                  render={({ field, fieldState }) => (
                    <SubjectSelect
                      required
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      label="Subject"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="language"
                  rules={{
                    required: 'Language is required',
                  }}
                  render={({ field, fieldState }) => (
                    <LanguageSelect
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      required
                      label="Language"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" spacing="24px">
                <TextField fullWidth label="Word Count" name="wordCount" type="number" control={control} />
                <Controller
                  control={control}
                  name="spellingType"
                  render={({ field }) => <SpellingTypeSelect label="Spelling Type" onChange={field.onChange} value={field.value} />}
                />
              </Stack>
              <Stack direction="row" spacing="24px">
                <TextField fullWidth label="Sequence" name="seq" type="number" control={control} />
                <Controller
                  control={control}
                  name="isDecodableLibrary"
                  render={({ field }) => <YesNoSelect label="Decodable Library" onChange={field.onChange} value={field.value} />}
                />
              </Stack>
              <Stack direction="row" spacing="24px">
                <Controller
                  control={control}
                  name="isDoublePageEnabled"
                  render={({ field }) => <YesNoSelect label="Double Page Enabled" onChange={field.onChange} value={field.value} />}
                />
                <Controller
                  control={control}
                  name="isStandardDoublePage"
                  render={({ field }) => <YesNoSelect label="Standard Double Page" onChange={field.onChange} value={field.value} />}
                />
              </Stack>
            </Stack>
          </Card>

          <Card sx={{ padding: '24px', marginTop: '24px' }}>
            <Stack spacing="24px">
              <Typography variant="titleLarge">Secondary Details</Typography>
              <Stack direction="row" spacing="24px">
                <Controller
                  control={control}
                  name="resourceType"
                  render={({ field }) => <ResourceTypeSelect label="Resource Type" onChange={field.onChange} value={field.value} />}
                />

                <Controller
                  control={control}
                  name="decodableFormat"
                  render={({ field }) => <DecodableFormatSelect label="Decodable Format" onChange={field.onChange} value={field.value} />}
                />
              </Stack>

              <Controller
                control={control}
                name="instructionalFocus"
                render={({ field }) => <InstructionalFocusAutocomplete onChange={field.onChange} value={field.value} />}
              />

              <Controller
                control={control}
                name="instructionalUnit"
                render={({ field }) => <InstructionalUnitAutocomplete onChange={field.onChange} value={field.value} />}
              />

              <Controller
                control={control}
                name="prioritizedSkill"
                render={({ field }) => <FilterLevelPrioritizedSkillAutocomplete onChange={field.onChange} value={field.value} />}
              />

              <Stack direction="row" spacing="24px">
                <Controller
                  control={control}
                  name="genre"
                  render={({ field }) => <GenreSelect label="Genre" onChange={field.onChange} value={field.value} />}
                />

                <Controller
                  control={control}
                  name="subGenre"
                  render={({ field }) => <SubGenreSelect label="Sub-Genre" onChange={field.onChange} value={field.value} />}
                />
              </Stack>

              <Stack direction="row" spacing="24px">
                <Controller
                  control={control}
                  name="topic"
                  render={({ field }) => <TopicAutcomplete onChange={field.onChange} value={field.value} />}
                />

                <Controller
                  control={control}
                  name="subTopic"
                  render={({ field }) => <SubTopicAutocomplete onChange={field.onChange} value={field.value} />}
                />
              </Stack>

              <Stack direction="row" spacing="24px">
                <Controller
                  control={control}
                  name="theme"
                  render={({ field }) => <ThemeAutocomplete onChange={field.onChange} value={field.value} />}
                />

                <Controller
                  control={control}
                  name="subTheme"
                  render={({ field }) => <SubThemeAutocomplete onChange={field.onChange} value={field.value} />}
                />
              </Stack>

              <Stack direction="row" spacing="24px">
                <Controller
                  control={control}
                  name="format"
                  render={({ field }) => <FormatSelect label="Format" onChange={field.onChange} value={field.value} />}
                />

                <Controller
                  control={control}
                  name="series"
                  render={({ field }) => <SeriesSelect label="Series" onChange={field.onChange} value={field.value} />}
                />
              </Stack>

              <Stack direction="row" spacing="24px">
                <Controller
                  control={control}
                  name="nonConforming"
                  render={({ field }) => <YesNoSelect label="Non-Conforming" onChange={field.onChange} value={field.value} />}
                />

                <Controller
                  control={control}
                  name="hiLo"
                  render={({ field }) => <YesNoSelect label="Hi-Lo" onChange={field.onChange} value={field.value} />}
                />
              </Stack>

              <Stack direction="row" spacing="24px">
                <Controller
                  control={control}
                  name="levellingSystem"
                  render={({ field }) => <LevellingSystemSelect label="Levelling System" onChange={field.onChange} value={field.value} />}
                />
                <Controller
                  control={control}
                  name="level"
                  rules={{ required: 'Level is required' }}
                  render={({ field, fieldState }) => (
                    <LevelSelect
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      label="Level"
                      levelSytem={levellingSystem}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" spacing="24px">
                <Controller
                  name="collections"
                  control={control}
                  render={({ field }) => <CollectionsAutocomplete onChange={field.onChange} value={field.value} />}
                />

                <Controller
                  name="contentTags"
                  control={control}
                  render={({ field }) => <ContentTagsAutocomplete onChange={field.onChange} value={field.value} />}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid item sm={3}>
          <Card sx={{ padding: '24px' }}>
            <Stack spacing="24px">
              <Typography variant="titleLarge">Status</Typography>
              <Controller control={control} name="isActive" render={({ field }) => <ActiveSelect onChange={field.onChange} value={field.value} />} />
            </Stack>
          </Card>

          <Card sx={{ marginTop: '24px', padding: '24px' }}>
            <Stack spacing="24px">
              <Typography variant="titleLarge">Book Cover</Typography>
              <Box
                sx={{
                  height: 'auto',
                  width: '100%',
                }}
              >
                {book.coverImageUrl ? (
                  <Box component="img" sx={{ height: '100%', width: '100%' }} src={book.coverImageUrl} alt="Cover Image" />
                ) : null}
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}
