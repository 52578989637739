import { Box, Button, Card, CardHeader, Grid, LinearProgress, ListItemText, Paper, Stack, Tooltip, Typography } from '@mui/material';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import fileDownloader from 'js-file-download';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import QueryableTablePagination from '@hoot/components/QueryableTablePagination';
import DropdownButton, { DropdownButtonMenuItem } from '@hoot/components/form/DropdownButton';
import ConfirmSubmitDialog from '@hoot/components/modals/ConfirmSubmitDialog';
import HeaderCard from '@hoot/components/ui/HeaderCard';
import { useAlert } from '@hoot/contexts/AlertContext';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import useDownloadBankingUpdatesCSVReport from '@hoot/hooks/api/teacher-invoice/useDownloadBankingUpdatesCSV';
import useDownloadInvoicesCSVReport from '@hoot/hooks/api/teacher-invoice/useDownloadInvoicesCSV';
import useUserSelectableProjects from '@hoot/hooks/api/teacher-invoice/useGetUserSelectableProjects';
import useSearchTeacherInvoiceBillableTasks, {
  InvoicePeriodDetailsResponse,
  SearchTeacherInvoiceBillableTasksQuery,
  TeacherInvoiceBillableTasksQuerySortKeyEnum,
  TeacherInvoiceBillableTasksResponse,
} from '@hoot/hooks/api/teacher-invoice/useSearchTeacherInvoiceBillableTasks';
import useSubmitFinalInvoice from '@hoot/hooks/api/teacher-invoice/useSubmitFinalInvoicePeriod';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { SortOrder } from '@hoot/interfaces/order-by';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import TeacherInvoiceBillableTasksFilters from '@hoot/pages/teacher-invoices/TeacherInvoiceBillableTasksFilters';
import TeacherInvoiceBillableTasksSearchInput from '@hoot/pages/teacher-invoices/TeacherInvoiceBillableTasksSearchInput';
import TeacherBillableTasksTable from '@hoot/pages/teacher-invoices/TeacherInvoiceBillableTasksTable';
import TeacherInvoiceBillableTasksUploadCard from '@hoot/pages/teacher-invoices/TeacherInvoiceBillableTasksUploadCard';
import { TeacherInvoiceBillableTasksPageQueryParams } from '@hoot/pages/teacher-invoices/interfaces/TeacherInvoiceBillableTasksPageQueryFilterParams';
import { routes } from '@hoot/routes/routes';
import { removeUndefinedProperties } from '@hoot/utils/remove-undefined-properties';
import { InvoicePeriodStatus } from './enums/enums';

const TeacherInvoiceBillableTasksPage = () => {
  usePageTitle('Teacher Invoicing');
  const { periodId } = useParams();
  const [searchParams] = useSearchParams();
  const { success, error } = useAlert();
  const queryClient = useQueryClient();

  const { data: projects } = useUserSelectableProjects();

  const [CSVLoading, setCSVLoading] = useState<boolean>(false);

  const [teacherInvoiceBillableTasksQuery, setTeacherInvoiceBillableTasksQuery] = useState<SearchTeacherInvoiceBillableTasksQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [teacherInvoiceBillableTasksPaginatedResponse, setTeacherInvoiceBillableTasksPaginatedResponse] = useState<
    GenericPaginatedResponse<TeacherInvoiceBillableTasksResponse, InvoicePeriodDetailsResponse>
  >({
    data: [],
    count: 0,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    extraData: undefined,
  });

  const getTeacherInvoiceBillableTasksRequest = useSearchTeacherInvoiceBillableTasks(periodId!, teacherInvoiceBillableTasksQuery, {
    enabled: !!periodId,
    retry: false,
    onSuccess: (data) => {
      setTeacherInvoiceBillableTasksPaginatedResponse({ ...data });
    },
    onError: (err) => {
      console.error(err);
      error(`An error occurred while loading invoicing periods`);
    },
  });

  const submitFinalInvoice = useSubmitFinalInvoice(periodId!);

  const invoicePeriod = teacherInvoiceBillableTasksPaginatedResponse.extraData;
  const invoiceDate = invoicePeriod?.periodStartDate ? DateTime.fromSQL(invoicePeriod?.periodStartDate).toFormat('LLLL yyyy') : '';

  const hasInvoicePeriodPassed = invoicePeriod?.isPeriodPast;
  const hasPendingBillableTasks = invoicePeriod?.invoiceHasPendingBillableTasks;
  const invoicePeriodSubmitted = invoicePeriod?.status === InvoicePeriodStatus.Submitted;
  const disableSubmit = !hasInvoicePeriodPassed || hasPendingBillableTasks || invoicePeriodSubmitted;
  const invoicesCSVReport = useDownloadInvoicesCSVReport(periodId!, {
    enabled: false,
    onSuccess: (res) => {
      const fileName = `${invoiceDate}-Invoices.csv`;
      fileDownloader(res.data, fileName);
      setCSVLoading(false);
    },
    onError: (err) => {
      setCSVLoading(false);
      console.error('Error downloading CSV', err);
    },
  });

  const bankingUpdatesCSVReport = useDownloadBankingUpdatesCSVReport(periodId!, {
    enabled: false,
    onSuccess: (res) => {
      const fileName = `${invoiceDate}-Banking-Updates.csv`;
      fileDownloader(res.data, fileName);
      setCSVLoading(false);
    },
    onError: (err) => {
      setCSVLoading(false);
      console.error('Error downloading CSV', err);
    },
  });

  const confirmBodyText =
    'This will finalize all invoice values for the billing period. This cannot be undone. Please ensure that all banking updates have been exported prior to submitting. Are you sure you want to submit?';

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const handleSubmit = () => {
    submitFinalInvoice.mutate(undefined, {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.SearchTeacherInvoiceBillableTasks);
        queryClient.invalidateQueries(QueryKey.SearchTeacherInvoiceUploadedTasks);
        success('Final Invoice Successfully Submitted');
        setShowConfirmDialog(false);
      },
      onError: () => {
        error('There was an error while submitting final invoice');
      },
    });
  };

  useEffect(() => {
    // Updating the query will trigger a re-fetch.
    setTeacherInvoiceBillableTasksQuery(() => {
      const searchText = searchParams.get(TeacherInvoiceBillableTasksPageQueryParams.Search) as string;
      const projectFilters = searchParams.getAll(TeacherInvoiceBillableTasksPageQueryParams.Project) as string[];
      const statusFilters = searchParams.getAll(TeacherInvoiceBillableTasksPageQueryParams.Status) as string[];

      const page = parseInt(searchParams.get(TeacherInvoiceBillableTasksPageQueryParams.Page) ?? '1') ?? 1;
      const pageSize = parseInt(searchParams.get(TeacherInvoiceBillableTasksPageQueryParams.PageSize) ?? `${DEFAULT_PAGE_SIZE}`) ?? DEFAULT_PAGE_SIZE;

      const orderBy =
        (searchParams.get(TeacherInvoiceBillableTasksPageQueryParams.OrderBy) as TeacherInvoiceBillableTasksQuerySortKeyEnum | null) ?? undefined;
      const sortDirection = (searchParams.get(TeacherInvoiceBillableTasksPageQueryParams.SortDirection) as SortOrder | null) ?? undefined;

      const updatedQuery: SearchTeacherInvoiceBillableTasksQuery = {
        search: searchText,
        project: projectFilters.length ? projectFilters : undefined,
        status: statusFilters.length ? statusFilters : undefined,
        page,
        pageSize,
        orderBy,
        sortDirection,
      };
      return removeUndefinedProperties(updatedQuery) as SearchTeacherInvoiceBillableTasksQuery;
    });
  }, [searchParams]);

  if (!periodId) {
    return (
      <Grid item xs={12} justifyContent="center">
        <div>The period in the url is invalid.</div>
      </Grid>
    );
  }

  function ActionButtons() {
    const onActionItemClicked = () => {
      return;
    };

    const actionItems: DropdownButtonMenuItem[] = [
      {
        id: 'export-banking-updates',
        label: <ListItemText>Export Banking Updates</ListItemText>,
        onClick: () => {
          bankingUpdatesCSVReport.refetch();
          setCSVLoading(true);
        },
        MenuItemProps: {
          // Only allow them to export banking updates for periods that aren't submitted yet
          disabled: invoicePeriodSubmitted,
        },
      },
      {
        id: 'export-invoice',
        label: <ListItemText>Export Invoice</ListItemText>,
        onClick: () => {
          invoicesCSVReport.refetch();
          setCSVLoading(true);
        },
        MenuItemProps: {
          disabled: !invoicePeriodSubmitted,
        },
      },
    ];

    return (
      <>
        <DropdownButton
          label="Actions"
          menuItems={actionItems}
          onMenuItemClicked={onActionItemClicked}
          disabled={CSVLoading}
          isLoading={CSVLoading}
          sx={{ width: '114px', height: '44px' }}
        />
        <Tooltip title={invoicePeriodSubmitted ? 'This invoice has already been finalized' : ''}>
          <Box component="span">
            <Button
              sx={{ height: '44px' }}
              type="button"
              color="primary"
              variant="contained"
              size="large"
              disabled={disableSubmit}
              onClick={() => setShowConfirmDialog(true)}
            >
              Submit Final Invoice
            </Button>
          </Box>
        </Tooltip>
      </>
    );
  }

  return (
    <>
      <HeaderCard
        showNavigateBackButton={true}
        title={invoiceDate}
        breadcrumbs={[{ label: 'Teacher Invoicing', route: routes.invoicing.url }]}
        actionButtons={<ActionButtons />}
      />
      <ConfirmSubmitDialog
        open={showConfirmDialog}
        onCancel={() => setShowConfirmDialog(false)}
        onContinue={handleSubmit}
        title="Finalize Invoice"
        bodyText={confirmBodyText}
        submitButtonText="Yes, Submit Invoice"
        isLoading={submitFinalInvoice.isLoading}
      />
      <Card sx={{ mt: 3, p: 3 }}>
        <CardHeader
          sx={{ p: 0, pb: 3 }}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography component="h3" variant="titleLarge">
                Teacher-Submitted Tasks
              </Typography>
            </Stack>
          }
        />
        <TeacherInvoiceBillableTasksSearchInput />
        {projects ? <TeacherInvoiceBillableTasksFilters sx={{ mt: 3 }} projects={projects} /> : null}
        <Box sx={{ width: '100%', mt: 6.5, height: '4px' }}>{getTeacherInvoiceBillableTasksRequest.isFetching && <LinearProgress />}</Box>
        <TableContainer component={Paper}>
          <TeacherBillableTasksTable
            query={teacherInvoiceBillableTasksQuery}
            teacherInvoiceBillableTasksPaginatedResponse={teacherInvoiceBillableTasksPaginatedResponse}
          />
          <QueryableTablePagination paginatedResponse={teacherInvoiceBillableTasksPaginatedResponse} />
        </TableContainer>
      </Card>
      <TeacherInvoiceBillableTasksUploadCard periodId={periodId} periodStatus={invoicePeriod?.status} />
    </>
  );
};

export default TeacherInvoiceBillableTasksPage;
