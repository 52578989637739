import { ProductProgram } from '@hoot-reading/hoot-core/dist/enums/hfs/product-program.enum';

export const productProgramList: { value: ProductProgram; label: string }[] = [
  { value: ProductProgram.HootForSchools, label: 'HFS' },
  { value: ProductProgram.HootForAll, label: 'HFA' },
  { value: ProductProgram.HootForCompanies, label: 'HFC' },
  { value: ProductProgram.HootReading, label: 'HR' },
];

export const productProgram = productProgramList.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in ProductProgram]: string };
