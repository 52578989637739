import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import axios from 'axios';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import { config } from '@hoot/config';
import { SortableQuery } from '@hoot/interfaces/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/interfaces/pagination';
import { QueryKey } from '../queryKeys';

export interface SearchDistrictSchoolDetailsQuery extends PaginatableQuery, SortableQuery<DistrictSchoolQuerySortKeyEnum> {
  search?: string;
  type?: LocationType[];
  productStatus?: ProductStatus[];
  enrolmentStatus?: EnrolmentStatus[];
  country?: HootSupportedCountry[];
}

export enum DistrictSchoolQuerySortKeyEnum {
  Name = 'name',
  Type = 'type',
  FriendlyId = 'friendlyId',
  Products = 'productstatuscount',
  Enrolments = 'enrolmentstatuscount',
  Schools = 'schools',
  Students = 'students',
  Country = 'country',
}

export enum LocationTypeFilterOptions {
  School = 'SCHOOL',
  District = 'DISTRICT',
  StandaloneSchool = 'STANDALONE_SCHOOL',
}

export interface DistrictSchoolDetailSearchResult {
  id: string;
  friendlyId: string;
  name: string;
  city: string;
  country: HootSupportedCountry;
  type: LocationTypeFilterOptions;
  productStatusCounts: Partial<Record<ProductStatus, number>>;
  enrolmentStatusCounts: Partial<Record<EnrolmentStatus, number>>;
  schoolCount: number | undefined;
  studentCount: number;
}

const searchDistrictSchoolDetails = async (
  searchParams: SearchDistrictSchoolDetailsQuery,
): Promise<GenericPaginatedResponse<DistrictSchoolDetailSearchResult>> => {
  const { data } = await axios.get(`${config.apiUrl}/admin/hfs/district-schools/search-details`, {
    params: searchParams,
  });
  return data;
};

export default function useSearchDistrictSchoolDetails(
  searchParams: SearchDistrictSchoolDetailsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<DistrictSchoolDetailSearchResult>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.SearchDistrictsSchoolsDetails, searchParams], () => searchDistrictSchoolDetails(searchParams), options);
}
