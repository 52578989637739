import axios, { AxiosError } from 'axios';
import { UseMutateFunction, useMutation } from 'react-query';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';
import { config } from '../../../config';

async function importAvailability(formData: FormData) {
  await axios.post<void>(`${config.apiUrl}/admin/availability/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export default function useImportAvailablity(
  options?: Omit<UseMutateFunction<void, AxiosError<ErrorResponseDto>, FormData>, 'queryKey' | 'queryFn'>,
) {
  return useMutation<void, AxiosError<ErrorResponseDto>, FormData>((data: FormData) => {
    return importAvailability(data);
  }, options);
}
