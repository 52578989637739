import { CancellationReason, ScheduledLessonStatus, ScheduledLessonType, SubRequired } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { ConflictsResponse } from './useGetConflicts';

export interface UpdateLessonRequest {
  studentProfileId: string;
  teacherAccountId: string | null;
  lessonType: ScheduledLessonType;
  startsAt: number;
  durationInMinutes: number;
  status: ScheduledLessonStatus;
  cancellationReason?: CancellationReason | null;
  subRequired: SubRequired;
}

export interface UpdateLessonResponse {
  isSuccess: boolean;
  conflicts: ConflictsResponse[];
}

async function updateLesson(lessonId: string, request: UpdateLessonRequest) {
  const { data } = await axios.post<UpdateLessonResponse>(`${config.apiUrl}/admin/scheduler/update/${lessonId}`, request);

  return data;
}

export default function useUpdateLesson(
  lessonId: string,
  options?: Omit<UseMutationOptions<UpdateLessonResponse, AxiosError, UpdateLessonRequest>, 'queryKey' | 'queryFn'>,
) {
  return useMutation((request) => updateLesson(lessonId, request), options);
}
