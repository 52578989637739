import { Alert, AlertTitle, CircularProgress, Grid, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import useGetTeacherAccount from '@hoot/hooks/api/user/teacher/useGetTeacherAccount';
import useGetTeacherReliabilityScore from '@hoot/hooks/api/user/teacher/useGetTeacherReliabilityScore';
import TeacherBillableLessonCard from '@hoot/pages/users/teachers/TeacherBillableLessonCard';
import TeacherReliabilityScoreCard from '@hoot/pages/users/teachers/tabs/TeacherReliabilityScoreCard';
import { routes } from '@hoot/routes/routes';
import theme from '@hoot/theme';

const TeacherReliabilityTab = () => {
  const { teacherAccountId } = useParams() as { teacherAccountId: string };
  const { error } = useAlert();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { hootEmployee } = useAuth();
  const navigate = useNavigate();

  const hideReliabilityTab = !hootEmployee?.canManageTeacherDetails;

  const teacherAccountResponse = useGetTeacherAccount(teacherAccountId, {
    onError: () => {
      error('There was an issue fetching the teacher account');
    },
  });

  const trsHistory = useGetTeacherReliabilityScore(teacherAccountId, {
    enabled: !!teacherAccountResponse?.data,
    onError: (err) => {
      const message = `There was an issue getting TRS data for ${teacherAccountResponse.data?.displayName}!`;
      error(message);
      console.error(err);
    },
  });

  if (teacherAccountResponse.isLoading || trsHistory.isLoading) {
    return <CircularProgress />;
  }

  const teacherAccount = teacherAccountResponse.data;
  const existingItems = trsHistory.data ?? [];

  if (!teacherAccount) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        This is not a valid teacher!
      </Alert>
    );
  }

  // Route guard for trying to access the Reliability tab via url without permissions.
  if (hideReliabilityTab) {
    navigate(routes.users.teachers.details.url(teacherAccountId));
  }

  return (
    <Grid container marginTop="24px">
      <Grid container item xs={12} lg={4} alignContent="flex-start" paddingRight={isSmallScreen ? undefined : '12px'}>
        <Grid container item direction="column" rowGap={3}>
          <TeacherBillableLessonCard billableLessonsCount={teacherAccount?.billableLessonsCount} />
        </Grid>
      </Grid>
      <Grid container item xs={12} lg={8} paddingTop={isSmallScreen ? '24px' : undefined} paddingLeft={isSmallScreen ? undefined : '12px'}>
        <Grid container item direction="column" rowGap={3}>
          {existingItems.length > 0 ? (
            existingItems.map((trs, idx) => <TeacherReliabilityScoreCard key={`${trs.id}-${idx}`} trs={trs} idx={idx} />)
          ) : (
            <TeacherReliabilityScoreCard />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeacherReliabilityTab;
