import { PriorityHigh, Warning } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { ShiftConflictSeverity } from '@hoot/hooks/api/user/teacher/useGetTeacherShiftConflicts';
import { BlockConflictSeverity } from '../../../../hooks/api/blocks/useGetTeacherBlockConflicts';
import { ConflictType, ConflictsResponse } from '../../../../hooks/api/lessons/useGetConflicts';

function getConflictErrorIconColor(severity?: ShiftConflictSeverity | BlockConflictSeverity) {
  if (!severity) {
    return 'success';
  }

  switch (severity) {
    case 'BLOCKER':
      return 'error';
    case 'HIGH_RISK':
      return 'secondary';
    case 'CAUTION':
      return 'warning';
    default:
      return 'success';
  }
}

function getConflictTypeIconColor(conflictType: ConflictType) {
  if (!conflictType) {
    return 'success';
  }

  switch (conflictType) {
    case 'CONFLICT':
      return '#B3261E';
    case 'EXCEPTION':
      return '#9C27B0';
    case 'OUTSIDE_OF_SHIFT':
      return '#E6B800';
    case 'OUTSIDE_OF_AVAILABILITY':
      return '#ED6C02';
    default:
      return 'success';
  }
}

export function ShiftConflictMessage(props: { message: string; severity: ShiftConflictSeverity }) {
  const color = getConflictErrorIconColor(props.severity);

  return (
    <Tooltip title={props.message}>
      <IconButton edge="end" aria-label="error" size="large">
        {props.severity === 'BLOCKER' ? <Warning color={color} /> : <PriorityHigh color={color} />}
      </IconButton>
    </Tooltip>
  );
}

export function BlockConflictMessage(props: { message: string; severity: BlockConflictSeverity }) {
  const color = getConflictErrorIconColor(props.severity);

  return (
    <Tooltip title={props.message}>
      <IconButton edge="end" aria-label="error" size="large">
        {props.severity === 'BLOCKER' ? <Warning color={color} /> : <PriorityHigh color={color} />}
      </IconButton>
    </Tooltip>
  );
}

export function ConflictMessage(props: { message: string; conflictType: ConflictType }) {
  const color = getConflictTypeIconColor(props.conflictType);

  return (
    <Tooltip title={props.message}>
      <IconButton edge="end" aria-label="error" size="large">
        {props.conflictType === 'CONFLICT' ? <Warning htmlColor={color} /> : <PriorityHigh htmlColor={color} />}
      </IconButton>
    </Tooltip>
  );
}

interface MultipleConflictsMessageProps {
  conflicts: ConflictsResponse[];
}

export function MultipleConflictsMessage(props: MultipleConflictsMessageProps) {
  const { conflicts } = props;

  const toolTipMessage = useMemo(() => {
    return conflicts.map((x) => x.message).join(' - ');
  }, [conflicts]);

  // Get the "highest" severity.
  const conflictType = useMemo<ConflictType>(() => {
    if (conflicts.some((x) => x.conflictType === 'CONFLICT')) {
      return 'CONFLICT';
    } else if (conflicts.some((x) => x.conflictType === 'EXCEPTION')) {
      return 'EXCEPTION';
    } else if (conflicts.some((x) => x.conflictType === 'OUTSIDE_OF_SHIFT')) {
      return 'OUTSIDE_OF_SHIFT';
    } else {
      return 'OUTSIDE_OF_AVAILABILITY';
    }
  }, [conflicts]);

  const color = getConflictTypeIconColor(conflictType);

  return (
    <Tooltip title={toolTipMessage}>
      <IconButton edge="end" aria-label="error" size="large">
        {conflictType === 'CONFLICT' ? <Warning htmlColor={color} /> : <PriorityHigh htmlColor={color} />}
      </IconButton>
    </Tooltip>
  );
}
