import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { GetLessonSetsRequest, LessonSetOrderBy, useGetLessonSets } from '@hoot/hooks/api/lesson-sets/useGetLessonSets';
import { SortOrder } from '@hoot/interfaces/order-by';
import { LessonSetSearchFieldOptions } from '@hoot/pages/lesson-sets/LessonSets';

interface LessonSetAutoCompleteProps {
  value: string;
  onChange: (value: string | null) => void;
  size: 'small' | 'medium';
  debounceTimeMillis?: number;
}

const DEFAULT_DEBOUNCE_TIME_MILLIS = 500;

const PAGE_SIZE = 5;

const LessonSetAutoComplete = (props: LessonSetAutoCompleteProps) => {
  const { value, onChange, size, debounceTimeMillis = DEFAULT_DEBOUNCE_TIME_MILLIS } = props;

  const [inputValue, setInputValue] = useState('');

  const [query, setQuery] = useState<GetLessonSetsRequest>({
    query: inputValue,
    searchFieldSelection: LessonSetSearchFieldOptions.All,
    page: 1,
    pageSize: PAGE_SIZE, // This never changes.
    sortColumn: LessonSetOrderBy.EnrolmentId,
    sortDirection: SortOrder.ASC,
  });

  const _onInputChanged = (newInputValue: string) => {
    setInputValue(newInputValue);
    setDebouncedInputValue(newInputValue);
  };

  const setDebouncedInputValue = useDebouncedCallback((searchText) => {
    setQuery((prev) => ({
      ...prev,
      query: searchText,
    }));
  }, debounceTimeMillis);

  const getLessonSetsRequest = useGetLessonSets(query);

  const selectedLessonSet = getLessonSetsRequest.data?.lessonSets.find((x) => x.lessonSetId === value);

  return (
    <Autocomplete
      onChange={(event, val) => onChange(val?.lessonSetNumber?.toString() ?? null)}
      size={size}
      fullWidth
      loading={getLessonSetsRequest.isFetching}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => _onInputChanged(newInputValue)}
      options={getLessonSetsRequest.data?.lessonSets ?? []}
      getOptionLabel={(option) => `${option.lessonSetNumber}`}
      value={selectedLessonSet || null}
      filterOptions={(x) => x}
      renderInput={(params) => <TextField {...params} label="Lesson Set" variant="outlined" />}
    />
  );
};

export default LessonSetAutoComplete;
