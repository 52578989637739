import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { Box, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { CompletedPMAUnit, GetPMADetailsResponse, PMAAssessedByTeacher } from '@hoot/hooks/api/student-hoot-assessments/useGetPMASubmissionDetails';
import { routes } from '@hoot/routes/routes';
import { enumToTitleCase } from '@hoot/utils/stringUtils';

interface Props {
  unit: CompletedPMAUnit;
  assessment: GetPMADetailsResponse;
}

const renderScore = (score?: number | null, maxScore?: number | null) => {
  if (!score || !maxScore) return;

  const percentage = ((Number(score) / maxScore) * 100).toFixed(0);

  return `${score} / ${maxScore} (${percentage}%)`;
};

const BoxContainer = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <Stack gap={4}>
      <Stack>{children}</Stack>
    </Stack>
  );
};

const Footer = (props: { teacher: PMAAssessedByTeacher }) => {
  const { teacher } = props;
  return (
    <Stack gap={4}>
      <Stack>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Assessed by
        </Box>
        <Link to={routes.users.teachers.details.url(teacher.id)}>{teacher.prefixedNumber}</Link>
      </Stack>
    </Stack>
  );
};

const ReadingFluencyCard = (props: { unit: CompletedPMAUnit }) => {
  const { unit } = props;
  return (
    <>
      <Stack gap={2} alignItems={'center'} direction={'row'}>
        <Box component="span" sx={{ fontSize: '22px' }}>
          Reading Fluency
        </Box>
      </Stack>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Book
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.bookForRunningRecord?.name}</Box>
        <Box sx={{ fontSize: '14px' }}>{unit.bookForRunningRecord?.level}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Accuracy Rate:
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.accuracyRate}%</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          WCPM
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.wordCountPerMin}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Net Fluency Score
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.fluencyScore}</Box>
      </BoxContainer>
      <Footer teacher={unit.assessedBy} />
    </>
  );
};

const ComprehensionStrategiesCard = (props: { unit: CompletedPMAUnit }) => {
  const { unit } = props;
  return (
    <>
      <Stack gap={2} alignItems={'center'} direction={'row'}>
        <Box component="span" sx={{ fontSize: '22px' }}>
          Comprehension Strategies
        </Box>
      </Stack>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Book
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.bookForRunningRecord?.name}</Box>
        <Box sx={{ fontSize: '14px' }}>{unit.bookForRunningRecord?.level}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Assessment Type
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.unit.unitName}</Box>
      </BoxContainer>
      <BoxContainer>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Net Comprehension Score
        </Box>
        <Box sx={{ fontSize: '14px' }}>{unit.comprehensionScore}</Box>
      </BoxContainer>
      <Footer teacher={unit.assessedBy} />
    </>
  );
};

const Header = (props: { unit: CompletedPMAUnit }) => {
  const { unit } = props;
  return (
    <Stack gap={2} alignItems={'center'} direction={'row'}>
      <Box component="span" sx={{ fontSize: '22px' }}>
        {unit.unit.unitName}
      </Box>
    </Stack>
  );
};

const ModulesCard = (props: { unit: CompletedPMAUnit }) => {
  const { unit } = props;
  return (
    <>
      <Header unit={unit} />
      <BoxContainer>
        <Box component="span" sx={{ fontSize: '14px', fontWeight: 500 }}>
          Score
        </Box>
        <Box sx={{ fontSize: '14px' }}>{renderScore(unit.score, unit.totalQuestions)}</Box>
        <Box sx={{ fontSize: '14px' }}>{enumToTitleCase(unit?.instructionIndicator ?? '')}</Box>
      </BoxContainer>
      <Footer teacher={unit.assessedBy} />
    </>
  );
};

const StudentProgressMonitoringAssessmentCard = (props: Props) => {
  const { unit } = props;
  if (unit.unit.unitId === PMAUnitIdentifier.Module_4_Unit_1) {
    return <ReadingFluencyCard unit={unit} />;
  } else if ([PMAUnitIdentifier.Module_4_Unit_3, PMAUnitIdentifier.Module_4_Unit_4].includes(unit.unit.unitId)) {
    return <ComprehensionStrategiesCard unit={unit} />;
  } else {
    //For modules 1, 2, and 3
    return <ModulesCard unit={unit} />;
  }
};

export default StudentProgressMonitoringAssessmentCard;
