import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

interface LocationProductEnrolmentsInfo {
  status: EnrolmentStatus;
  count: number;
}

export interface GetLocationProductsResponse {
  productId: string;
  friendlyId: string;
  startDate: number;
  endDate: number;
  requirements: HootQualification[];
  enrolments: LocationProductEnrolmentsInfo[];
  status: ProductStatus;
}

const searchLocationProducts = async (locationId: string): Promise<GetLocationProductsResponse[]> => {
  const { data } = await axios.get(`${config.apiUrl}/admin/hfs/location/${locationId}/products`);
  return data;
};

export default function useSearchLocationProducts(
  locationId: string,
  options?: Omit<UseQueryOptions<GetLocationProductsResponse[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.SearchLocationProducts, locationId], () => searchLocationProducts(locationId), options);
}
