import { TeacherBlockCancellationReason, TeacherBlockStatus, TeacherBlockType } from '@hoot-reading/hoot-core/dist/enums/teacher-block';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { ErrorResponseDto } from '@hoot/interfaces/error-response-dto';

export interface TeacherBlockResponse {
  id: string;
  number: number;
  prefixedNumber: string;
  prefixedTeacherNumber: string;
  teacherAccountId: string;
  teacherRecurringBlockId: string | null;
  type: TeacherBlockType;
  status: TeacherBlockStatus;
  startsAt: number;
  endsAt: number;
  cancelledAt: number | null;
  cancellationReason: TeacherBlockCancellationReason | null;
}

async function getTeacherBlock(blockId: string): Promise<TeacherBlockResponse> {
  const { data } = await axios.get(`${config.apiUrl}/admin/teacher-block/${blockId}`);
  return data;
}

export default function useGetTeacherBlock(
  blockId: string,
  options?: Omit<UseQueryOptions<TeacherBlockResponse, AxiosError<ErrorResponseDto>>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TeacherBlockResponse, AxiosError<ErrorResponseDto>> {
  return useQuery([QueryKey.GetTeacherBlock, blockId], () => getTeacherBlock(blockId), options);
}
