import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface Location {
  id: string;
  name: string;
}

export interface LocationResponse {
  billableLocations: Location[];
}

async function getAllBillableLocations() {
  const { data } = await axios.get<LocationResponse>(`${config.apiUrl}/admin/hfs/billable-location`);
  return data;
}

export default function useGetAllBillableLocations() {
  return useQuery(QueryKey.GetAllBillableLocations, () => getAllBillableLocations());
}
