import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { ProgressMonitoringAssessmentStatus } from '@hoot/interfaces/assessments-enums';
import { SortableQuery } from '@hoot/interfaces/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/interfaces/pagination';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface HootProgressMonitoringAssessmentResponse {
  id: string;
  status: ProgressMonitoringAssessmentStatus;
  createdAt: number;
  completedAt: number | undefined;
  startedBy: string;
  units: string[];
  assessedById: string;
  assessedByPrefixedNumber: string;
}

export enum StudentProgressMonitoringAssessmentsQuerySortKeyEnum {
  StartDate = 'StartDate',
  Status = 'Status',
  CompletionDate = 'CompletionDate',
  AssessedBy = 'AssessedBy',
}

export type GetStudentProgressMonitoringAssessmentsQuery = PaginatableQuery & SortableQuery<StudentProgressMonitoringAssessmentsQuerySortKeyEnum>;

export function useGetProgressMonitoringAssessments(
  studentProfileId: string,
  query: GetStudentProgressMonitoringAssessmentsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<HootProgressMonitoringAssessmentResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetStudentProgressMonitoringAssessments, studentProfileId, query],
    async () => {
      return (
        await axios.get<GenericPaginatedResponse<HootProgressMonitoringAssessmentResponse>>(
          `${config.apiUrl}/admin/progress-monitoring-assessment/student/${studentProfileId}`,
          {
            params: query,
          },
        )
      ).data;
    },
    options,
  );
}
