import { useCallback, useEffect, useRef } from 'react';
import { SetURLSearchParams, useSearchParams as useSearchParamsOrig } from 'react-router-dom';

export function useStableSearchParams(): [URLSearchParams, SetURLSearchParams] {
  const [searchParams, setSearchParams] = useSearchParamsOrig();
  // Store a reference to the setter
  const setSearchParamsRef = useRef(setSearchParams);
  // Update the reference when the setter changes
  useEffect(() => {
    setSearchParamsRef.current = setSearchParams;
  }, [setSearchParams]);
  // Return a stable setter (which has no dep on searchParams)
  const setSearchParamsStable = useCallback((...args: Parameters<typeof setSearchParams>) => setSearchParamsRef.current(...args), []);
  return [searchParams, setSearchParamsStable];
}
