import { DateTime } from 'luxon';
import { SelectedTeacher } from '@hoot/components/modals/SelectTeacher';
import CreateShiftWizard from './CreateShiftWizard';
import CreateShiftContextProvider from './context/CreateShiftContextProvider';

const CreateShiftPage = (props: {
  shiftStartTime?: DateTime;
  shiftEndTime?: DateTime;
  teacher?: SelectedTeacher;
  preSelectedTeacher?: boolean;
  closeModal?: () => void;
}) => {
  return (
    <CreateShiftContextProvider
      shiftStartTime={props.shiftStartTime}
      shiftEndTime={props.shiftEndTime}
      teacher={props.teacher}
      closeModal={props.closeModal}
      preSelectedTeacher={props.preSelectedTeacher}
    >
      <CreateShiftWizard />
    </CreateShiftContextProvider>
  );
};

export default CreateShiftPage;
