import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { useAlert } from '@hoot/contexts/AlertContext';

const AlertPopup = () => {
  const { handleClose, message, severity, showAlert } = useAlert();

  if (!showAlert) {
    return null;
  }

  return (
    <Snackbar open={showAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertPopup;
