import { CustomerStatus } from '@hoot-reading/hoot-core/dist/enums/user/customer-status.enum';
import { DistrictRepresentativeStatus } from '@hoot-reading/hoot-core/dist/enums/user/district-representative/district-representative-status.enum';
import { PasswordSetFilter } from '@hoot-reading/hoot-core/dist/enums/user/password-reset-filter.enum';
import { StudentDateType } from '@hoot-reading/hoot-core/dist/enums/user/student/student-date-type.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { Close } from '@mui/icons-material';
import { Button, Divider, Drawer, FormControl, Grid, IconButton, InputLabel, List, ListItem, MenuItem, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { SearchSelect } from '@hoot/components/SearchSelect';
import { MultiSelect } from '@hoot/components/form/MultiSelect';
import { Select } from '@hoot/components/form/SelectField';
import useGetAccounts from '@hoot/hooks/api/common/useGetAccounts';
import { UserQuery } from '@hoot/hooks/api/end-users/useGetUsers';
import { usePlaces } from '@hoot/hooks/usePlaces';
import { booleanFilterOptions } from '@hoot/interfaces/form';
import { UserAccount } from '@hoot/interfaces/user';
import { customerStatusFilterOptions } from '@hoot/pages/users/interfaces/user-filters-form';
import { timeZoneData } from '@hoot/utils/timeZones';

interface Form {
  accountType: UserAccount | '';
  timeZone: string[];
  province: string[];
  userIsEnabled: string | '';
  country: string | '';
  isPasswordSet: string | '';
  student: {
    studentStatus: (StudentStatus | 'IS_EMPTY')[];
    studentLocationIds?: (string | 'IS_EMPTY')[] | '';
    studentSchoolIds?: (string | 'IS_EMPTY')[] | '';
    enrolmentIds?: (string | 'IS_EMPTY')[] | '';
    lineOfBusiness?: string | '';
    dateType?: StudentDateType | '';
    fromDate?: string;
    toDate?: string;
    passwordSet?: PasswordSetFilter | '';
  };
  customer: {
    customerStatus?: (CustomerStatus | 'IS_EMPTY')[];
    account?: string[] | '';
  };
  districtRep: {
    districtRepLocationIds?: (string | 'IS_EMPTY')[] | '';
    districtRepSchoolIds?: (string | 'IS_EMPTY')[] | '';
    districtRepStatus?: DistrictRepresentativeStatus | '';
  };
}

interface Props {
  showDrawer: boolean;
  setShowDrawer: (showAddHootEmployeeModal: boolean) => void;
  userQuery: UserQuery;
  setUserQuery: React.Dispatch<React.SetStateAction<UserQuery>>;
}

const ParentFilters = (props: { control: Control<Form> }) => {
  const { control } = props;
  const accounts = useGetAccounts(false);

  const accountsOptions =
    accounts?.data?.map((option) => {
      return { value: option.id, label: option.name };
    }) ?? [];

  return (
    <>
      <ListItem>
        <MultiSelect size="small" control={control} label="Status" name="customer.customerStatus" data={customerStatusFilterOptions} />
      </ListItem>

      <ListItem>
        <MultiSelect size="small" control={control} label="Account" name="customer.account" data={accountsOptions} />
      </ListItem>
    </>
  );
};

const ParentFiltersDrawer = (props: Props) => {
  const { showDrawer, setShowDrawer, userQuery, setUserQuery } = props;

  const { watch, control, reset, handleSubmit, setValue } = useForm<Form>({
    mode: 'onSubmit',
    defaultValues: {
      accountType: userQuery.accountType || '',
      country: userQuery.country || '',
      timeZone: userQuery.timeZones || [],
      province: userQuery.provincesStates || [],
      userIsEnabled: userQuery.isEnabled?.toString() || '',
      isPasswordSet: userQuery.isPasswordSet?.toString() || '',
      customer: {
        account: userQuery.customerQuery.account || [],
        customerStatus: userQuery.customerQuery.customerStatus || [],
      },
      districtRep: {
        districtRepStatus: userQuery.districtRepQuery.districtRepStatus || '',
        districtRepLocationIds: userQuery.districtRepQuery.districtRepLocationIds || [],
        districtRepSchoolIds: userQuery.districtRepQuery.districtRepSchoolIds || [],
      },
    },
  });

  const { country } = watch();
  const { provinceStateOptions } = usePlaces(country);

  const countryOptionsCanadaFirst = [
    { label: 'Canada', value: 'CA' },
    { label: 'United States', value: 'US' },
  ];

  const handleClose = () => {
    setShowDrawer(false);
  };

  const handleClearFilters = () => {
    reset();
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    setUserQuery((current) => ({
      ...current,
      page: 1,
      country: data.country !== '' ? data.country : undefined,
      isEnabled: data.userIsEnabled !== '' ? data.userIsEnabled === 'true' : undefined,
      timeZones: data.timeZone?.length === 0 ? undefined : data.timeZone,
      account: data.accountType !== '' ? data.accountType : undefined,
      provincesStates: data.province?.length === 0 ? undefined : data.province,
      isPasswordSet: data.isPasswordSet !== '' ? data.isPasswordSet === 'true' : undefined,
      customerQuery: {
        account: data.customer.account !== '' ? data.customer.account : undefined,
        customerStatus: data.customer.customerStatus?.length === 0 ? undefined : data.customer.customerStatus,
      },
      districtRepQuery: {
        districtRepStatus: data.districtRep.districtRepStatus !== '' ? data.districtRep.districtRepStatus : undefined,
        districtRepLocationIds: data.districtRep.districtRepLocationIds !== '' ? data.districtRep.districtRepLocationIds : undefined,
        districtRepSchoolIds: data.districtRep.districtRepSchoolIds !== '' ? data.districtRep.districtRepSchoolIds : undefined,
      },
    }));

    handleClose();
  };

  return (
    <Drawer anchor="right" open={showDrawer} onClose={handleClose}>
      <Box sx={{ minWidth: '320px' }}>
        <Box sx={{ position: 'relative' }}>
          <Typography
            style={{
              marginLeft: '24px',
              marginTop: '105px',
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '48px',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            Parent Filters
          </Typography>

          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '4px',
              cursor: 'pointer',
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ marginTop: '16px' }} />
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <List disablePadding sx={{ marginTop: '16px' }}>
          <ListItem>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="is-employee-enabled-label">Is Enabled</InputLabel>
              <Select control={control} name="userIsEnabled" labelId="is-employee-enabled-label" label="Is Enabled" fullWidth>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </ListItem>

          <ParentFilters control={control} />
          <>
            <ListItem>
              <MultiSelect size="small" control={control} label="Time Zone" name="timeZone" data={timeZoneData} />
            </ListItem>

            <ListItem>
              <SearchSelect
                size="small"
                onChangeCallback={() => setValue('province', [])}
                control={control}
                label="Country"
                name="country"
                data={countryOptionsCanadaFirst}
              />
            </ListItem>

            <ListItem>
              <MultiSelect
                size="small"
                control={control}
                label="Province / State"
                name="province"
                data={provinceStateOptions}
                disabled={country === 'all'}
              />
            </ListItem>
            <ListItem>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="is-password-set-label">Is Password Set</InputLabel>
                <Select control={control} name="isPasswordSet" labelId="is-password-set-label" label="Is Password Set" fullWidth>
                  <MenuItem value={''}>None</MenuItem>
                  {booleanFilterOptions.map((o) => {
                    return (
                      <MenuItem key={o.value} value={o.value}>
                        {o.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </ListItem>
          </>
        </List>
        <Grid container p={2} sx={{ position: 'sticky', bottom: 0, zIndex: 1, background: '#fff' }}>
          <Grid item container>
            <Button type="submit" size="small" variant="contained" fullWidth>
              Apply
            </Button>
          </Grid>
          <Grid item container sx={{ marginTop: '16px' }}>
            <Button color="inherit" type="button" onClick={handleClearFilters} size="small" variant="contained" fullWidth>
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  );
};

export default ParentFiltersDrawer;
