import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { DataImportType, dataImportTypeList } from './data-import-type-enums';

interface IUserImportTypeSelectionProps {
  selectedImportType: DataImportType;
  setImportType: (type: DataImportType) => void;
}

const DataImportTypeSelectionStep = (props: IUserImportTypeSelectionProps) => {
  const { selectedImportType, setImportType } = props;

  return (
    <>
      <Typography variant="titleMedium" sx={{ color: '#000' }}>
        Step 1 - Import Type Selection
      </Typography>
      <Stack sx={{ pt: 3, pl: 2 }}>
        <FormControl>
          <RadioGroup value={selectedImportType} onChange={(_event, value) => setImportType(value as DataImportType)}>
            {dataImportTypeList.map((type) => (
              <FormControlLabel key={type.value} value={type.value} control={<Radio />} label={type.label} />
            ))}
          </RadioGroup>
        </FormControl>
      </Stack>
    </>
  );
};

export default DataImportTypeSelectionStep;
