import { Button, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, styled } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { defaultRowsPerPage, rowsPerPageOptions } from '@hoot/common/constants';
import ConfirmDeleteDialog from '@hoot/components/modals/ConfirmDeleteDialog';
import { useAlert } from '@hoot/contexts/AlertContext';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { InvoicePeriodDetailsResponse } from '@hoot/hooks/api/teacher-invoice/useSearchTeacherInvoiceBillableTasks';
import {
  SearchTeacherInvoiceUploadedTasksQuery,
  TeacherInvoiceUploadedTasksQuerySortKeyEnum,
  TeacherInvoiceUploadedTasksResponse,
} from '@hoot/hooks/api/teacher-invoice/useSearchTeacherInvoiceUploadedBillableTasks';
import useSoftDeleteTeacherInvoiceTaskCsvLine from '@hoot/hooks/api/teacher-invoice/useSoftDeleteTeacherInvoiceTaskCsvLine';
import { InvoicePeriodStatus } from '@hoot/pages/teacher-invoices/enums/enums';
import { SortOrder, TableHeaderCell, muiTableSortDirectionLookup } from '../../interfaces/order-by';
import { GenericPaginatedResponse } from '../../interfaces/pagination';
import { routes } from '../../routes/routes';

const TeacherPageLink = styled(Link)({
  textDecoration: 'none',
  color: '#1976D2',
});

interface TeacherInvoiceUploadedTasksTableProps {
  query: SearchTeacherInvoiceUploadedTasksQuery;
  setQuery: React.Dispatch<React.SetStateAction<SearchTeacherInvoiceUploadedTasksQuery>>;
  teacherInvoiceUploadedTasksPaginatedResponse: GenericPaginatedResponse<TeacherInvoiceUploadedTasksResponse, InvoicePeriodDetailsResponse>;
}

const tableHeaderCells: TableHeaderCell<TeacherInvoiceUploadedTasksQuerySortKeyEnum>[] = [
  { label: 'Teacher', sortKey: TeacherInvoiceUploadedTasksQuerySortKeyEnum.Teacher },
  { label: 'Date', sortKey: TeacherInvoiceUploadedTasksQuerySortKeyEnum.Date },
  { label: 'Project', sortKey: TeacherInvoiceUploadedTasksQuerySortKeyEnum.Project },
  { label: 'Description', sortKey: TeacherInvoiceUploadedTasksQuerySortKeyEnum.Description },
  { label: 'Amount', sortKey: TeacherInvoiceUploadedTasksQuerySortKeyEnum.Amount },
  { label: 'Action' },
];

const TeacherInvoiceUploadedTasksTable = (props: TeacherInvoiceUploadedTasksTableProps) => {
  const {
    query,
    setQuery,
    teacherInvoiceUploadedTasksPaginatedResponse: { data, extraData, count, page, pageSize },
  } = props;
  const { orderBy, sortDirection } = query;
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [rowData, setRowData] = useState<TeacherInvoiceUploadedTasksResponse>();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const isRemoveDisabled = extraData?.status === InvoicePeriodStatus.Submitted;

  // If we've toggling the same order-by key, then just flip the sort direction.
  // Else, we're sorting by a different column, and we can default the sort to 'asc'.
  const toggleOrderBy = (newOrderBy: string) => () => {
    const isAsc = orderBy === newOrderBy && sortDirection === SortOrder.ASC;
    setQuery((prev) => ({
      ...prev,
      orderBy: newOrderBy as TeacherInvoiceUploadedTasksQuerySortKeyEnum,
      sortDirection: isAsc ? SortOrder.DESC : SortOrder.ASC,
    }));
  };

  const softDeleteTaskCsvLine = useSoftDeleteTeacherInvoiceTaskCsvLine({
    onSuccess: () => {
      setShowConfirmDialog(false);
      alert.success(`Successfully deleted task.`);
      queryClient.invalidateQueries([QueryKey.SearchTeacherInvoiceUploadedTasks]);
    },
    onError: () => alert.error('Error deleting the task.'),
  });

  const openModal = (row: TeacherInvoiceUploadedTasksResponse) => {
    setRowData(row);
    setShowConfirmDialog(true);
  };

  const closeModal = () => {
    setRowData(undefined);
    setShowConfirmDialog(false);
  };

  const handleDelete = () => {
    if (rowData) {
      softDeleteTaskCsvLine.mutate(rowData.id);
    }
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery((prev) => ({ ...prev, pageSize: parseInt(event.target.value, defaultRowsPerPage), page: 1 }));
  };

  return (
    <>
      <Table sx={{ minWidth: 650 }} aria-label="uploaded tasks table">
        <TableHead>
          <TableRow>
            {tableHeaderCells.map((cell) => (
              <TableCell
                variant="head"
                sx={{ width: cell.cellWidth }}
                key={`headerCell-${cell.label}`}
                sortDirection={
                  cell.sortKey ? (orderBy === cell.sortKey ? muiTableSortDirectionLookup[sortDirection ?? SortOrder.ASC] : false) : undefined
                }
              >
                {cell.sortKey ? (
                  <TableSortLabel
                    active={orderBy === cell.sortKey}
                    direction={orderBy === cell.sortKey ? muiTableSortDirectionLookup[sortDirection ?? SortOrder.ASC] : undefined}
                    onClick={toggleOrderBy(cell.sortKey)}
                  >
                    {cell.label}
                  </TableSortLabel>
                ) : (
                  cell.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '& > td': { verticalAlign: 'top' },
              }}
            >
              <TableCell>
                <TeacherPageLink to={routes.users.teachers.details.url(row.teacherId ?? '')}>{row.teacherName}</TeacherPageLink>
              </TableCell>
              <TableCell>{DateTime.fromISO(row.date).toFormat('MM/dd/yyyy')}</TableCell>
              <TableCell>{row.project}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>
                {row.total.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </TableCell>
              <TableCell>
                <Button type="button" disabled={isRemoveDisabled} color="error" variant="outlined" size="large" onClick={() => openModal(row)}>
                  Remove
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={7}
              rowsPerPageOptions={rowsPerPageOptions}
              count={count ?? 0}
              rowsPerPage={pageSize}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
      {rowData ? (
        <ConfirmDeleteDialog
          open={showConfirmDialog}
          onCancel={closeModal}
          onContinue={handleDelete}
          title="Remove task"
          bodyText="This removes the task and its amount from the pending invoice. Are you sure you want to continue?"
        />
      ) : null}
    </>
  );
};

export default TeacherInvoiceUploadedTasksTable;
