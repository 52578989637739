import React, { PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { useStableSearchParams } from '@hoot/hooks/useStableSearchParams';
import CreateLessonReviewStep from '@hoot/pages/lessons/create-v2/review-step/CreateLessonReviewStep';
import CreateLessonStudentSelectStep from '@hoot/pages/lessons/create-v2/student-and-lesson-step/CreateLessonStudentSelectStep';
import StudentConflictCheckStep from '@hoot/pages/lessons/create-v2/student-conflict-check/StudentConflictCheckStep';
import TeacherConflictCheckStep from '@hoot/pages/lessons/create-v2/teacher-conflict-check/TeacherConflictCheckStep';
import CreateLessonDateTimeStep from '../date-time-step/CreateLessonDateTimeStep';

export enum CreateLessonWizardStepEnum {
  SelectStudent = 1,
  LessonDateAndTime,
  StudentAvailability,
  TeacherAvailability,
  Review,
}

export interface CreateLessonWizardStep {
  id: CreateLessonWizardStepEnum;
  stepperLabel: string;
  contentLabel?: string;
  content: () => ReactNode;
}

interface CreateLessonContextProps {
  currentStep: CreateLessonWizardStep;
  goToStep: (step: CreateLessonWizardStepEnum) => void;
  goToPrevStep: () => void;
  goToNextStep: () => void;
  loadingCustomer: boolean;
  loadingEnrolments: boolean;
  setLoadingCustomer: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingEnrolments: React.Dispatch<React.SetStateAction<boolean>>;
  hideStudentName: boolean;
}

export const createLessonWizardSteps: CreateLessonWizardStep[] = [
  {
    id: CreateLessonWizardStepEnum.SelectStudent,
    stepperLabel: 'Student & Lesson',
    content: () => <CreateLessonStudentSelectStep />,
  },
  {
    id: CreateLessonWizardStepEnum.LessonDateAndTime,
    stepperLabel: 'Date & Time',
    content: () => <CreateLessonDateTimeStep />,
  },
  {
    id: CreateLessonWizardStepEnum.StudentAvailability,
    stepperLabel: 'Student Availability & Conflicts',
    contentLabel: 'Student Availability & Conflicts Check',
    content: () => <StudentConflictCheckStep />,
  },
  {
    id: CreateLessonWizardStepEnum.TeacherAvailability,
    stepperLabel: 'Teacher Availability & Conflicts',
    contentLabel: 'Teacher Availability & Conflicts Check',
    content: () => <TeacherConflictCheckStep />,
  },
  {
    id: CreateLessonWizardStepEnum.Review,
    stepperLabel: 'Review',
    content: () => <CreateLessonReviewStep />,
  },
];

const CreateLessonContext = React.createContext<CreateLessonContextProps>(undefined!);
CreateLessonContext.displayName = 'CreateLessonContext';

const CreateLessonContextProvider = (props: PropsWithChildren<any>) => {
  const { children } = props;

  const { hootEmployee } = useAuth();
  const hideStudentName = !hootEmployee?.canManageStudentDetails;

  const [searchParams, setSearchParams] = useStableSearchParams();
  const stepSearchParam = searchParams.get('step');
  const numericStep = Number(stepSearchParam);

  const [loadingCustomer, setLoadingCustomer] = useState<boolean>(false);
  const [loadingEnrolments, setLoadingEnrolments] = useState<boolean>(false);

  // Hacky workaround so that we can guarantee that we only ever render step #1 on page load.
  const mountedRef = useRef(false);

  const redirectToInitialStep = useCallback(() => {
    setSearchParams(
      {
        step: `${CreateLessonWizardStepEnum.SelectStudent}`,
      },
      {
        replace: true,
      },
    );
  }, [setSearchParams]);

  // On page load, always start at step #1. All data is lost on refresh, so we need to start over.
  useEffect(() => {
    mountedRef.current = true;
    redirectToInitialStep();
  }, [redirectToInitialStep]);

  // Ensure step is always within bounds.
  useEffect(() => {
    // If invalid step, then start at the beginning.
    if (isNaN(numericStep) || numericStep < CreateLessonWizardStepEnum.SelectStudent || numericStep > CreateLessonWizardStepEnum.Review) {
      redirectToInitialStep();
    }
  }, [numericStep, redirectToInitialStep, stepSearchParam]);

  const currentStep = useMemo(() => {
    // Ensure the step query is an actual number.
    const numericStep = !isNaN(Number(stepSearchParam)) ? Number(stepSearchParam) : CreateLessonWizardStepEnum.SelectStudent;

    // If the step is out of bounds, then just default to the beginning.
    const step: CreateLessonWizardStepEnum =
      numericStep < CreateLessonWizardStepEnum.SelectStudent || numericStep > CreateLessonWizardStepEnum.Review
        ? CreateLessonWizardStepEnum.SelectStudent
        : numericStep;

    return createLessonWizardSteps.find((x) => x.id === step) ?? createLessonWizardSteps[0];
  }, [stepSearchParam]);

  const goToStep = (step: CreateLessonWizardStepEnum) => {
    setSearchParams({
      step: `${step}`,
    });
  };

  const goToPrevStep = () => {
    if (currentStep.id > CreateLessonWizardStepEnum.SelectStudent) {
      goToStep(currentStep.id - 1);
    } else {
      console.error(`Can not go to previous step. There is no step before step ${CreateLessonWizardStepEnum.SelectStudent}.`);
    }
  };

  const goToNextStep = () => {
    if (currentStep.id < CreateLessonWizardStepEnum.Review) {
      goToStep(currentStep.id + 1);
    } else {
      console.error(`Can not go to next step. There is no step after step ${CreateLessonWizardStepEnum.Review}.`);
    }
  };

  return (
    <CreateLessonContext.Provider
      value={{
        currentStep,
        goToStep,
        goToPrevStep,
        goToNextStep,
        loadingCustomer,
        loadingEnrolments,
        setLoadingCustomer,
        setLoadingEnrolments,
        hideStudentName,
      }}
    >
      {mountedRef.current ? children : null}
    </CreateLessonContext.Provider>
  );
};

export const useCreateLessonContext = () => {
  const context = React.useContext(CreateLessonContext);

  if (context === undefined) {
    throw new Error('useCreateLessonContext must be used within a CreateLessonContextProvider');
  }
  return context;
};

export default CreateLessonContextProvider;
