import CheckIcon from '@mui/icons-material/Check';
import { Chip } from '@mui/material';
import { FormOption } from '@hoot/interfaces/form';

interface Props<T extends string> {
  onChange: (val: T, selected: boolean) => void;
  values: T[];
  options: FormOption<T>[];
}

interface ChipToggleProps<T extends string> {
  onChange: (val: T, selected: boolean) => void;
  values: T[];
  option: FormOption<T>;
}

const ChipToggle = <T extends string>(props: ChipToggleProps<T>) => {
  const { option, values, onChange } = props;
  const isSelected = values.some((v) => v === option.value);

  const handleClick = () => {
    onChange(option.value, !isSelected);
  };

  return (
    <Chip
      key={option.value}
      label={`${option.label}`}
      onDelete={isSelected ? handleClick : undefined}
      deleteIcon={<CheckIcon />}
      variant={`${isSelected ? 'filled' : 'outlined'}`}
      sx={{
        backgroundColor: isSelected ? '#1976D2' : undefined,
        color: isSelected ? '#FFFFFF' : '#49454F',
        marginRight: '8px',
        marginTop: '8px',
        fontSize: 14,
        '&:hover': {
          backgroundColor: '#4287f5',
        },
        '& .MuiChip-deleteIcon': {
          color: '#FFFFFF',
          '&:hover': {
            color: '#FFFFFF',
          },
        },
      }}
      onClick={handleClick}
    />
  );
};

const ChipToggleDisplay = <T extends string>(props: Props<T>) => {
  const { onChange, values, options } = props;

  return (
    <>
      {options.map((option, idx) => (
        <ChipToggle key={`${option.value}-${idx}`} option={option} values={values} onChange={onChange} />
      ))}
    </>
  );
};

export default ChipToggleDisplay;
