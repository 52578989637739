import { ScheduledLessonStatus, lessonStatusesLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Chip, SvgIconProps } from '@mui/material';
import FullCircleIcon from '../../icons/FullCircle';

export function LessonStatusChip(props: { status: ScheduledLessonStatus; count?: number; error?: boolean }) {
  return (
    <Chip
      sx={{
        backgroundColor: props.error ? '#FCDAD3' : undefined,
        border: props.error ? '1px solid #BF3A1C' : undefined,
        paddingLeft: '8px',
        '& > svg': {
          width: '8px',
          height: '8px',
        },
      }}
      icon={<StatusIcon status={props.status} />}
      size="small"
      label={`${lessonStatusesLookup[props.status]}${props.count ? ` (${props.count})` : ''}`}
    />
  );
}

function StatusIcon(props: { status: ScheduledLessonStatus; sx?: SvgIconProps['sx'] }) {
  const { sx, status } = props;
  switch (status) {
    case ScheduledLessonStatus.CompletedSuccessfully:
    case ScheduledLessonStatus.CompletedUnsuccessfully:
      return <FullCircleIcon fill="#70AF22" sx={sx} />;
    case ScheduledLessonStatus.InProgress:
    case ScheduledLessonStatus.Open:
    case ScheduledLessonStatus.OpenWaitingForStudent:
    case ScheduledLessonStatus.OpenWaitingForTeacher:
    case ScheduledLessonStatus.Rescheduled:
    case ScheduledLessonStatus.Scheduled:
      return <FullCircleIcon fill="#1976D2" sx={sx} />;
    case ScheduledLessonStatus.Cancelled:
      return <FullCircleIcon fill="#D32D41" sx={sx} />;
  }
}
