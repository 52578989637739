import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface InstructionalFocusResponse {
  id: string;
  name: string;
}

async function getInstructionalFocus(): Promise<InstructionalFocusResponse[]> {
  const { data } = await axios.get<InstructionalFocusResponse[]>(`${config.apiUrl}/admin/library/instructional-focus`);
  return data;
}

export default function useGetInstructionalFocus(options?: Omit<UseQueryOptions<InstructionalFocusResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetInstructionalFocus], () => getInstructionalFocus(), options);
}
