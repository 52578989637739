import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface MetabaseDashboardEmbedResponse {
  name: string;
  iframeUrl: string;
}

async function getMetabaseDashboardEmbed(metabaseReportId: number): Promise<MetabaseDashboardEmbedResponse> {
  const { data } = await axios.get<MetabaseDashboardEmbedResponse>(`${config.apiUrl}/admin/reporting/metabase/report-embed/${metabaseReportId}`);
  return data;
}

export default function useGetMetabaseDashboardEmbed(
  metabaseReportId: number,
  options?: Omit<UseQueryOptions<MetabaseDashboardEmbedResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetMetabaseReportEmbed, metabaseReportId], () => getMetabaseDashboardEmbed(metabaseReportId), options);
}
