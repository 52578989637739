import { ScheduledLessonStatus, SubRequired } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { ScheduledLessonSetStatus } from '@hoot/pages/lessons/enums';
import { QueryKey } from '../queryKeys';
import {
  QualityAssuranceCategory,
  QualityAssuranceOwningTeam,
  QualityAssuranceStatus,
  QualityAssuranceTaskIssue,
  QualityAssuranceType,
  ThresholdType,
} from './enums';

export interface QualityAssuranceTaskDetailsResource {
  id: string;
  title: string;
}

export interface QualityAssuranceTaskDetailsResponse {
  id: string;
  dateTaskCreated: number;
  taskNumber: number;
  taskPrefixedNumber: string;
  taskType: QualityAssuranceType;
  taskCategory: QualityAssuranceCategory;
  status: QualityAssuranceStatus;
  actioned: boolean;
  studentId: string;
  studentName: string;
  studentNumber: number;
  studentPrefixedNumber: string;
  lessonSetId?: string;
  lessonSetNumber?: number;
  lessonSetPrefixedNumber?: string;
  lessonSetStatus?: ScheduledLessonSetStatus;
  lessonId?: string;
  lessonNumber?: number;
  lessonPrefixedNumber?: string;
  lessonStatus?: ScheduledLessonStatus;
  teacherId?: string;
  teacherNumber?: number;
  teacherPrefixedNumber?: string;
  teacherName?: string;
  subRequired: SubRequired;
  issue: QualityAssuranceTaskIssue;
  resources: QualityAssuranceTaskDetailsResource[];
  failedThreshold: ThresholdType;
  lessonReviewId: string;
  owningTeam: QualityAssuranceOwningTeam;
}

async function getQualityAssuranceTaskDetails(qualityAssuranceTaskId: string): Promise<QualityAssuranceTaskDetailsResponse> {
  const { data } = await axios.get<QualityAssuranceTaskDetailsResponse>(`${config.apiUrl}/admin/quality-assurance-tasks/${qualityAssuranceTaskId}`);
  return data;
}

export default function useGetQualityTaskDetails(
  qualityAssuranceTaskId: string,
  options?: Omit<UseQueryOptions<QualityAssuranceTaskDetailsResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.QualityAssurnaceTaskDetails, qualityAssuranceTaskId],
    () => getQualityAssuranceTaskDetails(qualityAssuranceTaskId),
    options,
  );
}
