import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SelectTeacher, { SelectedTeacher } from '@hoot/components/modals/SelectTeacher';
import useGetTeacherAccount from '@hoot/hooks/api/user/teacher/useGetTeacherAccount';
import useGetTeacherShiftConflicts, { ShiftConflictSeverity } from '@hoot/hooks/api/user/teacher/useGetTeacherShiftConflicts';
import { TentativeLessonTeacherAccountFilter } from '@hoot/hooks/api/user/teacher/useSearchTeacherAccounts';
import { ShiftConflictMessage } from '@hoot/pages/lessons/create-v2/common/ConflictMessage';
import CreateShiftFooter from '../CreateShiftFooter';
import { CreateShiftWizardStepEnum, useCreateShiftContext } from '../context/CreateShiftContextProvider';

export interface TentativeShiftForm {
  startsAt: DateTime;
  endsAt: DateTime;
  skip: boolean;
  conflict?: {
    severity: ShiftConflictSeverity;
    message: string;
  };
}

interface Form {
  teacher: SelectedTeacher | null;
  tentativeShifts: TentativeShiftForm[];
}

const TeacherConflictCheckStep = () => {
  const { goToNextStep, goToPrevStep, goToStep, shiftDetails, setShiftDetails } = useCreateShiftContext();

  const { control, handleSubmit, watch, reset, setValue } = useForm<Form>({
    defaultValues: {
      teacher: shiftDetails.teacher,
      tentativeShifts: [],
    },
  });

  // If we are launching the wizard from a teacher's profile, we can populate the teacher now
  const { data: teacherData } = useGetTeacherAccount(shiftDetails.urlParamTeacherAccountId ?? '', {
    enabled: !!shiftDetails.urlParamTeacherAccountId,
  });

  useEffect(() => {
    if (teacherData) {
      setValue('teacher', {
        id: teacherData.id,
        name: `${teacherData.user.firstName} ${teacherData.user.lastName}`,
        number: `${teacherData.number}`,
      });
    }
  }, [teacherData, setValue]);

  const { teacher, tentativeShifts } = watch();

  const atLeastOneShiftSelected = tentativeShifts.filter((shift) => !shift.skip).length > 0;

  const getConflicts = useGetTeacherShiftConflicts(
    {
      teacherAccountId: teacher?.id ?? '',
      requestedShifts:
        shiftDetails.tentativeShifts?.map((shift) => ({
          startsAt: shift.startsAt.toMillis(),
          endsAt: shift.endsAt.toMillis(),
        })) ?? [],
    },
    {
      enabled: !!teacher && !!shiftDetails?.tentativeShifts?.length,
      onSuccess: (response) => {
        if (shiftDetails?.tentativeShifts) {
          const tentativeShifts = shiftDetails.tentativeShifts?.map<TentativeShiftForm>((l) => {
            const conflict = response.find((r) => r.startsAt === l.startsAt.toMillis());
            return {
              startsAt: l.startsAt,
              endsAt: l.endsAt,
              skip: conflict?.severity === ShiftConflictSeverity.Blocker || l.skip,
              conflict,
            };
          });
          reset({
            teacher: teacher,
            tentativeShifts: tentativeShifts,
          });
        }
      },
    },
  );

  const handlePreviousClick = () => {
    goToPrevStep();
  };

  const onSubmit = (data: Form) => {
    if (teacher) {
      setShiftDetails({
        ...shiftDetails!,
        tentativeShifts: data.tentativeShifts,
        teacher: teacher,
      });
      goToNextStep();
    }
  };

  const isLoading = getConflicts.isFetching;

  const teacherName = teacher ? teacher.name : 'N/A';
  if (!shiftDetails) {
    goToStep(CreateShiftWizardStepEnum.DateAndTime);
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Typography variant="titleMedium">Select Teacher</Typography>
        <Controller
          name="teacher"
          control={control}
          render={({ field }) => (
            <SelectTeacher
              disabled={!!shiftDetails.preSelectedTeacher}
              value={field.value}
              onChange={field.onChange}
              tentativePeriods={shiftDetails.tentativeShifts.map<TentativeLessonTeacherAccountFilter>((shift) => ({
                startsAt: shift.startsAt.toMillis(),
                endsAt: shift.endsAt.toMillis(),
              }))}
              periodIsLesson={false}
              showHasConflictFilter={false}
              error={!teacher}
            />
          )}
        />
        {teacher ? (
          <>
            <Typography sx={{ marginTop: '24px' }} variant="titleMedium">
              Select from these available dates for {teacherName}
            </Typography>
            <Controller
              control={control}
              name="tentativeShifts"
              render={({ field }) => <TentativeShifts isLoading={isLoading} onChange={field.onChange} value={field.value} />}
            />
          </>
        ) : null}
      </Stack>
      <CreateShiftFooter>
        <Button onClick={handlePreviousClick} variant="contained" size="large">
          Previous Step
        </Button>
        <Stack direction="row" gap={3}>
          <Button type="submit" variant="contained" size="large" disabled={!teacher || !atLeastOneShiftSelected}>
            Next Step
          </Button>
        </Stack>
      </CreateShiftFooter>
    </form>
  );
};

function TentativeShifts(props: { isLoading?: boolean; value: TentativeShiftForm[]; onChange: (value: TentativeShiftForm[]) => void }) {
  if (props.isLoading) {
    return <CircularProgress />;
  }

  const handleClick = (shift: TentativeShiftForm) => {
    const updatedState = props.value.map<TentativeShiftForm>((v) =>
      shift.startsAt === v.startsAt
        ? {
            ...v,
            skip: !shift.skip,
          }
        : v,
    );
    props.onChange(updatedState);
  };

  return (
    <Stack>
      <Stack sx={{ marginTop: '8px' }} direction="row">
        <Typography sx={{ width: '49px' }} variant="bodySmall">
          Create Shift
        </Typography>
        <Typography sx={{ marginLeft: '40px', width: '43px' }} variant="bodySmall">
          Skip Shift
        </Typography>
      </Stack>
      <FormGroup sx={{ marginTop: '8px' }}>
        {props.value.map((l) => (
          <TentativeShiftField key={l.startsAt.toMillis()} value={l} onClick={handleClick} />
        ))}
      </FormGroup>
    </Stack>
  );
}

function TentativeShiftField(props: { value: TentativeShiftForm; onClick: (value: TentativeShiftForm) => void }) {
  const canAssignTeacher = props.value.conflict?.severity !== 'BLOCKER';
  const handleClick = () => {
    props.onClick(props.value);
  };

  return (
    <Stack direction="row">
      <FormControlLabel
        disabled={!canAssignTeacher}
        control={
          <Stack direction="row">
            <Checkbox sx={{ marginLeft: '6px' }} disabled={!canAssignTeacher} onClick={handleClick} checked={!props.value.skip} />
            <Checkbox sx={{ marginLeft: '45px' }} disabled={!canAssignTeacher} onClick={handleClick} checked={props.value.skip} />
          </Stack>
        }
        label={
          <Typography variant="bodyLarge" sx={{ marginLeft: '42px' }}>
            {props.value.startsAt.toFormat('ccc, LLL d, yyyy @ h:mma')} - {props.value.endsAt.toFormat('h:mma')}
          </Typography>
        }
      />
      {props.value.conflict ? <ShiftConflictMessage severity={props.value.conflict.severity} message={props.value.conflict.message} /> : null}
    </Stack>
  );
}

export default TeacherConflictCheckStep;
