import { SortByQueryParams } from '@hoot/interfaces/order-by';
import { PaginationQueryParams } from '@hoot/interfaces/pagination';

enum LocationStudentsPageQueryNames {
  Search = 'search',
  SearchByField = 'searchByField',
  Status = 'status',
  HasEnrolments = 'hasEnrolments',
  Enrolment = 'enrolment',
}

export const LocationStudentsPageQueryParams = { ...LocationStudentsPageQueryNames, ...PaginationQueryParams, ...SortByQueryParams };
