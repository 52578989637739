import { CircularProgress } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useAlert } from '@hoot/contexts/AlertContext';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import useCreateQualityAssuranceTaskComment from '@hoot/hooks/api/tasks/useCreateQualityAssuranceTaskComment';
import useGetQualityAssuranceTaskHistory from '@hoot/hooks/api/tasks/useGetQualityAssuranceTaskHistory';
import { CommentSubmission } from '@hoot/interfaces/history';
import { CommonHistoryTimeline } from '../users/common/CommonHistoryTimeline';

const QualityAssuranceHistory = (props: { qualityAssuranceTaskId: string }) => {
  const { qualityAssuranceTaskId } = props;
  const { error, success } = useAlert();
  const queryClient = useQueryClient();

  const { data, isFetching } = useGetQualityAssuranceTaskHistory(qualityAssuranceTaskId, {
    cacheTime: 0,
    onError: (err) => {
      const message =
        err.response && err.response.data?.message?.trim().length > 0
          ? err.response.data.message
          : `There was an issue getting the history for task ${qualityAssuranceTaskId}!`;
      error(message);
      console.error(err);
    },
  });

  const createComment = useCreateQualityAssuranceTaskComment(qualityAssuranceTaskId, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.GetQualityAssuranceTaskHistory, qualityAssuranceTaskId]);
      success(`Successfully added an item to tasks's history timeline!`);
    },
    onError: (err) => {
      error(`There was an issue submitting the item for this task!`);
      console.error(err);
    },
  });

  const handleCommentSubmit = (comment: CommentSubmission, cb: () => void) => {
    createComment.mutate(comment, {
      onSettled: () => {
        cb();
      },
    });
  };

  if (!data || isFetching) {
    return <CircularProgress />;
  }

  return <CommonHistoryTimeline handleCommentSubmit={handleCommentSubmit} historyItems={data?.historyItems ?? []} />;
};

export default QualityAssuranceHistory;
