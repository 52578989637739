import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { SetStateAction, useState } from 'react';
import { Tldraw, loadSnapshot } from 'tldraw';
import useGetLessonWhiteboardSnapshot, { WhiteboardStoreSnapshotResponse } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboardSnapshot';

interface IWhiteboardModalProps {
  whiteboardId: string | undefined;
  setSelectedWhiteboard: React.Dispatch<SetStateAction<string | undefined>>;
}

const WhiteboardModal = (props: IWhiteboardModalProps) => {
  const { whiteboardId, setSelectedWhiteboard } = props;
  const [whiteboardSnapshotResponse, setWhiteboardSnapshotResponse] = useState<WhiteboardStoreSnapshotResponse>();

  useGetLessonWhiteboardSnapshot(whiteboardId!, {
    enabled: !!whiteboardId,
    onSuccess: (response) => {
      setWhiteboardSnapshotResponse(response);
    },
  });

  const onClose = () => {
    setSelectedWhiteboard(undefined);
    setWhiteboardSnapshotResponse(undefined);
  };

  return (
    <Dialog open={!!whiteboardId} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Whiteboard - {whiteboardSnapshotResponse?.templateName}</Typography>
          <IconButton onClick={onClose} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box
          className="tldraw__editor"
          sx={{
            height: '70vh',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {whiteboardSnapshotResponse ? (
            <Tldraw
              components={{
                StylePanel: null,
                PageMenu: null,
                DebugPanel: null,
                DebugMenu: null,
                SharePanel: null,
                CursorChatBubble: null,
              }}
              onMount={(editor) => {
                loadSnapshot(editor.store, { document: whiteboardSnapshotResponse.storeSnapshot });
                editor.updateInstanceState({ isReadonly: true });
                editor.zoomToFit();
              }}
            />
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WhiteboardModal;
