import { Search } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

const SEARCH_INPUT_DEBOUNCE_TIME_MS = 1000;

export interface SearchTextFieldProps {
  searchInput: string;
  onSearchInputChanged: (text: string) => void;
  onSearchInputDebounced?: (text: string) => void;
  debounceTime?: number;
  sx?: TextFieldProps['sx'];
  autoFocus?: boolean;
}

const SearchTextField = (props: SearchTextFieldProps) => {
  const { searchInput, onSearchInputChanged, onSearchInputDebounced, debounceTime = SEARCH_INPUT_DEBOUNCE_TIME_MS, sx, autoFocus } = props;

  const _onSearchInputChanged: TextFieldProps['onChange'] = (event) => {
    const input = event.target.value;
    onSearchInputChanged(input);
    if (onSearchInputDebounced) {
      setDebouncedSearchInput(input);
    }
  };

  const setDebouncedSearchInput = useDebouncedCallback((searchText) => {
    onSearchInputDebounced?.(searchText);
  }, debounceTime);

  const handleClearSearch = () => {
    onSearchInputChanged('');
    onSearchInputDebounced?.('');
  };

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        label="Search"
        value={searchInput}
        onChange={_onSearchInputChanged}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment:
            searchInput.length > 0 ? (
              <InputAdornment position="end">
                <IconButton aria-label="clear search text" onClick={handleClearSearch} edge="end">
                  <HighlightOffIcon />
                </IconButton>
              </InputAdornment>
            ) : undefined,
        }}
        sx={sx}
        autoFocus={autoFocus}
      />
    </>
  );
};

export default SearchTextField;
