import { Route, Routes } from 'react-router-dom';
import Login from '@hoot/pages/public/login';
import PublicView from '../pages/public/PublicView';

const PublicRoutes = () => {
  return (
    <PublicView>
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    </PublicView>
  );
};

export default PublicRoutes;
