import { CircularProgress, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ITotalsTimeSlotProps } from '../../shift-interfaces';

const TotalsTimeSlot = (props: ITotalsTimeSlotProps) => {
  const { lessonCount, shiftCount, isLoading } = props;
  const backgroundColour = lessonCount === shiftCount ? '#6CB944' : '#F26D4F';

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '130px',
        borderLeft: '1px solid #CAC4D0',
        borderBottom: '1px solid #CAC4D0',
        '&:last-child': { borderRight: '1px solid #CAC4D0' },
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Tooltip title={`Shifts: ${shiftCount}`}>
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-evenly',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography variant="titleSmall" sx={{ fontWeight: 700 }}>
                S.
              </Typography>
              <Typography
                variant="labelSmall"
                sx={{
                  borderRadius: '50%',
                  width: '18px',
                  height: '18px',
                  background: backgroundColour,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {shiftCount}
              </Typography>
            </Stack>
          </Tooltip>
          <Tooltip title={`Lessons: ${lessonCount}`}>
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-evenly',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography variant="titleMedium" sx={{ fontWeight: 700 }}>
                L.
              </Typography>
              <Typography
                variant="labelSmall"
                sx={{
                  borderRadius: '50%',
                  width: '18px',
                  height: '18px',
                  background: backgroundColour,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {lessonCount}
              </Typography>
            </Stack>
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

export default TotalsTimeSlot;
