import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { FilterOption } from '@hoot/components/form/filterDropDown/common/DateFilter';
import FilterDropDown from '../../FilterDropDown';

export function StudentSchoolFilterDropDown(props: {
  value: string[];
  onChange: (val: string[]) => void;
  popOverButtonSx?: ButtonProps['sx'];
  options: FilterOption<string>[];
}) {
  const { value, onChange, popOverButtonSx, options } = props;

  return (
    <FilterDropDown<string>
      title="Schools"
      dismissPopoverOnSelection={true}
      value={value}
      options={options}
      onChange={(val) => onChange(val)}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveStudentSchoolFilterChips(props: { value?: string[]; onChange: (val: string[]) => void; options: FilterOption<string>[] }) {
  const { value = [], onChange, options } = props;

  const handleDelete = (val: string) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`School: ${options.find((o) => o.value === c)?.label}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}
