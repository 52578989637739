import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface School {
  id: string;
  shortId: string;
  name: string;
  city: string;
  country: string;
  districtId: string | null;
  provinceState: string;
  timezone: string;
}

export interface GetSchoolsResponse {
  schools: School[];
}

const getSchoolsForDistrict = async (locationId: string): Promise<GetSchoolsResponse> => {
  const { data } = await axios.get(`${config.apiUrl}/admin/hfs/${locationId}/schools`);
  return data;
};

export default function useGetSchoolsForDistrict(locationId: string, options?: Omit<UseQueryOptions<GetSchoolsResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetSchoolsForDistrict], () => getSchoolsForDistrict(locationId), options);
}
