import axios, { AxiosError, AxiosResponse } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { ParentDetailsForm } from '@hoot/components/Secure/Users/parent/form';
import { config } from '@hoot/config';

export interface CreateParentProfileResponse {
  id: string;
}

async function createParentProfile(payload: ParentDetailsForm): Promise<AxiosResponse<CreateParentProfileResponse>> {
  return await axios.post<CreateParentProfileResponse>(`${config.apiUrl}/admin/parent/create`, payload);
}

export default function useCreateParentProfile(
  options?: Omit<UseMutationOptions<AxiosResponse<CreateParentProfileResponse>, AxiosError, ParentDetailsForm>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((data: ParentDetailsForm) => {
    return createParentProfile(data);
  }, options);
}
