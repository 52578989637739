import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface GetTeacherAvailabilityExceptionRequest {
  teacherAccountId: string;
  startsAt: number;
  endsAt: number;
}

export interface TeacherAvailabilityExceptionResponse {
  id: string;
  startsAt: number;
  endsAt: number;
}

async function getTeacherAvailabilityException(params: GetTeacherAvailabilityExceptionRequest) {
  const { data } = await axios.get<TeacherAvailabilityExceptionResponse[]>(`${config.apiUrl}/admin/availability-exception`, { params });
  return data;
}

export function useGetTeacherAvailabilityException(
  params: GetTeacherAvailabilityExceptionRequest,
  options?: Omit<UseQueryOptions<TeacherAvailabilityExceptionResponse[], AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.TeacherAvailabilityException, params], () => getTeacherAvailabilityException(params), options);
}
