import { TeacherBlockType } from '@hoot-reading/hoot-core/dist/enums/teacher-block';
import { Check } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Control, Controller, UseFormSetValue, useForm } from 'react-hook-form';
import { TimePicker2 } from '@hoot/components/form/DatePicker';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { daysOfWeekLabels } from '@hoot/pages/lessons/utils/date';
import { DayOfWeek } from '@hoot/utils/dateTime';
import CreateBlockFooter from '../CreateBlockFooter';
import { DayOfWeekForBlock, teacherBlockTypeOptions, useCreateBlockContext } from '../context/CreateBlockContextProvider';
import { TentativeBlockForm } from '../teacher-conflicts-step/TeacherConflictCheckStep';
import { BlockTimeByDayOfWeek, GenerateBlock, generateBlockTimes } from '../utils/generateBlockTimes';

interface Form {
  blockDate: DateTime;
  blockStartTime: DateTime;
  blockEndTime: DateTime;
  blockType: TeacherBlockType;
  recurring: boolean;
  recurrenceEnd: RecurrenceEndType;
  occurrences: number;
  endAfterDate: DateTime;
  daysOfWeek: DayOfWeekForBlock[];
}

const MAX_SHIFTS = 365;

export enum RecurrenceEndType {
  EndAfterDate = 'end_after_date',
  EndOnBlockCount = 'end_on_block_count',
}

const CreateBlockDateTimeStep = () => {
  const { goToNextStep, blockDetails, setBlockDetails } = useCreateBlockContext();

  const now = DateTime.now().plus({ minutes: 1 });

  function getMinStartDate() {
    return DateTime.now();
  }

  function getMaxStartDate() {
    return DateTime.now().plus({ year: 1 }).endOf('day');
  }

  const minBlockRecurrenceDate = DateTime.now().plus({ days: 7 }).endOf('day');

  const { control, handleSubmit, formState, getValues, setValue, watch } = useForm<Form>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      blockDate: blockDetails.blockDate,
      blockStartTime: blockDetails.blockStartTime,
      blockEndTime: blockDetails.blockEndTime,
      blockType: blockDetails.blockType,
      recurring: blockDetails.recurring,
      recurrenceEnd: blockDetails.recurrenceEnd,
      occurrences: blockDetails.occurrences,
      endAfterDate: blockDetails.endAfterDate,
      daysOfWeek: blockDetails.daysOfWeek,
    },
  });

  const { recurring, recurrenceEnd } = watch();

  function validateEndDate(value: DateTime | undefined) {
    const { blockStartTime } = getValues();
    if (value) {
      if (value <= blockStartTime) {
        return 'End Date cannot be before Start Date';
      }
    }
    return true;
  }

  const onSubmit = (data: Form) => {
    const blockStartsAt = DateTime.fromObject({
      year: data.blockDate.year,
      month: data.blockDate.month,
      day: data.blockDate.day,
      hour: data.blockStartTime.hour,
      minute: data.blockStartTime.minute,
      second: 0,
      millisecond: 0,
    });

    const blockEndsAt = DateTime.fromObject({
      year: data.blockDate.year,
      month: data.blockDate.month,
      day: data.blockDate.day,
      hour: data.blockEndTime.hour,
      minute: data.blockEndTime.minute,
      second: 0,
      millisecond: 0,
    });

    let tentativeBlocks: TentativeBlockForm[] = [];

    if (data.recurring) {
      const daysOfWeek = data.daysOfWeek
        .filter((d) => d.isSelected)
        .map<BlockTimeByDayOfWeek>((d) => ({
          dayOfWeek: d.dayOfWeek,
          startTime: blockStartsAt?.toFormat('H:mm') ?? DateTime.now().toFormat('H:mm'),
          endTime: blockEndsAt?.toFormat('H:mm') ?? DateTime.now().toFormat('H:mm'),
        }));

      const request: GenerateBlock =
        data.recurrenceEnd === RecurrenceEndType.EndAfterDate && data.endAfterDate
          ? {
              // FYI: `startsAt` just represents the start day range from which we can start creating blocks from.
              // It doesn't necessarily mean that the initial blocks will land on this day (`blockTimeByDayOfWeek`
              // will determine first block day starting from the `startsAt`).
              startsAt: blockStartsAt.startOf('day'),
              endsAt: blockEndsAt.startOf('day'),
              blockTimeByDayOfWeek: daysOfWeek,
              endDate: data.endAfterDate,
            }
          : {
              startsAt: blockStartsAt.startOf('day'),
              endsAt: blockEndsAt.startOf('day'),
              blockTimeByDayOfWeek: daysOfWeek,
              occurrences: data.occurrences,
            };

      tentativeBlocks = generateBlockTimes(request);
    } else {
      tentativeBlocks = [
        {
          startsAt: blockStartsAt,
          endsAt: blockEndsAt,
          skip: false,
        },
      ];
    }

    setBlockDetails({
      blockDate: data.blockDate,
      blockStartTime: data.blockStartTime,
      blockEndTime: data.blockEndTime,
      blockType: data.blockType,
      recurring: data.recurring,
      recurrenceEnd: data.recurrenceEnd,
      occurrences: data.occurrences,
      endAfterDate: data.endAfterDate,
      daysOfWeek: data.daysOfWeek,
      tentativeBlocks: tentativeBlocks,
      teacher: blockDetails.teacher,
      preSelectedTeacher: blockDetails.preSelectedTeacher,
      urlParamTeacherAccountId: blockDetails.urlParamTeacherAccountId,
    });
    goToNextStep();
  };

  const minStartDate = getMinStartDate();
  const maxStartDate = getMaxStartDate();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Typography variant="titleMedium">Scheduling</Typography>
        <Stack sx={{ marginTop: '24px' }} direction="row" spacing="12px" gap={2}>
          <Controller
            name="blockDate"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <DesktopDatePicker
                label={'Date'}
                disablePast
                minDate={minStartDate}
                onChange={field.onChange}
                value={field.value}
                format="LL/dd/yyyy"
                slotProps={{
                  textField: {
                    helperText: fieldState.error?.message,
                    error: !!fieldState.error,
                  },
                }}
              />
            )}
          />
          <Controller
            name="blockStartTime"
            control={control}
            rules={{
              required: true,
              validate: (val) => {
                if (!val) {
                  return 'Selected Time is a required value';
                }

                const { blockDate, blockStartTime } = getValues();

                if (!blockDate) {
                  return 'Date cannot be empty!';
                }

                try {
                  const startsAtDateTime = blockDate.set({
                    hour: blockStartTime.hour,
                    minute: blockStartTime.minute,
                    second: 0,
                    millisecond: 0,
                  });

                  const now = DateTime.now();
                  if (startsAtDateTime.toMillis() < now.toMillis()) {
                    return `Selected Time must be greater than ${now.toFormat('h:mm a')}`;
                  }
                } catch (exc) {
                  console.error('Not a valid time', exc);
                  return 'Not a valid time.';
                }

                return true;
              },
            }}
            render={({ field, fieldState }) => (
              <TimePicker2
                onChange={field.onChange}
                value={field.value}
                label="Start Time"
                slotProps={{ textField: { error: !!fieldState.error, helperText: fieldState.error?.message } }}
              />
            )}
          />
          <Controller
            name="blockEndTime"
            control={control}
            rules={{
              required: true,
              validate: (val) => {
                if (!val) {
                  return 'Selected Time is a required value';
                }

                const { blockStartTime } = getValues();

                if (!blockStartTime) {
                  return 'Date cannot be empty!';
                }

                if (val.toMillis() < blockStartTime.toMillis()) {
                  return `Selected Time must be greater than ${blockStartTime.toFormat('h:mm a')}`;
                }

                return true;
              },
            }}
            render={({ field, fieldState }) => (
              <TimePicker2
                onChange={field.onChange}
                value={field.value}
                label="End Time"
                slotProps={{ textField: { error: !!fieldState.error, helperText: fieldState.error?.message } }}
              />
            )}
          />
        </Stack>
        <FormGroup sx={{ marginTop: '24px', width: '150px', gap: 2 }}>
          <Dropdown name="blockType" label="Type" options={teacherBlockTypeOptions} control={control} rules={{ required: true }} variant="outlined" />
          <Controller
            control={control}
            name="recurring"
            render={({ field }) => <FormControlLabel control={<Checkbox onChange={field.onChange} checked={field.value} />} label="Recurring" />}
          />
        </FormGroup>
        {recurring ? (
          <Stack marginLeft={10} marginTop="16px" gap={2}>
            <Typography variant="titleMedium">Recurrence Details</Typography>
            <Box>
              <Controller
                control={control}
                name="daysOfWeek"
                rules={{
                  validate: (data) => {
                    if (data.every((v) => v.isSelected === false)) {
                      return 'You must select 1 day of the week.';
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState }) => (
                  <DaysOfWeek
                    defaultTime={now}
                    onChange={field.onChange}
                    value={field.value}
                    errorMessage={fieldState.error?.message}
                    parentControl={control}
                    sameTimeEveryDay={false}
                    onSameTimeEveryDayChanged={(_checked) => {}}
                    setValue={setValue}
                  />
                )}
              />
            </Box>
            <Typography sx={{ marginTop: '24px' }} variant="titleMedium">
              Recurrence End
            </Typography>

            <Controller
              name="recurrenceEnd"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  onChange={field.onChange}
                  value={field.value}
                  sx={{
                    width: '250px;',
                  }}
                >
                  <FormControlLabel value={RecurrenceEndType.EndAfterDate} control={<Radio />} label="End after date" />
                  <FormControlLabel value={RecurrenceEndType.EndOnBlockCount} control={<Radio />} label="End on block count" />
                </RadioGroup>
              )}
            />

            {recurrenceEnd === RecurrenceEndType.EndOnBlockCount ? (
              <Controller
                control={control}
                name="occurrences"
                rules={{
                  required: recurrenceEnd === RecurrenceEndType.EndOnBlockCount,
                  validate: (val: number | '' | undefined) => {
                    if (!val) {
                      return 'Number of blocks is required.';
                    }

                    if (val && val < 1) {
                      return 'A minimum of 1 block is required.';
                    }

                    if (val && val > MAX_SHIFTS) {
                      return `Maximum number of blocks allowed is ${MAX_SHIFTS}.`;
                    }

                    return true;
                  },
                }}
                render={({ field, fieldState }) => (
                  <Stack>
                    <TextField
                      sx={{
                        marginLeft: '32px',
                        maxWidth: '156px',
                      }}
                      error={!!fieldState.error}
                      onChange={(e) => field.onChange(parseInt(e.currentTarget.value))}
                      value={field.value}
                      variant="outlined"
                      label="Number of blocks"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputProps: { min: 1, defaultValue: 1 },
                      }}
                      helperText={fieldState.error ? fieldState.error.message : null}
                    />
                  </Stack>
                )}
              />
            ) : (
              <FormControl
                sx={{
                  marginLeft: '32px',
                  maxWidth: '156px',
                }}
                fullWidth
              >
                <Controller
                  name="endAfterDate"
                  control={control}
                  rules={{
                    required: recurrenceEnd === RecurrenceEndType.EndAfterDate,
                    validate: validateEndDate,
                  }}
                  render={({ field, fieldState }) => (
                    <DesktopDatePicker
                      label={'Recurrance End'}
                      minDate={minBlockRecurrenceDate}
                      maxDate={maxStartDate}
                      onChange={field.onChange}
                      value={field.value}
                      format="LL/dd/yyyy"
                      slotProps={{
                        textField: {
                          helperText: fieldState.error?.message,
                          error: !!fieldState.error,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            )}
          </Stack>
        ) : undefined}
        <CreateBlockFooter>
          <Button disabled={true} variant="contained" size="large">
            Previous Step
          </Button>
          <Button disabled={!formState.errors} type="submit" variant="contained" size="large">
            Next Step
          </Button>
        </CreateBlockFooter>
      </Stack>
    </form>
  );
};

function DaysOfWeek(props: {
  onChange: (val: DayOfWeekForBlock[]) => void;
  value: DayOfWeekForBlock[];
  defaultTime: DateTime;
  errorMessage?: string;
  parentControl: Control<Form, any>;
  sameTimeEveryDay: boolean;
  onSameTimeEveryDayChanged: (checked: boolean) => void;
  setValue: UseFormSetValue<Form>;
}) {
  const handleClick = (dayOfWeek: DayOfWeek) => () => {
    const updatedValue = props.value.map((v) => (v.dayOfWeek === dayOfWeek ? { ...v, isSelected: !v.isSelected } : v));
    props.onChange(updatedValue);
  };

  return (
    <Stack>
      <Stack direction="row" spacing="8px">
        {props.value.map((dow) => (
          <Chip
            key={dow.dayOfWeek}
            icon={dow.isSelected ? <Check /> : undefined}
            sx={{
              backgroundColor: dow.isSelected ? '#D8E3F8' : '#FFFFFF',
            }}
            variant="outlined"
            label={daysOfWeekLabels.get(dow.dayOfWeek)}
            onClick={handleClick(dow.dayOfWeek)}
          />
        ))}
      </Stack>
      {props.errorMessage ? <FormHelperText error>{props.errorMessage}</FormHelperText> : null}
    </Stack>
  );
}

export default CreateBlockDateTimeStep;
