import { TeacherSpecializedTraining } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-specialized-training.enum';
import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { teacherSpecializedTrainingOptions, teacherSpecializedTrainingTypes } from '../../../../interfaces/teacher';
import FilterDropDown from '../../FilterDropDown';

export function TeacherSpecializedTrainingsFilterDropDown(props: {
  value?: TeacherSpecializedTraining[];
  onChange: (val: TeacherSpecializedTraining[]) => void;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { value, onChange, popOverButtonSx } = props;

  return (
    <FilterDropDown<string>
      title="Specialized Training"
      dismissPopoverOnSelection={true}
      value={value}
      options={teacherSpecializedTrainingOptions}
      onChange={(val) => onChange(val as TeacherSpecializedTraining[])}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveTeacherSpecializedTrainingsFilterDropDown(props: {
  value?: TeacherSpecializedTraining[];
  onChange: (val: TeacherSpecializedTraining[]) => void;
}) {
  const { value = [], onChange } = props;

  const handleDelete = (val: TeacherSpecializedTraining) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`Specialized: ${teacherSpecializedTrainingTypes[c]}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}
