import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';

async function getAvailablityExport() {
  const { data } = await axios.get<string>(`${config.apiUrl}/admin/availability/export`);
  return data;
}

export default function useExportAvailablity(options?: Omit<UseQueryOptions<string, AxiosError, string>, 'queryKey' | 'queryFn'>) {
  return useQuery<string, AxiosError, string>('export-availablity', () => getAvailablityExport(), options);
}
