import { SvgIcon, SvgIconProps } from '@mui/material';

const FormativeAssessmentIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" sx={{ fill: props.fill ?? 'none' }} {...props}>
    <g clipPath="url(#clip0_15842_84082)">
      <rect width="24" height="24" fill="#5782EF" />
      <g filter="url(#filter0_dd_15842_84082)">
        <g clipPath="url(#clip1_15842_84082)">
          <path
            d="M10.6892 6.18086C11.2256 5.05072 12.7756 5.05072 13.3121 6.18086L14.2392 8.13404C14.4522 8.58282 14.864 8.89388 15.3404 8.96584L17.4135 9.27904C18.6131 9.46027 19.092 10.9929 18.224 11.8727L16.7239 13.393C16.3792 13.7423 16.222 14.2456 16.3033 14.7389L16.6574 16.8856C16.8624 18.1278 15.6084 19.075 14.5354 18.4885L12.6812 17.475C12.2551 17.2421 11.7462 17.2421 11.3201 17.475L9.46582 18.4885C8.39291 19.075 7.13893 18.1278 7.34384 16.8857L7.69798 14.7389C7.77934 14.2456 7.62206 13.7423 7.27737 13.393L5.77724 11.8727C4.90923 10.9929 5.3882 9.46027 6.58776 9.27904L8.6609 8.96584C9.13724 8.89388 9.54903 8.58282 9.76206 8.13404L10.6892 6.18086Z"
            fill="#F0C037"
          />
        </g>
        <rect x="3.5" y="3.5" width="17" height="17" rx="4.5" stroke="white" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_15842_84082"
        x="0.333333"
        y="1"
        width="23.3333"
        height="23.3333"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feMorphology radius="0.666667" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_15842_84082" />
        <feOffset dy="0.666667" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15842_84082" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="0.666667" />
        <feGaussianBlur stdDeviation="0.666667" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_15842_84082" result="effect2_dropShadow_15842_84082" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_15842_84082" result="shape" />
      </filter>
      <clipPath id="clip0_15842_84082">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_15842_84082">
        <rect x="4" y="4" width="16" height="16" rx="4" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default FormativeAssessmentIcon;
