import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../../queryKeys';

export enum ShiftConflictSeverity {
  Blocker = 'BLOCKER',
  HighRisk = 'HIGH_RISK',
  Caution = 'CAUTION',
}

export interface RequestedShiftForConflictChecker {
  startsAt: number;
  endsAt: number;
}

export interface TeacherShiftConflictsRequest {
  teacherAccountId: string;
  requestedShifts: RequestedShiftForConflictChecker[];
}

export interface TeacherShiftConflictsResponse {
  id: string;
  number: number;
  startsAt: number;
  endsAt: number;
  severity: ShiftConflictSeverity;
  message: string;
}

export async function getTeacherShiftConflicts(request: TeacherShiftConflictsRequest) {
  const { data } = await axios.post<TeacherShiftConflictsResponse[]>(`${config.apiUrl}/admin/teacher-shift/conflicts`, request);
  return data;
}

export default function useGetTeacherShiftConflicts(
  conflictsRequest: TeacherShiftConflictsRequest,
  options?: Omit<UseQueryOptions<TeacherShiftConflictsResponse[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.TeacherShiftConflicts, conflictsRequest], () => getTeacherShiftConflicts(conflictsRequest), options);
}
