import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { ControllerFieldState } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { hootQualifications } from '@hoot/interfaces/teacher';
import { routes } from '@hoot/routes/routes';
import { enrolmentStatusMapping } from '../../../../../components/hfs-utils';
import ProductIcon from '../../../../../components/icons/ProductIcon';
import NoneItem from '../../../../../components/ui/NoneItem';
import StatusChip, { StatusChipIcon, StatusChipIconColor } from '../../../../../components/ui/StatusChip';
import useGetProduct, { ProductDetailsResponse } from '../../../../../hooks/api/hfs/district-schools/products/useGetProduct';
import { productProgram } from '../../../products/enum';
import { enrolmentStatusChipLookup } from '../../../types';
import ProductAssociationModal from './ProductAssociationModal';

export function EnrolmentsStatus(props: { status: EnrolmentStatus; count: number }) {
  const { count, status } = props;

  return (
    <StatusChip
      size="small"
      statusIcon={enrolmentStatusChipLookup[status].statusIcon}
      iconColor={enrolmentStatusChipLookup[status].iconColor}
      label={enrolmentStatusMapping[status]}
      count={count}
    />
  );
}

const AssociatedProduct = (props: { product: ProductDetailsResponse }) => {
  const { product } = props;

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid
            alignItems="center"
            item
            xs={3}
            sx={{
              p: '24px',
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              pt: 5,
            }}
          >
            <ProductIcon />
            <Link to={routes.districtsSchools.products.details.url(product.location.id, product.id)}>
              <Typography variant="bodySmall">{product.friendlyId}</Typography>
            </Link>
            <StatusChip label="Active" iconColor={StatusChipIconColor.Green} statusIcon={StatusChipIcon.FullCircle} />
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              p: '24px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="bodySmall">Type</Typography>
            <Typography variant="bodyLarge">{productProgram[product.program]}</Typography>

            <Typography sx={{ mt: 1 }} variant="bodySmall">
              Start Date
            </Typography>
            <Typography variant="bodyLarge">{DateTime.fromMillis(product.startDate).toFormat('LL-dd-yyyy')}</Typography>

            <Typography sx={{ mt: 1 }} variant="bodySmall">
              End Date
            </Typography>
            <Typography variant="bodyLarge">{DateTime.fromMillis(product.endDate).toFormat('LL-dd-yyyy')}</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              p: '24px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Typography variant="bodySmall">Enrolments</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <EnrolmentsStatus status={EnrolmentStatus.Open} count={product.openEnrolments} />
              <EnrolmentsStatus status={EnrolmentStatus.InProgress} count={product.inProgressEnrolments} />
              <EnrolmentsStatus status={EnrolmentStatus.Scheduled} count={product.scheduledEnrolments} />
              <EnrolmentsStatus status={EnrolmentStatus.Closed} count={product.closedEnrolments} />
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              p: '24px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Typography variant="bodySmall" sx={{ paddingLeft: 0 }}>
              Requirements
            </Typography>
            <Typography
              component="ul"
              sx={{
                paddingLeft: 0,
                listStyleType: 'none',
                '& li::before': {
                  content: '"•"',
                  color: 'black',
                  display: 'inline-block',
                  width: '1em',
                },
              }}
            >
              {product.qualificationRequirements.length > 0 ? (
                product.qualificationRequirements.map((item, index) => (
                  <Typography component="li" variant="bodyLarge" key={index}>
                    {hootQualifications[item as HootQualification]}
                  </Typography>
                ))
              ) : (
                <Typography variant="bodyLarge">None</Typography>
              )}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export function EnrolmentAssociatedProductCard(props: {
  value: string;
  onChange: (val: { id: string; startsAt: number; endsAt: number }) => void;
  fieldState?: ControllerFieldState;
}) {
  const { locationId, enrolmentId } = useParams() as { locationId: string; enrolmentId: string };
  const { value } = props;

  const isEditMode = !!enrolmentId;

  const getProduct = useGetProduct(value, {
    enabled: !!value,
    onSuccess: (data) => {
      if (props.onChange) {
        props.onChange({
          id: value,
          startsAt: data.startDate,
          endsAt: data.endDate,
        });
      }
    },
  });

  const [productOpen, setProductOpen] = useState<boolean>(false);

  const handleApplyProducts = (productId?: string) => {
    if (props.onChange && productId) {
      props.onChange({
        id: productId,
        startsAt: 0,
        endsAt: 0,
      });
    }
  };

  if (getProduct.isLoading) {
    return <CircularProgress />;
  }

  const product = getProduct.data;

  return (
    <Card sx={{ mt: 3 }}>
      <CardHeader
        sx={{ p: 3, pb: 0 }}
        title={
          <Stack sx={{ p: 0 }} justifyContent="space-between" alignItems="center" direction="row">
            <Typography sx={{ color: props.fieldState?.invalid ? '#B3261E' : undefined }} variant="titleLarge">
              Associated Product *
            </Typography>
            {!isEditMode ? (
              <Button variant="contained" onClick={() => setProductOpen(true)}>
                Add
              </Button>
            ) : null}
          </Stack>
        }
      />
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="bodySmall">Product</Typography>
        </Box>
        <Box mt={2}>{product ? <AssociatedProduct product={product} /> : <NoneItem errorMessage={props.fieldState?.error?.message} />}</Box>
      </CardContent>

      {productOpen ? (
        <ProductAssociationModal
          defaultFilters={{ locationId: locationId }}
          isOpen={productOpen}
          onApply={handleApplyProducts}
          close={() => setProductOpen(false)}
        />
      ) : null}
    </Card>
  );
}
