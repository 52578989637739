/** Subject */
export enum Subject {
  Reading = 'READING',
  Math = 'MATH',
}

export const subjectLookUp: Record<Subject, string> = {
  MATH: 'Math',
  READING: 'Reading',
};

/** Language */
export enum Language {
  English = 'EN',
  Spanish = 'ES',
  French = 'FR',
}

export const languageLookUp: Record<Language, string> = {
  EN: 'English',
  ES: 'Spanish',
  FR: 'French',
};

/** Spelling Type */

export enum SpellingType {
  Canada = 'CA',
  UnitedStates = 'US',
}

export const spellingTypeLookUp: Record<SpellingType, string> = {
  CA: 'Canada',
  US: 'UnitedStates',
};

/** BookType */
export enum BookType {
  HootReadingAssessment = 'HOOT_READING_ASSESSMENT',
  Instruction = 'INSTRUCTION',
  FormativeAssessment = 'FORMATIVE_ASSESSMENT',
  SpecialProjects = 'SPECIAL_PROJECTS',
  ProgressMonitoringAssessment = 'PROGRESS_MONITORING_ASSESSMENT',
}

export const bookTypeLookUp: Record<BookType, string> = {
  HOOT_READING_ASSESSMENT: 'Hoot Reading Assessment',
  INSTRUCTION: 'Instruction',
  FORMATIVE_ASSESSMENT: 'Formative Assessment',
  SPECIAL_PROJECTS: 'Special Projects',
  PROGRESS_MONITORING_ASSESSMENT: 'Progress Monitoring Assessment',
};

/** Decodable Formats */
export enum DecodableFormat {
  WiseWords = 'WISE_WORDS',
  DecodablePassages = 'DECODABLE_PASSAGES',
  DecodableBooks = 'DECODABLE_BOOKS',
  QuickChecks = 'QUICK_CHECKS',
}

export const decodableFormatLookUp: Record<DecodableFormat, string> = {
  DECODABLE_BOOKS: 'Decodable Books',
  DECODABLE_PASSAGES: 'Decodable Passages',
  QUICK_CHECKS: 'Quick Checks',
  WISE_WORDS: 'Wise Words',
};

export enum Grade {
  EarlyChildhood = 'EARLY_CHILDHOOD',
  Kindergarten = 'KINDERGARTEN',
  Grade1 = 'GRADE_1',
  Grade2 = 'GRADE_2',
  Grade3 = 'GRADE_3',
  Grade4 = 'GRADE_4',
  Grade5 = 'GRADE_5',
  Grade6To8 = 'GRADE_6_TO_8',
  Grade9To12 = 'GRADE_9_to_12',
}

export const gradeLookUp: Record<Grade, string> = {
  EARLY_CHILDHOOD: 'Early Childhood',
  KINDERGARTEN: 'Kindergarten',
  GRADE_1: 'Grade 1',
  GRADE_2: 'Grade 2',
  GRADE_3: 'Grade 3',
  GRADE_4: 'Grade 4',
  GRADE_5: 'Grade 5',
  GRADE_6_TO_8: 'Grade 6 to 8',
  GRADE_9_to_12: 'Grade 9 to 12',
};
