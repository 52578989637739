import { Close } from '@mui/icons-material';
import { Box, ButtonProps, Chip, Popover } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { PopoverButton } from './PopoverButton';

export interface FilterOption<T> {
  label: string;
  value: T;
}

function DateFilter(props: {
  onChange?: (val: DateTime) => void;
  title: string;
  value?: DateTime;
  dismissPopoverOnSelection?: boolean;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { title, onChange, dismissPopoverOnSelection, value, popOverButtonSx } = props;

  useEffect(() => {
    if (dismissPopoverOnSelection) {
      handleClose();
    }
  }, [dismissPopoverOnSelection]);

  return (
    <Box>
      <PopoverButton title={title} onClick={handleClick} popOverButtonSx={popOverButtonSx} />
      <Popover
        sx={{ mt: 1, maxHeight: '400px' }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DatePicker value={value} onChange={(val: DateTime | null) => (onChange && val ? onChange(val) : null)} />
      </Popover>
    </Box>
  );
}

export function ActiveDateFilter(props: { label: string; value?: string; onChange: (val: string | undefined) => void }) {
  const { label, onChange, value } = props;

  const handleDelete = () => {
    onChange(undefined);
  };
  return (
    <>
      {value ? (
        <Chip key={`chip-date-${label}`} label={`${label}: ${value}`} variant="outlined" onDelete={handleDelete} deleteIcon={<Close />} />
      ) : null}
    </>
  );
}

export default DateFilter;
