import { Button, CircularProgress, Grid, Stack } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { routes } from '@hoot/routes/routes';
import useCreateTeacherBlock, { TeacherBlockRequest } from '../../../../../hooks/api/blocks/useCreateTeacherBlock';
import useCreateTeacherRecurringBlock, {
  RequestedBlockForConflictChecker,
  TeacherRecurringBlockRequest,
} from '../../../../../hooks/api/blocks/useCreateTeacherRecurringBlock';
import CreateBlockFooter from '../CreateBlockFooter';
import { useCreateBlockContext } from '../context/CreateBlockContextProvider';
import ReviewTeacherBlockCard from './ReviewTeacherBlockCard';

const CreateBlockReviewStep = () => {
  const { goToPrevStep, blockDetails, closeBlockCreationModal } = useCreateBlockContext();
  const queryClient = useQueryClient();

  const { setIsDirty } = useBlockIfDirty();

  const navigate = useNavigate();
  const { error, success } = useAlert();

  const createTeacherBlock = useCreateTeacherBlock({
    onSuccess: () => {
      success('Successfully created block.');
      queryClient.invalidateQueries([QueryKey.GetTeacherBlocks]);
      if (!blockDetails.preSelectedTeacher) {
        navigate(routes.users.teachers.details.url(blockDetails!.teacher!.id));
      }
      closeBlockCreationModal();
    },
    onError: (err) => {
      console.error(err);

      // Show error message from API if exists.
      const errorMessage = `An unknown error occurred while creating block(s).`;
      error(errorMessage);
    },
  });

  const createTeacherRecurringBlock = useCreateTeacherRecurringBlock({
    onSuccess: () => {
      success('Successfully created blocks.');
      queryClient.invalidateQueries([QueryKey.GetTeacherBlocks]);
      if (!blockDetails.preSelectedTeacher) {
        navigate(routes.users.teachers.details.url(blockDetails!.teacher!.id));
      }
      closeBlockCreationModal();
    },
    onError: (err) => {
      console.error(err);

      // Show error message from API if exists.
      const errorMessage = `An unknown error occurred while creating recurring block(s).`;
      error(errorMessage);
    },
  });

  const onSubmitBlocks = () => {
    const { teacher, tentativeBlocks, recurring, blockType, blockStartTime, blockEndTime, recurrenceEnd } = blockDetails!;

    const selectedBlocks = tentativeBlocks?.filter((block) => !block.skip) ?? [];

    if (teacher && selectedBlocks.length > 0) {
      setIsDirty(false);
      if (recurring) {
        const request: TeacherRecurringBlockRequest = {
          teacherAccountId: teacher.id,
          startsAt: blockStartTime.toMillis(),
          endsAt: blockEndTime.toMillis(),
          type: blockType,
          recurrenceRules: recurrenceEnd,
          requestedBlocks: selectedBlocks.map<RequestedBlockForConflictChecker>((block) => ({
            startsAt: block.startsAt.toMillis(),
            endsAt: block.endsAt.toMillis(),
          })),
        };
        createTeacherRecurringBlock.mutate(request);
      } else {
        const block = selectedBlocks[0];
        const request: TeacherBlockRequest = {
          teacherAccountId: teacher.id,
          startsAt: block.startsAt.toMillis(),
          endsAt: block.endsAt.toMillis(),
          type: blockType,
        };
        createTeacherBlock.mutate(request);
      }
    }
  };

  return (
    <>
      <Stack>
        <Grid container spacing={3}>
          {!!blockDetails?.tentativeBlocks?.length ? (
            <Grid item sm={12} md={6}>
              <ReviewTeacherBlockCard teacher={blockDetails.teacher!} blocks={blockDetails?.tentativeBlocks} />
            </Grid>
          ) : null}
        </Grid>
        <CreateBlockFooter>
          <Button variant="contained" size="large" onClick={goToPrevStep}>
            Previous Step
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={onSubmitBlocks}
            disabled={createTeacherBlock.isLoading || createTeacherRecurringBlock.isLoading}
          >
            {createTeacherBlock.isLoading || createTeacherRecurringBlock.isLoading ? <CircularProgress color="inherit" size={24} /> : 'Complete'}
          </Button>
        </CreateBlockFooter>
      </Stack>
    </>
  );
};

export default CreateBlockReviewStep;
