import { IconButton, Stack, Typography } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import DeleteBinIcon from '@hoot/components/icons/DeleteBinIcon';
import EditPenIcon from '@hoot/components/icons/EditPenIcon';
import ConfirmDeleteDialog from '@hoot/components/modals/ConfirmDeleteDialog';
import { HeaderData, Table } from '@hoot/components/ui/Table';
import EditAllowedModal from '@hoot/pages/district-schools/details/enrolments/capacity/EditAllowedModal';
import { UpdateEnrolmentCapacityForm } from '@hoot/pages/district-schools/details/enrolments/capacity/EnrolmentCapacity';
import { daysOfWeekLabels } from '@hoot/pages/lessons/utils/date';
import { convert24HourClockTo12 } from '@hoot/utils/dateTime';

interface ScheduleCombinationTableRow {
  id?: string;
  scheduleCombinations: ReactNode;
  allowed: ReactNode;
  available: ReactNode;
  booked: ReactNode;
}

const headers: HeaderData<ScheduleCombinationTableRow>[] = [
  { name: 'Combinations', property: 'scheduleCombinations', width: '20%' },
  { name: 'Allowed', property: 'allowed', width: '30%' },
  { name: 'Est. Available', property: 'available', width: '30%' },
  { name: 'Booked', property: 'booked', width: '20%' },
];

interface Props {
  setValue: UseFormSetValue<UpdateEnrolmentCapacityForm>;
  watch: UseFormWatch<UpdateEnrolmentCapacityForm>;
  setSequence: Dispatch<SetStateAction<number | undefined>>;
}

const EnrolmentSchedulingCard = (props: Props) => {
  const { setValue, watch, setSequence } = props;
  const { scheduleCombinations } = watch();
  const [deleteCombinationModal, setDeleteCombinationModal] = useState<number | undefined>();
  const [allowedModal, setAllowedModal] = useState<number | undefined>();

  const openAllowedModal = (sequence: number) => {
    setAllowedModal(sequence);
  };

  const handleAllowedUpdate = (allowed: number) => {
    const updatedCombinations = scheduleCombinations.map((sc) => {
      if (sc.sequence === allowedModal) {
        return {
          ...sc,
          allowed: allowed,
        };
      }
      return sc;
    });
    setValue('scheduleCombinations', updatedCombinations, { shouldDirty: true });
    setAllowedModal(undefined);
  };

  const openCombinationModal = (sequence: number) => {
    setSequence(sequence);
  };

  const handleDeleteCombination = (sequence: number) => {
    const updatedCombinations = scheduleCombinations.filter((sc) => sc.sequence !== sequence);
    setValue('scheduleCombinations', updatedCombinations, { shouldDirty: true });
    setDeleteCombinationModal(undefined);
  };

  const openDeleteModal = (sequence: number) => {
    setDeleteCombinationModal(sequence);
  };

  const mapScheduleCombinationsToTableRows = (): ScheduleCombinationTableRow[] => {
    const rows = scheduleCombinations?.map<ScheduleCombinationTableRow>((sc) => {
      return {
        id: sc.id,
        allowed: (
          <Stack direction="row" alignItems="center">
            <Typography variant="bodyMedium">{sc.allowed}</Typography>
            <IconButton onClick={() => openAllowedModal(sc.sequence)}>
              <EditPenIcon />
            </IconButton>
          </Stack>
        ),
        scheduleCombinations: (
          <Stack direction="row" alignItems="center">
            <Stack direction="column">
              {sc.combinationSlots.map((cs) => {
                return (
                  <Typography key={cs.id} variant="bodyMedium">
                    {daysOfWeekLabels.get(cs.dayOfWeek)} {convert24HourClockTo12(cs.time)}
                  </Typography>
                );
              })}
            </Stack>
            <IconButton onClick={() => openCombinationModal(sc.sequence)}>
              <EditPenIcon />
            </IconButton>
            <IconButton onClick={() => openDeleteModal(sc.sequence)}>
              <DeleteBinIcon />
            </IconButton>
          </Stack>
        ),
        available: sc.available,
        booked: sc.booked,
      };
    });
    const total: ScheduleCombinationTableRow = {
      scheduleCombinations: <Typography variant="titleMedium">Total</Typography>,
      allowed: <Typography variant="titleMedium">{scheduleCombinations.reduce((acc, sc) => acc + (sc.allowed ?? 0), 0)}</Typography>,
      available: <Typography variant="titleMedium">{scheduleCombinations.reduce((acc, sc) => acc + (sc.available ?? 0), 0)}</Typography>,
      booked: <Typography variant="titleMedium">{scheduleCombinations.reduce((acc, sc) => acc + (sc.booked ?? 0), 0)}</Typography>,
    };
    return [...rows, total];
  };

  return (
    <Stack width="100%">
      <Table data={mapScheduleCombinationsToTableRows()} headers={headers} />
      {deleteCombinationModal ? (
        <ConfirmDeleteDialog
          open={!!deleteCombinationModal}
          onCancel={() => setDeleteCombinationModal(undefined)}
          onContinue={() => handleDeleteCombination(deleteCombinationModal)}
          title="Delete Combination"
          bodyText="Are you sure you want to delete this combination?"
        />
      ) : null}
      {allowedModal ? (
        <EditAllowedModal
          open={!!allowedModal}
          onCancel={() => setAllowedModal(undefined)}
          onContinue={handleAllowedUpdate}
          title="Edit Value"
          value={scheduleCombinations.find((sc) => sc.sequence === allowedModal)?.allowed}
        />
      ) : null}
    </Stack>
  );
};

export default EnrolmentSchedulingCard;
