import { Alert, AlertTitle, CircularProgress } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import useCreateStudentComment from '@hoot/hooks/api/end-users/student/useCreateStudentComment';
import useGetStudentHistory from '@hoot/hooks/api/end-users/student/useGetStudentHistory';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { CommentSubmission } from '@hoot/interfaces/history';
import useGetStudentProfile from '../../../hooks/api/end-users/useGetStudentProfile';
import { CommonHistoryTimeline } from '../common/CommonHistoryTimeline';

const StudentHistoryTab = () => {
  const { studentProfileId } = useParams() as { studentProfileId: string };
  const { error, success } = useAlert();
  const queryClient = useQueryClient();

  const studentProfileResponse = useGetStudentProfile(studentProfileId, {
    onError: () => {
      error('There was an issue fetching the student');
    },
  });

  const historyResponse = useGetStudentHistory(studentProfileId, {
    cacheTime: 0,
    enabled: !!studentProfileResponse.data,
    onError: (err) => {
      const message =
        err.response && err.response.data?.message?.trim().length > 0
          ? err.response.data.message
          : `There was an issue getting the comments for ${studentProfileResponse.data?.name}!`;
      error(message);
      console.error(err);
    },
  });

  const createComment = useCreateStudentComment(studentProfileId, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.GetStudentHistory, studentProfileId]);
      success(`Successfully added an item to ${studentProfileResponse.data?.name}'s history timeline!`);
    },
    onError: (err) => {
      error(`There was an issue submitting the item for ${studentProfileResponse.data?.name}!`);
      console.error(err);
    },
  });

  if (studentProfileResponse.isLoading || historyResponse.isLoading) {
    return <CircularProgress />;
  }

  const studentProfile = studentProfileResponse.data;
  const existingItems = historyResponse.data?.historyItems ?? [];

  const handleCommentSubmit = (comment: CommentSubmission, cb: () => void) => {
    createComment.mutate(comment, {
      onSettled: () => {
        cb();
      },
    });
  };

  if (!studentProfile) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        This is not a valid student!
      </Alert>
    );
  }

  return <CommonHistoryTimeline historyItems={existingItems} handleCommentSubmit={handleCommentSubmit} />;
};

export default StudentHistoryTab;
