import { ReceiptLongOutlined } from '@mui/icons-material';
import { Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types/form';
import { IEnrolmentCheckBoxRow } from '@hoot/components/hfs-interfaces';
import NoneItem from '@hoot/components/ui/NoneItem';
import EnrolmentStatusIcon from '@hoot/components/ui/enrolments/EnrolmentStatusIcon';
import { StudentEnrolmentResponse } from '@hoot/hooks/api/end-users/useGetStudentProfile';
import { enrolmentStatuses } from '@hoot/hooks/api/user/district-representative/useGetDistrictRepresentative';
import { AccountType, ActionUnavailableModal } from '@hoot/pages/users/common/ActionUnavailableModal';
import { routes } from '@hoot/routes/routes';
import AddEnrolmentModal from '../../../../../components/modals/AddEnrolmentModal';
import { StudentDetailsForm } from '../form';

interface IStudentEnrolmentsCardProps {
  studentProfileId?: string;
  hasSavedLocation?: boolean;
  watch: UseFormWatch<StudentDetailsForm>;
  setValue: UseFormSetValue<StudentDetailsForm>;
  disableFields: boolean;
}

const StudentEnrolmentsCard = (props: IStudentEnrolmentsCardProps) => {
  const { studentProfileId, hasSavedLocation, watch, setValue, disableFields } = props;
  const { enrolments } = watch();
  const [showHFSAdd, setShowHFSAdd] = useState<boolean>(false);
  const [showFunctionalityDisabledModal, setShowFunctionalityDisabledModal] = useState<boolean>(false);

  const onAddClick = () => {
    if (hasSavedLocation) {
      setShowHFSAdd(true);
    } else {
      setShowFunctionalityDisabledModal(true);
    }
  };

  const onApply = (selectedRows: IEnrolmentCheckBoxRow[]) => {
    if (selectedRows?.length === 0) {
      return;
    }

    const currentEnrolmentIds = enrolments?.map((r) => r.id);
    const newEnrolments: StudentEnrolmentResponse[] = selectedRows
      .filter((r) => !currentEnrolmentIds?.includes(r.id))
      .map((r) => {
        const newEnrolment: StudentEnrolmentResponse = {
          id: r.id,
          name: r.locationName,
          locationName: r.locationName,
          locationId: r.locationId,
          friendlyId: r.friendlyId,
          status: r.status,
        };
        return newEnrolment;
      });

    if (newEnrolments) {
      setValue('enrolments', [...(enrolments?.length ? enrolments : []), ...newEnrolments], { shouldDirty: true });
    }
    setShowHFSAdd(false);
  };

  return (
    <Card sx={{ pl: 3, pt: 3, pr: 3 }}>
      <CardHeader
        sx={{ p: 0, pb: 3 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="titleLarge">
              Enrolments
            </Typography>
            <Button onClick={onAddClick} type="button" variant="contained" disabled={disableFields}>
              Add
            </Button>
          </Stack>
        }
      />

      <CardContent sx={{ p: 0 }}>
        <Typography component="h5" variant="bodySmall">
          Enrolments*
        </Typography>
        <Stack sx={{ paddingTop: 1 }}>
          {enrolments ? (
            enrolments.map((enrolment, idx) => (
              <Stack
                direction="row"
                gap={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ border: '1px solid #CAC4D0', borderRadius: '4px', p: 2 }}
                key={idx}
              >
                <Stack direction="row" gap={3} alignItems="center">
                  <ReceiptLongOutlined />
                  <Stack>
                    <Typography component="div" variant="bodyLarge">
                      {enrolment.friendlyId}
                    </Typography>
                    <Typography component="h5" variant="bodySmall">
                      {enrolment.locationName}
                    </Typography>
                    <Typography component="div" variant="labelMedium">
                      {EnrolmentStatusIcon(enrolment.status)} {enrolmentStatuses[enrolment.status]}
                    </Typography>
                  </Stack>
                </Stack>
                <Button
                  href={routes.districtsSchools.enrolments.details.url(enrolment.locationId, enrolment.id)}
                  variant="text"
                  color="primary"
                  size="large"
                >
                  View
                </Button>
              </Stack>
            ))
          ) : (
            <NoneItem />
          )}
        </Stack>
      </CardContent>

      {hasSavedLocation ? (
        <AddEnrolmentModal open={showHFSAdd} setOpen={setShowHFSAdd} onApply={onApply} studentProfileId={studentProfileId} />
      ) : (
        <ActionUnavailableModal
          setOpen={setShowFunctionalityDisabledModal}
          open={showFunctionalityDisabledModal}
          accountType={AccountType.Student}
          customMessage={'To use this feature, please save a District or School first.'}
        />
      )}
    </Card>
  );
};

export default StudentEnrolmentsCard;
