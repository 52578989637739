import { TeacherShiftType } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import { AddCircle, Cancel } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { SelectedTeacher } from '@hoot/components/modals/SelectTeacher';
import { ShiftUtilizationShift, ShiftUtilizationTeacher } from '@hoot/hooks/api/shifts/useGetShiftUtilization';
import { BaseComponentProps } from '@hoot/types';
import { ShiftColor } from '../../shift-constants';
import { SelectedTimeSlot, ShiftTimeSlot } from '../../shift-interfaces';

interface ITimeSlotProps {
  timeSlot: ShiftTimeSlot;
  hasLesson?: boolean;
  teacher?: ShiftUtilizationTeacher;
  shift?: ShiftUtilizationShift;
  shiftStarts?: boolean;
  shiftEnds?: boolean;
  onShiftClick?: React.Dispatch<React.SetStateAction<ShiftUtilizationShift | undefined>>;
  showCreateShiftWizard?: boolean;
  setShowCreateShiftWizard?: React.Dispatch<React.SetStateAction<SelectedTimeSlot | undefined>>;
}

const TimeSlot = (props: ITimeSlotProps & BaseComponentProps) => {
  const { timeSlot, children, shift, shiftEnds, shiftStarts, onShiftClick, setShowCreateShiftWizard, hasLesson, teacher } = props;
  const [isHovered, setIsHovered] = useState(false);
  const now = DateTime.now().toMillis();

  const timeSlotIsInThePast = now > timeSlot.start;

  const handleShiftClick = () => {
    if (onShiftClick && shift) {
      onShiftClick(shift);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleSelectTimeSlot = () => {
    if (!shift && !hasLesson && !!setShowCreateShiftWizard && teacher && !timeSlotIsInThePast) {
      const selectedTeacher: SelectedTeacher = {
        id: teacher.id,
        name: teacher.name,
        number: teacher.prefixedNumber,
      };
      setShowCreateShiftWizard({
        startDate: DateTime.fromMillis(timeSlot.start),
        endDate: DateTime.fromMillis(timeSlot.end),
        teacher: selectedTeacher,
      });
    }
  };

  const handleTimeSlotIcon = () => {
    if (isHovered && !!setShowCreateShiftWizard && !hasLesson && !shift) {
      if (now > timeSlot.start) {
        return (
          <Tooltip title={'Can not create shift in the past'}>
            <Cancel sx={{ color: 'red', fontSize: 32 }} />
          </Tooltip>
        );
      } else {
        return <AddCircle sx={{ color: 'green', fontSize: 32 }} />;
      }
    }
  };

  const handleShiftColor = (shift: ShiftUtilizationShift) => {
    if (shift.cancelledAt) {
      return ShiftColor.Cancelled;
    }
    if (shift.type === TeacherShiftType.Interview) {
      return ShiftColor.Interview;
    }
    return ShiftColor.Regular;
  };

  return (
    <Stack
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleSelectTimeSlot}
      justifyContent={'center'}
      sx={{
        width: '130px',
        borderLeft: '1px solid #CAC4D0',
        borderBottom: '1px solid #CAC4D0',
        '&:last-child': { borderRight: '1px solid #CAC4D0' },
        '&:hover': {
          cursor: !!setShowCreateShiftWizard && !timeSlotIsInThePast ? 'pointer' : undefined,
        },
      }}
    >
      <Stack
        onClick={handleShiftClick}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: shift ? '8px' : '0px 8px',
          marginY: 1,
          ...(shift
            ? {
                background: handleShiftColor(shift),
                '&:hover': {
                  cursor: 'pointer',
                },
              }
            : {}),
          ...(shiftStarts ? { paddingLeft: 1, marginLeft: 1, borderRadius: '8px 0px 0px 8px' } : {}),
          ...(shiftEnds ? { paddingRight: 1, marginRight: 1, borderRadius: '0px 8px 8px 0px' } : {}),
          ...(shiftStarts && shiftEnds ? { borderRadius: '8px' } : {}),
        }}
      >
        {children}
        {handleTimeSlotIcon()}
      </Stack>
    </Stack>
  );
};

export default TimeSlot;
