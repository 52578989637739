import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { Language } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { hootQualificationOptions, hootQualifications } from '@hoot/interfaces/teacher';
import { NoneListItem } from '../details/Shared';

export const QualificationsSection = (props: { onChange: (val: HootQualification[]) => void; value: HootQualification[] }) => {
  const { onChange, value } = props;

  const [showModal, setShowModal] = useState<boolean>(false);

  const onEditClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <Grid container item direction="column" xs={12}>
        <Grid container item xs={12}>
          <Grid container item justifyContent="space-between">
            <Grid item>
              <Typography component="h3" variant="titleLarge">
                Qualification Requirements
              </Typography>
            </Grid>
            <Grid item>
              <Button onClick={onEditClick} variant="contained" color="primary">
                Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid sx={{ marginTop: '24px' }} container item xs={12}>
          <List sx={{ width: '100%' }}>
            {value && value.length > 0 ? (
              value.map((val, idx) => (
                <ListItem key={`${val}-${idx}`}>
                  <ListItemIcon>
                    <Language />
                  </ListItemIcon>
                  <ListItemText primary={hootQualifications[val as HootQualification]} />
                </ListItem>
              ))
            ) : (
              <NoneListItem />
            )}
          </List>
        </Grid>
      </Grid>
      {showModal ? <RequirementsModal onChange={onChange} value={value} open={showModal} onClose={() => setShowModal(false)} /> : null}
    </>
  );
};

const RequirementsModal = (props: {
  value: HootQualification[];
  onChange: (val: HootQualification[]) => void;
  open: boolean;
  onClose: () => void;
}) => {
  const excludedRequirements: HootQualification[] = [HootQualification.ORIENTATION_AND_TRAINING, HootQualification.INTERVIEW];

  const { open, onChange, onClose, value } = props;

  const [selectedValues, setSelectedValues] = useState<HootQualification[]>(value);

  const handleCheckboxChange = (val: HootQualification) => (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      const valTyped = val as HootQualification;
      setSelectedValues((currentState) => [...(currentState as HootQualification[]), valTyped]);
    } else {
      const valTyped = val as HootQualification;
      setSelectedValues((currentState) => (currentState as HootQualification[]).filter((s) => s !== valTyped));
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleApply = () => {
    onChange(selectedValues);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Edit Qualification Requirements</DialogTitle>
      <DialogContent>
        <FormGroup>
          {hootQualificationOptions
            .filter((q) => !excludedRequirements.some((excluded) => excluded === q.value))
            .map((p) => (
              <FormControlLabel
                key={p.value}
                control={<Checkbox onChange={handleCheckboxChange(p.value)} checked={selectedValues.some((v) => v === p.value)} />}
                label={p.label}
              />
            ))}
        </FormGroup>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button autoFocus onClick={handleCancel} variant="contained" color="inherit">
          Cancel
        </Button>
        <Button onClick={handleApply} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
