import { Card, CardContent, CardHeader, Grid, Stack, TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { DateFormats } from '@hoot/common/constants';
import { TeacherReliabilityScoreResponse } from '@hoot/hooks/api/user/teacher/useGetTeacherReliabilityScore';

const TeacherReliabilityScoreCard = (props: { trs?: TeacherReliabilityScoreResponse; idx?: number }) => {
  const { trs, idx } = props;
  const title = idx && idx > 0 ? 'TRS Score' : 'Current TRS Score';
  const scoreBackgroundColor = (idx && idx > 0) || !trs?.calculatedScore ? '#E8E8E8' : '#D0E8FF';
  const score = trs?.calculatedScore ? `${trs.calculatedScore * 100}%` : 'N/A';
  const period = trs?.trsDate ? DateTime.fromISO(trs.trsDate).toFormat(DateFormats.SHORT_MDY) : '-';
  const inputs = trs?.inputs && trs.inputs.length > 0 ? trs.inputs.map((obj) => `${Object.values(obj)[0] * 100}%`).join(', ') : '-';

  return (
    <Card>
      <CardHeader
        sx={{ px: 3 }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="titleLarge">{title}</Typography>
          </Stack>
        }
      />
      <CardContent sx={{ pt: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: scoreBackgroundColor,
                borderRadius: '8px',
              }}
            >
              <Typography variant="displayMedium">{score}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <Stack spacing={2}>
              <TextField
                variant="outlined"
                label="TRS Inputs"
                defaultValue={inputs}
                fullWidth
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                InputProps={{
                  style: { color: 'black' },
                  readOnly: true,
                }}
              />
              <TextField
                variant="outlined"
                label="TRS Period"
                defaultValue={period}
                fullWidth
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                InputProps={{
                  style: { color: 'black' },
                  readOnly: true,
                }}
              />{' '}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TeacherReliabilityScoreCard;
