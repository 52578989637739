import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface TeacherAvailabilityExceptionImpactRequest {
  teacherAccountId: string;
}

export interface TeacherAvailabilityExceptionsImpactsResponse {
  teacherAccountId: string;
  teacherTimezone: string;
  exceptions: ExceptionResponse[];
}

export interface ExceptionResponse {
  startsAt: number;
  endsAt: number;
  createdAt: number;
  keptLessons: ScheduledLesson[];
  droppedLessons: ScheduledLesson[];
  droppedShifts: {
    shiftId: string;
    shiftNumber: number;
  }[];
}

export interface ScheduledLesson {
  lessonId: string;
  lessonNumber: string;
}

async function getTeacherAvailabilityExceptionImpacts(params: TeacherAvailabilityExceptionImpactRequest) {
  const { data } = await axios.get<TeacherAvailabilityExceptionsImpactsResponse>(`${config.apiUrl}/admin/availability-exception/impacts`, {
    params,
  });
  return data;
}

export function useGetTeacherAvailabilityExceptionImpacts(
  params: TeacherAvailabilityExceptionImpactRequest,
  options?: Omit<UseQueryOptions<TeacherAvailabilityExceptionsImpactsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.TeacherAvailabilityExceptionImpacts, params], () => getTeacherAvailabilityExceptionImpacts(params), options);
}
