import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';
import { QualityAssuranceStatus } from './enums';

export interface UpdateQualityAssuranceTaskRequest {
  taskId: string;
  status: QualityAssuranceStatus;
  isActioned: boolean;
}

export default function useUpdateQualityAssuranceTask(
  options?: Omit<UseMutationOptions<void, AxiosError, UpdateQualityAssuranceTaskRequest>, 'mutationFn' | 'mutationKey'>,
) {
  return useMutation(async (request: UpdateQualityAssuranceTaskRequest) => {
    await axios.post<void>(`${config.apiUrl}/admin/quality-assurance-tasks/${request.taskId}`, request);
  }, options);
}
