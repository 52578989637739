import { CircularProgress, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useAlert } from '@hoot/contexts/AlertContext';
import useGetLocationSummaryDashboard, {
  LocationSummaryDashboardResponse,
  LocationSummaryQuery,
} from '@hoot/hooks/api/hfs/useGetLocationSummaryDashboard';

interface IDashboardProps {
  query: LocationSummaryQuery;
  locationId?: string;
}

const SummaryDashboard = (props: IDashboardProps) => {
  const { query, locationId } = props;
  const { error } = useAlert();

  const [summaryData, setSummaryData] = useState<LocationSummaryDashboardResponse>({
    scheduledLessons: '',
    paidLessons: '',
    uniqueStudentAssociations: '',
    hoursInLesson: '',
    attendanceRate: '',
    uniqueTeachers: '',
  });

  const { isFetching } = useGetLocationSummaryDashboard(locationId ?? '', query, {
    enabled: !!locationId,
    onSuccess: (data) => {
      setSummaryData(data);
    },
    onError: (err) => {
      console.error(err);
      error('There was an issue with the sumary dashboard.');
    },
  });

  const SummaryDetail = (props: { summaryTitle: string; summaryDetail: string }) => {
    return (
      <Stack alignItems="center" gap={2} sx={{ border: `1px solid #1976D2`, padding: 2, borderRadius: '8px', width: '100%', background: '#E9F4FF' }}>
        <Typography variant="bodyLarge" textAlign="center">
          {props.summaryTitle}
        </Typography>
        {isFetching ? <CircularProgress /> : <Typography variant="headlineLarge">{props.summaryDetail}</Typography>}
      </Stack>
    );
  };

  return (
    <Stack gap={2}>
      <Typography variant="titleLarge">Dashboard</Typography>
      <Stack direction="row" gap={3} width="100%">
        <SummaryDetail summaryTitle={'Scheduled Lesson(s)'} summaryDetail={summaryData.scheduledLessons} />
        <SummaryDetail summaryTitle={'Paid Lessons'} summaryDetail={summaryData.paidLessons} />
        <SummaryDetail summaryTitle={'Unique Student Association(s)'} summaryDetail={summaryData.uniqueStudentAssociations} />
        <SummaryDetail summaryTitle={'Hours in Lessons'} summaryDetail={summaryData.hoursInLesson} />
        <SummaryDetail summaryTitle={'Attendance Rate'} summaryDetail={summaryData.attendanceRate} />
        <SummaryDetail summaryTitle={'Unique Teacher(s)'} summaryDetail={summaryData.uniqueTeachers} />
      </Stack>
    </Stack>
  );
};

export default SummaryDashboard;
