import { Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { EnrolmentResponse } from '@hoot/hooks/api/hfs/useGetEnrolment';
import useGetEnrolmentPeriods from '@hoot/hooks/api/hfs/useGetEnrolmentPeriods';
import AddEnrolmentPeriodModal from './AddEnrolmentPeriodModal';
import DeleteEnrolmentPeriodModal from './DeleteEnrolmentPeriodModal';
import EnrolmentPeriodTable from './EnrolmentPeriodTable';

const EnrolmentPeriodCard = (props: { enrolment: EnrolmentResponse | undefined }) => {
  const { enrolmentId } = useParams() as { enrolmentId: string };
  const { data, isLoading } = useGetEnrolmentPeriods(enrolmentId);

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [periodToDelete, setPeriodToDelete] = useState<string | undefined>();

  const handleAddModalClose = () => setIsAddModalOpen(false);
  const handleDeleteModalClose = () => setPeriodToDelete(undefined);

  return (
    <>
      <Card sx={{ mt: 3 }}>
        <CardHeader
          sx={{ p: 3, pb: 0 }}
          title={
            <Stack direction="row" alignItems="center">
              <Typography variant="titleLarge">Enrolment Schedule</Typography>
              {enrolmentId && (
                <Button onClick={() => setIsAddModalOpen(true)} variant="contained" sx={{ ml: 'auto' }}>
                  Add
                </Button>
              )}
            </Stack>
          }
        />
        <CardContent>
          <EnrolmentPeriodTable periodData={data} periodDataLoading={isLoading} setOpen={setPeriodToDelete} />
        </CardContent>
      </Card>

      {/* Modals */}
      <AddEnrolmentPeriodModal open={isAddModalOpen} onClose={handleAddModalClose} enrolment={props.enrolment} periodData={data} />
      <DeleteEnrolmentPeriodModal periodIdToDelete={periodToDelete} onClose={handleDeleteModalClose} enrolmentId={enrolmentId} />
    </>
  );
};

export default EnrolmentPeriodCard;
