import { ScheduledLessonStatus, lessonStatusesLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { useMemo } from 'react';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { FormSelect, FormSelectOption, SelectProps } from './FormSelect';

interface LessonStatusSelectProps<T extends FieldValues, TObj extends Path<T>> extends SelectProps, UseControllerProps<T, TObj> {
  filter?: ScheduledLessonStatus[];
}

export function LessonStatusSelect<T extends FieldValues, TObj extends Path<T>>(props: LessonStatusSelectProps<T, TObj>) {
  const { filter, ...rest } = props;

  const options = useMemo<FormSelectOption[]>(() => {
    return Object.entries(lessonStatusesLookup)
      .filter((v) => (filter && filter.length > 0 ? filter.some((f) => f === v[0]) : v))
      .map((s) => {
        return {
          label: s[1],
          value: s[0],
        };
      });
  }, [filter]);

  return <FormSelect label="Status" labelId="status-label" options={options} {...rest} />;
}
