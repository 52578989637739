import { StudentSearchFieldOptions } from '@hoot-reading/hoot-core/dist/enums/user/student/student-search-field-options.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { FilterAltOutlined, MailOutline, PhoneOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Badge,
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { defaultRowsPerPage } from '@hoot/common/constants';
import SearchTextField, { SearchTextFieldProps } from '@hoot/components/form/SearchTextField';
import { accountDisplay } from '@hoot/components/hfs-utils';
import { HeaderData, Table } from '@hoot/components/ui/Table';
import { useAlert } from '@hoot/contexts/AlertContext';
import { UserQuery } from '@hoot/hooks/api/end-users/useGetUsers';
import useGetAllBillableLocations from '@hoot/hooks/api/hfs/useGetAllBillableLocations';
import useGetAllEnrolments from '@hoot/hooks/api/hfs/useGetAllEnrolments';
import useGetAllSchools from '@hoot/hooks/api/hfs/useGetAllSchools';
import useSearchStudents, { StudentAccountResponse, StudentQuery, StudentSearchParams } from '@hoot/hooks/api/user/student/useSearchStudents';
import { usePlaces } from '@hoot/hooks/usePlaces';
import { FormOption } from '@hoot/interfaces/form';
import { UserAccount } from '@hoot/interfaces/user';
import { lineOfBusinessFilterOptions } from '@hoot/pages/users/interfaces/user-filters-form';
import StudentFiltersDrawer from '@hoot/pages/users/students/components/StudentsTable/StudentFiltersDrawer';
import { getChipStatusColor } from '@hoot/pages/users/utils/userFunctions';
import { routes } from '@hoot/routes/routes';
import { removePrefix } from '@hoot/utils/stringUtils';

const IGNORE_FILTERS = [
  'searchText',
  'page',
  'pageSize',
  'account',
  'accountType',
  'teacherQuery',
  'customerQuery',
  'districtRepQuery',
  'studentQuery',
];

const IGNORE_STUDENT_FILTERS = ['searchFieldSelection'];

type QueryKeys = keyof UserQuery;

type StudentQueryKeys = keyof StudentQuery;

type StudentKeys = keyof StudentSearchParams;

type AllKeys = QueryKeys | StudentQueryKeys | StudentKeys;

const searchFieldOptions: FormOption<StudentSearchFieldOptions>[] = [
  { value: StudentSearchFieldOptions.StudentName, label: 'Student Name' },
  { value: StudentSearchFieldOptions.StudentNumber, label: 'Student Number' },
  { value: StudentSearchFieldOptions.ParentPhoneNumber, label: 'Parent Phone' },
  { value: StudentSearchFieldOptions.ParentEmail, label: 'Parent Email' },
  { value: StudentSearchFieldOptions.All, label: 'All Fields' },
];

interface StudentTableRow {
  studentName: React.ReactNode;
  studentHFSNumber: React.ReactNode;
  parent: React.ReactNode;
  status: React.ReactNode;
  account: React.ReactNode;
}

const headers: HeaderData<StudentTableRow>[] = [
  { property: 'studentName', name: 'Student Name' },
  { property: 'studentHFSNumber', name: 'Hoot Student #' },
  { property: 'parent', name: 'Parent' },
  { property: 'status', name: 'Status' },
  { property: 'account', name: 'Account' },
];

export enum StudentTabs {
  Details = 'DETAILS',
  LessonReviews = 'LESSON_REVIEWS',
  Assessments = 'ASSESSMENTS',
  Schedule = 'SCHEDULE',
  History = 'HISTORY',
  InstructionalPlan = 'INSTRUCTIONAL_PLAN',
  SpecialIndicators = 'SPECIAL_INDICATORS',
  Timeline = 'TIMELINE',
  Tasks = 'TASKS',
}

const FilterChips = (props: {
  badgesWithMultiSelectors: any[];
  userQuery: UserQuery;
  setUserQuery: React.Dispatch<React.SetStateAction<UserQuery>>;
  studentQuery: StudentQuery;
  setStudentQuery: React.Dispatch<React.SetStateAction<StudentQuery>>;
}) => {
  const { badgesWithMultiSelectors, setUserQuery, studentQuery, setStudentQuery } = props;
  const billableLocations = useGetAllBillableLocations();
  const schools = useGetAllSchools();
  const enrolments = useGetAllEnrolments();
  const { countryOptionsCanadaFirst } = usePlaces('CA');

  const countryTypes = [...countryOptionsCanadaFirst].reduce(
    (a, b) => ({
      ...a,
      [b.value]: b.label,
    }),
    {},
  ) as { [key: string]: string };

  return (
    <Grid container item sx={{ marginLeft: '24px' }}>
      {badgesWithMultiSelectors?.map((badge) => {
        const [filterType, filterValue]: [AllKeys, any] = badge;
        let key = filterType;
        let value = filterValue;
        if (filterType === 'fromDate' || filterType === 'toDate') {
          value = DateTime.fromISO(filterValue).toFormat('LLL dd, yyyy');
        } else if (filterType === 'isEnabled') {
          value = filterValue ? 'Yes' : 'No';
        } else if (filterType === 'country') {
          value = countryTypes[filterValue as SpokenLanguage];
        } else if (['studentLocationIds', 'districtRepLocationIds'].includes(filterType)) {
          key = 'District' as AllKeys;
          value = billableLocations.data?.billableLocations?.find((a) => a.id === filterValue)?.name;
        } else if (['studentSchoolIds', 'districtRepSchoolIds'].includes(filterType)) {
          key = 'School' as AllKeys;
          value = schools.data?.schools?.find((a) => a.id === filterValue)?.name;
        } else if (filterType === 'enrolmentIds') {
          key = 'Enrolment' as AllKeys;
          value = enrolments.data?.enrolments?.find((a) => a.id === filterValue)?.friendlyId;
        } else if (filterType === 'lineOfBusiness') {
          value = lineOfBusinessFilterOptions.find((o) => o.value === filterValue)?.label ?? filterValue;
        } else {
          if (typeof filterValue === 'string') {
            value = capitalCase(filterValue);
          }
        }
        return (
          <Chip
            key={`${key}-${value}`}
            sx={{
              margin: '4px',
            }}
            label={`${capitalCase(key)}: ${value}`}
            onDelete={() => {
              if (Object.keys(studentQuery.studentQuery).includes(filterType)) {
                setStudentQuery((q: StudentQuery) => ({
                  ...q,
                  studentQuery: {
                    ...q.studentQuery,
                    [filterType]: Array.isArray(q.studentQuery[filterType as StudentKeys])
                      ? (q.studentQuery[filterType as StudentKeys] as any)?.filter((val: string) => val !== filterValue)
                      : undefined,
                  },
                }));
              } else {
                setUserQuery((q: UserQuery) => ({
                  ...q,
                  [filterType]: Array.isArray(q[filterType as QueryKeys])
                    ? (q[filterType as QueryKeys] as any)?.filter((val: string) => val !== filterValue)
                    : undefined,
                }));
                setStudentQuery((q: StudentQuery) => ({
                  ...q,
                  [filterType]: Array.isArray(q[filterType as StudentQueryKeys])
                    ? (q[filterType as StudentQueryKeys] as any)?.filter((val: string) => val !== filterValue)
                    : undefined,
                }));
              }
            }}
          />
        );
      })}
    </Grid>
  );
};

function StudentsTable() {
  return (
    <Stack spacing="16px">
      <HeaderCard />
      <SearchCard />
    </Stack>
  );
}

function HeaderCard() {
  return (
    <Card sx={{ padding: '24px' }}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Typography variant="headlineLarge">Students</Typography>
        <AddStudentAction />
      </Grid>
    </Card>
  );
}

const SearchCard = () => {
  const [searchText, setSearchText] = useState('');
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [userQuery, setUserQuery] = useState<UserQuery>({
    pageSize: 10,
    page: 1,
    searchText,
    customerQuery: {},
    teacherQuery: {},
    districtRepQuery: {},
    accountType: UserAccount.Student,
  });

  const [studentQuery, setStudentQuery] = useState<StudentQuery>({
    pageSize: 10,
    page: 1,
    searchText,
    customerQuery: {},
    studentQuery: { ...{ studentStatus: [StudentStatus.Active], searchFieldSelection: StudentSearchFieldOptions.All } },
  });

  // Search for users based on toolbar searchText, resulting data goes to table body as rows of users.
  const { isFetching: isLoadingStudentSearch, data: studentResponseData } = useSearchStudents({
    ...studentQuery,
    searchText: removePrefix(studentQuery.searchText),
  });

  const { success } = useAlert();

  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    success('Copied to clipboard');
  };

  const handleParentIdentifier = (user: StudentAccountResponse): string => {
    if (!user.hasCustomerAccount) {
      return 'None';
    } else {
      return `${user.parentPrefixedNumber}`;
    }
  };

  const ParentWithIcons = (props: { user: StudentAccountResponse }) => {
    const { user } = props;

    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Link to={routes.users.parents.details.url(user.id)}>
            <Typography variant="bodyMedium" noWrap sx={{ maxWidth: 140 }}>
              {handleParentIdentifier(user)}
            </Typography>
          </Link>
        </Grid>

        <Grid item>
          <Tooltip title={user?.email ?? ''}>
            <IconButton onClick={handleCopyToClipboard(user.email)}>
              <MailOutline sx={{ cursor: 'pointer' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={user?.phoneNumber ?? ''}>
            <IconButton onClick={handleCopyToClipboard(user.phoneNumber)}>
              <PhoneOutlined sx={{ cursor: 'pointer' }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const data =
    studentResponseData && studentResponseData.users
      ? studentResponseData.users.map<StudentTableRow>((user) => ({
          studentName: (
            <Typography variant="bodyMedium" noWrap sx={{ maxWidth: 180 }}>
              {user?.studentProfile?.name}
            </Typography>
          ),
          studentHFSNumber: (
            <Link to={routes.users.students.details.url(user.studentProfile!.id)}>
              <Typography variant="bodyMedium" noWrap sx={{ maxWidth: 140 }}>
                {user?.studentProfile?.prefixedNumber}
              </Typography>
            </Link>
          ),
          parent: <ParentWithIcons user={user} />,
          status: (
            <Chip
              label={`${capitalCase(user.studentProfile?.status ?? '')}`}
              sx={{
                margin: '4px',
                fontSize: 10,
                color: '#fff',
                backgroundColor: getChipStatusColor(user.studentProfile?.status),
              }}
            />
          ),
          account: (
            <Tooltip title={user?.accountName ?? ''}>
              <Typography variant="bodyMedium" noWrap>
                {accountDisplay(user.accountName)}
              </Typography>
            </Tooltip>
          ),
        }))
      : [];

  const badges = () => {
    const mainBadges = Object.entries(studentQuery).filter(
      ([k, v]) => !IGNORE_FILTERS.includes(k) && v !== undefined && (Array.isArray(v) ? v.length !== 0 : true),
    ) as [QueryKeys, any][];

    const studentBadges = Object.entries(studentQuery.studentQuery).filter(
      ([k, v]) => !IGNORE_STUDENT_FILTERS.includes(k) && v !== undefined && v !== '' && (Array.isArray(v) ? v.length !== 0 : true),
    ) as [QueryKeys, any][];
    mainBadges.push(...studentBadges);

    return mainBadges;
  };

  const badgesWithMultiSelectors = badges().reduce((acc: any[], badge) => {
    if (Array.isArray(badge[1])) return [...acc, ...badge[1].map((val: any) => [badge[0], val])];
    return [...acc, badge];
  }, []);

  const handlePageChange = (_event: unknown, page: number) => {
    setStudentQuery((current) => ({ ...current, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setStudentQuery((current) => ({ ...current, pageSize: pageSize }));
  };

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchText(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (searchText) => {
    setStudentQuery((current) => ({ ...current, searchText, page: 1 }));
  };

  const searchFieldOnChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setStudentQuery((current) => ({
      ...current,
      studentQuery: { ...current.studentQuery, searchFieldSelection: value as StudentSearchFieldOptions },
    }));
  };

  return (
    <Card sx={{ padding: '24px' }}>
      <Stack sx={{ mb: 3 }}>
        <Typography variant="titleLarge">Students</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <Box width={'250px'}>
          <FormControl fullWidth variant={'outlined'} size={'medium'}>
            <InputLabel id={'fieldSearchField'}>Search in</InputLabel>
            <Select
              labelId={'fieldSearchField'}
              label={'Search in'}
              value={studentQuery.studentQuery.searchFieldSelection as any}
              onChange={searchFieldOnChange}
            >
              {searchFieldOptions.map((x) => (
                <MenuItem key={`fieldSearchField-${x.value}`} value={x.value}>
                  {x.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <SearchTextField
          searchInput={searchText}
          onSearchInputChanged={onSearchInputChanged}
          onSearchInputDebounced={onSearchInputDebounced}
          autoFocus
        />

        <Tooltip title={'Filter Results'}>
          <IconButton size="large" onClick={() => setShowFilters(true)} color="primary">
            <Badge color="primary">
              <FilterAltOutlined sx={{ cursor: 'pointer' }} />
            </Badge>
          </IconButton>
        </Tooltip>
      </Stack>

      <Box sx={{ marginY: '20px' }}>
        <FilterChips
          badgesWithMultiSelectors={badgesWithMultiSelectors}
          userQuery={userQuery}
          setUserQuery={setUserQuery}
          studentQuery={studentQuery}
          setStudentQuery={setStudentQuery}
        />
      </Box>

      <Stack sx={{ marginTop: '32px' }}>
        <Table
          isPaginated
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          headers={headers}
          data={data}
          count={studentResponseData?.count || 0}
          page={studentResponseData?.page || 1}
          rowsPerPage={studentQuery.pageSize}
          isLoading={isLoadingStudentSearch}
        />
      </Stack>

      {showFilters ? (
        <StudentFiltersDrawer
          showDrawer={showFilters}
          setShowDrawer={setShowFilters}
          userQuery={userQuery}
          setUserQuery={setUserQuery}
          studentQuery={studentQuery}
          setStudentQuery={setStudentQuery}
        />
      ) : null}
    </Card>
  );
};

const AddStudentAction = () => {
  const navigate = useNavigate();
  return (
    <>
      <Button onClick={() => navigate(routes.users.students.create.url)} style={{ height: 36 }} variant="contained" color="primary">
        <AddIcon fontSize="small" style={{ marginRight: 5 }} /> Add Student
      </Button>
    </>
  );
};
export default StudentsTable;
