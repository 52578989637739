import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import AdminDashboard from './AdminDashboard';

const AdminView = () => {
  return (
    <AdminDashboard>
      <Box sx={{ marginTop: '32px' }}>
        <Outlet />
      </Box>
    </AdminDashboard>
  );
};

export default AdminView;
