import { ScheduledLessonStatus, lessonStatusesLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Close, LaunchOutlined, MovieOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DateTime, Duration } from 'luxon';
import React, { useState } from 'react';
import { HeaderData, Table } from '@hoot/components/ui/Table';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import { LessonDetailResponse } from '@hoot/hooks/api/lessons/useGetLesson';
import useGetDailyRecordings from '../../../hooks/api/daily/useGetDailyRecording';
import useGetDailyRecordingsAccessLink from '../../../hooks/api/daily/useGetDailyRecordingsAccessLink';

const EXPIRATION_DAYS = 30;

interface TableData {
  name: string;
  length: string;
  id: string;
  action: React.ReactNode;
}

const headers: HeaderData<TableData>[] = [
  { name: 'Name', property: 'name' },
  { name: 'Length', property: 'length' },
  { name: 'Id', property: 'id' },
  { name: 'Action', property: 'action' },
];

function DailyRecordings(props: { lesson: LessonDetailResponse }) {
  return (
    <Stack>
      <Typography variant="titleLarge" sx={{ marginBottom: '16px' }}>
        Recordings
      </Typography>
      <Content lesson={props.lesson} />
    </Stack>
  );
}

function Content(props: { lesson: LessonDetailResponse }) {
  const { scopes } = useAuth();

  const accessGranted = !!scopes.find((s) => s === HootEmployeeScope.ViewLessonVideos);

  const isExpired = Math.abs(DateTime.fromMillis(props.lesson.startsAt).diffNow('days').days) >= EXPIRATION_DAYS;

  const isFutureOrInProgressLesson = [
    lessonStatusesLookup.IN_PROGRESS,
    lessonStatusesLookup.OPEN,
    lessonStatusesLookup.OPEN_WAITING_STUDENT,
    lessonStatusesLookup.OPEN_WAITING_TEACHER,
    lessonStatusesLookup.SCHEDULED,
    lessonStatusesLookup.RESCHEDULED,
  ].includes(lessonStatusesLookup[props.lesson.lessonStatus as ScheduledLessonStatus]);

  if (!accessGranted) {
    return (
      <CommentBlock
        title="Permission Not Granted"
        message="You do not have permission to view this page. Please contact your manager if you require permission."
      />
    );
  }

  if (!props.lesson.dailyRoomName) {
    const message = `There are no recordings available for this lesson. ${isFutureOrInProgressLesson ? 'Lesson has not occurred yet.' : ''}`;
    return <CommentBlock title="No Recordings" message={message} />;
  }

  if (isExpired) {
    return <CommentBlock title="Recordings Expired" message="Recordings have expired for this lesson and have been removed." />;
  }

  return (
    <Stack>
      <Recordings lessonId={props.lesson.lessonId} />
    </Stack>
  );
}

function CommentBlock(props: { title: string; message: string }) {
  return (
    <Stack maxWidth="50%" sx={{ borderRadius: '8px', backgroundColor: '#fef4e9', padding: '32px' }}>
      <Stack direction="row" textAlign="center" alignItems="center" spacing="32px">
        <MovieOutlined />
        <Stack alignItems="flex-start">
          <Typography variant="titleMedium">{props.title}</Typography>
          <Typography variant="body1">{props.message}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

function Recordings(props: { lessonId: string }) {
  const [selectedRecordingId, setSelectedRecordingId] = useState<string>();
  const getDailyRecordings = useGetDailyRecordings(props.lessonId);

  if (getDailyRecordings.isFetching) {
    return <CircularProgress />;
  }

  const handleViewClick = (recordingId: string) => () => {
    setSelectedRecordingId(recordingId);
  };

  const handleVideoPlayerClose = () => {
    setSelectedRecordingId(undefined);
  };

  const data =
    getDailyRecordings?.data?.map<TableData>((d, idx) => ({
      name: `Recording ${idx + 1}`,
      length: Duration.fromMillis(d.duration * 1000).toFormat("mm'm' ss's'"),
      id: d.recordingId,
      action: (
        <Button onClick={handleViewClick(d.recordingId)} size="large" endIcon={<LaunchOutlined />} variant="contained">
          View
        </Button>
      ),
    })) ?? [];

  return (
    <Stack>
      <Table headers={headers} data={data} />
      {selectedRecordingId ? (
        <VideoPlayerDialog lessonId={props.lessonId} recordingId={selectedRecordingId} onClose={handleVideoPlayerClose} />
      ) : null}
    </Stack>
  );
}

function VideoPlayerDialog(props: { lessonId: string; recordingId: string; onClose: () => void }) {
  const dailyRecordingsAccessLink = useGetDailyRecordingsAccessLink(props.lessonId, props.recordingId);

  return (
    <Dialog maxWidth="lg" fullWidth open={true} onClose={props.onClose}>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <IconButton onClick={props.onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ height: '600px', overflow: 'hidden' }}>
        {dailyRecordingsAccessLink.isFetching || !dailyRecordingsAccessLink.data ? (
          <CircularProgress />
        ) : (
          <video height="100%" width="100%" controls={true} controlsList="nodownload" style={{ background: 'black' }}>
            <source src={dailyRecordingsAccessLink.data?.accessLink} type="video/mp4" />
          </video>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 3, position: 'relative' }}>
        <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
        <Button size="large" variant="outlined" color="primary" onClick={props.onClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DailyRecordings;
