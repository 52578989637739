import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '@hoot/contexts/AlertContext';
import { routes } from '@hoot/routes/routes';
import useCreateLessonsV2, { CreateLessonRequestV2, CreateLessonResponseV2 } from '../../../../hooks/api/lessons/useCreateLessonV2';
import useGetLessonConflicts, { ConflictType } from '../../../../hooks/api/lessons/useGetConflicts';
import CreateLessonFooter from '../CreateLessonFooter';
import { ConflictMessage } from '../common/ConflictMessage';
import { useCreateLessonContext } from '../context/CreateLessonContextProvider';
import { useStudentAndLessonContext } from '../context/StudentAndLessonContextProvider';
import { TentativeLesson, TentativeLessonStatus, useStudentLessonDetailsContext } from '../context/StudentLessonDetailsProvider';

interface TentativeLessonForm {
  lessonAt: DateTime;
  status: TentativeLessonStatus;
  conflict?: {
    conflictType: ConflictType;
    message: string;
  };
}

interface Form {
  tentativeLessons: TentativeLessonForm[];
}

function StudentConflictCheckStep() {
  const { goToNextStep, goToPrevStep, hideStudentName } = useCreateLessonContext();
  const { studentAndLessonData } = useStudentAndLessonContext();
  const { lessonDetails } = useStudentLessonDetailsContext();
  const navigate = useNavigate();
  const { error } = useAlert();

  const { control, handleSubmit, reset, watch } = useForm<Form>({
    defaultValues: {
      tentativeLessons: [],
    },
  });

  const tentativeLessons = watch('tentativeLessons');

  const atLeastOneLessonSelected = useMemo(() => {
    return tentativeLessons.some((x) => x.status !== TentativeLessonStatus.NotSet && x.status !== TentativeLessonStatus.Exclude);
  }, [tentativeLessons]);

  const getConflicts = useGetLessonConflicts(
    {
      durationInMinutes: lessonDetails.duration ? lessonDetails.duration : 20,
      lessonStartTimes: lessonDetails.tentativeLessons?.map((l) => l.lessonAt.toMillis()) ?? [],
      teacherAccountId: null,
      studentProfileId: studentAndLessonData?.studentProfile.id,
    },
    {
      enabled: (lessonDetails.tentativeLessons?.length ?? 0) > 0,
      onSuccess: (response) => {
        if (lessonDetails.tentativeLessons) {
          const tentativeLessons = lessonDetails.tentativeLessons?.map<TentativeLessonForm>((l) => {
            const conflict = response.find((r) => r.lessonStart === l.lessonAt.toMillis());
            if (conflict) {
              if (conflict.conflictType === 'CONFLICT') {
                return {
                  lessonAt: l.lessonAt,
                  status: TentativeLessonStatus.Exclude,
                  conflict: {
                    conflictType: conflict.conflictType,
                    message: conflict.message,
                  },
                };
              }
            }
            return { lessonAt: l.lessonAt, status: TentativeLessonStatus.NoTeacher };
          });

          reset({
            tentativeLessons: tentativeLessons,
          });
        }
      },
    },
  );

  const handlePreviousClick = () => {
    goToPrevStep();
  };

  const onSubmit = (data: Form) => {
    lessonDetails.tentativeLessons = data.tentativeLessons.map<TentativeLesson>((l) => ({
      lessonAt: l.lessonAt,
      status: l.status,
    }));
    goToNextStep();
  };

  const createLessonRequest = useCreateLessonsV2({
    onError: (err) => {
      console.error(err);
      // Show error message from API if exists.
      const errorMessage = err.response?.data.message ?? `An unknown error occurred while creating lesson(s).`;
      error(errorMessage);
    },
  });

  const createLessonSet = (props: { onSuccess: (response: CreateLessonResponseV2) => void }) => {
    const { studentProfile, subject, lessonType, lessonSetType, isCustomerBilledLesson, language, selectedEnrolment, theme } = studentAndLessonData!;

    const { duration, lessonAt, teacher, endAfterDate, recurring } = lessonDetails;

    // need to capture any changes made to selected lesson list
    lessonDetails.tentativeLessons = tentativeLessons.map<TentativeLesson>((l) => ({
      lessonAt: l.lessonAt,
      status: l.status,
    }));

    const lessonDatesWithoutTeacher =
      lessonDetails.tentativeLessons?.filter((l) => l.status === TentativeLessonStatus.NoTeacher).map<number>((l) => l.lessonAt.toMillis()) ?? [];

    const request: CreateLessonRequestV2 = {
      studentProfileId: studentProfile.id,
      enrolmentId: selectedEnrolment?.id,
      // The API is smart enough to know whether  we should be adding a new lesson set or using an existing one.
      isLessonSet: lessonSetType === 'NEW_LESSON_SET' || lessonSetType === 'WITHIN_EXISTING_LESSON_SET',
      lessonType: lessonType,
      subject: subject,
      language: language,
      theme: theme,
      isCustomerBillable: isCustomerBilledLesson,
      teacherAccountId: teacher?.id,
      startsAt: lessonAt!.toMillis(),
      endsAt: endAfterDate?.toMillis(),
      durationInMinutes: duration!,
      isRecurring: !!recurring,
      lessonDatesWithTeacher: [],
      lessonDatesWithoutTeacher,
    };
    createLessonRequest.mutate(request, {
      onSuccess: props.onSuccess,
    });
  };

  const handleCreateStartAnotherSetClick = () => {
    createLessonSet({
      onSuccess: () => {
        navigate(routes.lessons.create.url);
      },
    });
  };

  const handleCreateGoToSet = () => {
    createLessonSet({
      onSuccess: (response) => {
        if (response.lessonSetId) {
          navigate(routes.lessonSets.details.url(response.lessonSetId));
        } else {
          navigate(routes.lessons.create.url);
        }
      },
    });
  };

  const studentName = hideStudentName ? studentAndLessonData?.studentProfile.studentPrefixedNumber : studentAndLessonData?.studentProfile.name;

  function ButtonSet() {
    if (!!studentAndLessonData?.lessonSetType) {
      return (
        <CreateLessonFooter>
          <Button onClick={handlePreviousClick} variant="contained" size="large">
            Previous Step
          </Button>
          <Box>
            <Button onClick={handleCreateStartAnotherSetClick} type="button" variant="contained" size="large">
              Create & Start Another Set
            </Button>
            <Button sx={{ marginLeft: '8px' }} onClick={handleCreateGoToSet} type="button" variant="contained" size="large">
              Create & Go to Set
            </Button>
          </Box>
        </CreateLessonFooter>
      );
    } else {
      return (
        <CreateLessonFooter>
          <Button onClick={handlePreviousClick} variant="contained" size="large">
            Previous Step
          </Button>
          <Button type="submit" variant="contained" size="large" disabled={!atLeastOneLessonSelected}>
            Next Step
          </Button>
        </CreateLessonFooter>
      );
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Typography variant="titleMedium">Select from these available dates for {studentName}</Typography>
      </Stack>
      <Controller
        control={control}
        name="tentativeLessons"
        render={({ field }) => <TentativeLessons isLoading={getConflicts.isLoading} value={field.value} onChange={field.onChange} />}
      />
      <ButtonSet />
    </form>
  );
}

function TentativeLessons(props: { isLoading: boolean; value: TentativeLessonForm[]; onChange: (value: TentativeLessonForm[]) => void }) {
  if (props.isLoading) {
    return <CircularProgress />;
  }

  const handleClick = (lessonAt: DateTime) => (_event: any, checked: boolean) => {
    const updatedState = props.value.map<TentativeLessonForm>((v) =>
      lessonAt === v.lessonAt
        ? {
            ...v,
            status: checked ? TentativeLessonStatus.NoTeacher : TentativeLessonStatus.NotSet,
          }
        : v,
    );
    props.onChange(updatedState);
  };

  return (
    <FormGroup sx={{ marginTop: '24px' }}>
      {props.value.map((l) => (
        <TentativeLessonField key={l.lessonAt.toMillis()} value={l} onClick={handleClick(l.lessonAt)} />
      ))}
    </FormGroup>
  );
}

function TentativeLessonField(props: {
  value: TentativeLessonForm;
  onClick: (event: React.SyntheticEvent<Element, Event>, checked: boolean) => void;
}) {
  const isDisabled = props.value.status === TentativeLessonStatus.Exclude;
  const isChecked = [TentativeLessonStatus.NoTeacher, TentativeLessonStatus.TeacherAssigned].some((v) => v === props.value.status);

  return (
    <Stack direction="row">
      <FormControlLabel
        disabled={isDisabled}
        checked={isChecked}
        onChange={props.onClick}
        control={<Checkbox />}
        label={props.value.lessonAt.toFormat('ccc, LLL d, yyyy @ h:mma')}
      />
      {props.value.conflict ? <ConflictMessage conflictType={props.value.conflict.conflictType} message={props.value.conflict.message} /> : null}
    </Stack>
  );
}

export default StudentConflictCheckStep;
