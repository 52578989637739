import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { StatusChipIcon, StatusChipIconColor } from '@hoot/components/ui/StatusChip';

export function getChipStatusColor(value: StudentStatus | undefined): string {
  if (!value) return '';

  switch (value) {
    case StudentStatus.Active:
      return '#338F59';
    case StudentStatus.Lead:
      return '#0091D5';
    case StudentStatus.Cold:
      return '#ED6C02';
    case StudentStatus.Paused:
      return '#9C27B0';
    case StudentStatus.Inactive:
      return '#D32D41';
    default:
      return '#E0E0E0';
  }
}

// Used w/ StatusChip component
export function getStatusChipIcon(status?: StudentStatus): StatusChipIcon {
  switch (status) {
    case StudentStatus.Active:
      return StatusChipIcon.FullCircle;
    case StudentStatus.Lead:
      return StatusChipIcon.PartialCircle;
    case StudentStatus.Cold:
      return StatusChipIcon.EmptyCircle;
    case StudentStatus.Paused:
      return StatusChipIcon.EmptyCircle;
    case StudentStatus.Inactive:
      return StatusChipIcon.EmptyCircle;
    default:
      return StatusChipIcon.FullCircle;
  }
}

// Used w/ StatusChip component
export function getStatusChipColour(status?: StudentStatus): StatusChipIconColor {
  switch (status) {
    case StudentStatus.Active:
      return StatusChipIconColor.Green;
    case StudentStatus.Lead:
      return StatusChipIconColor.Blue;
    case StudentStatus.Cold:
      return StatusChipIconColor.Orange;
    case StudentStatus.Paused:
      return StatusChipIconColor.Grey;
    case StudentStatus.Inactive:
      return StatusChipIconColor.Red;
    default:
      return StatusChipIconColor.Grey;
  }
}
