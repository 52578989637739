import { ArrowDropDown } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';

export function PopoverButton(props: {
  title: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { title, onClick, popOverButtonSx } = props;
  return (
    <Button
      startIcon={<ArrowDropDown style={{ fill: '#1976D2' }} />}
      sx={{
        height: '32px',
        background: 'linear-gradient(0deg, rgba(0, 95, 175, 0.05), rgba(0, 95, 175, 0.05)), #FDFCFF',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
        textTransform: 'none',
        color: '#1A1C1E',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '20px',
        mx: 1,
        whiteSpace: 'nowrap',
        ...popOverButtonSx,
      }}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}
