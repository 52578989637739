import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import LanguageIcon from '@mui/icons-material/Language';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FormState } from 'react-hook-form';
import GenericCheckListModal from '@hoot/components/modals/GenericCheckListModal';
import { spokenLanguageOptions, spokenLanguageTypes } from '@hoot/interfaces/teacher';
import { TeacherAccountAndUserForm } from '@hoot/pages/users/interfaces/form';
import NoneListItem from './NoneListItem';

interface Props {
  selectedSpokenLanguages: SpokenLanguage[];
  setSelectedSpokenLanguages: Dispatch<SetStateAction<SpokenLanguage[]>>;
  formState: FormState<TeacherAccountAndUserForm>;
}

const TeacherSpokenLanguagesCard = (props: Props) => {
  const { selectedSpokenLanguages, setSelectedSpokenLanguages, formState } = props;
  const noneSelected = selectedSpokenLanguages.length === 0;

  const [openSpokenLanguageModal, setOpenSpokenLanguageModal] = useState<boolean>(false);

  const spokenLanguagesSet = useMemo(() => new Set<string>(selectedSpokenLanguages), [selectedSpokenLanguages]);

  const onConfirm = (checkedValues: string[]) => {
    setSelectedSpokenLanguages(checkedValues as SpokenLanguage[]);
    setOpenSpokenLanguageModal(false);
  };

  const onDismiss = () => {
    setOpenSpokenLanguageModal(false);
  };

  return (
    <>
      <GenericCheckListModal
        show={openSpokenLanguageModal}
        title={'Spoken Languages'}
        listItems={spokenLanguageOptions}
        defaultCheckedItems={spokenLanguagesSet}
        onConfirmButtonClicked={onConfirm}
        onDismiss={onDismiss}
        confirmButtonTitle="Apply"
        dialogContentSx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            width: '480px',
          },
        })}
      />

      <Grid container item>
        <Card
          sx={{
            width: 1,
          }}
        >
          <CardHeader
            sx={{ p: 3, pb: 0 }}
            title={
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="titleLarge">Spoken Languages</Typography>
                <Button
                  type="button"
                  sx={{ marginLeft: '8px' }}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => setOpenSpokenLanguageModal(true)}
                >
                  {noneSelected ? 'Add' : 'Edit'}
                </Button>
              </Stack>
            }
          />
          <CardContent sx={{ p: 3, pt: 0 }}>
            <Grid marginTop="24px" container sx={{ rowGap: 3 }}>
              <Grid item container xs={12} flexDirection="column">
                <List dense>
                  {selectedSpokenLanguages.length > 0 ? (
                    selectedSpokenLanguages.map((lang, idx) => (
                      <ListItem
                        key={lang}
                        sx={{
                          borderTop: `${idx !== 0 ? '1px solid #CAC4D0' : undefined}`,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                          <LanguageIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography component="div" variant="bodyLarge">
                              {spokenLanguageTypes[lang]}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))
                  ) : (
                    <NoneListItem />
                  )}
                </List>
                {formState.errors.spokenLanguages ? (
                  <FormHelperText sx={{ color: '#B3261E' }}>{formState.errors.spokenLanguages.message}</FormHelperText>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default TeacherSpokenLanguagesCard;
