import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface GetDailyRecordingsResponse {
  recordingId: string;
  dailyRoomName: string;
  duration: number;
  recordingStartedAt: number;
}

async function getDailyRecordings(lessonId: string): Promise<GetDailyRecordingsResponse[]> {
  const { data } = await axios.get<GetDailyRecordingsResponse[]>(`${config.apiUrl}/admin/scheduler/lesson/${lessonId}/recordings`);
  return data;
}

export default function useGetDailyRecordings(
  lessonId: string,
  options?: Omit<UseQueryOptions<GetDailyRecordingsResponse[], AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetDailyRecordings, lessonId], () => getDailyRecordings(lessonId), options);
}
