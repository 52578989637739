import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import { HootSupportedCountry } from '@hoot-reading/hoot-core/dist/enums/hfs/hoot-supported-country.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { StudentSpecialIndicators } from '@hoot-reading/hoot-core/dist/enums/user/student/student-special-indicators.enum';
import { FormOption } from '@hoot/interfaces/form';

export const enrolmentStatusMapping: Record<EnrolmentStatus, string> = {
  [EnrolmentStatus.Open]: 'Open',
  [EnrolmentStatus.Scheduled]: 'Scheduled',
  [EnrolmentStatus.InProgress]: 'In Progress',
  [EnrolmentStatus.Closed]: 'Closed',
};

export const productStatusLabels: Record<ProductStatus, string> = {
  [ProductStatus.Active]: 'Active',
  [ProductStatus.Requested]: 'Requested',
  [ProductStatus.Expired]: 'Expired',
};

export const enrolmentTypeMapping: Record<EnrolmentType, string> = {
  [EnrolmentType.AtHome]: 'At Home',
  [EnrolmentType.InSchool]: 'In School',
};

export const getEnrolmentStatusKeyFromValue = (value: string): EnrolmentStatus | undefined => {
  const key = Object.keys(enrolmentStatusMapping).find((key) => enrolmentStatusMapping[key as EnrolmentStatus] === value);
  return key as EnrolmentStatus | undefined;
};

export const districtSchoolMapping: Record<LocationType, string> = {
  [LocationType.School]: 'School',
  [LocationType.District]: 'District',
};

export const getDistrictSchoolTypeKeyFromValue = (value: string): LocationType | undefined => {
  const key = Object.keys(districtSchoolMapping).find((key) => districtSchoolMapping[key as LocationType] === value);
  return key as LocationType | undefined;
};

export const hootSupportedCountryMapping: Record<HootSupportedCountry, string> = {
  [HootSupportedCountry.Canada]: 'Canada',
  [HootSupportedCountry.UnitedStates]: 'United States',
};

export const getHootSupportedCountryKeyFromValue = (value: string): HootSupportedCountry | undefined => {
  const key = Object.keys(hootSupportedCountryMapping).find((key) => hootSupportedCountryMapping[key as HootSupportedCountry] === value);
  return key as HootSupportedCountry | undefined;
};

export const studentSpecialIndicators: FormOption<StudentSpecialIndicators>[] = [
  { value: StudentSpecialIndicators.ELL, label: 'ELL' },
  { value: StudentSpecialIndicators.FiveOhFour, label: '504' },
  { value: StudentSpecialIndicators.FosterCare, label: 'Foster Care' },
  { value: StudentSpecialIndicators.HispanicLatino, label: 'Hispanic or Latinx' },
  { value: StudentSpecialIndicators.Homeless, label: 'Homeless' },
  { value: StudentSpecialIndicators.IEP, label: 'IEP' },
  { value: StudentSpecialIndicators.Migrant, label: 'Migrant' },
  { value: StudentSpecialIndicators.NewComer, label: 'New Comer' },
  { value: StudentSpecialIndicators.PublicAssistance, label: 'Public Assistance' },
  { value: StudentSpecialIndicators.SED, label: 'SED' },
  { value: StudentSpecialIndicators.SpecialEducation, label: 'Special Education' },
];

export const studentSpecialIndicatorMapping: Record<StudentSpecialIndicators, string> = {
  [StudentSpecialIndicators.ELL]: 'ELL',
  [StudentSpecialIndicators.HispanicLatino]: 'Hispanic or Latinx',
  [StudentSpecialIndicators.Homeless]: 'Homeless',
  [StudentSpecialIndicators.FosterCare]: 'Foster Care',
  [StudentSpecialIndicators.NewComer]: 'New Comer',
  [StudentSpecialIndicators.SED]: 'SED',
  [StudentSpecialIndicators.Migrant]: 'Migrant',
  [StudentSpecialIndicators.PublicAssistance]: 'Public Assistance',
  [StudentSpecialIndicators.SpecialEducation]: 'Special Education',
  [StudentSpecialIndicators.IEP]: 'IEP',
  [StudentSpecialIndicators.FiveOhFour]: '504',
};

export const ENABLED_ACCOUNTS = ['Accenture (NA)', 'Go Easy', 'Riverside Natural Foods'];

export function accountDisplay(userAccount: string | null): string {
  //Set the if...else this way as a safeguard for empty data
  if (!userAccount || (userAccount && userAccount.trim() === '')) {
    return 'None';
  } else if (!ENABLED_ACCOUNTS.includes(userAccount)) {
    return 'None';
  } else {
    return userAccount;
  }
}
