import {
  CancellationReason,
  ScheduledLessonLanguage,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonType,
  SubRequired,
  cancellationReasonLookup,
  lessonLanguagesLookup,
  lessonStatusesLookup,
  lessonSubjectLookup,
  scheduledLessonTypeLookup,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Edit, MailOutline } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Button, Card, CardContent, CardHeader, Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Controller, FieldValues, FormProvider, Path, UseControllerProps, UseFormReturn, useController } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { TIME_FORMAT } from '@hoot/common/constants';
import { ConnectForm } from '@hoot/components/form/ConnectForm';
import { NonFormDisplayField } from '@hoot/components/form/NonFormDisplayField';
import FullCircleIcon from '@hoot/components/icons/FullCircle';
import WarningIcon from '@hoot/components/icons/WarningIcon';
import { TeacherModal, TeacherRowFieldDefinitions } from '@hoot/components/modals/SelectTeacher';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import useGetAccounts from '@hoot/hooks/api/common/useGetAccounts';
import useGetProduct from '@hoot/hooks/api/hfs/district-schools/products/useGetProduct';
import { ConflictsResponse } from '@hoot/hooks/api/lessons/useGetConflicts';
import { LessonDetailResponse } from '@hoot/hooks/api/lessons/useGetLesson';
import useGetTeacherAccount, { TeacherAccount } from '@hoot/hooks/api/user/teacher/useGetTeacherAccount';
import { LessonSetStatusChip } from '@hoot/pages/lesson-sets/LessonSets';
import { LessonDetailsForm, useEditLessonDetailsContext } from '@hoot/pages/lessons/edit/EditLessonDetailsContext';
import EditDropdownValueModal from '@hoot/pages/lessons/edit/Modals/EditDropdownValueModal';
import EditLessonDateTimeModal from '@hoot/pages/lessons/edit/Modals/EditLessonDateTimeModal';
import EditLessonStatusModal from '@hoot/pages/lessons/edit/Modals/EditLessonStatusModal';
import { ScheduledLessonSetStatus, subRequired } from '@hoot/pages/lessons/enums';
import { ShiftStatus } from '@hoot/pages/users/components/user-schedule/weekly-schedule/interfaces';
import { shiftColour } from '@hoot/pages/users/components/user-schedule/weekly-schedule/utils';
import { routes } from '@hoot/routes/routes';
import { enumToTitleCase } from '@hoot/utils/stringUtils';

const canUserEditField = (
  currentLessonState: LessonDetailResponse,
  editLessonForm: UseFormReturn<LessonDetailsForm>,
  fieldName: Path<LessonDetailsForm>,
  advancedTeacherEdit: boolean,
  advancedLessonDetailsEdit: boolean,
): boolean => {
  const openInProgressStatuses = [
    ScheduledLessonStatus.InProgress,
    ScheduledLessonStatus.Open,
    ScheduledLessonStatus.OpenWaitingForStudent,
    ScheduledLessonStatus.OpenWaitingForTeacher,
  ];

  switch (fieldName) {
    case 'lessonDate':
    case 'startTime':
    case 'lessonStatus':
    case 'cancellationReason':
      if (currentLessonState.lessonStatus === ScheduledLessonStatus.Scheduled) {
        return true;
      }
      return advancedLessonDetailsEdit;
    case 'teacherAccountId':
      if (currentLessonState.lessonStatus === ScheduledLessonStatus.Scheduled) {
        return true;
      }
      return advancedTeacherEdit && openInProgressStatuses.includes(currentLessonState.lessonStatus);
    case 'subRequired':
      const selectedTeacherAcctId = editLessonForm.getValues('teacherAccountId');
      const lessonInNextTwoHours = DateTime.fromMillis(currentLessonState.startsAt) <= DateTime.now().plus({ hours: 2 });
      return currentLessonState.lessonStatus === ScheduledLessonStatus.Scheduled && !!selectedTeacherAcctId && !lessonInNextTwoHours;
    case 'duration':
    case 'lessonType':
      return currentLessonState.lessonStatus === ScheduledLessonStatus.Scheduled;
    default:
      return false;
  }
};

const TeacherDetails = (props: { teacherAccountId: string | null; onChange: (id: string) => void }) => {
  const { info } = useAlert();
  const { scopes } = useAuth();
  const { teacherAccountId, onChange } = props;
  const [showTeacherAccountModal, setShowTeacherAccountModal] = useState<boolean>(false);
  const { lessonDetails, lessonDetailsForm } = useEditLessonDetailsContext();

  const getProduct = useGetProduct(lessonDetails?.enrolment?.productId!, {
    enabled: !!lessonDetails?.enrolment?.productId,
  });

  const [teacherAccount, setTeacherAccount] = useState<{
    name: string | null;
    email: string | null;
    number: string | null;
  }>({
    name: lessonDetails?.teacherName ?? null,
    email: lessonDetails?.teacherEmail ?? null,
    number: lessonDetails?.lessonPrefixedNumber ?? null,
  });

  const handleTeacherEditClick = () => {
    setShowTeacherAccountModal(true);
  };

  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    info(`Copied "${val}" to clipboard.`);
  };

  useGetTeacherAccount(teacherAccountId!, {
    enabled: !!teacherAccountId,
    onSuccess: (data: TeacherAccount) => {
      setTeacherAccount({
        name: `${data.user.firstName} ${data.user.lastName}`,
        email: data.user.email,
        number: data.prefixedNumber,
      });
    },
  });

  const onNewTeacherSelected = (selectedTeacher: TeacherRowFieldDefinitions) => {
    onChange(selectedTeacher.id);

    const selectedTeacherAccountId = lessonDetailsForm.getValues('teacherAccountId');
    if (selectedTeacherAccountId && selectedTeacherAccountId !== lessonDetails?.teacherAccountId) {
      lessonDetailsForm.setValue('subRequired', SubRequired.Updated, { shouldDirty: true });
    }

    setShowTeacherAccountModal(false);
  };

  if (!lessonDetails) {
    return null;
  }

  const advancedTeacherEdit = scopes.some((s) => s === HootEmployeeScope.AdvancedEditLessonTeacher);
  const advancedLessonDetailsEdit = scopes.some((s) => s === HootEmployeeScope.AdvancedEditLessonDetails);
  const editable = canUserEditField(
    lessonDetails,
    lessonDetailsForm,
    'teacherAccountId' as Path<LessonDetailsForm>,
    advancedTeacherEdit,
    advancedLessonDetailsEdit,
  );
  const lessonDuration = lessonDetailsForm.getValues('duration');
  const lessonDate = lessonDetailsForm.getValues('lessonDate');
  const lessonStartsAtTime = lessonDetailsForm.getValues('startTime'); //startTime;
  const lessonAt = lessonDate?.set({
    hour: lessonStartsAtTime.hour,
    minute: lessonStartsAtTime.minute,
    second: 0,
    millisecond: 0,
  });

  const WithPIIDetailsRemoved = () => {
    return (
      <>
        <Stack direction="column">
          <NonFormDisplayField
            label="Teacher Number"
            value={teacherAccountId ? teacherAccount.number : 'None'}
            linkTo={teacherAccountId ? routes.users.teachers.details.url(teacherAccountId) : undefined}
            onEditClick={editable ? handleTeacherEditClick : undefined}
          />
        </Stack>
        {teacherAccountId ? (
          <NonFormDisplayField
            label="Teacher Email"
            isCopyable={false}
            renderValueOverride={() => {
              return (
                <>
                  <Tooltip title={teacherAccount.email}>
                    <IconButton
                      sx={{ width: '14px', height: '14px', ml: 0, p: 1.5 }}
                      onClick={teacherAccount.email ? handleCopyToClipboard(teacherAccount.email) : () => null}
                    >
                      <MailOutline
                        sx={{
                          cursor: 'pointer',
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              );
            }}
          />
        ) : null}
      </>
    );
  };

  return (
    <>
      <WithPIIDetailsRemoved />
      {lessonAt && lessonDuration ? (
        <TeacherModal
          open={showTeacherAccountModal}
          onApply={onNewTeacherSelected}
          onDismiss={() => setShowTeacherAccountModal(false)}
          lessonType={lessonDetailsForm.getValues('lessonType')}
          tentativePeriods={[{ startsAt: lessonAt.toMillis(), endsAt: lessonAt.plus({ minutes: lessonDuration }).toMillis() }]}
          queryDefaults={{
            countryRequirements: getProduct.data?.countryRequirements,
            hootQualificationRequirements: getProduct.data?.qualificationRequirements,
            backgroundCheckRequirements: getProduct.data?.backgroundCheckRequirements,
          }}
          periodIsLesson
          showHasConflictFilter
        />
      ) : null}
    </>
  );
};

function LessonFormDisplayField<T extends FieldValues, TObj extends Path<T>>(
  props: { label: string; onEditClick: (fieldName: Path<LessonDetailsForm>) => void } & UseControllerProps<T, TObj>,
) {
  const { scopes } = useAuth();
  const advancedTeacherEdit = !!scopes.find((s) => s === HootEmployeeScope.AdvancedEditLessonTeacher);
  const advancedLessonDetailsEdit = !!scopes.find((s) => s === HootEmployeeScope.AdvancedEditLessonDetails);

  const { info } = useAlert();
  const { label, onEditClick } = props;
  const { lessonDetails, lessonDetailsForm } = useEditLessonDetailsContext();

  const { field, fieldState } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });

  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    info(`Copied "${val}" to clipboard.`);
  };

  const getFieldDisplayValue = (fieldName: Path<LessonDetailsForm>): string => {
    switch (fieldName) {
      case 'lessonStatus':
        return lessonStatusesLookup[field.value as ScheduledLessonStatus];
      case 'cancellationReason':
        return cancellationReasonLookup[field.value as CancellationReason];
      case 'lessonDate':
        return !!field.value ? (field.value as DateTime).toFormat('dd/LL/yyyy') : '';
      case 'startTime':
        return !!field.value ? ((field.value as DateTime).toFormat(TIME_FORMAT) ?? '') : '';
      case 'duration':
        return `${field.value} Minutes`;
      case 'lessonType':
        return scheduledLessonTypeLookup[field.value as ScheduledLessonType];
      case 'subRequired':
        return subRequired[field.value as SubRequired];
      default:
        return field.value;
    }
  };

  if (!lessonDetails) {
    return null;
  }

  const editable = canUserEditField(
    lessonDetails,
    lessonDetailsForm,
    field.name as Path<LessonDetailsForm>,
    advancedTeacherEdit,
    advancedLessonDetailsEdit,
  );
  const valueToDisplay = getFieldDisplayValue(field.name as Path<LessonDetailsForm>);

  return (
    <Box
      marginY="10px"
      paddingY="10px"
      paddingX="8px"
      style={{ backgroundColor: '#FAFAFA', border: fieldState.invalid ? 'solid 1px #FF0000' : undefined }}
    >
      <Stack direction="row">
        <Stack direction="column">
          <Typography variant="body2" style={{ color: fieldState.invalid ? '#FF0000' : undefined }}>
            {label}
          </Typography>
          {valueToDisplay ? (
            <Stack direction="row">
              <Typography variant="body1">{valueToDisplay}</Typography>
              <Tooltip title="Copy to clipboard">
                <IconButton onClick={handleCopyToClipboard(valueToDisplay)} sx={{ width: '14px', height: '14px', ml: 0.5, p: 1.5 }}>
                  <ContentCopyIcon sx={{ width: '11px', height: '13px' }} />
                </IconButton>
              </Tooltip>
            </Stack>
          ) : null}
        </Stack>
        {editable ? (
          <Grid container xs={2} item alignItems="center" justifyContent="center" title="Edit" marginLeft="auto">
            <Button sx={{ fontWeight: 'bold' }} onClick={() => onEditClick(field.name as Path<LessonDetailsForm>)}>
              <Edit />
            </Button>
          </Grid>
        ) : null}
      </Stack>
    </Box>
  );
}

const LessonFieldLayout = () => {
  const { info } = useAlert();
  const { lessonDetails, lessonDetailsForm } = useEditLessonDetailsContext();
  const getAccountsRequest = useGetAccounts(true);
  const accountDetails = getAccountsRequest.data?.find((acct) => acct.id === lessonDetails?.account);
  const [showEditStatus, setShowEditStatus] = useState<boolean>(false);
  const [showEditTime, setShowEditTime] = useState<boolean>(false);
  const [showEditDropDown, setShowEditDropDown] = useState<{
    show: boolean;
    fieldName: Path<LessonDetailsForm> | null;
    label: string | null;
  }>({
    show: false,
    fieldName: null,
    label: null,
  });

  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    info(`Copied "${val}" to clipboard.`);
  };

  const handleDropdownFieldEditClick = (fieldName: Path<LessonDetailsForm>, fieldLable: string) => {
    setShowEditDropDown({
      show: true,
      fieldName: fieldName,
      label: fieldLable,
    });
  };

  if (!lessonDetails) {
    return null;
  }

  return (
    <>
      <ConnectForm<LessonDetailsForm>>
        {({ control }) => {
          return (
            <Stack direction="row" spacing={3} sx={{ mt: 3 }}>
              <Stack gap={2} sx={{ width: '33%' }}>
                <Card>
                  <CardHeader
                    sx={{ p: 3, pb: 0 }}
                    title={
                      <Stack sx={{ p: 0 }}>
                        <Typography variant="titleLarge">Lesson Details</Typography>
                      </Stack>
                    }
                  />

                  <CardContent>
                    <LessonFormDisplayField name="lessonStatus" label="Lesson Status" control={control} onEditClick={() => setShowEditStatus(true)} />
                    {[ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled].includes(lessonDetailsForm.getValues('lessonStatus')) ? (
                      <LessonFormDisplayField
                        name="cancellationReason"
                        label="Cancellation Reason"
                        control={control}
                        onEditClick={() => setShowEditStatus(true)}
                      />
                    ) : null}
                    <LessonFormDisplayField name="lessonDate" label="Date" control={control} onEditClick={() => setShowEditTime(true)} />
                    <LessonFormDisplayField name="startTime" label="Time" control={control} onEditClick={() => setShowEditTime(true)} />
                    <LessonFormDisplayField
                      name="duration"
                      label="Duration"
                      control={control}
                      onEditClick={(fieldName) => handleDropdownFieldEditClick(fieldName, 'Duration')}
                    />
                    <LessonFormDisplayField
                      name="lessonType"
                      label="Lesson Type"
                      control={control}
                      onEditClick={(fieldName) => handleDropdownFieldEditClick(fieldName, 'Lesson Type')}
                    />

                    {/* Lesson sets are recurring lessons, no need to show the fact it's a lesson set and recurring = YES */}
                    {lessonDetails?.lessonSet?.status ? (
                      <NonFormDisplayField
                        label="Lesson Set Number"
                        value={lessonDetails?.lessonSet?.lessonSetPrefixedNumber}
                        renderValueOverride={() => {
                          return (
                            <>
                              <Typography variant="body1">
                                <Link to={routes.lessonSets.details.url(lessonDetails?.lessonSet?.id ?? '')}>
                                  {lessonDetails?.lessonSet?.lessonSetPrefixedNumber}
                                </Link>
                              </Typography>
                              &nbsp;
                              <LessonSetStatusChip status={lessonDetails?.lessonSet?.status as ScheduledLessonSetStatus} />
                            </>
                          );
                        }}
                      />
                    ) : (
                      <NonFormDisplayField label="Recurring" value={lessonDetails.isRecurring ? 'Yes' : 'No'} />
                    )}
                    <NonFormDisplayField
                      label="Enrolment"
                      value={lessonDetails?.enrolment?.friendlyId}
                      linkTo={
                        lessonDetails.enrolment
                          ? routes.districtsSchools.enrolments.details.url(lessonDetails.enrolment.locationId, lessonDetails.enrolment.id)
                          : undefined
                      }
                    />
                    <NonFormDisplayField label="Customer-Billed Lesson" value={lessonDetails.isCustomerBillable ? 'Yes' : 'No'} />
                    {lessonDetails?.subject !== ScheduledLessonSubject.Reading ? (
                      <NonFormDisplayField label="Subject" value={lessonSubjectLookup[lessonDetails.subject as ScheduledLessonSubject]} />
                    ) : null}
                    {lessonDetails?.language !== ScheduledLessonLanguage.English ? (
                      <NonFormDisplayField label="Language" value={lessonLanguagesLookup[lessonDetails.language as ScheduledLessonLanguage]} />
                    ) : null}
                  </CardContent>
                </Card>
              </Stack>

              <Stack gap={2} sx={{ width: '33%' }}>
                <Card>
                  <CardHeader
                    sx={{ p: 3, pb: 0 }}
                    title={
                      <Stack sx={{ p: 0 }}>
                        <Typography variant="titleLarge">Student Details</Typography>
                      </Stack>
                    }
                  />
                  <CardContent>
                    <NonFormDisplayField
                      label="Student Number"
                      value={lessonDetails?.studentPrefixedNumber}
                      linkTo={lessonDetails?.studentProfileId ? routes.users.students.details.url(lessonDetails?.studentProfileId) : undefined}
                    />
                    <NonFormDisplayField
                      label="Parent Number"
                      value={lessonDetails?.parentPrefixedNumber}
                      linkTo={lessonDetails?.parentProfileId ? routes.users.parents.details.url(lessonDetails.parentProfileId) : undefined}
                    />

                    <NonFormDisplayField
                      label="Parent Email"
                      isCopyable={false}
                      renderValueOverride={
                        !lessonDetails?.parentEmail
                          ? undefined
                          : () => {
                              return (
                                <>
                                  <Tooltip title={lessonDetails?.parentEmail}>
                                    <IconButton
                                      sx={{ width: '14px', height: '14px', ml: 0, p: 1.5 }}
                                      onClick={lessonDetails?.parentEmail ? handleCopyToClipboard(lessonDetails?.parentEmail) : () => null}
                                    >
                                      <MailOutline
                                        sx={{
                                          cursor: 'pointer',
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              );
                            }
                      }
                    />
                    <NonFormDisplayField label="Account" value={accountDetails?.name} />
                    <NonFormDisplayField
                      label="District/School"
                      value={lessonDetails?.enrolment?.billableEntityName ?? null}
                      linkTo={lessonDetails?.enrolment ? routes.districtsSchools.details.url(lessonDetails.enrolment.billableEntityId) : undefined}
                    />
                  </CardContent>
                </Card>
              </Stack>

              <Stack gap={2} sx={{ width: '33%' }}>
                <Card>
                  <CardHeader
                    sx={{ p: 3, pb: 0 }}
                    title={
                      <Stack sx={{ p: 0 }}>
                        <Typography variant="titleLarge">Teacher Details</Typography>
                      </Stack>
                    }
                  />
                  <CardContent>
                    <Controller
                      control={control}
                      name="teacherAccountId"
                      render={({ field }) => <TeacherDetails teacherAccountId={field.value} onChange={field.onChange} />}
                    />
                    <LessonFormDisplayField
                      name="subRequired"
                      label="Sub Required"
                      control={control}
                      onEditClick={(fieldName) => handleDropdownFieldEditClick(fieldName, 'Sub Required')}
                    />
                    {lessonDetails?.teacherBlock ? (
                      <NonFormDisplayField
                        label="Block"
                        renderValueOverride={() => {
                          return (
                            <Stack direction="row" gap={1}>
                              <Typography variant="body1">
                                <Link
                                  to={
                                    lessonDetails.teacherBlock?.id && lessonDetails?.teacherAccountId
                                      ? routes.users.teachers.blocks.details.url(lessonDetails?.teacherAccountId, lessonDetails.teacherBlock.id)
                                      : ''
                                  }
                                >
                                  {lessonDetails?.teacherBlock?.prefixedNumber}
                                </Link>
                              </Typography>
                              &nbsp;
                              <Chip
                                sx={{
                                  paddingLeft: '4px',
                                  '& > svg': {
                                    width: '8px',
                                    height: '8px',
                                  },
                                }}
                                icon={<FullCircleIcon fill={shiftColour(lessonDetails.teacherBlock?.status as ShiftStatus)} />}
                                size="small"
                                label={enumToTitleCase(lessonDetails.teacherBlock?.status ?? '')}
                              />
                            </Stack>
                          );
                        }}
                      />
                    ) : null}
                  </CardContent>
                </Card>
              </Stack>
            </Stack>
          );
        }}
      </ConnectForm>
      <EditLessonStatusModal
        open={showEditStatus}
        handleClose={() => {
          setShowEditStatus(false);
        }}
      />
      <EditLessonDateTimeModal
        open={showEditTime}
        handleClose={() => {
          setShowEditTime(false);
        }}
      />
      <EditDropdownValueModal
        name={showEditDropDown.fieldName ?? 'duration'}
        label={showEditDropDown.label ?? ''}
        open={showEditDropDown.show}
        handleClose={() => {
          setShowEditDropDown({
            show: false,
            fieldName: null,
            label: null,
          });
        }}
      />
    </>
  );
};

const LessonDetails = () => {
  const { lessonDetailsForm, updateRequestConflicts } = useEditLessonDetailsContext();

  return (
    <FormProvider {...lessonDetailsForm}>
      {!!updateRequestConflicts ? (
        <Box padding={3} marginY={3} style={{ backgroundColor: '#FDEDE9', border: 'solid 2px #FF0000' }}>
          <Stack gap={2} direction="row">
            <Box style={{ alignSelf: 'center' }}>
              <WarningIcon />
            </Box>
            <Stack direction="column">
              <Typography>Could not save changes. This schedule contains conflicts and must be modified.</Typography>
              <ul>
                {updateRequestConflicts?.map((c: ConflictsResponse, idx) => {
                  return <li key={`${c.lessonStart}-${idx}`}>{c.message}</li>;
                })}
              </ul>
            </Stack>
          </Stack>
        </Box>
      ) : null}
      <LessonFieldLayout />
    </FormProvider>
  );
};

export default LessonDetails;
