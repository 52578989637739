import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { defaultRowsPerPage } from '@hoot/common/constants';
import { HeaderData, Table } from '@hoot/components/ui/Table';
import { LessonReviewsOrderColumn, LessonReviewsQuery, useGetLessonReviews } from '@hoot/hooks/api/user/student/lesson-reviews/useGetLessonReviews';
import { SortOrder, toggleSortOrder } from '@hoot/interfaces/order-by';
import { routes } from '@hoot/routes/routes';
import { OrderBy } from '../../../lessons/enums';

export interface LessonReviewRow {
  id: string;
  lessonDate: ReactNode;
  lessonNumber: ReactNode;
  createdBy: ReactNode;
  subject: string;
  focus: string;
}

const headers: HeaderData<LessonReviewRow>[] = [
  { name: 'id', property: 'id', isHidden: true },
  { name: 'Lesson Date', property: 'lessonDate', isSortable: true },
  { name: 'Lesson Number', property: 'lessonNumber', isSortable: true },
  { name: 'Created By', property: 'createdBy', isSortable: true },
  { name: 'Subject', property: 'subject', isSortable: true },
  { name: 'Focus', property: 'focus', isSortable: true },
];

const LessonReviewsCard = (props: { studentProfileId: string }) => {
  const [query, setQuery] = useState<LessonReviewsQuery>({
    page: 1,
    pageSize: defaultRowsPerPage,
    studentProfileId: props.studentProfileId,
    orderColumn: LessonReviewsOrderColumn.LessonDate,
    orderBy: SortOrder.DESC,
  });
  const lessonReviews = useGetLessonReviews(query);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize }));
  };

  const handleSortBy = (selectedColumn: keyof LessonReviewRow) => {
    function sortKey(): LessonReviewsOrderColumn {
      switch (selectedColumn) {
        case 'createdBy':
          return LessonReviewsOrderColumn.CreatedBy;
        case 'focus':
          return LessonReviewsOrderColumn.Focus;
        case 'subject':
          return LessonReviewsOrderColumn.Subject;
        case 'lessonDate':
          return LessonReviewsOrderColumn.LessonDate;
        case 'lessonNumber':
          return LessonReviewsOrderColumn.LessonNumber;
        default:
          return LessonReviewsOrderColumn.LessonDate;
      }
    }

    const orderColumn = sortKey();
    setQuery((q) => ({ ...q, orderColumn: orderColumn, orderBy: toggleSortOrder(q.orderBy), page: 1 }));
  };

  if (!lessonReviews.data) {
    return null;
  }

  const data = lessonReviews.data.data.map<LessonReviewRow>((lr) => ({
    id: lr.id,
    createdBy: <Link to={routes.users.teachers.details.url(lr.teacherAccountId)}>{lr.createdByPrefixedNumber}</Link>,
    focus: lr.focus,
    lessonDate: (
      <Link to={routes.users.students.lessonReviews.urlWithId(props.studentProfileId, lr.id)}>
        {DateTime.fromSQL(lr.lessonDate).toFormat('ccc LLLL dd, yyyy')}
      </Link>
    ),
    lessonNumber: <Link to={routes.lessons.details.url(lr.lessonId)}>{lr.lessonPrefixedNumber}</Link>,
    subject: lr.subject,
  }));

  function sortColumn(): keyof LessonReviewRow {
    switch (query.orderColumn) {
      case LessonReviewsOrderColumn.CreatedBy:
        return 'createdBy';
      case LessonReviewsOrderColumn.Focus:
        return 'focus';
      case LessonReviewsOrderColumn.LessonDate:
        return 'lessonDate';
      case LessonReviewsOrderColumn.LessonNumber:
        return 'lessonNumber';
      case LessonReviewsOrderColumn.Subject:
        return 'subject';
    }
  }

  return (
    <Card
      sx={{
        width: 1,
      }}
    >
      <CardContent>
        <Stack>
          <Typography variant="titleLarge">Lesson Reviews</Typography>
          <Box sx={{ marginTop: '24px' }}>
            <Table
              isSortable
              headers={headers}
              data={data}
              isPaginated
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onSortBy={handleSortBy}
              sortBy={sortColumn()}
              sortOrder={query.orderBy === SortOrder.ASC ? OrderBy.Asc : OrderBy.Desc}
              page={lessonReviews.data.page}
              count={lessonReviews.data.count}
              rowsPerPage={query.pageSize}
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default LessonReviewsCard;
