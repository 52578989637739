import { SearchStudentsQuerySortKeyEnum } from '@hoot-reading/hoot-core/dist/enums/user/search-student-query-sort-key.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { SortableQuery } from '@hoot/interfaces/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/interfaces/pagination';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface SearchStudentsQuery extends PaginatableQuery, SortableQuery<SearchStudentsQuerySortKeyEnum> {
  search?: string;
  profileStatus?: StudentStatus[];
  hasEnrolments?: boolean[];
  hasParent?: boolean;
}

export interface SearchStudentResponse {
  id: string;
  userId: string;
  name: string;
  studentNumber: string;
  studentPrefixedNumber: string;
  parent: string | undefined;
  parentEmail: string | undefined;
  profileStatus: StudentStatus;
}

export default function useSearchStudentsV2(
  query: SearchStudentsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<SearchStudentResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.SearchStudentProfilesV2, query],
    async () => {
      const { data } = await axios.get(`${config.apiUrl}/admin/student-profile/search/v2`, {
        params: query,
      });
      return data;
    },
    options,
  );
}
