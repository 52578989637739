import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { useCallback, useState } from 'react';
import { ShiftUtilizationLesson, ShiftUtilizationShift, ShiftUtilizationTeacher } from '@hoot/hooks/api/shifts/useGetShiftUtilization';
import { StatusColours } from '@hoot/pages/users/components/user-schedule/weekly-schedule/enums';
import { lessonColour } from '@hoot/pages/users/components/user-schedule/weekly-schedule/utils';
import { ITeacherTableRowsProps, ITeacherTimeSlot } from '../shift-interfaces';
import { lessonHoverColor, shiftEnds, shiftStarts, timeSlotLessons, timeSlotShifts } from '../shift-utils';
import LessonDetailsDialog from './dialogs/LessonDetailsDialog';
import ShiftDetailsDialog from './dialogs/ShiftDetailsDialog';
import TimeSlot from './timeslots/TimeSlot';

const TeacherTableRows = (props: ITeacherTableRowsProps) => {
  const { teacherData, timeSlots, showCreateShiftWizard, setShowCreateShiftWizard } = props;
  const [selectedTimeSlotShift, setSelectedTimeSlotShift] = useState<ShiftUtilizationShift | undefined>(undefined);
  const [selectedTimeSlotLessons, setSelectedTimeSlotLessons] = useState<ShiftUtilizationLesson[]>([]);

  const handleLessonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, lessons: ShiftUtilizationLesson[]) => {
    event.stopPropagation();
    setSelectedTimeSlotLessons(lessons);
  };

  const handleCloseShiftDetails = () => {
    setSelectedTimeSlotShift(undefined);
  };

  const handleCloseLessonDetails = () => {
    setSelectedTimeSlotLessons([]);
  };

  const handleLessonColour = (lessons: ShiftUtilizationLesson[]): StatusColours => {
    if (lessons.length === 1) {
      return lessonColour(lessons[0].status);
    } else {
      const atLeastOneLessonScheduled = lessons.some((lesson) => lesson.status === ScheduledLessonStatus.Scheduled);
      const atLeastOneLessonCompleted = lessons.some((lesson) =>
        [ScheduledLessonStatus.CompletedSuccessfully, ScheduledLessonStatus.CompletedUnsuccessfully].includes(lesson.status),
      );
      if (atLeastOneLessonScheduled) {
        return StatusColours.Scheduled;
      } else if (atLeastOneLessonCompleted) {
        return StatusColours.Completed;
      } else {
        return StatusColours.Cancelled;
      }
    }
  };

  const teacherTableData = useCallback(
    (teacher: ShiftUtilizationTeacher) => {
      const rows: ITeacherTimeSlot[] = [];

      for (const timeSlot of timeSlots) {
        const lessons = timeSlotLessons(teacher.lessons, timeSlot);
        const shift = timeSlotShifts(teacher.shifts, timeSlot);
        const shiftStartsInTimeSlot = shiftStarts(teacher.shifts, timeSlot);
        const shiftEndsInTimeSlot = shiftEnds(teacher.shifts, timeSlot);
        const lessonColor = handleLessonColour(lessons);

        const t: ITeacherTimeSlot = {
          timeSlot: timeSlot,
          teacher: teacher,
          lessons: lessons,
          shift: shift[0],
          shiftStarts: shiftStartsInTimeSlot,
          shiftEnds: shiftEndsInTimeSlot,
          lessonColor: lessonColor,
        };
        rows.push(t);
      }
      return rows;
    },
    [timeSlots],
  );

  return (
    <>
      <Stack>
        {teacherData
          ? teacherData.teachers.map((d) => (
              <Stack key={d.id} direction="row" sx={{ height: '65px' }}>
                {teacherTableData(d).map((t) => (
                  <TimeSlot
                    key={d.id}
                    timeSlot={t.timeSlot}
                    teacher={t.teacher}
                    hasLesson={!!t.lessons.length}
                    shift={t.shift}
                    shiftStarts={t.shiftStarts}
                    shiftEnds={t.shiftEnds}
                    onShiftClick={setSelectedTimeSlotShift}
                    showCreateShiftWizard={showCreateShiftWizard}
                    setShowCreateShiftWizard={setShowCreateShiftWizard}
                  >
                    {!!t.lessons.length ? (
                      <Button
                        variant="contained"
                        sx={{
                          height: '100%',
                          width: '100%',
                          borderRadius: '8px',
                          background: t.lessonColor,
                          '&:hover': { background: lessonHoverColor(t.lessonColor) },
                          ...(!t.shift ? { marginY: 1 } : {}),
                        }}
                        onClick={(event) => handleLessonClick(event, t.lessons)}
                      >
                        {t.lessons.length > 1 ? t.lessons.length : null}
                      </Button>
                    ) : null}
                  </TimeSlot>
                ))}
              </Stack>
            ))
          : Array(10)
              .fill(0)
              .map((_a, idx) => (
                <Stack key={idx} direction="row" sx={{ height: '65px' }}>
                  {timeSlots.map((t, idx) => (
                    <TimeSlot key={`${t.start}-${idx}`} timeSlot={t} />
                  ))}
                </Stack>
              ))}
      </Stack>
      <ShiftDetailsDialog open={!!selectedTimeSlotShift} onClose={handleCloseShiftDetails} shift={selectedTimeSlotShift} />
      <LessonDetailsDialog open={!!selectedTimeSlotLessons.length} onClose={handleCloseLessonDetails} lessons={selectedTimeSlotLessons} />
    </>
  );
};

export default TeacherTableRows;
