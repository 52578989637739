import { ContentCopy, Edit } from '@mui/icons-material';
import { Button, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useAlert } from '../../contexts/AlertContext';

export const NonFormDisplayField = (props: {
  label: string;
  value?: React.ReactNode | null;
  renderValueOverride?: () => ReactElement;
  linkTo?: string | undefined;
  onEditClick?: () => void | undefined;
  isCopyable?: boolean;
  isElevated?: boolean;
  paddingX?: string;
}) => {
  const { info } = useAlert();
  const { label, value, linkTo, onEditClick, renderValueOverride, isCopyable = true } = props;

  const handleCopyToClipboard = (val: string) => () => {
    navigator.clipboard.writeText(val);
    info(`Copied "${val}" to clipboard.`);
  };

  if (!value && !renderValueOverride) {
    return null;
  }

  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: '#FAFAFA',
        paddingX: props.paddingX ?? '8px',
        paddingY: '10px',
        marginY: '10px',
        boxShadow: props.isElevated ? '0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004D' : undefined,
      }}
    >
      <Stack direction="column">
        <Typography variant="body2">{label}</Typography>
        <Stack direction="row">
          {renderValueOverride ? (
            renderValueOverride()
          ) : (
            <Typography variant="body1">
              {linkTo ? (
                <Link to={linkTo} style={{ fontSize: '16px', lineHeight: '24px' }}>
                  {value}
                </Link>
              ) : (
                value
              )}
            </Typography>
          )}
          {isCopyable && typeof value === 'string' ? (
            <Tooltip title="Copy to clipboard">
              <IconButton onClick={handleCopyToClipboard(value!)} sx={{ width: '14px', height: '14px', ml: 0.5, p: 1.5 }}>
                <ContentCopy sx={{ width: '11px', height: '13px' }} />
              </IconButton>
            </Tooltip>
          ) : null}
        </Stack>
      </Stack>
      {onEditClick ? (
        <Grid container xs={2} item alignItems="center" justifyContent="center" title="Edit" marginLeft="auto">
          <Button
            sx={{
              color: '#1976d2',
              backgroundColor: '#FFFFFF',
              blend: 'Pass through',
              //no other way to size a material-ui btn apparently
              maxWidth: '40px',
              maxHeight: '40px',
              minWidth: '40px',
              minHeight: '40px',
            }}
            onClick={onEditClick}
          >
            <Edit />
          </Button>
        </Grid>
      ) : null}
    </Stack>
  );
};
