import { DateTime } from 'luxon';
import { DayOfWeek } from '@hoot/utils/dateTime';

type Occurrences = {
  occurrences: number;
  endsAt?: never;
};

type EndsAt = {
  occurrences?: never;
  endsAt: DateTime;
};

export type LessonTimeByDayOfWeek = {
  dayOfWeek: DayOfWeek;
  time: string;
};

export type GenerateLesson = {
  startsAt: DateTime;
  lessonTimeByDayOfWeek: LessonTimeByDayOfWeek[];
} & (Occurrences | EndsAt);

//Check if the API version of this function needs to be updated if modifying this.
export function generateLessonTimes(params: GenerateLesson) {
  let offset = 0;
  const results = [];
  const now = DateTime.now();
  const yearFromNow = params.startsAt.plus({ year: 1 });

  loop1: for (;;) {
    const week = params.startsAt.plus({ week: offset }).startOf('day');

    // break if current week is greater than or equal to a year from now
    if (week >= yearFromNow) {
      break;
    }

    // if ends at is set, check to see if current week is less than or equal to ends at
    for (const lessonTime of params.lessonTimeByDayOfWeek) {
      if (lessonTime) {
        const timeUnits = lessonTime.time.split(':');
        const hour = parseInt(timeUnits[0]);
        const minute = parseInt(timeUnits[1]);
        const lessonDate = week.set({
          weekday: lessonTime.dayOfWeek,
          hour: hour,
          minute: minute,
          second: 0,
          millisecond: 0,
        });

        // if using endsAt to determine when lessons should be created up to
        if (params.endsAt && lessonDate >= params.endsAt) {
          break;
        }

        if (lessonDate > now && lessonDate >= params.startsAt) {
          results.push(lessonDate);

          // if using occurrences to determine when lessons should be created up to
          if (params.occurrences && results.length >= params.occurrences) {
            break loop1;
          }
        }
      }
    }
    offset += 1;
  }
  return results;
}
