import { TeacherBlockType } from '@hoot-reading/hoot-core/dist/enums/teacher-block';
import { DateTime } from 'luxon';
import React, { PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectedTeacher } from '@hoot/components/modals/SelectTeacher';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { useStableSearchParams } from '@hoot/hooks/useStableSearchParams';
import { FormOption } from '@hoot/interfaces/form';
import { DayOfWeek } from '@hoot/utils/dateTime';
import CreateBlockDateTimeStep, { RecurrenceEndType } from '../date-time-step/CreateBlockDateTimeStep';
import CreateBlockReviewStep from '../review-step/CreateBlockReviewStep';
import TeacherConflictCheckStep, { TentativeBlockForm } from '../teacher-conflicts-step/TeacherConflictCheckStep';

export enum CreateBlockWizardStepEnum {
  DateAndTime = 1,
  TeacherAndConflicts,
  Review,
}

export interface CreateBlockWizardStep {
  id: CreateBlockWizardStepEnum;
  stepperLabel: string;
  contentLabel?: string;
  content: () => ReactNode;
}

export interface DayOfWeekForBlock {
  isSelected: boolean;
  dayOfWeek: DayOfWeek;
}

export const teacherBlockTypeOptions: FormOption<TeacherBlockType>[] = [
  { label: 'Regular', value: TeacherBlockType.Regular },
  { label: 'On Call', value: TeacherBlockType.OnCall },
  { label: 'Interview', value: TeacherBlockType.Interview },
];

export interface BlockDetails {
  blockDate: DateTime;
  blockStartTime: DateTime;
  blockEndTime: DateTime;
  blockType: TeacherBlockType;
  recurring: boolean;
  recurrenceEnd: RecurrenceEndType;
  occurrences: number;
  endAfterDate: DateTime;
  daysOfWeek: DayOfWeekForBlock[];
  tentativeBlocks: TentativeBlockForm[];
  preSelectedTeacher: boolean;
  urlParamTeacherAccountId?: string;
  teacher?: SelectedTeacher;
}

interface CreateBlockContextProps {
  blockDetails: BlockDetails;
  setBlockDetails: React.Dispatch<React.SetStateAction<BlockDetails>>;
  currentStep: CreateBlockWizardStep;
  goToStep: (step: CreateBlockWizardStepEnum) => void;
  goToPrevStep: () => void;
  goToNextStep: () => void;
  closeBlockCreationModal: () => void;
}

export const createBlockWizardSteps: CreateBlockWizardStep[] = [
  {
    id: CreateBlockWizardStepEnum.DateAndTime,
    stepperLabel: 'Date & Time',
    content: () => <CreateBlockDateTimeStep />,
  },
  {
    id: CreateBlockWizardStepEnum.TeacherAndConflicts,
    stepperLabel: 'Teacher & Conflicts',
    content: () => <TeacherConflictCheckStep />,
  },
  {
    id: CreateBlockWizardStepEnum.Review,
    stepperLabel: 'Review',
    content: () => <CreateBlockReviewStep />,
  },
];

const CreateBlockContext = React.createContext<CreateBlockContextProps>(undefined!);
CreateBlockContext.displayName = 'CreateBlockContext';

interface CreateBlockContextProviderProps extends PropsWithChildren<any> {
  blockStartTime?: DateTime;
  blockEndTime?: DateTime;
  teacher?: SelectedTeacher;
  preSelectedTeacher?: boolean;
  closeModal?: () => void;
}

const CreateBlockContextProvider = (props: CreateBlockContextProviderProps) => {
  const { children, blockStartTime, blockEndTime, teacher, preSelectedTeacher, closeModal } = props;
  const { setIsDirty } = useBlockIfDirty();
  const { teacherAccountId } = useParams() as { teacherAccountId: string | undefined };
  const [searchParams, setSearchParams] = useStableSearchParams();

  const stepSearchParam = searchParams.get('step');
  const numericStep = Number(stepSearchParam);

  const now = DateTime.now().plus({ minutes: 1 });
  const minBlockRecurrenceDate = DateTime.now().plus({ days: 7 }).endOf('day');

  const defaultValues: BlockDetails = {
    blockDate: blockStartTime ?? now,
    blockStartTime: blockStartTime ?? now,
    blockEndTime: blockEndTime ?? now,
    blockType: TeacherBlockType.Regular,
    recurring: false,
    recurrenceEnd: RecurrenceEndType.EndAfterDate,
    occurrences: 0,
    endAfterDate: minBlockRecurrenceDate,
    daysOfWeek: [
      { isSelected: false, dayOfWeek: DayOfWeek.Monday },
      { isSelected: false, dayOfWeek: DayOfWeek.Tuesday },
      { isSelected: false, dayOfWeek: DayOfWeek.Wednesday },
      { isSelected: false, dayOfWeek: DayOfWeek.Thursday },
      { isSelected: false, dayOfWeek: DayOfWeek.Friday },
      { isSelected: false, dayOfWeek: DayOfWeek.Saturday },
      { isSelected: false, dayOfWeek: DayOfWeek.Sunday },
    ],
    tentativeBlocks: [],
    preSelectedTeacher: preSelectedTeacher ?? !!teacherAccountId,
    urlParamTeacherAccountId: teacherAccountId,
    teacher: teacher ?? undefined,
  };

  const [blockDetails, setBlockDetails] = useState<BlockDetails>(defaultValues);

  // Hacky workaround so that we can guarantee that we only ever render step #1 on page load.
  const mountedRef = useRef(false);

  const redirectToInitialStep = useCallback(() => {
    setSearchParams(
      {
        step: `${CreateBlockWizardStepEnum.DateAndTime}`,
      },
      {
        replace: true,
      },
    );
  }, [setSearchParams]);

  // On page load, always start at step #1. All data is lost on refresh, so we need to start over.
  useEffect(() => {
    mountedRef.current = true;
    setIsDirty(true);
    redirectToInitialStep();
  }, [redirectToInitialStep, setIsDirty]);

  // Ensure step is always within bounds.
  useEffect(() => {
    // If invalid step, then start at the beginning.
    if (isNaN(numericStep) || numericStep < CreateBlockWizardStepEnum.DateAndTime || numericStep > CreateBlockWizardStepEnum.Review) {
      redirectToInitialStep();
    }
  }, [numericStep, redirectToInitialStep, stepSearchParam]);

  const currentStep = useMemo(() => {
    // Ensure the step query is an actual number.
    const numericStep = !isNaN(Number(stepSearchParam)) ? Number(stepSearchParam) : CreateBlockWizardStepEnum.DateAndTime;

    // If the step is out of bounds, then just default to the beginning.
    const step: CreateBlockWizardStepEnum =
      numericStep < CreateBlockWizardStepEnum.DateAndTime || numericStep > CreateBlockWizardStepEnum.Review
        ? CreateBlockWizardStepEnum.DateAndTime
        : numericStep;

    return createBlockWizardSteps.find((x) => x.id === step) ?? createBlockWizardSteps[0];
  }, [stepSearchParam]);

  const goToStep = (step: CreateBlockWizardStepEnum) => {
    setSearchParams(
      {
        step: `${step}`,
      },
      {
        replace: true,
      },
    );
  };

  const goToPrevStep = () => {
    if (currentStep.id > CreateBlockWizardStepEnum.DateAndTime) {
      goToStep(currentStep.id - 1);
    } else {
      console.error(`Can not go to previous step. There is no step before step ${CreateBlockWizardStepEnum.DateAndTime}.`);
    }
  };

  const goToNextStep = () => {
    if (currentStep.id < CreateBlockWizardStepEnum.Review) {
      goToStep(currentStep.id + 1);
    } else {
      console.error(`Can not go to next step. There is no step after step ${CreateBlockWizardStepEnum.Review}.`);
    }
  };

  const closeBlockCreationModal = () => {
    closeModal?.();
  };

  return (
    <CreateBlockContext.Provider
      value={{
        blockDetails,
        setBlockDetails,
        currentStep,
        goToStep,
        goToPrevStep,
        goToNextStep,
        closeBlockCreationModal,
      }}
    >
      {mountedRef.current ? children : null}
    </CreateBlockContext.Provider>
  );
};

export const useCreateBlockContext = () => {
  const context = React.useContext(CreateBlockContext);

  if (context === undefined) {
    throw new Error('useCreateBlockContext must be used within a CreateBlockContextProvider');
  }
  return context;
};

export default CreateBlockContextProvider;
