import { TeacherStage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-stage.enum';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import { Card, CardContent, CardHeader, FormHelperText, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Control, FormState } from 'react-hook-form';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types/form';
import { DatePicker } from '@hoot/components/form/DatePicker';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import useSearchLessonPayRateTiers from '@hoot/hooks/api/user/teacher/useSearchLessonPayRateTiers';
import { FormOption } from '@hoot/interfaces/form';
import { agreementEndReasonOptions } from '@hoot/interfaces/teacher';
import { TeacherAccountAndUserForm } from '@hoot/pages/users/interfaces/form';

interface Props {
  control: Control<TeacherAccountAndUserForm>;
  watch: UseFormWatch<TeacherAccountAndUserForm>;
  formState: FormState<TeacherAccountAndUserForm>;
  setValue: UseFormSetValue<TeacherAccountAndUserForm>;
}

const TeacherContractDatesCard = (props: Props) => {
  const { control, watch, formState, setValue } = props;

  const { agreementEndDate, goLiveDate, teacherStatus, teacherStage, leaveEffectiveDate, payEffectiveDate, payTier } = watch();

  const { hootEmployee } = useAuth();

  const canAccessInvoicing = hootEmployee?.canAccessInvoicing;
  const canManageTeacher = hootEmployee?.canManageTeacherDetails;
  const hideContractCard = !canManageTeacher;
  const teacherHasManageDetailsAndInvoicingPermissions = !!canAccessInvoicing && !!canManageTeacher;

  const teacherStatusActive = teacherStatus === TeacherStatus.Active;
  const teacherStageOnLeave = teacherStage === TeacherStage.OnLeave;

  const teacherPayTierRequired = !!goLiveDate || !!payEffectiveDate;

  const { data } = useSearchLessonPayRateTiers();

  let teacherPayLabel = 'Teaching Pay';
  teacherPayLabel = teacherPayTierRequired ? `${teacherPayLabel}*` : teacherPayLabel;

  useEffect(() => {
    if (!teacherStageOnLeave) {
      setValue('leaveEffectiveDate', null, { shouldDirty: false });
      setValue('leaveReturnDate', null, { shouldDirty: false });
    }
  }, [teacherStageOnLeave, setValue]);

  return (
    <>
      {hideContractCard ? null : (
        <Grid container item>
          <Card
            sx={{
              width: 1,
            }}
          >
            <CardHeader
              sx={{ p: 3, pb: 0 }}
              title={
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="titleLarge">Agreement Information</Typography>
                </Stack>
              }
            />
            <CardContent sx={{ p: 3, pt: 0 }}>
              <Grid marginTop="24px" container sx={{ rowGap: 3 }}>
                <Grid item container xs={12} flexDirection="column">
                  <DatePicker name="employmentAgreementDate" label={'Agreement Date'} control={control} />
                  <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                    <Tooltip title="Date Format">
                      <Typography variant="labelSmall">MM/DD/YYYY</Typography>
                    </Tooltip>
                  </FormHelperText>
                </Grid>

                <Grid item container xs={12} flexDirection="column">
                  <DatePicker name="goLiveDate" label="Go Live Date" control={control} />
                  {formState.errors.goLiveDate ? (
                    <FormHelperText style={{ paddingLeft: '16px' }} error={!!formState.errors.goLiveDate}>
                      {formState.errors.goLiveDate.message}
                    </FormHelperText>
                  ) : (
                    <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                      <Tooltip title="Date Format">
                        <Typography variant="labelSmall">MM/DD/YYYY</Typography>
                      </Tooltip>
                    </FormHelperText>
                  )}
                </Grid>

                {teacherHasManageDetailsAndInvoicingPermissions ? (
                  <>
                    <Grid item container xs={12} flexDirection="column">
                      <Dropdown
                        name="trainingPayTier"
                        label={`${teacherStatusActive ? 'Training Pay*' : 'Training Pay'}`}
                        options={
                          !data
                            ? []
                            : data.tiers.map((t) => {
                                const formOption: FormOption<string> = {
                                  label: `$${t.hourlyRate}`,
                                  value: t.tier,
                                };

                                return formOption;
                              })
                        }
                        control={control}
                        variant="outlined"
                        rules={{
                          required: teacherStatusActive,
                          validate: (val: any) => {
                            if (teacherStatusActive) {
                              return !(val === undefined || val === null);
                            }
                            return true;
                          },
                        }}
                      />
                    </Grid>

                    <Grid item container xs={12} flexDirection="column">
                      <Dropdown
                        name="payTier"
                        label={teacherPayLabel}
                        options={
                          !data
                            ? []
                            : data.tiers.map((t) => {
                                const formOption: FormOption<string> = {
                                  label: `$${t.hourlyRate}`,
                                  value: t.tier,
                                };

                                return formOption;
                              })
                        }
                        control={control}
                        variant="outlined"
                        rules={{
                          required: teacherPayTierRequired,
                          validate: (val) => {
                            if (teacherPayTierRequired) {
                              return !(val === undefined || val === null);
                            }
                            return true;
                          },
                        }}
                      />
                    </Grid>
                  </>
                ) : null}

                <Grid item container xs={12} flexDirection="column">
                  {teacherHasManageDetailsAndInvoicingPermissions ? (
                    <>
                      <DatePicker
                        name="payEffectiveDate"
                        disablePast
                        label={`${teacherStatusActive || !!payTier ? 'Pay Effective Date*' : 'Pay Effective Date'}`}
                        control={control}
                        rules={{
                          required: teacherStatusActive || !!payTier,
                          validate: (val) => {
                            if (formState.dirtyFields.payEffectiveDate || formState.dirtyFields.payTier) {
                              if (!val) {
                                return 'Pay Effective Date required!';
                              }
                              if (val && val < DateTime.now().toISODate()) {
                                return 'Pay Effective Date must be in the future or the current date!';
                              }
                            }

                            return true;
                          },
                        }}
                      />
                      {formState.errors.payEffectiveDate ? null : (
                        <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                          <Tooltip title="Date Format">
                            <Typography variant="labelSmall">MM/DD/YYYY</Typography>
                          </Tooltip>
                        </FormHelperText>
                      )}
                    </>
                  ) : null}
                </Grid>
                <Grid item container xs={12} flexDirection="column">
                  <DatePicker name="agreementEndDate" label="Agreement End Date" control={control} />
                  {formState.errors.agreementEndDate ? (
                    <FormHelperText style={{ paddingLeft: '16px' }} error={!!formState.errors.agreementEndDate}>
                      {formState.errors.agreementEndDate.message}
                    </FormHelperText>
                  ) : (
                    <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                      <Tooltip title="Date Format">
                        <Typography variant="labelSmall">MM/DD/YYYY</Typography>
                      </Tooltip>
                    </FormHelperText>
                  )}
                </Grid>

                {agreementEndDate ? (
                  <Grid item container xs={12} flexDirection="column">
                    <Dropdown
                      name="agreementEndReason"
                      variant="outlined"
                      label="Agreement End Reason*"
                      options={agreementEndReasonOptions}
                      control={control}
                      rules={{ required: true }}
                    />
                  </Grid>
                ) : null}

                {teacherStageOnLeave ? (
                  <Grid item container xs={12} flexDirection="column">
                    <DatePicker
                      name="leaveEffectiveDate"
                      label="Leave Effective Date*"
                      control={control}
                      rules={{
                        required: 'Leave Return Date is required!',
                      }}
                    />
                    {formState.errors.leaveEffectiveDate ? null : (
                      <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                        <Tooltip title="Date Format">
                          <Typography variant="labelSmall">MM/DD/YYYY</Typography>
                        </Tooltip>
                      </FormHelperText>
                    )}
                  </Grid>
                ) : null}

                {teacherStageOnLeave ? (
                  <Grid item container xs={12} flexDirection="column">
                    <DatePicker
                      name="leaveReturnDate"
                      label="Leave Return Date*"
                      control={control}
                      disabled={!leaveEffectiveDate}
                      rules={{
                        required: 'Leave Return Date is required!',
                        validate: (val) => {
                          if (val && leaveEffectiveDate && leaveEffectiveDate < val) {
                            return true;
                          }
                          return 'Leave Return Date must be after the Leave Effective Date!';
                        },
                      }}
                    />
                    {formState.errors.leaveReturnDate ? null : (
                      <FormHelperText sx={{ paddingLeft: 2, color: '#1C1B1F' }}>
                        <Tooltip title="Date Format">
                          <Typography variant="labelSmall">MM/DD/YYYY</Typography>
                        </Tooltip>
                      </FormHelperText>
                    )}
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default TeacherContractDatesCard;
