import { StudentAttendanceRate } from '@hoot-reading/hoot-core/dist/enums/hfs/student-attendance-rate.enum';
import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { FocusChallenges } from '@hoot-reading/hoot-core/dist/enums/user/student/focus-challenges.enum';
import { NotConvertedReason } from '@hoot-reading/hoot-core/dist/enums/user/student/not-converted-reason.enum';
import { PausedReason } from '@hoot-reading/hoot-core/dist/enums/user/student/paused-reason.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { StudentType } from '@hoot-reading/hoot-core/dist/enums/user/student/student-type.enum';
import { FormOption } from './form';
import { UserProfileType } from './user';

export interface Profile {
  id: string;
  name: string;
  parentName: string;
  email: string;
  type: UserProfileType;
}

export const studentProfileNotConvertedReason: FormOption<NotConvertedReason>[] = [
  { value: NotConvertedReason.TooOld, label: 'Too Old' },
  {
    value: NotConvertedReason.TooYoung,
    label: 'Too Young',
  },
  { value: NotConvertedReason.Payment, label: 'Payment' },
  { value: NotConvertedReason.ProgramContent, label: 'Program Content' },
  { value: NotConvertedReason.ChildTooBusy, label: 'Child too Busy' },
  { value: NotConvertedReason.LessonScheduling, label: 'Lesson Scheduling' },
  { value: NotConvertedReason.LessonExperience, label: 'Lesson Experience' },
  { value: NotConvertedReason.NoResponse, label: 'No Response' },
  { value: NotConvertedReason.Privacy, label: 'Privacy' },
  { value: NotConvertedReason.Other, label: 'Other' },
];
studentProfileNotConvertedReason.sort((a, b) => a.value.localeCompare(b.value));

export enum StudentProfileInactiveReason {
  TOO_YOUNG = 'TOO_YOUNG',
  PAYMENT = 'PAYMENT',
  PROGRAM_CONTENT = 'PROGRAM_CONTENT',
  CHILD_TOO_BUSY = 'CHILD_TOO_BUSY',
  LESSON_EXPERIENCE = 'LESSON_EXPERIENCE',
  NO_RESPONSE = 'NO_RESPONSE',
  PROGRAM_EXPIRATION = 'PROGRAM_EXPIRATION',
  GRADUATED = 'GRADUATED',
  LESSON_SCHEDULING = 'LESSON_SCHEDULING',
  PRIVACY = 'PRIVACY',
  TOO_OLD = 'TOO_OLD',
  OTHER = 'OTHER',
  LOW_ATTENDANCE = 'LOW_ATTENDANCE',
  NOT_RIGHT_FIT = 'NOT_RIGHT_FIT',
  MOVED_SCHOOLS = 'MOVED_SCHOOLS',
  FUNDING_PERIOD = 'FUNDING_PERIOD',
}

export const studentProfileInactiveReason: Record<StudentProfileInactiveReason, string> = {
  [StudentProfileInactiveReason.CHILD_TOO_BUSY]: 'Child Too Busy',
  [StudentProfileInactiveReason.GRADUATED]: 'Graduated',
  [StudentProfileInactiveReason.LESSON_EXPERIENCE]: 'Lesson Experience',
  [StudentProfileInactiveReason.LESSON_SCHEDULING]: 'Lesson Scheduling',
  [StudentProfileInactiveReason.NO_RESPONSE]: 'No Response',
  [StudentProfileInactiveReason.OTHER]: 'Other',
  [StudentProfileInactiveReason.PAYMENT]: 'Payment',
  [StudentProfileInactiveReason.PRIVACY]: 'Privacy',
  [StudentProfileInactiveReason.PROGRAM_CONTENT]: 'Program Content',
  [StudentProfileInactiveReason.PROGRAM_EXPIRATION]: 'Program Expiration',
  [StudentProfileInactiveReason.TOO_OLD]: 'Too Old',
  [StudentProfileInactiveReason.TOO_YOUNG]: 'Too Young',
  [StudentProfileInactiveReason.LOW_ATTENDANCE]: 'Low Attendance',
  [StudentProfileInactiveReason.NOT_RIGHT_FIT]: 'Not Right Fit',
  [StudentProfileInactiveReason.MOVED_SCHOOLS]: 'Moved Schools',
  [StudentProfileInactiveReason.FUNDING_PERIOD]: 'Funding Period',
};

export const studentProfileInactiveReasonOptions: FormOption<StudentProfileInactiveReason>[] = Object.entries(studentProfileInactiveReason).map(
  ([value, label]) => ({
    value: value as StudentProfileInactiveReason,
    label,
  }),
);

export const studentProfileFocusChallenges: FormOption<FocusChallenges>[] = [
  { value: FocusChallenges.Yes, label: 'Yes' },
  {
    value: FocusChallenges.No,
    label: 'No',
  },
];

export const studentProfileFocusChallengesWithNull: FormOption<FocusChallenges | 'None'>[] = [
  { value: 'None', label: 'None' },
  ...studentProfileFocusChallenges,
];

export const studentAttendance: FormOption<StudentAttendanceRate>[] = [
  { value: StudentAttendanceRate.LessThan10, label: '< 10%' },
  { value: StudentAttendanceRate.Between10And20, label: '10 - 20%' },
  { value: StudentAttendanceRate.Between20And30, label: '20 - 30%' },
  { value: StudentAttendanceRate.Between30And40, label: '30 - 40%' },
  { value: StudentAttendanceRate.Between40And50, label: '40 - 50%' },
  { value: StudentAttendanceRate.Between50And60, label: '50 - 60%' },
  { value: StudentAttendanceRate.Between60And70, label: '60 - 70%' },
  { value: StudentAttendanceRate.Between70And80, label: '70 - 80%' },
  { value: StudentAttendanceRate.Between80And90, label: '80 - 90%' },
  { value: StudentAttendanceRate.GreaterThan90, label: '> 90%' },
];

export const studentAttendanceRateMapping: Record<StudentAttendanceRate, string> = {
  [StudentAttendanceRate.LessThan10]: '< 10%',
  [StudentAttendanceRate.Between10And20]: '10 - 20%',
  [StudentAttendanceRate.Between20And30]: '20 - 30%',
  [StudentAttendanceRate.Between30And40]: '30 - 40%',
  [StudentAttendanceRate.Between40And50]: '40 - 50%',
  [StudentAttendanceRate.Between50And60]: '50 - 60%',
  [StudentAttendanceRate.Between60And70]: '60 - 70%',
  [StudentAttendanceRate.Between70And80]: '70 - 80%',
  [StudentAttendanceRate.Between80And90]: '80 - 90%',
  [StudentAttendanceRate.GreaterThan90]: '> 90%',
};

export const studentProfileStatus: FormOption<StudentStatus>[] = [
  { value: StudentStatus.Active, label: 'Active' },
  { value: StudentStatus.Cold, label: 'Cold' },
  { value: StudentStatus.Inactive, label: 'Inactive' },
  { value: StudentStatus.Lead, label: 'Lead' },
  { value: StudentStatus.Paused, label: 'Paused' },
];

export const studentProfileStatusMapping: Record<StudentStatus, string> = {
  [StudentStatus.Active]: 'Active',
  [StudentStatus.Cold]: 'Cold',
  [StudentStatus.Inactive]: 'Inactive',
  [StudentStatus.Lead]: 'Lead',
  [StudentStatus.Paused]: 'Paused',
};

export type StudentFutureField = StudentStatus.Inactive | StudentStatus.Paused;

export const studentProfileFutureStatus: FormOption<StudentFutureField>[] = [
  { value: StudentStatus.Paused, label: 'Paused' },
  { value: StudentStatus.Inactive, label: 'Inactive' },
];

export const studentGradeOptions: FormOption<StudentGrade>[] = [
  { value: StudentGrade.PreKindergarten, label: 'Pre-Kindergarten' },
  { value: StudentGrade.Kindergarten, label: 'Kindergarten' },
  { value: StudentGrade.Grade1, label: 'Grade 1' },
  { value: StudentGrade.Grade2, label: 'Grade 2' },
  { value: StudentGrade.Grade3, label: 'Grade 3' },
  { value: StudentGrade.Grade4, label: 'Grade 4' },
  { value: StudentGrade.Grade5, label: 'Grade 5' },
  { value: StudentGrade.Grade6, label: 'Grade 6' },
  { value: StudentGrade.Grade7, label: 'Grade 7' },
  { value: StudentGrade.Grade8, label: 'Grade 8' },
  { value: StudentGrade.Grade9, label: 'Grade 9' },
  { value: StudentGrade.Grade10, label: 'Grade 10' },
  { value: StudentGrade.Grade11, label: 'Grade 11' },
  { value: StudentGrade.Grade12, label: 'Grade 12' },
];

export const studentGradeMapping: Record<StudentGrade, string> = {
  [StudentGrade.PreKindergarten]: 'Pre-Kindergarten',
  [StudentGrade.Kindergarten]: 'Kindergarten',
  [StudentGrade.Grade1]: 'Grade 1',
  [StudentGrade.Grade2]: 'Grade 2',
  [StudentGrade.Grade3]: 'Grade 3',
  [StudentGrade.Grade4]: 'Grade 4',
  [StudentGrade.Grade5]: 'Grade 5',
  [StudentGrade.Grade6]: 'Grade 6',
  [StudentGrade.Grade7]: 'Grade 7',
  [StudentGrade.Grade8]: 'Grade 8',
  [StudentGrade.Grade9]: 'Grade 9',
  [StudentGrade.Grade10]: 'Grade 10',
  [StudentGrade.Grade11]: 'Grade 11',
  [StudentGrade.Grade12]: 'Grade 12',
};

export const studentGradeOptionsWithNull: FormOption<StudentGrade | 'None'>[] = [{ value: 'None', label: 'None' }, ...studentGradeOptions];

export const studentProfileStatusType = studentProfileStatus.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in StudentStatus]: string };

export type FAndPReadingLevel =
  | 'PRE-A'
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z'
  | 'Z+';

export type GBPlusReadingLevel =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'
  | '30'
  | '30+';

export type MembershipOption =
  | 'HOOT_READING_1X_20'
  | 'HOOT_READING_2X_20'
  | 'HOOT_READING_3X_20'
  | 'HOOT_READING_4X_20'
  | 'HOOT_READING_1X_40'
  | 'HOOT_READING_2X_40'
  | 'HOOT_READING_3X_40'
  | 'HOOT_READING_4X_40'
  | 'HOOT_READING_ON_DEMAND';

export const membershipOptionsList: FormOption<MembershipOption>[] = [
  { value: 'HOOT_READING_1X_20', label: 'Hoot Reading 1x, 20' },
  { value: 'HOOT_READING_2X_20', label: 'Hoot Reading 2x, 20' },
  { value: 'HOOT_READING_3X_20', label: 'Hoot Reading 3x, 20' },
  { value: 'HOOT_READING_4X_20', label: 'Hoot Reading 4x, 20' },
  { value: 'HOOT_READING_1X_40', label: 'Hoot Reading 1x, 40' },
  { value: 'HOOT_READING_2X_40', label: 'Hoot Reading 2x, 40' },
  { value: 'HOOT_READING_3X_40', label: 'Hoot Reading 3x, 40' },
  { value: 'HOOT_READING_4X_40', label: 'Hoot Reading 4x, 40' },
  { value: 'HOOT_READING_ON_DEMAND', label: 'Hoot Reading On Demand' },
];
membershipOptionsList.sort((a, b) => a.value.localeCompare(b.value));

export const studentMembershipTypes = membershipOptionsList.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in MembershipOption | '']: string };

export const studentTypeList: FormOption<StudentType>[] = [
  { value: StudentType.HootDecodableAccess, label: 'Hoot Decodable Access' },
  { value: StudentType.HootTutoring, label: 'Hoot Tutoring' },
];

export const studentType = studentTypeList.reduce(
  (a, b) => ({
    ...a,
    [b.value]: b.label,
  }),
  {},
) as { [key in StudentType | '']: string };

export const pausedReasonOptions: FormOption<PausedReason>[] = [
  { value: PausedReason.ChildTooBusy, label: 'Child Too Busy' },
  { value: PausedReason.Payment, label: 'Payment' },
  { value: PausedReason.ProgramContent, label: 'Program Content' },
  { value: PausedReason.ProgramExpiration, label: 'Program Expiration' },
  { value: PausedReason.LessonExperience, label: 'Lesson Experience' },
  { value: PausedReason.TeacherScheduling, label: 'Teacher Scheduling' },
  { value: PausedReason.Other, label: 'Other' },
];
pausedReasonOptions.sort((a, b) => a.value.localeCompare(b.value));

export type DecodableReadingLevel =
  | '1.1'
  | '1.2'
  | '1.3'
  | '1.4'
  | '1.5'
  | '1.6'
  | '1.7'
  | '1.8'
  | '1.9'
  | '1.10'
  | '1.11'
  | '1.12'
  | '1.13'
  | '2.1'
  | '2.2'
  | '2.3'
  | '2.4'
  | '2.5'
  | '2.6'
  | '2.7'
  | '2.8'
  | '3.1'
  | '3.2'
  | '3.3'
  | '3.4'
  | '3.5'
  | '3.6'
  | '3.7'
  | '3.8'
  | '3.9'
  | '3.10'
  | '4.1'
  | '4.2'
  | '4.3'
  | '4.4'
  | '4.5'
  | '4.6'
  | '4.7'
  | '4.8'
  | '4.9'
  | '4.10'
  | '5.1'
  | '5.2'
  | '5.3'
  | '5.4'
  | '5.5'
  | '5.6'
  | '5.7'
  | '6.1'
  | '6.2'
  | '6.3'
  | '6.4'
  | '6.5'
  | '6.6'
  | '6.7'
  | '6.8'
  | '6.9'
  | '6.10'
  | '6.11'
  | '6.12'
  | '6.13'
  | '6.14'
  | '6.15';

export type MathReadingLevel = '1' | '2' | '3' | '4';
