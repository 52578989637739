import { Box, Button, Card, CardContent, CardHeader, Grid2, Stack, Switch, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TextField } from '@hoot/components/form/TextField';
import AvatarIcon from '@hoot/components/icons/AvatarIcon';
import CommentCircle from '@hoot/components/icons/CommentCircle';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { CommentSubmission, HistoryItemResponse } from '@hoot/interfaces/history';

interface Props {
  historyItems: HistoryItemResponse[];
  handleCommentSubmit: (comment: CommentSubmission, cb: () => void) => void;
}

interface AddCommentForm {
  comment: string;
}

const defaultValues: AddCommentForm = {
  comment: '',
};

//TODO: in the future when non-human comments are shown will need to refine enum displays and things like that
const History = (props: { item: HistoryItemResponse; idx: number; showComments: boolean }) => {
  const { item, idx, showComments } = props;

  const isComment = item.isComment;
  const dateAndTimeCreated = DateTime.fromMillis(item.createdAt).toFormat('LLLL d, yyyy h:mm:ss a');

  if (!showComments && isComment) {
    return null;
  }

  const formattedBody = () => {
    const splitByNewline = item.body.split('\n').filter((x) => x !== '');
    return (
      <Stack>
        {splitByNewline.map((text) => (
          <Typography key={text} variant="bodyMedium" sx={{ overflowWrap: 'anywhere' }}>
            {text}
          </Typography>
        ))}
        <Typography variant="bodySmall" color={'#757575'} marginTop="8px">
          {item.createdBy}
        </Typography>
      </Stack>
    );
  };

  return (
    <Grid2 key={idx} container>
      <Grid2 container size={1} justifyContent="center" alignItems={isComment ? 'flex-start' : 'center'}>
        {isComment ? <AvatarIcon /> : <CommentCircle />}
      </Grid2>
      <Grid2
        container
        size={11}
        justifyContent="space-between"
        sx={{
          backgroundColor: isComment ? 'white' : undefined,
          borderRadius: isComment ? '12px' : undefined,
          px: isComment ? '16px' : undefined,
          py: '12px',
          marginBottom: '8px',
        }}
      >
        <Grid2 size={10}>{formattedBody()}</Grid2>
        <Grid2 container size={2} justifyContent="flex-end">
          <Typography variant="bodyMedium">{dateAndTimeCreated}</Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export const CommonHistoryTimeline = (props: Props) => {
  const { historyItems, handleCommentSubmit } = props;
  const [showComments, setShowComments] = useState<boolean>(true);
  const { error } = useAlert();
  const { hootEmployee } = useAuth();

  const { control, handleSubmit, reset } = useForm<AddCommentForm>({
    defaultValues,
  });

  const onSubmit: SubmitHandler<AddCommentForm> = (data) => {
    const formError = !data.comment || data.comment.replace(/\s/g, '').length === 0;
    if (formError) {
      // checking for form error
      error('Cannot submit an empty item!');
    } else {
      if (!hootEmployee) {
        error('Error: Unauthenticated. Please log out and log in again to retry.');
      }
      const payload: CommentSubmission = {
        comment: data.comment,
        createdById: hootEmployee!.id,
      };

      handleCommentSubmit(payload, () => reset());
    }
  };

  return (
    <Card
      sx={{
        width: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ pl: 3, pt: 3, pr: 3, margin: '24px', width: '100%', backgroundColor: '#F4F6F8', borderRadius: '12px' }}>
        <CardHeader
          sx={{ p: 0, pb: 3 }}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography component="h3" variant="titleLarge">
                History Timeline
              </Typography>
              <Stack direction="row" alignItems="center">
                <Typography variant="labelLarge">Show Comments</Typography>
                <Switch sx={{ marginLeft: '8px' }} defaultChecked onChange={(e) => setShowComments(e.target.checked)} />
              </Stack>
            </Stack>
          }
        />
        <CardContent sx={{ p: 0 }}>
          <Grid2 container>
            <Grid2
              sx={{
                background: `${historyItems.length > 0 ? 'linear-gradient(#C1DBF5, #C1DBF5) no-repeat center/3px 100%' : undefined}`,
              }}
              container
              size={1}
              justifyContent="center"
              alignItems="flex-start"
            >
              <AvatarIcon />
            </Grid2>
            <Grid2
              container
              size={11}
              sx={{
                backgroundColor: 'white',
                borderRadius: '12px',
                minHeight: '88px',
                marginBottom: '24px',
              }}
            >
              <Box
                sx={{
                  minHeight: '44px',
                  width: '100%',
                  px: '16px',
                }}
                display="flex"
                alignItems="center"
              >
                <TextField
                  name="comment"
                  control={control}
                  variant="standard"
                  multiline
                  fullWidth
                  minRows={1}
                  placeholder="Leave a comment..."
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
              <Box
                sx={{ height: '44px', width: '100%', px: '16px', borderTop: '1px Solid #CAC4D0' }}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  sx={{ borderRadius: '12px', fontSize: '16px', fontWeight: 500 }}
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                >
                  Post
                </Button>
              </Box>
            </Grid2>
          </Grid2>
          <Stack
            sx={{
              '& > div:not(:last-child) > div:first-of-type': {
                background: 'linear-gradient(#C1DBF5, #C1DBF5) no-repeat top/3px 100%',
              },
              '& > div:last-child > div:first-of-type': {
                background: 'linear-gradient(#C1DBF5, #C1DBF5) no-repeat top/3px 50%', // Adjust the height to stop at the last circle
              },
            }}
          >
            {historyItems && historyItems.length > 0
              ? historyItems.map((item, idx) => {
                  return <History key={item.id} item={item} idx={idx} showComments={showComments} />;
                })
              : null}
          </Stack>
        </CardContent>
      </Box>
    </Card>
  );
};
