import { TeacherBlockType } from '@hoot-reading/hoot-core/dist/enums/teacher-block';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import { Close, SkipNext, SkipPrevious } from '@mui/icons-material';
import { Button, Card, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { DateFormats } from '@hoot/common/constants';
import { IFilterSelection } from '@hoot/components/form/searchFilters/FilterSelects';
import SearchFilters from '@hoot/components/form/searchFilters/SearchFilters';
import useTeacherGetBlockUtilization, { TeacherBlockUtilizationQuery } from '../../hooks/api/blocks/useGetBlockUtilization';
import CreateBlockPage from '../users/teachers/create-block-wizard/CreateBlockPage';
import BlocksTable from './BlocksTable';
import { BlockColor, THIRTY_MINUTES_IN_MILLISECONDS, defaultBlocksFilters, timeEndDefault, timeStartDefault } from './block-constants';
import { BlockTimeSlot, IBlocksHeaderProps, IBlocksScheduleProps, IFilterProps, SelectedTimeSlot } from './block-interfaces';

const BlocksHeader = (props: IBlocksHeaderProps) => {
  const { setShowCreateBlockWizard } = props;

  return (
    <Card sx={{ padding: '24px 24px' }}>
      <Stack direction="row" justifyContent={'space-between'}>
        <Typography variant="headlineLarge">Blocks</Typography>
        <Stack direction="row" gap={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              setShowCreateBlockWizard({
                startDate: DateTime.now().plus({ minute: 1 }),
                endDate: DateTime.now().plus({ minute: 31 }),
                teacher: undefined,
              })
            }
          >
            Create
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

const ScheduleCard = (props: IBlocksScheduleProps) => {
  const { showCreateBlockWizard, setShowCreateBlockWizard, setBlocksQuery, teacherData, isLoading } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now().startOf('day'));
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [filterSelections, setFilterSelections] = useState<IFilterSelection[]>(defaultBlocksFilters);
  const [timeSlots, setTimeSlots] = useState<BlockTimeSlot[]>(calculateTimeSlots(defaultBlocksFilters, timeStartDefault));

  function calculateTimeSlots(filterSelections: IFilterSelection[], selectedDate: DateTime) {
    const timeStartFilter = filterSelections.find((f) => f.queryName === 'timeStart');
    const timeEndFilter = filterSelections.find((f) => f.queryName === 'timeEnd');

    // get date info from selectedDate
    const selectedYear = selectedDate.year;
    const selectedMonth = selectedDate.month;
    const selectedDay = selectedDate.day;

    const startTimeInMillis = DateTime.fromFormat(timeStartFilter!.values[0].queryValue!, 'h:mm a')
      .set({ year: selectedYear, month: selectedMonth, day: selectedDay })
      .toMillis();
    const endTimeInMillis = DateTime.fromFormat(timeEndFilter!.values[0].queryValue!, 'h:mm a')
      .set({ year: selectedYear, month: selectedMonth, day: selectedDay })
      .toMillis();

    const slotsStartTime = timeStartFilter!.values[0].isSelected ? startTimeInMillis : selectedDate.toMillis();
    const slotsEndTime = timeEndFilter!.values[0].isSelected ? endTimeInMillis : selectedDate.endOf('day').toMillis();

    const sls: BlockTimeSlot[] = [];
    let currentTime = slotsStartTime;

    while (currentTime < slotsEndTime) {
      sls.push({ start: currentTime, end: currentTime + THIRTY_MINUTES_IN_MILLISECONDS });
      currentTime = currentTime + THIRTY_MINUTES_IN_MILLISECONDS;
    }
    return sls;
  }

  useEffect(() => {
    const newTimeSlots = calculateTimeSlots(filterSelections, selectedDate);
    setTimeSlots(newTimeSlots);
  }, [filterSelections, selectedDate]);

  const handlePrevious = () => {
    const newDate = selectedDate.minus({ day: 1 });
    setSelectedDate(newDate);
    setShowDatePicker(false);
  };

  const handleNext = () => {
    const newDate = selectedDate.plus({ day: 1 });
    setSelectedDate(newDate);
  };

  const handleDateChange = (date: DateTime<boolean> | null) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!showDatePicker) {
      setShowDatePicker(true);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseDatePicker = () => {
    setShowDatePicker(false);
    setAnchorEl(null);
  };

  return (
    <Card sx={{ padding: '24px' }}>
      <Stack gap={3}>
        <Stack direction="row" justifyContent={'space-between'}>
          <Typography variant="headlineLarge">Schedule</Typography>
          <Stack direction="row" alignItems="center" sx={{ background: '#F2F2F2', gap: 3, padding: '12px', borderRadius: '8px' }}>
            <IconButton
              onClick={handlePrevious}
              sx={{
                backgroundColor: '#1976D2',
                '&:hover': {
                  backgroundColor: '#166ABD',
                },
              }}
            >
              <SkipPrevious sx={{ color: 'white' }} />
            </IconButton>
            <Button onClick={handleDateClick}>
              <Typography variant="titleLarge" sx={{ textDecoration: 'underline', color: 'black' }}>
                {selectedDate.toFormat(DateFormats.FULL_MDY)}
              </Typography>
            </Button>
            {showDatePicker ? (
              <DatePicker
                sx={{
                  display: 'none',
                }}
                slotProps={{
                  popper: {
                    anchorEl: anchorEl,
                    placement: 'bottom',
                    sx: { paddingTop: '12px' },
                  },
                }}
                onChange={handleDateChange}
                value={selectedDate}
                open={showDatePicker}
                onClose={handleCloseDatePicker}
              />
            ) : null}
            <IconButton
              onClick={handleNext}
              sx={{
                backgroundColor: '#1976D2',
                '&:hover': {
                  backgroundColor: '#166ABD',
                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                },
              }}
            >
              <SkipNext sx={{ color: 'white' }} />
            </IconButton>
          </Stack>
        </Stack>
        <Filters
          filterSelections={filterSelections}
          setFilterSelections={setFilterSelections}
          selectedDate={selectedDate}
          setBlocksQuery={setBlocksQuery}
        />
        <BlocksTable
          timeSlots={timeSlots}
          selectedDate={selectedDate}
          showCreateBlockWizard={showCreateBlockWizard}
          setShowCreateBlockWizard={setShowCreateBlockWizard}
          teacherData={teacherData}
          isLoading={isLoading}
        />
        <BlockColorLegend />
      </Stack>
    </Card>
  );
};

const BlockColorLegend = () => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Stack direction="row" gap={'4px'} alignItems="center">
        <Box sx={{ width: 20, height: 20, backgroundColor: BlockColor.Regular, borderRadius: '4px' }} />
        <Typography> Regular/On-call</Typography>
      </Stack>
      <Stack direction="row" gap={'4px'} alignItems="center">
        <Box sx={{ width: 20, height: 20, backgroundColor: BlockColor.Cancelled, borderRadius: '4px' }} />
        <Typography>Cancelled</Typography>
      </Stack>
      <Stack direction="row" gap={'4px'} alignItems="center">
        <Box sx={{ width: 20, height: 20, backgroundColor: BlockColor.Interview, borderRadius: '4px' }} />
        <Typography>Interview</Typography>
      </Stack>
    </Stack>
  );
};

const Filters = (props: IFilterProps) => {
  const { filterSelections, setFilterSelections, selectedDate, setBlocksQuery } = props;

  useEffect(() => {
    const queryDate = selectedDate.toFormat('LLLL dd, yyyy');
    const queryTeacherStatuses = filterSelections[0].values.filter((value) => value.isSelected).map((s) => s.queryValue! as TeacherStatus);
    const queryBlocksToday = filterSelections[1].values.find((v) => v.isSelected)?.queryValue === 'true' ? true : false;
    const queryBlockType = filterSelections[2].values.filter((value) => value.isSelected).map((s) => s.queryValue! as TeacherBlockType);
    const queryFromStartTime = filterSelections[3].values[0].isSelected ? filterSelections[3].values[0].queryValue : undefined;
    const queryToStartTime = filterSelections[4].values[0].isSelected ? filterSelections[4]?.values[0].queryValue : undefined;
    const queryShowCancelled = filterSelections[5].values.find((v) => v.isSelected)!.queryValue === 'true' ? true : false;

    const newQuery: TeacherBlockUtilizationQuery = {
      teacherStatuses: !!queryTeacherStatuses.length ? queryTeacherStatuses : undefined,
      blockToday: queryBlocksToday,
      blockType: !!queryBlockType.length ? queryBlockType : undefined,
      showCancelled: queryShowCancelled,
      date: queryDate,
      fromStartTime: queryFromStartTime,
      toStartTime: queryToStartTime,
    };

    setBlocksQuery(newQuery);
  }, [filterSelections, selectedDate, setBlocksQuery]);

  return <SearchFilters filterSelections={filterSelections} setFilterSelections={setFilterSelections} />;
};

const Blocks = () => {
  const defaultQuery: TeacherBlockUtilizationQuery = {
    teacherStatuses: [TeacherStatus.Active],
    blockToday: true,
    showCancelled: false,
    date: timeStartDefault.toFormat('LLLL dd, yyyy'),
    fromStartTime: timeStartDefault.toFormat('h:mm a'),
    toStartTime: timeEndDefault.toFormat('h:mm a'),
  };

  const [showCreateBlockWizard, setShowCreateBlockWizard] = useState<SelectedTimeSlot | undefined>(undefined);
  const [blockQuery, setBlocksQuery] = useState<TeacherBlockUtilizationQuery>(defaultQuery);
  const { data: blockUtilizationData, isFetching } = useTeacherGetBlockUtilization(blockQuery);

  const closeCreateBlockWizard = () => {
    setShowCreateBlockWizard(undefined);
  };

  return (
    <>
      <Stack gap={3} width={'96vw'}>
        <BlocksHeader setShowCreateBlockWizard={setShowCreateBlockWizard} />
        <ScheduleCard
          showCreateBlockWizard={!!showCreateBlockWizard}
          setShowCreateBlockWizard={setShowCreateBlockWizard}
          setBlocksQuery={setBlocksQuery}
          teacherData={blockUtilizationData}
          isLoading={isFetching}
        />
      </Stack>
      <Dialog open={!!showCreateBlockWizard} fullWidth maxWidth={'lg'} onClose={closeCreateBlockWizard}>
        <DialogTitle sx={{ padding: '8px 8px 0px 0px' }}>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton onClick={closeCreateBlockWizard} sx={{ cursor: 'pointer', color: '#1C1B1F', position: 'fixed' }}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <CreateBlockPage
            blockStartTime={showCreateBlockWizard?.startDate}
            blockEndTime={showCreateBlockWizard?.endDate}
            teacher={showCreateBlockWizard?.teacher}
            preSelectedTeacher={!!showCreateBlockWizard?.teacher}
            closeModal={closeCreateBlockWizard}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Blocks;
