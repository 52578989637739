import { CancellationReason, cancellationReasonLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { FormControl, FormHelperText, InputLabel, MenuItem } from '@mui/material';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { Select } from '../SelectField';
import { SelectProps } from './FormSelect';

export function CancellationReasonSelect<T extends FieldValues, TObj extends Path<T>>(
  props: { label?: string } & SelectProps & UseControllerProps<T, TObj>,
) {
  const { size, errorMessage, includeEmpty, includeAll, variant, isFilterDropdown, label, ...rest } = props;
  return (
    <FormControl variant={variant || 'outlined'} fullWidth size={size}>
      <InputLabel id="cancellation-reason-label">{label ? label : 'Cancellation Reason'}</InputLabel>
      <Select {...rest} labelId="cancellation-reason-label" label="Cancellation Reason" fullWidth>
        {includeEmpty ? (
          <MenuItem value="">
            <span>None</span>
          </MenuItem>
        ) : null}

        {includeAll ? (
          <MenuItem value="all">
            <span>All</span>
          </MenuItem>
        ) : null}

        {Object.entries(cancellationReasonLookup)
          .filter((reason) => reason[0] !== CancellationReason.SiteClosure)
          .map((s) => (
            <MenuItem key={s[0]} value={s[0]} disabled={!isFilterDropdown && s[0] === 'NO_ONE_JOINED'}>
              {s[1]}
            </MenuItem>
          ))}
      </Select>
      {errorMessage ? <FormHelperText error={true}>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
}
