import React from 'react';
import { SERVER_TIME_SYNC_PERIOD } from '../common/constants';
import useFetchCurrentTime from '../hooks/api/common/useGetCurrentTime';

interface Props {
  children: React.ReactNode;
}

interface Values {
  getCurrentTime: () => number;
}

const CurrentTimeContext = React.createContext<Values>(undefined!);

export const CurrentTimeProvider = (props: Props) => {
  const { children } = props;
  const [offset, setOffset] = React.useState<number>(0);

  useFetchCurrentTime({
    refetchInterval: SERVER_TIME_SYNC_PERIOD,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onSuccess: (serverTime) => {
      setOffset(Date.now() - serverTime.time);
    },
    onError: () => {},
  });

  const getCurrentTime = () => {
    return Date.now() - offset;
  };

  return <CurrentTimeContext.Provider value={{ getCurrentTime }}>{children}</CurrentTimeContext.Provider>;
};

export const useCurrentTime = () => {
  const context = React.useContext(CurrentTimeContext);

  if (context === undefined) {
    throw new Error('useCurrentTime must be used within a CurrentTimeProvider');
  }

  return context;
};
