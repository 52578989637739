import { StudentType } from '@hoot-reading/hoot-core/dist/enums/user/student/student-type.enum';
import { Close } from '@mui/icons-material';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { studentTypeList } from '@hoot/interfaces/profile';
import { StudentDetailsForm } from '../form';

const StudentTypeModal = (props: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  studentType: StudentType;
  setValue: UseFormSetValue<StudentDetailsForm>;
}) => {
  const { setOpen, open, studentType, setValue } = props;
  const [selectedOption, setSelectedOption] = useState<StudentType>(studentType);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setValue('studentType', selectedOption, { shouldDirty: true });
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper">
      <Stack sx={{ padding: '24px', position: 'relative' }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">Student Type</Typography>
            <IconButton onClick={handleClose} sx={{ cursor: 'pointer', color: '#1C1B1F' }}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Stack sx={{ width: 400 }}></Stack>
          {studentTypeList.map((option) => {
            return (
              <Stack key={option.value}>
                <Stack key={option.value} direction="row" gap={2} alignItems="center">
                  <Checkbox onChange={() => setSelectedOption(option.value)} checked={option.value === selectedOption} />
                  <Typography>{option.label}</Typography>
                </Stack>
              </Stack>
            );
          })}
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button
              sx={{
                fontSize: 14,
                color: '#000',
              }}
              fullWidth
              size="medium"
              color="inherit"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                fontSize: 14,
              }}
              fullWidth
              size="medium"
              variant="contained"
              onClick={handleSave}
            >
              Apply
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default StudentTypeModal;
