import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Button, Checkbox, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DateTime, Info } from 'luxon';
import { useMemo } from 'react';
import StatusChip, { StatusChipIcon, StatusChipIconColor } from '@hoot/components/ui/StatusChip';
import { DayAndTimeLessonGrouping } from '@hoot/pages/lesson-sets/details/teacher-assignment-tab/LessonSetTeacherAssignment';
import { ScheduledLessonSetStatus } from '@hoot/pages/lessons/enums';

interface LessonSetLessonGroupingProps {
  lessonSetStatus: ScheduledLessonSetStatus;
  index: number;
  dayAndTimeLessonGrouping: DayAndTimeLessonGrouping;
  isChecked: boolean;
  onToggleSelectLessonGroup: (index: number) => void;
  onRemoveTeacher?: (index: number) => void;
}

const LessonSetLessonGrouping = (props: LessonSetLessonGroupingProps) => {
  const { lessonSetStatus, dayAndTimeLessonGrouping: group, onToggleSelectLessonGroup, isChecked, index, onRemoveTeacher } = props;

  const timeOfDayLabel = useMemo(() => {
    return DateTime.fromISO(group.isoTimeOfDay).toFormat('h:mm a (ZZZZZ)');
  }, [group.isoTimeOfDay]);

  // Only want to consider SCHEDULED lessons, so we don't edit lessons that have already been completed.
  // https://app.shortcut.com/hoot-reading/story/6833/sets-teacher-assignment-status#activity-7536
  const scheduledLessons = useMemo(() => {
    return group.lessons.filter((x) => x.status === ScheduledLessonStatus.Scheduled);
  }, [group.lessons]);

  // A lesson group is considered "Assigned" if at least one lesson in the group has a teacher.
  const isLessonGroupAssigned = useMemo(() => {
    return scheduledLessons.some((x) => !!x.teacher);
  }, [scheduledLessons]);

  // We can only select this group (to be edited) if the lesson set status is PENDING|RESCHEDULING.
  const isSelectable =
    [ScheduledLessonSetStatus.Pending, ScheduledLessonSetStatus.Rescheduling].includes(lessonSetStatus) && scheduledLessons.length > 0;

  const isRemoveTeacherDisabled =
    [ScheduledLessonSetStatus.Posted, ScheduledLessonSetStatus.Closed].includes(lessonSetStatus) || !isLessonGroupAssigned;

  const _onToggleSelectLessonGroup = () => {
    onToggleSelectLessonGroup(index);
  };

  const _onRemoveTeacher = () => {
    onRemoveTeacher?.(index);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={(theme) => ({
        padding: theme.spacing(1.5, 3, 1.5, 0.5),
      })}
    >
      {/* If a DOW has teacher assignment status = Assigned, then DOW checkbox should be disabled and FALSE*/}
      <Checkbox sx={{ padding: 1.5, marginRight: 0.5 }} onClick={_onToggleSelectLessonGroup} checked={isChecked} disabled={!isSelectable} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
        <Stack flex={1}>
          {/* NOTE: The WeekdayNumbers enum start at 1 (Monday), and Info.weekdays returns an array where index 0 -> Monday. */}
          <Typography variant="bodyLarge">
            {Info.weekdays('long')[group.dayOfWeek - 1]} (x{group.lessons.length})
          </Typography>
          <Typography variant="labelMedium">{timeOfDayLabel}</Typography>

          {scheduledLessons.length === 0 ? (
            // If there are no lessons with lesson status "Scheduled" on a day of the week, hide the assignment status.
            // In its place, show message: "There are no "Scheduled" lessons on this day of the week."
            // See https://app.shortcut.com/hoot-reading/story/6833/sets-teacher-assignment-status
            <Typography variant="labelMedium" sx={{ marginTop: 0.25 }}>
              There are no "Scheduled" lessons on this day and time of the week.
            </Typography>
          ) : lessonSetStatus === ScheduledLessonSetStatus.Pending || lessonSetStatus === ScheduledLessonSetStatus.Rescheduling ? (
            // Only show teacher assignment status if the lesson set status is PENDING or RESCHEDULING.
            // See https://app.shortcut.com/hoot-reading/story/6833/sets-teacher-assignment-status
            <StatusChip
              sx={{ marginTop: '2px' }}
              label={isLessonGroupAssigned ? 'Assigned' : 'Unassigned'}
              statusIcon={StatusChipIcon.FullCircle}
              iconColor={isLessonGroupAssigned ? StatusChipIconColor.Green : StatusChipIconColor.Orange}
            />
          ) : // Else, the Lesson Set status = Scheduled or Cancelled. No status needs to be displayed.
          // See https://app.shortcut.com/hoot-reading/story/6833/sets-teacher-assignment-status
          null}
        </Stack>
        {onRemoveTeacher && (
          <Button variant="outlined" color="error" size="large" disabled={isRemoveTeacherDisabled} onClick={_onRemoveTeacher}>
            Remove Teacher
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default LessonSetLessonGrouping;
