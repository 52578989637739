import { Add, Remove } from '@mui/icons-material';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import { HEADER_HEIGHT } from './constants';
import { Label } from './styled-components';

interface TableHeaderProps {
  daysOfWeek: any[];
  showHide: boolean;
  setShowHide: React.Dispatch<React.SetStateAction<boolean>>;
}

function TableHeader(props: TableHeaderProps) {
  const onShowHideClick = () => props.setShowHide((prev) => !prev);

  return (
    <Box sx={{ position: 'sticky', top: -24, zIndex: 500 }}>
      <Stack
        direction="row"
        height={`${HEADER_HEIGHT}px`}
        sx={{
          background: '#E5E5E5',
          borderRadius: '8px 8px 0px 0px',
        }}
      >
        <Stack
          flex={1}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            background: '#F2F2F2',
            // borderRight: '1px solid #CCC',
            borderTopLeftRadius: '8px',
            boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.30),0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
          }}
        >
          <Tooltip
            title={
              <div>
                Show - displays the empty time slots
                <br />
                Hide - removes the time slots
              </div>
            }
          >
            <Button
              onClick={onShowHideClick}
              variant="contained"
              startIcon={props.showHide ? <Add /> : <Remove />}
              sx={{ height: '40px', borderRadius: '8px' }}
            >
              {props.showHide ? 'Show' : 'Hide'}
            </Button>
          </Tooltip>
        </Stack>
        {props.daysOfWeek.map((dayOfWeek) => (
          <Stack key={dayOfWeek} flex={1} alignItems="center" justifyContent="center" height={`${HEADER_HEIGHT}px`} borderRight="1px solid #CCC">
            <Label>{dayOfWeek.toFormat('EEE')}</Label>
            <Label>{dayOfWeek.toFormat('LLL dd')}</Label>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}

export default TableHeader;
