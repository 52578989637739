import MuiPhoneNumber, { MuiPhoneNumberProps } from 'material-ui-phone-number';
import { FieldValues, Path, UseControllerProps, useController } from 'react-hook-form';
import { AdapterProps } from './common';

export type PhoneInputProps<T extends FieldValues, TObj extends Path<T>> = Omit<MuiPhoneNumberProps, 'onChange'> &
  UseControllerProps<T, TObj> &
  AdapterProps;

export function PhoneNumberInput<T extends FieldValues, TObj extends Path<T>>(props: PhoneInputProps<T, TObj>) {
  const { field, fieldState } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });
  return (
    <MuiPhoneNumber
      onChange={field.onChange}
      value={field.value}
      fullWidth={props.fullWidth}
      variant={props.variant}
      label={props.label}
      excludeCountries={props.excludeCountries}
      onlyCountries={props.onlyCountries}
      preferredCountries={props.preferredCountries}
      defaultCountry={props.defaultCountry}
      inputClass={props.inputClass}
      dropdownClass={props.dropdownClass}
      autoFormat={props.autoFormat}
      disableAreaCodes={props.disableAreaCodes}
      disableCountryCode={props.disableCountryCode}
      disableDropdown={props.disableDropdown}
      enableLongNumbers={props.enableLongNumbers}
      countryCodeEditable={props.countryCodeEditable}
      error={!!fieldState.error}
      helperText={props.helperTextFn?.(fieldState)}
    />
  );
}
