import { SVGProps } from 'react';

const AvatarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1052_31987)">
        <rect x="4" y="4" width="44" height="44" fill="#C1DBF5" />
        <path
          d="M25.5 28.5C23.2312 28.5 21.2891 27.6922 19.6734 26.0766C18.0578 24.4609 17.25 22.5188 17.25 20.25C17.25 17.9812 18.0578 16.0391 19.6734 14.4234C21.2891 12.8078 23.2312 12 25.5 12C27.7687 12 29.7109 12.8078 31.3266 14.4234C32.9422 16.0391 33.75 17.9812 33.75 20.25C33.75 22.5188 32.9422 24.4609 31.3266 26.0766C29.7109 27.6922 27.7687 28.5 25.5 28.5ZM9 48.4375V39.225C9 38.0563 9.30113 36.9817 9.90338 36.0013C10.5043 35.0223 11.3031 34.275 12.3 33.7594C14.4312 32.6938 16.5969 31.8942 18.7969 31.3607C20.9969 30.8286 23.2312 30.5625 25.5 30.5625C27.7687 30.5625 30.0031 30.8286 32.2031 31.3607C34.4031 31.8942 36.5687 32.6938 38.7 33.7594C39.6969 34.275 40.4958 35.0223 41.0966 36.0013C41.6989 36.9817 42 38.0563 42 39.225V48.4375H9Z"
          fill="#1976D2"
        />
      </g>
      <rect x="2" y="2" width="48" height="48" rx="14" stroke="#DBEAF9" strokeWidth="4" />
      <defs>
        <clipPath id="clip0_1052_31987">
          <rect x="4" y="4" width="44" height="44" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AvatarIcon;
