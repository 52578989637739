import { scheduledLessonTypeLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { TeacherShiftStatus, TeacherShiftType, teacherShiftCancellationLookup } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import { Close, Lock } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { DateTime, Interval } from 'luxon';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ConnectForm } from '@hoot/components/form/ConnectForm';
import { DatePicker, TimePicker3 } from '@hoot/components/form/DatePicker';
import { Dropdown } from '@hoot/components/form/Dropdown';
import { DropdownButtonProps } from '@hoot/components/form/DropdownButton';
import { LessonStatusChip } from '@hoot/components/form/chips/LessonStatusChip';
import Clipboard from '@hoot/components/ui/Clipboard';
import { HeaderData, Table } from '@hoot/components/ui/Table';
import { useAlert } from '@hoot/contexts/AlertContext';
import { useBlockIfDirty } from '@hoot/contexts/BlockIfDirtyContext';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import useDeleteTeacherShift from '@hoot/hooks/api/shifts/useDeleteTeacherShift';
import useGetTeacherShift from '@hoot/hooks/api/shifts/useGetTeacherShift';
import useGetTeacherShiftLessons, { TeacherShiftLessonsQuery, teacherShiftLessonsQueryKey } from '@hoot/hooks/api/shifts/useGetTeacherShiftLessons';
import useUpdateTeacherShift, { UpdateTeacherShiftRequest } from '@hoot/hooks/api/shifts/useUpdateTeacherShift';
import { SortOrder, toggleSortOrder } from '@hoot/interfaces/order-by';
import { OrderBy } from '@hoot/pages/lessons/enums';
import { routes } from '@hoot/routes/routes';
import { teacherShiftTypeOptions } from '../create-shift-wizard/context/CreateShiftContextProvider';
import { MenuItemId, TeacherShiftForm, TeacherShiftHeaderCard } from './TeacherShiftHeaderCard';
import { RescheduleShiftsDialog } from './reschedule-shifts/RescheduleShiftsDialog';

interface LessonsTableColumn {
  id: string;
  number: ReactNode;
  type: string;
  time: string;
  duration: string;
  status: ReactNode;
}

export enum ApplyShiftChangeOption {
  UpdateThisShift = 'UPDATE_THIS_SHIFT',
  UpdateFutureRecurringShifts = 'UPDATE_FUTURE_RECURRING_SHIFTS',
}

export enum ShiftLessonOrderBy {
  Number = 'number',
  Type = 'type',
  Time = 'time',
  Duration = 'duration',
  Status = 'status',
}

const tableHeaders: HeaderData<LessonsTableColumn>[] = [
  { name: 'ID', property: 'id', isHidden: true },
  { name: 'Lesson ID', property: 'number', sortKey: ShiftLessonOrderBy.Number },
  { name: 'Type', property: 'type', sortKey: ShiftLessonOrderBy.Type },
  { name: 'Time', property: 'time', sortKey: ShiftLessonOrderBy.Time },
  { name: 'Duration', property: 'duration', sortKey: ShiftLessonOrderBy.Duration },
  { name: 'Status', property: 'status', sortKey: ShiftLessonOrderBy.Status },
];

const TeacherField = (props: { form: TeacherShiftForm }) => {
  const { form } = props;

  return (
    <Box
      sx={{
        borderRadius: '4px',
        padding: '8px 16px',
        background: '#FAFAFA',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography sx={{ fontSize: '14px' }}>Teacher</Typography>
          <Stack direction="row" sx={{ fontSize: '16px' }}>
            <Link to={routes.users.teachers.details.url(form.teacherId)}>{form.teacherNumber}</Link>
            <Clipboard toCopy={form.teacherNumber} iconProps={{ fontSize: 'inherit' }} sx={{ fontSize: 14, height: 20 }} />
          </Stack>
        </Stack>
        <Stack width={48} height={48} justifyContent="center" alignItems="center">
          <Lock />
        </Stack>
      </Stack>
    </Box>
  );
};

const DetailsCard = (props: { form: TeacherShiftForm }) => {
  const { form } = props;

  const statusOptions = () => {
    const now = DateTime.now();
    const startDateTime = DateTime.fromMillis(form.startTime);
    const betweenNowAndStartTime = Interval.fromDateTimes(now, startDateTime);

    const cancelledOption = { value: TeacherShiftStatus.Cancelled, label: 'Cancelled' };
    const scheduledOption = { value: TeacherShiftStatus.Scheduled, label: 'Scheduled' };
    const publishedOption = { value: TeacherShiftStatus.Published, label: 'Published' };
    const completedOption = { value: TeacherShiftStatus.Completed, label: 'Completed' };

    const allowedOptions = [cancelledOption]; // Always show the `Cancelled` option.

    if (form.endTime <= now.toMillis()) {
      allowedOptions.push(completedOption); // Considered `Complete` if the current time has passed the endTime.
    } else if (betweenNowAndStartTime.length('days') > 7) {
      allowedOptions.push(scheduledOption); // Considered `Scheduled` if the Shift occurs in more than 7 days.
    } else {
      allowedOptions.push(publishedOption);
    }

    return allowedOptions;
  };

  const cancellationReasonOptions = Object.entries(teacherShiftCancellationLookup).map((c) => ({
    value: c[0],
    label: c[1],
  }));

  return (
    <ConnectForm<TeacherShiftForm>>
      {({ control }) => {
        return (
          <Card>
            <CardContent>
              <Typography variant="titleLarge">Shift Details</Typography>
              <Stack sx={{ marginTop: '24px' }} gap={3}>
                <TeacherField form={form} />
                <Dropdown name="status" required label="Shift Status" options={statusOptions()} control={control} variant="outlined" />
                {form.status === TeacherShiftStatus.Cancelled ? (
                  <Dropdown
                    name="cancellationReason"
                    required
                    label="Cancellation Reason"
                    options={cancellationReasonOptions}
                    control={control}
                    variant="outlined"
                    rules={{
                      required: true,
                    }}
                  />
                ) : null}
                <Dropdown
                  name="type"
                  required
                  label="Shift Type"
                  options={teacherShiftTypeOptions}
                  control={control}
                  disabled={form.status === TeacherShiftStatus.Cancelled || form.status === TeacherShiftStatus.Completed}
                  variant="outlined"
                />
              </Stack>
            </CardContent>
          </Card>
        );
      }}
    </ConnectForm>
  );
};

const ScheduleCard = (props: { form: TeacherShiftForm; lastSavedStartTime: number; lastSavedEndTime: number }) => {
  const { form, lastSavedStartTime, lastSavedEndTime } = props;

  const disableDate = form.status === TeacherShiftStatus.Cancelled || form.status === TeacherShiftStatus.Completed;
  const disableStartTime = lastSavedStartTime <= DateTime.now().toMillis() || disableDate;
  const disableEndTime = lastSavedEndTime <= DateTime.now().toMillis() || disableDate;

  return (
    <ConnectForm<TeacherShiftForm>>
      {({ control }) => {
        return (
          <Card>
            <CardContent>
              <Typography variant="titleLarge">Shift Schedule</Typography>
              <Stack sx={{ marginTop: '24px' }} gap={3} direction="row">
                <Box sx={{ flex: 1 }}>
                  <DatePicker name="date" label="Date" treatDateAsNumber control={control} sx={{ width: '100%' }} disabled={disableDate} />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <TimePicker3
                    sx={{ width: '100%', height: '100%' }}
                    ampm
                    name="startTime"
                    label="Start Time"
                    control={control}
                    rules={{
                      required: true,
                      validate: (val) => {
                        if (val && val > form.endTime) {
                          return 'Start time must be before end time';
                        }
                        return true;
                      },
                    }}
                    disabled={disableStartTime}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <TimePicker3
                    sx={{ width: '100%', height: '100%' }}
                    ampm
                    name="endTime"
                    label="End Time"
                    control={control}
                    rules={{
                      required: true,
                      validate: (val) => {
                        if (val && val < form.startTime) {
                          return 'Start time must be before end time';
                        }
                        return true;
                      },
                    }}
                    disabled={disableEndTime}
                  />
                </Box>
              </Stack>
            </CardContent>
          </Card>
        );
      }}
    </ConnectForm>
  );
};

const LessonsCard = (props: {
  lessonsQuery: TeacherShiftLessonsQuery;
  setLessonsQuery: React.Dispatch<React.SetStateAction<TeacherShiftLessonsQuery>>;
}) => {
  const { lessonsQuery, setLessonsQuery } = props;
  const lessonsRequest = useGetTeacherShiftLessons(lessonsQuery);

  const handleSortBy = (selectedColumn: keyof LessonsTableColumn) => {
    function sortKey(): ShiftLessonOrderBy {
      switch (selectedColumn) {
        case 'number':
          return ShiftLessonOrderBy.Number;
        case 'type':
          return ShiftLessonOrderBy.Type;
        case 'time':
          return ShiftLessonOrderBy.Time;
        case 'duration':
          return ShiftLessonOrderBy.Duration;
        case 'status':
          return ShiftLessonOrderBy.Status;
        default:
          return ShiftLessonOrderBy.Number;
      }
    }
    const sortColumn = sortKey();
    setLessonsQuery((q) => ({ ...q, sortColumn: sortColumn, sortDirection: toggleSortOrder(q.sortDirection) }));
  };

  const data: LessonsTableColumn[] =
    lessonsRequest.data?.lessons.map((l) => ({
      id: l.id,
      number: <Link to={routes.lessons.details.url(l.id)}>{l.preFixedNumber}</Link>,
      type: scheduledLessonTypeLookup[l.type],
      time: DateTime.fromMillis(l.startsAt).toLocaleString(DateTime.TIME_SIMPLE),
      duration: `${l.duration} Minutes`,
      status: <LessonStatusChip status={l.status} />,
    })) ?? [];

  return (
    <Card>
      <CardContent>
        <Typography variant="titleLarge">Lessons</Typography>
        <Box sx={{ marginTop: '24px' }}>
          <Table
            data={data}
            headers={tableHeaders}
            isSortable
            isLoading={lessonsRequest.isFetching}
            sortOrder={lessonsQuery.sortDirection === SortOrder.ASC ? OrderBy.Asc : OrderBy.Desc}
            sortBy={lessonsQuery.sortColumn}
            onSortBy={handleSortBy}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

const TeacherShiftDetailsPage = () => {
  const { shiftId, teacherAccountId } = useParams() as { shiftId: string; teacherAccountId: string };
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const { setIsDirty } = useBlockIfDirty();
  const { success, error } = useAlert();
  const startTimeRef = useRef<number>(0);
  const endTimeRef = useRef<number>(0);
  const [showApplyChanges, setShowApplyChanges] = useState(false);
  const [showRescheduleWizard, setShowRescheduleWizard] = useState(false);

  const [shiftLessonsQuery, setShiftLessonsQuery] = useState<TeacherShiftLessonsQuery>({
    shiftId: shiftId ?? '',
    sortColumn: ShiftLessonOrderBy.Time,
    sortDirection: SortOrder.ASC,
  });

  const shiftForm = useForm<TeacherShiftForm>({
    mode: 'onChange',
    defaultValues: {
      number: '',
      teacherId: '',
      teacherNumber: '',
      status: TeacherShiftStatus.Scheduled,
      type: TeacherShiftType.Regular,
      date: DateTime.now().toMillis(),
      startTime: DateTime.now().toMillis(),
      endTime: DateTime.now().toMillis(),
    },
  });

  useEffect(() => {
    setFormIsDirty(shiftForm.formState.isDirty);
    setIsDirty(shiftForm.formState.isDirty);
  }, [shiftForm.formState, setIsDirty, setFormIsDirty]);

  const formValues = shiftForm.watch();

  const getShiftRequest = useGetTeacherShift(shiftId ?? '', {
    enabled: shiftId !== undefined,
    retry: false,
    onSuccess: (data) => {
      startTimeRef.current = data.startsAt;
      endTimeRef.current = data.endsAt;
      shiftForm.reset({
        number: data.prefixedNumber,
        teacherId: data.teacherId,
        teacherNumber: data.prefixedTeacherNumber,
        status: data.status,
        cancellationReason: data.cancellationReason ?? undefined,
        type: data.type,
        date: data.startsAt,
        startTime: data.startsAt,
        endTime: data.endsAt,
      });
    },
    onError: (err) => {
      console.error(err);
      error('Sorry. An error occurred while loading shift details.');
    },
  });

  const updateShiftRequest = useUpdateTeacherShift(shiftId ?? '');

  const deleteShiftRequest = useDeleteTeacherShift(shiftId ?? '');

  const handleSaveShift = (formData: TeacherShiftForm) => {
    // apply value from date field to start and end times
    const shiftDate = DateTime.fromMillis(formData.date);
    const startTime = DateTime.fromMillis(formData.startTime);
    const endTime = DateTime.fromMillis(formData.endTime);

    const adjustedStartTime = shiftDate.set({ hour: startTime.hour, minute: startTime.minute }).startOf('minute');
    const adjustedEndTime = shiftDate.set({ hour: endTime.hour, minute: endTime.minute }).startOf('minute');

    const request: UpdateTeacherShiftRequest = {
      startsAt: adjustedStartTime.toMillis(),
      endsAt: adjustedEndTime.toMillis(),
      type: formData.type,
    };

    if (formData.status === TeacherShiftStatus.Cancelled && formData.cancellationReason) {
      // add cancellation reason - this will tell the API to apply a cancelledAt
      request.cancellationReason = formData.cancellationReason;
    }

    updateShiftRequest.mutate(request, {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.GetTeacherShift, shiftId]);
        queryClient.invalidateQueries([QueryKey.GetTeacherShiftLessons, teacherShiftLessonsQueryKey(shiftLessonsQuery)]);
        success('Shift has been updated.');
      },
      onError: (err) => {
        console.error(err);
        if (err?.response?.status === 409) {
          error(`The changes made conflict with another shift for ${formValues.teacherNumber}`);
        } else {
          error('An error occurred while updating the shift.');
        }
      },
    });
  };

  const handleDeleteShift = () => {
    deleteShiftRequest.mutate(undefined, {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.GetTeacherShift, shiftId]);
        success('Shift has been deleted.');
        navigate(routes.users.teachers.details.url(formValues.teacherId));
      },
      onError: (err) => {
        console.error(err);
        error('An error occurred while deleting the shift.');
      },
    });
  };

  const isLoading = getShiftRequest.isLoading;
  const isSavingChanges = updateShiftRequest.isLoading;

  const onMenuItemClicked: DropdownButtonProps['onMenuItemClicked'] = (menuItemId) => {
    switch (menuItemId) {
      case MenuItemId.DeleteShift:
        handleDeleteShift();
        break;
    }
  };

  const handleSave = async () => {
    // Re-validate the start and end times.
    await shiftForm.trigger('startTime');
    await shiftForm.trigger('endTime');

    // If there are form errors, then bail.
    if (Object.values(shiftForm.formState.errors).length) {
      console.error('Form errors', shiftForm.formState.errors);
      // Just grab the first error and show the message to the user.
      const firstErrorMessage = Object.values(shiftForm.formState.errors)?.[0]?.message;
      error(firstErrorMessage ?? 'There are errors in form.');
      return;
    }
    // if it's not a recurring shift, we can just save the shift, otherwise show the dialog
    if (!getShiftRequest.data?.teacherRecurringShiftId) {
      shiftForm.handleSubmit((d) => handleSaveShift(d))();
    } else {
      setShowApplyChanges(true);
    }
  };

  const handleApplyChangeModalDismiss = () => {
    setShowApplyChanges(false);
  };

  const handleApplyChangeModalApply = (option: ApplyShiftChangeOption) => {
    setShowApplyChanges(false);
    switch (option) {
      case ApplyShiftChangeOption.UpdateThisShift:
        shiftForm.handleSubmit((d) => handleSaveShift(d))();
        break;
      case ApplyShiftChangeOption.UpdateFutureRecurringShifts:
        setShowRescheduleWizard(true);
        break;
    }
  };

  const handleRescheduleShiftApply = () => {
    setShowRescheduleWizard(false);
  };

  const handleRescheduleShiftDismiss = () => {
    setShowRescheduleWizard(false);
  };

  const showWarning =
    formValues.status === TeacherShiftStatus.Cancelled &&
    (formValues.startTime !== getShiftRequest.data?.startsAt || formValues.endTime !== getShiftRequest.data.endsAt);

  return (
    <Stack gap={3}>
      <TeacherShiftHeaderCard
        form={formValues}
        isLoading={isLoading}
        isSavingChanges={isSavingChanges}
        canSave={formIsDirty}
        handleSave={handleSave}
        shiftNumber={formValues.number}
        teacherAccountId={teacherAccountId}
        shiftId={shiftId}
        onMenuItemClicked={onMenuItemClicked}
      />
      <FormProvider {...shiftForm}>
        <Grid container item xs={12} spacing={3}>
          <Grid item md={3} sm={12}>
            <Stack gap={3}>
              <DetailsCard form={formValues} />
            </Stack>
          </Grid>
          <Grid item md={9} sm={12}>
            <Stack gap={3}>
              <ScheduleCard form={formValues} lastSavedStartTime={startTimeRef.current} lastSavedEndTime={endTimeRef.current} />
              <LessonsCard lessonsQuery={shiftLessonsQuery} setLessonsQuery={setShiftLessonsQuery} />
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
      <ApplyChangeModal
        open={showApplyChanges}
        onDismiss={handleApplyChangeModalDismiss}
        onApply={handleApplyChangeModalApply}
        showWarning={showWarning}
      />
      <RescheduleShiftsDialog
        key={`RescheduleShiftsDialog-${shiftId}-${showRescheduleWizard}`}
        teacherAccountId={teacherAccountId}
        shiftId={shiftId}
        rescheduledChange={{
          status: formValues.status,
          cancellationReason: formValues.cancellationReason,
          originalStartsAt: DateTime.fromMillis(getShiftRequest?.data?.startsAt ?? 0),
          originalEndsAt: DateTime.fromMillis(getShiftRequest?.data?.endsAt ?? 0),
          updatedStartsAt: DateTime.fromMillis(formValues.startTime),
          updatedEndAt: DateTime.fromMillis(formValues.endTime),
        }}
        open={showRescheduleWizard}
        onApply={handleRescheduleShiftApply}
        onDismiss={handleRescheduleShiftDismiss}
        showWarning={showWarning}
      />
    </Stack>
  );
};

function ApplyChangeModal(props: { open: boolean; onDismiss: () => void; onApply: (option: ApplyShiftChangeOption) => void; showWarning?: boolean }) {
  const [option, setOption] = useState<ApplyShiftChangeOption>(ApplyShiftChangeOption.UpdateThisShift);

  const handleApplyClick = () => {
    props.onApply(option);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOption((event.target as HTMLInputElement).value as ApplyShiftChangeOption);
  };

  return (
    <Dialog key={`update-shift-changes-${props.open}`} maxWidth="sm" fullWidth open={props.open} onClose={props.onDismiss}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="headlineSmall">Apply Changes</Typography>
          <IconButton onClick={props.onDismiss}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        {props.showWarning ? (
          <Stack marginY="16px">
            <Alert severity="warning">
              Note, only the status change will take effect. The data/time will not be updated. To update the date/time, do so separately.
            </Alert>
          </Stack>
        ) : null}

        <FormControl>
          <Typography variant="titleMedium">Apply Changes</Typography>
          <RadioGroup value={option} onChange={handleChange}>
            <FormControlLabel value={ApplyShiftChangeOption.UpdateThisShift} control={<Radio />} label="Update this Shift" />
            <FormControlLabel value={ApplyShiftChangeOption.UpdateFutureRecurringShifts} control={<Radio />} label="Update Future Recurring Shifts" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ p: 3, position: 'relative' }}>
        <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
        <Button size="large" variant="outlined" color="primary" onClick={props.onDismiss}>
          Cancel
        </Button>
        <Button size="large" variant="contained" onClick={handleApplyClick}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TeacherShiftDetailsPage;
