import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface SeriesResponse {
  id: string;
  name: string;
}

async function getSeries(): Promise<SeriesResponse[]> {
  const { data } = await axios.get<SeriesResponse[]>(`${config.apiUrl}/admin/library/series`);
  return data;
}

export default function useGetSeries(options?: Omit<UseQueryOptions<SeriesResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetSeries], () => getSeries(), options);
}
