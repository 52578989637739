import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  GetStudentProgressMonitoringAssessmentsQuery,
  HootProgressMonitoringAssessmentResponse,
  StudentProgressMonitoringAssessmentsQuerySortKeyEnum,
  useGetProgressMonitoringAssessments,
} from '@hoot/hooks/api/student-hoot-assessments/useGetStudentProgressMonitoringAssessments';
import { ProgressMonitoringAssessmentStatus } from '@hoot/interfaces/assessments-enums';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/interfaces/pagination';
import { routes } from '@hoot/routes/routes';
import { enumToTitleCase } from '@hoot/utils/stringUtils';
import { HeaderData, Table } from '../../../../components/ui/Table';
import { SortOrder, toggleSortOrder } from '../../../../interfaces/order-by';
import { OrderBy } from '../../../lessons/enums';
import { StatusColours } from '../../components/user-schedule/weekly-schedule/enums';
import ProgressMonitoringAssessmentDetailsModal from './StudentProgressMonitoringAssessmentsDetailsModal';

interface ProgressMonitoringAssessmentTableRow {
  id: string;
  startDate: string;
  completionDate: string;
  assessedBy: ReactNode;
  status: ReactNode;
  units: ReactNode;
}

const headers: HeaderData<ProgressMonitoringAssessmentTableRow>[] = [
  { name: 'Status', property: 'status', isSortable: true, width: '20%' },
  { name: 'Start Date', property: 'startDate', isSortable: true, width: '20%' },
  { name: 'Completion Date', property: 'completionDate', isSortable: true, width: '20%' },
  { name: 'Assessed By', property: 'assessedBy', isSortable: true, width: '20%' },
  { name: 'Unit(s)', property: 'units', width: '20%' },
];

const StudentProgressMonitoringAssessmentsCard = (props: { studentProfileId: string }) => {
  const { state: navigationState } = useLocation();

  const [selectedProgressMonitoringAssmtId, setSelectedProgressMonitoringAssmtId] = useState<string | undefined>();
  const [query, setQuery] = useState<GetStudentProgressMonitoringAssessmentsQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: StudentProgressMonitoringAssessmentsQuerySortKeyEnum.StartDate,
    sortDirection: SortOrder.DESC,
  });
  const [results, setResults] = useState<GenericPaginatedResponse<ProgressMonitoringAssessmentTableRow>>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    data: [],
    count: 0,
  });

  useGetProgressMonitoringAssessments(props.studentProfileId!, query, {
    enabled: !!props.studentProfileId,
    onSuccess: (response) => {
      setResults({
        ...response,
        data: mapAssessmentResponseToTableRows(response.data),
      });
    },
  });

  // If a PMA Id was provided via navigation options.state,
  // then we automatically open the appropriate PMA details dialog
  useEffect(() => {
    if (!!navigationState?.pmaId && !!results.data.length) {
      const pmaId = results.data.find(({ id }) => id === navigationState.pmaId)?.id;
      if (pmaId) {
        handleRowClick(pmaId);
      }
      // Important to keep - need to clear the navigation state upon 1st render to prevent it from opening the dialog on page reload.
      window.history.replaceState(null, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results.data.length]);

  const handleRowClick = (assessmentId: string) => {
    setSelectedProgressMonitoringAssmtId(assessmentId);
  };

  const handleClose = () => {
    setSelectedProgressMonitoringAssmtId(undefined);
  };

  const assessmentColor = (status: ProgressMonitoringAssessmentStatus): StatusColours => {
    if (status === ProgressMonitoringAssessmentStatus.Abandoned) {
      return StatusColours.Cancelled;
    } else if (status === ProgressMonitoringAssessmentStatus.Completed) {
      return StatusColours.Completed;
    } else {
      return StatusColours.Scheduled;
    }
  };

  const mapAssessmentResponseToTableRows = (
    formativeAssessments: HootProgressMonitoringAssessmentResponse[],
  ): ProgressMonitoringAssessmentTableRow[] => {
    return formativeAssessments.map<ProgressMonitoringAssessmentTableRow>((x) => {
      return {
        id: x.id,
        startDate: DateTime.fromMillis(x.createdAt).toFormat('LLLL dd, yyyy'),
        completionDate: x.completedAt ? DateTime.fromMillis(x.completedAt).toFormat('LLLL dd, yyyy') : '-',
        assessedBy: <Link to={routes.users.teachers.details.url(x.assessedById)}>{x.assessedByPrefixedNumber}</Link>,
        status: (
          <Stack direction="row" alignItems="center" gap={1}>
            <Box sx={{ width: '8px', height: '8px', backgroundColor: assessmentColor(x.status), borderRadius: '50px' }}></Box>
            <Typography variant="bodyMedium">{enumToTitleCase(x.status)}</Typography>
          </Stack>
        ),
        units: (
          <Stack gap={'4px'}>
            {x.units.map((unit, idx) => (
              <Typography key={`${unit}-${idx}`} variant="bodyMedium">
                {unit}
              </Typography>
            ))}
          </Stack>
        ),
      };
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize }));
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleSortBy = (selectedColumn: keyof ProgressMonitoringAssessmentTableRow) => {
    const getQueryOrderByColumn = (): StudentProgressMonitoringAssessmentsQuerySortKeyEnum => {
      switch (selectedColumn) {
        case 'assessedBy':
          return StudentProgressMonitoringAssessmentsQuerySortKeyEnum.AssessedBy;
        case 'startDate':
          return StudentProgressMonitoringAssessmentsQuerySortKeyEnum.StartDate;
        case 'completionDate':
          return StudentProgressMonitoringAssessmentsQuerySortKeyEnum.CompletionDate;
        case 'status':
          return StudentProgressMonitoringAssessmentsQuerySortKeyEnum.Status;
        default:
          return StudentProgressMonitoringAssessmentsQuerySortKeyEnum.StartDate;
      }
    };
    const queryOrderByColumn = getQueryOrderByColumn();
    setQuery((currentState) => ({
      ...currentState,
      orderBy: queryOrderByColumn,
      sortDirection: toggleSortOrder(currentState.sortDirection),
    }));
  };

  function orderByColumn(): keyof ProgressMonitoringAssessmentTableRow {
    switch (query.orderBy) {
      case StudentProgressMonitoringAssessmentsQuerySortKeyEnum.AssessedBy:
        return 'assessedBy';
      case StudentProgressMonitoringAssessmentsQuerySortKeyEnum.StartDate:
        return 'startDate';
      case StudentProgressMonitoringAssessmentsQuerySortKeyEnum.CompletionDate:
        return 'completionDate';
      case StudentProgressMonitoringAssessmentsQuerySortKeyEnum.Status:
        return 'status';
      default:
        return 'startDate';
    }
  }

  if (!results.data) {
    return null;
  }

  return (
    <Card
      sx={{
        width: 1,
      }}
    >
      <CardContent>
        <Stack>
          <Typography variant="titleLarge">Progress Monitoring Assessments</Typography>
          <Box sx={{ marginTop: '24px' }}>
            <Table
              isSortable
              isPaginated
              data={results.data}
              headers={headers}
              onRowsPerPageChange={handleChangeRowsPerPage}
              count={results.count}
              page={query.page}
              onPageChange={handleChangePage}
              onSortBy={handleSortBy}
              sortBy={orderByColumn()}
              sortOrder={query.sortDirection === SortOrder.ASC ? OrderBy.Asc : OrderBy.Desc}
              rowsPerPage={query.pageSize}
              onRowClick={handleRowClick}
            />
          </Box>
        </Stack>
      </CardContent>
      <ProgressMonitoringAssessmentDetailsModal assessmentId={selectedProgressMonitoringAssmtId} onClose={handleClose} />
    </Card>
  );
};

export default StudentProgressMonitoringAssessmentsCard;
