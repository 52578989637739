import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { Stack, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { Link, createSearchParams, useSearchParams } from 'react-router-dom';
import { enrolmentStatusMapping } from '@hoot/components/hfs-utils';
import StatusChip, { StatusChipIcon, StatusChipIconColor } from '@hoot/components/ui/StatusChip';
import { useAuth } from '@hoot/contexts/Auth/AuthContext';
import { HootEmployeeScope } from '@hoot/contexts/Auth/enums/hoot-employee.scope';
import {
  GetLocationStudentsQuery,
  GetLocationStudentsQuerySortKeys,
  LocationStudentSearchResult,
} from '@hoot/hooks/api/hfs/district-schools/students/useGetLocationStudents';
import { SortOrder, TableHeaderCell, muiTableSortDirectionLookup } from '@hoot/interfaces/order-by';
import { studentProfileStatusType } from '@hoot/interfaces/profile';
import { LocationStudentsPageQueryParams } from '@hoot/pages/district-schools/students/models';
import { enrolmentStatusChipLookup, studentProfileStatusChipLookup } from '@hoot/pages/district-schools/types';
import { routes } from '@hoot/routes/routes';

interface LocationStudentsTableProps {
  query: GetLocationStudentsQuery;
  results: LocationStudentSearchResult[];
  isLocationConnectedToEdlink: boolean;
}

const tableHeaderCells: TableHeaderCell<GetLocationStudentsQuerySortKeys>[] = [
  { label: 'Student Number', sortKey: GetLocationStudentsQuerySortKeys.StudentNumber },
  { label: 'Full Name', sortKey: GetLocationStudentsQuerySortKeys.Name },
  { label: 'Student School Number', sortKey: GetLocationStudentsQuerySortKeys.HfsNumber },
  { label: 'Enrolments' },
  { label: 'Profile Status', sortKey: GetLocationStudentsQuerySortKeys.Status },
  { label: 'SSO Status', sortKey: GetLocationStudentsQuerySortKeys.SsoStatus },
];

const LocationStudentsTable = (props: LocationStudentsTableProps) => {
  const { query, results, isLocationConnectedToEdlink } = props;
  const { orderBy, sortDirection } = query;

  const [, setSearchParams] = useSearchParams();
  const { scopes } = useAuth();
  const hasViewDistrictDetailsPermission = !!scopes.find((s) => s === HootEmployeeScope.ViewDistrictDetails);

  // If we've toggling the same order-by key, then just flip the sort direction.
  // Else, we're sorting by a different column, and we can default the sort to 'asc'.
  const toggleOrderBy = (newOrderBy: string) => () => {
    const isAsc = orderBy === newOrderBy && sortDirection === SortOrder.ASC;

    setSearchParams(
      (prev) => {
        const newSearchParams = createSearchParams(prev);
        newSearchParams.set(LocationStudentsPageQueryParams.OrderBy, newOrderBy);
        newSearchParams.set(LocationStudentsPageQueryParams.SortDirection, isAsc ? SortOrder.DESC : SortOrder.ASC);
        return newSearchParams;
      },
      { replace: true },
    );
  };

  const getHeaders = (): TableHeaderCell<GetLocationStudentsQuerySortKeys>[] => {
    let headers = tableHeaderCells;
    if (!hasViewDistrictDetailsPermission) {
      headers = headers.filter((h) => h.sortKey !== GetLocationStudentsQuerySortKeys.Name);
    }
    if (!isLocationConnectedToEdlink) {
      headers = headers.filter((h) => h.sortKey !== GetLocationStudentsQuerySortKeys.SsoStatus);
    }
    return headers;
  };

  return (
    <>
      <Table sx={{ minWidth: 650 }} aria-label="Location students table">
        <TableHead>
          <TableRow>
            {getHeaders().map((cell) => {
              if (!cell.sortKey) {
                return (
                  <TableCell variant="head" sx={{ width: cell.cellWidth }} key={`headerCell-${cell.label}`}>
                    {cell.label}
                  </TableCell>
                );
              }
              return (
                <TableCell
                  variant="head"
                  sx={{ width: cell.cellWidth }}
                  key={`headerCell-${cell.label}`}
                  sortDirection={
                    cell.sortKey ? (orderBy === cell.sortKey ? muiTableSortDirectionLookup[sortDirection ?? SortOrder.ASC] : false) : undefined
                  }
                >
                  {cell.sortKey ? (
                    <TableSortLabel
                      active={orderBy === cell.sortKey}
                      direction={orderBy === cell.sortKey ? muiTableSortDirectionLookup[sortDirection ?? SortOrder.ASC] : undefined}
                      onClick={toggleOrderBy(cell.sortKey)}
                    >
                      {cell.label}
                    </TableSortLabel>
                  ) : (
                    cell.label
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((row) => (
            <TableRow key={row.name} hover tabIndex={-1} sx={{ cursor: 'pointer' }}>
              <TableCell>
                <Link to={routes.users.students.details.url(row.id)}>{row.prefixedStudentNumber}</Link>
              </TableCell>
              {/* New requirements sc-10825 say to remove the link from name (only link the prefixed
                  student number in the 1st col) and only show if the user has permissions
               */}
              {hasViewDistrictDetailsPermission ? (
                <>
                  <TableCell>{row.name}</TableCell>
                </>
              ) : null}
              <TableCell>{row.hfsStudentNumber ?? 'Not Set'}</TableCell>
              <TableCell>
                <Stack gap={1.5}>
                  {row.enrolmentsToDisplay.map((e) => (
                    <Stack direction="row" gap={1} key={`${row.id}-${e.id}`}>
                      <Typography variant="bodyMedium">{e.friendlyId}</Typography>
                      <StatusChip
                        size="small"
                        statusIcon={enrolmentStatusChipLookup[e.status as EnrolmentStatus].statusIcon}
                        iconColor={enrolmentStatusChipLookup[e.status as EnrolmentStatus].iconColor}
                        label={enrolmentStatusMapping[e.status as EnrolmentStatus]}
                      />
                    </Stack>
                  ))}
                  {row.allEnrolments.length > row.enrolmentsToDisplay.length && (
                    <Typography variant="bodySmall">
                      {row.enrolmentsToDisplay.length > 0 ? '+' : null}
                      {row.allEnrolments.length - row.enrolmentsToDisplay.length} Closed &gt;30 days ago
                    </Typography>
                  )}
                </Stack>
              </TableCell>
              <TableCell>
                <StatusChip
                  size="small"
                  statusIcon={studentProfileStatusChipLookup[row.status as StudentStatus].statusIcon}
                  iconColor={studentProfileStatusChipLookup[row.status as StudentStatus].iconColor}
                  label={studentProfileStatusType[row.status]}
                />
              </TableCell>
              {isLocationConnectedToEdlink ? (
                <TableCell>
                  <StatusChip
                    size="small"
                    label={row.edlinkData ? (row.edlinkData.edlinkPersonId ? 'Connected' : 'Not Connected') : 'N/A'}
                    statusIcon={row.edlinkData ? StatusChipIcon.FullCircle : StatusChipIcon.EmptyCircle}
                    iconColor={
                      row.edlinkData
                        ? row.edlinkData.edlinkPersonId
                          ? StatusChipIconColor.Green
                          : StatusChipIconColor.Red
                        : StatusChipIconColor.Grey
                    }
                  />
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default LocationStudentsTable;
