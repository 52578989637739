import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import axios from 'axios';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import { config } from '@hoot/config';
import { SortableQuery } from '@hoot/interfaces/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/interfaces/pagination';
import { LocationStudentsSearchFieldOptions } from '@hoot/pages/district-schools/students/LocationSearchTextField';
import { QueryKey } from '../../../queryKeys';

export interface GetLocationStudentsQuery extends PaginatableQuery, SortableQuery<GetLocationStudentsQuerySortKeys> {
  search?: string;
  statuses?: StudentStatus[];
  hasEnrolments?: boolean[];
  enrolments?: string[];
  searchFieldSelection: LocationStudentsSearchFieldOptions;
}

export enum GetLocationStudentsQuerySortKeys {
  Name = 'name',
  StudentNumber = 'student-number',
  HfsNumber = 'hfs-number',
  Enrolments = 'enrolments',
  Status = 'status',
  SsoStatus = 'sso-status',
}

export interface LocationStudentSearchResult {
  id: string;
  prefixedStudentNumber: string;
  hfsStudentNumber: string | undefined;
  name: string;
  status: StudentStatus;
  allEnrolments: LocationStudentSearchResultEnrolment[];
  enrolmentsToDisplay: LocationStudentSearchResultEnrolment[];
  edlinkData: LocationStudentSearchResultEdlinkData | null;
}

export interface LocationStudentSearchResultEnrolment {
  id: string;
  friendlyId: string;
  status: string;
}

export interface LocationStudentSearchResultEdlinkData {
  integrationId: string;
  integrationName: string;
  edlinkPersonId: string | null;
}

export default function useGetLocationStudents(
  locationId: string,
  searchParams: GetLocationStudentsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<LocationStudentSearchResult>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetLocationStudents, locationId, searchParams],
    async () => {
      return (
        await axios.get(`${config.apiUrl}/admin/hfs/location/${locationId}/students`, {
          params: searchParams,
        })
      ).data;
    },
    options,
  );
}
