// Removes undefined props from an object (shallow). Does not go deeper than one level.
export const removeUndefinedProperties = (obj: any) => {
  return Object.entries(obj).reduce(
    (acc, [key, value]) =>
      value === undefined
        ? acc
        : {
            ...acc,
            [key]: value,
          },
    {},
  );
};
