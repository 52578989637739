import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { capitalCase } from 'change-case';
import { activeTeacherStatusOptions } from '@hoot/interfaces/teacher';
import FilterDropDown from '../../FilterDropDown';

function TeacherProfileStatusFilter(props: { value?: string[]; onChange: (val: string[]) => void; popOverButtonSx?: ButtonProps['sx'] }) {
  const { onChange, value, popOverButtonSx } = props;

  return (
    <FilterDropDown<string>
      title="Profile Status"
      dismissPopoverOnSelection={true}
      value={value}
      options={activeTeacherStatusOptions}
      onChange={(val) => onChange(val as string[])}
      popOverButtonSx={popOverButtonSx}
    />
  );
}

export function ActiveProfileStatusFilter(props: { value?: string[]; onChange: (val: string[] | undefined) => void }) {
  const { onChange, value = [] } = props;

  const handleDelete = (val: string) => () => {
    const updated = value.filter((v) => v !== val);
    onChange(updated);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`Profile Status: ${capitalCase(c)}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}

export default TeacherProfileStatusFilter;
