import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';
import useSearchTeacherAccounts, { QueryTeacherAccount, TeacherAccount } from '../../hooks/api/user/teacher/useSearchTeacherAccounts';

const TeacherAutoComplete = (props: {
  onChange?: (teacherAccount?: TeacherAccount) => void;
  value?: TeacherAccount;
  size?: 'medium' | 'small';
  label?: string;
}) => {
  const { onChange, value, size, label } = props;

  const [teacherAccounts, setTeacherAccounts] = useState<TeacherAccount[]>([]);
  const [query, setQuery] = useState<QueryTeacherAccount>({
    name: '',
    excludeInactive: false,
    page: 1,
    pageSize: 5,
  });

  const { isLoading } = useSearchTeacherAccounts(query, {
    keepPreviousData: true,
    onSuccess: (data) => {
      setTeacherAccounts(data.teacherAccount);
    },
  });

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        if (typeof newValue === 'object') {
          if (onChange) {
            onChange(newValue as TeacherAccount);
          }
        }
      }}
      size={size}
      fullWidth
      loading={isLoading}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={teacherAccounts}
      getOptionLabel={(option) => `${option.displayName} (${option.user.email})`}
      value={value || null}
      filterOptions={(x) => x}
      onInputChange={(event, val) => {
        setQuery((current) => ({ ...current, name: val }));
      }}
      renderInput={(params) => <TextField {...params} variant="outlined" label={label ?? 'Teacher Account Search'} fullWidth />}
    />
  );
};

export default TeacherAutoComplete;
