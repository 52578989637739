import { ScheduledLessonLanguage, ScheduledLessonType, lessonLanguagesLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TableContainer,
  TextField,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import SearchTextField, { SearchTextFieldProps } from '@hoot/components/form/SearchTextField';
import ProvinceStateFilter, { ActiveProvinceStateFilter } from '@hoot/components/form/filterDropDown/teacher-account/ProvinceStateFilters';
import TeacherProfileStatusFilter, {
  ActiveProfileStatusFilter,
} from '@hoot/components/form/filterDropDown/teacher-account/TeacherProfileStatusFilters';
import { ActiveTeacherStageFilter, TeacherStageFilter } from '@hoot/components/form/filterDropDown/teacher-account/TeacherStageFilters';
import { hootSupportedCountryMapping } from '@hoot/components/hfs-utils';
import { MYLTorontoSchoolBoard, defaultRowsPerPage } from '../../common/constants';
import useGetTeacherAccount from '../../hooks/api/user/teacher/useGetTeacherAccount';
import useSearchTeacherAccounts, {
  QueryTeacherAccount,
  TentativeLessonTeacherAccountFilter,
} from '../../hooks/api/user/teacher/useSearchTeacherAccounts';
import { BackgroundCheck, backgroundCheckTypes, hootQualifications } from '../../interfaces/teacher';
import YesNoFilter, { ActiveYesNoFilter } from '../form/filterDropDown/common/YesNoFilter';
import {
  ActiveCertificationAreasFilterDropDown,
  CertificationAreasFilterDropDown,
} from '../form/filterDropDown/teacher-account/CertificationAreasFilters';
import {
  ActiveTeacherSpecializedTrainingsFilterDropDown,
  TeacherSpecializedTrainingsFilterDropDown,
} from '../form/filterDropDown/teacher-account/SpecizliedTrainingFilters';
import { ActiveSpokenLanguagesFilterDropDown, SpokenLanguagesFilterDropDown } from '../form/filterDropDown/teacher-account/SpokenLanguagesFilters';
import StatusChip, { StatusChipIcon, StatusChipIconColor } from '../ui/StatusChip';
import { HeaderData, Table } from '../ui/Table';

export interface SelectedTeacher {
  id: string;
  name: string;
  number: string;
}

export interface TeacherRowFieldDefinitions {
  id: string;
  selected: boolean;
  fullName: string;
  displayName: string;
  teacherNumber: string;
  emailAddress: string;
  profileStatus: React.ReactNode;
}

const headers: HeaderData<TeacherRowFieldDefinitions>[] = [
  { name: 'Full Name', property: 'fullName', isSortable: false },
  { name: 'Teacher Number', property: 'teacherNumber', isSortable: false },
  { name: 'Email Address', property: 'emailAddress', isSortable: false },
  { name: 'Profile Status', property: 'profileStatus', isSortable: false },
];

export function TeacherModal(props: {
  open: boolean;
  onApply: (selectedTeacherId: TeacherRowFieldDefinitions) => void;
  onDismiss: () => void;
  tentativePeriods?: TentativeLessonTeacherAccountFilter[];
  periodIsLesson: boolean;
  showHasConflictFilter: boolean;
  queryDefaults?: Partial<QueryTeacherAccount>;
  lessonType?: ScheduledLessonType;
}) {
  const { open, onApply, onDismiss, tentativePeriods, periodIsLesson, showHasConflictFilter } = props;

  const [searchInput, setSearchInput] = useState('');
  const [selectedTeacher, setSelectedTeacher] = useState<TeacherRowFieldDefinitions>();
  const isSingleLesson = periodIsLesson && tentativePeriods?.length === 1;
  const enableHasConflictFilter = showHasConflictFilter && isSingleLesson; //Note: currently only supported on single lesson periods

  const [query, setQuery] = useState<QueryTeacherAccount>({
    name: '',
    excludeInactive: true,
    page: 1,
    pageSize: defaultRowsPerPage,
    status: [TeacherStatus.Active],
    tentativeLessons: tentativePeriods,
    hasConflict: enableHasConflictFilter ? false : undefined,
    withinAvailability: true,
    ...props.queryDefaults,
  });

  useEffect(() => {
    setQuery((currentState) => ({ ...currentState, ...props.queryDefaults }));
  }, [props.queryDefaults]);

  const teacherSearch = useSearchTeacherAccounts(query);

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchInput(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (debouncedText) => {
    setQuery((currentState) => ({ ...currentState, name: debouncedText }));
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize: pageSize }));
  };

  const handleSelected = (val: TeacherRowFieldDefinitions, selected: boolean) => {
    setSelectedTeacher(selected ? val : undefined);
  };

  const _onApply = () => {
    if (selectedTeacher) {
      onApply(selectedTeacher);
    }
  };

  const handleFilterChange = (updatedQuery: QueryTeacherAccount) => {
    setQuery(updatedQuery);
  };

  const data: TeacherRowFieldDefinitions[] =
    teacherSearch.data?.teacherAccount.map<TeacherRowFieldDefinitions>((ta) => ({
      isSelectable: false,
      emailAddress: ta.user.email,
      fullName: ta.user.firstName + ' ' + ta.user.lastName,
      displayName: ta.displayName,
      teacherNumber: ta.prefixedNumber,
      id: ta.id,
      profileStatus: (
        <StatusChip size="small" statusIcon={getChipStatusIcon(ta.status)} iconColor={getChipStatusColor(ta.status)} label={capitalCase(ta.status)} />
      ),
      selected: ta.id === selectedTeacher?.id,
    })) ?? [];

  const handleRowClick = (teacherAccountId: string) => {
    const teacher = data.find((t) => t.id === teacherAccountId);
    if (teacher) {
      setSelectedTeacher(teacher);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onDismiss}
      fullWidth
      maxWidth="lg"
      sx={{
        '& .MuiDialog-paper': {
          maxHeight: '800px',
          height: '80vh',
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        Select Teacher
        <IconButton onClick={onDismiss}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <SearchTextField
          sx={{
            marginTop: '6px', // So the floating label doesn't get clipped.
          }}
          searchInput={searchInput}
          onSearchInputChanged={onSearchInputChanged}
          onSearchInputDebounced={onSearchInputDebounced}
          autoFocus
        />
        <SearchFilters value={query} onChange={handleFilterChange} enableHasConflictFilter={enableHasConflictFilter} />
        <ActiveFilters value={query} onChange={handleFilterChange} />
        <Box sx={{ width: '100%', mt: 6.5, height: '4px' }}>{teacherSearch.isFetching && <LinearProgress />}</Box>
        <TableContainer>
          <Table
            isSelectable
            isPaginated
            data={data}
            headers={headers}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onSelect={handleSelected}
            count={teacherSearch.data?.count ?? 0}
            page={teacherSearch.data?.page ?? 1}
            onRowClick={handleRowClick}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Divider sx={{ position: 'absolute', top: 0, left: 0, right: 0, marginLeft: 3, marginRight: 3 }} />
        <Button onClick={onDismiss} variant="outlined" size="large">
          Cancel
        </Button>
        <Button onClick={_onApply} autoFocus variant="contained" size="large" disabled={!selectedTeacher}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function SelectTeacher(props: {
  value: SelectedTeacher | null;
  onChange?: (val: SelectedTeacher | null) => void;
  tentativePeriods?: TentativeLessonTeacherAccountFilter[];
  periodIsLesson: boolean;
  showHasConflictFilter: boolean;
  queryDefaults?: Partial<QueryTeacherAccount>;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  lessonType?: ScheduledLessonType;
}) {
  const [showModal, setShowModal] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const fetchTeacher = useGetTeacherAccount(props.value?.id!, {
    enabled: !!props.value,
  });

  const onRemoveSelected = () => {
    props.onChange?.(null);
  };

  const onShowSelect = () => {
    setShowModal(true);
  };

  const handleApply = (teacher: TeacherRowFieldDefinitions | undefined) => {
    if (teacher) {
      props.onChange?.({ id: teacher?.id, name: teacher?.fullName, number: teacher?.teacherNumber });
    } else {
      props.onChange?.(null);
    }

    setShowModal(false);
  };

  const handleDismiss = () => {
    setShowModal(false);
  };

  const teacherName = fetchTeacher.data ? `${fetchTeacher.data?.user.firstName} ${fetchTeacher.data?.user.lastName}` : 'None';

  return (
    <Stack direction="row" alignItems="baseline" gap={2} mt={3}>
      <TextField
        required
        fullWidth
        label="Teacher"
        value={teacherName}
        disabled={props.disabled}
        sx={{
          maxWidth: '500px',
        }}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <PermIdentityIcon />
            </InputAdornment>
          ),
          endAdornment: props.value ? (
            <InputAdornment position="end">
              <IconButton onClick={onRemoveSelected} disabled={props.disabled}>
                <HighlightOffIcon />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
        error={props.error}
        helperText={props.helperText}
      />

      {props.value ? (
        <Button variant="outlined" size="large" onClick={onRemoveSelected} disabled={props.disabled}>
          Remove
        </Button>
      ) : (
        <Button variant="outlined" size="large" onClick={onShowSelect} disabled={props.disabled}>
          Select
        </Button>
      )}
      {showModal ? (
        <TeacherModal
          queryDefaults={props.queryDefaults}
          open={showModal}
          onApply={handleApply}
          onDismiss={handleDismiss}
          tentativePeriods={props.tentativePeriods}
          lessonType={props.lessonType}
          periodIsLesson={props.periodIsLesson}
          showHasConflictFilter={props.showHasConflictFilter}
        />
      ) : null}
    </Stack>
  );
}

export const SearchFilters = (props: {
  value: QueryTeacherAccount;
  onChange?: (filters: QueryTeacherAccount) => void;
  enableHasConflictFilter: boolean;
}) => {
  const { value, onChange, enableHasConflictFilter } = props;

  const handleChange = (property: keyof QueryTeacherAccount) => (val: any | any[]) => {
    if (onChange) {
      onChange({ ...value, [property]: val });
    }
  };

  return (
    <Grid sx={{ marginTop: '24px' }} container item xs={12} alignItems="center">
      <Stack alignItems="center" direction="row" gap={2} sx={{ flexWrap: 'wrap' }}>
        <Typography sx={{ marginRight: '8px' }} variant="bodySmall">
          Filters
        </Typography>
        {enableHasConflictFilter ? (
          <YesNoFilter title="Has Conflict" onChange={handleChange('hasConflict')} value={value.hasConflict} popOverButtonSx={{ ml: 0, mr: '8px' }} />
        ) : null}
        <YesNoFilter title="Within Shift" onChange={handleChange('withinShift')} value={value.withinShift} popOverButtonSx={{ ml: 0, mr: '8px' }} />
        <YesNoFilter
          title="Within Availabilty"
          onChange={handleChange('withinAvailability')}
          value={value.withinAvailability}
          popOverButtonSx={{ ml: 0, mr: '8px' }}
        />
        <YesNoFilter
          title="Has Availability Exceptions"
          onChange={(val) => handleChange('noAvailabilityExceptions')(!val)}
          value={value.noAvailabilityExceptions !== undefined ? !value.noAvailabilityExceptions : undefined}
          popOverButtonSx={{ ml: 0, mr: '8px' }}
        />
        <SpokenLanguagesFilterDropDown
          onChange={handleChange('spokenLanguages')}
          value={value.spokenLanguages}
          popOverButtonSx={{ ml: 0, mr: '8px' }}
        />
        <CertificationAreasFilterDropDown
          onChange={handleChange('certificationAreas')}
          value={value.certificationAreas}
          popOverButtonSx={{ ml: 0, mr: '8px' }}
        />
        <TeacherSpecializedTrainingsFilterDropDown
          onChange={handleChange('specializedTrainings')}
          value={value.specializedTrainings}
          popOverButtonSx={{ ml: 0, mr: '8px' }}
        />
        <ProvinceStateFilter onChange={handleChange('provinceState')} value={value.provinceState} popOverButtonSx={{ ml: 0, mr: '8px' }} />
        <TeacherProfileStatusFilter onChange={handleChange('status')} value={value.status} popOverButtonSx={{ ml: 0, mr: '8px' }} />
        <TeacherStageFilter onChange={handleChange('teacherStage')} value={value.teacherStage} popOverButtonSx={{ ml: 0, mr: '8px' }} />
      </Stack>
    </Grid>
  );
};

export const ActiveFilters = (props: { value: QueryTeacherAccount; onChange?: (filters: QueryTeacherAccount) => void }) => {
  const { value, onChange } = props;

  const handleChange =
    (
      property: keyof Pick<
        QueryTeacherAccount,
        | 'withinAvailability'
        | 'noAvailabilityExceptions'
        | 'hasConflict'
        | 'withinShift'
        | 'spokenLanguages'
        | 'certificationAreas'
        | 'specializedTrainings'
        | 'countryRequirements'
        | 'provinceState'
        | 'status'
        | 'teacherStage'
      >,
    ) =>
    (updatedValues: any | any[]) => {
      const updatedFilters = { ...value };
      updatedFilters[property] = updatedValues;
      if (onChange) {
        onChange(updatedFilters);
      }
    };

  return (
    <Grid sx={{ marginTop: '24px' }} container item xs={12} alignItems="flex-start">
      <Stack alignItems="flex-start" spacing="8px" direction="row">
        <Typography sx={{ marginTop: '8px', marginRight: '8px' }} variant="bodySmall">
          Active
        </Typography>
        <Stack direction="row" justifyContent="flex-start" flexWrap="wrap" columnGap="8px" rowGap="8px">
          <ActiveYesNoFilter
            label="Has Conflict"
            onChange={handleChange('hasConflict')}
            value={value.hasConflict !== undefined ? (value.hasConflict ? 'Yes' : 'No') : undefined}
          />
          <ActiveYesNoFilter
            label="Within Availabilty"
            onChange={handleChange('withinAvailability')}
            value={value.withinAvailability !== undefined ? (value.withinAvailability ? 'Yes' : 'No') : undefined}
          />
          <ActiveYesNoFilter
            label="Has Availability Exceptions"
            onChange={handleChange('noAvailabilityExceptions')}
            value={value.noAvailabilityExceptions !== undefined ? (value.noAvailabilityExceptions ? 'No' : 'Yes') : undefined}
          />
          <ActiveYesNoFilter
            label="Within Shift"
            onChange={handleChange('withinShift')}
            value={value.withinShift !== undefined ? (value.withinShift ? 'Yes' : 'No') : undefined}
          />

          <ActiveSpokenLanguagesFilterDropDown onChange={handleChange('spokenLanguages')} value={value.spokenLanguages} />

          <ActiveCertificationAreasFilterDropDown onChange={handleChange('certificationAreas')} value={value.certificationAreas} />

          <ActiveTeacherSpecializedTrainingsFilterDropDown onChange={handleChange('specializedTrainings')} value={value.specializedTrainings} />

          <ActiveProvinceStateFilter onChange={handleChange('provinceState')} value={value.provinceState} />

          <ActiveProfileStatusFilter onChange={handleChange('status')} value={value.status} />

          {value.lessonDetails?.language && value.lessonDetails.language !== ScheduledLessonLanguage.English ? (
            <Chip label={`Language Qualifications: ${lessonLanguagesLookup[value.lessonDetails.language]}`} variant="outlined" />
          ) : null}

          {value.hootQualificationRequirements && value.hootQualificationRequirements.length > 0
            ? value.hootQualificationRequirements.map((hq: HootQualification, index: number) => (
                <Chip key={`${hq}-${index}`} label={`Hoot Qualifications: ${hootQualifications[hq]}`} variant="outlined" />
              ))
            : null}

          {value.backgroundCheckRequirements && value.backgroundCheckRequirements.length > 0
            ? value.backgroundCheckRequirements.map((bc: BackgroundCheck, index: number) => (
                <Chip key={`${bc}-${index}`} label={`Background Checks: ${backgroundCheckTypes[bc]}`} variant="outlined" />
              ))
            : null}

          {value.countryRequirements ? (
            <Chip label={`Country: ${hootSupportedCountryMapping[value.countryRequirements]}`} variant="outlined" />
          ) : null}

          {value.lessonDetails?.account === MYLTorontoSchoolBoard ? (
            <Chip label={`Hoot Qualifications: ${hootQualifications[HootQualification.ANTI_OPPRESSION]}`} variant="outlined" />
          ) : null}

          <ActiveTeacherStageFilter onChange={handleChange('teacherStage')} value={value.teacherStage} />
        </Stack>
      </Stack>
    </Grid>
  );
};

function getChipStatusIcon(value: TeacherStatus): StatusChipIcon {
  switch (value) {
    case TeacherStatus.Active:
      return StatusChipIcon.FullCircle;
    case TeacherStatus.Inactive:
      return StatusChipIcon.EmptyCircle;
    case TeacherStatus.Candidate:
      return StatusChipIcon.PartialCircle;
  }
}

function getChipStatusColor(value: TeacherStatus): StatusChipIconColor {
  if (!value) return StatusChipIconColor.Grey;
  switch (value) {
    case TeacherStatus.Active:
      return StatusChipIconColor.Green;
    case TeacherStatus.Candidate:
      return StatusChipIconColor.Blue;
    case TeacherStatus.Inactive:
      return StatusChipIconColor.Red;
  }
}

export default SelectTeacher;
