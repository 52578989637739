export enum HootLevelModuleStatus {
  Mastered = 'MASTERED',
  Passed = 'PASSED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
}

export const hootLevelModuleStatusLookup: Record<HootLevelModuleStatus, string> = {
  IN_PROGRESS: 'In Progress',
  MASTERED: 'Mastered',
  NOT_STARTED: 'Not Assessed',
  PASSED: 'Not Assessed',
};

export enum HootLevelUnitStatus {
  Mastered = 'MASTERED',
  Passed = 'PASSED',
  InstructionRequired = 'INSTRUCTION_REQUIRED',
  ReviewRequired = 'REVIEW_REQUIRED',
  NotStarted = 'NOT_STARTED',
}

export const hootLevelUnitStatusLookup: Record<HootLevelUnitStatus, string> = {
  INSTRUCTION_REQUIRED: 'Instruction Required',
  MASTERED: 'Mastered',
  NOT_STARTED: 'Not Started',
  PASSED: 'Passed',
  REVIEW_REQUIRED: 'Review Required',
};
