import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../../config';
import { QueryKey } from '../../queryKeys';

export interface ThemesResponse {
  id: string;
  name: string;
}

async function getThemes(): Promise<ThemesResponse[]> {
  const { data } = await axios.get<ThemesResponse[]>(`${config.apiUrl}/admin/library/themes`);
  return data;
}

export default function useGetThemes(options?: Omit<UseQueryOptions<ThemesResponse[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetThemes], () => getThemes(), options);
}
