import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { defaultRowsPerPage } from '@hoot/common/constants';
import { HeaderData, SelectState, Table } from '@hoot/components/ui/Table';
import { SortOrder } from '@hoot/interfaces/order-by';
import { OrderBy } from '@hoot/pages/lessons/enums';
import SearchTextField from '../../../../components/form/SearchTextField';
import useQueryLibraryV2, { AdminQueryLibraryV2, LibraryOrderByEnum } from '../../../../hooks/api/library/useQueryLibraryV2';

interface AddBookColumns {
  id: string;
  selected: boolean;
  cover: React.ReactNode;
  title: string;
  bookLevel: string;
  instructionalUnits: string;
  filterLevelPrioritizedSkills: string;
  bookNumber: string;
}

const studentFavoritesHeaders: HeaderData<AddBookColumns>[] = [
  { name: 'ID', property: 'id', isHidden: true },
  { name: 'Selected', property: 'selected', isHidden: true },
  { name: 'Cover', property: 'cover' },
  { name: 'Title', property: 'title', isSortable: true, sortKey: LibraryOrderByEnum.Title },
  { name: 'Book Level', property: 'bookLevel', isSortable: true, sortKey: LibraryOrderByEnum.BookLevel },
  { name: 'Instructional Unit', property: 'instructionalUnits' },
  { name: 'Prioritized Skill', property: 'filterLevelPrioritizedSkills' },
  { property: 'bookNumber', name: 'Number', isSortable: true, sortKey: LibraryOrderByEnum.BookNumber },
];

interface Props {
  onClose: () => void;
  onApply: (bookIds: string[]) => void;
  isLessonPlanBook?: boolean;
}

export function AddBookDialog(props: Props) {
  const { isLessonPlanBook = false } = props;
  const { studentProfileId } = useParams();
  const [query, setQuery] = useState<AdminQueryLibraryV2>({
    page: 1,
    pageSize: defaultRowsPerPage,
    isActive: true,
    orderBy: SortOrder.ASC,
    ...(isLessonPlanBook
      ? {
          isInstructionalLibrary: true,
          studentProfileId: studentProfileId,
        }
      : {}),
  });

  const [selected, setSelected] = useState<string[]>([]);
  const [searchText, setSearchInput] = useState<string>('');

  const { data, isFetching } = useQueryLibraryV2(query);

  const onSearchInputChanged = (text: string) => {
    setSearchInput(text);
  };

  const onSearchInputDebounced = (debouncedText: string) => {
    setQuery((currentState) => ({
      ...currentState,
      query: debouncedText,
      page: 1,
    }));
  };

  const handleOnClose = () => {
    props.onClose();
  };

  const handleOnApply = () => {
    props.onApply(selected);
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setSelected([]);
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, defaultRowsPerPage);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize: pageSize }));
  };

  const handleSelectAll = (selectState: SelectState) => {
    if (selectState === 'all') {
      setSelected([]);
    } else {
      setSelected(data?.data.map((d) => d.id) ?? []);
    }
  };

  const handleSelect = (val: AddBookColumns, selected: boolean) => {
    if (selected) {
      setSelected((currentState) => [...currentState, val.id]);
    } else {
      setSelected((currentState) => currentState.filter((s) => s !== val.id));
    }
  };

  const handleSortBy = (column: keyof AddBookColumns) => {
    function orderColumn() {
      switch (column) {
        case 'title':
          return LibraryOrderByEnum.Title;
        case 'bookLevel':
          return LibraryOrderByEnum.BookLevel;
        case 'bookNumber':
          return LibraryOrderByEnum.BookNumber;
        default:
          return LibraryOrderByEnum.Title;
      }
    }

    setQuery((prev) => ({
      ...prev,
      orderColumn: orderColumn(),
      orderBy: prev.orderBy === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
      page: 1,
    }));
  };

  function sortBy(): keyof AddBookColumns {
    switch (query.orderColumn) {
      case LibraryOrderByEnum.Title:
        return 'title';
      case LibraryOrderByEnum.BookLevel:
        return 'bookLevel';
      case LibraryOrderByEnum.BookNumber:
        return 'bookNumber';
      default:
        return 'title';
    }
  }

  const tableData =
    data?.data.map<AddBookColumns>((d) => ({
      id: d.id,
      title: d.title,
      selected: selected.findIndex((s) => s === d.id) >= 0,
      bookLevel: d.level,
      cover: (
        <Box sx={{ height: '40px', width: '40px' }}>
          <Box sx={{ height: '100%', width: '100%', objectFit: 'cover' }} component="img" src={d.cover} />
        </Box>
      ),
      instructionalUnits: d.instructionalUnit.join(', '),
      filterLevelPrioritizedSkills: d.filterLevelPrioritizedSkills.join(', '),
      bookNumber: d.number,
    })) ?? [];

  const count = data?.count ?? 0;
  const pageSize = data?.pageSize ?? 0;
  const page = data?.page ?? 0;

  return (
    <Dialog open={true} onClose={handleOnClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="headlineSmall">Add Book</Typography>
        <IconButton onClick={handleOnClose} sx={{ color: '#1C1B1F' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <SearchTextField
            sx={{ marginTop: '8px' }}
            searchInput={searchText}
            onSearchInputChanged={onSearchInputChanged}
            onSearchInputDebounced={onSearchInputDebounced}
          />
          <Typography sx={{ marginTop: '16px' }} variant="labelLarge">
            {query.query ? `Showing ${count} results for ${query.query}` : ' '}
          </Typography>

          <Table
            stickyHeader
            isPaginated
            isSelectable
            isSortable
            isLoading={isFetching}
            data={tableData}
            headers={studentFavoritesHeaders}
            count={count}
            page={page}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onSelect={handleSelect}
            onSelectAll={handleSelectAll}
            sortOrder={query.orderBy === SortOrder.ASC ? OrderBy.Asc : OrderBy.Desc}
            sortBy={sortBy()}
            onSortBy={handleSortBy}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: 3 }}>
        <Divider sx={{ position: 'absolute', top: 0, left: '24px', right: '24px' }} />
        <Button size="large" variant="outlined" color="primary" onClick={handleOnClose}>
          Cancel
        </Button>

        <Button onClick={handleOnApply} size="large" variant="contained" type="button">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
