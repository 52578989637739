import {
  CancellationReason,
  ScheduledLessonLanguage,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonType,
  SubRequired,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { OrderBy, OrderColumn, TeacherAssigned } from '@hoot/pages/lessons/enums';
import { DayOfWeek } from '@hoot/utils/dateTime';

export enum LessonSearchFieldOptions {
  LessonNumber = 'LESSON_NUMBER',
  EnrolmentId = 'ENROLMENT_FRIENDLY_ID',
  LessonSetNumber = 'LESSON_SET_NUMBER',
  StudentNumber = 'STUDENT_NUMBER',
  ParentNumber = 'PARENT_NUMBER',
  ParentEmail = 'PARENT_EMAIL',
  TeacherNumber = 'TEACHER_NUMBER',
  TeacherEmail = 'TEACHER_EMAIL',
  All = 'ALL',
}

export interface LessonQueryRequest {
  accountId?: string;
  cancelledReason?: CancellationReason[];
  daysOfWeek?: DayOfWeek[];
  districtId?: string;
  enrolmentId?: string[];
  fromDate?: number;
  fromStartTime?: string;
  isCustomerBillable?: boolean;
  isRecurring?: boolean;
  language?: ScheduledLessonLanguage;
  lessonId?: string | string[];
  lessonSetNumber?: string;
  orderBy: OrderBy;
  orderColumn: OrderColumn;
  page: number;
  pageSize: number;
  previousTeacherAccountEmails?: string[];
  query?: string;
  lessonSearchFieldSelection?: LessonSearchFieldOptions;
  schoolId?: string;
  status?: ScheduledLessonStatus[];
  studentProfileIds?: string[];
  studentProfileStatus?: StudentStatus;
  subject?: ScheduledLessonSubject;
  subRequired?: SubRequired;
  teacherAccountIds?: string[];
  teacherAssigned?: TeacherAssigned;
  toDate?: number;
  toStartTime?: string;
  type?: ScheduledLessonType;
}
