import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { Close } from '@mui/icons-material';
import { ButtonProps, Chip } from '@mui/material';
import { hootQualificationOptions, hootQualifications } from '@hoot/interfaces/teacher';
import FilterDropDown from '../../FilterDropDown';

export function ProductRequirementsFilterDropDown(props: {
  value: HootQualification[];
  onChange: (val: HootQualification[]) => void;
  popOverButtonSx?: ButtonProps['sx'];
}) {
  const { value, onChange, popOverButtonSx } = props;

  return (
    <>
      <FilterDropDown<string>
        title="Requirements"
        dismissPopoverOnSelection={true}
        value={value}
        options={hootQualificationOptions.map((s) => ({
          label: s.label,
          value: s.value,
        }))}
        onChange={(val) => onChange(val as HootQualification[])}
        popOverButtonSx={popOverButtonSx}
      />
    </>
  );
}

export function ActiveProductRequirementsFilterDropDown(props: { value?: HootQualification[]; onChange: (val: HootQualification[]) => void }) {
  const { value = [], onChange } = props;

  const handleDelete = (val: HootQualification) => () => {
    const updated = value.filter((v) => v !== val);

    onChange(updated as HootQualification[]);
  };

  return (
    <>
      {value.map((c) => (
        <Chip
          key={`chip-status-${c}`}
          label={`Requirements: ${hootQualifications[c as HootQualification]}`}
          variant="outlined"
          onDelete={handleDelete(c)}
          deleteIcon={<Close />}
        />
      ))}
    </>
  );
}
