import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { HootEmployee } from '@hoot/interfaces/hoot-employee';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

async function getHootEmployee(userId: string): Promise<HootEmployee> {
  const { data } = await axios.get<HootEmployee>(`${config.apiUrl}/admin/hoot-employee/${userId}`);
  return data;
}

export default function useGetHootEmployee(userId: string, options?: Omit<UseQueryOptions<HootEmployee, AxiosError>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetHootEmployee, userId], () => getHootEmployee(userId!), options);
}
