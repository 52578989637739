import { UserImportType } from '@hoot-reading/hoot-core/dist/enums/user/user-import/user-import-type.enum';
import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { UserImportResponse } from '@hoot/hooks/api/hfs/district-schools/useUserImportCsv';
import UserImportSummary from './UserImportSummary';
import UserImportTypeSelection from './UserImportTypeSelection';
import UserImportUploadFile from './UserImportUploadFile';
import { userImportLabelDictionary } from './util';

const steps = ['Type selection', 'Upload file', 'Summary'];

export enum ImportUserStep {
  TypeSelection = 1,
  UploadFile = 2,
  Summary = 3,
}

interface ImportStepProps {
  activeStep: ImportUserStep;
  setActiveStep: React.Dispatch<React.SetStateAction<ImportUserStep>>;
  handleClose: () => void;
  importType: UserImportType | undefined;
  importTypeCallback: React.Dispatch<React.SetStateAction<UserImportType | undefined>>;
  setFileNameCallback: React.Dispatch<React.SetStateAction<string>>;
  uploadedImport: UserImportResponse | undefined;
  setImportResponseCallback: React.Dispatch<React.SetStateAction<UserImportResponse | undefined>>;
  enrolmentId: string;
}

interface UserImportDialogProps {
  close: () => void;
  isOpen: boolean;
  enrolmentId: string;
}

const ImportStep = (props: ImportStepProps) => {
  const {
    activeStep,
    setActiveStep,
    handleClose,
    importType,
    importTypeCallback,
    setFileNameCallback,
    uploadedImport,
    setImportResponseCallback,
    enrolmentId,
  } = props;

  const importHistory = useRef<ImportUserStep[]>([]);

  const previousStep = () => {
    const previousStep = importHistory.current.pop();
    setFileNameCallback('');
    importTypeCallback(undefined);
    if (previousStep) {
      setActiveStep(previousStep);
    }
  };

  const nextStep = (step: ImportUserStep) => {
    setActiveStep((currentStepState) => {
      importHistory.current.push(currentStepState);
      return step;
    });
  };

  switch (activeStep) {
    case ImportUserStep.TypeSelection:
      return <UserImportTypeSelection nextStep={nextStep} handleClose={handleClose} importType={importType} setImportType={importTypeCallback} />;
    case ImportUserStep.UploadFile:
      return (
        <UserImportUploadFile
          previousStep={previousStep}
          nextStep={nextStep}
          handleClose={handleClose}
          importType={importType}
          setFileNameCallback={setFileNameCallback}
          uploadedImport={uploadedImport}
          setImportResponseCallback={setImportResponseCallback}
          enrolmentId={enrolmentId}
        />
      );
    case ImportUserStep.Summary:
      return <UserImportSummary uploadedImport={uploadedImport} handleClose={handleClose} />;
    default:
      throw new Error(`Import State ${activeStep} is not valid"`);
  }
};

const UserImportDialog = (props: UserImportDialogProps) => {
  const { close, isOpen, enrolmentId } = props;
  const [activeStep, setActiveStep] = useState<ImportUserStep>(ImportUserStep.TypeSelection);
  const step = activeStep - 1;

  const [importType, setImportType] = useState<UserImportType | undefined>();
  const [fileName, setFileName] = useState<string>('');
  const [importResponse, setImportResponse] = useState<UserImportResponse | undefined>();

  const handleClose = () => close();

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <Stack direction="column" sx={{ backgroundColor: 'white', p: 3, minHeight: '515px' }} gap={3}>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="headlineSmall">Roster Bulk Import Tool</Typography>
            <Close sx={{ '&:hover': { cursor: 'pointer' }, mr: '22px' }} onClick={handleClose} />
          </Stack>

          <Stepper sx={{ marginTop: 6 }} activeStep={step} alternativeLabel>
            {steps.map((label, idx) => {
              return (
                <Step key={label}>
                  <StepLabel StepIconProps={{ sx: { transform: 'scale(2)', zIndex: 1, color: '#757575' } }}>
                    <span style={{ fontWeight: step === idx ? 'bold' : 'normal' }}>{label}</span>

                    <Typography variant="bodySmall" component="p" sx={{ mt: 0.5, color: '#757575', minHeight: 16 }}>
                      {/* Step 1 small label */}
                      {importType && idx === 0 && userImportLabelDictionary[importType]}
                      {/* Step 2 small label */}
                      {fileName && idx === 1 && fileName}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <ImportStep
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleClose={handleClose}
          importType={importType}
          importTypeCallback={setImportType}
          setFileNameCallback={setFileName}
          uploadedImport={importResponse}
          setImportResponseCallback={setImportResponse}
          enrolmentId={enrolmentId}
        />
      </Stack>
    </Dialog>
  );
};

export default UserImportDialog;
