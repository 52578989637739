import { DateTime } from 'luxon';
import React, { PropsWithChildren, useRef } from 'react';
import { DayOfWeek } from '@hoot/utils/dateTime';
import { RecurrenceEndType } from '../date-time-step/CreateLessonDateTimeStep';

export enum TentativeLessonStatus {
  NoTeacher = 'NO_TEACHER',
  TeacherAssigned = 'TEACHER_ASSIGNED',
  Exclude = 'EXCLUDE',
  NotSet = 'NOT_SET',
}

export interface TentativeLesson {
  lessonAt: DateTime;
  status: TentativeLessonStatus;
}

export interface DayOfWeekForLesson {
  isSelected: boolean;
  dayOfWeek: DayOfWeek;
  time?: string;
}

export interface LessonDetails {
  lessonAt?: DateTime;
  duration?: number;
  recurring?: boolean;
  daysOfWeek?: DayOfWeekForLesson[];
  recurrenceEnd?: RecurrenceEndType;
  occurrences?: number;
  endAfterDate?: DateTime;
  teacher?: {
    id: string;
    name: string;
    number: string;
  };
  tentativeLessons?: TentativeLesson[];
  sameTimeEveryDay?: boolean;
}

interface StudentLessonDetailsProps {
  lessonDetails: LessonDetails;
  reset: () => void;
}

const StudentLessonDetailsContext = React.createContext<StudentLessonDetailsProps>(undefined!);
StudentLessonDetailsContext.displayName = 'StudentLessonDetailsContext';

export const useStudentLessonDetailsContext = () => {
  const context = React.useContext(StudentLessonDetailsContext);

  if (context === undefined) {
    throw new Error('useStudentLessonDetailsContext must be used within a StudentAndLessonContextProvider');
  }
  return context;
};

const StudentLessonDetailsProvider = (props: PropsWithChildren<any>) => {
  const { children } = props;

  const lessonDetails = useRef<LessonDetails>({});

  return (
    <StudentLessonDetailsContext.Provider
      value={{
        lessonDetails: lessonDetails.current,
        reset: () => {
          lessonDetails.current = {};
        },
      }}
    >
      {children}
    </StudentLessonDetailsContext.Provider>
  );
};

export default StudentLessonDetailsProvider;
